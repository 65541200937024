import {DashboardState} from "metadata/dashboard/DashboardState";
import {ArcEngineState} from "engine/ArcEngineState";
import {ArcMessageType} from "engine/ArcMessage";
import {GlobalFiltersMessage} from "engine/actor/GlobalFiltersMessage";
import {DashboardBuilderDelegate} from "app/dashboard/DashboardBuilderDelegate";
import {FacetingSelectionHandler} from "engine/actor/FacetingSelectionHandler";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ReferenceQuery} from "metadata/dashboard/DashboardQueries";
import {Optional} from "common/Optional";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {SingleSource} from "metadata/query/ArcQLSource";
import {SelectionMessage} from "engine/SelectionMessage";
import {VizSelection} from "engine/actor/VizSelection";
import {DashboardFilterActor, FILTER_ACTOR_ID} from "app/dashboard/DashboardFilterActor";
import {DatasetV2Service} from "services/DatasetV2Service";

/**
 * Applies appropriate conversions between Dashboard state and Arc Engine state.
 */
export class StateConverter {

    /**
     * Given engine state, convert to the corresponding dashboard state.
     */
    static toDashboardState(
        dashboardFilterActor: DashboardFilterActor,
        engineState: ArcEngineState
    ): DashboardState {
        const selections = new Map<string, VizSelection>();

        for (const [actorId, message] of engineState.messagesOfType(ArcMessageType.SELECTION)) {
            // Only interested in selection messages from ReferencedQueryActors
            if (!DashboardBuilderDelegate.isQueryActor(actorId)) {
                continue;
            }
            const selection = (message as SelectionMessage).selection;
            if (selection) {
                const queryId = DashboardBuilderDelegate.queryIdFromQueryActor(actorId);
                selections.set(queryId, selection);
            }
        }

        return new DashboardState(
            dashboardFilterActor.currentGlobalFilters,
            selections
        );
    }

    /**
     * Given a dashboard and a state, convert to the corresponding ArcEngine state.
     */
    static async toArcEngineState(
        dashboard: ArcDashboard,
        dashboardState: DashboardState
    ): Promise<ArcEngineState> {
        // create global filter state
        const newEngineState = new ArcEngineState();
        newEngineState.setActorMessage(FILTER_ACTOR_ID, ArcMessageType.GLOBAL_FILTERS, new GlobalFiltersMessage(dashboardState.globalFilters));

        // apply selection state
        const controller = new AbortController();
        for (const [queryId, selection] of dashboardState.selections) {
            // if dashboard doesn't have this query metadata, ignore as it's erroneous or out of date
            if (!dashboard.queries.has(queryId)) {
                continue;
            }

            const queryMetadata = Optional.ofType(dashboard.queries.get(queryId), ReferenceQuery);
            if (queryMetadata.isNone) {
                continue;
            }

            // inherent optimization already exists with metadata caching when fetching arcQL + dataset
            const arcQL = await ServiceProvider.get(MetadataService)
                .fetchArcQL(queryMetadata.get().fqn, controller.signal, dashboard.fqn)
                .then(e => e.rightOrThrow());
            const dataset = await ServiceProvider.get(DatasetV2Service)
                .describeDataset((arcQL.source as SingleSource).fqn, controller.signal, arcQL.fullyQualifiedName)
                .then(e => e.rightOrThrow());

            new FacetingSelectionHandler(arcQL, dataset).onSelection(selection)
                .map(message =>
                    newEngineState.setActorMessage(
                        DashboardBuilderDelegate.queryActorId(queryId),
                        ArcMessageType.SELECTION,
                        message
                    )
                );
        }

        return newEngineState;
    }
}