import React, {FunctionComponent, ReactNode} from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import {DialogS} from "app/DialogS";
import {StepLabel, Stepper} from "@mui/material";
import Step from '@mui/material/Step';
import {ChooseConnectionStep, ConnectionSelection} from "app/components/settings/connections/ChooseConnectionStep";
import {EnterConnectionStep} from "app/components/settings/connections/EnterConnectionStep";
import {ConnectionStepperHandler} from "app/components/settings/connections/ConnectionStepperHandler";
import {InputConnectionDetails} from "metadata/connections/InputConnectionDetails";
import {ConnectionStepperHandlerFactory} from "app/components/settings/connections/ConnectionStepperHandlerFactory";
import DialogContent from "@mui/material/DialogContent";
import styled from "@emotion/styled";
import {TestConnectionStep} from "app/components/settings/connections/TestConnectionStep";
import {ServiceProvider} from "services/ServiceProvider";
import {OrgService} from "services/OrgService";
import {CreateConnectionRequest} from "metadata/connections/CreateConnectionRequest";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {OrgMembership} from "metadata/OrgMembership";

interface Props {
    orgMemberships: OrgMembership[]
    onConfirm: () => void
    onCancel: () => void
}

type Step = {
    index: StepIndex
    label: string
    content: ReactNode
    actions?: ReactNode[]
}

enum StepIndex {
    CHOOSE_CONNECTION = 0,
    ENTER_DETAILS = 1,
    TEST_CONNECTION = 2
}

/**
 * Dialog for adding a new connection.
 */
export const AddConnectionDialog: FunctionComponent<Props> = (props: Props) => {

    const [activeStep, setActiveStep] = React.useState(StepIndex.CHOOSE_CONNECTION);
    const [connectionSelection, setConnectionSelection] = React.useState<ConnectionSelection>(null);
    const [connectionHandler, setConnectionHandler] = React.useState<ConnectionStepperHandler>(null);
    const [inputDetails, setInputDetails] = React.useState<InputConnectionDetails>(null);
    const [createdConnection, setCreatedConnection] = React.useState<RemoteDataSource>(null);

    const onConnectionSelection = (selection: ConnectionSelection) => {
        setActiveStep(StepIndex.ENTER_DETAILS);
        setConnectionSelection(selection);
        setConnectionHandler(ConnectionStepperHandlerFactory.forType(selection.type));
    };

    /**
     * Upon submitting / saving the connection details
     */
    const onSubmitDetails = () => {
        // create connection
        ServiceProvider.get(OrgService).saveConnection(
            connectionSelection.orgName,
            new CreateConnectionRequest(
                connectionSelection.label,
                connectionSelection.type,
                inputDetails
            )
        ).then(
            response =>
                response.match(
                    remoteDataSource => {
                        setCreatedConnection(remoteDataSource);
                        setActiveStep(StepIndex.TEST_CONNECTION);
                    },
                    error => {
                        ServiceProvider.get(NotificationsService).publish(
                            "AddConnectionDialog.saveConnection",
                            NotificationSeverity.ERROR,
                            error.prettyPrint()
                        );
                    }
                )
        );
    };

    const steps: Step[] = [
        {
            index: StepIndex.CHOOSE_CONNECTION,
            label: "Choose Connection",
            content: <ChooseConnectionStep
                orgMemberships={props.orgMemberships}
                onConnectionSelection={onConnectionSelection}
            />
        },
        {
            index: StepIndex.ENTER_DETAILS,
            label: "Connection Details",
            content: <EnterConnectionStep
                handler={connectionHandler}
                onDetailChange={setInputDetails}
            />,
            actions: [
                <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>,
                <Button
                    variant="contained"
                    onClick={onSubmitDetails}
                    disabled={!inputDetails?.isValid()}
                >
                    Save
                </Button>
            ]
        },
        {
            index: StepIndex.TEST_CONNECTION,
            label: "Test Connection",
            content: <TestConnectionStep
                handler={connectionHandler}
                connection={createdConnection}
            />,
            actions: [
                <Button
                    variant="contained"
                    onClick={props.onConfirm}
                >
                    Confirm
                </Button>
            ]
        }
    ];

    return (
        <Dialog
            open={true}
            onClose={props.onCancel}
            PaperProps={{
                sx: {
                    width: "600px"
                }
            }}
        >
            <DialogS.Title>Add a Database Connection</DialogS.Title>
            <S.DialogContent dividers>
                <DialogS.Body>
                    <Box sx={{width: '100%'}}>
                        <Stepper
                            activeStep={activeStep}
                        >
                            {steps.map((step, index) => (
                                <Step key={index} completed={activeStep > index}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <S.StepContent>
                        {steps[activeStep].content}
                    </S.StepContent>
                </DialogS.Body>
            </S.DialogContent>
            {
                steps[activeStep].actions && <S.Actions>
                    {...steps[activeStep].actions}
                </S.Actions>
            }
        </Dialog>
    );
};

class S {
    static DialogContent = styled(DialogContent)`
        padding: 16px;
    `;

    static StepContent = styled.div`
        margin-top: 16px;
        // to align with the stepper
        padding-left: 8px;
    `;

    static readonly Actions = styled(DialogActions)`
        padding: 16px;
    `;
}