import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {FolderResult} from "metadata/project/FolderResult";

/**
 * Folders from a single account.
 */
export class Folders {

    static fromJSON(json: JsonObject): Folders {
        return new Folders(
            json['content'].map(FolderResult.fromJSON)
        );
    }

    private readonly folders: Map<string, FolderResult>;

    constructor(folders: FolderResult[]) {
        this.folders = new Map(folders.map(folder => [folder.name, folder]));
    }

    get(name: string): Optional<FolderResult> {
        return Optional.of(this.folders.get(name));
    }

    get all(): FolderResult[] {
        return Array.from(this.folders.values());
    }
}