import {Enum} from "common/Enum";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import {ComponentType} from "react";

/**
 * The various account types (ex: user vs organization)
 */
export class AccountType extends Enum {

    static USER = new this('user', 'User', AccountBoxOutlinedIcon);
    static ORGANIZATION = new this('org', 'Organization', AccountBalanceOutlinedIcon);

    constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly icon?: ComponentType
    ) {
        super(name);
    }

}

AccountType.finalize();