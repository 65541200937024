import * as React from "react";
import {ReactNode} from "react";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import {DashboardWidgetProps} from "app/dashboard/widgets/WidgetContainer";
import {ChartMetadata} from "metadata/dashboard/widgets/ChartMetadata";
import {Optional} from "common/Optional";
import {Selectable} from "engine/actor/Selectable";
import {DashboardBuilderDelegate} from "app/dashboard/DashboardBuilderDelegate";
import {ChartWidget} from "app/dashboard/widgets/ChartWidget";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {DashboardQuery, ReferenceQuery} from "metadata/dashboard/DashboardQueries";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ModifyWidgetDelegate} from "app/query/ModifyWidgetDelegate";
import {WidgetComponentFactory} from "app/dashboard/widgets/WidgetComponentFactory";
import {ChartWidgetConfig} from "metadata/dashboard/widgets/config/ChartWidgetConfig";
import {WidgetEditor} from "app/dashboard/widgets/WidgetEditor";
import {QueryMetadataToolbar} from "app/dashboard/widgets/config/QueryMetadataToolbar";
import {QueryMetadataDisplay} from "app/dashboard/widgets/config/QueryMetadataDisplay";
import {WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";
import {ReferencedQueryActor} from "engine/actor/ReferencedQueryActor";

export class ChartWidgetFactory implements WidgetComponentFactory {

    icon(): ReactNode {
        return <AddchartOutlinedIcon/>;
    }

    view(props: DashboardWidgetProps): ReactNode {
        const metadata = props.widgetMetadata as ChartMetadata;
        const selectable: Optional<Selectable> = metadata.queryId.flatMap(
            (queryId: string) => {

                const actor = props.engine.getActor(DashboardBuilderDelegate.queryActorId(queryId));
                if (actor instanceof ReferencedQueryActor) {
                    props.pauseSelectionsMsg.forEach(
                        msg => actor.pauseSelections(msg)
                    ).orForEach(() => actor.resumeSelections());
                    return actor;
                }

                return null;
            }
        );

        return <ChartWidget
            metadata={metadata}
            resultMessages={props.resultMessages}
            queryStatuses={props.queryStatuses}
            selectable={selectable.getOr(null)}
        />;
    }

    get matchWidgetDimensions(): boolean {
        return false;
    }

    inlineEditor(
        widgetMetadata: WidgetMetadataBound,
        mergedWidgetConfig: WidgetConfig,
        queryMetadata: Optional<DashboardQuery>,
        dashboard: ArcDashboard,
        delegate: ModifyWidgetDelegate
    ): ReactNode {
        return queryMetadata
            .flatMap(q => Optional.ofType(q, ReferenceQuery))
            .map(
                q =>
                    <WidgetEditor
                        onConfigChange={
                            (configKey, configJson) => {
                                delegate.modifyWidgetConfig(widgetMetadata.id, new Map([[configKey, configJson]]));
                            }
                        }
                        partsMap={mergedWidgetConfig.parts}
                        sortedParts={mergedWidgetConfig.partsByKeys(
                            [ChartWidgetConfig.CONTAINER_CONFIG_KEY]
                        )}
                        preToolbarEditors={
                            <QueryMetadataToolbar
                                queryMetadata={q}
                                onQueryMetadataChange={
                                    (config: { [key: string]: any }) => {
                                        delegate.modifyQueryConfig(q.id, config);
                                    }
                                }
                            />
                        }
                        preBlockEditors={
                            <QueryMetadataDisplay
                                queryMetadata={q}
                                dashboard={dashboard}
                            />
                        }
                    />
            )
            .getOr(<></>);
    }
}

