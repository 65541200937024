import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import {Enum} from "common/Enum";
import type {SvgIconProps} from "@mui/material/SvgIcon";

export class WidgetContainerAction extends Enum {

    static OPEN = new this(
        'open',
        'Open Query',
        LaunchOutlinedIcon
    );
    static DELETE = new this(
        'delete',
        'Delete',
        DeleteOutlineOutlinedIcon
    );
    static SHARE = new this(
        'share',
        'Share',
        IosShareOutlinedIcon
    );
    static TREND = new this(
        'trend',
        'Trends',
        TrendingUpIcon
    );

    constructor(
        name: string,
        public readonly label: string,
        public readonly IconElementType: React.ElementType<SvgIconProps>
    ) {
        super(name);
    }

}

WidgetContainerAction.finalize();