import {ArcEvent} from "engine/ArcEvent";
import {ArcMessage} from "engine/ArcMessage";
import {Optional} from "common/Optional";

/**
 * Define which events an actor should be notified of.
 */
export interface ArcEventFilter {

    /**
     * Check if the given event matches the conditions of the filter.
     */
    of(event: ArcEvent): boolean;

}

export class TypedEventFilter<T extends ArcMessage> implements ArcEventFilter {

    constructor(
        public readonly clazz: abstract new (...args: any[]) => T
    ) { }

    of(event: ArcEvent): boolean {
        return this.filter(event).map(() => true).getOr(false);
    }

    filter(event: ArcEvent): Optional<T> {
        return Optional.ofType(event.message, this.clazz);
    }

}

export class ActorEventFilter implements ArcEventFilter {

    constructor(
        public readonly actorId: string
    ) { }

    of(event: ArcEvent): boolean {
        return event.actor.id === this.actorId;
    }

}

export class CompositeEventFilter implements ArcEventFilter {

    constructor(
        public readonly filters: ArcEventFilter[]
    ) { }

    of(event: ArcEvent): boolean {
        return this.filters.every(f => f.of(event));
    }

}