import {useAuth0} from "@auth0/auth0-react";
import styled from "@emotion/styled";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {Colors, Durations, FontSizes} from "app/components/StyleVariables";
import {LoginButton} from "app/home/LoginButton";
import {UserSettings} from "app/home/UserSettings";
import {Tab} from "app/Tab";
import {TabPath} from "app/TabPath";
import {UserContext} from "app/UserContext";
import {Optional} from "common/Optional";
import * as React from "react";
import {FunctionComponent, MouseEvent, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import TripOriginIcon from '@mui/icons-material/TripOrigin';

interface Props {
    tabs: Tab[],
    selectedPath: TabPath,
    onAdd: () => void,
    onSelect: (tab: Tab) => void,
    onClose: (tab: Tab) => void
}

export const Chrome: FunctionComponent<Props> = (props: Props) => {

    const {isLoading, isAuthenticated} = useAuth0();
    // the close button we're currently hovered over
    const [hoveredClose, setHoveredClose] = useState<Optional<Tab>>(Optional.none());

    const onClose = (tab: Tab, e: MouseEvent) => {
        props.onClose(tab);
        e.stopPropagation();
    };

    const tabComps = props.tabs.map((tab: Tab) => {
        const isSelected = tab.path.isSameTab(props.selectedPath);
        const className = ['tab'];
        if (isSelected) {
            className.push('selected');
        }

        const label = Optional.of(tab.label)
            .map(l =>
                <Tooltip
                    title={tab.label}
                    enterDelay={Durations.tooltipSecondaryEnterDelay}
                    enterNextDelay={Durations.tooltipSecondaryEnterNextDelay}
                    arrow
                >
                    <S.TabLabel>{l}</S.TabLabel>
                </Tooltip>
            )
            .nullable;
        // center the icon if there is no label
        if (label == null) {
            className.push('center');
        }

        const suffix = Optional.bool(tab.path.type.closeable)
            .map(() => {
                let icon;
                if (hoveredClose.map(t => t === tab).getOr(false) || !tab.hasChanges) {
                    // if hover over the close button of the tab or if the tab doesn't have changes show close
                    icon = <CloseIcon onClick={e => onClose(tab, e)} className="close" />;
                } else {
                    // otherwise indicate there are changes
                    icon = <TripOriginIcon onClick={e => onClose(tab, e)} />;
                }

                const suffix = <S.Suffix
                    className={isSelected ? 'selected' : ''}
                    onMouseOver={() => setHoveredClose(Optional.some(tab))}
                    onMouseOut={() => setHoveredClose(Optional.none())}
                >
                    {icon}
                </S.Suffix>;

                if (tab.hasChanges) {
                    return <Tooltip arrow title="Close with unsaved changes.">
                        {suffix}
                    </Tooltip>;
                } else {
                    return suffix;
                }
            })
            .nullable;

        return <div className={className.join(' ')} key={tab.path.path} onClick={() => props.onSelect(tab)}>
            <S.TabContent>
                {tab.icon}
                {label}
                {suffix}
            </S.TabContent>
        </div>;
    });

    return <S.Chrome>
        <S.Header>
            <S.Branding>
                <img src="/static/images/logo.svg" height="24px" alt="Hyperarc" />
            </S.Branding>
            <S.Controls>{
                isLoading || <UserContext.Consumer>{
                    userContext => userContext.user
                        .map(_ => <UserSettings />)
                        .getOr(isAuthenticated ? <></> : <LoginButton />)
                }</UserContext.Consumer>
            }</S.Controls>
        </S.Header>
        <S.Tabs >
            {tabComps}
            <S.Add>
                <S.TabContent onClick={props.onAdd}>
                    <AddIcon />
                </S.TabContent>
            </S.Add>
            <S.Space />
        </S.Tabs>
    </S.Chrome>;

};

const S = {

    Chrome: styled.div`
        border-bottom: 1px solid ${Colors.borderGrey};
        display: flex;
        flex-direction: column;
    `,

    Header: styled.div`
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid ${Colors.borderGrey};
    `,

    Branding: styled.div`
        display: flex;
        flex: 1;
        align-items: center;
        padding: 13px 0 13px 16px;
    `,

    Tabs: styled.div`
        height: 39px;
        flex: 1;
        background-color: ${Colors.headerGrey};
        display: flex;
        flex-direction: row;
        
        .tab {
            height: 38px;
            min-width: 40px;
            max-width: 200px;
            padding: 0 10px 0 12px;
            text-align: center;
            display: flex;
            align-items: center;
            border-right: 1px solid ${Colors.borderGrey};
            cursor: pointer;
            border-top: 3px solid transparent;
            font-weight: 400;
            color: ${Colors.textTertiary};
            
            svg {
                vertical-align: middle;
                height: 16px;
                font-size: 1rem;
                opacity: 0.4;
            }

            :hover, &.selected {
                background-color: white;
                color: ${Colors.textPrimary};
                border-top: 3px solid ${Colors.hyperarcYellowSecondary};

                .close {
                    opacity: 1;
                }
                
                svg {
                    opacity: 1;
                }
            }
            
            &.center {
                justify-content: center;

                svg {
                    font-size: 1.2rem;
                }
            }
        }
    `,

    Suffix: styled.span`
        padding-left: 8px;

        .close {
            opacity: 0;
        }
        
        &.selected {
            svg {
                color: ${Colors.iconPrimary};
            }
        }

        :hover {
            svg {
                color: ${Colors.hyperarcYellowSecondary};
            }
        }
    `,

    TabContent: styled.div`
        text-align: center;
        font-size:  ${FontSizes.small};
        line-height: ${FontSizes.small};
        margin-bottom: 2px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex: 1;
        height: 100%;
    `,

    TabLabel: styled.span`
        vertical-align: middle;
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 4px 0 4px 6px;
    `,

    Controls: styled.div`
        padding: 0 12px 0 0;
        display: flex;
        align-items: center;
        justify-content: right;
    `,

    Add: styled.div`
        margin-top: 2px;
        width: 40px;
        height: 100%;
        padding: 0 4px;
        text-align: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        svg {
            color: ${Colors.iconSecondary}
        }
        
        :hover {
            svg {
                color: ${Colors.hyperarcYellowSecondary}
            }
        }
    `,

    Space: styled.div`
        flex: 1;
    `

};
