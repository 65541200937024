import {Optional} from "common/Optional";
import {ScopedSearch, ScopedSearchChipProps} from "app/home/ScopedSearch";
import React from "react";
import {S} from "app/home/ScopedSearchS";
import BrowseView from "app/home/browse/BrowseView";
import {FolderResult} from "metadata/project/FolderResult";

export class ScopedSearchAccount implements ScopedSearch {

    constructor(
        private readonly _account: string
    ) {
    }

    get project(): Optional<FolderResult> {
        return Optional.none();
    }

    get account(): Optional<string> {
        return Optional.some(this._account);
    }

    renderChip(props: ScopedSearchChipProps): React.JSX.Element {
        return <S.ScopeChip
            label={this._account}
            onDelete={props.onDelete}
        />;
    }

    searchBarPlaceholderText(): string {
        return "Search for selected owner's projects or assets in them";
    }

    browseView(queryPresent: boolean): BrowseView {
        return BrowseView.ACCOUNT_VIEW;
    }

}