import {Enum} from "common/Enum";

export class GlobalFilterEmbedMode extends Enum {
    static DEFAULT = new this('default', 'Default');
    static HIDDEN = new this('hidden', 'Hidden');
    static STATIC = new this('static', 'Static');

    static sorted(): GlobalFilterEmbedMode[] {
        return GlobalFilterEmbedMode.enums<GlobalFilterEmbedMode>().sort((a, b) => a.label.localeCompare(b.label));
    }

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}

GlobalFilterEmbedMode.finalize();