import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {AnalyticsType} from "metadata/AnalyticsType";
// @ts-ignore
import FusionCharts, {DataTable} from "fusioncharts/core";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ValueFormatter} from "common/ValueFormatter";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import dayjs from "dayjs";
import {JsonObject} from "common/CommonTypes";

// some date strings in different format standards
const FUSION_FORMAT: string = '%Y-%m-%d %H:%M:%S.%L';
const DAY_JS_FORMAT: string = 'YYYY-MM-DD HH:mm:ss.sss';

export abstract class TimelineData {

    protected readonly measureColumns: string[];
    private _dataTable: DataTable;

    constructor(
        protected readonly dateField: string,
        protected readonly response: ArcQLResponse
    ) {
        this.measureColumns = this.response.result.columnsByType(new Set([AnalyticsType.MEASURE]));
    }

    abstract get schema(): {[key: string]: string}[];

    /**
     * Indices for the groupings in addition to the date which will be converted to strings.
     */
    abstract get groupingIndex(): number;

    dataSourceConfig(config: VisualizationConfig): JsonObject {
        const isCombo = config.get('combo').getOr(false);
        const plotType = config.get('plotType').getOr('column');

        const baseConfigs = {
            format: {
                // timeline think's we're in gigabytes
                formatter: (v: any) => ValueFormatter.compactNumber(v['value'])
            },
            title: config.emptyableString('yAxisName').nullable,
            type: this.logType(config)
        };
        const basePlotConfigs = {
            type: plotType,
            connectNullData: config.get('connectMissing').getOr(false)
        };

        const yAxis = (() => {
            if (plotType === 'candlestick') {
                return this.candlestickPlots(baseConfigs, basePlotConfigs);
            } else if (isCombo) {
                return this.dualAxisCartesianPlots(baseConfigs, basePlotConfigs);
            } else {
                return this.cartesianPlots(baseConfigs, basePlotConfigs);
            }
        })();

        return {yAxis};
    }

    /**
     * Build individual plots for each measure.
     */
    private cartesianPlots(baseConfigs: JsonObject, basePlotConfigs: JsonObject): JsonObject[] {
        // create a new series for each measure
        return this.measureColumns.map(c => ({
            ...baseConfigs,
            plot: [
                {
                    ...basePlotConfigs,
                    value: c
                }
            ]
        }));
    }

    /**
     * Build a single plot with up to 2 axes. The first will contain all but the last measure if there are more than 1.
     */
    private dualAxisCartesianPlots(baseConfigs: JsonObject, basePlotConfigs: JsonObject): JsonObject[] {
        const firstAxisColumnEnd = Math.max(1, this.measureColumns.length - 1);
        const plots: JsonObject[] = [{
            ...baseConfigs,
            plot: this.measureColumns.slice(0, firstAxisColumnEnd).map(c => ({
                ...basePlotConfigs,
                value: c
            }))
        }];

        if (this.measureColumns.length > 1) {
            plots.push({
                ...baseConfigs,
                plot: {
                    ...basePlotConfigs,
                    value: this.measureColumns[this.measureColumns.length - 1]
                },
                // use the default title for the second axis to disambiguate
                title: null
            });
        }

        return plots;
    }

    /**
     * Build a candlestick plot using the first 2 or 4 measures for open/close and high/low. A second plot containing
     * will be added for the 5th "volume" measure if present.
     */
    private candlestickPlots(baseConfigs: JsonObject, basePlotConfigs: JsonObject): JsonObject[] {
        const values: JsonObject = {
            open: this.measureColumns[0],
            close: this.measureColumns[1]
        };

        if (this.measureColumns.length > 2) {
            values['high'] = this.measureColumns[2];
            values['low'] = this.measureColumns[3];
        }

        const plots: JsonObject[] = [{
            ...baseConfigs,
            plot: {
                ...basePlotConfigs,
                value: values
            }
        }];

        if (this.measureColumns.length > 4) {
            plots.push({
                ...baseConfigs,
                plot: {
                    ...basePlotConfigs,
                    type: 'column',
                    value: this.measureColumns[4]
                },
                // use the default title for volume chart since it's a different measure
                title: null
            });
        }

        return plots;
    }

    logType(config: VisualizationConfig): string | null {
        return config.get('logAxis').flatMap((logAxis) => logAxis ? 'log' : null).nullable;
    }

    get dataTable(): DataTable {
        if (this._dataTable == null) {
            const dateIndex = this.response.result.columnIndices.get(this.dateField);
            const groupingIndex = this.groupingIndex;
            const measureIndices = this.measureColumns.map(c => this.response.result.columnIndices.get(c));

            this._dataTable = new FusionCharts.DataStore().createDataTable(
                this.response.result.mapRows((row: any[]) => {
                    const dataRow = [];

                    const dateValue = row[dateIndex];

                    // in cases of epoch number or unexpected formatted date string (ex: missing milliseconds),
                    // always convert to dayjs and then reformat
                    dataRow.push(dayjs(dateValue).format(DAY_JS_FORMAT));

                    if (groupingIndex != null) {
                        dataRow.push(`${row[this.groupingIndex]}`);
                    }
                    dataRow.push(...measureIndices.map(i => row[i]));
                    return dataRow;
                }),
                this.schema
            );
        }

        return this._dataTable;
    }

}

/**
 * Timeline data for one or more metrics, each in its own plot.
 */
export class MultiMetricTimelineData extends TimelineData {

    get schema(): {[key: string]: string}[] {
        const schema: {[key: string]: string}[] = [{
            'name': this.dateField,
            'type': 'date',
            'format': FUSION_FORMAT
        }];

        this.measureColumns.forEach(columnName =>
            schema.push({
                'name': columnName,
                'type': 'number'
            })
        );

        return schema;
    }

    get groupingIndex(): number {
        return null;
    }

}

/**
 * Timeline for metrics stacked by the second and last grouping, each in its own plot.
 */
export class StackedTimelineData extends TimelineData {

    private readonly stackedGrouping: ArcQLGrouping;

    constructor(dateField: string, response: ArcQLResponse) {
        super(dateField, response);

        this.stackedGrouping = this.response.arcql.groupings.last;
    }

    get schema(): {[key: string]: string}[] {
        // date and stacks
        const schema = [
            {
                'name': this.dateField,
                'type': 'date',
                'format': FUSION_FORMAT
            }, {
                'name': this.stackedGrouping.field,
                'type': 'string'
            }
        ];
        // all measures
        this.measureColumns.forEach(
            c => schema.push({
                'name': c,
                'type': 'number'
            })
        );

        return schema;
    }

    get groupingIndex(): number {
        return this.response.result.columnIndices.get(this.stackedGrouping.projectedAs);
    }

    dataSourceConfig(config: VisualizationConfig): {[key: string]: any} {
        return Object.assign(
            super.dataSourceConfig(config),
            {
                // series defines the stacks which is the last grouping
                series: this.stackedGrouping.field
            }
        );
    }

}