/**
 * Helper class for validating field aliases.
 */
export class ProjectionValidation {

    // List of illegal characters as a plain string to be used for messaging.
    public static readonly ILLEGAL_CHARS = `! " $ ( ) * , . / ; ? @ [ ] ^ { } \``;

    private static readonly ILLEGAL_CHARS_REGEX = /[!"$()*,.\/;?@[\]^`{}~]/;

    /**
     * Validates the given alias such that it's compatible with the strictest query engine
     * (currently BigQuery).
     */
    public static isValid(as: string): boolean {
        return !ProjectionValidation.ILLEGAL_CHARS_REGEX.test(as);
    }
}