import {Indexable} from "common/Indexable";

export class HyperGraphSearchParams implements Indexable {

    constructor(
        public readonly query: string,
        public readonly size?: number
    ) {}

    static default(query?: string): HyperGraphSearchParams {
        return new HyperGraphSearchParams(query, 5);
    }
    
}