import {InputConnectionDetails} from "metadata/connections/InputConnectionDetails";
import {ConnectionType} from "metadata/connections/ConnectionType";

/**
 * Input connection details for Snowflake.
 */
export class SnowflakeInputConnectionDetails implements InputConnectionDetails {

    public readonly type = ConnectionType.SNOWFLAKE;

    constructor(
        public readonly account: string,
        public readonly username: string,
    ) {
    }

    isValid(): boolean {
        return this.account.length > 0 && this.username.length > 0;
    }
}