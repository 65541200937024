import * as React from "react"
import {SVGProps} from "react"

const FormatTextColorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <g>
            <path
                fillRule="evenodd"
                d="M12.948 3.21A1 1 0 0 0 12 2.632a1 1 0 0 0-.948.576L5.135 15.898a1 1 0 1 0 1.813.845l1.45-3.111h7.203l1.451 3.111a1 1 0 0 0 1.813-.845L12.948 3.209Zm1.72 8.422L12 5.909l-2.669 5.723h5.338Z"
                clipRule="evenodd"
                fill="#000"
            />
            <path d="M2 20h20v4H2v-4z" stroke="black" strokeWidth="1"/>

        </g>
    </svg>
);
export default FormatTextColorIcon
