import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";
import {Tuple} from "common/Tuple";
import {VizEnumProperty} from "app/visualizations/definition/property/VizEnumProperty";

/**
 * @author zuyezheng
 */
export class CandlestickChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON,
        [
            new VizStringProperty('xAxisName', 'X-Axis'),
            new VizStringProperty('pYAxisName', 'Primary Y-Axis'),
            new VizStringProperty('vYAxisName', 'Volume Y-Axis'),
            new VizEnumProperty(
                'volumeSize',
                'Volume Chart Size',
                [
                    Tuple.of('small', 'Small'),
                    Tuple.of('medium', 'Medium'),
                    Tuple.of('large', 'Large')
                ]
            )
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['volumeSize', 'medium']
    ]);

    get properties(): VizProperty<any>[] {
        return CandlestickChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return CandlestickChartDefinition.DEFAULTS;
    }

}