import React, {FunctionComponent, useState} from "react";
import {Button, CircularProgress, Dialog} from "@mui/material";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {DialogS} from "app/DialogS";
import {FileUploadForm} from "app/components/form/FileUploadForm";
import {ServiceProvider} from "services/ServiceProvider";
import {DatasetV2Service} from "services/DatasetV2Service";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {FQN} from "common/FQN";
import styled from "@emotion/styled";
import {FontSizes} from "app/components/StyleVariables";
import {Optional} from "common/Optional";

type Props = {
    datasetFqn: FQN
    label: string
    onCancel: () => void;
    onUpload: (datasetV2: DatasetV2) => void;
};

/**
 * Update / modify a dataset via upload.
 */
export const UpdateUploadDialog: FunctionComponent<Props> = (props: Props) => {
    const [file, setFile] = React.useState<File | null>(null);
    const [uploading, setUploading] = React.useState<boolean>(false);
    const [uploadError, setUploadError] = useState<Optional<string>>(Optional.none);

    const onUpload = () => {
        setUploading(true);
        setUploadError(Optional.none);
        ServiceProvider.get(DatasetV2Service)
            .upload(props.datasetFqn, file, props.label)
            .then(response => {
                setUploading(false);
                response.match(
                    dataset => {
                        props.onUpload(dataset);
                    },
                    error => {
                        const errorMsg = `Upload failed: ${error.prettyPrint()}`;
                        ServiceProvider.get(NotificationsService).publish(
                            'UpdateUploadDialog.onUpload', NotificationSeverity.ERROR, errorMsg
                        );
                        setUploadError(Optional.some(errorMsg));
                    }
                );
            })
            .finally(
                () => setUploading(false)
            );
    };

    const handleClose = () => {
        // do not allow closing of modal while uploading
        if (uploading) {
            ServiceProvider.get(NotificationsService).publish(
                'UpdateUploadDialog.handleClose',
                NotificationSeverity.WARNING,
                'Please wait while upload is in progress.'
            );
            return;
        }
        props.onCancel();
    };

    return <Dialog
        open={true}
        onClose={handleClose}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        <DialogS.Title>Update data for '{props.label}'</DialogS.Title>
        <DialogS.Content dividers>
            <DialogS.Body>
                <FileUploadForm
                    file={file}
                    onFileSelected={setFile}
                    onFileRemoved={() => setFile(null)}
                />
            </DialogS.Body>
            {
                uploadError.map(err =>
                    <S.ErrorText>{err}</S.ErrorText>
                ).nullable
            }
        </DialogS.Content>
        <DialogS.Actions>
            <Button variant="outlined" onClick={props.onCancel} disabled={uploading}>Cancel</Button>
            <Button
                variant="contained"
                disabled={file == null || uploading}
                onClick={onUpload}
            >
                {uploading ? <CircularProgress size={24}/> : 'Upload'}
            </Button>
        </DialogS.Actions>
    </Dialog>;
};

class S {

    static readonly ErrorText = styled.div`
        font-size: ${FontSizes.small};
        color: red;
        margin-top: 10px;
    `;

}