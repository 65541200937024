import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {S as CanvasS} from "app/query/QueryBuilderCanvasS";
import {FieldsPanel} from "app/query/panels/FieldsPanel";
import {GroupingPanel} from "app/query/panels/GroupingPanel";
import {FilterPanel} from "app/query/panels/FilterPanel";
import {DragAndDropData} from "common/DragAndDropData";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Optional} from "common/Optional";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import styled from "@emotion/styled";

type Props = {
    arcqlBundle: ArcQLBundle
    delegate: QueryBuilderDelegate
    drillClauses: Optional<FilterClause[]>
}

/**
 * Content when visualization panel tabbed to 'Query'.
 */
export const VisualizationQueryContent: FunctionComponent<Props> = (props: Props) => {

    // we can't rely on the query state since we need to store intermediate values as the user types
    const [limit, setLimit] = useState<number>(props.arcqlBundle.arcql.limit);

    // keep the limit in sync with the new query results
    useEffect(() => {
        setLimit(props.arcqlBundle.arcql.limit);
    }, [props.arcqlBundle]);

    const onDragStart = (event: React.DragEvent) => {
        DragAndDropData.fromAttributes<QueryDnDSource>(event.currentTarget, 'data-field-id', QueryDnDSource)
            .setData(event);
    };

    const onDragOverFields = (event: React.DragEvent) => {
        event.preventDefault();
    };
    const onDropFields = (event: React.DragEvent) => {
        const dndData = DragAndDropData.fromEvent<QueryDnDSource>(event, QueryDnDSource);

        // add a dataset column as a field
        dndData.isOf(QueryDnDSource.FIELD)
            .flatMap(columnName => props.delegate.addFieldByColumn(columnName))
            .forEach(warning =>
                ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, warning
                )
            );

        // change a grouping to a metric
        dndData.isOf(QueryDnDSource.GROUPING)
            .flatMap(groupingField => props.delegate.convertGroupingToMetric(groupingField))
            .forEach(warning =>
                ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, warning
                )
            );

        // add a filter field as a metric
        dndData.isOf(QueryDnDSource.FILTER)
            .map(filterOrdinal => props.arcqlBundle.arcql.filters.get(parseInt(filterOrdinal)))
            .flatMap(clause => Optional.ofType(clause, LiteralsFilterClause))
            .flatMap(clause => props.delegate.addFieldByColumn(clause.column))
            .forEach(warning =>
                ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, warning
                )
            );
    };

    const onDragOverGroupings = (event: React.DragEvent) => {
        event.preventDefault();
    };
    const onDropGroupings = (event: React.DragEvent) => {
        const dndData = DragAndDropData.fromEvent<QueryDnDSource>(event, QueryDnDSource);

        // add a dataset column as a grouping
        dndData.isOf(QueryDnDSource.FIELD)
            .flatMap(columnName => props.delegate.addGroupingByColumnName(columnName))
            .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                'queryBuilder', NotificationSeverity.WARNING, warning
            ));

        // change a metric to a grouping
        dndData.isOf(QueryDnDSource.METRIC)
            .flatMap(metricAs => props.delegate.convertMetricToGrouping(metricAs))
            .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                'queryBuilder', NotificationSeverity.WARNING, warning
            ));

        // add a filter field as a grouping
        dndData.isOf(QueryDnDSource.FILTER)
            .map(filterOrdinal => props.arcqlBundle.arcql.filters.get(parseInt(filterOrdinal)))
            .flatMap(clause => Optional.ofType(clause, LiteralsFilterClause))
            .flatMap(clause => props.delegate.addGroupingByColumnName(clause.column))
            .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                'queryBuilder', NotificationSeverity.WARNING, warning
            ));
    };

    const onDragOverFilters = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const onDropFilters = (event: React.DragEvent) => {
        const dndData = DragAndDropData.fromEvent<QueryDnDSource>(event, QueryDnDSource);

        // from the fields picker, a column from the dataset
        dndData.isOf(QueryDnDSource.FIELD)
            .forEach(f => props.delegate.startFilter(f));
        // from the metrics panel, should be an aggregate field
        dndData.isOf(QueryDnDSource.METRIC)
            .flatMap(f => props.delegate.startAggregateFilter(f))
            // notify the user of any reasons why the aggregate filter couldn't be started
            .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                'queryBuilder', NotificationSeverity.WARNING, warning
            ));
        // add a grouping as a filter
        dndData.isOf(QueryDnDSource.GROUPING)
            .forEach(f =>
                props.arcqlBundle.dataset.getPossible(f)
                    .forEach(f => props.delegate.startFilter(f.name))
                    .orForEach(() => ServiceProvider.get(NotificationsService).publish(
                        'queryBuilder', NotificationSeverity.WARNING, 'Can\'t filter by an expression grouping.'
                    ))
            );
    };

    const onDeleteFilter = (ordinal: number, isAggregate: boolean) => {
        props.delegate.deleteFilter(ordinal, isAggregate);
    };


    const onChangeLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let newLimit = parseInt(event.target.value);
        if (isNaN(newLimit)) {
            // use the default limit from the server
            newLimit = null;
        }

        setLimit(newLimit);
    };

    const onBlurLimit = () => {
        props.delegate.changeLimit(limit);
    };

    return <S.Content>
        <FieldsPanel
            arcqlBundle={props.arcqlBundle}
            delegate={props.delegate}
            onDragStart={onDragStart}
            onDragOver={onDragOverFields}
            onDrop={onDropFields}
        />
        <CanvasS.Divider/>
        <GroupingPanel
            arcqlBundle={props.arcqlBundle}
            delegate={props.delegate}
            onDragOver={onDragOverGroupings}
            onDrop={onDropGroupings}
            onDragStart={onDragStart}
        />
        <CanvasS.Divider/>
        <FilterPanel
            arcqlBundle={props.arcqlBundle}
            delegate={props.delegate}
            drillClauses={props.drillClauses}
            onDragStart={onDragStart}
            onDragOver={onDragOverFilters}
            onDrop={onDropFilters}
            onDeleteFilter={onDeleteFilter}
        />
        <CanvasS.Divider/>
        <CanvasS.Shelf>
            <CanvasS.ShelfHeader>Limit</CanvasS.ShelfHeader>
            <CanvasS.ShelfContent>
                <CanvasS.LimitField
                    value={limit == null ? '' : limit}
                    variant="standard"
                    size="small"
                    onChange={(e) => onChangeLimit(e)}
                    onBlur={onBlurLimit}
                />
            </CanvasS.ShelfContent>
        </CanvasS.Shelf>
    </S.Content>;
};

class S {

    static readonly Content = styled.div`
        display: flex;
        flex-direction: column;
    `;

}