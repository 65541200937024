import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcQLSourceRefType, SingleSource} from "metadata/query/ArcQLSource";
import {DateFilterHelper} from "app/query/filters/DateFilterHelper";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {Either} from "common/Either";
import {LocalStorageService} from "services/LocalStorageService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ErrorResponse} from "services/ApiResponse";
import {DatasetV2Service} from "services/DatasetV2Service";

/**
 * Helps load and parse metadata for the query builder.
 *
 * @author zuyezheng
 */
export class QueryLoader {

    /**
     * Figure out how to load or create a query for a given fqn.
     */
    static async load(
        assetFqn: FQN,
        context: Map<string, any>,
        signal?: AbortSignal
    ): Promise<Optional<LoadResult>> {
        if (context.has('datasetFqn')) {
            // if the context has a dataset path, we know it's a new arcql query on the given dataset
            return QueryLoader.buildNew(context.get('datasetFqn'), signal);
        } else if (context.has('arcql')) {
            // if an arcql is given in the context, start a new query with it
            return QueryLoader.buildNewWithQuery(context.get('arcql'), signal);
        } else {
            // otherwise, it would be a saved query so check if we have something stored locally
            return QueryLoader.loadExisting(
                assetFqn,
                context.get('skipLocalStorage') === true,
                signal
            );
        }
    }

    static async buildNew(datasetFqn: FQN, signal?: AbortSignal): Promise<Optional<LoadResult>> {
        const dataset = await ServiceProvider.get(DatasetV2Service)
            .describeDataset(datasetFqn, signal)
            .then(e => e.rightOrThrow());
        // create an empty arcql of the provided dataset
        let arcql = ArcQL.minimal(new SingleSource(ArcQLSourceRefType.DATASET, dataset.fqn));
        // if the dataset has a primary date field, add a default date filter
        arcql = DateFilterHelper.addDefault(arcql, dataset);

        return Optional.some(new LoadResult(arcql, dataset, false));
    }

    static async buildNewWithQuery(arcqlJson: Map<string, any>, signal?: AbortSignal): Promise<Optional<LoadResult>> {
        const arcql = ArcQL.fromJSON(arcqlJson);

        const dataset = await ServiceProvider.get(DatasetV2Service)
            .describeDataset((arcql.source as SingleSource).fqn, signal)
            .then(e => e.rightOrThrow());

        return Optional.some(new LoadResult(arcql, dataset, false));
    }

    static async loadExisting(assetFqn: FQN, skipLocalStorage: boolean, signal?: AbortSignal): Promise<Optional<LoadResult>> {
        // see if we have a local version to restore from
        const localStorageService = ServiceProvider.get(LocalStorageService);
        const possibleLocal = await localStorageService.getAsset(assetFqn, ArcQL.fromJSON);

        let arcql;
        let isRestored;
        if (possibleLocal.isPresent && !skipLocalStorage) {
            // we have a local version of the asset, try to use it unless there is external state which we can assume
            // is in a "view" persona and should always load the most recent query from the server
            arcql = possibleLocal.get();
            isRestored = true;

            // do some validation
            if (arcql.isExisting) {
                // restored a query that has been saved, still fetch from the server so we can inform the user if
                // there's any new version
                const arcqlResponse = await ServiceProvider.get(MetadataService).fetchArcQL(assetFqn, signal);

                // if it doesn't exist on the server, something sketch, clear the local copy
                if (arcqlResponse.isLeft) {
                    localStorageService.clearAsset(assetFqn);
                    ServiceProvider.get(NotificationsService)
                        .publish('queryBuilder', NotificationSeverity.ERROR, 'Restored query not found.');

                    return Optional.none();
                }

                // see if the most recent server version is different to warn the user
                if (arcql.version >= arcqlResponse.rightOrThrow().version) {
                    ServiceProvider.get(NotificationsService)
                        .publish('queryBuilder', NotificationSeverity.SUCCESS, 'Restored query.');
                } else {
                    ServiceProvider.get(NotificationsService)
                        .publish(
                            'queryBuilder',
                            NotificationSeverity.WARNING,
                            'Restored query, but a newer version found, reload to use the newer version.'
                        );
                }
            } else {
                ServiceProvider.get(NotificationsService)
                    .publish('queryBuilder', NotificationSeverity.SUCCESS, 'Restored unsaved query.');
            }
        } else {
            // clear the local storage just in case since we're going to be loading a fresh one
            localStorageService.clearAsset(assetFqn);

            // no local version, load from server
            const arcqlResponse = await ServiceProvider.get(MetadataService).fetchArcQL(assetFqn, signal);

            // should be a saved query, but was either not there or not accessible
            if (arcqlResponse.isLeft) {
                ServiceProvider.get(NotificationsService)
                    .publish('queryBuilder', NotificationSeverity.ERROR, 'Saved query not found.');
                return Optional.none();
            }

            arcql = arcqlResponse.rightOrThrow();
            isRestored = false;
        }

        // extract the dataset from the saved metadata to load it
        const dataset = await ServiceProvider.get(DatasetV2Service)
            .describeDataset((arcql.source as SingleSource).fqn, signal, arcql.fullyQualifiedName)
            .then(e => e.rightOrThrow());

        return Optional.some(new LoadResult(arcql, dataset, isRestored));
    }

    static async refresh(query: ArcQL): Promise<Either<ErrorResponse, ArcQL>> {
        const response = await ServiceProvider.get(MetadataService).fetchArcQL(query.fqn);
        return response.match(
            (query) => {
                ServiceProvider.get(LocalStorageService).clearAsset(query.fqn);
                return query;
            },
            (error) => {
                ServiceProvider.get(NotificationsService)
                    .publish('queryBuilder', NotificationSeverity.ERROR, 'Refresh error.');
                return error;
            }
        );
    }

}

export class LoadResult {

    constructor(
        public readonly query: ArcQL,
        public readonly dataset: ArcDataset,
        // if load was restored from local storage
        public readonly isRestored: boolean
    ) {}

}