import {Enum} from "common/Enum";
import {OrderedMap} from "common/OrderedMap";
import {Optional} from "common/Optional";
import {JsonObject} from "common/CommonTypes";
import { Tuple } from "common/Tuple";
import {ArcQLError} from "metadata/query/ArcQLError";


export class ArcQLOrderBys {

    static fromJSON(jsons: JsonObject[]): ArcQLOrderBys {
        return new ArcQLOrderBys(jsons.map(ArcQLOrderBy.fromJSON));
    }

    static empty(): ArcQLOrderBys {
        return new ArcQLOrderBys([]);
    }

    private readonly _orderBys: OrderedMap<string, ArcQLOrderBy>;

    constructor(orderBys: ArcQLOrderBy[]) {
        this._orderBys = OrderedMap.fromKeyed(orderBys, v => v.field);
    }

    get size(): number {
        return this._orderBys.size;
    }

    get(field: string): Optional<ArcQLOrderBy> {
        return this._orderBys.get(field);
    }

    find(field: string): Optional<Tuple<ArcQLOrderBy, number>> {
        return this._orderBys.find(field);
    }

    all(): ArcQLOrderBy[] {
        return this._orderBys.values;
    }

    map<T>(f: (field: ArcQLOrderBy) => T): T[] {
        return this._orderBys.values.map(f);
    }

    /**
     * Place a new sort at the given ordinal value.
     */
    with(orderBy: ArcQLOrderBy, ordinal?: number): ArcQLOrderBys {
        if (ordinal < 0) {
            throw new ArcQLError('Can\'t add a grouping before ordinal 0.');
        }

        // if no ordinal or greater than the length, add it to the end
        if (ordinal == null || ordinal >= this._orderBys.size) {
            return new ArcQLOrderBys([...this._orderBys.values, orderBy]);
        }

        return new ArcQLOrderBys(
            this._orderBys.values.flatMap(
                (o: ArcQLOrderBy, oOrdinal: number) => ordinal === oOrdinal ? [orderBy, o] : [o]
            )
        );
    }

    /**
     * Add a sort by the given field to the end of the sort conditions.
     */
    withField(field: string, direction: Direction) {
        return new ArcQLOrderBys([
            ...this._orderBys.values,
            new ArcQLOrderBy(field, direction)
        ]);
    }

    without(field: string): ArcQLOrderBys {
        return new ArcQLOrderBys(
            this._orderBys.values.filter(o => o.field !== field)
        );
    }

    /**
     * Replace a given order by at the given ordinal.
     */
    replace(ordinal: number, orderBy: ArcQLOrderBy): ArcQLOrderBys {
        return new ArcQLOrderBys(
            this._orderBys.values.map(
                (o: ArcQLOrderBy, oOrdinal: number) => oOrdinal === ordinal ? orderBy : o
            )
        );
    }

    toJSON(): Object {
        return this._orderBys.values;
    }

}

export class ArcQLOrderBy {

    public static fromJSON(json: JsonObject): ArcQLOrderBy {
        return new ArcQLOrderBy(json['field'], Direction.get(json['direction']));
    }

    constructor(
        public readonly field: string,
        public readonly direction: Direction
    ) {}

    with(field: string): ArcQLOrderBy {
        return new ArcQLOrderBy(field, this.direction);
    }

    toJSON(): Object {
        return {
            'field': this.field,
            'direction': this.direction
        };
    }

}

export class Direction extends Enum {

    static ASC = new Direction('asc', 'ascending');
    static DESC = new Direction('desc', 'descending');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
Direction.finalize();
