import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import {FQN} from "common/FQN";
import {QueryPath, SubPathKey} from "app/query/QueryPath";

/**
 * A search result for an AI summary node that can navigate to the node
 */
export class AiSummaryNodeSearchResult implements NavigableSearchResult {
    constructor(
        public readonly queryFqn: FQN,
        public readonly nodeId: string
    ) {
    }

    get id(): string {
        return this.nodeId;
    }

    get path(): string {
        return QueryPath.fromBase(this.queryFqn)
            .with(SubPathKey.SELECTED_NODES, [this.nodeId])
            .toString();
    }

}