import * as React from "react";
import {FunctionComponent, MouseEvent, useRef, useState} from "react";
import styled from "@emotion/styled";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import Tooltip from "@mui/material/Tooltip";

import {ActorStatus} from "engine/actor/ActorStatus";
import {ArcEngine} from "engine/ArcEngine";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {WidgetContainerAction} from "app/dashboard/widgets/WidgetContainerAction";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {ResultMessage} from "engine/ResultMessage";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";
import {Optional} from "common/Optional";
import {WidgetTypeExtended} from "app/dashboard/WidgetTypeExtended";
import {css} from "@emotion/react";
import {DashboardFilterActor} from "app/dashboard/DashboardFilterActor";
import {BorderVisibility} from "metadata/dashboard/widgets/config/BorderVisibility";
import {DropShadow} from "metadata/dashboard/widgets/config/DropShadow";
import {Border} from "metadata/dashboard/widgets/config/Border";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {StringUtils} from "common/StringUtils";
import {WidgetTrendsMap} from "metadata/trend/WidgetTrendsMap";

export type OnWidgetActionCallback = (action: WidgetContainerAction, widgetMetadata: WidgetMetadataBound, buttonEl: Element, canvasEl: Element) => void;

export interface DashboardWidgetProps {
    isViewMode: boolean
    // stuff the widget implementation needs
    widgetMetadata: WidgetMetadataBound
    dashboardConfig: DashboardConfig
    resultMessages: Map<string, ResultMessage>
    queryStatuses: Map<string, ActorStatus>
    engine: ArcEngine
    filterActor: DashboardFilterActor

    onClickAttachQuery: (widgetMetadata: WidgetMetadataBound) => void
    onClickMask?: (widgetMetadata: WidgetMetadataBound, event: React.MouseEvent) => void

    showActions: (isOver: boolean) => boolean
    buildActions: () => WidgetContainerAction[]
    onAction: OnWidgetActionCallback

    // highlight widget that it contains trends
    highlightAsTrendWidget: boolean
    // highlight widget whose trends are currently being viewed
    highlightViewingTrend: boolean
    // Helper map needed for style variance in trend highlights
    trendsMap: Optional<WidgetTrendsMap>
    // Message to notify widget to pause selections
    pauseSelectionsMsg: Optional<string>
}

/**
 * Container for a widget on a dashboard
 *
 * @author zuyezheng
 */
export const WidgetContainer: FunctionComponent<DashboardWidgetProps> = (props: DashboardWidgetProps) => {

    const canvasEl = useRef<HTMLDivElement>(null);
    const [isOver, setIsOver] = useState<boolean>(false);

    const widgetTypeExtended: WidgetTypeExtended = WidgetTypeExtended.get(
        props.widgetMetadata.type.name
    );

    const onClickAttach = (e: MouseEvent) => {
        props.onClickAttachQuery(props.widgetMetadata);
        e.stopPropagation();
    };

    const showAttach = Optional.ofType(props.widgetMetadata, QueriedWidgetMetadata).map(w => !w.isAttached).getOr(false);
    const showActions = props.showActions(isOver);
    const actions = showActions ? props.buildActions() : [];

    const mergedWidgetConfig = props.widgetMetadata.mergeWidgetConfig(props.dashboardConfig);

    // viewing trend takes precedence over highlight trend widget
    const showHighlightTrendWidget = props.highlightAsTrendWidget && !props.highlightViewingTrend;
    const classNames = {
        'selected': showActions,
        'highlightTrendWidget': showHighlightTrendWidget,
        'viewingTrend': props.highlightViewingTrend
    };

    return <S.Container
        className={StringUtils.toClassName(classNames)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        isViewMode={props.isViewMode}
        isLink={widgetTypeExtended === WidgetTypeExtended.LINK}
        {...mergedWidgetConfig.container.toProps()}
    >
        {showHighlightTrendWidget &&
            <S.TrendHighlightHeader>
                {
                    props.trendsMap.map(
                        m => m.hasAlertsForWidget(props.widgetMetadata.id)
                    ).getOr(false) ?
                        'Trended with Alerts' :
                        'Trended without Alerts'
                }
            </S.TrendHighlightHeader>
        }
        {props.highlightViewingTrend &&
            <S.ViewingTrendHeader>
                <S.TrendingUpIcon/>
                <span> Viewing Trend </span>
            </S.ViewingTrendHeader>
        }
        {
            // if in edit mode show a mask to prevent interactions with the charts
            !props.isViewMode && <S.Mask
                onClick={(e) => props.onClickMask && props.onClickMask(props.widgetMetadata, e)}
            >
                {showAttach && <S.PlaceholderContainer>
                    <S.AttachQuery
                        onMouseDown={e => e.stopPropagation()}
                        onClick={onClickAttach}
                    >
                        <QueryStatsOutlinedIcon/>
                        Attach a Query
                    </S.AttachQuery>
                </S.PlaceholderContainer>}
            </S.Mask>
        }
        {
            (actions.length > 0) && <S.Controls isViewMode={props.isViewMode}>{
                actions.map(action =>
                    <Tooltip key={action.name} title={action.label} arrow>
                        <action.IconElementType
                            onMouseDown={e => e.stopPropagation()}
                            onClick={(e) => props.onAction(
                                action, props.widgetMetadata, e.currentTarget, canvasEl.current
                            )}
                        />
                    </Tooltip>
                )
            }
            </S.Controls>
        }
        <S.Visualization ref={canvasEl}>
            {showAttach || widgetTypeExtended.widgetComponentFactory.view(props)}
        </S.Visualization>
    </S.Container>;
};

class S {

    static readonly Container = styled.div<{
        backgroundColor: string
        borderWidth: number,
        borderRadius: number,
        borderColor: string,
        dropShadow: DropShadow,
        borderVisibility: Set<Border>,
        isViewMode: boolean
        isLink: boolean
    }>`
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: ${(props) => props.backgroundColor};
        border-radius: ${(props) => `${props.borderRadius}px`};
        ${props => css(props.dropShadow.styling)}
        ${props => BorderVisibility.fromSet(props.borderVisibility).styling(`${props.borderWidth}px solid ${props.borderColor}`)}
        // ensures that the border does not increase size but rather takes up space inside the container
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &.selected {
            outline: 1px solid ${Colors.lightBlue};
            ${props => props.isViewMode && css`
                outline-color: transparent;
            `}
        }

        &.viewingTrend {
            outline: 1px solid ${Colors.hyperarcYellow};
        }

        &.highlightTrendWidget {
            outline: 1px solid ${Colors.changePositive};
        }

        ${props => props.isViewMode && props.isLink && css`
            &:hover {
                cursor: pointer;
            }
        `}
    `;

    static readonly TrendHighlightHeader = styled.div`
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: ${Colors.changePositive};
        font-size: ${FontSizes.xSmall};
        color: white;
        font-weight: bold;
        padding: 6px 4px;
        box-sizing: border-box;
        z-index: 10;
    `;

    static readonly ViewingTrendHeader = styled.div`
        position: absolute;
        display: flex;
        align-items: center;
        top: 0px;
        left: 0;
        width: 100%;
        background-color: ${Colors.hyperarcYellow};
        font-size: ${FontSizes.xSmall};
        font-weight: bold;
        text-transform: uppercase;
        padding: 6px 2px;
        box-sizing: border-box;
        gap: 5px;
        // make sure it's overlaid on anything else
        z-index: 10;
    `;

    static readonly TrendingUpIcon = styled(TrendingUpIcon)`
        margin-left: 10px;
        width: 16px;
        height: 16px;
        color: ${Colors.iconPrimary};
    `;

    static readonly Mask = styled.div`
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
    `;

    static readonly Visualization = styled.div`
        overflow: hidden;
        height: 100%;
        width: 100%;
        padding: 4px 8px;
        background-color: inherit;
        box-sizing: border-box;
    `;

    static readonly Controls = styled.div<{
        isViewMode: boolean
    }>`
        position: absolute;
        top: 0;
        right: 20px;
        background-color: ${Colors.lightBlue};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 4px;
        z-index: 1100;

        svg {
            color: white;
            height: 18px;
            width: 18px;
            padding: 4px;
            cursor: pointer;
        }

        ${props => props.isViewMode && css`
            background-color: #0000005F;
            top: 10px;
            right: 10px;
            padding: 2px 6px;
            border-radius: 100px;

            svg {
                height: 16px;
                width: 16px;
            }
        `}
    `;

    static readonly PlaceholderContainer = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    static readonly AttachQuery = styled.button`
        height: 30px;
        border: 1px solid ${Colors.borderGrey};
        border-radius: 4px;
        background-color: white;
        padding: 0 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        color: ${Colors.textSecondary};

        svg {
            height: 18px;
            width: 18px;
            color: ${Colors.textSecondary};
            padding-right: 8px
        }
    `;
}