import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {VizSelection} from "engine/actor/VizSelection";
import {Category, Dataset} from "app/visualizations/FusionTypes";
import {CartesianData, SeriesConfigFactory} from "app/visualizations/data/CartesianData";

/**
 * Data for cartesian charts (bars/lines) for one or more metric series.
 *
 * @author zuyezheng
 */
export class MultiSeriesCartesianData implements CartesianData {

    public readonly categories: Category[];

    constructor(
        private readonly response: ArcQLResponse
    ) {
        this.categories = MultiSeriesCartesianData.categories(response);
    }

    get plotToolText(): string {
        if (this.response.arcql.isGrouped() && this.response.arcql.groupings.isAll) {
            // group by all
            return '$seriesName: <b>$dataValue</b>';
        } else {
            return '<b>$label</b><br>$seriesName: <b>$dataValue</b>';
        }
    }

    /**
     * Return the dataset with the given selections.
     */
    dataset(selections: VizSelection, seriesConfig?: SeriesConfigFactory): Dataset {
        const plotAlpha = selections.isEmpty() ? '80' : '30';
        const result = this.response.result;

        if (seriesConfig == null) {
            seriesConfig = () => ({});
        }

        // can only plot numeric values
        return result.columnsByType(new Set([AnalyticsType.MEASURE])).map(
            (column: string, seriesIndex: number, allColumns: string[]) => {
                const columnIndex = result.columnIndices.get(column);
                return Object.assign(
                    {
                        seriesName: column,
                        data: result.mapRows((row: any[], index: string, formatters: ResultValueFormatter[]) => {
                            const categoryValues = result.categoryColumns.map(c => row[c.right]);
                            return {
                                value: row[columnIndex],
                                categoryValues: categoryValues,
                                alpha: selections.has(categoryValues) ? '80' : plotAlpha
                            };
                        })
                    },
                    seriesConfig(column, seriesIndex, seriesIndex + 1 === allColumns.length)
                );
            }
        );
    }

    private static categories(response: ArcQLResponse): Category[] {
        const isGrouped = response.arcql.isGrouped();
        const categoryColumnIndices = response.result.categoryColumns.map(c => c.right);
        const categoryLabels = response.result.mapRows(
            (row: any[], index: string) =>
                response.result.rowLabel(row, categoryColumnIndices, isGrouped ? null : index)
        );

        return [{
            category: categoryLabels.map(l => ({'label': l}))
        }];
    }

}