import {Either, Left, Right} from "common/Either";
import {FQN} from "common/FQN";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import { ArcDataset } from "metadata/dataset/ArcDataset";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {DatasetV2Service} from "services/DatasetV2Service";
import {ErrorResponse} from "./ApiResponse";
import {Asset} from "metadata/Asset";
import {Optional} from "common/Optional";

/**
 * Mocked implementation of dataset v2 service.
 */
export class MockedDatasetV2Service implements DatasetV2Service {

    private readonly mockedAssets: Map<string, Asset>;

    constructor() {
        this.mockedAssets = new Map();
    }


    mockAsset(fqn: FQN, asset: Asset) {
        this.mockedAssets.set(fqn.toString(), asset);
    }

    describeDataset(fqn: FQN, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcDataset>> {
        return Promise.resolve(
            Optional.of(this.mockedAssets.get(fqn.toString()))
                .map<Either<ErrorResponse, ArcDataset>>(dataset => new Right(dataset as ArcDataset))
                .getOr(new Left(new ErrorResponse({}, null)))
        );
    }

    fetch(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>> {
        return Promise.resolve(
            Optional.of(this.mockedAssets.get(fqn.toString()))
                .map<Either<ErrorResponse, DatasetV2>>(dataset => new Right(dataset as DatasetV2))
                .getOr(new Left(new ErrorResponse({}, null)))
        );
    }

    save(fqn: FQN, dataset: DatasetV2, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>> {
        return Promise.resolve(undefined);
    }

    getTableMetadata(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, RemoteTableMetadata>> {
        return Promise.resolve(undefined);
    }

    upload(fqn: FQN, file: File, datasetLabel: string, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>> {
        return Promise.resolve(undefined);
    }
}