import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ToMeasureFieldsChange} from "metadata/query/changes/ToMeasureFieldsChange";
import {DetailField, DetailOperator} from "metadata/query/DetailField";
import {MeasureField} from "metadata/query/MeasureField";

/**
 * Convert the given fields to detail fields.
 *
 * @author zuyezheng
 */
export class ToDetailFieldsChange implements ArcMetadataChange<ArcQL> {

    constructor(
        // projected names of fields with a nullable operator to use to convert them to measures
        public readonly fields: [string, DetailOperator | null][]
    ) {}

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const fields = new Map(this.fields);

        return new Tuple(
            metadata.with({
                'fields': metadata.fields.withMapped(field => {
                    // only measure fields can be converted
                    if (!(field instanceof MeasureField)) {
                        throw new Error(`Expected ${field.as} to be a measure to convert to a detail.`);
                    }

                    const measureField = field as MeasureField;
                    if (fields.has(measureField.as)) {
                        // convert to a detail field
                        return new DetailField(measureField.field, fields.get(measureField.as), measureField.as);
                    } else {
                        // no change
                        return field;
                    }
                })
            }),
            new ToMeasureFieldsChange(
                Array.from(fields.keys()).map(as =>
                    // get the old metadata operators for undo
                    [as, (metadata.fields.get(as) as MeasureField).operator]
                )
            )
        );
    }

    describe(): string {
        return `Changed grouping to details query with ${this.fields.length} fields.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}