import {Either, Left, Right} from "common/Either";

export class FilterSetTextHelper {

    /** Converts raw text to string array by splitting with ',' */
    static textToValues(text: string): string[] {
        return text.split(',').map((str) => str.trim()).filter((str) => str.length > 0);
    }

    /** Converts string array to pretty formatted text.
     *  Note: doing values.join(', ') causes weird wrapping
     */
    static valuesToText(values: string[], numValuesPerLine = 2): string {
        let result = '';
        for (let i = 0; i < values.length; i++) {
            result += values[i];
            if ((i + 1) % numValuesPerLine === 0 && i !== values.length - 1) {
                result += ',\n';
            } else if (i !== values.length - 1) {
                result += ', ';
            }
        }
        return result;
    }

    /**
     * Validates the list of values such as valid hexes if hexCheck is set to true.
     * Additionally, will process such as deduping and lowercasing hex values as well.
     */
    static validateAndProcessValues(values: string[], hexCheck: boolean): Either<ValidationError[], ValidationSuccess> {
        const errors: ValidationError[] = [];
        const processedValues = hexCheck ? values.map(v => v.toLowerCase()) : values;
        const dedupedValues = [...new Set(processedValues)];
        const numberOfDupes = processedValues.length - dedupedValues.length;

        // Size checks
        if (dedupedValues.length === 0) {
            errors.push({
                alertTitle: "No Values",
                alertMessage: "Your entry must contain at least one valid value."
            });
            return new Left(errors);
        }

        if (dedupedValues.length > 10000) {
            errors.push({
                alertTitle: "Limit Exceeded",
                alertMessage: "Please enter a maximum of 10k values per filter set."
            });
        }

        // hex check
        const invalidValuesCount = dedupedValues.filter(
            val => hexCheck && !/^0x([a-f0-9]+)$/.test(val)
        ).length;

        if (invalidValuesCount > 0) {
            errors.push({
                alertTitle: "Mismatched Values",
                alertMessage: `Your entry contains ${invalidValuesCount} mismatched values for hexes.`
            });
        }

        if (errors.length > 0) {
            return new Left(errors);
        }

        const success: ValidationSuccess = {
            validatedValues: dedupedValues,
            numberOfDupes: numberOfDupes
        };
        return new Right(success);
    }
}

export type ValidationError = {
    alertTitle: string;
    alertMessage: string;
}

export type ValidationSuccess = {
    validatedValues: string[],
    numberOfDupes: number;
}