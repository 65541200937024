import {DatasetType} from "metadata/dataset/DatasetType";

import {ColumnMetadata} from "metadata/dataset/ColumnMetadata";

/**
 * Any Snowflake specific column metadata
 */
export class SnowflakeColumnMetadata implements ColumnMetadata {

    public readonly type: DatasetType = DatasetType.SNOWFLAKE;
}