import styled from "@emotion/styled";
import {Input, Select} from "@mui/material";
import {Colors, FontSizes} from "app/components/StyleVariables";
import MenuItem from "@mui/material/MenuItem";

export class DashboardConfigS {

    static Container = styled.div`
        display: flex;
        flex-direction: column;
        overflow: auto;
    `;

    static ColorPreview = styled.div<{ color: string }>`
        width: 16px;
        height: 16px;
        background-color: ${({color}) => color !== 'transparent' ? color : 'none'};
        background-image: ${({color}) => color === 'transparent' ?
            `linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), 
             linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc)` : 'none'};
        background-position: ${({color}) => color === 'transparent' ? '0 0, 5px 5px' : 'none'};
        background-size: ${({color}) => color === 'transparent' ? '10px 10px' : 'none'};
        border: none;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
        margin: 4px 0 2px;
    `;

    static Input = styled(Input)`
        .MuiInput-input {
            padding-top: 5px;
            font-size: ${FontSizes.small};
            color: ${Colors.textPrimary};
        }

        .MuiInputAdornment-root {
            svg {
                width: 16px;
                height: 16px;
            }
        }
    `;

    static Select = styled(Select)`
        .MuiInput-input {
            padding-top: 5px;
            font-size: ${FontSizes.small};
            color: ${Colors.textPrimary};
        }
        
        .MuiInputAdornment-root {
            svg {
                height: 16px;
                width: 16px;
                color: ${Colors.iconPrimary};
            }
        }
    `;

    static MenuItem = styled(MenuItem)`
        font-size: ${FontSizes.small};
        
        svg {
            height: 16px;
            width: 16px;
        }
    `;
}
