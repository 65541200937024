import {Enum} from "common/Enum";
import { Optional } from "common/Optional";


/**
 * Representation of a relative date.
 *
 * @author zuyezheng
 */
export class RelativeDate {

    private static readonly PATTERN = /^\s*(\d*) (day|week|month|year)[s]* ago\s*$/;

    public static isA(v: Object): boolean {
        return typeof v === 'string' && RelativeDate.PATTERN.test(v);
    }

    public static parse(v: string): Optional<RelativeDate> {
        const matched = RelativeDate.PATTERN.exec(v);

        if (matched == null) {
            return Optional.none();
        }

        const duration = parseInt(matched[1]);
        // can't do negative durations, will also cover NaN
        if (duration <= 0) {
            return Optional.none();
        }

        return Optional.some(
            new RelativeDate(duration, RelativeDateUnit.get(matched[2]))
        );
    }

    constructor(
        public readonly duration: number,
        public readonly unit: RelativeDateUnit
    ) { }


    withDuration(duration: number): RelativeDate {
        return new RelativeDate(duration, this.unit);
    }

    withUnit(unit: RelativeDateUnit): RelativeDate {
        return new RelativeDate(this.duration, unit);
    }

    toString(): string {
        return `${this.duration} ${this.unit.label(this.duration)} ago`;
    }

}

/**
 * Unit for relative date.
 */
export class RelativeDateUnit extends Enum {

    static DAY = new this('day');

    static WEEK = new this('week');

    static MONTH = new this('month');

    static YEAR = new this('year');

    label(duration: number): string {
        return duration > 1 ? this.name + 's' : this.name;
    }

}
RelativeDateUnit.finalize();
