import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VizSelection} from "engine/actor/VizSelection";
import {Category, Dataset} from "app/visualizations/FusionTypes";
import {CartesianData, SeriesConfigFactory} from "app/visualizations/data/CartesianData";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {Tuple} from "common/Tuple";
import {AnalyticsType} from "metadata/AnalyticsType";


/**
 * Cartesian charts (bars/lines) for a single metric with one or more groupings that are hierarchical and optionally
 * stacked.
 *
 * Not that stacked and hierarchical are used interchangeably.
 *
 * @author zuyezheng
 */
export class GroupedCartesianData implements CartesianData {

    public readonly categories: Category[];
    private readonly measure: string;

    // categories of collapsed grouping labels to their values, the first N -1 groupings are collapsed into 1 category
    private readonly collapsedCategories: Map<string, string[]>;

    // the last grouping used for each segment of the stacked row
    private readonly stackedGrouping: string;
    private readonly stackedDimensionFormatter: ResultValueFormatter;
    // each stacked dimension will produce it's own "dataset", map the stacked dimension label to a tuple of the
    // unformatted value and index of the dataset
    private readonly stackedDimensionLabels: Map<string, Tuple<string | number, number>>;

    constructor(
        protected readonly response: ArcQLResponse,
        // if we should show the total of the hierarchical values, this is only available in some charts
        private readonly showTotal: boolean
    ) {
        // we can only create stacks with 2 groups, the first grouping used for each "row" and the second grouping used
        // for each segment of the stacked row. If we have more than 2 groups, we need to collapse the first N -1 groups
        // into 1 group.
        const result = this.response.result;

        this.measure = this.response.result.columnsByType(new Set([AnalyticsType.MEASURE]))[0];

        const collapsedGroupIndices = result.indexColumns.slice(0, -1).map(c => c.right);
        this.collapsedCategories = new Map(
            result.rows.map(row => [
                // generate a unique label which will also be used to dedupe since we will have multiple rows with the
                // same grouping label
                result.rowLabel(row, collapsedGroupIndices),
                // build a list of the individual grouping values
                collapsedGroupIndices.map(i => row[i])
            ])
        );

        // we need to make sure there's an equal number of stacks for every bar so that the dataset aligns, we also
        // can't assume the query results are sorted or contain a stacked dimension for every "grouping" so need to
        // iterate through all stacked dimensions for every grouping
        this.stackedGrouping = this.response.arcql.groupings.last.projectedAs;
        this.stackedDimensionFormatter = result.columnFormatters[result.columnIndices.get(this.stackedGrouping)];
        this.stackedDimensionLabels = new Map(
            Array.from(result.unique(this.stackedGrouping))
                .map(
                    (v: string | number, i: number) => [this.stackedDimensionFormatter(v), Tuple.of(v, i)]
                )
        );

        this.categories = [{
            category: Array.from(this.collapsedCategories.keys()).map(l => ({'label': l}))
        }];
    }

    get plotToolText(): string {
        const groupingLabel = this.response.dataset
            .getPossible(this.stackedGrouping)
            .map(c => c.label)
            .getOr(this.stackedGrouping);

        const tooltext = [
            '<b>$label</b>',
            `<br>${groupingLabel}: <b>$seriesName</b>`,
            `<br>${this.measure}: <b>$dataValue</b>`
        ];
        if (this.showTotal) {
            tooltext.push('<br>Total: <b>$sum</b>');
        }

        return tooltext.join('');
    }

    dataset(selections: VizSelection, seriesConfig?: SeriesConfigFactory): Dataset {
        const plotAlpha = selections.isEmpty() ? '80' : '30';
        const metricIndex = this.response.result.columnIndices.get(this.measure);

        if (seriesConfig == null) {
            seriesConfig = () => ({});
        }

        return Array.from(this.stackedDimensionLabels.entries()).map(
            ([label, valueAndIndex]: [string, Tuple<string | number, number>], seriesIndex: number, labels: any[]) =>
                Object.assign(
                    {
                        seriesName: label,
                        data: Array.from(this.collapsedCategories.values()).map(indexValues => {
                            const stackValue = valueAndIndex.left == null ? null : valueAndIndex.left.toString();
                            const categoryValues = [...indexValues, stackValue];
                            const row = this.response.result.indexedRow(categoryValues);
                            return {
                                value: row == null ? null : row[metricIndex],
                                // index values sans the final stacked dimension value which we have a lookup for
                                categoryValues: categoryValues,
                                alpha: selections.has(categoryValues) ? '80' : plotAlpha
                            };
                        })
                    },
                    seriesConfig(label, seriesIndex, seriesIndex + 1 === labels.length)
                )
        );
    }

}