import {JsonObject} from "common/CommonTypes";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import {BROWSE_PATH} from "common/Paths";

export class FolderResult implements NavigableSearchResult {

    static fromJSON(json: JsonObject): FolderResult {
        const folder = json;
        return new FolderResult(
            folder['id'],
            folder['name'],
            folder['label'],
            folder['description'],
            folder['ownerId'],
            folder['ownerName'],
            folder['createdOn'],
            folder['updatedOn'],
            folder['isPublic']
        );
    }

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        public readonly ownerId: string,
        public readonly ownerName: string,
        public readonly createdOn: string,
        public readonly updatedOn: string,
        public readonly isPublic: boolean,
    ) {
    }

    getFolderUrl(): string {
        return `${this.ownerName}/${this.name}`;
    }

    get path(): string {
        return BROWSE_PATH + "/" + this.getFolderUrl();
    }
}