import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {ArcQL} from "metadata/query/ArcQL";
import {OnLoadSelection, Selectable, SetSelection} from "engine/actor/Selectable";
import {DiscreteSelection, RangeSelection, VizSelection} from "engine/actor/VizSelection";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {SingleSource} from "metadata/query/ArcQLSource";
import {Facet, FacetingEventFilter} from "engine/FacetingMessage";
import {ArcActor} from "engine/actor/ArcActor";
import {SequentialQuerier} from "engine/actor/SequentialQuerier";
import {FacetingSelectionHandler} from "engine/actor/FacetingSelectionHandler";
import {Optional} from "common/Optional";
import {FQN} from "common/FQN";
import {ReferenceQuery} from "metadata/dashboard/DashboardQueries";
import {GlobalFilterEventFilter} from "engine/actor/GlobalFiltersMessage";
import {LoadStateEventFilter} from "engine/actor/LoadStateMessage";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import debounce from "lodash/debounce";
import {SelectMode} from "metadata/dashboard/SelectMode";
import {ClearSelectionsEventFilter} from "engine/ClearSelectionsMessage";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {SelectionEventFilter} from "engine/SelectionMessage";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {QueryReason} from "engine/actor/QueryResason";
import {DatasetV2Service} from "services/DatasetV2Service";

/**
 * Actor for references to saved queries that are immutable.
 *
 * @author zuyezheng
 */
export class ReferencedQueryActor extends ArcActor implements Selectable {

    public arcql: ArcQL;
    public dataset: ArcDataset;

    private readonly loadStateFilter: LoadStateEventFilter;
    private selectionsFilter: SelectionEventFilter;
    private globalFiltersFilter: GlobalFilterEventFilter;
    private facetingFilter: FacetingEventFilter;
    private clearSelectionsFilter: ClearSelectionsEventFilter;

    private readonly querier: SequentialQuerier;
    private appliedFacets: Facet[];
    private appliedGlobalFilters: GlobalFilterClause[];

    private readonly debouncedBroadcastFaceting: () => void;
    private setSelection: Optional<SetSelection>;
    private onLoadSelection: Optional<OnLoadSelection>;
    private _selection: VizSelection;
    private _pausedReason: string;

    constructor(
        id: string,
        public readonly queryId: string,
        public readonly query: ReferenceQuery,
        private readonly dashboardFqn: FQN,
        // number of milliseconds to debounce faceting messages
        facetingDebounce: number = 1000
    ) {
        super(id);

        this.querier = new SequentialQuerier(false);
        this.appliedFacets = [];
        this.appliedGlobalFilters = [];

        // we want the existing and initial state before making our first query
        this.loadStateFilter = new LoadStateEventFilter(id);

        this.debouncedBroadcastFaceting = debounce(this.broadcastFaceting, facetingDebounce);
        this.setSelection = Optional.none();
        this.onLoadSelection = Optional.none();
        this._selection = VizSelection.none();
    }

    async initialize(controller: AbortController): Promise<boolean> {
        // fetch the saved query
        this.arcql = await ServiceProvider.get(MetadataService)
            .fetchArcQL(this.query.fqn, controller.signal, this.dashboardFqn)
            .then(e => e.rightOrThrow());
        this.dataset = await ServiceProvider.get(DatasetV2Service)
            .describeDataset((this.arcql.source as SingleSource).fqn, controller.signal, this.arcql.fullyQualifiedName)
            .then(e => e.rightOrThrow());

        this.selectionsFilter = new SelectionEventFilter();
        // scope event filters to this dataset
        this.globalFiltersFilter = new GlobalFilterEventFilter(this.dataset.fqn);
        this.facetingFilter = new FacetingEventFilter(this.dataset.fqn.toString());
        this.clearSelectionsFilter = new ClearSelectionsEventFilter(this.dataset.fqn.toString());
        return true;
    }

    eventFilters(): ArcEventFilter[] {
        return [this.loadStateFilter, this.selectionsFilter, this.globalFiltersFilter, this.facetingFilter, this.clearSelectionsFilter];
    }

    notify(event: ArcEvent): void {
        // if it's an initial state message, see what state we need to apply before making our first query
        this.loadStateFilter.filter(event)
            .forEach(message => {
                // when loading new state, reset selection + applied facets
                this._selection = VizSelection.empty();
                this.appliedFacets = [];
                this.appliedGlobalFilters = [];

                // see if there are stateful messages we care about
                Optional.of(message.statefulMessages
                    .map(m => new ArcEvent(event.actor, m))
                    .find(e => this.selectionsFilter.of(e))
                ).flatMap(
                    // ideally should only have 1 stateful selection message; if not, last one wins
                    e => this.selectionsFilter.filter(e)
                        .map(m => {
                                this._selection = m.selection;
                            }
                        )
                );

                // see if there are any facets we care about
                let facets: Facet[] = null;
                if (this.query.receiveFacets) {
                    facets = Optional.of(
                        message.statefulMessages
                            .map(m => new ArcEvent(event.actor, m))
                            .find(e => this.facetingFilter.of(e))
                    ).flatMap(e =>
                        this.facetingFilter.filter(e)
                            .map(m => m.facets.filter(f => !f.isEmpty))
                    ).nullable;
                }

                let globalFilters: GlobalFilterClause[] = null;
                if (this.query.receiveGlobalFilters) {
                    globalFilters = Optional.of(
                        message.statefulMessages
                            .map(m => new ArcEvent(event.actor, m))
                            .find(e => this.globalFiltersFilter.of(e))
                    ).flatMap(e =>
                        this.globalFiltersFilter.filter(e)
                            .map(m => m.forDataset(this.dataset.fqn))
                    ).nullable;
                }

                // make the query with existing state
                this.makeQuery(facets, globalFilters);

                // propagate selection changes (if any)
                this.onSelection(false);
                this.onLoadSelection.map(f => f(this._selection));
            });

        // if it's a faceting message, see if we need to apply it
        this.facetingFilter.filter(event)
            // only care if configured to receive facets
            .filter(() => this.query.receiveFacets)
            .forEach(m =>
                // facet by non-empty filters
                this.makeQuery(m.facets.filter(f => !f.isEmpty), null)
            );

        // if it's a global filter message, see if we need to apply it
        this.globalFiltersFilter.filter(event)
            // only care if configured to receive global filters
            .filter(() => this.query.receiveGlobalFilters)
            .forEach(message => {
                // make the query with global filters for the current dataset
                this.makeQuery(null, message.forDataset(this.dataset.fqn))
            });

        // see if we need to clear selections
        this.clearSelectionsFilter.filter(event)
            .filter(m => {
                // depending on composite facets, we might need to massage the current selections to match
                const selection = this.query.compositeFacets ? this._selection : this._selection.rootSelection;
                return selection.isOn(m.columns);
            })
            .forEach(() => {
                this._selection = this._selection.empty();
                // notify the change without a debounce since unlike selections, there is no chance for multiple
                this.onSelection(true, false);
            });
    }

    /**
     * URL encode any faceting clauses.
     */
    encodedFilters(): Optional<string> {
        if (this.appliedGlobalFilters.length === 0 && this.appliedFacets.length === 0) {
            return Optional.none();
        }

        return Optional.some(
            btoa(JSON.stringify(this.appliedFilters().clauses))
        );
    }

    /**
     * Get applied filters.
     */
    public appliedFilters(): ArcQLFilters {
        let filters = this.arcql.filters;
        // convert global filters to base filter clause for proper JSON serialization
        const globalFilters: FilterClause[] = this.appliedGlobalFilters.map(f => f.toBaseFilterClause());

        // apply global filters
        if (this.appliedGlobalFilters.length > 0) {
            if (this.query.globalFiltersReplace) {
                // if merge is on we try to replace any existing filters before applying additional
                filters = filters.merge(globalFilters);
            } else {
                filters = filters.withAll(globalFilters);
            }
        }
        // apply facets
        if (this.appliedFacets.length > 0) {
            filters = filters.withAll(this.appliedFacets.map(f => f.filterClause));
        }

        return filters;
    }

    /**
     * Query, apply facets, and publish the results.
     */
    private makeQuery(facets: Facet[] = null, globalFilters: GlobalFilterClause[] = null) {
        // if either are null, use the applied
        if (facets != null) {
            this.appliedFacets = facets;
        }
        if (globalFilters != null) {
            this.appliedGlobalFilters = globalFilters;
        }

        const appliedFilters = this.appliedFilters();
        this.querier.querySaved(
            this.arcql,
            QueryReason.FACETING,
            appliedFilters.size === 0 ? Optional.none() : Optional.some(appliedFilters),
            message => this.connector.publish(message)
        );
    }

    private broadcastFaceting(): void {
        // kill switch if not emitting facets
        if (!this.query.emitFacets) {
            return;
        }

        new FacetingSelectionHandler(this.arcql, this.dataset)
            // if composite facets is off, broadcast the root selection vs the full selection across all groupings
            .onSelection(this.query.compositeFacets ? this._selection : this._selection.rootSelection)
            .map(message => {
                this.connector.publish(message);
                return true;
            })
            .getOr(false);
    }

    private onSelection(broadcast: boolean = true, debounce: boolean = true) {
        // notify the linked component
        this.setSelection.map(f => f(this._selection));

        if (broadcast) {
            debounce ? this.debouncedBroadcastFaceting() : this.broadcastFaceting();
        }
    }

    linkSelectable(setSelection: SetSelection, onLoad: OnLoadSelection): void {
        this.setSelection = Optional.some(setSelection);
        this.onLoadSelection = Optional.some(onLoad);
        // if selection non-empty due to initial state injection, notify linked component but no need to broadcast facets
        if (!this._selection.isEmpty()) {
            this.onSelection(false);
            this.onLoadSelection.map(f => f(this._selection));
        }
    }

    unlinkSelectable(): void {
        this.setSelection = Optional.none();
    }

    canSelect(): boolean {
        // can't select without groupings
        if (this.arcql == null || this.arcql.groupings.size === 0) {
            return false;
        }

        // can't select with expression groupings
        return !this.arcql.groupings.fields.some(g => g.type === ArcQLGroupingType.EXPRESSION);
    }

    toggleDiscrete(values: string[][]): void {
        if (!this.canSelect()) {
            return;
        }

        if (this.isPaused()) {
            return;
        }

        // if the current selections are empty, create a new discrete selection with the current groupings
        if (this._selection.isEmpty()) {
            this._selection = VizSelection.empty(this.arcql.groupings.fields.map(f => f.field));
        }

        if (this.query.selectMode === SelectMode.MULTI) {
            // multiselect, just toggle selection values
            this._selection = values.reduce(
                (selection, v) => selection.toggle(v),
                this._selection
            );
        } else {
            // single select so use only the first value
            const value = values[0];

            // need to figure out if we need to clear the selection or select a single value
            const isSelected = this._selection.has(value);
            this._selection = VizSelection.empty(this.arcql.groupings.fields.map(f => f.field));

            if (!isSelected) {
                this._selection = this._selection.toggle(value);
            }
        }

        this.onSelection();
    }

    setDiscrete(values: string[][]): void {
        if (!this.canSelect()) {
            return;
        }

        if (this.isPaused()) {
            return;
        }

        // set the selection as truth
        this._selection = DiscreteSelection.fromValues(this.arcql.groupings.fields.map(f => f.field), values);

        this.onSelection();
    }

    selectRange(start: number, end: number): RangeSelection {
        if (this.isPaused()) {
            return;
        }

        this._selection = new RangeSelection(this.arcql.firstDate()[2].field, [start, end]);

        this.onSelection();
        return this._selection as RangeSelection;
    }

    get selection(): VizSelection {
        return this._selection;
    }

    pauseSelections(reason: string): void {
        this._pausedReason = reason;
    }

    resumeSelections(): void {
        this._pausedReason = null;
    }

    private isPaused(): boolean {
        if (this._pausedReason != null) {
            ServiceProvider.get(NotificationsService).publish(
                'ReferencedQueryActor.warnFreezingSelections',
                NotificationSeverity.WARNING,
                this._pausedReason
            );
            return true;
        }
        return false;
    }
}