import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {ConnectionType} from "metadata/connections/ConnectionType";
import {DatasetListItem} from "metadata/dataset/DatasetListItem";
import {HyperArcUser} from "metadata/HyperArcUser";

/**
 * Stubs a user's connection to HyperArc to create personal datasets.
 */
export class HyperArcConnection {

    /**
     * Create a minimal HyperArc connection with fake creation dates.
     */
    public static minimal(): RemoteDataSource {
        return new RemoteDataSource(
            '00000000-00000000-00000000-00000000',
            'hyperarc',
            'HyperArc',
            ConnectionType.HYPERARC,
            {},
            'hyperarc',
            '2022-01-01T11:00:00Z',
            'hyperarc',
            '2022-01-01T11:00:00Z',
            []
        );
    }

    /**
     * Create the fake HyperArc connection tailored to a user wherein creation is equivalent to the user's creation date.
     */
    public static new(user: HyperArcUser, datasets: DatasetListItem[]): RemoteDataSource {
        return new RemoteDataSource(
            '00000000-00000000-00000000-00000000',
            'hyperarc',
            'HyperArc',
            ConnectionType.HYPERARC,
            {},
            user.username,
            user.createdOn,
            user.username,
            user.createdOn,
            datasets
        );
    }
}