import {Enum} from "common/Enum";
import {JsonObject} from "common/CommonTypes";

/**
 * Trigger Condition interface for alerts.
 */
export interface TriggerCondition {

    get type(): TriggerConditionType;

    toJSON(): JsonObject;

    /**
     * Returns a string representation of the condition.
     */
    detail(field: string): string;
}


/**
 * Different types of trigger conditions.
 */
export class TriggerConditionType extends Enum {

    static THRESHOLD = new this('threshold', 'Threshold');
    static RANGE = new this('range', 'Range');

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}

TriggerConditionType.finalize();