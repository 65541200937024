import styled from "@emotion/styled";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import {Colors} from "app/components/StyleVariables";
import {Optional} from "common/Optional";
import debounce from "lodash/debounce";
import {AssetSearchParams, SearchSince} from "metadata/search/AssetSearchParams";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import React, {
    ChangeEvent,
    FocusEvent,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import {MetadataService} from "services/MetadataService";
import {FoldersSearchResponse} from "metadata/project/FoldersSearchResponse";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {Divider} from "@mui/material";
import {ServiceProvider} from "services/ServiceProvider";
import {AssetType} from "metadata/AssetType";
import {InternalRouterService} from "services/InternalRouterService";
import {TabPath} from "app/TabPath";
import {JsonObject} from "common/CommonTypes";
import {LinkService} from "services/LinkService";
import {SearchArcQLTemplate} from "app/components/search/SearchArcQLTemplates";
import {ScopedSearch} from "app/home/ScopedSearch";
import {ScopedSearchProject} from "app/home/ScopedSearchProject";
import {AccountsSearchResponse} from "metadata/account/AccountsSearchResponse";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import {ScopedSearchNone} from "app/home/ScopedSearchNone";
import {SearchResponse} from "metadata/search/SearchResponse";
import {useListNav} from "app/components/hooks/ListNav";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import { HyperGraphSearchParams } from "metadata/search/HyperGraphSearchParams";
import {HyperGraphNodeSearchResultsList} from "app/home/HyperGraphNodeSearchResultsList";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {AssetAutoCompleteItem} from "app/components/search/autocomplete/AssetAutoCompleteItem";
import {FolderResult} from "metadata/project/FolderResult";
import {ProjectAutoCompleteItem} from "app/components/search/autocomplete/ProjectAutoCompleteItem";
import {AccountResult} from "metadata/account/AccountResult";
import {AccountAutoCompleteItem} from "app/components/search/autocomplete/AccountAutoCompleteItem";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    className?: string

    // alter the search term
    searchTerm?: string
    // provide a callback to change this to a controlled component
    onSearchTermChange?: (searchTerm: string) => void

    // callback for when a search should be initiated through enter or timeout
    onSearch?: (searchTerm: Optional<string>) => void
    // optional delay to trigger onSearch if the search term has changed even if enter is not pressed
    delayOnSearch?: number

    // scope of the search for autocomplete, contextualizes the search to an account or project if present
    scopedSearch: ScopedSearch
    // callback if user deselects the scoped search
    onScopeReset: () => void

    since?: SearchSince
    // if autocomplete should be enabled
    autoComplete?: boolean
    // if autocomplete is enabled, callback for autocomplete selection
    onSelectAutoComplete?: (selected: NavigableSearchResult) => void
    // disable the placeholder text
    disablePlaceholderText?: boolean

    // change focus to the input
    focus?: boolean
    onFocusChange?: (focus: boolean) => void
}

/**
 * Search bar with autocomplete.
 *
 * @author zuyezheng
 */
export const SearchBar: FunctionComponent<Props> = (props: Props) => {

    const input = useRef(null);
    const autoCompleteResultsEl = useRef();
    const delayedSearchTimeout = useRef(null);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [assetSearchResults, setAssetSearchResults] = useState<Optional<AssetsSearchResponse>>(Optional.none());
    const [folderSearchResults, setFolderSearchResults] = useState<Optional<FoldersSearchResponse>>(Optional.none());
    const [accountSearchResults, setAccountSearchResults] = useState<Optional<AccountsSearchResponse>>(Optional.none());
    const [hyperGraphSearchResults, setHyperGraphSearchResults] = useState<Optional<AssetsSearchResponse>>(Optional.none());

    // if the current input should be focused on
    const [focused, setFocused] = useState<boolean>(false);
    const [numAutoCompleteItems, setNumAutoCompleteItems] = useState<number>(0);
    const {navIndex, resetNavIndex, onNavKeyDown} = useListNav(numAutoCompleteItems);

    useEffect(() => {
        if (props.focus && input.current) {
            input.current.focus();
        }
    }, [props.focus]);

    useEffect(() => {
        if (props.searchTerm != null) {
            setSearchTerm(props.searchTerm);
        }
    }, [props.searchTerm]);

    const autoCompleteSearch = useCallback(
        debounce((searchTerm: string) => {
            const controller = new AbortController();
            setIsSearching(true);
            const searchPromises = [];

            const isNoneScope = props.scopedSearch instanceof ScopedSearchNone;
            searchPromises.push(ServiceProvider.get(MetadataService)
                .assetSearch(new AssetSearchParams(
                    isNoneScope ? 4 : 5,
                    searchTerm,
                    AssetType.searchable(),
                    'recent_all',
                    props.scopedSearch.account.getOr(null),
                    null,
                    props.since == null ? 'month' : props.since,
                    props.scopedSearch.project.map(p => p.getFolderUrl()).getOr(null)
                ), controller.signal)
                .then(r => r.forEach(
                    searchResults => setAssetSearchResults(searchResults.optional)
                )));

            if (!(props.scopedSearch instanceof ScopedSearchProject)) {
                searchPromises.push(ServiceProvider.get(MetadataService)
                    .folderSearch(new FolderSearchParams(
                        isNoneScope ? 4 : 5,
                        searchTerm,
                        null,
                        null,
                        props.scopedSearch.account.getOr(null),
                    ), controller.signal)
                    .then(r => r.forEach(
                        searchResults => setFolderSearchResults(searchResults.optional)
                    )));
            } else {
                setFolderSearchResults(Optional.none());
            }

            if (isNoneScope) {
                searchPromises.push(ServiceProvider.get(MetadataService)
                    .accountSearch(new AccountSearchParams(
                        2,
                        searchTerm,
                    ), controller.signal)
                    .then(r => r.forEach(
                        searchResults => setAccountSearchResults(searchResults.optional)
                    )));
            }

            const terms = searchTerm.split(' ');

            // Only search the hypergraph if someone is actually attempting to ask a question
            if (terms.length >= 3) {
                searchPromises.push(ServiceProvider.get(MetadataService).hyperGraphSearchTest(searchTerm, controller.signal)
                    .then(r => r.optional().map(isQuestion => {
                        if (isQuestion) {
                            return ServiceProvider.get(MetadataService).hyperGraphSearch(new HyperGraphSearchParams(searchTerm), controller.signal)
                                .then(r => r.forEach(
                                    searchResults => setHyperGraphSearchResults(Optional.of(searchResults))
                                ));
                        } else {
                            setHyperGraphSearchResults(Optional.none());
                            return false;
                        }
                    })));
            }

            Promise.all(searchPromises).finally(() => setIsSearching(false));

            return controller;
        }, 500),
        [props.since, props.scopedSearch]
    );

    // if autocomplete results change update the state of arrow selection
    useEffect(() => {
        setNumAutoCompleteItems(
            autoCompleteResponses().map(r => r.results.length).reduce((a, b) => a + b, 0)
        );
        resetNavIndex();
    }, [assetSearchResults, folderSearchResults, accountSearchResults]);


    useEffect(() => {
        // if we adjust focus, reset the arrow index
        resetNavIndex();
        // sync up with any external watchers
        props.onFocusChange && props.onFocusChange(focused);
    }, [focused]);

    // trigger new autocomplete searches
    useEffect(() => {
        if (!props.autoComplete) {
            // if autocomplete switched to disabled, remove any existing results
            setAssetSearchResults(Optional.none());
            setFolderSearchResults(Optional.none());
            setAccountSearchResults(Optional.none());
            setHyperGraphSearchResults(Optional.none());
            return;
        }

        if (searchTerm.length > 0) {
            const controller = autoCompleteSearch(searchTerm);

            return () => {
                if (controller != null) {
                    controller.abort();
                }
            };
        } else {
            setAssetSearchResults(Optional.none());
            setFolderSearchResults(Optional.none());
            setAccountSearchResults(Optional.none());
            setHyperGraphSearchResults(Optional.none());
        }
    }, [searchTerm, props.autoComplete]);

    // return all non empty auto complete responses
    const autoCompleteResponses = (): SearchResponse<NavigableSearchResult>[] => {
        return [assetSearchResults, folderSearchResults, accountSearchResults]
            .flatMap<SearchResponse<NavigableSearchResult>>(r => r.array);
    };

    const onKeyDown = (event: KeyboardEvent) => {
        onNavKeyDown(event, onAutoCompleteItemSelection, onSearchEnter);
    };

    const onAutoCompleteItemSelection = (index: number) => {
        let overallItemIndex = 0;
        autoCompleteResponses().flatMap((response) => {
            response.results.forEach((r) => {
                if (index === overallItemIndex) {
                    onClickAutoComplete(r);
                }
                overallItemIndex++;
            });
        });
    };

    const onSearchEnter = () => {
        if (props.onSearch != null) {
            props.onSearch(Optional.string(searchTerm));
        }
    };

    // modify the search term depending on if it is a controlled component or not
    const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFocused(true);

        const newTerm = e.target.value;
        // see if it is a controlled component or not
        if (props.onSearchTermChange == null) {
            setSearchTerm(newTerm);
        } else {
            props.onSearchTermChange(newTerm);
        }

        if (props.delayOnSearch != null && props.onSearch != null) {
            if (delayedSearchTimeout.current != null) {
                clearTimeout(delayedSearchTimeout.current);
            }

            delayedSearchTimeout.current = setTimeout(
                () => props.onSearch(Optional.string(newTerm)),
                props.delayOnSearch
            );
        }
    };

    const onClickAutoComplete = (result: NavigableSearchResult) => {
        props.onSelectAutoComplete?.(result);
        setSearchTerm('');
        setFocused(false);
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        let el = event.relatedTarget;
        while (el != null) {
            if (el === autoCompleteResultsEl.current) {
                return;
            }
            el = el.parentElement;
        }

        setFocused(false);
    };

    const onScopedChipDelete = () => {
        props.onScopeReset();
        setSearchTerm('');
    };

    const openQuery = (arcqlJson: JsonObject) => {
        ServiceProvider.get(LinkService).query()
            .then(url => ServiceProvider.get(InternalRouterService).route(
                'search',
                TabPath.fromRaw(url),
                new Map([[
                    "arcql", arcqlJson
                ]])
            ));
    };

    const onFindTransaction = () => {
        openQuery(SearchArcQLTemplate.transactionSearch(searchTerm.toLowerCase()));
    };

    const onFindWallet = () => {
        openQuery(SearchArcQLTemplate.walletSearch(searchTerm.toLowerCase()));
    };

    const showAutoComplete = focused &&
        (!hyperGraphSearchResults.isPresent || hyperGraphSearchResults.get().results.length === 0) &&
        (
            // asset or folder search results
            assetSearchResults.isPresent ||
            folderSearchResults.isPresent ||
            accountSearchResults.isPresent ||
            // hash search
            searchTerm.startsWith('0x')
        );

    const placeHolderText = (): string => {
        if (props.disablePlaceholderText) {
            return null;
        }
        return props.scopedSearch.searchBarPlaceholderText();
    };

    const buildAutoCompleteResults = (): React.ReactNode[] => {
        let overallItemIndex = 0;
        return autoCompleteResponses().flatMap((response, responseIndex) => {
            let resultComps: React.JSX.Element[] = [];

            // If response is not the first and the previous response is present, start with a divider.
            if (responseIndex !== 0) {
                resultComps.push(<Divider key={`divider_${responseIndex}`}/>);
            }

            const listItems = response.results.map((r, rI) => {
                let autocompleteComp;
                const selected = navIndex.map(i => i === overallItemIndex).getOr(false);
                if (r instanceof AssetSearchResult) {
                    autocompleteComp = <AssetAutoCompleteItem
                        key={rI}
                        selected={selected}
                        result={r}
                        onClick={() => onClickAutoComplete(r)}
                    />;
                } else if (r instanceof FolderResult) {
                    autocompleteComp = <ProjectAutoCompleteItem
                        key={rI}
                        selected={selected}
                        result={r}
                        onClick={() => onClickAutoComplete(r)}
                    />;
                } else if (r instanceof AccountResult) {
                    autocompleteComp = <AccountAutoCompleteItem
                        key={rI}
                        selected={selected}
                        result={r}
                        onClick={() => onClickAutoComplete(r)}
                    />;
                } else {
                    throw new Error('Unknown search result type');
                }
                overallItemIndex++;
                return autocompleteComp;
            });
            if (listItems.length > 0) {
                resultComps.push(<List key={`list_${responseIndex}`}>{listItems}</List>);
            }

            return resultComps;
        });
    };

    return <S.SearchBar className={props.className}>
        <TextField
            inputRef={input}
            InputProps={{
                startAdornment:
                    <InputAdornment position="start">
                        <SearchIcon/>
                        {props.scopedSearch.renderChip({onDelete: onScopedChipDelete})}
                    </InputAdornment>,
                endAdornment:
                    isSearching ?
                        <InputAdornment position="end">
                            <CircularProgress size={21}/>
                        </InputAdornment> :
                        null
            }}

            autoComplete="off"
            fullWidth={true}
            variant="outlined"
            size="small"
            placeholder={placeHolderText()}
            value={searchTerm}
            onChange={onSearchTermChange}
            onKeyDown={onKeyDown}
            onFocus={() => setFocused(true)}
            onClick={() => setFocused(true)}
            onBlur={onBlur}
        />
        {
            props.autoComplete && showAutoComplete && <S.AutoComplete className="autoComplete" ref={autoCompleteResultsEl}>
                {
                    Optional.bool(searchTerm.startsWith('0x')).map(() =>
                        <List>
                            <S.AutoCompleteItem
                                onClick={onFindTransaction}
                            >
                                <ListItemText
                                    primary={searchTerm}
                                    secondary={'Find Transaction'}
                                />
                            </S.AutoCompleteItem>
                            <S.AutoCompleteItem
                                onClick={onFindWallet}
                            >
                                <ListItemText
                                    primary={searchTerm}
                                    secondary={'Find Wallet'}
                                />
                            </S.AutoCompleteItem>
                        </List>
                    ).nullable
                }
                {buildAutoCompleteResults()}
            </S.AutoComplete>
        }
        {
            props.autoComplete && focused && hyperGraphSearchResults.map(r => {
                if (r.results.length > 0) {
                    return (
                        <S.AutoComplete className="autoComplete" ref={autoCompleteResultsEl}>
                            <HyperGraphNodeSearchResultsList assets={r} onClick={onClickAutoComplete}/>
                        </S.AutoComplete>
                    );
                }
                return null;
            }).nullable
        }
    </S.SearchBar>;
};

const S = {

    SearchBar: styled.div`
        width: 100%;
    `,

    AutoComplete: styled.div`
        position: absolute;
        margin-top: 4px;
        background-color: white;
        border: 1px solid ${Colors.borderGrey};
        border-radius: 4px;
        min-width: 450px;
        z-index: 1000;
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 12px 0;

        .MuiListItemIcon-root {
            padding-left: 8px;
            min-width: 40px;
        }
    `,

    AutoCompleteItem: styled(ListItemButton)`
        padding: 4px;
    `
};
