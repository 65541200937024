import * as React from "react"
import {SVGProps} from "react"

const ToggleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M21 4.5H3c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1v-14c0-.55-.45-1-1-1Zm-13 14H4v-12h4v12Zm6 0h-4v-12h4v12Zm6 0h-4v-12h4v12Z"
        />
    </svg>
);
export default ToggleIcon;
