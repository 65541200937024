import {Either} from "common/Either";
import {FQN} from "common/FQN";
import {ErrorResponse} from "services/ApiResponse";
import {UserSubscription} from "metadata/subscription/UserSubscription";

/**
 * Service specific for subscribing to assets.
 */
export abstract class SubscriptionService {

    /**
     * List all subscriptions that a user has.
     */
    abstract listSubscriptions(signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription[]>>;

    /**
     * Subscribe to a trend alert.
     */
    abstract subscribeToAlert(trendFqn: FQN, alertId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>>;

    /**
     * Unsubscribe from a trend alert.
     */
    abstract unsubscribeFromAlert(trendFqn: FQN, alertId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>>;

    /**
     * Archive a subscription.
     */
    abstract archiveSubscription(subscriptionId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>>;
}