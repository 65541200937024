import * as React from "react"
import {SVGProps} from "react"

const ReceiveReplaceGlobalFiltersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={24}
        {...props}
    >
        <path
            fill="#000"
            fillOpacity={0.54}
            d="M3 6h10l-5.01 6.3L3 6ZM.25 5.61C2.27 8.2 6 13 6 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39A.998.998 0 0 0 14.95 4H1.04c-.83 0-1.3.95-.79 1.61Z"
        />
        <path
            fill="#43A047"
            d="m25.334 9.607-.94-.94-7.727 7.726V12h-1.333v6.667h6.667v-1.334h-4.394l7.727-7.726Z"
        />
        <path
            fill="#039BE5"
            d="m32.286 10.78-.91 1.36 3.09-.558-.664-3.07-.91 1.36c-2.075-1.387-4.842-.889-6.182 1.116a4.219 4.219 0 0 0-.71 2.32l1.128-.203c.04-.518.215-1.031.52-1.49 1.005-1.5 3.084-1.875 4.638-.835Zm2.646 2.911-1.129.204a3.194 3.194 0 0 1-.52 1.49c-1.004 1.5-3.083 1.875-4.637.836l.91-1.36-3.091.557.665 3.07.91-1.36c2.075 1.387 4.841.889 6.182-1.116a4.218 4.218 0 0 0 .71-2.32Z"
        />
    </svg>
)
export default ReceiveReplaceGlobalFiltersIcon
