import {ArcActor} from "engine/actor/ArcActor";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";

/**
 * Singleton system actor that can handle system-y stuff such as sending messages from the engine itself (such as
 * collated state) or for debugging to listen to all events.
 *
 * @author zuyezheng
 */
export class SystemActor extends ArcActor {

    constructor() {
        super('__SYSTEM__');
    }

    eventFilters(): ArcEventFilter[] {
        return [];
    }

    notify(event: ArcEvent): void {
    }

}