import * as React from "react"
import {SVGProps} from "react"

export const UploadDatasetIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="42" viewBox="0 0 43 42" fill="none" {...props}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.9991 7.875H35.125V34.125H8.875V16.958C8.58708 16.9858 8.2952 17 8 17C7.40121 17 6.81608 16.9415 6.25 16.83L6.25 34.125C6.25 34.825 6.5125 35.4375 7.0375 35.9625C7.5625 36.4875 8.175 36.75 8.875 36.75H35.125C35.825 36.75 36.4375 36.4875 36.9625 35.9625C37.4875 35.4375 37.75 34.825 37.75 34.125V7.875C37.75 7.175 37.4875 6.5625 36.9625 6.0375C36.4375 5.5125 35.825 5.25 35.125 5.25L16.5721 5.25C16.8379 6.07912 16.9867 6.96059 16.9991 7.875ZM13.6875 14.9755V18.8125H19.8125V12.6875H15.6844C15.1518 13.5588 14.4751 14.3325 13.6875 14.9755ZM13.6875 29.3125H19.8125V23.1875H13.6875V29.3125ZM24.1875 29.3125H30.3125V23.1875H24.1875V29.3125ZM24.1875 18.8125H30.3125V12.6875H24.1875V18.8125Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM6.5625 12.5V6.40326H3.75L7.5 2.5L11.25 6.40326H8.4375V12.5H6.5625Z"
                  fill="white"/>
        </svg>
    );
};
