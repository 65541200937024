import {Enum} from "common/Enum";
import React from "react";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {ContainerConfigToolbar} from "app/dashboard/widgets/config/ContainerConfigToolbar";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";
import {TextConfigToolbar} from "app/dashboard/widgets/config/TextConfigToolbar";
import {MarkdownConfigEditor} from "app/dashboard/widgets/config/MarkdownConfigEditor";
import {LinkConfigEditor} from "app/dashboard/widgets/config/LinkConfigEditor";
import {QueryMetadataDisplay} from "app/dashboard/widgets/config/QueryMetadataDisplay";
import {ImageConfigToolbar} from "app/dashboard/widgets/config/ImageConfigToolbar";
import {ImageConfigEditor} from "app/dashboard/widgets/config/ImageConfigEditor";
import {LinkConfigToolbar} from "app/dashboard/widgets/config/LinkConfigToolbar";

/**
 * Properties needed for editing a configuration part.
 */
export interface ConfigEditorProps<Props extends WidgetConfigPart> {
    config: Props
    onConfigPropertyChange: (propsChanged: Props) => void
}

/**
 * An extension of WidgetConfigPart specific for app usages such as the editor component.
 */
export class AppWidgetConfigPartType extends Enum {

    static readonly CONTAINER = new this(
        WidgetConfigPartType.CONTAINER,
        ContainerConfigToolbar,
        null
    );

    static readonly TEXT = new this(
        WidgetConfigPartType.TEXT,
        TextConfigToolbar,
        null
    );

    static readonly MARKDOWN = new this(
        WidgetConfigPartType.MARKDOWN,
        null,
        MarkdownConfigEditor,
    );

    static readonly LINK = new this(
        WidgetConfigPartType.LINK,
        LinkConfigToolbar,
        LinkConfigEditor,
    );

    static readonly CHART = new this(
        WidgetConfigPartType.CHART,
        null,
        null
    );

    static readonly IMAGE = new this(
        WidgetConfigPartType.IMAGE,
        ImageConfigToolbar,
        ImageConfigEditor
    );


    constructor(
        public readonly configPartType: WidgetConfigPartType,
        // the toolbar editor component for this config part type
        public readonly ToolbarEditor: React.ComponentType<ConfigEditorProps<WidgetConfigPart>> | null,
        // the block editor component for this config part type
        public readonly BlockEditor: React.ComponentType<ConfigEditorProps<WidgetConfigPart>> | null
    ) {
        super(configPartType.name);

    }

    public static from(type: WidgetConfigPartType): AppWidgetConfigPartType {
        return this.get(type.name);
    }
}

AppWidgetConfigPartType.finalize();