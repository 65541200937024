import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {Optional} from "common/Optional";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";

/**
 * Attach (or detatch with a Optional.empty) a query to a widget.
 *
 * @author zuyezheng
 */
export class AttachQueryToWidget implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly queryId: Optional<string>,
        public readonly widgetId: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const widget = metadata.widgets.get(this.widgetId) as QueriedWidgetMetadata<any, any>;
        const updatedWidget = this.queryId
            .map(id => widget.attach(id))
            .getOrElse(() => widget.detach());

        return Tuple.of(
            metadata.with({
                widgets: metadata.widgets.with(this.widgetId, updatedWidget)
            }),
            new AttachQueryToWidget(widget.queryId, this.widgetId)
        );
    }

    describe(): string {
        return this.queryId.map(q => `Attached query ${q} to widget ${this.widgetId}.`)
            .getOr(`Detached query from widget ${this.widgetId}`);
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}