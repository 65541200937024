import * as React from "react";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {Tuple} from "common/Tuple";
import FormControl from "@mui/material/FormControl";
import {S} from "app/visualizations/definition/property/VizPropertyS";
import {Optional} from "common/Optional";

export class VizEnumProperty implements VizProperty<string> {

    constructor(
        public readonly name: string,
        public readonly label: string,
        // values provide as a tuple of (value, label)
        public readonly values: Tuple<string, string>[],
        // include icon for each value
        public readonly getIcon?: (value: string) => React.ReactNode
    ) {
    }

    el(v: Optional<string>, onChange: (v: string) => void): React.ReactNode {
        return <FormControl fullWidth size="small">
            <S.TextField
                select
                variant="standard"
                margin="dense"
                id={this.name}
                label={this.label}
                value={v.getOr('')}
                onChange={e => onChange(e.target.value)}
            >{
                this.values.map(value => {
                    const icon = this.getIcon?.(value.left);
                    return <S.SelectItem key={value.left} value={value.left}>
                        {
                            icon && <S.ListItemIcon>
                                {icon}
                            </S.ListItemIcon>
                        }
                        {value.right}
                    </S.SelectItem>;
                })
            }</S.TextField>
        </FormControl>;
    }

}

