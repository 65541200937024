import * as React from "react";
import {ChangeEvent, FunctionComponent, useContext, useEffect, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {Optional} from "common/Optional";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {ServiceProvider} from "services/ServiceProvider";
import {UserService} from "services/UserService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FolderCreateRequest from "metadata/project/FolderCreateRequest";
import {ErrorCode} from "services/ApiResponse";
import {MetadataService} from "services/MetadataService";
import {DialogS} from "app/DialogS";
import {SlugHelper} from "metadata/SlugHelper";
import {FolderResult} from "metadata/project/FolderResult";
import {UserContext} from "app/UserContext";


const IS_PUBLIC_TRUE = "public";
const IS_PUBLIC_FALSE = "private";

type Props = {
    account: string
    onCancel: () => void
    onCreate: (createdProject: FolderResult) => void
}

/**
 * Dialog for creating a new project.
 */
export const CreateProjectDialog: FunctionComponent<Props> = (props: Props) => {

    const [account, setAccount] = useState<string>(props.account);
    const [label, setLabel] = useState<string>('');
    const [name, setName] = useState<string>(SlugHelper.convert(label));
    const [description, setDescription] = useState<string>('');
    const [isPublic, setIsPublic] = useState<boolean>(false);

    // available accounts and folders
    const [accounts, setAccounts] = useState<Optional<string[]>>(Optional.none());
    const [saveErrors, setSaveErrors] = useState<Optional<Map<string, string>>>(Optional.none());

    // get current user account
    const userContext = useContext(UserContext);
    const loggedInUserName = userContext.user.map(u => u.username).getOr(null);

    // load the initial list of accounts, these wont change during the save dialog
    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            await ServiceProvider.get(UserService)
                .listMemberships()
                .then(response => response.match(
                    memberships => setAccounts(Optional.some([loggedInUserName, ...memberships.map(m => m.name)])),
                    () => ServiceProvider.get(NotificationsService).publish(
                        'save', NotificationSeverity.ERROR, 'Could not load accounts.'
                    )
                ));
        })();

        return () => controller.abort();
    }, []);

    const onChangeLabel = (e: ChangeEvent<HTMLInputElement>) => {
        SlugHelper.handleLabelChange(label, e.target.value, name, setLabel, setName);
    };


    const isAbsent = (s: string): boolean => {
        return Optional.string(s).map(_ => false).getOr(true);
    };

    const onCreate = () => {
        ServiceProvider.get(MetadataService).createFolder(
            account,
            new FolderCreateRequest(name, label, description, isPublic)
        ).then(response => response.match(
            folder => {
                ServiceProvider.get(NotificationsService).publish(
                    'createProject', NotificationSeverity.SUCCESS, `Successfully created Project "${folder.label}"!`
                );
                props.onCreate(folder);
            },
            error => {
                if (error.errorCode === ErrorCode.INVALID_FIELDS) {
                    setSaveErrors(error.fieldErrors);
                    return;
                }
                ServiceProvider.get(NotificationsService).publish(
                    'createProject', NotificationSeverity.ERROR, `Project creation failed: ${error.prettyPrint()}`
                );
            }
        ));
    };

    const onVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPublic(stringToBoolVisibility(event.target.value));
    };

    return Optional.all([accounts]).map(([accounts]: [string[]]) =>
        <Dialog
            open={true}
            onClose={props.onCancel}
            PaperProps={{
                sx: {
                    width: "600px"
                }
            }}
        >
            <DialogS.Title>Create a New Project</DialogS.Title>
            <DialogS.Content dividers>
                <DialogS.Body>
                    <DialogS.SelectorRow>
                        <div>
                            <FormControl sx={{width: "200px", marginRight: "16px"}} size={"small"}>
                                <FormLabel id="account-label" sx={{mb: 0.5}}>Owner</FormLabel>
                                <Select
                                    margin="dense"
                                    id="account"
                                    aria-labelledby="account-label"
                                    value={account}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setAccount(e.target.value)}
                                >{
                                    accounts.map(account =>
                                        <MenuItem key={account} value={account}>@{account}</MenuItem>
                                    )
                                }</Select>
                            </FormControl>
                        </div>
                        <FormControl fullWidth>
                            <FormLabel id="name-label" sx={{mb: 0.5}}>Name</FormLabel>
                            <TextField
                                autoFocus
                                margin="none"
                                size="small"
                                id="label"
                                aria-labelledby="name-label"
                                placeholder="Enter a name for the Project"
                                value={label}
                                error={saveErrors.map(errors => errors.has("label")).getOr(false)}
                                helperText={saveErrors.map(errors => errors.get("label")).getOr(null)}
                                onChange={onChangeLabel}
                                sx={{
                                    width: "280px"
                                }}
                            />
                        </FormControl>
                    </DialogS.SelectorRow>
                    <DialogS.InputRow>
                        <FormControl fullWidth>
                            <FormLabel id="description-label" sx={{mb: 0.5}}>Description</FormLabel>
                            <TextField
                                margin="none"
                                size="small"
                                id="description"
                                aria-labelledby="description-label"
                                placeholder="Optional description"
                                value={description}
                                error={saveErrors.map(errors => errors.has("description")).getOr(false)}
                                helperText={saveErrors.map(errors => errors.get("description")).getOr(null)}
                                fullWidth={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </FormControl>
                    </DialogS.InputRow>
                    <DialogS.InputRow>
                        <FormControl>
                            <FormLabel id="url-label" sx={{mb: 0.5}}>URL</FormLabel>
                            <TextField
                                margin="none"
                                size="small"
                                id="name"
                                aria-labelledby="url-label"
                                value={name}
                                error={saveErrors.map(errors => errors.has("name")).getOr(false)}
                                helperText={saveErrors.map(errors => errors.get("name")).getOr(null)}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(SlugHelper.convert(e.target.value))}
                                sx={{
                                    width: "280px"
                                }}
                            />
                            <DialogS.FullUrl>
                                hyperarc.com
                                / {account} / <DialogS.SlugText>{name}</DialogS.SlugText>
                            </DialogS.FullUrl>
                        </FormControl>
                    </DialogS.InputRow>
                    <DialogS.InputRow>
                        <FormControl>
                            <FormLabel>Visibility</FormLabel>
                            <RadioGroup
                                value={boolToStringVisibility(isPublic)}
                                onChange={onVisibilityChange}
                            >
                                <FormControlLabel value={"private"} control={<Radio/>} label="Private"/>
                                <FormControlLabel value={"public"} control={<Radio/>} label="Public"/>
                            </RadioGroup>
                        </FormControl>
                    </DialogS.InputRow>
                </DialogS.Body>
            </DialogS.Content>
            <DialogS.Actions>
                <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={onCreate}
                    disabled={isAbsent(name) || isAbsent(label)}
                >
                    Save
                </Button>
            </DialogS.Actions>
        </Dialog>
    ).getOrElse(() =>
        <Dialog
            open={true}
            PaperProps={{
                sx: {
                    width: "600px"
                }
            }}
        >
            <CircularProgress/>
        </Dialog>
    );

};

function stringToBoolVisibility(value: string): boolean {
    return value === IS_PUBLIC_TRUE;
}

function boolToStringVisibility(value: boolean): string {
    return value ? IS_PUBLIC_TRUE : IS_PUBLIC_FALSE;
}