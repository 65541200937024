import * as React from "react"
import {SVGProps} from "react"

const SingleSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        {...props}
    >
        <path
            fill="#000"
            fillOpacity={0.54}
            d="M6.24 3.64a2.6 2.6 0 0 0 0 5.2 2.6 2.6 0 0 0 0-5.2Zm0-2.6c-2.87 0-5.2 2.33-5.2 5.2s2.33 5.2 5.2 5.2 5.2-2.33 5.2-5.2-2.33-5.2-5.2-5.2Zm0 9.36a4.159 4.159 0 0 1-4.16-4.16 4.159 4.159 0 0 1 4.16-4.16 4.159 4.159 0 0 1 4.16 4.16 4.159 4.159 0 0 1-4.16 4.16ZM6.24 12.56c-2.87 0-5.2 2.33-5.2 5.2s2.33 5.2 5.2 5.2 5.2-2.33 5.2-5.2-2.33-5.2-5.2-5.2Zm0 9.36a4.159 4.159 0 0 1-4.16-4.16 4.159 4.159 0 0 1 4.16-4.16 4.159 4.159 0 0 1 4.16 4.16 4.159 4.159 0 0 1-4.16 4.16ZM17.76 12.56c-2.87 0-5.2 2.33-5.2 5.2s2.33 5.2 5.2 5.2 5.2-2.33 5.2-5.2-2.33-5.2-5.2-5.2Zm0 9.36a4.159 4.159 0 0 1-4.16-4.16 4.159 4.159 0 0 1 4.16-4.16 4.159 4.159 0 0 1 4.16 4.16 4.159 4.159 0 0 1-4.16 4.16ZM17.76 1.04c-2.87 0-5.2 2.33-5.2 5.2s2.33 5.2 5.2 5.2 5.2-2.33 5.2-5.2-2.33-5.2-5.2-5.2Zm0 9.36a4.159 4.159 0 0 1-4.16-4.16 4.159 4.159 0 0 1 4.16-4.16 4.159 4.159 0 0 1 4.16 4.16 4.159 4.159 0 0 1-4.16 4.16Z"
        />
    </svg>
)
export default SingleSelectIcon
