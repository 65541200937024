import {SessionService} from "services/SessionService";
import {Either} from "common/Either";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {RestService} from "services/RestService";
import {ServiceProvider} from "services/ServiceProvider";
import {SessionType} from "metadata/session/SessionType";
import {JsonObject} from "common/CommonTypes";
import {ArcSession, ArcSessionJson} from "metadata/session/ArcSession";

/**
 * Utilizes API to implement the SessionService.
 */
export class ApiSessionService implements SessionService {

    async create(
        path: string,
        type: SessionType,
        referenceId: string,
        state: JsonObject,
        signal?: AbortSignal
    ) : Promise<Either<ErrorResponse, ArcSession>> {
        return ServiceProvider.get(RestService).post(
            '/api/v1/sessions',
            ArcSession.forCreation(path, type, referenceId, state).toJSON(),
            signal
        ).then(r => ApiResponse.custom(
            r,
            json => ArcSession.fromJson(json as ArcSessionJson),
            (json: any, r: Response) => ErrorResponse.of(json, r)
        ));
    }

    async get(
        sessionId: string,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcSession>> {
        return ServiceProvider.get(RestService).get(
            `/api/v1/sessions/${sessionId}`,
            signal
        ).then(r => ApiResponse.custom(
            r,
            json => ArcSession.fromJson(json as ArcSessionJson),
            (json: any, r: Response) => ErrorResponse.of(json, r)
        ));
    }
}