import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {Tuple} from "common/Tuple";
import {ArcQLError} from "metadata/query/ArcQLError";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import isEqual from "lodash/isEqual";
import {FilterClauseFactory} from "metadata/query/filterclause/FilterClauseFactory";
import {References} from "metadata/References";

export class ArcQLFilters {

    static fromJSON(json: JsonObject, isAggregate: boolean, references: References): ArcQLFilters {
        return new ArcQLFilters(
            json['clauses'].map(
                (obj: JsonObject) => FilterClauseFactory.fromJSON(obj, isAggregate, references)
            ),
            json['expression'],
            isAggregate
        );
    }

    static empty(isAggregate: boolean): ArcQLFilters {
        return new ArcQLFilters([], null, isAggregate);
    }

    /**
     * And all clauses.
     */
    static ands(clauses: FilterClause[], isAggregate: boolean): ArcQLFilters {
        return new ArcQLFilters(clauses, '', isAggregate);
    }

    constructor(
        public readonly clauses: FilterClause[],
        public readonly expression: string,
        public readonly isAggregate: boolean
    ) {
    }

    get size(): number {
        return this.clauses.length;
    }

    get hasExpression(): boolean {
        return this.expression != null && this.expression.length > 0;
    }

    get(ordinal: number): FilterClause {
        return this.clauses[ordinal];
    }

    /**
     * Find the first filter on the given fields returning an optional tuple of the found clause and it's ordinal.
     */
    find(fields: string[]): Optional<Tuple<FilterClause, number>> {
        const index = this.clauses.findIndex(c => c.isFor(fields));
        return index >= 0 ? Optional.some(Tuple.of(this.clauses[index], index)) : Optional.none();
    }

    get last(): FilterClause {
        return this.clauses[this.clauses.length - 1];
    }

    /**
     * Create a copy of filters with all the new clauses.
     */
    withAll(clauses: FilterClause[]): ArcQLFilters {
        return new ArcQLFilters([...this.clauses, ...clauses], this.expression, this.isAggregate);
    }

    /**
     * Create a copy with the new clause at the specific ordinal.
     */
    with(clause: FilterClause, ordinal: number): ArcQLFilters {
        if (ordinal < 0) {
            throw new ArcQLError('Can\'t add a filter before ordinal 0.');
        }

        // add to the end
        if (ordinal >= this.clauses.length) {
            return this.withAll([clause]);
        }

        // add it to the right spot
        return new ArcQLFilters(
            this.clauses.flatMap(
                (c: FilterClause, cOrdinal: number) => {
                    if (ordinal === cOrdinal) {
                        // add the clause at the specified ordinal
                        return [clause, c];
                    } else {
                        return [c];
                    }
                }
            ),
            this.expression,
            this.isAggregate
        );
    }

    /**
     * Every clause in the current filters will be replaced with a given clause if a matching field is found. All
     * unmatched clauses will be added to the end.
     *
     * If there are multiple clauses on the same field, some wierd stuff is bound to happen and merge should probably
     * not be used.
     */
    merge(clauses: FilterClause[]): ArcQLFilters {
        return new ArcQLFilters(
            [
                ...this.clauses.filter(
                    v => clauses.every(c => !isEqual(c.fields, v.fields))
                ),
                ...clauses
            ],
            this.expression,
            this.isAggregate
        );
    }

    /**
     * Create with a new expression.
     */
    withExpression(expression: string): ArcQLFilters {
        return new ArcQLFilters(this.clauses, expression, this.isAggregate);
    }

    /**
     * Create a new filter object with the given clause removed.
     */
    without(ordinal: number): ArcQLFilters {
        return new ArcQLFilters(
            this.clauses.filter((_: FilterClause, i: number) => i !== ordinal),
            this.expression,
            this.isAggregate
        );
    }

    /**
     * Create a new filter object replacing the clause at the given ordinal.
     */
    replace(ordinal: number, clause: FilterClause): ArcQLFilters {
        return new ArcQLFilters(
            this.clauses.map((c: FilterClause, i: number) => i === ordinal ? clause : c),
            this.expression,
            this.isAggregate
        );
    }

    /**
     * Return the set of filters excluding any "all" noops.
     */
    excludingAlls(): ArcQLFilters {
        return new ArcQLFilters(
            this.clauses.filter((f: FilterClause) => !f.isAll),
            this.expression,
            this.isAggregate
        );
    }

    toJSON(): JsonObject {
        return {
            'clauses': this.clauses,
            'expression': this.expression
        };
    }
}