import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {SaveAsDialog} from "app/components/SaveAsDialog";
import {AssetType} from "metadata/AssetType";
import {SaveHandler} from "metadata/SaveHandler";
import {Optional} from "common/Optional";
import {StateConverter} from "app/dashboard/StateConverter";
import {ServiceProvider} from "services/ServiceProvider";
import {SessionService} from "services/SessionService";
import {SessionType} from "metadata/session/SessionType";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import styled from "@emotion/styled";
import {FontSizes} from "app/components/StyleVariables";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {DashboardFilterActor} from "app/dashboard/DashboardFilterActor";
import {ArcEngine} from "engine/ArcEngine";
import {TrendWidgetContext} from "app/dashboard/components/TrendsPanel";

interface Props {
    dashboard: ArcDashboard;
    filterActor: DashboardFilterActor;
    engine: ArcEngine;
    widgetContext: TrendWidgetContext;
    onSave: (trend: ArcTrend) => void;
    onCancel: () => void;
}

/**
 * Helper in DashboardBuilder to create a new trend for a widget.
 */
export const TrendCreator: FunctionComponent<Props> = (props: Props) => {

    const [trendForCreation, setTrendForCreation] = useState<Optional<ArcTrend>>(Optional.none());

    useEffect(() => {
        setupTrendCreate();
    }, []);

    const setupTrendCreate = () => {
        // create a session for the current dashboard state
        const dashboardState = StateConverter.toDashboardState(props.filterActor, props.engine.state);
        ServiceProvider.get(SessionService).create(
            props.dashboard.fqn.toString(),
            SessionType.DASHBOARD,
            props.dashboard.id,
            dashboardState.toJSON()
        ).then(resp => resp.match(
            session => {
                setTrendForCreation(
                    Optional.of(
                        ArcTrend.minimal().with({
                            arcQLId: props.widgetContext.actor.arcql.id,
                            filters: props.widgetContext.actor.appliedFilters(),
                            sessionId: session.id,
                            widgetId: props.widgetContext.widgetId,
                        })
                    )
                );
            },
            _ => {
                ServiceProvider.get(NotificationsService).publish(
                    'TrendCreator.setupTrendCreate',
                    NotificationSeverity.ERROR,
                    'Failed to create session for trend.'
                );
            }
        ));
    };

    // no tab change applicable upon save
    const onTabChange = () => {
    };

    return (
        trendForCreation.map( trend =>
            <SaveAsDialog
                asset={trend}
                fqn={
                    props.dashboard.fqn.with({
                        type: AssetType.TREND,
                        name: props.widgetContext.label
                    })
                }
                saveHandler={new SaveHandler(trend, onTabChange, Optional.none())}
                onCancel={props.onCancel}
                onSave={props.onSave}

                disclaimer={
                    <S.SaveAsDisclaimer>
                        <strong>
                            Note: the following active global filters and facets will apply to this saved trend:
                        </strong>
                        <ul>
                            {
                                trend.filters.clauses.map((f, index) =>
                                    <li key={index}>
                                        {`${f.fieldsLabel(props.widgetContext.actor.dataset)} ${f.description(true)}`}
                                    </li>
                                )
                            }
                        </ul>
                    </S.SaveAsDisclaimer>
                }
            />
        ).getOr(<></>)
    );
};

class S {

    static readonly SaveAsDisclaimer = styled.div`
        font-size: ${FontSizes.small};

        ul {
            list-style-type: disc;
            padding-left: 20px;
        }

        li {
            margin-bottom: 4px;
        }
    `;

}