import {JsonObject} from "common/CommonTypes";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FQN} from "common/FQN";
import {FilterClause, FilterValue, RawFilterValue} from "metadata/query/filterclause/FilterClause";
import isEqual from "lodash/isEqual";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";

/**
 * Global filter clause is just a query filter clause with a dataset reference.
 */
export class GlobalLiteralsFilterClause extends LiteralsFilterClause implements GlobalFilterClause {

    static fromJSON(json: JsonObject): GlobalLiteralsFilterClause {
        return new GlobalLiteralsFilterClause(
            json['datasetFullyQualifiedName'],
            json['field'],
            FilterOperator.get(json['operator']),
            json['values']
        );
    }

    public readonly datasetFqn: FQN;

    constructor(
        datasetFullyQualifiedName: string,
        column: string,
        operator: FilterOperator,
        values: RawFilterValue[]
    ) {
        super(column, operator, values, false);

        this.datasetFqn = FQN.parse(datasetFullyQualifiedName);
    }

    equals(other: GlobalFilterClause): boolean {
        return this.datasetFqn.equals(other.datasetFqn)
            && this.column === other.column
            && this.operator === other.operator
            && isEqual(this.values, other.values);
    }

    with(operator: FilterOperator, values: FilterValue[]): GlobalFilterClause {
        return new GlobalLiteralsFilterClause(
            this.datasetFqn.toString(),
            this.column,
            operator,
            values as RawFilterValue[]
        );
    }

    withColumn(datasetFqn: FQN, column: string): GlobalFilterClause {
        return new GlobalLiteralsFilterClause(
            datasetFqn.toString(),
            column,
            this.operator,
            this.values
        );
    }

    toBaseFilterClause(): FilterClause {
        return new LiteralsFilterClause(this.column, this.operator, this.values, false);
    }

    toJSON(): JsonObject {
        return {
            'datasetFullyQualifiedName': this.datasetFqn.toString(),
            ...super.toJSON()
        };
    }
}