import * as React from "react"
import {SVGProps} from "react"

const ComboIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M18.546 4.5a1.455 1.455 0 0 0-1.434 1.702l-2.43 2.025a1.454 1.454 0 0 0-1.301.15l-2.113-1.056a1.455 1.455 0 0 0-2.478-.94 1.455 1.455 0 0 0-.422 1.122l-2.27 1.513a1.455 1.455 0 1 0 .807 1.21l2.27-1.514c.2.099.42.151.643.152.285 0 .564-.085.801-.242l2.113 1.057a1.455 1.455 0 0 0 2.478.94 1.454 1.454 0 0 0 .405-1.275l2.43-2.026a1.455 1.455 0 1 0 .5-2.818Zm-.728 5.818a.728.728 0 0 0-.727.728V20.5H20v-9.454a.728.728 0 0 0-.727-.728h-1.455Zm-8.727 1.455a.728.728 0 0 0-.727.727v8h2.909v-8a.728.728 0 0 0-.727-.727H9.09Zm4.364 1.454a.727.727 0 0 0-.728.727V20.5h2.91v-6.546a.728.728 0 0 0-.728-.727h-1.455Zm-8.728 1.455a.728.728 0 0 0-.727.727V20.5h2.91v-5.09a.728.728 0 0 0-.728-.728H4.727Z"
        />
    </svg>
);
export default ComboIcon;
