import {MutableRefObject, useEffect, useState} from "react";
import debounce from "lodash/debounce";
import {Optional} from "common/Optional";

/**
 * Hook to watch for resizes to a specific component
 */
export const useResize = (
    // component ref to watch for resizes
    component: MutableRefObject<any>,
    // milliseconds to debounce resize events
    debounceTime: number = 1000
): Optional<[number, number]> => {

    const [containerWidth, setContainerWidth] = useState<Optional<[number, number]>>(Optional.none());

    useEffect(() => {
        // debounce resizing since it's expensive to redraw
        const handleResize = debounce(() => {
            if (!component.current) {
                return;
            }

            setContainerWidth(Optional.some(
                [component.current.clientWidth, component.current.clientHeight]
            ));
        }, debounceTime);
        const resizeObserver = new ResizeObserver(() => handleResize());
        resizeObserver.observe(component.current);

        return () => resizeObserver.disconnect();
    }, []);

    return containerWidth;

};
