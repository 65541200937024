import FormControl from "@mui/material/FormControl";
import * as React from "react";
import {ChangeEvent, FunctionComponent} from "react";
import {SlugHelper} from "metadata/SlugHelper";
import {DialogS} from "app/DialogS";
import {Optional} from "common/Optional";
import {FQN} from "common/FQN";
import {FormS} from "app/components/form/FormS";

type Props = {
    account: string;
    project: string;
    name: string;
    fqn: FQN;
    onEdit: (name: string) => void;
    saveErrors: Optional<Map<string, string>>;
}

/**
 * Input for displaying and editing an asset URL.
 */
export const AssetUrlInput: FunctionComponent<Props> = (props: Props) => {
    return <FormControl>
        <FormS.Label id="url-label">URL</FormS.Label>
        <FormS.TextField
            margin="none"
            size="small"
            id="name"
            aria-labelledby="url-label"
            value={props.name}
            error={props.saveErrors.map(errors => errors.has("name")).getOr(false)}
            helperText={props.saveErrors.map(errors => errors.get("name")).getOr(null)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => props.onEdit(SlugHelper.convert(e.target.value, SlugHelper.ASSET_MAX_NAME_LENGTH))}
        />
        <DialogS.FullUrl>
            hyperarc.com
            / {props.account} / {props.project} / {props.fqn.type.name} / <DialogS.SlugText>{props.name}</DialogS.SlugText>
        </DialogS.FullUrl>
    </FormControl>;
};