import {FQN} from "common/FQN";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Service for handling datasets.
 */
export abstract class DatasetV2Service {

    /**
     * Describe the dataset.
     */
    abstract describeDataset(fqn: FQN, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcDataset>>;

    /**
     * Fetch the dataset. (note: not the describe metadata)
     */
    abstract fetch(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>>;

    /**
     * Save the dataset.
     */
    abstract save(fqn: FQN, dataset: DatasetV2, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>>;

    /**
     * Get the table metadata for dataset.
     */
    abstract getTableMetadata(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, RemoteTableMetadata>>;

    /**
     * Upload a file to create or modify a dataset.
     */
    abstract upload(fqn:FQN, file: File, datasetLabel: string, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>>;
}