import {JsonObject} from "common/CommonTypes";

export default class FolderCreateRequest {
    constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        public readonly isPublic: boolean,
    ) {
    }

    toJSON(): JsonObject {
        return {
            name: this.name,
            label: this.label,
            description: this.description,
            isPublic: this.isPublic
        };
    }
}