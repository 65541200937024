import {Enum} from "common/Enum";
import {DragAndDropData} from "common/DragAndDropData";

/**
 * @author zuyezheng
 */
export class QueryDnDSource extends Enum {

    static readonly FIELD = new this('field');
    static readonly METRIC = new this('metric');
    static readonly GROUPING = new this('grouping');
    static readonly FILTER = new this('filter');
    static readonly AGGREGATE_FILTER = new this('aggregateFilter');

    of(name: string): DragAndDropData<QueryDnDSource> {
        return new DragAndDropData<QueryDnDSource>(this, name);
    }

}
QueryDnDSource.finalize();