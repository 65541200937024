import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {DeleteColumnGroup} from "metadata/dashboard/changes/DeleteColumnGroup";
import {ColumnGroupColumn} from "metadata/dashboard/DashboardInteractions";

/**
 * Create an interaction column group.
 *
 * @author zuyezheng
 */
export class CreateColumnGroup implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly label: string,
        public readonly columns: ColumnGroupColumn[]
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(
            metadata.with({
                interactions: metadata.interactions.withColumnGroup(this.label, this.columns)
            }),
            new DeleteColumnGroup(this.label)
        );
    }

    describe(): string {
        return `Created interaction column group ${this.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}