import {KeyboardEvent, useState} from "react";
import {Optional} from "common/Optional";

/**
 * Hook to handle keyboard navigation of a list of items.
 *
 * @author zuyezheng
 */
export const useListNav = (
    numItems: number
): {
    navIndex: Optional<number>,
    resetNavIndex: () => void,
    onNavKeyDown: (
        event: KeyboardEvent,
        onNavSelection: (index: number) => void,
        onPassThroughEnter: () => void
    ) => boolean
} => {

    const [navIndex, setNavIndex] = useState<Optional<number>>(Optional.none);

    const onNavKeyDown = (
        event: KeyboardEvent,
        onNavSelection: (index: number) => void,
        onPassThroughEnter: () => void
    ) => {
        switch (event.key) {
            case 'Escape':
                return false;
            case 'ArrowDown':
                // default to a value if none before
                navIndex.orElse(() => Optional.some(-1))
                    // increment the value and make sure it is in range
                    .map(i => Math.min(i + 1, numItems - 1))
                    // only keep it if it is different from the previous value
                    .filter(i => navIndex.map(j => i !== j).getOr(true))
                    // update the state if necessary
                    .forEach(i => setNavIndex(Optional.some(i)));

                event.preventDefault();
                return true;
            case 'ArrowUp':
                // default to a value if none before
                navIndex.orElse(() => Optional.some(-1))
                    // decrement the value and make sure it is in range
                    .map(i => Math.max(i - 1, 0))
                    // only keep it if it is different from the previous value
                    .filter(i => navIndex.map(j => i !== j).getOr(true))
                    // update the state if necessary
                    .forEach(i => setNavIndex(Optional.some(i)));

                event.preventDefault();
                return true;
            case 'Enter':
                navIndex.forEach(i => {
                    // if there is an arrow selection, find it and trigger a selection
                    onNavSelection(i)
                }).orForEach(() => {
                    // if no arrow selection, do a search
                    onPassThroughEnter();
                });
                return false;
            default:
                return true;
        }
    };

    const resetNavIndex = () => setNavIndex(Optional.none());

    return {navIndex, resetNavIndex, onNavKeyDown};

};