import {VizSelection} from "engine/actor/VizSelection";
import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {Optional} from "common/Optional";
import {AnalyticsType} from "metadata/AnalyticsType";
import {CandlestickData} from "app/visualizations/data/CandlestickData";
import {SelectionEvent} from "app/visualizations/FusionTypes";


/**
 * Config for a candlestick chart that supports discrete selections.
 *
 * @author zuyezheng
 */
export class CandlestickChartConfig extends ChartConfig {

    private data: CandlestickData;

    constructor(response: ArcQLResponse, config: VisualizationConfig) {
        super(response, config);
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    handleDiscreteClick(event: SelectionEvent, originalDataSource: {[key: string]: any}): Optional<string[][]> {
        return Optional.some([
            originalDataSource.dataset[event.datasetIndex].data[event.x].categoryValues
        ]);
    }

    validate(): Optional<string> {
        const measures = this.response.result.columnsByType(new Set([AnalyticsType.MEASURE]));
        if (measures.length < 2) {
            return Optional.some('Requires at least measures for open and close.');
        }
        if (measures.length > 5) {
            return Optional.some('At most 5 measures can be visualized.');
        }
        if (measures.length == 3) {
            return Optional.some('Another measure required for low.');
        }

        this.data = new CandlestickData(
            [measures[0], measures[1]],
            measures.length >= 4 ? Optional.of([measures[2], measures[3]]) : Optional.none(),
            measures.length === 5 ? Optional.of(measures[4]) : Optional.none(),
            this.response.result,
            this.response.arcql.isGrouped()
        );

        return Optional.none();
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        return {
            type: 'candlestick',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    // candlesticks will appropriately size the header unlike other charts
                    ChartConfig.buildConfig(false, false),
                    {
                        bearBorderColor: this.config.theme.threePoint[0],
                        bearFillColor: this.config.theme.threePoint[0],
                        bullBorderColor: this.config.theme.threePoint[2],
                        bullFillColor: this.config.theme.threePoint[2],

                        pYAxisNameFontSize: 12,
                        vYAxisNameFontSize: 12,

                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),

                        xAxisName: this.config.emptyableString('xAxisName').getOr(this.xAxisName(false)),
                        pYAxisName: this.config.emptyableString('pYAxisName').nullable,
                        vYAxisName: this.config.emptyableString('vYAxisName').nullable,

                        showVolumeChart: this.data.hasVolume(),
                        volumeHeightPercent: this.volumeHeightPercent(this.config.get('volumeSize').nullable),
                        setAdaptiveYMin: true,

                        drawCrossLine: false
                    }
                ),
                categories: this.data.categories(size[0]),
                dataset: this.data.dataset(selections)
            }
        };
    }

    private volumeHeightPercent(size: string) {
        switch(size) {
            case 'small':
                return 20;
            case 'medium':
                return 30;
            case 'large':
                return 40;
            default:
                return 30;
        }
    }

}