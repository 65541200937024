import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";

/**
 * @author zuyezheng
 */
export class ScorecardDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [
        new VizStringProperty('title', 'Title'),
        new VizStringProperty('prefix', 'Prefix'),
        new VizStringProperty('suffix', 'Suffix'),
        new VizBooleanProperty('compact', 'Compact Numbers'),
        new VizBooleanProperty('change', 'Change'),
        new VizBooleanProperty('positiveGreen', 'Positive Green'),
        new VizBooleanProperty('spark', 'Spark Line'),
        new VizBooleanProperty('animate', 'Animate')
    ];

    static DEFAULTS = new Map<string, any>([
        ['compact', false],
        ['change', true],
        ['positiveGreen', true],
        ['spark', true],
        ['animate', true]
    ]);

    get properties(): VizProperty<any>[] {
        return ScorecardDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return ScorecardDefinition.DEFAULTS;
    }

}