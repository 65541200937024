import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import md5 from "md5";
import {Optional} from "common/Optional";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {CommonS} from "app/components/CommonS";
import {ExternalStateDelegate} from "app/components/ExternalStateDelegate";

export type Props = {
    encodedState: string | null
    isEmbed: boolean
    delegate: ExternalStateDelegate<any>
    // optional override to double check the state is actually a state
    isEncodedState?: (encodedState: string) => boolean
}

/**
 * Component to manage loading with external state and applying new external state to an existing page.
 *
 * @author zuyezheng
 */
export const ExternalState: FunctionComponent<Props> = (props: Props) => {

    // if it's the first load, confirmation is skipped since assumed loading directly with state won't override any
    // prior state
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    // external state we want to confirm with the user before applying
    const [externalStateToConfirm, setExternalStateToConfirm] = useState<Optional<string>>(Optional.none());
    // md5 hash of last encoded external state so we don't keep applying the same thing
    const [lastExternalState, setLastExternalState] = useState<string>(null);

    useEffect(() => {
        Optional.of(props.encodedState).forEach(encodedState => {
            // if there is a state checker, see if it is actually an encoded state
            if (props.isEncodedState && !props.isEncodedState(encodedState)) {
                return;
            }

            if (isFirstLoad || props.isEmbed) {
                apply(encodedState);
            } else if (md5(encodedState) !== lastExternalState) {
                // ask if we should apply the state if this is not the initial load and the state is different
                setExternalStateToConfirm(Optional.some(encodedState));
            }
        });

        setIsFirstLoad(false);
    }, [props.encodedState, props.isEmbed]);

    const apply = async (encodedState: string) => {
        props.delegate.decodeExternalState(JSON.parse(atob(encodedState)))
            .then(result => {
                result.match(
                    state => {
                        ServiceProvider.get(NotificationsService).publish(
                            'externalState', NotificationSeverity.INFO, props.delegate.applyExternalState(state)
                        );
                    },
                    error => {
                        ServiceProvider.get(NotificationsService).publish(
                            'externalState', NotificationSeverity.WARNING, error
                        );
                    }
                );
                // store the md5 hash of the applied facets so we can skip it if applied again
                setLastExternalState(md5(encodedState));
            })
            .catch(error => {
                ServiceProvider.get(NotificationsService).publish(
                    'externalState', NotificationSeverity.WARNING, error
                );
            });
    };

    const confirmApply = () => {
        apply(externalStateToConfirm.get());
        setExternalStateToConfirm(Optional.none());
    };

    return !props.isEmbed && externalStateToConfirm.isPresent &&
        <Dialog open={true} onClose={() => setExternalStateToConfirm(Optional.none())}>
            <DialogContent>
                <DialogContentText>
                    Do you want to apply external state to your current session?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CommonS.Button onClick={confirmApply}>
                    Yes
                </CommonS.Button>
                <CommonS.Button onClick={() => setExternalStateToConfirm(Optional.none())}>
                    No
                </CommonS.Button>
            </DialogActions>
        </Dialog>;

};
