import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {Optional} from "common/Optional";

export const CLIPPED_DESCRIPTION_LENGTH: number = 60;

export type FilterSetValue = AssetSearchResult;
export type ArcQLFilterValue = {resultColumn: Optional<string>, arcql: AssetSearchResult};
export type RawFilterValue = (string | number);
export type CompositeFilterValue = (string | number)[];
export type FilterValue = FilterSetValue | ArcQLFilterValue | RawFilterValue | CompositeFilterValue;

export interface FilterClause {

    /**
     * If it's a filter by "all".
     */
    get isAll(): boolean

    /**
     * Return all fields used in the filter.
     */
    get fields(): string[]

    /**
     * Values of the filter
     */
    get values(): FilterValue[]

    get type(): FilterClauseType

    /**
     * If the structure of the filter clause is valid, actual filter might still be invalid.
     */
    get isStructureValid(): boolean

    /**
     * If this clause is for the given fields.
     */
    isFor(fields: string[]): boolean

    fieldsLabel(dataset: ArcDataset): string

    /**
     * Get the filter description with optional clipping.
     */
    description(short: boolean): string

    equals(other: FilterClause): boolean

}