import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {DeleteColumnFromColumnGroup} from "metadata/dashboard/changes/DeleteColumnFromColumnGroup";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {Column} from "metadata/Column";

/**
 * Add a column to an existing interaction column group.
 *
 * @author zuyezheng
 */
export class AddColumnToColumnGroup implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly label: string,
        public readonly dataset: ArcDataset,
        public readonly column: Column
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const columnGroup = metadata.interactions.getColumnGroup(this.label).get();

        return Tuple.of(
            metadata.with({
                interactions: metadata.interactions.withColumnGroup(
                    this.label,
                    columnGroup.withColumn(this.dataset.fqn, this.column.name).columns
                )
            }),
            new DeleteColumnFromColumnGroup(this.label, this.dataset, this.column.name)
        );
    }

    describe(): string {
        return `Added column ${this.column.label} to interaction column group ${this.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}