import {Enum} from "common/Enum";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";
import {ComponentType} from "react";
import SingleSelectIcon from "app/components/icons/config/SingleSelectIcon";
import MultiSelectIcon from "app/components/icons/config/MultiSelectIcon";

export class SelectMode extends Enum implements ExplainedSetting {

    static SINGLE = new this(
        'single',
        'Single Select',
        'Allow one selection on this chart at a time.',
        SingleSelectIcon
    );
    static MULTI = new this(
        'multi',
        'Multi Select',
        'Allow multiple selections on this chart. To unselect, simply toggle the selection.',
        MultiSelectIcon
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly explanation: string,
        public readonly icon: ComponentType
    ) {
        super(name);
    }

}

SelectMode.finalize();