import React, {FunctionComponent} from "react";
import {GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Edit from '@mui/icons-material/Edit';
import dayjs from "dayjs";
import styled from "@emotion/styled";
import {DataGridPro} from "@mui/x-data-grid-pro/DataGridPro";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {DatasetListItem} from "metadata/dataset/DatasetListItem";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";

interface Props {
    className?: string;
    connection: RemoteDataSource
    onAccountBreadcrumb?: (account: string) => void
    onSelect: (dataset: DatasetListItem) => void
    onEdit: (dataset: DatasetListItem) => void
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    TABLE: 'table',
    LABEL: 'label',
    CREATED_BY: 'createdBy',
    CREATED_ON: 'createdOn',
    ACTIONS: 'actions',
    RESULT: 'result'
};

/**
 * Expanded connection view in the connection table.
 */
export const ExpandedConnection: FunctionComponent<Props> = (props: Props) => {

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        if (params.field === 'actions') {
            event.stopPropagation();
        }
    };

    const onRowClick = (params: GridRowParams) => {
        props.onSelect(params.row.result as DatasetListItem);
    };

    const columns: GridColDef[] = [{
        'field': FIELD_IDENTIFIERS.TABLE,
        'headerName': 'Imported Table',
        'renderCell': (params: GridRenderCellParams<[string]>) =>
            <S.ImportedTableCell>
                <S.TableName>{params.value}</S.TableName>
                <ArrowForwardIcon className="arrow-right"/>
            </S.ImportedTableCell>,
        'flex': 2
    }, {
        'field': FIELD_IDENTIFIERS.LABEL,
        'headerName': 'HyperArc Dataset',
        'flex': 2
    }, {
        'field': FIELD_IDENTIFIERS.CREATED_BY,
        'headerName': 'Created By',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<string>) =>
            <AccountBreadCrumbInResultRow
                text={params.value}
                onClick={() => props?.onAccountBreadcrumb(params.value)}
            />
    }, {
        'field': FIELD_IDENTIFIERS.CREATED_ON,
        'headerName': 'Date Created',
        'flex': 1
    }, {
        'field': FIELD_IDENTIFIERS.ACTIONS,
        'headerName': '',
        'width': 30,
        'renderCell': (params: GridRenderCellParams) =>
            <S.Edit onClick={() => {
                props.onEdit(params.row.result as DatasetListItem);
            }}/>
    }];

    return <div className={props.className}>
        <S.ResultsTable
            // force re-render if number of datasets change
            key={`${props.connection.id}-${props.connection.datasets.length}`}
            columns={columns}
            rows={
                props.connection.datasets
                    .map(dataset => ({
                        [FIELD_IDENTIFIERS.ID]: dataset.id,
                        [FIELD_IDENTIFIERS.TABLE]: dataset.config.fullTableName,
                        [FIELD_IDENTIFIERS.LABEL]: dataset.label,
                        [FIELD_IDENTIFIERS.CREATED_BY]: dataset.createdBy,
                        [FIELD_IDENTIFIERS.CREATED_ON]: dayjs(dataset.createdOn).format("MMM D, YYYY"),
                        [FIELD_IDENTIFIERS.RESULT]: dataset
                    }))
            }
            onCellClick={handleCellClick}
            onRowClick={onRowClick}

            pageSize={5}
            // reduce the default row height by a smidge, default is 52
            rowHeight={40}
            autoHeight
            density="standard"

            disableSelectionOnClick
            disableColumnMenu
            hideFooter
        />
    </div>;
};

class S {
    static readonly ResultsTable = styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-columnSeparator {
            display: none;
        }
    `;

    static readonly ImportedTableCell = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .arrow-right {
            opacity: 0.5;
            margin-left: auto;
        }
    `;

    static readonly TableName = styled.div`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

    static readonly Edit = styled(Edit)`
        height: 20px;
        width: 20px;

        :hover {
            cursor: pointer;
        }
    `;
}
