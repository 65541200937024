import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {Optional} from "common/Optional";

/**
 * Bundles state for an actor.
 *
 * @author zuyezheng
 */
export class LoadStateMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.LOAD_STATE;

    constructor(
        // who the state is for
        public readonly actorId: string,
        // all existing stateful messages
        public readonly statefulMessages: ArcMessage[]
    ) { }

}

export class LoadStateEventFilter implements ArcEventFilter {

    constructor(
        public readonly actorId: string
    ) { }

    of(event: ArcEvent): boolean {
        return this.filter(event).map(() => true).getOr(false);
    }

    filter(event: ArcEvent): Optional<LoadStateMessage> {
        return Optional.ofType(event.message, LoadStateMessage)
            .filter(message => message.actorId === this.actorId);
    }

}