import * as React from "react";
import {FunctionComponent, useRef} from "react";
import {ValueFormatter} from "common/ValueFormatter";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Divider, IconButton} from "@mui/material";
import Popover from "@mui/material/Popover";
import {DatasetIcon} from "app/query/components/DatasetIcon";
import {ArcDatasetStatusMetadata} from "metadata/dataset/ArcDatasetStatusMetadata";
import {Column} from "metadata/Column";
import {DatasetStatus} from "metadata/dataset/DatasetStatus";
import {ChainInfo} from "app/query/components/ChainInfo";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {Optional} from "semantic-abi/dist/src/common/Optional";

type Props = {
    className?: string

    datasetLabel: string
    columnFields: Column[]
    status: DatasetStatus
}

/**
 * Popover to show dataset information (specifically its status)
 */
export const DatasetInfoPopover: FunctionComponent<Props> = (props: Props) => {

    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const buildPopover = () => {
        if (!open) {
            return;
        }

        return <Popover
            id="mouse-over-popover"
            open={true}
            anchorEl={anchorRef.current}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => setOpen(false)}
        >
            <S.Status>
                <S.InfoBody>
                    <DatasetIcon isStreaming={props.status.metadata.isStreamable} size={16}/>
                    {props.datasetLabel}
                </S.InfoBody>
                <Divider/>
                <S.Table>
                    <tbody>
                    <tr>
                        <S.ParameterCell>Rows</S.ParameterCell>
                        <S.TableValue>{ValueFormatter.compactNumber(props.status.rowCount)}</S.TableValue>
                    </tr>
                    <tr>
                        <td>Columns</td>
                        <S.TableValue>{props.columnFields.length}</S.TableValue>
                    </tr>
                    </tbody>
                </S.Table>
                {
                    Optional.ofType(props.status.metadata, ArcDatasetStatusMetadata).map(metadata =>
                        metadata.sources.map(chain =>
                            <ChainInfo
                                key={chain.name}
                                chain={chain}
                                columnFields={props.columnFields}
                                statusMetadata={props.status.metadata as ArcDatasetStatusMetadata}
                            />
                        )
                    ).nullable
                }
            </S.Status>
        </Popover>;
    };

    return <div ref={anchorRef} className={props.className}>
        <IconButton onClick={() => setOpen(true)}>
            <InfoOutlinedIcon fontSize='small'/>
        </IconButton>
        {buildPopover()}
    </div>;

};

const S = {

    InfoBody: styled.div`
        padding: 4px;
        display: flex;
        gap: 3px;
        align-items: center;
        color: ${Colors.textPrimary};
        min-width: 300px;
    `,

    Status: styled.div`
        display: flex;
        flex-direction: column;
        padding: 10px 10px 16px;
        gap: 5px;
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
        max-height: 600px;
    `,

    ParameterCell: styled.td`
        width: 50%;
    `,

    Table: styled.table`
        width: 100%;
    `,

    TableValue: styled.td`
        color: ${Colors.textPrimary};
        display: flex;
        flex-direction: row;
        gap: 5px;
    `,

    PerChainTable: styled.div`
        margin-top: 10px;
    `,

    DatasetLabel: styled.div`
        display: flex;
        gap: 3px;
        align-items: center;
    `

};
