import {Enum} from "common/Enum";

export class DataSuperType extends Enum {

    static NONE = new this('none', "None");
    static STAR = new this('star', "Star");
    static TIMESTAMP = new this('timestamp', "Timestamp");
    static BOOLEAN = new this('boolean', "Boolean");
    static STRING = new this('string', "String");
    static NUMBER = new this('number', "Number");

    constructor(
        name: string,
        public readonly tooltipText: string
    ) {
        super(name);
    }
}

DataSuperType.finalize();

export class DataType extends Enum {

    // special type for * as in count(*)
    static STAR = new this('star', DataSuperType.STAR);
    static TIMESTAMP = new this('timestamp', DataSuperType.TIMESTAMP);
    static BOOLEAN = new this('boolean', DataSuperType.BOOLEAN);
    static STRING = new this('string', DataSuperType.STRING);
    static BYTE = new this('byte', DataSuperType.NUMBER);
    static SHORT = new this('short', DataSuperType.NUMBER);
    static LONG = new this('long', DataSuperType.NUMBER);
    static INTEGER = new this('integer', DataSuperType.NUMBER);
    static FLOAT = new this('float', DataSuperType.NUMBER);
    static DOUBLE = new this('double', DataSuperType.NUMBER);
    static DECIMAL = new this('decimal', DataSuperType.NUMBER);

    private static INTEGER_TYPES: Set<DataType> = new Set([DataType.BYTE, DataType.SHORT, DataType.LONG, DataType.INTEGER]);
    private static DECIMAL_TYPES: Set<DataType> = new Set([DataType.FLOAT, DataType.DOUBLE, DataType.DECIMAL]);

    constructor(
        name: string,
        public readonly superType: DataSuperType
    ) {
        super(name);
    }

    get isInteger(): boolean {
        return DataType.INTEGER_TYPES.has(this);
    }

    get isDecimal(): boolean {
        return DataType.DECIMAL_TYPES.has(this);
    }

}

DataType.finalize();
