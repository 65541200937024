import {Enum} from "common/Enum";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";

export class DimensionFilterMode extends Enum {

    static readonly VALUES = new this(
        'values',
        "Filter",
        FilterClauseType.SINGLE,
        [
            FilterOperator.IN,
            FilterOperator.NOT_IN,
            FilterOperator.EQUAL,
            FilterOperator.TERM,
            FilterOperator.EXPRESSION
        ])
    ;
    static readonly FILTER_SET = new this(
        'filterSet',
        "Filter Sets",
        FilterClauseType.FILTER_SET,
        [FilterOperator.IN, FilterOperator.NOT_IN]
    );
    static readonly ARCQL = new this(
        'arcql',
        "Dynamic Filter Sets",
        FilterClauseType.ARCQL,
        [FilterOperator.IN, FilterOperator.NOT_IN]
    );

    static from(clauseType: FilterClauseType): DimensionFilterMode {
        switch (clauseType) {
            case FilterClauseType.SINGLE:
                return this.VALUES;
            case FilterClauseType.FILTER_SET:
                return this.FILTER_SET;
            case FilterClauseType.ARCQL:
                return this.ARCQL;
            default:
                throw new Error(`Unsupported filter clause type: ${clauseType}`);
        }
    }

    constructor(
        name: string,
        public readonly label: string,
        public readonly clauseType: FilterClauseType,
        public readonly operators: FilterOperator[]
    ) {
        super(name);
    }
}

DimensionFilterMode.finalize();