/**
 * Translate rejected call promises (such as client aborts) into rejected responses to merge the error handling paths.
 *
 * @author zuyezheng
 */
export class RejectedResponse implements Response {

    readonly status = -1;
    readonly ok = false;

    readonly body: ReadableStream<Uint8Array> | null = null;
    readonly bodyUsed: boolean = false;
    readonly headers: Headers = null;
    readonly redirected: boolean = false;
    readonly type: ResponseType = null;

    constructor(
        public readonly url: string = null,
        public readonly statusText: string = 'rejected call response'
    ) {}

    arrayBuffer(): Promise<ArrayBuffer> {
        return Promise.reject(this.statusText);
    }

    blob(): Promise<Blob> {
        return Promise.reject(this.statusText);
    }

    clone(): Response {
        return undefined;
    }

    formData(): Promise<FormData> {
        return Promise.reject(this.statusText);
    }

    json(): Promise<any> {
        return this.text();
    }

    text(): Promise<string> {
        return Promise.reject(this.statusText);
    }

}