import {DatasetV2} from "metadata/dataset/DatasetV2";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import {DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {RemoteTable} from "metadata/connections/RemoteItem";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {OrgService} from "services/OrgService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {RemoteDatasetConfig} from "metadata/dataset/RemoteDatasetConfig";
import {DatasetV2Service} from "services/DatasetV2Service";
import {DatasetType} from "metadata/dataset/DatasetType";
import {HyperArcConnection} from "metadata/connections/HyperArcConnection";

/**
 * Helper to load the necessary resources to build a remote dataset V2
 */
export class DatasetLoader {

    /**
     * Figure out how to load or create a dataset for a given fqn.
     */
    static async load(
        assetFqn: FQN,
        context: Map<string, any>,
        signal?: AbortSignal
    ): Promise<Optional<LoadResult>> {
        const isNew: boolean = context.get('isNew') as boolean || false;
        if (isNew) {
            return DatasetLoader.buildNew(context, signal);
        } else {
            return DatasetLoader.loadExisting(assetFqn, signal);
        }
    }

    // note: for now, we only support building new datasets from remote tables. Personal datasets will be automatically
    // created from backend when user uploads their respective files
    static async buildNew(context: Map<string, any>, signal: AbortSignal): Promise<Optional<LoadResult>> {
        const remoteTable: RemoteTable = context.get('remoteTable') as RemoteTable;
        const dataSource: RemoteDataSource = context.get('connection') as RemoteDataSource;
        // Fetch the remote table and initialize an empty dataset
        const remoteTableMetadata: Either<ErrorResponse, RemoteTableMetadata> = await DatasetLoader.fetchRemoteTable(dataSource, remoteTable);
        if (remoteTableMetadata.isLeft) {
            ServiceProvider.get(NotificationsService).publish(
                'DatasetV2Builder.getRemoteTable', NotificationSeverity.ERROR, 'Could not fetch table metadata from connection.');
            return Optional.none();
        }

        return Optional.some(
            new LoadResult(
                dataSource,
                remoteTableMetadata.rightOrThrow(),
                DatasetV2BuilderState.fromNewTable(remoteTableMetadata.rightOrThrow(), dataSource),
                new DatasetV2()
            )
        );
    }

    static async loadExisting(assetFqn: FQN, signal: AbortSignal): Promise<Optional<LoadResult>> {
        try {
            // Loading an existing dataset requires first loading the data, then the connection (if applicable), then the
            // underlying remote table metadata
            const dataset: DatasetV2 = (await ServiceProvider.get(DatasetV2Service)
                .fetch(assetFqn, signal))
                .rightOrThrow((_) => new Error('Could not fetch dataset.'));

            let connection: RemoteDataSource;
            // if personal dataset, build the fake hyperarc connection
            if (dataset.config.type === DatasetType.PERSONAL) {
                connection = HyperArcConnection.minimal();
            } else {
                // else all other v2 datasets are assumed remote datasets with a reference to a connection
                const remoteConfig: RemoteDatasetConfig = dataset.config as RemoteDatasetConfig;
                connection = (await ServiceProvider.get(OrgService)
                    .getConnection(assetFqn.account, remoteConfig.connectionId))
                    .rightOrThrow((_) => new Error('Could not fetch remote connection.'));
            }

            const remoteTableMetadata: RemoteTableMetadata = (await ServiceProvider.get(DatasetV2Service).getTableMetadata(
                assetFqn, signal
            )).rightOrThrow((_) => new Error('Could not fetch table metadata for dataset.'));

            return Optional.some(
                new LoadResult(
                    connection,
                    remoteTableMetadata,
                    DatasetV2BuilderState.fromExistingDataset(remoteTableMetadata, dataset),
                    dataset
                )
            );
        } catch (e) {
            ServiceProvider.get(NotificationsService).publish(
                'DatasetV2Builder.init', NotificationSeverity.ERROR, e.message);

            return Optional.none();
        }
    }

    private static fetchRemoteTable(connection: RemoteDataSource, remoteTable: RemoteTable): Promise<Either<ErrorResponse, RemoteTableMetadata>> {
        return ServiceProvider.get(OrgService)
            .getRemoteTable(connection.organizationName, connection.id, remoteTable.databaseName, remoteTable.schemaName, remoteTable.name);
    }
}

export class LoadResult {
    constructor(
        public readonly dataSource: RemoteDataSource,
        public readonly tableMetadata: RemoteTableMetadata,
        public readonly datasetBuilderState: DatasetV2BuilderState,
        public readonly dataset: DatasetV2
    ) {
    }
}