import * as React from "react";
import FormatShapesOutlinedIcon from "@mui/icons-material/FormatShapesOutlined";
import {DashboardWidgetProps} from "app/dashboard/widgets/WidgetContainer";
import {MarkdownWidget} from "app/dashboard/widgets/MarkdownWidget";
import {MarkdownMetadata} from "metadata/dashboard/widgets/MarkdownMetadata";
import {WidgetComponentFactory} from "app/dashboard/widgets/WidgetComponentFactory";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {Optional} from "common/Optional";
import {DashboardQuery} from "metadata/dashboard/DashboardQueries";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ModifyWidgetDelegate} from "app/query/ModifyWidgetDelegate";
import {WidgetEditor} from "app/dashboard/widgets/WidgetEditor";
import {MarkdownWidgetConfig} from "metadata/dashboard/widgets/config/MarkdownWidgetConfig";
import {WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";
import {ReactNode} from "react";

export class MarkdownWidgetFactory implements WidgetComponentFactory {

    icon(): React.ReactNode {
        return <FormatShapesOutlinedIcon/>;
    }

    view(props: DashboardWidgetProps): React.ReactNode {
        return <MarkdownWidget config={(props.widgetMetadata as MarkdownMetadata).widgetConfig()}/>;
    }

    get matchWidgetDimensions(): boolean {
        return true;
    }

    inlineEditor(
        widgetMetadata: WidgetMetadataBound,
        mergedWidgetConfig: WidgetConfig,
        queryMetadata: Optional<DashboardQuery>,
        dashboard: ArcDashboard,
        delegate: ModifyWidgetDelegate
    ): ReactNode {
        return <WidgetEditor
            onConfigChange={
                (configKey, configJson) => {
                    delegate.modifyWidgetConfig(widgetMetadata.id, new Map([[configKey, configJson]]));
                }
            }
            partsMap={mergedWidgetConfig.parts}
            sortedParts={mergedWidgetConfig.partsByKeys(
                [MarkdownWidgetConfig.TEXT_CONFIG_KEY, MarkdownWidgetConfig.CONTAINER_CONFIG_KEY, MarkdownWidgetConfig.MARKDOWN_CONFIG_KEY]
            )}
        />;
    }

}
