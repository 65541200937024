import {Enum} from "common/Enum";
import {ComponentType} from "react";

import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import BorderAll from "@mui/icons-material/BorderAll";
import {Border, PLACEHOLDER_TEXT} from "metadata/dashboard/widgets/config/Border";

/**
 * Set<Border> conversion to be used as a single selection in the UI.
 */
export class BorderVisibility extends Enum {

    static readonly NONE = new this(
        'none',
        'None',
        BorderClearIcon,
        new Set<Border>()
    );

    static readonly ALL = new this(
        'all',
        'All Borders',
        BorderAll,
        new Set<Border>([Border.TOP, Border.RIGHT, Border.BOTTOM, Border.LEFT])
    );

    static readonly TOP = new this(
        'top',
        'Top Border',
        BorderTopIcon,
        new Set<Border>([Border.TOP])
    );

    static readonly RIGHT = new this(
        'right',
        'Right Border',
        BorderRightIcon,
        new Set<Border>([Border.RIGHT])
    );

    static readonly BOTTOM = new this(
        'bottom',
        'Bottom Border',
        BorderBottomIcon,
        new Set<Border>([Border.BOTTOM])
    );

    static readonly LEFT = new this(
        'left',
        'Left Border',
        BorderLeftIcon,
        new Set<Border>([Border.LEFT])
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType,
        public readonly borders: Set<Border>
    ) {
        super(name);
    }

    /**
     * Aggregate all styling from base BorderVisibility set and appropriately replace placeholder text
     */
    styling(borderStyling: string): { [prop: string]: string } {
        const styling: { [prop: string]: string } = {};
        for (const borderVisibility of this.borders) {
            for (const [prop, value] of Object.entries(borderVisibility.styling)) {
                styling[prop] = value.replace(PLACEHOLDER_TEXT, borderStyling);
            }
        }
        return styling;
    }

    /**
     * Converts from Set<Border> to this appropriate enum for user selection and label.
     * ex: empty set means 'None'
     */
    public static fromSet(bordersSet: Set<Border>): BorderVisibility {
        // match by rawSet
        for (const bv of this.enums<BorderVisibility>()) {
            if (bv.borders.size === bordersSet.size && [...bv.borders].every((b) => bordersSet.has(b))) {
                return bv;
            }
        }
        throw new Error(`No set conversion found for ${JSON.stringify(Array.from(bordersSet.values()))}`);
    }
}

BorderVisibility.finalize();
