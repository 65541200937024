import {ArcQLFieldType} from "metadata/query/ArcQLFieldType";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {Enum} from "common/Enum";
import {Optional} from "common/Optional";
import {ColumnField} from "metadata/query/ArcQLField";
import {DataSuperType} from "metadata/DataType";

export class MeasureField extends ColumnField {

    constructor(
        public readonly field: string,
        public readonly operator: MeasureOperator,
        as: string,
        public readonly cumulative: boolean = false
    ) {
        super(ArcQLFieldType.MEASURE, as);
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        return AnalyticsType.MEASURE;
    }

    with({operator, as, cumulative}: {
        operator?: MeasureOperator,
        as?: string
        cumulative?: boolean
    }): MeasureField {
        return new MeasureField(
            this.field,
            operator == null ? this.operator : operator,
            as == null ? this.as : as,
            cumulative == null ? this.cumulative : cumulative
        );
    }

    get tooltip(): string {
        return `${this.operator.name}(${this.field})`;
    }

    get prefix(): string {
        return this.operator.abbreviation;
    }

}

export class MeasureOperator extends Enum {

    static AVG = new this(
        'avg',
        'Average',
        'AVG',
        [DataSuperType.NUMBER, DataSuperType.BOOLEAN]
    );
    static SUM = new this(
        'sum',
        'Sum',
        'SUM',
        [DataSuperType.NUMBER, DataSuperType.BOOLEAN]
    );
    static MIN = new this(
        'min',
        'Min',
        'MIN',
        [DataSuperType.NUMBER, DataSuperType.BOOLEAN]
    );
    static MAX = new this(
        'max',
        'Max',
        'MAX',
        [DataSuperType.NUMBER, DataSuperType.BOOLEAN]
    );
    static MODE = new this(
        'mode',
        'Mode',
        'MDE',
        [DataSuperType.NUMBER]
    );
    static MEDIAN_ESTIMATE = new this(
        'median_estimate',
        'Median Approximate',
        'MDA',
        [DataSuperType.NUMBER]
    );
    static MEDIAN = new this(
        'median',
        'Median',
        'MD',
        [DataSuperType.NUMBER]
    );
    static STDDEV_POP = new this(
        'stddev_pop',
        'Standard Deviation (Population)',
        'SDP',
        [DataSuperType.NUMBER],
        'Std Dev'
    );
    static STDDEV_SAMP = new this(
        'stddev_samp',
        'Standard Deviation (Sample)',
        'SDS',
        [DataSuperType.NUMBER],
        'Std Dev'
    );
    static VAR_POP = new this(
        'var_pop',
        'Variance (Population)',
        'VRP',
        [DataSuperType.NUMBER],
        'Variance'
    );
    static VAR_SAMP = new this(
        'var_samp',
        'Variance (Sample)',
        'VRS',
        [DataSuperType.NUMBER],
        'Variance'
    );
    static MINMAXRANGE = new this(
        'minmaxrange',
        'Min Max Range',
        'MMR',
        [DataSuperType.NUMBER],
        'Range'
    );

    static COUNT = new this(
        'count',
        'Count',
        'CT',
        []
    );

    static DISTINCT_HLL = new this(
        'distinct_hll',
        'Distinct Approximate',
        'DCA',
        [DataSuperType.NUMBER, DataSuperType.STRING, DataSuperType.BOOLEAN],
        'Distinct'
    );
    static DISTINCT = new this(
        'distinct',
        'Distinct',
        'DC',
        [DataSuperType.NUMBER, DataSuperType.STRING, DataSuperType.BOOLEAN]
    );

    public readonly dataSuperTypes: Optional<Set<DataSuperType>>;

    constructor(
        name: string,
        public readonly label: string,
        // up to 3 character abbreviation
        public readonly abbreviation: string,
        // data types the operator supports
        dataSuperTypes: DataSuperType[],
        // prefix to use when building a field label, null to use the label
        public readonly prefix: string = label
    ) {
        super(name);

        this.dataSuperTypes = dataSuperTypes.length === 0 ? Optional.none() : Optional.some(new Set(dataSuperTypes));
    }

    /**
     * Construct a default display label to be used as a projection
     */
    defaultLabel(fieldLabel: string) {
        return `${this.prefix} ${fieldLabel}`;
    }

    /**
     * Filter the operators down to what's supported for the given column type.
     */
    static forDataSuperType(dataSuperType: DataSuperType): MeasureOperator[] {
        return this.enums<MeasureOperator>()
            .filter(operator => operator.dataSuperTypes.map(
                types => types.has(dataSuperType)
            ).getOr(true));
    }

}

MeasureOperator.finalize();