import {useHotkeys} from "react-hotkeys-hook";
import {TabType} from "app/TabType";
import {TabPath} from "app/TabPath";
import {useState} from "react";
import {ScopedSearch} from "app/home/ScopedSearch";
import {Optional} from "common/Optional";
import {ScopedSearchNone} from "app/home/ScopedSearchNone";
import {ScopedSearchAccount} from "app/home/ScopedSearchAccount";

/**
 * Hotkey to show global search from any part of the app.
 *
 * @author zuyezheng
 */
export const useSearchHotkey = (selectedPath: TabPath): [
    Optional<ScopedSearch>,
    (showSearch: Optional<ScopedSearch>) => void
] => {
    const [showSearch, setShowSearch] = useState<Optional<ScopedSearch>>(Optional.none());

    useHotkeys(['mod+k', 'shift+mod+k'], (keyboardEvent: KeyboardEvent) => {
        // home handles its own search hotkey
        if (selectedPath.type === TabType.HOME) {
            return;
        }

        if (showSearch.isPresent) {
            setShowSearch(Optional.none());
        } else {
            if (keyboardEvent.shiftKey) {
                // if shift is also used, scope to account if possible
                setShowSearch(Optional.of(
                    selectedPath.possibleFqn
                        .map<ScopedSearch>(fqn => new ScopedSearchAccount(fqn.account))
                        .getOrElse(() => new ScopedSearchNone())
                ));
            } else {
                // search all
                setShowSearch(Optional.of(new ScopedSearchNone()));
            }

        }

        // prevent browser defaults that might exist on different platforms
        keyboardEvent.preventDefault();
    }, {keydown: true, keyup: true});

    return [showSearch, setShowSearch];
};