import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {TypedEventFilter} from "engine/ArcEventFilter";
import { ArcEvent } from "engine/ArcEvent";
import { Optional } from "common/Optional";

/**
 * Clear selections for the columns in the given dataset.
 *
 * @author zuyezheng
 */
export class ClearSelectionsMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.CLEAR_SELECTION;

    constructor(
        public readonly datasetFqn: string,
        public readonly columns: string[]
    ) { }

}

export class ClearSelectionsEventFilter extends TypedEventFilter<ClearSelectionsMessage> {

    constructor(
        // which dataset to listen for clear selections
        private readonly datasetFqn?: string
    ) {
        super(ClearSelectionsMessage);
    }

    filter(event: ArcEvent): Optional<ClearSelectionsMessage> {
        return super.filter(event)
            .filter(m => this.datasetFqn == null || this.datasetFqn === m.datasetFqn);
    }

}