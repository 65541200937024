import * as React from "react";
import {DashboardWidgetProps} from "app/dashboard/widgets/WidgetContainer";
import {WidgetComponentFactory} from "app/dashboard/widgets/WidgetComponentFactory";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {Optional} from "common/Optional";
import {DashboardQuery} from "metadata/dashboard/DashboardQueries";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ModifyWidgetDelegate} from "app/query/ModifyWidgetDelegate";
import {WidgetEditor} from "app/dashboard/widgets/WidgetEditor";
import {ImageMetadata} from "metadata/dashboard/widgets/ImageMetadata";
import {ImageWidgetConfig} from "metadata/dashboard/widgets/config/ImageWidgetConfig";
import {ImageWidget} from "app/dashboard/widgets/ImageWidget";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import {WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";
import {ReactNode} from "react";

export class ImageWidgetFactory implements WidgetComponentFactory {

    icon(): React.ReactNode {
        return <ImageOutlinedIcon/>;
    }

    view(props: DashboardWidgetProps): React.ReactNode {
        return <ImageWidget config={(props.widgetMetadata as ImageMetadata).widgetConfig()}/>;
    }

    get matchWidgetDimensions(): boolean {
        return false;
    }

    inlineEditor(
        widgetMetadata: WidgetMetadataBound,
        mergedWidgetConfig: WidgetConfig,
        queryMetadata: Optional<DashboardQuery>,
        dashboard: ArcDashboard,
        delegate: ModifyWidgetDelegate
    ): ReactNode {
        return <WidgetEditor
            onConfigChange={
                (configKey, configJson) => {
                    delegate.modifyWidgetConfig(widgetMetadata.id, new Map([[configKey, configJson]]));
                }
            }
            partsMap={mergedWidgetConfig.parts}
            sortedParts={mergedWidgetConfig.partsByKeys(
                [ImageWidgetConfig.IMAGE_CONFIG_KEY, ImageWidgetConfig.CONTAINER_CONFIG_KEY]
            )}
        />;
    }

}