import {ProjectSearchResultsTable} from "app/components/search/ProjectSearchResultsTable";
import React, {FunctionComponent} from "react";
import BrowseViewProps from "app/home/browse/BrowseViewProps";
import {S} from "app/home/browse/BrowseViewS";
import {ScopedSearchAccount} from "app/home/ScopedSearchAccount";
import {CreateProjectDialog} from "app/components/project/CreateProjectDialog";
import AddIcon from '@mui/icons-material/Add';
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";

export const BrowseAccountView: FunctionComponent<
    BrowseViewProps & { scopedSearch: ScopedSearchAccount }
> = (props) => {
    // safeguard if incorrect view is set and scopedSearch != account
    const hideCreateProjectModal = () => {
        props.setModalsVisibility({
            ...props.modalsVisibility,
            showCreateProjectModal: false
        });
    };
    return props.scopedSearch.account.map(account => {
            const currentUserHasWriteAccess =
                props.userContext.memberships.some(membership => membership.name === account && membership.includesAdmin())
                || props.userContext.user.map(u => u.username === account).getOr(false);

            return <>
                <S.HeaderRow>
                    <S.ScopedHeader.Header>
                        <S.ScopedHeader.Owner>@{account}</S.ScopedHeader.Owner>
                    </S.ScopedHeader.Header>
                    {currentUserHasWriteAccess &&
                        <S.CreateProjectButton
                            variant="contained"
                            startIcon={<AddIcon/>}
                            onClick={() => props.setModalsVisibility({
                                ...props.modalsVisibility,
                                showCreateProjectModal: true
                            })}
                        >
                            New Project
                        </S.CreateProjectButton>}
                </S.HeaderRow>
                <ProjectSearchResultsTable
                    searchParams={props.folderSearchParams}
                    onSelect={props.onProjectSelect}
                    onSearch={props.onSearchProjects}
                    onAccountBreadcrumb={props.onAccountBreadcrumb}
                    showActions={currentUserHasWriteAccess}
                />
                <S.ResultsHeader>{`Assets in Projects of @${account}`}</S.ResultsHeader>
                <AssetSearchResultsTable
                    searchParams={props.assetSearchParams}
                    onSelect={props.onAssetSelectSearchResult}
                    rowType={SearchResultsRowType.TABLE}
                    onSearch={props.onSearchAssets}
                />
                {props.modalsVisibility.showCreateProjectModal &&
                    <CreateProjectDialog
                        account={account}
                        onCancel={hideCreateProjectModal}
                        onCreate={(createdProject) => {
                            props.onProjectSelect(createdProject);
                            hideCreateProjectModal();
                        }}
                    />
                }
            </>;
        }
    ).getOr(<> </>);
};