import { Optional } from 'common/Optional';
import {HyperGraphNode} from 'metadata/hypergraph/HyperGraphNode';
import {JsonObject} from 'common/CommonTypes';
import { FQN } from 'common/FQN';

/**
 * Results from a search.
 *
 * @author zuyezheng
 */
export class HyperGraphAnswer {

    constructor(
        public readonly answer: string,
        public readonly nodes: AnswerNode[]
    ) { }

    get optional(): Optional<HyperGraphAnswer> {
        if (this.nodes.length === 0) {
            return Optional.none();
        } else {
            return Optional.of(this);
        }
    }

    static empty(): HyperGraphAnswer {
        return new HyperGraphAnswer('', []);
    }

}


export type AnswerNode = {

    // the node
    node: HyperGraphNode,
    // why the node is relevant
    why: string

}

/**
 * JSON response of answer API.
 */
export type AnswerResponse = {

    answer: string,
    primary: string,
    secondaries: string[]

}

/**
 * Request for answers.
 */
export type AnswerWithNodesRequest = {

    question: string,
    primary: SupportingNode,
    secondaries: SupportingNode[]
    datasetFqn: FQN

}

export type SupportingNode = {

    label: string,
    embeddableContent: string,
    arcql: JsonObject,
    sql: string,
    rows?: JsonObject[]

}

