import {JsonObject} from "common/CommonTypes";
import {ArcQLFilterValue, FilterClause, FilterSetValue, RawFilterValue} from "metadata/query/filterclause/FilterClause";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {References} from "metadata/References";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import ArcQLFilterClause from "metadata/query/filterclause/ArcQLFilterClause";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {CompositeFilterClause} from "metadata/query/filterclause/CompositeFilterClause";
import {FilterSetFilterClause} from "metadata/query/filterclause/FilterSetFilterClause";

export class FilterClauseFactory {

    /**
     * JSON deserialization may come from different sources such as from the API call or from local storage.
     * Additionally, some extra attributes are needed for proper deserialization logic based on the type.
     */
    static fromJSON(json: JsonObject, isAggregate: boolean, references: References): FilterClause {
        const type: FilterClauseType = FilterClauseType.get(json['type']);
        switch (type) {
            case FilterClauseType.SINGLE:
                return LiteralsFilterClause.fromJSON(json, isAggregate);
            case FilterClauseType.COMPOSITE:
                return CompositeFilterClause.fromJSON(json);
            case FilterClauseType.FILTER_SET:
                return FilterSetFilterClause.fromJSON(json, references);
            case FilterClauseType.ARCQL:
                return ArcQLFilterClause.fromJSON(json, references);
            default:
                throw new Error(`Unsupported filter clause type: ${type}`);
        }
    }

    /**
     * Given an existing single field FilterClause and a pending editor FilterChange, create the new FilterClause instance.
     */
    static fromFilterChange(existingClause: SingleFieldFilterClause, change: EditorFilterChange, isAggregate: boolean): FilterClause {
        // default case of filter by all, users never populated or selected values correctly
        if (change.values.length === 0) {
            return new LiteralsFilterClause(
                existingClause.column,
                FilterOperator.IN,
                [],
                false
            );
        }
        switch (change.type) {
            case FilterClauseType.SINGLE:
                return new LiteralsFilterClause(
                    existingClause.column,
                    change.operator,
                    change.values as RawFilterValue[],
                    isAggregate
                );
            case FilterClauseType.FILTER_SET:
                return FilterSetFilterClause.fromFilterSetAssetAndOperator(
                    existingClause.column,
                    change.values[0] as FilterSetValue,
                    change.operator
                );
            case FilterClauseType.ARCQL:
                return ArcQLFilterClause.fromArcQLFilter(
                    existingClause.column,
                    change.values[0] as ArcQLFilterValue,
                    change.operator
                );
            default:
                throw new Error(`Unsupported filter clause type: ${change.type}`);
        }
    }

}
