import * as React from "react";
import {FunctionComponent, useState} from "react";
import {HorizontalAlignment} from "metadata/dashboard/widgets/config/HorizontalAlignment";
import {IconButton, Popover} from "@mui/material";
import {ColorResult, SketchPicker} from "react-color";
import styled from "@emotion/styled";
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatTextColorIcon from "app/components/icons/config/FormatTextColorIcon";
import {VerticalAlignment} from "metadata/dashboard/widgets/config/VerticalAlignment";
import {StyleEnumButtonMenu} from "app/dashboard/widgets/config/StyleEnumButtonMenu";
import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";
import {TextConfigPart} from "metadata/dashboard/widgets/config/text/TextConfigPart";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {ColorPickerButtonIcon} from "app/dashboard/widgets/config/ColorPickerButtonIcon";
import Tooltip from "@mui/material/Tooltip";

interface EditorProps extends ConfigEditorProps<TextConfigPart> {

}

/**
 * Editor for text-based widgets - effectively a Toolbar.
 */
export const TextConfigToolbar: FunctionComponent<EditorProps> = (props: EditorProps) => {

    const [anchorColorPickerEl, setAnchorColorPickerEl] = useState<HTMLButtonElement>(null);
    const [anchorFontSizeEl, setAnchorFontSizeEl] = useState<HTMLButtonElement>(null);

    const handleColorPickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorColorPickerEl(event.currentTarget);
    };

    const handleColorPickerClose = () => {
        setAnchorColorPickerEl(null);
    };

    const handleFontSizeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFontSizeEl(event.currentTarget);
    };

    const handleFontSizeClose = () => {
        setAnchorFontSizeEl(null);
    };

    const handleFontSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFontSizeChange(event.target.value + "px");
    };

    const onAlignmentChange = (alignment: HorizontalAlignment) => {
        props.onConfigPropertyChange(props.config.with({alignment: alignment}));
    };

    const onVerticalAlignmentChange = (alignment: VerticalAlignment) => {
        props.onConfigPropertyChange(props.config.with({verticalAlignment: alignment}));
    };

    const onFontColorChange = (color: ColorResult) => {
        props.onConfigPropertyChange(props.config.with({fontColor: color.hex}));
    };

    const onFontSizeChange = (size: string) => {
        props.onConfigPropertyChange(props.config.with({fontSize: size}));
    };


    return <>
        <StyleEnumButtonMenu
            id="horizontal-alignment"
            tooltipCategorization={"Horizontal Alignment"}
            current={props.config.getValue('alignment')}
            onStylingChange={onAlignmentChange}
            styles={HorizontalAlignment.enums<StylingEnum>()}
        />
        <StyleEnumButtonMenu
            id="vertical-alignment"
            tooltipCategorization={"Vertical Alignment"}
            current={props.config.getValue('verticalAlignment')}
            onStylingChange={onVerticalAlignmentChange}
            styles={VerticalAlignment.enums<StylingEnum>()}
        />
        <ColorPickerButtonIcon
            tooltip={"Font Color"}
            icon={
                <FormatTextColorIcon
                    width={24}
                    height={24}
                    style={{fill: props.config.getValue('fontColor')}}
                />
            }
            currentColor={props.config.getValue('fontColor')}
            onChange={onFontColorChange}
        />
        <Tooltip title={"Font Size"} placement="top" arrow>
            <IconButton
                onClick={handleFontSizeClick}
            >
                <FormatSizeIcon/>
            </IconButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorFontSizeEl)}
            anchorEl={anchorFontSizeEl}
            onClose={handleFontSizeClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <S.TextInputContainer>
                <input
                    type="number"
                    value={(props.config.getValue('fontSize')).replace('px', '')}
                    onChange={handleFontSizeChange}
                    style={{width: '50px'}}
                /> px
            </S.TextInputContainer>
        </Popover>
    </>;
};

const S = {


    TextInputContainer: styled.div`
        padding: 10px;
    `

};
