import * as React from "react";
import {FunctionComponent, useContext, useEffect, useState} from "react";
import {UploadDatasetIcon} from 'app/components/icons/asset/UploadDatasetIcon';
import {ImportedDatasetIcon} from 'app/components/icons/asset/ImportedDatasetIcon';
import FilterSetIcon from 'app/components/icons/asset/FilterSetIcon';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {Colors, FontSizes} from 'app/components/StyleVariables';
import DialogContent from '@mui/material/DialogContent';
import {ServiceProvider} from 'services/ServiceProvider';
import {UserService} from 'services/UserService';
import {Optional} from 'common/Optional';
import {NotificationSeverity, NotificationsService} from 'services/NotificationsService';
import {LinkService} from 'services/LinkService';
import {Enum} from "common/Enum";
import {FQN} from 'common/FQN';
import {AssetType} from 'metadata/AssetType';
import {CreateUploadDialog} from 'app/components/settings/connections/CreateUploadDialog';
import {UserContext} from "app/UserContext";
import {DatasetV2} from 'metadata/dataset/DatasetV2';
import {RemoteDataSource} from 'metadata/connections/RemoteDataSource';
import {ImportTableDialog} from 'app/components/settings/connections/ImportTableDialog';


export type Props = {

    step: YourDataStep
    // close the dialog
    onClose(): void
    // go somewhere else and close
    onRoute(route: string): void

}

export const YourData: FunctionComponent<Props> = (props: Props) => {

    // TODO ZZ this should probably be encapsulated in a useEffect in ImportTableDialog since currently we will have an
    //         "empty" state while loading remote connections
    const [remoteConnections, setRemoteConnections] = useState<Optional<RemoteDataSource[]>>(Optional.none());

    // get current user account
    const userContext = useContext(UserContext);

    useEffect(() => {
        // need to load connections
        if (props.step === YourDataStep.CONNECT) {
            ServiceProvider.get(UserService).listConnections()
                .then(response => response.match(
                    connections => setRemoteConnections(Optional.some(connections)),
                    _ => {
                        ServiceProvider.get(NotificationsService).publish(
                            'NewLanding',
                            NotificationSeverity.ERROR,
                            "Failed to load remote connections."
                        );
                        props.onClose();
                    }
                ));
        }
    }, [props.step]);

    const routeStep = (step: YourDataStep) => {
        props.onRoute('/new/dataset/' + step.name);
    };

    const onClickFilterset = () => {
        ServiceProvider.get(LinkService).filterset()
            .then(props.onRoute);
    };

    const onUploadDataset = (datasetV2: DatasetV2) => {
        ServiceProvider.get(NotificationsService).publish(
            'NewLanding',
            NotificationSeverity.SUCCESS,
            `Successfully uploaded dataset ${datasetV2.label}!`
        );
        props.onRoute('/' + datasetV2.fqn);
    };

    const buildPicker = () => {
        return <S.YourDataDialog open={true} onClose={props.onClose}>
            <S.YourDataDialogTitle>
                What do you want to create with your data?
            </S.YourDataDialogTitle>
            <S.YourDataDialogContent>
                <S.DataCard onClick={() => routeStep(YourDataStep.UPLOAD)}>
                    <S.DataIconContainer
                        startColor={"rgba(85, 185, 110, 0.9)"}
                        middleColor={"rgba(60, 170, 90, 0.9)"}
                        endColor={"rgba(45, 155, 75, 0.9)"}
                    >
                        <UploadDatasetIcon/>
                    </S.DataIconContainer>
                    <S.DataCardContent>
                        <S.DataCardTitle>Upload Dataset</S.DataCardTitle>
                        <S.DataCardText>
                            Create a new HyperArc hosted dataset by uploading a file.
                        </S.DataCardText>
                    </S.DataCardContent>
                </S.DataCard>
                <S.DataCard onClick={() => routeStep(YourDataStep.CONNECT)}>
                    <S.DataIconContainer
                        startColor={"rgba(119, 116, 232, 0.9)"}
                        middleColor={"rgba(84, 81, 241, 0.9)"}
                        endColor={"rgba(72, 69, 231, 0.9)"}
                    >
                        <ImportedDatasetIcon/>
                    </S.DataIconContainer>
                    <S.DataCardContent>
                        <S.DataCardTitle>Connect Dataset</S.DataCardTitle>
                        <S.DataCardText>
                            Connect a remote data source and use it directly in HyperArc.
                        </S.DataCardText>
                    </S.DataCardContent>
                </S.DataCard>
                <S.DataCard onClick={onClickFilterset}>
                    <S.DataIconContainer
                        startColor={"rgba(255, 202, 100, 0.90)"}
                        middleColor={"rgba(249, 179, 45, 0.90)"}
                        endColor={"rgba(255, 170, 5, 0.90)"}
                    >
                        <FilterSetIcon viewBox={"0 0 20 20"}/>
                    </S.DataIconContainer>
                    <S.DataCardContent>
                        <S.DataCardTitle>Filter Set</S.DataCardTitle>
                        <S.DataCardText>
                            Create set of reusable values to share across queries and dashboards.
                        </S.DataCardText>
                    </S.DataCardContent>
                </S.DataCard>
            </S.YourDataDialogContent>
        </S.YourDataDialog>;
    };

    switch(props.step) {
        case YourDataStep.PICKER:
            return buildPicker();
        case YourDataStep.UPLOAD:
            return <CreateUploadDialog
                fqn={new FQN(
                    userContext.user.map(u => u.username).getOr(null), 'private', AssetType.DATASET_V2, ""
                )}
                onCancel={props.onClose}
                onUpload={onUploadDataset}
            />;
        case YourDataStep.CONNECT:
            return remoteConnections.map(connections => {
                return <ImportTableDialog
                    initialConnection={connections.length > 0 ? connections[0] : null}
                    connections={connections}
                    onSelect={props.onClose}
                    onCancel={props.onClose}
                />;
            }).nullable;
    }

};


export class YourDataStep extends Enum {
    static readonly PICKER = new this('picker');
    static readonly UPLOAD = new this('upload');
    static readonly CONNECT = new this('connect');
}
YourDataStep.finalize();


class S {

    static readonly YourDataDialog = styled(Dialog)`
        .MuiDialog-paper {
            max-width: 500px;
            background-color: #f5f5f5;
        }
    `;

    static readonly YourDataDialogTitle = styled(DialogTitle)`
        font-size: ${FontSizes.medium};
        color: ${Colors.textPrimary};
        text-align: left;
        border-bottom: 1px solid ${Colors.borderGrey};
        margin-bottom: 20px;
    `;

    static readonly YourDataDialogContent = styled(DialogContent)`
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 25px;
        overflow: visible;
    `;

    static readonly DataCard = styled.div`
        width: 100%;
        height: 100px;
        display: flex;

        border-radius: 8px;

        cursor: pointer;
        transition: 0.3s;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.20);

        &:hover {
            transform: translateY(-5px);
        }
    `;

    static readonly DataCardContent = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15px;
    `;

    static readonly DataCardTitle = styled.div`
        color: ${Colors.textPrimary};
        font-size: ${FontSizes.medium};
        font-weight: 600;
        margin-top: 10px;
        padding: 10px 0;
    `;

    static readonly DataCardText = styled.div`
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.xSmall};
        padding-right: 20px;
        overflow: auto;
    `;

    static readonly DataIconContainer = styled.div<{
        startColor: string,
        middleColor: string,
        endColor: string
    }>`
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        position: relative;
        // gradient background
        background: linear-gradient(to bottom right, ${props => props.startColor} 0%, ${props => props.middleColor} 41%, ${props => props.endColor} 50%) bottom right / 50% 50% no-repeat,
        linear-gradient(to bottom left, ${props => props.startColor} 0%, ${props => props.middleColor} 41%, ${props => props.endColor} 50%) bottom left / 50% 50% no-repeat,
        linear-gradient(to top left, ${props => props.startColor} 0%, ${props => props.middleColor} 41%, ${props => props.endColor} 50%) top left / 50% 50% no-repeat,
        linear-gradient(to top right, ${props => props.startColor} 0%, ${props => props.middleColor} 41%, ${props => props.endColor} 50%) top right / 50% 50% no-repeat;

        // ensure svg is aligned in the center of this wrap
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 42px;
            height: 42px;
            fill: white;
        }
    `;

}