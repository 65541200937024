import React, {FunctionComponent, ReactElement, ReactNode} from 'react';
import {Colors, Durations} from "app/components/StyleVariables";
import {AnalyticsType} from "metadata/AnalyticsType";
import BlockHashEventIcon from "app/components/icons/field/BlockHashEventIcon";
import BlockHashEntityIcon from "app/components/icons/field/BlockHashEntityIcon";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import IntegerIcon from "app/components/icons/field/IntegerIcon";
import StringIcon from "app/components/icons/field/StringIcon";
import DecimalIcon from "app/components/icons/field/DecimalIcon";
import BooleanIcon from "app/components/icons/field/BooleanIcon";
import TimeIcon from "app/components/icons/field/TimeIcon";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {HigherOrderSuperType} from "metadata/HigherOrderType";
import {DataSuperType} from "metadata/DataType";
import {Optional} from "common/Optional";
import {Column} from "metadata/Column";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Props {
    field: Column
}

export const FieldIcon: FunctionComponent<Props> = (props: Props) => {

    const fillColor = props.field.analyticsType === AnalyticsType.MEASURE ?
        Colors.measureGreen : Colors.dimensionBlue;

    const tooltipWrap = (fieldIcon: ReactNode, toolTipText: string): ReactElement => {
        const finalIcon = props.field.isPublic ? fieldIcon : <VisibilityOffIcon/>;
        return <Tooltip
            title={toolTipText}
            enterDelay={Durations.tooltipPrimaryEnterDelay}
            enterNextDelay={Durations.tooltipPrimaryEnterNextDelay}
            disableInteractive
            arrow
        >
            <S.FieldIconContainer>
                {finalIcon}
            </S.FieldIconContainer>
        </Tooltip>;
    };

    return props.field.higherOrderSuperType.flatMap<ReactElement>(
        (orderSuperType: HigherOrderSuperType) => {
            switch (orderSuperType) {
                case HigherOrderSuperType.BLOCK_HASH_EVENT:
                    return tooltipWrap(
                        <BlockHashEventIcon fill={fillColor}/>,
                        orderSuperType.tooltipText
                    );
                case HigherOrderSuperType.BLOCK_HASH_ENTITY:
                    return tooltipWrap(
                        <BlockHashEntityIcon stroke={fillColor}/>,
                        orderSuperType.tooltipText
                    );
                default:
                    return Optional.none();
            }
        }
    ).getOrElse(
        () => {
            switch (props.field.dataSuperType) {
                case DataSuperType.NUMBER:
                    // Number is the only unique exception in which there's two subtypes of INTEGER + DECIMAL
                    if (props.field.dataType.isInteger) {
                        return tooltipWrap(
                            <IntegerIcon fill={fillColor}/>,
                            "Integer"
                        );
                    } else {
                        return tooltipWrap(
                            <DecimalIcon fill={fillColor}/>,
                            "Decimal"
                        );
                    }
                case DataSuperType.STRING:
                    return tooltipWrap(
                        <StringIcon fill={fillColor}/>,
                        props.field.dataSuperType.tooltipText
                    );
                case DataSuperType.BOOLEAN:
                    return tooltipWrap(
                        <BooleanIcon fill={fillColor}/>,
                        props.field.dataSuperType.tooltipText
                    );
                case DataSuperType.TIMESTAMP:
                    return tooltipWrap(
                        <TimeIcon fill={fillColor}/>,
                        props.field.dataSuperType.tooltipText
                    );
                default:
                    // Error icon to denote unknown data type
                    return tooltipWrap(
                        <ErrorOutlineIcon color={"error"}/>,
                        "Unknown Data Type"
                    );
            }
        }
    );
};

class S {
    static readonly FieldIconContainer = styled.div`
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            height: 14px;
            width: 14px;
        }
    `;
}
