import {NodeStructuredDetail} from 'metadata/hypergraph/content/NodeStructuredDetail';

/**
 * Detail comprised for multiple subfacts.
 *
 * @author zuyezheng
 */
export class SubFactsDetail implements NodeStructuredDetail {

    constructor(
        // label for all the sub facts
        public readonly label: string,
        // title and content for each sub fact
        public readonly subFacts: [string, string][]
    ) { }

    get embeddingContent(): string {
        return [
            `### ${this.label}`,
            ...this.subFacts.map(([title, content]) => `- **${title}**: ${content}`)
        ].join('\n');
    }

}