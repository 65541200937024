import {Enum} from "common/Enum";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ColumnField} from "metadata/query/ArcQLField";
import {MeasureField} from "metadata/query/MeasureField";
import {ExpressionGrouping} from "metadata/query/ExpressionGrouping";
import {ExpressionField} from "metadata/query/ExpressionField";

/**
 * Actions that can be performed on a field.
 *
 * @author zuyezheng
 */
export class FieldAction extends Enum {

    static readonly ORDER_DESC = new this('orderDesc', 'Sort Descending');
    static readonly ORDER_ASC = new this('orderAsc', 'Sort Ascending');
    static readonly CUMULATIVE = new this('cumulative', 'Cumulative');
    static readonly MOVE_UP = new this('moveUp', 'Move Up');
    static readonly MOVE_DOWN = new this('moveDown', 'Move Down');
    static readonly REMOVE = new this('remove', 'Remove');
    static readonly RENAME = new this('rename', 'Rename');
    static readonly ADD_FILTER = new this('addFilter', 'Add Filter');

    static label = (action: FieldAction) => action.label;

    /**
     * Filter the available actions based on if the field is the first or last (or both).
     */
    static filtered(field: ColumnField | ArcQLGrouping, isFirst: boolean, isLast: boolean): FieldAction[] {
        const actions = [
            FieldAction.ORDER_DESC,
            FieldAction.ORDER_ASC
        ];

        if (field instanceof MeasureField || field instanceof ExpressionField) {
            actions.push(FieldAction.ADD_FILTER);
        }

        if (field instanceof MeasureField) {
            actions.push(FieldAction.CUMULATIVE);
        }

        if (!isFirst) {
            actions.push(FieldAction.MOVE_UP);
        }
        if (!isLast) {
            actions.push(FieldAction.MOVE_DOWN);
        }

        if (field instanceof ExpressionGrouping || field instanceof ColumnField) {
            actions.push(FieldAction.RENAME);
        }

        actions.push(FieldAction.REMOVE);

        return actions;
    }

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
FieldAction.finalize();