import * as React from "react";
import {FunctionComponent} from "react";
import {Optional} from "common/Optional";
import {Selectable} from "engine/actor/Selectable";
import {ActorStatus} from "engine/actor/ActorStatus";
import {ArcVisualization} from "app/visualizations/ArcVisualization";
import {ResultMessage} from "engine/ResultMessage";
import {ChartMetadata} from "metadata/dashboard/widgets/ChartMetadata";
import {LoadingMask} from "app/components/decoration/LoadingMask";

interface Props {
    metadata: ChartMetadata
    resultMessages: Map<string, ResultMessage>
    queryStatuses: Map<string, ActorStatus>
    selectable?: Selectable
}

export const ChartWidget: FunctionComponent<Props> = (props: Props) => {

    return props.metadata.queryId.map((queryId: string) =>
        Optional.of(props.resultMessages.get(queryId)).map((message: ResultMessage) => {
            const status = props.queryStatuses.get(`${queryId}`);
            const vizProps = {
                queryResponse: Optional.of(props.resultMessages.get(queryId)).map(r => r.response),
                config: message.arcql.visualizations.default,
                isBusy: status == null || status.isBusy(),
                selectable: props.selectable
            };

            return <ArcVisualization {...vizProps} />;
        })
        // no results yet
        .getOr(<LoadingMask containerDimensions={['100%', '100%']} maskOpacity={0} />)
    )
    // no query id, show blank
    .getOr(<></>);

};
