import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";

/**
 * Empty definition that should be a placeholder.
 *
 * @author zuyezheng
 */
export class EmptyDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [];

    static DEFAULTS = new Map<string, any>();

    get properties(): VizProperty<any>[] {
        return EmptyDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return EmptyDefinition.DEFAULTS;
    }

}