import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {MeasureFilterEditorProps} from "app/query/filters/MeasureFilterEditorProps";
import {ServiceProvider} from "services/ServiceProvider";
import {QueryService} from "services/QueryService";
import {Tuple} from "common/Tuple";
import {Optional} from "common/Optional";
import {MeasureFilterHelper, OPERATORS} from "app/query/filters/MeasureFilterHelper";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {FilterEditorS} from "app/query/filters/FilterEditor";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import { ToggleS } from "app/components/input/ToggleS";


export const MeasureFilterEditor: FunctionComponent<MeasureFilterEditorProps> = (props: MeasureFilterEditorProps) => {

    const [operator, setOperator] = useState<FilterOperator>(props.filter.operator);
    const [range, setRange] = useState<Optional<Tuple<number, number>>>(Optional.none());
    const [value, setValue] = useState<number[]>([null, null]);

    useEffect(() => {
        setValue(props.filter.isAll ? [null, null] : props.filter.values as number[]);

        // range is tricky with aggregate filters since it takes an expensive (and currently impossible) nested select
        if (props.isAggregate) {
            return;
        }

        // figure out the bounds for the filter
        const controller = new AbortController();
        ServiceProvider.get(QueryService)
            .rangeValues(
                props.arcqlBundle.dataset,
                MeasureFilterHelper.rangeValuesRequest(props.arcqlBundle, props.filter, props.ordinal),
                controller.signal
            )
            .then((results: Either<ErrorResponse, ArcQLResponse>) => results.rightOrThrow())
            .then((response: ArcQLResponse) => setRange(Optional.some(MeasureFilterHelper.range(response))));

        return () => controller.abort();
    }, [props.filter]);

    const onChangeOperator = (_: React.MouseEvent<HTMLElement>, operatorName: string) => {
        if (operatorName == null) {
            // no change
            return;
        }

        const newOperator: FilterOperator = FilterOperator.get(operatorName);
        const newValue = MeasureFilterHelper.changeBounds(operator, newOperator, value, range);

        setOperator(newOperator);
        setValue(newValue);
        props.onChange(newOperator, newValue);
    };

    const onChangeValue = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValues = MeasureFilterHelper.processBoundInput(index, event.target.value, value, Optional.none());
        setValue(newValues);
        props.onChange(operator, newValues);
    };

    const onBlur = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValues = MeasureFilterHelper.processBoundInput(
            index, event.target.value, value, range.orElse(() => Optional.some(Tuple.of(0, 0)))
        );

        setValue(newValues);
        props.onChange(operator, newValues);
    };

    return <FilterEditorS.Container>
        <FilterEditorS.HelpText>Use rows when the value is:</FilterEditorS.HelpText>
        <ToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            value={operator.name}
            onChange={onChangeOperator}
        >{
            OPERATORS.map(operator =>
                <ToggleS.ToggleButton key={operator.name} value={operator.name}>
                    {operator.label}
                </ToggleS.ToggleButton>
            )
        }</ToggleButtonGroup>
        <FilterEditorS.BoundsContainer>{
            Optional.bool(operator === FilterOperator.IN)
                .map(
                    () => <>
                        Looks like you've ended up with an in operator from drill ins or external link, to change the
                        measure bounds just pick a different operator.
                    </>
                ).getOrElse(() =>
                    <> {
                        value.map((v, i) =>
                            <FilterEditorS.Bound key={`bound_${i}`}>
                                <FilterEditorS.BoundField
                                    value={value[i] == null ? '' : value[i]}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => onChangeValue(i, e)}
                                    onBlur={(e) => onBlur(i, e)}
                                />
                                <FilterEditorS.HelpText>
                                    {i === 0 ? 'Start' : 'End'}
                                </FilterEditorS.HelpText>
                            </FilterEditorS.Bound>
                        )
                    } </>
                )
        }</FilterEditorS.BoundsContainer>
        <FilterEditorS.HelpText>{
            !props.isAggregate && range
                .map(r => `Data range between ${Number(r.left).toFixed(2)} and ${Number(r.right).toFixed(2)}.`)
                .getOr('Checking data range...')
        }
        </FilterEditorS.HelpText>
    </FilterEditorS.Container>;

};
