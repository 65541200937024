import {ArcQL} from "metadata/query/ArcQL";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import md5 from "md5";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {QueryResult} from "metadata/query/QueryResult";

/**
 * Response from an ArcQL query.
 *
 * @author zuyezheng
 */
export class ArcQLResponse {

    static fromJson(json: {[key: string]: any}, dataset: ArcDataset): ArcQLResponse {
        const query: ArcQL = ArcQL.fromJSON(json['arcql']);

        let results = new QueryResult(json['result'], query, dataset);
        // if the first grouping is a date, sort by groupings ascending since we want to sort descending on the server
        // to get the most recent date values while we want to present results ascending
        if (query.isGrouped() && query.groupings.first.type === ArcQLGroupingType.DATE) {
            const categoryIndices = results.categoryColumns.map(c => c.right);
            results = results.sort((a, b) => {
                const aLabel = categoryIndices.map(i => a[i]).join('>');
                const bLabel = categoryIndices.map(i => b[i]).join('>');

                if (aLabel > bLabel) {
                    return 1;
                } else if (aLabel < bLabel) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }

        return new ArcQLResponse(query, results, json['changes'], dataset);
    }

    public readonly id: string;

    constructor(
        public readonly arcql: ArcQL,
        public readonly result: QueryResult,
        public readonly changes: string[],
        // TODO ZZ should prob be private
        public readonly dataset: ArcDataset
    ) {
        // generate a new id for every query as a easy way to diff without diffing results, we know if the hash is the
        // same, the results must be the same
        this.id = md5(Math.random().toString());
    }

    /**
     * Create a copy with new results.
     */
    withResults(result: QueryResult) {
        return new ArcQLResponse(
            this.arcql, result, this.changes, this.dataset
        );
    }

}

