import styled from "@emotion/styled";
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import {GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import {Optional} from "common/Optional";
import React, {useEffect, useState} from "react";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {FoldersSearchResponse} from "metadata/project/FoldersSearchResponse";
import {Colors} from "app/components/StyleVariables";
import {Chip} from "@mui/material";
import {WrappedProjectIcon} from "app/components/icons/project/WrappedProjectIcon";
import dayjs from "dayjs";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {TableResultMenu} from "app/components/TableResultMenu";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {EditProjectDialog} from "app/components/project/EditProjectDialog";
import {TableResultAction} from "app/components/TableResultAction";
import {ChangeVisibilityProjectDialog} from "app/components/project/ChangeVisibilityProjectDialog";
import {FolderResult} from "metadata/project/FolderResult";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";


interface Props {
    searchParams: FolderSearchParams
    onSelect: (selected: FolderResult) => void
    onSearch: (params: FolderSearchParams, controller: AbortController) => Promise<Either<ErrorResponse, FoldersSearchResponse>>
    onAccountBreadcrumb?: (account: string) => void
    showActions?: boolean
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    TYPE: 'type',
    LABEL: 'label',
    OWNER: 'owner',
    UPDATED_ON: 'updatedOn',
    RESULT: 'result',
    ACTIONS: 'actions'
};

const StandardProjectActions = {
    EDIT: new TableResultAction('edit', 'Edit', <EditOutlinedIcon/>),
    CHANGE_VISIBILITY: new TableResultAction('changeVisibility', 'Change Visibility', <VisibilityOutlinedIcon/>),
};

/**
 * Query and display project results given the search params.
 *
 * @author fli
 */
export const ProjectSearchResultsTable = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchResponse, setSearchResponse] = useState<Optional<FoldersSearchResponse>>(Optional.none());
    const [page, setPage] = useState<number>(0);
    const [projectSelectedForActions, setProjectSelectedForActions] = useState<Optional<FolderResult>>(Optional.none());
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showChangeVisibilityModal, setShowChangeVisibilityModal] = useState<boolean>(false);
    const [reloadTrigger, setReloadTrigger] = useState<number>(0);

    useEffect(() => {
        setIsLoading(true);

        const controller = new AbortController();
        (async () => {
            const searchResponse = await props.onSearch(props.searchParams.with({page: page}), controller)
                .then(r => r.rightOrThrow());
            setSearchResponse(Optional.some(searchResponse));
            setIsLoading(false);
        })();

        return () => controller.abort();
    }, [page, props.searchParams, reloadTrigger]);

    const onRowClick = (params: GridRowParams) => {
        props.onSelect(params.row.result as FolderResult);
    };

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        if (params.field === FIELD_IDENTIFIERS.ACTIONS) {
            event.stopPropagation();
            setProjectSelectedForActions(Optional.some(params.row.result as FolderResult));
        }
    };

    const onAction = (action: TableResultAction) => {
        switch (action.id) {
            case StandardProjectActions.EDIT.id:
                setShowEditModal(true);
                break;
            case StandardProjectActions.CHANGE_VISIBILITY.id:
                setShowChangeVisibilityModal(true);
                break;
        }
    };

    const reloadSearch = () => {
        setReloadTrigger(prev => prev + 1);
        // because user successfully updated a folder, revert back to page 0 where it should appear at the top of the list
        setPage(0);
    };

    const teardownEditModal = () => {
        setShowEditModal(false);
        setProjectSelectedForActions(Optional.none());
    };
    const teardownChangeVisibilityModal = () => {
        setShowChangeVisibilityModal(false);
        setProjectSelectedForActions(Optional.none());
    };

    const columns: GridColDef[] = [{
        'field': FIELD_IDENTIFIERS.TYPE,
        'headerName': '',
        'width': 30,
        'renderCell': (params: GridRenderCellParams<boolean>) =>
            <S.Icon>
                <WrappedProjectIcon isPublic={params.value}/>
            </S.Icon>
    }, {
        'field': FIELD_IDENTIFIERS.LABEL,
        'headerName': 'Label',
        'renderCell': (params: GridRenderCellParams<[string, boolean]>) =>
            <S.LabelCell>
                <div className="label">{params.value[0]}</div>
                <S.VisibilityChip
                    label={params.value[1] ? 'Public' : 'Private'}
                    color={"secondary"}
                    size="small"
                />
            </S.LabelCell>,
        'flex': 3
    }, {
        'field': FIELD_IDENTIFIERS.OWNER,
        'headerName': 'Owner',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<string>) =>
            <AccountBreadCrumbInResultRow
                text={params.value}
                onClick={() => props?.onAccountBreadcrumb(params.value)}
            />
    }, {
        'field': FIELD_IDENTIFIERS.UPDATED_ON,
        'headerClassName': 'HideRightSeparator',
        'headerName': 'Last Updated',
        'flex': 1
    }, {
        'field': FIELD_IDENTIFIERS.ACTIONS,
        'headerName': '',
        'width': 80,
        'renderCell': (params) => {
            return <S.ProjectResultActionsContainer
                className={
                    projectSelectedForActions.map(
                        selected => selected.id === params.row.id ? 'SelectedActionMenu' : 'ActionMenu'
                    )
                        .getOr('ActionMenu')
                }
            >
                <TableResultMenu
                    actions={Object.values(StandardProjectActions)}
                    onAction={onAction}
                    onCancel={() => setProjectSelectedForActions(Optional.none())}
                />
            </S.ProjectResultActionsContainer>;
        },
        'sortable': false
    }];

    return <div>
        <S.ResultsTable
            loading={isLoading}
            columnVisibilityModel={{[FIELD_IDENTIFIERS.ACTIONS]: props.showActions || false}}

            columns={columns}
            rows={
                searchResponse
                    .map(resp => resp.results.map(result => ({
                        [FIELD_IDENTIFIERS.ID]: result.id,
                        [FIELD_IDENTIFIERS.TYPE]: result.isPublic,
                        [FIELD_IDENTIFIERS.LABEL]: [result.label, result.isPublic],
                        [FIELD_IDENTIFIERS.OWNER]: result.ownerName,
                        [FIELD_IDENTIFIERS.UPDATED_ON]: dayjs(result.updatedOn).format("MMM D, YYYY"),
                        [FIELD_IDENTIFIERS.RESULT]: result
                    })))
                    .getOr([])
            }
            onRowClick={onRowClick}
            onCellClick={handleCellClick}

            pagination
            paginationMode="server"
            page={page}
            pageSize={props.searchParams.size}
            rowsPerPageOptions={[props.searchParams.size]}
            rowCount={searchResponse.map(r => r.total).getOr(0)}
            onPageChange={(newPage) => setPage(newPage)}
            autoHeight={true}
            // reduce the default row height by a smidge, default is 52
            rowHeight={40}
            density="standard"
            disableSelectionOnClick
            disableColumnMenu
        />
        {showEditModal &&
            projectSelectedForActions.map(project => <EditProjectDialog
                    project={project}
                    onEdit={() => {
                        teardownEditModal();
                        reloadSearch();
                    }}
                    onCancel={teardownEditModal}
                />
            ).getOr(<></>)
        }
        {showChangeVisibilityModal &&
            projectSelectedForActions.map(project => <ChangeVisibilityProjectDialog
                    project={project}
                    onVisibilityChange={() => {
                        teardownChangeVisibilityModal();
                        reloadSearch();
                    }}
                    onCancel={teardownChangeVisibilityModal}
                />
            ).getOr(<></>)
        }

    </div>;

};

const S = {
    ResultsTable: styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-row {
            max-height: 30px;
        }

        .HideRightSeparator {
            & > .MuiDataGrid-columnSeparator {
                display: none
            }
        }

        .MuiDataGrid-row:hover .ActionMenu {
            visibility: visible;
        }

        .SelectedActionMenu {
            visibility: visible;
        }
    `,

    Icon: styled.div`
        display: flex;

        svg {
            width: 20px;
            height: 22px;
            color: ${Colors.iconPrimary};
        }
    `,

    LabelCell: styled.div`
        display: flex;
    `,

    VisibilityChip: styled(Chip)`
        margin-left: 8px;
        border: 1px solid ${Colors.textSecondary};
        color: ${Colors.textSecondary};
    `,

    ProjectResultActionsContainer: styled.div`
        visibility: hidden;
    `
};
