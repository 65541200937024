import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {IconButton} from "@mui/material";
import Badge from "@mui/material/Badge";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

/**
 * Common styles for Trends Panel.
 */
export class TrendS {

    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: start;
        flex: 1;
        overflow: auto;
        position: relative;
    `;

    static Header = styled.div`
        background-color: ${Colors.headerGrey};
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.xSmall};
        line-height: ${FontSizes.xSmall};
        font-weight: 500;
        height: 44px;
        display: flex;
        align-items: center;
        width: 100%;
    `;

    static HeaderLabel = styled.div`
        padding: 0 10px;
        text-overflow: ellipsis;
        text-transform: uppercase;
    `;

    static readonly TrendIcon = styled(TrendingUpIcon)`
        margin-left: 5px;
        width: 16px;
        height: 16px;
    `;

    static SubHeaderArcQL = class {

        static readonly Container = styled.div`
            display: flex;
            flex-direction: row;
            align-items: center;
        `;

        static readonly Label = styled.span`
            margin-left: 5px;
            color: ${Colors.textPrimary};
            font-weight: 500;
            font-size: ${FontSizes.small};
        `;
    };


    static ButtonGroup = styled.div`
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-right: 5px;
    `;

    static HeaderButton = styled(IconButton)`
        height: 36px;
        border-radius: 18px;
        line-height: 1;
        
        .MuiSvgIcon-root {
            font-size: 1.25rem;
        }
    `;

    static readonly BadgeIcon = styled(Badge)`
        .MuiBadge-badge {
            font-size: 0.7rem;
            height: 12px;
            min-width: 12px;
            line-height: 12px;
        }
    `;

    static readonly Bullets = styled.ul`
        list-style-type: disc;
        padding-left: 8px;
    `;
}