import {Enum} from "common/Enum";

export class QueryReason extends Enum {

    static readonly REPLACE = new this('replace');
    // More specific type of replace when an asset is being replaced by a different saved version of the same asset
    static readonly VERSION_REPLACE = new this('version_replace');
    static readonly UNDO = new this('undo');
    static readonly REDO = new this('redo');
    static readonly INTERACTION = new this('interaction');
    static readonly FACETING = new this('faceting');

    private constructor(
        name: string,
    ) {
        super(name);
    }

}
QueryReason.finalize();