import {Colors} from "app/components/StyleVariables";
import {JsonObject} from "common/CommonTypes";
import {GlobalFilterEmbedMode} from "metadata/dashboard/GlobalFilterEmbedMode";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {JsonUtils} from "metadata/JsonUtils";
import {Optional} from "common/Optional";


interface Props {
    backgroundColor?: string
    numberColumns?: number
    gridGapX?: number
    gridGapY?: number,
    globalFilterEmbedMode?: GlobalFilterEmbedMode,
    maxWidth?: number,
    gutterColor?: string,
    container?: ContainerConfigPart
}

export const MAX_WIDTH_UNSET = 0;

/**
 * Configuration for Dashboard such as background color of entire dashboard, spacing between widgets, number of columns, etc.
 */
export class DashboardConfig {

    private static readonly BACKGROUND_COLOR_KEY = "backgroundColor";
    private static readonly NUMBER_COLUMNS_KEY = "numberColumns";
    private static readonly GRID_GAP_X_KEY = "gridGapX";
    private static readonly GRID_GAP_Y_KEY = "gridGapY";
    private static readonly GLOBAL_FILTER_EMBED_MODE_KEY = "globalFilterEmbedMode";
    private static readonly MAX_WIDTH_KEY = "maxWidth";
    private static readonly GUTTER_COLOR_KEY = "gutterColor";
    private static readonly CONTAINER_KEY = "container";

    static default(): DashboardConfig {
        return new DashboardConfig(
            Colors.backgroundGrey,
            12,
            12,
            12,
            GlobalFilterEmbedMode.DEFAULT,
            MAX_WIDTH_UNSET,
            Colors.backgroundGrey,
            ContainerConfigPart.defaults()
        );
    }

    static fromJSON(json: JsonObject, withDefaults: DashboardConfig = this.default()): DashboardConfig {
        return new DashboardConfig(
            json[this.BACKGROUND_COLOR_KEY] || withDefaults.backgroundColor,
            json[this.NUMBER_COLUMNS_KEY] || withDefaults.numberColumns,
            json[this.GRID_GAP_X_KEY] || withDefaults.gridGapX,
            json[this.GRID_GAP_Y_KEY] || withDefaults.gridGapY,
            GlobalFilterEmbedMode.get(json[this.GLOBAL_FILTER_EMBED_MODE_KEY]) || withDefaults.globalFilterEmbedMode,
            json[this.MAX_WIDTH_KEY] || withDefaults.maxWidth,
            json[this.GUTTER_COLOR_KEY] || withDefaults.gutterColor,
            ContainerConfigPart.fromJSON(
                // defaults overwritten by container config in json
                // ensures if outdated dashboards are missing new keys, defaults are used
                JsonUtils.merge(withDefaults.container.toJSON(), json[this.CONTAINER_KEY])
            )
        );
    }

    constructor(
        // background color of entire dashboard, may be string or hex
        public readonly backgroundColor: string,
        // number of columns in dashboard grid
        public readonly numberColumns: number,
        // spacing / padding between widgets on x plane in pixels
        public readonly gridGapX: number,
        public readonly gridGapY: number,
        public readonly globalFilterEmbedMode: GlobalFilterEmbedMode,
        // maximum width of dashboard in pixels
        public readonly maxWidth: number,
        // color of the gutter of the dashboard (only applicable if maxWidth reached)
        public readonly gutterColor: string,
        public readonly container: ContainerConfigPart
    ) {
    }

    /**
     * Create a copy of DashbordConfig with the appropriate edit.
     */
    with({
        backgroundColor,
        numberColumns,
        gridGapX,
        gridGapY,
        globalFilterEmbedMode,
        maxWidth,
        gutterColor,
        container
    }: Props): DashboardConfig {
        return new DashboardConfig(
            backgroundColor == null ? this.backgroundColor : backgroundColor,
            numberColumns == null ? this.numberColumns : numberColumns,
            gridGapX == null ? this.gridGapX : gridGapX,
            gridGapY == null ? this.gridGapY : gridGapY,
            globalFilterEmbedMode == null ? this.globalFilterEmbedMode : globalFilterEmbedMode,
            maxWidth == null ? this.maxWidth : maxWidth,
            gutterColor == null ? this.gutterColor : gutterColor,
            container == null ? this.container : container
        );
    }

    toJSON(): JsonObject {
        return {
            [DashboardConfig.BACKGROUND_COLOR_KEY]: this.backgroundColor,
            [DashboardConfig.NUMBER_COLUMNS_KEY]: this.numberColumns,
            [DashboardConfig.GRID_GAP_X_KEY]: this.gridGapX,
            [DashboardConfig.GRID_GAP_Y_KEY]: this.gridGapY,
            [DashboardConfig.GLOBAL_FILTER_EMBED_MODE_KEY]: this.globalFilterEmbedMode,
            [DashboardConfig.MAX_WIDTH_KEY]: this.maxWidth,
            [DashboardConfig.GUTTER_COLOR_KEY]: this.gutterColor,
            [DashboardConfig.CONTAINER_KEY]: this.container.toJSON()
        };
    }
}
