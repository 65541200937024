import {DatasetType} from "metadata/dataset/DatasetType";
import {RemoteDatasetConfig} from "metadata/dataset/RemoteDatasetConfig";


/** JSON representation of a BigQuery dataset configuration. */
export type BigQueryDatasetConfigJson = {
    connectionId: string;
    project: string;
    dataset: string;
    table: string;
};

/**
 * Configuration for a BigQuery dataset.
 */
export class BigQueryDatasetConfig implements RemoteDatasetConfig {

    public readonly type = DatasetType.BIG_QUERY;

    constructor(
        public readonly connectionId: string,
        public readonly project: string,
        public readonly dataset: string,
        public readonly table: string
    ) {
    }

    static fromJSON(json: BigQueryDatasetConfigJson): BigQueryDatasetConfig {
        return new BigQueryDatasetConfig(
            json.connectionId,
            json.project,
            json.dataset,
            json.table
        );
    }

    // for BigQuery, project name is equivalent to database name and what's database name is equivalent to schema name
    get database(): string {
        return this.project;
    }

    // for BigQuery, dataset name is equivalent to schema name
    get schema(): string {
        return this.dataset;
    }

    get fullTableName(): string {
        return `${this.project}.${this.dataset}.${this.table}`;
    }
    
}