import {Enum} from "common/Enum";
import {GridColumns} from "@mui/x-data-grid-premium";
import {DataGridProProps} from "@mui/x-data-grid-pro/models/dataGridProProps";
import {GridRenderCellParams} from "@mui/x-data-grid";
import React from "react";
import styled from "@emotion/styled";
import {Durations, FontSizes} from "app/components/StyleVariables";
import dayjs from "dayjs";
import {LoadedSearchResult} from "app/components/search/AssetSearchResultsTable";
import {CommonS} from "app/components/CommonS";
import {AssetType} from "metadata/AssetType";
import {AssetIcon, IconStyling, StandardIconSize} from "app/components/icons/asset/AssetIcon";
import Tooltip from "@mui/material/Tooltip";
import relativeTime from "dayjs/plugin/relativeTime";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {SearchResultsListItem} from "app/components/search/SearchResultsListItem";
import {CreatedInfo} from "app/components/decoration/CreatedInfo";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";

dayjs.extend(relativeTime);

/**
 * Different display types for search results.
 *
 * @author zuyezheng
 */
export class SearchResultsRowType extends Enum {

    static TABLE = new this(
        'table',
        (_, disableProjectInfo, onAccountBreadcrumb) =>
            [{
                'field': 'type',
                'headerName': '',
                'width': 30,
                'renderCell': (params: GridRenderCellParams<string>) =>
                    <S.Icon>
                        <AssetIcon
                            assetType={AssetType.get(params.value)}
                            size={StandardIconSize.SMALL}
                        />
                    </S.Icon>
            }, {
                'field': 'labelAndName',
                'headerName': 'Label',
                'renderCell': (params: GridRenderCellParams<[string, string]>) =>
                    <S.LabelAndName>
                        <div className="label">{params.value[0]}</div>
                        <CommonS.MinorText>{params.value[1]}</CommonS.MinorText>
                    </S.LabelAndName>,
                'flex': disableProjectInfo ? 3 : 1
            }, {
                'field': 'projectLabel',
                'headerName': 'Project',
                'flex': 1,
            }, {
                'field': 'projectOwner',
                'headerName': 'Owner',
                'flex': 1,
                'renderCell': (params: GridRenderCellParams<string>) =>
                    <AccountBreadCrumbInResultRow
                        text={params.value}
                        onClick={() => onAccountBreadcrumb(params.value)}
                    />
            }, {
                'field': 'createdBy',
                'headerName': 'Created By',
                'flex': 1,
                'renderCell': (params: GridRenderCellParams<string>) =>
                    <AccountBreadCrumbInResultRow
                        text={params.value}
                        onClick={() => onAccountBreadcrumb(params.value)}
                    />
            }, {
                'field': 'lastUpdatedOn',
                'headerName': 'Last Updated On ',
                'flex': 1
            }]
        ,
        loadedResult => ({
            'id': loadedResult.result.fullyQualifiedName,
            'type': loadedResult.result.type.name,
            'labelAndName': [loadedResult.result.label, loadedResult.result.name],
            'projectLabel': loadedResult.result.folderLabel,
            'projectOwner': loadedResult.result.folderOwner,
            'createdBy': loadedResult.result.createdBy,
            'lastUpdatedOn': dayjs(loadedResult.result.lastUpdatedOn).format("MMM D, YYYY"),
            'result': loadedResult.result
        }),
        false,
        {},
        (disableProjectInfo?) =>
            disableProjectInfo ? {'projectLabel': false, 'projectOwner': false} : {}
    );

    static SIMPLE_LIST_ITEM = new this(
        'listItem',
        (selectedRows: Set<string>) => [{
            'field': 'card',
            'renderCell': (params: GridRenderCellParams<LoadedSearchResult>) => {
                const loadedResult: LoadedSearchResult = params.row;
                const isSelected = selectedRows.has(loadedResult.result.fullyQualifiedName);
                return <SearchResultsListItem
                    isSelected={isSelected}
                    loadedResult={loadedResult}
                    detailed={false}
                />;

            },
            'flex': 1
        }],
        result => result,
        false,
        {
            className: 'card',
            headerHeight: 0,
            rowHeight: 50
        },
        () => ({})
    );

    static SMALL_CARD = new this(
        'smallCard',
        () => [{
            'field': 'card',
            'renderCell': (params: GridRenderCellParams<LoadedSearchResult>) => {
                const loadedResult: LoadedSearchResult = params.row;

                return <Tooltip
                    title={loadedResult.result.label}
                    enterDelay={Durations.tooltipSecondaryEnterDelay}
                    enterNextDelay={Durations.tooltipSecondaryEnterNextDelay}
                    arrow
                    placement="top"
                >
                    <S.SmallCard>
                        <S.PreviewImage className="previewImage" url={loadedResult.previewDataUrl.getOr('')}/>
                        <S.CardDetails>
                            <S.DetailRow>
                                <S.Icon>
                                    <AssetIcon
                                        assetType={AssetType.get(loadedResult.result.type.name)}
                                        iconStyling={IconStyling.OUTLINED_COLORED}
                                    />
                                </S.Icon>
                                <S.Label>{loadedResult.result.label}</S.Label>
                            </S.DetailRow>
                            <S.DetailRow>
                                <CreatedInfo
                                    createdBy={loadedResult.result.createdBy}
                                    createdOn={loadedResult.result.lastUpdatedOn}
                                />
                            </S.DetailRow>
                        </S.CardDetails>
                    </S.SmallCard>
                </Tooltip>;
            },
            'flex': 1
        }],
        result => result,
        true,
        {
            className: 'card',
            headerHeight: 0,
            rowHeight: 91
        },
        () => ({})
    );

    constructor(
        name: string,
        public readonly columns: (selectedRows?: Set<string>, disableProjectInfo?: boolean, onAccountBreadcrumb?: (account: string) => void) => GridColumns,
        public readonly rowBuilder: (loadedResult: LoadedSearchResult) => any,
        // if associated images should be fetched
        public readonly loadImages: boolean,
        public readonly tableProps: Partial<DataGridProProps>,
        public readonly visibility: (disableProjectInfo?: boolean) => GridColumnVisibilityModel
    ) {
        super(name);
    }

}

SearchResultsRowType.finalize();

type CardProps = {
    url: string
}

class S {

    static LabelAndName = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static Icon = styled.div`
        display: flex;
    `;

    static SmallCard = styled.div`
        display: flex;
        width: 100%;
        cursor: pointer;

        .previewImage {
            width: 150px;
            height: 75px;
            flex-shrink: 0;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
        }
    `;

    static PreviewImage = styled.div((props: CardProps) => ({
        backgroundImage: `url('${props.url}')`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white'
    }));

    static CardDetails = styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 12px;
    `;

    static DetailRow = styled.div`
        overflow: hidden;
        display: flex;
        flex-shrink: 2;
        align-items: center;
        padding: 2px 0;
    `;

    static Label = styled.div`
        font-size: ${FontSizes.medium};
        padding-left: 4px;
    `;

}