import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {HyperGraphNodeHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {HyperGraphNodeProps, HyperGraphNodeSerialized} from "metadata/hypergraph/HyperGraphTypes";
import {NodeRating} from "metadata/hypergraph/NodeRating";
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {Optional} from "common/Optional";
import {Enum} from "common/Enum";

/**
 * The fully resolved HyperGraph node linked to the rest of the graph.
 *
 * @author zuyezheng
 */
export abstract class HyperGraphNode<R extends HyperGraphRequest = HyperGraphRequest> {

    constructor(
        public readonly id: string = crypto.randomUUID(),
        public readonly createdOn: number = Date.now(),
        public readonly modifiedOn: number = createdOn,
        // embedding of just this node
        public readonly nodeEmbedding: number[] = [],
        // embedding of all or part of the walk including this node
        public readonly walkEmbedding: number[] = [],
        public readonly rating: NodeRating = NodeRating.UNRATED
    ) {}

    /**
     * Type used for deserialization.
     */
    abstract get type(): HyperGraphNodeType;

    abstract label(hyperGraph: HyperGraph): string;

    /**
     * Simple description that is possibly user editable stored as a markdown string.
     */
    abstract get description(): string;

    /**
     * More complex structured content and description that is not user editable as text, but can support interaction.
     */
    abstract structuredContent(graph: HyperGraph): NodeStructuredSection[];

    /**
     * Immediate request associated with this node.
     */
    abstract get request(): R;

    /**
     * Get the query this node processed.
     */
    abstract getQuery(graph: HyperGraph): ArcQL;

    /**
     * Get the query result this node processed.
     */
    abstract getQueryResult(graph: HyperGraph): QueryResult;

    /**
     * Return the nth hypothesis produced from the node. N should be ignored if no query variants, or aligned with the
     * variant index.
     */
    abstract getHypothesis(nth: number): HyperGraphNodeHypothesis;

    /**
     * Return a copy of this node with updated properties.
     */
    abstract with(props: Partial<HyperGraphNodeProps>): HyperGraphNode<R>;

    /**
     * Single string used for generating an embedding.
     */
    embeddingContent(graph: HyperGraph): string {
        return [
            `# ${this.label(graph)}`,
            ...Optional.string(this.description).array,
            ...this.structuredContent(graph).map(c => c.embeddingContent)
        ].join('\n\n');
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            type: this.type.toJSON(),
            id: this.id,
            request: this.request.toJSON(),
            createdOn: this.createdOn,
            modifiedOn: this.modifiedOn,
            nodeEmbedding: this.nodeEmbedding,
            walkEmbedding: this.walkEmbedding,
            rating: this.rating.toJSON()
        };
    }

    get isRoot(): boolean {
        return this.request.parent == null;
    }

    /**
     * If the node is currently in progress.
     */
    get inProgress(): boolean {
        return false;
    }

    /**
     * If the node supports ratings.
     */
    get canRate(): boolean {
        return true;
    }

    /**
     * Types of edits supported by this node.
     */
    get editTypes(): Set<EditType> {
        return new Set();
    }

    /**
     * User edits are treated a little differently for each node and might not be directly stored as props, so a
     * separate handler instead of the common with props.
     */
    withEdit(type: EditType, content: string): HyperGraphNode<R> {
        return this;
    }

    protected getParent(graph: HyperGraph): HyperGraphNode {
        return graph.getTyped(this.request.parent, HyperGraphNode);
    }

    protected getParentQuery(graph: HyperGraph): ArcQL {
        return this.getParent(graph).getQuery(graph);
    }

    protected getParentQueryResult(graph: HyperGraph): QueryResult {
        return this.getParent(graph).getQueryResult(graph);
    }

}

/**
 * Which edit types are supported?
 */
export class EditType extends Enum {

    static readonly TITLE = new EditType('title');
    static readonly DESCRIPTION = new EditType('description');

}