import {ArcMetadata} from "metadata/ArcMetadata";
import {Optional} from "common/Optional";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcEvent} from "engine/ArcEvent";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ArcActor} from "engine/actor/ArcActor";
import {ArcQLMessage} from "metadata/ArcQLMessage";
import {ArcQLVisualizationChange} from "metadata/query/changes/ArcQLVisualizationChange";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {ArcQLInfoChange} from "metadata/query/changes/ArcQLInfoChange";
import {ArcQLReplace} from "metadata/query/changes/ArcQLReplace";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ArcMetadataChange} from "metadata/ArcMetadataChange";
import {DeleteOrderBy} from "metadata/query/changes/DeleteOrderBy";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {ActorStatus} from "engine/actor/ActorStatus";
import {ActorStatusEventFilter} from "engine/ActorStatusMessage";
import {LimitChange} from "metadata/query/changes/LimitChange";
import {ArcQLField, ColumnField} from "metadata/query/ArcQLField";
import {ExpressionField} from "metadata/query/ExpressionField";
import {Direction} from "metadata/query/ArcQLOrderBy";
import {AddOrderBy} from "metadata/query/changes/AddOrderBy";
import {AssetProps} from "metadata/Asset";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {ArcQLFilterExpressionChange} from "metadata/query/changes/ArcQLFilterExpressionChange";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {ResultEventFilter} from "engine/ResultMessage";
import {ExpressionGrouping} from "metadata/query/ExpressionGrouping";
import {ModifyFilter} from "metadata/query/changes/ModifyFilter";
import {AddFilter} from "metadata/query/changes/AddFilter";
import {DeleteFilter} from "metadata/query/changes/DeleteFilter";
import {AddGrouping} from "metadata/query/changes/AddGrouping";
import {DeleteGrouping} from "metadata/query/changes/DeleteGrouping";
import {ModifyGrouping} from "metadata/query/changes/ModifyGrouping";
import {DataSuperType} from "metadata/DataType";
import {MoveGrouping} from "metadata/query/changes/MoveGrouping";
import {AddField} from "metadata/query/changes/AddField";
import {DeleteField} from "metadata/query/changes/DeleteField";
import {ModifyField} from "metadata/query/changes/ModifyField";
import {MoveField} from "metadata/query/changes/MoveField";
import {ReplaceReason} from "metadata/ReplaceReason";
import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {LocalStorageService} from "services/LocalStorageService";
import throttle from 'lodash/debounce';
import {DateFilterHelper} from "app/query/filters/DateFilterHelper";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {FilterClauseFactory} from "metadata/query/filterclause/FilterClauseFactory";
import {SelectSource} from "metadata/query/changes/SelectSource";
import {ApplyDefaultQuery} from "metadata/query/changes/ApplyDefaultQuery";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";
import {ArcQLConverter} from "app/query/ArcQLConverter";
import {HyperGraphChangeHandler, HyperGraphExecutor} from "app/query/hypergraph/HyperGraphExecutor";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQLBuilder} from "app/query/ArcQLBuilder";
import {QueryReason} from "engine/actor/QueryResason";
import {ArcQLGroupingFactory} from "metadata/query/ArcQLGroupingFactory";
import {ChangeSetType} from "metadata/ChangeSetType";
import {ProjectionValidation} from "metadata/query/ProjectionValidation";

/**
 * Delegate for query builder that transforms the query based on user interactions and broadcasts the changes to the
 * engine.
 *
 * @author zuyezheng
 */
export class QueryBuilderDelegate extends ArcActor {

    /**
     * Update local storage with the current metadata for the given FQN.
     */
    public readonly updateLocal: (fqn: FQN) => void;

    private dataset: ArcDataset;
    public readonly hyperGraphExecutor: HyperGraphExecutor;

    private readonly resultEventFilter: ResultEventFilter;
    private readonly statusEventFilter: ActorStatusEventFilter;

    constructor(
        id: string,
        dataset: ArcDataset,
        private readonly query: ArcMetadata<ArcQL>,
        private readonly onChange: (query: Optional<ArcQL>) => void,
        private readonly onResult: (result: Optional<ArcQLResponse>) => void,
        private readonly onActorStatus: (status: ActorStatus) => void,
        private readonly _onHyperGraphChange: HyperGraphChangeHandler
    ) {
        super(id);

        this.dataset = dataset;
        this.hyperGraphExecutor = new HyperGraphExecutor(
            // load the existing or start and empty hypergraph
            query.metadata.hyperGraph(this.dataset).getOr(HyperGraph.empty()),
            (next, previous) => this.onHyperGraphChange(next, previous)
        );
        this._onHyperGraphChange(this.hyperGraphExecutor.hyperGraph);

        this.resultEventFilter = new ResultEventFilter();
        this.statusEventFilter = new ActorStatusEventFilter();

        // after query results, let the UI settle (in case it gets crashy) and store a copy in local storage, we don't
        // want to immediately store incase the change causes a crash and we end up in an unrecoverable loop
        this.updateLocal = throttle(this._updateLocal, 1000, {trailing: true});
    }

    changePersona(newDataset: ArcDataset, newPersona: Optional<AssetSearchResult>) {
        this.dataset = newDataset;
        const changes: ArcMetadataChange<ArcQL>[] = [new SelectSource(
            this.dataset.fqn,
            newPersona
        )];
        // If there is no default query, just leave things alone I guess because trying to create a default query
        // that is just a minimal arcql ends up adding an ArcQLReplace change that prevents undoing
        if (this.dataset.defaultQueries.size > 0) {
            changes.push(
                new ApplyDefaultQuery(this.dataset.defaultQueries)
            );
        }
        this.applyChanges(changes);
    }

    setDataset(newDataset: ArcDataset) {
        this.dataset = newDataset;
    }

    changeInfo(info: AssetProps) {
        this.applyChanges([new ArcQLInfoChange(info)], QueryReason.REPLACE, false);
    }

    /**
     * Replace the entire arcql.
     */
    replace(arcql: ArcQL, reason: ReplaceReason) {
        // when query changes creates a new node, we select that node automatically in the hypergraph which triggers
        // a replacement (as you would when selecting nodes to navigate the graph), this causes us add the same query
        // twice to the undo stack so need to do a bit of deduping
        if (reason === ReplaceReason.HYPERGRAPH && this.query.metadata.hash() === arcql.hash()) {
            return;
        }

        this.applyChanges(
            [new ArcQLReplace(arcql, reason)],
            reason === ReplaceReason.VERSION ? QueryReason.VERSION_REPLACE : QueryReason.REPLACE
        );
    }

    /**
     * Return a flattened list of changes after the most recent persisted change from new to old .
     */
    get changes(): string[] {
        const changesBeforePersistence: string[] = [];
        this.query.changesZipped.some(change => {
            if (this.isChangePersisted(change.right)) {
                // found a persisted change, can stop
                return true;
            } else {
                changesBeforePersistence.push(...change.left);
                return false;
            }
        });

        return changesBeforePersistence;
    }

    /**
     * Serialize changes into a single string from new to old.
     */
    describeChanges(): string {
        return this.changes.map(c => '- ' + c).join('\n');
    }

    /**
     * If there are any unsaved changes.
     */
    get hasChanges(): boolean {
        const undoChanges = this.query.undoChanges;
        return undoChanges.length !== 0 && !this.isChangePersisted(undoChanges[0]);
    }

    /**
     * If the specific change indicates persistence (e.g. was saved or loading a saved asset).
     */
    private isChangePersisted(change: ArcMetadataChange<ArcQL>[]): boolean {
        return change.length === 1 && Optional.ofType(change[0], ArcQLReplace)
            .map(c => c.reason.isPersisted)
            .getOr(false);
    }

    /**
     * Explicitly take an FQN to save the asset with since new queries will not have an FQN.
     */
    private _updateLocal(fqn: FQN) {
        const localService = ServiceProvider.get(LocalStorageService);
        if (this.hasChanges) {
            // have changes, store it
            localService.storeAsset(this.query.metadata.with({
                hyperGraph: this.hyperGraphExecutor.hyperGraph.toJSON()
            }), fqn);
        } else {
            // changes persisted or no longer relevant, can clear local storage
            localService.clearAsset(fqn);
        }
    }

    /**
     * Add ArcQLField.
     */
    addField(field: ArcQLField) {
        this.applyChanges([new AddField(field)]);
    }

    /**
     * Add a dataset column to the query returning None if no warnings and a string if there was an issue.
     */
    addFieldByColumn(columnName: string): Optional<string> {
        return new ArcQLBuilder(this.dataset)
            .buildField(this.query.metadata, columnName)
            .fold(
                field => {
                    this.addField(field);
                    return Optional.none();
                },
                // error
                Optional.some
            );
    }

    /**
     * Add a dataset column to a suggested section of the query based on the current query, returning an optional string
     * if there was a error.
     */
    addFieldOrGrouping(columnName: string): Optional<string> {
        if (this.query.metadata.isGrouped()) {
            // if grouped, default all dimensions and dates as groupings
            const column = this.dataset.get(columnName);

            switch (column.analyticsType) {
                case AnalyticsType.DIMENSION:
                case AnalyticsType.DATE:
                    return this.addGroupingByColumnName(columnName);
                case AnalyticsType.MEASURE:
                    return this.addFieldByColumn(columnName);
            }
        } else {
            // if the query isn't grouped, just add it to fields
            return this.addFieldByColumn(columnName);
        }
    }

    convertGroupingToMetric(groupingField: string): Optional<string> {
        // make sure there is a grouping to move
        const grouping = this.query.metadata.groupings.get(groupingField);

        if (grouping.type === ArcQLGroupingType.EXPRESSION) {
            return new Optional('Can\'t convert an expression grouping to a metric.');
        }

        // see if we can add the grouping as a metric
        return new ArcQLBuilder(this.dataset)
            .buildField(this.query.metadata, grouping.field)
            .fold(
                f => {
                    // remove the grouping and add a new field
                    this.applyChanges([
                        new DeleteGrouping(groupingField, this.dataset),
                        new AddField(f)
                    ]);

                    return Optional.none();
                },
                Optional.some
            );
    }

    convertMetricToGrouping(metricAs: string): Optional<string> {
        const metric = this.query.metadata.fields.get(metricAs);

        if (metric instanceof ColumnField) {
            if (metric.isStar) {
                return Optional.some('Can\'t move aggregate on star to grouping.');
            }

            // delete the metric and add it as a grouping
            this.applyChanges([
                new DeleteField(metric.as),
                new AddGrouping(this.buildGrouping(metric.field), this.dataset)
            ]);
        } else if (metric instanceof ExpressionField) {
            this.applyChanges([
                new DeleteField(metric.as),
                new AddGrouping(new ExpressionGrouping(metric.expression, metric.as), this.dataset)
            ]);
        } else {
            return Optional.some('Can\'t move field type to grouping.');
        }

        return Optional.none();
    }

    deleteField(fieldAs: string) {
        this.applyChanges([new DeleteField(fieldAs)]);
    }

    /**
     * Modify the given field with the original as alias returning an optional warning.
     */
    modifyField(field: ArcQLField, originalAs: string): Optional<string> {
        const changes: ArcMetadataChange<ArcQL>[] = [new ModifyField(originalAs, field)];

        // if the projected field name changed
        if (field.as !== originalAs) {
            // validate uniqueness
            if (this.query.metadata.fields.has(field.as)) {
                return Optional.some(`Field with the same label '${field.as}' already exists, please try something unique.`);
            }

            // validate as has no illegal characters
            if (!ProjectionValidation.isValid(field.as)) {
                return Optional.some(`Field name '${field.as}' cannot contain ${ProjectionValidation.ILLEGAL_CHARS}.`);
            }

            // see if we need to change any aggregate filters referencing the old projected name
            this.query.metadata.aggregateFilters.clauses.forEach(
                (c: FilterClause, ordinal: number) => Optional.ofType(c, LiteralsFilterClause)
                    .filter(c => c.isFor([originalAs]))
                    .map(c => new ModifyFilter(
                        ordinal,
                        new LiteralsFilterClause(field.as, c.operator, c.values, true),
                        true,
                        this.dataset
                    ))
                    .forEach(c => changes.push(c))
            );
        }

        this.applyChanges(changes);

        return Optional.none();
    }

    moveField(as: string, ordinal: number) {
        this.applyChanges([new MoveField(as, ordinal)]);
    }

    private buildGrouping(columnName: string): ArcQLGrouping {
        const column = this.dataset.get(columnName);
        return ArcQLGroupingFactory.fromColumn(column);
    }

    addGroupingByColumnName(columnName: string): Optional<string> {
        // detail query, can't add groupings
        if (!this.query.metadata.isGrouped()) {
            return Optional.some('Can\'t add a grouping to a detail query, change to a grouped query first.');
        }

        this.applyChanges([new AddGrouping(this.buildGrouping(columnName), this.dataset)]);

        return Optional.none();
    }

    addGrouping(grouping: ArcQLGrouping): Optional<string> {
        // detail query, can't add groupings
        if (!this.query.metadata.isGrouped()) {
            return Optional.some('Can\'t add a grouping to a detail query, change to a grouped query first.');
        }

        this.applyChanges([new AddGrouping(grouping, this.dataset)]);

        return Optional.none();
    }

    modifyGrouping(originalField: string, grouping: ArcQLGrouping) {
        this.applyChanges([new ModifyGrouping(originalField, grouping, this.dataset)]);
    }

    deleteGrouping(fieldName: string) {
        this.applyChanges([new DeleteGrouping(fieldName, this.dataset)]);
    }

    moveGrouping(fieldName: string, ordinal: number) {
        this.applyChanges([new MoveGrouping(fieldName, ordinal, this.dataset)]);
    }

    /**
     * Change the order by for the given field. We only support ordering by a single field through the UI so we will
     * remove all previous order bys. If this order by is an exact match of the existing this will toggle the order by
     * off.
     */
    orderByField(fieldName: string, direction: Direction) {
        // delete the current order by if the direction is the same
        const deleteCurrent = this.query.metadata.orderBys.get(fieldName)
            .map(o => o.direction === direction)
            .getOr(false);

        // delete all the existing field order bys since only one at a time for now
        const changes = this.query.metadata.orderBys.all().map(
            orderBy => new DeleteOrderBy(orderBy.field, this.dataset)
        );

        // if we're not toggling the current grouping off, add it
        if (!deleteCurrent) {
            changes.push(new AddOrderBy(fieldName, direction, this.dataset));
        }

        this.applyChanges(changes);
    }

    /**
     * Start a new pre-aggregate filter for the given dataset column and returns the constructed clause.
     */
    startFilter(columnName: string): FilterClause {
        const column = this.dataset.get(columnName);
        return Optional.map(() => {
            switch (column.dataSuperType) {
                case DataSuperType.BOOLEAN:
                case DataSuperType.STRING:
                    return new LiteralsFilterClause(column.name, FilterOperator.IN, [], false);
                case DataSuperType.NUMBER:
                    return new LiteralsFilterClause(column.name, FilterOperator.BETWEEN, [], false);
                case DataSuperType.TIMESTAMP:
                    return DateFilterHelper.defaultClause(column);
            }
        }).map(clause => {
            this.addFilters([clause], false);
            return clause;
        }).get();
    }

    /**
     * Start a new post-aggregate filter for a given metric field, returning a optional string if there was an error.
     */
    startAggregateFilter(fieldName: string): Optional<string> {
        if (this.query.metadata.groupings.size === 0) {
            return Optional.some('Can only start aggregate filters on queries with 1 or more groupings.');
        }

        return this.query.metadata.fields.getPossible(fieldName)
            .map(() => {
                // aggregates are all measures so create a measure filter
                this.addFilters([
                    new LiteralsFilterClause(fieldName, FilterOperator.BETWEEN, [], true)
                ], true);

                return Optional.none<string>();
            })
            .getOr(Optional.some('Field does not exist on query.'));
    }

    /**
     * Modify an existing filter operator and/or values. This requires the filter index since its valid to have multiple
     * filter clauses on the same field.
     *
     * Additionally, supports FilterClause type switching (ex: SingleFieldFilterClause vs FilterSetFilterClause)
     */
    modifyFilter(ordinal: number, isAggregate: boolean, change: EditorFilterChange) {
        Optional.some(this.query.metadata.filtersFor(isAggregate).get(ordinal))
            .filter(clause => clause.type.editable)
            .forEach((clause: SingleFieldFilterClause) => {
                this.applyChanges([
                    new ModifyFilter(
                        ordinal,
                        FilterClauseFactory.fromFilterChange(clause, change, isAggregate),
                        isAggregate,
                        this.dataset
                    )
                ]);
            });
    }

    /**
     * Modify the current filter expression.
     */
    modifyFilterExpression(expression: string, isAggregate: boolean) {
        this.applyChanges([
            new ArcQLFilterExpressionChange(expression, isAggregate)
        ]);
    }

    /**
     * Resets the filter expression back to default by setting it to null.
     */
    resetFilterExpression(isAggregate: boolean) {
        this.modifyFilterExpression(null, isAggregate);
    }

    /**
     * Add additional filter clauses.
     */
    addFilters(clauses: FilterClause[], isAggregate: boolean) {
        this.applyChanges(clauses.map(c => new AddFilter(c, isAggregate, this.dataset)));
    }

    /**
     * Merge a new set of filters, removing any that are dupes.
     */
    mergeFilters(clauses: FilterClause[]) {
        const changes = [
            // add filters that aren't dupes
            ...clauses
                .filter(c => this.query.metadata.filters.clauses.every(existing => !existing.equals(c)))
                .map(c => new AddFilter(c, false, this.dataset))
        ];

        if (changes.length === 0) {
            return;
        }

        this.applyChanges(changes);
    }

    deleteFilter(ordinal: number, isAggregate: boolean) {
        this.applyChanges([
            new DeleteFilter(ordinal, isAggregate, this.dataset)
        ]);
    }

    changeLimit(limit: number) {
        this.applyChanges([
            new LimitChange(limit)
        ]);
    }

    /**
     * Build the set of changes to convert to a grouped query.
     */
    toGroupedChanges(): ArcMetadataChange<ArcQL>[] {
        return new ArcQLConverter(this.dataset).toGroupedChanges(this.query.metadata);
    }

    /**
     * Build the set of changes to convert to a detail query.
     */
    toDetailChanges(): ArcMetadataChange<ArcQL>[] {
        return new ArcQLConverter(this.dataset).toDetailChanges(this.query.metadata);
    }

    /**
     * Change a query from grouped to detail and vice versa, providing the desired grouping state.
     */
    toggleGrouped(grouped: boolean) {
        this.applyChanges(grouped ? this.toGroupedChanges() : this.toDetailChanges());
    }

    changeVizConfig(vizConfig: VisualizationConfig) {
        this.applyChanges([
            ArcQLVisualizationChange.default(vizConfig.type, vizConfig.config)
        ]);
        // after viz changes get applied, update what's stored in the hypergraph as well
        this.hyperGraphExecutor.changeVisualization(this.query.metadata.visualizations.default);
    }

    onHyperGraphChange(next: HyperGraph, previous: HyperGraph) {
        // only care about change with a working node
        next.workingNode.forEach(nextNode => {
            const query = nextNode.getQuery(next);
            // only need to update the query if a selection has changed (ignoring the initial selection when loading)
            if (previous.workingNode.map(p => p.id !== nextNode.id).getOr(false)) {
                // When replacing the current query with a new one from the hypergraph, we need to pass forward any
                // existing id for the query so that the builder knows the query still exists, as well as the label
                this.replace(query.with({
                    id: this.query.metadata.id,
                    label: this.query.metadata.label
                }), ReplaceReason.HYPERGRAPH);
            }

            // notify the callback
            this._onHyperGraphChange(next);
        });
    }

    undo() {
        if (this.query.undo()) {
            this.onChanged(QueryReason.UNDO);
        }
    }

    redo() {
        if (this.query.redo()) {
            this.onChanged(QueryReason.REDO);
        }
    }

    get hasUndo(): boolean {
        return this.query.hasUndo();
    }

    get hasRedo(): boolean {
        return this.query.hasRedo();
    }

    /**
     * Broadcast a metadata change to the engine and UI.
     */
    onChanged(reason: QueryReason = QueryReason.INTERACTION, publishMessage: boolean = true, changeSetType: ChangeSetType = ChangeSetType.NONE) {
        this.onChange(Optional.some(this.query.metadata));

        if (publishMessage) {
            // publishing a message will trigger a full requery, make this optional
            this.connector.publish(new ArcQLMessage(
                new ArcQLBundle(this.query.metadata, this.dataset),
                reason,
                changeSetType
            ));
        }
    }

    private applyChanges(
        changes: ArcMetadataChange<ArcQL>[],
        reason: QueryReason = QueryReason.INTERACTION,
        publishMessage: boolean = true
    ) {
        this.query.apply(changes);
        this.onChanged(reason, publishMessage, ChangeSetType.computeTypeFromChanges(changes));
    }

    eventFilters(): ArcEventFilter[] {
        return [
            this.resultEventFilter,
            this.statusEventFilter
        ];
    }

    notify(event: ArcEvent): void {
        this.resultEventFilter.filter(event).forEach(result => {
            // if the query was massaged by the server with the results, use the updated query parts with the
            // existing metadata and visualization
            const mergedQuery = this.query.metadata.withContent(result.response.arcql, true, false);
            if (result.response.arcql.hash() !== this.query.metadata.hash()) {
                this.replace(mergedQuery, ReplaceReason.SAVE);
            }
            // update the results as well
            const mergedResult = new ArcQLResponse(
                mergedQuery,
                result.response.result,
                result.response.changes,
                result.response.dataset
            );
            this.onResult(Optional.some(mergedResult));

            if (this.hyperGraphExecutor.hyperGraph.isEmpty) {
                this.hyperGraphExecutor.initializeGraph(mergedResult);
            } else {
                switch (result.reason) {
                    case QueryReason.INTERACTION:
                        const isDelete: boolean = result.changeSetType === ChangeSetType.DELETE;
                        this.hyperGraphExecutor.onUserQueryResponse(mergedResult, isDelete);
                        break;
                    case QueryReason.UNDO:
                        this.hyperGraphExecutor.onUndo(mergedResult);
                        break;
                    case QueryReason.REDO:
                        this.hyperGraphExecutor.onRedo(mergedResult);
                        break;
                    case QueryReason.VERSION_REPLACE:
                        this.hyperGraphExecutor.onVersionReplace(mergedResult);
                        break;
                }
            }
        });

        this.statusEventFilter.filter(event)
            .forEach(m => this.onActorStatus(m.status));
    }

}