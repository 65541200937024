import * as React from "react";
import {FunctionComponent, ReactNode, useEffect, useState} from "react";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {PersonaListParams} from "metadata/PersonaListParams";
import {DatasetInfo} from "app/query/components/DatasetInfo";
import {PersonaSelector} from "app/query/components/PersonaSelector";
import styled from "@emotion/styled";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {Optional} from "common/Optional";
import {ColumnsPicker, ColumnsPickerProps} from "app/query/components/ColumnsPicker";
import {FQN} from "common/FQN";
import {S as CanvasS} from "app/query/QueryBuilderCanvasS";

type Props = ColumnsPickerProps & {
    isOpen: boolean
    persona: Optional<FQN>

    onPersonaSelect: (persona: Optional<AssetSearchResult>) => void
    onClickHeader: () => void
}

const PERSONA_LIST_PARAMS: PersonaListParams = new PersonaListParams(10, 0);

/**
 * Compose the columns picker and some query builder specific components.
 *
 * @author zuyezheng
 */
export const DatasetPanel: FunctionComponent<Props> = (props: Props) => {

    const [personas, setPersonas] = useState<AssetSearchResult[]>([]);

    useEffect(() => {
        const controller = new AbortController();
        // For now, just loading the personas once
        ServiceProvider.get(MetadataService)
            .personaSearch(props.dataset.fqn, PERSONA_LIST_PARAMS, controller.signal)
            .then(r => r.match(resp => {
                setPersonas(resp.results);
            }, err => {
                ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, err.toString()
                );
            }));

        return () => controller.abort();
    }, []);

    const buildOpenPanel = (): ReactNode => {
        return <>
            <CanvasS.PanelTitle>Dataset</CanvasS.PanelTitle>
            <DatasetInfo dataset={props.dataset} onClickHeader={props.onClickHeader}/>
            <PersonaSelector
                disabled={props.isSaved}
                currentPersona={props.persona}
                personas={personas}
                onPersonaSelect={props.onPersonaSelect}
            />
            <ColumnsPicker {...props} />
        </>;
    };

    const buildClosedPanel = (): ReactNode => {
        return <DatasetInfo
            dataset={props.dataset}
            isVertical={true}
            onClickHeader={props.onClickHeader}
        />;
    };

    return <S.Container className={props.isOpen ? 'open' : 'closed'}>
        {props.isOpen ? buildOpenPanel() : buildClosedPanel()}
    </S.Container>;

};

class S {

    static Container = styled.div`
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        
        &.open {
            width: 275px;
        }
    `;

}