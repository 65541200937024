export const Colors = {
    hyperarcYellow: '#F8BF00',
    hyperarcYellowSecondary: '#F8BF00CC',
    hyperarcYellowTertiary: '#F8BF0099',
    hyperarcYellowChip: '#F8BF0026',
    hyperarcYellowToggled: '#F8BF0033',
    hyperarcBanner: '#F9F6EC',
    hyperarcYellowA: (a: number) => `rgba(248, 191, 0, ${a})`,

    borderGrey: '#E0E0E0',
    headerGrey: '#F8F8FA',
    backgroundGrey: '#FAFAFA',
    disabledGrey: '#F0F0F0',
    toggleButtonGrey: '#EEEEEE',

    iconPrimary: '#0000008A',
    iconSecondary: '#00000042',
    iconTertiary: '#0000001F',

    iconQuery: '#71BE71',
    iconDashboard: '#f06292',
    iconDataset: '#4845E7',
    iconFilterSet: '#FFAA05',
    iconMask: '#039BE5',
    iconYourData: '#039BE5',

    iconGreyBackground: '#AAA',

    textPrimary: '#000000DE',
    textSecondary: '#00000099',
    textTertiary: '#00000061',
    textDisabled: '#00000038',

    inputBlue: '#1976D2',
    lightBlue: '#039BE5',

    measureGreen: '#8BB88F',
    measurePrefix: 'rgba(151, 200, 151, 0.3)',
    measureBackground: 'rgba(151, 200, 151, 0.08)',
    measureHover: 'rgba(151, 200, 151, 0.15)',

    dimensionBlue: '#4397F8',
    dimensionPrefix: 'rgba(67, 151, 248, 0.3)',
    dimensionBackground: 'rgba(67, 151, 248, 0.08)',
    dimensionHover: 'rgba(67, 151, 248, 0.15)',

    filterGrey: '#BDBDBD',
    filterPrefix: 'rgba(189, 189, 189, 0.3)',
    filterBackground: 'rgba(189, 189, 189, 0.05)',
    filterHover: 'rgba(189, 189, 189, 0.15)',

    changePositive: '#4CAF50',
    changeNegative: '#EF5350',

    formError: '#D32f2F',
    formWarning: '#FFA000',
    formWarningA: (a: number) => `rgba(255, 160, 0, ${a})`,

    skeletonBase: '#EBE6D4',
    skeletonHighlight: '#E8D28A',
};

export const FontSizes = {
    xxLarge: '2rem',
    xLarge: '1.5rem',
    large: '1.2rem',

    medium: '1rem',

    small: '.857rem',
    xSmall: '.786rem',
    xxSmall: '.715rem',
};

export const BoxShadow = {
    level1: 'rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.06) 0px 0px 3px 0px',
    level2: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    level3: 'rgba(50, 50, 93, 0.07) 0px 1px 2px -1px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
};

export const Durations = {

    tooltipPrimaryEnterDelay: 400,
    tooltipPrimaryEnterNextDelay: 400,

    tooltipSecondaryEnterDelay: 1000,
    tooltipSecondaryEnterNextDelay: 800,

};

/** Preset Color Selection When Picking a New Color */
export const PresetColors = [
    Colors.backgroundGrey, '#D0021B', '#F5A623', Colors.hyperarcYellow, '#8B572A', '#7ED321', '#417505', '#9013FE',
    '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent'
];
