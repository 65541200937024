import type React from "react";

/**
 * Represents an action that can be performed within a table result row (like in DataGridPro).
 */
export class TableResultAction {

    constructor(
        public readonly id: string,
        public readonly label: string,
        public readonly icon: React.JSX.Element,
        public readonly disabled: boolean = false,
        public readonly color?: string
    ) {
    }

    equals(other: TableResultAction): boolean {
        return this.id === other.id;
    }
}
