import {DashboardPalette} from "app/dashboard/components/DashboardPalette";
import {BoxShadow, Colors, FontSizes} from "app/components/StyleVariables";
import {JsonPanel} from "app/components/dev/JsonPanel";
import styled from "@emotion/styled";
import {VersionsPanel} from "app/components/detailpanel/VersionsPanel";
import {DashboardConfigPanel} from "app/dashboard/components/DashboardConfigPanel";
import {StoriesPanel} from "app/components/detailpanel/StoriesPanel";
import {TrendsPanel} from "app/dashboard/components/TrendsPanel";
import {MAX_WIDTH_UNSET} from "metadata/dashboard/DashboardConfig";

export class S {

    static Dashboard = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    `;

    static Body = styled.div`
        display: flex;
        flex: 1;
        overflow: hidden;
    `;

    static Content = styled.div`
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;

        &.sharing {
            overflow: auto;

            > div {
                overflow: visible;
            }
        }
    `;

    static Palette = styled(DashboardPalette)`
        border-bottom: 1px solid ${Colors.borderGrey};
    `;

    // horizontally center the grid
    static GridCenter = styled.div<{ backgroundColor: string }>`
        background: ${(props) => props.backgroundColor};
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: auto;
        
        &.recording {
            background: rgba(0, 0, 0, 0.7);
        }
    `;

    // constrain the grid to a max width
    static GridWidthBoundary = styled.div<{
        maxWidth: number
    }>`
        display: flex;
        width: 100%;
        flex-direction: column;
        max-width: ${props => props.maxWidth <= MAX_WIDTH_UNSET ? 'none' : `${props.maxWidth}px`};

        &.recording {
            max-width: 1200px;
            justify-content: center;
        }
    `;

    // final viewport of the grid which can be controlled vertically as well
    static GridViewport = styled.div`
        overflow: visible;
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;

        &.recording {
            flex: none;
            aspect-ratio: 1.91/1;
            scrollbar-width: none;
            overflow: auto;
            position: relative;
        }
    `;

    // container for the grid and its components at full dimensions
    static GridContainer = styled.div<{
        backgroundColor: string
    }>`
        width: 100%;
        
        .react-grid-layout {
            width: 100%;
            padding-bottom: 200px;
            position: relative;
            background: ${(props) => props.backgroundColor} radial-gradient(#D4E2E8 1.3px, transparent 0);
            background-size: 20px 20px;

            &.view {
                background: ${(props) => props.backgroundColor};
                padding-bottom: 0;
            }

            .react-grid-placeholder {
                background: rgba(3, 155, 229, .1);
                border-radius: 4px;
            }
        }
    `;

    static Caption = styled.div`
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px;
        background: rgba(0, 0, 0, .8);
        color: white;
        font-size: ${FontSizes.xxLarge};
    `;

    static Widget = styled.div`
        // selectively taken from https://github.com/react-grid-layout/react-grid-layout/blob/master/css/styles.css
        &.selected {
            .react-resizable-handle {
                position: absolute;
                width: 20px;
                height: 20px;
                z-index: 2000;
            }

            .react-resizable-handle::after {
                content: "";
                position: absolute;
                right: 3px;
                bottom: 3px;
                width: 5px;
                height: 5px;
                border-right: 2px solid ${Colors.lightBlue};
                border-bottom: 2px solid ${Colors.lightBlue};
            }

            .react-resizable-handle.react-resizable-handle-se {
                bottom: 0;
                right: 0;
                cursor: se-resize;
            }
        }
    `;

    static DevPanel = styled(JsonPanel)`
        flex: 1;
        border-top: 1px solid ${Colors.borderGrey};
    `;

    static InlineEditor = styled.div`
        background: white;
        border-radius: 4px;
        box-shadow: ${BoxShadow.level3};
        margin: 6px 0;
        display: flex;
    `;

    static InlineEditorContainer = styled.div`
        width: 100%;
        padding: 4px;
    `;

    static InlineEditorContent = styled.div`
        width: 100%;
        height: 100%;
    `;

    static VersionsPanel = styled(VersionsPanel)`
        width: 300px;
        border-left: 1px solid ${Colors.borderGrey};
    `;

    static DashboardConfigPanel = styled(DashboardConfigPanel)`
        width: 300px;
        border-left: 1px solid ${Colors.borderGrey};
    `;

    static StoriesPanel = styled(StoriesPanel)`
        width: 300px;
        border-left: 1px solid ${Colors.borderGrey};
    `;

    static TrendsPanel = styled(TrendsPanel)`
        width: 50%;
        border-left: 1px solid ${Colors.borderGrey};
    `;
}