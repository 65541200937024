import {ProjectSearchResultsTable} from "app/components/search/ProjectSearchResultsTable";
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import React, {FunctionComponent} from "react";
import BrowseViewProps from "app/home/browse/BrowseViewProps";
import {S} from "app/home/browse/BrowseViewS";
import {AccountSearchResultsTable} from "app/components/search/AccountSearchResultsTable";
import {HyperGraphNodeSearchResultsTable} from "app/components/search/HyperGraphNodeSearchResultsTable";
import {Optional} from "common/Optional";

export const BrowseWithQueryView: FunctionComponent<BrowseViewProps> = (props: BrowseViewProps) => {

    return props.query.map(query =>
        <>
            {
                Optional.of(props.hyperGraphSearchParams)
                    .map(params =>
                        <S.HyperGraphAnswer
                            searchTerm={params.query}
                            onSelect={props.onAssetSelectSearchResult}
                        />
                    ).nullable
            }
            <S.ResultsHeader>{`Project Results for "${query}"`}</S.ResultsHeader>
            <ProjectSearchResultsTable
                searchParams={props.folderSearchParams}
                onSelect={props.onProjectSelect}
                onSearch={props.onSearchProjects}
                onAccountBreadcrumb={props.onAccountBreadcrumb}
            />
            <S.ResultsHeader>{`HyperGraph Results for "${query}"`}</S.ResultsHeader>
            <HyperGraphNodeSearchResultsTable
                searchParams={props.hyperGraphSearchParams}
                onSelect={props.onAssetSelectSearchResult}
                onAccountBreadcrumb={props.onAccountBreadcrumb}
            />
            <S.ResultsHeader>{`Asset Results for "${query}"`}</S.ResultsHeader>
            <AssetSearchResultsTable
                searchParams={props.assetSearchParams}
                onSelect={props.onAssetSelectSearchResult}
                rowType={SearchResultsRowType.TABLE}
                onSearch={props.onSearchAssets}
                onAccountBreadcrumb={props.onAccountBreadcrumb}
            />
            <S.ResultsHeader>{`Account Results for "${query}"`}</S.ResultsHeader>
            <AccountSearchResultsTable
                searchParams={props.accountSearchParams}
                onSelect={props.onAccountSelect}
                onSearch={props.onSearchAccounts}
            />
        </>
    ).nullable;

};