import {Enum} from "common/Enum";

export type NotificationCallback = (caller: string, severity: NotificationSeverity, message: string) => void;

/**
 * Global notifications service.
 *
 * @author zuyezheng
 */
export class NotificationsService {

    private readonly callbacks: Map<Object, NotificationCallback>;

    constructor() {
        this.callbacks = new Map();
    }

    /**
     * Register with a given receiver. The exact object needs to be used when unregistering.
     */
    register(receiver: Object, callback: NotificationCallback): void {
        this.callbacks.set(receiver, callback);
    }

    /**
     * Remove the callback.
     */
    unregister(receiver: Object): void {
        this.callbacks.delete(receiver);
    }

    /**
     * Publish a message.
     */
    publish(caller: string, severity: NotificationSeverity, message: string) {
        this.callbacks.forEach(c => c(caller, severity, message));
    }

}

export class NotificationSeverity extends Enum {

    static ERROR = new this('error');
    static WARNING = new this('warning');
    static INFO = new this('info');
    static SUCCESS = new this('success');

}
NotificationSeverity.finalize();