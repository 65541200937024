import {Asset} from "metadata/Asset";
import {AssetType} from "metadata/AssetType";
import {FQN} from "common/FQN";
import {JsonObject} from "common/CommonTypes";
import {DatasetV2Column, DatasetV2ColumnJson} from "metadata/dataset/DatasetV2Column";
import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetConfig} from "metadata/dataset/DatasetConfig";
import {DatasetConfigFactory} from "metadata/dataset/DatasetConfigFactory";

/**
 * Asset definition of DatasetV2, used for updating the entity
 */
export class DatasetV2 implements Asset {

    static fromJSON(json: JsonObject): DatasetV2 {
        const type: DatasetType = DatasetType.get(json['type']);
        const columns: Map<string, DatasetV2Column> = new Map();
        for (const columnName in json['columns']) {
            const columnJson: DatasetV2ColumnJson = json['columns'][columnName];
            const column = DatasetV2Column.fromJSON(columnJson);
            columns.set(column.name, column);
        }
        return new DatasetV2(
            json['id'],
            json['name'],
            json['label'],
            json['description'],
            type,
            DatasetConfigFactory.fromJSON(type, json['config']),
            columns,
            json['fullyQualifiedName'] && FQN.parse(json['fullyQualifiedName']),
            json['version'],
            json['folderId'],
            json['parentId']
        );
    }

    public constructor(
        // id should default to null until saved as it's the indicator for a new vs existing asset
        public readonly id: string = null,
        // these should default to null so we know when to apply defaults vs user explicitly set an empty string
        public readonly name: string = null,
        public readonly label: string = null,
        public readonly description: string = null,
        public readonly type: DatasetType = null,
        public readonly config: DatasetConfig = null,
        public readonly columns: Map<string, DatasetV2Column> = new Map(),
        public readonly fullyQualifiedName: FQN = null,
        public readonly version: number = -1,
        public readonly folderId: string = null,
        public readonly parentId: string = null
    ) {
    }

    get assetType(): AssetType {
        return AssetType.DATASET_V2;
    }

    get fqn(): FQN {
        return this.fullyQualifiedName;
    }

    get isExisting(): boolean {
        return this.id != null;
    }

    with({
        name,
        label,
        description,
        type,
        config,
        columns
    }: Partial<DatasetV2>): DatasetV2 {
        return new DatasetV2(
            this.id,
            name ?? this.name,
            label ?? this.label,
            description ?? this.description,
            type ?? this.type,
            config ?? this.config,
            columns ?? this.columns,
            this.fullyQualifiedName,
            this.version,
            this.folderId,
            this.parentId
        );
    }

    toJSON(): JsonObject {
        const columns: JsonObject = {};
        for (const column of this.columns.values()) {
            columns[column.name] = column;
        }
        return {
            id: this.id,
            name: this.name,
            label: this.label,
            description: this.description,
            type: this.type.name,
            config: this.config,
            columns: columns
        };
    }

    toLocalStorageJSON(fqn: FQN): JsonObject {
        return {
            ...this.toJSON(),
            // stuff we normally wouldn't pass to the server for saving
            fullyQualifiedName: fqn.toString(),
            version: this.version
        };
    }
}