import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React, {ChangeEvent, FunctionComponent, KeyboardEvent, ReactNode, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";

interface Props {
    label: string
    value: string
    onChange: (value: string) => void;
    variant?: 'standard' | 'outlined' | 'filled';
    placeholder?: string;
    margin?: 'dense' | 'normal' | 'none';
    error?: boolean;
    helperText?: ReactNode;
}

/**
 * Wrapped text field that only calls the change event when blur or enter is pressed.
 *
 * @author zuyezheng
 */
export const BlurTextField: FunctionComponent<Props> = (props: Props) => {

    const [value, setValue] = useState<string>(props.value || '');

    useEffect(() => {
        // handle if value set to null -> default to empty string
        setValue(props.value || '');
    }, [props.value]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        props.onChange(value);
    };

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            props.onChange(value);
        }
    };

    const variant = props.variant || 'standard';
    const margin = props.margin || 'dense';

    return <FormControl fullWidth size="small">
        <S.TextField
            variant={variant}
            size="small"
            margin={margin}
            label={props.label}
            value={value}
            placeholder={props.placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            error={props.error}
            helperText={props.helperText}
            color={props.helperText ? 'warning' : 'primary'}
        />
    </FormControl>;

};

class S {

    static readonly TextField = styled(TextField)`
        .MuiInputBase-colorWarning {
            & fieldset {
                border-color: ${Colors.formWarning};
            }
            &:hover fieldset {
                border-color: ${Colors.formWarning};
            }
            &.Mui-focused fieldset {
                border-color: ${Colors.formWarning};
            }
        }
    `;
}