import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import { OrderedMap } from "common/OrderedMap";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

/**
 * Holder of versions of a specific asset type.
 *
 * @author zuyezheng
 */
export class AssetVersions {

    static fromJSON(json: JsonObject): AssetVersions {
        return new AssetVersions(
            json['versions'].map(AssetVersion.fromJSON),
            new Map(
                Object.entries<JsonObject[]>(json['forks']).map(
                    ([id, forks]: [string, JsonObject[]]) => [id, forks.map(AssetVersion.fromJSON)]
                )
            ),
            Optional.of(json['parent']).map(AssetVersion.fromJSON).nullable
        );
    }

    // ordered versions keyed off of asset id
    public readonly versions: OrderedMap<string, AssetVersion>;

    private constructor(
        versions: AssetVersion[],
        public readonly forks: Map<string, AssetVersion[]>,
        public readonly parent: AssetVersion
    ) {
        this.versions = OrderedMap.fromKeyed(versions, v => v.id);
    }

    /**
     * Get the forks for the given version, returning empty array if none.
     */
    forksFor(version: AssetVersion): AssetVersion[] {
        return Optional.of(this.forks.get(version.id)).getOr([]);
    }

}

export class AssetVersion {

    static fromJSON(json: JsonObject): AssetVersion {
        return new AssetVersion(
            json['id'],
            json['name'],
            json['version'],
            json['parentId'],
            json['label'],
            json['description'],
            dayjs(json['createdOn']),
            json['createdBy'],
            json['isArchived']
        );
    }

    private constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly version: number,
        public readonly parentId: string,
        public readonly label: string,
        public readonly description: string,
        public readonly createdOn: Dayjs,
        public readonly createdBy: string,
        public readonly isArchived: boolean
    ) {}

}