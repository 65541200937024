import { JsonObject } from "common/CommonTypes";
import { AssetType } from "metadata/AssetType";
import {ArcQLContext, ArcQLContextJson} from "metadata/search/ArcQLContext";

/**
 * Additional asset-specific context provided with an asset search result
 */
export interface AssetContext {
    type: AssetType;
}

export class AssetContextFactory {
    static fromJSON(json: JsonObject): AssetContext {
        if (!json) {
            return undefined;
        }

        switch (AssetType.get(json.type)) {
            case AssetType.ARCQL:
                return ArcQLContext.fromJSON(json as ArcQLContextJson);
            default:
                return undefined;
        }
    }
}