import {ArcMetadata} from "metadata/ArcMetadata";
import {Optional} from "common/Optional";
import {ArcEvent} from "engine/ArcEvent";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcActor} from "engine/actor/ArcActor";
import {ArcMetadataChange} from "metadata/ArcMetadataChange";
import {AssetProps} from "metadata/Asset";
import {ReplaceReason} from "metadata/ReplaceReason";
import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {LocalStorageService} from "services/LocalStorageService";
import throttle from 'lodash/debounce';
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";
import {ArcFilterSetInfoChange} from "metadata/filterset/changes/ArcFilterSetInfoChange";
import {ArcFilterSetReplace} from "metadata/filterset/changes/ArcFilterSetReplace";
import {ArcFilterSetValuesChange} from "metadata/filterset/changes/ArcFilterSetValuesChange";


/**
 * Delegate for filter set builder. Due to its simplicity, mainly to propagate callback for filter set metadata changes.
 */
export class FilterSetBuilderDelegate extends ArcActor {

    public readonly updateLocal: (fqn: FQN) => void;


    constructor(
        id: string,
        private readonly filterSet: ArcMetadata<ArcFilterSet>,
        private readonly onChange: (filterSet: Optional<ArcFilterSet>) => void
    ) {
        super(id);

        // let the UI settle (in case it gets crashy) and store a copy in local storage, we don't
        // want to immediately store incase the change causes a crash and we end up in an unrecoverable loop
        this.updateLocal = throttle(this._updateLocal, 1000, {trailing: true});
    }

    undo() {
        if (this.filterSet.undo()) {
            this.onChanged();
        }
    }

    redo() {
        if (this.filterSet.redo()) {
            this.onChanged();
        }
    }

    get hasUndo(): boolean {
        return this.filterSet.hasUndo();
    }

    get hasRedo(): boolean {
        return this.filterSet.hasRedo();
    }

    changeInfo(info: AssetProps) {
        this.filterSet.apply([new ArcFilterSetInfoChange(info)]);
        this.onChanged(false);
    }


    /**
     * Replace the entire filter set.
     */
    replace(filterSet: ArcFilterSet, reason: ReplaceReason) {
        this.filterSet.apply([new ArcFilterSetReplace(filterSet, reason)]);
        this.onChanged();
    }

    /**
     * Return a flattened list of changes after the most recent persisted change from new to old .
     */
    get changes(): string[] {
        const changesBeforePersistence: string[] = [];
        this.filterSet.changesZipped.some(change => {
            if (this.isChangePersisted(change.right)) {
                // found a persisted change, can stop
                return true;
            } else {
                changesBeforePersistence.push(...change.left);
                return false;
            }
        });

        return changesBeforePersistence;
    }

    /**
     * Serialize changes into a single string from new to old.
     */
    describeChanges(): string {
        return this.changes.map(c => '- ' + c).join('\n');
    }

    /**
     * If there are any unsaved changes.
     */
    get hasChanges(): boolean {
        const undoChanges = this.filterSet.undoChanges;
        return undoChanges.length !== 0 && !this.isChangePersisted(undoChanges[0]);
    }

    /**
     * If the specific change indicates persistence (e.g. was saved or loading a saved asset).
     */
    private isChangePersisted(change: ArcMetadataChange<ArcFilterSet>[]): boolean {
        return change.length === 1 && Optional.ofType(change[0], ArcFilterSetReplace)
            .map(c => c.reason.isPersisted)
            .getOr(false);
    }

    /**
     * Explicitly take an FQN to save the asset with since new queries will not have an FQN.
     */
    private _updateLocal(fqn: FQN) {
        const localService = ServiceProvider.get(LocalStorageService);
        if (this.hasChanges) {
            // have changes, store it
            localService.storeAsset(this.filterSet.metadata, fqn);
        } else {
            // changes persisted or no longer relevant, can clear local storage
            localService.clearAsset(fqn);
        }
    }

    /**
     * Modify the current filter set values.
     */
    modifyValues(values: string[]) {
        this.filterSet.apply([
            new ArcFilterSetValuesChange(values)
        ]);
        this.onChanged();
    }


    /**
     * Broadcast a metadata change to the engine and UI.
     */
    onChanged(publishMessage: boolean = true) {
        this.onChange(Optional.some(this.filterSet.metadata));
    }

    eventFilters(): ArcEventFilter[] {
        return [];
    }

    notify(event: ArcEvent): void {
    }

}