import {VizSelection} from "engine/actor/VizSelection";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";

export type AdditionalAttributeFactory = (
    categoryValues?: any[], selections?: VizSelection, row?: any[], index?: string, formatters?: ResultValueFormatter[]
) => {[key: string]: any}

/**
 * Simple single metric data.
 *
 * @author zuyezheng
 */
export class SingleMetricData {

    private readonly metricIndex: number;
    private readonly categoryColumnIndices: number[];

    constructor(
        private readonly response: ArcQLResponse,
        // factory to add additional attributes to each data point
        private readonly additionalAttributes: AdditionalAttributeFactory = null,
        // optionally specific a specific metric index
        metricIndex: number = null
    ) {
        const result = this.response.result;
        this.metricIndex = metricIndex || result.columnIndices.get(
            result.columnsByType(new Set([AnalyticsType.MEASURE]))[0]
        );
        this.categoryColumnIndices = result.categoryColumns.map(c => c.right);
    }

    dataset(selections: VizSelection): {[key: string]: any}[] {
        const result = this.response.result;
        return result.mapRows(
            (row: any[], index: string, formatters: ResultValueFormatter[]) => {
                const categoryValues = result.categoryColumns.map(c => row[c.right]);

                const dataPoint = {
                    label: result.rowLabel(
                        row,
                        this.categoryColumnIndices,
                        this.response.arcql.isGrouped() ? null : index
                    ),
                    value: row[this.metricIndex],
                    // raw categorical values to make selections easier
                    categoryValues: result.categoryColumns.map(c => row[c.right])
                };

                return this.additionalAttributes == null
                    ? dataPoint
                    : Object.assign(
                        dataPoint,
                        this.additionalAttributes(categoryValues, selections, row, index, formatters)
                    );
            }
        );
    }

}