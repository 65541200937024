import {JsonObject} from "common/CommonTypes";

export const SearchArcQLTemplate = {

    transactionSearch: (transactionHash: string): JsonObject => {
        return {
            "source": {
                "type": "single",
                "ref": {
                    "type": "dataset",
                    "fullyQualifiedName": "hyperarc/public/dataset/ethereumTransfers"
                }
            },
            "fields": [
                {
                    "type": "detailDate",
                    "as": "Block Timestamp",
                    "field": "blockTimestamp",
                    "grain": "second"
                },
                {
                    "type": "detail",
                    "as": "Block Number",
                    "field": "blockNumber",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Chain",
                    "field": "chain",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Transaction Hash",
                    "field": "transactionHash",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "From Address",
                    "field": "from",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "To Address",
                    "field": "to",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Contract Address",
                    "field": "contractAddress",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Transfer Type",
                    "field": "transferType",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Token Type",
                    "field": "tokenType",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Value",
                    "field": "valueDouble",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Value (USD)",
                    "field": "valueUSD",
                    "operator": "all"
                }
            ],
            "filters": {
                "clauses": [
                    {
                        "type": "single",
                        "field": "transactionHash",
                        "operator": "in",
                        "values": [transactionHash]
                    },
                    {
                        "type": "single",
                        "field": "blockTimestamp",
                        "operator": "greaterThanEqual",
                        "values": [
                            "2 years ago"
                        ]
                    }
                ]
            },
            "aggregateFilters": {
                "clauses": []
            },
            "groupings": [],
            "orderBys": [],
            "limit": 500,
            "id": null,
            "name": null,
            "label": null,
            "description": null,
            "visualizations": [
                {
                    "name": "default",
                    "label": "Default",
                    "type": "table",
                    "config": {
                        "pivot": true
                    }
                }
            ]
        };
    },

    walletSearch: (wallet: string): JsonObject => {
        return {
            "source": {
                "type": "single",
                "ref": {
                    "type": "dataset",
                    "fullyQualifiedName": "hyperarc/public/dataset/interactions"
                }
            },
            "fields": [
                {
                    "type": "detail",
                    "as": "Wallet Address",
                    "field": "from",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Transaction To - Category 3 (Project Name)",
                    "field": "transactionToCategory3",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Chain",
                    "field": "chain",
                    "operator": "all"
                },
                {
                    "type": "detailDate",
                    "as": "Block Timestamp - Month",
                    "field": "blockTimestampMonth",
                    "grain": "second"
                },
                {
                    "type": "detail",
                    "as": "Transfers - Total",
                    "field": "transfers",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Transactions - Total",
                    "field": "transactions",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Value (USD) - Sum",
                    "field": "totalValueUSD",
                    "operator": "all"
                },
                {
                    "type": "detail",
                    "as": "Wallet Age - Months",
                    "field": "walletAgeMonths",
                    "operator": "all"
                }
            ],
            "filters": {
                "clauses": [
                    {
                        "type": "single",
                        "field": "from",
                        "operator": "in",
                        "values": [wallet]
                    }
                ]
            },
            "aggregateFilters": {
                "clauses": []
            },
            "groupings": [],
            "orderBys": [],
            "limit": 500,
            "id": null,
            "name": null,
            "label": null,
            "description": null,
            "visualizations": [
                {
                    "name": "default",
                    "label": "Default",
                    "type": "table",
                    "config": {
                        "pivot": true
                    }
                }
            ]
        };
    }

};
