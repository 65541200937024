import * as React from 'react';
import styled from "@emotion/styled";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import {ToolbarAction} from 'app/components/toolbar/ToolbarAction';
import {Colors} from 'app/components/StyleVariables';
import Tooltip from '@mui/material/Tooltip';

export const HyperGraphAction = (id: string, label: string, tooltip: string): ToolbarAction =>
    new ToolbarAction(
        id,
        label,
        false,
        false,
        {
            content: <S.Container>
                <AutoAwesomeOutlinedIcon />
                <Tooltip title={<span style={{ whiteSpace:'pre-line'}}>{tooltip}</span>} placement="bottom" arrow>
                    <div>{label}</div>
                </Tooltip>
            </S.Container>
        }
    );

class S {

    static Container = styled.div`
        display: flex;
        
        svg {
            color: ${Colors.hyperarcYellow};
            width: 18px;
            margin-left: -2px;
            margin-right: 10px;
        }
    `;

}