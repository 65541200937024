import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";

/**
 * @author zuyezheng
 */
export class LineChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON_WITH_CARTESIAN_AND_LEGEND,
        [
            new VizBooleanProperty('scroll', 'Scroll'),
            new VizBooleanProperty('showValues', 'Show Values')
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', true],
        ['scroll', true]
    ]);

    get properties(): VizProperty<any>[] {
        return LineChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return LineChartDefinition.DEFAULTS;
    }

}