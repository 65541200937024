import {BaseWidgetConfigPart} from "metadata/dashboard/widgets/config/BaseWidgetConfigPart";
import {JsonObject} from "common/CommonTypes";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";
import {DropShadow} from "metadata/dashboard/widgets/config/DropShadow";
import {Border} from "metadata/dashboard/widgets/config/Border";
import {BorderVisibility} from "metadata/dashboard/widgets/config/BorderVisibility";


interface ContainerConfigProps {
    padding: number;
    backgroundColor: string;
    borderRadius: number;
    borderWidth: number;
    borderColor: string;
    dropShadow: DropShadow;
    borderVisibility: Set<Border>;
}

/**
 * Properties for configuring a widget's container.
 */
export class ContainerConfigPart extends BaseWidgetConfigPart<ContainerConfigPart> {

    constructor(
        public readonly padding: number,
        public readonly backgroundColor: string,
        public readonly borderRadius: number,
        public readonly borderWidth: number,
        public readonly borderColor: string,
        public readonly dropShadow: DropShadow,
        public readonly borderVisibility: Set<Border>,
        defaults: ContainerConfigPart
    ) {
        super(defaults);
    }

    protected get properties(): (keyof ContainerConfigPart)[] {
        return ['padding', 'backgroundColor', 'borderRadius', 'borderWidth', 'borderColor', 'dropShadow', 'borderVisibility'];
    }

    get type(): WidgetConfigPartType {
        return WidgetConfigPartType.CONTAINER;
    }

    createInstance(props: Record<keyof ContainerConfigPart, any>, defaults: ContainerConfigPart): ContainerConfigPart {
        return new ContainerConfigPart(
            props.padding,
            props.backgroundColor,
            props.borderRadius,
            props.borderWidth,
            props.borderColor,
            props.dropShadow,
            props.borderVisibility,
            defaults
        );
    }

    toProps(): ContainerConfigProps {
        return {
            padding: this.getValue('padding'),
            backgroundColor: this.getValue('backgroundColor'),
            borderRadius: this.getValue('borderRadius'),
            borderWidth: this.getValue('borderWidth'),
            borderColor: this.getValue('borderColor'),
            dropShadow: this.getValue('dropShadow'),
            borderVisibility: this.getValue('borderVisibility')
        };
    }

    static fromJSON(configJson: JsonObject, defaults: ContainerConfigPart = ContainerConfigPart.defaults()): ContainerConfigPart {
        return BaseWidgetConfigPart.fromJSONHelper(
            configJson,
            defaults,
            {
                'dropShadow': (value) => DropShadow.get(value),
                'borderVisibility': (value) =>
                    new Set(
                        (value as []).map(v => Border.get(v))
                    )
            }
        );
    }

    static defaults(): ContainerConfigPart {
        return new ContainerConfigPart(
            8,
            'white',
            4,
            1,
            'transparent',
            DropShadow.LIGHT,
            BorderVisibility.NONE.borders,
            null
        );
    }
}