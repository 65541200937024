import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ColumnInfosResponse} from "metadata/dataset/ColumnInfosResponse";
import {Column} from "metadata/Column";

/**
 * Service for supplementing building a hypergraph dataset such as generating column descriptions.
 */
export abstract class HyperGraphDatasetService {

    /**
     * Generate the description of the columns in a dataset.
     */
    abstract generateColumnInfos(
        datasetLabel: string,
        datasetFqn: string,
        datasetDescription: string,
        columns: Column[],
        signal?: AbortSignal,
    ): Promise<Either<ErrorResponse, ColumnInfosResponse>>;

}