import * as React from "react"
import {SVGProps} from "react"

const DonutIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M13 5.58a7 7 0 0 1 5.92 5.92h3.03c-.47-4.72-4.23-8.48-8.95-8.95v3.03Zm5.92 7.92A7 7 0 0 1 13 19.42v3.03c4.72-.47 8.48-4.23 8.95-8.95h-3.03ZM11 19.42c-3.39-.49-6-3.4-6-6.92s2.61-6.43 6-6.92V2.55c-5.05.5-9 4.76-9 9.95 0 5.19 3.95 9.45 9 9.95v-3.03Z"
        />
    </svg>
);
export default DonutIcon;
