import {HyperGraph, WorkingNodeStatus} from "metadata/hypergraph/HyperGraph";
import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {EditType, HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {ServiceProvider} from "services/ServiceProvider";
import {HyperGraphService} from "services/hypergraph/HyperGraphService";
import {RequestNode, RequestState} from "metadata/hypergraph/nodes/RequestNode";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryContainer, QueryReference} from "metadata/hypergraph/nodes/QueryContainer";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {QueryContainerNode} from "metadata/hypergraph/nodes/QueryContainerNode";
import {QueryService} from "services/QueryService";
import {Optional} from "common/Optional";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import debounce from "lodash.debounce";
import {NodeChange} from "reactflow";
import {NodePositionChange} from "@reactflow/core/dist/esm/types/changes";
import {HyperGraphNodeLayout} from "metadata/hypergraph/HyperGraphNodeLayout";
import {HyperGraphProcess} from "app/query/hypergraph/HyperGraphProcess";
import { HyperGraphNodeFactoryOfFactory } from "metadata/hypergraph/HyperGraphTypes";
import { NodeRating } from "metadata/hypergraph/NodeRating";
import { ArcDataset } from "metadata/dataset/ArcDataset";
import { VisualizationConfig } from "metadata/query/ArcQLVisualizations";


export type HyperGraphChangeHandler = (next: HyperGraph, previous?: HyperGraph) => void;

/**
 * Helps with execution of operations on a HyperGraph.
 *
 * @author zuyezheng
 */
export class HyperGraphExecutor {

    // flag for if we should layout
    private shouldLayout;

    // debounced finalization of the selected node if the node is still selected
    private readonly finalizeSelected: (nodeId: string) => void;

    constructor(
        private _hyperGraph: HyperGraph,
        private readonly _onChange: HyperGraphChangeHandler
    ) {
        this.shouldLayout = true;
        this.finalizeSelected = debounce((nodeId: string) => {
            if (this._hyperGraph.selectedNodeIds.has(nodeId)) {
                this.finalizeNode(nodeId);
            }
        }, 4000);
    }

    get hyperGraph(): HyperGraph {
        return this._hyperGraph;
    }

    batchLayout<T>(doStuff: () => T): T {
        // do some stuff with layout paused
        this.shouldLayout = false;
        const result = doStuff();
        this.shouldLayout = true;

        // layout all the batched changes
        this.onChange(this._hyperGraph.layoutNewNodes());

        return result;
    }

    selectNodes(nodeIds: string[]) {
        // see if there are changes
        const updated = this._hyperGraph.withSelections(nodeIds);
        if (updated === this._hyperGraph) {
            return false;
        }

        // update the hypergraph with the new selections, but first build the list of prior selections to finalize
        const newSelectedNodes = updated.selectedNodeIds;
        const nodesToFinalize = [...this._hyperGraph.selectedNodeIds].filter(s => !newSelectedNodes.has(s));
        this.onChange(updated);

        nodesToFinalize.forEach(n => this.finalizeNode(n));

        return true;
    }

    /**
     * Select a node in the graph, returning true if there was a change.
     */
    selectNode(nodeId: string): boolean {
        return this.selectNodes([nodeId]);
    }

    /**
     * Reset selection back to the working node.
     */
    resetSelection(): boolean {
        return this._hyperGraph.workingNode
            .map(n => this.selectNode(n.id))
            .getOr(false);
    }

    upsertNode<N extends HyperGraphNode>(node: N, broadcast: boolean = true): N {
        let updated = this._hyperGraph.upsert(node);
        if (this.shouldLayout) {
            updated = updated.layoutNewNodes();
        }
        this.onChange(updated, broadcast);
        return node;
    }

    changeRating(nodeId: string, rating: NodeRating): HyperGraphNode {
        return this.upsertNode(
            this._hyperGraph.get(nodeId).with({rating})
        );
    }

    /**
     * Update and store the visualization config for the working node, this will not broadcast the change since nothing
     * else is being updated.
     */
    changeVisualization(config: VisualizationConfig): void {
        this.onChange(
            this._hyperGraph.withVisualizationConfig(config),
            false
        );
    }

    async onChangeContent(nodeId: string, type: EditType, content: string): Promise<HyperGraphNode> {
        const nodeWithEmbedding = await this.updateEmbedding(
            this._hyperGraph.get(nodeId).withEdit(type, content)
        );

        return this.upsertNode(nodeWithEmbedding);
    }

    /**
     * Handle specific node changes we care about.
     */
    onNodesChange(changes: NodeChange[]): void {
        // react flow seems to bundle all like changes together so use that assumption for processing.
        const changeType = new Set(changes.map(c => c.type));
        if (changeType.size !== 1) {
            return;
        }

        // only care about position changes
        if (changeType.has('position')) {
            // process node positioning changes
            let hasChanges = false;
            const newLayout = this._hyperGraph.layouts;
            changes.forEach((change: NodePositionChange) => {
                if (change.position == null) {
                    // it happens I guess?
                    return;
                }

                const currentLayout = newLayout.get(change.id);
                if (currentLayout == null) {
                    // shouldn't happen since we don't draw nodes without layouts yet
                    return;
                }

                if (currentLayout.x !== change.position.x || currentLayout.y !== change.position.y) {
                    hasChanges = true;
                    newLayout.set(change.id, new HyperGraphNodeLayout(
                        change.position.x,
                        change.position.y,
                        currentLayout.width,
                        currentLayout.height
                    ));
                }
            });

            if (hasChanges) {
                this.onChange(this._hyperGraph.withLayout(newLayout));
            }
        }
    }

    onNodeClick(nodeId: string, multiSelect: boolean, onSelectNodes: (nodeIds: string[]) => void): void {
        // figure out what to do with the selection
        const curSelections = this._hyperGraph.selectedNodeIds;
        const select = () => {
            if (multiSelect) {
                // toggle from the existing selections list
                if (curSelections.has(nodeId)) {
                    return [...curSelections].filter(s => s !== nodeId);
                } else {
                    return [...curSelections, nodeId];
                }
            } else {
                // single select
                return [nodeId];
            }
        };

        // always need a selection
        const selections = select();
        if (selections.length > 0) {
            this.selectNodes(selections);
            onSelectNodes(selections);
        }
    }

    execute(process: HyperGraphProcess): void {
        process.start(this);
    }

    async newCompletion<RQ extends HyperGraphRequest, RS, N extends HyperGraphNode<RQ>>(
        request: RQ,
        factoryOfFactory: HyperGraphNodeFactoryOfFactory<RQ, RS, N>,
        dataset: ArcDataset
    ): Promise<[N, HyperGraph]> {
        const hyperGraphService = ServiceProvider.get(HyperGraphService);

        const inProgressNode = this.upsertNode(new RequestNode(request));
        const nodeResponse = await hyperGraphService.completeNode(
            inProgressNode,
            factoryOfFactory(request, inProgressNode.id),
            this._hyperGraph,
            dataset
        );

        const nodeWithEmbedding = await this.updateEmbedding(nodeResponse.rightOrThrow());
        return [this.upsertNode(nodeWithEmbedding), this._hyperGraph];
    }

    async newQuery(
        parent: HyperGraphNode,
        query: ArcQL,
        operation: HyperGraphNodeOperation,
        hypothesis: HyperGraphNodeHypothesis
    ): Promise<[QueryContainerNode, HyperGraph]> {
        const queryService = ServiceProvider.get(QueryService);

        const queryReference = new QueryReference(parent.id);
        const queryingContainer = new QueryContainer(queryReference, query, operation, hypothesis);
        const requestNode = this.upsertNode(new RequestNode(queryingContainer));

        const newMeasuresResponse = await queryService.query(query);
        return new Promise(
            (resolve, reject) => newMeasuresResponse.match(
                async (response) => {
                    const node = new QueryContainerNode(
                        new QueryContainer(queryReference, response.arcql, operation, hypothesis),
                        response.result,
                        response.dataset,
                        requestNode.id
                    );
                    resolve([this.upsertNode(node), this._hyperGraph]);
                },
                error => {
                    console.log(error);
                    this.upsertNode(requestNode.with({
                        state: RequestState.ERROR
                    }));
                    reject(error);
                }
            )
        );
    }

    /**
     * Initialize an empty graph.
     *
     * TODO ZZ might want to merge this and initialize a "starting" graph vs using HyperGraph.empty().
     */
    initializeGraph(response: ArcQLResponse): HyperGraphNode {
        // new graph, create the root node
        const queryNode = new QueryContainerNode(
            new QueryContainer(
                QueryReference.empty(),
                response.arcql,
                HyperGraphNodeOperation.SYSTEM,
                new SimpleHypothesis('The Start', 'What are you going to discover?')
            ),
            response.result,
            response.dataset
        );

        this.upsertNode(queryNode, false);
        this.selectNode(queryNode.id);
        return queryNode;
    }

    /**
     * New user query change, return the node representing the change if it was possible to modify or create one.
     */
    onUserQueryResponse(response: ArcQLResponse, isDelete: boolean): Optional<HyperGraphNode> {
        const [workingNode, workingStatus] = this._hyperGraph.workingState;

        // the branching of a new user query will be off of the working node
        if (workingNode.getQuery(this._hyperGraph).equals(response.arcql)) {
            // if the query is unchanged, don't need to do anything
            return Optional.none();
        } else {
            // check for any relatives that might be the same query to avoid creating duplicates. If we just took a
            // delete action, we want to look at the parents to see if there was something without whatever got deleted.
            // Otherwise, we just look at the descendants.
            const relatives = isDelete ?
                this._hyperGraph.ancestors(workingNode.id) :
                this._hyperGraph.descendents(workingNode.id);
            const match = relatives
                .find(c => c.getQuery(this._hyperGraph).equals(response.arcql));
            // found the same query in the subgraph, just select it
            if (match != null) {
                this.selectNode(match.id);
                return Optional.some(match);
            }

            // no exact match, see if we update or create a new node
            const parent = workingStatus === WorkingNodeStatus.BEHIND ?
                // if the match to the visualized node is behind, branch off of the parent
                this._hyperGraph.get(workingNode.request.parent) :
                // if full match or ahead, branch from the current visualized node
                workingNode;
            return Optional.some(this.buildQueryNode(parent, response));
        }
    }

    /**
     * Process a query response due to an undo.
     */
    onUndo(response: ArcQLResponse): void {
        this._hyperGraph.workingNode.forEach(workingNode => {
            if (workingNode.request.parent == null) {
                // root node, nothing to do
                return;
            }

            // if the response of an undo matches that of the visualized parent, set the parent as the visualized node,
            // otherwise set the current visualized state to partially visualized
            const parent = this._hyperGraph.get(workingNode.request.parent);
            if (parent.getQuery(this._hyperGraph).equals(response.arcql)) {
                // undo-ed to the parent query, select it
                this.selectNode(parent.id);
            } else {
                // finalize and set to partially visualized
                this.onChange(this._hyperGraph.withWorkingStatus(WorkingNodeStatus.BEHIND));
            }

            // finalize the node we are undoing from
            this.finalizeNode(workingNode.id);
        });
    }

    /**
     * Process a query response due to redo.
     */
    onRedo(response: ArcQLResponse): void {
        this._hyperGraph.workingNode.forEach(workingNode => {
            // see if the redo resulted in a match of any existing children, otherwise it's a partial match
            const match = this._hyperGraph.children(workingNode.id)
                .find(c => c.getQuery(this._hyperGraph).equals(response.arcql));

            if (match == null) {
                // set to partially visualized
                this.onChange(this._hyperGraph.withWorkingStatus(WorkingNodeStatus.AHEAD));
            } else {
                // redo-ed to a child query, select it
                this.selectNode(match.id);
            }
        });
    }

    /**
     * If a query gets replaced by a different saved version, we should replace the hypergraph as well
     */
    onVersionReplace(response: ArcQLResponse): void {
        this.onChange(response.arcql.hyperGraph(response.dataset).getOr(HyperGraph.empty()));

        // If the graph from the selected version is empty, make sure we initialize it
        if (this.hyperGraph.isEmpty) {
            this.initializeGraph(response);
        }
    }

    private onChange(
        hyperGraph: HyperGraph,
        // if we need to broadcast the change back to the change handler
        broadcast: boolean = true
    ): void {
        // avoid unnecessary updates
        if (hyperGraph === this._hyperGraph) {
            return;
        }

        const previous = this._hyperGraph;
        this._hyperGraph = hyperGraph;
        if (broadcast) {
            this._onChange(this._hyperGraph, previous);
        }
    }

    /**
     * Build a new query node for the given response.
     */
    private buildQueryNode(currentNode: HyperGraphNode, response: ArcQLResponse): QueryContainerNode {
        // if the given node is user query node that is not finalized, reuse it to collapse multiple changes to avoid
        // an overly complex graph
        const queryNode = Optional.ofType(currentNode.request, QueryContainer)
            .flatMap((queryContainer) =>
                // can reuse the existing node is not final and is a leaf
                Optional.bool(!queryContainer.isFinal && this._hyperGraph.isLeaf(currentNode.id))
                    .map(() =>
                        new QueryContainerNode(
                            new QueryContainer(
                                queryContainer.ref,
                                response.arcql,
                                HyperGraphNodeOperation.USER,
                                queryContainer.hypothesis,
                                false
                            ),
                            response.result,
                            response.dataset,
                            currentNode.id
                        )
                    )
            )
            // create a new node
            .getOrElse(() =>
                new QueryContainerNode(
                    new QueryContainer(
                        new QueryReference(currentNode.id),
                        response.arcql,
                        HyperGraphNodeOperation.USER,
                        new SimpleHypothesis('Taking notes...', 'Keep exploring, we\'ll summarize your changes.'),
                        false
                    ),
                    response.result,
                    response.dataset
                )
            );

        // kick off timer to finalize the node unless new changes come in
        this.finalizeSelected(queryNode.id);

        // update the graph and selections
        this.upsertNode(queryNode, false);
        this.selectNode(queryNode.id);

        return queryNode;
    }

    /**
     * Attempt to finalize a node by summarizing the changes with its parent.
     */
    private finalizeNode(nodeId: string): void {
        const node = this._hyperGraph.get(nodeId);

        // we only know how to finalize queries
        Optional.ofType(node, QueryContainerNode)
            // make sure it's not already finalized
            .filter(queryNode => !queryNode.request.isFinal)
            .forEach(async queryNode => {
                // do a "temp" finalization to block additional changes while we summarize
                this.upsertNode(
                    queryNode.with({
                        hypothesis: new SimpleHypothesis('Summarizing...', 'Summarizing your changes.'),
                        isFinal: true
                    })
                );

                const parent = this._hyperGraph.get(queryNode.request.parent);
                const hyperGraphService = ServiceProvider.get(HyperGraphService);
                const queryResult = queryNode.getQueryResult(this._hyperGraph);
                const differences = await hyperGraphService.summarizeQueryChanges(
                    parent.getHypothesis(0),
                    parent.getQuery(this._hyperGraph),
                    parent.getQueryResult(this._hyperGraph),
                    queryNode.getQuery(this._hyperGraph),
                    queryResult
                );

                differences.map(async differences => {
                    const embedding = await hyperGraphService.embedding([
                        `# ${differences.label}`,
                        differences.summary,
                        ...differences.structuredContent(queryResult).map(c => c.embeddingContent)
                    ].join('\n\n'));

                    this.upsertNode(
                        queryNode.with({
                            hypothesis: new SimpleHypothesis(
                                differences.label,
                                [
                                    differences.summary,
                                    ...differences.structuredContent(queryResult).map(c => c.embeddingContent)
                                ].join('\n\n')
                            ),
                            nodeEmbedding: embedding.rightOrThrow(),
                            isFinal: true
                        }),
                    );
                });
            });
    }

    private async updateEmbedding<N extends HyperGraphNode>(node: N): Promise<N> {
        const hyperGraphService = ServiceProvider.get(HyperGraphService);

        const embedding = await hyperGraphService.embedding(node.embeddingContent(this.hyperGraph));
        return node.with({nodeEmbedding: embedding.rightOrThrow()}) as N;
    }

}
