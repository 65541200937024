import FormControl from "@mui/material/FormControl";
import {Autocomplete} from "@mui/material";
import * as React from "react";
import {ChangeEvent, FunctionComponent} from "react";
import TextField from "@mui/material/TextField";
import {FormS} from "app/components/form/FormS";
import styled from "@emotion/styled";

type Props = {
    account: string;
    accounts: string[];
    onSelectAccount: (account: string) => void;
    disabled?: boolean;
};

/**
 * Input for selecting account
 */
export const AccountInput: FunctionComponent<Props> = (props: Props) => {
    // sort accounts alphabetically
    const sortedAccounts = props.accounts.sort((a, b) => a.localeCompare(b));

    return <S.AccountForm size={"small"}>
        <FormS.Label id="account-label">Owner</FormS.Label>
        <Autocomplete
            id="account"
            aria-labelledby="account-label"
            value={props.account}
            options={sortedAccounts}
            onChange={(e: ChangeEvent<HTMLInputElement>, newValue: string) => newValue && props.onSelectAccount(newValue)}
            fullWidth
            disabled={props.disabled}
            renderInput={(params) =>
                <TextField
                    {...params}
                    size="small"
                    placeholder="Select Owner..."
                />
            }
        />
    </S.AccountForm>;
};

class S {

    static readonly AccountForm = styled(FormControl)`
        width: 200px;
        margin-right: 16px;
    `;

}