import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import {TriggerCondition, TriggerConditionType} from "metadata/trend/TriggerCondition";
import {ThresholdCondition, ThresholdOperator} from "metadata/trend/ThresholdCondition";
import {RangeCondition} from "metadata/trend/RangeCondition";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {ToggleS} from "app/components/input/ToggleS";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";

type Props = {
    currentCondition: TriggerCondition,
    onConditionChange: (condition: TriggerCondition) => void
}

export const AlertTriggerConditionForm: FunctionComponent<Props> = (props: Props) => {

    const onTriggerTypeChange = (typeStr: string) => {
        const type = TriggerConditionType.get<TriggerConditionType>(typeStr);
        if (type === TriggerConditionType.THRESHOLD) {
            props.onConditionChange(ThresholdCondition.default());
        } else {
            props.onConditionChange(RangeCondition.default());
        }
    };

    const onThresholdConditionChange = (condition: ThresholdCondition) => {
        props.onConditionChange(condition);
    };

    const onRangeConditionChange = (condition: RangeCondition) => {
        props.onConditionChange(condition);
    };

    const buildTriggerConditionForm = (condition: TriggerCondition): ReactNode => {
        switch (condition.type) {
            case TriggerConditionType.THRESHOLD:
                const thresholdCondition = condition as ThresholdCondition;
                return (
                    <S.FormRow>
                        <ToggleButtonGroup
                            color="primary"
                            size="small"
                            exclusive
                            value={thresholdCondition.operator.name}
                            onChange={(e, value) => onThresholdConditionChange(
                                thresholdCondition.with({operator: ThresholdOperator.get(value)})
                            )}
                        >{
                            ThresholdOperator.enums<ThresholdOperator>().map(operator =>
                                <ToggleS.ToggleButton value={operator.name} key={operator.name}>
                                    {operator.label}
                                </ToggleS.ToggleButton>
                            )
                        }</ToggleButtonGroup>
                        <S.ThresholdInput
                            margin="normal"
                            label="Threshold"
                            type="number"
                            value={thresholdCondition.threshold}
                            onChange={(e) => onThresholdConditionChange(
                                thresholdCondition.with({threshold: parseFloat(e.target.value)})
                            )}
                        />
                    </S.FormRow>
                );
            case TriggerConditionType.RANGE:
                const rangeCondition = condition as RangeCondition;
                return (
                    <S.FormRow>
                        <S.ThresholdInput
                            margin="normal"
                            label="Lower"
                            type="number"
                            value={rangeCondition.lowerThreshold}
                            onChange={(e) => onRangeConditionChange(
                                rangeCondition.with({lowerThreshold: parseFloat(e.target.value)})
                            )}
                        />
                        <S.ThresholdInput
                            margin="normal"
                            label="Upper"
                            type="number"
                            value={rangeCondition.upperThreshold}
                            onChange={(e) => onRangeConditionChange(
                                rangeCondition.with({upperThreshold: parseFloat(e.target.value)})
                            )}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rangeCondition.inRange}
                                        onChange={(e) => onRangeConditionChange(
                                            rangeCondition.with({inRange: e.target.checked})
                                        )}
                                    />
                                }
                                label="In Range"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rangeCondition.inclusiveLower}
                                        onChange={(e) => onRangeConditionChange(
                                            rangeCondition.with({inclusiveLower: e.target.checked})
                                        )}
                                    />
                                }
                                label="Include Lower"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rangeCondition.inclusiveUpper}
                                        onChange={(e) => onRangeConditionChange(
                                            rangeCondition.with({inclusiveUpper: e.target.checked})
                                        )}
                                    />
                                }
                                label="Include Upper"
                            />
                        </FormGroup>
                    </S.FormRow>
                );

        }
    };

    return <S.FormWrapper>
        <S.Row>
            <FormControl>
                <FormLabel id="trigger-type-label" sx={{mb: 0.5}}>Trigger Type</FormLabel>
                <Select
                    labelId="trigger-type-label"
                    id="trigger-type-select"
                    value={props.currentCondition.type.name}
                    onChange={e => onTriggerTypeChange(e.target.value as string)}
                    size="small"
                >
                    {
                        TriggerConditionType.enums<TriggerConditionType>().map(t =>
                            <MenuItem key={t.name}
                                      value={t.name}>{t.label}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </S.Row>
        {buildTriggerConditionForm(props.currentCondition)}
    </S.FormWrapper>;
};

class S {

    static readonly FormWrapper = styled.div`
    `;

    static readonly Row = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
    `;

    static readonly FormRow = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;

        .MuiFormLabel-root {
            color: ${Colors.textTertiary};
            transform: translate(9px, 6px) scale(1);

            &.MuiInputLabel-shrink {
                font-size: ${FontSizes.small};
                transform: translate(11.5px, -9.3px) scale(0.9);

            }
        }
    `;


    static readonly ThresholdInput = styled(TextField)`
        width: 100px;
        margin: 0;
        align-items: center;

        input {
            font-size: ${FontSizes.small};
            padding: 9px 14px;
            text-align: center;
        }

    `;
}