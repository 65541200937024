import {Facet} from "engine/FacetingMessage";
import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {ArcEvent} from "engine/ArcEvent";
import {Optional} from "common/Optional";
import {TypedEventFilter} from "engine/ArcEventFilter";
import {VizSelection} from "engine/actor/VizSelection";

/**
 * When a single selection of a facet happens.
 *
 * @author zuyezheng
 */
export class SelectionMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.SELECTION;

    constructor(
        public readonly datasetFqn: string,
        public readonly selection: VizSelection,
        // facet defining the selection
        public readonly facet: Facet
    ) { }

}

export class SelectionEventFilter extends TypedEventFilter<SelectionMessage> {

    constructor(
        private readonly publisherId?: string
    ) {
        super(SelectionMessage);
    }

    filter(event: ArcEvent): Optional<SelectionMessage> {
        return super.filter(event)
            .filter(() => this.publisherId == null || this.publisherId === event.actor.id);
    }

}

