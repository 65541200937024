import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL, ArcQLProps} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {DeleteFilter} from "metadata/query/changes/DeleteFilter";
import {FilterChange} from "metadata/query/changes/FilterChange";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FilterClause} from "metadata/query/filterclause/FilterClause";

/**
 * Add a filter clause.
 *
 * @author zuyezheng
 */
export class AddFilter extends FilterChange {

    constructor(
        public readonly clause: FilterClause,
        // if the change should be for aggregate filters or pre aggregate
        isAggregate: boolean,
        public readonly dataset: ArcDataset,
        // optionally specify where to add the filter
        public readonly ordinal?: number
    ) {
        super(isAggregate);
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const filters = this.filters(metadata);

        const newMetadata: Partial<ArcQLProps> = {};
        newMetadata[filters.left] = this.ordinal == null ?
            filters.right.withAll([this.clause]) :
            filters.right.with(this.clause, this.ordinal);

        return new Tuple(
            metadata.with(newMetadata),
            // if no ordinal provided, we know we added it to the end
            new DeleteFilter(
                this.ordinal == null ? filters.right.clauses.length : this.ordinal,
                this.isAggregate,
                this.dataset
            )
        );
    }

    describe(): string {
        const filterType = this.isAggregate ? 'aggregate filter' : 'filter';
        const fieldLabel = this.clause.fieldsLabel(this.dataset);

        return `Added new ${filterType} for ${fieldLabel} ${this.clause.description(true)}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}