import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {JsonObject} from "common/CommonTypes";

export class DashboardInteractions {

    static default(): DashboardInteractions {
        return new DashboardInteractions([]);
    }

    static fromJSON(json: JsonObject): DashboardInteractions {
        if (json == null) {
            return DashboardInteractions.default();
        } else {
            return new DashboardInteractions(
                Object.entries(json['columnGroups']).map(
                    ([label, groupJson]: [string, JsonObject]) => ColumnGroup.fromJSON(label, groupJson)
                )
            )
        }
    }

    private readonly _columnGroups: Map<string, ColumnGroup>;

    constructor(columnGroups: ColumnGroup[]) {
        this._columnGroups = new Map(
            columnGroups.map(g => [g.label, g])
        );
    }

    get columnGroups(): ColumnGroup[] {
        return Array.from(this._columnGroups.values());
    }

    /**
     * Find all column groups that contain the given dataset column.
     */
    findColumnGroups(datasetFqn: FQN, column: string): ColumnGroup[] {
        return this.columnGroups.filter(
            g => g.hasColumn(datasetFqn, column)
        );
    }

    /**
     * Find all unique linked columns across all matched groups.
     */
    findLinkedColumns(datasetFqn: FQN, column: string): ColumnGroupColumn[] {
        const searchColumn = new ColumnGroupColumn(datasetFqn, column);
        const uniqueColumns: Map<string, ColumnGroupColumn> = new Map();

        this.findColumnGroups(datasetFqn, column)
            .flatMap(g => g.columns)
            .forEach(c => {
                // don't add itself
                if (c.key === searchColumn.key) {
                    return;
                }

                // do some deduping
                uniqueColumns.set(c.key, c);
            });

        return Array.from(uniqueColumns.values());
    }

    getColumnGroup(label: string): Optional<ColumnGroup> {
        return Optional.some(this._columnGroups.get(label));
    }

    /**
     * New instance with the given column group to add or replace the existing.
     */
    withColumnGroup(label: string, columns: ColumnGroupColumn[]): DashboardInteractions {
        return new DashboardInteractions([
            ...this.columnGroups.filter(g => g.label != label),
            new ColumnGroup(label, columns)
        ]);
    }

    /**
     * New instance without the given column group.
     */
    withoutColumnGroup(label: string): DashboardInteractions {
        return new DashboardInteractions(
            this.columnGroups.filter(g => g.label != label)
        );
    }

    toJSON(): JsonObject {
        return {
            'columnGroups': Object.fromEntries(this._columnGroups)
        };
    }

}

export class ColumnGroup {

    static fromJSON(label: string, json: JsonObject): ColumnGroup {
        return new ColumnGroup(
            label,
            json['columns'].map(ColumnGroupColumn.fromJSON)
        )
    }

    // columns keyed off of `<datasetFqn>/<column>`
    private readonly _columns: Map<string, ColumnGroupColumn>;

    constructor(
        public readonly label: string,
        columns: ColumnGroupColumn[]
    ) {
        this._columns = new Map(
            columns.map(c => [c.key, c])
        );
    }

    get columns(): ColumnGroupColumn[] {
        return Array.from(this._columns.values());
    }

    hasColumn(datasetFqn: FQN, column: string): boolean {
        return this._columns.has(new ColumnGroupColumn(datasetFqn, column).key)
    }

    /**
     * Return a new instance with the given column.
     */
    withColumn(datasetFqn: FQN, column: string): ColumnGroup {
        const newColumn = new ColumnGroupColumn(datasetFqn, column);

        return new ColumnGroup(
            this.label,
            [
                ...this.columns.filter(c => !c.equals(newColumn)),
                newColumn
            ]
        );
    }

    withoutColumn(datasetFqn: FQN, column: string): ColumnGroup {
        const toRemove = new ColumnGroupColumn(datasetFqn, column);

        return new ColumnGroup(
            this.label,
            this.columns.filter(c => !c.equals(toRemove)),
        );
    }

    toJSON(): JsonObject {
        return {
            'columns': this.columns
        };
    }

}

export class ColumnGroupColumn {

    static fromJSON(json: JsonObject): ColumnGroupColumn {
        return new ColumnGroupColumn(
            FQN.parse(json['datasetFqn']),
            json['column']
        );
    }

    constructor(
        public readonly datasetFqn: FQN,
        public readonly column: string
    ) {
    }

    get key(): string {
        return this.datasetFqn.toString() + '/' + this.column;
    }

    equals(other: ColumnGroupColumn): boolean {
        return this.key === other.key;
    }

    toJSON(): JsonObject {
        return {
            'datasetFqn': this.datasetFqn.toString(),
            'column': this.column
        };
    }

}