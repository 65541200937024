import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Optional} from "common/Optional";
import {Either, Left, Right} from "common/Either";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";

export class ToggleData {

    constructor(
        public readonly options: ToggleOption[]
    ) { }

    static from(response: ArcQLResponse): Either<string, ToggleData> {
        if (response.arcql.groupings.size < 1) {
            return new Left('Need at least 1 grouping for toggle.');
        }

        // if there are measures, use the last for display
        const measureIndex: Optional<number> = Optional.bool(response.arcql.fields.size > 0)
            .map(() => response.arcql.fields.last)
            .map(f => response.result.columnIndices.get(f.projectedAs));

        if (response.result.length === 0) {
            return new Left('No results.');
        }

        const groupingIndices: number[] = response.arcql.groupings.fields.map(
            g => response.result.columnIndices.get(g.projectedAs)
        );
        const data: ToggleOption[] = response.result.mapRows(
            (row: any[], index: string, formatters: ResultValueFormatter[]) => {
                return new ToggleOption(
                    index,
                    response.result.rowLabel(row, groupingIndices),
                    measureIndex.map(i => row[i]),
                    groupingIndices.map(i => row[i])
                );
            }
        );

        return new Right(new ToggleData(data));
    }

}

export class ToggleOption {

    constructor(
        public readonly index: string,
        public readonly label: string,
        public readonly metric: Optional<number>,
        public readonly groupings: string[]
    ) {
    }

}