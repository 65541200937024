import {MetadataMessage} from "engine/ArcMessage";
import {TypedEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {Optional} from "common/Optional";
import {QueryReason} from "engine/actor/QueryResason";
import {ChangeSetType} from "metadata/ChangeSetType";

export class ArcQLMessage extends MetadataMessage<ArcQLBundle> {

    constructor(
        metadata: ArcQLBundle,
        public readonly reason: QueryReason,
        public readonly changeSetType: ChangeSetType
    ) {
        super(metadata);
    }

}

export class ArcQLEventFilter extends TypedEventFilter<ArcQLMessage> {

    constructor(
        public readonly publisherId: string
    ) {
        super(ArcQLMessage);
    }

    filter(event: ArcEvent): Optional<ArcQLMessage> {
        return super.filter(event)
            // if it's the right type, check the publisherId
            .filter(m => this.publisherId === event.actor.id);
    }

}