import * as React from "react";
import {FunctionComponent, useEffect, useRef, useState} from "react";
import {VisualizationComponents, VisualizationProps} from "app/visualizations/ArcVisualization";
import * as d3 from "d3";
import {D3ZoomEvent} from "d3";
import {NodeAndLinkData} from "app/visualizations/data/NodeAndLinkData";
import {SimulationLinkDatum, SimulationNodeDatum} from "d3-force";
import {Optional} from "common/Optional";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {VizSelection} from "engine/actor/VizSelection";

type NodeDatum = SimulationNodeDatum & {
    id: string
    group: string
}

type LinkDatum = SimulationLinkDatum<SimulationNodeDatum> & {
    value: number
}

type ChartTooltip = {
    text: string
    x: number
    y: number
}


/**
 * Network graph.
 *
 * @author zuyezheng
 */
export const NetworkGraph: FunctionComponent<VisualizationProps> = (props: VisualizationProps) => {

    const containerRef = useRef(null);
    const svgRef = useRef(null);
    // ref of currently rendered query response
    const queryResponseRef = useRef<Optional<ArcQLResponse>>(Optional.none());

    // any validation errors
    const [validationMessage, setValidationMessage] = useState<Optional<string>>(Optional.none());
    // tooltip for mouse overs of nodes and links
    const [chartTooltip, setChartTooltip] = useState<Optional<ChartTooltip>>(Optional.none());

    useEffect(() => {
        // if svg isn't ready or we don't have a query response
        if (svgRef.current === null || props.queryResponse === null) {
            return;
        }
        // don't render the same query
        if (queryResponseRef.current.map(r => r.id === props.queryResponse.id).getOr(false)) {
            return;
        }
        // update the query were going to try to render
        queryResponseRef.current = Optional.some(props.queryResponse);

        // start creating the network graph structure and validate first
        const data: NodeAndLinkData = new NodeAndLinkData(props.queryResponse, false);
        const validation = data.validate();
        setValidationMessage(data.validate());
        if (validation.isPresent) {
            // if validation failed stop
            return;
        }

        // transform the data for d3
        const dataset = data.dataset(VizSelection.none());
        const nodes: NodeDatum[] = dataset.nodes.map(
            n => ({
                id: n.label,
                group: n.source.ordinal.toString()
            })
        );

        let minValue: number;
        let maxValue: number;
        const links: LinkDatum[] = dataset.links.map(
            l => {
                if (minValue == null || l.value < minValue) {
                    minValue = l.value;
                }
                if (maxValue == null || l.value > maxValue) {
                    maxValue = l.value;
                }

                return {
                    source: l.from,
                    target: l.to,
                    value: l.value
                };
            }
        );
        const valueDomain = maxValue - minValue;

        d3.select(svgRef.current)
            .select('g')
            .remove();

        // main svg to capture all the events
        const svg = d3.select(svgRef.current);
        // graph to draw stuff to but also pan and zoom
        const g = svg.attr('width', '100%')
            .attr('height', '100%')
            .append('g');

        // handle zooms
        const handleZoom = (e: D3ZoomEvent<any, any>) => {
            // @ts-ignore
            g.attr('transform', e.transform);
        };
        const zoom = d3.zoom().on('zoom', handleZoom);
        svg.call(zoom);

        // plot all the nodes in the center and run a simulation to cluster them
        const simulation = d3.forceSimulation(nodes)
            .force('link', d3.forceLink(links).id((node: NodeDatum) => node.id).distance(1).strength(0.5))
            .force('charge', d3.forceManyBody().strength(-175))
            .force('center', d3.forceCenter(svgRef.current.clientWidth/2, svgRef.current.clientHeight/2))
            .force('x', d3.forceX())
            .force('y', d3.forceY());

        const link = g.append('g')
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.75)
            .selectAll('line')
            .data(links)
            .enter().append('line')
            .attr('stroke-width', (link: LinkDatum) => 1 + (5 * (link.value - minValue) / valueDomain))
            .on('mousemove', (event: any, link: LinkDatum) => {
                setChartTooltip(Optional.some({
                    text: link.value.toString(),
                    x: event.offsetX + 10,
                    y: event.offsetY
                }));
            })
            .on('mouseout', (event: any, link: LinkDatum) => {
                setChartTooltip(Optional.none());
            });

        // color pallet for the nodes
        const color = d3.scaleOrdinal(['blue', 'green', 'red']);
        const node = g.append('g')
            .attr('class', 'nodes')
            .attr('stroke', '#999')
            .attr('stroke-width', 1)
            .selectAll('circle')
            .data(nodes)
            .enter().append('circle')
            .attr('r', 10)
            .attr('fill', (node: NodeDatum) => color(node.group))
            .on('mousemove', (event: any, node: NodeDatum) => {
                setChartTooltip(Optional.some({
                    text: node.id,
                    x: event.offsetX + 10,
                    y: event.offsetY
                }));
            })
            .on('mouseout', (event: any, node: NodeDatum) => {
                setChartTooltip(Optional.none());
            });

        simulation.on('tick', () => {
            link
                .attr('x1', (link: LinkDatum) => (link.source as NodeDatum).x)
                .attr('y1', (link: LinkDatum) => (link.source as NodeDatum).y)
                .attr('x2', (link: LinkDatum) => (link.target as NodeDatum).x)
                .attr('y2', (link: LinkDatum) => (link.target as NodeDatum).y);

            node
                .attr('cx', d => d.x)
                .attr('cy', d => d.y);
        });
    }, [svgRef.current, props.queryResponse]);

    return <VisualizationComponents.Container ref={containerRef}>
        {
            chartTooltip.map(tooltip =>
                <S.Tooltip style={{
                    left: `${tooltip.x}px`,
                    top: `${tooltip.y}px`
                }}>
                    {tooltip.text}
                </S.Tooltip>
            ).getOr(<></>)
        }
        {
            validationMessage.map(message =>
                <VisualizationComponents.Mask>
                    {message}
                </VisualizationComponents.Mask>
            ).getOr(<></>)
        }
        <svg ref={svgRef} />
    </VisualizationComponents.Container>;

};

const S = {

    Tooltip: styled.div`
        padding: 2px 4px;
        position: absolute;
        background-color: white;
        pointer-events: none;
        font-size: ${FontSizes.small};
        color: ${Colors.textPrimary};
    `

};