import {Enum} from "common/Enum";

export class LinkType extends Enum {

    static readonly ASSET = new this('asset', 'Asset');
    static readonly URL = new this('url', 'URL');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
LinkType.finalize();