/**
 * Actions for Trends.
 */
export class TrendAction {

    constructor(
        public readonly id: string,
        public readonly label: string
    ) {
    }

    equals(other: TrendAction): boolean {
        return this.id === other.id;
    }
}

export const StandardTrendActions = {
    VIEW: new TrendAction('view', 'View Trends'),
    CREATE: new TrendAction('create', 'Create New'),
};
