import Switch from "@mui/material/Switch";
import {ProjectSearchResultsTable} from "app/components/search/ProjectSearchResultsTable";
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import React, {FunctionComponent} from "react";
import BrowseViewProps from "app/home/browse/BrowseViewProps";
import {S} from "app/home/browse/BrowseViewS";

export const BrowseAllView: FunctionComponent<BrowseViewProps> = (props) => {

    return <>
        <S.HeaderRow>
            <S.ResultsHeader>Browse All</S.ResultsHeader>
            <S.BrowseByProjectSwitchLabel
                control={
                    <Switch
                        checked={props.browseByProject}
                        onChange={() => props.setBrowseByProject(!props.browseByProject)}
                    />
                }
                label={"Browse by Project"}
                labelPlacement={"start"}
            />
        </S.HeaderRow>
        {
            props.browseByProject && <ProjectSearchResultsTable
                searchParams={props.folderSearchParams}
                onSelect={props.onProjectSelect}
                onSearch={props.onSearchProjects}
                onAccountBreadcrumb={props.onAccountBreadcrumb}
            />
        }
        {
            !props.browseByProject && <AssetSearchResultsTable
                searchParams={props.assetSearchParams}
                onSelect={props.onAssetSelectSearchResult}
                rowType={SearchResultsRowType.TABLE}
                onSearch={props.onSearchAssets}
                onAccountBreadcrumb={props.onAccountBreadcrumb}
            />
        }
    </>;

};