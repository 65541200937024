import * as React from "react";
import {ChangeEvent, useState} from "react";
import styled from "@emotion/styled";
import {SaveAsDialog} from "app/components/SaveAsDialog";
import {SaveHandler} from "metadata/SaveHandler";
import {Optional} from "common/Optional";
import {Story} from "metadata/asset/story/Story";
import {TabPath} from "app/TabPath";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {ServiceProvider} from "services/ServiceProvider";
import {ImageService} from "services/ImageService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Colors} from "app/components/StyleVariables";
import {MetadataService} from "services/MetadataService";
import {Asset} from "metadata/Asset";
import {LoadingMask} from "app/components/decoration/LoadingMask";


export interface Props {
    targetAsset: Asset
    viewportEl: HTMLElement
    // start recording a new scene
    onRecord: (caption: Optional<StoryScene>) => void
    // done creating the story
    onDone: () => void
}

/**
 * Starting a story and recording subsequent scenes.
 *
 * @author zuyezheng
 */
export const StoryCreate = (props: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [stage, setStage] = useState<Stage>(Stage.NONE);
    const [story, setStory] = useState<Optional<Story>>(Optional.none());

    // label and description of the scene being created
    const [scenes, setScenes] = useState<StoryScene[]>([]);

    const onStart = () => {
        setStory(Optional.some(Story.new(props.targetAsset)));
        setStage(Stage.START);
        setScenes([StoryScene.new(`Scene ${scenes.length + 1}`)]);
    };

    const onTabChange = (label?: string, path?: TabPath, hasChanges?: boolean) => {
        // nothing to do
    };

    const saveHandler = story.map(q => new SaveHandler(q, onTabChange, Optional.none()));

    const onCancelSave = () => {
        setStage(Stage.NONE);
    };

    const onSave = (story: Story) => {
        setStage(Stage.RECORDING);
        setStory(Optional.some(story));
        props.onRecord(Optional.some(editingScene()));
    };

    // return the scene currently being edited
    const editingScene = () => {
        return scenes[scenes.length - 1];
    };

    const onSceneChange = (prop: string, e: ChangeEvent<HTMLInputElement>) => {
        const editedScene = editingScene().with({[prop]: e.target.value});
        setScenes(scenes.slice(0, scenes.length - 1).concat([editedScene]));
        props.onRecord(Optional.some(editedScene));
    };

    // capture a new scene
    const onCapture = () => {
        setIsLoading(true);

        (async () => {
            try {
                // create a screenshot
                const screenshot = (await ServiceProvider.get(ImageService).saveAssetScreenshot(
                    props.viewportEl, props.targetAsset.id, props.targetAsset.fqn, true, false
                )).rightOrThrow();
                // finalize the scene and persist
                const finalizedScene = editingScene().with({
                    'image': screenshot.path,
                    'imageDataUrl': screenshot.dataUrl
                });
                (await ServiceProvider.get(MetadataService).createScene(story.get(), finalizedScene)).rightOrThrow();

                // update the UI
                setScenes(scenes.slice(0, scenes.length - 1).concat([
                    finalizedScene,
                    StoryScene.new(`Scene ${scenes.length + 1}`)
                ]));
                props.onRecord(Optional.some(editingScene()));
                setIsLoading(false);
            } catch (e) {
                ServiceProvider.get(NotificationsService).publish(
                    'createStory', NotificationSeverity.ERROR, 'Failed to capture scene.'
                );
            }

            setIsLoading(false);
        })();
    };

    const recordingComponents = (story: Story) => {
        return <S.Record>
            <S.StoryInfo>
                <S.Name>{story.label}</S.Name>
                <S.Description>{story.description}</S.Description>
            </S.StoryInfo>
            <S.CreateScene>
                <FormControl fullWidth size="small">
                    <TextField
                        variant="standard"
                        size="small"
                        margin="dense"
                        label="Label"
                        value={editingScene().label}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onSceneChange('label', e)}
                    />
                    <TextField
                        multiline
                        rows={2}
                        variant="standard"
                        size="small"
                        margin="dense"
                        label="Description"
                        value={editingScene().description}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onSceneChange('description', e)}
                    />
                </FormControl>
                <S.Control>
                    <S.Button onClick={onCapture} variant="outlined">Capture Scene</S.Button>
                    <S.Button onClick={props.onDone} variant="outlined">Done</S.Button>
                </S.Control>
            </S.CreateScene>
            <S.Scenes>
                {
                    scenes.slice(0, scenes.length - 1).map((scene, i) =>
                        <S.Scene key={i}>
                            <img src={scene.imageDataUrl} alt={scene.label} />
                            <div><b>{scene.label}</b> {scene.description}</div>
                        </S.Scene>
                    )
                }
            </S.Scenes>
        </S.Record>
    };

    return <S.Container>
        {
            isLoading && <LoadingMask />
        }
        {stage === Stage.NONE &&
            <Button onClick={onStart} variant="outlined">Start</Button>
        }
        {stage === Stage.START &&
            <SaveAsDialog
                asset={story.get()}
                fqn={story.get().fqn}
                saveHandler={saveHandler.get()}
                onCancel={onCancelSave}
                onSave={onSave}
            />
        }
        {stage === Stage.RECORDING && recordingComponents(story.get())}
    </S.Container>

};

class S {

    static Container = styled.div`
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        flex: 1;
        overflow: auto;
        position: relative;
    `;

    static Mask = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        background-color: rgba(0, 0, 0, .5);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1000;
    `;

    static Record = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static Button = styled(Button)`
        margin: 0 4px;
    `;

    static StoryInfo = styled.div`
    
    `;

    static Name = styled.div`
        font-weight: 500;  
    `;

    static Description = styled.div`
        padding-top: 4px;
    `;

    static CreateScene = styled.div`
        padding: 10px 0;
    `;

    static Control = styled.div`
        display: flex;
        justify-content: center;
        padding: 4px 0;
    `;

    static Scenes = styled.div`
        overflow: auto;
        flex: 1;
        padding: 5px 0 10px;
    `;

    static Scene = styled.div`
        margin: 6px 0;
        border: 1px solid ${Colors.borderGrey};
        border-radius: 4px;
        padding: 6px;
        
        img {
            width: 100%;
        }
    `;
    
}

enum Stage {
    NONE, START, RECORDING
}