import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ArcQLFieldType} from "metadata/query/ArcQLFieldType";
import {Enum} from "common/Enum";
import {ColumnField} from "metadata/query/ArcQLField";

export class DetailField extends ColumnField {

    public readonly operator: DetailOperator;

    constructor(
        public field: string,
        operator: DetailOperator,
        as: string
    ) {
        super(ArcQLFieldType.DETAIL, as);

        // optional operator, if none use the "noop" ALL
        this.operator = operator || DetailOperator.ALL;
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        // same type as the dataset field
        return dataset.get(this.field).analyticsType;
    }

    with({operator, as}: {
        // operator is optional so passing in an explicit null will remove the operator
        operator?: DetailOperator,
        as?: string
    }): DetailField {
        return new DetailField(
            this.field,
            operator === undefined ? this.operator : operator,
            as == null ? this.as : as
        );
    }

    get tooltip(): string {
        return this.field;
    }

    get prefix(): string | null {
        return this.operator.abbreviation;
    }

}

export class DetailOperator extends Enum {

    // all values (e.g. no op)
    static ALL = new this('all', 'All', 'ALL');
    // distinct values
    static DISTINCT = new this('distinct', 'Distinct', 'DCT');

    constructor(
        name: string,
        public readonly label: string,
        public readonly abbreviation: string
    ) {
        super(name);
    }

}

DetailOperator.finalize();