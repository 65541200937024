import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {Optional} from "common/Optional";

/**
 * State of an ArcEngine, namely keeping the last stateful message of an actor.
 */
export class ArcEngineState {

    // The state is the last event for an [actor, event type] pair. The state for actor foo could be its last query
    // result and the last selection of something in that result set. So state is a map from:
    // actor id -> event type -> event.
    private readonly state: Map<string, Map<string, ArcMessage>>;

    constructor() {
        this.state = new Map();
    }

    /**
     * Remove actor and all of its corresponding state messages.
     */
    removeActor(actorId: string): void {
        this.state.delete(actorId);
    }


    /**
     * Set a message for an actor.
     */
    setActorMessage(actorId: string, messageType: ArcMessageType, message: ArcMessage): void {
        if (!this.state.has(actorId)) {
            this.addActor(actorId);
        }
        this.state.get(actorId).set(messageType.name, message);
    }

    /**
     * Get a state message for an actor.
     */
    getActorMessage(actorId: string, messageType: ArcMessageType): Optional<ArcMessage> {
        return Optional.of(this.state.get(actorId)?.get(messageType.name));
    }

    /**
     * Replace the entire state for an actor.
     */
    setActorState(actorId: string, actorState: Map<string, ArcMessage>): void {
        this.state.set(actorId, new Map(actorState));
    }

    /**
     * Get all messages of a certain type, paired with corresponding actor i.e. [actorId, ArcMessage].
     */
    messagesOfType(messageType: ArcMessageType): [string, ArcMessage][] {
        const result: [string, ArcMessage][] = [];
        for (const [actorId, state] of this.state) {
            const message = state.get(messageType.name);
            if (message) {
                result.push([actorId, message]);
            }
        }
        return result;
    }

    /**
     * Get all state messages, optionally excluding a certain actor and message type.
     */
    getStateMessagesExcluding(excludedActorId?: string, excludedMessageType?: ArcMessageType): ArcMessage[] {
        return Array.from(this.state.entries())
            // exclude state of the current actor if specified
            .filter(([actorId, _]: [string, Map<string, ArcMessage>]) => excludedActorId ? actorId !== excludedActorId : true)
            // flatten all messages
            .flatMap(([_, messages]: [string, Map<string, ArcMessage>]) =>
                Array.from(messages.values())
                    // exclude messages of the specified type if specified
                    .filter(m => excludedMessageType ? m.type !== excludedMessageType : true)
            );
    }

    /**
     * Gather all actors and their state messages i.e [actorId, Map<string, ArcMessage>].
     */
    get allMessages(): [string, Map<string, ArcMessage>][] {
        return Array.from(this.state.entries());
    }

    /**
     * Reset the state completely.
     */
    clear(): void {
        this.state.clear();
    }

    /**
     * Add an actor to the state.
     */
    private addActor(actorId: string): void {
        this.state.set(actorId, new Map());
    }
}