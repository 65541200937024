import {FQN} from "common/FQN";
import {Optional} from "common/Optional";

/**
 * Utility function for Service methods
 */
export class ServiceUtils {

    /**
     * Get requesting header for asset.
     */
    static getRequestingAssetHeader(requestingFqn?: FQN): { [key: string]: string } {
        return Optional.of(requestingFqn)
            .map(fqn => {
                const header: { [key: string]: string } = {'X-Requesting-Asset': fqn.toString()};
                return header;
            })
            .getOr({});
    }
}