import {Enum} from "common/Enum";

export class FilterOperator extends Enum {

    static IN = new this('in', 'In');
    static NOT_IN = new this('notIn', 'Not In');
    static PREFIX = new this('prefix', 'Prefix');
    static TERM = new this('term', 'Term');
    static BETWEEN = new this('between', 'Between');
    static EQUAL = new this('equal', '=');
    static GREATER_THAN = new this('greaterThan', '>');
    static GREATER_THAN_EQUAL = new this('greaterThanEqual', '>=');
    static LESS_THAN = new this('lessThan', '<');
    static LESS_THAN_EQUAL = new this('lessThanEqual', '<=');
    static EXPRESSION = new this('expression', 'f(x)');

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}

FilterOperator.finalize();