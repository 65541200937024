import {Either} from "common/Either";
import {FQN} from "common/FQN";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {DatasetV2Service} from "services/DatasetV2Service";
import {ApiResponse, ErrorResponse} from "./ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {ExpiryCache} from "common/ExpiryCache";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ServiceUtils} from "services/ServiceUtils";

/**
 * API implementation of dataset v2 service.
 */
export class ApiDatasetV2Service implements DatasetV2Service {

    private readonly metadataCache: ExpiryCache<
        // fqn as a string
        string,
        // untyped cached asset
        Promise<Either<ErrorResponse, any>>
    >;

    constructor(
        cacheSize: number = 50,
        // invalidate cache at 10 minutes by default
        defaultCacheTimeout: number = 10 * 60 * 1000
    ) {
        this.metadataCache = new ExpiryCache(cacheSize, defaultCacheTimeout);
    }

    describeDataset(fqn: FQN, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcDataset>> {
        const headers: { [key: string]: string } = ServiceUtils.getRequestingAssetHeader(requestingFqn);
        const cacheKey = fqn.toString();
        return this.metadataCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService)
                .get(`/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/${fqn.type.plural}/${fqn.name}/describe`, signal, null, headers)
                .then(r => ApiResponse.custom(
                    r,
                    json => new ArcDataset(json),
                    (json: any, r: Response) => {
                        this.metadataCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                ))
        );
    }

    fetch(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/${fqn.type.plural}/${fqn.name}`, signal)
            .then(ApiResponse.success(DatasetV2.fromJSON));
    }

    save(fqn: FQN, dataset: DatasetV2, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/${fqn.type.plural}`,
                dataset.toJSON()
            ).then(ApiResponse.success((json, response) => {
                this.metadataCache.delete(fqn.toString());
                return DatasetV2.fromJSON(json);
            }));
    }

    getTableMetadata(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, RemoteTableMetadata>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/${fqn.type.plural}/${fqn.name}/table`, signal)
            .then(ApiResponse.success(RemoteTableMetadata.fromJSON));
    }


    upload(fqn: FQN, file: File, datasetLabel: string, signal?: AbortSignal): Promise<Either<ErrorResponse, DatasetV2>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', fqn.name);
        formData.append('label', datasetLabel);

        return ServiceProvider.get(RestService)
            .postForm(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/datasets_v2/upload`,
                formData,
                signal
            ).then(ApiResponse.success((json, response) => {
                this.metadataCache.delete(fqn.toString());
                return DatasetV2.fromJSON(json);
            }));
    }
}