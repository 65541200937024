import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {CreateQuery} from "metadata/dashboard/changes/CreateQuery";

/**
 * Create a query reference on the dashboard.
 *
 * @author zuyezheng
 */
export class DeleteQuery implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly queryId: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(
            metadata.with({
                queries: metadata.queries.without(this.queryId)
            }),
            new CreateQuery(metadata.queries.get(this.queryId))
        );
    }

    describe(): string {
        return `Removed query ${this.queryId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}