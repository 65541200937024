import * as React from "react"
import {SVGProps} from "react"

const BarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M5 9.7h3v9.8H5V9.7Zm11.2 3.8H19v6h-2.8v-6Zm-5.6-8h2.8v14h-2.8v-14Z"
        />
    </svg>
);
export default BarIcon;
