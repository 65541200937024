import styled from "@emotion/styled";
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import {GridRowParams} from "@mui/x-data-grid";
import {Optional} from "common/Optional";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import React, {useEffect, useRef, useState} from "react";
import {ServiceProvider} from "services/ServiceProvider";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import {ImageService} from "services/ImageService";
import {Either} from "common/Either";
import {ErrorCode, ErrorResponse} from "services/ApiResponse";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";

/**
 * Search result with required associated references loaded.
 */
export class LoadedSearchResult {
    id: string;
    result: AssetSearchResult;
    previewDataUrl: Optional<string>;

    static from(result: AssetSearchResult): LoadedSearchResult {
        return {
            id: result.id,
            result: result,
            previewDataUrl: Optional.none()
        };
    }
}

interface Props {
    searchParams: AssetSearchParams
    onSelect: (selected: NavigableSearchResult) => void
    rowType: SearchResultsRowType
    onSearch: (params: AssetSearchParams, controller: AbortController) => Promise<Either<ErrorResponse, AssetsSearchResponse>>
    onAccountBreadcrumb?: (account: string) => void
    className?: string,
    bordered?: boolean,
    // if true, constrains height to parent div and becomes scrollable if overflow.
    disableAutoHeight?: boolean,
    // notify which rows are selected for proper cell rendering within DataGridPro
    selectedRows?: AssetSearchResult[]
    // if true, hides project info columns
    disableProjectInfo?: boolean
    // bubble up selected rows (note: this will not carry over loaded images)
    bubbleUpSelected?: boolean
}

/**
 * Query and display search results given the search params.
 *
 * @author zuyezheng
 */
export const AssetSearchResultsTable = (props: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchResults, setSearchResults] = useState<Optional<LoadedSearchResult[]>>(Optional.none());
    const [totalResults, setTotalResults] = useState<number>(0);
    const [page, setPage] = useState<number>(0);

    const prevSearchParamsRef = useRef<AssetSearchParams>();

    useEffect(() => {
        // Check if searchParams have changed. If so, reset page to 0.
        if (JSON.stringify(props.searchParams) !== JSON.stringify(prevSearchParamsRef.current)) {
            prevSearchParamsRef.current = props.searchParams;
            if (page !== 0) {
                // this will re-trigger this effect as it also listens to page changes
                // (i.e. prevents an extraneous search when search params changing caused a page reset)
                setPage(0);
                return;
            }
        }

        setIsLoading(true);

        const controller = new AbortController();
        const imageService = ServiceProvider.get(ImageService);
        (async () => {
            const response = await props.onSearch(props.searchParams.with({page: page}), controller)
                .then(r =>
                    r.match(
                        response => response,
                        error => {
                            if (error.errorCode === ErrorCode.FORBIDDEN) {
                                ServiceProvider.get(NotificationsService).publish(
                                    'assetSearchResults', NotificationSeverity.ERROR, "You do not have permission to search these assets."
                                );
                            }
                        }
                    )
                );

            // early exit if search errored out
            if (response.isLeft) {
                setIsLoading(false);
                return;
            }
            const searchResponse = response.rightOrThrow();

            // for every result, try to fetch the preview image and convert it into a data url that can be paired
            // with the original search result
            let loadedResults = await Promise.all<LoadedSearchResult>(
                searchResponse.results.map(searchResult => {
                    const fetchImage: Promise<Optional<string>> =
                        // if we should load images and there is an image to load
                        (props.rowType.loadImages && searchResult.previewUrl != null)
                            ? imageService.getImageDataUrl(searchResult.previewUrl).then(r => r.optional())
                            : Promise.resolve(Optional.none());

                    return fetchImage.then(imageUrl => ({
                        id: searchResult.id,
                        result: searchResult,
                        previewDataUrl: imageUrl
                    }));
                })
            );

            // If bubbleUpSelected is true, adjust results so that selected results bubble up to the top
            if (props.bubbleUpSelected && props.selectedRows) {
                // if query term, only return match selected results; else if no query, always return selected
                const selectedResults: LoadedSearchResult[] =
                    !props.searchParams.isEmpty() ?
                        loadedResults.filter(result => selectedRowFqns.has(result.result.fullyQualifiedName)) :
                        props.selectedRows.map(LoadedSearchResult.from);
                const nonSelectedResults = loadedResults.filter(result => !selectedRowFqns.has(result.result.fullyQualifiedName));
                loadedResults = [...selectedResults, ...nonSelectedResults];
            }

            setSearchResults(Optional.some(loadedResults));
            setTotalResults(searchResponse.total);
            setIsLoading(false);
        })();

        return () => controller.abort();
    }, [page, props.searchParams]);

    const onRowClick = (params: GridRowParams) => {
        props.onSelect(params.row.result as AssetSearchResult);
    };


    const selectedRowFqns: Set<string> = props.selectedRows ?
        new Set(props.selectedRows.map(row => row.fullyQualifiedName)) :
        new Set();

    return <S.ResultsTable
        loading={isLoading}

        columnVisibilityModel={props.rowType.visibility(props.disableProjectInfo || false)}
        columns={props.rowType.columns(selectedRowFqns, props.disableProjectInfo || false, props.onAccountBreadcrumb || ((account:string) => {}))}
        rows={
            searchResults
                .map(results => results.map(props.rowType.rowBuilder))
                .getOr([])
        }
        onRowClick={onRowClick}

        pagination
        paginationMode="server"
        page={page}
        pageSize={props.searchParams.size}
        rowsPerPageOptions={[props.searchParams.size]}
        rowCount={totalResults}
        onPageChange={(newPage) => setPage(newPage)}

        density="standard"
        // Note: it's undefined here because if set to false, the footer disappears to the bottom rather
        // than being stickied (i.e the CSS is different and incorrect for false for some reason x.x)
        autoHeight={props.disableAutoHeight ? undefined : true}
        disableSelectionOnClick
        disableColumnMenu
        bordered={props.bordered || false}
        {...props.rowType.tableProps}
    />;

};

const S = {
    ResultsTable: styled(DataGridPro)<{ bordered?: boolean; }>`
        border: ${props => props.bordered ? '1px solid #e0e0e0' : '0'};

        &.card {
            .MuiDataGrid-columnHeaders {
                display: none;
            }
        }
    `
};
