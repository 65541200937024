import { Tuple } from "common/Tuple";
import { VisualizationConfig } from "metadata/query/ArcQLVisualizations";
import { VizProperty } from "app/visualizations/definition/property/VizProperty";
import { Optional } from "common/Optional";
import {VizSectionedProperty} from "app/visualizations/definition/property/VizSectionedProperty";

/**
 * Defines the properties available to configuring a chart.
 *
 * @author zuyezheng
 */
export abstract class VizDefinition {

    /**
     * Return all available visualization properties.
     */
    abstract get properties(): VizProperty<any>[];

    /**
     * Get the default property values.
     */
    abstract get defaults(): Map<string, any>;

    /**
     * Zip any available config values with their properties.
     */
    zip(config: VisualizationConfig): Tuple<VizProperty<any>, any>[] {
        const defaults = this.defaults;

        return this.properties.map(p =>
            Tuple.of(
                p,
                config.get(p.name)
                    // get the default as an optional if no value
                    .orElse(() => Optional.of(defaults.get(p.name)))
            )
        );
    }

    /**
     * Sanitize a given config, keeping only the properties defined in this definition and applying defaults if
     * possible.
     */
    sanitize(config: VisualizationConfig): VisualizationConfig {
        const newConfig = new Map();

        // Helper function to add property value to the new config
        const addPropertyToConfig = (property: VizProperty<any>, value: any) => {
            newConfig.set(property.name, value);
        };

        // Recursive function to sanitize properties
        const sanitizeProperty = (property: VizProperty<any>, config: VisualizationConfig, defaults: Map<string, any>) => {
            config.get(property.name)
                .forEach(v => addPropertyToConfig(property, v))
                .orForEach(() => Optional.of(defaults.get(property.name))
                    .forEach((v) => addPropertyToConfig(property, v))
                );

            if (property instanceof VizSectionedProperty) {
                property.childProperties.forEach(relatedProp => {
                    sanitizeProperty(relatedProp, config, defaults);
                });
            }
        };

        // Sanitize each property
        this.properties.forEach(property => {
            sanitizeProperty(property, config, this.defaults);
        });

        return config.withConfig(newConfig);
    }

}