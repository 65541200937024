import {ArcMessage} from "engine/ArcMessage";
import {ArcEngine} from "engine/ArcEngine";
import {ArcActor} from "engine/actor/ArcActor";
import {ArcEngineError} from "engine/ArcEngineError";
import {ActorStatus} from "engine/actor/ActorStatus";


/**
 * Limited interface for a specific actor to interact with the engine.
 *
 * @author zuyezheng
 */
export class ArcEngineActorConnector {

    constructor(
        private readonly engine: ArcEngine,
        private readonly actor: ArcActor
    ) { }

    publish(message: ArcMessage): void {
        this.engine.publish(this.actor, message);
    }

    getActorIds(includeSelf: boolean, includeSystem: boolean): Set<string> {
        const actorIds = this.engine.getActorIds(includeSystem);
        if (!includeSelf) {
            actorIds.delete(this.actor.id);
        }
        return actorIds;
    }

    getActor<T extends ArcActor>(id: string): T {
        return this.engine.getActor(id);
    }

    getActorStatuses(): Map<string, ActorStatus> {
        return this.engine.actorStatuses;
    }

    registerActor(actor: ArcActor): Promise<ActorStatus> {
        return this.engine.register(actor);
    }

    registerAll(actors: ArcActor[]): Promise<ActorStatus[]> {
        return this.engine.registerAll(actors);
    }

    collectActors<AT, RT>(clazz: abstract new (...args: any[]) => AT, collector: (actor: AT) => RT): RT[] {
        return this.engine.collectActors(clazz, collector);
    }

    removeActor(actorId: string): ArcEngineActorConnector {
        if (actorId === this.actor.id) {
            throw new ArcEngineError(`Can't remove yourself: ${actorId}.`);
        }

        this.engine.removeActor(actorId);
        return this;
    }

}