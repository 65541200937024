import * as React from "react";
import {ChangeEvent, FunctionComponent, useState} from "react";
import {TabProps} from "app/TabType";
import styled from "@emotion/styled";
import AceEditor from "react-ace";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {FontSizes} from "app/components/StyleVariables";
import {ServiceProvider} from "services/ServiceProvider";
import {Web3Service} from "services/Web3Service";
import {Tuple} from "common/Tuple";
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import { GridColDef, GridRowsProp } from "@mui/x-data-grid-premium";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { EvmChain, SemanticTransformer } from "semantic-abi";

/**
 * Builder to develop and test semantic ABIs before submission.
 */
export const SemanticAbiBuilder: FunctionComponent<TabProps> = (props: TabProps) => {

    const [chain, setChain] = useState<EvmChain>(EvmChain.ETHEREUM);
    const [transactionHash, setTransactionHash] = useState<string>('');
    const [abi, setAbi] = useState<string>('');
    const [results, setResults] = useState<Tuple<GridColDef[], GridRowsProp>>(null);

    const onChainChange = (event: SelectChangeEvent) => {
        setChain(EvmChain.get<EvmChain>(event.target.value as string));
    };

    const onTransactionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTransactionHash(event.target.value);
    };

    const onRun = () => {
        // parse the ABI into a transformer
        const transformer = new SemanticTransformer(JSON.parse(abi));
        // fetch the transactions and build them into a table
        ServiceProvider.get(Web3Service)
            // split the transaction hashes in case there are more than one and do some cleansing
            .receiptsAndTraces(chain, transactionHash.split(',').map(h => h.trim()))
            .then(result => {
                setResults(Tuple.of(
                    // transform the results into column definitions and rows
                    transformer.schema.columns.map(c => ({
                        'field': c.name,
                        'headerName': c.name
                    })),
                    transformer.transform(result.rightOrThrow()).map(
                        (row, rowI) => {
                            row['id'] = rowI;
                            Object.keys(row).forEach(k => {
                                // by default we decode web3 data as big ints, serialize them back to numbers
                                if (typeof row[k] === 'bigint') {
                                    row[k] = Number(row[k].toString());
                                }
                            });
                            return row;
                        }
                    )
                ));
            });
    };


    return <S.Builder>
        <S.Editor>
            <S.Controls>
                <S.Select value={chain.name} onChange={onChainChange}>
                    {
                        EvmChain.proxyCallSupported()
                            .map(chain => <MenuItem key={chain.name} value={chain.name}>{chain.name}</MenuItem>)
                    }
                </S.Select>
                <S.Input
                    margin="dense"
                    value={transactionHash}
                    onChange={onTransactionChange}
                    placeholder="Transaction Hash"
                />
                <Button variant="outlined" onClick={onRun}>Run</Button>
            </S.Controls>
            <S.AbiEditor>
                <AceEditor
                    mode="json"
                    theme="github"
                    height="100%"
                    width="100%"
                    value={abi}
                    onChange={setAbi}
                    editorProps={{
                        $blockScrolling: true
                    }}
                    setOptions={{
                        printMargin: 120,
                        wrap: true,
                        useWorker: false,
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true
                    }}
                />
            </S.AbiEditor>
        </S.Editor>
        <S.Preview>
            {
                results && <DataGridPro
                    columns={results.left}
                    rows={results.right}
                    pageSize={2000}
                    density="compact"
                    checkboxSelection={false}
                    disableMultipleSelection={true}
                    disableColumnMenu
                    disableColumnReorder
                />
            }
        </S.Preview>
    </S.Builder>;

};

class S {

    static readonly Builder = styled.div`
        padding: 10px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    `;

    static readonly Editor = styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
    `;

    static readonly Select = styled(Select)`
        margin: 0 8px 0 0;
 
        .MuiSelect-select {
            padding: 0 30px 0 10px;
        }
    `;

    static readonly Input = styled(TextField)`
        margin: 0 8px 0 0;
        flex: 1;

        input {
            font-size: ${FontSizes.small};
            padding: 8px 8px;
        }
    `;

    static readonly Preview = styled.div`
        padding-top: 10px;
        flex: 1;
        box-sizing: border-box;
    `;

    static readonly Controls = styled.div`
        padding-bottom: 10px;
        display: flex;
    `;

    static readonly AbiEditor = styled.div`
        flex: 1;
    `;

}