import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {AnalyticsType} from "metadata/AnalyticsType";
import {Optional} from "common/Optional";
import {SingleMetricData} from "app/visualizations/data/SingleMetricData";
import {VizSelection} from "engine/actor/VizSelection";
import { Either, Left, Right } from "common/Either";
import {QueryResult} from "metadata/query/QueryResult";

export class ScorecardData {

    constructor(
        private readonly response: ArcQLResponse,
        public readonly metric: string,
        public readonly metricIndex: number,
        public readonly sparklineData: {[key: string]: any}[],
        public readonly score: number
    ) { }

    /**
     * Initialize returning either self on success or a validation message if not.
     */
    static from(response: ArcQLResponse): Either<string, ScorecardData> {
        const result = response.result;
        const measures = result.columnsByType(new Set([AnalyticsType.MEASURE]));
        if (measures.length === 0) {
            return new Left('Need at least 1 measure for scorecard.');
        }

        // use the last measure for the scorecard
        const metric = measures[measures.length - 1];
        const metricIndex = result.columnIndices.get(metric);

        // data for the sparkline
        const sparklineData = new SingleMetricData(response, null, metricIndex)
            .dataset(VizSelection.none());

        return ScorecardData.getLastValue(result, metricIndex, 0)
            .map<Either<string, ScorecardData>>(v => {
                return new Right(new ScorecardData(
                    response,
                    metric,
                    metricIndex,
                    sparklineData,
                    v
                ));
            })
            .getOr(new Left('No results.'));
    }

    private static getLastValue(result: QueryResult, metricIndex: number, n: number): Optional<number> {
        if (n >= result.length) {
            return Optional.none();
        }

        return Optional.some(
            result.rows[result.length - n - 1][metricIndex]
        );
    }

    /**
     * If the metric has more than N data points, compute the change.
     */
    change(n: number = 1): Optional<number> {
        return ScorecardData.getLastValue(this.response.result, this.metricIndex, n)
            .map(v => (this.score - v) / v);
    }

}