import styled from "@emotion/styled";
import React, {ChangeEvent, FunctionComponent, ReactNode, useEffect, useState} from "react";
import {EditType, HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraphSelectionDetails} from "app/query/hypergraph/selection/HyperGraphSelectionDetails";
import {Either, Left, Right} from "common/Either";
import isEqual from "lodash/isEqual";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import {ServiceProvider} from "services/ServiceProvider";
import {HyperGraphService} from "services/hypergraph/HyperGraphService";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import similarity from "compute-cosine-similarity";
import {Optional} from "common/Optional";
import {ProcessFlowIcon} from 'app/query/hypergraph/nodes/ProcessFlowIcon';
import {StringUtils} from "common/StringUtils";
import {Colors} from "app/components/StyleVariables";
import {HyperGraphAnswer} from 'services/hypergraph/HyperGraphAnswer';
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {ArcDataset} from 'metadata/dataset/ArcDataset';
import {Markdown} from 'app/components/Markdown';


export type Props = {
    className?: string
    hyperGraph: HyperGraph
    dataset: ArcDataset

    onResetSelection(): void
    onNodeClick(nodeId: string, multiSelect: boolean, center: boolean): void
    onChangeRating(nodeId: string, rating: NodeRating): void
    onChangeContent(nodeId: string, type: EditType, content: string): void
}

/**
 * Tools pane for the HyperGraph.
 */
export const HyperGraphTools: FunctionComponent<Props> = (props: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>('');

    // right is currently open node details, left is closed node details to prevent reopening
    const [showDetails, setShowDetails] = useState<Either<Set<string>, HyperGraphNode[]>>(new Left(new Set()));

    const [searchResults, setSearchResults] = useState<Optional<HyperGraphAnswer>>(Optional.none());
    const [isSearching, setIsSearching] = useState<boolean>(false);

    useEffect(() => {
        // if there is a selection
        const selectedNodes = props.hyperGraph.selectedNodes;
        if (selectedNodes.length > 0) {
            const shouldShow = showDetails.fold(
                // if open, show
                () => true,
                // if closed, only show if the selections have changed
                prev => !isEqual(prev, props.hyperGraph.selectedNodeIds)
            );

            if (shouldShow) {
                setShowDetails(new Right(selectedNodes));
            }
        }
    }, [props.hyperGraph]);

    const onCloseDetails = () => {
        // change current selection from right to left
        setShowDetails(showDetails.flatMap(nodes =>
            new Left(new Set(nodes.map(node => node.id)))
        ));
        props.onResetSelection();
    };

    const buildSelectedDetails = () => {
        if (showDetails.isLeft || props.hyperGraph.selectedNodes.length === 0) {
            return;
        }

        return <HyperGraphSelectionDetails
            hyperGraph={props.hyperGraph}
            nodes={props.hyperGraph.selectedNodes}
            onClose={onCloseDetails}
            onNodeClick={props.onNodeClick}
            onChangeRating={props.onChangeRating}
            onChangeContent={props.onChangeContent}
        />;
    };

    const searchNodes = async (term: string) => {
        if (term.trim().length === 0) {
            setSearchResults(Optional.none());
            return;
        }

        setIsSearching(true);
        const hyperGraphService = ServiceProvider.get(HyperGraphService);
        const embedding = (await hyperGraphService.embedding(term)).rightOrThrow();

        // score each node given the search term
        const scoredNodes = props.hyperGraph.nodes
            // filter out nodes with no embedding
            .filter(node => node.nodeEmbedding != null && node.nodeEmbedding.length > 0)
            // disliked nodes will not be included for answers
            .filter(node => node.rating !== NodeRating.DISLIKE)
            .map<[HyperGraphNode, number]>(node => [node, similarity(embedding, node.nodeEmbedding)])
            // apply a threshold for irrelevant things
            .filter(([_, score]) => score > 0.4);

        if (scoredNodes.length === 0) {
            setSearchResults(Optional.some(HyperGraphAnswer.empty()));
        } else {
            // rank them
            scoredNodes.sort((a, b) => b[1] - a[1]);
            const nodesToConsider = scoredNodes.slice(0, 5).map(([node, _]) => node);

            // try to answer the question with the nodes
            const answer = (await hyperGraphService.answerWithNodes(
                term, nodesToConsider, props.hyperGraph, props.dataset
            )).rightOrThrow();
            setSearchResults(Optional.some(answer));
        }

        setIsSearching(false);
    };

    const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearchResults(Optional.none());
        setSearchTerm(e.target.value);
    };

    const onSearchKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            searchNodes(searchTerm);
        }
    };

    const buildSearchResults = (): ReactNode => {
        if (isSearching) {
            return <S.SearchResultsPane>Thinking...</S.SearchResultsPane>;
        }

        return searchResults.map(results => {
            const resultContent = results.optional
                .map(results => {
                    const nodes = results.nodes.map(result => {
                        const node = result.node;
                        return <S.SearchNode key={node.id} onClick={() => props.onNodeClick(node.id, false, true)}>
                            <ProcessFlowIcon operation={node.request.operation} isCompact={true}/>
                            <S.SearchNodeContent>
                                <S.SearchNodeLabel>{node.label(props.hyperGraph)}</S.SearchNodeLabel>
                                <S.SearchNodeWhy>{result.why}</S.SearchNodeWhy>
                            </S.SearchNodeContent>
                        </S.SearchNode>;
                    });

                    return <>
                        <S.SearchAnswer>
                            <Markdown>{results.answer}</Markdown>
                        </S.SearchAnswer>
                        <S.SearchNodes>
                            {nodes}
                        </S.SearchNodes>
                    </>;
                })
                .getOrElse(() => <>Nothing found to help with that.</>);

            return <S.SearchResultsPane>
                <S.SearchResults>
                    {resultContent}
                </S.SearchResults>
            </S.SearchResultsPane>;
        }).nullable;
    };
    const searchResultsComp = buildSearchResults();
    const searchClassName = StringUtils.toClassName({
        'withResults': searchResultsComp != null
    });

    return <S.Container>
        <S.Search className={searchClassName}>
            <TextField
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                }}

                autoComplete="off"
                fullWidth={true}
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={onSearchTermChange}
                onKeyDown={onSearchKeyDown}
            />
        </S.Search>
        { searchResultsComp }
        { buildSelectedDetails() }
    </S.Container>;

};

class S {

    static Container = styled.div`
        position: absolute;
        top: 34px;
        right: 34px;
        width: 50%;
        max-width: 340px;
        height: calc(100% - 50px);
        pointer-events: none;
        display: flex;
        flex-direction: column;
    `;

    static Search = styled.div`
        background: rgba(255, 255, 255, .97);
        box-shadow: rgba(0, 0, 0, 0.2) 0 2px 12px 0;
        margin-bottom: 8px;
        border-radius: 12px;
        pointer-events: all;
        position: relative;
        z-index: 1000;
        
        .MuiFormControl-root {
            margin-top: 0;
        }

        .MuiInputBase-root {
            border-radius: 12px;
        }
        
        &.withResults {
            border-radius: 12px 12px 0 0;
            margin-bottom: 0;
            
            .MuiInputBase-root {
                border-radius: 12px 12px 0 0;
            }
        }
    `;

    static SearchResultsPane = styled.div`
        pointer-events: all;
        border-radius: 0 0 12px 12px;
        background: rgba(255, 255, 255, .97);
        padding: 8px;
        margin-bottom: 8px;
        border: 1px solid ${Colors.borderGrey};
        border-top: none;
        overflow: hidden;
        max-height: 50%;
        flex: none;
    `;

    static SearchResults = styled.div`
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
    `;

    static SearchAnswer = styled.div`
        padding: 8px;
    `;

    static SearchNodes = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static SearchNode = styled.div`
        display: flex;
        flex-direction: row;
        padding-top: 8px;
        cursor: pointer;
    `;

    static SearchNodeContent = styled.div`
        display: flex;
        flex-direction: column;
        padding-left: 8px;
    `;

    static SearchNodeLabel = styled.div`
        font-weight: 600;
    `;

    static SearchNodeWhy = styled.div`

    `;

}