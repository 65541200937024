import {Either, Left, Right} from "common/Either";
import {ArcQLField, ColumnField} from "metadata/query/ArcQLField";
import {AnalyticsType} from "metadata/AnalyticsType";
import {MeasureField, MeasureOperator} from "metadata/query/MeasureField";
import {DetailField} from "metadata/query/DetailField";
import {DetailDateField} from "metadata/query/DetailDateField";
import {DateGrain} from "metadata/query/DateGrain";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {Optional} from "common/Optional";
import {Column} from "metadata/Column";
import {ProjectionValidation} from "metadata/query/ProjectionValidation";

/**
 * Helps with constructing parts of an ArcQL query.
 *
 * @author zuyezheng
 */
export class ArcQLBuilder {

    constructor(
        public readonly dataset: ArcDataset
    ) {
    }


    /**
     * Build a new query field for the given dataset column name, returning a left string for errors.
     */
    public buildField(
        query: ArcQL,
        columnName: string,
        skipValidation: boolean = false
    ): Either<string, ColumnField> {
        const column = this.dataset.get(columnName);

        let fieldToBuild: ColumnField = this.newFieldFromColumn(query, column);
        if (!skipValidation) {
            const validationError = this.validateField(fieldToBuild, query);
            if (validationError.isPresent) {
                return new Left(validationError.get());
            }
        }
        return new Right(fieldToBuild);
    }

    /**
     * Validate the field name, returning string for errors.
     */
    public validateField(field: ArcQLField, query: ArcQL): Optional<string> {
        const columnName = Optional.ofType(field, ColumnField).map(f => f.field).getOr(null);

        // validate as has no illegal characters
        if (!ProjectionValidation.isValid(field.as)) {
            return Optional.some(`Field name '${field.as}' cannot contain ${ProjectionValidation.ILLEGAL_CHARS}.`);
        }

        // if columnName null, means it's not a ColumnField and is an expression field. Hence no validation needed.
        if (columnName == null) {
            return Optional.none();
        }
        const column = this.dataset.get(columnName);
        // build a field for a grouped query
        if (query.isGrouped()) {
            // if it's a multi value field, can't aggregate by it
            if (column.isMultiValue) {
                return Optional.of(`${column.label} is a multi value field which can only be filtered or grouped by.`);
            }

            if (column.analyticsType === AnalyticsType.DATE) {
                return Optional.of('Can\'t use a date as a field when there are groupings, have you tried grouping by it?');
            }

            if (query.fields.has(field.as)) {
                return Optional.some(
                    `Field with the same column and default label exists with '${field.as}', please change its label first.`
                );
            }
        }
        return Optional.none();
    }

    /**
     * Construct new field from column.
     */
    private newFieldFromColumn(query: ArcQL, column: Column): ColumnField {
        if (query.isGrouped()) {
            switch (column.analyticsType) {
                case AnalyticsType.DIMENSION:
                    return new MeasureField(column.name, MeasureOperator.DISTINCT_HLL, MeasureOperator.DISTINCT_HLL.defaultLabel(column.label));
                case AnalyticsType.MEASURE:
                    return new MeasureField(column.name, MeasureOperator.AVG, MeasureOperator.AVG.defaultLabel(column.label));
                case AnalyticsType.DATE:
                    return new MeasureField(column.name, MeasureOperator.DISTINCT_HLL, column.label);
            }
        } else {
            switch (column.analyticsType) {
                case AnalyticsType.DIMENSION:
                case AnalyticsType.MEASURE:
                    return new DetailField(column.name, null, column.label);
                case AnalyticsType.DATE:
                    return new DetailDateField(column.name, DateGrain.SEC, column.label);
            }
        }
    }

}