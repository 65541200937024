import * as React from "react";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import FormControl from "@mui/material/FormControl";
import {Optional} from "common/Optional";
import {BlurTextField} from "app/components/input/BlurTextField";

export class VizStringProperty implements VizProperty<string> {

    constructor(
        public readonly name: string,
        public readonly label: string
    ) {}

    el(v: Optional<string>, onChange: (v: string) => void): React.ReactNode {
        return <FormControl fullWidth size="small">
            <BlurTextField
                label={this.label}
                value={v.getOr('')}
                onChange={onChange}
            />
        </FormControl>;
    }

}