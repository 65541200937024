import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";

/**
 * No-op change for things that can't be undone/redone such as removing a column that no longer exists.
 *
 * @author zuyezheng
 */
export class DashboardNoopChange implements ArcMetadataChange<ArcDashboard> {

    constructor(
        private readonly opposite: ArcMetadataChange<ArcDashboard>,
        private readonly description: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(metadata, this.opposite);
    }

    describe(): string {
        return this.description;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}