import * as React from 'react';
import {useEffect} from 'react';
import styled from '@emotion/styled';
import {ArcDashboard} from 'metadata/dashboard/ArcDashboard';
import {ArcEngine} from "engine/ArcEngine";
import {DashboardFilterActor} from "app/dashboard/DashboardFilterActor";
import {ReferencedQueryActor} from "engine/actor/ReferencedQueryActor";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {Optional} from "common/Optional";
import {SingleTrendView} from "app/dashboard/components/SingleTrendView";
import {DashboardState, DashboardStateJson} from "metadata/dashboard/DashboardState";
import {StateConverter} from "app/dashboard/StateConverter";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ArcTrendListItem} from "metadata/trend/ArcTrendListItem";
import {TrendsService} from "services/TrendsService";
import {WidgetTrendsMap} from "metadata/trend/WidgetTrendsMap";
import {EmptyTrendView} from "app/dashboard/components/EmptyTrendView";
import {Alert} from "metadata/trend/Alert";

export interface TrendsPanelProps {
    className?: string;
    dashboard: ArcDashboard;
    filterActor: DashboardFilterActor;
    engine: ArcEngine;
    trends: WidgetTrendsMap
    widgetContext: TrendWidgetContext;
    handleTrendCreate: (ctx: TrendWidgetContext) => void;
    onSaveAlert: (savedAlert: Alert) => void;
    onClose: () => void;
}

/**
 * Context for a trend widget.
 */
export type TrendWidgetContext = {
    widgetId: string;
    label: string;
    actor: ReferencedQueryActor;
    loadedTrend: Optional<ArcTrend>;
};

/**
 * Need to ensure selected widget + widgetTrends are synced together, or else the selected trend may not be in the list.
 */
type PanelState = {
    selectedTrend: Optional<ArcTrend>;
    widgetTrends: ArcTrendListItem[];
};

/**
 * Panel for displaying and creating trends for a dashboard + particular widget.
 */
export const TrendsPanel = (props: TrendsPanelProps) => {

    const [panelState, setPanelState] = React.useState<PanelState>({
        selectedTrend: Optional.none(),
        widgetTrends: []
    });

    // handle widget context change
    useEffect(() => {

        // if the widget context already has a loaded trend, use that and set the panel state accordingly
        if (props.widgetContext.loadedTrend.isPresent) {
            setPanelState({
                selectedTrend: props.widgetContext.loadedTrend,
                widgetTrends: props.trends.forWidget(props.widgetContext.widgetId)
            });
            return;
        }

        // automatically select first trend of widget if applicable
        props.trends.firstForWidget(props.widgetContext.widgetId).match(
            trend => onSelectTrend(trend),
            () => setPanelState({selectedTrend: Optional.none(), widgetTrends: []})
        );

    }, [props.widgetContext]);

    const onSelectTrend = (trend: ArcTrendListItem): void => {
        // fetch trend individually, which will have all related entities like arcql and session fully loaded
        ServiceProvider.get(TrendsService).fetchTrend(trend.fqn).then(response => response.match(
            async t => {
                const loadedDashboardState = await DashboardState.fromJSON(t.session.state as DashboardStateJson);
                // load the dashboard state upon which the trend was created from
                StateConverter.toArcEngineState(props.dashboard, loadedDashboardState)
                    .then(engineStateToLoad => props.engine.loadState(engineStateToLoad));
                setPanelState({
                    selectedTrend: Optional.of(t),
                    widgetTrends: props.trends.forWidget(props.widgetContext.widgetId)
                });
            },
            error => {
                ServiceProvider.get(NotificationsService).publish(
                    'TrendsPanel', NotificationSeverity.ERROR, `Failed to load trend: ${error.prettyPrint()}`
                );
            }
        ));
    };

    return (
        <S.Container className={props.className}>
            {
                panelState.selectedTrend.map(
                    t => <SingleTrendView
                        trend={t}
                        dataset={props.widgetContext.actor.dataset}
                        widgetTrends={panelState.widgetTrends}
                        onSelectTrend={onSelectTrend}
                        onSaveAlert={props.onSaveAlert}
                        onClose={props.onClose}
                    />
                ).getOr(
                    <EmptyTrendView
                        queryLabel={props.widgetContext.label}
                        handleTrendCreate={() => props.handleTrendCreate(props.widgetContext)}
                        onClose={props.onClose}
                    />
                )
            }
        </S.Container>
    );
};

class S {
    static readonly Container = styled.div`
        overflow: hidden;
        display: flex;
        flex-direction: column;
    `;
}
