import {ArcMetadataChange} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {MeasureOperator} from "metadata/query/MeasureField";
import {ArcQLField, ColumnField} from "metadata/query/ArcQLField";
import {AnalyticsType} from "metadata/AnalyticsType";
import {FieldGrouping} from "metadata/query/ArcQLGroupings";
import {AddGrouping} from "metadata/query/changes/AddGrouping";
import {DeleteField} from "metadata/query/changes/DeleteField";
import {ToMeasureFieldsChange} from "metadata/query/changes/ToMeasureFieldsChange";
import {DeleteOrderBy} from "metadata/query/changes/DeleteOrderBy";
import {DeleteGrouping} from "metadata/query/changes/DeleteGrouping";
import {ToDetailFieldsChange} from "metadata/query/changes/ToDetailFieldsChange";
import {Optional} from "common/Optional";
import {DateGrouping} from "metadata/query/DateGrouping";
import {DetailDateField} from "metadata/query/DetailDateField";
import {DetailField} from "metadata/query/DetailField";
import {ExpressionGrouping} from "metadata/query/ExpressionGrouping";
import {ExpressionField} from "metadata/query/ExpressionField";
import {AddField} from "metadata/query/changes/AddField";
import {DateGrain} from "metadata/query/DateGrain";
import {ArcQLVisualizationChange} from "metadata/query/changes/ArcQLVisualizationChange";
import {VizType} from "metadata/query/VizType";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Helps with converting between query shapes.
 *
 * @author zuyezheng
 */
export class ArcQLConverter {

    constructor(
        private readonly dataset: ArcDataset
    ) { }

    toGroupedChanges(query: ArcQL): ArcMetadataChange<ArcQL>[] {
        const changes: ArcMetadataChange<ArcQL>[] = [];

        const toGroupings = new Map<string, ArcQLGrouping>();
        const toMeasures: [string, MeasureOperator][] = [];
        // stuff we want convert
        const toRemove: ArcQLField[] = [];
        query.fields.fields.forEach(f => {
            if (f instanceof ColumnField) {
                switch (this.dataset.get(f.field).analyticsType) {
                    case AnalyticsType.DIMENSION:
                        // use up to 3 dimensions as groupings and dedupe for fields that use the same column since
                        // we can only group by a column once
                        if (!toGroupings.has(f.field) && toGroupings.size < 3) {
                            toGroupings.set(f.field, new FieldGrouping(f.field));
                            // need to remove it from measures
                            toRemove.push(f);
                        } else {
                            // otherwise add it as a count
                            toMeasures.push([f.as, MeasureOperator.COUNT]);
                        }

                        return;
                    case AnalyticsType.MEASURE:
                        toMeasures.push([f.as, MeasureOperator.SUM]);
                        return;
                    case AnalyticsType.DATE:
                        // TODO we should probably group by 1 date
                        toRemove.push(f);
                        return;
                }
            }

            // if we got here, don't know what to do with it
            toRemove.push(f);
        });
        // add new groupings
        changes.push(...Array.from(toGroupings.values()).map(
            g => new AddGrouping(g, this.dataset)
        ));
        // delete the fields we couldn't convert to groupings in reverse so undo puts them in the right order
        changes.push(...toRemove.reverse().map(
            f => new DeleteField(f.as)
        ));
        // convert detail fields to measures
        changes.push(new ToMeasureFieldsChange(toMeasures));

        return changes;
    }

    /**
     * Build the set of changes to convert to a detail query.
     */
    toDetailChanges(query: ArcQL): ArcMetadataChange<ArcQL>[] {
        const changes: ArcMetadataChange<ArcQL>[] = [];

        // change to a detail query by removing all the sorts first since details will be much higher cardinality,
        // we do this first to avoid conflicts where delete groupings will also remove order bys
        changes.push(...query.orderBys.map(
            o => new DeleteOrderBy(o.field, this.dataset)
        ).reverse());
        // delete all groupings in reverse so they're added back in the right order
        changes.push(...query.groupings.map(
            g => new DeleteGrouping(g.field, this.dataset)
        ).reverse());

        // see which fields to convert to details and which to remove
        const toDetail: [string, null][] = [];
        const toRemove: ArcQLField[] = [];
        query.fields.fields.forEach(f => {
            if (f instanceof ColumnField
                // delete star fields
                && !f.isStar
                // can't convert it if not a measure
                && f.analyticsType(this.dataset) === AnalyticsType.MEASURE
            ) {
                toDetail.push([f.as, null]);
            } else {
                toRemove.push(f);
            }
        });
        // delete what we need to in reverse
        changes.push(...toRemove.reverse().map(
            f => new DeleteField(f.as)
        ));
        // convert the remaining
        if (toDetail.length > 0) {
            changes.push(new ToDetailFieldsChange(toDetail));
        }

        // try to convert groupings to grains
        query.groupings.fields.forEach(
            g => Optional.ofType(g, DateGrouping)
                .map<ArcQLField>(g => new DetailDateField(g.field, g.grain, g.field))
                .orElse(
                    () => Optional.ofType(g, FieldGrouping)
                        .map(g => new DetailField(g.field, null, g.field))
                ).orElse(
                    () => Optional.ofType(g, ExpressionGrouping)
                        .map(g => new ExpressionField(g.expression, g.as))
                ).forEach(
                    f => changes.push(new AddField(f))
                )
        );

        // if there are no fields we can convert to details, this conversion won't "stick" as a default measure will
        // be added so add a default dimension field
        if (query.groupings.size === 0 && toDetail.length === 0) {
            const defaultField = this.dataset.primaryDate()
                .map<ColumnField>(f => new DetailDateField(f.name, DateGrain.SEC, f.name))
                .getOrElse(() => {
                    const firstDimension = this.dataset.dimensions.values().next().value;
                    return new DetailField(firstDimension.name, null, firstDimension.name);
                });

            changes.push(new AddField(defaultField));
        }

        // change to a table as a default
        changes.push(ArcQLVisualizationChange.default(VizType.TABLE));

        return changes;
    }

}