import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Move a grouping to a new ordinal position
 *
 * @author zuyezheng
 */
export class MoveGrouping implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly fieldName: string,
        public readonly ordinal: number,
        public readonly dataset: ArcDataset
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const grouping = metadata.groupings.find(this.fieldName).get();
        return new Tuple(
            metadata.with({
                groupings: metadata.groupings.without(this.fieldName)
                    .with(grouping.left, this.ordinal),
            }),
            new MoveGrouping(this.fieldName, grouping.right, this.dataset)
        );
    }

    describe(metadata: ArcQL): string {
        const groupingLabel = metadata.groupings.get(this.fieldName).label(this.dataset);
        return `Reordered grouping ${groupingLabel} to ${this.ordinal}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}