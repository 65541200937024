import * as React from "react";
import {FunctionComponent, useRef} from "react";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {Optional} from "common/Optional";
import {S as CanvasS} from "app/query/QueryBuilderCanvasS";
import {FilterPanelContent} from "app/query/panels/FilterPanelContent";
import styled from "@emotion/styled";
import {IconButton} from "@mui/material";
import {Settings} from "@mui/icons-material";
import {TypedMenu} from "app/components/TypedMenu";
import {FilterPanelAction} from "app/query/panels/FilterPanelAction";
import {FilterExpressionEditor} from "app/query/filters/FilterExpressionEditor";
import {ArcQL} from "metadata/query/ArcQL";

type Props = {
    className?: string
    arcqlBundle: ArcQLBundle
    drillClauses: Optional<FilterClause[]>
    delegate: QueryBuilderDelegate
    onDragStart: (event: React.DragEvent) => void
    onDragOver: (event: React.DragEvent) => void
    onDrop: (event: React.DragEvent) => void
    onDeleteFilter: (ordinal: number, isAggregate: boolean) => void
}

/**
 * Complete panel for editing and creating pre and post aggregate filters.
 */
export const FilterPanel: FunctionComponent<Props> = (props: Props) => {
    const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = React.useState<HTMLElement>(null);
    const [expressionEditorCtx, setExpressionEditorCtx] = React.useState<Optional<ExpressionEditorCtx>>(Optional.none);
    const settingsButtonRef = useRef<HTMLButtonElement>(null);

    const onCloseMenu = () => {
        setSettingsMenuAnchorEl(null);
    };

    const onFilterPanelAction = (el: HTMLElement, action: FilterPanelAction) => {
        switch (action) {
            case FilterPanelAction.EDIT_FILTER_EXPRESSION:
                setExpressionEditorCtx(Optional.of({el: el, isAggregate: false}));
                break;
            case FilterPanelAction.EDIT_AGGREGATE_FILTER_EXPRESSION:
                setExpressionEditorCtx(Optional.of({el: el, isAggregate: true}));
                break;
            case FilterPanelAction.RESET_FILTER_EXPRESSION:
                props.delegate.resetFilterExpression(false);
                break;
            case FilterPanelAction.RESET_AGGREGATE_FILTER_EXPRESSION:
                props.delegate.resetFilterExpression(true);
                break;
        }
        onCloseMenu();
    };

    const onCloseExpressionEditor = () => {
        setExpressionEditorCtx(Optional.none());
    };

    const onSaveFilterExpression = (expression: string, isAggregate: boolean) => {
        props.delegate.modifyFilterExpression(expression, isAggregate);
        onCloseExpressionEditor();
    };

    return <CanvasS.Shelf onDragOver={props.onDragOver} onDrop={props.onDrop}>
        <S.ShelfHeader>
            <span>Filters</span>
            <S.Setting
                size="small"
                ref={settingsButtonRef}
                onClick={() => setSettingsMenuAnchorEl(settingsButtonRef.current)}
            >
                <Settings/>
            </S.Setting>
        </S.ShelfHeader>
        {
            settingsMenuAnchorEl && <TypedMenu
                el={settingsMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                items={
                    [FilterPanelAction.visibleActions(props.arcqlBundle.arcql.filters, props.arcqlBundle.arcql.aggregateFilters)]
                }
                itemId={item => item.name}
                itemLabel={item => item.label}
                isDisabled={item => item.isDisabled(props.arcqlBundle.arcql)}
                onSelect={item => onFilterPanelAction(settingsMenuAnchorEl, item)}
                onClose={onCloseMenu}
            />
        }
        <CanvasS.ShelfContent>
            <FilterPanelContent
                arcqlBundle={props.arcqlBundle}
                drillClauses={props.drillClauses}
                isAggregate={false}
                delegate={props.delegate}
                onDragStart={props.onDragStart}
                onExpressionClick={(el: Element) => setExpressionEditorCtx(Optional.of({el: el, isAggregate: false}))}
                onDeleteFilter={props.onDeleteFilter}
            />
            {
                props.arcqlBundle.arcql.aggregateFilters.size > 0 && <>
                    <CanvasS.FiltersSeperator>
                        <CanvasS.FiltersSeperatorSpacing className="left"/>
                        <CanvasS.FiltersSeperatorLabel>Aggregate</CanvasS.FiltersSeperatorLabel>
                        <CanvasS.FiltersSeperatorSpacing className="right"/>
                    </CanvasS.FiltersSeperator>
                    <FilterPanelContent
                        arcqlBundle={props.arcqlBundle}
                        drillClauses={Optional.none()}
                        isAggregate={true}
                        delegate={props.delegate}
                        onDragStart={props.onDragStart}
                        onExpressionClick={(el: Element) => setExpressionEditorCtx(Optional.of({
                            el: el,
                            isAggregate: true
                        }))}
                        onDeleteFilter={props.onDeleteFilter}
                    />
                </>
            }
            {
                expressionEditorCtx.map(ctx => {
                    const filters = props.arcqlBundle.arcql.filtersFor(ctx.isAggregate);
                    return <FilterExpressionEditor
                        el={ctx.el}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        expression={filters.expression ?? ArcQL.defaultFilterExpression(filters)}
                        onSave={expression => onSaveFilterExpression(expression, ctx.isAggregate)}
                        onClose={onCloseExpressionEditor}
                    />
                }).getOr(null)
            }
        </CanvasS.ShelfContent>
    </CanvasS.Shelf>;
};

type ExpressionEditorCtx = {
    el: Element,
    isAggregate: boolean
}

class S {

    static readonly ShelfHeader = styled(CanvasS.ShelfHeader)`
        // rather than 18px padding on right, reduce to 3px due to setting button
        padding-right: 3px;
    `;

    static readonly Setting = styled(IconButton)`
        // have it at tail end of shelf header
        margin-left: auto;

        svg {
            width: 16px;
            height: 16px;
        }
    `;

}
