import { Enum } from "common/Enum";

/**
 * Enumeration of hypergraph embedding models.
 *
 * @author zuyezheng
 */
export default class EmbeddingModel extends Enum {

    static readonly CHISEL = new this('chisel');
    static readonly PLANE = new this('plane');

    private constructor(name: string) {
        super(name);
    }

}
EmbeddingModel.finalize();
