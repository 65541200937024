import * as React from "react";
import {ChangeEvent, FunctionComponent, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {Optional} from "common/Optional";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {FolderResult} from "metadata/project/FolderResult";
import {MetadataService} from "services/MetadataService";
import FolderPatchRequest from "metadata/project/FolderPatchRequest";
import {DialogS} from "app/DialogS";

type Props = {
    project: FolderResult
    onCancel: () => void
    onEdit: (editedProject: FolderResult) => void
}

/**
 * Dialog for editing a project such as label and description.
 */
export const EditProjectDialog: FunctionComponent<Props> = (props: Props) => {

    const [label, setLabel] = useState<string>(props.project.label);
    const [description, setDescription] = useState<string>(props.project.description || '');

    const isAbsent = (s: string): boolean => {
        return Optional.string(s).map(_ => false).getOr(true);
    };

    const originalLabel = props.project.label;

    const onEdit = () => {
        ServiceProvider.get(MetadataService).patchFolder(
            props.project.ownerName,
            props.project.name,
            new FolderPatchRequest(label, description)
        ).then(response => response.match(
            folder => {
                ServiceProvider.get(NotificationsService).publish(
                    'editProject', NotificationSeverity.SUCCESS, `Successfully edited Project @${folder.getFolderUrl()}!`
                );
                props.onEdit(folder);
            },
            error => {
                ServiceProvider.get(NotificationsService).publish(
                    'editProject', NotificationSeverity.ERROR, `Project editing failed: ${error.prettyPrint()}`
                );
            }
        ));
    };

    const descriptionChanged = props.project.description !== description.trim();
    const labelChanged = originalLabel !== label.trim();

    return <Dialog
        open={true}
        onClose={props.onCancel}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        <DialogS.Title>{`Edit "${originalLabel}" Project`}</DialogS.Title>
        <DialogS.Content dividers>
            <DialogS.Body>
                <DialogS.SelectorRow>
                    <FormControl fullWidth>
                        <FormLabel id="name-label" sx={{mb: 0.5}}>Name</FormLabel>
                        <TextField
                            autoFocus
                            margin="none"
                            size="small"
                            id="label"
                            aria-labelledby="name-label"
                            placeholder="Enter a new name for the Project"
                            value={label}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
                            sx={{
                                width: "280px"
                            }}
                        />
                    </FormControl>
                </DialogS.SelectorRow>
                <DialogS.InputRow>
                    <FormControl fullWidth>
                        <FormLabel id="description-label" sx={{mb: 0.5}}>Description</FormLabel>
                        <TextField
                            margin="none"
                            size="small"
                            id="description"
                            aria-labelledby="description-label"
                            placeholder="Enter a new description for the Project"
                            value={description}
                            fullWidth={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                        />
                    </FormControl>
                </DialogS.InputRow>
            </DialogS.Body>
        </DialogS.Content>
        <DialogS.Actions>
            <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
            <Button
                variant="contained"
                onClick={onEdit}
                disabled={isAbsent(label) || (!labelChanged && !descriptionChanged)}
            >
                Save
            </Button>
        </DialogS.Actions>
    </Dialog>;
};