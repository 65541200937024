import {JsonObject} from "common/CommonTypes";
import {FQN} from "common/FQN";
import {References} from "metadata/References";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {Optional} from "common/Optional";

export type ArcTrendListItemJson = {
    id: string,
    fqn: string,
    name: string,
    label: string,
    description: string,
    arcQLId: string,
    filters: JsonObject,
    sessionId: string,
    widgetId: string,
    createdBy: string,
    createdOn: string,
    references: JsonObject,
    version: number,
    previewUrl: string,
    alertsCount: number
}

/**
 * Trend result model in list.
 */
export class ArcTrendListItem {

    static fromJSON(json: ArcTrendListItemJson): ArcTrendListItem {
        const references: References = References.fromJSON(json.references);
        const fqn = FQN.parse(json.fqn);
        return new ArcTrendListItem(
            json.id,
            fqn,
            json.name,
            json.label,
            json.description,
            json.arcQLId,
            ArcQLFilters.fromJSON(json.filters, false, references),
            json.sessionId,
            json.widgetId,
            json.createdBy,
            json.createdOn,
            references,
            json.version,
            json.previewUrl,
            json.alertsCount,
            Optional.none()
        );
    }

    private constructor(
        public readonly id: string,
        public readonly fqn: FQN,
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        public readonly arcQLId: string,
        public readonly filters: ArcQLFilters,
        public readonly sessionId: string,
        public readonly widgetId: string,
        public readonly createdBy: string,
        public readonly createdOn: string,
        public readonly references: References,
        public readonly version: number,
        public readonly previewUrl: string,
        public readonly alertsCount: number,
        public readonly previewDataUrl: Optional<string> = Optional.none(),
    ) {
    }

    with({
        previewDataUrl
    }: {
        previewDataUrl?: string
    }): ArcTrendListItem {
        return new ArcTrendListItem(
            this.id,
            this.fqn,
            this.name,
            this.label,
            this.description,
            this.arcQLId,
            this.filters,
            this.sessionId,
            this.widgetId,
            this.createdBy,
            this.createdOn,
            this.references,
            this.version,
            this.previewUrl,
            this.alertsCount,
            previewDataUrl ? Optional.of(previewDataUrl) : this.previewDataUrl
        );
    }
}