import * as React from "react";
import {FunctionComponent} from "react";
import {Position} from "reactflow";
import {HyperGraphNodeProps} from "app/query/hypergraph/nodes/HyperGraphNodeProps";
import styled from "@emotion/styled";
import {StringUtils} from "common/StringUtils";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {FlowNodeS} from "app/query/hypergraph/nodes/FlowNodeS";


/**
 * Node indicating the start of an automated process.
 *
 * @author zuyezheng.
 */
export const ProcessStartNode: FunctionComponent<HyperGraphNodeProps> = (props: HyperGraphNodeProps) => {

    const containerClassNames = {
        'selected': props.selected
    };

    return <S.Container className={StringUtils.toClassName(containerClassNames)}>
        <FlowNodeS.Handle type="target" position={Position.Top} />
        <S.Content>
            <S.Label>{props.data.label}</S.Label>
        </S.Content>
        <FlowNodeS.Handle type="source" position={Position.Bottom} />
    </S.Container>;

};

class S {

    static Container = styled.div`
        width: 150px;
        height: 30px;
        
        border-radius: 4px; 
        border: 1px solid #CFD8DC;
        background: #ECEFF1;
        padding: 1px;
        display: flex;

        &.selected {
            border: 2px solid ${Colors.hyperarcYellow};
            padding: 0;
        }
    `;

    static Content = styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    `;

    static Label = styled.div`
        font-size: ${FontSizes.small};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    `;

}