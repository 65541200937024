import * as React from "react"
import {SVGProps} from "react"

const BlockHashEntityIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
        {...props}
    >
        <path
            d="M12.5 4.5 7 2 1.5 4.5m11 0v6L7 13m5.5-8.5L7 7m0 6-5.5-2.5v-6M7 13V7M1.5 4.5 7 7"
        />
    </svg>
)
export default BlockHashEntityIcon
