import * as React from "react";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {Optional} from "common/Optional";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import {S} from "app/visualizations/definition/property/VizPropertyS";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";

/**
 * A property in which when enabled, expands to then show and allow configuration of related properties.
 */
export class VizSectionedProperty implements VizProperty<boolean> {
    constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly childProperties: VizProperty<any>[]
    ) {
    }

    el(v: Optional<boolean>, onChange: (v: boolean, propertyName: string) => void, config: VisualizationConfig): React.ReactNode {
        const expanded = v.getOr(false);

        const handleChange = (checked: boolean) => {
            onChange(checked, this.name);
        };

        const handleChildChange = (childPropName: string, value: any) => {
            return onChange(value, childPropName);
        };

        return (
            <FormControl fullWidth size="small">
                <S.Toggle>
                    <S.ToggleSwitch>
                        <Switch
                            color="primary"
                            checked={expanded}
                            onChange={e => handleChange(e.target.checked)}
                        />
                    </S.ToggleSwitch>
                    <S.ToggleLabel>{this.label}</S.ToggleLabel>
                </S.Toggle>
                {expanded && this.childProperties && (
                    <S.ExpandedSection>
                        {
                            this.childProperties.map((prop) =>
                                // cloning this element, so we can add key id (required by React)
                                React.cloneElement(
                                    prop.el(
                                        config.get(prop.name),
                                        (v: any) => handleChildChange(prop.name, v),
                                        config
                                    ) as React.ReactElement,
                                    {key: prop.name}
                                )
                            )
                        }
                    </S.ExpandedSection>
                )}
            </FormControl>
        );
    }
}
