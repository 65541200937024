import * as React from "react";
import {FunctionComponent} from "react";
import {DetailField, DetailOperator} from "metadata/query/DetailField";
import {ColumnField} from "metadata/query/ArcQLField";
import {FieldSettingView} from "app/query/components/setting/FieldSettingView";
import {ArcDataset} from "metadata/dataset/ArcDataset";

type Props = {
    // field being modified
    field: DetailField,
    // field label to display
    fieldLabel: string,
    // if modifying an existing field vs new
    modifying: boolean,
    // callback when the field has changed
    onChange: (field: ColumnField) => void
    // on back of the setting view
    onBack: () => void
}

/**
 * Setting view for editing a detail field.
 */
export const DetailFieldSettingView: FunctionComponent<Props> = (props: Props) => {

    const onChangeOperator = (operator: DetailOperator) => {
        props.onChange(props.field.with({operator: operator}));
    };

    return <FieldSettingView
        fieldLabel={props.fieldLabel}
        fieldCategorization="Field"
        settingLabel="aggregation"
        modifying={props.modifying}
        field={props.field}
        items={DetailOperator.enums<DetailOperator>()}
        isSelected={item => props.field.operator === item}
        onChange={item => onChangeOperator(item)}
        onBack={props.onBack}
    />;
};