import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcQLField} from "metadata/query/ArcQLField";
import {Optional} from "common/Optional";

/**
 * Modify a field.
 *
 * @author zuyezheng
 */
export class ModifyField implements ArcMetadataChange<ArcQL> {

    constructor(
        // modify the field for the given projected as
        public readonly originalAs: string,
        public readonly field: ArcQLField
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        // if projected as has changed, see if there is an existing order by that needs to be modified
        const orderBys = Optional.bool(this.originalAs !== this.field.as)
            .flatMap(() => metadata.orderBys.find(this.originalAs)
                .map(o => metadata.orderBys.replace(
                    o.right, o.left.with(this.field.as)
                ))
            )
            // otherwise take the existing
            .getOr(metadata.orderBys);

        return new Tuple(
            metadata.with({
                'fields': metadata.fields.replace(this.field, this.originalAs),
                'orderBys': orderBys
            }),
            new ModifyField(this.field.as, metadata.fields.get(this.originalAs))
        );
    }

    describe(): string {
        return `Changed ${this.originalAs} to ${this.field.type.label} field ${this.field.as}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}