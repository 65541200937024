import {JsonObject} from "common/CommonTypes";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {Optional} from "common/Optional";
import isEqual from "lodash/isEqual";
import {CLIPPED_DESCRIPTION_LENGTH, FilterClause, RawFilterValue} from "metadata/query/filterclause/FilterClause";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {ValueFormatter} from "common/ValueFormatter";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";

/**
 *  Main default Filter Clause - identifying which field, operator, and corresponding value(s) to filter by.
 */
export class LiteralsFilterClause implements SingleFieldFilterClause {

    static fromJSON(json: JsonObject, isAggregate: boolean): LiteralsFilterClause {
        return new LiteralsFilterClause(
            json['field'],
            FilterOperator.get(json['operator']),
            json['values'],
            isAggregate
        );
    }

    constructor(
        public readonly column: string,
        public readonly operator: FilterOperator,
        // single field filter values are a little more restrictive
        public readonly values: RawFilterValue[],
        public readonly isAggregate: boolean
    ) {
    }

    get isAll(): boolean {
        return this.values.length === 0;
    }

    get fields(): string[] {
        return [this.column];
    }

    isFor(fields: string[]): boolean {
        return fields.length === 1 && fields[0] === this.column;
    }

    fieldsLabel(dataset: ArcDataset): string {
        if (this.isAggregate) {
            // if aggregate, field is the projected field name, no label to lookup
            return this.column;
        } else {
            return dataset.getLabel(this.column, `<missing: ${this.column}>`);
        }
    }

    description(short: boolean): string {
        if (this.isAll) {
            return 'All';
        }

        let description;
        switch (this.operator) {
            case FilterOperator.IN:
            case FilterOperator.NOT_IN:
                description = this.values.map(value => this.formatValue(value)).join(', ');
                if (short && description.length >= CLIPPED_DESCRIPTION_LENGTH) {
                    description = description.substring(0, CLIPPED_DESCRIPTION_LENGTH) + '...';
                }

                description = `[${description}]`;
                break;
            case FilterOperator.BETWEEN:
                description = `${this.formatValue(this.values[0])} and ${this.formatValue(this.values[1])}`;
                break;
            default:
                description = this.formatValue(this.values[0]);
                break;
        }

        return `${this.operator.label} ${description}`;
    }

    private formatValue(value: string | number): string {
        if (value == null) {
            return 'null';
        }
        if (typeof value === 'number') {
            // do some number formating
            return ValueFormatter.compactNumber(value);
        } else {
            // string be strings
            return value;
        }
    }

    get isStructureValid(): boolean {
        return this.column != null && this.operator != null && this.values != null;
    }

    equals(other: FilterClause): boolean {
        return Optional.ofType(other, LiteralsFilterClause)
            .map(o => this.column === o.column && this.operator === o.operator && isEqual(this.values, o.values))
            .getOr(false);
    }

    get type(): FilterClauseType {
        return FilterClauseType.SINGLE;
    }

    toJSON(): JsonObject {
        return {
            'type': FilterClauseType.SINGLE.name,
            'field': this.column,
            'operator': this.operator,
            'values': this.values
        };
    }
}
