import {TabPath} from "app/TabPath";

/**
 * Helps parse home browse path and encapsulates helper logic.
 */
export class HomeBrowsePath {

    static fromTabPath(tabPath: TabPath): HomeBrowsePath {
        let accountName = tabPath.accountName;
        let projectName = tabPath.folderName;
        if (tabPath.parts.length == 3) {
            accountName = tabPath.parts[2];
        } else if (tabPath.parts.length == 4) {
            accountName = tabPath.parts[2];
            projectName = tabPath.parts[3];
        }
        return new HomeBrowsePath(tabPath.parts, accountName, projectName);
    }

    constructor(
        public readonly parts: string[],
        public readonly accountName?: string,
        public readonly folderName?: string,
    ) {
    }

    get isAccountPath(): boolean {
        return this.parts.length == 3;
    }

    get isProjectPath(): boolean {
        return this.parts.length == 4;
    }
}