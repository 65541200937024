import * as React from "react";
import {ChangeEvent, FunctionComponent} from "react";
import FormControl from "@mui/material/FormControl";
import {Optional} from "common/Optional";
import {SlugHelper} from "metadata/SlugHelper";
import {FormS} from "app/components/form/FormS";

type Props = {
    label: string;
    name: string;
    setLabel: (label: string) => void;
    setName: (name: string) => void
    saveErrors: Optional<Map<string, string>>;
}
/**
 * Input for editing label.
 */
export const LabelInput: FunctionComponent<Props> = (props: Props) => {

    const onChangeLabel = (e: ChangeEvent<HTMLInputElement>) => {
        SlugHelper.handleLabelChange(props.label, e.target.value, props.name, props.setLabel, props.setName, SlugHelper.ASSET_MAX_NAME_LENGTH);
    };

    return <FormControl fullWidth>
        <FormS.Label id="name-label">Name</FormS.Label>
        <FormS.TextField
            autoFocus
            margin="none"
            size="small"
            id="label"
            aria-labelledby="name-label"
            placeholder="What should we call your creation?"
            value={props.label}
            error={props.saveErrors.map(errors => errors.has("label")).getOr(false)}
            helperText={props.saveErrors.map(errors => errors.get("label")).getOr(null)}
            onChange={onChangeLabel}
        />
    </FormControl>;
};