import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {DeleteGlobalFilter} from "metadata/dashboard/changes/DeleteGlobalFilter";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";

export class AddGlobalFilter implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly clause: GlobalFilterClause
    ) {
    }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const newDashboard: ArcDashboard = metadata.with({
            globalFilters: metadata.globalFilters.with({
                clauses: [this.clause]
            })
        });
        return new Tuple(
            newDashboard,
            // adding always adds to the end of the clauses so we know the ordinal is 1 less than the length
            new DeleteGlobalFilter(newDashboard.globalFilters.clauses.length - 1)
        );
    }

    describe(): string {
        return `Added global filter for ${this.clause.column} on ${this.clause.datasetFqn.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}