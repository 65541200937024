import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ReplaceReason} from "metadata/ReplaceReason";

/**
 * Replace the entire query.
 *
 * @author zuyezheng
 */
export class ArcQLReplace implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly arcql: ArcQL,
        public readonly reason: ReplaceReason
    ) {}

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        return Tuple.of(
            // use the query as is or do a merge of the content
            this.reason.onlyContent ? metadata.withContent(this.arcql) : this.arcql,
            new ArcQLReplace(metadata, this.reason)
        );
    }

    describe(): string {
        return `Replaced query from ${this.reason.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.REPLACE;
    }
}