import {ArcQL} from "metadata/query/ArcQL";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {DateFilterType} from "app/query/filters/DateFilterType";
import { Column } from "metadata/Column";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";


/**
 * @author zuyezheng
 */
export class DateFilterHelper {

    /**
     * Add a default date filter to reduce query range.
     */
    static addDefault(arcQL: ArcQL, dataset: ArcDataset): ArcQL {
        return dataset.primaryDate().map(column => arcQL.with({
            'filters': arcQL.filters.withAll([
                DateFilterHelper.defaultClause(column)
            ])
        })).getOr(arcQL);
    }

    static defaultClause(column: Column): FilterClause {
        return new LiteralsFilterClause(
            column.name,
            FilterOperator.GREATER_THAN_EQUAL,
            [DateFilterType.RELATIVE.defaultValue()],
            false
        );
    }

}
