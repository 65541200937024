import * as React from "react";
import {FunctionComponent, memo, useState} from "react";
import styled from "@emotion/styled";
import {Tab} from "app/Tab";
import {TabPath} from "app/TabPath";

type TabContentProps = {
    // if tab is selected and we should show its contents
    selected: boolean,
    tab: Tab,

    onTabChange: (originalPath: TabPath, label: string, path: TabPath, hasChanges?: boolean) => void
    onCloseTab: (tab: Tab) => void
}


/**
 * Optimizes rendering of multiple tabs by only rendering the selected tab and delay rendering of background tabs such
 * as home.
 *
 * @author zuyezheng
 */
export const TabContent: FunctionComponent<TabContentProps> = (props: TabContentProps) => {

    // container is always updated, but the inner content is memoized
    return <S.Container selected={props.selected}>
        <TabInnerContent {...props} />
    </S.Container>;

};

export const TabInnerContent: FunctionComponent<TabContentProps> = memo(

    (props: TabContentProps) => {
        const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);

        // if the tab is initialized in the background, don't draw until it is selected for the first time
        if (props.selected) {
            if (!hasBeenSelected) {
                setHasBeenSelected(true);
            }
        }

        // once selected, always return the component which will get memoized
        return hasBeenSelected ?
            <props.tab.path.type.ComponentType
                path = {props.tab.path}
                context = {props.tab.context}
                isSelected = {props.selected}
                onTabChange = {props.onTabChange.bind(this, props.tab.path)}
                onClose = {() => props.onCloseTab(props.tab)}
            />:
            <></>;
    },

    (oldProps: TabContentProps, newProps: TabContentProps) => {
        // if old and new state are not selected, don't update
        if (!oldProps.selected && !newProps.selected) {
            return true;
        }

        // otherwise compare the props
        return oldProps.selected === newProps.selected &&
            oldProps.tab === newProps.tab &&
            oldProps.onTabChange === newProps.onTabChange &&
            oldProps.onCloseTab === newProps.onCloseTab;
    }

);

type ContainerProps = {
    selected: boolean
}

const S = {
    Container: styled.div<ContainerProps>`
        height: 100%;
        overflow: hidden;
        display: ${props => props.selected ? '' : 'none'};
    `
};