import {SubscriptionRowHandler} from "app/components/settings/notifications/SubscriptionRowHandler";
import {Either} from "common/Either";
import {AlertSubscriptionMetadata} from "metadata/subscription/AlertSubscriptionMetadata";
import {UserSubscription} from "metadata/subscription/UserSubscription";
import {ErrorResponse} from "services/ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {SubscriptionService} from "services/SubscriptionService";

/**
 * Handles alert subscription rows.
 */
export class AlertSubscriptionTableHandler implements SubscriptionRowHandler {

    constructor(
        private readonly sub: UserSubscription,
        private readonly metadata: AlertSubscriptionMetadata
    ) {

    }

    appPathToSubscription(): string {
        return '/trends/' + this.metadata.trendId;
    }

    subscribe(): Promise<Either<ErrorResponse, UserSubscription>> {
        return ServiceProvider.get(SubscriptionService)
            .subscribeToAlert(this.metadata.trendFqn, this.sub.subscribedToId);

    }

    unsubscribe(): Promise<Either<ErrorResponse, UserSubscription>> {
        return ServiceProvider.get(SubscriptionService)
            .unsubscribeFromAlert(this.metadata.trendFqn, this.sub.subscribedToId);
    }
}