import {TabPath} from "app/TabPath";

export type RoutingCallback = (caller: string, path: TabPath, context: Map<string, any>) => void;

/**
 * Internal routing for complex state that can't be passed through URL.
 *
 * @author zuyezheng
 */
export class InternalRouterService {

    private readonly callbacks: Map<Object, RoutingCallback>;

    constructor() {
        this.callbacks = new Map();
    }

    /**
     * Register with a given receiver. The exact object needs to be used when unregistering.
     */
    register(receiver: Object, callback: RoutingCallback): void {
        this.callbacks.set(receiver, callback);
    }

    /**
     * Remove the callback.
     */
    unregister(receiver: Object): void {
        this.callbacks.delete(receiver);
    }

    /**
     * Route with internal context.
     */
    route(caller: string, path: TabPath, context: Map<string, any>) {
        this.callbacks.forEach(c => c(caller, path, context));
    }

}