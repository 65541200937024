import * as React from "react";
import {FunctionComponent, ReactNode, useRef, useState} from "react";
import {EditType, HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import styled from "@emotion/styled";
import {FontSizes} from "app/components/StyleVariables";
import {useResize} from "app/components/hooks/DomHooks";
import {StringUtils} from "common/StringUtils";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {NodeRating} from "metadata/hypergraph/NodeRating";
import {NodeDetailsS} from "app/query/hypergraph/selection/NodeDetailsS";
import {NodeDetailsHeader} from 'app/query/hypergraph/selection/NodeDetailsHeader';
import {NodeDetailsBody} from 'app/query/hypergraph/selection/NodeDetailsBody';


export type Props = {
    className?: string,
    hyperGraph: HyperGraph,
    nodes: HyperGraphNode[],

    onClose(): void
    onNodeClick(nodeId: string, multiSelect: boolean, center: boolean): void
    onChangeRating(nodeId: string, rating: NodeRating): void
    onChangeContent(nodeId: string, type: EditType, content: string): void
}

/**
 * Floating and collapsable panel to show details for selected HyperGraph nodes.
 *
 * @author zuyezheng
 */
export const HyperGraphSelectionDetails: FunctionComponent<Props> = (props: Props) => {

    const containerRef = useRef(null);

    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const containerSize = useResize(containerRef);

    const containerClassNames: {[key: string]: boolean} = {
        'expanded': isExpanded
    };
    if (props.className != null) {
        containerClassNames[props.className] = true;
    }

    // center the selected node if there is only 1
    const onHeaderClick = () => {
        if (props.nodes.length === 1) {
            props.onNodeClick(props.nodes[0].id, false, true);
        }
    };

    const onClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        props.onClose();
    };

    const buildExpand = (): ReactNode => {
        const containerHeight = containerSize.map(s => s[1]).getOr(0);

        // only need to show expand/collapse controls if the container is exactly or larger than 250px
        if (containerHeight < 250) {
            return;
        }

        return isExpanded ?
            <S.Expand onClick={() => setIsExpanded(false)}>&gt; Show less</S.Expand> :
            <S.Expand onClick={() => setIsExpanded(true)}>&gt; Show more</S.Expand>;
    };

    const buildSingle = (node: HyperGraphNode): ReactNode => {
        return <>
            <NodeDetailsHeader
                hyperGraph={props.hyperGraph}
                node={node}
                onClose={onClose}
                onHeaderClick={onHeaderClick}
                onChangeTitle={title => props.onChangeContent(node.id, EditType.TITLE, title)}
            />
            <NodeDetailsBody
                hyperGraph={props.hyperGraph}
                node={node}
                onChangeRating={(rating: NodeRating) => props.onChangeRating(node.id, rating)}
                onChangeDescription={description => props.onChangeContent(node.id, EditType.DESCRIPTION, description)}
            >
                {buildExpand()}
            </NodeDetailsBody>
        </>;
    };

    const buildMultiple = (nodes: HyperGraphNode[]): ReactNode => {
        return <>
            <NodeDetailsS.Header>
                <NodeDetailsS.NodeIcon operation={HyperGraphNodeOperation.COMPARE} isCompact={true} />
                <NodeDetailsS.NodeTitle>{nodes.length} Selected</NodeDetailsS.NodeTitle>
                <NodeDetailsS.CloseIcon onClick={onClose} />
            </NodeDetailsS.Header>
            <NodeDetailsS.Body>
                <NodeDetailsS.Details>
                    Comparing.
                </NodeDetailsS.Details>
            </NodeDetailsS.Body>
        </>;
    };

    return <S.Container className={StringUtils.toClassName(containerClassNames)} ref={containerRef}> {
        props.nodes.length === 1 ? buildSingle(props.nodes[0]) : buildMultiple(props.nodes)
    } </S.Container>;

};

class S {

    static readonly Container = styled.div`
        border-radius: 12px;
        border: 1px solid #DADADA;
        background: #263238;
        display: flex;
        flex-direction: column;
        color: white;
        font-size: ${FontSizes.medium};
        pointer-events: all;
        max-height: 250px;
        overflow: hidden;
        
        &.expanded {
            max-height: unset;
        }
    `;

    static readonly Expand = styled.div`
        color: #42A5F5;
        border-top: 1px solid #757575;
        padding-top: 14px;
        cursor: pointer;
    `;

}