import React, {FunctionComponent, useState} from "react";
import {ColorResult} from "react-color";
import {DashboardConfig, MAX_WIDTH_UNSET} from "metadata/dashboard/DashboardConfig";
import {FormControl, FormHelperText, InputLabel} from "@mui/material";
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import InputAdornment from "@mui/material/InputAdornment";
import {CollapsibleSection} from "app/components/CollapsibleSection";
import {DashboardConfigS} from "app/dashboard/components/DashboardConfigS";
import {ColorPickerForm} from "app/dashboard/components/ColorPickerForm";
import WidthFullOutlinedIcon from '@mui/icons-material/WidthFullOutlined';
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
    dashboard: ArcDashboard
    config: DashboardConfig
    onChange: (config: DashboardConfig) => void
}

type ErrorState = {
    columnsError: string
    gapXError: string
    gapYError: string
    maxWidthError: string
}

export const DashboardConfigLayoutSection: FunctionComponent<Props> = (props: Props) => {
    // State for tracking validation errors
    const [errors, setErrors] = useState<ErrorState>({
        columnsError: '',
        gapXError: '',
        gapYError: '',
        maxWidthError: ''
    });

    // states to track user changes that are not yet committed due to validation errors
    const [numberColumns, setNumberColumns] = useState(props.config.numberColumns);
    const [gapX, setGapX] = useState(props.config.gridGapX);
    const [gapY, setGapY] = useState(props.config.gridGapY);
    const [maxWidth, setMaxWidth] = useState(props.config.maxWidth);

    const validateColumns = (value: number) => {
        if (value < 1 || value > 100) {
            return "Number of columns must be between 1 and 100.";
        }

        if (props.dashboard.layouts.default.maxColumn > value) {
            return `Cannot reduce the number of columns to ${value} as current layout occupies 
                ${props.dashboard.layouts.default.maxColumn} columns.`;
        }

        return "";
    };

    const validateGap = (value: number) => {
        if (value < 0 || value > 100) {
            return "Gap must be between 0 and 100.";
        }
        return "";
    };

    const validateMaxWidth = (value: number) => {
        if (value < MAX_WIDTH_UNSET) {
            return `Max width must be greater or equal to ${MAX_WIDTH_UNSET} (unset).`;
        }
        return "";
    };

    const onBackgroundColorChange = (color: ColorResult) => {
        props.onChange(props.config.with({backgroundColor: color.hex}));
    };

    const onColumnsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newColumnValue = parseInt(event.target.value, 10);
        setNumberColumns(newColumnValue);
        const columnsError = validateColumns(newColumnValue);
        setErrors({...errors, columnsError});

        if (columnsError) {
            return;
        }
        props.onChange(props.config.with({numberColumns: newColumnValue}));
    };

    const onGapXChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newGapValue = parseInt(event.target.value, 10);
        setGapX(newGapValue);
        const gapError = validateGap(newGapValue);
        setErrors({...errors, gapXError: gapError});

        if (gapError) {
            return;
        }

        props.onChange(props.config.with({gridGapX: newGapValue}));
    };

    const onGapYChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newGapValue = parseInt(event.target.value, 10);
        setGapY(newGapValue);
        const gapError = validateGap(newGapValue);
        setErrors({...errors, gapYError: gapError});

        if (gapError) {
            return;
        }
        props.onChange(props.config.with({gridGapY: newGapValue}));
    };

    const onMaxWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMaxWidth = parseInt(event.target.value, 10);
        setMaxWidth(newMaxWidth);
        const maxWidthError = validateMaxWidth(newMaxWidth);
        setErrors({...errors, maxWidthError: maxWidthError});

        if (maxWidthError) {
            return;
        }

        props.onChange(props.config.with({maxWidth: newMaxWidth}));
    };

    const onGutterColorChange = (color: ColorResult) => {
        props.onChange(props.config.with({gutterColor: color.hex}));
    };


    return (
        <CollapsibleSection title={"layout"}>
            <ColorPickerForm
                pickerId={"dashboard-background-color"}
                label={"Background Color"}
                currentColor={props.config.backgroundColor}
                onChange={onBackgroundColorChange}
            />
            <FormControl fullWidth size="small" margin="dense" error={Boolean(errors.columnsError)}>
                <InputLabel htmlFor="number-columns">Number of Columns</InputLabel>
                <DashboardConfigS.Input
                    id="number-columns"
                    type="number"
                    value={numberColumns}
                    onChange={onColumnsChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <ViewColumnOutlinedIcon/>
                        </InputAdornment>
                    }
                />
                {errors.columnsError && <FormHelperText>{errors.columnsError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth size="small" margin="dense" error={Boolean(errors.gapXError)}>
                <InputLabel htmlFor="grid-gap-x">Horizontal Gap Between Widgets</InputLabel>
                <DashboardConfigS.Input
                    id="grid-gap-x"
                    type="number"
                    value={gapX}
                    onChange={onGapXChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <SwapHorizOutlinedIcon/>
                        </InputAdornment>
                    }
                />
                {errors.gapXError && <FormHelperText>{errors.gapXError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth size="small" margin="dense" error={Boolean(errors.gapYError)}>
                <InputLabel htmlFor="grid-gap-y">Vertical Gap Between Widgets</InputLabel>
                <DashboardConfigS.Input
                    id="grid-gap-y"
                    type="number"
                    value={gapY}
                    onChange={onGapYChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <SwapVertOutlinedIcon/>
                        </InputAdornment>
                    }
                    error={Boolean(errors.gapYError)}
                />
                {errors.gapYError && <FormHelperText>{errors.gapYError}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth size="small" margin="dense" error={Boolean(errors.maxWidthError)}>
                <InputLabel htmlFor="max-width">Max Width</InputLabel>
                <DashboardConfigS.Input
                    id="max-width"
                    type="number"
                    value={maxWidth}
                    onChange={onMaxWidthChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <WidthFullOutlinedIcon/>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title={`Set '${MAX_WIDTH_UNSET}' to UNSET max width, allowing dashboard to stretch to full width of the container.`}>
                                <InfoOutlinedIcon color="action" />
                            </Tooltip>
                        </InputAdornment>
                    }
                    error={Boolean(errors.maxWidthError)}
                />
                {errors.maxWidthError && <FormHelperText>{errors.maxWidthError}</FormHelperText>}
            </FormControl>
            <ColorPickerForm
                pickerId={"gutter-background-color"}
                label={"Gutter Color"}
                currentColor={props.config.gutterColor}
                onChange={onGutterColorChange}
            />

        </CollapsibleSection>
    );
};




