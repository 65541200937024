import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {Optional} from "common/Optional";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Modify a grouping
 *
 * @author zuyezheng
 */
export class ModifyGrouping implements ArcMetadataChange<ArcQL> {

    constructor(
        // modify the grouping for the given field name
        public readonly originalField: string,
        public readonly grouping: ArcQLGrouping,
        public readonly dataset: ArcDataset
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        // if projected as has changed, see if there is an existing order by that needs to be modified
        const originalGrouping = metadata.groupings.get(this.originalField);
        const orderBys = Optional.bool(this.originalField !== this.grouping.projectedAs)
            .flatMap(() => metadata.orderBys.find(originalGrouping.projectedAs)
                .map(o => metadata.orderBys.replace(
                    o.right, o.left.with(this.grouping.projectedAs)
                ))
            )
            // otherwise take the existing
            .getOr(metadata.orderBys);

        return new Tuple(
            metadata.with({
                groupings: metadata.groupings.replace(this.grouping, this.originalField),
                orderBys: orderBys
            }),
            new ModifyGrouping(
                this.grouping.field,
                metadata.groupings.get(this.originalField),
                this.dataset
            )
        );
    }

    describe(metadata: ArcQL): string {
        const originalLabel = metadata.groupings.get(this.originalField).label(this.dataset);
        const newLabel = this.grouping.label(this.dataset);
        return `Changed grouping ${originalLabel} to ${this.grouping.type.name} on ${newLabel}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}