import * as React from "react";
import {FunctionComponent, useEffect, useState} from 'react';
import {Optional} from "common/Optional";
import {useAuth0} from "@auth0/auth0-react";
import {App} from 'app/App';
import {EmailVerificationCompleteDialog} from "app/home/EmailVerificationCompleteDialog";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {VerifyEmailDialog} from "app/home/VerifyEmailDialog";
import {Config} from "services/Config";

type Props = {
    queryParams: URLSearchParams
}

/**
 * Handles any auth query params to display the appropriate dialogs/messages if necessary, otherwise attempts to
 * render the app.
 */
export const AppContainer: FunctionComponent<Props> = (props: Props) => {

    const { loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [isReady, setReady] = useState<boolean>(false);
    // handle if user is verified
    const emailToBeVerified: Optional<string> = Optional.string(props.queryParams.get("verificationEmail"));
    const authAction: Optional<string> = Optional.string(props.queryParams.get("authAction"));
    const authSuccess: boolean = Optional.string(props.queryParams.get("success")).map(v => v === "true").getOr(false);
    const isEmbed: boolean = Optional.string(props.queryParams.get("isEmbed")).map(v => v === "true").getOr(false);
    const embedId: Optional<string> = Optional.string(props.queryParams.get("embedId"));

    useEffect(() => {
        if (ServiceProvider.get(Config).isTest) {
            setReady(true);
            return;
        }

        ServiceProvider.get(RestService).initialize(getAccessTokenSilently, isAuthenticated, embedId);
        if (!isLoading) {
            if (isAuthenticated || isEmbed) {
                setReady(true);
            } else {
                // If we're showing the email to be verified dialog, don't redirect to login
                if (emailToBeVerified.isNone) {
                    loginWithRedirect();
                }
            }
        }
    }, [getAccessTokenSilently, isLoading, isAuthenticated]);

    return emailToBeVerified
        .map(emailAddress =>
            <VerifyEmailDialog
                email={emailAddress}
                onClose={() => loginWithRedirect()}
            />
        ).getOrElse(() => authAction.map(action => {
            if (action === "email_verified" && authSuccess) {
                return <EmailVerificationCompleteDialog
                    onClose={() => loginWithRedirect()}
                />;
            }

            return null;
        }).getOrElse(() => {
            return isReady && <App isEmbed={isEmbed}/>;
        }))

};