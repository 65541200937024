import {GridColDef, GridRenderCellParams, GridRowsProp} from "@mui/x-data-grid";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {TableDataProvider} from "app/visualizations/data/TableDataProvider";
import React from "react";
import {CommonS} from "app/components/CommonS";
import {ValueFormatter} from "common/ValueFormatter";

/**
 * Turn query results into tabular data for visualization.
 *
 * @author zuyezheng
 */
export class TableData implements TableDataProvider {

    constructor(
        private readonly response: ArcQLResponse
    ) {}

    get columns(): GridColDef[] {
        // set of all url column names
        const urlColumns = new Set(this.response.result.urlColumns.map(c => c[0]));

        const applyRenderer = (columnName: string, colDef: GridColDef): GridColDef => {
            if (urlColumns.has(columnName)) {
                colDef['renderCell'] = (params: GridRenderCellParams<string>) => (
                    <CommonS.TruncatedLink href={params.value} target="_blank" rel="noopener">
                        {params.value}
                    </CommonS.TruncatedLink>
                )
            }

            return colDef
        };

        // order columns by grouping and then fields
        const groupingColumns = this.response.arcql.groupings.fields.map(grouping => applyRenderer(
            grouping.field,
            {
                'field': grouping.projectedAs,
                'headerName': grouping.label(this.response.dataset),
                'sortable': false,
                'flex': 1
            }
        ));
        const fieldColumns = this.response.arcql.fields.fields.map(field =>  applyRenderer(
            field.as,
            {
                'field': field.as,
                'headerName': field.as,
                'groupable': false,
                'sortable': false,
                'flex': 1
            }
        ));

        return [...groupingColumns, ...fieldColumns];
    }

    rows(compactNumbers: boolean): GridRowsProp {
        return this.response.result.objectRows(
            'id',
            compactNumbers ? ValueFormatter.compactNumber : ValueFormatter.basicNumber
        );
    }

    /**
     * If rows should be grouped due to hierarchical data.
     */
    get rowGroupings(): string[] {
        return this.response.result.indexColumns.slice(0, -1).map(c => c.left);
    }

    get columnVisibility(): GridColumnVisibilityModel {
        // hide columns used for row groupings
        const model: {[key: string]: boolean} = {};
        this.response.result.indexColumns.slice(0, -1).forEach(c => {
            model[c.left] = false;
        });

        return model;
    }

}