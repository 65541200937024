import * as React from "react";
import {ChangeEvent, ComponentType, FunctionComponent, useState} from "react";
import {S as CanvasS} from "app/query/QueryBuilderCanvasS";
import {Optional} from "common/Optional";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {Enum} from "common/Enum";
import {CommonS} from "app/components/CommonS";
import styled from "@emotion/styled";
import {VisualizationQueryContent} from "app/query/panels/VisualizationQueryContent";
import {VisualizationDisplayContent} from "app/query/panels/VisualizationDisplayContent";
import {S as VizPropertyS} from "app/visualizations/definition/property/VizPropertyS";
import {VizType} from "metadata/query/VizType";
import FormControl from "@mui/material/FormControl";
import {ListItemIcon} from "@mui/material";
import {PaletteOutlined, QueryStatsOutlined} from "@mui/icons-material";

type Props = {
    arcqlBundle: ArcQLBundle
    delegate: QueryBuilderDelegate
    drillClauses: Optional<FilterClause[]>
}

/**
 * Panel for editing and updating the query and/or its visualization.
 */
export const VisualizationPanel: FunctionComponent<Props> = (props: Props) => {

    const [mode, setMode] = useState<VisualizationPanelMode>(VisualizationPanelMode.QUERY);

    const vizConfig = props.arcqlBundle.arcql.visualizations.default;
    const onChangeMode = (_: React.MouseEvent<HTMLElement>, modeName: string) => {
        setMode(VisualizationPanelMode.get<VisualizationPanelMode>(modeName));
    };

    const onChangeVizType = (event: ChangeEvent<HTMLInputElement>) => {
        props.delegate.changeVizConfig(vizConfig.withType(VizType.get(event.target.value)));
    };

    const buildEditor = () => {
        switch (mode) {
            case VisualizationPanelMode.QUERY:
                return <VisualizationQueryContent
                    arcqlBundle={props.arcqlBundle}
                    delegate={props.delegate}
                    drillClauses={props.drillClauses}
                />;

            case VisualizationPanelMode.DISPLAY:
                return <VisualizationDisplayContent
                    config={vizConfig}
                    delegate={props.delegate}
                />;
        }
    };

    return <CanvasS.Left>
        <CanvasS.PanelTitle> Visualization </CanvasS.PanelTitle>
        <CanvasS.Shelf>
            <CanvasS.ShelfHeader> Type </CanvasS.ShelfHeader>
            <S.VizType fullWidth size="small">
                <VizPropertyS.Select
                    id="type"
                    variant="standard"
                    value={vizConfig.type.name}
                    onChange={onChangeVizType}
                >{
                    VizType.sorted().map((type: VizType) =>
                        <VizPropertyS.SelectItem key={type.name} value={type.name}>
                            <S.ListItemIcon>
                                <type.icon/>
                            </S.ListItemIcon>
                            <span> {type.label} </span>
                        </VizPropertyS.SelectItem>
                    )
                }</VizPropertyS.Select>
            </S.VizType>
        </CanvasS.Shelf>
        <CommonS.Tabs
            value={mode.name}
            onChange={onChangeMode}
            variant="fullWidth"
        >
            {
                VisualizationPanelMode.enums<VisualizationPanelMode>().map(
                    m =>
                        <CommonS.Tab
                            key={m.name}
                            icon={<m.icon/>}
                            iconPosition="start"
                            value={m.name}
                            label={m.label}
                        />
                )
            }
        </CommonS.Tabs>
        <S.Content>
            {buildEditor()}
        </S.Content>
    </CanvasS.Left>;
};

class VisualizationPanelMode extends Enum {

    static readonly QUERY = new this('query', "Query", QueryStatsOutlined);
    static readonly DISPLAY = new this('display', "Display", PaletteOutlined);

    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType
    ) {
        super(name);
    }

}
VisualizationPanelMode.finalize();

class S {

    static readonly Content = styled.div`
    `;

    static readonly VizType = styled(FormControl)`
    `;

    static readonly ListItemIcon = styled(ListItemIcon)`
        &.MuiListItemIcon-root {
            min-width: 0;
            padding: 0 8px;
        }
    `;

    static readonly SelectedItem = styled.div`
        display: flex;
        align-items: center;
    `;

}