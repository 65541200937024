import {ArcQL} from "metadata/query/ArcQL";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {VizType} from "metadata/query/VizType";
import {ArcQLVisualizations, VisualizationConfig} from "metadata/query/ArcQLVisualizations";

/**
 * Change the visualization type of a named visualization.
 */
export class ArcQLVisualizationChange implements ArcMetadataChange<ArcQL> {

    static default(newType: VizType, config: Map<string, any> = new Map()): ArcQLVisualizationChange {
        return new ArcQLVisualizationChange(ArcQLVisualizations.DEFAULT_KEY, newType, config);
    }

    constructor(
        public readonly name: string,
        public readonly newType: VizType,
        public readonly config: Map<string, any> = new Map()
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const existing = metadata.visualizations.get(this.name);
        const newMetadata = metadata.with({
            visualizations: metadata.visualizations.with(
                new VisualizationConfig(
                    this.name, existing.label, this.newType, this.config
                )
            )
        });

        return new Tuple(
            newMetadata,
            new ArcQLVisualizationChange(this.name, existing.type, existing.config)
        );
    }

    describe(): string {
        return `Changed the ${this.name} visualization to a ${this.newType.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}