import {ArcQL} from "metadata/query/ArcQL";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {DateGrouping} from "metadata/query/DateGrouping";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ArcQLField} from "metadata/query/ArcQLField";
import {DateGrain} from "metadata/query/DateGrain";
import {DetailDateField} from "metadata/query/DetailDateField";
import {ArcQLFieldType} from "metadata/query/ArcQLFieldType";

export type ResultValueFormatter = (v: any) => string;

dayjs.extend(utc);

/**
 * Provides formatting for ArcQL results based on the metadata.
 *
 * @author zuyezheng
 */
export class ResultFormatter {

    static identityFormatter(v: any): any {
        return v;
    }

    static toStringFormatter(v: any): string {
        if (v == null) {
            return 'null';
        } else {
            return v.toString();
        }
    }

    static dateFormatter(grain: DateGrain): ResultValueFormatter {
        return v => {
            if (typeof v === 'number') {
                return grain.formatEpoch(v);
            } else {
                return dayjs(v, ArcQL.DATE_FORMAT).format(grain.displayFormat);
            }
        }
    }

    /**
     * Return the formatter for the given grouping name.
     */
    static groupingFormatter(grouping: ArcQLGrouping): ResultValueFormatter {
        if (grouping.type === ArcQLGroupingType.DATE) {
            return ResultFormatter.dateFormatter((grouping as DateGrouping).grain);
        } else {
            return ResultFormatter.toStringFormatter;
        }
    }

    /**
     * Return the formatter for the given field as.
     */
    static fieldFormatter(field: ArcQLField): ResultValueFormatter {
        if (field.type === ArcQLFieldType.DETAIL_DATE) {
            return ResultFormatter.dateFormatter((field as DetailDateField).grain);
        } else {
            return ResultFormatter.toStringFormatter;
        }
    }

}