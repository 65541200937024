import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {VizSelection} from "engine/actor/VizSelection";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {NodeAndLinkData} from "app/visualizations/data/NodeAndLinkData";
import {Optional} from "common/Optional";
import {SelectionEvent} from "app/visualizations/FusionTypes";

/**
 * @author zuyezheng
 */
export class ChordChartConfig extends ChartConfig {

    private readonly data: NodeAndLinkData;

    constructor(
        response: ArcQLResponse,
        config: VisualizationConfig
    ) {
        super(response, config);

        this.data = new NodeAndLinkData(response, false);
    }

    validate(): Optional<string> {
        return this.data.validate();
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    handleDiscreteClick(
        event: SelectionEvent, originalDataSource: {[key: string]: any}, eventType: string
    ): Optional<string[][]> {
        // only support clicking on a link since we don't know if a node in a chord is the from or the to grouping
        if (eventType === 'linkclick') {
            return Optional.some([
                [event.linkedNodes[1], event.linkedNodes[0]]
            ]);
        }

        return Optional.none();
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        const {nodes, links} = this.data.dataset(selections);

        return {
            type: 'chord',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: false,
                        enableToggle: false
                    }
                ),
                nodes: nodes,
                links: links
            }
        };
    }

}