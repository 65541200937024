import * as React from "react";
import styled from "@emotion/styled";
import {ListS} from "app/query/components/ListS";
import {FieldSettingItem} from "app/query/components/FieldSettingItem";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {ArrowBack} from "@mui/icons-material";
import Button from "@mui/material/Button";

// An enumerated out setting (i.e. an item in a Menu)
interface ItemSetting {
    name: string
    label: string
}

type Props<T, S extends ItemSetting> = {
    fieldLabel: string
    // label dictating what kind of field categorization (ex: Field vs Grouping)
    fieldCategorization: string
    // detailing more minutely what setting this is
    settingLabel: string
    // field is being edited rather than created
    modifying: boolean
    field: T
    // the whole item list of settings
    items: S[]
    // logic to denote which setting is selected
    isSelected: (item: S) => boolean
    // on selection change
    onChange: (item: S) => void
    onBack: () => void
}

/**
 * General setting view for editing or adding a field.
 */
export const FieldSettingView = <T extends Object, S extends ItemSetting>(props: Props<T, S>) => {
    return <S.Container>
        <S.BackButton
            variant='outlined'
            onClick={props.onBack}
        >
            <S.ArrowBack/>
            Choose a Different {props.fieldCategorization}
        </S.BackButton>
        <S.HelperText>
            Select {props.settingLabel} for
            <strong> {props.fieldLabel} </strong>
            :
        </S.HelperText>
        <ListS.Container>
            <ListS.List>
                {
                    props.items.map(
                        item =>
                            <FieldSettingItem
                                key={item.name}
                                label={item.label}
                                isSelected={props.modifying ? props.isSelected(item) : false}
                                onClick={() => props.onChange( item)}
                            />
                    )
                }
            </ListS.List>
        </ListS.Container>
    </S.Container>;
};

class S {

    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
    `;

    static readonly BackButton = styled(Button)`
        width: 200px;
        text-transform: none;
        font-size: ${FontSizes.small};
        padding: 5px 10px 5px 0;

        svg {
            width: 16px;
            height: 16px;
        }
    `;

    static readonly ArrowBack = styled(ArrowBack)`
        margin-right: 8px;
        color: ${Colors.iconPrimary};
    `;

    static readonly HelperText = styled.div`
        font-size: ${FontSizes.small};
    `;
}
