import React, {FunctionComponent, ReactNode, useEffect, useRef, useState} from "react";
import {ServiceProvider} from "services/ServiceProvider";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {QueryBuilderCanvas} from "app/query/QueryBuilderCanvas";
import {Optional} from "common/Optional";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcEngine} from "engine/ArcEngine";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {QueryPath, SubPathKey} from "app/query/QueryPath";
import {ArcMetadata} from "metadata/ArcMetadata";
import {DelegatedQueryActor} from "engine/actor/DelegatedQueryActor";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {DragAndDropData} from "common/DragAndDropData";
import {Toolbar} from "app/components/toolbar/Toolbar";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {SaveAsDialog} from "app/components/SaveAsDialog";
import {ActorStatus} from "engine/actor/ActorStatus";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {TabProps} from "app/TabType";
import {DrillActor} from "app/query/DrillActor";
import {SaveHandler} from "metadata/SaveHandler";
import {QueryLoader} from "app/query/QueryLoader";
import {S} from "app/query/QueryBuilderS";
import {ExternalState} from "app/components/ExternalState";
import {ShareDialog} from "app/components/share/ShareDialog";
import {AssetVersion} from "metadata/AssetVersions";
import {MetadataService} from "services/MetadataService";
import {SaveDialog} from "app/components/SaveDialog";
import {Column} from "metadata/Column";
import {ReplaceReason} from "metadata/ReplaceReason";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {FQN} from "common/FQN";
import {SingleSource} from "metadata/query/ArcQLSource";
import {ArchiveDialog} from "app/components/toolbar/ArchiveDialog";
import {ApplyMaskDialog} from "app/query/components/ApplyMaskDialog";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {HyperGraphProcess} from "app/query/hypergraph/HyperGraphProcess";
import {useToolbar} from "app/query/QueryBuilderToolbar";
import {QueryBuilderTool} from "app/query/QueryBuilderTool";
import {SmallMultiples} from "app/query/SmallMultiples";
import {DatasetPanel} from "app/query/DatasetPanel";
import {ReactFlowProvider} from "reactflow";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {useHistory} from "react-router-dom";
import {DatasetV2Service} from "services/DatasetV2Service";
import {TabPath} from 'app/TabPath';


const DELEGATE_ID = 'delegate';
const DRILL_ID = 'drill';
const QUERIER_ID = 'querier';

export const QueryBuilder: FunctionComponent<TabProps> = (props: TabProps) => {

    const canvasEl = useRef<HTMLElement>(null);
    const history = useHistory();

    // core query state
    const [dataset, setDataset] = useState<Optional<ArcDataset>>(Optional.none());
    const [query, setQuery] = useState<Optional<ArcQL>>(Optional.none());
    const [engine, setEngine] = useState<Optional<ArcEngine>>(Optional.none());
    const [queryResult, setQueryResult] = useState<Optional<ArcQLResponse>>(Optional.none());
    const [actorStatus, setActorStatus] = useState<ActorStatus>(ActorStatus.INITIALIZING);
    const [facetClauses, setFacetClauses] = useState<Optional<FilterClause[]>>(Optional.none());

    // null indicates no pending persona, none indicates clearing the existing persona selection
    const [pendingPersona, setPendingPersona] = useState<Optional<AssetSearchResult>>(null);
    const [hyperGraph, setHyperGraph] = useState<HyperGraph>(HyperGraph.empty());

    // UI state
    const delegate: Optional<QueryBuilderDelegate> = engine.map(e => e.getActor(DELEGATE_ID));

    const {isToolShown, toggleTool, toolbarActions, onToolbarAction} = useToolbar(
        props.path, delegate, query, [QueryBuilderTool.DATASET_PANEL]
    );

    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            const queryPath = QueryPath.fromParts(props.path.parts);

            // create a copy of the context since we merge it with some state from the query path
            const context = new Map(props.context);
            if (queryPath.skipLocal()) {
                context.set('skipLocalStorage', true);
            }

            // load the metadata for the query
            const possibleLoadResult = await QueryLoader.load(props.path.assetFqn, context, controller.signal);
            if (possibleLoadResult.isNone) {
                props.onClose();
                return;
            }

            // successfully loaded the metadata for the query
            const loadResult = possibleLoadResult.get();
            setDataset(Optional.some(loadResult.dataset));

            const delegate = new QueryBuilderDelegate(
                DELEGATE_ID,
                loadResult.dataset,
                new ArcMetadata<ArcQL>(loadResult.query, 50),
                setQuery,
                setQueryResult,
                setActorStatus,
                setHyperGraph
            );
            const drillActor = new DrillActor(
                DRILL_ID,
                loadResult.dataset.fqn,
                setFacetClauses,
                (facets) => delegate.mergeFilters(facets)
            );
            const querier = new DelegatedQueryActor(
                QUERIER_ID,
                DELEGATE_ID
            );

            // new dataset, new engine
            // TODO ZZ cleanup old engine by removing hooks, etc. and add a watcher/thrower for new events to signal
            //         engine that was retained and source of memory leak
            const engine = new ArcEngine('builder');
            await engine.registerAll([delegate, drillActor, querier]);

            setEngine(Optional.some(engine));

            if (loadResult.isRestored) {
                // push a sentinel change so we know there are unsaved changes which will also trigger an initial query
                delegate.replace(loadResult.query, ReplaceReason.RESTORED);
            } else {
                // force the delegate to publish a change with the initial arcql query
                delegate.onChanged();
            }

            // see if there are any nodes to select which might impact the URL if there are bad node ids
            const path = queryPath.selectedNodes()
                .map(nodes => TabPath.fromRaw(showSelectedNodes(delegate, nodes).toString()))
                .getOr(props.path);
            props.onTabChange(loadResult.query.label, path);
        })();

        return () => controller.abort();
    }, []);

    // update local storage if query results or the hypergraph changes
    useEffect(() => {
        delegate.forEach(d => d.updateLocal(props.path.assetFqn));
    }, [queryResult, hyperGraph]);

    // update the tab with any asset changes from labels to unsaved changes
    useEffect(() => {
        const controller = new AbortController();

        Optional.all([delegate, query]).forEach(([delegate, query]: [QueryBuilderDelegate, ArcQL]) => {
            props.onTabChange(query.label || query.name, props.path, delegate.hasChanges);

            // Make sure we update our dataset when the query changes as the persona may have been changed. Relying
            // on the metadata caching for this to be nice and quick
            ServiceProvider.get(DatasetV2Service).describeDataset((query.source as SingleSource).fqn, controller.signal, query.fqn)
                .then(r => r.match(newDataset => {
                    setDataset(Optional.of(newDataset));
                    delegate.setDataset(newDataset);
                }, err => {
                    ServiceProvider.get(NotificationsService).publish(
                        'queryBuilder', NotificationSeverity.WARNING, err.toString()
                    );
                }));
        });

        return () => controller.abort();
    }, [query]);

    useEffect(() => {
        // if we link back to an open query without any selections, respect the currently selected node so we don't
        // "clear" the current selection
        // TODO ZZ: we should probably do something like we do for faceting state and ask if we want to "reset" the
        //          selected node or keep the current and update the URL with the selected node
        Optional.all([
            delegate, QueryPath.fromParts(props.path.parts).selectedNodes()
        ]).forEach(([delegate, nodes]: [QueryBuilderDelegate, string[]]) =>
            history.push(showSelectedNodes(delegate, nodes).toString())
        );
    }, [props.path]);

    /**
     * Safely select the given nodes and make sure the hypergraph is open to show the selection. Return an updated path
     * in case selections have changed.
     */
    const showSelectedNodes = (delegate: QueryBuilderDelegate, nodes: string[]): QueryPath => {
        const path = QueryPath.fromParts(props.path.parts);
        const verifiedNodes = nodes.filter(n =>
            delegate.hyperGraphExecutor.hyperGraph.getPossible(n).isPresent
        );

        // no valid nodes, clear the selection
        if (verifiedNodes.length === 0) {
            return path.without(SubPathKey.SELECTED_NODES);
        }

        // purged some, update the URL
        if (verifiedNodes.length !== nodes.length) {
            return path.with(SubPathKey.SELECTED_NODES, verifiedNodes);
        }

        delegate.hyperGraphExecutor.selectNodes(verifiedNodes);
        toggleTool(QueryBuilderTool.HYPER_GRAPH, true);
        toggleTool(QueryBuilderTool.DATASET_PANEL, false);
        return path;
    };

    const describeDatasetWithNewPersona = (describeFqn: FQN, selectedPersona: Optional<AssetSearchResult>, controller: AbortController) => {
        ServiceProvider.get(DatasetV2Service).describeDataset(describeFqn, controller.signal, query.map(q => q.fqn).nullable)
            .then(r => r.match(newDataset => {
                setDataset(Optional.of(newDataset));
                delegate.forEach(d => d.changePersona(newDataset, selectedPersona));
            }, err => {
                ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, err.toString()
                );
            }));
    };

    const updatePersona = (selectedPersona: Optional<AssetSearchResult>) => {
        const controller = new AbortController();

        dataset.forEach(dataset => {
            selectedPersona.forEach(persona => {
                describeDatasetWithNewPersona(persona.fqn, selectedPersona, controller);
            }).orForEach(() => {
                describeDatasetWithNewPersona(dataset.fqn, selectedPersona, controller);
            });
        });
    };

    const onPersonaSelect = (selectedPersona: Optional<AssetSearchResult>) => {
        if (delegate.map(d => d.hasChanges).getOr(false)) {
            setPendingPersona(selectedPersona);
        } else {
            updatePersona(selectedPersona);
        }
    };

    const onConfirmPersonaSelect = () => {
        updatePersona(pendingPersona);
        setPendingPersona(null);
    };

    const onFieldsDrop = (drop: DragAndDropData<QueryDnDSource>) => {
        delegate.forEach(d => {
            drop.isOf(QueryDnDSource.METRIC).forEach(name => d.deleteField(name));
            drop.isOf(QueryDnDSource.GROUPING).forEach(name => d.deleteGrouping(name));
            drop.isOf(QueryDnDSource.FILTER).forEach(name => d.deleteFilter(parseInt(name), false));
            drop.isOf(QueryDnDSource.AGGREGATE_FILTER).forEach(name => d.deleteFilter(parseInt(name), true));
        });
    };

    const onDoubleClickField = (column: Column) => {
        delegate.forEach(d => d.addFieldOrGrouping(column.name)
            .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                'queryBuilder', NotificationSeverity.WARNING, warning
            ))
        );
    };

    const onToolbarChange = (assetLabel: string) => {
        delegate.forEach(d => d.changeInfo({
            label: assetLabel
        }));
    };

    const onCancelSave = () => toggleTool(QueryBuilderTool.SAVE, false);
    const onCancelArchive = () => toggleTool(QueryBuilderTool.ARCHIVE, false);
    const onCloseShare = () => toggleTool(QueryBuilderTool.SHARE, false);

    const onSave = (arcQL: ArcQL) => {
        // persisted, clear local copy
        delegate.forEach(d => d.replace(arcQL, ReplaceReason.SAVE));
        toggleTool(QueryBuilderTool.SAVE, false);
    };

    const onArchive = () => {
        props.onClose();
    };

    const onSelectVersion = (version: AssetVersion) => {
        query.forEach(
            q => ServiceProvider.get(MetadataService)
                .fetchArcQLVersion(q.fullyQualifiedName, version.version)
                .then(result => {
                    delegate.forEach(d => d.replace(result.rightOrThrow(), ReplaceReason.VERSION));
                })
        );
    };

    const isToolShownWith = <T, >(tool: QueryBuilderTool, dependency: Optional<T>): Optional<T> => {
        return Optional.bool(isToolShown(tool)).flatMap(() => dependency);
    };

    const onSelectNodes = (nodes: string[]) => {
        let path = QueryPath.fromBase(props.path.assetFqn);
        if (nodes.length > 0) {
            path = path.with(SubPathKey.SELECTED_NODES, nodes);
        }
        history.push(path.toString());
    };

    const buildViz = (): Optional<ReactNode> => {
        return Optional.all([delegate, dataset]).flatMap(([delegate, dataset]: [QueryBuilderDelegate, ArcDataset]) => {
            if (hyperGraph.selectedNodes.length > 1) {
                // show small multiples of all the selected nodes
                return Optional.of(<SmallMultiples
                    cells={hyperGraph.selectedNodes.map(n => ({
                        query: n.getQuery(hyperGraph),
                        label: n.label(hyperGraph),
                        description: n.description[0]
                    }))}
                />);
            } else {
                // show a single visualization
                return query.map(query =>
                    <QueryBuilderCanvas
                        ref={canvasEl}
                        delegate={delegate}
                        selectable={engine.map(e => e.getActor<DelegatedQueryActor>(QUERIER_ID)).get()}
                        arcqlBundle={new ArcQLBundle(query, dataset)}
                        queryResult={queryResult}
                        status={actorStatus}
                        drillClauses={facetClauses}
                    />
                );
            }
        });
    };

    return <S.QueryBuilder>
        <Toolbar
            fqn={props.path.assetFqn}
            assetLabel={query.map(q => q.label).getOr(null)}
            assetLabelEditable={true}
            onLabelChange={onToolbarChange}
            isCompactTitle={false}
            actions={toolbarActions}
            onAction={onToolbarAction}
        />
        <S.Content>
            <S.LeftPanel>{
                dataset.map(d =>
                    <DatasetPanel
                        dataset={d}
                        isSaved={query.map(q => q.isExisting).getOr(false)}
                        draggable
                        onDrop={onFieldsDrop}
                        onDoubleClick={onDoubleClickField}

                        isOpen={isToolShown(QueryBuilderTool.DATASET_PANEL)}
                        persona={query.flatMap(q => q.source.persona)}
                        onPersonaSelect={onPersonaSelect}
                        onClickHeader={() => toggleTool(QueryBuilderTool.DATASET_PANEL)}
                    />
                ).nullable
            }</S.LeftPanel>
            <S.BodyPanel>
                {buildViz().nullable}
            </S.BodyPanel>
            {
                isToolShownWith(QueryBuilderTool.VERSIONS, query).map(query =>
                    <S.VersionsPanel
                        fqn={query.fullyQualifiedName}
                        selected={query.version}
                        onSelect={onSelectVersion}
                    />
                ).nullable
            }
            {
                isToolShownWith(QueryBuilderTool.HYPER_GRAPH, Optional.all([delegate, dataset]))
                    .map(([delegate, dataset]: [QueryBuilderDelegate, ArcDataset]) =>
                    <ReactFlowProvider>
                        <S.HyperGraphPanel
                            hyperGraph={hyperGraph}
                            dataset={dataset}

                            onResetSelection={() => delegate.hyperGraphExecutor.resetSelection()}
                            onClickConnectStart={n => delegate.hyperGraphExecutor.execute(new HyperGraphProcess(dataset, n))}
                            onNodesChange={c => delegate.hyperGraphExecutor.onNodesChange(c)}
                            onNodeClick={(n, m) => delegate.hyperGraphExecutor.onNodeClick(n, m, onSelectNodes)}
                            onChangeRating={(n, r) => delegate.hyperGraphExecutor.changeRating(n, r)}
                            onChangeContent={(n, t, c) => delegate.hyperGraphExecutor.onChangeContent(n, t, c)}
                        />
                    </ReactFlowProvider>
                ).nullable
            }
        </S.Content>
        {
            isToolShownWith(QueryBuilderTool.SAVE, query).map(query => {
                const saveHandler = new SaveHandler(
                    query.with({
                        hyperGraph: delegate.get().hyperGraphExecutor.hyperGraph.toJSON()
                    }),
                    props.onTabChange,
                    Optional.of(canvasEl.current)
                );

                return query.isExisting ?
                    <SaveDialog
                        fqn={props.path.assetFqn}
                        label={query.label}
                        description={delegate.map(d => d.describeChanges()).getOr('')}
                        saveHandler={saveHandler}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    /> :
                    <SaveAsDialog
                        asset={query}
                        fqn={props.path.assetFqn}
                        saveHandler={saveHandler}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    />;
            }).nullable
        }
        {
            isToolShownWith(QueryBuilderTool.SHARE, query).map(query =>
                <ShareDialog
                    assetName={query.label}
                    element={canvasEl.current}
                    path={query.isExisting && props.path.fullPath}
                    queryResponse={queryResult.nullable}
                    onClose={onCloseShare}
                />
            ).nullable
        }
        {
            isToolShownWith(QueryBuilderTool.ARCHIVE, query).map(q =>
                <ArchiveDialog
                    asset={q}
                    onCancel={onCancelArchive}
                    onArchive={onArchive}
                />
            ).nullable
        }
        {
            isToolShown(QueryBuilderTool.DEV) && <S.DevPanel>
                <S.JsonPanel json={query}/>
                <S.SqlPanel sql={queryResult.map(q => q.result.sql)}/>
            </S.DevPanel>
        }
        {
            engine.map<DrillActor>(e => e.getActor(DRILL_ID)).map(actor =>
                <ExternalState
                    encodedState={QueryPath.fromParts(props.path.parts).facets().nullable}
                    isEmbed={props.path.isEmbed}
                    delegate={actor}
                />
            ).nullable
        }
        {
            pendingPersona && <ApplyMaskDialog
                onCancel={() => setPendingPersona(Optional.none())}
                onConfirmSelection={onConfirmPersonaSelect}
            />
        }
    </S.QueryBuilder>;

};
