import {Enum} from "common/Enum";
import {FQN} from "common/FQN";

export class AssetType extends Enum {

    static readonly DATASET = new this('dataset', 'datasets', 'Dataset', true);
    static readonly ABI = new this('abi', 'abis', 'Semantic ABI', false);
    static readonly ARCQL = new this('arcql', 'arcqls', 'Query', true);
    static readonly DASHBOARD = new this('dashboard', 'dashboards', 'Dashboard', true);
    static readonly PERSONA = new this('persona', 'personas', 'Mask', false);
    static readonly FILTER_SET = new this('filterset', 'filtersets', 'Filter Set', true);
    static readonly STORY = new this('story', 'stories', 'Story', false);
    static readonly TREND = new this('trend', 'trends', 'Trend', false);
    static readonly DATASET_V2 = new this('dataset_v2', 'datasets_v2', 'Dataset', true);

    /**
     * Enumerate all AssetTypes that support search.
     */
    static searchable(): AssetType[] {
        return AssetType.enums<AssetType>().filter(t => t.searchable);
    }

    fqn(account: string, folder: string, name: string): FQN {
        return new FQN(account, folder, this, name);
    }

    constructor(
        name: string,
        public readonly plural: string,
        public readonly label: string,
        public readonly searchable: boolean
    ) {
        super(name);
    }

}
AssetType.finalize();