import {ArcQL} from "metadata/query/ArcQL";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {Optional} from "common/Optional";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import DistinctValuesArcQLRequest from "services/DistinctValuesArcQLRequest";
import RangeValuesArcQLRequest from "services/RangeValuesArcQLRequest";

/**
 * Service to issue all the queries.
 */
export abstract class QueryService {

    /**
     * Make a raw arbitrary ArcQL query.
     */
    abstract query(
        arcql: ArcQL,
        signal?: AbortSignal,
        // when to expire the query from the cache
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, ArcQLResponse>>;

    /**
     * Query a saved ArcQL with optional filters replacing the existing.
     */
    abstract querySaved(
        arcql: ArcQL,
        filters: Optional<ArcQLFilters>,
        signal?: AbortSignal,
        // when to expire the query from the cache
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, ArcQLResponse>>;

    /**
     * Search for distinct values for a given dataset and field (notably Dimension).
     */
    abstract distinctValues(
        dataset: ArcDataset,
        request: DistinctValuesArcQLRequest,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcQLResponse>>;

    /**
     * Search for range of a given dataset and field (notably Measure).
     */
    abstract rangeValues(
        dataset: ArcDataset,
        request: RangeValuesArcQLRequest,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcQLResponse>>;
}