import {Chain} from "metadata/Chain";
import {Column} from "metadata/Column";
import {ArcDatasetStatusMetadata} from "metadata/dataset/ArcDatasetStatusMetadata";
import * as React from "react";
import {FunctionComponent} from "react";
import Avatar from "@mui/material/Avatar";
import {ValueFormatter} from "common/ValueFormatter";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";

type Props = {
    chain: Chain
    columnFields: Column[]
    statusMetadata: ArcDatasetStatusMetadata
}

export const ChainInfo: FunctionComponent<Props> = (props: Props) => {

    return <S.PerChainTable>
        <S.Table>
            <tbody>
            <tr>
                <S.ParameterCell>Chain</S.ParameterCell>
                <S.TableValue>
                    <Avatar
                        alt={props.chain.label}
                        src={`/static/images/${props.chain}.svg`}
                        sx={{width: 16, height: 16}}
                    />
                    {props.chain.label}
                </S.TableValue>
            </tr>
            </tbody>
            {
                props.statusMetadata.chainToTrackedRanges.get(props.chain.name).map(trackedRange => {
                    const columnLabel = props.columnFields
                        .find(field => field.name === trackedRange.columnName).label;
                    return <tbody key={`${props.chain.name}_${columnLabel}`}>
                    <tr>
                        <S.ParameterCell>Min {columnLabel}</S.ParameterCell>
                        <S.TableValue>{ValueFormatter.roundedNumber(trackedRange.min)}</S.TableValue>
                    </tr>
                    <tr>
                        <td>Max {columnLabel}</td>
                        <S.TableValue>{ValueFormatter.roundedNumber(trackedRange.max)}</S.TableValue>
                    </tr>
                    </tbody>;
                })
            }
            <tbody>
            <tr>
                <td>Last{props.statusMetadata.isStreamable ? " Safe " : " "}Block</td>
                <S.TableValue>
                    {
                        ValueFormatter.fromNow(props.statusMetadata.timestampsByChain.get(props.chain.name).max.toUTCString())
                    }
                </S.TableValue>
            </tr>
            </tbody>
        </S.Table>
    </S.PerChainTable>;

};

const S = {

    ParameterCell: styled.td`
        width: 50%;
    `,

    Table: styled.table`
        width: 100%;
    `,

    TableValue: styled.td`
        color: ${Colors.textPrimary};
        display: flex;
        flex-direction: row;
        gap: 5px;
    `,

    PerChainTable: styled.div`
        margin-top: 10px;
    `

};
