import styled from "@emotion/styled";
import {FormLabel, TextField} from "@mui/material";

export class FormS {

    static readonly Label = styled(FormLabel)`
        margin-bottom: 4px;
    `;

    static readonly TextField = styled(TextField)`
        width: 280px;
    `;

}