import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useRef, useState} from "react";
import ReactFC from "react-fusioncharts";
// @ts-ignore
import FusionCharts from "fusioncharts/core";
// @ts-ignore
import Charts from "fusioncharts/charts";
// @ts-ignore
import TimeSeries from 'fusioncharts/timeseries';
// @ts-ignore
import Treemap from 'fusioncharts/treemap';
// @ts-ignore
import PowerCharts from 'fusioncharts/powercharts';
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import isEqual from "lodash/isEqual";

import {Optional} from "common/Optional";
import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {VisualizationComponents, VisualizationProps} from "app/visualizations/ArcVisualization";
import {RangeSelection, VizSelection} from "engine/actor/VizSelection";
import {ChartConfigFactory} from "app/visualizations/config/ChartConfigFactory";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {useResize} from "app/components/hooks/DomHooks";
import {Selectable} from "engine/actor/Selectable";
import styled from "@emotion/styled";
import {TimelineChartConfig} from "app/visualizations/config/TimelineChartConfig";
import {VizType} from "metadata/query/VizType";

ReactFC.fcRoot(FusionCharts, Charts, TimeSeries, Treemap, PowerCharts, FusionTheme);

// charts that don't resize and we need to force
const SHITTY_CHARTS = new Set(['sankey', 'chord']);

/**
 * Wrapper visualization that handles changing query results into a chart.
 *
 * @author zuyezheng
 */
export const ArcChart: FunctionComponent<VisualizationProps> = (props: VisualizationProps) => {

    const containerRef = useRef(null);
    const chartRef = useRef(null);

    // if the chart is drawing where we should delay updates as it often breaks shit
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<Optional<string>>(Optional.none());
    const containerSize = useResize(containerRef);
    const [selections, setSelections] = useState<VizSelection>(VizSelection.none());
    // if we should render a dummy before rerendering the visualization forcing fusion to redraw, this is wrapped in an
    // optional so that the useEffect that flips this after a dummy is rendered will always be triggered even if
    // this state is set twice within the same draw cycle to the same boolean value
    const [shouldRenderDummy, setShouldRenderDummy] = useState<Optional<boolean>>(Optional.some(false));
    const [chartConfigBuilt, setChartConfigBuilt] = useState<Optional<{[key: string]: any}>>(Optional.none());

    // track the current chart config as a ref instead of state since fusion handlers are memoized and chartConfigBuilt
    // state stores the resolved config used in fusion
    const chartConfigRef = useRef<Optional<ChartConfig>>(Optional.none());
    // track the last container size to know when we need to resize
    const containerSizeRef = useRef<Optional<[number, number]>>(Optional.none());
    // track selections as a ref instead of state since fusion memoizes callbacks around selections
    const selectionsRef = useRef<VizSelection>(VizSelection.none());

    // these props are used in callabacks which get memoized
    const queryResponseRef = useRef<ArcQLResponse>();
    queryResponseRef.current = props.queryResponse;
    const selectableRef = useRef<Selectable>();
    selectableRef.current = props.selectable;

    // if we should ignore the next range selection event
    const ignoreNextRangeSelection = useRef<boolean>(true);

    // handle the case in which we want to load a range selection from a linked selectable (namely timeline charts)
    const loadingRangeSelection = useRef<boolean>(false);

    // need to remember the last chart interactions when a redraw happens
    const scrollPosition = useRef(0);

    // link the selectable to let it manage selections if set
    useEffect(() => {
        if (props.selectable == null) {
            return;
        }

        // default onLoad is a no-op
        let onLoad = (selection: VizSelection) => {};
        // but for timeline charts, we require special handling of setting range selections
        if (props.config.type === VizType.TIMELINE) {
            onLoad = (selection: VizSelection) => {
                loadingRangeSelection.current = true;
            };
        }
        props.selectable.linkSelectable(setSelections, onLoad);
        return () => props.selectable.unlinkSelectable();
    }, [props.selectable]);

    // with either config changes or a new query response, figure out how the visualization is impacted
    useEffect(() => {
        if (isDrawing) {
            return;
        }

        // fusion will fire range selection change events every rerender, ignore them if selections remain the same
        ignoreNextRangeSelection.current = selections.isEqual(selectionsRef.current);

        // if no results, don't give fusion a chance to blowing up
        if (props.queryResponse.result.length === 0) {
            setValidationMessage(Optional.some('No results.'));
            // clear the state in case next results is the same as the prior right before the 0 results
            setChartConfigBuilt(Optional.none());
            chartConfigRef.current = Optional.none();
            return;
        }

        // no need to build out the chart config until we know the container size and it's non 0
        if (containerSize.map(s => isEqual(s, [0, 0])).getOr(true)) {
            return;
        }

        // do some diffing to see if we need to rebuild the fusion config which triggers a rerender
        const newConfig = ChartConfigFactory.get(props.config, props.queryResponse);

        const sizeChanged = Optional.all([containerSizeRef.current, containerSize])
            .map(s => !isEqual(s[0], s[1]))
            .getOr(true);
        if (
            // chart config hasn't changed
            chartConfigRef.current.map(c => c.isEqual(newConfig)).getOr(false)
            // container size hasn't changed
            && !sizeChanged
            // rerender if selections have changed
            && selections.isEqual(selectionsRef.current)
        ) {
            return;
        }

        const newQueryResponse = props.queryResponse.id !== chartConfigRef.current.map(c => c.response.id).nullable;

        // update the selections ref to the current state for memoized handlers
        selectionsRef.current = selections;
        containerSizeRef.current = containerSize;

        // validate the new chart config
        const validationMessage = newConfig.validate();
        setValidationMessage(validationMessage);

        // update or clear the config based on validation
        chartConfigRef.current = validationMessage
            .map(() => Optional.none<ChartConfig>())
            .getOr(Optional.some(newConfig));

        // @ts-ignore
        const adjustedContainerSize: [number, number] = containerSize.get().slice();
        if (newConfig instanceof TimelineChartConfig) {
            adjustedContainerSize[0] += 20;
            adjustedContainerSize[1] += 54;
        }

        // build the new chart config if there's one to build
        const newChartConfigBuilt: Optional<{[key: string]: any}> = chartConfigRef.current
            .map(config => config.build(adjustedContainerSize, selections));

        // fusion is pretty sketchy changing chart types and some will consistently blow up and in places that are
        // impossible to catch such as in an internal timeout/interval function, so to avoid these issues render a
        // dummy component which will unmount the chart before mounting it to render the next visualization when the
        // chart type is changed
        setShouldRenderDummy(Optional.some(
            chartConfigBuilt.map(c => c['type']).nullable !== newChartConfigBuilt.map(c => c['type']).nullable ||
            // some charts don't resize unless we force a rerender
            newChartConfigBuilt.filter(c => SHITTY_CHARTS.has(c['type'])).map(() => sizeChanged).getOr(false) ||
            newQueryResponse
        ));

        setChartConfigBuilt(newChartConfigBuilt);
    }, [props.config, props.queryResponse, containerSize, selections, isDrawing]);

    // dummy rendered, render the real thing
    useEffect(() => {
        shouldRenderDummy.filter(b => b)
            .forEach(() => setShouldRenderDummy(Optional.some(false)));
    }, [shouldRenderDummy]);

    const onClick = (event: any) => {
        // see if there's anything to listen to selections
        if (selectableRef.current == null) {
            return;
        }

        // there should be a chart builder and it should support discrete selections
        if (chartConfigRef.current.map(b => b.selectionType !== SelectionType.DISCRETE).getOr(true)) {
            return;
        }
        const chartConfig = chartConfigRef.current.get();

        // selections are only possible for explicitly grouped queries
        if (!queryResponseRef.current.arcql.isGrouped() || queryResponseRef.current.arcql.groupings.isAll) {
            return;
        }

        // figure out which data points were clicked, this needs to be toggled with existing state to figure out if
        // if it's a select or unselect
        const clickedData = chartConfig.handleDiscreteClick(event.data, event.sender.originalDataSource, event.type);
        // no valid selections
        if (clickedData.isNone) {
            return;
        }

        // let the selectable know what was clicked
        selectableRef.current.toggleDiscrete(clickedData.get());
    };

    const onRangeSelectionChange = (event: any) => {
        // don't track or broadcast if we're ignoring the next range selection (happens on every re-render)
        if (ignoreNextRangeSelection.current) {
            ignoreNextRangeSelection.current = false;
            return;
        }

        // see if there's anything to listen to selections
        if (selectableRef.current == null) {
            return;
        }

        // we're loading a range selection from a linked selectable, ignore the default range selection event
        // and any further range selection events until onDraw -> will set this to false
        if (loadingRangeSelection.current) {
            return;
        }

        // Non timeline charts takes a rerender cycle to make the necessary selections since selections are passed in
        // through the chart config. Timeline charts maintain its own selection state so the actual selection has
        // already happened. To avoid re-renders and to know when selectable is sending in a range change we need to
        // manually update the selectionsRef before setSelections is called by selectable.
        selectionsRef.current = selectableRef.current.selectRange(event.data.start, event.data.end);
    };

    const onScroll = (event: any) => {
        scrollPosition.current = event.data.scrollPosition;
    };

    // need to remember when a chart is drawing, if we try to redraw or update while drawing or animating, boom
    const onBeforeDraw = () => {
        setIsDrawing(true);
    };

    // when animation is done, this gets called (most of the time)
    const onDrawComplete = () => {
        setIsDrawing(false);

        // TODO ZZ hack since scroll bar not adjusting after scroll to
        if (scrollPosition.current > 0) {
            // chart type changed and no longer scrollable, reset tracking
            if (chartRef.current.chartObj.scrollTo == null) {
                scrollPosition.current = 0;
                return;
            }

            chartRef.current.chartObj.scrollTo(scrollPosition.current);
            setTimeout(() => chartRef.current?.chartObj.resizeTo(containerSize.get()), 0);
        }

        // if it's a timeline chart and there's a loaded range selection from linked selectable, then set initial range
        if (loadingRangeSelection.current) {
            loadingRangeSelection.current = false;
            if (selectableRef.current.selection instanceof RangeSelection) {
                const rangeSelection = selectableRef.current.selection as RangeSelection;
                chartRef.current.chartObj.setTimeSelection({
                    start: rangeSelection.start,
                    end: rangeSelection.end
                });
            }
        }
    };

    // no results will call this
    const onDisposed = () => {
        setIsDrawing(false);
    };

    const onDataLabelClick = (event: any) => {
        try {
            // getting the value from a label click gets sketchy so just give it a good old college try
            let values = event.sender.originalDataSource.dataset[0].data[event['data']['index']].categoryValues;
            if (values.length > 1) {
                // there are multiple values when there are multiple groupings, however the label is only for the n-1
                // groupings with the last being stacked so remove the last value which will default to the first of
                // the last grouping
                values = values.slice(0, -1)
            }
            const value = values.join(' > ');

            navigator.clipboard.writeText(value).then(() => {
                ServiceProvider.get(NotificationsService).publish(
                    'arcChart', NotificationSeverity.INFO, 'Value copied to clipboard!'
                );
            });
        } catch(e) {
            // swallow silently
        }
    };

    const beforeDrillDown = (event: any) => {
        // treemap has some confusing drill down interaction, prevent that
        event.preventDefault();
    };

    const chartComp = useMemo(() => {
        return chartConfigBuilt.map(resolved =>
            <ReactFC
                key={props.queryResponse.id}
                ref={chartRef}

                // most click events routed here
                fcEvent-dataplotClick={onClick}
                // click events for the links between nodes in charts like sankey
                fcEvent-linkClick={onClick}

                fcEvent-onScroll={onScroll}
                // range zoom/pan selection on timeline charts
                fcEvent-selectionChange={onRangeSelectionChange}

                fcEvent-beforeDraw={onBeforeDraw}
                fcEvent-drawComplete={onDrawComplete}
                fcEvent-disposed={onDisposed}

                fcEvent-dataLabelClick={onDataLabelClick}

                fcEvent-beforeDrillDown={beforeDrillDown}

                {...resolved}
            />
        ).getOr(<></>);
    }, [chartConfigBuilt]);

    const isOverDrawn = chartConfigBuilt.map(c => c['type'] === 'timeseries').getOr(false);

    return <S.Outer>
        <S.Inner className={isOverDrawn ? 'overDraw' : null} ref={containerRef}>{
            validationMessage.map(message =>
                <VisualizationComponents.Mask>{message}</VisualizationComponents.Mask>
            ).getOrElse(() =>
                shouldRenderDummy.filter(b => b)
                    .map(() => <></>)
                    .getOr(chartComp)
            )
        }</S.Inner>
    </S.Outer>;

};

const S = {

    Outer: styled.div`
        height: 100%;
        width: 100%;
        background-color: white;
        padding: 6px 4px;
        box-sizing: border-box;
        &:has(.overDraw) {
            padding-bottom: 24px;
        }
    `,

    Inner: styled.div`
        width: 100%;
        height: 100%;

        &.overDraw {
            margin-left: -20px;
            margin-top: -3px;
        }
    `

};
