import {JsonObject} from "common/CommonTypes";
import {FQN} from "common/FQN";
import {Asset, AssetProps} from "metadata/Asset";
import {FilterSetDataFactory} from "metadata/filterset/FilterSetDataFactory";
import {FilterSetData} from "metadata/filterset/FilterSetData";
import {FilterSetValueList} from "metadata/filterset/FilterSetValueList";
import {AssetType} from "metadata/AssetType";

export type ArcFilterSetBodyProps = {
    data?: FilterSetData
}

// all mutable props
export type ArcFilterSetProps = AssetProps & ArcFilterSetBodyProps;

/**
 * Mutable definition of our ArcFilterSet spec supporting serialization.
 */
export class ArcFilterSet implements Asset, ArcFilterSetProps {

    static fromJSON(json: JsonObject): ArcFilterSet {
        return new ArcFilterSet(
            json['id'],
            json['fullyQualifiedName'] && FQN.parse(json['fullyQualifiedName']),
            json['name'],
            json['label'],
            json['description'],
            FilterSetDataFactory.fromJSON(json['data']),
            json['version'],
            json['folderId'],
            json['parentId']
        );
    }

    static minimal(): ArcFilterSet {
        return new ArcFilterSet().with({data: new FilterSetValueList([])});
    }

    private constructor(
        // id should default to null until saved as its the indicator for a new vs existing asset
        public readonly id: string = null,
        public readonly fullyQualifiedName: FQN = null,
        // these should default to null so we know when to apply defaults vs user explicitly set an empty string
        public readonly name: string = null,
        public readonly label: string = null,
        public readonly description: string = null,
        public readonly data: FilterSetData = null,
        public readonly version: number = -1,
        public readonly folderId: string = null,
        public readonly parentId: string = null
    ) {
    }

    get assetType(): AssetType {
        return AssetType.FILTER_SET;
    }

    /**
     * Has the FilterSet already been saved?
     */
    get isExisting(): boolean {
        return this.id != null;
    }

    get fqn(): FQN {
        return this.fullyQualifiedName;
    }

    with({
        name,
        label,
        description,
        data
    }: ArcFilterSetProps): ArcFilterSet {
        return new ArcFilterSet(
            this.id,
            this.fullyQualifiedName,
            name == null ? this.name : name,
            label == null ? this.label : label,
            description == null ? this.description : description,
            data == null ? this.data : data,
            this.version,
            this.folderId,
            this.parentId
        );
    }

    toJSON(): JsonObject {
        return {
            id: this.id,
            name: this.name,
            label: this.label,
            description: this.description,
            data: this.data
        };
    }

    toLocalStorageJSON(fqn: FQN): JsonObject {
        return {
            ...this.toJSON(),
            // stuff we normally wouldn't pass to the server for saving
            fullyQualifiedName: fqn.toString(),
            version: this.version
        };
    }
}