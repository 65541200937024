import {NextBestGroupings} from "metadata/hypergraph/nodes/NextBestGroupings";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {AddGrouping} from "metadata/query/changes/AddGrouping";
import {FieldGrouping} from "metadata/query/ArcQLGroupings";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {DeleteGrouping} from "metadata/query/changes/DeleteGrouping";
import {HyperGraphNodeHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {WithQueryVariants} from "metadata/hypergraph/WithQueryVariants";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {
    HyperGraphNodeFactory,
    HyperGraphNodeProps,
    HyperGraphNodeSerialized
} from "metadata/hypergraph/HyperGraphTypes";
import {ModifyGrouping} from 'metadata/query/changes/ModifyGrouping';
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {SubFactsDetail} from 'metadata/hypergraph/content/SubFactsDetail';

/**
 * Resulting next best groupings.
 *
 * @author zuyezheng
 */
export class NextBestGroupingsNode extends HyperGraphNode<NextBestGroupings> implements WithQueryVariants<NextBestGrouping> {

    constructor(
        public readonly request: NextBestGroupings,
        private readonly response: NextBestGroupingsJson,
        id?: string,
        createdOn?: number,
        modifiedOn?: number,
        nodeEmbedding?: number[],
        rating?: NodeRating
    ) {
        super(id, createdOn, modifiedOn, nodeEmbedding, undefined, rating);
    }

    get replacementGroupings(): NextBestGrouping[] {
        return this.response.replacementGroupings.map(
            g => new NextBestGrouping(g.field, g.why, g.nullHypothesis, g.alternativeHypothesis, false)
        );
    }

    get additionalGroupings(): NextBestGrouping[] {
        return this.response.additionalGroupings.map(
            g => new NextBestGrouping(g.field, g.why, g.nullHypothesis, g.alternativeHypothesis, true)
        );
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.NEXT_BEST_GROUPINGS;
    }

    label(hyperGraph: HyperGraph): string {
        return 'Next Best Groupings';
    }

    get description(): string {
        return '';
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        const groupingToDetail = (g: NextBestGrouping) => new SubFactsDetail(
            g.field,
            [
                ['Why', g.why],
                ['Null Hypothesis', g.nullHypothesis],
                ['Alternative Hypothesis', g.alternativeHypothesis]
            ]
        );

        return [
            new NodeStructuredSection(
                'Replacement Groupings',
                this.replacementGroupings.map(groupingToDetail)
            ),
            new NodeStructuredSection(
                'Additional Groupings',
                this.additionalGroupings.map(groupingToDetail)
            )
        ];
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        if (nth < this.replacementGroupings.length) {
            return this.replacementGroupings[nth];
        } else {
            return this.additionalGroupings[nth - this.replacementGroupings.length];
        }
    }

    get variantsOperation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.GROUPS;
    }

    getVariants(graph: HyperGraph, dataset: ArcDataset): [NextBestGrouping, ArcQL][] {
        const baseQuery = this.getQuery(graph);

        return [
            ...this.replacementGroupings.map<[NextBestGrouping, ArcQL]>(grouping => {
                const change = new ModifyGrouping(
                    baseQuery.groupings.last.field,
                    new FieldGrouping(grouping.field),
                    dataset
                );
                return [grouping, change.apply(baseQuery).left];
            }),
            ...this.additionalGroupings.map<[NextBestGrouping, ArcQL]>(grouping => {
                const change = grouping.isPrevious ?
                    new DeleteGrouping(baseQuery.groupings.last.field, dataset) :
                    new AddGrouping(new FieldGrouping(grouping.field), dataset);
                return [grouping, change.apply(baseQuery).left];
            })
        ];
    }

    with(props: Partial<HyperGraphNodeProps>): NextBestGroupingsNode {
        return new NextBestGroupingsNode(
            this.request,
            this.response,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn,
            props.nodeEmbedding ?? this.nodeEmbedding,
            props.rating ?? this.rating
        );
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            response: this.response
        };
    }

    static factoryOfFactory(
        request: NextBestGroupings, id: string
    ): HyperGraphNodeFactory<NextBestGroupingsJson, NextBestGroupingsNode> {
        return (response: NextBestGroupingsJson) => new NextBestGroupingsNode(request, response, id);
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): NextBestGroupingsNode {
        return new NextBestGroupingsNode(
            NextBestGroupings.fromJSON(json.request, dataset),
            json.response,
            json.id,
            json.createdOn,
            json.modifiedOn,
            json.nodeEmbedding,
            NodeRating.withDefault(json.rating)
        );
    }

}

export class NextBestGrouping implements HyperGraphNodeHypothesis {

    constructor(
        public readonly field: string,
        public readonly why: string,
        public readonly nullHypothesis: string,
        public readonly alternativeHypothesis: string,
        // add or replace grouping
        public readonly isAdd: boolean
    ) { }

    get change(): string {
        return this.isAdd ? `Add grouping by "${this.field}".` : `Replace last grouping with "${this.field}".`;
    }

    /**
     * If the grouping field is the sentinel value for removing a grouping.
     */
    get isPrevious(): boolean {
        return this.field === '__previous__';
    }

}

export type NextBestGroupingJson = {
    field: string,
    why: string,
    nullHypothesis: string,
    alternativeHypothesis: string
}

export type NextBestGroupingsJson = {

    replacementGroupings: NextBestGroupingJson[],
    additionalGroupings: NextBestGroupingJson[]

}