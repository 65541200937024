import {Either} from "common/Either";
import {HyperGraphDatasetService} from "services/HyperGraphDatasetService";
import {ApiResponse, ErrorResponse} from "./ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {ColumnInfosResponse, ColumnInfosResponseJson} from "metadata/dataset/ColumnInfosResponse";
import {Column} from "metadata/Column";

/**
 * API implementation of HyperGraphDatasetService.
 */
export class ApiHyperGraphDatasetService implements HyperGraphDatasetService {

    generateColumnInfos(
        datasetLabel: string,
        datasetFqn: string,
        datasetDescription: string,
        columns: Column[],
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ColumnInfosResponse>> {
        return ServiceProvider.get(RestService)
            .post(
                '/api/v1/hypergraph/dataset/column/infos',
                {
                    datasetLabel: datasetLabel,
                    datasetFqn: datasetFqn,
                    datasetDescription: datasetDescription,
                    columns: columns
                },
                signal
            )
            .then(ApiResponse.success((json: ColumnInfosResponseJson) => ColumnInfosResponse.fromJSON(json)));
    }

}