import * as React from "react";
import {SVGProps} from "react";

interface ThemeIconProps extends SVGProps<SVGSVGElement> {
    color1?: string;
    color2?: string;
    color3?: string;
}

/**
 * Icon that shows a theme's primary 3 colors. Default colors are Hyperarc.
 */
const ThemeIcon: React.FC<ThemeIconProps> = ({
    color1 = "#FF8588",
    color2 = "#FCBD70",
    color3 = "#8FCEC1",
    ...props
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        {...props}
    >
        <path fill={color1} d="M16 5.5h5v14h-5z"/>
        <path fill={color2} d="M10 5.5h5v14h-5z"/>
        <path fill={color3} d="M4 5.5h5v14H4z"/>
    </svg>
);

export default ThemeIcon;
