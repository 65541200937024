import {Enum} from "common/Enum";

/**
 * Enumeration of node types for (de)serialization.
 *
 * @author zuyezheng
 */
export class HyperGraphNodeType extends Enum {

    static readonly CONSOLIDATE_ANALYSIS = new this('consolidateAnalysis');
    static readonly DATA_OF_INTEREST = new this('dataOfInterest');
    static readonly NEXT_BEST_GROUPINGS = new this('nextBestGroupings');
    static readonly NEXT_BEST_MEASURES = new this('nextBestMeasures');
    static readonly QUERY_CONTAINER = new this('queryContainer');
    static readonly START_ANALYSIS = new this('startAnalysis');
    static readonly VALIDATE_HYPOTHESIS = new this('validateHypothesis');

    static readonly REQUEST = new this('request');

    private constructor(name: string) {
        super(name);
    }

}
HyperGraphNodeType.finalize();