import {DataOfInterest} from "metadata/hypergraph/nodes/DataOfInterest";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {ArcQL} from "metadata/query/ArcQL";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {QueryResult} from "metadata/query/QueryResult";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {
    HyperGraphNodeFactory,
    HyperGraphNodeProps,
    HyperGraphNodeSerialized
} from "metadata/hypergraph/HyperGraphTypes";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {TrendsAndDataPoints, TrendsAndDataPointsJson} from 'metadata/hypergraph/request/TrendsAndDataPoints';

/**
 * Resulting data of interest.
 *
 * @author zuyezheng
 */
export class DataOfInterestNode extends HyperGraphNode<DataOfInterest> {

    public readonly trendsAndDataPoints: TrendsAndDataPoints;

    constructor(
        public readonly request: DataOfInterest,
        public readonly response: TrendsAndDataPointsJson,
        id?: string,
        createdOn?: number,
        modifiedOn?: number,
        nodeEmbedding?: number[],
        rating?: NodeRating
    ) {
        super(id, createdOn, modifiedOn, nodeEmbedding, undefined, rating);

        this.trendsAndDataPoints = TrendsAndDataPoints.fromJSON(this.response);
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.DATA_OF_INTEREST;
    }

    label(hyperGraph: HyperGraph): string {
        return this.trendsAndDataPoints.label;
    }

    get description(): string {
        return this.trendsAndDataPoints.summary;
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        return this.trendsAndDataPoints.structuredContent(this.getQueryResult(graph));
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return new SimpleHypothesis('There are interesting patterns in the query results.', 'No changes.');
    }

    with(props: Partial<HyperGraphNodeProps>): DataOfInterestNode {
        return new DataOfInterestNode(
            this.request,
            this.response,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn,
            props.nodeEmbedding ?? this.nodeEmbedding,
            props.rating ?? this.rating
        );
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            response: this.response
        };
    }

    static factoryOfFactory(
        request: DataOfInterest, id: string
    ): HyperGraphNodeFactory<TrendsAndDataPointsJson, DataOfInterestNode> {
        return (response: TrendsAndDataPointsJson) => new DataOfInterestNode(request, response, id);
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): DataOfInterestNode {
        return new DataOfInterestNode(
            DataOfInterest.fromJSON(json.request),
            json.response,
            json.id,
            json.createdOn,
            json.modifiedOn,
            json.nodeEmbedding,
            NodeRating.withDefault(json.rating)
        );
    }

}
