/**
 * SlugHelper class provides utility functions to handle label change and appropriate label/name slug transformations.
 */
export class SlugHelper {

    // Define static constants for max length values
    static readonly DEFAULT_MAX_NAME_LENGTH: number = 40;
    static readonly ASSET_MAX_NAME_LENGTH: number = 80;

    /**
     * Handles label change and appropriate label/name slug transformations.
     */
    static handleLabelChange(
        currentLabel: string,
        newLabel: string,
        currentName: string,
        setLabel: (value: string) => void,
        setName: (value: string) => void,
        maxNameLength: number = SlugHelper.DEFAULT_MAX_NAME_LENGTH
    ): void {
        const oldDefaultSlug = SlugHelper.convert(currentLabel, maxNameLength);
        const newSlug = SlugHelper.convert(newLabel, maxNameLength);

        // Only update the name if the user hasn't modified it
        const updatedName = oldDefaultSlug === currentName ? newSlug : currentName;

        setLabel(newLabel);
        setName(updatedName);
    }

    /**
     * Converts string to valid slug
     */
    static convert(value: string, maxNameLength: number = SlugHelper.DEFAULT_MAX_NAME_LENGTH): string {
        //replace special characters and symbols with a space
        value = value.replace(/[`~!@#$%^&*()+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();

        // trim spaces at start and end of string
        value = value.replace(/^\s+|\s+$/gm, '');

        // ignore emojis
        value = value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');

        // replace space with dash/hyphen
        value = value.replace(/\s+/g, '-');

        // Cap the name length to 40 characters
        return value.substring(0, maxNameLength);
    }

}