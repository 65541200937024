import * as React from "react";
import {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";
import Input from '@mui/material/Input';
import {ToolbarAction} from "app/components/toolbar/ToolbarAction";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {ToolbarActions, ToolbarActionsSection} from "app/components/toolbar/ToolbarActions";

type Props = {
    fqn: FQN,
    // fqn might stay the same, but label might change when saving a new version, this is hard to detect so rely on
    // parent to provide it, if null the fqn asset name will be used
    assetLabel?: string
    // if the asset label should be editable
    assetLabelEditable: boolean
    // callback when asset label has changed
    onLabelChange?: (assetLabel: string) => void
    // only show the label, rather than all parts of the FQN
    isCompactTitle: boolean

    // actions and callbacks for when they are selected
    actions: ToolbarActions,
    onAction: (action: ToolbarAction) => void

    // actions by id that are toggled
    toggledActions?: Set<string>

    className?: string
};

/**
 * Toolbar for displaying the current asset and actions that can be performed on it.
 */
export const Toolbar: FunctionComponent<Props> = (props: Props) => {

    const [folderLabel, setFolderLabel] = useState<Optional<string>>(Optional.none());
    const [assetLabel, setAssetLabel] = useState<Optional<string>>(Optional.of(props.assetLabel));

    useEffect(() => {
        const controller = new AbortController();
        const metadataService = ServiceProvider.get(MetadataService);

        metadataService.fetchFolder(props.fqn.account, props.fqn.folder, controller.signal)
            .then(response => response.optional()
                .map(folder => folder.label)
                .getOr(props.fqn.folder)
            )
            .then(label => setFolderLabel(Optional.some(label)));

        return () => controller.abort();
    }, []);

    useEffect(() => {
        setAssetLabel(Optional.some(props.assetLabel));
    }, [props.assetLabel]);

    const onChangeAssetLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setAssetLabel(Optional.some(event.target.value));
    };

    const onBlurAssetLabel = () => {
        assetLabel.forEach(label => props.onLabelChange(label));
    };

    const assetLabelComponent = props.assetLabelEditable
        ? <Input
            placeholder="Label"
            fullWidth
            value={assetLabel.getOr(props.fqn.name)}
            onChange={onChangeAssetLabel}
            onBlur={onBlurAssetLabel}
            inputProps={{
                sx: {
                    fontSize: 15,
                    padding: '5px 0px 3px'
                }
            }}
        />
        : <div>{assetLabel.getOr(props.fqn.name)}</div>;

    const titleCrumbs = props.isCompactTitle
        ? <S.Crumb>{assetLabelComponent}</S.Crumb>
        : <>
            <S.Crumb>
                <div>{props.fqn.account}</div>
                <div>/</div>
            </S.Crumb>
            <S.Crumb>
                <div>{folderLabel.getOr(props.fqn.folder)}</div>
                <div>/</div>
            </S.Crumb>
            <S.Crumb className="fullWidth">{assetLabelComponent}</S.Crumb>
        </>;

    return <S.Toolbar className={props.className}>
        <S.Breadcrumbs>
            {titleCrumbs}
        </S.Breadcrumbs>
        <S.Actions>
            {
                props.actions.sections.map((actionGroup: ToolbarActionsSection, groupIndex: number) =>
                    <S.ActionSection key={groupIndex}>
                        {actionGroup.comp(props.toggledActions || new Set(), a => props.onAction(a))}
                    </S.ActionSection>
                )
            }
        </S.Actions>
    </S.Toolbar>;
};

class S {
    static readonly Toolbar = styled.div`
        height: 52px;
        padding: 0 18px;
        display: flex;
        border-bottom: 1px solid ${Colors.borderGrey};
        overflow: hidden;
        align-items: center;
    `;

    static readonly Breadcrumbs = styled.div`
        display: flex;
        color: ${Colors.textPrimary};
        flex: 1;
    `;

    static readonly Crumb = styled.div`
        display: flex;
        align-items: center;
        white-space: nowrap;

        > div {
            margin-right: 7px;
        }

        &.fullWidth {
            flex: 1;
            width: 100%;
            max-width: 400px;
        }
    `;

    static readonly Actions = styled.div`
        display: flex;
        align-items: center;
    `;

    static readonly ActionSection = styled.div`
        padding: 5px;

        svg {
            color: ${Colors.iconPrimary};
        }

        button {
            &:hover {
                svg {
                    color: ${Colors.hyperarcYellowSecondary};
                }
            }
        }
    `;

}
