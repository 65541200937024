import React, {FunctionComponent} from "react";
import {KeyedWidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfig";
import {JsonObject} from "common/CommonTypes";
import {AppWidgetConfigPartType} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {ToolbarS} from "app/dashboard/widgets/ToolbarS";

interface Props {
    onConfigChange: (configKey: string, configJson: JsonObject) => void;
    partsMap: Map<string, WidgetConfigPart>
    sortedParts: KeyedWidgetConfigPart[]
    preToolbarEditors?: React.ReactNode
    preBlockEditors?: React.ReactNode
}

/**
 * Renders the editor for a widget by using the widget's config.
 */
export const WidgetEditor: FunctionComponent<Props> = (props: Props) => {
    const appPartTypes: [string, AppWidgetConfigPartType][] = props.sortedParts.map(
        ([key, part]) => [key, AppWidgetConfigPartType.from(part.type)]
    );
    const toolbarEditorParts = appPartTypes.filter(
        ([, partType]) => partType.ToolbarEditor !== null
    );

    const blockEditorParts = appPartTypes.filter(
        ([, partType]) => partType.BlockEditor !== null
    );

    return <>
        {toolbarEditorParts.length > 0 && (
            <ToolbarS.Container>
                {props.preToolbarEditors}
                {toolbarEditorParts.map(([key, partType]) => (
                    <ToolbarS.Section key={key} compactButtons={true}>
                        <partType.ToolbarEditor
                            onConfigPropertyChange={(changedProps) => {
                                props.onConfigChange(key, changedProps.toJSON());
                            }}
                            config={props.partsMap.get(key)}
                        />
                    </ToolbarS.Section>
                ))}
            </ToolbarS.Container>
        )}
        {props.preBlockEditors}
        {blockEditorParts.map(([key, partType]) => (
            <partType.BlockEditor
                key={key}
                onConfigPropertyChange={(changedProps) => {
                    props.onConfigChange(key, changedProps.toJSON());
                }}
                config={props.partsMap.get(key)}
            />
        ))}
    </>;
};