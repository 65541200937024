import {IdentityProperties, Property, UserAnalyticsService} from "services/UserAnalyticsService";
import {Config} from "services/Config";
import {ServiceProvider} from "services/ServiceProvider";

/**
 * Send user analytics data to Appcues
 */
export class AppcuesUserAnalyticsService implements UserAnalyticsService {

    private _environment: string;

    private get environment(): string {
        if (this._environment == null) {
            this._environment = ServiceProvider.get(Config).environment;
        }

        return this._environment;
    }

    identify(userId: string, properties: IdentityProperties): void {
        properties.environment = this.environment;
        // @ts-ignore
        window.AppcuesReady(() => window.Appcues.identify(userId, properties));
    }

    startFlow(flowId: string): void {
        // @ts-ignore
        window.AppcuesReady(() => window.Appcues.show(flowId));
    }

    track(eventName: string, additionalProperties: Record<string, Property> = {}): void {
        additionalProperties.environment = this.environment;
        // @ts-ignore
        window.AppcuesReady(() => window.Appcues.track(eventName, additionalProperties));
    }
}