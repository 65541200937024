import FormControl from "@mui/material/FormControl";
import {Autocomplete, ListItemIcon} from "@mui/material";
import {FolderResult} from "metadata/project/FolderResult";
import * as React from "react";
import {ChangeEvent, FunctionComponent} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {WrappedProjectIcon} from "app/components/icons/project/WrappedProjectIcon";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import {Folders} from "metadata/project/Folders";
import {FormS} from "app/components/form/FormS";

type Props = {
    loggedInUserName: string;
    folders: Folders;
    selected: string;
    onSelectFolder: (folder: FolderResult) => void;
};

/**
 * Input for selecting a project.
 */
export const ProjectInput: FunctionComponent<Props> = (props: Props) => {
    const isPersonalPrivateFolder = (folder: FolderResult) => folder.name === 'private' && folder.ownerName === props.loggedInUserName;
    const selectedFolder = props.folders.all.find(folder => folder.name === props.selected);

    // sort folders by label alphabetically, but put personal private folder first
    const sortedFolders = props.folders.all
        .sort((a, b) => {
            if (isPersonalPrivateFolder(a)) return -1;
            if (isPersonalPrivateFolder(b)) return 1;
            return a.label.localeCompare(b.label);
        });

    return <S.ProjectInput>
        <FormControl size={"small"} fullWidth>
            <FormS.Label id="project-label">Project</FormS.Label>
            <Autocomplete
                id="project"
                aria-labelledby="project-label"
                value={selectedFolder}
                options={sortedFolders}
                getOptionLabel={(option: FolderResult) => option.label}
                onChange={(e: ChangeEvent<HTMLInputElement>, newValue: FolderResult) => newValue && props.onSelectFolder(newValue)}
                fullWidth
                renderInput={(params) =>
                    <TextField
                        {...params}
                        size="small"
                        placeholder="Select Project..."
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                selectedFolder && <InputAdornment position="start" sx={{paddingLeft: "10px"}}>
                                    <WrappedProjectIcon isPublic={selectedFolder.isPublic}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                renderOption={(props, option: FolderResult) => (
                    <MenuItem {...props} divider={isPersonalPrivateFolder(option)}>
                        <ListItemIcon>
                            <WrappedProjectIcon isPublic={option.isPublic}/>
                        </ListItemIcon>
                        {option.label}
                    </MenuItem>
                )}
            />
        </FormControl>
    </S.ProjectInput>;
};

class S {

    static readonly ProjectInput = styled.div`
        display: contents;
    `;
}