import * as React from "react";
import {FunctionComponent, useEffect, useRef, useState} from "react";
import {ReferencedQueryActor} from "engine/actor/ReferencedQueryActor";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {Optional} from "common/Optional";
import {ArcEngine} from "engine/ArcEngine";
import {DashboardBuilderDelegate} from "app/dashboard/DashboardBuilderDelegate";
import {ArcMetadata} from "metadata/ArcMetadata";
import {Toolbar} from "app/components/toolbar/Toolbar";
import {StandardActions, ToolbarAction} from "app/components/toolbar/ToolbarAction";
import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {SaveAsDialog} from "app/components/SaveAsDialog";
import {ActorStatus} from "engine/actor/ActorStatus";
import {AssetPicker} from "app/components/search/AssetPicker";
import {AssetType} from "metadata/AssetType";
import {TabProps} from "app/TabType";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {SaveHandler} from "metadata/SaveHandler";
import {WidgetContainerAction} from "app/dashboard/widgets/WidgetContainerAction";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {S} from "app/dashboard/DashboardBuilderS";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {GlobalFilterFieldsPicker} from "app/dashboard/components/GlobalFilterFieldsPicker";
import {DashboardFilterPanel} from "app/dashboard/components/DashboardFilterPanel";
import {DashboardFilterActor, FILTER_ACTOR_ID} from "app/dashboard/DashboardFilterActor";
import {ResultMessage} from "engine/ResultMessage";
import {ExternalState} from "app/components/ExternalState";
import {ShareDialog} from "app/components/share/ShareDialog";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {
    ToolbarActions,
    ToolbarActionsGroup,
    ToolbarActionsMenu,
    ToolbarActionsMore,
    ToolbarActionsSection,
    ToolbarActionsSingle
} from "app/components/toolbar/ToolbarActions";
import Button from "@mui/material/Button";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {AssetVersion} from "metadata/AssetVersions";
import {SaveMode} from "app/components/SaveMode";
import {SaveDialog} from "app/components/SaveDialog";
import {Column} from "metadata/Column";
import {ReplaceReason} from "metadata/ReplaceReason";
import {TabPath} from "app/TabPath";
import {DashboardLoader} from "app/dashboard/DashboardLoader";
import {ErrorResponse} from "services/ApiResponse";
import {Either} from "common/Either";
import {InteractionsEditor} from "app/dashboard/interactions/InteractionsEditor";
import {ArchiveDialog} from "app/components/toolbar/ArchiveDialog";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";
import {DashboardGrid, SelectedWidgetState} from "app/dashboard/DashboardGrid";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {WelcomeMat} from "app/components/WelcomeMat";
import {StylesUtil} from "app/components/StylesUtil";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {StateConverter} from "app/dashboard/StateConverter";
import {SessionService} from "services/SessionService";
import {SessionType} from "metadata/session/SessionType";
import {DashboardState, DashboardStateJson} from "metadata/dashboard/DashboardState";
import {JsonObject} from "common/CommonTypes";
import {TrendWidgetContext} from "app/dashboard/components/TrendsPanel";
import {TrendsService} from "services/TrendsService";
import {TrendToolbarAction} from "app/dashboard/TrendToolbarAction";
import {WidgetTrendsMap} from "metadata/trend/WidgetTrendsMap";
import {TrendActionMenu} from "app/dashboard/TrendActionMenu";
import {StandardTrendActions, TrendAction} from "app/dashboard/TrendAction";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {TrendCreator} from "app/dashboard/components/TrendCreator";
import {ChartWidgetConfig} from "metadata/dashboard/widgets/config/ChartWidgetConfig";
import {InternalRouterService} from "services/InternalRouterService";
import {QueryPath, SubPathKey} from "app/query/QueryPath";
import {DatasetV2Service} from "services/DatasetV2Service";


// these need to be imported last
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

const DELEGATE_ID = '__SYSTEM__delegate';
// sentinel id for when adding a query to a new widget without a placeholder
const NEW_WIDGET_ID = '___NEW___';


enum SidePanelView {
    NONE,
    VERSIONS,
    CONFIG,
    TRENDS,
    STORIES
}

export const DashboardBuilder: FunctionComponent<TabProps> = (props: TabProps) => {

    const canvasEl = useRef<HTMLDivElement>(null);
    const viewportEl = useRef<HTMLDivElement>(null);

    const [dashboard, setDashboard] = useState<Optional<ArcDashboard>>(Optional.none());
    const [engine, setEngine] = useState<Optional<ArcEngine>>(Optional.none());

    // last query result and status for each query actor
    const [resultMessages, setResultMessages] = useState<Map<string, ResultMessage>>(new Map());
    // status of only the query actors by their names without prefix
    const [queryStatuses, setQueryStatuses] = useState<Map<string, ActorStatus>>(new Map());
    const [selectedWidget, setSelectedWidget] = useState<Optional<SelectedWidgetState>>(Optional.none());

    // id of the widget were attaching a query to, if any
    const [addingQueryTo, setAddingQueryTo] = useState<string>();

    // palette item last selected
    const [lastPaletteItem, setLastPaletteItem] = useState<Optional<WidgetType>>(Optional.none());

    // state for various modals and side panels
    const [saveMode, setSaveMode] = useState<Optional<SaveMode>>(Optional.none());
    const [sidePanelView, setSidePanelView] = useState<SidePanelView>(SidePanelView.NONE);
    const [showDevPanel, setShowDevPanel] = useState<boolean>(false);
    const [showArchive, setShowArchive] = useState<boolean>(false);

    const [recordingScene, setRecordingScene] = useState<Optional<StoryScene>>(Optional.none());

    // trends map for the trends panel, keyed by widget id
    const [trendsMap, setTrendsMap] = useState<Optional<WidgetTrendsMap>>(Optional.none());
    const [trendsButtonToggled, setTrendsButtonToggled] = useState<boolean>(false);
    const [trendActionMenuCtx, setTrendActionMenuCtx] = useState<Optional<TrendWidgetContext>>(Optional.none());
    const [trendPanelCtx, setTrendPanelCtx] = useState<Optional<TrendWidgetContext>>(Optional.none());
    const [trendCreateCtx, setTrendCreateCtx] = useState<Optional<TrendWidgetContext>>(Optional.none());
    const [trendMenuAnchorEl, setTrendMenuAnchorEl] = useState<null | HTMLElement>(null);

    const [shareContext, setShareContext] = useState<Optional<ShareContext>>(Optional.none());

    // datasets to use when editing interactions or global filters, existence of these also indicates the respective
    // UI's should be shown
    const [datasetsForInteractions, setDatasetsForInteractions] = useState<Optional<ArcDataset[]>>(Optional.none());
    const [datasetsForGlobalFilter, setDatasetsForGlobalFilter] = useState<Optional<ArcDataset[]>>(Optional.none());

    const isViewMode = props.path.getPart(4).map(subroute => subroute !== 'edit').getOr(true);

    // initialize the engine, dashboard, and trends
    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            const possibleLoadResult = (await DashboardLoader.load(props.path.assetFqn, controller.signal));

            // load error
            if (possibleLoadResult.isNone) {
                props.onClose();
                return;
            }

            // successfully loaded the metadata for the dashboard
            const loadResult = possibleLoadResult.get();
            const engine = new ArcEngine('dashboard');

            // create the main delegate
            const delegate = new DashboardBuilderDelegate(
                DELEGATE_ID,
                new ArcMetadata<ArcDashboard>(loadResult.dashboard, 50),
                updateDashboard,
                onQueryResults,
                setQueryStatuses
            );
            const filterActor = new DashboardFilterActor(
                FILTER_ACTOR_ID,
                loadResult.dashboard.globalFilters,
                loadResult.dashboard.interactions,
                loadResult.dashboard.fullyQualifiedName
            );

            // has state to load, bypass initial state loading when registering the actors
            const hasStateToLoad = props.context.has('state');

            // register the delegate which will register all the query actors in its initialize
            await engine.registerAll([delegate, filterActor], hasStateToLoad);

            // load the state and propagate the messages to the actors
            if (hasStateToLoad) {
                engine.waitActorsReady()
                    .then(
                        () => loadEngineState(engine, loadResult.dashboard, props.context.get('state'))
                    );
            }

            setEngine(Optional.some(engine));

            if (loadResult.isRestored) {
                // push a sentinel change so we know there are unsaved changes which will also trigger initial queries
                delegate.replace(loadResult.dashboard, ReplaceReason.RESTORED);
            } else {
                // force the delegate to publish a change with the initial arcql query
                delegate.onChanged();
            }

            props.onTabChange(
                loadResult.dashboard.label,
                // if it's a new dashboard default to edit mode
                loadResult.dashboard.isExisting ? props.path : props.path.extendTabId('edit')
            );

            // load trends as well but only if not embedded and dashboard fqn exists (i.e. saved)
            if (!props.path.isEmbed) {
                await loadTrends(loadResult.dashboard);
            }

            // if trend context is present upon first load, show that trend
            const trend = props.context.get('trend') as ArcTrend;
            if (trend) {
                loadTrend(engine, loadResult.dashboard, trend);
            }
        })();

        return () => controller.abort();
    }, []);

    // if new context given to tab, update the state appropriately
    useEffect(() => {
        if (props.context.has('trend')) {
            Optional.all([engine, dashboard])
                .forEach(([engine, dashboard]: [ArcEngine, ArcDashboard]) => {
                    const trend = props.context.get('trend') as ArcTrend;
                    loadTrend(engine, dashboard, trend);
                });
        }
        if (props.context.has('state')) {
            Optional.all([engine, dashboard])
                .forEach(([engine, dashboard]: [ArcEngine, ArcDashboard]) =>
                    loadEngineState(engine, dashboard, props.context.get('state'))
                );
        }
    }, [props.context]);

    // update the tab with any asset changes from labels to unsaved changes
    useEffect(() => {
        delegate.forEach(d => d.updateLocal(props.path.assetFqn));
        onTabChange();
    }, [dashboard]);

    // if this tab selection state is changed clear the widget selection to hide things like the edit box
    useEffect(() => {
        setSelectedWidget(Optional.none());
    }, [props.isSelected]);

    const delegate: Optional<DashboardBuilderDelegate> = engine.map(e => e.getActor(DELEGATE_ID));
    const filterActor: Optional<DashboardFilterActor> = engine.map(e => e.getActor(FILTER_ACTOR_ID));

    const loadTrend = (e: ArcEngine, d: ArcDashboard, t: ArcTrend) => {
        if (!d.widgets.has(t.widgetId)) {
            ServiceProvider.get(NotificationsService).publish(
                'DashboardBuilder.loadTrend',
                NotificationSeverity.ERROR,
                `Failed to load trend as it is referencing to an outdated widget '${t.widgetId}'.`
            );
            return;
        }

        // from widget ID, get the query ID via its config (assumption is that it must be chart widget)
        const queryId = (d.widgets.get(t.widgetId).widgetConfig() as ChartWidgetConfig).chart.queryId;

        // always set the trend panel and propagate the loaded trend
        setTrendPanelCtx(Optional.of(
            {
                widgetId: t.widgetId,
                label: t.arcQL.label,
                actor: e.getActor(DashboardBuilderDelegate.queryActorId(queryId)),
                loadedTrend: Optional.of(t)
            }
        ));
        setSidePanelView(SidePanelView.TRENDS);
    };

    const loadEngineState = async (e: ArcEngine, d: ArcDashboard, stateJson: JsonObject): Promise<void> => {
        const loadedDashboardState = await DashboardState.fromJSON(stateJson as DashboardStateJson);
        StateConverter.toArcEngineState(d, loadedDashboardState)
            .then(engineStateToLoad => e.loadState(engineStateToLoad));
    };

    const onSaveTrend = (currentCtx: TrendWidgetContext, trend: ArcTrend, dashboard: ArcDashboard) => {
        loadTrends(dashboard)
            .then(() => {
                    trendPanelFor(currentCtx);
                }
            );
        setTrendCreateCtx(Optional.none);
    };

    const loadTrends = async (dashboard: ArcDashboard) => {
        // if dashboard has no referencable fqn (ex: never saved), skip
        if (!dashboard.fqn) {
            return;
        }

        return ServiceProvider.get(TrendsService).listTrends(dashboard.fqn, false)
            .then(r => r.match(
                trends => setTrendsMap(Optional.of(new WidgetTrendsMap(trends))),
                error => {
                    ServiceProvider.get(NotificationsService).publish(
                        'DashboardBuilder', NotificationSeverity.ERROR, `Failed to load trends: ${error.prettyPrint()}`
                    );
                }
            ));
    };

    const updateDashboard = (dashboard: ArcDashboard) => {
        setDashboard(Optional.some(dashboard));

        // clear the selections if the widget was deleted
        setSelectedWidget((selectedWidget) =>
            selectedWidget.flatMap(selected =>
                dashboard.widgets.has(selected.id)
                    ? selectedWidget
                    : Optional.none()
            )
        );
    };

    const onQueryResults = (queryId: string, message: ResultMessage) => {
        setResultMessages((messages) => {
            const newMessages = new Map(messages);
            newMessages.set(queryId, message);

            return newMessages;
        });
    };

    const onApplyRawJson = (json: string): void => {
        delegate.forEach(d => {
            const jsonObj = JSON.parse(json);
            // Stuff the version back into the json so that the copy saved to local storage will have the
            // appropriate version
            jsonObj['version'] = dashboard.map(db => db.version).getOr(-1);
            d.replace(
                ArcDashboard.fromJSON(jsonObj),
                ReplaceReason.DEV
            );
        });
    };

    const undoActions = dashboard.filter(_ => !isViewMode)
        .map(() => new ToolbarActionsGroup([
            StandardActions.UNDO(delegate.map(d => !d.hasUndo).getOr(true)),
            StandardActions.REDO(delegate.map(d => !d.hasRedo).getOr(true)),
        ]))
        .array;

    const refreshActions = dashboard.filter(q => q.isExisting)
        .map(() => new ToolbarActionsSingle(
            StandardActions.REFRESH
        ))
        .array;

    const moreActions = dashboard.filter(_ => !isViewMode)
        .map(() => new ToolbarActionsMore([[
            StandardActions.CONFIG,
            new ToolbarAction('interactions', 'Interactions'),
            StandardActions.DEV
        ]]))
        .array;

    const saveActions = dashboard.filter(q => q.isExisting)
        .map<ToolbarActionsSection>(() => new ToolbarActionsMenu(
            StandardActions.SAVE,
            [[
                StandardActions.VERSIONS,
                StandardActions.SAVE_AS,
                StandardActions.ARCHIVE
            ]],
            true
        ))
        .getOrElse(() => new ToolbarActionsSingle(StandardActions.SAVE_AS));

    // only show share in view mode, and if there even is a dashboard
    const shareOptions = [StandardActions.SHARE];
    // stories only when logged in
    if (!props.path.isEmbed) {
        shareOptions.push(new ToolbarAction('stories', 'Stories'));
    }
    const shareActions = isViewMode && dashboard.isPresent ? [new ToolbarActionsGroup(shareOptions)] : [];

    // show trend action / highlighting
    const totalWidgetsTrending = trendsMap.map(t =>
        t.widgetCount
    ).getOr(0);

    const trendActions = isViewMode && totalWidgetsTrending > 0 ?
        [new ToolbarActionsGroup([TrendToolbarAction(totalWidgetsTrending)])] :
        [];

    const toolbarActions = props.path.isEmbed ? new ToolbarActions(shareActions) : new ToolbarActions([
        ...trendActions,
        ...undoActions,
        ...refreshActions,
        ...moreActions,
        ...shareActions,
        saveActions,
        new ToolbarActionsGroup([
            new ToolbarAction('edit', 'Edit'),
            new ToolbarAction('view', 'View')
        ])
    ]);

    const onTabChange = (path?: TabPath) => {
        Optional.all([delegate, dashboard]).forEach(
            ([delegate, dashboard]: [DashboardBuilderDelegate, ArcDashboard]) =>
                props.onTabChange(
                    dashboard.label,
                    path ? path : props.path,
                    delegate.hasChanges
                )
        );
    };

    const setDatasetsFor = (datasetsStateFunc: (datasets: Optional<ArcDataset[]>) => void) => {
        const fqns: FQN[] = filterActor.map(a => a.commonSources).getOr([]);
        const datasets: Promise<Either<ErrorResponse, ArcDataset>>[] =
            fqns.map(fqn => ServiceProvider.get(DatasetV2Service).describeDataset(fqn));
        Promise.all(datasets).then(results => {
            datasetsStateFunc(Optional.of(results.map(r => r.rightOrThrow())));
        });
    };

    const saveHandler = dashboard.map(d => new SaveHandler(d, props.onTabChange, Optional.of(canvasEl.current)));

    const createAndShareSession = async (d: ArcDashboard, f: DashboardFilterActor, e: ArcEngine) => {
        const dashboardState = StateConverter.toDashboardState(f, e.state);
        try {
            const createdSession = await ServiceProvider.get(SessionService).create(
                props.path.assetFqn.toString(),
                SessionType.DASHBOARD,
                d.id,
                dashboardState.toJSON()
            ).then(e => e.rightOrThrow());

            setShareContext(Optional.of({
                assetName: d.label,
                element: canvasEl.current,
                path: `${window.location.protocol}//${window.location.host}/#/sessions/${createdSession.id}`
            }));
        } catch (error) {
            ServiceProvider.get(NotificationsService).publish(
                'DashboardBuilder.createAndShareSession',
                NotificationSeverity.ERROR,
                'Failed to create session.'
            );
        }
    };

    const trendPanelFor = (newCtx: TrendWidgetContext) => {
        if (!newCtx) {
            setTrendPanelCtx(Optional.none);
            setSidePanelView(SidePanelView.NONE);
            return;
        }

        if (trendPanelCtx.isNone) {
            setTrendPanelCtx(Optional.of(newCtx));
            setSidePanelView(SidePanelView.TRENDS);
            return;
        }

        const existingCtx = trendPanelCtx.get();
        // if same widget, close trends panel
        if (existingCtx.widgetId === newCtx.widgetId) {
            setTrendPanelCtx(Optional.none);
            setSidePanelView(SidePanelView.NONE);
        } else {
            // else switch to trend panel view of another widget
            setTrendPanelCtx(Optional.of(newCtx));
            setSidePanelView(SidePanelView.TRENDS);
        }
    };

    const onToolbarAction = (action: ToolbarAction) => {
        switch (action.id) {
            case StandardActions.UNDO().id:
                delegate.forEach(d => d.undo());
                break;
            case StandardActions.REDO().id:
                delegate.forEach(d => d.redo());
                break;
            case StandardActions.DEV.id:
                setShowDevPanel(!showDevPanel);
                break;
            case StandardActions.SAVE.id:
                setSelectedWidget(Optional.none());
                setSaveMode(Optional.some(SaveMode.SAVE));
                break;
            case StandardActions.SAVE_AS.id:
                setSelectedWidget(Optional.none());
                setSaveMode(Optional.some(SaveMode.SAVE_AS));
                break;
            case StandardActions.VERSIONS.id:
                setSidePanelView(sidePanelView === SidePanelView.VERSIONS ? SidePanelView.NONE : SidePanelView.VERSIONS);
                break;
            case StandardActions.ARCHIVE.id:
                setShowArchive(!showArchive);
                break;
            case StandardActions.CONFIG.id:
                setSidePanelView(sidePanelView === SidePanelView.CONFIG ? SidePanelView.NONE : SidePanelView.CONFIG);
                break;
            case StandardActions.REFRESH.id:
                Optional.all([dashboard, delegate, filterActor])
                    .forEach(([dashboard, delegate, filterActor]: [ArcDashboard, DashboardBuilderDelegate, DashboardFilterActor]) =>
                        DashboardLoader.refresh(dashboard).then(
                            response => {
                                filterActor.clearSession();
                                delegate.replace(response.rightOrThrow(), ReplaceReason.REFRESH);
                            }
                        )
                    );
                break;
            case 'view':
                if (!isViewMode) {
                    // clear any selections when moving between states
                    setSelectedWidget(Optional.none());
                    onTabChange(props.path.extendTabId());

                    setShowDevPanel(false);
                }

                break;
            case 'edit':
                // Make sure we don't try to switch to edit mode when we're embedded
                if (isViewMode && !props.path.isEmbed) {
                    // clear any selections when moving between states
                    setSelectedWidget(Optional.none());
                    onTabChange(props.path.extendTabId('edit'));

                    // close out recording
                    setSidePanelView(SidePanelView.NONE);
                    setRecordingScene(Optional.none());
                }
                setTrendPanelCtx(Optional.none);
                break;
            case 'interactions':
                if (filterActor.map(a => a.datasets.length).getOr(0) === 0) {
                    ServiceProvider.get(NotificationsService).publish(
                        'dashboardBuilder', NotificationSeverity.WARNING, 'Add a query first before adding interactions.'
                    );
                    break;
                }

                setDatasetsFor(setDatasetsForInteractions);
                break;
            case StandardActions.SHARE.id:
                if (dashboard.isNone || engine.isNone) {
                    return;
                }

                if (!dashboard.get().isExisting || props.path.isEmbed) {
                    setShareContext(Optional.of({
                        assetName: dashboard.get().label,
                        element: canvasEl.current
                    }));
                } else {
                    Optional.all([dashboard, filterActor, engine])
                        .forEach(
                            ([dashboard, filterActor, engine]: [ArcDashboard, DashboardFilterActor, ArcEngine]) =>
                                createAndShareSession(dashboard, filterActor, engine)
                        );
                }
                break;
            case 'stories':
                setSidePanelView(sidePanelView === SidePanelView.STORIES ? SidePanelView.NONE : SidePanelView.STORIES);
                setRecordingScene(Optional.none());
                break;
            case 'trends':
                setTrendsButtonToggled(!trendsButtonToggled);
                break;
        }
    };

    const onToolbarChange = (assetLabel: string) => {
        delegate.forEach(d => d.changeInfo({
            label: assetLabel
        }));
    };

    const onCancelSave = () => {
        setSaveMode(Optional.none);
    };

    const onSave = (dashboard: ArcDashboard) => {
        delegate.forEach(d => d.replace(dashboard, ReplaceReason.SAVE));
        setSaveMode(Optional.none);
    };

    const onCancelArchive = () => {
        setShowArchive(false);
    };

    const onArchive = () => {
        props.onClose();
    };

    const onCloseShare = () => {
        setShareContext(Optional.none());
    };

    // start adding a query to the dashboard
    const onClickPalette = (widgetType: WidgetType) => {
        if (widgetType === WidgetType.CHART) {
            setAddingQueryTo(NEW_WIDGET_ID);
        } else {
            ServiceProvider.get(NotificationsService).publish(
                'dashboardBuilder', NotificationSeverity.INFO, 'Drag widget onto grid to add to dashboard.'
            );
        }
    };

    // start dragging a widget from the palette
    const onMouseDownPalette = (widgetType: WidgetType) => {
        setLastPaletteItem(Optional.some(widgetType));
    };

    const onCancelQuerySelection = () => {
        setAddingQueryTo(null);
    };

    const onAddPlaceholder = () => {
        setAddingQueryTo(null);

        // add a placeholder
        delegate.forEach(d => {
            d.createPlaceholderWidget(WidgetType.CHART, {
                x: 0,
                // since not dragged, find the first available empty row to add the widget
                y: dashboard.get().layouts.default.firstAvailableRow(3),
                w: 3,
                h: 3
            });
        });
    };

    const onSelectQueryToAttach = (selected: AssetSearchResult) => {
        setAddingQueryTo(null);

        if (addingQueryTo === NEW_WIDGET_ID) {
            // place a new widget with an attached query
            delegate.forEach(d =>
                d.createQueryWithWidget(selected.fqn, {
                    x: 0,
                    // since not dragged, find the first available empty row to add the widget
                    y: dashboard.get().layouts.default.firstAvailableRow(3),
                    w: 3,
                    h: 3
                })
            );
        } else {
            // attach the query to an existing widget
            delegate.forEach(d => d.createAndAttachQuery(selected.fqn, addingQueryTo));
        }
    };

    const onWidgetAction = (
        action: WidgetContainerAction, widgetMetadata: WidgetMetadataBound, buttonEl: HTMLElement, canvasEl: HTMLElement
    ) => {
        engine.forEach(engine => {
            const actor = (): Optional<ReferencedQueryActor> => {
                return Optional.ofType(widgetMetadata, QueriedWidgetMetadata)
                    .flatMap(metadata => metadata.queryId)
                    .map(queryId => engine.getActor(DashboardBuilderDelegate.queryActorId(queryId)));
            };

            switch (action) {
                case WidgetContainerAction.OPEN:
                    actor().map(actor => {
                        // base URL to the query being visualized
                        const queryPath = QueryPath.fromBase(actor.query.fqn);

                        if (isViewMode) {
                            // encode any filter state if in view mode
                            return actor.encodedFilters()
                                .map(facets => queryPath.with(SubPathKey.FACETS, [facets]))
                                .getOr(queryPath)
                                .toString();
                        } else {
                            // in edit mode, don't encode any state
                            return queryPath.toString();
                        }
                    }).forEach(url => {
                        ServiceProvider.get(InternalRouterService)
                            .route(
                                'dashboard',
                                TabPath.fromRaw(url),
                                new Map([[
                                    'skipLocalStorage', true
                                ]])
                            );
                    });

                    break;
                case WidgetContainerAction.DELETE:
                    delegate.forEach(d => d.deleteWidget(widgetMetadata));
                    break;
                case WidgetContainerAction.SHARE:
                    // can only share widgets with a query
                    if (!(widgetMetadata instanceof QueriedWidgetMetadata)) {
                        return;
                    }

                    setShareContext(Optional.some({
                        assetName: actor().map(actor => actor.arcql.label).getOr(widgetMetadata.id),
                        element: canvasEl,
                        // see if we have a query response for this widget that can be downloaded
                        queryResponse: widgetMetadata.queryId
                            .flatMap(queryId => Optional.of(
                                resultMessages.get(queryId)
                            ))
                            .map(message => message.response)
                            .nullable
                    }));
                    break;
                case WidgetContainerAction.TREND:

                    const newCtx: TrendWidgetContext = {
                        widgetId: widgetMetadata.id,
                        label: actor().get().arcql.label,
                        actor: actor().get(),
                        loadedTrend: Optional.none()
                    };

                    // if trends for this widget, show action menu
                    const widgetHasTrends = trendsMap.map(t =>
                        t.hasTrendsForWidget(widgetMetadata.id)
                    ).getOr(false);

                    if (widgetHasTrends) {
                        setTrendMenuAnchorEl(buttonEl);
                        setTrendActionMenuCtx(Optional.of(newCtx));
                        return;
                    } else {
                        trendPanelFor(newCtx);
                    }

                    break;
            }
        });
    };

    const showWidgetActions = (widgetId: string): boolean => {
        return trendActionMenuCtx.map(ctx => ctx.widgetId === widgetId).getOr(false);
    };

    const onAddGlobalFilter = () => {
        if (filterActor.map(a => a.datasets.length).getOr(0) === 0) {
            ServiceProvider.get(NotificationsService).publish(
                'dashboardBuilder', NotificationSeverity.WARNING, 'Add a query first before adding global filters.'
            );
            return;
        }

        // open the field picker with unique datasets used in queries
        setDatasetsFor(setDatasetsForGlobalFilter);
    };

    const onCancelAddGlobalFilter = () => {
        setDatasetsForGlobalFilter(Optional.none());
    };

    const onAddedGlobalFilter = (dataset: ArcDataset, column: Column) => {
        delegate.map(d => d.startGlobalFilter(dataset, column));
        setDatasetsForGlobalFilter(Optional.none());
    };

    const onSelectVersion = (version: AssetVersion) => {
        dashboard.forEach(
            d => ServiceProvider.get(MetadataService)
                .fetchDashboardVersion(d.fullyQualifiedName, version.version)
                .then(result => {
                    // loading previous version, clear local copy
                    delegate.forEach(d => d.replace(result.rightOrThrow(), ReplaceReason.VERSION));
                })
        );
    };

    const onCloseInteractions = () => {
        setDatasetsForInteractions(Optional.none());
    };

    const onRecord = (caption: Optional<StoryScene>) => {
        setRecordingScene(caption);
    };

    const closeTrendMenu = () => {
        setTrendActionMenuCtx(Optional.none);
        setTrendMenuAnchorEl(null);
    };

    const handleTrendAction = (ctx: TrendWidgetContext, action: TrendAction) => {
        switch (action.id) {
            case StandardTrendActions.VIEW.id:
                trendPanelFor(ctx);
                break;

            case StandardTrendActions.CREATE.id:
                setTrendCreateCtx(Optional.of(ctx));
                break;
        }
        closeTrendMenu();
    };

    return <S.Dashboard>
        <Toolbar
            fqn={props.path.assetFqn}
            assetLabel={dashboard.map(d => d.label).getOr(null)}
            assetLabelEditable={!isViewMode}
            onLabelChange={onToolbarChange}
            isCompactTitle={props.path.isEmbed}
            actions={toolbarActions}
            onAction={onToolbarAction}
            toggledActions={new Set([isViewMode ? 'view' : 'edit', trendsButtonToggled ? 'trends' : null])}
        />
        {
            isViewMode || <S.Palette
                onMouseDown={onMouseDownPalette}
                onClick={onClickPalette}
                onAddGlobalFilter={onAddGlobalFilter}
            />
        }
        <S.Body>
            <S.Content ref={canvasEl} className={
                StylesUtil.toClassName([
                    shareContext.map(() => 'sharing'),
                    recordingScene.map(() => 'recording')
                ])
            }>
                {
                    Optional.all([dashboard, filterActor])
                        .map(([dashboard, filterActor]: [ArcDashboard, DashboardFilterActor]) =>
                            <DashboardFilterPanel
                                dashboard={dashboard}
                                actor={filterActor}
                                isViewMode={isViewMode}
                                isEmbed={props.path.isEmbed}
                                freezeFilters={trendPanelCtx.isPresent}
                                onModifyGlobalFilter={
                                    (ordinal, change) => delegate.get().modifyGlobalFilter(ordinal, change)
                                }
                                onDeleteGlobalFilter={
                                    (ordinal) => delegate.get().deleteGlobalFilter(ordinal)
                                }
                            />
                        ).nullable
                }
                {
                    Optional.all([dashboard, engine, delegate, filterActor])
                        .map(([
                            dashboard, engine, delegate, filterActor
                        ]: [
                            ArcDashboard, ArcEngine, DashboardBuilderDelegate, DashboardFilterActor
                        ]) =>
                            <DashboardGrid
                                ref={viewportEl}
                                dashboard={dashboard}
                                engine={engine}
                                delegate={delegate}
                                filterActor={filterActor}

                                isViewMode={isViewMode}
                                isEmbed={props.path.isEmbed}
                                showWidgetActions={showWidgetActions}
                                recordingScene={recordingScene}

                                resultMessages={resultMessages}
                                queryStatuses={queryStatuses}
                                selectedWidget={selectedWidget}
                                lastPaletteItem={lastPaletteItem}

                                onSelectWidget={setSelectedWidget}
                                onWidgetAction={onWidgetAction}
                                onAddQueryTo={setAddingQueryTo}

                                highlightedTrendWidgets={
                                    Optional.bool(trendsButtonToggled)
                                        .flatMap(() => trendsMap)
                                        .map(t => new Set(t.widgets))
                                        .getOr(new Set())
                                }
                                viewingTrendWidget={
                                    trendPanelCtx.map(ctx => Optional.some(ctx.widgetId)).getOr(Optional.none())
                                }
                                trendsMap={trendsMap}
                            />
                        )
                        .nullable
                }
            </S.Content>
            {
                sidePanelView === SidePanelView.VERSIONS && dashboard.map(dashboard =>
                    <S.VersionsPanel
                        fqn={dashboard.fullyQualifiedName}
                        selected={dashboard.version}
                        onSelect={onSelectVersion}
                    />
                ).getOr(<></>)
            }
            {
                !isViewMode && sidePanelView === SidePanelView.CONFIG && dashboard.map(dashboard =>
                    <S.DashboardConfigPanel
                        dashboard={dashboard}
                        config={dashboard.config}
                        onChange={
                            config => delegate.forEach(d => d.modifyDashboardConfig(config))
                        }
                    />
                ).getOr(<></>)
            }
            {
                isViewMode && sidePanelView === SidePanelView.STORIES && dashboard.map(dashboard =>
                    <S.StoriesPanel
                        targetAsset={dashboard}
                        onRecord={onRecord}
                        viewportEl={viewportEl.current}
                    />
                ).getOr(<></>)
            }
            {
                trendActionMenuCtx.map(ctx =>
                    <TrendActionMenu
                        el={trendMenuAnchorEl}
                        actions={Object.values(StandardTrendActions)}
                        onAction={(action) => handleTrendAction(ctx, action)}
                        onCancel={closeTrendMenu}
                    />
                ).getOr(<></>)
            }
            {
                isViewMode && Optional.all([dashboard, filterActor, engine, trendCreateCtx])
                    .map((
                        [dashboard, filterActor, engine, trendCtx]:
                        [ArcDashboard, DashboardFilterActor, ArcEngine, TrendWidgetContext]
                    ) =>
                        <TrendCreator
                            dashboard={dashboard}
                            filterActor={filterActor}
                            engine={engine}
                            widgetContext={trendCtx}
                            onSave={t => onSaveTrend(trendCtx, t, dashboard)}
                            onCancel={() => setTrendCreateCtx(Optional.none)}
                        />
                    ).getOr(<></>)
            }
            {
                isViewMode && sidePanelView === SidePanelView.TRENDS && Optional.all([dashboard, filterActor, engine, trendsMap, trendPanelCtx])
                    .map((
                        [dashboard, filterActor, engine, trends, trendCtx]:
                        [ArcDashboard, DashboardFilterActor, ArcEngine, WidgetTrendsMap, TrendWidgetContext]
                    ) =>
                        <S.TrendsPanel
                            dashboard={dashboard}
                            filterActor={filterActor}
                            engine={engine}
                            trends={trends}
                            widgetContext={trendCtx}
                            handleTrendCreate={(ctx) => setTrendCreateCtx(Optional.of(ctx))}
                            // reload trends to get proper alert counts
                            onSaveAlert={() => loadTrends(dashboard)}
                            onClose={() => trendPanelFor(null)}
                        />
                    ).getOr(<></>)
            }
        </S.Body>
        {
            datasetsForGlobalFilter.map(
                (datasets) => <GlobalFilterFieldsPicker
                    datasets={datasets}
                    onClick={onAddedGlobalFilter}
                    onCancel={onCancelAddGlobalFilter}
                />
            ).nullable
        }
        {
            addingQueryTo && <AssetPicker
                title="Select a query to add."
                searchParams={AssetSearchParams.recentAll([AssetType.ARCQL])}
                onSelect={onSelectQueryToAttach}
                onCancel={onCancelQuerySelection}
                footer={
                    (addingQueryTo === NEW_WIDGET_ID) && <Button variant="contained" onClick={onAddPlaceholder}>
                        Insert as Placeholder
                    </Button>
                }
            />
        }
        {
            Optional.all([dashboard, saveMode]).map(([dashboard, mode]: [ArcDashboard, SaveMode]) =>
                mode === SaveMode.SAVE_AS ?
                    <SaveAsDialog
                        asset={dashboard}
                        fqn={props.path.assetFqn}
                        saveHandler={saveHandler.get()}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    /> :
                    <SaveDialog
                        fqn={props.path.assetFqn}
                        label={dashboard.label}
                        description={delegate.map(d => d.describeChanges()).getOr('')}
                        saveHandler={saveHandler.get()}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    />
            ).nullable
        }
        {
            shareContext.map(context => <ShareDialog {...context} onClose={onCloseShare}/>).nullable
        }
        {
            !showDevPanel || <S.DevPanel json={dashboard} onApply={onApplyRawJson}/>
        }
        {
            showArchive && dashboard.map(q =>
                <ArchiveDialog
                    asset={q}
                    onCancel={onCancelArchive}
                    onArchive={onArchive}
                />
            ).nullable
        }
        {
            Optional.all([dashboard, datasetsForInteractions, delegate])
                .map(([dashboard, datasets, delegate]: [ArcDashboard, ArcDataset[], DashboardBuilderDelegate]) =>
                    <InteractionsEditor
                        datasets={datasets}
                        interactions={dashboard.interactions}

                        onCreateColumnGroup={delegate.startColumnGroup.bind(delegate)}
                        onDeleteColumnGroup={delegate.deleteColumnGroup.bind(delegate)}
                        onAddColumnToGroup={delegate.addColumnToColumnGroup.bind(delegate)}
                        onDeleteColumnFromGroup={delegate.deleteColumnFromColumnGroup.bind(delegate)}
                        onCancel={onCloseInteractions}
                    />
                ).nullable
        }
        {
            filterActor.map(a =>
                <ExternalState
                    encodedState={props.path.getPart(4).getOr(null)}
                    isEmbed={props.path.isEmbed}
                    delegate={a}
                    // encoded state is only valid in view mode, in edit mode the path part will be "edit"
                    isEncodedState={e => e !== 'edit'}
                />
            ).nullable
        }
        {
            dashboard.filter(v => v.isExisting)
                .map(() => <WelcomeMat/>)
                .nullable
        }
    </S.Dashboard>;

};

type ShareContext = {
    assetName: string
    element: HTMLElement
    path?: string
    queryResponse?: ArcQLResponse
}
