import {FunctionComponent, useContext, useEffect, useState} from "react";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {isEqual} from "lodash";
import BrowseResultProps from "app/home/browse/BrowseResultProps";
import BrowseView from "app/home/browse/BrowseView";
import {ScopedSearchProject} from "app/home/ScopedSearchProject";
import {BrowseModalsVisibility} from "app/home/browse/BrowseModalsVisibility";
import {UserContext} from "app/UserContext";
import {ScopedSearchAccount} from "app/home/ScopedSearchAccount";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import { HyperGraphSearchParams } from "metadata/search/HyperGraphSearchParams";

/**
 * In Home tab, displays the results when in Browse section.
 */
export const BrowseResults: FunctionComponent<BrowseResultProps> = (props: BrowseResultProps) => {

    const [view, setView] = useState<BrowseView>(BrowseView.BROWSE_ALL);
    const [assetSearchParams, setAssetSearchParams] = useState<AssetSearchParams>(AssetSearchParams.browse());
    const [folderSearchParams, setFolderSearchParams] = useState<FolderSearchParams>(FolderSearchParams.default());
    const [accountSearchParams, setAccountSearchParams] = useState<AccountSearchParams>(AccountSearchParams.default());
    const [hyperGraphSearchParams, setHyperGraphSearchParams] = useState<HyperGraphSearchParams>(HyperGraphSearchParams.default());
    const [browseByProject, setBrowseByProject] = useState(false);
    const [modalsVisibility, setModalsVisibility] = useState<BrowseModalsVisibility>({
        showCreateProjectModal: false,
    });
    const userContext = useContext(UserContext);

    // update search params based on query and project context. Doing value equality checks helps prevent
    // new references being created form parent re-renders and therefore triggering same API calls.
    useEffect(() => {
        let newAssetParams: AssetSearchParams;
        if (props.scopedSearch instanceof ScopedSearchProject) {
            newAssetParams = AssetSearchParams.project(
                props.scopedSearch.project.getOr(null), props.query.getOr(null)
            );
        } else if (props.scopedSearch instanceof ScopedSearchAccount) {
            newAssetParams = AssetSearchParams.account(
                props.scopedSearch.account.getOr(null), props.query.getOr(null)
            );
        } else {
            newAssetParams = AssetSearchParams.browse(props.query.getOr(null), 15);
        }
        if (!isEqual(newAssetParams, assetSearchParams)) {
            setAssetSearchParams(newAssetParams);
        }
        const newFolderParams = FolderSearchParams.default(
            props.query.getOr(null),
            props.scopedSearch.account.getOr(null)
        );
        if (!isEqual(newFolderParams, folderSearchParams)) {
            setFolderSearchParams(newFolderParams);
        }

        const newAccountParams = AccountSearchParams.default(
            props.query.getOr(null)
        );

        if (!isEqual(newAccountParams, accountSearchParams)) {
            setAccountSearchParams(newAccountParams);
        }

        const newHyperGraphParams = HyperGraphSearchParams.default(
            props.query.getOr(null)
        );
        if (!isEqual(newHyperGraphParams, hyperGraphSearchParams)) {
            setHyperGraphSearchParams(newHyperGraphParams);
        }
    }, [props.scopedSearch, props.query]);

    // update view based on query and project context
    useEffect(() => {
        setView(props.scopedSearch.browseView(props.query.isPresent));
    }, [props.scopedSearch, props.query]);

    return view.render({
        userContext,
        browseByProject,
        setBrowseByProject,
        modalsVisibility,
        setModalsVisibility,
        folderSearchParams,
        assetSearchParams,
        accountSearchParams,
        hyperGraphSearchParams,
        ...props,
    });

};