import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {DatasetV2BuilderColumn, DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";

/**
 * Update a column in DatasetV2 state.
 */
export class DatasetV2StateColumnChange implements ArcMetadataChange<DatasetV2BuilderState> {

    constructor(
        public readonly column: DatasetV2BuilderColumn,
    ) {
    }

    apply(metadata: DatasetV2BuilderState): Tuple<DatasetV2BuilderState, ArcMetadataChange<DatasetV2BuilderState>> {
        const originalCol = metadata.rows.find(c => c.name === this.column.name);
        metadata = metadata.withUpdatedColumn(this.column);
        return Tuple.of(
            metadata,
            new DatasetV2StateColumnChange(originalCol)
        );
    }

    describe(): string {
        return `Modified column ${this.column.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}