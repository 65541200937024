import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {Chip} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { HyperGraphAnswer } from "app/components/search/HyperGraphAnswer";

export class S {
    
    static readonly ScopedHeader = {
        Header: styled.div`
            font-size: ${FontSizes.xLarge};
            padding-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        `,
        Owner: styled.span`
            color: ${Colors.textSecondary}
        `,
        OwnerWithBreadcrumb: styled.span`
            color: ${Colors.textSecondary};

            &:hover {
                cursor: pointer;
            }
        `,

        Delimiter: styled.span`
            color: ${Colors.textTertiary}
        `,
        PrivateChip: styled(Chip)`
            margin-left: 8px;
            color: rgba(0, 0, 0, 0.38);
        `
    };

    static readonly HeaderRow = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `;

    static readonly ResultsHeader = styled.div`
        font-size: ${FontSizes.large};
        width: 100%;
    `;

    static readonly BrowseByProjectSwitchLabel = styled(FormControlLabel)`
        white-space: nowrap;
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
    `;

    static readonly CreateProjectButton = styled(Button)`
        text-transform: none;
    `;

    static readonly HyperGraphAnswer = styled(HyperGraphAnswer)`
        border-bottom: 1px solid ${Colors.borderGrey};
    `;

}