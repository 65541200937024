import 'reactflow/dist/style.css';

import * as React from "react";
import {FunctionComponent} from "react";
import ReactFlow, {Background, BackgroundVariant} from "reactflow";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {FlowNodeType} from "app/query/hypergraph/nodes/FlowNodeType";
import {HyperGraphToFlow} from "app/query/hypergraph/HyperGraphToFlow";
import type {NodeChange} from "@reactflow/core/dist/esm/types/changes";

export type HyperGraphFlowHandlers = {
    onClickConnectStart(nodeId: string): void
    onNodesChange(changes: NodeChange[]): void
    onResetSelection(): void
    onNodeClick(nodeId: string, multiSelect: boolean, center: boolean): void
}

export type Props = HyperGraphFlowHandlers & {
    hyperGraph: HyperGraph
}

export const HyperGraphFlow: FunctionComponent<Props> = (props: Props) => {

    const [nodes, edges] = new HyperGraphToFlow(props.hyperGraph)
        .nodesAndEdges(props.hyperGraph.selectedNodeIds);

    return <ReactFlow
        fitView
        minZoom={0.25}
        maxZoom={1.25}
        nodeTypes={FlowNodeType.map()}
        proOptions={{hideAttribution: true}}
        selectionKeyCode={null}
        multiSelectionKeyCode={null}

        nodes={nodes}
        edges={edges}

        onNodesChange={props.onNodesChange}
        // massage a bit so we don't send react events to the handler, to decouple from the component a bit
        onNodeClick={(e, n) => props.onNodeClick(n.id, e.shiftKey, false)}
        onPaneClick={props.onResetSelection}
        onClickConnectStart={(e, p) => props.onClickConnectStart(p.nodeId)}
    >
        <Background
            color="#D4E2E8"
            variant={BackgroundVariant.Dots}
            size={2}
            gap={20}
        />
    </ReactFlow>;

};
