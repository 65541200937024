import {UserSubscriptionType} from "metadata/subscription/UserSubscriptionType";
import {AlertSubscriptionTableHandler} from "app/components/settings/notifications/AlertSubscriptionTableHandler";
import {UserSubscription} from "metadata/subscription/UserSubscription";
import {AlertSubscriptionMetadata} from "metadata/subscription/AlertSubscriptionMetadata";

/**
 * Factory to create the proper SubscriptionRowHandler for a given subscription type.
 */
export class SubscriptionRowHandlerFactory {

    /**
     * Create a SubscriptionTableHandler for the given subscription type.
     */
    static for(sub: UserSubscription) {
        switch (sub.type) {
            case UserSubscriptionType.ALERT:
                return new AlertSubscriptionTableHandler(sub, sub.metadata as AlertSubscriptionMetadata);
            default:
                throw new Error(`Unknown subscription type: ${sub.type}`);
        }
    }

}