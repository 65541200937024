import * as React from "react"
import { SVGProps } from "react"

const ExpressionFxIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={11}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            fillOpacity={0.6}
            d="M6.882 0c-2.688 0-2.99 3.002-3.146 3.854h-1.4l-.178.856h1.43C2.793 9.43 2.453 9.755 2.06 9.755 1.706 8.76 1.448 8.442.91 8.442.154 8.442 0 9.065 0 9.3c0 .53.44.976 1.295.976 3.17 0 3.423-2.6 3.92-5.566h1.462l.179-.856H5.345c.159-1.232.33-3.013.798-3.332.077.205.362 1.313 1.154 1.313.85 0 .921-.793.921-.887C8.218.664 8.058 0 6.882 0ZM8.72 5.566c-.438 0-.994.266-1.238.376l-.09.372c.068-.025.387-.124.525-.124.485 0 .566.223.8 1.016.056.187.131.463.221.83-.1.143-.426.517-.466.55-.106-.038-.437-.152-.712-.152-.629 0-.776.545-.776.79 0 .217.15.624.596.624.633 0 .813-.498 1.446-1.442.167.678.208 1.442 1.242 1.442.442 0 .97-.215 1.237-.33l.09-.372s-.3.123-.524.123c-.651 0-.645-.476-1.03-1.89.106-.146.385-.508.475-.567.092.032.422.148.71.148.216 0 .774-.14.774-.781 0-.222-.132-.613-.596-.613-.735 0-1.11 1.008-1.453 1.447-.186-.932-.382-1.447-1.231-1.447Z"
        />
    </svg>
);
export default ExpressionFxIcon;
