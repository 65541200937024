import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import {ReferenceQuery} from "metadata/dashboard/DashboardQueries";
import {AssetType} from "metadata/AssetType";
import {AssetIcon, StandardIconSize} from "app/components/icons/asset/AssetIcon";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import Tooltip from "@mui/material/Tooltip";
import {InlineEditorS as S} from "app/dashboard/widgets/InlineEditorS";


interface Props {
    queryMetadata: ReferenceQuery
    dashboard: ArcDashboard
}


/**
 * Inline editor display for chart widgets on dashboards.
 */
export const QueryMetadataDisplay: FunctionComponent<Props> = (props: Props) => {

    const [queryLabel, setQueryLabel] = useState<string>('Loading...');

    useEffect(() => {
        const controller = new AbortController();

        ServiceProvider
            .get(MetadataService)
            .fetchArcQL(props.queryMetadata.fqn, controller.signal, props.dashboard.fullyQualifiedName)
            .then(arcql => arcql.forEach(q => setQueryLabel(q.label)));

        return () => controller.abort();
    }, [props.queryMetadata.fqn]);


    return <S.Container>
        <S.LabelRow>
            <AssetIcon assetType={AssetType.ARCQL} size={StandardIconSize.SMALL}/>
            <Tooltip title={queryLabel} arrow>
                <S.Label>{queryLabel}</S.Label>
            </Tooltip>
        </S.LabelRow>
    </S.Container>;

};
