import {ColorPickerForm} from "app/dashboard/components/ColorPickerForm";
import {FormControl, FormHelperText, InputLabel} from "@mui/material";
import {DashboardConfigS} from "app/dashboard/components/DashboardConfigS";
import InputAdornment from "@mui/material/InputAdornment";
import Circle from "@mui/icons-material/Circle";
import LineWeight from "@mui/icons-material/LineWeight";
import React, {FunctionComponent, useState} from "react";
import {ColorResult} from "react-color";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {BorderVisibility} from "metadata/dashboard/widgets/config/BorderVisibility";

interface Props {
    className?: string
    config: ContainerConfigPart
    onChange: (config: ContainerConfigPart) => void
}

type ErrorState = {
    borderRadiusError: string
    borderWidthError: string
}

/**
 * Form for configuring border properties of a widget.
 */
export const BorderConfigForm: FunctionComponent<Props> = (props: Props) => {
    // State for tracking validation errors
    const [errors, setErrors] = useState<ErrorState>({
        borderRadiusError: '',
        borderWidthError: ''
    });

    // states to track user changes that are not yet committed due to validation errors
    const [borderRadius, setBorderRadius] = useState<number>(props.config.getValue('borderRadius'));
    const [borderWidth, setBorderWidth] = useState<number>(props.config.getValue('borderWidth'));

    const validatePixelChange = (value: number) => {
        if (value < 0 || value > 100) {
            return "Value should be between 0 and 100.";
        }
        return "";
    };

    const onBorderVisibilityChange = (value: string) => {
        const visibility: BorderVisibility = BorderVisibility.get(value);
        props.onChange(props.config.with({borderVisibility: visibility.borders}));
    };

    const onBorderColorChange = (color: ColorResult) => {
        props.onChange(props.config.with({borderColor: color.hex}));
    };


    const onBorderRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBorderRadius = parseInt(event.target.value, 10);
        setBorderRadius(newBorderRadius);
        const radiusError = validatePixelChange(newBorderRadius);
        setErrors({...errors, borderRadiusError: radiusError});

        if (radiusError) {
            return;
        }

        props.onChange(props.config.with({borderRadius: newBorderRadius}));
    };

    const onBorderWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBorderWidth = parseInt(event.target.value, 10);
        setBorderWidth(newBorderWidth);
        const widthError = validatePixelChange(newBorderWidth);
        setErrors({...errors, borderWidthError: widthError});

        if (widthError) {
            return;
        }

        props.onChange(props.config.with({borderWidth: newBorderWidth}));
    };


    const currentVisibility = BorderVisibility.fromSet(props.config.getValue('borderVisibility'));
    return <div className={props.className}>
        <FormControl fullWidth size="small" margin="dense">
            <InputLabel htmlFor="border-visibility">Border</InputLabel>
            <DashboardConfigS.Select
                id="border-visibility"
                variant="standard"
                margin="dense"
                value={currentVisibility.name}
                onChange={e => onBorderVisibilityChange(e.target.value as string)}
                // Render only the label when not active
                renderValue={() => currentVisibility.label}
                startAdornment={
                    <InputAdornment position="start">
                        {<currentVisibility.icon/>}
                    </InputAdornment>
                }
            >
                {BorderVisibility.enums<BorderVisibility>().map(v => (
                    <DashboardConfigS.MenuItem key={v.name} value={v.name}>
                        <ListItemIcon>
                            {<v.icon/>}
                        </ListItemIcon>
                        {v.label}
                    </DashboardConfigS.MenuItem>
                ))}
            </DashboardConfigS.Select>
        </FormControl>
        <ColorPickerForm
            pickerId={"border-color"}
            label={"Border Color"}
            currentColor={props.config.getValue('borderColor')}
            onChange={onBorderColorChange}
        />
        <FormControl fullWidth size="small" margin="dense" error={Boolean(errors.borderWidthError)}>
            <InputLabel htmlFor="border-width">Border Width</InputLabel>
            <DashboardConfigS.Input
                id="border-width"
                type="number"
                value={borderWidth}
                onChange={onBorderWidthChange}
                startAdornment={
                    <InputAdornment position="start">
                        <LineWeight/>
                    </InputAdornment>
                }
            />
            {errors.borderWidthError && <FormHelperText>{errors.borderWidthError}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth size="small" margin="dense" error={Boolean(errors.borderRadiusError)}>
            <InputLabel htmlFor="border-radius">Border Radius</InputLabel>
            <DashboardConfigS.Input
                id="border-radius"
                type="number"
                value={borderRadius}
                onChange={onBorderRadiusChange}
                startAdornment={
                    <InputAdornment position="start">
                        <Circle/>
                    </InputAdornment>
                }
            />
            {errors.borderRadiusError && <FormHelperText>{errors.borderRadiusError}</FormHelperText>}
        </FormControl>
    </div>;
};