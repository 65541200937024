import React, {FunctionComponent} from "react";
import List from "@mui/material/List";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import styled from "@emotion/styled";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {HyperGraphNodeSearchResult} from "app/components/search/HyperGraphNodeSearchResult";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavigableSearchResult } from "metadata/search/NavigableSearchResult";
import {ProcessFlowIcon} from "app/query/hypergraph/nodes/ProcessFlowIcon";
import {HyperGraphSearchResultsUtil, SortableNode} from "app/home/HyperGraphSearchResultsUtil";

type Props = {
    assets: AssetsSearchResponse
    onClick: (result: NavigableSearchResult) => void
}

/**
 * List display of hypergraph node search results
 */
export const HyperGraphNodeSearchResultsList: FunctionComponent<Props> = (props: Props) => {

    const nodes: SortableNode[] = HyperGraphSearchResultsUtil.sortSearchResultsBySimilarity(props.assets);

    const buildResultItem = (result: SortableNode) => {
        return <div key={result.node.id}>
            <ListItemButton
                onClick={() => props.onClick(new HyperGraphNodeSearchResult(result.query, result.node.id))}
            >
                <ListItemIcon>
                    <ProcessFlowIcon operation={result.node.request.operation} isCompact={true}/>
                </ListItemIcon>
                <ListItemText
                    primary={result.node.label(null)}
                    secondary={`in ${result.query.fullyQualifiedName} by @${result.query.createdBy}`}
                />
            </ListItemButton>
        </div>;
    };

    return <S.Container>
        <List>
            {
                nodes.map(node => {
                    return buildResultItem(node);
                })
            }
        </List>
    </S.Container>;
};

class S {
    static readonly Container = styled.div`
        max-height: 500px;
        overflow: auto;
    `;
}