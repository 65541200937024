import {ConnectionDetails} from "metadata/connections/ConnectionDetails";
import {SnowflakeConnectionDetails} from "metadata/connections/snowflake/SnowflakeConnectionDetails";
import {ConnectionType} from "metadata/connections/ConnectionType";
import {BigQueryConnectionDetails} from "metadata/connections/bigquery/BigQueryConnectionDetails";

/**
 * Factory for creating connection details.
 */
export class ConnectionDetailsFactory {

    /**
     * Generate connection details from a JSON object.
     */
    static fromJSON(type: ConnectionType, connectionJson: any): ConnectionDetails {
        switch (type) {
            case ConnectionType.SNOWFLAKE:
                return SnowflakeConnectionDetails.fromJSON(connectionJson);
            case ConnectionType.BIG_QUERY:
                return new BigQueryConnectionDetails();
            default:
                throw new Error(`Unsupported connection type for dataset: ${type}`);
        }
    }
}