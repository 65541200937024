import React, {FunctionComponent} from "react";
import {TestConnectionProps} from "app/components/settings/connections/ConnectionStepperHandler";
import {DialogS} from "app/DialogS";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

/**
 * Test BigQuery connection details.
 */
export const TestBigQueryConnection: FunctionComponent<TestConnectionProps> = (props: TestConnectionProps) => {

    return (
        <S.Container>
            <DialogS.Description>
                <strong>Important: </strong>
                {" You must have assigned "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://cloud.google.com/bigquery/docs/access-control#bigquery.dataViewer"}
                >
                    BigQuery Data Viewer
                </a>
                {" role (or equivalent permissibility) to the service account for proper connection access."}
            </DialogS.Description>
            <S.Button
                variant={'outlined'}
                onClick={props.onTestConnection}
            >
                Test Connection
            </S.Button>
        </S.Container>
    );
};

class S {
    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `;
    static readonly Button = styled(Button)`
        width: fit-content;
        text-transform: none;
    `;
}