import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {ValidateHypothesisNode} from "metadata/hypergraph/nodes/ValidateHypothesisNode";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {HyperGraphRequestSerialized} from "metadata/hypergraph/HyperGraphTypes";


export class ConsolidateAnalysis implements HyperGraphRequest {

    constructor(
        // common ancestor of all the analyses that will be consolidated
        public readonly parent: string,
        // the analyses nodes that will be consolidated
        public readonly ommers: string[],
        // up to number of additional segments to suggest
        public readonly n: number
    ) {
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.CONSOLIDATE_ANALYSIS;
    }

    get operation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.CONSOLIDATION;
    }

    label(graph: HyperGraph): string {
        return 'Consolidating multiple analysis.';
    }

    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            parent: this.parent,
            ommers: this.ommers,
            n: this.n
        };
    }

    static from(parent: HyperGraphNode, analysisNodes: ValidateHypothesisNode[], n: number): ConsolidateAnalysis {
        return new ConsolidateAnalysis(parent.id, analysisNodes.map(a => a.id), n);
    }

    static fromJSON(json: HyperGraphRequestSerialized): ConsolidateAnalysis {
        return new ConsolidateAnalysis(json.parent, json.ommers, json.n);
    }

}
