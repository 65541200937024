import {DatasetType} from "metadata/dataset/DatasetType";
import {RemoteDatasetConfig} from "metadata/dataset/RemoteDatasetConfig";


/** JSON representation of a Snowflake dataset configuration. */
export type SnowflakeDatasetConfigJson = {
    connectionId: string;
    database: string;
    schema: string;
    table: string;
};

/**
 * Configuration for a Snowflake dataset.
 */
export class SnowflakeDatasetConfig implements RemoteDatasetConfig {

    public readonly type = DatasetType.SNOWFLAKE;

    constructor(
        public readonly connectionId: string,
        public readonly database: string,
        public readonly schema: string,
        public readonly table: string
    ) {
    }

    static fromJSON(json: SnowflakeDatasetConfigJson): SnowflakeDatasetConfig {
        return new SnowflakeDatasetConfig(
            json.connectionId,
            json.database,
            json.schema,
            json.table
        );
    }

    get fullTableName(): string {
        return `${this.database}.${this.schema}.${this.table}`;
    }

}