import {useEffect, useState} from 'react';
import {FQN} from 'common/FQN';
import {Optional} from 'common/Optional';
import {ServiceProvider} from 'services/ServiceProvider';
import {MetadataService} from 'services/MetadataService';
import {UserService} from 'services/UserService';
import {NotificationSeverity, NotificationsService} from 'services/NotificationsService';
import {SlugHelper} from 'metadata/SlugHelper';
import {Either} from 'common/Either';
import {Folders} from "metadata/project/Folders";

type Props = {
    fqn: FQN;
    loggedInUserName: string;
    initialLabel?: string;
};

/**
 * Hook + utility for managing the state of an asset form.
 */
export const useAssetFormState = (props: Props) => {
    const [account, setAccount] = useState<string>(props.fqn.account);
    const [project, setProject] = useState<string>(props.fqn.folder);
    const [label, setLabel] = useState<string>(props.initialLabel || props.fqn.name);
    const [name, setName] = useState<string>(SlugHelper.convert(label, 80));
    const [accounts, setAccounts] = useState<Optional<string[]>>(Optional.none());
    const [folders, setFolders] = useState<Optional<Folders>>(Optional.none());
    const [saveErrors, setSaveErrors] = useState<Optional<Map<string, string>>>(Optional.none());

    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            await ServiceProvider.get(UserService)
                .listMemberships()
                .then(response => response.match(
                    memberships => setAccounts(Optional.some([props.loggedInUserName, ...memberships.map(m => m.name)])),
                    () => ServiceProvider.get(NotificationsService).publish(
                        'assetFormState', NotificationSeverity.ERROR, 'Could not load accounts.'
                    )
                ));
        })();

        return () => controller.abort();
    }, [props.fqn]);

    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            await listFolders(controller.signal);
        })();

        return () => controller.abort();
    }, [account]);

    const listFolders = (signal?: AbortSignal): Promise<Either<void, void>> => {
        return ServiceProvider.get(MetadataService)
            .listFolders(account, signal)
            .then(response => response.match(
                folders => {
                    setFolders(Optional.some(folders));
                    setProject(folders.all.length > 0 ? folders.all[0].name : '');
                },
                () => ServiceProvider.get(NotificationsService).publish(
                    'assetFormState', NotificationSeverity.ERROR, 'Could not load folders.'
                )
            ));
    };

    return {
        account,
        project,
        label,
        name,
        accounts,
        folders,
        saveErrors,
        setAccount,
        setProject,
        setLabel,
        setName,
        setSaveErrors
    };
};
