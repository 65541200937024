import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Optional} from "common/Optional";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import {isEqual} from "lodash";
import {UserContext} from "app/UserContext";
import {AssetType} from "metadata/AssetType";
import {FontSizes} from "app/components/StyleVariables";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";

interface Props {
    query: Optional<string>;
    onSearchAssets: (params: AssetSearchParams, controller: AbortController) => Promise<Either<ErrorResponse, AssetsSearchResponse>>;
    onAssetSelectSearchResult: (selected: NavigableSearchResult) => void;
}

/**
 * In Home tab, displays the results when in ForYou section.
 */
export const ForYouResults: FunctionComponent<Props> = (props: Props) => {
    const [trendingParams, setTrendingParams] = useState<AssetSearchParams>(
        AssetSearchParams.trending([AssetType.ARCQL, AssetType.DASHBOARD], 8)
    );
    const [recentParams, setRecentParams] = useState<AssetSearchParams>(
        AssetSearchParams.recent([AssetType.ARCQL, AssetType.DASHBOARD], 8)
    );

    useEffect(() => {
        const newTrendingParams = trendingParams.with({query: props.query.getOr(null)});
        if (!isEqual(newTrendingParams, trendingParams)) {
            setTrendingParams(newTrendingParams);
        }
        const newRecentParams = recentParams.with({query: props.query.getOr(null)});
        if (!isEqual(newRecentParams, recentParams)) {
            setRecentParams(newRecentParams);
        }
    }, [props.query]);


    return <S.Recommended>
        <S.ResultsHeader>
            For You
        </S.ResultsHeader>
        <UserContext.Consumer>{
            userContext => userContext.user.map(() =>
                <>
                    <S.MRU>
                        <S.RecommendedHeader>Recents</S.RecommendedHeader>
                        <AssetSearchResultsTable
                            searchParams={recentParams}
                            onSelect={props.onAssetSelectSearchResult}
                            rowType={SearchResultsRowType.SMALL_CARD}
                            onSearch={props.onSearchAssets}
                        />
                    </S.MRU>
                    <S.Trending>
                        <S.RecommendedHeader>Trending</S.RecommendedHeader>
                        <AssetSearchResultsTable
                            searchParams={trendingParams}
                            onSelect={props.onAssetSelectSearchResult}
                            rowType={SearchResultsRowType.SMALL_CARD}
                            onSearch={props.onSearchAssets}
                        />
                    </S.Trending>
                </>
            )
                .getOrElse(() =>
                    <S.Trending>
                        <S.RecommendedHeader>Trending</S.RecommendedHeader>
                        <AssetSearchResultsTable
                            searchParams={trendingParams}
                            onSelect={props.onAssetSelectSearchResult}
                            rowType={SearchResultsRowType.SMALL_CARD}
                            onSearch={props.onSearchAssets}
                        />
                    </S.Trending>
                )
        }</UserContext.Consumer>
    </S.Recommended>;
};

const S = {
    ResultsHeader: styled.div`
        font-size: ${FontSizes.large};
        width: 100%;
        padding-bottom: 16px;
    `,

    Recommended: styled.div`
        display: flex;
        flex-wrap: wrap;
    `,

    MRU: styled.div`
        padding: 10px 20px 0 0;
        flex: 1;
    `,

    Trending: styled.div`
        padding: 10px 0;
        flex: 1;
    `,

    RecommendedHeader: styled.div`
        font-size: ${FontSizes.medium};
        font-weight: 500;
        margin-bottom: 16px;
    `
};