import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL, FiltersPropertyKey} from "metadata/query/ArcQL";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";

export abstract class FilterChange implements ArcMetadataChange<ArcQL> {

    constructor(
        // if the change should be for aggregate filters or pre aggregate
        public readonly isAggregate: boolean
    ) {
    }

    protected filters(metadata: ArcQL): Tuple<FiltersPropertyKey, ArcQLFilters> {
        const metadataKey = ArcQL.filtersPropertyKey(this.isAggregate);
        return Tuple.of(metadataKey, metadata[metadataKey]);
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        return undefined;
    }

    describe(metadata: ArcQL): string {
        return "";
    }

    abstract changeType: ChangeType;
}