import {JsonObject} from "common/CommonTypes";
import {AssetType} from "metadata/AssetType";
import {FQN} from "common/FQN";
import { NavigableSearchResult } from "metadata/search/NavigableSearchResult";
import { AssetContext, AssetContextFactory } from "metadata/search/AssetContext";

export class AssetSearchResult implements NavigableSearchResult {

    static fromJSON(json: JsonObject): AssetSearchResult {
        return new AssetSearchResult(
            AssetType.get(json['type']),
            json['id'],
            json['name'],
            json['label'],
            json['fullyQualifiedName'],
            json['createdBy'],
            json['folderLabel'],
            json['folderOwner'],
            json['lastUpdatedOn'],
            json['previewUrl'],
            AssetContextFactory.fromJSON(json['context'])
        );
    }

    toJSON(): JsonObject {
        return {
            type: this.type,
            id: this.id,
            name: this.name,
            label: this.label,
            fullyQualifiedName: this.fullyQualifiedName,
            createdBy: this.createdBy,
            folderLabel: this.folderLabel,
            folderOwner: this.folderOwner,
            lastUpdatedOn: this.lastUpdatedOn,
            previewUrl: this.previewUrl
        };
    }

    constructor(
        public readonly type: AssetType,
        public readonly id: string,
        public readonly name: string,
        public readonly label: string,
        public readonly fullyQualifiedName: string,
        public readonly createdBy: string,
        public readonly folderLabel: string,
        public readonly folderOwner: string,
        public readonly lastUpdatedOn: string,
        public readonly previewUrl?: string,
        public readonly context?: AssetContext
    ) {
    }

    get fqn(): FQN {
        return FQN.parse(this.fullyQualifiedName);
    }

    /**
     *  Project path using recognizable label rather than dev name.
     */
    get projectPath(): string {
        return [this.folderOwner, this.folderLabel].join('/');
    }

    get path(): string {
        // our app points to a trend ID rather than a FQN due to bound dashboard sessions to 1 version of a trend
        if (this.fqn.type === AssetType.TREND) {
            return '/trends/' + this.id;
        }
        let path = '/' + this.fullyQualifiedName;
        // for datasets, the common action is to query
        if (this.fqn.type === AssetType.DATASET || this.fqn.type === AssetType.DATASET_V2) {
            path += '/query';
        }
        return path;
    }
}