import * as React from "react"
import {SVGProps} from "react"

const TimelineIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M7.896 6.5a.818.818 0 0 0-.805.83H5.455A2.467 2.467 0 0 0 3 9.783a2.467 2.467 0 0 0 2.455 2.454H7.09a.818.818 0 1 0 1.636 0h6.546a.82.82 0 0 0 1.577.317c.04-.1.06-.208.059-.316h1.636A2.467 2.467 0 0 0 21 9.784a2.467 2.467 0 0 0-2.455-2.455H16.91a.817.817 0 1 0-1.636 0H8.727a.818.818 0 0 0-.83-.829ZM5.455 8.966H7.09v1.636H5.455a.805.805 0 0 1-.819-.818c0-.462.356-.818.819-.818Zm11.454 0h1.636c.463 0 .819.356.819.818a.805.805 0 0 1-.819.818H16.91V8.966ZM3.805 14.682a.818.818 0 0 0-.805.83v.817c0 .895.742 1.637 1.636 1.637h3.136a.818.818 0 0 0 .267 0h3.824a.818.818 0 0 0 .266 0h3.824a.819.819 0 0 0 .267 0h3.144c.894 0 1.636-.742 1.636-1.637v-.818a.818.818 0 1 0-1.636 0v.818h-2.455v-.818a.817.817 0 1 0-1.636 0v.818h-2.455v-.818a.817.817 0 1 0-1.636 0v.818H8.727v-.818a.818.818 0 1 0-1.636 0v.818H4.636v-.818a.818.818 0 0 0-.83-.83Z"
        />
    </svg>
);
export default TimelineIcon;
