import styled from "@emotion/styled";

export const PanelS = {

    Fields: styled.div`
        width: 100%;
        
        &.horizontal {
            display: flex;
            padding: 0;
        }
    `,

    Add: styled.div`
        padding: 5px 18px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        opacity: 0.7;
        
        &:hover {
            opacity: 1;
        }
    `

};
