import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {AddGrouping} from "metadata/query/changes/AddGrouping";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Delete a grouping
 *
 * @author zuyezheng
 */
export class DeleteGrouping implements ArcMetadataChange<ArcQL> {

    constructor(
        // delete the grouping for the given field
        public readonly fieldName: string,
        public readonly dataset: ArcDataset
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const oldGrouping = metadata.groupings.find(this.fieldName).get();

        return new Tuple(
            metadata.with({
                groupings: metadata.groupings.without(this.fieldName),
                orderBys: metadata.orderBys.without(oldGrouping.left.projectedAs)
            }),
            new AddGrouping(
                oldGrouping.left,
                this.dataset,
                oldGrouping.right,
                metadata.orderBys.find(oldGrouping.left.projectedAs)
            )
        );
    }

    describe(metadata: ArcQL): string {
        const groupingLabel = metadata.groupings.get(this.fieldName).label(this.dataset);
        return `Deleted grouping ${groupingLabel}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}