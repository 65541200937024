import {AssetType} from "metadata/AssetType";
import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {UserService} from "services/UserService";
import {LocalStorageService} from "services/LocalStorageService";
import {TabService} from "services/TabService";


/**
 * Encapsulate the logic for generating new links for when defaults are stateful such as requiring the current user.
 *
 * @author zuyezheng
 */
export class LinkService {

    private readonly apiHost: string;
    private readonly countByType: Map<AssetType, number>;

    constructor(apiHost: string) {
        this.apiHost = apiHost;
        this.countByType = new Map([
            [AssetType.ARCQL, 0],
            [AssetType.DASHBOARD, 0],
            [AssetType.FILTER_SET, 0],
            [AssetType.DATASET_V2, 0]
        ]);
    }

    async query(): Promise<string> {
        return this.defaultAccount()
            .then(account => this.nextAvailable(account, 'private', AssetType.ARCQL));
    }

    datasetQuery(datasetFqn: FQN): string {
        return `/${datasetFqn.toString()}/query`;
    }

    async dashboard(): Promise<string> {
        return this.defaultAccount()
            .then(account => this.nextAvailable(account, 'private', AssetType.DASHBOARD));
    }

    async filterset(): Promise<string> {
        return this.defaultAccount()
            .then(account => this.nextAvailable(account, 'private', AssetType.FILTER_SET));
    }

    async datasetV2(account: string): Promise<string> {
        return this.nextAvailable(account, 'private', AssetType.DATASET_V2);
    }

    incrementCounter(type: AssetType): number {
        const c = this.countByType.get(type);
        this.countByType.set(type, c + 1);
        return c + 1;
    }

    nextAvailable(account: string, folder: string, type: AssetType): string {
        const localStorageService = ServiceProvider.get(LocalStorageService);
        const tabService = ServiceProvider.get(TabService);

        // keep trying until we find a url not in use
        for (let i=0; i<100; i++) {
            const nextPossibleFqn = new FQN(
                account, folder, type, `new ${this.incrementCounter(type)}`
            );

            if (localStorageService.hasAsset(nextPossibleFqn)) {
                continue;
            }
            if (tabService.getByFqn(nextPossibleFqn).isPresent) {
                continue;
            }

            // found one
            return '/' + nextPossibleFqn.toString();
        }

        // if we went through 100 iterations something went really bad
        throw new Error(`Could not find available url for ${type.label}`);
    }

    embedUrl(fqn: FQN, params: Map<string, string | number>): string {
        const base = `${this.apiHost}/services/embed?fqn=${fqn.toString()}`;
        
        if (params.size === 0) {
            return base;
        } else {
            const paramsString = Array.from(params).map(([key, value]) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }).join('&');

            return `${base}&${paramsString}`;
        }
    }

    private defaultAccount(): Promise<string> {
        return ServiceProvider.get(UserService)
            .getCurrentUser()
            .then(r => r.rightOrThrow().username);
    }

}