import md5 from "md5";
import {ArcQLSource} from "metadata/query/ArcQLSource";
import {ArcQLFields} from "metadata/query/ArcQLFields";
import {ArcQLGroupings, ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {ArcQLOrderBys} from "metadata/query/ArcQLOrderBy";
import {ArcQLVisualizations} from "metadata/query/ArcQLVisualizations";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {ArcQLQueryProps} from "metadata/query/ArcQLQueryProps";
import {ResultFormatter} from "metadata/query/ResultFormatter";
import {JsonObject} from "common/CommonTypes";
import {FQN} from "common/FQN";
import {Asset, AssetMetadataProps, AssetProps} from "metadata/Asset";
import {Optional} from "common/Optional";
import {DateGrain} from "metadata/query/DateGrain";
import {DateGrouping} from "metadata/query/DateGrouping";
import {DetailDateField} from "metadata/query/DetailDateField";
import {ArcQLFieldType, FieldSuperType} from "metadata/query/ArcQLFieldType";
import {ColumnField} from "metadata/query/ArcQLField";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {Column} from "metadata/Column";
import {References} from "metadata/References";
import {UnhydratedReferences} from "metadata/UnhydratedReferences";
import {AssetType} from "metadata/AssetType";
import {ReferencingFilterClause} from "metadata/query/filterclause/ReferencingFilterClause";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {HyperGraphDeserializer} from "metadata/hypergraph/HyperGraphDeserializer";

// all mutable props
export type ArcQLProps = AssetProps & AssetMetadataProps & ArcQLQueryProps & {
    source: ArcQLSource
    isFork: boolean,
    hyperGraph: JsonObject
};

export type FiltersPropertyKey = 'filters' | 'aggregateFilters';

/**
 * Mutable definition of our ArcQL spec supporting serialization.
 */
export class ArcQL implements Asset {

    // all queried truncated dates will be this format regardless of grain
    static DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss[.SSS]';

    static DEFAULT_LIMIT = 500;

    static fromJSON(json: JsonObject): ArcQL {
        const references = References.fromJSON(json[References.JSON_KEY]);
        return new ArcQL(
            ArcQLSource.fromJSON(json['source']),
            json['id'],
            json['name'],
            json['label'],
            json['description'],
            ArcQLFields.fromJSON(json['fields']),
            ArcQLFilters.fromJSON(json['filters'], false, references),
            ArcQLFilters.fromJSON(json['aggregateFilters'], true, references),
            ArcQLGroupings.fromJSON(json['groupings']),
            ArcQLOrderBys.fromJSON(json['orderBys']),
            json['limit'],
            ArcQLVisualizations.fromJSON(json['visualizations']),
            json['version'],
            json['fullyQualifiedName'] && FQN.parse(json['fullyQualifiedName']),
            json['folderId'],
            json['parentId'],
            references,
            json['isFork'],
            json['hyperGraph']
        );
    }

    static minimal(source: ArcQLSource): ArcQL {
        return new ArcQL(source);
    }

    /**
     * Property key for aggregate filters vs non aggregate filters
     */
    static filtersPropertyKey(isAggregate: boolean): FiltersPropertyKey {
        return isAggregate ? 'aggregateFilters' : 'filters';
    }

    /**
     * Default filter expression. All filter clauses should be AND-ed together.
     */
    static defaultFilterExpression(filters: ArcQLFilters): string {
        return filters.clauses.map((_, ordinal) => (ordinal + 1).toString()).join(' AND ');
    }

    private _hash: string;

    private constructor(
        public readonly source: ArcQLSource,
        // id should default to null until saved as it's the indicator for a new vs existing asset
        public readonly id: string = null,
        // these should default to null so we know when to apply defaults vs user explicitly set an empty string
        public readonly name: string = null,
        public readonly label: string = null,
        public readonly description: string = null,
        public readonly fields: ArcQLFields = ArcQLFields.empty(),
        public readonly filters: ArcQLFilters = ArcQLFilters.empty(false),
        public readonly aggregateFilters: ArcQLFilters = ArcQLFilters.empty(true),
        public readonly groupings: ArcQLGroupings = ArcQLGroupings.empty(),
        public readonly orderBys: ArcQLOrderBys = ArcQLOrderBys.empty(),
        public readonly limit: number = ArcQL.DEFAULT_LIMIT,
        public readonly visualizations: ArcQLVisualizations = ArcQLVisualizations.default(),
        public readonly version: number = -1,
        public readonly fullyQualifiedName: FQN = null,
        public readonly folderId: string = null,
        public readonly parentId: string = null,
        public readonly references: References = null,
        public readonly isFork: boolean = false,
        // hypergraph needs to be "hydrated" with a dataset so can only store it serialized until requested with dataset
        public readonly hyperGraphJson: JsonObject = null
    ) {
    }

    get assetType(): AssetType {
        return AssetType.ARCQL;
    }

    /**
     * TODO ZZ: deprecate fullyQualifiedName.
     */
    get fqn(): FQN {
        return this.fullyQualifiedName;
    }

    /**
     * Build a unique md5 hash of the query portions.
     */
    hash(): string {
        if (this._hash == null) {
            this._hash = md5(JSON.stringify(
                // exclude any noop filters which we know won't change the query
                this.with({filters: this.filters.excludingAlls()})
                    // only serialize the query parts for the hash
                    .toQueryPartsJSON()
            ));
        }

        return this._hash;
    }

    formatter(): ResultFormatter {
        return new ResultFormatter();
    }

    /**
     * If other is a version of the current query.
     */
    isVersionOf(other: ArcQL): boolean {
        return this.folderId === other.folderId && this.name === other.name;
    }


    filtersFor(isAggregate: boolean): ArcQLFilters {
        return this[ArcQL.filtersPropertyKey(isAggregate)];
    }

    /**
     * If a grouped or a detail query.
     */
    isGrouped(): boolean {
        // if no fields, we assume it's grouped since a count(*) will be defaulted
        if (this.fields.size === 0) {
            return true;
        }

        // otherwise consider grouped if supertype of all fields is not DETAIL
        return this.fields.superType() !== FieldSuperType.DETAIL;
    }

    /**
     * Has the query already been saved?
     */
    get isExisting(): boolean {
        // If it is a fork we retain id to keep version history, but it should not be classified as an "existing" query.
        return this.id != null && !this.isFork;
    }

    /**
     * If this is a default query (ignoring filters) which is exactly count(*) group by all.
     */
    isDefaultQuery(): boolean {
        return this.groupings.isAll
            && this.fields.size === 1
            && this.fields.first instanceof ColumnField
            && this.fields.first.isStar;
    }

    /**
     * Return the first date field starting with groupings and moving to detail fields as a tuple of:
     * [projected name, date grain, grouping or detail field]
     */
    firstDate(): [string, DateGrain, DateGrouping | DetailDateField] {
        const dateGrouping = this.groupings.fields.find(g => g.type === ArcQLGroupingType.DATE) as DateGrouping;
        if (dateGrouping != null) {
            return [dateGrouping.projectedAs, dateGrouping.grain, dateGrouping];
        }

        const detailDate = this.fields.fields.find(f => f.type === ArcQLFieldType.DETAIL_DATE) as DetailDateField;
        if (detailDate != null) {
            return [detailDate.as, detailDate.grain, detailDate];
        }

        return null;
    }

    /**
     * Hydrate the serialized HyperGraph with a given dataset.
     */
    hyperGraph(dataset: ArcDataset): Optional<HyperGraph> {
        return Optional.of(this.hyperGraphJson)
            .map(json => HyperGraphDeserializer.toGraph(json, dataset));
    }

    /**
     * Shallow optionally replace parts of the query.
     */
    with(props: Partial<ArcQLProps>): ArcQL {
        return new ArcQL(
            props.source ?? this.source,
            props.id ?? this.id,
            props.name ?? this.name,
            props.label ?? this.label,
            props.description ?? this.description,
            props.fields ?? this.fields,
            props.filters ?? this.filters,
            props.aggregateFilters ?? this.aggregateFilters,
            props.groupings ?? this.groupings,
            props.orderBys ?? this.orderBys,
            // if no limit provided use existing, also allow for a null imit to clear the limit
            props.limit === undefined ? this.limit : props.limit,
            props.visualizations ?? this.visualizations,
            this.version,
            this.fullyQualifiedName,
            this.folderId,
            this.parentId,
            props.references ?? this.references,
            props.isFork ?? this.isFork,
            props.hyperGraph ?? this.hyperGraphJson
        );
    }

    /**
     * Extend this query with the specified content parts retaining things, like  id, name, label, etc.
     */
    withContent(other: ArcQL, queryParts: boolean = true, vizParts: boolean = true): ArcQL {
        const partsToKeep: Partial<ArcQLProps> = {};
        if (queryParts) {
            Object.assign(partsToKeep, {
                'source': other.source,
                'fields': other.fields,
                'filters': other.filters,
                'aggregateFilters': other.aggregateFilters,
                'groupings': other.groupings,
                'orderBys': other.orderBys,
                'limit': other.limit
            });
        }
        if (vizParts) {
            Object.assign(partsToKeep, {
                'visualizations': other.visualizations
            });
        }

        return this.with(partsToKeep);
    }

    /**
     * Attempt to describe the query in plain english.
     */
    describe(dataset: ArcDataset): string {
        const fieldsDescription = (() => {
            return ` queries ${this.fields.map(f => f.as).join(', ')}`;
        })();

        const groupingsDescription = (() => {
            if (!this.isGrouped()) {
                return '';
            }

            return ' grouped by ' + (
                this.groupings.isAll ?
                    'all' :
                    this.groupings.map(g => g.label(dataset)).join(', ')
            );
        })();

        const filtersDescription = (() => {
            const filters = [...this.filters.clauses, ...this.aggregateFilters.clauses];

            if (filters.length === 0) {
                return '';
            }

            return filters.map(
                f => f.fieldsLabel(dataset) + ' ' + f.description(true)
            ).join(', ');
        })();

        return this.label + fieldsDescription + groupingsDescription + filtersDescription;
    }

    /**
     * Return all unique dataset columns used in this query.
     */
    columns(dataset: ArcDataset): Column[] {
        const columns = new Set(
            [
                ...this.groupings.fields.map(g => g.type === ArcQLGroupingType.EXPRESSION ? null : g.field),
                ...this.fields.fields.map(f => Optional.ofType(f, ColumnField).map(cF => cF.field).nullable),
                ...this.filters.clauses.flatMap(f => f.fields)
            ]
                // filter out nulls since things like expressions and aggregates might not have a field
                .filter(v => v != null)
        );

        return Array.from(columns)
            // just make sure all the columns used are actually in the dataset
            .flatMap(columnName => dataset.getPossible(columnName).array);
    }

    toJSON(key?: string, visualizations: boolean = true, metadata: boolean = true): JsonObject {
        const parts: object[] = [{
            'source': this.source,
            'fields': this.fields,
            'filters': this.filters,
            'aggregateFilters': this.aggregateFilters,
            'groupings': this.groupings,
            'orderBys': this.orderBys,
            'limit': this.limit
        }];

        if (visualizations) {
            parts.push({'visualizations': this.visualizations});
        }

        if (metadata) {
            parts.push({
                'id': this.id,
                'name': this.name,
                'label': this.label,
                'description': this.description,
                'hyperGraph': this.hyperGraphJson
            });
        }

        return Object.assign(parts[0], ...parts.slice(1));
    }

    toQueryPartsJSON(key?: string): JsonObject {
        return this.toJSON(key, false, false);
    }

    /**
     * The JSON representation of ArcQL for proper forking. This is different from toJSON() in that it includes
     * references and sets isFork to true. Moreover, it ensures all entries are classless objects.
     */
    toForkingJSON(): JsonObject {
        const arcqlJson = this.toJSON();
        // we serialize is fork for cloning, but it is actually never read by the server since the server
        // determines forks by id
        arcqlJson['isFork'] = true;
        arcqlJson['references'] = this.references.toJSON();
        return JSON.parse(JSON.stringify(arcqlJson));
    }

    toLocalStorageJSON(fqn: FQN): JsonObject {
        return {
            ...this.toJSON(),
            // stuff we normally wouldn't pass to the server for saving
            fullyQualifiedName: fqn.toString(),
            version: this.version,
            folderId: this.folderId,
            parentId: this.parentId,
            // ArcQLFilters may constantly change due to it being WIP and unsaved, hence store information in local to later hydrate refs
            [UnhydratedReferences.JSON_KEY]: this.unhydratedReferences()
        };
    }

    equals(other: ArcQL): boolean {
        return this.hash() === other.hash();
    }


    /**
     * Grabs the latest references unhydrated (i.e. just the FQNs) for arcQL such as FilterSet assets in FilterSet filter clauses.
     */
    private unhydratedReferences(): UnhydratedReferences {
        return new UnhydratedReferences(
            this.filters.clauses.filter(c => c.type.hasAssetRefs)
                .map((c: ReferencingFilterClause) => c.fullyQualifiedName)
        );
    }

}
