import * as React from "react";
import {FunctionComponent, ReactNode} from "react";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import {ShareDownloader} from "app/components/share/ShareDownloader";
import {UrlLink} from "app/components/decoration/UrlLink";

type Props = {
    // name of what's being shared
    assetName: string
    // dom element being shared such as a chart or dashboard
    element: HTMLElement
    // if sharing a top level asset, a URL that can be shared
    path?: string
    // if sharing a single query, the query response that can be downloaded
    queryResponse?: ArcQLResponse

    onClose: () => void
}

/**
 * Dialog of all things sharing.
 *
 * @author zuyezheng
 */
export const ShareDialog: FunctionComponent<Props> = (props: Props) => {

    const shareLinkEls = (): ReactNode => {
        if (props.path == null) {
            return <></>;
        }

        return <S.Section>
            <S.SectionTitle>Url</S.SectionTitle>
            <UrlLink path={props.path} />
        </S.Section>;
    };

    return <Dialog
        open={true}
        maxWidth="md"
        onClose={props.onClose}
    >
        <DialogTitle>Share {props.assetName}</DialogTitle>
        <DialogContent dividers>
            <S.Section>
                <S.SectionTitle>Download</S.SectionTitle>
                <ButtonGroup variant='outlined'>
                    <Button
                        onClick={() => ShareDownloader.downloadImage(props.assetName, props.element)}
                    >Image</Button>
                    { props.queryResponse && <Button
                        onClick={() => ShareDownloader.downloadCsv(props.assetName, props.queryResponse)}
                    >CSV</Button> }
                </ButtonGroup>
            </S.Section>
            {shareLinkEls()}
        </DialogContent>
    </Dialog>;

};

const S = {

    Section: styled.div`
        padding-bottom: 10px;
    `,

    SectionTitle: styled.div`
        font-weight: 500;
        padding: 4px 0 8px;
    `

};
