import styled from "@emotion/styled";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Colors, FontSizes} from "app/components/StyleVariables";

export class DialogS {

    static readonly Title = styled(DialogTitle)`
        padding: 16px 40px;
    `;

    static readonly Content = styled(DialogContent)`
        padding: 16px 40px;
    `;

    static readonly Body = styled.div`
    `;

    static readonly Actions = styled(DialogActions)`
        padding: 16px 40px;
    `;

    static readonly InputRow = styled.div`
        margin-bottom: 16px;
    `;

    static readonly SelectorRow = styled.div`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 16px;
    `;

    static readonly URL = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static readonly FullUrl = styled.p`
        color: #888;
        margin: 4px 0;
        padding: 0;
        font-size: ${FontSizes.small};
        letter-spacing: 0.25px;
    `;

    static readonly SlugText = styled.span`
        color: #000;
        font-weight: 500;
    `;

    static readonly Description = styled.div`
        
        ul {
            list-style-type: disc;
            padding-left: 20px;
        }

        li {
            margin-bottom: 4px;
        }

        a {
            color: ${Colors.inputBlue};
            text-decoration: none;
        }
    `;
}