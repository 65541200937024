import * as React from "react";
import {FunctionComponent, useEffect} from "react";
import {SettingsTabSection} from "app/components/settings/SettingsTabSection";
import {UserSubscription} from "metadata/subscription/UserSubscription";
import {ServiceProvider} from "services/ServiceProvider";
import {Optional} from "common/Optional";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {LoadingMask} from "app/components/decoration/LoadingMask";
import styled from "@emotion/styled";
import {useHistory} from "react-router-dom";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {TableResultAction} from "app/components/TableResultAction";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {SubscriptionRowHandlerFactory} from "app/components/settings/notifications/SubscriptionRowHandlerFactory";
import {SubscriptionService} from "services/SubscriptionService";
import {MetadataService} from "services/MetadataService";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetType} from "metadata/AssetType";
import {HyperArcUser} from "metadata/HyperArcUser";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {UserSubscriptionType} from "metadata/subscription/UserSubscriptionType";
import {AlertSubscriptionMetadata} from "metadata/subscription/AlertSubscriptionMetadata";
import {
    StandardSubscriptionActions,
    SubscriptionsTable
} from "app/components/settings/notifications/SubscriptionsTable";
import {TrendsTable} from "app/components/settings/notifications/TrendsTable";
import { AccountResult } from "metadata/account/AccountResult";

interface Props {
    user: HyperArcUser
}

/**
 * Notifications settings tab content.
 */
export const Notifications: FunctionComponent<Props> = (props: Props) => {

    const history = useHistory();

    const [subscriptions, setSubscriptions] = React.useState<Optional<UserSubscription[]>>(Optional.none);
    const [trendSearchResults, setTrendSearchResults] = React.useState<Optional<AssetSearchResult[]>>(Optional.none);

    useEffect(
        () => {
            loadSubscriptions();
            loadUserTrends();
        }, []);

    const loadSubscriptions = () => {
        ServiceProvider.get(SubscriptionService)
            .listSubscriptions()
            .then(response => response.match(
                subscriptions => setSubscriptions(Optional.some(subscriptions)),
                () => ServiceProvider.get(NotificationsService).publish(
                    'Notifications', NotificationSeverity.ERROR, 'Could not load your subscriptions.'
                )
            ));
    };

    const loadUserTrends = () => {
        ServiceProvider.get(MetadataService)
            .assetSearch(new AssetSearchParams(
                // search for all trends of a user
                1000,
                null,
                [AssetType.TREND],
                null,
                props.user.username
            )).then(r => r.match(
            assets => setTrendSearchResults(Optional.some(assets.results)),
            error => ServiceProvider.get(NotificationsService).publish(
                'Notifications', NotificationSeverity.ERROR, `Could not load trends: ${error.prettyPrint()}`
            )
        ));
    };

    const archiveSubscription = async (subscriptionId: string) => {
        const response = await ServiceProvider.get(SubscriptionService)
            .archiveSubscription(subscriptionId);
        return response.match(
            subscription => ServiceProvider.get(NotificationsService).publish(
                'Notifications', NotificationSeverity.SUCCESS, `Subscription ${subscription.label} deleted.`
            ),
            (error) => ServiceProvider.get(NotificationsService).publish(
                'Notifications', NotificationSeverity.ERROR, `Could not delete subscription: ${error.prettyPrint()}`
            )
        );
    };

    const onSubscriptionSelect = (sub: UserSubscription) => {
        const handler = SubscriptionRowHandlerFactory.for(sub);
        history.push(handler.appPathToSubscription());
    };

    const onSubscriptionAction = (sub: UserSubscription, action: TableResultAction) => {
        const handler = SubscriptionRowHandlerFactory.for(sub);
        switch (action.id) {
            case StandardSubscriptionActions.DISABLE.id:
                handler.unsubscribe().then(postSubscription);
                break;
            case StandardSubscriptionActions.ENABLE.id:
                handler.subscribe().then(postSubscription);
                break;
            case StandardSubscriptionActions.DELETE.id:
                archiveSubscription(sub.id).then(loadSubscriptions);
                break;
        }
    };

    const postSubscription = (resp: Either<ErrorResponse, UserSubscription>) => {
        resp.match(
            subscription => {
                ServiceProvider.get(NotificationsService).publish(
                    'Notifications', NotificationSeverity.SUCCESS, `Subscription ${subscription.label} updated.`
                );
                // reload subscriptions
                loadSubscriptions();
            },
            (error) => ServiceProvider.get(NotificationsService).publish(
                'Notifications', NotificationSeverity.ERROR, `Could not update the subscription: ${error.prettyPrint()}`
            )
        );
    };

    const onAccountSelect = (account: string) => {
        history.push(AccountResult.path(account));
    };

    const onTrendSelect = (trendResult: AssetSearchResult) => {
        history.push(trendResult.path);
    };

    return (
        <SettingsTabSection title={'Trends & Alerts'}>
            {
                Optional.all([subscriptions, trendSearchResults]).map(
                    ([subs, trends]: [UserSubscription[], AssetSearchResult[]]) => {
                        // from subscriptions, create set indicating which trends has alert subs
                        const trendsWithSubs = new Set(
                            subs
                                .filter(sub => sub.type === UserSubscriptionType.ALERT)
                                .map(sub => (sub.metadata as AlertSubscriptionMetadata).trendId)
                        );

                        // then filter trends to get those without alert subs
                        const trendsWithoutSubs = trends.filter(
                            trend => !trendsWithSubs.has(trend.id)
                        );

                        return <S.Container>
                            <S.ResultsTableTitle>Subscriptions</S.ResultsTableTitle>
                            <SubscriptionsTable
                                subscriptions={subs}
                                onSubscriptionSelect={onSubscriptionSelect}
                                onSubscriptionAction={onSubscriptionAction}
                                onAccountBreadcrumb={onAccountSelect}
                            />
                            <S.ResultsTableTitle>Your Trends Without Subscriptions</S.ResultsTableTitle>
                            <TrendsTable
                                trendResults={trendsWithoutSubs}
                                onTrendSelect={onTrendSelect}
                                onAccountBreadcrumb={onAccountSelect}
                            />
                        </S.Container>;
                    })
                    .getOr(<LoadingMask/>)
            }
        </SettingsTabSection>
    );
};

class S {

    static readonly Container = styled.div`
        width: 100%;
        box-sizing: border-box;
        padding: 16px 32px;
        gap: 16px;
    `;

    static readonly ResultsTableTitle = styled.div`
        font-size: ${FontSizes.medium};
        color: ${Colors.textPrimary};
    `;
}
