import {ArcQL} from "metadata/query/ArcQL";

export class ArcQLError extends Error {

    constructor(
        message: string,
        public readonly query?: ArcQL
    ) {
        super(message);
    }

}