import {GeneratedColumnDescriptionJson, GeneratedColumnInfo} from "metadata/dataset/GeneratedColumnInfo";
import {JsonUtils} from "metadata/JsonUtils";

export type ColumnInfosResponseJson = {
    infos: Map<string, GeneratedColumnDescriptionJson>
};

/**
 * POJO detailing column infos response from an AI.
 */
export class ColumnInfosResponse {

    constructor(
        // name of the column
        public readonly infos: Map<string, GeneratedColumnInfo>
    ) {

    }

    static fromJSON(json: ColumnInfosResponseJson): ColumnInfosResponse {
        return new ColumnInfosResponse(
            JsonUtils.toMap(json.infos, GeneratedColumnInfo.fromJSON)
        );
    }
}