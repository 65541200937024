import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ArcQLFilterValue, FilterClause} from "metadata/query/filterclause/FilterClause";
import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {References} from "metadata/References";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {FieldProjection} from "metadata/query/FieldProjection";
import {ArcQL} from "metadata/query/ArcQL";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";
import {ReferencingFilterClause} from "metadata/query/filterclause/ReferencingFilterClause";

/**
 * Filter clause that uses an ArcQL as a Dynamic Filter Set to dictate the filter values.
 */
export default class ArcQLFilterClause implements SingleFieldFilterClause, ReferencingFilterClause {

    static fromJSON(json: JsonObject, references: References): ArcQLFilterClause {
        const fullyQualifiedName = json['fullyQualifiedName'];
        return new ArcQLFilterClause(
            json['field'],
            fullyQualifiedName,
            FilterOperator.get(json['operator']),
            json['resultColumn'] === undefined ? Optional.none() : Optional.of(json['resultColumn']),
            // either get the arcql from passed-in references in ArcQL response or local storage
            references ? references.get(fullyQualifiedName).nullable : null
        );
    }

    static fromArcQLFilter(
        // Field in question.
        field: string,
        // The selected ArcQLFilterValue.
        value: ArcQLFilterValue,
        // FilterOperator selected
        operator: FilterOperator
    ): ArcQLFilterClause {
        return new ArcQLFilterClause(
            field,
            value.arcql.fullyQualifiedName,
            operator,
            value.resultColumn,
            value.arcql
        );
    }

    /**
     * Extract viable fields for Dynamic Filter Set. If grouping, return grouping fields; else, return detail fields.
     */
    static extractFieldsForDynamicFilterSet(arcql: ArcQL): FieldProjection[] {
        return arcql.groupings.size > 0 ? arcql.groupings.fields : arcql.fields.fields;
    }

    /**
     * All public fields are 1:1 model with the BE but contains additional data like underlying ArcQL asset.
     */
    constructor(
        public readonly column: string,
        public readonly fullyQualifiedName: string,
        public readonly operator: FilterOperator,
        public readonly resultColumn: Optional<string>,
        public readonly arcqlAsset: AssetSearchResult
    ) {
    }

    description(short: boolean): string {
        const display = this.arcqlAsset ? this.arcqlAsset.label : this.fullyQualifiedName;
        return this.resultColumn.map(
            rc => `${this.operator.label} ${display} <${rc}>`
        ).getOr(`${this.operator.label} ${display}`);
    }

    get fields(): string[] {
        return [this.column];
    }

    fieldsLabel(dataset: ArcDataset): string {
        return dataset.getLabel(this.column, `<missing: ${this.column}>`);
    }

    get isAll(): boolean {
        return false;
    }

    isFor(fields: string[]): boolean {
        return fields.length === 1 && fields[0] === this.column;
    }

    get isStructureValid(): boolean {
        return this.column != null && this.fullyQualifiedName != null && this.operator != null;
    }

    equals(other: FilterClause): boolean {
        return Optional.ofType(other, ArcQLFilterClause)
            .map(o => this.column === o.column
                && this.fullyQualifiedName === o.fullyQualifiedName
                && this.operator === o.operator)
            .getOr(false);
    }

    get values(): ArcQLFilterValue[] {
        return [{resultColumn: this.resultColumn, arcql: this.arcqlAsset}];
    }

    get type(): FilterClauseType {
        return FilterClauseType.ARCQL;
    }

    toJSON(): JsonObject {
        return {
            'type': FilterClauseType.ARCQL.name,
            'field': this.column,
            'fullyQualifiedName': this.fullyQualifiedName,
            'operator': this.operator,
            'resultColumn': this.resultColumn.map(rc => rc).getOr(null)
        };
    }
}

