import styled from "@emotion/styled";
import * as React from "react";
import {FunctionComponent} from "react";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {Tuple} from "common/Tuple";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";

type Props = {
    config: VisualizationConfig
    onChange: (config: VisualizationConfig) => void
}

/**
 * Component to edit a given visualization config.
 *
 * @author zuyezheng
 */
export const VizEditor: FunctionComponent<Props> = (props: Props) => {

    const onChangeConfig = (name: string, value: any) => {
        props.onChange(props.config.withConfigChange(name, value));
    };

    return <S.Editor>
        {
            props.config.type.definition.zip(props.config).map(
                (prop: Tuple<VizProperty<any>, any>) =>
                    <div key={prop.left.name}>{
                        prop.left.el(
                            prop.right,
                            // normally we would use base name of VizProperty but for sectioned properties we need to use the name of the child property
                            (v: any, propertyName: string) => onChangeConfig(propertyName ?? prop.left.name, v),
                            props.config
                        )
                    }</div>
            )
        }
    </S.Editor>;

};

class S {

    static readonly Editor = styled.div`

    `;
}
