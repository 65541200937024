import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import CheckIcon from "@mui/icons-material/Check";
import {css} from "@emotion/react";

export class ToolbarS {
    static Container = styled.div`
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 8px;
        border-bottom: 1px solid ${Colors.borderGrey};
        padding: 4px;
    `;

    static Section = styled.div<{
        compactButtons?: boolean
        dividerHeight?: string
    }>`
        display: flex;
        align-items: center;
        position: relative;

        // vertical divider if not last child
        &:not(:last-child)::after {
            content: '';
            position: absolute;
            // Half of the margin-right to center the divider
            right: -8px;
            height: ${props => props.dividerHeight || '24px'};
            width: 2px;
            background-color: ${Colors.borderGrey};
        }

        &:not(:last-child) {
            margin-right: 16px;
        }

        button {
            padding: 4px;

            ${props => props.compactButtons && css`
                svg {
                    width: 16px;
                    height: 16px;
                }
            `}
        }
    `;

    static SelectedText = styled.div`
        font-size: ${FontSizes.xSmall};
        color: ${Colors.textPrimary};
    `;

    static ThemedCheck = styled(CheckIcon)`
        color: ${Colors.hyperarcYellow};
    `;
}