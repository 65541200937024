import {Enum} from "common/Enum";
import {HierarchicalGrain} from "metadata/query/HierarchicalField";
import dayjs from "dayjs";
import {ArcQL} from "metadata/query/ArcQL";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export class DateGrain extends Enum implements HierarchicalGrain {

    static YEAR = new DateGrain('year', 'Year', 'YR', 'YYYY');
    static MONTH = new DateGrain('month', 'Month', 'MON', 'YYYY-MM');
    static WEEK = new DateGrain('week', 'Week', 'WEEK', 'YYYY-MM-DD');
    static DAY = new DateGrain('day', 'Day', 'DAY', 'YYYY-MM-DD');
    static HOUR = new DateGrain('hour', 'Hour', 'HR', 'YYYY-MM-DD HH[:00]');
    static MIN = new DateGrain('minute', 'Minute', 'MIN', 'YYYY-MM-DD HH:mm');
    static SEC = new DateGrain('second', 'Second', 'SEC', 'YYYY-MM-DD HH:mm:ss');

    constructor(
        name: string,
        public readonly label: string,
        public readonly short: string,
        public readonly displayFormat: string
    ) {
        super(name);
    }

    formatEpoch(epoch: number): string {
        return dayjs(epoch).utc().format(this.displayFormat);
    }

    /**
     * Dates suck, sometimes pinot returns epochs (non primary index dates), sometimes they are formatted as strings,
     * sometimes they're epochs as strings since fusion doesn't like numbers for groupings, and sometimes they're other
     * stuff.
     */
    formatMysteryDate(value: string | number): string {
        if (typeof value === 'number') {
            // if a number, for sure epoch
            return this.formatEpoch(value);
        } else {
            // if it's a string, go scooby go, first check to see if it's a string in pinot query date format
            let parsedDate = dayjs(value, ArcQL.DATE_FORMAT);
            if (parsedDate.isValid()) {
                return parsedDate.format(this.displayFormat);
            }

            // it could also have been reformatted to the date grain display format
            if (value.length === this.displayFormat.length) {
                parsedDate = dayjs(value, this.displayFormat);
                if (parsedDate.isValid()) {
                    return value
                }
            }

            // last option is an epoch as a string, we check this last since one display format is just year which
            // would also parse to a valid string
            const epoch = Number(value);
            if (!isNaN(epoch)) {
                return this.formatEpoch(epoch);
            }

            return null;
        }
    }

    toEpoch(value: string): number {
        return dayjs.utc(value, this.displayFormat).valueOf();
    }

}
DateGrain.finalize();