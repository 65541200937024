import * as React from "react";
import {FunctionComponent} from "react";
import {ConnectionStepperHandler} from "app/components/settings/connections/ConnectionStepperHandler";
import {InputConnectionDetails} from "metadata/connections/InputConnectionDetails";

interface Props {
    handler: ConnectionStepperHandler
    onDetailChange: (details: InputConnectionDetails) => void
}

/**
 * Step for entering details of a connection.
 */
export const EnterConnectionStep: FunctionComponent<Props> = (props: Props) => {

    return (
        <div>
            {props.handler.enterDetailsStep(
                {
                    onDetailChange: (details: InputConnectionDetails) => {
                        props.onDetailChange(details);
                    }
                }
            )}
        </div>
    );
};