import React, {ChangeEvent, FunctionComponent} from "react";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";
import {FormControl, InputLabel} from "@mui/material";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {CollapsibleSection} from "app/components/CollapsibleSection";
import {VizType} from "metadata/query/VizType";
import {GlobalFilterEmbedMode} from "metadata/dashboard/GlobalFilterEmbedMode";
import MenuItem from "@mui/material/MenuItem";
import {DashboardConfigS} from "app/dashboard/components/DashboardConfigS";
import {DashboardConfigLayoutSection} from "app/dashboard/components/DashboardConfigLayoutSection";
import {DashboardConfigWidgetSection} from "app/dashboard/components/DashboardConfigWidgetSection";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import InputAdornment from "@mui/material/InputAdornment";

interface Props {
    className?: string
    dashboard: ArcDashboard
    config: DashboardConfig
    onChange: (config: DashboardConfig) => void
}

export const DashboardConfigPanel: FunctionComponent<Props> = (props: Props) => {

    const onEmbedModeChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.config.with({globalFilterEmbedMode: GlobalFilterEmbedMode.get(event.target.value)}));
    };


    return (
        <DashboardConfigS.Container className={props?.className}>
            <CollapsibleSection title={"embed"}>
                <FormControl fullWidth size="small" margin="dense">
                    <InputLabel htmlFor="embed-global-filter">Global Filters Mode</InputLabel>
                    <DashboardConfigS.Select
                        id="embed-global-filter"
                        label="Global Filters"
                        variant="standard"
                        value={props.config.globalFilterEmbedMode.name}
                        onChange={onEmbedModeChange}
                        startAdornment={
                            <InputAdornment position="start">
                                <FilterAltOutlinedIcon/>
                            </InputAdornment>
                        }
                    >{
                        GlobalFilterEmbedMode.sorted().map((type: VizType) =>
                            <DashboardConfigS.MenuItem key={type.name} value={type.name}>{type.label}</DashboardConfigS.MenuItem>
                        )
                    }</DashboardConfigS.Select>
                </FormControl>
            </CollapsibleSection>
            <DashboardConfigLayoutSection
                dashboard={props.dashboard}
                config={props.config}
                onChange={props.onChange}
            />
            <DashboardConfigWidgetSection
                dashboard={props.dashboard}
                config={props.config}
                onChange={props.onChange}
            />
        </DashboardConfigS.Container>
    );
};
