import React, {FunctionComponent, ReactNode, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";
import {ExplainedMenuItem} from "app/dashboard/widgets/config/ExplainedMenuItem";

interface Props {
    // Tooltip text to display when hovering over the button
    tooltipCategorization: string
    // Icon to display on the button
    menuButtonIcon: ReactNode
    // The currently selected setting
    currentSetting: ExplainedSetting;
    // Callback to call when a setting is selected
    onSettingChange: (o: ExplainedSetting) => void;
    // List of settings to enumerate for selection
    settings: ExplainedSetting[];
}

/**
 * Button trigger to open up list of settings, with an explanation of what each setting means.
 */
export const ExplainedSettingButtonMenu: FunctionComponent<Props> = (props) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const onMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const onSettingClick = (settingEnum: ExplainedSetting) => {
        props.onSettingChange(settingEnum);
        setShowMenu(false);
    };

    const onCloseMenu = () => {
        setShowMenu(false);
    };

    return (
        <div ref={anchorRef}>
            <Tooltip title={props.tooltipCategorization} placement="top" arrow>
                <IconButton
                    onClick={onMenuClick}
                >
                    {props.menuButtonIcon}
                </IconButton>
            </Tooltip>

            {showMenu &&
                <Menu
                    anchorEl={anchorRef.current}
                    keepMounted
                    open={Boolean(anchorRef)}
                    onClose={onCloseMenu}
                >
                    {props.settings.map(((setting, i) => (
                        <ExplainedMenuItem
                            key={setting.name}
                            setting={setting}
                            isSelected={setting === props.currentSetting}
                            isLastItem={i === (props.settings.length - 1)}
                            onSettingClick={onSettingClick}
                        />
                    )))}
                </Menu>
            }
        </div>
    );
};