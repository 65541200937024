import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {RangeSelection, VizSelection} from "engine/actor/VizSelection";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {MultiMetricTimelineData, StackedTimelineData, TimelineData} from "app/visualizations/data/TimelineData";
import {ArcQL} from "metadata/query/ArcQL";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Optional} from "common/Optional";
import {DateGrain} from "metadata/query/DateGrain";
import {AnalyticsType} from "metadata/AnalyticsType";
import {DateGrouping} from "metadata/query/DateGrouping";
import {JsonObject} from "common/CommonTypes";

dayjs.extend(utc);

/**
 * Builder for multi metric and stacked timeline charts.
 *
 * @author zuyezheng
 */
export class TimelineChartConfig extends ChartConfig {

    private dataBuilder: TimelineData;
    private grain: DateGrain;

    constructor(response: ArcQLResponse, config: VisualizationConfig) {
        super(response, config);
    }

    get selectionType(): SelectionType {
        return SelectionType.RANGE;
    }

    validate(): Optional<string> {
        // can have up to 2 groups, 1 date and 1 stack and need at least 1 measure
        const result = this.response.result;
        if (result.categoryColumns.length > 2 || result.columnsByType(new Set([AnalyticsType.MEASURE])).length < 1) {
            return Optional.some('Timeline can have at most 2 categories and needs at least 1 measure.');
        }

        if (this.response.arcql.groupings.size > 0 && this.response.arcql.groupings.first instanceof DateGrouping) {
            const dateGrouping = this.response.arcql.groupings.first;
            this.grain = dateGrouping.grain;
            this.dataBuilder = TimelineChartConfig.canStack(this.response.arcql)
                ? new StackedTimelineData(dateGrouping.projectedAs, this.response)
                : new MultiMetricTimelineData(dateGrouping.projectedAs, this.response);
        } else {
            return Optional.some('Timeline requires the first grouping to be a date.');
        }

        return Optional.none();
    }

    build(size: [number, number], selection: VizSelection): {[key: string]: any} {
        const xAxis: {[key: string]: any} = {};

        // some wierd edge case for fusion where if there's only a handful of rows and we explicitly set binning it will
        // get in an infinite loop, so if there are only a couple of rows, let it do its thing since its most likely
        // not going to bucket them together causing wierd aggregations such as taking an avg of an avg
        if (this.dataBuilder.dataTable.getData().data.length >= 5) {
            // set the binning to align with the grain
            xAxis['binning'] = {
                year: [],
                month: [],
                day: [],
                hour: [],
                minute: [],
                second: []
            };
            xAxis['binning'][this.grain.name] = [1, 1];
        }

        Optional.ofType(selection, RangeSelection)
            .forEach(s => {
                if (s.isEmpty()) {
                    return;
                }

                xAxis['initialInterval'] = {
                    from: dayjs(s.start).utc().format(ArcQL.DATE_FORMAT),
                    to: dayjs(s.end).utc().format(ArcQL.DATE_FORMAT)
                };
            });

        const plotType = this.config.get('plotType').getOr('column');

        return {
            type: 'timeseries',
            width: size[0],
            height: size[1],
            dataSource: Object.assign(
                {
                    chart: {
                        paletteColors: plotType === 'candlestick' ?
                            this.config.theme.threePoint[1] :
                            this.config.theme.toConfig(),
                        multiCanvas: !this.config.get('combo').getOr(false),
                    },
                    plotConfig: {
                        "candlestick": {
                            "style": {
                                "bear": {
                                    'fill': this.config.theme.threePoint[0],
                                    'stroke': this.config.theme.threePoint[0]
                                },
                                "bull": {
                                    'fill': this.config.theme.threePoint[2],
                                    'stroke': this.config.theme.threePoint[2]
                                }
                            }
                        }
                    },
                    caption: {
                        text: this.config.emptyableString('title').nullable,
                        position: this.config.get('titlePosition').getOr('left'),
                        style: {
                            text: {
                                "font-size": "14px",
                                "font-family": "NotoSans"
                            }
                        }
                    },
                    subCaption: {
                        text: this.config.emptyableString('subTitle').nullable,
                        position: this.config.get('titlePosition').getOr('left'),
                        style: {
                            text: {
                                "font-size": "12px",
                                "font-family": "NotoSans"
                            }
                        }
                    },
                    legend: {
                        enabled: this.config.get('showLegend').nullable,
                        style: {
                            text: {
                                "font-size": "10px",
                                "font-family": "NotoSans"
                            }
                        }
                    },
                    data: this.dataBuilder.dataTable,
                    xAxis: xAxis,
                    extensions: {
                        customRangeSelector: {
                            enabled: "0"
                        }
                    },
                    navigator: this.navigatorConfig(plotType)
                },
                this.dataBuilder.dataSourceConfig(this.config)
            )
        };
    }

    private navigatorConfig(plotType: string): JsonObject {
        // configure the navigator size and visibility
        let navigatorProperty = this.config.get('navigator').getOr('medium');

        // candlesticks throws bunch of errors without a navigator so force a small one
        if (navigatorProperty === 'hide' && plotType === 'candlestick') {
            navigatorProperty = 'small';
        }

        const navigator: any = {};
        if (navigatorProperty === 'hide') {
            navigator['enabled'] = false;
        } else {
            navigator['enabled'] = true;

            if (navigatorProperty === 'small') {
                navigator['height'] = 40;
            } else if (navigatorProperty === 'medium') {
                navigator['height'] = 60;
            } else if (navigatorProperty === 'large') {
                navigator['height'] = 100;
            }
        }

        return navigator;
    }

    /**
     * Can only stack with 2 groupings (the first being a date) and a single measure.
     */
    static canStack(arcql: ArcQL): boolean {
        return arcql.groupings.size === 2
            && arcql.groupings.first.type === ArcQLGroupingType.DATE;
    }

}