import { Optional } from 'common/Optional';
import {NodeStructuredDetail} from 'metadata/hypergraph/content/NodeStructuredDetail';

/**
 * Structured content of a node that provides additional formatting or interactivity compared to the standard
 * description.
 *
 * @author zuyezheng
 */
export class NodeStructuredSection {

    constructor(
        // name of the section
        public readonly name: string,
        public readonly content: NodeStructuredDetail[] = []
    ) { }

    /**
     * Single string used for generating an embedding.
     */
    get embeddingContent(): string {
        return [
            ...Optional.string(this.name).map(n => `## ${this.name}`).array,
            ...this.content.map(c => c.embeddingContent)
        ].join('\n');
    }

}