import {AnalyticsType} from "metadata/AnalyticsType";
import {JsonObject} from "common/CommonTypes";
import {DataSuperType, DataType} from "metadata/DataType";
import {Optional} from "common/Optional";
import {HigherOrderSuperType, HigherOrderType} from "metadata/HigherOrderType";
import {jaroWinkler} from "jaro-winkler-typescript";
import {IndexType} from "metadata/IndexType";

/**
 * Column representation for dataset V1 describe response.
 */
export class Column {

    static fromJSON(json: JsonObject) {
        return new Column(
            AnalyticsType.get(json['columnType']),
            json
        );
    }

    constructor(
        public readonly analyticsType: AnalyticsType,
        public readonly json: JsonObject,
        // label when displayed relative to a parent
        private readonly _relativeLabel: string = null,
        // optional children
        public readonly children: Column[] = [],
    ) {
    }

    /** Returns a new Field copy with display label overwritten. */
    withRelativeLabel(parent: Column): Column {
        // figure out which part of the parent name should be the "prefix" (before the hyphen if one)
        const parentPrefixIndex = parent.label.indexOf('-');
        let prefix = parent.label;
        if (parentPrefixIndex > -1) {
            prefix = parent.label.substring(0, parentPrefixIndex).trim();
        }

        // figure out the relative label if matching prefix
        const relativeLabel = this.label.indexOf(prefix + ' - ') > -1 ?
            // 3 chars for ' - '
            this.label.substring(prefix.length + 3) :
            this.label;

        return new Column(this.analyticsType, this.json, relativeLabel, this.children);
    }

    /** Returns a new Field copy with appropriate children */
    withChildren(children: Column[]): Column {
        return new Column(this.analyticsType, this.json, this.relativeLabel, children);
    }

    equals(other: Column): boolean {
        return this.name === other.name;
    }

    get name(): string {
        return this.json['name'];
    }

    get label(): string {
        return this.json['label'] || this.name;
    }

    /**
     * Return the label for relative cases such as hierarchical display of fields. If there is no relative label such
     * as for parent fields, the label will be returned.
     */
    get relativeLabel(): string {
        return this._relativeLabel || this.label;
    }

    get description(): string {
        return this.json['description'] || '';
    }

    get dataType(): DataType {
        return DataType.get(this.json['dataType']);
    }

    get dataSuperType(): DataSuperType {
        return this.dataType.superType;
    }

    get isMultiValue(): boolean {
        return this.json['isMultiValue'];
    }

    extended(key: string): Optional<any> {
        if (this.json['extended'] == null) {
            return Optional.none();
        }

        return Optional.of(this.json['extended'][key]);
    }

    get higherOrderType(): Optional<HigherOrderType> {
        return this.extended("higherOrderType").map(
            (typeStr: string) => HigherOrderType.get(typeStr)
        );
    }

    get higherOrderSuperType(): Optional<HigherOrderSuperType> {
        return this.higherOrderType.map((orderType: HigherOrderType) => orderType.superType);
    }

    get parentField(): Optional<string> {
        return Optional.of(this.json['parentColumnName']);
    }

    get hasChildren(): boolean {
        return this.children.length > 0;
    }

    get isRoot(): boolean {
        return this.hasChildren || !this.parentField.isPresent;
    }

    get isPublic(): boolean {
        return this.json['isPublic'];
    }

    get isVerified(): boolean {
        return this.name.startsWith("hyperarc__");
    }

    get indexTypes(): Set<IndexType> {
        return new Set<IndexType>(
            this.json['indexTypes'].map(
                (typeStr: string) => IndexType.get(typeStr)
            )
        );
    }

    get hasStringIndex(): boolean {
        return Array.from(this.indexTypes).some(indexType => indexType.isStringIndex)
    }

    /**
     * If this or any of its children are a match, return the match result.
     */
    match(matcher: (f: Column) => boolean): Optional<ColumnMatchResult> {
        const matchedChildren = this.children.filter(matcher);
        const hasMatchedChildren = matchedChildren.length > 0;

        if (matcher(this)) {
            // match parent, return all, no need to copy since children hasn't changed
            return Optional.some(new ColumnMatchResult(this, hasMatchedChildren));
        } else {
            if (matchedChildren.length === 0) {
                return Optional.none();
            } else {
                return Optional.some(
                    new ColumnMatchResult(this.withChildren(matchedChildren), hasMatchedChildren)
                );
            }
        }
    }

    /** Return the max relevancy score of this Field branch. **/
    relevancy(term: string): number {
        return [this, ...this.children].map(f => jaroWinkler(term, f.label.toLowerCase()))
            .reduce((a, b) => Math.max(a, b));
    }

    /**
     * To JSON representation.
     */
    toJSON(): JsonObject {
        return this.json;
    }

}

export class ColumnMatchResult {

    constructor(
        public readonly column: Column,
        public readonly matchedChildren: boolean
    ) {
    }

}