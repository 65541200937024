import * as React from "react"
import {SVGProps} from "react"

const ScorecardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M19 3.5H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm0 16H5l4-4 4 4 6-6v6Zm0-8.5-6 6-4-4-4 4V5.5h14V11Zm-5.5-1.5v-3H12v6h1.5v-3Zm3.7 3-2-3 2-3h-1.7l-2 3 2 3h1.7ZM11 11H8.5v-.75H11V6.5H7V8h2.5v.75H7v3.75h4V11Z"
        />
    </svg>
);
export default ScorecardIcon;
