import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";

export class ModifyQueryConfig implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly queryId: string,
        public readonly config: {[key: string]: any},
        // if we should merge or replace
        public readonly merge: boolean
    ) { }

    apply(metadata: ArcDashboard) {
        return Tuple.of(
            metadata.with({
                queries: metadata.queries.withChange(
                    this.queryId,
                    (query) => query.withConfigs(this.config, this.merge)
                )
            }),
            // full restore to previous state
            new ModifyQueryConfig(this.queryId, metadata.queries.get(this.queryId).config, false)
        );
    }

    describe(): string {
        return `Modified config for query ${this.queryId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}