import {Either, Left, Right} from "common/Either";
import {FQN} from "common/FQN";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcQL} from "metadata/query/ArcQL";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {ErrorResponse} from "services/ApiResponse";
import {MetadataService} from "services/MetadataService";
import {AssetVersions} from "metadata/AssetVersions";
import {Folders} from "metadata/project/Folders";
import {Asset} from "metadata/Asset";
import {FoldersSearchResponse} from "metadata/project/FoldersSearchResponse";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {AssetType} from "metadata/AssetType";
import {Optional} from "common/Optional";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";
import {References} from "metadata/References";
import {PersonaListParams} from "metadata/PersonaListParams";
import {FolderResult} from "metadata/project/FolderResult";
import FolderCreateRequest from "metadata/project/FolderCreateRequest";
import FolderPatchRequest from "metadata/project/FolderPatchRequest";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import {AccountsSearchResponse} from "metadata/account/AccountsSearchResponse";
import {Story} from "metadata/asset/story/Story";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {HyperGraphSearchParams} from "metadata/search/HyperGraphSearchParams";
import {GlobalAnswerWithNodes} from "metadata/search/GlobalAnswerWithNodes";

/**
 * Metadata service used for tests.
 *
 * @author zuyezheng
 */
export class MockedMetadataService implements MetadataService {

    private readonly mockedAssets: Map<string, Asset>;

    constructor() {
        this.mockedAssets = new Map();
    }


    mockAsset(fqn: FQN, asset: Asset) {
        this.mockedAssets.set(fqn.toString(), asset);
    }

    fetchArcQL(fqn: FQN, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcQL>> {
        return Promise.resolve(
            Optional.of(this.mockedAssets.get(fqn.toString()))
                .map<Either<ErrorResponse, ArcQL>>(dataset => new Right(dataset as ArcQL))
                .getOr(new Left(new ErrorResponse({}, null)))
        );
    }

    fetchArcQLVersion(fqn: FQN, version: number, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcQL>> {
        return Promise.resolve(undefined);
    }

    fetchAsset(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>> {
        switch (fqn.type) {
            case AssetType.ARCQL:
                return this.fetchArcQL(fqn, signal);
            case AssetType.DASHBOARD:
                return this.fetchDashboard(fqn, signal);
            case AssetType.DATASET:
            default:
                throw new Error(`Asset type ${fqn.type} not supported.`);
        }
    }

    fetchDashboard(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return Promise.resolve(undefined);
    }

    fetchDashboardVersion(fqn: FQN, version: number, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return Promise.resolve(undefined);
    }

    fetchFolder(accountName: string, folderName: string, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>> {
        return Promise.resolve(undefined);
    }

    createFolder(accountName: string, folder: FolderCreateRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>> {
        return Promise.resolve(undefined);
    }

    patchFolder(accountName: string, folderName: string, folderRequest: FolderPatchRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>> {
        return Promise.resolve(undefined);
    }

    fetchTask(fqn: FQN, signal?: AbortSignal): Promise<Object> {
        return Promise.resolve(undefined);
    }

    listArcQLVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>> {
        return Promise.resolve(undefined);
    }

    listDashboardVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>> {
        return Promise.resolve(undefined);
    }

    listFolders(accountName: string, signal?: AbortSignal): Promise<Either<ErrorResponse, Folders>> {
        return Promise.resolve(undefined);
    }

    saveArcQL(fqn: FQN, arcQL: ArcQL): Promise<Either<ErrorResponse, ArcQL>> {
        return Promise.resolve(undefined);
    }

    saveAsset(fqn: FQN, asset: Asset, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>> {
        return Promise.resolve(undefined);
    }

    saveDashboard(fqn: FQN, dashboard: ArcDashboard): Promise<Either<ErrorResponse, ArcDashboard>> {
        return Promise.resolve(undefined);
    }

    fetchFilterSet(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return Promise.resolve(undefined);
    }

    fetchFilterSetVersion(fqn: FQN, version: number, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return Promise.resolve(undefined);
    }

    listFilterSetVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>> {
        return Promise.resolve(undefined);
    }

    saveFilterSet(fqn: FQN, filterSet: ArcFilterSet): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return Promise.resolve(undefined);
    }

    fetchReferences(fqns: Set<FQN>, signal?: AbortSignal): Promise<Either<ErrorResponse, References>> {
        return Promise.resolve(new Right(References.empty()));
    }

    archiveArcQL(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQL>> {
        return Promise.resolve(undefined);
    }

    archiveDashboard(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return Promise.resolve(undefined);
    }

    archiveFilterSet(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return Promise.resolve(undefined);
    }

    archiveAsset(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>> {
        return Promise.resolve(undefined);
    }

    listStories(fqn: FQN, loadImages: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, Story[]>> {
        return Promise.resolve(undefined);
    }

    createScene(story: Story, scene: StoryScene, signal?: AbortSignal): Promise<Either<ErrorResponse, StoryScene>> {
        return Promise.resolve(undefined);
    }

    listScenes(fqn: FQN, loadImages: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, StoryScene[]>> {
        return Promise.resolve(undefined);
    }

    assetSearch(params: AssetSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>> {
        return Promise.resolve(undefined);
    }

    folderSearch(params: FolderSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, FoldersSearchResponse>> {
        return Promise.resolve(undefined);
    }

    personaSearch(datasetFqn: FQN, params: PersonaListParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>> {
        return Promise.resolve(undefined);
    }

    accountSearch(params: AccountSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AccountsSearchResponse>> {
        return Promise.resolve(undefined);
    }

    hyperGraphSearch(params: HyperGraphSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>> {
        return Promise.resolve(undefined);
    }

    hyperGraphSearchTest(query: string, signal?: AbortSignal): Promise<Either<ErrorResponse, boolean>> {
        return Promise.resolve(undefined);
    }

    hyperGraphGlobalAnswer(query: string, signal?: AbortSignal): Promise<Either<ErrorResponse, GlobalAnswerWithNodes>> {
        return Promise.resolve(undefined);
    }
}