import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";

/**
 * Modifications to the dashboard config.
 */
export class ModifyDashboardConfig implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly config: DashboardConfig,
        public readonly originalConfig: DashboardConfig
    ) {
    }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return new Tuple(
            metadata.with({
                config: this.config
            }),
            new ModifyDashboardConfig(metadata.config, this.config)
        );
    }

    describe(): string {
        const changes = [];
        if (this.config.backgroundColor !== this.originalConfig.backgroundColor) {
            changes.push(`background changed to ${this.config.backgroundColor}`);
        }
        if (this.config.numberColumns !== this.originalConfig.numberColumns) {
            changes.push(`number of columns changed to ${this.config.numberColumns}`);
        }
        if (this.config.gridGapX !== this.originalConfig.gridGapX) {
            changes.push(`margin gap X changed to ${this.config.gridGapX}`);
        }
        if (this.config.gridGapY !== this.originalConfig.gridGapY) {
            changes.push(`margin gap Y changed to ${this.config.gridGapY}`);
        }

        if (this.config.globalFilterEmbedMode !== this.originalConfig.globalFilterEmbedMode) {
            changes.push(`global filter embed mode changed to ${this.config.globalFilterEmbedMode}`);
        }

        if (this.config.maxWidth !== this.originalConfig.maxWidth) {
            changes.push(`max width changed to ${this.config.maxWidth}`);
        }

        if (this.config.gutterColor !== this.originalConfig.gutterColor) {
            changes.push(`gutter color changed to ${this.config.gutterColor}`);
        }

        const containerChanges = this.describeContainerChanges(this.config.container, this.originalConfig.container);
        if (containerChanges) {
            changes.push(containerChanges);
        }

        if (changes.length === 0) {
            return "No changes made to dashboard config.";
        }

        return `Modified dashboard config: ${changes.join(",")}.`;
    }

    private describeContainerChanges(newContainer: ContainerConfigPart, originalContainer: ContainerConfigPart): string | null {
        const containerChanges = [];
        if (newContainer.padding !== originalContainer.padding) {
            containerChanges.push(`widget padding changed to ${newContainer.padding}`);
        }
        if (newContainer.backgroundColor !== originalContainer.backgroundColor) {
            containerChanges.push(`widget container background changed to ${newContainer.backgroundColor}`);
        }
        if (newContainer.borderRadius !== originalContainer.borderRadius) {
            containerChanges.push(`widget border radius changed to ${newContainer.borderRadius}`);
        }
        if (newContainer.borderWidth !== originalContainer.borderWidth) {
            containerChanges.push(`widget border width changed to ${newContainer.borderWidth}`);
        }
        if (newContainer.borderColor !== originalContainer.borderColor) {
            containerChanges.push(`widget border color changed to ${newContainer.borderColor}`);
        }
        if (newContainer.dropShadow !== originalContainer.dropShadow) {
            containerChanges.push(`widget box shadow changed to ${newContainer.dropShadow}`);
        }

        return containerChanges.length > 0 ? `${containerChanges.join(", ")}` : null;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}