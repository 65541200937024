import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";

/**
 * @author zuyezheng
 */
export class ToggleDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [
        new VizStringProperty('title', 'Title'),
        new VizStringProperty('prefix', 'Prefix'),
        new VizStringProperty('suffix', 'Suffix'),
        new VizBooleanProperty('compact', 'Compact Numbers'),
    ];

    static DEFAULTS = new Map<string, any>([
        ['compact', false]
    ]);

    get properties(): VizProperty<any>[] {
        return ToggleDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return ToggleDefinition.DEFAULTS;
    }

}