import {ArcQLFieldType} from "metadata/query/ArcQLFieldType";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ArcQLField, ArcQLFieldProps} from "metadata/query/ArcQLField";

export class ExpressionField extends ArcQLField {

    constructor(
        public readonly expression: string,
        as: string
    ) {
        super(ArcQLFieldType.EXPRESSION, as);
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        return AnalyticsType.MEASURE;
    }

    get tooltip(): string {
        return this.expression;
    }

    get prefix(): string {
        return 'f(x)';
    }

    with({as}: ArcQLFieldProps): ArcQLField {
        return new ExpressionField(this.expression, as);
    }

}