import * as React from "react";
import {FunctionComponent} from "react";
import {SettingsTabSection} from "app/components/settings/SettingsTabSection";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {UserService} from "services/UserService";
import {ServiceProvider} from "services/ServiceProvider";
import {Optional} from "common/Optional";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {LoadingMask} from "app/components/decoration/LoadingMask";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {AddConnectionDialog} from "app/components/settings/connections/AddConnectionDialog";
import {OrgService} from "services/OrgService";
import {ConnectionsTable} from "app/components/settings/connections/ConnectionsTable";
import {useHistory} from "react-router-dom";
import {DatasetListItem} from "metadata/dataset/DatasetListItem";
import {OrgMembership} from "metadata/OrgMembership";
import {ImportTableDialog} from "app/components/settings/connections/ImportTableDialog";
import {AccountResult} from "metadata/account/AccountResult";
import {HyperArcConnection} from "metadata/connections/HyperArcConnection";
import {HyperArcUser} from "metadata/HyperArcUser";
import {CreateUploadDialog} from "app/components/settings/connections/CreateUploadDialog";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {FQN} from "common/FQN";
import {AssetType} from "metadata/AssetType";
import {ConnectionType} from "metadata/connections/ConnectionType";

type Props = {
    user: HyperArcUser
    orgMemberships: OrgMembership[]
}

/**
 * Data sources settings.
 */
export const DataSources: FunctionComponent<Props> = (props: Props) => {
    const history = useHistory();
    const [connections, setConnections] = React.useState<Optional<RemoteDataSource[]>>(Optional.none);
    const [personalDatasets, setPersonalDatasets] = React.useState<Optional<DatasetListItem[]>>(Optional.none);
    const [showAddConnectionDialog, setShowAddConnectionDialog] = React.useState<boolean>(false);
    const [showUploadDialog, setShowUploadDialog] = React.useState<boolean>(false);
    const [selectedConnectionForImport, setSelectedConnectionForImport] = React.useState<Optional<RemoteDataSource>>(Optional.none());

    React.useEffect(() => {
        // load datasets for user
        loadPersonalDatasets();
        // load connections for user
        loadConnections();
    }, []);

    const withHyperArcConnection = (connections: RemoteDataSource[], datasets: DatasetListItem[]) => {
        return [HyperArcConnection.new(props.user, datasets), ...connections];
    };

    const loadConnections = () => {
        ServiceProvider.get(UserService)
            .listConnections()
            .then(response => response.match(
                connections => setConnections(Optional.some(connections)),
                () => ServiceProvider.get(NotificationsService).publish(
                    'DataSources', NotificationSeverity.ERROR, 'Could not load connections.'
                )
            ));
    };

    const loadPersonalDatasets = () => {
        ServiceProvider.get(UserService)
            .listDatasets()
            .then(response => response.match(
                datasets => setPersonalDatasets(Optional.some(datasets)),
                () => ServiceProvider.get(NotificationsService).publish(
                    'DataSources', NotificationSeverity.ERROR, 'Could not load personal datasets.'
                )
            ));
    };

    const handleConnectionDialog = () => {
        loadConnections();
        setShowAddConnectionDialog(false);
    };

    const onAccountSelect = (account: string) => {
        history.push(AccountResult.path(account));
    };

    const onDatasetSelect = (dataset: DatasetListItem) => {
        history.push(dataset.fqn.toString() + "/query");
    };

    const onDatasetEdit = (dataset: DatasetListItem) => {
        history.push("/" + dataset.fqn);
    };

    const onDeleteConnection = (connection: RemoteDataSource) => {
        ServiceProvider.get(OrgService).deleteConnection(connection.organizationName, connection.id).then(
            response => response.match(
                () => {
                    // reload connections
                    loadConnections();
                    ServiceProvider.get(NotificationsService).publish(
                        'DataSources.onDeleteConnection',
                        NotificationSeverity.SUCCESS,
                        `Connection '${connection.label}' deleted.`
                    );
                },
                () => ServiceProvider.get(NotificationsService).publish(
                    'DataSources.onDeleteConnection', NotificationSeverity.ERROR, 'Could not delete connection.'
                )
            )
        );
    };

    const onTestConnection = (connection: RemoteDataSource) => {
        ServiceProvider.get(OrgService).testConnection(connection.organizationName, connection.id).then(
            response => response.match(
                ((testResp) =>
                        testResp.success ?
                            notifySuccessfulConnection(connection) : notifyFailedConnection(connection)
                ),
                () => notifyFailedConnection(connection)
            )
        );
    };

    const notifySuccessfulConnection = (connection: RemoteDataSource) => {
        ServiceProvider.get(NotificationsService).publish(
            'DataSources.notifySuccessfulConnection',
            NotificationSeverity.SUCCESS,
            `Test connection to '${connection.label}' successful.`
        );
    };

    const notifyFailedConnection = (connection: RemoteDataSource) => {
        ServiceProvider.get(NotificationsService).publish(
            'DataSources.notifyFailedConnection',
            NotificationSeverity.ERROR,
            `Test connection to '${connection.label}' failed.`
        );
    };

    const onClickImportTableForConnection = (dataSource: RemoteDataSource) => {
        switch(dataSource.type) {
            case ConnectionType.HYPERARC:
                setShowUploadDialog(true);
                break;
            default:
                setSelectedConnectionForImport(Optional.some(dataSource));
                break;
        }
    };

    const onSelectTable = () => {
        setSelectedConnectionForImport(Optional.none());
    };

    const onUploadDataset = (datasetV2: DatasetV2) => {
        setShowUploadDialog(false);
        ServiceProvider.get(NotificationsService).publish(
            'DataSources.onUploadDataset',
            NotificationSeverity.SUCCESS,
            `Successfully uploaded dataset ${datasetV2.label}!`
        );
        history.push("/" + datasetV2.fqn);
        // reload personal datasets
        loadPersonalDatasets();
    };

    return (
        <SettingsTabSection
            title={'Connections'}
        >
            {
                Optional.all([connections, personalDatasets]).map(
                    ([conns, datasets]: [RemoteDataSource[], DatasetListItem[]]) =>
                        <S.Content>
                            <S.AddConnection
                                variant={'contained'}
                                startIcon={<AddIcon/>}
                                onClick={() => setShowAddConnectionDialog(true)}
                            >
                                Add Connection
                            </S.AddConnection>
                            {
                                <ConnectionsTable
                                    connections={withHyperArcConnection(conns, datasets)}
                                    onAccountBreadcrumb={onAccountSelect}
                                    onTestConnection={onTestConnection}
                                    onDeleteConnection={onDeleteConnection}
                                    onDatasetSelect={onDatasetSelect}
                                    onDatasetEdit={onDatasetEdit}
                                    onClickAddTableForConnection={onClickImportTableForConnection}
                                />
                            }
                            {
                                showAddConnectionDialog && (
                                    <AddConnectionDialog
                                        orgMemberships={props.orgMemberships}
                                        onConfirm={handleConnectionDialog}
                                        onCancel={handleConnectionDialog}
                                    />
                                )
                            }
                            {
                                selectedConnectionForImport.map(
                                    dataSource => (
                                        <ImportTableDialog
                                            initialConnection={dataSource}
                                            connections={conns}
                                            onSelect={onSelectTable}
                                            onCancel={() => setSelectedConnectionForImport(Optional.none())}
                                        />
                                    )
                                ).getOr(null)
                            }
                            {
                                showUploadDialog && <CreateUploadDialog
                                    fqn={new FQN(props.user.username, 'private', AssetType.DATASET_V2, '')}
                                    currentDatasets={datasets}
                                    onCancel={() => setShowUploadDialog(false)}
                                    onUpload={onUploadDataset}
                                />
                            }
                        </S.Content>
                ).getOr(
                    <LoadingMask/>
                )
            }
        </SettingsTabSection>
    );
};

class S {

    static readonly Content = styled.div`
        // padding included in box's width + height
        box-sizing: border-box;
        width: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    `;

    static readonly AddConnection = styled(Button)`
        width: fit-content;
        text-transform: none;
    `;

    static readonly NoConnectionsText = styled.div`
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.small};
    `;
}