import * as React from "react"

export type Props = {
    className?: string
}

export const ForkIcon = (props: Props) =>
    <svg className={props.className} width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.875 2.75006C4.84125 2.75006 4 3.59131 4 4.62506C4 5.43857 4.52422 6.1264 5.25 6.38531V10.8751V11.6148C4.52422 11.8737 4 12.5616 4 13.3751C4 14.4088 4.84125 15.2501 5.875 15.2501C6.90875 15.2501 7.75 14.4088 7.75 13.3751C7.75 12.5616 7.22578 11.8737 6.5 11.6148V10.8751C6.5 10.5105 6.67306 10.306 7.1665 10.0181C7.65995 9.73029 8.41629 9.47554 9.2063 9.19904C9.99631 8.92253 10.8243 8.62389 11.5122 8.13092C12.0717 7.72993 12.5349 7.14109 12.6902 6.40363C13.4467 6.16208 14 5.46051 14 4.62506C14 3.59131 13.1587 2.75006 12.125 2.75006C11.0912 2.75006 10.25 3.59131 10.25 4.62506C10.25 5.40755 10.7331 6.07792 11.4158 6.35846C11.3126 6.65362 11.1109 6.88151 10.7847 7.1153C10.3007 7.46217 9.56619 7.74946 8.7937 8.01984C8.02121 8.29021 7.21505 8.54327 6.53662 8.93903C6.52392 8.94643 6.51264 8.95707 6.5 8.96466V6.38531C7.22578 6.1264 7.75 5.43857 7.75 4.62506C7.75 3.59131 6.90875 2.75006 5.875 2.75006Z" fill="#9E9E9E"/>
    </svg>;
