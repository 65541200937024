import { AssetContext } from "metadata/search/AssetContext";
import { AssetType } from "metadata/AssetType";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraphDeserializer} from "metadata/hypergraph/HyperGraphDeserializer";
import {HyperGraphNodeSerialized} from "metadata/hypergraph/HyperGraphTypes";

type HyperGraphNodeResultJson = {
    node: HyperGraphNodeSerialized
    similarity: number
}

export type ArcQLContextJson = {
    hyperGraphNodeResults: HyperGraphNodeResultJson[]
}

export class HyperGraphNodeResult {
    constructor(
        public readonly node: HyperGraphNode,
        public readonly similarity: number
    ) {
    }

    static fromJSON(json: HyperGraphNodeResultJson): HyperGraphNodeResult {
        return new HyperGraphNodeResult(HyperGraphDeserializer.toNode(json.node, null), json.similarity);
    }
}

export class ArcQLContext implements AssetContext {
    constructor(
        public readonly hyperGraphNodeResults: HyperGraphNodeResult[]
    ) {
    }

    get type(): AssetType {
        return AssetType.ARCQL;
    }

    static fromJSON(json: ArcQLContextJson): ArcQLContext {
        return new ArcQLContext(json.hyperGraphNodeResults.map(HyperGraphNodeResult.fromJSON));
    }
}