import {FQN} from "common/FQN";
import {Alert} from "metadata/trend/Alert";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {TrendsService} from "services/TrendsService";
import {ArcTrendListItem} from "metadata/trend/ArcTrendListItem";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";

export class MockedTrendsService implements TrendsService {

    listTrends(dashboardFQN: FQN, loadImages?: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrendListItem[]>> {
        return Promise.resolve(undefined);
    }

    fetchTrend(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>> {
        return Promise.resolve(undefined);
    }

    fetchTrendById(id: string, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>> {
        return Promise.resolve(undefined);
    }

    saveTrend(fqn: FQN, trend: ArcTrend, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>> {
        return Promise.resolve(undefined);
    }

    getTrendResults(trend: ArcTrend, since: string, dataset: ArcDataset, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQLResponse>> {
        return Promise.resolve(undefined);
    }

    listAlerts(trendFqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert[]>> {
        return Promise.resolve(undefined);
    }

    createAlert(trendFqn: FQN, alert: Alert, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert>> {
        return Promise.resolve(undefined);
    }

    patchAlert(trendFqn: FQN, alert: Alert, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert>> {
        return Promise.resolve(undefined);
    }
}