import {JsonUtils} from "metadata/JsonUtils";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {JsonObject} from "common/CommonTypes";
import {WidgetMetadataDeserializer} from "metadata/dashboard/widgets/WidgetMetadataDeserializer";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";
import {Optional} from "common/Optional";

export class DashboardWidgets {

    static empty() {
        return new DashboardWidgets(new Map());
    }

    static fromJSON(json: JsonObject): DashboardWidgets {
        return new DashboardWidgets(JsonUtils.toMap(json, WidgetMetadataDeserializer.fromJSON));
    }

    private widgetCounter: number;

    constructor(
        // named widgets to their definition
        public readonly widgets: Map<string, WidgetMetadataBound>
    ) {
        this.widgetCounter = widgets.size;
    }

    get(widgetId: string): WidgetMetadataBound {
        return this.widgets.get(widgetId);
    }

    has(widgetId: string): boolean {
        return this.widgets.has(widgetId);
    }

    /**
     * Return all widgets attached to the given query.
     */
    attachedToQuery(queryId: string): QueriedWidgetMetadata<any, any>[] {
        return Array.from(this.widgets.values())
            .filter(w => Optional.ofType(w, QueriedWidgetMetadata)
                .flatMap(qw => qw.queryId.map(qId => qId === queryId))
                .getOr(false)
            )
            .map(w => w as QueriedWidgetMetadata<any, any>);
    }

    /**
     * Create a copy of widgets with the given addition.
     */
    with(widgetId: string, widget: WidgetMetadataBound): DashboardWidgets {
        const newWidgets = new Map(this.widgets);
        newWidgets.set(widgetId, widget);

        return new DashboardWidgets(newWidgets);
    }

    /**
     * Create a copy of widgets with the given change applied.
     */
    withChange(widgetId: string, change: (widget: WidgetMetadataBound) => WidgetMetadataBound): DashboardWidgets {
        const newWidgets = new Map(this.widgets);
        newWidgets.set(widgetId, change(this.widgets.get(widgetId)));

        return new DashboardWidgets(newWidgets);
    }

    /**
     * Create a copy of widgets with the given widget removed.
     */
    without(widgetId: string): DashboardWidgets {
        const newWidgets = new Map(this.widgets);
        newWidgets.delete(widgetId);

        return new DashboardWidgets(newWidgets);
    }

    toJSON(): Object {
        return JsonUtils.fromMap(this.widgets);
    }

}
