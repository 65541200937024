import {Colors, FontSizes} from "app/components/StyleVariables";
import {VersionsPanel} from "app/components/detailpanel/VersionsPanel";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import {DataGridPro} from "@mui/x-data-grid-pro/DataGridPro";
import FormControlLabel from "@mui/material/FormControlLabel";

export const S = {

    FilterSetBuilder: styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `,

    Content: styled.div`
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    `,

    ValuesInputPanel: styled.div`
        display: flex;
        flex-direction: column;
        padding: 30px;
        height: 100%;
        overflow: auto;
        width: 950px;
        border-right: 1px solid ${Colors.borderGrey};
    `,
    InputHelpText: styled.div<{ hasInputError: boolean }>`
        color: ${props => props.hasInputError ? Colors.formError : Colors.textPrimary};
        font-size: ${FontSizes.small};
        padding: 12px 0;
    `,

    TextField: styled(TextField)`
        .MuiInputBase-root {
            width: 100%;
            .MuiInputBase-inputMultiline {
                font-size: ${FontSizes.xSmall};
                font-family: monospace;
                overflow-x: wrap;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }
    `,

    AreHexValuesCheckbox: styled(FormControlLabel)`
        margin-top: 10px;
        .MuiFormControlLabel-label {
            font-size: ${FontSizes.small};        
        }
    `,

    ValuesPreviewPanel: styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
    `,

    DataGridPro: styled(DataGridPro)`
        .MuiDataGrid-columnHeaders {
            background-color: ${Colors.headerGrey};
        }
        
        .cellText {
            font-size: ${FontSizes.xSmall};
        }
    `,

    NoDataPreview: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: ${Colors.textTertiary};    
    `,

    ErrorsInPreview: styled.div`
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
    `,

    VersionsPanel: styled(VersionsPanel)`
        width: 300px;
        border-left: 1px solid ${Colors.borderGrey};
    `
};