import {JsonObject} from "common/CommonTypes";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {GlobalFilterClauseFactory} from "metadata/dashboard/GlobalFilterClauseFactory";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {References} from "metadata/References";

export type GlobalFilterProps = {
    clauses?: GlobalFilterClause[]
}

export class DashboardGlobalFilters {

    static default(): DashboardGlobalFilters {
        return new DashboardGlobalFilters([]);
    }

    static fromJSON(json: JsonObject, references: References): DashboardGlobalFilters {
        return new DashboardGlobalFilters(
            json['clauses'].map((clauseJson: JsonObject) => {
                    return GlobalFilterClauseFactory.fromJSON(clauseJson, references);
                }
            )
        );
    }

    constructor(
        public readonly clauses: GlobalFilterClause[],
    ) {
    }

    get(ordinal: number): GlobalFilterClause {
        return this.clauses[ordinal];
    }

    find(datasetFqn: FQN, field: string): Optional<number> {
        const index = this.clauses.findIndex(v => v.datasetFqn.equals(datasetFqn) && v.column === field);
        return Optional.of(index === -1 ? null : index);
    }

    with({
        clauses
    }: GlobalFilterProps): DashboardGlobalFilters {
        return new DashboardGlobalFilters(
            clauses == null ? this.clauses : [...this.clauses, ...clauses]
        );
    }

    without(ordinal: number): DashboardGlobalFilters {
        return new DashboardGlobalFilters(
            this.clauses.filter((_, i: number) => i !== ordinal)
        );
    }

    replace(ordinal: number, clause: GlobalFilterClause): DashboardGlobalFilters {
        return new DashboardGlobalFilters(
            this.clauses.map((c: GlobalFilterClause, i: number) => i === ordinal ? clause : c)
        );
    }

    equals(other: DashboardGlobalFilters): boolean {
        if (this.clauses.length !== other.clauses.length) {
            return false;
        }

        // if we can't find one that is not equal, then the filters are the same
        return this.clauses.find(
            (c: GlobalFilterClause, cI: number) => !other.clauses[cI].equals(c)
        ) == null;
    }

}

