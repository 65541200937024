import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";
import {Enum} from "common/Enum";
import {ComponentType} from "react";

export class DropShadow extends Enum implements StylingEnum {
    static readonly NONE = new this(
        'none',
        'Disabled',
        undefined,
        {}
    );

    static readonly LIGHT = new this(
        'light',
        'Enabled',
        undefined,
        {boxShadow: 'rgba(50, 50, 93, 0.07) 0px 1px 2px -1px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px'}
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType,
        public readonly styling: { [prop: string]: string }
    ) {
        super(name);
    }
}

DropShadow.finalize();