import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VizSelection} from "engine/actor/VizSelection";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {Optional} from "common/Optional";
import {Enum} from "common/Enum";

export type NodesAndLinks = {
    nodes: {
        label: string,
        source: NodeSource
    }[]
    links: {
        from: string,
        to: string,
        value: number
    }[]
}

export class NodeAndLinkData {

    private nodesAndLinks: NodesAndLinks;

    constructor(
        protected readonly response: ArcQLResponse,
        // if we should suffix values in the from and the to avoid self and circular loops
        protected readonly suffix: boolean
    ) { }

    validate(): Optional<string> {
        const result = this.response.result;
        if (
            result.categoryColumns.length !== 2
            || result.columnsByType(new Set([AnalyticsType.MEASURE])).length !== 1
        ) {
            return Optional.some('Chart type requires exactly 2 categories and 1 measure.');
        }

        return Optional.none();
    }

    dataset(selections: VizSelection): NodesAndLinks {
        if (this.nodesAndLinks == null) {
            const result = this.response.result;
            // need exactly 2 category columns (from and to)
            if (result.categoryColumns.length !== 2) {
                return {
                    nodes: [],
                    links: []
                };
            }

            // iterate through all the row to collect a set of unique category labels
            const categoryColumnIndices = result.categoryColumns.map(c => c.right);
            const categoryLabels: Map<string, NodeSource> = new Map();
            const addCategory = (label: string, source: NodeSource) => {
                const curSource = categoryLabels.get(label);
                if (curSource == null) {
                    categoryLabels.set(label, source);
                } else if (curSource !== source) {
                    categoryLabels.set(label, NodeSource.BOTH);
                }
            };

            // also build links from each row
            const metricIndex = result.columnIndices.get(
                result.columnsByType(new Set([AnalyticsType.MEASURE]))[0]
            );

            // build a tuple for the value label and label with suffix for uniqueness
            const buildLabel = (
                row: any[], formatters: ResultValueFormatter[], category: number
            ): [string, string] => {
                const label = formatters[categoryColumnIndices[category]](row[categoryColumnIndices[category]]);
                const suffixedLabel = this.suffix ?
                    label + (category === 0 ? ' (From)' : ' (To)') :
                    label;

                return [label, suffixedLabel];
            };

            const links = result.mapRows(
                (row: any[], index: string, formatters: ResultValueFormatter[]) => {
                    const from = buildLabel(row, formatters, 0);
                    const to = buildLabel(row, formatters, 1);

                    addCategory(from[1], NodeSource.FROM);
                    addCategory(to[1], NodeSource.TO);

                    return {
                        from: from[1],
                        to: to[1],
                        value: row[metricIndex],
                        alpha: selections.has([from[0], to[0]]) ? 100 : 25
                    };
                }
            );

            this.nodesAndLinks = {
                nodes: Array.from(categoryLabels.entries()).map(
                    ([label, source]: [string, NodeSource]) => ({
                        label: label,
                        source: source
                    })
                ),
                links: links
            };
        }

        return this.nodesAndLinks;
    }

}

/**
 * Where was the node found?
 */
export class NodeSource extends Enum {
    static FROM = new this('from');
    static TO = new this('to');
    static BOTH = new this('both');
}
NodeSource.finalize();
