import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {ArcEvent} from "engine/ArcEvent";
import {TypedEventFilter} from "engine/ArcEventFilter";
import {Optional} from "common/Optional";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {CompositeFilterClause} from "metadata/query/filterclause/CompositeFilterClause";

export class FacetingMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.FACETING;

    constructor(
        public readonly datasetFqn: string,
        public readonly facets: Facet[]
    ) {
    }

}

export interface Facet {

    /**
     * All columns in the facet.
     */
    get columns(): string[]

    /**
     * Convert the facet into a filter clause.
     */
    get filterClause(): FilterClause;

    /**
     * Empty filters.
     */
    get isEmpty(): boolean;

    /**
     * Return a new facet of the same values with the new columns.
     */
    withColumns(columns: string[]): Facet;

}

export class DiscreteFacet implements Facet {

    constructor(
        public readonly columns: string[],
        public readonly rows: (string | number)[][]
    ) {
    }

    get filterClause(): FilterClause {
        if (this.columns.length === 1) {
            return new LiteralsFilterClause(
                this.columns[0],
                FilterOperator.IN,
                Array.from(new Set(this.rows.map(row => row[0]))),
                false
            );
        } else {
            return new CompositeFilterClause(this.columns, this.rows);
        }
    }

    get isEmpty(): boolean {
        return this.rows.length === 0;
    }

    withColumns(columns: string[]): Facet {
        if (this.columns.length !== columns.length) {
            throw new Error('Can only create discrete facet with the same number of columns.')
        }

        return new DiscreteFacet(columns, this.rows);
    }

}

export class RangeFacet implements Facet {

    constructor(
        public readonly column: string,
        public readonly min: number | string,
        public readonly max: number | string
    ) {
    }

    get columns(): string[] {
        return [this.column];
    }

    get filterClause(): FilterClause {
        return new LiteralsFilterClause(
            this.column,
            FilterOperator.BETWEEN,
            [this.min, this.max],
            false
        );
    }

    get isEmpty(): boolean {
        return this.min == null || this.max == null;
    }

    withColumns(columns: string[]): Facet {
        if (columns.length != 1) {
            throw new Error('Can only create range with 1 column.')
        }

        return new RangeFacet(columns[0], this.min, this.max);
    }

}

export class FacetingEventFilter extends TypedEventFilter<FacetingMessage> {

    constructor(
        public readonly datasetFqn?: string
    ) {
        super(FacetingMessage);
    }

    filter(event: ArcEvent): Optional<FacetingMessage> {
        return super.filter(event)
            // if it's the right type, check the dataset
            .filter(m => this.datasetFqn == null || m.datasetFqn === this.datasetFqn);
    }

}