import {JsonObject} from "common/CommonTypes";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FQN} from "common/FQN";
import {FilterClause, FilterSetValue, FilterValue} from "metadata/query/filterclause/FilterClause";
import isEqual from "lodash/isEqual";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {References} from "metadata/References";
import {FilterSetFilterClause} from "metadata/query/filterclause/FilterSetFilterClause";

/**
 * Global FilterSet filter clause is just a FilterSet filter clause with a dataset reference.
 */
export class GlobalFilterSetFilterClause extends FilterSetFilterClause implements GlobalFilterClause {

    static fromJSON(json: JsonObject, references: References): GlobalFilterSetFilterClause {
        const filterSetClause: FilterSetFilterClause = FilterSetFilterClause.fromJSON(json, references);
        return new GlobalFilterSetFilterClause(
            json['datasetFullyQualifiedName'],
            filterSetClause
        );
    }

    public readonly datasetFqn: FQN;

    constructor(
        datasetFullyQualifiedName: string,
        baseClause: FilterSetFilterClause
    ) {
        super(baseClause.column, baseClause.fullyQualifiedName, baseClause.include, baseClause.filterSetAsset);
        this.datasetFqn = FQN.parse(datasetFullyQualifiedName);
    }

    equals(other: GlobalFilterClause): boolean {
        return this.datasetFqn.equals(other.datasetFqn)
            && this.column === other.column
            && this.operator === other.operator
            && isEqual(this.values, other.values);
    }

    with(operator: FilterOperator, values: FilterValue[]): GlobalFilterClause {
        return new GlobalFilterSetFilterClause(
            this.datasetFqn.toString(),
            new FilterSetFilterClause(
                this.column,
                this.fullyQualifiedName,
                operator === FilterOperator.IN,
                values[0] as FilterSetValue
            )
        );
    }

    withColumn(datasetFqn: FQN, column: string): GlobalFilterClause {
        return new GlobalFilterSetFilterClause(
            datasetFqn.toString(),
            new FilterSetFilterClause(
                column,
                this.fullyQualifiedName,
                this.include,
                this.filterSetAsset
            )
        );
    }

    toBaseFilterClause(): FilterClause {
        return new FilterSetFilterClause(this.column, this.fullyQualifiedName, this.include, this.filterSetAsset);
    }

    toJSON(): JsonObject {
        return {
            'datasetFullyQualifiedName': this.datasetFqn.toString(),
            ...super.toJSON()
        };
    }
}