import {TrendS} from "app/dashboard/components/TrendS";
import Tooltip from "@mui/material/Tooltip";
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import * as React from "react";
import {ChangeEvent, FunctionComponent} from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {CreatedInfo} from "app/components/decoration/CreatedInfo";
import Select from "@mui/material/Select";
import {ArcTrendListItem} from "metadata/trend/ArcTrendListItem";
import MenuItem from "@mui/material/MenuItem";
import {ArcTrend} from "metadata/trend/ArcTrend";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
    className?: string;
    trends: ArcTrendListItem[];
    currentTrend: ArcTrend;
    onSelectTrend: (trend: ArcTrendListItem) => void;
}

/**
 * Dropdown for selecting a trend from a list of trends.
 */
export const TrendListSelect: FunctionComponent<Props> = (props: Props) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedTrendId = event.target.value as string;
        const selectedTrend = props.trends.find(trend => trend.id === selectedTrendId);
        if (selectedTrend.id !== props.currentTrend.id) {
            props.onSelectTrend(selectedTrend);
        }
    };

    return (
        <S.Select
            className={props.className}
            value={props.currentTrend.id}
            onChange={handleChange}
            renderValue={(id: string) => {
                const trend =  props.trends.find(trend => trend.id === id);
                return <span>{trend.label}</span>;
            }}
            // for menu dropdown styling, must override via this way as it's a completely separate element (i.e. can't
            // be targeted via emotion/styled)
            MenuProps={{
                sx: {
                    '.MuiMenu-paper': {
                        '.MuiList-root': {
                            padding: 0,
                        },
                    },
                },
            }}
        >
            {props.trends.map((trend, index) => (
                <S.MenuItem value={trend.id} key={index} divider={true}>
                    <ListItemIcon>
                        {props.currentTrend.id === trend.id && <CheckIcon color="primary"/>}
                    </ListItemIcon>
                    <S.TrendDetails>
                        <S.TitleContainer>
                            <S.TrendTitle>{trend.label}</S.TrendTitle>
                            <CreatedInfo
                                createdBy={trend.createdBy}
                                createdOn={trend.createdOn}
                            />
                        </S.TitleContainer>
                        <S.Badges>
                            <TrendS.BadgeIcon badgeContent={trend.filters.clauses.length} color="primary">
                                <Tooltip
                                    title={
                                        <TrendS.Bullets>
                                            {trend.filters.clauses.map((filter, index) => (
                                                <li key={index}>
                                                    {`${filter.fields} ${filter.description(true)}`}
                                                </li>
                                            ))}
                                        </TrendS.Bullets>
                                    }
                                >
                                    <FilterListIcon/>
                                </Tooltip>
                            </TrendS.BadgeIcon>
                            <TrendS.BadgeIcon badgeContent={trend.alertsCount} color="primary">
                                <Tooltip title={`${trend.alertsCount} Alerts`}>
                                    <NotificationsOutlined/>
                                </Tooltip>
                            </TrendS.BadgeIcon>
                        </S.Badges>

                    </S.TrendDetails>
                </S.MenuItem>
            ))}
        </S.Select>
    );
};

class S {

    static readonly Select = styled(Select)`
    `;

    static readonly MenuItem = styled(MenuItem)`
        width: 500px;
    `;

    static readonly TrendDetails = styled.div`
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    `;

    static readonly TitleContainer = styled.div`
        color: ${Colors.textPrimary};
        font-size: ${FontSizes.small};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    `;

    static readonly TrendTitle = styled.div`
        margin: 0;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

    static readonly Badges = styled.div`
        display: flex;
        gap: 20px;
        align-items: center;

        svg {
            height: 18px;
        }
    `;
}