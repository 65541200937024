import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {AddGlobalFilter} from "metadata/dashboard/changes/AddGlobalFilter";

export class DeleteGlobalFilter implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly ordinal: number
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return new Tuple(
            metadata.with({
                globalFilters: metadata.globalFilters.without(this.ordinal)
            }),
            new AddGlobalFilter(metadata.globalFilters.get(this.ordinal))
        );
    }

    describe(metadata: ArcDashboard): string {
        const globalFilter = metadata.globalFilters.get(this.ordinal);
        return `Deleted global filter for ${globalFilter.column} on ${globalFilter.datasetFqn.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}