import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {Json, JsonObject} from "common/CommonTypes";
import {References} from "metadata/References";
import {Either, Left, Right} from "common/Either";
import {FQN} from "common/FQN";
import {ErrorResponse} from "services/ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {ReferencingFilterClause} from "metadata/query/filterclause/ReferencingFilterClause";

export class ExternalFilterState<T extends FilterClause> {

    constructor(
        public readonly clauses: T[]
    ) {}

    static async decodeExternalState<T extends FilterClause>(
        json: Json,
        label: string,
        fromJSON: (json: Json, refs: References) => T
    ): Promise<Either<string, ExternalFilterState<T>>> {
        try {
            if (!Array.isArray(json)) {
                return new Left(`Additional ${label} need to be an array.`);
            }

            const unreferencedClauses: FilterClause[] = json.map((obj: JsonObject) => fromJSON(obj, null));

            const fqnsToReference: FQN[] = unreferencedClauses
                .filter(c => c.type.hasAssetRefs)
                .map((c: ReferencingFilterClause) => FQN.parse(c.fullyQualifiedName));

            const resp: Either<ErrorResponse, References> = await ServiceProvider.get(MetadataService)
                .fetchReferences(new Set(fqnsToReference));

            if (resp.isLeft) {
                return new Left(`Failed to apply ${label}.`);
            }

            const validClauses = json.map(obj => fromJSON(obj, resp.rightOrThrow()))
                .filter(c => c.isStructureValid);

            if (validClauses.length === 0) {
                return new Left(`No valid ${label} to apply.`);
            }
            return new Right(new ExternalFilterState(validClauses));
        } catch (e) {
            return new Left(`Could not decode additional ${label} from URL.`);
        }
    }

}