import {ResultFormatter, ResultValueFormatter} from "metadata/query/ResultFormatter";
import {Tuple} from "common/Tuple";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {Optional} from "common/Optional";
import {ColumnField} from "metadata/query/ArcQLField";
import {JsonObject} from "common/CommonTypes";

export class QueryResult {

    // Map of column name to the column index.
    public readonly columnIndices: Map<string, number>;

    // Formatters for each column.
    public readonly columnFormatters: ResultValueFormatter[];

    // Map of rows by their index, if it is a grouped query, the groupings form the index, otherwise it will be row
    // ordinal as a string.
    public readonly indexedRows: Map<string, any[]>;
    // Tuples (column name, column index) of the columns used to build the row indices.
    public readonly indexColumns: Tuple<string, number>[];

    constructor(
        private readonly json: { [key: string]: any },
        private readonly query: ArcQL,
        private readonly dataset: ArcDataset,
        // string used to concatenate column values to build partial or full indexes
        public readonly indexJoiner: string = ' > '
    ) {
        this.columnIndices = new Map(
            this.columns.map((c: string, i: number) => [c, i])
        );

        // build out formatters for all groupings and fields
        this.columnFormatters = new Array(this.columnIndices.size);
        this.query.groupings.fields.forEach(
            grouping => {
                this.columnFormatters[this.columnIndices.get(grouping.projectedAs)] = ResultFormatter.groupingFormatter(grouping);
            }
        );
        this.query.fields.fields.forEach(
            field => {
                this.columnFormatters[this.columnIndices.get(field.as)] = ResultFormatter.fieldFormatter(field);
            }
        );

        this.indexColumns = this.query.groupings.fields.map(grouping => grouping.projectedAs)
            // find the index of the column to build into an index
            .map(c => Tuple.of(c, this.columnIndices.get(c)));

        // map each row to its index
        this.indexedRows = new Map(this.rows.map(
            (row: any[], rowI: number) => [this.indexForRow(row, rowI), row]
        ));
    }

    get sql(): string {
        return this.json['sql'];
    }

    get columns(): string[] {
        return this.json['columns'];
    }

    get rows(): any[][] {
        return this.json['rows'];
    }

    get length(): number {
        return this.rows.length;
    }

    /**
     * Return the columns for the specific types across fields and groupings.
     */
    columnsByType(types: Set<AnalyticsType>): string[] {
        return [
            ...this.query.fields.fields
                .filter(field => types.has(field.analyticsType(this.dataset)))
                .map(field => field.as),
            ...this.query.groupings.fields
                .filter(grouping => types.has(grouping.analyticsType(this.dataset)))
                .map(grouping => grouping.field)
        ];
    }

    /**
     * Return all the non-metric columns that "describe" a row. If grouped, this will be unique.
     */
    get categoryColumns(): Tuple<string, number>[] {
        return this.query.isGrouped()
            ? this.indexColumns
            : this.columnsByType(new Set([AnalyticsType.DIMENSION, AnalyticsType.DATE]))
                .map(c => Tuple.of(c, this.columnIndices.get(c)));
    }

    /**
     * Return all unique values for the given field.
     */
    unique(field: string): Set<string | number> {
        return new Set(this.values(field));
    }

    /**
     * Get values for a specific field.
     */
    values(column: string): any[] {
        const columnIndex = this.columnIndices.get(column);
        return this.rows.map((row: any[]) => row[columnIndex]);
    }

    /**
     * Iterate through all rows
     */
    forEachRow(f: (row: any[], index: string, formatters: ResultValueFormatter[]) => void): void {
        return Array.from(this.indexedRows.entries()).forEach(
            ([index, row]: [string, any[]]) => f(row, index, this.columnFormatters)
        );
    }

    /**
     * Map each row.
     */
    mapRows<T>(f: (row: any[], index: string, formatters: ResultValueFormatter[]) => T): T[] {
        return Array.from(this.indexedRows.entries()).map(
            ([index, row]: [string, any[]]) => f(row, index, this.columnFormatters)
        );
    }

    /**
     * Get the unique index string for a row.
     */
    indexForRow(row: any[], rowI: number): string {
        if (this.indexColumns.length === 0) {
            return rowI.toString();
        } else {
            return this.indexColumns.map(column => row[column.right]).join(this.indexJoiner);
        }
    }

    /**
     * Build the label for given columns in a row of data using column formatters.
     */
    rowLabel(row: any[], columnIndices: number[], rowIndex: string = null): string {
        const values = columnIndices.map(i => this.columnFormatters[i](row[i]));
        if (rowIndex != null) {
            values.unshift(rowIndex);
        }

        return values.join(this.indexJoiner);
    }

    /**
     * Get a row by index values.
     */
    indexedRow(indexValues: string[]): any[] {
        return this.indexedRows.get(indexValues.join(this.indexJoiner));
    }

    sort(rowComparator: (a: any[], b: any[]) => number): QueryResult {
        const newRows = this.rows.slice();
        newRows.sort(rowComparator);
        return new QueryResult(
            Object.assign({}, this.json, {
                'columns': this.columns,
                'rows': newRows
            }),
            this.query,
            this.dataset,
            this.indexJoiner
        );
    }

    /**
     * Filter the existing rows into a new result.
     */
    filter(f: (row: any[]) => boolean): QueryResult {
        return new QueryResult(
            Object.assign({}, this.json, {
                'columns': this.columns,
                'rows': this.rows.filter(f)
            }),
            this.query,
            this.dataset,
            this.indexJoiner
        );
    }

    /**
     * Extend the current results with new rows.
     */
    extend(rows: any[][], addToStart: boolean = false): QueryResult {
        const newRows = this.rows.slice();
        if (addToStart) {
            newRows.unshift(...rows);
        } else {
            newRows.push(...rows);
        }

        return new QueryResult(
            Object.assign({}, this.json, {
                'columns': this.columns,
                'rows': newRows
            }),
            this.query,
            this.dataset,
            this.indexJoiner
        );
    }

    /**
     * Return column tuples [name, index] that may contain urls.
     */
    get urlColumns(): [string, number][] {
        // figure out which rows to sample
        const rowsToCheck: any[] = [];
        if (this.length > 0) {
            // if there are results, check the first
            rowsToCheck.push(0);
            // if there is more than one row, check the last
            if (this.length > 1) {
                rowsToCheck.push(this.length - 1);
            }
            // if there are more than 2 rows, check a middle
            if (this.length > 2) {
                rowsToCheck.push(Math.round(this.length / 2))
            }
        }

        return Array.from(this.columnIndices.entries())
            .filter(([column, columnI]: [string, number]) =>
                rowsToCheck.some((rowI) => {
                    const value = this.rows[rowI][columnI];
                    return typeof value === 'string' && (
                        value.startsWith('http://') || value.startsWith('https://')
                    );
                })
            )
    }

    /**
     * Return rows as objects keyed by their column name specifying a key to store the row index as and an optional
     * formatter for any fields that are numbers.
     */
    objectRows(indexField: string, numberFormatter: ResultValueFormatter = null): JsonObject[] {
        const columns = this.columns;

        const columnRefs = this.columnRefs;
        const objectify = (index: string, row: any[]): JsonObject => {
            const rowMapped: JsonObject = {};
            columns.forEach((c: string, cI: number) => {
                const value = row[cI];
                const formatter = (() => {
                    if (numberFormatter && !columnRefs[cI].isGrouping && typeof value === 'number') {
                        return numberFormatter;
                    } else if (this.columnFormatters[cI]) {
                        // use the formatter if we have one
                        return this.columnFormatters[cI];
                    } else {
                        // use the identity formatter if we don't, these will be hidden system fields
                        return ResultFormatter.identityFormatter;
                    }
                })();

                rowMapped[c] = formatter(row[cI]);
            });
            rowMapped[indexField] = index;

            return rowMapped;
        };

        return this.mapRows((row: any[], index: string) => objectify(index, row));
    }

    // Map by column index to identify-able metadata about it.
    public get columnRefs(): ColumnRef[] {
        return [
            ...this.query.groupings.fields.map(grouping => ({
                isGrouping: true,
                name: grouping.field,
                label: grouping.label(this.dataset),
                projectedAs: grouping.projectedAs
            })),
            ...this.query.fields.fields.map(field => ({
                isGrouping: false,
                name: Optional.ofType(field, ColumnField).map(c => c.field).nullable,
                label: field.as,
                projectedAs: field.as
            }))
        ];
    }

    toJSON(): any {
        return this.json;
    }

}

export type ColumnRef = {

    // if it's a grouping or field
    isGrouping: boolean

    // referenced dataset column name, if any
    name: string | null,

    // human readable label to use
    label: string,

    // the name the column is projected as and retrievable in results
    projectedAs: string

}