import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";

/**
 * @author zuyezheng
 */
export class DonutChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON,
        CommonChartProperties.LEGEND
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', false]
    ]);

    get properties(): VizProperty<any>[] {
        return DonutChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return DonutChartDefinition.DEFAULTS;
    }

}