import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import React, {FunctionComponent} from "react";
import Box from "@mui/material/Box";

type Props = {
    onClose: () => void
}

/**
 * Dialog for when a user has clicked the verification link and can now login
 */
export const EmailVerificationCompleteDialog: FunctionComponent<Props> = (props: Props) => {
    return <Dialog
        open={true}
        onClose={props.onClose}
        maxWidth="sm"
    >
        <Container sx={{ py:6 }}>
            <Stack spacing={ 2 } sx={{ px: 2 }}>
                <Typography variant="h5" color="text.primary" align="center" gutterBottom>
                    Thanks for verifying your email! Please login to continue.
                </Typography>
                <Box alignSelf="center">
                    <Button variant="outlined" size="small" color="inherit" onClick={props.onClose}>Login</Button>
                </Box>
            </Stack>
        </Container>
    </Dialog>;
};