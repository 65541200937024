import * as React from "react";
import {FunctionComponent, useContext, useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {FQN} from "common/FQN";
import styled from "@emotion/styled";
import {ServiceProvider} from "services/ServiceProvider";
import {Optional} from "common/Optional";
import {Folders} from "metadata/project/Folders";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {CircularProgress} from "@mui/material";
import {DialogS} from "app/DialogS";
import {UserContext} from "app/UserContext";
import {FolderResult} from "metadata/project/FolderResult";
import {LoadingMask} from "app/components/decoration/LoadingMask";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {DatasetV2Service} from "services/DatasetV2Service";
import {AssetType} from "metadata/AssetType";
import {FileUploadForm} from "app/components/form/FileUploadForm";
import {useAssetFormState} from "app/components/form/AssetFormState";
import {LabelInput} from "app/components/form/LabelInput";
import {AccountInput} from "app/components/form/AccountInput";
import {ProjectInput} from "app/components/form/ProjectInput";
import {AssetUrlInput} from "app/components/form/AssetUrlInput";
import {StringUtils} from "common/StringUtils";
import {FontSizes} from "app/components/StyleVariables";
import {UserService} from "services/UserService";
import {DatasetListItem} from "metadata/dataset/DatasetListItem";

type Props = {
    fqn: FQN;
    currentDatasets?: DatasetListItem[];

    onCancel: () => void;
    onUpload: (datasetV2: DatasetV2) => void;
};

export const CreateUploadDialog: FunctionComponent<Props> = (props: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<Optional<string>>(Optional.none);
    const [personalDatasets, setPersonalDatasets] = React.useState<Optional<DatasetListItem[]>>(Optional.of(props.currentDatasets));

    const userContext = useContext(UserContext);
    const loggedInUserName = userContext.user.map(u => u.username).getOr(null);

    const {
        account,
        project,
        label,
        name,
        accounts,
        folders,
        saveErrors,
        setAccount,
        setProject,
        setLabel,
        setName,
        setSaveErrors
    } = useAssetFormState({
        fqn: props.fqn,
        loggedInUserName: loggedInUserName,
    });

    const loadPersonalDatasets = () => {
        ServiceProvider.get(UserService)
            .listDatasets()
            .then(response => response.match(
                datasets => setPersonalDatasets(Optional.some(datasets)),
                () => ServiceProvider.get(NotificationsService).publish(
                    'CreateUploadDialog', NotificationSeverity.ERROR, 'Could not load personal datasets.'
                )
            ));
    };

    useEffect(() => {
        if (personalDatasets.isNone) {
            loadPersonalDatasets();
        }
    }, [personalDatasets]);

    const onUpload = () => {
        setUploading(true);
        setUploadError(Optional.none);
        ServiceProvider.get(DatasetV2Service)
            .upload(new FQN(account, project, AssetType.DATASET_V2, name), file, label)
            .then(response => {
                setUploading(false);
                response.match(
                    dataset => {
                        props.onUpload(dataset);
                    },
                    error => {
                        const errorMsg = `Upload failed: ${error.prettyPrint()}`;
                        ServiceProvider.get(NotificationsService).publish(
                            'UploadDatasetDialog.onUpload', NotificationSeverity.ERROR, errorMsg
                        );
                        setSaveErrors(error.fieldErrors());
                        setUploadError(Optional.some(errorMsg));
                    }
                );
            })
            .finally(
                () => setUploading(false)
            );
    };

    const buildDisclaimerContent = (numDatasets: number) => {
        if (numDatasets < 5) {
            return <strong>
                You are currently using {numDatasets} of your 5 datasets.
            </strong>;
        }
        return <strong>
            All 5 datasets used, feel free to update an existing.
        </strong>;
    };

    const handleClose = () => {
        if (uploading) {
            ServiceProvider.get(NotificationsService).publish(
                'CreateUploadDialog.handleClose',
                NotificationSeverity.WARNING,
                'Please wait while upload is in progress.'
            );
            return;
        }
        props.onCancel();
    };

    const invalidUpload = (numDatasets: number) => {
        return StringUtils.isAbsent(name) || StringUtils.isAbsent(label)
            || StringUtils.isAbsent(project) || file == null
            || (!userContext.isSuperUser && numDatasets >= 5);
    };

    return <Dialog
        open={true}
        onClose={handleClose}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        {
            Optional.all([accounts, folders, personalDatasets]).map(([accounts, folders, datasets]: [string[], Folders, DatasetListItem[]]) =>
                <>
                    <DialogS.Title>Upload new dataset as...</DialogS.Title>
                    <DialogS.Content dividers>
                        <DialogS.Body>
                            <DialogS.InputRow>
                                <LabelInput
                                    label={label}
                                    name={name}
                                    setLabel={setLabel}
                                    setName={setName}
                                    saveErrors={saveErrors}
                                />
                            </DialogS.InputRow>
                            <DialogS.SelectorRow>
                                <AccountInput
                                    account={account}
                                    accounts={accounts}
                                    onSelectAccount={setAccount}
                                    disabled={!userContext.isSuperUser}
                                />
                                <ProjectInput
                                    loggedInUserName={loggedInUserName}
                                    folders={folders}
                                    selected={project}
                                    onSelectFolder={(folder: FolderResult) => setProject(folder.name)}
                                />
                            </DialogS.SelectorRow>
                            <DialogS.InputRow>
                                <AssetUrlInput
                                    account={account}
                                    project={project}
                                    name={name}
                                    fqn={props.fqn}
                                    onEdit={setName}
                                    saveErrors={saveErrors}
                                />
                            </DialogS.InputRow>
                            <FileUploadForm
                                file={file}
                                onFileSelected={(file: File) => setFile(file)}
                                onFileRemoved={() => setFile(null)}
                            />
                            <S.Disclaimer>
                                {buildDisclaimerContent(datasets.length)}
                            </S.Disclaimer>
                            {
                                uploadError.map(err =>
                                    <S.ErrorText>{err}</S.ErrorText>
                                ).nullable
                            }
                        </DialogS.Body>
                    </DialogS.Content>
                    <DialogS.Actions>
                        <Button variant="outlined" onClick={props.onCancel} disabled={uploading}>Cancel</Button>
                        <Button
                            variant="contained"
                            onClick={onUpload}
                            disabled={invalidUpload(datasets.length) || uploading}
                        >
                            {uploading ? <CircularProgress size={24}/> : 'Upload'}
                        </Button>
                    </DialogS.Actions>
                </>
            ).getOrElse(() =>
                <LoadingMask containerDimensions={['100%', '200px']}/>
            )
        }
    </Dialog>;
};

class S {

    static readonly Disclaimer = styled.div`
        font-size: ${FontSizes.small};
    `;

    static readonly ProjectInput = styled.div`
        display: contents;
    `;

    static readonly ErrorText = styled.div`
        font-size: ${FontSizes.small};
        color: red;
        margin-top: 10px;
    `;
}