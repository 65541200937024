import React, {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {LoadedSearchResult} from "app/components/search/AssetSearchResultsTable";
import Radio from "@mui/material/Radio";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Colors, FontSizes} from "app/components/StyleVariables";
import dayjs from "dayjs";
import ListItem from "@mui/material/ListItem";

interface Props {
    isSelected: boolean
    loadedResult: LoadedSearchResult
    detailed: boolean
}

export const SearchResultsListItem: FunctionComponent<Props> = (props: Props) => {
    return <S.ListItem key={props.loadedResult.id}>
        <Radio
            checkedIcon={<CheckCircleIcon/>}
            checked={props.isSelected}
            value={props.loadedResult.result.fullyQualifiedName}
        />
        <S.ListItemInfo>
            <S.ListItemHeaderName
                isSelected={props.isSelected}
            >
                {props.loadedResult.result.label}
            </S.ListItemHeaderName>
            {props.detailed && <S.ListItemDetails>
                • Created
                by {props.loadedResult.result.createdBy} on {dayjs(props.loadedResult.result.lastUpdatedOn).format("MMM D, YYYY")}
            </S.ListItemDetails>}
            <S.ListItemPath>@{props.loadedResult.result.projectPath}</S.ListItemPath>
        </S.ListItemInfo>
    </S.ListItem>;
};

class S {
    static ListItem = styled(ListItem)`
        padding: 12px 0px;
        display: flex;
        // Align items to the top
        align-items: flex-start;
    `;

    static ListItemInfo = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static ListItemHeaderName = styled.span<{ isSelected: boolean }>`
        font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};
        font-size: ${FontSizes.medium};
        color: ${props => (props.isSelected ? Colors.textPrimary : Colors.textSecondary)};
    `;

    static ListItemDetails = styled.span`
        font-size: ${FontSizes.xSmall};
        color: ${Colors.textSecondary};
        margin-top: 2px;
    `;

    static ListItemPath = styled.span`
        font-size: ${FontSizes.xSmall};
        color: ${Colors.textDisabled};
        margin-top: 2px;
    `;
}