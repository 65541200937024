import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetStatusMetadata} from "metadata/dataset/DatasetStatusMetadata";
import {DatasetStatusDisplay} from "metadata/dataset/DatasetStatusDisplay";


type BigQueryDatasetStatusMetadataJson = {}

/**
 * Metadata for a BigQuery dataset status.
 */
export class BigQueryDatasetStatusMetadata implements DatasetStatusMetadata {

    static fromJSON(json: BigQueryDatasetStatusMetadataJson): BigQueryDatasetStatusMetadata {
        return new BigQueryDatasetStatusMetadata();
    }

    get sources(): DatasetType[] {
        return [DatasetType.BIG_QUERY];
    }

    get isStreamable(): boolean {
        return false;
    }

    get type(): DatasetType {
        return DatasetType.BIG_QUERY;
    }

    get statusDisplay() {
        return DatasetStatusDisplay.ZERO_COPY;
    }
}