import {HyperArcUser, HyperArcUserJson} from "metadata/HyperArcUser";
import {UserSubscriptionType} from "metadata/subscription/UserSubscriptionType";
import {JsonObject} from "common/CommonTypes";
import {SubscriptionMetadata} from "metadata/subscription/SubscriptionMetadata";
import {SubscriptionMetadataFactory} from "metadata/subscription/SubscriptionMetadataFactory";

export type UserSubscriptionJson = {
    id: string,
    user: HyperArcUserJson,
    label: string,
    description: string,
    type: string,
    subscribedToId: string,
    isActive: boolean,
    createdOn: string,
    updatedOn: string,
    metadata: JsonObject
}

/**
 * User Subscription model.
 */
export class UserSubscription {
    constructor(
        public readonly id: string = null,
        public readonly user: HyperArcUser = null,
        public readonly label: string = null,
        public readonly description: string = null,
        public readonly type: UserSubscriptionType = null,
        public readonly subscribedToId: string = null,
        public readonly isActive: boolean = true,
        public readonly createdOn: string = null,
        public readonly updatedOn: string = null,
        public readonly metadata: SubscriptionMetadata = null
    ) {
    }

    static fromJSON(json: UserSubscriptionJson): UserSubscription {
        return new UserSubscription(
            json.id,
            HyperArcUser.fromJSON(json.user),
            json.label,
            json.description,
            UserSubscriptionType.get(json.type),
            json.subscribedToId,
            json.isActive,
            json.createdOn,
            json.updatedOn,
            SubscriptionMetadataFactory.fromJSON(json.metadata)
        );
    }
}