import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import AceEditor from "react-ace";
import {Optional} from "common/Optional";
import {CommonS} from "app/components/CommonS";
import {Colors} from "app/components/StyleVariables";
import "ace-builds/src-noconflict/mode-sql";

interface Props {
    className?: string,
    sql: Optional<string>,
    onApply?: (json: string) => void;
}

export const SqlPanel: FunctionComponent<Props> = (props: Props) => {

    const [editedSql, setEditedSql] = useState<string>();

    useEffect(() => {
        setEditedSql(props.sql.getOr(''));
    }, [props.sql]);

    const onChange = (text: string) => {
        setEditedSql(text);
    };

    return <S.DevPanel className={props.className}>
        <S.Editor>
            <AceEditor
                mode="sql"
                theme="github"
                height="100%"
                width="100%"
                value={editedSql}
                onChange={onChange}
                editorProps={{
                    $blockScrolling: true
                }}
                setOptions={{
                    printMargin: 120,
                    wrap: true,
                    useWorker: false
                }}
            />
        </S.Editor>
        {
            Optional.of(props.onApply)
                .map(() =>
                    <S.Footer>
                        <CommonS.Buttons>
                            <CommonS.Button onClick={() => props.onApply(editedSql)}>Apply</CommonS.Button>
                        </CommonS.Buttons>
                    </S.Footer>
                )
                .getOr(<></>)
        }

    </S.DevPanel>;

};

const S = {
    DevPanel: styled.div`
        display: flex;
        flex-direction: column;
    `,
    Editor: styled.div`
        flex: 1
    `,
    Footer: styled.div`
        border-top: 1px solid ${Colors.borderGrey};
        padding: 10px;
    `
};
