import * as React from "react";
import {FunctionComponent} from "react";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ExpressionGrouping} from "metadata/query/ExpressionGrouping";
import styled from "@emotion/styled";
import {CommonS} from "app/components/CommonS";
import {Enum} from "common/Enum";
import Popover, {PopoverOrigin} from "@mui/material/Popover";
import {Optional} from "common/Optional";
import {DateGroupingSettingView} from "app/query/components/DateGroupingSettingView";
import {DateGrouping} from "metadata/query/DateGrouping";
import {ExpressionEditorContent} from "app/query/components/ExpressionEditorContent";
import {ColumnsPicker} from "app/query/components/ColumnsPicker";
import {ArcQLGroupingFactory} from "metadata/query/ArcQLGroupingFactory";
import {AnalyticsType} from "metadata/AnalyticsType";
import {FieldSettingView} from "app/query/components/setting/FieldSettingView";

type Props = {
    // element and where to anchor the editor popup
    el: Element
    anchorOrigin: PopoverOrigin
    transformOrigin?: PopoverOrigin

    arcQLBundle: ArcQLBundle

    // grouping being modified
    grouping: Optional<ArcQLGrouping>
    // callback with the modified or new grouping
    onChange: (grouping: ArcQLGrouping) => void
    // close the editor without any changes
    onClose: () => void
}

class GroupingEditorMode extends Enum {

    static readonly FIELD = new this('field', "Select a Group");
    static readonly EXPRESSION = new this('expression', "Create Expression Field");

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}

GroupingEditorMode.finalize();

/**
 * Dialog box for editing or adding a grouping.
 */
export const GroupingEditor: FunctionComponent<Props> = (props: Props) => {
    const [mode, setMode] = React.useState<GroupingEditorMode>(GroupingEditorMode.FIELD);
    const [pendingGrouping, setPendingGrouping] = React.useState<Optional<ArcQLGrouping>>(
        props.grouping
    );

    const onChangeMode = (_: React.MouseEvent<HTMLElement>, modeName: string) => {
        setMode(GroupingEditorMode.get<GroupingEditorMode>(modeName));
    };

    const buildEditor = () => {
        switch (mode) {
            case GroupingEditorMode.FIELD:
                // if selected context has advanced view, display that
                return pendingGrouping.map(
                    pending => Optional.ofType(pending, DateGrouping).map(
                        dg => <FieldSettingView
                            fieldLabel={props.arcQLBundle.dataset.get(dg.field).label}
                            fieldCategorization="Group"
                            settingLabel="date/time grain"
                            modifying={props.grouping.map(g => g.field === dg.field).getOr(false)}
                            field={dg}
                            items={dg.grains}
                            isSelected={item => dg.grain === item}
                            onChange={item => props.onChange(dg.withGrain(item) as DateGrouping)}
                            onBack={() => setPendingGrouping(Optional.none)}
                        />
                    ).nullable
                ).getOrElse(
                    // else show field selection list
                    () => <ColumnsPicker
                        dataset={props.arcQLBundle.dataset}
                        isSaved={false}
                        isFieldSelected={(field) => props.grouping.map(g => g.field === field.name).getOr(false)}
                        onClick={
                            (col) => props.onChange(ArcQLGroupingFactory.fromColumn(col))
                        }
                        hasFieldSettingView={(column) => column.analyticsType === AnalyticsType.DATE}
                        onFieldSettingView={
                            (column) =>
                                setPendingGrouping(
                                    // if existing grouping, use that. else create / build new
                                    props.grouping.map(g => g.field === column.name ? Optional.of(g) : null)
                                        .getOr(Optional.of(ArcQLGroupingFactory.fromColumn(column)))
                                )
                        }
                    />
                );
            case GroupingEditorMode.EXPRESSION:
                return <ExpressionEditorContent
                    arcQLBundle={props.arcQLBundle}
                    onChange={(f) => props.onChange(new ExpressionGrouping(f.expression, f.as))}
                    onClose={props.onClose}
                />;
        }
    };

    return <Popover
        open={true}
        anchorEl={props.el}
        onClose={props.onClose}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
    >
        <S.Container>
            <CommonS.Tabs
                value={mode.name}
                onChange={onChangeMode}
                variant="fullWidth"
            >
                {
                    GroupingEditorMode.enums<GroupingEditorMode>().map(
                        m =>
                            <CommonS.Tab
                                key={m.name}
                                value={m.name}
                                label={m.label}
                                disabled={m === GroupingEditorMode.EXPRESSION && props.grouping.isPresent}
                            />
                    )
                }
            </CommonS.Tabs>
            <S.Content>
                {buildEditor()}
            </S.Content>

        </S.Container>

    </Popover>;
};

class S {
    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static readonly Content = styled.div`
        width: 450px;
        max-height: 480px;
        padding: 4px 12px 8px 12px;
    `;
}