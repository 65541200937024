import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";

export class ArcFilterSetValuesChange implements ArcMetadataChange<ArcFilterSet> {

    constructor(
        public readonly values: string[]
    ) {
    }

    apply(metadata: ArcFilterSet): Tuple<ArcFilterSet, ArcMetadataChange<ArcFilterSet>> {
        const updatedData = metadata['data'].withValues(this.values);
        return new Tuple(
            metadata.with({data: updatedData}),
            new ArcFilterSetValuesChange(metadata.data.values)
        );
    }

    describe(): string {
        return `Modified values: total = ${this.values.length}, [first...last] = [${this.values[0]}...${this.values[this.values.length - 1]}`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}