import React, {FunctionComponent, useEffect, useState} from "react";
import {TabProps} from "app/TabType";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {DatasetV2BuilderColumn, DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";
import styled from "@emotion/styled";
import {Durations} from "app/components/StyleVariables";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import {Toolbar} from "app/components/toolbar/Toolbar";
import {Optional} from "common/Optional";
import {
    ToolbarActions,
    ToolbarActionsGroup,
    ToolbarActionsMore,
    ToolbarActionsSection,
    ToolbarActionsSingle
} from "app/components/toolbar/ToolbarActions";
import {StandardActions, ToolbarAction} from "app/components/toolbar/ToolbarAction";
import {SaveMode} from "app/components/SaveMode";
import {SaveAsDialog} from "app/components/SaveAsDialog";
import {SaveDialog} from "app/components/SaveDialog";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {SaveHandler} from "metadata/SaveHandler";
import {GridColDef} from "@mui/x-data-grid-pro";
import {GridRenderCellParams} from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import {AnalyticsType} from "metadata/AnalyticsType";
import {DataType} from "metadata/DataType";
import {DataGridPro} from "@mui/x-data-grid-pro/DataGridPro";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import {BlurTextField} from "app/components/input/BlurTextField";
import {DatasetLoader, LoadResult} from "app/datasetv2/DatasetLoader";
import {useHistory} from "react-router-dom";
import {UpdateUploadDialog} from "app/components/settings/connections/UpdateUploadDialog";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {DatasetV2BuilderActions} from "app/datasetv2/DatasetV2BuilderActions";
import {ArcMetadata} from "metadata/ArcMetadata";
import {DatasetV2BuilderDelegate} from "app/datasetv2/DatasetV2BuilderDelegate";
import {ReplaceReason} from "metadata/ReplaceReason";
import {GridRowHeightParams, GridRowHeightReturnValue} from "@mui/x-data-grid-premium";
import {ProjectionValidation} from "metadata/query/ProjectionValidation";
import {PersonalDatasetConfig} from "metadata/dataset/personal/PersonalDatasetConfig";
import {DatasetV2BuilderHeader} from "app/datasetv2/DatasetV2MetadataHeader";
import {HyperGraphDatasetService} from "services/HyperGraphDatasetService";
import {LoadingMask} from "app/components/decoration/LoadingMask";

const FIELD_IDENTIFIERS = {
    ID: 'id',
    INCLUDE: 'include',
    IS_DIMENSION: 'is_dimension',
    TYPE: 'type',
    NAME: 'name',
    LABEL: 'label',
    DESCRIPTION: 'description',
    RESULT: 'result'
};

const DELEGATE_ID = 'delegate';

type GridRowType = {
    id: string,
    include: boolean,
    is_dimension: boolean,
    type: DataType,
    name: string,
    label: string,
    description: string,
    result: DatasetV2BuilderColumn
};

/**
 * Builder for creating a dataset from a table in a remote data source
 */
export const DatasetV2Builder: FunctionComponent<TabProps> = (props: TabProps) => {

    const [connection, setConnection] = useState<Optional<RemoteDataSource>>(Optional.none());
    const [remoteTableMetadata, setRemoteTableMetadata] = useState<Optional<RemoteTableMetadata>>(Optional.none());
    const [datasetBuilderState, setDatasetBuilderState] = useState<Optional<DatasetV2BuilderState>>(Optional.none());
    const [delegate, setDelegate] = useState<Optional<DatasetV2BuilderDelegate>>(Optional.none());
    const [saveMode, setSaveMode] = useState<Optional<SaveMode>>(Optional.none());
    const [isDescribing, setIsDescribing] = useState<boolean>(false);
    const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        const controller = new AbortController();
        loadDataset(controller.signal).then(
            async (loadResult) => {
                const delegate = new DatasetV2BuilderDelegate(
                    DELEGATE_ID,
                    new ArcMetadata<DatasetV2BuilderState>(loadResult.datasetBuilderState, 50),
                    setDatasetBuilderState
                );

                setDelegate(Optional.of(delegate));
                props.onTabChange(loadResult.datasetBuilderState.label, props.path);
            }
        );
        return () => controller.abort();
    }, []);

    // update the tab with any state changes from labels to unsaved changes
    useEffect(() => {
        Optional.all([delegate, datasetBuilderState]).forEach(([delegate, d]: [DatasetV2BuilderDelegate, DatasetV2BuilderState]) => {
            props.onTabChange(d.label || d.name, props.path, delegate.hasChanges);
        });
    }, [datasetBuilderState]);

    const loadDataset = async (signal?: AbortSignal) => {
        const possibleLoadResult: Optional<LoadResult> = await DatasetLoader.load(
            props.path.assetFqn,
            props.context,
            signal
        );

        // load error on trying to retrieve any of the dataset, the connection, or the remote table metadata
        if (possibleLoadResult.isNone) {
            props.onClose();
            return;
        }

        // successfully loaded the metadata for dataset
        const loadResult = possibleLoadResult.get();
        return onSuccessLoad(loadResult);
    };

    const onSuccessLoad = (loadResult: LoadResult) => {
        setConnection(Optional.of(loadResult.dataSource));
        setRemoteTableMetadata(Optional.of(loadResult.tableMetadata));
        setDatasetBuilderState(Optional.of(loadResult.datasetBuilderState));
        return loadResult;
    };

    const actions: ToolbarActionsSection[] = DatasetV2BuilderActions.visibleToolbarActions()
        .map(a => new ToolbarActionsSingle(a));

    const moreActions = datasetBuilderState.map(state => {
        const visibleActions = DatasetV2BuilderActions.visibleMoreActions(state);
        // if no actions for more, don't show the more actions menu
        if (visibleActions.length == 0) {
            return null;
        }
        return new ToolbarActionsMore([visibleActions]);
    }).array;

    const saveActions = datasetBuilderState.map(state => state.isExisting ?
        new ToolbarActionsSingle(StandardActions.SAVE) :
        new ToolbarActionsSingle(StandardActions.SAVE_AS));
    saveActions.forEach(a => actions.push(a));

    const toolbarActions = new ToolbarActions([
        new ToolbarActionsGroup([
            StandardActions.UNDO(delegate.map(d => !d.hasUndo).getOr(true)),
            StandardActions.REDO(delegate.map(d => !d.hasRedo).getOr(true)),
        ]),
        ...moreActions,
        ...actions
    ]);

    const onToolbarChange = (assetLabel: string) => {
        delegate.forEach(d => d.changeInfo({label: assetLabel}));
    };

    const runDescribe = (state: DatasetV2BuilderState, delegate: DatasetV2BuilderDelegate) => {
        setIsDescribing(true);
        ServiceProvider.get(HyperGraphDatasetService)
            .generateColumnInfos(
                state.label,
                state.fqn.toString(),
                state.description,
                state.rows.map(r => r.toArcColumn())
            )
            .then(either => either.match(
                resp => {
                    setIsDescribing(false);
                    delegate.replace(
                        state.withGeneratedColumnInfos(resp.infos),
                        ReplaceReason.DEV
                    );
                },
                errorResp => {
                    setIsDescribing(false);
                    ServiceProvider.get(NotificationsService).publish(
                        'DatasetV2Builder.runDescribe', NotificationSeverity.ERROR, `Prep failed: ${errorResp.prettyPrint()}`
                    );
                }
            ));
    };

    const onToolbarAction = (action: ToolbarAction) => {
        switch (action.id) {
            case StandardActions.SAVE.id:
                setSaveMode(Optional.some(SaveMode.SAVE));
                break;
            case StandardActions.SAVE_AS.id:
                setSaveMode(Optional.some(SaveMode.SAVE_AS));
                break;
            case StandardActions.UNDO().id:
                delegate.forEach(d => d.undo());
                break;
            case StandardActions.REDO().id:
                delegate.forEach(d => d.redo());
                break;
            case DatasetV2BuilderActions.EXPLORE.name:
                // Navigate to query builder
                history.push('/' + props.path.assetFqn.toString() + '/query');
                break;

            case DatasetV2BuilderActions.UPDATE_DATA.name:
                setShowUpdateDialog(true);
                break;
            case DatasetV2BuilderActions.DESCRIBE.name:
                Optional.all([delegate, datasetBuilderState]).forEach(([delegate, state]: [DatasetV2BuilderDelegate, DatasetV2BuilderState]) => {
                    runDescribe(state, delegate);
                });
                break;
        }
    };


    const onSave = (dataset: DatasetV2) => {
        Optional.all([delegate, remoteTableMetadata]).forEach(([delegate, table]: [DatasetV2BuilderDelegate, RemoteTableMetadata]) => {
            const updatedState = DatasetV2BuilderState.fromExistingDataset(table, dataset);
            delegate.replace(updatedState, ReplaceReason.SAVE);
        });
        setSaveMode(Optional.none());
    };

    const onCancelSave = () => {
        setSaveMode(Optional.none());
    };

    const onUpload = () => {
        // re-load the dataset as table metadata is also updated
        loadDataset().then(
            async (loadResult) => {
                onSuccessLoad(loadResult);
                delegate.forEach(d => d.replace(loadResult.datasetBuilderState, ReplaceReason.SAVE));
                setShowUpdateDialog(false);
                ServiceProvider.get(NotificationsService).publish(
                    'DatasetV2Builder.onUpload',
                    NotificationSeverity.SUCCESS,
                    `Successfully updated data for '${loadResult.dataset.label}'!`
                );
            }
        );
    };

    const onCancelUpload = () => {
        setShowUpdateDialog(false);
    };

    const saveHandler = datasetBuilderState.map(
        state => new SaveHandler(state.toDatasetV2(), props.onTabChange, Optional.none())
    );

    const updateRow = (row: DatasetV2BuilderColumn) => {
        Optional.all([delegate, datasetBuilderState]).forEach(([delegate, state]: [DatasetV2BuilderDelegate, DatasetV2BuilderState]) => {
            const currentCol = state.getColumn(row.name);
            if (!currentCol.equals(row)) {
                delegate.updateColumn(row);
            }
        });
    };

    const rowHasFormHelperText = (row: DatasetV2BuilderColumn) => {
        return row.descriptionHelperText || row.hasInvalidLabel;
    };

    const columns: GridColDef[] = [
        {
            field: FIELD_IDENTIFIERS.INCLUDE,
            headerName: 'Include',
            width: 88,
            sortable: false,
            resizable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams<boolean, GridRowType>) => (
                <Checkbox
                    disabled={!params.row.result.isValid}
                    checked={params.value}
                    onClick={() => updateRow(params.row.result.with({include: !params.row.result.include}))}
                    inputProps={{
                        tabIndex: -1
                    }}
                />
            )
        },
        {
            field: FIELD_IDENTIFIERS.IS_DIMENSION,
            width: 113,
            sortable: false,
            resizable: false,
            align: 'center',
            headerAlign: 'center',
            renderHeader: () => (
                <S.IsDimensionHeader>
                    Dimension
                    <Tooltip
                        title="Import column as a dimension instead of a measure"
                        placement="top"
                        arrow
                        disableInteractive
                        enterDelay={Durations.tooltipPrimaryEnterDelay}
                        enterNextDelay={Durations.tooltipPrimaryEnterNextDelay}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    width: '222px'
                                },
                            },
                        }}
                    >
                        <InfoOutlined color={"disabled"} sx={{fontSize: 18}}/>
                    </Tooltip>
                </S.IsDimensionHeader>
            ),
            renderCell: (params: GridRenderCellParams<boolean, GridRowType>) => (
                params.row.result.originalColumnType === AnalyticsType.MEASURE ?
                    <Switch
                        checked={params.value}
                        onClick={() => updateRow(params.row.result.with({isDimension: !params.row.result.isDimension}))}
                        inputProps={{
                            tabIndex: -1
                        }}
                    /> :
                    <></>
            )
        },
        {
            field: FIELD_IDENTIFIERS.TYPE,
            headerName: 'Type',
            width: 110,
            sortable: false,
            renderCell: (params: GridRenderCellParams<DataType>) => (
                params.value ? params.value.name.toUpperCase() : 'Unsupported'
            )
        },
        {
            field: FIELD_IDENTIFIERS.NAME,
            headerName: 'Column Name',
            flex: 1,
            sortable: false,
        },
        {
            field: FIELD_IDENTIFIERS.LABEL,
            headerName: 'Column Display Name',
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams<DatasetV2BuilderColumn>) => {
                const textField =
                    <BlurTextField
                        variant={"outlined"}
                        margin={"none"}
                        label={null}
                        value={params.value.label}
                        error={params.value.hasInvalidLabel}
                        helperText={params.value.hasInvalidLabel ? <span>Contains illegal characters:<br />{ProjectionValidation.ILLEGAL_CHARS}</span>  : null}
                        placeholder={params.value.name}
                        onChange={(val) => updateRow(params.row.result.with({label: val}))}
                    />;

                // note: if either label or description has helper text, we need to vertically align
                if (rowHasFormHelperText(params.value)) {
                    return <S.TextInputWithHelperContainer>
                        {textField}
                    </S.TextInputWithHelperContainer>;
                }
                return textField;
            }
        },
        {
            field: FIELD_IDENTIFIERS.DESCRIPTION,
            headerName: 'Column Description',
            flex: 3,
            sortable: false,
            renderCell: (params: GridRenderCellParams<DatasetV2BuilderColumn>) => {
                const textField =
                    <BlurTextField
                        variant={"outlined"}
                        margin={"dense"}
                        label={null}
                        value={params.value.description}
                        placeholder={"Fill in a few descriptions and let Prep do the rest!"}
                        helperText={params.value.descriptionHelperText}
                        onChange={(val) =>
                            // wipe out helper text only if description is not empty
                            updateRow(params.row.result.with({description: val, helperText: val ? null : undefined}))
                        }
                    />;

                // note: if either label or description has helper text, we need to vertically align
                if (rowHasFormHelperText(params.value)) {
                    return <S.TextInputWithHelperContainer>
                        {textField}
                    </S.TextInputWithHelperContainer>;
                }

                return textField;
            }
        }
    ];

    const getRowHeight = (params: GridRowHeightParams): GridRowHeightReturnValue => {
        const row: DatasetV2BuilderColumn = params.model.result as DatasetV2BuilderColumn;
        if (row.descriptionHelperText || row.hasInvalidLabel) {
            return 'auto';
        }
        return 56;
    };

    return <S.Builder>
        {
            isDescribing && <LoadingMask
                message={"We’re prepping your dataset with labels and descriptions to make the rest of HyperArc smarter, get ready for awesomeness with memory in queries."}
            />
        }
        {
            datasetBuilderState.map(d =>
                <Toolbar
                    fqn={props.path.assetFqn}
                    assetLabel={d.label}
                    assetLabelEditable={true}
                    onLabelChange={onToolbarChange}
                    isCompactTitle={false}
                    actions={toolbarActions}
                    onAction={onToolbarAction}
                />
            ).getOr(null)
        }
        {
            Optional.all([connection, remoteTableMetadata, datasetBuilderState]).map(([conn, metadata, state]: [RemoteDataSource, RemoteTableMetadata, DatasetV2BuilderState]) =>
                <DatasetV2BuilderHeader
                    description={state.description}
                    onDescriptionChange={(description: string) => delegate.forEach(d => d.changeInfo({description}))}
                    connection={conn}
                    metadata={metadata}
                    fileName={state.datasetConfig instanceof PersonalDatasetConfig ? state.datasetConfig.fileName : undefined}
                />
            ).nullable
        }
        {
            datasetBuilderState.map(state => {
                return <S.DatasetColumnTable
                    disableColumnMenu={true}
                    disableColumnReorder={true}
                    disableSelectionOnClick={true}
                    headerHeight={36}
                    onCellKeyDown={(params, events) => events.stopPropagation()}
                    getRowHeight={getRowHeight}
                    disableMultipleSelection
                    hideFooter
                    columns={columns}
                    rows={
                        state.rows.map(
                            row => ({
                                [FIELD_IDENTIFIERS.ID]: row.name,
                                [FIELD_IDENTIFIERS.INCLUDE]: row.include,
                                [FIELD_IDENTIFIERS.IS_DIMENSION]: row.isDimension,
                                [FIELD_IDENTIFIERS.TYPE]: row.type,
                                [FIELD_IDENTIFIERS.NAME]: row.name,
                                [FIELD_IDENTIFIERS.LABEL]: row,
                                [FIELD_IDENTIFIERS.DESCRIPTION]: row,
                                [FIELD_IDENTIFIERS.RESULT]: row
                            })
                        )
                    }
                />;
            }).getOrElse(() => null)
        }
        {
            Optional.all([datasetBuilderState, saveMode]).map(([state, mode]: [DatasetV2BuilderState, SaveMode]) =>
                mode === SaveMode.SAVE_AS ?
                    <SaveAsDialog
                        asset={state.toDatasetV2()}
                        fqn={props.path.assetFqn}
                        disableAccountSelection={true}
                        saveHandler={saveHandler.get()}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    /> :
                    <SaveDialog
                        fqn={props.path.assetFqn}
                        label={state.label}
                        description={state.description || ''}
                        saveHandler={saveHandler.get()}
                        onCancel={onCancelSave}
                        onSave={onSave}
                        overrideTitle={"Save Dataset"}
                    />
            ).nullable
        }
        {
            showUpdateDialog && datasetBuilderState.filter(state => state.isExisting).map(state =>
                <UpdateUploadDialog
                    datasetFqn={state.fqn}
                    label={state.label}
                    onCancel={onCancelUpload}
                    onUpload={onUpload}
                />
            ).nullable
        }
    </S.Builder>;
};

class S {

    static readonly Builder = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `;

    static readonly DatasetColumnTable = styled(DataGridPro)`
        .MuiDataGrid-columnHeaderTitleContainer {
            font-size: 11px;
        }

        .MuiDataGrid-cell {
            font-size: 12px;

            .MuiInputBase-input {
                font-size: 12px;
            }
        }
    `;

    static readonly TextInputWithHelperContainer = styled.div`
        width: 100%;
        // don't automatically center itself to grid cell and instead start at top of cell
        align-self: flex-start;
        // because flex-start, apply margin to roughly match vertical alignment of other cells
        margin-top: 25px;
    `;

    static readonly IsDimensionHeader = styled.div`
        display: flex;
        align-items: center;
    `;
}