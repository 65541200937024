import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import styled from "@emotion/styled";
import {CommonS} from "app/components/CommonS";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {useHistory} from "react-router-dom";
import {AssetPicker} from "app/components/search/AssetPicker";
import {AssetType} from "metadata/AssetType";
import {ServiceProvider} from "services/ServiceProvider";
import {LinkService} from "services/LinkService";
import {FQN} from "common/FQN";
import {TabProps} from "app/TabType";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import ListAltIcon from '@mui/icons-material/ListAlt';
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {AssetIcon, IconStyling} from "app/components/icons/asset/AssetIcon";
import {YourData, YourDataStep} from "app/home/YourData";
import {Optional} from "common/Optional";

const ADD_ICON_CLASSNAME = 'add-icon';

/**
 * Landing page for creating new assets.
 *
 * @author zuyezheng
 */
export const NewLanding: FunctionComponent<TabProps> = (props: TabProps) => {

    // sub page state, these should not be set directly but instead routed to via props.path
    const [showAssetPicker, setShowDatasetPicker] = useState<boolean>(false);
    const [showYourData, setShowYourData] = useState<Optional<YourDataStep>>(Optional.none());

    const history = useHistory();

    // handle subpage route for creating new assets
    useEffect(() => {
        // not the cleanest but reset state to figure out where to route
        setShowDatasetPicker(false);
        setShowYourData(Optional.none());

        // no extra routing needed
        if (props.path.parts.length < 2) {
            return;
        }

        switch (props.path.parts[1]) {
            case 'query':
                setShowDatasetPicker(true);
                break;
            case 'dataset':
                setShowYourData(Optional.some(
                    YourDataStep.possible(props.path.parts[2]).getOr(YourDataStep.PICKER)
                ));
                break;
            default:
                break;
        }
    }, [props.path]);

    // route to the root of the new landing page
    const root = () => {
        history.push('/new');
    };

    // route to a subpage of new
    const subPage = (page: string) => {
        history.push(`/new/${page}`);
    };

    // close the landing page and route to a new page
    const closeAndRoute = (route: string) => {
        // need to close first or ending path will be the result of close
        props.onClose();
        history.push(route);
    };

    // dataset selected to create a new query with
    const onSelectDataset = (selected: AssetSearchResult) => {
        closeAndRoute(
            ServiceProvider.get(LinkService).datasetQuery(FQN.parse(selected.fullyQualifiedName))
        );
    };

    // create a new dashboard
    const onClickDashboard = () => {
        ServiceProvider.get(LinkService).dashboard().then(closeAndRoute);
    };

    return <S.Page>
        {
            showAssetPicker && <AssetPicker
                title="Select a dataset to query."
                searchParams={new AssetSearchParams(0, null, [AssetType.DATASET, AssetType.DATASET_V2])}
                onSelect={onSelectDataset}
                onCancel={root}
            />
        }
        {
            showYourData.map(step =>
                <YourData step={step} onClose={root} onRoute={closeAndRoute} />
            ).nullable
        }
        <S.PageRow>
            <S.Spacer/>
            <S.Card onClick={() => subPage('query')}>
                <S.Spacer/>
                <S.CardRow className="title">
                    <div className={ADD_ICON_CLASSNAME}><S.Add/></div>
                    <S.CardTitle>
                        <AssetIcon
                            assetType={AssetType.ARCQL}
                            iconStyling={IconStyling.OUTLINED_COLORED}
                            containerStyle={{marginRight: 8}}
                        />
                        New Query
                    </S.CardTitle>
                </S.CardRow>
                <S.CardRow>
                    <CommonS.MinorText>
                        Explore, visualize, and ask questions.
                    </CommonS.MinorText>
                </S.CardRow>
                <S.Spacer/>
            </S.Card>
            <S.Card onClick={onClickDashboard}>
                <S.Spacer/>
                <S.CardRow className="title">
                    <div className={ADD_ICON_CLASSNAME}><S.Add/></div>
                    <S.CardTitle>
                        <AssetIcon
                            assetType={AssetType.DASHBOARD}
                            iconStyling={IconStyling.OUTLINED_COLORED}
                            containerStyle={{marginRight: 8}}
                        />
                        New Dashboard
                    </S.CardTitle>
                </S.CardRow>
                <S.CardRow>
                    <CommonS.MinorText>
                        Gather multiple queries to present and share insights.
                    </CommonS.MinorText>
                </S.CardRow>
                <S.Spacer/>
            </S.Card>
            <S.Card onClick={() => subPage('dataset')}>
                <S.Spacer/>
                <S.CardRow className="title">
                    <div className={ADD_ICON_CLASSNAME}><S.Add/></div>
                    <S.CardTitle>
                        <ListAltIcon className="your-data"/>
                        Your Data
                    </S.CardTitle>
                </S.CardRow>
                <S.CardRow>
                    <CommonS.MinorText>
                        BYOD. Connect, upload, and define your data.
                    </CommonS.MinorText>
                </S.CardRow>
                <S.Spacer/>
            </S.Card>
            <S.Spacer/>
        </S.PageRow>
    </S.Page>;

};

class S {

    static readonly Page = styled.div`
        padding: 80px 0;
    `;

    static readonly PageRow = styled.div`
        display: flex;
    `;

    static readonly Spacer = styled.div`
        flex: 1;
    `;

    static readonly Card = styled.div`
        margin: 16px;
        width: 324px;
        height: 224px;
        box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 3px solid transparent;

        &:hover {
            border: 3px solid ${Colors.hyperarcYellowSecondary};

            .${ADD_ICON_CLASSNAME} {
                svg {
                    color: ${Colors.hyperarcYellow};
                }
            }
        }
    `;

    static readonly Add = styled(AddOutlinedIcon)`
        height: 35px;
        width: 35px;
        color: ${Colors.iconPrimary};
        margin-bottom: 8px;
    `;

    static readonly CardRow = styled.div`
        padding: 8px 75px 24px;
        display: flex;
        justify-content: center;
        text-align: center;

        &.title {
            flex-direction: column;
            align-items: center;
        }
    `;

    static readonly CardTitle = styled.div`
        font-size: ${FontSizes.large};
        line-height: ${FontSizes.xLarge};
        color: ${Colors.textPrimary};
        display: flex;

        svg {
            margin-right: 16px;

            &.your-data {
                margin-right: 8px;
                color: ${Colors.iconYourData};
            }
        }
    `;

}