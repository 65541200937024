import {Enum} from "common/Enum";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";

/**
 * Setting for how to emit facets when the emitting query has multiple groupings.
 */
export class FacetEmitSetting extends Enum implements ExplainedSetting {
    static ALL = new this(
        'all',
        'Use Composite on All',
        'Use all groupings to facet receiving queries.'
    );
    static FIRST_GROUPING_ONLY = new this(
        'firstGrouping',
        'Facet First Grouping Only',
        'Use first grouping to facet receiving queries.'
    );

    private constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly explanation: string
    ) {
        super(name);
    }
}

FacetEmitSetting.finalize();
