import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {ArcQLField} from "metadata/query/ArcQLField";
import {Tuple} from "common/Tuple";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {TableDataProvider} from "app/visualizations/data/TableDataProvider";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {ValueFormatter} from "common/ValueFormatter";

/**
 * Turn query results into pivoted tabular data for visualization.
 *
 * @author zuyezheng
 */
export class PivotedTableData implements TableDataProvider {

    constructor(
        private readonly response: ArcQLResponse,
    ) {}

    get columns(): GridColDef[] {
        // when pivoted, each row will be a column and thus each column will be a grouping value instead of a field,
        // except the first which will be field names
        const firstGrouping = this.response.result.indexColumns[0];
        const columns = this.response.result.mapRows(
            (row: any[], index: string, formatters: ResultValueFormatter[]) => ({
                // we use the unique per row generated index as the "field" to align the data with, however only show
                // the first grouping since we can use subsequent rows for those values to avoid clutter
                'field': index,
                'headerName': firstGrouping == null
                    // if a grain query, use the row index as the header
                    ? index
                    // if a grouped query, use the formatted grouping
                    : formatters[firstGrouping.right](row[firstGrouping.right]),
                'sortable': false,
                'flex': 1
            })
        );
        columns.unshift({
            'field': 'field',
            'headerName': 'Field',
            'sortable': false,
            'flex': 1
        });

        return columns;
    }

    rows(compactNumbers: boolean): GridRowsProp {
        const rows: {[key: string]: any}[] = [];

        // add rows for additional groupings
        this.response.result.indexColumns.slice(1).forEach((column: Tuple<string, number>) => {
            const tableRow: {[key: string]: any} = {
                'id': column.left,
                'field': this.response.dataset.getLabel(column.left)
            };
            this.response.result.forEachRow(
                (row: any[], index: string, formatters: ResultValueFormatter[]) => {
                    tableRow[index] = formatters[column.right](row[column.right]);
                }
            );

            rows.push(tableRow);
        });

        // add rows for each measure field
        this.response.arcql.fields.fields.forEach((field: ArcQLField) => {
            const tableRow: {[key: string]: any} = {
                'id': field.as,
                'field': field.as
            };
            const fieldIndex = this.response.result.columnIndices.get(field.as);

            this.response.result.forEachRow(
                (row: any[], index: string, formatters: ResultValueFormatter[]) => {
                    const value = row[fieldIndex];
                    const formatter = (() => {
                        if (typeof value === 'number') {
                            return compactNumbers ? ValueFormatter.compactNumber : ValueFormatter.basicNumber;
                        } else {
                            return formatters[fieldIndex];
                        }
                    })();

                    tableRow[index] = formatter(row[fieldIndex]);
                }
            );

            rows.push(tableRow);
        });

        return rows;
    }

    get rowGroupings(): string[] {
        // no row groupings when pivoted
        return [];
    }

    get columnVisibility(): GridColumnVisibilityModel {
        // no visibility to control since no row groupings
        return {};
    }

}