import * as React from "react";
import {FunctionComponent} from "react";
import {GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import TrendingUpOutlined from "@mui/icons-material/TrendingUpOutlined";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";
import styled from "@emotion/styled";
import {DataGridPro} from "@mui/x-data-grid-pro/DataGridPro";
import dayjs from "dayjs";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";

interface Props {
    className?: string
    trendResults: AssetSearchResult[]
    onTrendSelect: (trendResult: AssetSearchResult) => void
    onAccountBreadcrumb: (account: string) => void
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    ICON: 'icon',
    LABEL: 'label',
    PROJECT_LABEL: 'projectLabel',
    PROJECT_OWNER: 'projectOwner',
    UPDATED_ON: 'updatedOn',
    RESULT: 'result'
};

/**
 * Table for displaying trend results.
 */
export const TrendsTable: FunctionComponent<Props> = (props: Props) => {

    const columns: GridColDef[] = [{
        'field': FIELD_IDENTIFIERS.ICON,
        'headerName': '',
        'renderCell': (params: GridRenderCellParams<boolean>) =>
            <TrendingUpOutlined/>,
        'width': 30
    }, {
        'field': FIELD_IDENTIFIERS.LABEL,
        'headerName': 'Name',
        'flex': 1.5
    }, {
        'field': FIELD_IDENTIFIERS.PROJECT_LABEL,
        'headerName': 'Project',
        'flex': 1
    }, {
        'field': FIELD_IDENTIFIERS.PROJECT_OWNER,
        'headerName': 'Owner',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<string>) =>
            <AccountBreadCrumbInResultRow
                text={params.value}
                onClick={() => props.onAccountBreadcrumb(params.value)}
            />
    }, {
        'field': FIELD_IDENTIFIERS.UPDATED_ON,
        'headerName': 'Last Updated On',
        'flex': 1
    }];

    const onRowClick = (params: GridRowParams) => {
        const result = params.row.result as AssetSearchResult;
        return props.onTrendSelect(result);
    };

    return <S.ResultsTable
        className={props.className}
        columns={columns}
        rows={
            props.trendResults.map(t => ({
                [FIELD_IDENTIFIERS.ID]: t.id,
                [FIELD_IDENTIFIERS.LABEL]: t.label,
                [FIELD_IDENTIFIERS.PROJECT_LABEL]: t.folderLabel,
                [FIELD_IDENTIFIERS.PROJECT_OWNER]: t.folderOwner,
                [FIELD_IDENTIFIERS.UPDATED_ON]: dayjs(t.lastUpdatedOn).format("MMM D, YYYY"),
                [FIELD_IDENTIFIERS.RESULT]: t
            }))
        }
        onRowClick={onRowClick}

        pagination
        pageSize={5}
        autoHeight
        density="standard"

        disableSelectionOnClick
        disableColumnMenu
    />;
};


class S {

    static readonly ResultsTable = styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-columnSeparator {
            display: none;
        }
    `;
}
