import * as React from "react";
import {FunctionComponent, useCallback, useState} from "react";
import styled from "@emotion/styled";
import {DimensionFilterEditor} from "app/query/filters/DimensionFilterEditor";
import Popover, {PopoverOrigin} from "@mui/material/Popover";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {FilterClause, FilterValue, RawFilterValue} from "metadata/query/filterclause/FilterClause";
import {MeasureFilterEditor} from "app/query/filters/MeasureFilterEditor";
import {DateFilterEditor} from "app/query/filters/DateFilterEditor";
import {Colors, FontSizes} from "app/components/StyleVariables";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {DataSuperType} from "metadata/DataType";
import {
    BaseFilterEditorProps,
    DimensionFilterEditorProps,
    MeasureFilterEditorProps
} from "app/query/filters/MeasureFilterEditorProps";
import {Optional} from "common/Optional";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";

export type EditorFilterChange = {
    type: FilterClauseType
    operator: FilterOperator
    values: FilterValue[]
}

export type SelectedFilter = {
    el: Element
    filter: FilterClause
    ordinal: number
    isAggregate: boolean
}

export type Props = {
    // encapsulate the selection event
    selected: SelectedFilter,
    anchorOrigin: PopoverOrigin,
    arcqlBundle: ArcQLBundle,
    // close the popover with possible changes
    onClose: (change: Optional<EditorFilterChange>) => void
}

export const FilterEditor: FunctionComponent<Props> = (props: Props) => {

    const [committedChange, setCommittedChange] = useState<Optional<EditorFilterChange>>(Optional.none());

    const onChange = useCallback((type: FilterClauseType, operator: FilterOperator, values: FilterValue[]) => {
        setCommittedChange(Optional.some({type, operator, values}));
    }, []);

    const onClose = () => {
        props.onClose(committedChange);
    };

    const onChangeSingleField = (operator: FilterOperator, values: RawFilterValue[]) => {
        onChange(FilterClauseType.SINGLE, operator, values);
    };

    const buildEditor = (filter: SingleFieldFilterClause) => {
        const editorProps: BaseFilterEditorProps<FilterClause> = {
            arcqlBundle: props.arcqlBundle,
            ordinal: props.selected.ordinal,
            isAggregate: props.selected.isAggregate,
            filter: filter
        };

        // FilterSetFilterClause or ArcQLFilterClause only supported with DimensionFilterEditor
        if (filter.type.hasAssetRefs) {
            return <DimensionFilterEditor
                {...editorProps as DimensionFilterEditorProps}
                onChange={onChange}
                onClose={props.onClose}
                withFilterSetMode={true}
            />;
        }

        if (props.selected.isAggregate) {
            // aggregate filters can only be for measures
            return <MeasureFilterEditor {...editorProps as MeasureFilterEditorProps} onChange={onChangeSingleField}/>;
        }

        // if not an aggregate filter, need to figure out what the source column type is
        return props.arcqlBundle.dataset.getPossible(filter.column)
            .map(field => {
                switch (field.dataSuperType) {
                    case DataSuperType.STRING:
                    case DataSuperType.BOOLEAN:
                        return <DimensionFilterEditor
                            {...editorProps as DimensionFilterEditorProps}
                            onChange={onChange}
                            onClose={props.onClose}
                            withFilterSetMode={field.dataSuperType === DataSuperType.STRING}
                        />;

                    case DataSuperType.NUMBER:
                        return <MeasureFilterEditor
                            {...editorProps as MeasureFilterEditorProps}
                            onChange={onChangeSingleField}
                        />;
                    case DataSuperType.TIMESTAMP:
                        return <DateFilterEditor
                            {...editorProps as MeasureFilterEditorProps}
                            onChange={onChangeSingleField}
                        />;
                }
            })
            .nullable;
    };

    const buildReadonly = (filter: FilterClause) => {
        return <FilterEditorS.Readonly>
            <FilterEditorS.ReadonlyTitle>Readonly Filter Clause</FilterEditorS.ReadonlyTitle>
            <div>{filter.fieldsLabel(props.arcqlBundle.dataset)} {filter.description(false)}</div>
        </FilterEditorS.Readonly>;
    };

    const buildPopover = () => {
        if (props.selected.filter.type.editable) {
            return buildEditor(props.selected.filter as SingleFieldFilterClause);
        } else {
            return buildReadonly(props.selected.filter);
        }
    };

    return <Popover
        open={true}
        anchorEl={props.selected.el}
        onClose={onClose}
        anchorOrigin={props.anchorOrigin}
    >
        <FilterEditorS.PopoverContent>{buildPopover()}</FilterEditorS.PopoverContent>
    </Popover>;

};

export const FilterEditorS = {
    PopoverContent: styled.div`
        margin: 18px;
        width: 425px;
    `,
    Container: styled.div`
        display: flex;
        flex-direction: column;
        align-items: baseline;
    `,
    HelpText: styled.div`
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.small};
        padding: 6px 0;
    `,
    Select: styled(Select)`
        font-size: ${FontSizes.small};
    `,
    MenuItem: styled(MenuItem)`
        font-size: ${FontSizes.small};
    `,
    Row: styled.div`
        width: 100%;
        display: flex;

        > div {
            margin-left: 5px;

            &:first-child {
                margin-left: 0;
            }
        }
    `,
    BoundsContainer: styled.div`
        padding-top: 24px;
        display: flex;
        width: 100%;
    `,
    Bound: styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: end;

        &:first-of-type {
            align-items: start;
        }
    `,
    BoundField: styled(TextField)`
        width: 190px;
        margin: 0;

        input {
            font-size: ${FontSizes.small};
            padding: 9px 14px;
        }
    `,
    Readonly: styled.div`
        font-size: ${FontSizes.small};
    `,
    ReadonlyTitle: styled.div`
        font-weight: 500;
        margin-bottom: 8px;
    `
};
