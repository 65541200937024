import * as React from "react"
import {SVGProps} from "react"

const TableIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M20 3.5H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm0 2v3H5v-3h15Zm-5 14h-5v-9h5v9Zm-10-9h3v9H5v-9Zm12 9v-9h3v9h-3Z"
        />
    </svg>
);
export default TableIcon;
