import * as React from "react"
import {SVGProps} from "react"

const ReceiveGlobalFiltersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={24}
        {...props}
    >
        <path
            fill="#000"
            fillOpacity={0.54}
            d="M3 6h10l-5.01 6.3L3 6ZM.25 5.61C2.27 8.2 6 13 6 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39A.998.998 0 0 0 14.95 4H1.04c-.83 0-1.3.95-.79 1.61Z"
        />
        <path
            fill="#43A047"
            d="m25.334 9.607-.94-.94-7.727 7.726V12h-1.333v6.667h6.667v-1.334h-4.394l7.727-7.726Z"
        />
    </svg>
)
export default ReceiveGlobalFiltersIcon
