import * as React from "react";
import {ChangeEvent, FunctionComponent, useState} from "react";
import {DialogS} from "app/DialogS";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {ConnectionType} from "metadata/connections/ConnectionType";
import {Autocomplete, Card, CardActionArea, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {OrgMembership} from "metadata/OrgMembership";


export type ConnectionSelection = {
    label: string
    orgName: string
    type: ConnectionType
}

interface Props {
    orgMemberships: OrgMembership[]

    onConnectionSelection(selection: ConnectionSelection): void
}


/**
 * Step for choosing a connection.
 */
export const ChooseConnectionStep: FunctionComponent<Props> = (props: Props) => {

    const [org, setOrg] = useState<string>('');
    const [label, setLabel] = useState<string>('');

    const sortedMemberships = props.orgMemberships.sort((a, b) => a.name.localeCompare(b.name));
    const validSelection = label.trim() !== '' && org.trim() !== '';
    return (
        <div>
            <DialogS.SelectorRow>
                <FormControl sx={{width: "200px", marginRight: "16px"}} size={"small"}>
                    <FormLabel id="org-label" sx={{mb: 0.5}}>Organization</FormLabel>
                    <Autocomplete
                        id="organizations"
                        aria-labelledby="org-label"
                        value={org}
                        options={sortedMemberships.map(m => m.name)}
                        onChange={(e: ChangeEvent<HTMLInputElement>, newValue: string) => newValue && setOrg(newValue)}
                        fullWidth
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size="small"
                                placeholder="Select Org..."
                            />
                        }
                    />
                </FormControl>
            </DialogS.SelectorRow>
            <DialogS.InputRow>
                <FormControl fullWidth>
                    <FormLabel id="name-label" sx={{mb: 0.5}}>Connection Name</FormLabel>
                    <TextField
                        margin="none"
                        size="small"
                        id="label"
                        aria-labelledby="name-label"
                        placeholder="Enter a name for your database connection"
                        value={label}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
                        sx={{
                            width: "340px"
                        }}
                    />
                </FormControl>
            </DialogS.InputRow>
            <S.SelectText>Select a supported database: </S.SelectText>
            <Grid container spacing={4}>
                {ConnectionType.enums<ConnectionType>().filter(ct => !ct.isFake).map((ct) => (
                    <Grid item key={ct.name} xs={3}>
                        <S.Card variant="outlined">
                            <CardActionArea
                                onClick={() => props.onConnectionSelection({label, orgName: org, type: ct})}
                                disabled={!validSelection}
                                sx={{opacity: label.trim() === '' ? 0.5 : 1}}
                            >
                                <S.CardContent>
                                    <S.CardIcon>
                                        <img
                                            src={`/static/images/${ct.name}.svg`}
                                            alt={ct.label}
                                        />
                                    </S.CardIcon>
                                    <Typography gutterBottom variant="body2" component="div">
                                        {ct.label}
                                    </Typography>
                                </S.CardContent>
                            </CardActionArea>
                        </S.Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

class S {
    static readonly SelectText = styled.div`
        padding: 16px 0;
        font-size: ${FontSizes.medium};
        color: ${Colors.textPrimary};
    `;

    static readonly Card = styled(Card)`
        border-radius: 8px;
    `;

    static readonly CardContent = styled(CardContent)`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    `;

    static readonly CardIcon = styled.div`
        img {
            width: 50px;
            height: 50px;
        }
    `;
}