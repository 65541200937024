import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraph} from 'metadata/hypergraph/HyperGraph';
import {HyperGraphNodeType} from 'metadata/hypergraph/nodes/HyperGraphNodeType';
import {HyperGraphRequestSerialized} from 'metadata/hypergraph/HyperGraphTypes';

/**
 * Initial request as the 'system' role to set the context for the entire completion.
 *
 * @author zuyezheng
 */
export class StartAnalysis implements HyperGraphRequest {

    constructor(
        public readonly parent: string,
        private readonly dataset: ArcDataset
    ) {}

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.START_ANALYSIS;
    }

    get operation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.SYSTEM;
    }

    label(graph: HyperGraph): string {
        return 'Start analysis.';
    }

    get ommers(): string[] {
        return [this.parent];
    }


    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            parent: this.parent
        };
    }

    static fromJSON(json: HyperGraphRequestSerialized, dataset:ArcDataset): StartAnalysis {
        return new StartAnalysis(json.parent, dataset);
    }

}