import {DatasetType} from "metadata/dataset/DatasetType";

import {ColumnMetadata} from "metadata/dataset/ColumnMetadata";

/**
 * Any BigQuery specific column metadata
 */
export class BigQueryColumnMetadata implements ColumnMetadata {

    public readonly type: DatasetType = DatasetType.BIG_QUERY;
}