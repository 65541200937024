import * as React from "react";
import {ChangeEvent, FunctionComponent, useMemo, useState} from "react";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import styled from "@emotion/styled";
import {Tuple} from "common/Tuple";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import {DragAndDropData} from "common/DragAndDropData";
import {Colors, FontSizes} from "app/components/StyleVariables";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {FieldsPickerSection} from "app/query/components/FieldsPickerSection";
import {SectionFieldItem} from "app/query/components/SectionFieldItem";
import {Column} from "metadata/Column";
import {Optional} from "common/Optional";
import {AnalyticsType} from "metadata/AnalyticsType";

export type ColumnsPickerProps = {
    dataset: ArcDataset

    // Has the query been saved?
    isSaved: boolean
    // are the fields draggable?
    draggable?: boolean

    // is there a field selected?
    isFieldSelected?: (field: Column) => boolean
    // is there an extra field setting view to be displayed
    hasFieldSettingView?: (column: Column) => boolean
    // on field setting click
    onFieldSettingView?: (column: Column) => void

    // can something be dropped into the fields panel?
    onDrop?: (dragAndDropData: DragAndDropData<QueryDnDSource>) => void
    onClick?: (column: Column) => void
    onDoubleClick?: (column: Column) => void

    // Section order (wherein each section is by AnalyticsType)
    sectionOrder?: AnalyticsType[]
}

/**
 * Column selection of a single dataset.
 *
 * @author zuyezheng
 */
export const ColumnsPicker: FunctionComponent<ColumnsPickerProps> = (props: ColumnsPickerProps) => {

    // default some props
    const {draggable = false, sectionOrder = [AnalyticsType.DATE, AnalyticsType.DIMENSION, AnalyticsType.MEASURE]} = props;

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [expandedFields, setExpandedFields] = useState<Set<String>>(new Set());
    const [searchResults, setSearchResults] = useState<Optional<Tuple<string, Column[]>[]>>(Optional.none());

    const onDragStart = (event: React.DragEvent) => {
        DragAndDropData.fromAttributes<QueryDnDSource>(event.currentTarget, 'data-drag-id', QueryDnDSource).setData(event);
    };

    const onDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };
    const onDrop = (event: React.DragEvent) => {
        props.onDrop(DragAndDropData.fromEvent<QueryDnDSource>(event, QueryDnDSource));
    };

    const sections: Tuple<string, FieldsPickerSection>[] = useMemo(() => {
        const allSections = {
            [AnalyticsType.DATE.name]: new FieldsPickerSection(Array.from(props.dataset.dates.values())),
            [AnalyticsType.DIMENSION.name]: new FieldsPickerSection(Array.from(props.dataset.dimensions.values())),
            [AnalyticsType.MEASURE.name]: new FieldsPickerSection(Array.from(props.dataset.measures.values()))
        };

        return sectionOrder.map(analyticsType => new Tuple(analyticsType.categoryLabel, allSections[analyticsType.name]));
    }, [props.dataset]);

    const handleExpandFieldToggle = (fieldName: string) => {
        const updatedSet = new Set(expandedFields);
        if (expandedFields.has(fieldName)) {
            updatedSet.delete(fieldName);
        } else {
            updatedSet.add(fieldName);
        }
        setExpandedFields(updatedSet);
    };

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        const newSearchResults = sections.map(section =>
            Tuple.of(section.left, section.right.search(newSearchTerm))
        );
        setExpandedFields(new Set(
            newSearchResults.flatMap(
                // collect all fields with matched children to initialize them as expanded
                section => section.right
                    .filter(r => r.matchedChildren)
                    .flatMap(r => r.column.name)
            )
        ));
        setSearchResults(Optional.some(
            newSearchResults.map(
                section => Tuple.of(section.left, section.right.map(m => m.column))
            )
        ));
        setSearchTerm(newSearchTerm);
    };

    const buildSections = () => {
        return sections.map((section: Tuple<string, FieldsPickerSection>, sectionI: number) => {
            // see if there are any search results
            const filteredColumns = searchResults.map(results => results[sectionI].right)
                .getOrElse(() => section.right.parentFields);

            // filter out sections without fields
            if (filteredColumns.length === 0) {
                return null;
            }

            return <li key={`${section.left}`}>
                <S.FieldsSection>
                    <S.SectionHeader>{section.left}</S.SectionHeader>
                    {
                        filteredColumns.map((field: Column) =>
                            <SectionFieldItem
                                key={field.name}
                                field={field}
                                section={section.right}
                                draggable={draggable}
                                isFieldSelected={props.isFieldSelected?.(field)}
                                isExpanded={expandedFields.has(field.name)}
                                onToggleExpand={handleExpandFieldToggle}
                                onClick={props.onClick}
                                onDoubleClick={props.onDoubleClick}
                                onDragStart={onDragStart}
                                onFieldSettingView={props.hasFieldSettingView?.(field) ? props.onFieldSettingView : undefined}
                            />
                        )
                    }
                </S.FieldsSection>
            </li>;
        }).filter(c => c != null);
    };

    return <S.Container onDragOver={onDragOver} onDrop={onDrop}>
        <S.Search>
            <TextField
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                }}
                fullWidth={true}
                variant="outlined"
                size="small"
                placeholder="Search columns"
                autoComplete="off"
                value={searchTerm}
                onChange={onSearchChange}
            />
        </S.Search>
        <S.FieldsPickerContainer>
            <S.FieldsPickerList>
                {buildSections()}
            </S.FieldsPickerList>
        </S.FieldsPickerContainer>
    </S.Container>;

};

class S {

    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    `;

    static readonly Search = styled.div`
        padding: 10px;

        input {
            font-size: ${FontSizes.small};
        }
    `;

    static readonly FieldsPickerContainer = styled.div`
        flex: 1;
        overflow: auto;
    `;

    static readonly FieldsPickerList = styled(List)`
        padding: 0;
    `;

    static readonly FieldsSection = styled.ul`
        padding: 0;
    `;

    static readonly SectionHeader = styled(ListSubheader)`
        color: ${Colors.textSecondary};
        padding: 8px;
        font-size: ${FontSizes.small};
        line-height: ${FontSizes.small};
        font-weight: 500;
        margin-bottom: 4px;
    `;

}