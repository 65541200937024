import {JsonObject} from "common/CommonTypes";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {Asset} from "metadata/Asset";
import {References} from "metadata/References";

/**
 * Helps with persisting hyperarc state in local storage.
 *
 * @author zuyezheng
 */
export class LocalStorageService {

    // expire changes after 2 days
    private static readonly ASSET_TIMEOUT = 2 * (1000 * 60 * 60 * 24);

    constructor() {
        this.purgeAssets();
    }

    storeAsset(asset: Asset, fqn?: FQN): boolean {
        // make sure the fqns aren't conflicting
        if (fqn && asset.fqn && !asset.fqn.equals(fqn)) {
            return false;
        }

        try {
            localStorage.setItem(fqn.toString(), JSON.stringify({
                asset: asset.toLocalStorageJSON(fqn),
                timestamp: Date.now()
            }));
            return true;
        } catch (e) {
            // likely no local storage, out of disk, or private browsing, in case it's the first 2 try to delete some
            // stuff
            this.clearAsset(asset.fqn);
            return false;
        }
    }

    hasAsset(fqn: FQN): boolean {
        try {
            return localStorage.getItem(fqn.toString()) !== null;
        } catch (e) {
            // unsupported by the browser or private browsing
            return false;
        }
    }

    async getAsset<A extends Asset>(fqn: FQN, fromJSON: (json: JsonObject) => A): Promise<Optional<A>> {
        try {
            const jsonStr = localStorage.getItem(fqn.toString());
            if (jsonStr) {
                const localBundle: JsonObject = JSON.parse(jsonStr);
                const hydratedAsset: JsonObject = await References.hydrate(localBundle.asset);
                return Optional.of(fromJSON(hydratedAsset));
            }
            return Optional.none();
        } catch (e) {
            // unsupported by the browser or private browsing
            return Optional.none();
        }
    }

    /**
     * Purge any assets in local storage that are expired.
     */
    purgeAssets() {
        try {
            Object.keys(localStorage)
                // only check FQN keys
                .filter(key => FQN.isFqn(key))
                .forEach(key => {
                    try {
                        const localBundle: JsonObject = JSON.parse(localStorage.getItem(key));
                        if (this.isAssetExpired(localBundle)) {
                            console.log(`Purging expired asset: ${key}`);
                            localStorage.removeItem(key);
                        }
                    } catch (e) {
                        // something bad with the asset, just remove it
                        localStorage.removeItem(key);
                    }
                });
        } catch (e) {
            // unsupported by the browser or private browsing
        }
    }

    clearAsset(fqn: FQN) {
        try {
            localStorage.removeItem(fqn.toString());
            return true;
        } catch (e) {
            // unsupported by the browser or private browsing
            return false;
        }
    }

    private isAssetExpired(localBundle: JsonObject): boolean {
        // make sure there is a timestamp, expire legacy assets
        return localBundle.timestamp == null ||
            // check if it hit the threshold
            (Date.now() - localBundle.timestamp) > LocalStorageService.ASSET_TIMEOUT;
    }

}
