import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {ColumnGroupColumn, DashboardInteractions} from "metadata/dashboard/DashboardInteractions";
import {StatePassMode} from "metadata/dashboard/widgets/config/StatePassMode";
import {GlobalFilterClauseFactory} from "metadata/dashboard/GlobalFilterClauseFactory";
import {Facet} from "engine/FacetingMessage";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {OrderedMap} from "common/OrderedMap";

/**
 * Static functions for collating dashboard global filter clauses.
 *
 * @author zuyezheng
 */
export class DashboardFilterCollator {

    /**
     * Given interactions, figure out which clauses should be relayed.
     */
    static relayClauses(clauses: GlobalFilterClause[], interactions: DashboardInteractions): GlobalFilterClause[] {
        return clauses.flatMap(clause => {
            const linked = interactions.findLinkedColumns(clause.datasetFqn, clause.column);

            if (linked.length === 0) {
                return [clause];
            } else {
                // also include the original global filter clause that triggered the rest of the interaction linked cols
                linked.push(new ColumnGroupColumn(clause.datasetFqn, clause.column));
                return linked.map(c => clause.withColumn(c.datasetFqn, c.column));
            }
        });
    }

    /**
     * Apply global filters from external state.
     */
    static applyStateClauses(
        // clauses we should apply
        clauses: GlobalFilterClause[],
        // persisted clauses
        persistedClauses: GlobalFilterClause[],
        // currently applied session clauses
        sessionFilters: GlobalFilterClause[],
        interactions: DashboardInteractions
    ): GlobalFilterClause[] {
        return persistedClauses.flatMap((persistedClause, index) => {
            // Find columns in interaction groups related to the persisted clause if any
            const columnsInInteractionGroup = interactions
                .findLinkedColumns(persistedClause.datasetFqn, persistedClause.column);

            // Include the current persisted clause column as a column to search
            columnsInInteractionGroup.push(
                new ColumnGroupColumn(
                    persistedClause.datasetFqn,
                    persistedClause.column
                )
            );

            // Find applicable session clauses that match any of the columns in the interaction group
            const applicableSessionClauses = clauses.filter(sessionClause =>
                columnsInInteractionGroup.some(column =>
                    column.datasetFqn.equals(sessionClause.datasetFqn) && column.column === sessionClause.column
                )
            );

            if (applicableSessionClauses.length === 1) {
                // if an applicable session clause matched via an interaction group column, need to ensure the
                // session clause is updated to match the persisted clause
                const adjustedClauseToPersisted = applicableSessionClauses[0]
                    .withColumn(persistedClause.datasetFqn, persistedClause.column);
                sessionFilters[index] = adjustedClauseToPersisted;
                return adjustedClauseToPersisted;
            } else if (applicableSessionClauses.length > 1) {
                // conflicting session state, drop as we can't apply unambiguously
            }

            // No session clauses were applied to this specific persisted clause
            return [];
        });
    }

    /**
     * Collate the current state for linking to dashboards.
     */
    static collateStateForDashboards(
        mode: StatePassMode, globalFilters: GlobalFilterClause[], facets: Map<string, Facet[]>
    ): GlobalFilterClause[] {
        const collated: GlobalFilterClause[] = [];

        if (mode === StatePassMode.GLOBAL_FILTERS || mode === StatePassMode.ALL) {
            collated.push(...globalFilters);
        }

        if (mode === StatePassMode.FACETS || mode === StatePassMode.ALL) {
            // convert facets as global filters
            const facetsAsGlobalFilters = Array.from(facets).flatMap(([datasetFqn, facetsArray]) =>
                facetsArray.flatMap(facet => {
                    const maybeFilter = GlobalFilterClauseFactory.fromFilterClause(facet.filterClause, datasetFqn);
                    return maybeFilter.isPresent ? [maybeFilter.get()] : [];
                })
            );
            collated.push(...facetsAsGlobalFilters);
        }

        return this.mergeGlobalFiltersByDatasetFqnAndColumn(collated);
    }

    /**
     * Collate the current state for linking to queries.
     */
    static collateStateForQueries(
        mode: StatePassMode,
        datasetFqnOfQuery: string,
        globalFilters: GlobalFilterClause[],
        facets: Map<string, Facet[]>
    ): ArcQLFilters {
        let filters = ArcQLFilters.empty(false);

        if (mode === StatePassMode.GLOBAL_FILTERS || mode === StatePassMode.ALL) {
            filters = filters.withAll(
                globalFilters
                    .filter(f => f.datasetFqn.toString() === datasetFqnOfQuery)
                    .map(f => f.toBaseFilterClause())
            );
        }

        if (mode === StatePassMode.FACETS || mode === StatePassMode.ALL) {
            // merge only the facets for the given dataset of query
            filters = filters.withAll(
                (facets.get(datasetFqnOfQuery) || []).map(f => f.filterClause)
            );
        }

        return filters;
    }

    /**
     * Merge global filters by dataset fqn and column where last one wins out. The reason being
     * the last is assumed to be most selective (ex: facets over global filters).
     */
    private static mergeGlobalFiltersByDatasetFqnAndColumn(globalFilters: GlobalFilterClause[]): GlobalFilterClause[] {
        const mergedFiltersMap: OrderedMap<string, GlobalFilterClause> = OrderedMap.empty();

        globalFilters.forEach(filter => {
            const key = `${filter.datasetFqn.toString()}/${filter.column}`;
            mergedFiltersMap.set(key, filter);
        });

        return Array.from(mergedFiltersMap.values);
    }

}