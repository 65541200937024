import {Indexable} from "common/Indexable";
import {PageParams} from "metadata/PageParams";

export class PersonaListParams implements Indexable, PageParams<PersonaListParams> {
    constructor(
        // page size is required to support pagination
        public readonly size: number,
        // pages are 0 indexed
        public readonly page?: number
    ) {
    }

    with(
        {
            size,
            page
        }: {
            size?: number
            page?: number
        }) {
        return new PersonaListParams(
            size == null ? this.size : size,
            page == null ? this.page : page
        );
    }
}