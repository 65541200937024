import React, {FunctionComponent} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import {WrappedProjectIcon} from "app/components/icons/project/WrappedProjectIcon";
import ListItemText from "@mui/material/ListItemText";
import {AutoCompleteS} from "app/components/search/autocomplete/AutoCompleteS";
import {AutoCompleteItemProps} from "app/components/search/AutoCompleteItemProps";
import {FolderResult} from "metadata/project/FolderResult";


/**
 * Autocomplete Item for Project result.
 */
export const ProjectAutoCompleteItem: FunctionComponent<AutoCompleteItemProps<FolderResult>> = (props: AutoCompleteItemProps<FolderResult>) => {
    return <AutoCompleteS.Item
        key={props.result.id}
        onClick={() => props.onClick(props.result)}
        selected={props.selected}
    >
        <ListItemIcon>
            <WrappedProjectIcon isPublic={props.result.isPublic}/>
        </ListItemIcon>
        <ListItemText
            primary={props.result.label}
            secondary={`owned by @${props.result.ownerName}`}
        />
    </AutoCompleteS.Item>;
};