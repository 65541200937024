import {JsonObject} from 'common/CommonTypes';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {FactDetail} from 'metadata/hypergraph/content/FactDetail';
import {DataRowDetail} from 'metadata/hypergraph/content/DataRowDetail';
import {QueryResult} from 'metadata/query/QueryResult';

/**
 * A common operation in the HyperGraph is to find relevant data points and trends for given query results with a long
 * summary and short label. Encapsulate some of this common logic.
 *
 * @author zuyezheng
 */
export class TrendsAndDataPoints {

    constructor(
        // short label of the summary
        public readonly label: string,
        // longer form summary of trends and data points
        public readonly summary: string,
        // interesting things in the data
        public readonly trends: Trend[],
        public readonly dataPoints: DataPoint[],
    ) { }

    structuredContent(result: QueryResult): NodeStructuredSection[] {
        return [
            new NodeStructuredSection(
                'Trends',
                this.trends.map(t => new FactDetail(t.label, t.why, true))
            ),
            new NodeStructuredSection(
                'Data of Interest',
                this.dataPoints.map(d => DataRowDetail.fromRow(d.why, d.row, result))
            )
        ];
    }

    static fromJSON(json: TrendsAndDataPointsJson): TrendsAndDataPoints {
        return new TrendsAndDataPoints(
            json.label,
            json.summary,
            json.trends.map(t => Trend.fromJSON(t)),
            json.dataPoints.map(d => DataPoint.fromJSON(d))
        );
    }

}

export class Trend {

    constructor(
        public readonly label: string,
        public readonly why: string
    ) {}

    toString(): string {
        return `${this.label}: ${this.why}`;
    }

    static fromJSON(json: JsonObject): Trend {
        return new Trend(json.label, json.why);
    }

}

export class DataPoint {

    constructor(
        public readonly row: (string | number)[],
        public readonly why: string
    ) {}

    toString(): string {
        // vertex API sometimes returns empty row...
        return this.row == null ?
            this.why :
            `${this.why} (${this.row.join(", ")})`;
    }

    static fromJSON(json: JsonObject): DataPoint {
        return new DataPoint(json.row, json.why);
    }

}

export type TrendsAndDataPointsJson = {

    // trends of interest across data points
    trends: {
        label: string,
        why: string
    }[]

    // specific rows of interest
    dataPoints: {
        row: (string | number)[],
        why: string
    }[]

    /**
     * Note we ask for summary after it has generated the facts above so that it might use what is has previously
     * generated to more accurately predict the next token which is also why we ask for the label summarizing the
     * summary last.
     */

    // longer summary of the trends and data points
    summary: string
    // short label describing the trends and data points
    label: string

}