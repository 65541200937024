import React, {FunctionComponent} from 'react';
import {TypedMenu} from "app/components/TypedMenu";
import {TrendAction} from "app/dashboard/TrendAction";

interface Props {
    el: Element,
    actions: TrendAction[]
    onAction: (action: TrendAction) => void
    onCancel: () => void
}

/**
 * Menu upon Trends action on a widget.
 */
export const TrendActionMenu: FunctionComponent<Props> = (props) => {

    const onMenuAction = (action: TrendAction) => {
        props.onAction(action);
    };

    const onCloseMenu = () => {
        props.onCancel();
    };

    return (
        <TypedMenu
            el={props.el}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            items={[props.actions]}
            itemId={item => item.id}
            itemLabel={item => item.label}
            onSelect={onMenuAction}
            onClose={onCloseMenu}
        />
    );
};