import * as React from "react"
import {SVGProps} from "react"

const SankeyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M10.56 15.949c3.746 4.126 8.503 4.354 9.213 4.364v-3.108c-.743.114-5.108.627-8.02-2.558a9.857 9.857 0 0 1-1.193 1.302ZM9.441 14.586a7.796 7.796 0 0 0 1.032-1.355 14.73 14.73 0 0 0-.864-.808c-.348.443-.7.83-1.048 1.165.283.294.577.626.88.998ZM9.583 8.874c-3.291-1.585-5.744-1.103-6.174-1v.893c.109-.013.292-.028.544-.028.91 0 2.694.217 4.838 1.589.27-.434.535-.917.792-1.454ZM11.3 9.857c-.187.27-.37.558-.549.868a15.03 15.03 0 0 1-.449.73c.267.226.536.47.808.736.397-.613.801-1.15 1.208-1.616-.1-.07-.198-.142-.3-.215-.243-.18-.482-.346-.716-.502l-.001-.001ZM14.31 11.72c-.601.42-1.202 1.002-1.77 1.796 3.525 2.849 6.725 2.34 7.233 2.233v-3.404c-.522.153-2.655.636-5.463-.626Z"
        />
        <path
            fill="#F8BF00"
            d="M10.592 10.626c3.398-5.894 8.322-4.289 9.18-3.952V3.593c-.764-.117-7.098-.882-9.937 5.192-2.502 5.352-5.816 5.652-6.426 5.66v1.13c.574.056 4.222.19 7.183-4.949Z"
        />
        <path
            fill="#F8BF00"
            d="M12.119 13.801c2.941-4.56 6.884-3.138 7.654-2.8V7.919c-.581-.186-5.23-1.412-9.022 5.218-2.368 4.139-6.625 3.989-7.342 3.933v.948c.664.1 5.569.655 8.71-4.216Z"
        />
    </svg>
);
export default SankeyIcon;
