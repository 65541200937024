import {Either} from "common/Either";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {RemoteDataSource, RemoteDataSourceJson} from "metadata/connections/RemoteDataSource";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {CreateConnectionRequest} from "metadata/connections/CreateConnectionRequest";
import {TestConnectionResponse, TestConnectionResponseJson} from "metadata/connections/TestConnectionResponse";
import {DeleteConnectionResponse, DeleteConnectionResponseJson} from "metadata/connections/DeleteConnectionResponse";
import {RemoteDatabase, RemoteListing, RemoteSchema, RemoteTable} from "metadata/connections/RemoteItem";
import {RemoteTableMetadata, RemoteTableMetadataJson} from "metadata/connections/RemoteTableMetadata";

/**
 * API service for organization related operations
 */
export class OrgService {

    /**
     * Save a new connection.
     */
    async saveConnection(orgName: string, request: CreateConnectionRequest): Promise<Either<ErrorResponse, RemoteDataSource>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/orgs/${orgName}/connections`, request)
            .then(r => ApiResponse.custom(
                r,
                (json: RemoteDataSourceJson) => RemoteDataSource.fromJSON(json)
                ,
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * Get a connection.
     */
    async getConnection(orgName: string, connectionId: string): Promise<Either<ErrorResponse, RemoteDataSource>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/orgs/${orgName}/connections/${connectionId}`)
            .then(r => ApiResponse.custom(
                r,
                (json: RemoteDataSourceJson) => RemoteDataSource.fromJSON(json)
                ,
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * Delete a connection.
     */
    async deleteConnection(orgName: string, connectionId: string): Promise<Either<ErrorResponse, DeleteConnectionResponse>> {
        return ServiceProvider.get(RestService)
            .delete(`/api/v1/orgs/${orgName}/connections/${connectionId}`)
            .then(r => ApiResponse.custom(
                r,
                (json: DeleteConnectionResponseJson) => DeleteConnectionResponse.fromJson(json),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * Test a connection
     */
    async testConnection(orgName: string, connectionId: string): Promise<Either<ErrorResponse, TestConnectionResponse>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/orgs/${orgName}/connections/${connectionId}/test`, {})
            .then(r => ApiResponse.custom(
                r,
                (json: TestConnectionResponseJson) => TestConnectionResponse.fromJson(json)
                ,
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * List the remote databases associated with the given connection
     */
    async listRemoteDatabases(orgName: string, connectionId: string): Promise<Either<ErrorResponse, RemoteListing<RemoteDatabase>>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/orgs/${orgName}/connections/${connectionId}/databases`)
            .then(r => ApiResponse.custom(
                r,
                (json: any) => RemoteListing.fromJSON(json, RemoteDatabase.fromJSON),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * List the remote schemas associated with the given connection and database
     */
    async listRemoteSchemas(orgName: string, connectionId: string, database: string): Promise<Either<ErrorResponse, RemoteListing<RemoteSchema>>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/orgs/${orgName}/connections/${connectionId}/databases/${database}/schemas`)
            .then(r => ApiResponse.custom(
                r,
                (json: any) => RemoteListing.fromJSON(json, RemoteSchema.fromJSON),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * List the remote tables associated with the given connection, database and schema
     */
    async listRemoteTables(orgName: string, connectionId: string, database: string, schema: string): Promise<Either<ErrorResponse, RemoteListing<RemoteTable>>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/orgs/${orgName}/connections/${connectionId}/databases/${database}/schemas/${schema}/tables`)
            .then(r => ApiResponse.custom(
                r,
                (json: any) => RemoteListing.fromJSON(json, RemoteTable.fromJSON),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    /**
     * Get the metadata for a remote table in a connection
     */
    async getRemoteTable(orgName: string, connectionId: string, database: string, schema: string, table: string): Promise<Either<ErrorResponse, RemoteTableMetadata>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/orgs/${orgName}/connections/${connectionId}/databases/${database}/schemas/${schema}/tables/${table}`)
            .then(r => ApiResponse.custom(
                r,
                (json: RemoteTableMetadataJson) => RemoteTableMetadata.fromJSON(json),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }
}