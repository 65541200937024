import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {Optional} from "common/Optional";
import {FQN} from "common/FQN";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";

/**
 * Message for when there are changes to global filters.
 *
 * @author zuyezheng
 */
export class GlobalFiltersMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.GLOBAL_FILTERS;

    constructor(
        public readonly clauses: GlobalFilterClause[]
    ) {
    }

    /**
     * True if any clause is for the given dataset.
     */
    hasDataset(datasetFqn: FQN): boolean {
        return this.clauses.some(c => c.datasetFqn.equals(datasetFqn));
    }

    /**
     * Filter clauses for the given dataset. Optionally include clauses that are filter by all, will be skipped by
     * default.
     */
    forDataset(datasetFqn: FQN, includeAll: boolean = false): GlobalFilterClause[] {
        return this.clauses.filter(
            c => (includeAll || !c.isAll) && c.datasetFqn.equals(datasetFqn)
        );
    }

}

/**
 * Match to global filter messages containing clauses for the given dataset.
 */
export class GlobalFilterEventFilter implements ArcEventFilter {

    constructor(public readonly datasetFqn: FQN) {
    }

    of(event: ArcEvent): boolean {
        return this.filter(event).map(() => true).getOr(false);
    }

    /**
     * Return an optional of the event message if it matches the criteria of this event filter.
     */
    filter(event: ArcEvent): Optional<GlobalFiltersMessage> {
        return Optional.ofType(event.message, GlobalFiltersMessage)
            .filter(message => message.hasDataset(this.datasetFqn));
    }

}
