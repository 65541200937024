import {AssetType} from "metadata/AssetType";
import {FQN} from "common/FQN";
import {OrderedMap} from "common/OrderedMap";
import {Tab} from "app/Tab";
import {Optional} from "common/Optional";


/**
 * Service to mirror tab state maintained in the App component.
 *
 * TODO This should probably be the source of truth for tab state vs the component.
 *
 * @author zuyezheng
 */
export class TabService {

    private tabs: OrderedMap<string, Tab>;

    constructor() {
        this.tabs = OrderedMap.empty();
    }

    setTabs(tabs: OrderedMap<string, Tab>): void {
        this.tabs = tabs;
    }

    getByPath(path: string): Optional<Tab> {
        return this.tabs.get(path)
    }

    getByAssetType(type: AssetType): Tab[] {
        return this.tabs.values.filter(tab => tab.path.possibleFqn
            .map(fqn => fqn.type === type)
            .getOr(false)
        )
    }

    getByFqn(fqn: FQN): Optional<Tab> {
        return Optional.some(
            this.tabs.values.find(tab => tab.path.possibleFqn
                .map(tabFqn => tabFqn.equals(fqn))
                .getOr(false)
            )
        );
    }

}