/**
 * Container for our environment-specific configuration. These values are injected into the index.html file that
 * gets sent to the client as values on `window`. If any config values are added, they will also need to be added
 * to server/configure-server.js
 */
import {Cookies} from "common/Cookies";

declare global {
    interface Window {
        AUTH0_DOMAIN: string;
        AUTH0_CLIENT_ID: string;
        AUTH0_AUDIENCE: string;
        API_HOST: string;
        ENVIRONMENT: string;
        USER_ANALYTICS: string;
        APPCUES_SCRIPT: string;
        VERSION: string;
        SENTRY_DSN?: string;
        SENTRY_TUNNEL_PATH?: string;
    }
}

export class Config {

    static fromWindow(): Config {
        // test automation
        if (Cookies.getBoolean('isTest')) {
            return new TestConfig();
        }

        try {
            return new Config(
                window.AUTH0_DOMAIN,
                window.AUTH0_CLIENT_ID,
                window.AUTH0_AUDIENCE,
                window.API_HOST,
                window.ENVIRONMENT,
                window.USER_ANALYTICS,
                window.APPCUES_SCRIPT,
                window.VERSION,
                window.SENTRY_DSN,
                window.SENTRY_TUNNEL_PATH
            );
        } catch(e) {
            // no window, test environment
            return new TestConfig();
        }
    }

    constructor(
        public readonly auth0Domain: string,
        public readonly auth0ClientId: string,
        public readonly auth0Audience: string,
        public readonly apiHost: string,
        public readonly environment: string,
        public readonly userAnalytics: string,
        public readonly appcuesScript: string,
        public readonly version: string,
        public readonly sentryDsn?: string,
        public readonly sentryTunnelPath?: string
    ) { }

    get isTest(): boolean {
        return false;
    }

}

export class TestConfig extends Config {

    constructor() {
        super('', '', '', '', '', '', '', '');
    }

    get isTest(): boolean {
        return true;
    }

}