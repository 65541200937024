import * as React from "react";
import {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import {DialogS} from "app/DialogS";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {EnterDetailProps} from "app/components/settings/connections/ConnectionStepperHandler";
import {SnowflakeInputConnectionDetails} from "metadata/connections/snowflake/SnowflakeInputConnectionDetails";
import {SnowflakeAuthentication} from "app/components/settings/connections/snowflake/SnowflakeAuthentication";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import {BoxShadow, Colors, FontSizes} from "app/components/StyleVariables";

/**
 * Step for entering details of a Snowflake connection.
 */
export const EnterSnowflakeConnection: FunctionComponent<EnterDetailProps> = (props: EnterDetailProps) => {

    const [authenticationMethod, setAuthenticationMethod] = useState<SnowflakeAuthentication>(SnowflakeAuthentication.KEYPAIR);
    const [account, setAccount] = useState<string>('');
    const [username, setUsername] = useState<string>('');

    useEffect(
        () => {
            props.onDetailChange(
                new SnowflakeInputConnectionDetails(
                    account,
                    username
                )
            );
        },
        [account, username]
    );

    return (
        <div>
            <DialogS.SelectorRow>
                <FormControl>
                    <FormLabel id="authmethod-label" sx={{mb: 0.5}}>Authentication Method</FormLabel>
                    <Select
                        labelId="authmethod-label"
                        id="authmethod-select"
                        value={authenticationMethod}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setAuthenticationMethod(
                            SnowflakeAuthentication.get<SnowflakeAuthentication>(e.target.value)
                        )
                        }
                        size="small"
                    >
                        {
                            SnowflakeAuthentication.enums<SnowflakeAuthentication>().map(a =>
                                <MenuItem
                                    key={a.name} value={a.name}>{a.label}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </DialogS.SelectorRow>
            <DialogS.InputRow>
                <FormControl fullWidth>
                    <FormLabel id="account-label" sx={{mb: 0.5}}>Account</FormLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <S.FilledInput>
                            https://
                        </S.FilledInput>
                        <TextField
                            autoFocus
                            margin="none"
                            size="small"
                            id="label"
                            aria-labelledby="account-label"
                            placeholder="your account name"
                            value={account}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setAccount(e.target.value)}
                            sx={{
                                width: "300px"
                            }}
                        />
                        <S.FilledInput>
                            .snowflakecomputing.com
                        </S.FilledInput>
                    </Box>

                </FormControl>
            </DialogS.InputRow>
            <DialogS.InputRow>
                <FormControl fullWidth>
                    <FormLabel id="username-label" sx={{mb: 0.5}}>Username</FormLabel>
                    <TextField
                        autoFocus
                        margin="none"
                        size="small"
                        id="label"
                        aria-labelledby="username-label"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                        sx={{
                            width: "250px"
                        }}
                    />
                </FormControl>
            </DialogS.InputRow>
        </div>
    );
};

class S {

    static readonly FilledInput = styled(Box)`
        background-color: ${Colors.disabledGrey};
        font-size: ${FontSizes.medium};
        border-radius: 4px;
        padding: 8px;
        box-shadow: ${BoxShadow.level1};
    `;
}