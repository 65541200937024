import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {AddField} from "metadata/query/changes/AddField";

/**
 * Delete a field.
 *
 * @author zuyezheng
 */
export class DeleteField implements ArcMetadataChange<ArcQL> {

    constructor(
        // delete the field with the given projected as name
        public readonly fieldAs: string
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const oldField = metadata.fields.find(this.fieldAs).get();

        return new Tuple(
            metadata.with({
                'fields': metadata.fields.without(this.fieldAs),
                'orderBys': metadata.orderBys.without(this.fieldAs)
            }),
            new AddField(
                oldField.left,
                oldField.right,
                metadata.orderBys.find(oldField.left.as)
            )
        );
    }

    describe(): string {
        return `Deleted field ${this.fieldAs}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}