import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";

export class ModifyGlobalFilter implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly clause: GlobalFilterClause,
        public readonly ordinal: number
    ) {
    }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return new Tuple(
            metadata.with({
                globalFilters: metadata.globalFilters.replace(this.ordinal, this.clause)
            }),
            new ModifyGlobalFilter(metadata.globalFilters.get(this.ordinal), this.ordinal)
        );
    }

    describe(): string {
        return `Modified global filter for ${this.clause.column} on ${this.clause.datasetFqn.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}