import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VizSelection} from "engine/actor/VizSelection";
import {Optional} from "common/Optional";
import {DetailDateField} from "metadata/query/DetailDateField";
import {DateGrain} from "metadata/query/DateGrain";


/**
 * Builds the dataset for a multi metric scatter chart.
 *
 * @author zuyezheng
 */
export abstract class ScatterData {

    public readonly xField: string;
    public readonly yField: string;
    public readonly zField: Optional<string>;
    public readonly categories: {[key: string]: any}[];

    protected readonly xIndex: number;
    protected readonly yIndex: number;
    protected readonly zIndex: Optional<number>;

    protected readonly xFormatter: (v: any) => number;
    protected readonly yFormatter: (v: any) => number;

    constructor(
        protected readonly response: ArcQLResponse,
        protected readonly palette: string[],
        hasZ: boolean,
        // how many x-axis labels to create if its a time dimension
        xAxisTimeCategories: number = 5
    ) {
        const result = this.response.result;

        const xField = this.response.arcql.fields.fields[0];
        const yField = this.response.arcql.fields.fields[1];

        this.xField = xField.as;
        this.yField = yField.as;
        this.zField = Optional.bool(hasZ).map(() => this.response.arcql.fields.fields[2].as);

        this.xIndex = result.columnIndices.get(this.xField);
        this.yIndex = result.columnIndices.get(this.yField);
        this.zIndex = this.zField.map(f => result.columnIndices.get(f));

        // need to do some special stuff for dates
        if (xField instanceof DetailDateField) {
            // if the x-axis is a date, do some date pretty-ifing for the x-axis labels (we can't do this for y)
            this.xFormatter = (v) => xField.grain.toEpoch(v);

            // need to find the min max date range
            let minEpoch: number;
            let maxEpoch: number;
            result.forEachRow((row: any[]) => {
                const rowEpoch = this.xFormatter(row[this.xIndex]);
                if (minEpoch == null || rowEpoch < minEpoch) {
                    minEpoch = rowEpoch;
                }
                if (maxEpoch == null || rowEpoch > maxEpoch) {
                    maxEpoch = rowEpoch;
                }
            });

            // if the timestep is less then a day, show seconds, else use days
            const timeStep = Math.round((maxEpoch - minEpoch) / (xAxisTimeCategories - 1));
            const grain = timeStep < 86400000 ? DateGrain.SEC : DateGrain.DAY;

            // figure out where to draw the labels
            const epochCategories = [minEpoch];
            for (let i=1; i<xAxisTimeCategories - 1; i++) {
                epochCategories.push(minEpoch + (i * timeStep));
            }
            epochCategories.push(maxEpoch);

            // build the actual categories
            this.categories = epochCategories.map(e => ({
                x: e,
                label: grain.formatEpoch(e)
            }));
        } else {
            this.xFormatter = (v) => v;
        }
        this.yFormatter = yField instanceof DetailDateField ?
            (v) => yField.grain.toEpoch(v):
            (v) => v;
    }

    /**
     * Every unique grouping value ("category" in cartesian charts) will get its own series.
     */
    abstract dataset(selections: VizSelection): {[key: string]: any}[];

    protected buildDataPoint(row: any[], displayValue: string, selections: VizSelection): {[key: string]: any} {
        const categoryValues = this.response.result.categoryColumns.map(c => row[c.right]);
        const alpha = selections.has(categoryValues) ? 100 : 35;

        const dataPoint:{[key: string]: any} = {
            x: this.xFormatter(row[this.xIndex]),
            y: this.yFormatter(row[this.yIndex]),
            displayValue: displayValue,
            categoryValues: categoryValues,
            anchorBgAlpha: alpha,
            alpha: alpha
        };

        this.zIndex.forEach(z => {
            dataPoint['z'] = row[z];
        });

        return dataPoint;
    }

}