import {grey} from "@mui/material/colors";
import {Colors, FontSizes} from "app/components/StyleVariables";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {ThemeOptions} from "@mui/material/styles";

export const DefaultTheme: ThemeOptions = {
    palette: {
        primary: {
            main: Colors.hyperarcYellow,
            light: Colors.hyperarcYellowA(0.15)
        },
        secondary: {
            main: '#FFFFFF',
            light: '#FFFFFF',
            dark: '#000000',
        },
        warning: {
            main: Colors.formWarning,
            light: Colors.formWarningA(0.8)
        },
    },
    typography: {
        fontFamily: 'NotoSans, sans-serif',
        fontSize: 14,
        body2: {
            fontSize: FontSizes.small,
        },
        h3: {
            fontSize: '1.953rem',
            fontWeight: 300,
        },
        caption: {
            fontSize: FontSizes.xSmall,
        }
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#1976d2'
                }
            }
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    '&.MuiButton-contained': {
                        color: grey[800],
                        backgroundColor: Colors.hyperarcYellowSecondary,
                        '&:hover': {
                            color: 'black',
                            backgroundColor: Colors.hyperarcYellow,
                        }
                    },
                    '&.Mui-disabled':{
                        backgroundColor: Colors.hyperarcYellowTertiary,
                        opacity: 0.5
                    },
                    '&.MuiButton-outlinedPrimary': {
                        color: grey[600],
                        borderColor: grey[400],
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: grey[700],
                            color: grey[900],
                        },
                        '&.Mui-disabled': {
                            backgroundColor: grey[100],
                            opacity: 0.5,
                        }
                    }
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    '.MuiInputBase-input': {
                        '&.MuiInput-input': {
                            fontSize: '0.857rem',
                            fontWeight: 400,
                        }
                    }
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    paddingTop: 4,
                    paddingRight: 6,
                    paddingBottom: 4,
                    color: grey[300],
                    '&:hover': {
                        color: grey[400],
                    },
                    '&.Mui-checked': {
                        color: Colors.hyperarcYellow,
                        '&:hover': {
                            color: Colors.hyperarcYellowTertiary,
                        }
                    }
                }
            }
        },
        // @ts-ignore
        MuiDataGrid: {
            defaultProps: {
                columnHeaderHeight: 48,
            },
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    '.MuiDataGrid-columnHeader': {
                        color: grey[600],
                        fontSize: '0.89rem',
                        letterSpacing: '0.01rem',
                        '&:focus':{
                            outline: 'none',
                        }
                    },
                    '.MuiDataGrid-cell:hover, .MuiDataGrid-row:hover': {
                        backgroundColor: grey[50],
                    },
                    '.MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    fontSize: '0.9rem',
                    marginTop: '2px',
                    '.MuiInput-input': {
                        color: grey[900],
                        fontSize: '0.9rem',
                    },
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    lineHeight: '1.6rem',
                    '&.Mui-focused': {
                        color: grey[700],
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '.MuiInput-input': {
                        fontSize: FontSizes.medium,
                    },
                    '&.MuiInput-underline:before': {
                        borderBottomColor: grey[300],
                    },
                    '&.MuiInput-underline:hover:before': {
                        borderBottomColor: grey[600],
                    }
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '.MuiSelect-multiple': {
                        '&.MuiInputBase-inputSizeSmall': {
                            padding: '7px 8px',
                        }
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: FontSizes.medium,
                    color: grey[400],
                    transform: 'translate(2px, 17px) scale(1)',
                    '&.MuiInputLabel-sizeSmall': {
                        fontSize: '0.9rem',
                    },
                    '&.MuiInputLabel-shrink': {
                        fontSize: '0.9rem',
                        color: grey[500],
                        transform: 'translate(0, -0.3px) scale(0.9)',
                    },
                },
                standard: {
                    fontSize: '0.9rem',
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 16,
                    borderRadius: 8,
                    backgroundColor: grey[200],
                    '.MuiLinearProgress-bar': {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 8,
                        backgroundColor: Colors.hyperarcYellowSecondary,
                    }
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: grey[300],
                    paddingTop: 4,
                    paddingRight: 6,
                    paddingBottom: 4,
                    '&:hover': {
                        color: grey[400],
                    },
                    '&.Mui-checked': {
                        color: Colors.hyperarcYellow,
                        '&:hover': {
                            color: Colors.hyperarcYellowTertiary,
                        }
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: KeyboardArrowDownOutlinedIcon,
            },
            styleOverrides: {
                select: {
                    '&.MuiInputBase-inputSizeSmall': {
                        fontSize: '0.9rem',
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '&.MuiSelect-icon': {
                        width: '18px',
                        color: grey[400],
                        '&:hover': {
                            color: 'black',
                        }
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: FontSizes.small,
                    padding: '8px 16px',
                    backgroundColor: Colors.hyperarcYellow,
                    color: 'black',
                },
                arrow: {
                    color: Colors.hyperarcYellow,
                }
            }
        }
    }
};
