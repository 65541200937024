import * as React from "react";
import {FunctionComponent} from "react";
import {ReferenceQuery} from "metadata/dashboard/DashboardQueries";
import {SelectChangeEvent} from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CheckIcon from '@mui/icons-material/Check';
import {FacetingOption} from "metadata/dashboard/FacetingOption";
import {GlobalFiltersSetting} from "metadata/dashboard/GlobalFiltersSetting";
import {SelectMode} from "metadata/dashboard/SelectMode";
import {FacetEmitSetting} from "metadata/dashboard/FacetEmitSetting";
import {ToolbarS} from "app/dashboard/widgets/ToolbarS";
import {ExplainedSettingButtonMenu} from "app/dashboard/widgets/config/ExplainedSettingButtonMenu";
import {ToolbarLabeledSelectMenu} from "app/dashboard/widgets/config/ToolbarLabeledSelectMenu";

interface EditorProps {
    queryMetadata: ReferenceQuery
    onQueryMetadataChange: (config: { [key: string]: any }) => void
}


/**
 * Inline editor for chart widgets on dashboards.
 */
export const QueryMetadataToolbar: FunctionComponent<EditorProps> = (props: EditorProps) => {

    const onFacetChange = (event: SelectChangeEvent<string[]>) => {
        const options: Set<string> = new Set(event.target.value);
        props.onQueryMetadataChange(
            {
                'emitFacets': options.has(FacetingOption.EMIT.label),
                'receiveFacets': options.has(FacetingOption.RECEIVE.label),
            }
        );
    };

    const onCompositeChange = (event: SelectChangeEvent) => {
        props.onQueryMetadataChange(
            {
                'compositeFacets': event.target.value === FacetEmitSetting.ALL.label
            }
        );
    };

    const onGlobalFiltersChange = (setting: GlobalFiltersSetting) => {
        props.onQueryMetadataChange(
            {
                'receiveGlobalFilters': setting === GlobalFiltersSetting.RECEIVE || setting === GlobalFiltersSetting.RECEIVE_AND_REPLACE,
                'globalFiltersReplace': setting === GlobalFiltersSetting.RECEIVE_AND_REPLACE
            }
        );
    };

    const onSelectModeChange = (mode: SelectMode) => {
        props.onQueryMetadataChange(
            {
                'selectMode': mode
            }
        );
    };

    return <>
        <ToolbarS.Section dividerHeight='40px'>
            <ToolbarLabeledSelectMenu
                title='Faceting'
                tooltipTitle='Faceting (Cross-Filter)'
                selected={props.queryMetadata.facetingOptions.map(o => o.label)}
                onSelectionChange={onFacetChange}
                settings={FacetingOption.enums<FacetingOption>()}
                isSelectedIcon={<ToolbarS.ThemedCheck/>}
                helperText='To disable faceting for this chart, simply deselect both options above.'
                additionalSelectProps={
                    {
                        multiple: true,
                        displayEmpty: true,
                        renderValue: (selected: string[]) => {
                            if (selected.length === 0) {
                                return <ToolbarS.SelectedText>Disabled</ToolbarS.SelectedText>;
                            }
                            return <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {
                                    selected.map((value) => (
                                        <Chip key={value} label={value} size="small" icon={<CheckIcon/>}
                                              color="secondary"/>
                                    ))
                                }
                            </Box>;
                        },
                        sx: {width: '150px'}
                    }
                }
            />
        </ToolbarS.Section>
        <ToolbarS.Section dividerHeight='40px'>
            <ToolbarLabeledSelectMenu
                title='Emit Faceting Settings'
                tooltipTitle='Emit Faceting Settings'
                selected={props.queryMetadata.emitSetting.label}
                onSelectionChange={onCompositeChange}
                settings={FacetEmitSetting.enums<FacetingOption>()}
                additionalSelectProps={
                    {
                        renderValue: (selected: string) => <ToolbarS.SelectedText>{selected}</ToolbarS.SelectedText>,
                        sx: {width: '180px'}
                    }
                }
            />
        </ToolbarS.Section>
        <ToolbarS.Section dividerHeight='40px'>
            <ExplainedSettingButtonMenu
                tooltipCategorization='Global Filters Setting'
                menuButtonIcon={<props.queryMetadata.globalFilterSetting.icon/>}
                currentSetting={props.queryMetadata.globalFilterSetting}
                onSettingChange={onGlobalFiltersChange}
                settings={GlobalFiltersSetting.enums<GlobalFiltersSetting>()}
            />
        </ToolbarS.Section>
        <ToolbarS.Section dividerHeight='40px'>
            <ExplainedSettingButtonMenu
                tooltipCategorization='Select Mode'
                menuButtonIcon={<props.queryMetadata.selectMode.icon/>}
                currentSetting={props.queryMetadata.selectMode}
                onSettingChange={onSelectModeChange}
                settings={SelectMode.enums<SelectMode>()}
            />
        </ToolbarS.Section>
    </>
        ;
};
