import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {ArcEngineActorConnector} from "engine/ArcEngineActorConnector";

/**
 * Actors are loosely coupled bridges between the Engine and the UI. Actors can send arbitrary messages to the engine
 * but cannot specify the recipient of messages. Actors can specify which messages it should be notified about through
 * event filters, any message that matches any filter (OR) will trigger a notify on the actor.
 *
 * This forces actors to be designed to message specs vs tightly coupled actor to actor interactions allowing for more
 * composable UIs such as for interactive dashboards that are constructed and customed by users vs predefined and need
 * to support features such as faceting by dataset which can be sent by any visualization/actor on the dashboard.
 *
 * @author zuyezheng
 */
export abstract class ArcActor {

    protected connector: ArcEngineActorConnector;

    constructor(
        public readonly id: string
    ) { }

    /** Connect the actor to the engine. */
    connect(connector: ArcEngineActorConnector): void {
        this.connector = connector;
    }

    /**
     * Callback to do any initialization steps. This will be called once after connected and can contain async
     * operations such as fetching metadata. it will not block other actors from being registered and initialized.
     *
     * Once initialization is finished and the promise returned, the engine will resend any existing state messages
     * matching the actor's filters if the promise value is true.
     *
     * If an actor needs to initialize other actors and might need to handle messages from those actors, postInitialize
     * should be used as this actor might not have been marked initialized yet.
     */
    async initialize(controller: AbortController): Promise<boolean> {
        return Promise.resolve(false);
    }

    /**
     * Will be called after initialization success.
     */
    postInitialize() {
        return;
    }

    /** Which events does the actor care about being notified about. */
    abstract eventFilters(): ArcEventFilter[];

    /** Notify the actor of a matching event. */
    abstract notify(event: ArcEvent): void;

}