/**
 * Why are we making the query for this node?
 *
 * @author zuyezheng
 */
export interface HyperGraphNodeHypothesis {

    get change(): string;

    get why(): string;

    get nullHypothesis(): string;

    get alternativeHypothesis(): string;

}

export class SimpleHypothesis implements HyperGraphNodeHypothesis {

    constructor(
        public readonly why: string,
        public readonly change: string,
        public readonly nullHypothesis: string = 'The change does not surface anything new in the underlying data.',
        public readonly alternativeHypothesis: string = 'The change surfaces new possibilities for exploration.'
    ) { }

}