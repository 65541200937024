import {ArcQL} from "metadata/query/ArcQL";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * ArcQL with fully fetched references.
 *
 * @author zuyezheng
 */
export class ArcQLBundle {

    constructor(
        public readonly arcql: ArcQL,
        public readonly dataset: ArcDataset
    ) { }

}