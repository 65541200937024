import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcQLSourceRefType, SingleSource} from "metadata/query/ArcQLSource";
import {Optional} from "common/Optional";
import {FQN} from "common/FQN";
import {References} from "metadata/References";
import {AssetType} from "metadata/AssetType";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";

export class SelectSource implements ArcMetadataChange<ArcQL> {

    constructor(
        private readonly datasetFqn: FQN,
        private readonly newPersona: Optional<AssetSearchResult>
    ) {
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const newSource: SingleSource = this.newPersona.match(
            p => new SingleSource(ArcQLSourceRefType.PERSONA, p.fqn),
            () => new SingleSource(ArcQLSourceRefType.DATASET, this.datasetFqn)
        );

        const currentSourceFqn: FQN = (metadata.source as SingleSource).fqn;
        // If the current source is a persona, remove it from the references
        let previousPersona: Optional<AssetSearchResult> = Optional.none();
        const filteredReferences: AssetSearchResult[] = [];
        if (metadata.references !== null) {
            for (let ref of metadata.references.values()) {
                if (currentSourceFqn.type === AssetType.PERSONA && ref.fqn.equals(currentSourceFqn)) {
                    previousPersona = Optional.of(ref);
                } else {
                    filteredReferences.push(ref);
                }
            }
        }
        const newReferences: References = this.newPersona.match(
            p => {
                filteredReferences.push(p);
                return References.fromArray(filteredReferences);
            },
            () => {
                return References.fromArray(filteredReferences);
            }
        );

        return new Tuple(
            metadata.with({
                'source': newSource,
                'references': newReferences
            }),
            new SelectSource(this.datasetFqn, previousPersona)
        );
    }

    describe(): string {
        return `Using ${this.newPersona.map(p => p.fqn).getOr(this.datasetFqn)}`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}