import {domToJpeg} from 'modern-screenshot'
import Papa from "papaparse";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";

/**
 * Helper for downloading to CSV and images.
 *
 * @author zuyezheng
 */
export class ShareDownloader {

    static downloadImage(fileName: string, element: HTMLElement): void {
        domToJpeg(element, {
            // render the full element include anything hidden by scrolling
            quality: 0.9,
            width: element.clientWidth,
            height: element.scrollHeight
        }).then(dataUrl => {
            // start a download of the jpeg
            const link = document.createElement('a');
            link.download = fileName + '.jpeg';
            link.href = dataUrl;
            link.click();
        });
    }

    static downloadCsv(fileName: string, response: ArcQLResponse): void {
        // build out the columns of the csv with the field labels (vs names)
        const columns = response.result.columnRefs;
        // get the data rows and transform them into arrays given columns we just built
        const dataRows = response.result.objectRows('id')
            .map(rowObj => columns.map(c => rowObj[c.projectedAs]));

        // build the data with column labels as headers
        const data = new Blob(
            [Papa.unparse([
                columns.map(c => c.label),
                ...dataRows
            ])],
            { type: 'text/csv' }
        );

        // download
        const link = document.createElement('a');
        link.download = fileName + '.csv';
        link.href = window.URL.createObjectURL(data);
        link.click();
    }

}