import {JsonUtils} from "metadata/JsonUtils";
import {FQN} from "common/FQN";
import {JsonObject} from "common/CommonTypes";
import isEqual from "lodash/isEqual";
import {SelectMode} from "metadata/dashboard/SelectMode";
import {FacetingOption} from "metadata/dashboard/FacetingOption";
import {GlobalFiltersSetting} from "metadata/dashboard/GlobalFiltersSetting";
import {FacetEmitSetting} from "metadata/dashboard/FacetEmitSetting";

export class DashboardQueries {

    static empty() {
        return new DashboardQueries(new Map());
    }

    static fromJSON(json: { [key: string]: any }): DashboardQueries {
        return new DashboardQueries(JsonUtils.toMap(json, ReferenceQuery.fromJSON));
    }

    constructor(
        // named queries to their references
        public readonly queries: Map<string, DashboardQuery>
    ) {
    }

    map<T>(f: (queryId: string, query: DashboardQuery) => T): T[] {
        return Array.from(this.queries.entries()).map(v => f(v[0], v[1]));
    }

    has(queryId: string): boolean {
        return this.queries.has(queryId);
    }

    get(queryId: string): DashboardQuery {
        return this.queries.get(queryId);
    }

    with(queryId: string, query: DashboardQuery): DashboardQueries {
        const newQueries = new Map(this.queries);
        newQueries.set(queryId, query);

        return new DashboardQueries(newQueries);
    }

    withChange(queryId: string, change: (query: DashboardQuery) => DashboardQuery): DashboardQueries {
        const newQueries = new Map(this.queries);
        newQueries.set(queryId, change(this.queries.get(queryId)));

        return new DashboardQueries(newQueries);
    }

    without(queryId: string): DashboardQueries {
        const newQueries = new Map(this.queries);
        newQueries.delete(queryId);

        return new DashboardQueries(newQueries);
    }

    toJSON(): Object {
        return Object.fromEntries(this.queries);
    }

}

export abstract class DashboardQuery {

    protected constructor(
        public readonly id: string
    ) {
    }


    abstract get config(): JsonObject;

    abstract equals(other: DashboardQuery): boolean;

    abstract withConfigs(configs: JsonObject, merge: boolean): DashboardQuery;

}

export class ReferenceQuery extends DashboardQuery {

    static fromJSON(json: { [key: string]: any }, id: string): ReferenceQuery {
        return new ReferenceQuery(
            id,
            FQN.parse(json['fullyQualifiedName']),
            json['receiveFacets'],
            json['emitFacets'],
            json['compositeFacets'],
            json['receiveGlobalFilters'],
            json['globalFiltersReplace'],
            SelectMode.get(json['selectMode'])
        );
    }

    constructor(
        id: string,
        public readonly fqn: FQN,
        public readonly receiveFacets: boolean = true,
        public readonly emitFacets: boolean = true,
        public readonly compositeFacets: boolean = true,
        public readonly receiveGlobalFilters: boolean = true,
        public readonly globalFiltersReplace: boolean = false,
        public readonly selectMode: SelectMode = SelectMode.MULTI
    ) {
        super(id);
    }

    get config(): JsonObject {
        return {
            'receiveFacets': this.receiveFacets,
            'emitFacets': this.emitFacets,
            'compositeFacets': this.compositeFacets,
            'receiveGlobalFilters': this.receiveGlobalFilters,
            'globalFiltersReplace': this.globalFiltersReplace,
            'selectMode': this.selectMode.name
        };
    }

    equals(other: DashboardQuery): boolean {
        if (!(other instanceof ReferenceQuery)) {
            return false;
        }

        return isEqual(this, other);
    }

    get facetingOptions(): FacetingOption[] {
        const options: FacetingOption[] = [];
        if (this.emitFacets) {
            options.push(FacetingOption.EMIT);
        }
        if (this.receiveFacets) {
            options.push(FacetingOption.RECEIVE);
        }

        return options;
    }

    get emitSetting(): FacetEmitSetting {
        if (this.compositeFacets) {
            return FacetEmitSetting.ALL;
        }
        return FacetEmitSetting.FIRST_GROUPING_ONLY;
    }

    get globalFilterSetting(): GlobalFiltersSetting {
        if (!this.receiveGlobalFilters) {
            return GlobalFiltersSetting.IGNORE;
        }

        if (this.globalFiltersReplace) {
            return GlobalFiltersSetting.RECEIVE_AND_REPLACE;
        }

        return GlobalFiltersSetting.RECEIVE;
    }

    withConfigs(configs: JsonObject, merge: boolean = false): DashboardQuery {
        const mergeProp = <T>(newProp: T, existingProp: T): T => {
            if (merge) {
                return newProp === undefined ? existingProp : newProp;
            } else {
                return newProp;
            }
        };

        return new ReferenceQuery(
            this.id,
            this.fqn,
            mergeProp(configs['receiveFacets'], this.receiveFacets),
            mergeProp(configs['emitFacets'], this.emitFacets),
            mergeProp(configs['compositeFacets'], this.compositeFacets),
            mergeProp(configs['receiveGlobalFilters'], this.receiveGlobalFilters),
            mergeProp(configs['globalFiltersReplace'], this.globalFiltersReplace),
            mergeProp(configs['selectMode'], this.selectMode)
        );
    }

    toJSON(): JsonObject {
        return Object.assign({
            'fullyQualifiedName': this.fqn.toString(),
        }, this.config);
    }

}
