import {Enum} from "common/Enum";


/**
 * Enumerate all the roles a member can have in an organization.
 */
export class OrgMembershipRole extends Enum {

    static ADMIN = new this('admin', 'Admin');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
OrgMembershipRole.finalize();