import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";
import { Colors } from "app/components/StyleVariables";

export class SkeletonS {
    private static readonly progress = keyframes`
        0% {
            transform: translate3d(-100%, 0, 0);
        }
        100% {
            transform: translate3d(100%, 0, 0);
        }
    `;

    static readonly Line = styled.div`
        position: relative;
        overflow: hidden;
        background-color: ${Colors.skeletonBase};
        
        &.answer {
            height: 20px;
            margin: 4px 0;
            width: 100%;
            border-radius: 10px;
            &.shortest {
                width: 82%;
            }
            &.midlength {
                width: 90%;
            }
            &.longest {
                width: 95%;
            }
            &.endparagraph {
                margin-bottom: 32px;
            }
        }

        &.source {
            background-size: 200% 100%;

            &.source-group {
                display: flex;
                flex-direction: column;

                > * + * {
                    margin: 10px;
                }
            }

            &.icon {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                margin-top: 10px;
            }

            &.block {
                width: 260px;
                height: 12px;
                border-radius: 8px;
                margin-top: 10px;

                &.small {
                    width: 160px;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 400px;
            height: 100%;
            background: linear-gradient(90deg, ${Colors.skeletonBase}, ${Colors.skeletonHighlight}, ${Colors.skeletonBase});
            animation: ${SkeletonS.progress} 1s ease-in-out infinite;
        }
    `;

    private static readonly gradient = keyframes`
        from{
            background-position: 100% 0%;
        }
        to{
            background-position: 0% 0%;
        }
    `;

    static readonly GradientText = styled.span`
        animation: ${SkeletonS.gradient} 1s linear infinite;
        font-size: 13px;
        font-weight: normal;
        background: -webkit-linear-gradient(15deg,#888, #444, #aaa, #444);
        background-size: 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `;
}