import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL, ArcQLProps} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {AddFilter} from "metadata/query/changes/AddFilter";
import {FilterChange} from "metadata/query/changes/FilterChange";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Delete filter by ordinal.
 *
 * @author zuyezheng
 */
export class DeleteFilter extends FilterChange {

    constructor(
        public readonly ordinal: number,
        isAggregate: boolean,
        public readonly dataset: ArcDataset
    ) {
        super(isAggregate);
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const filters = this.filters(metadata);

        const newMetadata: Partial<ArcQLProps> = {};
        newMetadata[filters.left] = filters.right.without(this.ordinal);

        return new Tuple(
            metadata.with(newMetadata),
            new AddFilter(filters.right.get(this.ordinal), this.isAggregate, this.dataset, this.ordinal)
        );
    }

    describe(metadata: ArcQL): string {
        const filterType = this.isAggregate ? 'aggregate filter' : 'filter';
        const filterField = this.filters(metadata).right.get(this.ordinal).fieldsLabel(this.dataset);
        const fieldLabel = this.isAggregate ? filterField : this.dataset.getLabel(filterField);

        return `Deleted ${filterType} for ${fieldLabel}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}