import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {VizSelection} from "engine/actor/VizSelection";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {Optional} from "common/Optional";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ScatterData} from "app/visualizations/ScatterData";
import {SingleSeriesScatterData} from "app/visualizations/data/SingleSeriesScatterData";
import {MultiSeriesScatterData} from "app/visualizations/data/MultiSeriesScatterData";
import {SelectionEvent} from "app/visualizations/FusionTypes";

/**
 * Config for scatter and bubble charts.
 *
 * @author zuyezheng
 */
export class ScatterChartConfig extends ChartConfig {

    private data: ScatterData;
    private plotToolText: string;

    constructor(
        response: ArcQLResponse,
        config: VisualizationConfig,
        private readonly hasZ: boolean
    ) {
        super(response, config);
    }

    validate(): Optional<string> {
        // requires either 2 or 3 measures depending on if there is a z (bubble) axis
        const measuresRequired = this.hasZ ? 3 : 2;

        const measures = this.response.arcql.isGrouped() ?
            // for a grouped query, we look at only measures
            this.response.result.columnsByType(new Set([AnalyticsType.MEASURE])):
            // if it is a grain query, we can treat dates as measures as well
            this.response.result.columnsByType(new Set([AnalyticsType.MEASURE, AnalyticsType.DATE]));

        if (measures.length != measuresRequired) {
            if (measures.length == 2) {
                return Optional.some(`Only 2 measures, try a scatter chart.`);
            } else if (measures.length == 3) {
                return Optional.some(`More than 2 measures, try a bubble chart.`);
            }

            return Optional.some(`${measuresRequired} measures required.`);
        }

        // TODO ZZ standardize initialization as part of the charting framework
        this.initialize();

        return Optional.none();
    }

    private initialize() {
        const plotToolParts = [];
        if (this.response.arcql.groupings.size > 1) {
            this.data = new MultiSeriesScatterData(this.response, this.config.theme.palette, this.hasZ);
            plotToolParts.push('$seriesName: <b>$displayValue</b>');
        } else {
            this.data = new SingleSeriesScatterData(this.response, this.config.theme.palette, this.hasZ);
            plotToolParts.push('<b>$displayValue</b>');
        }

        plotToolParts.push(
            `${this.data.xField}: $xDataValue`,
            `${this.data.yField}: $yDataValue`,
        );
        this.data.zField.forEach(f => plotToolParts.push(`${f}: $zDataValue`));
        this.plotToolText = plotToolParts.join('<br>');
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    handleDiscreteClick(
        event: SelectionEvent, originalDataSource: {[key: string]: any}, eventType: string
    ): Optional<string[][]> {
        return Optional.some([
            originalDataSource.dataset[event.datasetIndex].data[event.dataIndex].categoryValues
        ]);
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        return {
            type: this.hasZ ? 'bubble' : 'scatter',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: this.config.get('showLegend').getOr(true),
                        showRegressionLine: this.config.get('showRegression').getOr(false),
                        showYOnX: this.config.get('yOnX').getOr(false),

                        xAxisName: this.config.emptyableString('xAxisName').getOr(this.data.xField),
                        yAxisName: this.config.emptyableString('yAxisName').getOr(this.data.yField),

                        plotToolText: this.plotToolText
                    }
                ),
                categories: [{
                    category: this.data.categories
                }],
                dataset: this.data.dataset(selections)
            }
        };
    }

}