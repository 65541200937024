import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Colors, Durations, FontSizes} from "app/components/StyleVariables";
import {SearchBar} from "app/components/search/SearchBar";
import {UserProfile} from "app/components/UserProfile";
import {Optional} from "common/Optional";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {useHistory} from "react-router-dom";
import {HomeSection} from "app/home/HomeSection";
import {TabProps} from "app/TabType";
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {BrowseResults} from "app/home/browse/BrowseResults";
import {FoldersSearchResponse} from "metadata/project/FoldersSearchResponse";
import {ScopedSearch} from "app/home/ScopedSearch";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ForYouResults} from "app/home/ForYouResults";
import {HomeBrowsePath} from "app/home/browse/HomeBrowsePath";
import {ScopedSearchNone} from "app/home/ScopedSearchNone";
import {ScopedSearchAccount} from "app/home/ScopedSearchAccount";
import {ScopedSearchProject} from "app/home/ScopedSearchProject";
import {BROWSE_PATH} from "common/Paths";
import {useHotkeys} from "react-hotkeys-hook";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import {AccountsSearchResponse} from "metadata/account/AccountsSearchResponse";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import {AccountResult} from "metadata/account/AccountResult";
import {useOnboardingState} from 'app/components/hooks/OnboardingHook';
import {HyperGraphAnswer} from "app/components/search/HyperGraphAnswer";

export const Home: FunctionComponent<TabProps> = (props: TabProps) => {

    const history = useHistory();

    const [selectedSection, setSelectedSection] = useState<HomeSection>(
        HomeSection.fromTabPath(props.path).getOr(HomeSection.FOR_YOU)
    );
    const [searchTerm, setSearchTerm] = useState<Optional<string>>(Optional.none());
    const [scopedSearch, setScopedSearch] = useState<ScopedSearch>(new ScopedSearchNone());

    const [focus, setFocus] = useState<boolean>(false);

    // for welcome banner state
    const {hasUploadedDatasets, hasCreatedQueries} = useOnboardingState();

    // handle any subroute changes
    useEffect(() => {
        // if the second part of the path is a valid home section, switch to it
        HomeSection.fromTabPath(props.path).forEach(section => {
            if (section === HomeSection.BROWSE) {
                const parsedBrowsePath = HomeBrowsePath.fromTabPath(props.path);
                if (parsedBrowsePath.isAccountPath) {
                    setScopedSearch(new ScopedSearchAccount(parsedBrowsePath.accountName));
                } else if (parsedBrowsePath.isProjectPath) {
                    ServiceProvider.get(MetadataService)
                        .fetchFolder(parsedBrowsePath.accountName, parsedBrowsePath.folderName)
                        .then(response => response.match(
                            folder => setScopedSearch(new ScopedSearchProject(folder)),
                            _ =>
                                ServiceProvider.get(NotificationsService).publish(
                                    'home', NotificationSeverity.ERROR, "Project not found or invalid permissions."
                                )
                        ));
                } else {
                    setScopedSearch(new ScopedSearchNone());
                }
            }
            // reset scoped search when switching back to FOR_YOU section
            if (section === HomeSection.FOR_YOU) {
                setScopedSearch(new ScopedSearchNone());
            }
            setSelectedSection(section);
        });
    }, [props.path]);

    useHotkeys(['mod+k', 'shift+mod+k'], (keyboardEvent: KeyboardEvent) => {
        setFocus(true);
        keyboardEvent.preventDefault();
    }, {keydown: true, keyup: true});

    const onSearchAssets = (params: AssetSearchParams, controller: AbortController): Promise<Either<ErrorResponse, AssetsSearchResponse>> => {
        return ServiceProvider.get(MetadataService)
            .assetSearch(params, controller.signal);
    };

    const onSearchProjects = (params: FolderSearchParams, controller: AbortController): Promise<Either<ErrorResponse, FoldersSearchResponse>> => {
        return ServiceProvider.get(MetadataService).folderSearch(params, controller.signal);
    };

    const onSearchAccounts = (params: AccountSearchParams, controller: AbortController): Promise<Either<ErrorResponse, AccountsSearchResponse>> => {
        return ServiceProvider.get(MetadataService).accountSearch(params, controller.signal);
    };

    const onScopeReset = () => {
        history.push(BROWSE_PATH);
    };

    const onSearch = (maybeTerm: Optional<string>) => {
        // if in FOR YOU section and user has clicked enter, jump to BROWSE
        if (selectedSection === HomeSection.FOR_YOU) {
            onSelectSection(HomeSection.BROWSE);
        }
        setSearchTerm(maybeTerm);
    };

    const onSelectSection = (selected: HomeSection) => {
        // reset query term when switching sections or scoped search
        setSearchTerm(Optional.none());
        history.push('/home/' + selected.name);
    };

    const onAccountSelect = (account: string) => {
        history.push(AccountResult.path(account));
    };

    const onResultSelect = (result: NavigableSearchResult) => {
        history.push(result.path);
    };

    const buildWelcomeBanner = () => {
        // if user has datasets and queries, don't show welcome banner
        if (hasUploadedDatasets && hasCreatedQueries) {
            return;
        }

        return <S.WelcomeBanner>
            <h1>Welcome to HyperArc</h1>
            <p>
                Discover insights faster with a complete memory of your analysis.<br/>
                Get started on your own data or test drive with one of our public datasets.
            </p>
            <S.BannerButtonGroup>
                <S.BannerButton
                    variant='contained'
                    color='primary'
                    onClick={() => history.push('/new/dataset/upload')}
                >
                    Upload a Dataset
                </S.BannerButton>
                <S.BannerButton
                    variant='contained'
                    color='primary'
                    onClick={() => history.push('/new/query')}
                >
                    Create a Query
                </S.BannerButton>
            </S.BannerButtonGroup>
        </S.WelcomeBanner>;
    };

    return <S.Home>
        <S.Body>
            { buildWelcomeBanner() }
            <S.Search>
                {
                    HomeSection.enums<HomeSection>().map(section =>
                        <Tooltip
                            key={section.name}
                            title={section.label}
                            disableInteractive
                            enterDelay={Durations.tooltipPrimaryEnterDelay}
                            enterNextDelay={Durations.tooltipPrimaryEnterNextDelay}
                            placement='top'
                            arrow
                        >
                            <S.HomeViewToggle
                                key={section.name}
                                className={section === selectedSection ? 'selected' : null}
                                onClick={() => onSelectSection(section)}
                            >
                                {section.icon()}
                            </S.HomeViewToggle>
                        </Tooltip>
                    )}
                <SearchBar
                    searchTerm={searchTerm.getOr('')}
                    scopedSearch={scopedSearch}
                    onSearch={onSearch}
                    // since browse doesn't have autocomplete, "auto" search after a delay
                    delayOnSearch={selectedSection === HomeSection.BROWSE ? 500 : null}
                    // if in for you section, rely on autocomplete search
                    autoComplete={selectedSection === HomeSection.FOR_YOU}
                    onSelectAutoComplete={onResultSelect}
                    onScopeReset={onScopeReset}
                    focus={focus}
                    onFocusChange={setFocus}
                />
            </S.Search>
                {selectedSection === HomeSection.BROWSE &&
                    <S.SearchResults>
                        <BrowseResults
                            query={searchTerm}
                            scopedSearch={scopedSearch}
                            onAccountBreadcrumb={onAccountSelect}
                            onSearchAssets={onSearchAssets}
                            onAssetSelectSearchResult={onResultSelect}
                            onSearchProjects={onSearchProjects}
                            onProjectSelect={onResultSelect}
                            onSearchAccounts={onSearchAccounts}
                            onAccountSelect={onResultSelect}
                        />
                    </S.SearchResults>
                }
                {selectedSection === HomeSection.FOR_YOU &&
                    <S.SearchResults>
                        <ForYouResults
                            query={searchTerm}
                            onSearchAssets={onSearchAssets}
                            onAssetSelectSearchResult={onResultSelect}
                        />
                    </S.SearchResults>
                }
        </S.Body>
    </S.Home>;

};

class S {
    static readonly Home = styled.div`
        display: flex;
        height: 100%;
    `;

    static readonly HomeViewToggle = styled(Button)`
        height: 40px;
        background-color: #F5F5F5;
        min-width: 48px;
        margin: 2px 8px 0 0;

        :hover {
            background-color: #F5F5F5;
        }

        svg {
            font-size: 1.3rem;
            color: ${Colors.iconTertiary};
        }

        &.selected, :hover {
            svg {
                color: ${Colors.textPrimary};
            }
        }
    `;

    static readonly RightNav = styled.div`
        width: 240px;
        height: 100%;
    `;

    static readonly UserMenu = styled(UserProfile)`
        padding: 24px;
    `;

    static readonly Body = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        padding: 10px 100px 0;
    `;

    static readonly Search = styled.div`
        padding: 30px 40px 48px;
        border-bottom: 1px solid ${Colors.borderGrey};
        display: flex;

        .MuiOutlinedInput-root {
            font-size: ${FontSizes.medium};
            padding-top: 2px;

            .MuiOutlinedInput-notchedOutline {
                border-color: ${Colors.borderGrey};
                padding: 17px;
            }

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: ${Colors.hyperarcYellow};
            }
        }

        .MuiOutlinedInput-input {
            color: black;

            ::placeholder {
                opacity: 0.5;
            }

            &:hover::placeholder {
                opacity: 0.9;
            }

            &:focus::placeholder {
                opacity: 0.25;
            }
        }
    `;

    static readonly SearchResults = styled.div`
        padding: 30px 30px 0;
    `;

    static readonly WelcomeBanner = styled.div`
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;

        > h1 {
            margin: 40px 0 0;
            color: ${Colors.textPrimary};
            font-size: ${FontSizes.xxLarge};
            font-weight: 400;
        }
        
        > p {
            color: ${Colors.textSecondary};
            font-size: ${FontSizes.medium};
            text-align: center;
        }

        background-color: ${Colors.hyperarcBanner};
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        min-height: 312px;

        background-image: url('static/images/onboarding/welcomeBanner.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    `;

    static readonly BannerButtonGroup = styled.div`
        display: flex;
        gap: 20px;

        margin-top: 60px;
        margin-bottom: 40px;
    `;

    static readonly BannerButton = styled(Button)`
        text-transform: none;
        width: 180px;
    `;

}