import {Tuple} from "common/Tuple";
import {Column, ColumnMatchResult} from "metadata/Column";

/**
 * Encapsulate a section of fields and allow operations like search and sort.
 *
 * @author zuyezheng
 */
export class FieldsPickerSection {

    public readonly parentFields: Column[];

    constructor(
        fields: Column[]
    ) {
        this.parentFields = fields.filter(f => f.isRoot);
    }

    /**
     Current assumptions & implementation:
     - parent fields live in the same section
     - parent field also returned if child field matched
     - will return all fields but with children limited to only what was found in search
     */
    search(term: string): ColumnMatchResult[] {
        if (term == null || term.length === 0) {
            // return all parent fields without matching to children since no term to match
            return this.parentFields.map(f => new ColumnMatchResult(f, false));
        }

        const termNormalized = term.toLowerCase();
        const matcher = (f: Column) => {
            // if label well-defined for field, use that. Else, use name
            if (f.label && f.label !== "") {
                return f.label.toLowerCase().indexOf(termNormalized) > -1;
            }
            return f.name.toLowerCase().indexOf(termNormalized) > -1;
        };

        return this.parentFields.flatMap(field => field.match(matcher).array)
            .map(matchResult => Tuple.of(matchResult.column.relevancy(term), matchResult))
            .sort((a, b) => {
                if (a.left < b.left) {
                    return 1;
                } else if (a.left > b.left) {
                    return -1;
                } else {
                    return 0;
                }
            })
            .map(matchResult => matchResult.right);
    }

    get hasExpandableFields(): boolean {
        return Array.from(this.parentFields).some(f => f.hasChildren);
    }

}