import {Enum} from "common/Enum";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {AssetType} from "metadata/AssetType";

/**
 * Path parser and builder specific to the query builder.
 */
export class QueryPath {

    constructor(
        public readonly fqn: FQN,
        private readonly subPathValues: Map<SubPathKey, string[]>
    ) { }

    static fromBase(fqn: FQN): QueryPath {
        return new QueryPath(fqn, new Map());
    }

    static fromParts(parts: string[]): QueryPath {
        return new QueryPath(
            new FQN(parts[0], parts[1], AssetType.ARCQL, parts[3]),
            QueryPath.subPathValues(parts)
        );
    }

    /**
     * Returns the base64 encoded facet string.
     */
    facets(): Optional<string> {
        return Optional.of(this.subPathValues.get(SubPathKey.FACETS)).map(v => v[0]);
    }

    selectedNodes(): Optional<string[]> {
        return Optional.of(this.subPathValues.get(SubPathKey.SELECTED_NODES));
    }

    /**
     * Return a new path with the given changes or additions.
     */
    with(key: SubPathKey, values: string[]): QueryPath {
        const newValues = new Map(this.subPathValues);
        newValues.set(key, values);
        return new QueryPath(this.fqn, newValues);
    }

    /**
     * Remove a key and its values.
     */
    without(key: SubPathKey): QueryPath {
        const newValues = new Map(this.subPathValues);
        newValues.delete(key);
        return new QueryPath(this.fqn, newValues);
    }

    /**
     * If the path contains any su\b path keys that require ignoring local storage.
     */
    skipLocal(): boolean {
        return Array.from(this.subPathValues.keys()).some(key => key.skipLocal);
    }

    /**
     * Construct the URL string.
     */
    toString(): string {
        const parts = this.fqn.parts;
        this.subPathValues.forEach((values, key) => {
            parts.push(key.name, ...values);
        });

        return '/' + parts.join('/');
    }

    private static subPathValues(pathParts: string[]): Map<SubPathKey, string[]> {
        const values = new Map<SubPathKey, string[]>();

        let curKey: SubPathKey = null;
        let curValues: string[] = [];
        pathParts.slice(4).forEach(part => {
            SubPathKey.possible<SubPathKey>(part)
                // new key
                .forEach(key => {
                    // record the current values and start collecting for the new key if there is an existing key
                    if (curKey != null) {
                        values.set(curKey, curValues);
                    }

                    curKey = key;
                    curValues = [];
                })
                // add value to the current key if there is one
                .orForEach(() => {
                    if (curKey != null) {
                        curValues.push(part);
                    }
                });
        });

        if (curKey != null) {
            values.set(curKey, curValues);
        }

        return values;
    }

}

export class SubPathKey extends Enum {

    static readonly SELECTED_NODES = new this('selectedNodes', false);
    static readonly FACETS = new this('facets', true);

    private constructor(
        name: string,
        // if values for the key is present, if we should clear the local storage of the query
        public readonly skipLocal: boolean
    ) {
        super(name);
    }

}
SubPathKey.finalize();