import {NodeStructuredDetail} from 'metadata/hypergraph/content/NodeStructuredDetail';
import {ColumnRef, QueryResult} from 'metadata/query/QueryResult';

/**
 * Detail referencing a single row in the data results.
 *
 * @author zuyezheng
 */
export class DataRowDetail implements NodeStructuredDetail {

    constructor(
        public readonly description: string,
        // any groupings as tuples of (grouping field, grouping value)
        public readonly groupings: FieldValue[],
        // non-grouped fields as a map of field name to value
        public readonly fields: FieldValue[]
    ) {
    }

    get embeddingContent(): string {
        return [
            '- **(',
            this.groupings.map(([f, v]) => `${f.projectedAs}: ${v}`).join(', '),
            ')**: ',
            this.description,
            ' *Values: (',
            this.fields.map(([f, v]) => `${f.projectedAs}: ${v}`).join(', '),
            ')*.'
        ].join('');
    }

    static fromRow(description: string, row: (string | number)[], result: QueryResult): DataRowDetail {
        // mismatch in row and query results, return just the description
        if (row.length !== result.columnRefs.length) {
            console.error(`Mismatch in row and query results, ${row.length} columns in the row vs ${result.columnRefs.length} in the result.`);
            return new DataRowDetail(description, [], []);
        }

        const groupings: FieldValue[] = [];
        const fields: FieldValue[] = [];
        result.columnRefs.forEach((ref, refI) => {
            if (ref.isGrouping) {
                groupings.push([ref, row[refI]]);
            } else {
                fields.push([ref, row[refI]]);
            }
        });

        return new DataRowDetail(description, groupings, fields);
    }

}

/**
 * Tuple of field to its value.
 */
export type FieldValue = [ColumnRef, (string | number)];