import {NodeStructuredDetail} from 'metadata/hypergraph/content/NodeStructuredDetail';

/**
 * A single labeled fact.
 *
 * @author zuyezheng
 */
export class FactDetail implements NodeStructuredDetail {

    constructor(
        public readonly label: string,
        public readonly fact: string,
        public readonly isList: boolean = false
    ) { }

    get embeddingContent(): string {
        return [
            this.isList ? '- ' : '',
            `**${this.label}**: ${this.fact}`,
            // if not a list need a trailing newline so next detail doesn't get clobbered to it
            this.isList ? '' : '\n'
        ].join('');
    }

}
