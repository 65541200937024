import {ArcQL} from "metadata/query/ArcQL";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {ArcQLOrderBy, Direction} from "metadata/query/ArcQLOrderBy";
import {DeleteOrderBy} from "metadata/query/changes/DeleteOrderBy";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Add an order by for the given field.
 *
 * @author zuyezheng
 */
export class AddOrderBy implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly field: string,
        public readonly direction: Direction,
        public readonly dataset: ArcDataset,
        public readonly ordinal?: number
    ) {}

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        return new Tuple(
            metadata.with({
                'orderBys': metadata.orderBys.with(new ArcQLOrderBy(this.field, this.direction), this.ordinal)
            }),
            new DeleteOrderBy(this.field, this.dataset)
        );
    }

    describe(): string {
        return `Added order by ${this.dataset.getLabel(this.field)} ${this.direction.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}