import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useRef, useState} from "react";
import {VisualizationProps} from "app/visualizations/ArcVisualization";
import styled from "@emotion/styled";
import {Optional} from "common/Optional";
import {ToggleData, ToggleOption} from "app/visualizations/data/ToggleData";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {VizSelection} from "engine/actor/VizSelection";
import {useResize} from "app/components/hooks/DomHooks";
import {ToggleS} from "app/components/input/ToggleS";
import {ValueFormatter} from "common/ValueFormatter";
import {Colors} from "app/components/StyleVariables";

/**
 * Toggle visualization.
 *
 * @author zuyezheng
 */
export const Toggle: FunctionComponent<VisualizationProps> = (props: VisualizationProps) => {

    const containerRef = useRef(null);
    const containerSize = useResize(containerRef);

    const [data, setData] = useState<Optional<ToggleData>>(Optional.none());
    const [validationMessage, setValidationMessage] = useState<Optional<string>>(Optional.none());
    const [selections, setSelections] = useState<VizSelection>(VizSelection.none());

    // process query response once
    useMemo(() => {
        ToggleData.from(props.queryResponse).match(
            data => {
                setData(Optional.some(data));
                setValidationMessage(Optional.none());
            },
            message => {
                setValidationMessage(Optional.some(message));
                setData(Optional.none());
            }
        );
    }, [props.queryResponse]);

    // link the selectable to let it manage selections if set
    useEffect(() => {
        if (props.selectable == null) {
            return;
        }

        props.selectable.linkSelectable(setSelections, null);
        return () => props.selectable.unlinkSelectable();
    }, [props.selectable]);

    const toggleGroup = (data: ToggleData, containerSize: [number, number]) => {
        const metric = (value: number) => {
            return <>
                {
                    props.config.emptyableString('prefix')
                        .map(v => <S.MetricDecoration className="prefix">{v}</S.MetricDecoration>)
                        .nullable
                }
                {
                    props.config.boolean('compact') ?
                        ValueFormatter.compactNumber(value) :
                        ValueFormatter.basicNumber(value)
                }
                {
                    props.config.emptyableString('suffix')
                        .map(v => <S.MetricDecoration className="suffix">{v}</S.MetricDecoration>)
                        .nullable
                }
            </>
        };

        const content = (option: ToggleOption) => {
            if (containerSize[1] < 40) {
                return <S.SingleLine>
                    {option.label}
                    {option.metric.map(m => <span> ({metric(m)})</span>).nullable}
                </S.SingleLine>
            } else {
                return <div>
                    <div>{option.label}</div>
                    {option.metric.map(m => <div>{metric(m)}</div>).nullable}
                </div>
            }
        };

        return <S.ToggleButtonGroup
            color="primary"
            size="small"
            fullWidth
            exclusive={false}
            onChange={onSelect}
        >
            {
                data.options.map(o =>
                    <ToggleS.ToggleButton
                        key={o.index}
                        selected={selections.has(o.groupings)}
                        value={o}
                    >
                        {content(o)}
                    </ToggleS.ToggleButton>
                )
            }
        </S.ToggleButtonGroup>
    };

    const onSelect = (_: React.MouseEvent, value: ToggleOption[]) => {
        props.selectable?.toggleDiscrete([value[0].groupings]);
    };

    return <S.ToggleContainer ref={containerRef}>
        {
            props.config.emptyableString('title')
                .map(title => <S.Title>{title}</S.Title>)
                .nullable
        }
        {validationMessage.map(m => <>{m}</>).nullable}
        {
            Optional.all([data, containerSize])
                .map((args: [ToggleData, [number, number]]) => toggleGroup(...args))
                .nullable
        }
    </S.ToggleContainer>;

};

export const S = {

    ToggleContainer: styled.div`
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;

        // scroll bar when widget is single height gets really hard to see, force what macos does with a hidden 
        // scrollbar
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    `,

    Title: styled.div`
        display: flex;
        white-space: nowrap;
        align-items: center;
        padding: 10px;
        font-weight: 500;
        color: ${Colors.textPrimary};
    `,

    SingleLine: styled.div`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,

    ToggleButtonGroup: styled(ToggleButtonGroup)`
        height: 100%;
    `,

    MetricDecoration: styled.span`
        font-weight: 500;
        
        &.prefix {
            padding-right: 2px;
        }
        
        &.suffix {
            padding-left: 2px;
        }
    `,

};
