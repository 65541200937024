import {Indexable} from "common/Indexable";
import {PageParams} from "metadata/PageParams";

export type SortDirection = 'asc' | 'desc';

export class AccountSearchParams implements Indexable, PageParams<AccountSearchParams> {

    constructor(
        // page size is required to support pagination
        public readonly size: number,
        public readonly query?: string,
        public readonly sort?: SortDirection,
        public readonly sortField?: string,
        // pages are 0 indexed
        public readonly page?: number
    ) {
    }

    static default(query? : string): AccountSearchParams {
        return new AccountSearchParams(3, query);
    }

    isEmpty() {
        return this.query == null || this.query.length === 0;
    }

    with(
        {
            size,
            page
        }: {
            size?: number
            page?: number
        }) {
        return new AccountSearchParams(
            size == null ? this.size : size,
            this.query,
            this.sort,
            this.sortField,
            page == null ? this.page : page
        );
    }
}