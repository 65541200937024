import {HierarchicalField} from "metadata/query/HierarchicalField";
import {JsonObject} from "common/CommonTypes";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {DateGrain} from "metadata/query/DateGrain";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";


export class DateGrouping extends ArcQLGrouping implements HierarchicalField<DateGrain> {

    constructor(
        field: string,
        public readonly grain: DateGrain
    ) {
        super(ArcQLGroupingType.DATE, field);
    }

    size(): number {
        return 1;
    }

    get projectedAs(): string {
        return `${this.field}_${this.grain.name}`;
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        return AnalyticsType.DATE;
    }

    withGrain(grain: DateGrain): HierarchicalField<DateGrain> {
        return new DateGrouping(this.field, grain);
    }

    get grains(): DateGrain[] {
        return DateGrain.enums();
    }

    fromName(name: string): DateGrain {
        return DateGrain.get(name);
    }

    toJSON(): JsonObject {
        return {
            'field': this.field,
            'grain': this.grain,
            'type': this.type
        };
    }

}
