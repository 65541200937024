import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {MarkdownConfigPart} from "metadata/dashboard/widgets/config/markdown/MarkdownConfigPart";
import {TextConfigPart} from "metadata/dashboard/widgets/config/text/TextConfigPart";
import {BaseWidgetConfig} from "metadata/dashboard/widgets/config/BaseWidgetConfig";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

/**
 * Complete Configuration for Markdown Widget such as its container and text configuration.
 */
export class MarkdownWidgetConfig extends BaseWidgetConfig {

    static readonly CONTAINER_CONFIG_KEY = 'container';
    static readonly TEXT_CONFIG_KEY = 'text';
    static readonly MARKDOWN_CONFIG_KEY = 'markdown';

    constructor(
        public readonly container: ContainerConfigPart,
        public readonly text: TextConfigPart,
        public readonly markdown: MarkdownConfigPart
    ) {
        super();
    }

    get parts(): Map<string, WidgetConfigPart> {
        return new Map<string, WidgetConfigPart>([
            [MarkdownWidgetConfig.CONTAINER_CONFIG_KEY, this.container],
            [MarkdownWidgetConfig.TEXT_CONFIG_KEY, this.text],
            [MarkdownWidgetConfig.MARKDOWN_CONFIG_KEY, this.markdown]
        ]);
    }

    static fromMap(configMap: Map<string, any>, dashboardConfig?: DashboardConfig): MarkdownWidgetConfig {
        let containerPart: ContainerConfigPart = ContainerConfigPart
            .fromJSON(configMap.get(MarkdownWidgetConfig.CONTAINER_CONFIG_KEY) || {});
        containerPart = dashboardConfig ? containerPart.withDefaults(dashboardConfig.container) : containerPart;
        return new MarkdownWidgetConfig(
            containerPart,
            TextConfigPart.fromJSON(configMap.get(MarkdownWidgetConfig.TEXT_CONFIG_KEY) || {}),
            MarkdownConfigPart.fromJSON(configMap.get(MarkdownWidgetConfig.MARKDOWN_CONFIG_KEY) || {})
        );
    }
}