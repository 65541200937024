import React, {ChangeEvent, FunctionComponent, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

type Props = {
    onCancel: () => void
    onConfirmSelection: () => void
}

export const ApplyMaskDialog: FunctionComponent<Props> = (props: Props) => {
    return <Dialog open={true}>
        <DialogTitle>Apply new Mask</DialogTitle>
        <DialogContent>This query will be reset upon switching Masks. Note that you’ll need to reapply any modifications you’ve made and want to keep. Don’t worry, you can undo this action from the toolbar if you change your mind.</DialogContent>
        <DialogActions>
            <Button
                onClick={props.onCancel}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                onClick={props.onConfirmSelection}
            >
                Reset query and apply new Mask
            </Button>
        </DialogActions>
    </Dialog>;
};