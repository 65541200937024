import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL, ArcQLProps} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {FilterChange} from "metadata/query/changes/FilterChange";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FilterClause} from "metadata/query/filterclause/FilterClause";

/**
 * Modify an existing filter clause.
 *
 * @author zuyezheng
 */
export class ModifyFilter extends FilterChange {

    constructor(
        public readonly ordinal: number,
        public readonly clause: FilterClause,
        public readonly isAggregate: boolean,
        public readonly dataset: ArcDataset
    ) {
        super(isAggregate);
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const filters = this.filters(metadata);

        const newMetadata: Partial<ArcQLProps> = {};
        newMetadata[filters.left] = filters.right.replace(this.ordinal, this.clause);

        return new Tuple(
            metadata.with(newMetadata),
            // adding always adds to the end of the clauses so we know the ordinal is the length of the previous
            new ModifyFilter(this.ordinal, filters.right.get(this.ordinal), this.isAggregate, this.dataset)
        );
    }

    describe(): string {
        const filterType = this.isAggregate ? 'aggregate filter' : 'filter';
        const fieldLabel = this.clause.fieldsLabel(this.dataset);

        return `Changed ${filterType} on ${fieldLabel} to ${this.clause.description(true)}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}