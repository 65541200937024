import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ArcQLOrderBy} from "metadata/query/ArcQLOrderBy";
import {DeleteGrouping} from "metadata/query/changes/DeleteGrouping";
import {Optional} from "common/Optional";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Add a grouping
 *
 * @author zuyezheng
 */
export class AddGrouping implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly grouping: ArcQLGrouping,
        public readonly dataset: ArcDataset,
        // if we should add it at a specific ordinal
        public readonly ordinal?: number,
        // if we should add an order by for the grouping at a given location
        public readonly orderBy: Optional<Tuple<ArcQLOrderBy, number>> = Optional.none()
    ) {
        this.orderBy.forEach(orderBy => {
            if (orderBy.left.field !== this.grouping.projectedAs) {
                throw new Error(`Order by field '${orderBy.left.field}' should be on the same as the grouping '${this.grouping.field}'.`);
            }
        });
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        return new Tuple(
            metadata.with({
                groupings: metadata.groupings.with(this.grouping, this.ordinal),
                orderBys: this.orderBy
                    .map(orderBy => metadata.orderBys.with(orderBy.left, orderBy.right))
                    .getOr(metadata.orderBys)
            }),
            new DeleteGrouping(this.grouping.field, this.dataset)
        );
    }

    describe(): string {
        return `Added new ${this.grouping.type.name} grouping on ${this.grouping.label(this.dataset)}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}