import * as React from "react"
import {SVGProps} from "react"

const AreaIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M5 5.5v14h14v-8.241l-3.875 3.875-3.058-3.06-3.907 3.908-1.604-1.604v-2.2l1.604 1.604 3.907-3.905 3.058 3.057L19 9.06V6.021L15.111 9.91l-2.836-2.836-3.972 3.974L6.556 9.3V5.5H5Z"
        />
    </svg>
);
export default AreaIcon;
