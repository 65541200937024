import * as React from "react";
import {FunctionComponent, useState} from "react";
import styled from "@emotion/styled";
import {CommonS} from "app/components/CommonS";
import {StoryCreate} from "app/components/detailpanel/StoryCreate";
import {Asset} from "metadata/Asset";
import {Optional} from "common/Optional";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {StoriesList} from "app/components/detailpanel/StoriesList";

export interface StoriesProps {
    className?: string
    // asset the story is for
    targetAsset: Asset
    viewportEl: HTMLElement
    // if recording, caption will be filled, otherwise empty
    onRecord: (caption: Optional<StoryScene>) => void
}

/**
 * Panel for displaying and creating stories for a given targeted asset.
 *
 * @author zuyezheng
 */
export const StoriesPanel: FunctionComponent<StoriesProps> = (props: StoriesProps) => {

    const [selectedTab, setSelectedTab] = useState<string>('Stories');

    const onTabChange = (tab: string) => {
        // stop any existing recordings
        props.onRecord(Optional.none());
        // change tabs
        setSelectedTab(tab);
    };

    const onStoryDone = () => {
        onTabChange('Stories');
    };

    return <S.Container className={props.className}>
        <CommonS.Tabs
            value={selectedTab}
            onChange={(_: React.MouseEvent<HTMLElement>, tab: string) => onTabChange(tab)}
            variant="fullWidth"
        >
            <CommonS.Tab
                value={'Stories'}
                label="Stories"
            />
            <CommonS.Tab
                value={'Create'}
                label="Create"
            />
        </CommonS.Tabs>
        {selectedTab === 'Stories' &&
            <StoriesList {...props}/>
        }
        {selectedTab === 'Create' &&
            <StoryCreate {...props} onDone={onStoryDone}/>
        }
    </S.Container>

};

const S = {

    Container: styled.div`
        overflow: hidden;
        display: flex;
        flex-direction: column;
    `

};