import React, {FunctionComponent} from "react";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";
import {FormControl, InputLabel} from "@mui/material";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import InputAdornment from "@mui/material/InputAdornment";
import {CollapsibleSection} from "app/components/CollapsibleSection";
import {DashboardConfigS} from "app/dashboard/components/DashboardConfigS";
import {DropShadow} from "metadata/dashboard/widgets/config/DropShadow";
import MenuItem from "@mui/material/MenuItem";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import {BorderConfigForm} from "app/dashboard/components/BorderConfigForm";
import {ColorPickerForm} from "app/dashboard/components/ColorPickerForm";
import {ColorResult} from "react-color";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";

interface Props {
    dashboard: ArcDashboard
    config: DashboardConfig
    onChange: (config: DashboardConfig) => void
}


export const DashboardConfigWidgetSection: FunctionComponent<Props> = (props: Props) => {

    const onBackgroundColorChange = (color: ColorResult) => {
        props.onChange(props.config.with({
            container: props.config.container.with({backgroundColor: color.hex})
        }));
    };

    const onContainerPartChange = (containerPart: ContainerConfigPart) => {
        props.onChange(props.config.with({container: containerPart}));
    };

    const onDropShadowChange = (levelStr: string) => {
        props.onChange(props.config.with(
            {
                container: props.config.container.with({dropShadow: DropShadow.get(levelStr)})
            })
        );
    };

    return (
        <CollapsibleSection title={"widget"}>
            <ColorPickerForm
                pickerId={"widget-background-color"}
                label={"Background Color"}
                currentColor={props.config.container.getValue('backgroundColor')}
                onChange={onBackgroundColorChange}
            />
            <BorderConfigForm
                config={props.config.container}
                onChange={onContainerPartChange}
            />
            <FormControl fullWidth size="small" margin="dense">
                <InputLabel htmlFor="drop-shadow">Drop Shadow</InputLabel>
                <DashboardConfigS.Select
                    id="drop-shadow"
                    variant="standard"
                    margin="dense"
                    value={props.config.container.getValue('dropShadow').name}
                    onChange={e => onDropShadowChange(e.target.value as string)}
                    startAdornment={
                        <InputAdornment position="start">
                            <LightModeOutlinedIcon/>
                        </InputAdornment>
                    }
                >
                    {DropShadow.enums<DropShadow>().map(shadow =>
                        <DashboardConfigS.MenuItem
                            key={shadow.name}
                            value={shadow.name}
                        >
                            {shadow.label}
                        </DashboardConfigS.MenuItem>
                    )}
                </DashboardConfigS.Select>
            </FormControl>
        </CollapsibleSection>
    );
};




