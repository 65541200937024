import {WidgetMetadata} from "metadata/dashboard/widgets/WidgetMetadata";
import {MarkdownWidgetConfig} from "metadata/dashboard/widgets/config/MarkdownWidgetConfig";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

export class MarkdownMetadata extends WidgetMetadata<MarkdownWidgetConfig, MarkdownMetadata> {

    mergeWidgetConfig(dashboardConfig?: DashboardConfig): MarkdownWidgetConfig {
        return MarkdownWidgetConfig.fromMap(this.config, dashboardConfig);
    }

}