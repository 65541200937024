import * as React from "react";
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';

import {Enum} from "common/Enum";
import {RGBA} from "common/RGBA";

export class AnalyticsType extends Enum {

    static DATE = new this(
        'date',
        'Dates',
        new RGBA(151, 200, 151),
        () => <AccessTimeOutlinedIcon />
    );
    static DIMENSION = new this(
        'dimension',
        'Dimensions',
        new RGBA(67, 151, 248),
        () => <AbcOutlinedIcon />
    );
    static MEASURE = new this(
        'measure',
        'Measures',
        new RGBA(191, 167, 222),
        () => <NumbersOutlinedIcon />
    );

    constructor(
        name: string,
        public readonly categoryLabel: string,
        public readonly color: RGBA,
        public readonly icon: () => React.ReactNode
    ) {
        super(name);
    }

    /**
     * RGBA string of the light version of the color.
     */
    light(): string {
        return this.color.withA(0.05).toString();
    }

    /**
     * RGBA string of the dark version of the color.
     */
    dark(): string {
        return this.color.withA(0.75).toString();
    }
}

AnalyticsType.finalize();