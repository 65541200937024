import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {AccountResult} from "metadata/account/AccountResult";
import {SearchResponse} from "metadata/search/SearchResponse";

export class AccountsSearchResponse implements SearchResponse<AccountResult> {

    static fromJSON(json: JsonObject): AccountsSearchResponse {
        return new AccountsSearchResponse(
            json['results'].map(AccountResult.fromJSON),
            json['total']
        );
    }

    constructor(
        public readonly results: AccountResult[],
        public readonly total: number
    ) {
    }

    get optional(): Optional<AccountsSearchResponse> {
        if (this.results.length === 0) {
            return Optional.none();
        } else {
            return Optional.some(this);
        }
    }

}

