import {BaseWidgetConfigPart} from "metadata/dashboard/widgets/config/BaseWidgetConfigPart";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";
import {JsonObject} from "common/CommonTypes";
import {HorizontalAlignment} from "metadata/dashboard/widgets/config/HorizontalAlignment";
import {ImageScale} from "metadata/dashboard/widgets/config/ImageScale";

export class ImageConfigPart extends BaseWidgetConfigPart<ImageConfigPart> {

    constructor(
        public readonly alignment: HorizontalAlignment,
        public readonly scale: ImageScale,
        public readonly source: string,
        defaults: ImageConfigPart
    ) {
        super(defaults);
    }

    protected get properties(): (keyof ImageConfigPart & string)[] {
        return ['alignment', 'scale', 'source'];
    }

    get type(): WidgetConfigPartType {
        return WidgetConfigPartType.IMAGE;
    }

    createInstance(props: Record<keyof ImageConfigPart, any>, defaults: ImageConfigPart): ImageConfigPart {
        return new ImageConfigPart(
            props.alignment,
            props.scale,
            props.source,
            defaults
        );
    }

    static fromJSON(configJson: JsonObject, defaults: ImageConfigPart = ImageConfigPart.defaults()): ImageConfigPart {
        return BaseWidgetConfigPart.fromJSONHelper(
            configJson,
            defaults,
            {
                'alignment': (value) => HorizontalAlignment.get(value),
                'scale': (value) => ImageScale.get(value)
            }
        );
    }

    static defaults(): ImageConfigPart {
        return new ImageConfigPart(
            HorizontalAlignment.CENTER_ALIGNED,
            ImageScale.SCALE,
            '/static/images/hyperarc.png',
            null
        );
    }
}