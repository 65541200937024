import React, {FunctionComponent, useState} from "react";
import {ColorResult, SketchPicker} from "react-color";
import {FormControl, InputLabel, Popover} from "@mui/material";
import {DashboardConfigS} from "app/dashboard/components/DashboardConfigS";
import InputAdornment from "@mui/material/InputAdornment";
import {PresetColors} from "app/components/StyleVariables";

interface Props {
    pickerId: string
    label: string
    currentColor: string
    onChange: (color: ColorResult) => void
}

export const ColorPickerForm: FunctionComponent<Props> = (props: Props) => {
    const [anchorColorPickerEl, setAnchorColorPickerEl] = useState<HTMLDivElement>(null);
    const handleColorPickerClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorColorPickerEl(event.currentTarget);
    };

    const handleColorPickerClose = () => {
        setAnchorColorPickerEl(null);
    };

    return <FormControl fullWidth size="small" margin="dense">
        <InputLabel htmlFor={props.pickerId}>{props.label}</InputLabel>
        <DashboardConfigS.Input
            id={props.pickerId}
            value={props.currentColor}
            startAdornment={
                <InputAdornment position="start">
                    <DashboardConfigS.ColorPreview
                        color={props.currentColor}
                    />
                </InputAdornment>
            }
            onClick={handleColorPickerClick}
        />
        <Popover
            open={Boolean(anchorColorPickerEl)}
            anchorEl={anchorColorPickerEl}
            onClose={handleColorPickerClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <SketchPicker
                color={props.currentColor}
                onChangeComplete={props.onChange}
                disableAlpha={true}
                presetColors={PresetColors}
            />
        </Popover>
    </FormControl>;
};