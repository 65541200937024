import React, {ReactNode} from 'react';

/**
 * In this case, this Processor will be able to support additional markdown features like colorizing text.
 */
export default class EnhancedMarkdownProcessor {

    /**
     * Function to recursively process the children of a React element, applying appropriate transformations.
     *
     * Recursion needs to be done because Markdown can be nested.
     * For example:
     *      Hello **from _italicized_ $(color=red){"HyperArc"}**!
     *      - renders as : Hello <strong>from <em>italicized</em> $(color=red){"HyperArc"}</strong>!
     */
    static children(children: ReactNode): ReactNode {
        return React.Children.map(children, child => {
            if (typeof child === 'string') {
                return EnhancedMarkdownProcessor.processText(child);
            }
            // If the child is a React element with children, process its children recursively
            if (React.isValidElement(child) && child.props.children) {
                return React.cloneElement(
                    child,
                    {
                        ...child.props,
                        children: EnhancedMarkdownProcessor.children(child.props.children)
                    }
                );
            }
            return child;
        });
    }

    /**
     * Function to process text, applying appropriate transformations such as colorizing text.
     */
    private static processText(text: string): ReactNode[] {
        return EnhancedMarkdownProcessor.colorizeText(text);
    }

    /**
     * Given string, colorize text if it contains curly braces with a color specification.
     * Supports both hex and named colors.
     * Ex: $(color=red){"This text is red"} || $(color=#FF0000){"This text is red"}
     */
    private static colorizeText(text: string): ReactNode[] {
        const colorOptionRegex = /\$\(color=([^)]+)\)\{"(.*?)"\}/g;
        const parts: ReactNode[] = [];
        let lastIndex = 0;

        while (true) {
            const match = colorOptionRegex.exec(text);
            if (!match) {
                break;
            }

            // Push the text before the match
            parts.push(text.slice(lastIndex, match.index));

            const color = match[1];
            const content = match[2];
            parts.push(<span style={{color: color}}>{content}</span>);

            // Update the last index to the end of the current match
            lastIndex = match.index + match[0].length;
        }

        // Push any remaining text after the last match
        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }
        return parts;
    }
}