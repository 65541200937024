import {ComponentType} from "react";
import {Enum} from "common/Enum";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";

/**
 * Enumerates the different types of alignment (left, center, right) in
 * a consistent vertical plane.
 */
export class HorizontalAlignment extends Enum implements StylingEnum {

    static readonly LEFT_ALIGNED = new this(
        'left_aligned',
        'Left Aligned',
        FormatAlignLeftIcon,
        {textAlign: 'left'}
    );

    static readonly CENTER_ALIGNED = new this(
        'center_aligned',
        'Center Aligned',
        FormatAlignCenterIcon,
        {textAlign: 'center'}
    );

    static readonly RIGHT_ALIGNED = new this(
        'right_aligned',
        'Right Aligned',
        FormatAlignRightIcon,
        {textAlign: 'right'}
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType,
        public readonly styling: { [prop: string]: string }
    ) {
        super(name);
    }
}

HorizontalAlignment.finalize();