import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import ToggleButton from "@mui/material/ToggleButton";

export class ToggleS {

    static ToggleButton = styled(ToggleButton)`
        padding: 4px 14px;
        background-color: ${Colors.toggleButtonGrey};

        &.MuiToggleButton-root {
            color: ${Colors.iconSecondary};
            font-size: ${FontSizes.xxSmall};
            letter-spacing: 0.2px;
            font-weight: bold;
            margin-left: 4px;
            margin-right: 5px;
            outline: 4px solid ${Colors.toggleButtonGrey};
            border: 1px solid ${Colors.toggleButtonGrey};
            border-radius: 0;

            &:hover {
                color: ${Colors.textSecondary};
                border: 1px solid ${Colors.toggleButtonGrey};
                background: ${Colors.borderGrey};
            }

            &:last-of-type {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &:first-of-type {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &.Mui-selected {
                background-color: white;
                color: ${Colors.textPrimary};
                font-weight: bold;
                margin-right: 5px;
                outline: 4px solid ${Colors.toggleButtonGrey};
                border: 1px solid ${Colors.iconTertiary};
                border-radius: 4px;
                transition: all 0.3s ease-in;

                &:not(:first-of-type), &:not(:last-of-type) {
                    box-shadow: 4px 0 0 4px ${Colors.toggleButtonGrey}, -4px 0 0 4px ${Colors.toggleButtonGrey};
                }

                &:first-of-type {
                    box-shadow: 4px 0 0 4px ${Colors.toggleButtonGrey};
                }

                &:last-of-type {
                    box-shadow: -4px 0 0 4px ${Colors.toggleButtonGrey};
                }

                &:hover {
                    background-color: white;
                }
            }
        }
    `

}