import {VizSelection} from "engine/actor/VizSelection";
import {CartesianChartConfig} from "app/visualizations/config/CartesianChartConfig";
import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";


export class ComboChartConfig extends CartesianChartConfig {

    get chartType(): string {
        const scroll = this.config.get('scroll').get();
        if (this.config.get('dualAxis').get()) {
            return scroll ? 'scrollcombidy2d' : 'mscombidy2d';
        } else {
            return scroll ? 'scrollcombi2d' : 'mscombi2d';
        }
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        return {
            type: this.chartType,
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: this.config.get('showLegend').getOr(true),

                        xAxisName: this.config.emptyableString('xAxisName')
                            .getOr(this.xAxisName(this.isHierarchical)),
                        pYAxisName: this.config.emptyableString('pYAxisName')
                            .getOr(this.response.arcql.fields.fields[0].as),
                        sYAxisName: this.config.emptyableString('sYAxisName')
                            .getOr(this.response.arcql.fields.fields[this.response.arcql.fields.fields.length - 1].as),

                        showValues: this.config.get('showValues').getOr(false),
                        rotateValues: this.config.get('rotateValues').get(),

                        numVisiblePlot: Math.round(size[0]/40),
                        labelDisplay: 'rotate',
                        useEllipsesWhenOverflow: '1',
                        slantLabels: true,
                        drawCrossLine: false
                    }
                ),
                categories: this.data.categories,
                dataset: this.data.dataset(
                    selections,
                    (column: string, index: number, isLastSeries: boolean) => ({
                        // last series will be a line
                        renderAs: isLastSeries ? 'line' : 'column',
                        // use the secondary axis for the last series if dual axis is enabled
                        parentYAxis: isLastSeries && this.config.get('dualAxis').get() ? 'S' : null,
                    })
                )
            }
        };
    }

}