import {ArcQL} from 'metadata/query/ArcQL';
import {ArcQLResponse} from 'metadata/query/ArcQLResponse';
import {Either, Right} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ArcDataset} from 'metadata/dataset/ArcDataset';
import {QueryService} from "services/QueryService";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {Optional} from "common/Optional";
import DistinctValuesArcQLRequest from "services/DistinctValuesArcQLRequest";
import RangeValuesArcQLRequest from "services/RangeValuesArcQLRequest";
import {QueryResult} from "metadata/query/QueryResult";

/**
 * Query service used for tests.
 *
 * @author zuyezheng
 */
export class MockedQueryService extends QueryService {

    private _defaultDataset: ArcDataset;

    /**
     * Set the default dataset for mocking empty responses.
     * @param dataset
     */
    setDefaultDataset(dataset: ArcDataset) {
        this._defaultDataset = dataset;
    }

    async query(
        arcql: ArcQL,
        signal?: AbortSignal,
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, ArcQLResponse>> {
        return Promise.resolve(new Right(
            new ArcQLResponse(
                arcql,
                new QueryResult(
                    {
                        'columns': [],
                        'rows': []
                    },
                    arcql,
                    this._defaultDataset
                ),
                [],
                this._defaultDataset
            )
        ));
    }

    querySaved(
        arcql: ArcQL,
        filters: Optional<ArcQLFilters>,
        signal?: AbortSignal,
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, ArcQLResponse>> {
        return this.query(
            filters.map(fs => arcql.with({filters: fs})).getOr(arcql),
            signal,
            cacheTimeout
        );
    }

    distinctValues(dataset: ArcDataset, request: DistinctValuesArcQLRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQLResponse>> {
        return Promise.resolve(undefined);
    }

    rangeValues(dataset: ArcDataset, request: RangeValuesArcQLRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQLResponse>> {
        return Promise.resolve(undefined);
    }

}