import React, {FunctionComponent} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import {AssetIcon} from "app/components/icons/asset/AssetIcon";
import ListItemText from "@mui/material/ListItemText";
import {AutoCompleteS} from "app/components/search/autocomplete/AutoCompleteS";
import {AutoCompleteItemProps} from "app/components/search/AutoCompleteItemProps";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";

/**
 * Autocomplete Item for Asset result.
 */
export const AssetAutoCompleteItem: FunctionComponent<AutoCompleteItemProps<AssetSearchResult>> = (props: AutoCompleteItemProps<AssetSearchResult>) => {
    return <AutoCompleteS.Item
        key={props.result.id}
        onClick={() => props.onClick(props.result)}
        selected={props.selected}
    >
        <ListItemIcon>
            <AssetIcon assetType={props.result.type}/>
        </ListItemIcon>
        < ListItemText
            primary={props.result.label}
            secondary={`in ${props.result.folderLabel} by @${props.result.createdBy}`
            }
        />
    </AutoCompleteS.Item>;
};
