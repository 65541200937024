import { AssetSearchResult } from "metadata/search/AssetSearchResult";
import { NavigableSearchResult } from "metadata/search/NavigableSearchResult";
import {QueryPath, SubPathKey} from "app/query/QueryPath";

/**
 * A global hypergraph search result that can optionally navigate to a query and show a specific node in the graph
 */
export class HyperGraphNodeSearchResult implements NavigableSearchResult {

    constructor(
        public readonly arcqlResult: AssetSearchResult,
        public readonly selectedNodeId: string | null
    ) { }

    get id(): string {
        return this.arcqlResult.id;
    }

    get path(): string {
        let queryBuilderPath = QueryPath.fromBase(this.arcqlResult.fqn);
        if (this.selectedNodeId !== null) {
            queryBuilderPath = queryBuilderPath.with(SubPathKey.SELECTED_NODES, [this.selectedNodeId]);
        }
        return queryBuilderPath.toString();
    }

}