import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {Optional} from "common/Optional";
import {TabPath} from "app/TabPath";
import {ImageService} from "services/ImageService";
import {ErrorCode} from "services/ApiResponse";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Asset} from "metadata/Asset";
import {Either} from "common/Either";
import {TabChange} from "app/Tab";

/**
 * Logic for actually saving.
 *
 * @author zuyezheng
 */
export class SaveHandler<T extends Asset> {

    constructor(
        private readonly asset: T,
        // update the current tab on successful save
        private readonly onTabChange: TabChange,
        // optional canvas object to take a screenshot with
        private readonly canvas: Optional<HTMLElement>
    ) {}

    save(fqn: FQN, label: string, description: string): Promise<Either<Optional<Map<string, string>>, T>> {
        return ServiceProvider.get(MetadataService)
            .saveAsset(fqn, this.asset.with({
                name: fqn.name,
                label: label,
                description: description
            }))
            .then(r => r.match(
                asset => {
                    // make an attempt to save a screenshot
                    this.canvas.map((el: HTMLDivElement) => {
                        ServiceProvider.get(ImageService).saveAssetScreenshot(el, asset.id, fqn);
                    });

                    // update the tab for FQN changes
                    this.onTabChange(label, TabPath.fromFqn(fqn));

                    ServiceProvider.get(NotificationsService).publish(
                        'save', NotificationSeverity.SUCCESS, `Saved ${label}!`
                    );

                    return asset as T;
                },
                error => {

                    if (error.errorCode === ErrorCode.INVALID_FIELDS) {
                        return error.fieldErrors();
                    }

                    ServiceProvider.get(NotificationsService).publish(
                        'save', NotificationSeverity.ERROR, `Save failed: ${error.prettyPrint()}`
                    );

                    return Optional.none();
                }
            ));
    }

}