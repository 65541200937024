import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {AddColumnToColumnGroup} from "metadata/dashboard/changes/AddColumnToColumnGroup";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {DashboardNoopChange} from "metadata/dashboard/changes/DashboardNoopChange";

/**
 * Delete a column from an existing interaction column group.
 *
 * @author zuyezheng
 */
export class DeleteColumnFromColumnGroup implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly label: string,
        public readonly dataset: ArcDataset,
        // this takes in column as a string to allow for deleting columns that may have been removed from the dataset
        public readonly column: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const columnGroup = metadata.interactions.getColumnGroup(this.label).get();

        return Tuple.of(
            metadata.with({
                interactions: metadata.interactions.withColumnGroup(
                    this.label,
                    columnGroup.withoutColumn(this.dataset.fqn, this.column).columns
                )
            }),
            this.dataset.getPossible(this.column)
                .map<ArcMetadataChange<ArcDashboard>>(c => new AddColumnToColumnGroup(this.label, this.dataset, c))
                .getOrElse(() => new DashboardNoopChange(this, ''))
        );
    }

    describe(): string {
        return `Deleted column ${this.column} from interaction column group ${this.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}