import {AnalyticsTyped} from "metadata/query/AnalyticsTyped";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import type {ArcQLFieldType} from "metadata/query/ArcQLFieldType";
import {FieldProjection} from "metadata/query/FieldProjection";

export type ArcQLFieldProps = {
    as?: string
}

/**
 * A detail or aggregate field in an ArcQL query that is distinct from a grouping field.
 *
 * @author zuyezheng
 */
export abstract class ArcQLField implements AnalyticsTyped, FieldProjection {

    public constructor(
        public readonly type: ArcQLFieldType,
        public readonly as: string
    ) {
    }

    abstract analyticsType(dataset: ArcDataset): AnalyticsType;

    /**
     * Info tooltip describing the field or null if none.
     */
    abstract get tooltip(): string | null;

    /**
     * A prefix for decorating the field or null if none.
     */
    abstract get prefix(): string | null;

    abstract with({as}: ArcQLFieldProps): ArcQLField;

    get projectedAs(): string {
        return this.as;
    }
}

/**
 * Field that uses a specific dataset column.
 *
 * @author zuyezheng
 */
export abstract class ColumnField extends ArcQLField {

    readonly field: string;

    get isStar(): boolean {
        return this.field === '*';
    }

}