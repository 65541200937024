import {Indexable} from "common/Indexable";
import {PageParams} from "metadata/PageParams";

export type SortDirection = 'asc' | 'desc';

export class FolderSearchParams implements Indexable, PageParams<FolderSearchParams> {

    /**
     * Default and simplified folder search with fixed page size.
     */
    static default(query? : string, owner? : string): FolderSearchParams {
        return new FolderSearchParams(5, query, null, null, owner);
    }

    constructor(
        // page size is required to support pagination
        public readonly size: number,
        public readonly query?: string,
        public readonly sort?: SortDirection,
        public readonly sortField?: string,
        public readonly owner?: string,
        public readonly createdBy?: string,
        // pages are 0 indexed
        public readonly page?: number
    ) {
    }

    isEmpty() {
        return this.query == null || this.query.length === 0;
    }

    with(
        {
            size,
            page
        }: {
            size?: number
            page?: number
        }) {
        return new FolderSearchParams(
            size == null ? this.size : size,
            this.query,
            this.sort,
            this.sortField,
            this.owner,
            this.createdBy,
            page == null ? this.page : page
        );
    }

}