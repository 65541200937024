import * as React from "react";
import {FunctionComponent} from "react";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import styled from "@emotion/styled";
import {Column} from "metadata/Column";
import {DatasetColumnsPicker} from "app/components/dataset/DatasetColumnsPicker";

type Props = {
    datasets: ArcDataset[]
    onClick: (dataset: ArcDataset, column: Column) => void
    onCancel: () => void
}

export const GlobalFilterFieldsPicker: FunctionComponent<Props> = (props: Props) => {

    return <Dialog
        open={true}
        onClose={props.onCancel}
        maxWidth="md"
    >
        <DialogTitle>Select a column for global filters.</DialogTitle>
        <S.DialogContent dividers>
            <DatasetColumnsPicker datasets={props.datasets} onClick={props.onClick} />
        </S.DialogContent>
    </Dialog>;

};

export const S = {

    DialogContent: styled(DialogContent)`
        max-height: 800px;
        width: 500px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `

};
