import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {Either} from "common/Either";
import {LocalStorageService} from "services/LocalStorageService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ErrorResponse} from "services/ApiResponse";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";

/**
 * Helps load and parse metadata for filter set builder.
 *
 * @author fli
 */
export class FilterSetLoader {

    /**
     * Figure out how to load or create a filter set for a given fqn.
     */
    static async load(assetFqn: FQN, signal?: AbortSignal): Promise<Optional<LoadResult>> {
        // see if we have a local version to restore from
        const localStorageService = ServiceProvider.get(LocalStorageService);
        const possibleLocal = await localStorageService.getAsset(assetFqn, ArcFilterSet.fromJSON);

        let filterSet;
        let isRestored;
        if (possibleLocal.isPresent) {
            // we have a local version of the asset, do some validation
            filterSet = possibleLocal.get();
            isRestored = true;

            if (filterSet.isExisting) {
                // restored a filterSet that has been saved, still fetch from the server so we can inform the user if
                // there's any new version
                const filterSetResponse = await ServiceProvider.get(MetadataService).fetchFilterSet(assetFqn, signal);

                // if it doesn't exist on the server, something sketch, clear the local copy
                if (filterSetResponse.isLeft) {
                    localStorageService.clearAsset(assetFqn);
                    ServiceProvider.get(NotificationsService)
                        .publish('filterSetBuilder', NotificationSeverity.ERROR, 'Restored filter set not found.');
                    return Optional.none();
                }

                // see if the most recent server version is different to warn the user
                if (filterSet.version >= filterSetResponse.rightOrThrow().version) {
                    ServiceProvider.get(NotificationsService)
                        .publish('filterSetBuilder', NotificationSeverity.SUCCESS, 'Restored filter set.');
                } else {
                    ServiceProvider.get(NotificationsService)
                        .publish(
                            'filterSetBuilder',
                            NotificationSeverity.WARNING,
                            'Restored filter set, but a newer version found, reload to use the newer version.'
                        );
                }
            } else {
                ServiceProvider.get(NotificationsService)
                    .publish('filterSetBuilder', NotificationSeverity.SUCCESS, 'Restored unsaved filter set.');
            }
        } else {
            const filterSetResponse = await FilterSetLoader.fetchOrCreate(assetFqn, false, signal);

            // no dashboard with that FQN, start a new one
            if (filterSetResponse.isNone) {
                ServiceProvider.get(NotificationsService)
                    .publish('filterSetBuilder', NotificationSeverity.ERROR, 'No access to filter set.');
                return Optional.none();
            }

            filterSet = filterSetResponse.get();
            isRestored = false;
        }
        return Optional.some(new LoadResult(filterSet, isRestored));
    }

    static async fetchOrCreate(
        assetFqn: FQN, strict: boolean, signal?: AbortSignal
    ): Promise<Optional<ArcFilterSet>> {
        const response = await ServiceProvider.get(MetadataService).fetchFilterSet(assetFqn, signal);

        return response.fold(
            r => Optional.some(r),
            (error) => {
                // if strict or no access, return a none
                if (strict || error.response.status === 403) {
                    return Optional.none();
                }

                // otherwise return a new dashboard
                return Optional.some(ArcFilterSet.minimal());
            }
        );
    }

    static async refresh(filterSet: ArcFilterSet): Promise<Either<ErrorResponse, ArcFilterSet>> {
        const response = await ServiceProvider.get(MetadataService).fetchFilterSet(filterSet.fqn);
        return response.match(
            (filterSet) => {
                ServiceProvider.get(LocalStorageService).clearAsset(filterSet.fqn);
                return filterSet;
            },
            (error) => {
                ServiceProvider.get(NotificationsService)
                    .publish('filterSetBuilder', NotificationSeverity.ERROR, 'Refresh error.');
                return error;
            }
        );
    }

}

export class LoadResult {

    constructor(
        public readonly filterSet: ArcFilterSet,
        public readonly isRestored: boolean
    ) {
    }

}