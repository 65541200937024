import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraph} from 'metadata/hypergraph/HyperGraph';
import {HyperGraphNodeType} from 'metadata/hypergraph/nodes/HyperGraphNodeType';
import {HyperGraphRequestSerialized} from 'metadata/hypergraph/HyperGraphTypes';

/**
 * Find data points of interest in a given query response.
 *
 * @author zuyezheng
 */
export class DataOfInterest implements HyperGraphRequest {

    constructor(
        public readonly parent: string,
        public readonly nTrends: number = 3,
        public readonly nDataPoints: number = 10
    ) { }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.DATA_OF_INTEREST;
    }

    get operation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.DISCOVERY;
    }

    label(graph: HyperGraph): string {
        return 'Finding interesting things in the data.';
    }

    get ommers(): string[] {
        return [this.parent];
    }

    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            parent: this.parent,
            nTrends: this.nTrends,
            nDataPoints: this.nDataPoints
        };
    }

    static fromJSON(json: HyperGraphRequestSerialized): DataOfInterest {
        return new DataOfInterest(json.parent, json.nTrends, json.nDataPoints);
    }

}
