import * as React from "react";
import {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {ImageWidgetConfig} from "metadata/dashboard/widgets/config/ImageWidgetConfig";

interface Props {
    config: ImageWidgetConfig
}

/**
 * Widget that supports inserting images.
 */
export const ImageWidget: FunctionComponent<Props> = (props: Props) => {

    return <S.Container
        alignmentStyling={props.config.image.getValue('alignment').styling}
        imageScaleStyling={props.config.image.getValue('scale').styling}
    >
        <img
            src={props.config.image.getValue('source')}
        />
    </S.Container>;

};

const S = {

    Container: styled.div<{
        alignmentStyling: { [prop: string]: string };
        imageScaleStyling: { [prop: string]: string };

    }>`
        ${props => props.alignmentStyling && css(props.alignmentStyling)}

        padding: 0 10px;
        height: 100%;
        overflow: hidden;

        img {
            ${props => props.imageScaleStyling && css(props.imageScaleStyling)}
        }
    `,
};