import {JsonObject} from "common/CommonTypes";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {GlobalLiteralsFilterClause} from "metadata/dashboard/GlobalLiteralsFilterClause";
import {GlobalFilterSetFilterClause} from "metadata/dashboard/GlobalFilterSetFilterClause";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {ArcQLFilterValue, FilterClause, FilterSetValue, RawFilterValue} from "metadata/query/filterclause/FilterClause";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {References} from "metadata/References";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {GlobalArcQLFilterClause} from "metadata/dashboard/GlobalArcQLFilterClause";
import ArcQLFilterClause from "metadata/query/filterclause/ArcQLFilterClause";
import {FilterSetFilterClause} from "metadata/query/filterclause/FilterSetFilterClause";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {Optional} from "common/Optional";

export class GlobalFilterClauseFactory {

    /**
     * Given a JSON, appropriate deserialize to the right GlobalFilter instance.
     */
    static fromJSON(json: JsonObject, references: References): GlobalFilterClause {
        const type: FilterClauseType = FilterClauseType.get(json['type']);
        switch (type) {
            case FilterClauseType.SINGLE:
                return GlobalLiteralsFilterClause.fromJSON(json);
            case FilterClauseType.FILTER_SET:
                return GlobalFilterSetFilterClause.fromJSON(json, references);
            case FilterClauseType.ARCQL:
                return GlobalArcQLFilterClause.fromJSON(json, references);
            default:
                throw new Error(`Unsupported global filter clause type: ${type}`);
        }
    }

    /**
     * Given a FilterClause and which datasetFQN, create the new GlobalFilterClause instance.
     */
    static fromFilterClause(filterClause: FilterClause, datasetFqn: string): Optional<GlobalFilterClause> {
        switch (filterClause.type) {
            case FilterClauseType.SINGLE:
                return Optional.of(
                    new GlobalLiteralsFilterClause(
                        datasetFqn,
                        (filterClause as LiteralsFilterClause).column,
                        (filterClause as LiteralsFilterClause).operator,
                        filterClause.values as RawFilterValue[]
                    )
                );
            case FilterClauseType.FILTER_SET:
                return Optional.of(
                    new GlobalFilterSetFilterClause(
                        datasetFqn,
                        filterClause as FilterSetFilterClause
                    )
                );
            case FilterClauseType.ARCQL:
                return Optional.of(
                    new GlobalArcQLFilterClause(
                        datasetFqn,
                        filterClause as ArcQLFilterClause
                    )
                );
            case FilterClauseType.COMPOSITE:
                // silently ignore composite filters
                return Optional.none();
            default:
                throw new Error(`Unsupported filter clause type: ${filterClause.type}`);
        }
    }

    /**
     * Given an existing FilterClause and a pending editor FilterChange, create the new GlobalFilterClause instance.
     */
    static fromFilterChange(existingClause: GlobalFilterClause, change: EditorFilterChange): GlobalFilterClause {
        // default case of Filter by all : users never populated or selected values correctly
        if (change.values.length === 0 || (change.values.length === 1 && change.values[0] === null)) {
            return new GlobalLiteralsFilterClause(
                existingClause.datasetFqn.toString(),
                existingClause.column,
                FilterOperator.IN,
                []
            );
        }

        switch (change.type) {
            case FilterClauseType.SINGLE:
                return new GlobalLiteralsFilterClause(
                    existingClause.datasetFqn.toString(),
                    existingClause.column,
                    change.operator,
                    change.values as RawFilterValue[]
                );
            case FilterClauseType.FILTER_SET:
                return new GlobalFilterSetFilterClause(
                    existingClause.datasetFqn.toString(),
                    new FilterSetFilterClause(
                        existingClause.column,
                        (change.values[0] as FilterSetValue).fullyQualifiedName,
                        change.operator === FilterOperator.IN,
                        change.values[0] as FilterSetValue
                    )
                );
            case FilterClauseType.ARCQL:
                return new GlobalArcQLFilterClause(
                    existingClause.datasetFqn.toString(),
                    ArcQLFilterClause.fromArcQLFilter(
                        existingClause.column,
                        change.values[0] as ArcQLFilterValue,
                        change.operator
                    )
                );
            default:
                throw new Error(`Unsupported filter clause type: ${change.type}`);
        }
    }
}
