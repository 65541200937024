import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {DashboardQuery} from "metadata/dashboard/DashboardQueries";
import {DeleteQuery} from "metadata/dashboard/changes/DeleteQuery";

/**
 * Create a query reference on the dashboard.
 *
 * @author zuyezheng
 */
export class CreateQuery implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly query: DashboardQuery
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(
            metadata.with({
                queries: metadata.queries.with(this.query.id, this.query)
            }),
            new DeleteQuery(this.query.id)
        );
    }

    describe(): string {
        return `Created query ${this.query.id}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}