import React, {FunctionComponent} from "react";
import {ListS} from "app/query/components/ListS";

export type Props = {
    isSelected: boolean;
    label: string;
    onClick: () => void
};


/**
 * FieldSettingItem corresponds to the item component when displaying some settings for a field.
 */
export const FieldSettingItem: FunctionComponent<Props> = (props: Props) => {

    return <ListS.ItemContainer
        onClick={props.onClick}
    >
        <ListS.StartIcon>
            {
                props.isSelected ? <ListS.ThemedCheckedRadio/> : <ListS.OpenRadio/>
            }
        </ListS.StartIcon>
        <ListS.ItemText primary={props.label}/>
    </ListS.ItemContainer>;
};