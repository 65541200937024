import {Enum} from "common/Enum";

export class UserSubscriptionType extends Enum {
    static readonly ALERT = new UserSubscriptionType("alert");

    private constructor(value: string) {
        super(value);
    }
}

UserSubscriptionType.finalize();