import {JsonObject} from "common/CommonTypes";
import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetConfig} from "metadata/dataset/DatasetConfig";
import {SnowflakeDatasetConfig, SnowflakeDatasetConfigJson} from "metadata/dataset/snowflake/SnowflakeDatasetConfig";
import {BigQueryDatasetConfig, BigQueryDatasetConfigJson} from "metadata/dataset/bigquery/BigQueryDatasetConfig";
import {PersonalDatasetConfig, PersonalDatasetConfigJson} from "metadata/dataset/personal/PersonalDatasetConfig";

/**
 * Factory for generating Dataset configs.
 */
export class DatasetConfigFactory {
    /**
     * Constructs a DatasetConfig from a JSON object.
     */
    static fromJSON(type: DatasetType, json: JsonObject): DatasetConfig {
        switch (type) {
            case DatasetType.PERSONAL:
                return PersonalDatasetConfig.fromJSON(json as PersonalDatasetConfigJson);
            case DatasetType.SNOWFLAKE:
                return SnowflakeDatasetConfig.fromJSON(json as SnowflakeDatasetConfigJson);
            case DatasetType.BIG_QUERY:
                return BigQueryDatasetConfig.fromJSON(json as BigQueryDatasetConfigJson);
            default:
                throw new Error(`Unsupported config type for dataset: ${type}`);
        }
    }

}