import {Optional} from "common/Optional";
import {HyperArcUser} from "metadata/HyperArcUser";
import React from "react";
import {OrgMembership} from "metadata/OrgMembership";

export type UserContextContent = {
    user: Optional<HyperArcUser>,
    memberships: OrgMembership[],
    isSuperUser: boolean,
}
export const UserContext = React.createContext<UserContextContent>(
    {
        user: Optional.none<HyperArcUser>(),
        memberships: [],
        isSuperUser: false
    }
);