import {RemoteTable, RemoteTableJson} from "metadata/connections/RemoteItem";
import {DataType} from "metadata/DataType";
import {AnalyticsType} from "metadata/AnalyticsType";

export type ColumnMetadataJson = {
    columnName: string,
    dataType: string,
    columnType: string,
    isValid: boolean
}

export class ColumnMetadata {
    constructor(
        public readonly name: string,
        public readonly type: DataType,
        public readonly columnType: AnalyticsType,
        public readonly isValid: boolean
    ) {
    }

    static fromJSON(json: ColumnMetadataJson): ColumnMetadata {
        return new ColumnMetadata(
            json.columnName,
            DataType.get(json.dataType),
            AnalyticsType.get(json.columnType),
            json.isValid
        );
    }
}

export type RemoteTableMetadataJson = {
    table: RemoteTableJson,
    columns: ColumnMetadataJson[],
    rowCount: number

}

/**
 * The table and column definitions for a table from a remote data source.
 */
export class RemoteTableMetadata {
    constructor(
        public readonly table: RemoteTable,
        public readonly columns: ColumnMetadata[],
        public readonly rowCount: number
    ) {
    }

    static fromJSON(json: RemoteTableMetadataJson): RemoteTableMetadata {
        return new RemoteTableMetadata(
            RemoteTable.fromJSON(json.table),
            json.columns.map((columnJson: ColumnMetadataJson) => ColumnMetadata.fromJSON(columnJson)),
            json.rowCount
        );
    }
}