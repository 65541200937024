import {Enum} from "common/Enum";

/**
 * Identifies the type of WidgetConfigPart.
 * Useful to avoid instanceof checks and apply a quick map reference.
 */
export class WidgetConfigPartType extends Enum {

    static readonly CONTAINER = new this('container');
    static readonly TEXT = new this('text');
    static readonly MARKDOWN = new this('markdown');
    static readonly LINK = new this('link');
    static readonly CHART = new this('chart');
    static readonly IMAGE = new this('image');

    constructor(name: string) {
        super(name);
    }
}

WidgetConfigPartType.finalize();