import {Enum} from "common/Enum";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";

/**
 * Faceting options for a query. Note: option denotes toggleable.
 */
export class FacetingOption extends Enum implements ExplainedSetting {
    static EMIT = new this(
        'emit',
        'Emit',
        'Selections made on this chart will facet (cross-filter) other queries.'
    );
    static RECEIVE = new this(
        'receive',
        'Receive',
        'Selections made on other charts will facet (cross-filter) this query.'
    );

    private constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly explanation: string
    ) {
        super(name);
    }
}

FacetingOption.finalize();
