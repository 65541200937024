import * as React from "react";
import {ChangeEvent, FunctionComponent, useState} from "react";
import {DialogS} from "app/DialogS";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {EnterDetailProps} from "app/components/settings/connections/ConnectionStepperHandler";
import {BigQueryAuthentication} from "app/components/settings/connections/bigquery/BigQueryAuthentication";
import {Input, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {BigQueryInputConnectionDetails} from "metadata/connections/bigquery/BigQueryInputConnectionDetails";

/**
 * Step for entering details of a BigQuery connection.
 */
export const EnterBigQueryConnection: FunctionComponent<EnterDetailProps> = (props: EnterDetailProps) => {
    const [authenticationMethod, setAuthenticationMethod] = useState<BigQueryAuthentication>(BigQueryAuthentication.SERVICE_ACCOUNT);

    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const text = await file.text();
            const json = JSON.parse(text);
            const details = BigQueryInputConnectionDetails.fromJSON(json);
            props.onDetailChange(details);
        }
    };

    return (
        <div>
            <DialogS.SelectorRow>
                <FormControl>
                    <FormLabel id="authmethod-label" sx={{mb: 0.5}}>Authentication Method</FormLabel>
                    <Select
                        labelId="authmethod-label"
                        id="authmethod-select"
                        value={authenticationMethod}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setAuthenticationMethod(
                            BigQueryAuthentication.get<BigQueryAuthentication>(e.target.value)
                        )}
                        size="small"
                    >
                        {
                            BigQueryAuthentication.enums<BigQueryAuthentication>().map(a =>
                                <MenuItem
                                    key={a.name} value={a.name}>{a.label}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </DialogS.SelectorRow>
            <S.Description>
                {"To connect to BigQuery, you must provide a service account credentials file. The steps:"}
                <ol>
                    <li>
                        Create a Service Account affiliated with a project if not done already.
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={"https://cloud.google.com/iam/docs/service-accounts-create#iam-service-accounts-create-console"}
                        >
                            &nbsp;(Create)
                        </a>
                    </li>
                    <li>
                        Generate a key for the Service Account.
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={"https://cloud.google.com/iam/docs/creating-managing-service-account-keys"}
                        >
                            &nbsp;(Generate Key)
                        </a>
                    </li>
                </ol>
            </S.Description>
            <DialogS.InputRow>
                <FormControl fullWidth>
                    <Input
                        type="file"
                        inputProps={{accept: ".json"}}
                        onChange={handleFileUpload}
                        sx={{mb: 1}}
                    />
                    <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                        Please upload the JSON file containing your BigQuery connection details.
                    </Typography>
                </FormControl>
            </DialogS.InputRow>
        </div>
    );
};

class S {

    static readonly Description = styled(DialogS.Description)`
        margin-bottom: 16px;
    `;


}
