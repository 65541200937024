import {ArcTrendListItem} from "metadata/trend/ArcTrendListItem";
import {Optional} from "common/Optional";

/**
 * Helper class to better encapsulate logic for trend collections.
 */
export class WidgetTrendsMap {

    private _widgetIdToTrends = new Map<string, ArcTrendListItem[]>();

    constructor(
        public readonly trends: ArcTrendListItem[]
    ) {
        this._widgetIdToTrends = trends.reduce(
            (acc, trend) => {
                const existing = acc.get(trend.widgetId);
                if (existing) {
                    existing.push(trend);
                } else {
                    acc.set(trend.widgetId, [trend]);
                }
                return acc;
            },
            new Map<string, ArcTrendListItem[]>()
        );
    }

    /**
     * Has trends for widget.
     */
    hasTrendsForWidget(widgetId: string): boolean {
        return this._widgetIdToTrends.has(widgetId);
    };

    /**
     * Get all trends for a widget.
     */
    forWidget(widgetId: string): ArcTrendListItem[] {
        return this._widgetIdToTrends.get(widgetId) ?? [];
    }

    /**
     * Get first trend for a widget (if any).
     */
    firstForWidget(widgetId: string): Optional<ArcTrendListItem> {
        const trends = this.forWidget(widgetId);
        if (trends.length === 0) {
            return Optional.none();
        }
        return Optional.of(trends[0]);
    }

    /**
     * See if widget has any alerts.
     */
    hasAlertsForWidget(widgetId: string): boolean {
        return this.forWidget(widgetId).some(trend => trend.alertsCount > 0);
    }

    /**
     * Get all widgets with trends.
     */
    get widgets(): string[] {
        return Array.from(this._widgetIdToTrends.keys());
    }

    /**
     * Number of widgets with trends.
     */
    get widgetCount(): number {
        return this._widgetIdToTrends.size;
    }
}