import {ConnectionType} from "metadata/connections/ConnectionType";
import {ConnectionStepperHandler} from "app/components/settings/connections/ConnectionStepperHandler";
import {
    SnowflakeConnectionStepperHandler
} from "app/components/settings/connections/snowflake/SnowflakeConnectionStepperHandler";
import {
    BigQueryConnectionStepperHandler
} from "app/components/settings/connections/bigquery/BigQueryConnectionStepperHandler";

/**
 * Factory for creating connection handlers.
 */
export class ConnectionStepperHandlerFactory {

    static forType(type: ConnectionType): ConnectionStepperHandler {
        switch (type) {
            case ConnectionType.SNOWFLAKE:
                return new SnowflakeConnectionStepperHandler();
            case ConnectionType.BIG_QUERY:
                return new BigQueryConnectionStepperHandler();
            default:
                throw new Error(`No handler for connection type ${type}`);
        }
    }
}