import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    setting: ExplainedSetting;
    isSelected: boolean;
    isLastItem: boolean;
    onSettingClick: (s: ExplainedSetting) => void;
}

/**
 * A menu item that displays an explanation per choice selection.
 */
export const ExplainedMenuItem: FunctionComponent<Props> = (props) => {
    return <S.MenuItem
        key={props.setting.name}
        value={props.setting.label}
        onClick={() => {
            props.onSettingClick(props.setting);
        }}
        selected={props.isSelected}
        divider={!props.isLastItem}
    >
        <S.ListItemIcon>
            {props.setting.icon && <props.setting.icon/>}
        </S.ListItemIcon>
        <ListItemText
            primary={props.setting.label}
            secondary={props.setting.explanation}
        />
    </S.MenuItem>;
};

class S {

    static MenuItem = styled(MenuItem)`
        width: 300px;
        white-space: normal;
        padding-left: 20px;
    `;

    static ListItemIcon = styled(ListItemIcon)`
        padding-right: 10px;
    `;
}