import { Enum } from "common/Enum";

/**
 * Enumerate all of our out of the box themes.
 *
 * @author zuyezheng
 */
export class VizTheme extends Enum {

    static HYPERARC = new this(
        'hyperarc',
        'HyperArc',
        ['#8FCEC1', '#FCBD70', '#FF8588', '#4CB0D3', '#B08769', '#805C5C', '#E6A06A', '#67C1C9', '#FFB2B2', '#999371'],
        ['#d2ebe6', '#2a6056'],
        ['#ff8588', '#fcbd70', '#8fcec1']
    );
    static SUNSET = new this(
        'sunset',
        'Sunset',
        ['#6a0584', '#cf4794', '#fb9162', '#f9ca8a', '#ee5d6c', '#f08eb3', '#9d268c', '#f57767', '#eeb161', '#8f6fbf'],
        ['#AC32D8', '#FFED23'],
        ['#f0f921', '#e74867', '#2c0594']
    );
    static BUMBLEBEE = new this(
        'bumblebee',
        'Bumblebee',
        ['#F8BF00', '#131515', '#A8A8A8', '#EB7A12', '#F3E900', '#F8756E', '#CFC6AD', '#82560D', '#FAFF00', '#F5A28F'],
        ['#F9F6EC', '#F8BF00'],
        ['#F8BF00', '#F9F6EC', '#131515']
    );
    static AQUA = new this(
        'aqua',
        'Aqua',
        ['#00b4d8', '#0077b6', '#70ffc4', '#023e8a', '#55afa3', '#98e1f0', '#9ad37b', '#6ce9f5', '#47bec2', '#caf0f8'],
        ['#025E85', '#E0FFFF'],
        ['#023e8a', '#9affff', '#2c8184']
    );
    static LEDGER = new this(
        'ledger',
        'Ledger',
        ['#00bb74', '#146524', '#cce4c3', '#f6e663', '#f65206', '#7eced1', '#018a7c', '#78a841', '#ff8a4b', '#10a736'],
        ['#e2fff0', '#007447'],
        ['#f65206', '#fff6c6', '#10a736']
    );
    static MONO = new this(
        'mono',
        'Mono',
        ['#222222', '#444444', '#666666', '#888888', '#AAAAAA', '#BBBBBB', '#CCCCCC', '#DDDDDD'],
        ['#DDDDDD', '#222222'],
        ['#DDDDDD', '#999999', '#222222']
    );
    static OLD_SCHOOL = new this(
        'old_school',
        'Old School',
        ['#4d559a', '#e36145', '#fddeae', '#88aad8', '#cc1a2d', '#e39824', '#182983', '#3173bd', '#ca8b58', '#957038'],
        ['#fcedd1', '#f15937'],
        ['#e36145', '#eeeeee', '#4d559a']
    );
    static UNICORNS = new this(
        'unicorns',
        'Unicorns',
        ['#ff007a', '#0f35ff', '#f9c5db', '#e96f92', '#63d0ef', '#489cf8', '#ff33ad', '#f14cff', '#8533a3', '#d237b8'],
        ['#ffe5f2', '#80003e'],
        ['#ff007a', '#d500ba', '#0f35ff']
    );
    static CLARITY = new this(
        'clarity',
        'Clarity',
        ['#27F19A', '#8C96F8', '#4B4B4B', '#FFF067', '#F55CCE', '#27DAF1', '#8CF8C9', '#C25CF5', '#F12738', '#F5B45C'],
        ['#27F19A', '#4B4B4B'],
        ['#27F19A', '#4B4B4B', '#8C96F8']
    );

    static SKY_DIVE = new this(
        'skyDive',
        'Sky Dive',
        ['#FD16CD', '#FCD2F3', '#7BFBFD', '#ABD7ED', '#FFE280', '#F45DEC', '#C8CAFF', '#40D2FF', '#CCF6FF', '#326FB3'],
        ['#FCD2F3', '#FD16CD'],
        ['#FD16CD', '#C8CAFF', '#7BFBFD']
    );
    
    constructor(
        name: string,
        public readonly label: string,
        public readonly palette: string[],
        public readonly twoPoint: [string, string],
        public readonly threePoint: [string, string, string]
    ) {
        super(name);

        this.label = label;
        this.palette = palette;
    }

    /**
     * Generate the config string used in fusion charts.
     */
    toConfig(): string {
        return this.palette.map(c => c.substring(1)).join(',');
    }

}
VizTheme.finalize();