import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {DeleteWidget} from "metadata/dashboard/changes/DeleteWidget";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";

export class CreateWidget implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly widgetId: string,
        public readonly widgetMetadata: WidgetMetadataBound
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(
            metadata.with({
                widgets: metadata.widgets.with(this.widgetId, this.widgetMetadata)
            }),
            new DeleteWidget(this.widgetId)
        );
    }

    describe(): string {
        return `Created ${this.widgetMetadata.type.name} widget ${this.widgetId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}