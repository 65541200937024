import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import React, {FunctionComponent} from "react";
import BrowseViewProps from "app/home/browse/BrowseViewProps";
import {S} from "app/home/browse/BrowseViewS";
import {ScopedSearchProject} from "app/home/ScopedSearchProject";

export const BrowseProjectView: FunctionComponent<
    BrowseViewProps & { scopedSearch: ScopedSearchProject }
> = (props) => {
    // safeguard if incorrect view is set and scopedSearch != project
    return props.scopedSearch.project.map(project =>
        <>
            <S.ScopedHeader.Header>
                <S.ScopedHeader.OwnerWithBreadcrumb
                    onClick={() => props.onAccountBreadcrumb(project.ownerName)}
                >
                    @{project.ownerName}
                </S.ScopedHeader.OwnerWithBreadcrumb>
                <S.ScopedHeader.Delimiter> / </S.ScopedHeader.Delimiter>
                <span>{project.label}</span>
                {!project.isPublic && <S.ScopedHeader.PrivateChip
                    label={"Private"}
                    variant="outlined"
                    size="small"
                />}
            </S.ScopedHeader.Header>
            <AssetSearchResultsTable
                searchParams={props.assetSearchParams}
                onSelect={props.onAssetSelectSearchResult}
                rowType={SearchResultsRowType.TABLE}
                onSearch={props.onSearchAssets}
                disableProjectInfo={true}
            />
        </>
    ).getOr(<></>);
};