import {Enum} from "common/Enum";
import {DragAndDropData} from "common/DragAndDropData";


export class DashboardDnDSource extends Enum {

    static PALETTE = new this('palette');
    static QUERIES = new this('queries');

    of(name: string): DragAndDropData<DashboardDnDSource> {
        return new DragAndDropData(this, name);
    }

}
DashboardDnDSource.finalize();