import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetStatusMetadata} from "metadata/dataset/DatasetStatusMetadata";
import {TrackedRange} from "metadata/dataset/ArcDataset";
import {Chain} from "metadata/Chain";
import {JsonUtils} from "metadata/JsonUtils";
import {JsonObject} from "common/CommonTypes";
import {DatasetStatusDisplay} from "metadata/dataset/DatasetStatusDisplay";


type ArcDatasetStatusMetadataJson = {
    versionStatus: string;
    chains: string[];
    isStreamable: boolean;
    trackedRanges: JsonObject;
    trackedPrimaryTimestamps: JsonObject;
}

type TimestampRange = { 'min': Date, 'max': Date };

/**
 * Metadata for an ArcDataset status.
 */
export class ArcDatasetStatusMetadata implements DatasetStatusMetadata<Chain> {

    static fromJSON(json: ArcDatasetStatusMetadataJson): ArcDatasetStatusMetadata {
        const chainToTrackedRanges = JsonUtils.toMap<TrackedRange[]>(
            json.trackedRanges,
            (columnToMinMaxMap) =>
                Object.entries<JsonObject>(columnToMinMaxMap).map(([columnName, minMaxMap]) => {
                        return new TrackedRange(
                            columnName,
                            minMaxMap['min'],
                            minMaxMap['max']);
                    }
                ));

        const timestampsByChain = JsonUtils.toMap<TimestampRange>(
            json.trackedPrimaryTimestamps,
            (minMaxMap) => {
                return {'min': new Date(minMaxMap['min']), 'max': new Date(minMaxMap['max'])};
            }
        );
        const chains = new Set(json.chains);
        return new ArcDatasetStatusMetadata(
            json.versionStatus,
            Chain.enums<Chain>().filter(c => chains.has(c.name)),
            json.isStreamable,
            chainToTrackedRanges,
            timestampsByChain
        );
    }

    constructor(
        public readonly versionStatus: string,
        public readonly chains: Chain[],
        public readonly isStreamable: boolean,
        public readonly chainToTrackedRanges: Map<string, TrackedRange[]>,
        public readonly timestampsByChain: Map<string, TimestampRange>
    ) {

    }

    get sources(): Chain[] {
        return this.chains;
    }


    get type(): DatasetType {
        return DatasetType.ARC;
    }

    get statusDisplay() {
        return this.isStreamable ? DatasetStatusDisplay.STREAMING : DatasetStatusDisplay.SCHEDULED;
    }
}