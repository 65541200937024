import {ComponentType} from "react";
import {Enum} from "common/Enum";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";

/**
 * Enumerates the different types of vertical alignment (top, center, bottom).
 */
export class VerticalAlignment extends Enum implements StylingEnum {

    static readonly TOP_ALIGNED = new this(
        'top_aligned',
        'Top Aligned',
        VerticalAlignTopIcon,
        {display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}
    );

    static readonly CENTER_ALIGNED = new this(
        'vertical_center_aligned',
        'Center Aligned',
        VerticalAlignCenterIcon,
        {display: 'flex', flexDirection: 'column', justifyContent: 'center'}
    );

    static readonly BOTTOM_ALIGNED = new this(
        'bottom_aligned',
        'Bottom Aligned',
        VerticalAlignBottomIcon,
        {display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType,
        public readonly styling: { [prop: string]: string }
    ) {
        super(name);
    }
}

VerticalAlignment.finalize();