export interface Indexable {
    [key: string]: any;
}

/**
 * Convert an indexable object to a list of key-value pairs that can be passed as query params to an ajax request.
 */
export function toParams(obj: Indexable): string[][] {
    const params: string[][] = [];

    Object.keys(obj).forEach((k) => {
        const v = obj[k];
        if (v != null) {
            if (Array.isArray(v)) {
                v.forEach((val) => params.push([k, val.toString()]));
            } else {
                params.push([k, v.toString()]);
            }
        }
    });

    return params;
}