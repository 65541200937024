import * as React from "react"
import {SVGProps} from "react"

const BubbleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M7.2 18.1a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4ZM14.8 20.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM15.2 14.1a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Z"
        />
    </svg>
);
export default BubbleIcon;
