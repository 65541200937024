import {ArcActor} from "engine/actor/ArcActor";
import {ArcEvent} from "engine/ArcEvent";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ReferencedQueryActor} from "engine/actor/ReferencedQueryActor";
import {DashboardGlobalFilters} from "metadata/dashboard/DashboardGlobalFilters";
import {GlobalFiltersMessage} from "engine/actor/GlobalFiltersMessage";
import {Facet, FacetingEventFilter, FacetingMessage} from "engine/FacetingMessage";
import {ClearSelectionsMessage} from "engine/ClearSelectionsMessage";
import {Optional} from "common/Optional";
import {ExternalStateDelegate} from "app/components/ExternalStateDelegate";
import {Json, JsonObject} from "common/CommonTypes";
import {Either} from "common/Either";
import {GlobalFilterClauseFactory} from "metadata/dashboard/GlobalFilterClauseFactory";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {References} from "metadata/References";
import {ExternalFilterState} from "metadata/ExternalFilterState";
import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {SingleSource} from "metadata/query/ArcQLSource";
import {StatePassMode} from "metadata/dashboard/widgets/config/StatePassMode";
import {DashboardInteractions} from "metadata/dashboard/DashboardInteractions";
import {DashboardFilterCollator} from "app/dashboard/DashboardFilterCollator";
import {LoadStateEventFilter} from "engine/actor/LoadStateMessage";
import {DatasetV2Service} from "services/DatasetV2Service";

export const FILTER_ACTOR_ID = '__SYSTEM__filter';

export class DashboardFilterActor extends ArcActor implements ExternalStateDelegate<ExternalFilterState<GlobalFilterClause>> {

    private readonly loadStateFilter: LoadStateEventFilter;
    private readonly facetingFilter = new FacetingEventFilter();

    private onGlobalFiltersChange: (clauses: GlobalFilterClause[], datasets: Map<string, ArcDataset>) => void;
    private onFacetsChange: (facets: Map<string, Facet[]>, datasets: Map<string, ArcDataset>) => void;

    // datasets by FQN used by filters
    private _datasets: Map<string, ArcDataset>;
    // filters that are persisted in metadata
    private persistedFilters: DashboardGlobalFilters;
    // session filters that modify persisted filters but are not saved
    private sessionFilters: GlobalFilterClause[];
    // facets by dataset fqn
    private facets: Map<string, Facet[]>;
    // interactions of the dashboard
    private interactions: DashboardInteractions;
    // The FQN of the dashboard
    private dashboardFqn: FQN;

    constructor(
        id: string,
        persistedFilters: DashboardGlobalFilters,
        interactions: DashboardInteractions,
        dashboardFqn: FQN
    ) {
        super(id);

        this.loadStateFilter = new LoadStateEventFilter(id);
        this._datasets = new Map();
        this.persistedFilters = persistedFilters;
        this.interactions = interactions;
        this.dashboardFqn = dashboardFqn;
        this.sessionFilters = [];
        this.facets = new Map();
    }

    async initialize(controller: AbortController): Promise<boolean> {
        this.connector.publish(this.buildGlobalFiltersMessage(this.persistedFilters.clauses));

        return true;
    }

    eventFilters(): ArcEventFilter[] {
        return [this.loadStateFilter, this.facetingFilter];
    }

    notify(event: ArcEvent): void {
        this.loadStateFilter.filter(event).forEach(message => {
            message.statefulMessages.forEach(statefulMessage => {
                // clear the session filters & facets
                this.sessionFilters = [];
                this.facets.clear();

                Optional.ofType(statefulMessage, GlobalFiltersMessage).forEach(m => {
                    // apply the global filters from loaded global filter message (and relayed based on interactions)
                    DashboardFilterCollator.applyStateClauses(
                        m.clauses, this.persistedFilters.clauses, this.sessionFilters, this.interactions
                    );
                    // update linked component on final global filters (for interactions, these message should be enqueued)
                    this.notifyGlobalFiltersChanges(true);
                });

                // and also apply any facets from the stateful message
                Optional.ofType(statefulMessage, FacetingMessage).forEach(m => {
                    this.onFacetMessage(m);
                });

                // update linked components
                this.refreshDatasets()
                    .then(() => this.onFacetsChange?.(this.facets, this._datasets));
            });
        });

        this.facetingFilter.filter(event).forEach(m => {
            this.onFacetMessage(m);
        });
    }

    async decodeExternalState(externalStateJson: Json): Promise<Either<string, ExternalFilterState<GlobalFilterClause>>> {
        return ExternalFilterState.decodeExternalState(
            externalStateJson,
            "global filter clauses",
            (json: JsonObject, refs: References) => GlobalFilterClauseFactory.fromJSON(json, refs)
        );
    }

    applyExternalState(state: ExternalFilterState<GlobalFilterClause>): string {
        // Iterate through persisted filters
        const applied = DashboardFilterCollator.applyStateClauses(
            state.clauses, this.persistedFilters.clauses, this.sessionFilters, this.interactions
        );

        // Notify about the changes if any clauses were applied
        if (applied.length > 0) {
            this.notifyGlobalFiltersChanges();
        }

        return `${applied.length} global filter(s) applied.`;
    }

    /**
     * Link to a callback that cares about when clauses are updated.
     */
    link(
        // callbacks for changes to global filters or facets
        onGlobalFiltersChange: (clauses: GlobalFilterClause[], datasets: Map<string, ArcDataset>) => void,
        onFacetsChange: (facets: Map<string, Facet[]>, datasets: Map<string, ArcDataset>) => void
    ) {
        this.onGlobalFiltersChange = onGlobalFiltersChange;
        this.onFacetsChange = onFacetsChange;

        // notify the component of existing state
        this.refreshDatasets()
            .then(() => {
                this.notifyGlobalFiltersChanges(false);
                this.onFacetsChange(this.facets, this._datasets);
            });
    }

    unlink() {
        this.onGlobalFiltersChange = undefined;
        this.onFacetsChange = undefined;
    }

    /**
     * Return all used datasets in the dashboard, not restricted to the dataset used in global filters.
     */
    get datasets(): ArcDataset[] {
        return Array.from(
            new Map(
                this.connector.collectActors(ReferencedQueryActor, querier => querier.dataset)
                    .map(dataset => [dataset.fqn.toString(), dataset])
            ).values()
        );
    }

    /**
     * Returns the set of common source fqns among all the queries in the dashboard. This means that if multiple
     * queries use different source fqns that all reference the same dataset, it'll return the dataset fqn. Otherwise,
     * it'll return the single source fqn if all queries use the same source fqn for a given dataset.
     */
    get commonSources(): FQN[] {
        const sourcesByDatasetFqn: Map<string, Set<string>> = new Map();
        this.connector.collectActors(ReferencedQueryActor, querier => querier)
            .forEach(querier => {
                const datasetFqn: FQN = querier.dataset.fqn;
                if (!sourcesByDatasetFqn.has(datasetFqn.toString())) {
                    sourcesByDatasetFqn.set(datasetFqn.toString(), new Set());
                }
                sourcesByDatasetFqn.get(datasetFqn.toString()).add((querier.arcql.source as SingleSource).fqn.toString());
            });

        const commonSources: FQN[] = [];
        sourcesByDatasetFqn.forEach((sources: Set<string>, datasetFqn: string) => {
            if (sources.size === 1) {
                commonSources.push(FQN.parse(sources.keys().next().value));
            } else {
                // If there's more than one unique source, then we just want to use the original dataset fqn.
                commonSources.push(FQN.parse(datasetFqn));
            }
        });

        return commonSources;
    }

    /**
     * Attempt to set the global filters + interactions, return false if no changes, true otherwise and publish a message.
     */
    setGlobalFiltersAndInteractions(globalFilters: DashboardGlobalFilters, interactions: DashboardInteractions): boolean {
        this.interactions = interactions;

        if (this.persistedFilters.equals(globalFilters)) {
            return;
        }
        this.persistedFilters = globalFilters;
        this.sessionFilters = [];

        // refresh dataset with the new global filters
        this.refreshDatasets().then(() => this.notifyGlobalFiltersChanges());
    }

    /**
     * Session filters can only modify the condition of a persisted filter so only the ordinal needs to be provided.
     */
    updateSessionFilter(ordinal: number, change: EditorFilterChange) {
        this.sessionFilters[ordinal] = GlobalFilterClauseFactory.fromFilterChange(
            this.persistedFilters.clauses[ordinal],
            change
        );
        this.notifyGlobalFiltersChanges();
    }

    /**
     * Clear facets for the given dataset and field.
     */
    clearFacets(datasetFqn: string, fields: string[]) {
        this.connector.publish(new ClearSelectionsMessage(datasetFqn, fields));
    }

    /**
     * Clear the current session filters.
     */
    clearSession() {
        // clear all the facets
        this.facets.forEach((facets: Facet[], datasetFqn: string) =>
            facets.forEach(facet => this.clearFacets(datasetFqn, facet.filterClause.fields))
        );

        // clear all the global filters
        if (this.sessionFilters.length > 0) {
            this.sessionFilters = [];
            this.notifyGlobalFiltersChanges();
        }
    }

    /**
     * Convert the current filter state of the dashboard into an encoded state that can be passed to a query.
     */
    filterStatePassToQuery(mode: StatePassMode, datasetFqnOfQuery: string): Optional<string> {
        if (mode === StatePassMode.NONE) {
            return Optional.none();
        }

        const filters = DashboardFilterCollator.collateStateForQueries(
            mode, datasetFqnOfQuery, this.currentGlobalFilters, this.facets
        );

        if (filters.clauses.length === 0) {
            return Optional.none();
        }

        return Optional.some(
            btoa(JSON.stringify(filters.clauses))
        );
    }

    /**
     * Convert the current filter state of the dashboard into an encoded state that can be passed to another dashboard.
     */
    filterStatePassToDashboard(mode: StatePassMode): Optional<string> {
        if (mode === StatePassMode.NONE) {
            return Optional.none();
        }

        const globalFilters: GlobalFilterClause[] = DashboardFilterCollator.collateStateForDashboards(
            mode, this.currentGlobalFilters, this.facets
        );

        if (globalFilters.length === 0) {
            return Optional.none();
        }

        return Optional.some(
            btoa(JSON.stringify(globalFilters))
        );
    }

    /**
     * Current global filters is the state of persisted merged with session filters.
     */
    public get currentGlobalFilters(): GlobalFilterClause[] {
        return this.persistedFilters.clauses.map((clause, index) => {
            return this.sessionFilters[index] || clause;
        });
    }

    /**
     * New global filters changes to broadcast to linked call back and optionally to the connector.
     */
    private notifyGlobalFiltersChanges(publish: boolean = true) {
        // merge session and persisted filters
        const mergedFilters = this.persistedFilters.clauses.map(
            (c, cI) => this.sessionFilters[cI] || c
        );

        // global filter changes are published by this actor
        this.onGlobalFiltersChange?.(mergedFilters, this._datasets);

        if (publish) {
            this.connector.publish(this.buildGlobalFiltersMessage(mergedFilters));
        }
    }

    private buildGlobalFiltersMessage(clauses: GlobalFilterClause[]): GlobalFiltersMessage {
        return new GlobalFiltersMessage(DashboardFilterCollator.relayClauses(clauses, this.interactions));
    }

    private async refreshDatasets() {
        // include linked clauses in global filters from interactions when populating all datasets
        const allGlobalFilters = DashboardFilterCollator.relayClauses(this.persistedFilters.clauses, this.interactions);

        const datasetsToFetch = new Set<string>([
            ...allGlobalFilters.map(c => c.datasetFqn.toString()),
            ...this.facets.keys()
        ]);

        // update the datasets map
        const newDatasets: Map<string, ArcDataset> = new Map();
        for (let datasetFqn of datasetsToFetch) {
            if (this._datasets.has(datasetFqn)) {
                newDatasets.set(datasetFqn, this._datasets.get(datasetFqn));
            } else {
                const dataset = await ServiceProvider.get(DatasetV2Service)
                    .describeDataset(FQN.parse(datasetFqn), null, this.dashboardFqn)
                    .then(e => e.rightOrThrow());

                newDatasets.set(datasetFqn, dataset);
            }
        }

        this._datasets = newDatasets;
    }

    /**
     * Handler for new faceting message (ex: relay to linked component).
     */
    private onFacetMessage(message: FacetingMessage) {
        // facets needs to be immutable for react
        this.facets = new Map(this.facets);
        this.facets.set(message.datasetFqn, message.facets);
        this.refreshDatasets()
            .then(() => this.onFacetsChange?.(this.facets, this._datasets));
    }

}