import * as React from "react"
import {SVGProps} from "react"

const ScatterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M7 17.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM11 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM16.6 21.1a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        />
    </svg>
);
export default ScatterIcon;
