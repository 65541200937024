import * as React from "react";
import { VizProperty } from "app/visualizations/definition/property/VizProperty";
import { Optional } from "common/Optional";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import { S } from "app/visualizations/definition/property/VizPropertyS";

type ToggleType = 'checkbox' | 'switch';

export class VizBooleanProperty implements VizProperty<boolean> {
    constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly toggleType: ToggleType = 'switch'
    ) {}

    el(v: Optional<boolean>, onChange: (v: boolean) => void): React.ReactNode {

        const buildToggle = (toggleType: ToggleType) => {
            switch (toggleType) {
                case 'checkbox':
                    return (
                        <Checkbox
                            color="primary"
                            checked={v.getOr(false)}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    );
                case 'switch':
                    return (
                        <Switch
                            color="primary"
                            checked={v.getOr(false)}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                    );
            }
        };
        return (
            <FormControl fullWidth size="small">
                <S.Toggle>
                    <S.ToggleSwitch>
                        {buildToggle(this.toggleType)}
                    </S.ToggleSwitch>
                    <S.ToggleLabel>{this.label}</S.ToggleLabel>
                </S.Toggle>
            </FormControl>
        );
    }
}
