import * as React from "react";
import {FunctionComponent, useState} from "react";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {Optional} from "common/Optional";
import {ExpressionField} from "metadata/query/ExpressionField";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {GroupingEditor} from "app/query/components/GroupingEditor";
import {PanelS} from "app/query/panels/PanelS";
import styled from "@emotion/styled";
import {Pill} from "app/query/components/Pill";
import {Colors} from "app/components/StyleVariables";
import {ExpressionGrouping} from "metadata/query/ExpressionGrouping";
import {TypedMenu} from "app/components/TypedMenu";
import {FieldAction} from "app/query/panels/FieldAction";
import {Direction} from "metadata/query/ArcQLOrderBy";
import {DragAndDropData} from "common/DragAndDropData";
import {S as CanvasS} from "app/query/QueryBuilderCanvasS";
import {IconButton} from "@mui/material";
import {ToggleOffOutlined, ToggleOn} from "@mui/icons-material";
import {ExpressionEditor} from "app/query/components/ExpressionEditor";
import ExpressionFxIcon from "app/components/icons/ExpressionFxIcon";

type Props = {
    arcqlBundle: ArcQLBundle
    delegate: QueryBuilderDelegate
    // drag events + callbacks
    onDragOver: (event: React.DragEvent) => void
    onDrop: (event: React.DragEvent) => void
    onDragStart: (event: React.DragEvent) => void
}

/**
 * Shelf panel for groupings in a query
 *
 * @author zuyezheng
 */
export const GroupingPanel: FunctionComponent<Props> = (props: Props) => {

    const [selected, setSelected] = useState<Optional<SelectedGrouping>>(Optional.none());
    const [selectedSuffix, setSelectedSuffix] = useState<Optional<SelectedGrouping>>(Optional.none());
    const [selectedGroupingForRename, setSelectedGroupingForRename] = useState<Optional<ArcQLGrouping>>(Optional.none());
    const [addTarget, setAddTarget] = useState<Optional<Element>>(Optional.none());

    const onIsGroupedChange = () => {
        props.delegate.toggleGrouped(!props.arcqlBundle.arcql.isGrouped());
    };

    const onSelect = (event: React.MouseEvent, grouping: ArcQLGrouping, ordinal: number) => {
        setSelected(Optional.some({
            // if event.currentTarget is null, use event.target as Element
            el: event.currentTarget ?? event.target as Element,
            grouping: grouping,
            ordinal: ordinal,
            isLast: ordinal === props.arcqlBundle.arcql.groupings.size - 1
        }));
    };

    const onChange = (originalField: string, grouping: ArcQLGrouping) => {
        props.delegate.modifyGrouping(originalField, grouping);
        setSelected(Optional.none());
        setSelectedGroupingForRename(Optional.none());
    };

    const onCloseSelected = () => {
        setSelected(Optional.none());
    };

    const onClickSuffix = (event: React.MouseEvent, grouping: ArcQLGrouping, ordinal: number) => {
        setSelectedSuffix(Optional.some({
            el: event.currentTarget,
            grouping: grouping,
            ordinal: ordinal,
            isLast: ordinal === props.arcqlBundle.arcql.groupings.size - 1
        }));
    };

    const isActionSelected = (action: FieldAction) => {
        // highlight the order direction if ordered by this field
        return selectedSuffix.flatMap(selected =>
            props.arcqlBundle.arcql.orderBys.get(selected.grouping.projectedAs)
                .map(orderBy =>
                    (action === FieldAction.ORDER_DESC && orderBy.direction === Direction.DESC) ||
                    (action === FieldAction.ORDER_ASC && orderBy.direction === Direction.ASC)
                )
        ).getOr(false);
    };

    const onClickAction = (action: FieldAction) => {
        selectedSuffix.map(selected => {
            switch (action) {
                case FieldAction.ORDER_DESC:
                    props.delegate.orderByField(selected.grouping.projectedAs, Direction.DESC);
                    return;
                case FieldAction.ORDER_ASC:
                    props.delegate.orderByField(selected.grouping.projectedAs, Direction.ASC);
                    return;
                case FieldAction.MOVE_UP:
                    props.delegate.moveGrouping(selected.grouping.field, selected.ordinal - 1);
                    return;
                case FieldAction.MOVE_DOWN:
                    props.delegate.moveGrouping(selected.grouping.field, selected.ordinal + 1);
                    return;
                case FieldAction.RENAME:
                    setSelectedGroupingForRename(Optional.of(selected.grouping));
                    return;

                case FieldAction.REMOVE:
                    props.delegate.deleteGrouping(selected.grouping.field);
                    return;
            }
        });

        setSelectedSuffix(Optional.none());
    };

    const onCloseActions = () => {
        setSelectedSuffix(Optional.none());
    };

    const onAdd = (event: React.MouseEvent) => {
        setAddTarget(Optional.some(event.currentTarget));
    };

    const onCloseAdd = () => {
        setAddTarget(Optional.none());
    };

    const onDropExistingGrouping = (event: React.DragEvent, ordinal: number) => {
        const dndData = DragAndDropData.fromEvent<QueryDnDSource>(event, QueryDnDSource);
        dndData.isOf(QueryDnDSource.GROUPING)
            .flatMap(field => {
                props.delegate.moveGrouping(field, ordinal);
                event.stopPropagation();
            });
    };

    return <CanvasS.Shelf onDragOver={props.onDragOver} onDrop={props.onDrop}>
        <S.ShelfHeader>
            <span> Groupings </span>
            <S.Toggle onClick={onIsGroupedChange}>
                {props.arcqlBundle.arcql.isGrouped() ? <ToggleOn/> : <ToggleOffOutlined/>}
            </S.Toggle>
        </S.ShelfHeader>
        {
            props.arcqlBundle.arcql.isGrouped() && <CanvasS.ShelfContent>
                {props.arcqlBundle.arcql.groupings.fields.map((grouping: ArcQLGrouping, ordinal: number) =>
                    <S.GroupingPill
                        key={grouping.field}
                        label={grouping.label(props.arcqlBundle.dataset)}
                        isLabelEditable={grouping instanceof ExpressionGrouping}
                        enterLabelEditMode={selectedGroupingForRename.map(g => g.field === grouping.field).getOr(false)}
                        onLabelChange={
                            grouping instanceof ExpressionGrouping ?
                                (label) => {
                                    onChange(grouping.field, new ExpressionGrouping(grouping.expression, label));
                                    return true;
                                }
                                : undefined
                        }
                        prefix={Optional.ofType(grouping, ExpressionGrouping).map(() => <ExpressionFxIcon/>).nullable}
                        tooltip={Optional.ofType(grouping, ExpressionGrouping).map(g => g.expression).nullable}
                        onClickPrefix={event => onSelect(event, grouping, ordinal)}
                        onClickLabel={event => onSelect(event, grouping, ordinal)}
                        onClickSuffix={event => onClickSuffix(event, grouping, ordinal)}
                        onDragStart={props.onDragStart}
                        dataFieldId={QueryDnDSource.GROUPING.of(grouping.field).serialize}
                        onDrop={e => onDropExistingGrouping(e, ordinal)}
                    />
                )}
                <S.Add className="groupings" onClick={onAdd}>
                    <AddOutlinedIcon/>
                </S.Add>
            </CanvasS.ShelfContent>
        }
        {
            // if grouping off, still display empty shelf content
            !props.arcqlBundle.arcql.isGrouped() && <CanvasS.ShelfContent/>
        }
        {
            selected.map(selected =>
                Optional.ofType(selected.grouping, ExpressionGrouping).map(eg =>
                    <ExpressionEditor
                        el={selected.el}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        arcQLBundle={props.arcqlBundle}
                        field={new ExpressionField(eg.expression, eg.as)}
                        onChange={f => onChange(selected.grouping.field, new ExpressionGrouping(f.expression, f.as))}
                        onClose={onCloseSelected}
                    />).getOrElse(
                    () => <GroupingEditor
                        el={selected.el}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        arcQLBundle={props.arcqlBundle}
                        grouping={Optional.of(selected.grouping)}
                        onChange={g => onChange(selected.grouping.field, g)}
                        onClose={onCloseSelected}
                    />
                )
            ).nullable
        }
        {
            selectedSuffix.map(selected =>
                <TypedMenu
                    el={selected.el}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    items={[FieldAction.filtered(
                        selected.grouping,
                        selected.ordinal === 0,
                        selected.isLast
                    )]}
                    itemId={item => item.name}
                    itemLabel={FieldAction.label}
                    isSelected={isActionSelected}
                    onSelect={onClickAction}
                    onClose={onCloseActions}
                />
            ).getOr(<></>)
        }
        {
            addTarget.map(element =>
                <GroupingEditor
                    el={element}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    arcQLBundle={props.arcqlBundle}
                    grouping={Optional.none()}
                    onChange={g => {
                        props.delegate.addGrouping(g);
                        onCloseAdd();
                    }}
                    onClose={onCloseAdd}
                />
            ).nullable
        }
    </CanvasS.Shelf>;

};

type SelectedGrouping = {
    el: Element,
    grouping: ArcQLGrouping,
    ordinal: number,
    // we know ordinal 0 is first
    isLast: boolean
}

class S {

    static readonly ShelfHeader = styled(CanvasS.ShelfHeader)`
        // rather than 18px padding on right, reduce to 3px due to toggle button
        padding-right: 3px;
    `;

    static readonly Toggle = styled(IconButton)`
        // have it at tail end of shelf header
        margin-left: auto;
    `;

    static readonly GroupingPill = styled(Pill)`
        margin-bottom: 6px;

        button.MuiButton-outlinedPrimary {
            border-color: ${Colors.dimensionPrefix};

            &.prefix {
                background-color: ${Colors.dimensionPrefix};

                &:hover {
                    border-color: ${Colors.dimensionPrefix};
                }
            }

            &.main, &.suffix {
                background-color: ${Colors.dimensionBackground};

                &:hover {
                    background-color: ${Colors.dimensionHover};
                    border-color: ${Colors.dimensionPrefix};
                }
            }
        }
    `;

    static readonly Add = styled(PanelS.Add)`
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%234397F8FF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

        svg {
            color: ${Colors.dimensionBlue};
        }
    `;

}

