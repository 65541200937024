import {Enum} from "common/Enum";
import {ArcQLError} from "metadata/query/ArcQLError";
import {OrderedMap} from "common/OrderedMap";
import {JsonObject} from "common/CommonTypes";
import {DateGrouping} from "metadata/query/DateGrouping";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {DateGrain} from "metadata/query/DateGrain";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ExpressionGrouping} from "metadata/query/ExpressionGrouping";
import {Optional} from "common/Optional";
import {Tuple} from "common/Tuple";
import {ArcQLGroupingFactory} from "metadata/query/ArcQLGroupingFactory";

/**
 * Groupings in an ArcQL query.
 *
 * @author zuyezheng
 */
export class ArcQLGroupings {

    static fromJSON(json: JsonObject[]): ArcQLGroupings {
        return new ArcQLGroupings(json.map(ArcQLGroupingFactory.fromJSON));
    }

    static empty(): ArcQLGroupings {
        return new ArcQLGroupings([]);
    }

    static fields(fields: ArcQLGrouping[]): ArcQLGroupings {
        return new ArcQLGroupings(fields);
    }

    private readonly _fields: OrderedMap<string, ArcQLGrouping>;

    constructor(fields: ArcQLGrouping[]) {
        this._fields = OrderedMap.fromKeyed(fields, v => v.field);
    }

    get type(): GroupingType {
        return this._fields.size === 0 ? GroupingType.ALL : GroupingType.FIELDS;
    }

    get isAll(): boolean {
        return this.type === GroupingType.ALL;
    }

    map<T>(f: (field: ArcQLGrouping, i: number) => T): T[] {
        return this._fields.values.map(f);
    }

    /**
     * Number of explicit groupings, with all being 0.
     */
    get size(): number {
        return this._fields.values.reduce(
            (prev: number, next: ArcQLGrouping) => prev + next.size(), 0
        );
    }

    /**
     * Return the first grouping.
     */
    get first(): ArcQLGrouping {
        return this.isAll ? new FieldGrouping('all') : this._fields.first;
    }

    /**
     * Return the last grouping.
     */
    get last(): ArcQLGrouping {
        return this.isAll ? new FieldGrouping('all') : this._fields.last;
    }

    get fields(): ArcQLGrouping[] {
        return this._fields.values;
    }

    has(name: string): boolean {
        return this._fields.has(name);
    }

    get<T extends ArcQLGrouping>(field: string): T {
        return <T>this._fields.get(field).get();
    }

    getPossible<T extends ArcQLGrouping>(field: string): Optional<T> {
        return <Optional<T>>this._fields.get(field);
    }

    find<T extends ArcQLGrouping>(field: string): Optional<Tuple<T, number>> {
        return <Optional<Tuple<T, number>>>this._fields.find(field);
    }

    with(grouping: ArcQLGrouping, ordinal?: number): ArcQLGroupings {
        if (ordinal < 0) {
            throw new ArcQLError('Can\'t add a grouping before ordinal 0.');
        }

        // if no ordinal or greater than the length, add it to the end
        if (ordinal == null || ordinal >= this._fields.size) {
            return new ArcQLGroupings([...this._fields.values, grouping]);
        }

        // add it to the specific spot
        return new ArcQLGroupings(
            this._fields.values.flatMap(
                (g: ArcQLGrouping, gOrdinal: number) => ordinal === gOrdinal ? [grouping, g] : [g]
            )
        );
    }

    without(field: string): ArcQLGroupings {
        return new ArcQLGroupings(
            this._fields.values.filter(f => f.field !== field)
        );
    }

    replace(grouping: ArcQLGrouping, originalField?: string): ArcQLGroupings {
        originalField = originalField || grouping.field;

        return new ArcQLGroupings(
            this._fields.values.map(
                f => f.field === originalField ? grouping : f
            )
        );
    }

    toJSON(): Object {
        return this._fields.values;
    }

}

export class GroupingType extends Enum {

    static ALL = new GroupingType('all');
    static FIELDS = new GroupingType('fields');

}
GroupingType.finalize();

export class ArcQLGroupingType extends Enum {

    static DIMENSION = new ArcQLGroupingType(
        'dimension',
        (json: JsonObject) => new FieldGrouping(json['field'])
    );
    static DATE = new ArcQLGroupingType(
        'date',
        (json: JsonObject) => new DateGrouping(json['field'], DateGrain.get(json['grain']))
    );
    static EXPRESSION = new ArcQLGroupingType(
        'expression',
        (json: JsonObject) => new ExpressionGrouping(json['expression'], json['as'])
    );
    static HIERARCHY = new ArcQLGroupingType(
        'hierarchy',
        (json: JsonObject) => new HierarchyGrouping(json['field'], json['grain'], HierarchyBound.get(json['bound']))
    );

    private constructor(
        name: string,
        public readonly fromJson: (json: JsonObject) => ArcQLGrouping
    ) {
        super(name);
    }

}
ArcQLGroupingType.finalize();

export class FieldGrouping extends ArcQLGrouping {

    constructor(field: string) {
        super(ArcQLGroupingType.DIMENSION, field);
    }

    size(): number {
        return 1;
    }

    get projectedAs(): string {
        return this.field;
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        // if grouped, always a dimension
        return AnalyticsType.DIMENSION;
    }

}

export class HierarchyGrouping extends ArcQLGrouping {

    constructor(
        field: string,
        public readonly grain: string,
        public readonly bound: HierarchyBound
    ) {
        super(ArcQLGroupingType.HIERARCHY, field);
    }

    size(): number {
        throw new ArcQLError('To be implemented.');
    }

    get projectedAs(): string {
        throw new ArcQLError('To be implemented.');
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        throw new ArcQLError('To be implemented.');
    }

}

export class HierarchyBound extends Enum {

    static HIGHEST = new HierarchyBound('highest');
    static LOWEST = new HierarchyBound('lowest');

}
HierarchyBound.finalize();
