import {JsonObject} from "common/CommonTypes";
import {TriggerCondition} from "metadata/trend/TriggerCondition";
import {TriggerConditionFactory} from "metadata/trend/TriggerConditionFactory";
import {RowsSelectorType} from "metadata/trend/RowsSelectorType";
import {ThresholdCondition} from "metadata/trend/ThresholdCondition";

export type AlertJson = {
    id: string,
    createdOn: string,
    createdBy: string,
    updatedOn: string,
    label: string,
    description?: string,
    field: string,
    triggerCondition: JsonObject,
    rowsSelector: string,
}

type Props = {
    label: string,
    description: string,
    field: string,
    triggerCondition: TriggerCondition,
    rowsSelector: RowsSelectorType
}

/**
 * Alert model.
 */
export class Alert {
    constructor(
        public readonly id: string = null,
        public readonly createdOn: string = null,
        public readonly createdBy: string = null,
        public readonly updatedOn: string = null,
        public readonly label: string = null,
        public readonly description: string = null,
        public readonly field: string = null,
        public readonly triggerCondition: TriggerCondition = ThresholdCondition.default(),
        public readonly rowsSelector: RowsSelectorType = RowsSelectorType.ALL
    ) {
    }

    static forCreation(
        label: string,
        description: string,
        field: string,
        triggerCondition: TriggerCondition,
        rowsSelector: RowsSelectorType
    ): Alert {
        return new Alert(
            null,
            null,
            null,
            null,
            label,
            description,
            field,
            triggerCondition,
            rowsSelector
        );
    }

    static fromJSON(json: AlertJson): Alert {
        return new Alert(
            json.id,
            json.createdOn,
            json.createdBy,
            json.updatedOn,
            json.label,
            json.description,
            json.field,
            TriggerConditionFactory.fromJSON(json.triggerCondition),
            RowsSelectorType.get(json.rowsSelector)
        );
    }

    with(props: Partial<Props>): Alert {
        return new Alert(
            this.id,
            this.createdOn,
            this.createdBy,
            this.updatedOn,
            props.label ?? this.label,
            props.description ?? this.description,
            props.field ?? this.field,
            props.triggerCondition ?? this.triggerCondition,
            props.rowsSelector ?? this.rowsSelector
        );

    }

    toJSON(): AlertJson {
        return {
            id: this.id,
            createdOn: this.createdOn,
            createdBy: this.createdBy,
            updatedOn: this.updatedOn,
            label: this.label,
            description: this.description,
            field: this.field,
            triggerCondition: this.triggerCondition.toJSON(),
            rowsSelector: this.rowsSelector.name
        };
    }
}