import {Enum} from "common/Enum";


/**
 * Enumerates the different types of state passing (i.e. like when link widget is clicked).
 */
export class StatePassMode extends Enum {

    static readonly NONE = new this(
        'none',
        'None'
    );

    static readonly GLOBAL_FILTERS = new this(
        'globalFilters',
        'Global Filters'
    );

    static readonly FACETS = new this(
        'facets',
        'Facets'
    );

    static readonly ALL = new this(
        'all',
        'All'
    );


    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}

StatePassMode.finalize();