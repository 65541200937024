import {Either} from "common/Either";
import {FQN} from "common/FQN";
import {UserSubscription} from "metadata/subscription/UserSubscription";
import {SubscriptionService} from "services/SubscriptionService";
import {ErrorResponse} from "services/ApiResponse";

export class MockedSubscriptionService implements SubscriptionService {
    listSubscriptions(signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription[]>> {
        return Promise.resolve(undefined);
    }

    subscribeToAlert(trendFqn: FQN, alertId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>> {
        return Promise.resolve(undefined);
    }

    unsubscribeFromAlert(trendFqn: FQN, alertId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>> {
        return Promise.resolve(undefined);
    }

    archiveSubscription(subscriptionId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>> {
        return Promise.resolve(undefined);
    }
}