import {JsonObject} from "common/CommonTypes";
import {Asset, AssetMetadataProps, AssetProps} from "metadata/Asset";
import {FQN} from "common/FQN";
import {References} from "metadata/References";
import {UnhydratedReferences} from "metadata/UnhydratedReferences";
import {AssetType} from "metadata/AssetType";
import {GlobalFilterSetFilterClause} from "metadata/dashboard/GlobalFilterSetFilterClause";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcSession} from "metadata/session/ArcSession";
import {Alert} from "metadata/trend/Alert";

export type ArcTrendBodyProps = {
    arcQLId?: string,
    filters?: ArcQLFilters,
    sessionId?: string,
    widgetId?: string
}

// all mutable props
export type ArcTrendProps = AssetProps & AssetMetadataProps & ArcTrendBodyProps;

/**
 * Trend asset result + metadata.
 */
export class ArcTrend implements Asset {

    static fromJSON(json: JsonObject): ArcTrend {
        const references: References = References.fromJSON(json[References.JSON_KEY]);
        return new ArcTrend(
            json['id'],
            json['fqn'] && FQN.parse(json['fqn']),
            json['name'],
            json['label'],
            json['description'],
            json['arcQLId'],
            ArcQLFilters.fromJSON(json['filters'], false, references),
            json['sessionId'],
            json['widgetId'],
            json['createdBy'],
            json['createdOn'],
            references,
            json['arcQL'] ? ArcQL.fromJSON(json['arcQL']) : null,
            json['session'] ? ArcSession.fromJson(json['session']) : null,
            json['alerts'] ? (json['alerts']).map(Alert.fromJSON) : null,
            json['version']
        );
    }

    static minimal(): ArcTrend {
        return new ArcTrend();
    }

    constructor(
        // id should default to null until saved as its the indicator for a new vs existing asset
        public readonly id: string = null,
        public readonly fqn: FQN = null,
        // these should default to null so we know when to apply defaults vs user explicitly set an empty string
        public readonly name: string = null,
        public readonly label: string = null,
        public readonly description: string = null,
        public readonly arcQLId: string = null,
        public readonly filters: ArcQLFilters = ArcQLFilters.empty(false),
        public readonly sessionId: string = null,
        public readonly widgetId: string = null,
        public readonly createdBy: string = null,
        public readonly createdOn: string = null,
        public readonly references: References = new References(),
        // fully loaded
        public readonly arcQL: ArcQL = null,
        public readonly session: ArcSession = null,
        public readonly alerts: Alert[] = [],
        public readonly version: number = -1
    ) {
    }

    get assetType(): AssetType {
        return AssetType.TREND;
    }

    with({
        name,
        label,
        description,
        arcQLId,
        filters,
        sessionId,
        widgetId,
        references
    }: ArcTrendProps): ArcTrend {
        return new ArcTrend(
            this.id,
            this.fqn,
            name ?? this.name,
            label ?? this.label,
            description ?? this.description,
            arcQLId ?? this.arcQLId,
            filters ?? this.filters,
            sessionId ?? this.sessionId,
            widgetId ?? this.widgetId,
            this.createdBy,
            this.createdOn,
            references ?? this.references,
            this.arcQL,
            this.session,
            this.alerts,
            this.version
        );
    }

    get isExisting(): boolean {
        return this.id != null;
    }


    /**
     * Grabs the latest references unhydrated (i.e. just the FQNs) for ArcTrends such as FilterSet assets in FilterSet filter clauses.
     */
    unhydratedReferences(): UnhydratedReferences {
        return new UnhydratedReferences(
            this.filters.clauses.filter(c => c.type.hasAssetRefs)
                .map((c: GlobalFilterSetFilterClause) => c.fullyQualifiedName)
        );
    }

    toJSON(): JsonObject {
        return {
            id: this.id,
            name: this.name,
            label: this.label,
            description: this.description,
            arcQLId: this.arcQLId,
            filters: this.filters,
            sessionId: this.sessionId,
            widgetId: this.widgetId
        };
    }

    toLocalStorageJSON(fqn: FQN): JsonObject {
        return {
            ...this.toJSON(),
            // stuff we normally wouldn't pass to the server for saving
            fullyQualifiedName: fqn.toString(),
            version: this.version,
            // ArcDashboard may constantly change due to it being WIP and unsaved, hence store information in local to later hydrate refs
            unhydratedReferences: this.unhydratedReferences()
        };
    }

}