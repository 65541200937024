import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {CreateWidgetLayout} from "metadata/dashboard/changes/CreateWidgetLayout";

/**
 * Remove the widget on the default layout.
 *
 * TODO ZZ specify which layout to remove from.
 *
 * @author zuyezheng
 */
export class DeleteWidgetLayout implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly widgetId: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const newMetadata = metadata.with({
            layouts: metadata.layouts.withUpdatesOnDefault(
                layout => layout.without(this.widgetId)
            )
        });

        return Tuple.of(
            newMetadata,
            new CreateWidgetLayout(this.widgetId, metadata.layouts.default.get(this.widgetId))
        );
    }

    describe(): string {
        return `Removed layout for widget ${this.widgetId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}