import React from "react";
import styled from "@emotion/styled";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {Colors} from "app/components/StyleVariables";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";


interface Props {
    className?: string;
    path: string;
}

/**
 * Display a URL link that can be copied to clipboard.
 *
 * @author zuyezheng
 */
export const UrlLink = (props: Props) => {

    const onCopyShareLink = () => {
        navigator.clipboard.writeText(props.path).then(() => {
            ServiceProvider.get(NotificationsService).publish(
                'shareDialog', NotificationSeverity.INFO, 'Sharing link copied to clipboard!'
            );
        });
    };

    return <S.UrlContainer className={props.className}>
        <S.Url>{props.path}</S.Url>
        <ContentCopyOutlinedIcon onClick={onCopyShareLink} />
    </S.UrlContainer>;

};

class S {

    static UrlContainer = styled.div`
        border-radius: 4px;
        border: 1px solid ${Colors.borderGrey};
        padding: 8px 12px;
        display: flex;
        align-items: center;
        
        svg {
            font-size: 1rem;
            margin-left: 8px;
        }
    `;

    static Url = styled.div`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

}