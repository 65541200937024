import * as React from "react"
import {SVGProps} from "react"

const ProjectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.25"
        height="22"
        fill="none"
        {...props}
    >
        <path
            fill="#DBA902"
            d="M2.167 0h1.625v20.465H2.167C.97 20.465 0 19.55 0 18.42V2.047C0 .917.97 0 2.167 0Z"
        />
        <path
            fill="#DBA902"
            d="M16.25 20.465c1.105 0 2-.916 2-2.046v-2.047h-15v4.093h13Z"
        />
        <path
            fill="#FFECB3"
            d="M17.083 16.372h-15c-.55 0-1 .46-1 1.023v1.024c0 .562.45 1.023 1 1.023h14c.553 0 1-.458 1-1.023v-2.047Z"
        />
        <path fill="#DBA902" d="m8.125 22-2.438-1.535L3.25 22v-4.605h4.875V22Z"/>
        <path
            fill="#F8BF00"
            fillRule="evenodd"
            d="M16.25 0c1.105 0 2 .916 2 2.047v14.325h-15V0h13ZM6 11.541c-.552 0-1 .322-1 .718 0 .397.448.718 1 .718s1-.321 1-.718c0-.396-.448-.718-1-.718Zm1-3.588c0 .397-.448.718-1 .718s-1-.32-1-.717c0-.397.448-.718 1-.718s1 .32 1 .718Zm0-4.305c0 .396-.448.717-1 .717s-1-.32-1-.717c0-.397.448-.718 1-.718s1 .321 1 .718Z"
            clipRule="evenodd"
        />
    </svg>
)
export default ProjectIcon
