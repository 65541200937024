import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {Optional} from "common/Optional";
import {ArcQLContext} from "metadata/search/ArcQLContext";

export type SortableNode = {
    similarity: number
    node: HyperGraphNode
    query: AssetSearchResult
}

export class HyperGraphSearchResultsUtil {
    static sortSearchResultsBySimilarity(searchResults: AssetsSearchResponse): SortableNode[] {
        const nodes: SortableNode[] = [];
        searchResults.results.forEach(result => {
            Optional.ofType(result.context, ArcQLContext).forEach(context => {
                context.hyperGraphNodeResults.forEach(nodeResult => {
                    nodes.push({
                        similarity: nodeResult.similarity,
                        node: nodeResult.node,
                        query: result
                    });
                });
            });
        });

        nodes.sort((a, b) => b.similarity - a.similarity);

        return nodes;
    }
}