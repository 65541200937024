import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import {UsernameStep} from "app/home/onboarding/UsernameStep";
import {HyperArcUser} from "metadata/HyperArcUser";
import React, {FunctionComponent} from "react";

type Props = {
    open: boolean
    onLoggedIn: (user: HyperArcUser) => void
    onFinish: () => void
}

export const Onboarding: FunctionComponent<Props> = (props: Props) => {
    return <Dialog
        fullScreen
        open={props.open}
    >
        <Container maxWidth="sm" sx={{ pt: 9 }}>
            <Stack spacing={4}>
                <img src="/static/images/logo.svg" height="40px" alt="Hyperarc" />
                <Box sx={{ px:4 }}>
                    <UsernameStep
                        onLoggedIn={props.onLoggedIn}
                        onEnd={() => {
                            props.onFinish();
                        }}
                    />
                </Box>
            </Stack>
        </Container>
    </Dialog>;

};