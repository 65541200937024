import {ArcMetadata} from "metadata/ArcMetadata";
import {Optional} from "common/Optional";
import {ArcEvent} from "engine/ArcEvent";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcActor} from "engine/actor/ArcActor";
import {ArcMetadataChange} from "metadata/ArcMetadataChange";
import {AssetProps} from "metadata/Asset";
import {ReplaceReason} from "metadata/ReplaceReason";
import {FQN} from "common/FQN";
import throttle from 'lodash/debounce';
import {DatasetV2StateReplace} from "metadata/dataset/changes/DatasetV2StateReplace";
import {DatasetV2StateInfoChange} from "metadata/dataset/changes/DatasetV2StateInfoChange";
import {ServiceProvider} from "services/ServiceProvider";
import {LocalStorageService} from "services/LocalStorageService";
import {DatasetV2StateColumnChange} from "metadata/dataset/changes/DatasetV2StateColumnChange";
import {DatasetV2BuilderColumn, DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";


/**
 * Delegate for DatasetV2 builder. Due to its simplicity, mainly to propagate callback for datasetv2 builder state metadata changes.
 */
export class DatasetV2BuilderDelegate extends ArcActor {

    constructor(
        id: string,
        private readonly state: ArcMetadata<DatasetV2BuilderState>,
        private readonly onChange: (datasetV2: Optional<DatasetV2BuilderState>) => void
    ) {
        super(id);
    }

    undo() {
        if (this.state.undo()) {
            this.onChanged();
        }
    }

    redo() {
        if (this.state.redo()) {
            this.onChanged();
        }
    }

    get hasUndo(): boolean {
        return this.state.hasUndo();
    }

    get hasRedo(): boolean {
        return this.state.hasRedo();
    }

    changeInfo(info: AssetProps) {
        this.state.apply([new DatasetV2StateInfoChange(info)]);
        this.onChanged(false);
    }

    /**
     * Replace the entire dataset state with the new.
     */
    replace(state: DatasetV2BuilderState, reason: ReplaceReason) {
        this.state.apply([new DatasetV2StateReplace(state, reason)]);
        this.onChanged();
    }

    /**
     * Return a flattened list of changes after the most recent persisted change from new to old.
     */
    get changes(): string[] {
        const changesBeforePersistence: string[] = [];
        this.state.changesZipped.some(change => {
            if (this.isChangePersisted(change.right)) {
                // found a persisted change, can stop
                return true;
            } else {
                changesBeforePersistence.push(...change.left);
                return false;
            }
        });

        return changesBeforePersistence;
    }

    /**
     * Serialize changes into a single string from new to old.
     */
    describeChanges(): string {
        return this.changes.map(c => '- ' + c).join('\n');
    }

    /**
     * If there are any unsaved changes.
     */
    get hasChanges(): boolean {
        const undoChanges = this.state.undoChanges;
        return undoChanges.length !== 0 && !this.isChangePersisted(undoChanges[0]);
    }

    /**
     * Update column for a dataset.
     */
    updateColumn(column: DatasetV2BuilderColumn) {
        this.state.apply([new DatasetV2StateColumnChange(column)]);
        this.onChanged();
    }

    /**
     * If the specific change indicates persistence (e.g. was saved or loading a saved asset).
     */
    private isChangePersisted(change: ArcMetadataChange<DatasetV2BuilderState>[]): boolean {
        return change.length === 1 && Optional.ofType(change[0], DatasetV2StateReplace)
            .map(c => c.reason.isPersisted)
            .getOr(false);
    }

    /**
     * Broadcast a metadata change to the engine and UI.
     */
    onChanged(publishMessage: boolean = true) {
        this.onChange(Optional.some(this.state.metadata));
    }

    eventFilters(): ArcEventFilter[] {
        return [];
    }

    notify(event: ArcEvent): void {
    }

}