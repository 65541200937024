import {Json} from "common/CommonTypes";
import {Either, Left, Right} from "common/Either";
import {Optional} from "common/Optional";
import {Enum} from "common/Enum";

export class ErrorCode extends Enum {
    static ERROR = new this("error");
    static NOT_FOUND = new this("not_found");
    static UNAUTHORIZED = new this("unauthorized");
    static FORBIDDEN = new this("forbidden");
    static DUPLICATE_NAME = new this("duplicate_name");
    static INVALID_FIELDS = new this("invalid_fields");
    static USER_EXISTS = new this("user_exists");
    static USER_NOT_FOUND = new this("user_not_found");
    static DUPLICATE_USER_EMAIL = new this("duplicate_user_email");
    static REMOTE_CONNECTION_ERROR = new this("remote_connection_error");
}
ErrorCode.finalize();

export type FieldError = {
    field: string;
    error: string;
}

/**
 * Encapsulate and prefetch json body for errors.
 */
export class ErrorResponse {

    static of(json: Json, response: Response): ErrorResponse {
        return new ErrorResponse(json, response);
    }

    constructor(
        public readonly json: Json,
        public readonly response: Response
    ) {}

    toString(): string {
        return `${this.response.status} (${this.response.statusText}): ${this.response.url}`;
    }

    prettyPrint(): string {
        if (this.json != null && 'message' in this.json) {
            return this.json['message'];
        } else {
            return this.response.statusText;
        }
    }

    get errorCode(): ErrorCode {
        if (this.json != null && 'errorCode' in this.json) {
            return ErrorCode.get(this.json['errorCode']);
        } else {
            return ErrorCode.ERROR;
        }
    }

    fieldErrors(): Optional<Map<string, string>> {
        if (this.json != null && 'fieldErrors' in this.json) {
            const fieldErrors: Array<FieldError> = this.json['fieldErrors'];
            return Optional.of(new Map(fieldErrors.map(obj => [obj.field, obj.error])));
        } else {
            return Optional.none();
        }
    }

}

/**
 * Helpers for API responses.
 *
 * @author zuyezheng
 */
export class ApiResponse {

    /**
     * Turn a response into an either.
     */
    static async custom<L, R>(
        r: Response,
        // if expected status code, do something and turn it into a right
        expected: (json: Json, r: Response) => R,
        // if unexpected status code, do something and turn it into a left
        unexpected: (json: Json, r: Response) => L,
        // expected status code
        expectedStatusCode: number = 200
    ): Promise<Either<L, R>> {
        let json;
        try {
            json = await r.json();
        } catch (e) { }
        if (r.status === expectedStatusCode) {
            return new Right(expected(json, r));
        } else {
            return new Left(unexpected(json, r));
        }
    }

    /**
     * Turn a response into an either with some identity functions.
     */
    static async identity(r: Response): Promise<Either<ErrorResponse, Json>> {
        return ApiResponse.custom(r, json => json, ErrorResponse.of);
    }

    /**
     * Text based response.
     */
    static async text(r: Response): Promise<Either<ErrorResponse, string>> {
        let text;
        try {
            text = await r.text();
        } catch (e) { }
        if (r.status === 200) {
            return new Right(text);
        } else {
            return new Left(ErrorResponse.of({}, r));
        }
    }

    static success<T>(successHandler: (json: Json, r: Response) => T): (r: Response) => Promise<Either<ErrorResponse, T>> {
        return (r: Response) => ApiResponse.custom(r, successHandler, ErrorResponse.of);
    }

}