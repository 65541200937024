import {NodeStructuredDetail} from 'metadata/hypergraph/content/NodeStructuredDetail';

/**
 * A single text blob detail.
 *
 * @author zuyezheng
 */
export class TextDetail implements NodeStructuredDetail {

    constructor(
        public readonly text: string,
        public readonly isImportant: boolean = false
    ) { }

    get embeddingContent(): string {
        return this.isImportant ? `**${this.text}**` : this.text;
    }

}
