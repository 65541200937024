import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {ActorStatus} from "engine/actor/ActorStatus";
import {TypedEventFilter} from "engine/ArcEventFilter";

/**
 * Message when an actor status change happens.
 */
export class ActorStatusMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.STATUS;

    constructor(
        public readonly status: ActorStatus,
        // optional reason for the status
        public readonly reason?: string
    ) {}

}

export class ActorStatusEventFilter extends TypedEventFilter<ActorStatusMessage> {

    constructor() {
        super(ActorStatusMessage);
    }

}