import React, {FunctionComponent, ReactNode} from "react";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {DatasetInfoPopover} from "app/query/components/DatasetInfoPopover";
import Avatar from "@mui/material/Avatar";
import {DatasetIcon} from "app/query/components/DatasetIcon";
import Tooltip from "@mui/material/Tooltip";
import {AvatarGroup} from "@mui/lab";
import {DatasetStatusDisplay} from "metadata/dataset/DatasetStatusDisplay";
import {DatasetType} from "metadata/dataset/DatasetType";

type Props = {
    dataset: ArcDataset
    // show vertical
    isVertical?: boolean

    onClickHeader: () => void
}

export const DatasetInfo: FunctionComponent<Props> = (props: Props) => {

    // defaults
    const {isVertical = false} = props;

    const buildDisplayStatus = (): ReactNode => {
        switch (props.dataset.status.metadata.statusDisplay) {
            case DatasetStatusDisplay.STREAMING:
                return <S.StreamingProcess>
                    <img src="/static/images/streaming.svg" height={`10px`} alt="Streaming"/>
                    {props.dataset.status.metadata.statusDisplay.label}
                </S.StreamingProcess>;
            default:
                return props.dataset.status.metadata.statusDisplay.label;
        }
    };

    const buildStatus = (): ReactNode => {
        // no status for vertical
        if (props.isVertical) {
            return;
        }

        return <S.Status>
            {buildDisplayStatus()}
            <S.AvatarGroup max={5}>
                {
                    props.dataset.status.metadata.sources.map(source =>
                        <Tooltip
                            key={source.name}
                            title={source.label}
                            placement="bottom"
                            disableInteractive
                            arrow
                        >
                            <Avatar
                                alt={source.label}
                                src={`/static/images/${source.name}.svg`}
                            />
                        </Tooltip>
                    )
                }
            </S.AvatarGroup>
        </S.Status>;
    };

    const className = isVertical ? 'vertical' : 'horizontal';
    return <S.Container className={className}>
        <S.InfoBody>
            <S.DatasetLabel className={className} onClick={props.onClickHeader}>
                <DatasetIcon isStreaming={props.dataset.status.metadata.isStreamable} size={20}/>
                {props.dataset.label}
            </S.DatasetLabel>
            {
                // TODO: for now hide popover for dataset v2 until more status information actually populated
                props.dataset.status.metadata.type === DatasetType.ARC && <S.DatasetInfoPopover
                    datasetLabel={props.dataset.label}
                    columnFields={props.dataset.allFields()}
                    status={props.dataset.status}
                    className={className}
                />
            }
        </S.InfoBody>
        {buildStatus()}
    </S.Container>;

};

const S = {

    Container: styled.div`
        &.horizontal {
            padding: 8px 16px 0;
        }

        &.vertical {
            background-color: ${Colors.headerGrey};
            padding: 18px 0 8px;
            writing-mode: tb;
            transform: rotate(180deg);
        }
    `,

    DatasetInfoPopover: styled(DatasetInfoPopover)`
        &.horizontal {
            margin-right: -8px;
        }
    `,

    InfoBody: styled.div`
        display: flex;
        justify-content: space-between;

        svg {
            font-size: 20px;
            fill: ${Colors.iconSecondary};
        }
    `,

    Status: styled.div`
        padding-top: 8px;
        display: flex;
        justify-content: space-between;
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
    `,

    AvatarGroup: styled(AvatarGroup)`
        display: flex;
        gap: 5px;

        .MuiAvatar-root {
            width: 18px;
            height: 18px;
            font-size: 10px;
        }
    `,

    DatasetLabel: styled.div`
        display: flex;
        gap: 4px;
        align-items: center;
        cursor: pointer;
        flex: 1;

        &.horizontal {
            padding: 8px 0;
        }

        &.vertical {
            padding: 0 8px 8px;
        }
    `,

    StreamingProcess: styled.div`
        display: flex;
        align-items: center;
        gap: 3px;

        svg {
            color: ${Colors.changeNegative};
        }
    `

};