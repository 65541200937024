import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import FormControl from "@mui/material/FormControl";
import {StepContinue} from "app/home/onboarding/StepContinue";
import {HyperArcUser} from "metadata/HyperArcUser";
import React, {FunctionComponent, useState} from "react";
import {ServiceProvider} from "services/ServiceProvider";
import {UserService} from "services/UserService";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import {ErrorResponse} from "services/ApiResponse";
import {Either} from "common/Either";

type Props = {
    onLoggedIn: (user: HyperArcUser) => void
    onEnd: () => void
}

export const UsernameStep: FunctionComponent<Props> = (props: Props) => {

    const [username, setUsername] = useState<string>();
    const [error, setError] = useState<string>();

    const onUsernameContinue = async () => {
        const response: Either<ErrorResponse, HyperArcUser> = await ServiceProvider.get(UserService)
            .createCurrentUser(username);
        response.match(
            user => {
                props.onLoggedIn(user);
                props.onEnd();
            },
            r => setError(r.fieldErrors().map(errors => errors.get("username")).getOr(r.prettyPrint()))
        );
    };

    return <Stack>
        <Box sx={{ mb: 6 }}>
            <Typography variant="h3" gutterBottom>Welcome! First things first...</Typography>
        </Box>
        <Typography variant="body1">Please choose a username:</Typography>
            <FormControl sx={{ mb: 6 }}>
                <TextField
                    size="small"
                    error={error !== undefined}
                    aria-describedby="username-helper-text"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">@</InputAdornment>
                    }}
                    onChange={(event) => setUsername(event.target.value)}
                    helperText={error}
                />
                <FormHelperText id="username-helper-text">HyperArc usernames are public and used to @ mention you</FormHelperText>
            </FormControl>
            <StepContinue canGoBack={false} canSkip={false} onContinue={onUsernameContinue}/>
        </Stack>;
    };
