import {Enum} from "common/Enum";

export class FilterSetType extends Enum {

    static LIST = new FilterSetType('list');

    private constructor(
        name: string
    ) {
        super(name);
    }
}

FilterSetType.finalize();
