import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {CreateColumnGroup} from "metadata/dashboard/changes/CreateColumnGroup";

/**
 * Create an interaction column group.
 *
 * @author zuyezheng
 */
export class DeleteColumnGroup implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly label: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(
            metadata.with({
                interactions: metadata.interactions.withoutColumnGroup(this.label)
            }),
            new CreateColumnGroup(
                this.label,
                metadata.interactions.getColumnGroup(this.label)
                    .map(g => g.columns)
                    .getOrElse(() => [])
            )
        );
    }

    describe(): string {
        return `Deleted interaction column group ${this.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}