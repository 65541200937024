import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import React, {FunctionComponent} from "react";
import Box from "@mui/material/Box";

type Props = {
    message: string
    onClose: () => void
}

/**
 * Dialog to display when a user has already signed up via one identity provider, say google oauth, but then signs
 * in via email/pw using the same email address as the google oauth login
 */
export const DuplicateEmailDialog: FunctionComponent<Props> = (props: Props) => {
    return <Dialog
        open={true}
        onClose={props.onClose}
        maxWidth="sm"
    >
        <Container sx={{ py:6 }}>
            <Stack spacing={ 2 } sx={{ px: 2 }}>
                <Typography variant="h5" color="text.primary" align="center" gutterBottom>
                    Email already in use
                </Typography>
                <Typography sx={{ pb: 6 }} variant="body2" color="text.secondary" align="center" component="p">
                    {props.message}
                </Typography>
                <Box alignSelf="center">
                    <Button variant="outlined" size="small" color="inherit" onClick={props.onClose}>Close</Button>
                </Box>
            </Stack>
        </Container>
    </Dialog>;
};