import styled from "@emotion/styled";
import {Colors, Durations, FontSizes} from "app/components/StyleVariables";
import {FQN} from "common/FQN";
import * as React from "react";
import {FunctionComponent, ReactNode, useEffect, useState} from "react";
import {MetadataService} from "services/MetadataService";
import {ServiceProvider} from "services/ServiceProvider";
import {AssetVersion, AssetVersions} from "metadata/AssetVersions";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import {ForkIcon} from "app/components/icons/Common";
import {Optional} from "common/Optional";
import Chip from "@mui/material/Chip";
import {AssetType} from "metadata/AssetType";
import Tooltip from "@mui/material/Tooltip";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";

dayjs.extend(localizedFormat);

interface Props {
    className?: string
    fqn: FQN
    // the selected version
    selected: number,
    // when a version is selected
    onSelect: (version: AssetVersion) => void
}

export const VersionsPanel: FunctionComponent<Props> = (props: Props) => {

    const [versions, setVersions] = useState<AssetVersions>(null);

    useEffect(() => {
        const controller = new AbortController();

        const listVersions = (fqn: FQN) => {
            const metadataService = ServiceProvider.get(MetadataService);
            switch (fqn.type) {
                case AssetType.ARCQL:
                    return metadataService.listArcQLVersions(fqn);
                case AssetType.DASHBOARD:
                    return metadataService.listDashboardVersions(fqn);
                case AssetType.FILTER_SET:
                    return metadataService.listFilterSetVersions(fqn);
                default:
                    throw new Error(`aseet ${fqn.type} does not support versioning`);
            }
        };

        if (props.fqn) {
            listVersions(props.fqn)
                .then(response => response.forEach(
                    versions => setVersions(versions)
                ));
        }
        return () => controller.abort();
    }, [props.fqn]);

    const buildTimestamp = (version: AssetVersion): ReactNode => {
        return <S.Timestamp color="text.secondary">
            <Tooltip
                title={version.createdOn.format('LLL')}
                enterDelay={Durations.tooltipPrimaryEnterDelay}
                enterNextDelay={Durations.tooltipPrimaryEnterNextDelay}
                arrow
            >
                <div>{version.createdOn.fromNow()}</div>
            </Tooltip>
        </S.Timestamp>;
    };

    const buildItem = (version: AssetVersion, previousVersion?: AssetVersion): ReactNode => {
        // variations if it's the selected version
        let className;
        let variant: 'outlined' | 'filled' = 'outlined';
        if (version.version === props.selected) {
            className = 'selected';
            variant = 'filled';
        }

        const separators = [];
        // use either a dot or fork icon depending on if there are forks
        separators.push(
            versions.forks.get(version.id) == null ?
                <S.TimelineDot key="dot" className={className} variant={variant}/> :
                <S.ForkDot key="dot" className={className}/>
        );
        // if it's not the first version, add a connector if contiguous which we know if there is no parent
        // if it's the first version, add a connector if there is a parent as we'll show the parent right before
        if (
            (version.version > 0 && version.parentId == null) ||
            (version.version === 0 && version.parentId != null)
        ) {
            separators.push(<S.TimelineConnector key="connector" className={className}/>);
        }

        // label starts with the version number
        let label = `v${version.version}`;
        // if the label has changed, include the asset label as well
        if (previousVersion == null || version.label !== previousVersion.label) {
            label += ': ' + version.label;
        }

        // figure out the versioning chips
        const chips: React.ReactNode[] = [];

        // has it been forked?
        Optional.of(versions.forks.get(version.id))
            .forEach(forks => chips.push(
                <S.Chip label={`${forks.length} ${forks.length > 1 ? 'forks' : 'fork'}`} size="small"/>
            ));
        // is it a fork?
        Optional.of(version.parentId)
            .forEach(parentId =>
                versions.versions.get(parentId)
                    .forEach(v => chips.push(<S.Chip label={`forked from v${v.version}`} size="small"/>))
                    .orForEach(() => chips.push(<S.Chip label="forked from external" size="small"/>))
            );

        return <S.TimelineItem key={version.version} onClick={() => props.onSelect(version)}>
            {buildTimestamp(version)}
            <TimelineSeparator>
                {separators}
            </TimelineSeparator>
            <S.Content isArchived={version.isArchived}>
                <S.Label>
                    {version.isArchived && <S.ArchivedChip color={"error"} label="archived" size="small"/>}
                    {version.isArchived && <br />}
                    <S.LabelText>{label}</S.LabelText>
                    {chips}
                </S.Label>
                <div>@{version.createdBy}</div>
                <S.Description>{version.description}</S.Description>
            </S.Content>
        </S.TimelineItem>;
    };

    const buildItems = (): ReactNode => {
        if (versions == null) {
            return;
        }

        const parent = Optional.of(versions.parent)
            .map(v => {
                    return <S.TimelineItem key={v.version}>
                        {buildTimestamp(v)}
                        <TimelineSeparator>
                            <S.ForkDot/>
                        </TimelineSeparator>
                        <S.Content isArchived={v.isArchived}>
                            <S.Label>
                                {v.isArchived && <S.ArchivedChip color={"error"} label="archived" size="small"/>}
                                {v.isArchived && <br />}
                                <S.LabelText> v{v.version}: {v.label} </S.LabelText>
                                <S.Chip label="external source" size="small"/>
                            </S.Label>
                            <div>@{v.createdBy}</div>
                            <S.Description>{v.description}</S.Description>
                        </S.Content>
                    </S.TimelineItem>;
                }
            )
            .array;

        return [
            ...versions.versions.map(
                (version: AssetVersion, versionI: number) => buildItem(
                    version, versionI > 0 ? versions.versions.at(versionI - 1) : null
                )
            ),
            ...parent
        ];
    };

    return <S.Container className={props.className}>
        <S.Timeline>
            {buildItems()}
        </S.Timeline>
    </S.Container>;

};

const S = {

    Container: styled.div`
        overflow: hidden auto;
    `,

    Timeline: styled(Timeline)`
        padding: 10px 0;
    `,

    TimelineItem: styled(TimelineItem)`
        cursor: pointer;
        padding-bottom: 10px;

        &:before {
            flex: 0;
            padding: 0;
        }
        
    `,

    Timestamp: styled(TimelineOppositeContent)`
        font-size: ${FontSizes.xSmall};
        flex: none;
        width: 60px;
    `,

    Content: styled(TimelineContent)<{isArchived: boolean }>`
        font-size: ${FontSizes.small};
        opacity: ${({ isArchived }) => (isArchived ? '0.6' : '1')};
    `,

    Label: styled.div`
        & > *:not(:last-child) {
            margin-right: 4px;
        }
    `,

    LabelText: styled.span`
        font-size: ${FontSizes.small};
        font-weight: 500;
    `,

    ForkDot: styled(ForkIcon)`
        margin: 7px 0;

        &.selected {
            path {
                fill: ${Colors.hyperarcYellow};
            }
        }
    `,

    TimelineDot: styled(TimelineDot)`
        margin: 11.5px 3px;

        &.selected {
            background-color: ${Colors.hyperarcYellow};
        }

    `,

    TimelineConnector: styled(TimelineConnector)`
        &.selected {
            background-color: ${Colors.hyperarcYellow};
        }
    `,

    Chip: styled(Chip)`
        background-color: ${Colors.hyperarcYellowChip};
        font-size: ${FontSizes.xSmall};
    `,

    ArchivedChip: styled(Chip)`
        font-size: ${FontSizes.xSmall};
    `,

    Description: styled.div`
        white-space: pre-line;
        word-break: break-word;
        overflow: hidden;
        -webkit-line-clamp: 4;
        max-height: 5rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    `

};