import {OrgMembershipRole} from "metadata/OrgMembershipRole";

export type OrgMembershipJson = {
    id: string,
    name: string,
    roles: string[]
}

/**
 * Represents a user's membership to an organization.
 */
export class OrgMembership {

    static fromJSON(json: OrgMembershipJson): OrgMembership {
        return new OrgMembership(
            json.id,
            json.name,
            json.roles.map(r => OrgMembershipRole.get(r))
        );

    }

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly roles: OrgMembershipRole[]
    ) {
    }

    includesAdmin(): boolean {
        return this.roles.includes(OrgMembershipRole.ADMIN);
    }
}