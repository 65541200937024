import * as React from "react";
import {FunctionComponent} from "react";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {MeasureField, MeasureOperator} from "metadata/query/MeasureField";
import {ColumnField} from "metadata/query/ArcQLField";
import {FieldSettingView} from "app/query/components/setting/FieldSettingView";

type Props = {
    // field being modified
    field: MeasureField,
    // field label to display
    fieldLabel: string,
    // dataset for field metadata
    dataset: ArcDataset,
    // if modifying an existing field vs new
    modifying: boolean,
    // callback when the field has changed
    onChange: (field: ColumnField) => void
    // on back of the setting view
    onBack: () => void
}

/**
 * Setting view for editing a measure field.
 */
export const MeasureFieldSettingView: FunctionComponent<Props> = (props: Props) => {

    const onChangeOperator = (operator: MeasureOperator) => {
        // Hacky solution to update the default label display on a measure field if a user
        // hasn't entered their own name yet when the operator gets changed
        // TODO ZZ move to QueryBuilderDelegate
        const newAs = props.field.as === props.field.operator.defaultLabel(props.fieldLabel)
            ? operator.defaultLabel(props.fieldLabel)
            : props.field.as;
        props.onChange(props.field.with({operator: operator, as: newAs}));
    };

    const supportedOperators = MeasureOperator.forDataSuperType(
        props.dataset.getDataSuperType(props.field.field)
    )
        .filter(op => props.dataset.features.aggregationOperators.has(op))
        .sort((a, b) => a.label.localeCompare(b.label));

    return <FieldSettingView
        fieldLabel={props.fieldLabel}
        fieldCategorization="Field"
        settingLabel="aggregation"
        modifying={props.modifying}
        field={props.field}
        items={supportedOperators}
        isSelected={item => props.field.operator === item}
        onChange={item => onChangeOperator(item)}
        onBack={props.onBack}
    />;

};