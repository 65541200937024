import OrchestratorModel from "metadata/hypergraph/request/OrchestratorModel";
import {LLMMessage} from "metadata/hypergraph/request/LLMMessage";

export class CompletionRequest {

    constructor(
        public readonly model: OrchestratorModel,
        public readonly messages: LLMMessage[]
    ) { }

}