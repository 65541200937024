import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcMessage, ArcMessageType} from "engine/ArcMessage";
import {ArcEvent} from "engine/ArcEvent";
import {Optional} from "common/Optional";
import {TypedEventFilter} from "engine/ArcEventFilter";
import { QueryReason } from "engine/actor/QueryResason";
import { ChangeSetType } from "metadata/ChangeSetType";

export class ResultMessage implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.RESULT;

    constructor(
        public readonly response: ArcQLResponse,
        // original arcql used for the query which will contain the visualization configuration
        public readonly arcql: ArcQL,
        // why the initial query was issued
        public readonly reason: QueryReason,
        // the type of change to the query that resulted in this execution
        public readonly changeSetType: ChangeSetType
    ) { }

}

export class ResultEventFilter extends TypedEventFilter<ResultMessage> {

    constructor(
        private readonly publisherId?: string
    ) {
        super(ResultMessage);
    }

    filter(event: ArcEvent): Optional<ResultMessage> {
        return super.filter(event)
            // see if we care about the publisher
            .filter(m => this.publisherId == null || this.publisherId === event.actor.id);
    }

}