import {TriggerCondition, TriggerConditionType} from "metadata/trend/TriggerCondition";
import {Enum} from "common/Enum";

export type ThresholdConditionJson = {
    type: string,
    operator: string,
    threshold: number
}

type Props = {
    operator: ThresholdOperator,
    threshold: number
}

/**
 * Threshold trigger condition for alerts.
 */
export class ThresholdCondition implements TriggerCondition {

    static fromJSON(json: ThresholdConditionJson): ThresholdCondition {
        return new ThresholdCondition(
            ThresholdOperator.get(json.operator),
            json.threshold
        );
    }

    static default(): ThresholdCondition {
        return new ThresholdCondition(ThresholdOperator.GREATER, 0);
    }

    constructor(
        public readonly operator: ThresholdOperator,
        public readonly threshold: number
    ) {
    }

    detail(field: string): string {
        return `${field} ${this.operator.label} ${this.threshold}`;
    }

    with(props: Partial<Props>): ThresholdCondition {
        return new ThresholdCondition(
            props.operator ?? this.operator,
            props.threshold ?? this.threshold
        );
    }

    toJSON(): ThresholdConditionJson {
        return {
            type: this.type.name,
            operator: this.operator.name,
            threshold: this.threshold
        };
    }

    get type(): TriggerConditionType {
        return TriggerConditionType.THRESHOLD;
    }
}

export class ThresholdOperator extends Enum {

    static GREATER = new this('greater', '>');
    static GREATER_OR_EQUAL = new this('greater_or_equal', '≥');
    static LESS = new this('less', '<');
    static LESS_OR_EQUAL = new this('less_or_equal', '≤');

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}

ThresholdOperator.finalize();