import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {NextBestMeasures} from "metadata/hypergraph/nodes/NextBestMeasures";
import {MeasureField, MeasureOperator} from "metadata/query/MeasureField";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {Optional} from "common/Optional";
import {ArcQLField} from "metadata/query/ArcQLField";
import {ExpressionField} from "metadata/query/ExpressionField";
import {AddField} from "metadata/query/changes/AddField";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {HyperGraphNodeHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {
    HyperGraphNodeFactory,
    HyperGraphNodeProps,
    HyperGraphNodeSerialized
} from "metadata/hypergraph/HyperGraphTypes";
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {FactDetail} from 'metadata/hypergraph/content/FactDetail';

/**
 * @author zuyezheng
 */
export class NextBestMeasuresNode extends HyperGraphNode<NextBestMeasures> {

    private readonly measures: NextBestMeasure[];

    constructor(
        public readonly request: NextBestMeasures,
        private readonly response: NextBestMeasuresJson,
        id?: string,
        createdOn?: number,
        modifiedOn?: number,
        nodeEmbedding?: number[],
        rating?: NodeRating
    ) {
        super(id, createdOn, modifiedOn, nodeEmbedding, undefined, rating);

        this.measures = [
            ...response.aggregations.map(m =>
                new NextBestAggregation(
                    m.field, m.operator, m.why, m.nullHypothesis, m.alternativeHypothesis
                )
            ),
            ...response.expressions.map(m =>
                new NextBestExpression(
                    m.expression, m.why, m.nullHypothesis, m.alternativeHypothesis
                )
            )
        ];
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.NEXT_BEST_MEASURES;
    }

    label(hyperGraph: HyperGraph): string {
        return 'Next Best Measures';
    }

    get description(): string {
        return '';
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        return this.measures.map(m => new NodeStructuredSection(
            m.change,
            [
                new FactDetail('Why', m.why),
                new FactDetail('Null Hypothesis', m.nullHypothesis),
                new FactDetail('Alternative Hypothesis', m.alternativeHypothesis)
            ]
        ));
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return this.measures[nth];
    }

    getVariant(graph: HyperGraph, dataset: ArcDataset): ArcQL {
        const measureChanges = this.measures.flatMap(
            measure => measure.buildField(dataset)
                .map(field => new AddField(field))
                .nullable
        );

        return measureChanges.reduce(
            (query, change) => change.apply(query).left,
            this.getQuery(graph)
        );
    }

    with(props: Partial<HyperGraphNodeProps>): NextBestMeasuresNode {
        return new NextBestMeasuresNode(
            this.request,
            this.response,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn,
            props.nodeEmbedding ?? this.nodeEmbedding,
            props.rating ?? this.rating
        );
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            response: this.response
        };
    }

    static factoryOfFactory(
        request: NextBestMeasures, id: string
    ): HyperGraphNodeFactory<NextBestMeasuresJson, NextBestMeasuresNode> {
        return (response: NextBestMeasuresJson) => new NextBestMeasuresNode(request, response, id);
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): NextBestMeasuresNode {
        return new NextBestMeasuresNode(
            NextBestMeasures.fromJSON(json.request),
            json.response,
            json.id,
            json.createdOn,
            json.modifiedOn,
            json.nodeEmbedding,
            NodeRating.withDefault(json.rating)
        );
    }

}

export interface NextBestMeasure extends HyperGraphNodeHypothesis {

    /**
     * Build the field to add, validated by the given dataset.
     */
    buildField(dataset: ArcDataset): Optional<ArcQLField>;

}

export class NextBestAggregation implements NextBestMeasure {

    public readonly operator: MeasureOperator;

    constructor(
        public readonly field: string,
        operator: string,
        public readonly why: string,
        public readonly nullHypothesis: string,
        public readonly alternativeHypothesis: string
    ) {
        this.operator = MeasureOperator.get(operator);
    }

    buildField(dataset: ArcDataset): Optional<ArcQLField> {
        // make sure the field exists
        return dataset.getPossible(this.field)
            .map(field => {
                // can only distinct of a dimension, LLMs get confused
                const operator = field.analyticsType === AnalyticsType.DIMENSION ?
                    MeasureOperator.DISTINCT :
                    this.operator;

                return new MeasureField(this.field, operator, operator.defaultLabel(this.field));
            });
    }

    get change(): string {
        return `Add aggregate ${this.operator.name}(${this.field}).`;
    }

}

export class NextBestExpression implements NextBestMeasure {

    constructor(
        public readonly expression: string,
        public readonly why: string,
        public readonly nullHypothesis: string,
        public readonly alternativeHypothesis: string
    ) {}

    buildField(dataset: ArcDataset): Optional<ArcQLField> {
        return Optional.some(new ExpressionField(this.expression, this.expression));
    }

    get change(): string {
        return `Add expression "${this.expression}".`;
    }

}

export type NextBestMeasuresJson = {

    aggregations: {
        field: string,
        operator: string,
        why: string,
        nullHypothesis: string,
        alternativeHypothesis: string
    }[]

    expressions: {
        expression: string,
        why: string,
        nullHypothesis: string,
        alternativeHypothesis: string
    }[]

}