import Button from "@mui/material/Button";
import * as React from "react";
import {ReactNode} from "react";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import styled, {StyledComponent} from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

/**
 * Defines the actions available on a toolbar and how they should be presented.
 */
export class ToolbarAction {

    constructor(
        public readonly id: string,
        public readonly label: string,
        public readonly disabled: boolean = false,
        private readonly isPrimary: boolean = false,
        public customized: CustomProps = {}
    ) {
    }

    equals(other: ToolbarAction): boolean {
        return this.id === other.id;
    }

    comp(onAction: (action: ToolbarAction) => void, isToggled: boolean): ReactNode {
        const Comp: StyledComponent<any> = this.customized.component ?? S.Button;

        return <Comp
            key={this.id}
            variant={this.isPrimary ? 'contained' : 'outlined'}
            className={isToggled ? 'toggled' : null}
            onClick={() => onAction(this)}
            disabled={this.disabled}
        >
            {this.customized.content ?? this.label}
        </Comp>;
    }

}

/**
 * Customize how toolbar actions are rendered.
 */
export type CustomProps = {

    // Custom content in a standard toolbar button.
    content?: ReactNode
    // A custom component to use instead of the standard button.
    component?: StyledComponent<any>
    // Custom color for the item, only used when displayed in a menu.
    color?: string

}

export const StandardActions = {

    UNDO: (disabled?: boolean) => new ToolbarAction(
        'undo', 'Undo', disabled || false, false, { content: <UndoIcon/> }
    ),
    REDO: (disabled = false) => new ToolbarAction(
        'redo', 'Redo', disabled || false, false, { content: <RedoIcon/> }
    ),

    DEV: new ToolbarAction('dev', 'Dev Mode'),
    REFRESH: new ToolbarAction('refresh', 'Refresh', false, false, { content: <RefreshOutlinedIcon/> }),

    VERSIONS: new ToolbarAction('versions', 'Versions'),
    SHARE: new ToolbarAction('share', 'Share'),
    SAVE: new ToolbarAction('save', 'Save', false, true),
    SAVE_AS: new ToolbarAction('saveAs', 'Save As', false, true),
    FORK: new ToolbarAction('fork', 'Fork', false, true),
    // TODO ZZ use more "semantic" colors, archive is not an error
    ARCHIVE: new ToolbarAction('archive', 'Archive', false, false, { color: Colors.formError }),
    CONFIG: new ToolbarAction('config', 'Config', false, false)

};


export const S = {

    Button: styled(Button)`

        &.toggled {
            background-color: ${Colors.hyperarcYellowToggled};
        }
        
    `

};
