import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {ReplaceReason} from "metadata/ReplaceReason";

/**
 * Replace the entire dashboard.
 *
 * @author zuyezheng
 */
export class DashboardReplace implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly dashboard: ArcDashboard,
        public readonly reason: ReplaceReason
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(this.dashboard, new DashboardReplace(metadata, this.reason));
    }

    describe(): string {
        return `Replaced dashboard because of ${this.reason.name}`;
    }

    get changeType(): ChangeType {
        return ChangeType.REPLACE;
    }
}