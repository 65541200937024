import {Either} from "common/Either";
import {FQN} from "common/FQN";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcQL} from "metadata/query/ArcQL";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {ErrorResponse} from "services/ApiResponse";
import {AssetVersions} from "metadata/AssetVersions";
import {Folders} from "metadata/project/Folders";
import {Asset} from "metadata/Asset";
import {FoldersSearchResponse} from "metadata/project/FoldersSearchResponse";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";
import {References} from "metadata/References";
import {PersonaListParams} from "metadata/PersonaListParams";
import {FolderResult} from "metadata/project/FolderResult";
import FolderCreateRequest from "metadata/project/FolderCreateRequest";
import FolderPatchRequest from "metadata/project/FolderPatchRequest";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import {AccountsSearchResponse} from "metadata/account/AccountsSearchResponse";
import {Story} from "metadata/asset/story/Story";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {HyperGraphSearchParams} from "metadata/search/HyperGraphSearchParams";
import {GlobalAnswerWithNodes} from "metadata/search/GlobalAnswerWithNodes";

/**
 * Service that fetches metadata.
 */
export abstract class MetadataService {

    /**
     * List all folders a user has access to in the given account.
     */
    abstract listFolders(accountName: string, signal?: AbortSignal): Promise<Either<ErrorResponse, Folders>>;

    abstract fetchFolder(accountName: string, folderName: string, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>>;

    abstract createFolder(accountName: string, folderRequest: FolderCreateRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>>;

    abstract patchFolder(accountName: string, folderName: string, folderRequest: FolderPatchRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>>;

    abstract fetchArcQL(fqn: FQN, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcQL>>;

    abstract fetchArcQLVersion(fqn: FQN, version: number, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcQL>>;

    abstract listArcQLVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>>;

    abstract saveArcQL(fqn: FQN, arcQL: ArcQL): Promise<Either<ErrorResponse, ArcQL>>;

    abstract archiveArcQL(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQL>>;

    abstract fetchDashboard(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>>;

    abstract fetchDashboardVersion(fqn: FQN, version: number, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>>;

    abstract listDashboardVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>>;

    abstract saveDashboard(fqn: FQN, dashboard: ArcDashboard): Promise<Either<ErrorResponse, ArcDashboard>>;

    abstract archiveDashboard(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>>;

    abstract fetchFilterSet(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>>;

    abstract fetchFilterSetVersion(fqn: FQN, version: number, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>>;

    abstract listFilterSetVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>>;

    abstract saveFilterSet(fqn: FQN, filterSet: ArcFilterSet): Promise<Either<ErrorResponse, ArcFilterSet>>;

    abstract archiveFilterSet(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>>;

    abstract fetchReferences(fqns: Set<FQN>, signal?: AbortSignal): Promise<Either<ErrorResponse, References>>;

    abstract fetchTask(fqn: FQN, signal?: AbortSignal): Promise<Object>;

    /**
     * List stories for a given asset.
     */
    abstract listStories(
        fqn: FQN,
        // for stories with scenes, load scene image data
        loadImages: boolean,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, Story[]>>;

    /**
     * Create a scene for a given story.
     */
    abstract createScene(story: Story, scene: StoryScene, signal?: AbortSignal): Promise<Either<ErrorResponse, StoryScene>>;

    /**
     * List all scenes for a given story fqn.
     */
    abstract listScenes(fqn: FQN, loadImages: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, StoryScene[]>>;

    /**
     * Fetch with delegation by FQN asset type.
     */
    abstract fetchAsset(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>>;

    abstract saveAsset(fqn: FQN, asset: Asset, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>>;

    abstract archiveAsset(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>>;

    /**
     * Search.
     */
    abstract assetSearch(params: AssetSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>>;

    abstract folderSearch(params: FolderSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, FoldersSearchResponse>>;

    abstract accountSearch(params: AccountSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AccountsSearchResponse>>;

    abstract personaSearch(datasetFqn: FQN, params: PersonaListParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>>;

    abstract hyperGraphSearch(params: HyperGraphSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>>;

    /**
     * Check if the query string is a question to be asked of the hypergraph
     */
    abstract hyperGraphSearchTest(query: string, signal?: AbortSignal): Promise<Either<ErrorResponse, boolean>>;

    /**
     * Answer a question with the hypergraphs of all visible queries
     */
    abstract hyperGraphGlobalAnswer(query: string, signal?: AbortSignal): Promise<Either<ErrorResponse, GlobalAnswerWithNodes>>;
}