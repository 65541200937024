import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import * as React from "react";
import {Enum} from "common/Enum";
import {TabPath} from "app/TabPath";
import {Optional} from "common/Optional";

export class HomeSection extends Enum {

    static FOR_YOU = new this(
        'foryou',
        'For You',
        () => <WidgetsOutlinedIcon/>
    );
    static BROWSE = new this(
        'browse',
        'Browse All',
        () => <ViewStreamOutlinedIcon/>
    );

    static fromTabPath(tabPath: TabPath): Optional<HomeSection> {
        if (tabPath.parts.length < 2) {
            return Optional.none();
        }
        return Optional.some(HomeSection.get(tabPath.parts[1]));
    }

    constructor(
        name: string,
        public readonly label: string,
        public readonly icon: () => React.ReactNode
    ) {
        super(name);
    }

}

HomeSection.finalize();