import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL, ArcQLProps} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";

export class ArcQLFilterExpressionChange implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly expression: string,
        public readonly isAggregate: boolean
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const metadataKey = ArcQL.filtersPropertyKey(this.isAggregate);
        const newMetadata: Partial<ArcQLProps> = {};
        newMetadata[metadataKey] = metadata[metadataKey].withExpression(this.expression);
        return new Tuple(
            metadata.with(newMetadata),
            new ArcQLFilterExpressionChange(metadata.filters.expression, this.isAggregate)
        );
    }

    describe(): string {
        if (!this.expression) {
            return `${this.isAggregate ? 'Aggregate filter' : 'Filter'} expression reset to default`;
        }
        return `${this.isAggregate ? 'Aggregate filter' : 'Filter'} expression changed to '${this.expression}'`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}