import * as React from "react";
import {ChangeEvent, FunctionComponent, MouseEvent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {MeasureFilterEditorProps} from "app/query/filters/MeasureFilterEditorProps";
import {ServiceProvider} from "services/ServiceProvider";
import {QueryService} from "services/QueryService";
import {Tuple} from "common/Tuple";
import {Optional} from "common/Optional";
import {MeasureFilterHelper, OPERATORS} from "app/query/filters/MeasureFilterHelper";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {FilterEditorS} from "app/query/filters/FilterEditor";
import {DateFilterGrain} from "app/query/filters/DateFilterGrain";
import {DateFilterType} from "app/query/filters/DateFilterType";
import {RelativeDate, RelativeDateUnit} from "app/query/filters/RelativeDate";
import TextField from "@mui/material/TextField";
import {FontSizes} from "app/components/StyleVariables";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import { ToggleS } from "app/components/input/ToggleS";


/**
 * Editor for relative and absolute date filters.
 *
 * @author zuyezheng
 */
export const DateFilterEditor: FunctionComponent<MeasureFilterEditorProps> = (props: MeasureFilterEditorProps) => {

    const [type, setType] = useState<DateFilterType>(DateFilterType.fromFilter(props.filter));
    const [grain, setGrain] = useState<DateFilterGrain>(
        DateFilterGrain.fromFilter(props.filter).getOrElse(() => DateFilterGrain.DATE)
    );
    const [operator, setOperator] = useState<FilterOperator>(props.filter.operator);
    // overall range of the faceted data
    const [range, setRange] = useState<Optional<Tuple<number, number>>>(Optional.none());
    const [values, setValues] = useState<string[]>([null, null]);

    useEffect(() => {
        setValues(props.filter.isAll ? [null, null] : props.filter.values as string[]);

        // figure out the bounds for the filter
        const controller = new AbortController();
        ServiceProvider.get(QueryService)
            .rangeValues(
                props.arcqlBundle.dataset,
                MeasureFilterHelper.rangeValuesRequest(props.arcqlBundle, props.filter, props.ordinal),
                controller.signal
            )
            .then((results: Either<ErrorResponse, ArcQLResponse>) =>
                results.optional().forEach(
                    r => setRange(Optional.some(MeasureFilterHelper.range(r)))
                )
            );
        return () => controller.abort();
    }, [props.filter]);

    const onChangeType = (event: ChangeEvent<HTMLInputElement>) => {
        const newType = DateFilterType.get<DateFilterType>(event.target.value);

        // types haven't changed, don't rock the boat
        if (newType === type) {
            return;
        }

        // if the type changed, reset values to defaults for that type
        const newOperator = FilterOperator.GREATER_THAN_EQUAL;
        const newValues = [newType.defaultValue()];

        setType(newType);
        setOperator(newOperator);
        setValues(newValues);

        if (type === DateFilterType.RELATIVE) {
            // only relative dates supported
            setGrain(DateFilterGrain.DATE);
        }

        props.onChange(newOperator, newValues);
    };

    const onChangeGrain = (event: ChangeEvent<HTMLInputElement>) => {
        const newGrain = DateFilterGrain.get<DateFilterGrain>(event.target.value);
        setGrain(newGrain);

        // date and datetime grains have different formats, so need to do some conversion
        const newValue = values.map(v => newGrain.epochToArcQL(grain.arcQLToEpoch(v)));
        setValues(newValue);
    };

    const onChangeOperator = (_: MouseEvent<HTMLElement>, operatorName: string) => {
        if (operatorName == null) {
            // no change
            return;
        }

        const newOperator: FilterOperator = FilterOperator.get(operatorName);
        const newValues = type === DateFilterType.ABSOLUTE ?
            MeasureFilterHelper.changeBounds(
                operator,
                newOperator,
                values,
                range.map(r => Tuple.of(grain.epochToArcQL(r.left), grain.epochToArcQL(r.right)))
            ):
            MeasureFilterHelper.changeBounds(
                operator,
                newOperator,
                values,
                Optional.none()
            );

        setOperator(newOperator);
        setValues(newValues);
        props.onChange(newOperator, newValues);
    };

    const onChange = (newValue: string, valueI: number) => {
        const newValues = values.slice();
        newValues[valueI] = newValue;
        setValues(newValues);
        props.onChange(operator, newValues);
    };

    const valuesEditor = (): React.ReactNode[] => {
        if (operator === FilterOperator.IN) {
            return [<>
                Looks like you've ended up with an in operator from a drill in or external link, to change the date bounds, pick an operator.
            </>];
        }

        return values.map((v, i) => {
            // value is initialized as null so skip them
            if (v == null) {
                return null;
            }

            const editorProps: ValueEditorProps = {
                value: v,
                valueI: i,
                grain: grain,
                onChange: v => onChange(v, i)
            };

            const editor = type === DateFilterType.ABSOLUTE ?
                <AbsoluteEditor {...editorProps} /> :
                <RelativeEditor {...editorProps} />;

            return <FilterEditorS.Bound key={i}>
                {editor}
                <FilterEditorS.HelpText>
                    {i === 0 ? 'Start' : 'End'}
                </FilterEditorS.HelpText>
            </FilterEditorS.Bound>;
        });
    };

    return <FilterEditorS.Container>
        <FilterEditorS.HelpText>Filter by:</FilterEditorS.HelpText>
        <FilterEditorS.Row>
            <FilterEditorS.Select
                size='small'
                margin="dense"
                value={type.name}
                onChange={onChangeType}
            >{
                DateFilterType.enums<DateFilterType>().map(type =>
                    <FilterEditorS.MenuItem key={type.name} value={type.name}>
                        {type.label}
                    </FilterEditorS.MenuItem>
                )
            }</FilterEditorS.Select>
            <FilterEditorS.Select
                size='small'
                margin="dense"
                value={grain.name}
                onChange={onChangeGrain}
            >{
                DateFilterGrain.supported(type).map(grain =>
                    <FilterEditorS.MenuItem key={grain.name} value={grain.name}>
                        {grain.label}
                    </FilterEditorS.MenuItem>
                )
            }</FilterEditorS.Select>
        </FilterEditorS.Row>
        <FilterEditorS.HelpText>Use rows when the value is:</FilterEditorS.HelpText>
        <ToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            value={operator.name}
            onChange={onChangeOperator}
        >{
            OPERATORS.map(operator =>
                <ToggleS.ToggleButton value={operator.name} key={operator.name}>
                    {operator.label}
                </ToggleS.ToggleButton>
            )
        }</ToggleButtonGroup>
        <FilterEditorS.BoundsContainer>
            {valuesEditor()}
        </FilterEditorS.BoundsContainer>
        <FilterEditorS.HelpText>{
            range.map(r => `Data range between ${grain.epochToDisplay(r.left)} and ${grain.epochToDisplay(r.right)} GMT.`)
                .getOr('Checking data range...')
        }
        </FilterEditorS.HelpText>
    </FilterEditorS.Container>;

};

type ValueEditorProps = {
    value: string
    valueI: number
    grain: DateFilterGrain
    onChange: (value: string) => void
}

const AbsoluteEditor: FunctionComponent<ValueEditorProps> = (props: ValueEditorProps) => {

    const [value, setValue] = useState<string>(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const onBlur = () => {
        props.onChange(props.grain.inputToArcQL(value));
    };

    return <FilterEditorS.BoundField
        type={props.grain.inputType}
        margin="dense"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        InputLabelProps={{
            shrink: true
        }}
    />;

};

const RelativeEditor: FunctionComponent<ValueEditorProps> = (props: ValueEditorProps) => {

    const [value, setValue] = useState<RelativeDate>(RelativeDate.parse(props.value).get());
    // track duration during edits which could be strings that are not valid ints
    const [duration, setDuration] = useState<string>(RelativeDate.parse(props.value).get().duration.toString());

    useEffect(() => {
        const newValue = RelativeDate.parse(props.value).get();
        setValue(newValue);
        setDuration(newValue.duration.toString());
    }, [props.value]);

    const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDuration(event.target.value);
    };

    const onBlurDuration = () => {
        let newDuration = parseInt(duration);
        if (newDuration <= 0) {
            newDuration = 1;
        }
        const newValue = value.withDuration(newDuration);
        setValue(newValue);
        props.onChange(newValue.toString());
    };

    const onChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = value.withUnit(RelativeDateUnit.get(event.target.value));
        setValue(newValue);
        props.onChange(newValue.toString());
    };

    return <RelativeEditorS.Cell className={props.valueI === 0 ? 'start' : 'end'}>
        <RelativeEditorS.Duration
            value={duration}
            variant="outlined"
            size="small"
            onChange={onChangeDuration}
            onBlur={onBlurDuration}
        />
        <FilterEditorS.Select
            size='small'
            margin="dense"
            value={value.unit.name}
            onChange={onChangeUnit}
        >{
            RelativeDateUnit.enums<RelativeDateUnit>().map(type =>
                <FilterEditorS.MenuItem key={type.name} value={type.name}>
                    {type.label(value.duration)}
                </FilterEditorS.MenuItem>
            )
        }</FilterEditorS.Select>
        <div>ago</div>
    </RelativeEditorS.Cell>;

};

const RelativeEditorS = {

    Cell: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        font-size: ${FontSizes.small};
        
        &.end {
            justify-content: right;
        }
        
        > div {
            margin-left: 5px;
            
            &:first-child {
                margin-left: 0;
            }
        }
    `,

    Duration: styled(TextField)`
        width: 54px;
        margin: 0;
        align-items: center;

        input {
            font-size: ${FontSizes.small};
            padding: 9px 14px;
            text-align: center;
        }
    `

};
