import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import * as React from "react";

type Props = {
    isStreaming: boolean,
    size: number
}

export const DatasetIcon = (props: Props) => {

    return props.isStreaming ?
        <img src="/static/images/streamingdataset.svg" height={`${props.size}px`} alt="Streaming Dataset" /> :
        <DatasetOutlinedIcon/>;

};
