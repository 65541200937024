import {Optional} from "common/Optional";

export class StringUtils {

    /**
     * Turn a possible sentence formatted string to not a sentence.
     */
    static unsentencify(text: string): string {
        const lowered = text.charAt(0).toLowerCase() + text.slice(1);
        return lowered.replace(/[,.!?;:]$/, '');
    }

    /**
     * Remove period at end of text if there is one.
     */
    static removePeriod(text: string): string {
        return text.replace(/\.$/, '');
    }

    static toClassName(classNames: ClassNameFlags): string {
        return Object.entries(classNames)
            .flatMap(([className, on]) => on ? [className]: [])
            .join(' ');
    }

    /**
     * Format bytes to a human readable string.
     */
    static formatBytes(bytes: number, decimals = 2): string {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };


    /**
     * Check if string is present.
     */
    static isAbsent = (s: string): boolean => {
        return Optional.string(s).map(_ => false).getOr(true);
    };

    /**
     * Normalized string comparison where null, undefined, and empty are considered equal.
     */
    static equalsNormalized(a: string, b: string): boolean {
        return (a ?? '') === (b ?? '');
    }
}

export type ClassNameFlags = {[key: string]: boolean};