import React, {FunctionComponent, ReactNode} from "react";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";

interface Props {
    title: string
    children: ReactNode
}

/**
 * Settings section content.
 */
export const SettingsTabSection: FunctionComponent<Props> = (props: Props) => {
    return (
        <S.Section>
            <S.TitleContainer>
                <S.Title>{props.title}</S.Title>
            </S.TitleContainer>
            {props.children}
        </S.Section>
    );
};

class S {

    static readonly Section = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0px 0px 0px 1px ${Colors.borderGrey};
        border-radius: 4px;
        padding: 16px 0;
    `;

    static readonly TitleContainer = styled.div`
        width: 100%;
        border-bottom: 1px solid ${Colors.borderGrey};
        padding-bottom: 16px;
    `;

    static readonly Title = styled.div`
        color: ${Colors.textPrimary};
        font-weight: bold;
        margin-left: 16px;
    `;
}