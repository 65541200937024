import * as React from "react";
import {FunctionComponent} from "react";
import TextField from "@mui/material/TextField";
import {InlineEditorS as S} from "app/dashboard/widgets/InlineEditorS";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {ImageConfigPart} from "metadata/dashboard/widgets/config/image/ImageConfigPart";

interface EditorProps extends ConfigEditorProps<ImageConfigPart> {

}

/**
 * Inline editor for image widgets on dashboards.
 */
export const ImageConfigEditor: FunctionComponent<EditorProps> = (props: EditorProps) => {

    const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onConfigPropertyChange(
            props.config.with({source: event.target.value})
        );
    };

    return <S.Container>
        <S.Row>
            <S.Config style={{width: "300px"}}>
                <S.FormControl size="small">
                    <TextField
                        label="Source"
                        variant="outlined"
                        size="small"
                        value={props.config.getValue('source')}
                        onChange={onChangeUrl}
                    />
                </S.FormControl>
            </S.Config>
        </S.Row>
    </S.Container>;

};