import {ArcMetadataChange} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {BoundedStack} from "common/BoundedStack";

/**
 * Holder of metadata, applier of changes.
 */
export class ArcMetadata<T> {

    public readonly maxVersions: number;

    private _metadata: T;
    private readonly undoStack: BoundedStack<ArcMetadataChange<T>[]>;
    private readonly redoStack: BoundedStack<ArcMetadataChange<T>[]>;
    private readonly changes: BoundedStack<string[]>;

    constructor(metadata: T, maxVersions: number) {
        this.maxVersions = maxVersions;
        this._metadata = metadata;

        this.undoStack = new BoundedStack(this.maxVersions);
        this.redoStack = new BoundedStack(this.maxVersions);
        this.changes = new BoundedStack(this.maxVersions);
    }

    get metadata(): T {
        return this._metadata;
    }

    /**
     * Apply changes and push to the undo stack, return this.
     */
    apply(changes: ArcMetadataChange<T>[]): ArcMetadata<T> {
        const results = this._apply(changes);

        this.undoStack.push(results.left);
        this.changes.push(results.right);

        // applying new changes invalidates any redos
        this.redoStack.clear();
        return this;
    }

    undo(): boolean {
        return this.undoStack.pop().map((changes: ArcMetadataChange<T>[]) => {
            this.redoStack.push(this._apply(changes).left);
            this.changes.pop();

            return true;
        }).getOr(false);
    }

    hasUndo(): boolean {
        return !this.undoStack.isEmpty();
    }

    /**
     * Return the list of undo changes from newest to oldest.
     */
    get undoChanges(): ArcMetadataChange<T>[][] {
        return this.undoStack.values.reverse();
    }

    /**
     * Return change descriptions with the undo operations from newest to oldest.
     */
    get changesZipped(): Tuple<string[], ArcMetadataChange<T>[]>[] {
        const undoChanges = this.undoStack.values;
        return this.changes.values.map(
            (change: string[], changeI: number) => Tuple.of(change, undoChanges[changeI])
        ).reverse();
    }

    redo(): boolean {
        return this.redoStack.pop().map((changes: ArcMetadataChange<T>[]) => {
            const results = this._apply(changes);
            this.undoStack.push(results.left);
            this.changes.push(results.right);

            return true;
        }).getOr(false);
    }

    hasRedo(): boolean {
        return !this.redoStack.isEmpty();
    }

    describe(): string[] {
        return this.changes.values.flatMap(cs => cs);
    }

    /**
     * Apply one or more changes, returning the inverse of changes applied.
     */
    private _apply(changes: ArcMetadataChange<T>[]): Tuple<ArcMetadataChange<T>[], string[]> {
        const undoChanges: ArcMetadataChange<T>[] = [];
        const descriptions: string[] = [];

        // apply all changes to get the new metadata and stack of undos to go back
        this._metadata = changes.reduce(
            (prevMetadata: T, change: ArcMetadataChange<T>) => {
                // apply the change
                const applied = change.apply(prevMetadata);

                undoChanges.unshift(applied.right);
                descriptions.unshift(change.describe(prevMetadata));

                return applied.left;
            },
            this._metadata
        );

        return Tuple.of(undoChanges, descriptions);
    }

}