import * as React from "react";
import {ForwardedRef, FunctionComponent} from "react";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {Optional} from "common/Optional";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {ArcVisualization} from "app/visualizations/ArcVisualization";
import {ActorStatus} from "engine/actor/ActorStatus";
import {S} from "app/query/QueryBuilderCanvasS";
import {Selectable} from "engine/actor/Selectable";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {VisualizationPanel} from "app/query/panels/VisualizationPanel";


type Props = {
    ref?: ForwardedRef<HTMLElement>
    delegate: QueryBuilderDelegate
    selectable: Selectable
    arcqlBundle: ArcQLBundle
    queryResult: Optional<ArcQLResponse>
    status: ActorStatus
    drillClauses: Optional<FilterClause[]>
}

export const QueryBuilderCanvas: FunctionComponent<Props> = React.forwardRef<HTMLDivElement, Props>((
    props: Props, visualizationRef: ForwardedRef<HTMLDivElement>
) => {

    return <S.Canvas>
        <VisualizationPanel
            arcqlBundle={props.arcqlBundle}
            delegate={props.delegate}
            drillClauses={props.drillClauses}
        />
        <S.Right>
            <S.Visualization ref={visualizationRef}>
                <ArcVisualization
                    queryResponse={props.queryResult}
                    config={props.arcqlBundle.arcql.visualizations.default}
                    isBusy={props.status.isBusy()}
                    selectable={props.selectable}
                />
            </S.Visualization>
        </S.Right>
    </S.Canvas>;

});
