import {InputConnectionDetails} from "metadata/connections/InputConnectionDetails";
import {ConnectionType} from "metadata/connections/ConnectionType";

type BigQueryConnectionDetailsJson = {
    type: string
    client_id: string,
    client_email: string,
    private_key: string,
    private_key_id: string,
    project_id: string,
    token_uri: string,
    quota_project_id: string
}

/**
 * Input connection details for BigQuery.
 */
export class BigQueryInputConnectionDetails implements InputConnectionDetails {
    constructor(
        public readonly clientId: string,
        public readonly clientEmail: string,
        public readonly privateKey: string,
        public readonly privateKeyId: string,
        public readonly projectId: string,
        public readonly tokenUri: string,
        public readonly quotaProjectId: string
    ) {
    }

    get type(): ConnectionType {
        return ConnectionType.BIG_QUERY;
    }

    isValid(): boolean {
        return !!this.clientId && !!this.clientEmail && !!this.privateKey && !!this.privateKeyId && !!this.projectId && !!this.tokenUri;
    }

    static fromJSON(json: BigQueryConnectionDetailsJson): BigQueryInputConnectionDetails {
        return new BigQueryInputConnectionDetails(
            json.client_id,
            json.client_email,
            json.private_key,
            json.private_key_id,
            json.project_id,
            json.token_uri,
            json.quota_project_id
        );
    }

    toJSON(): BigQueryConnectionDetailsJson {
        return {
            type: ConnectionType.BIG_QUERY.name,
            client_id: this.clientId,
            client_email: this.clientEmail,
            private_key: this.privateKey,
            private_key_id: this.privateKeyId,
            project_id: this.projectId,
            token_uri: this.tokenUri,
            quota_project_id: this.quotaProjectId
        };
    }


}