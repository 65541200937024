import styled from "@emotion/styled";
import MenuItem from "@mui/material/MenuItem";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {Select, TextField, ListItemIcon} from "@mui/material";

export class S {

    // Unlike TextField, Select does not include form + label baked in
    static readonly Select = styled(Select)`
        font-size: ${FontSizes.medium};
        .MuiSelect-select {
            display: flex;
            align-items: center;
        }
    `;

    // TextField with select variant enabled includes form + label baked in
    static readonly TextField = styled(TextField)`
        font-size: ${FontSizes.medium};
        
        .MuiSelect-select {
            display: flex;
            align-items: center;
        }
    `;

    static readonly SelectItem = styled(MenuItem)`
        display: flex;
        align-items: center;
        font-size: ${FontSizes.medium};
    `;

    static readonly ListItemIcon = styled(ListItemIcon)`
        &.MuiListItemIcon-root {
            min-width: 0;
            padding-left: 2px;
            padding-right: 8px;
        }
    `;

    static readonly ExpandedSection = styled.div`
        padding-left: 16px;
    `;

    static readonly Toggle = styled.div`
        width: 100%;
        display: flex;
    `;

    static readonly ToggleLabel = styled.div`
        flex: 1;
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
        display: flex;
        flex-direction: column;
        justify-content: center;
    `;

    static readonly ToggleSwitch = styled.div`

    `;

}
