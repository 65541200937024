import styled from "@emotion/styled";
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import {GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import {Optional} from "common/Optional";
import React, {useEffect, useState} from "react";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {Colors} from "app/components/StyleVariables";
import dayjs from "dayjs";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import {AccountResult} from "metadata/account/AccountResult";
import {AccountsSearchResponse} from "metadata/account/AccountsSearchResponse";
import Tooltip from "@mui/material/Tooltip";
import {AccountType} from "metadata/account/AccountType";


interface Props {
    searchParams: AccountSearchParams
    onSelect: (selected: AccountResult) => void
    onSearch: (params: AccountSearchParams, controller: AbortController) => Promise<Either<ErrorResponse, AccountsSearchResponse>>
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    TYPE: 'type',
    NAME: 'name',
    UPDATED_ON: 'updatedOn',
    RESULT: 'result'
};

/**
 * Query and display accounts results given the search params.
 *
 * @author fli
 */
export const AccountSearchResultsTable = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchResponse, setSearchResponse] = useState<Optional<AccountsSearchResponse>>(Optional.none());
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        setIsLoading(true);

        const controller = new AbortController();
        (async () => {
            const searchResponse = await props.onSearch(props.searchParams.with({page: page}), controller)
                .then(r => r.rightOrThrow());
            setSearchResponse(Optional.some(searchResponse));
            setIsLoading(false);
        })();

        return () => controller.abort();
    }, [page, props.searchParams]);

    const onRowClick = (params: GridRowParams) => {
        props.onSelect(params.row.result as AccountResult);
    };


    const columns: GridColDef[] = [{
        'field': FIELD_IDENTIFIERS.TYPE,
        'headerName': '',
        'width': 30,
        'renderCell': (params: GridRenderCellParams<AccountType>) =>
            <Tooltip title={params.value.label} placement={'top'}>
                <S.Icon>
                    <params.value.icon/>
                </S.Icon>
            </Tooltip>
    }, {
        'field': FIELD_IDENTIFIERS.NAME,
        'headerName': 'NAME',
        'flex': 3
    }, {
        'field': FIELD_IDENTIFIERS.UPDATED_ON,
        'headerClassName': 'HideRightSeparator',
        'headerName': 'Last Updated',
        'flex': 1
    }];

    return <div>
        <S.ResultsTable
            loading={isLoading}
            columns={columns}
            rows={
                searchResponse
                    .map(resp => resp.results.map(result => ({
                        [FIELD_IDENTIFIERS.ID]: result.id,
                        [FIELD_IDENTIFIERS.TYPE]: result.type,
                        [FIELD_IDENTIFIERS.NAME]: `@${result.name}`,
                        [FIELD_IDENTIFIERS.UPDATED_ON]: dayjs(result.updatedOn).format("MMM D, YYYY"),
                        [FIELD_IDENTIFIERS.RESULT]: result
                    })))
                    .getOr([])
            }
            onRowClick={onRowClick}

            pagination
            paginationMode="server"
            page={page}
            pageSize={props.searchParams.size}
            rowsPerPageOptions={[props.searchParams.size]}
            rowCount={searchResponse.map(r => r.total).getOr(0)}
            onPageChange={(newPage) => setPage(newPage)}
            autoHeight={true}
            // reduce the default row height by a smidge, default is 52
            rowHeight={40}
            density="standard"
            disableSelectionOnClick
            disableColumnMenu
        />
    </div>;

};

class S {
    static ResultsTable = styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-row {
            max-height: 30px;
        }

        .HideRightSeparator {
            & > .MuiDataGrid-columnSeparator {
                display: none
            }
        }

        .MuiDataGrid-row:hover .ActionMenu {
            visibility: visible;
        }

        .SelectedActionMenu {
            visibility: visible;
        }
    `;

    static Icon = styled.div`
        display: flex;

        svg {
            width: 20px;
            height: 22px;
            color: ${Colors.iconPrimary};
        }
    `;
}
