import {Enum} from "common/Enum";
import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {BarChartDefinition} from "app/visualizations/definition/BarChartDefinition";
import {TimelineChartDefinition} from "app/visualizations/definition/TimelineChartDefinition";
import {LineChartDefinition} from "app/visualizations/definition/LineChartDefinition";
import {EmptyDefinition} from "app/visualizations/definition/EmptyDefinition";
import {WaterfallChartDefinition} from "app/visualizations/definition/WaterfallChartDefinition";
import {DonutChartDefinition} from "app/visualizations/definition/DonutChartDefinition";
import {TreemapChartDefinition} from "app/visualizations/definition/TreemapChartDefinition";
import {TableDefinition} from "app/visualizations/definition/TableDefinition";
import {ScatterChartDefinition} from "app/visualizations/definition/ScatterChartDefinition";
import {AreaChartDefinition} from "app/visualizations/definition/AreaChartDefinition";
import {NodeAndLinkChartDefinition} from "app/visualizations/definition/NodeAndLinkChartDefinition";
import {ScorecardDefinition} from "app/visualizations/definition/ScorecardDefinition";
import {ToggleDefinition} from "app/visualizations/definition/ToggleDefinition";
import {ComboChartDefinition} from "app/visualizations/definition/ComboChartDefinition";
import {CandlestickChartDefinition} from "app/visualizations/definition/CandlestickChartDefinition";
import {ComponentType} from "react";
import BarIcon from "app/components/icons/viztype/BarIcon";
import LineIcon from "app/components/icons/viztype/LineIcon";
import AreaIcon from "app/components/icons/viztype/AreaIcon";
import ComboIcon from "app/components/icons/viztype/ComboIcon";
import ScatterIcon from "app/components/icons/viztype/ScatterIcon";
import BubbleIcon from "app/components/icons/viztype/BubbleIcon";
import DonutIcon from "app/components/icons/viztype/DonutIcon";
import TimelineIcon from "app/components/icons/viztype/TimelineIcon";
import CandlestickIcon from "app/components/icons/viztype/CandlestickIcon";
import TreemapIcon from "app/components/icons/viztype/TreemapIcon";
import ChordIcon from "app/components/icons/viztype/ChordIcon";
import SankeyIcon from "app/components/icons/viztype/SankeyIcon";
import WaterfallIcon from "app/components/icons/viztype/WaterfallIcon";
import NetworkIcon from "app/components/icons/viztype/NetworkIcon";
import TableIcon from "app/components/icons/viztype/TableIcon";
import ScorecardIcon from "app/components/icons/viztype/ScorecardIcon";
import ToggleIcon from "app/components/icons/viztype/ToggleIcon";

export class VizSuperType extends Enum {

    static FUSION = new this('fusion');
    static D3 = new this('d3');
    static TABLE = new this('table');
    static SCORECARD = new this('scorecard');
    static TOGGLE = new this('toggle');

}
VizSuperType.finalize();

/**
 * Enumeration of visualization types to their configs.
 */
export class VizType extends Enum {

    static BAR = new this(
        'bar',
        'Bar',
        VizSuperType.FUSION,
        new BarChartDefinition(),
        BarIcon
    );
    static LINE = new this(
        'line',
        'Line',
        VizSuperType.FUSION,
        new LineChartDefinition(),
        LineIcon
    );
    static AREA = new this(
        'area',
        'Area',
        VizSuperType.FUSION,
        new AreaChartDefinition(),
        AreaIcon
    );
    static COMBO = new this(
        'combo',
        'Combo',
        VizSuperType.FUSION,
        new ComboChartDefinition(),
        ComboIcon
    );
    static SCATTER = new this(
        'scatter',
        'Scatter',
        VizSuperType.FUSION,
        new ScatterChartDefinition(),
        ScatterIcon
    );
    static BUBBLE = new this(
        'bubble',
        'Bubble',
        VizSuperType.FUSION,
        new ScatterChartDefinition(),
        BubbleIcon
    );
    static DONUT = new this(
        'donut',
        'Donut',
        VizSuperType.FUSION,
        new DonutChartDefinition(),
        DonutIcon
    );
    static TIMELINE = new this(
        'timeline',
        'Timeline',
        VizSuperType.FUSION,
        new TimelineChartDefinition(),
        TimelineIcon
    );
    static CANDLESTICK = new this(
        'candlestick',
        'Candlestick',
        VizSuperType.FUSION,
        new CandlestickChartDefinition(),
        CandlestickIcon
    );
    static TREEMAP = new this(
        'treemap',
        'Treemap',
        VizSuperType.FUSION,
        new TreemapChartDefinition(),
        TreemapIcon
    );
    static CHORD = new this(
        'chord',
        'Chord',
        VizSuperType.FUSION,
        new NodeAndLinkChartDefinition(),
        ChordIcon
    );
    static SANKEY = new this(
        'sankey',
        'Sankey',
        VizSuperType.FUSION,
        new NodeAndLinkChartDefinition(),
        SankeyIcon
    );
    static WATERFALL = new this(
        'waterfall',
        'Waterfall',
        VizSuperType.FUSION,
        new WaterfallChartDefinition(),
        WaterfallIcon
    );

    static NETWORK = new this(
        'network',
        'Network',
        VizSuperType.D3,
        new EmptyDefinition(),
        NetworkIcon
    );

    static TABLE = new this(
        'table',
        'Table',
        VizSuperType.TABLE,
        new TableDefinition(),
        TableIcon
    );

    static SCORECARD = new this(
        'scorecard',
        'Scorecard',
        VizSuperType.SCORECARD,
        new ScorecardDefinition(),
        ScorecardIcon
    );

    static TOGGLE = new this(
        'toggle',
        'Toggle',
        VizSuperType.TOGGLE,
        new ToggleDefinition(),
        ToggleIcon
    );

    static sorted(): VizType[] {
        return VizType.enums<VizType>().sort((a, b) => a.label.localeCompare(b.label));
    }

    constructor(
        name: string,
        public readonly label: string,
        public readonly superType: VizSuperType,
        public readonly definition: VizDefinition,
        public readonly icon: ComponentType
    ) {
        super(name);
    }

}
VizType.finalize();