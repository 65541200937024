import {JsonObject} from "common/CommonTypes";

export interface RemoteItem {
    name: string
}

export type RemoteDatabaseJson = {
    name: string
}

/**
 * Top-level database in the remote data source
 */
export class RemoteDatabase implements RemoteItem {
    constructor(public name: string) {
    }

    static fromJSON(json: RemoteDatabaseJson): RemoteDatabase {
        return new RemoteDatabase(json.name);
    }
}

export type RemoteSchemaJson = {
    databaseName: string,
    name: string
}

/**
 * A schema in a database in the remote data source
 */
export class RemoteSchema implements RemoteItem {
    constructor(
        public database: string,
        public name: string
    ) {
    }

    static fromJSON(json: RemoteSchemaJson): RemoteSchema {
        return new RemoteSchema(json.databaseName, json.name);
    }
}

export type RemoteTableJson = {
    databaseName: string,
    schemaName: string,
    name: string
}

/**
 * A table in a schema in a database in the remote data source
 */
export class RemoteTable implements RemoteItem {
    constructor(
        public databaseName: string,
        public schemaName: string,
        public name: string
    ) {
    }

    static fromJSON(json: RemoteTableJson): RemoteTable {
        return new RemoteTable(json.databaseName, json.schemaName, json.name);
    }
}

export class RemoteListing<T extends RemoteItem> {
    constructor(
        public items: T[]
    ) {
    }

    static fromJSON<T extends RemoteItem>(json: JsonObject, itemFactory: (json: JsonObject) => T): RemoteListing<T> {
        return new RemoteListing(json['content'].map((itemJson: JsonObject) => itemFactory(itemJson)));
    }
}