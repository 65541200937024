import {Either} from "common/Either";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {EthBlock, EthBlockJson} from "semantic-abi";
import {Web3Service} from "services/Web3Service";
import {ExpiryCache} from "common/ExpiryCache";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {EvmChain} from "semantic-abi";

/**
 * Service to interact with Web3 specific APIs.
 */
export class ApiWeb3Service implements Web3Service {

    private readonly queryCache: ExpiryCache<
        string,
        Promise<Either<ErrorResponse, EthBlock>>
    >;

    constructor(
        cacheSize: number = 50,
        // invalidate query cache at 10 minutes by default
        defaultCacheTimeout: number = 10 * 60 * 1000,
    ) {
        this.queryCache = new ExpiryCache(cacheSize, defaultCacheTimeout);
    }

    /**
     * Fetch receipts and traces as a single EthBlock response.
     */
    receiptsAndTraces(
        chain: EvmChain,
        transactions: string[],
        signal?: AbortSignal,
        // when to expire the query from the cache
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, EthBlock>> {
        const cacheKey = 'receipts-and-traces/transactions/' + transactions.join(',');
        return this.queryCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService).post(
                '/api/v1/web3/receipts-and-traces/transactions', {
                    'chain': 'ethereum',
                    'transactions': transactions
                }, signal
            )
                .then(r => ApiResponse.custom(
                    r,
                    // query success
                    json => new EthBlock(chain, json as EthBlockJson),
                    // query error
                    (json: any, r: Response) => {
                        // don't cache query errors since they might be transient
                        this.queryCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                )),
            cacheTimeout
        );
    }

}