import {ArcActor} from "engine/actor/ArcActor";
import {ArcMessage} from "engine/ArcMessage";

/**
 * Wraps a message with its sender.
 */
export class ArcEvent {

    constructor(
        // sender
        public readonly actor: ArcActor,
        public readonly message: ArcMessage
    ) { }

}