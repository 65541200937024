import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {TrendS} from "app/dashboard/components/TrendS";
import {AssetIcon, IconStyling, StandardIconSize} from "app/components/icons/asset/AssetIcon";
import {AssetType} from "metadata/AssetType";
import {Colors, FontSizes} from "app/components/StyleVariables";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from '@mui/icons-material/Close';
import {TrendListSelect} from "app/dashboard/components/TrendListSelect";
import {ArcTrendListItem} from "metadata/trend/ArcTrendListItem";
import {TrendAlertsTable} from "app/dashboard/components/TrendAlertsTable";
import Tooltip from "@mui/material/Tooltip";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ServiceProvider} from "services/ServiceProvider";
import {TrendsService} from "services/TrendsService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Optional} from "common/Optional";
import {ArcVisualization} from "app/visualizations/ArcVisualization";
import {ArcQLVisualizations, VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {VizType} from "metadata/query/VizType";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {TimelineChartDefinition} from "app/visualizations/definition/TimelineChartDefinition";
import {VizEnumProperty} from "app/visualizations/definition/property/VizEnumProperty";
import {Tuple} from "common/Tuple";
import {Alert} from "metadata/trend/Alert";

export interface Props {
    trend: ArcTrend
    dataset: ArcDataset
    // all trends for this widget
    widgetTrends: ArcTrendListItem[]
    onSelectTrend: (trend: ArcTrendListItem) => void
    onSaveAlert: (savedAlert: Alert) => void;
    onClose: () => void
}

const TIMELINE_PLOT_TYPES: Tuple<string, string>[] = (TimelineChartDefinition.PROPERTIES.find(p => p.name === 'plotType') as VizEnumProperty).values;

/**
 * Single trend view for (dashboard, widget) in Trends Panel.
 */
export const SingleTrendView = (props: Props) => {

    const [trendResults, setTrendResults] = React.useState<Optional<ArcQLResponse>>(Optional.none());
    const [chartConfig, setChartConfig] = React.useState<VisualizationConfig>(
        new VisualizationConfig(ArcQLVisualizations.DEFAULT_KEY, 'Default', VizType.TIMELINE, new Map())
    );
    const [anchorChartConfigEl, setAnchorChartConfigEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        ServiceProvider.get(TrendsService).getTrendResults(props.trend, '30d', props.dataset)
            .then((r) => r.match(
                (arcQLResponse) => {
                    setTrendResults(Optional.of(arcQLResponse));
                    setChartConfig(new VisualizationConfig(ArcQLVisualizations.DEFAULT_KEY, 'Default', VizType.TIMELINE, new Map()));
                },
                error => {
                    ServiceProvider.get(NotificationsService).publish(
                        'SingleTrendView', NotificationSeverity.ERROR, `Failed to load trend results: ${error.prettyPrint()}`
                    );
                }
            ));
    }, [props.trend]);

    const handleChartConfigClose = () => {
        setAnchorChartConfigEl(null);
    };

    const handlePlotTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChartConfig(
            chartConfig.withConfigChange('plotType', (event.target as HTMLInputElement).value)
        );
    };

    const plotType = chartConfig.config.get('plotType') || 'column';
    const plotTypeLabel = TIMELINE_PLOT_TYPES.find(t => t.left === plotType)?.right || 'Column';

    const EmptyChart = () => {
        return <S.NoTrendChart>
            <span> {`Currently capturing daily snapshots for '${props.trend.label}'.`} </span>
            <span>Check back tomorrow to view trend data!</span>
        </S.NoTrendChart>;
    };

    const renderTrendChart = () => {
        return trendResults.map(resp =>
            resp.result.length === 0 ?
                EmptyChart()
                :
                <S.Chart>
                    <S.ChartToolbar>
                        <S.Button variant="outlined" startIcon={<CalendarTodayIcon/>} disabled={true}>
                            Last 30 days
                        </S.Button>
                        <S.Button
                            variant="outlined"
                            startIcon={<StackedLineChartIcon/>}
                            onClick={(event) => setAnchorChartConfigEl(event.currentTarget)}
                        >
                            {plotTypeLabel}
                        </S.Button>
                    </S.ChartToolbar>
                    <ArcVisualization
                        key={props.trend.id}
                        queryResponse={Optional.of(resp)}
                        isBusy={false}
                        config={chartConfig}
                    />
                    <Popover
                        open={Boolean(anchorChartConfigEl)}
                        anchorEl={anchorChartConfigEl}
                        onClose={handleChartConfigClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <S.ChartConfigForm.Container>
                            <S.ChartConfigForm.Form>
                                <S.ChartConfigForm.Title>Timeline Plot Type</S.ChartConfigForm.Title>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="plotType"
                                        name="plotType"
                                        value={plotType}
                                        onChange={handlePlotTypeChange}
                                    >
                                        {
                                            TIMELINE_PLOT_TYPES.map((option) => (
                                                <FormControlLabel
                                                    key={option.left}
                                                    value={option.left}
                                                    control={<Radio/>}
                                                    label={option.right}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </S.ChartConfigForm.Form>
                        </S.ChartConfigForm.Container>
                    </Popover>
                </S.Chart>
        ).getOrElse(() => EmptyChart());
    };

    return (
        <TrendS.Container>
            <TrendS.Header>
                <TrendS.TrendIcon/>
                <TrendS.HeaderLabel>Trend Analysis For </TrendS.HeaderLabel>
                <TrendS.SubHeaderArcQL.Container>
                    <AssetIcon assetType={AssetType.ARCQL} iconStyling={IconStyling.OUTLINED_COLORED}
                               size={StandardIconSize.SMALL}/>
                    <TrendS.SubHeaderArcQL.Label> {props.trend.arcQL.label} </TrendS.SubHeaderArcQL.Label>
                </TrendS.SubHeaderArcQL.Container>
                <TrendS.ButtonGroup>
                    <S.TrendListSelect
                        trends={props.widgetTrends}
                        currentTrend={props.trend}
                        onSelectTrend={props.onSelectTrend}
                    />
                    <TrendS.HeaderButton size="small" onClick={props.onClose}>
                        <CloseIcon/>
                    </TrendS.HeaderButton>
                </TrendS.ButtonGroup>
            </TrendS.Header>
            <S.Section>
                <S.SectionHeader>
                    <S.TitleColumnContainer>
                        <h2>Trend</h2>
                        <h1>{props.trend.label}</h1>
                    </S.TitleColumnContainer>
                    <S.InfoColumnContainer>
                        <TrendS.BadgeIcon badgeContent={props.trend.filters.clauses.length} color="primary">
                            <Tooltip
                                title={
                                    <TrendS.Bullets>
                                        {props.trend.filters.clauses.map((filter, index) => (
                                            <li key={index}>
                                                {`${filter.fieldsLabel(props.dataset)} ${filter.description(true)}`}
                                            </li>
                                        ))}
                                    </TrendS.Bullets>
                                }
                            >
                                <FilterListIcon/>
                            </Tooltip>
                        </TrendS.BadgeIcon>
                    </S.InfoColumnContainer>
                </S.SectionHeader>
            </S.Section>
            {renderTrendChart()}
            <S.Section>
                <S.SectionHeader>
                    <S.TitleColumnContainer>
                        <h2>Trend Notifications</h2>
                    </S.TitleColumnContainer>
                </S.SectionHeader>
                <S.TrendAlertsTable
                    trend={props.trend}
                    onSaveAlert={props.onSaveAlert}
                />
            </S.Section>
        </TrendS.Container>
    );
};

class S {

    static readonly TrendListSelect = styled(TrendListSelect)`
        width: 250px;
        height: 30px;
        margin-right: 5px;
        background: white;
        color: ${Colors.textPrimary};
        font-size: ${FontSizes.small};
    `;

    static readonly Section = styled.div`
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${Colors.borderGrey};
        padding: 8px 16px;
    `;

    static readonly SectionHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `;

    static readonly TitleColumnContainer = styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        h2 {
            margin: 0;
            color: ${Colors.textSecondary};
            font-size: ${FontSizes.xSmall};
            line-height: ${FontSizes.xSmall};
            text-transform: uppercase;
        }

        h1 {
            margin: 5px 0;
            font-size: ${FontSizes.medium};
        }
    `;

    static readonly InfoColumnContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;
        gap: 8px;
    `;

    static readonly Chart = styled.div`
        display: flex;
        flex-direction: column;
        height: 50%;
    `;

    static readonly ChartToolbar = styled.div`
        display: flex;
        align-items: center;
        padding: 5px 16px;
        gap: 10px;
        height: 40px;
        background: white;
        border-top: 1px solid ${Colors.borderGrey};
        border-bottom: 1px solid ${Colors.borderGrey};
    `;

    static readonly Button = styled(Button)`
        text-transform: none;
        border: none;
    `;

    static ChartConfigForm = class {
        static readonly Container = styled.div`
            display: flex;
            justify-content: space-between;
            width: 300px;
            padding: 10px;
        `;

        static readonly Title = styled.div`
            font-size: ${FontSizes.xSmall};
            color: ${Colors.textTertiary};
            text-transform: uppercase;
        `;

        static readonly Form = styled.div`
            display: flex;
            flex-direction: column;
            width: 100%;
        `;
    };

    static readonly NoTrendChart = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        background: #F8F8FA;
        gap: 5px;
        border-top: 1px solid ${Colors.borderGrey};
        font-size: ${FontSizes.small};
        color: ${Colors.textTertiary};
    `;

    static readonly TrendAlertsTable = styled(TrendAlertsTable)`
        margin-top: 5px;
    `;
}