import * as React from "react";
import {FunctionComponent, useState} from "react";
import TextField from "@mui/material/TextField";
import {Colors, FontSizes} from "app/components/StyleVariables";
import Popover, {PopoverOrigin} from "@mui/material/Popover";
import styled from "@emotion/styled";
import AceEditor from "react-ace";
import {CommonS} from "app/components/CommonS";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {Optional} from "common/Optional";

const EXPRESSION_REGEX = /^(and|or|not|[\d\s|!()])*$/;

export type Props = {
    el: Element
    anchorOrigin: PopoverOrigin

    // initial or current expression (which may be null)
    expression: string
    onSave: (filterExpression: string) => void
    onClose: () => void
}

/**
 * Filter expression editor.
 *
 * @author zuyezheng
 */
export const FilterExpressionEditor: FunctionComponent<Props> = (props: Props) => {

    const [expression, setExpression] = useState<string>(
        props.expression || ''
    );
    const [uncommittedWarning, setUncommittedWarning] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<Optional<string>>(Optional.none());

    const onSave = () => {
        if (!EXPRESSION_REGEX.test(expression.trim().toLowerCase())) {
            setValidationError(Optional.some(
                'Filter expression should only be an ordinal of the filters or "(), and, or, not/!".'
            ));
            return;
        }

        props.onSave(expression.trim());
    };

    // check if there are uncommitted changes we should confirm before closing the dialog
    const checkUncommitted = () => {
        if ((props.expression || '') !== expression) {
            setUncommittedWarning(true);
            return;
        }

        props.onClose();
    };

    const closeWarnings = () => {
        setUncommittedWarning(false);
        setValidationError(Optional.none());
    };

    const content = <>
        {
            uncommittedWarning && <Dialog open={true} onClose={closeWarnings}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to close without adding the expression?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CommonS.Button onClick={props.onClose}>Close Without Saving</CommonS.Button>
                    <CommonS.Button onClick={closeWarnings}>Back</CommonS.Button>
                </DialogActions>
            </Dialog>
        }
        {
            validationError.isPresent && <Dialog
                open={true}
                onClose={closeWarnings}
            >
                <DialogContent>
                    <DialogContentText>{validationError.get()}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CommonS.Button onClick={closeWarnings}>Back</CommonS.Button>
                </DialogActions>
            </Dialog>
        }
        <S.Content>
            <S.HelpText>Edit expression:</S.HelpText>
            <S.Editor>
                <AceEditor
                    mode="sql"
                    theme="github"
                    height="100%"
                    width="100%"
                    value={expression}
                    onChange={setExpression}
                    editorProps={{
                        $blockScrolling: true
                    }}
                    setOptions={{
                        printMargin: 120,
                        wrap: true,
                        useWorker: false
                    }}
                />
            </S.Editor>
            <S.ControlRow>
                <S.ControlRight>
                    <CommonS.Button onClick={onSave}>Save</CommonS.Button>
                </S.ControlRight>
            </S.ControlRow>
        </S.Content>
    </>;

    return <Popover
        open={true}
        anchorEl={props.el}
        onClose={checkUncommitted}
        anchorOrigin={props.anchorOrigin}
    >
        {content}
    </Popover>;

};

class S {

    static readonly Content = styled.div`
        margin: 18px;
        width: 425px;
    `;

    static readonly Input = styled(TextField)`
        margin: 0;

        input {
            font-size: ${FontSizes.small};
            padding: 6px 8px;
        }
    `;

    static readonly Editor = styled.div`
        width: 100%;
        height: 200px;
    `;

    static readonly HelpText = styled.div`
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.small};
        padding: 6px 0;
    `;

    static readonly ControlRow = styled.div`
        padding-top: 4px;
        display: flex;
    `;

    static readonly ControlLeft = styled.div`
        display: flex;
        flex: 1;
        justify-content: left;
    `;

    static readonly ControlRight = styled.div`
        display: flex;
        justify-content: right;
    `;
}