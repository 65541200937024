import * as React from "react";
import {FunctionComponent, useState} from "react";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {Optional} from "common/Optional";
import {ArcQLField, ColumnField} from "metadata/query/ArcQLField";
import {FieldEditor} from "app/query/components/FieldEditor";
import {TypedMenu} from "app/components/TypedMenu";
import {Direction} from "metadata/query/ArcQLOrderBy";
import {ExpressionField} from "metadata/query/ExpressionField";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Pill} from "app/query/components/Pill";
import {Colors} from "app/components/StyleVariables";
import {PanelS} from "app/query/panels/PanelS";
import styled from "@emotion/styled";
import {FieldAction} from "app/query/panels/FieldAction";
import {DragAndDropData} from "common/DragAndDropData";
import {MeasureField} from "metadata/query/MeasureField";
import {ExpressionEditor} from "app/query/components/ExpressionEditor";
import ExpressionFxIcon from "app/components/icons/ExpressionFxIcon";
import {ArcQLBuilder} from "app/query/ArcQLBuilder";
import {S as CanvasS} from "app/query/QueryBuilderCanvasS";


type Props = {
    arcqlBundle: ArcQLBundle
    delegate: QueryBuilderDelegate
    onDragStart: (event: React.DragEvent) => void
    onDragOver: (event: React.DragEvent) => void
    onDrop: (event: React.DragEvent) => void
}

/**
 * Panel for fields for a query.
 *
 * @author zuyezheng
 */
export const FieldsPanel: FunctionComponent<Props> = (props: Props) => {

    const [selected, setSelected] = useState<Optional<SelectedField>>(Optional.none());
    const [selectedSuffix, setSelectedSuffix] = useState<Optional<SelectedField>>(Optional.none());
    const [selectedFieldForRename, setSelectedFieldForRename] = useState<Optional<ColumnField>>(Optional.none());
    const [addTarget, setAddTarget] = useState<Optional<Element>>(Optional.none());

    const onAsChange = (field: ArcQLField, as: string): boolean => {
        return props.delegate.modifyField(field.with({as: as}), field.as)
            .map(warning => {
                ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, warning
                );
                return false;
            })
            .getOrElse(
                () => {
                    setSelectedFieldForRename(Optional.none());
                    return true;
                });
    };

    const onSelect = (event: React.MouseEvent, field: ArcQLField, ordinal: number) => {
        setSelected(Optional.some({
            // if event.currentTarget is null, use event.target as Element
            el: event.currentTarget ?? event.target as Element,
            field: field as ColumnField,
            ordinal: ordinal,
            isLast: ordinal === props.arcqlBundle.arcql.groupings.size - 1
        }));
    };

    const onChange = (field: ArcQLField) => {
        selected.map(
            selected => props.delegate.modifyField(field, selected.field.as)
                .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                    'queryBuilder', NotificationSeverity.WARNING, warning
                ))
        );
        setSelected(Optional.none());
    };

    const onClickSuffix = (event: React.MouseEvent, field: ArcQLField, ordinal: number) => {
        setSelectedSuffix(Optional.some({
            el: event.currentTarget,
            field: field as ColumnField,
            ordinal: ordinal,
            isLast: ordinal === props.arcqlBundle.arcql.groupings.size - 1
        }));
    };

    const isActionSelected = (action: FieldAction): boolean => {
        switch (action) {
            case FieldAction.CUMULATIVE:
                return selectedSuffix.flatMap(s => Optional.ofType(s.field, MeasureField))
                    .map(f => f.cumulative)
                    .getOr(false);
            case FieldAction.ORDER_DESC:
                return selectedSuffix
                    .flatMap(selected => props.arcqlBundle.arcql.orderBys.get(selected.field.as))
                    .map(orderBy => orderBy.direction === Direction.DESC)
                    .getOr(false);
            case FieldAction.ORDER_ASC:
                return selectedSuffix
                    .flatMap(selected => props.arcqlBundle.arcql.orderBys.get(selected.field.as))
                    .map(orderBy => orderBy.direction === Direction.ASC)
                    .getOr(false);
            default:
                return false;
        }
    };

    const onClickAction = (action: FieldAction) => {
        selectedSuffix.forEach(selected => {
            switch (action) {
                case FieldAction.ORDER_DESC:
                    props.delegate.orderByField(selected.field.as, Direction.DESC);
                    return;
                case FieldAction.ORDER_ASC:
                    props.delegate.orderByField(selected.field.as, Direction.ASC);
                    return;
                case FieldAction.ADD_FILTER:
                    props.delegate.startAggregateFilter(selected.field.as)
                        .forEach(warning => ServiceProvider.get(NotificationsService).publish(
                            'fieldsPanel', NotificationSeverity.WARNING, warning
                        ));
                    return;
                case FieldAction.CUMULATIVE:
                    const measureField = (selected.field as MeasureField);
                    props.delegate.modifyField(
                        measureField.with({
                            'cumulative': !measureField.cumulative
                        }),
                        selected.field.as
                    );
                    return;
                case FieldAction.MOVE_UP:
                    props.delegate.moveField(selected.field.as, selected.ordinal - 1);
                    return;
                case FieldAction.MOVE_DOWN:
                    props.delegate.moveField(selected.field.as, selected.ordinal + 1);
                    return;
                case FieldAction.RENAME:
                    setSelectedFieldForRename(Optional.of(selected.field));
                    return;
                case FieldAction.REMOVE:
                    props.delegate.deleteField(selected.field.as);
                    return;
            }
        });

        setSelectedSuffix(Optional.none());
    };

    const onCloseActions = () => {
        setSelectedSuffix(Optional.none());
    };

    const onAdd = (event: React.MouseEvent) => {
        setAddTarget(Optional.some(event.currentTarget));
    };

    const onCloseAdd = () => {
        setAddTarget(Optional.none());
    };

    const onDropExistingField = (event: React.DragEvent, ordinal: number) => {
        const dndData = DragAndDropData.fromEvent<QueryDnDSource>(event, QueryDnDSource);
        dndData.isOf(QueryDnDSource.METRIC)
            .flatMap(as => {
                props.delegate.moveField(as, ordinal);
                event.stopPropagation();
            });
    };

    const onCloseSelected = () => {
        setSelected(Optional.none());
    };

    const buildContent = () => {
        return <>
            <PanelS.Fields>
                {Array.from(props.arcqlBundle.arcql.fields.fields).map((field: ArcQLField, ordinal: number) =>
                    <S.FieldsPill
                        key={field.as}
                        label={field.as}
                        isLabelEditable={true}
                        enterLabelEditMode={selectedFieldForRename.map(f => f.as === field.as).getOr(false)}
                        onLabelChange={(label) => onAsChange(field, label)}
                        tooltip={field.tooltip}
                        prefix={field instanceof ExpressionField ? <ExpressionFxIcon/> : field.prefix}
                        onClickPrefix={event => onSelect(event, field, ordinal)}
                        onClickLabel={event => onSelect(event, field, ordinal)}
                        onClickSuffix={event => onClickSuffix(event, field, ordinal)}
                        onDragStart={props.onDragStart}
                        dataFieldId={QueryDnDSource.METRIC.of(field.as).serialize}
                        onDrop={e => onDropExistingField(e, ordinal)}
                    />
                )
                }</PanelS.Fields>
            <S.Add onClick={onAdd}>
                <AddOutlinedIcon/>
            </S.Add>
            {
                selected.map(selected =>
                    Optional.ofType(selected.field, ExpressionField).map(ef =>
                        <ExpressionEditor
                            el={selected.el}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            arcQLBundle={props.arcqlBundle}
                            field={ef}
                            onChange={onChange}
                            onClose={onCloseSelected}
                        />).getOrElse(
                        () => <FieldEditor
                            el={selected.el}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            arcQLBundle={props.arcqlBundle}
                            field={Optional.of(selected.field)}
                            onChange={onChange}
                            onClose={onCloseSelected}
                        />
                    )
                ).nullable
            }
            {
                selectedSuffix.map(selected =>
                    <TypedMenu
                        el={selected.el}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        items={[FieldAction.filtered(
                            selected.field,
                            selected.ordinal === 0,
                            selected.isLast
                        )]}
                        itemId={item => item.name}
                        itemLabel={FieldAction.label}
                        isSelected={isActionSelected}
                        onSelect={onClickAction}
                        onClose={onCloseActions}
                    />
                ).getOr(<></>)
            }
            {
                addTarget.map(element =>
                    <FieldEditor
                        el={element}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        arcQLBundle={props.arcqlBundle}
                        field={Optional.none()}
                        onChange={f => {
                            const validationError = new ArcQLBuilder(props.arcqlBundle.dataset).validateField(f, props.arcqlBundle.arcql);
                            validationError.match(
                                err => {
                                    ServiceProvider.get(NotificationsService).publish(
                                        'fieldsPanel', NotificationSeverity.WARNING,
                                        err
                                    );
                                },
                                () => {
                                    props.delegate.addField(f);
                                    onCloseAdd();
                                }
                            );
                        }}
                        onClose={onCloseAdd}
                    />
                ).nullable
            }
        </>;
    };


    return <CanvasS.Shelf onDragOver={props.onDragOver} onDrop={props.onDrop}>
        <CanvasS.ShelfHeader> Fields </CanvasS.ShelfHeader>
        <CanvasS.ShelfContent>
            {buildContent()}
        </CanvasS.ShelfContent>
    </CanvasS.Shelf>;

};

type SelectedField = {
    // metrics currently selected
    el: Element,
    field: ColumnField
    ordinal: number,
    // we know ordinal 0 is first
    isLast: boolean
}

class S {

    static readonly FieldsPill = styled(Pill)`
        margin-bottom: 6px;

        button.MuiButton-outlinedPrimary {
            border-color: ${Colors.measureGreen};

            &.prefix {
                color: ${Colors.textPrimary};
                background-color: ${Colors.measurePrefix};

                &:hover {
                    border-color: ${Colors.measureGreen};
                }
            }

            &.main, &.suffix {
                background-color: ${Colors.measureBackground};

                &:hover {
                    background-color: ${Colors.measureHover};
                    border-color: ${Colors.measureGreen};
                }
            }
        }
    `;

    static readonly Add = styled(PanelS.Add)`
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%2397C897FF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

        svg {
            color: ${Colors.measureGreen};
        }
    `;

}
