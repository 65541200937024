import * as React from "react"
import {SVGProps} from "react"

const NetworkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M9 17.667c.317.416.5.933.5 1.5 0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5c0-1.384 1.117-2.5 2.5-2.5.367 0 .708.075 1.025.216L9.2 15.408a3.753 3.753 0 0 1-.908-3.075L6.6 11.767a2.498 2.498 0 0 1-4.6-1.35c0-1.384 1.117-2.5 2.5-2.5S7 9.033 7 10.417c0 .058 0 .116-.008.175l1.691.566a3.724 3.724 0 0 1 2.684-1.933v-1.8A2.515 2.515 0 0 1 9.5 5c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5c0 1.167-.8 2.142-1.875 2.425v1.8a3.723 3.723 0 0 1 2.683 1.933L17 10.592v-.175c0-1.384 1.117-2.5 2.5-2.5s2.5 1.116 2.5 2.5c0 1.383-1.117 2.5-2.5 2.5a2.51 2.51 0 0 1-2.1-1.142l-1.692.567a3.741 3.741 0 0 1-.908 3.075l1.175 1.475c.317-.15.658-.225 1.025-.225 1.383 0 2.5 1.116 2.5 2.5 0 1.383-1.117 2.5-2.5 2.5a2.497 2.497 0 0 1-2.5-2.5c0-.567.183-1.084.5-1.5l-1.175-1.475a3.75 3.75 0 0 1-3.642 0L9 17.667Z"
        />
    </svg>
);
export default NetworkIcon;