import {ArcQL} from 'metadata/query/ArcQL';
import {ArcQLResponse} from 'metadata/query/ArcQLResponse';
import {Either} from "common/Either";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {ApiQueryService} from "services/ApiQueryService";
import {SingleSource} from "metadata/query/ArcQLSource";
import {ServiceProvider} from "services/ServiceProvider";
import {DatasetV2Service} from "services/DatasetV2Service";

/**
 * Dev service that will look for locally stored queries before making an API call to speed up development.
 *
 * @author zuyezheng
 */
export class LocallyCachedQuerySerivce extends ApiQueryService {

    async query(
        arcQL: ArcQL,
        signal?: AbortSignal,
        // use an explicit timeout for this query
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, ArcQLResponse>> {
        const datasetFqn = (arcQL.source as SingleSource).fqn;
        return fetch(
            `/static/repo/${datasetFqn.account}/${datasetFqn.folder}/queries/${arcQL.hash()}.json`,
            {'signal': signal}
        )
            .then(ApiResponse.identity)
            .then(response => {
                if (response.isRight) {
                    console.log(`query ${arcQL.hash()} loaded from local cache`);
                    // fetch the dataset which should already be cached
                    return ServiceProvider.get(DatasetV2Service).describeDataset(
                        (arcQL.source as SingleSource).fqn, signal, arcQL.fullyQualifiedName
                    ).then(dataestResponse => dataestResponse.map(
                        dataset => ArcQLResponse.fromJson(response.rightOrThrow(), dataset)
                    ));
                } else {
                    // not cached, look for it on the server
                    return super.query(arcQL, signal, cacheTimeout);
                }
            });
    }

}