import {DatasetType} from "metadata/dataset/DatasetType";

import {ColumnMetadata} from "metadata/dataset/ColumnMetadata";

/**
 * Any Personal's dataset specific column metadata
 */
export class PersonalColumnMetadata implements ColumnMetadata {

    public readonly type: DatasetType = DatasetType.PERSONAL;
}