import {JsonObject} from "common/CommonTypes";
import {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {JsonUtils} from "metadata/JsonUtils";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";

/**
 * Helps with deserializing widget metadata from JSON, this is broken out to avoid circular dependencies.
 *
 * @author zuyezheng
 */
export class WidgetMetadataDeserializer {

    static fromJSON(json: JsonObject, id: string): WidgetMetadataBound {
        const widgetType = WidgetType.get<WidgetType>(json['type']);
        return new widgetType.ctor(
            widgetType,
            id,
            json['label'],
            json['description'],
            JsonUtils.toMap(json['config'], v => v)
        );
    }

}