import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {VizSelection} from "engine/actor/VizSelection";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {NodeAndLinkData} from "app/visualizations/data/NodeAndLinkData";
import {Optional} from "common/Optional";
import {SelectionEvent} from "app/visualizations/FusionTypes";

/**
 * @author zuyezheng
 */
export class SankeyChartConfig extends ChartConfig {

    private readonly data: NodeAndLinkData;

    constructor(
        response: ArcQLResponse,
        config: VisualizationConfig
    ) {
        super(response, config);

        this.data = new NodeAndLinkData(response, true);
    }

    validate(): Optional<string> {
        return this.data.validate();
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    handleDiscreteClick(
        event: SelectionEvent, originalDataSource: {[key: string]: any}, eventType: string
    ): Optional<string[][]> {
        // for sankey, we append " (From)" and " (To)" to our node labels to ensure uniqueness, these need to be
        // stripped for selections, we can't "inject" the original values as hidden values as we do for other chart
        // types since fusion strips them out
        const buildFromTo = (fromLabel: string, toLabel?: string): string[] => {
            return [
                fromLabel.substring(0, fromLabel.length - 7),
                toLabel == null ? null : toLabel.substring(0, toLabel.length - 5)
            ];
        };

        if (eventType === 'dataplotclick') {
            // clicked one of the endpoints in the sankey
            if (event.sourceLinks.length > 0) {
                // clicked a start node, select all ends which are actually called "sources" in the sankey event
                return Optional.some(event.sourceLinks.map(
                    (end: string) => buildFromTo(event.label, end)
                ));
            } else if (event.targetLinks.length > 0) {
                // clicked an end node, select all the starts which are actually called "targets" in the sankey event
                return Optional.some(event.targetLinks.map(
                    (start: string) => buildFromTo(start, event.label)
                ));
            }
        } else if (eventType === 'linkclick') {
            // clicked the link connecting nodes
            return Optional.some([buildFromTo(event.from, event.to)]);
        }

        return Optional.none();
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        const {nodes, links} = this.data.dataset(selections);

        return {
            type: 'sankey',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),

                        showLegend: false
                    }
                ),
                nodes: nodes,
                links: links
            }
        };
    }

}