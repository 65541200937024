import {Tuple} from "common/Tuple";
import {VizTheme} from "app/visualizations/VizTheme";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";
import {VizEnumProperty} from "app/visualizations/definition/property/VizEnumProperty";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import ThemeIcon from "app/components/icons/ThemeIcon";
import React from "react";

export class CommonChartProperties {

    static COMMON: VizProperty<any>[] = [
        new VizEnumProperty(
            'theme',
            'Color Palette',
            VizTheme.enums<VizTheme>().map(t => Tuple.of(t.name, t.label)),
            t => {
                const theme = VizTheme.get<VizTheme>(t);
                return <ThemeIcon
                    color1={theme.palette[0]}
                    color2={theme.palette[1]}
                    color3={theme.palette[2]}
                />;
            }
        ),
        new VizStringProperty('title', 'Title Text'),
        new VizStringProperty('subTitle', 'Subtitle Text'),
        new VizEnumProperty(
            'titlePosition',
            'Title Position',
            [
                Tuple.of('left', 'Left'),
                Tuple.of('center', 'Center'),
                Tuple.of('right', 'Right')
            ]
        )
    ];

    static CARTESIAN: VizProperty<any>[] = [
        new VizStringProperty('xAxisName', 'X-Axis Label'),
        new VizStringProperty('yAxisName', 'Y-Axis Label'),
    ];

    static LEGEND: VizProperty<any>[] = [].concat(
        [
            new VizBooleanProperty('showLegend', 'Display Legend')
        ]
    );

    static COMMON_WITH_CARTESIAN_AND_LEGEND: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON,
        CommonChartProperties.CARTESIAN,
        CommonChartProperties.LEGEND
    );

}