import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";

/**
 * @author zuyezheng
 */
export class TableDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [
        new VizStringProperty('title', 'Title'),
        new VizStringProperty('footer', 'Footer'),
        new VizBooleanProperty('compactNumbers', 'Compact Numbers'),
        new VizBooleanProperty('pivot', 'Pivot')
    ];

    static DEFAULTS = new Map<string, any>([
    ]);

    get properties(): VizProperty<any>[] {
        return TableDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return TableDefinition.DEFAULTS;
    }

}