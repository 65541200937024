import {BaseWidgetConfig} from "metadata/dashboard/widgets/config/BaseWidgetConfig";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {ImageConfigPart} from "metadata/dashboard/widgets/config/image/ImageConfigPart";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

/**
 * Complete Configuration for Image Widget such as its container and text configuration.
 */
export class ImageWidgetConfig extends BaseWidgetConfig {
    static readonly CONTAINER_CONFIG_KEY = 'container';
    static readonly IMAGE_CONFIG_KEY = 'image';

    constructor(
        public readonly container: ContainerConfigPart,
        public readonly image: ImageConfigPart,
    ) {
        super();
    }


    get parts(): Map<string, WidgetConfigPart> {
        return new Map<string, WidgetConfigPart>([
            [ImageWidgetConfig.CONTAINER_CONFIG_KEY, this.container],
            [ImageWidgetConfig.IMAGE_CONFIG_KEY, this.image],
        ]);
    }

    static fromMap(configMap: Map<string, any>, dashboardConfig?: DashboardConfig): ImageWidgetConfig {
        let containerPart: ContainerConfigPart = ContainerConfigPart
            .fromJSON(configMap.get(ImageWidgetConfig.CONTAINER_CONFIG_KEY) || {});
        containerPart = dashboardConfig ? containerPart.withDefaults(dashboardConfig.container) : containerPart;
        return new ImageWidgetConfig(
            containerPart,
            ImageConfigPart.fromJSON(configMap.get(ImageWidgetConfig.IMAGE_CONFIG_KEY) || {})
        );
    }
}