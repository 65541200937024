import {ArcQL} from "metadata/query/ArcQL";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {AddOrderBy} from "metadata/query/changes/AddOrderBy";
import {ArcDataset} from "metadata/dataset/ArcDataset";

/**
 * Delete the order by of a given field.
 *
 * @author zuyezheng
 */
export class DeleteOrderBy implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly field: string,
        public readonly dataset: ArcDataset
    ) {}

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const oldOrderBy = metadata.orderBys.find(this.field).get();
        return new Tuple(
            metadata.with({
                'orderBys': metadata.orderBys.without(this.field)
            }),
            new AddOrderBy(this.field, oldOrderBy.left.direction, this.dataset, oldOrderBy.right)
        );
    }

    describe(): string {
        return `Deleted order by on ${this.dataset.getLabel(this.field)}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}