import * as React from "react";
import {FunctionComponent} from "react";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {S} from "app/query/QueryBuilderCanvasS";
import {VizEditor} from "app/query/components/VizEditor";

type Props = {
    config: VisualizationConfig
    delegate: QueryBuilderDelegate
}

/**
 * Content when visualization is tabbed to 'Display'.
 */
export const VisualizationDisplayContent: FunctionComponent<Props> = (props: Props) => {

    const onChangeVizConfig = (vizConfig: VisualizationConfig) => {
        props.delegate.changeVizConfig(vizConfig);
    };

    return <S.ShelfContent>
        <VizEditor
            config={props.config}
            onChange={onChangeVizConfig}
        />
    </S.ShelfContent>;
};