import * as React from "react";
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import {DashboardWidgetProps} from "app/dashboard/widgets/WidgetContainer";
import {WidgetComponentFactory} from "app/dashboard/widgets/WidgetComponentFactory";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {Optional} from "common/Optional";
import {DashboardQuery} from "metadata/dashboard/DashboardQueries";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ModifyWidgetDelegate} from "app/query/ModifyWidgetDelegate";
import {LinkWidget} from "app/dashboard/widgets/LinkWidget";
import {LinkMetadata} from "metadata/dashboard/widgets/LinkMetadata";
import {WidgetEditor} from "app/dashboard/widgets/WidgetEditor";
import {LinkWidgetConfig} from "metadata/dashboard/widgets/config/LinkWidgetConfig";
import {WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";
import {ReactNode} from "react";

export class LinkWidgetFactory implements WidgetComponentFactory {

    icon(): React.ReactNode {
        return <LinkOutlinedIcon/>;
    }

    view(props: DashboardWidgetProps): React.ReactNode {
        return <LinkWidget
            config={(props.widgetMetadata as LinkMetadata).widgetConfig()}
            filterActor={props.filterActor}
        />;
    }

    get matchWidgetDimensions(): boolean {
        return false;
    }

    inlineEditor(
        widgetMetadata: WidgetMetadataBound,
        mergedWidgetConfig: WidgetConfig,
        queryMetadata: Optional<DashboardQuery>,
        dashboard: ArcDashboard,
        delegate: ModifyWidgetDelegate
    ): ReactNode {
        return <WidgetEditor
            onConfigChange={
                (configKey, configJson) => {
                    delegate.modifyWidgetConfig(widgetMetadata.id, new Map([[configKey, configJson]]));
                }
            }
            partsMap={mergedWidgetConfig.parts}
            sortedParts={mergedWidgetConfig.partsByKeys(
                [LinkWidgetConfig.TEXT_CONFIG_KEY, LinkWidgetConfig.LINK_CONFIG_KEY, LinkWidgetConfig.CONTAINER_CONFIG_KEY]
            )}
        />;
    }

}