import {KeyedWidgetConfigPart, WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";

export abstract class BaseWidgetConfig implements WidgetConfig {
    abstract get container(): ContainerConfigPart;

    abstract get parts(): Map<string, WidgetConfigPart>

    partsByKeys(keys: string[]): KeyedWidgetConfigPart[] {
        return keys.reduce((acc: KeyedWidgetConfigPart[], key) => {
            const config = this.parts.get(key);
            if (config !== undefined) {
                acc.push([key, config]);
            }
            return acc;
        }, []);
    }
}