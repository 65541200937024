import {useAuth0} from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import {UserContext} from "app/UserContext";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {CommonS} from "app/components/CommonS";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import React, {FunctionComponent} from "react";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import {useHistory} from "react-router-dom";

type Props = {
    onClose: () => void
    anchorEl: Element
}

export const UserSettingsMenu: FunctionComponent<Props> = (props: Props) => {

    const history = useHistory();
    const {logout} = useAuth0();

    const onSettingsClick = () => {
        history.push('/settings');
        props.onClose();
    };

    return <>
        <S.UserMenu
            anchorEl={props.anchorEl}
            id="account-menu"
            open={true}
            onClose={props.onClose}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <S.UserInfo>
                <UserContext.Consumer>{
                    userContext => userContext.user.map(
                        user => <>
                            <CommonS.MinorText>Signed in as:</CommonS.MinorText>
                            <S.UserSignedIn>
                                @{user.username}
                            </S.UserSignedIn>
                            <S.UserEmail>{user.email}</S.UserEmail>
                        </>).getOr(<></>)
                }</UserContext.Consumer>
            </S.UserInfo>
            <Divider/>
            <S.MenuItem dense onClick={onSettingsClick}>
                <ListItemIcon>
                    <SettingsOutlined/>
                </ListItemIcon>
                Settings
            </S.MenuItem>
            <S.MenuItem dense onClick={() => logout({returnTo: window.location.origin})}>
                <ListItemIcon>
                    <Logout/>
                </ListItemIcon>
                Logout
            </S.MenuItem>
        </S.UserMenu>
    </>;

};
const S = {

    UserMenu: styled(Menu)`
        margin-top: 4px;
    `,

    UserInfo: styled(Box)`
        padding: 16px 32px 16px 16px;
        min-width: 150px;
        display: flex-row;
    `,

    UserSignedIn: styled.div`
        margin: 8px 0 3px 0;
        font-weight: bold;
    `,

    UserEmail: styled.div`
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
    `,

    MenuItem: styled(MenuItem)`
        margin-top: 4px;
    `,

};
