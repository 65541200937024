/**
 * Expected JSON response for deleting connection.
 */
export type DeleteConnectionResponseJson = {
    id: string;
}

/**
 * Response from deleting connection.
 */
export class DeleteConnectionResponse {

    constructor(
        public readonly id: string,
    ) {
    }

    static fromJson(json: DeleteConnectionResponseJson): DeleteConnectionResponse {
        return new DeleteConnectionResponse(
            json.id
        );
    }
}

