import * as React from "react"
import {SVGProps} from "react"

const CandlestickIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M9 4.5H7v2H5v12h2v2h2v-2h2v-12H9v-2Zm0 12H7v-8h2v8Zm10-8h-2v-4h-2v4h-2v7h2v5h2v-5h2v-7Zm-2 5h-2v-3h2v3Z"
        />
    </svg>
);
export default CandlestickIcon;
