import * as Sentry from "@sentry/browser";
import {HyperArcUser} from "metadata/HyperArcUser";
import {RestService} from "services/RestService";
import {ServiceProvider} from "services/ServiceProvider";
import {UserAnalyticsService} from "services/UserAnalyticsService";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {Either} from "common/Either";
import {JsonObject} from "common/CommonTypes";
import {RemoteDataSource, RemoteDataSourceJson} from "metadata/connections/RemoteDataSource";
import {OrgMembership, OrgMembershipJson} from "metadata/OrgMembership";
import {DatasetListItem, DatasetListItemJson} from "metadata/dataset/DatasetListItem";

type UserUpdate = {
    surveyResponse?: object
}

export class UserService {

    private currentUserPromise: Promise<Either<ErrorResponse, HyperArcUser>>;

    async createCurrentUser(username: string): Promise<Either<ErrorResponse, HyperArcUser>> {
        let requestBody = {
            username: username
        };
        this.currentUserPromise = ServiceProvider.get(RestService)
            .post("/api/v1/user", requestBody)
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    const hyperArcUser: HyperArcUser = HyperArcUser.fromJSON(json);
                    this.identifyUser(hyperArcUser);
                    return hyperArcUser;
                },
                ErrorResponse.of,
                201
            ));

        return await this.currentUserPromise;
    }

    /**
     * Get the current user, only the initialization call needs to provider a User to get.
     */
    getCurrentUser(): Promise<Either<ErrorResponse, HyperArcUser>> {
        if (this.currentUserPromise == null) {
            this.currentUserPromise = (async () => {
                return await ServiceProvider.get(RestService).get("/api/v1/user")
                    .then(r => ApiResponse.custom(
                        r,
                        (json: JsonObject) => {
                            const hyperArcUser: HyperArcUser = HyperArcUser.fromJSON(json);
                            this.identifyUser(hyperArcUser);
                            return hyperArcUser;
                        },
                        ErrorResponse.of
                    ));
            })();
        }

        return this.currentUserPromise;
    }

    /**
     * List memberships for the current user by name.
     */
    async listMemberships(): Promise<Either<ErrorResponse, OrgMembership[]>> {
        const currentUser: Either<ErrorResponse, HyperArcUser> = await this.currentUserPromise;
        return currentUser.map(_ =>
            ServiceProvider.get(RestService)
                .get(`/api/v1/user/memberships`)
                .then(r => ApiResponse.custom(
                    r,
                    (memberships: OrgMembershipJson[]) =>
                        memberships.map(OrgMembership.fromJSON)
                    ,
                    (json: any, r: Response) => ErrorResponse.of(json, r)
                ))
        ).rightOrThrow();
    }

    /**
     * List connections for current user.
     */
    async listConnections(): Promise<Either<ErrorResponse, RemoteDataSource[]>> {
        const currentUser: Either<ErrorResponse, HyperArcUser> = await this.currentUserPromise;
        return currentUser.map(_ =>
            ServiceProvider.get(RestService)
                .get(`/api/v1/user/connections`)
                .then(r => ApiResponse.custom(
                    r,
                    (connections: RemoteDataSourceJson[]) =>
                        connections.map(RemoteDataSource.fromJSON)
                    ,
                    (json: any, r: Response) => ErrorResponse.of(json, r)
                ))
        ).rightOrThrow();
    }

    /**
     * List Personal datasets for current user.
     */
    async listDatasets(): Promise<Either<ErrorResponse, DatasetListItem[]>> {
        const currentUser: Either<ErrorResponse, HyperArcUser> = await this.currentUserPromise;
        return currentUser.map(_ =>
            ServiceProvider.get(RestService)
                .get(`/api/v1/user/datasets`)
                .then(r => ApiResponse.custom(
                    r,
                    (datasets: DatasetListItemJson[]) =>
                        datasets.map(DatasetListItem.fromJSON)
                    ,
                    (json: any, r: Response) => ErrorResponse.of(json, r)
                ))
        ).rightOrThrow();
    }

    async updateCurrentUser(userUpdate: UserUpdate): Promise<Either<ErrorResponse, HyperArcUser>> {
        return ServiceProvider.get(RestService).fetch("/api/v1/user", {
            method: "PATCH",
            body: JSON.stringify(userUpdate)
        })
            .then(ApiResponse.identity)
            .then(r => r.map(HyperArcUser.fromJSON));
    }

    private identifyUser(user: HyperArcUser): void {
        ServiceProvider.get(UserAnalyticsService).identify(user.id, {
            username: user.username,
            email: user.email
        });
        Sentry.setUser({
            id: user.id,
            username: user.username,
            email: user.email
        });
    }

}