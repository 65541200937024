/**
 * Zip 2 arrays up to the shortest length.
 */
export function zip<T, U>(array1: T[], array2: U[]): [T, U][] {
    const length = Math.min(array1.length, array2.length);

    const result: [T, U][] = [];
    for (let i = 0; i < length; i++) {
        result.push([array1[i], array2[i]]);
    }

    return result;
}

/**
 * Zip columnar arrays into rows.
 */
export function zipAll(arrays: any[][]): any[][] {
    // Math.min() returns Infinity so need to explicitly handle nothing to zip.
    if (arrays.length === 0) {
        return [];
    }

    const length = Math.min(...arrays.map(a => a.length));
    const result: any[][] = [];
    for (let i = 0; i < length; i++) {
        result.push(arrays.map(a => a[i]));
    }

    return result;
}

export function toObject(map: Map<string, any>): any {
    const obj: any = {};
    map.forEach((v, k) => obj[k] = v);
    return obj;
}

/**
 * Form pairs given an array to make processing more fluent. If strict is true, odd number of values will throw an
 * error, otherwise the last value will be trimmed.
 */
export function pairs<T>(values: T[], strict: boolean = true): [T, T][] {
    if (values.length % 2 !== 0) {
        if (strict) {
            throw new Error('Uneven number of values to form pairs.');
        } else {
            values = values.slice(0, values.length - 1);
        }
    }

    const result: [T, T][] = [];
    for (let i = 0; i < values.length - 1; i += 2) {
        result.push([values[i], values[i + 1]]);
    }
    return result;
}