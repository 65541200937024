import {Optional} from "common/Optional";

/**
 * Helper for cookies.
 */
export class Cookies {

    static get isSupported(): boolean {
        return typeof document !== 'undefined' && navigator.cookieEnabled;
    }

    static get(name: string): Optional<string> {
        if (typeof document === 'undefined') {
            return Optional.none();
        }

        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match != null) {
            return Optional.of(decodeURIComponent(match[2]));
        } else {
            return Optional.none();
        }
    }

    static getBoolean(name: string): boolean {
        return this.get(name).map(v => v === 'true').getOr(false);
    }

    static set(name: string, value: string, days: number = 365) {
        if (typeof document === 'undefined') {
            return;
        }

        const expires = new Date();
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
    }

}