import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {AssetProps} from "metadata/Asset";
import {DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";

/**
 * Change basic metadata like name and label.
 */
export class DatasetV2StateInfoChange implements ArcMetadataChange<DatasetV2BuilderState> {

    constructor(
        public readonly infoChanges: AssetProps
    ) {
    }

    apply(metadata: DatasetV2BuilderState): Tuple<DatasetV2BuilderState, ArcMetadataChange<DatasetV2BuilderState>> {
        const originals = Object.keys(this.infoChanges).reduce(
            (acc: AssetProps, key: keyof AssetProps) => {
                acc[key] = metadata[key];
                return acc;
            },
            {}
        );

        return new Tuple(
            metadata.with(this.infoChanges), new DatasetV2StateInfoChange(originals)
        );
    }

    describe(): string {
        return `Changed label to ${this.infoChanges.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}