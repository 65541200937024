import {Tuple} from "common/Tuple";
import {ArcDashboardError} from "metadata/dashboard/ArrDashboardError";
import {JsonUtils} from "metadata/JsonUtils";


const DEFAULT_LAYOUT = 'default';

export class DashboardLayouts {

    static default(): DashboardLayouts {
        return new DashboardLayouts(new Map([
            [DEFAULT_LAYOUT, DashboardLayout.empty()]
        ]));
    }

    static fromJSON(json: {[key: string]: any}): DashboardLayouts {
        return new DashboardLayouts(JsonUtils.toMap(json, DashboardLayout.fromJSON));
    }

    constructor(
        public readonly layouts: Map<string, DashboardLayout>
    ) { }

    withUpdates(name: string, f: (layout: DashboardLayout) => DashboardLayout): DashboardLayouts {
        const newLayouts = new Map(this.layouts);
        if (!this.layouts.has(name)) {
            throw new ArcDashboardError(`Layout ${name} does not exist.`);
        }
        newLayouts.set(name, f(this.layouts.get(name)));

        return new DashboardLayouts(newLayouts);
    }

    withUpdatesOnDefault(f: (layout: DashboardLayout) => DashboardLayout): DashboardLayouts {
        return this.withUpdates(DEFAULT_LAYOUT, f);
    }

    get default(): DashboardLayout {
        return this.layouts.get(DEFAULT_LAYOUT);
    }

    toJSON(): Object {
        return JsonUtils.fromMap(this.layouts);
    }

}

export class DashboardLayout {

    static empty() {
        return new DashboardLayout(new Map());
    }

    static fromJSON(json: {[key: string]: any}): DashboardLayout {
        return new DashboardLayout(JsonUtils.toMap(json, WidgetLayout.from));
    }

    constructor(
        public readonly widgets: Map<string, WidgetLayout>
    ) {}

    get(widgetId: string): WidgetLayout {
        return this.widgets.get(widgetId);
    }

    /**
     * Create a new layout with the given widget.
     */
    with(widgetId: string, layout: LayoutLike): DashboardLayout {
        const newLayouts = new Map(this.widgets);
        newLayouts.set(widgetId, WidgetLayout.from(layout));

        return new DashboardLayout(newLayouts);
    }

    /**
     * Return a new widget layout with the given widget removed.
     */
    without(widgetId: string): DashboardLayout {
        const newLayouts = new Map(this.widgets);
        newLayouts.delete(widgetId);

        return new DashboardLayout(newLayouts);
    }

    /**
     * Create a new layout with the updated layouts for any existing widgets. Return the new layout as well as a boolean
     * indicating if there were any actual changes.
     */
    withUpdates(layouts: Map<string, LayoutLike>): Tuple<DashboardLayout, boolean> {
        let hasChanges = false;
        const newLayouts = new Map(this.map((widgetId: string, layout: WidgetLayout) => {
            const newLayout = layouts.get(widgetId) || layout;
            hasChanges = hasChanges || !layout.equals(newLayout);

            return [widgetId, WidgetLayout.from(newLayout)];
        }));

        return Tuple.of(new DashboardLayout(newLayouts), hasChanges);
    }

    hasChanges(layouts: Map<string, LayoutLike>): boolean {
        return Array.from(this.widgets.entries()).some(
            ([widgetId, layout]: [string, WidgetLayout]) => {
                const newLayout = layouts.get(widgetId);
                // assume missing layouts as no change, otherwise check for a change
                return newLayout != null && !layout.equals(newLayout);
            }
        );
    }

    /**
     * First available row given the height required
     */
    firstAvailableRow(heightRequired: number): number {
        const rowsOccupied = Array.from(this.widgets.values()).reduce(
            (rowsOccupied: boolean[], layout: WidgetLayout) => {
                for (let i = layout.y; i <= layout.bottom; i++) {
                    rowsOccupied[i] = true;
                }

                return rowsOccupied;
            },
            []
        );

        // see if we can sneak it in
        const rowsToCheck = [...Array(heightRequired)];
        for (let i=0; i<rowsOccupied.length; i++) {
            if (rowsToCheck.every((_, j) => rowsOccupied[i + j] !== true)) {
                return i;
            }
        }

        // nothing fits, use last row
        return rowsOccupied.length;
    }

    map<T>(f: (widgetId: string, layout: WidgetLayout) => T): T[] {
        return Array.from(this.widgets.entries()).map(v => f(v[0], v[1]));
    }

    forEach<T>(f: (widgetId: string, layout: WidgetLayout) => void) {
        return Array.from(this.widgets.entries()).forEach(v => f(v[0], v[1]));
    }

    /**
     * Returns the max column index occupied by any widget.
     */
    get maxColumn(): number {
        return Math.max(...this.map((_, layout: WidgetLayout) => layout.x + layout.w));
    }

    toJSON(): Object {
        return JsonUtils.fromMap(this.widgets);
    }

}

/**
 * Shim between this metadata and layout like objects used in various frameworks.
 */
export interface LayoutLike {

    x: number;
    y: number;
    w: number;
    h: number;

}

export class WidgetLayout implements LayoutLike {

    static from(layoutLike: LayoutLike): WidgetLayout {
        return new WidgetLayout(layoutLike.x, layoutLike.y, layoutLike.w, layoutLike.h);
    }

    constructor(
        public readonly x: number,
        public readonly y: number,
        public readonly w: number,
        public readonly h: number
    ) { }

    equals(layoutLike: LayoutLike): boolean {
        return this.x === layoutLike.x &&
            this.y === layoutLike.y &&
            this.w === layoutLike.w &&
            this.h === layoutLike.h;
    }

    /**
     * Get the row index occupied by the bottom of the widget.
     */
    get bottom(): number {
        // subtract 1 since a widget of height 1 occupies the same row it starts in
        return this.y + this.h - 1;
    }

}