import { Enum } from "common/Enum";

/**
 * Enumeration of all the tools that can be shown in the query builder.
 */
export class QueryBuilderTool extends Enum {

    static readonly DATASET_PANEL = new QueryBuilderTool('datasetPanel');
    static readonly SAVE = new QueryBuilderTool('save');
    static readonly SHARE = new QueryBuilderTool('share');
    static readonly DEV = new QueryBuilderTool('dev');
    static readonly VERSIONS = new QueryBuilderTool('versions');
    static readonly ARCHIVE = new QueryBuilderTool('archive');
    static readonly HYPER_GRAPH = new QueryBuilderTool('hyperGraph');

}
QueryBuilderTool.finalize();