import {ConnectionDetails} from "metadata/connections/ConnectionDetails";

type SnowflakeConnectionDetailsJson = {
    account: string,
    username: string,
    publicKey: string,
    publicKeyPem: string
}

/**
 * Snowflake connection details.
 */
export class SnowflakeConnectionDetails implements ConnectionDetails {
    constructor(
        public readonly account: string,
        public readonly username: string,
        public readonly publicKey: string,
        public readonly publicKeyPem: string
    ) {
    }

    static fromJSON(json: SnowflakeConnectionDetailsJson): SnowflakeConnectionDetails {
        return new SnowflakeConnectionDetails(
            json.account,
            json.username,
            json.publicKey,
            json.publicKeyPem
        );
    }


}