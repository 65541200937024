import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {ConsolidateAnalysis} from "metadata/hypergraph/nodes/ConsolidateAnalysis";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {HyperGraphNodeHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {StringUtils} from "common/StringUtils";
import {AddFilter} from "metadata/query/changes/AddFilter";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {DeleteField} from "metadata/query/changes/DeleteField";
import {WithQueryVariants} from "metadata/hypergraph/WithQueryVariants";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {
    HyperGraphNodeFactory,
    HyperGraphNodeProps,
    HyperGraphNodeSerialized
} from "metadata/hypergraph/HyperGraphTypes";
import {NodeRating} from "metadata/hypergraph/NodeRating";
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';

/**
 * @author zuyezheng
 */
export class ConsolidateAnalysisNode extends HyperGraphNode<ConsolidateAnalysis> implements WithQueryVariants<ConsolidatedPermutation> {

    private readonly permutations: ConsolidatedPermutation[];

    constructor(
        public readonly request: ConsolidateAnalysis,
        private readonly response: ConsolidatedAnalysisJson,
        id?: string,
        createdOn?: number,
        modifiedOn?: number,
        nodeEmbedding?: number[],
        rating?: NodeRating
    ) {
        super(id, createdOn, modifiedOn, nodeEmbedding, undefined, rating);

        this.permutations = response.permutations.map(p => new ConsolidatedPermutation(
            p.changeIndex,
            p.why,
            p.nullHypothesis,
            p.alternativeHypothesis,
            p.retainedMeasures,
            p.additionalFilters
        ));
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.CONSOLIDATE_ANALYSIS;
    }

    label(hyperGraph: HyperGraph): string {
        return 'Consolidated Analysis';
    }

    get description(): string {
        return this.consolidatedAnalysis;
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        return [];
    }

    get consolidatedAnalysis(): string {
        return this.response.consolidatedAnalysis;
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return this.permutations[nth];
    }

    get variantsOperation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.SEGMENTS;
    }

    getVariants(graph: HyperGraph, dataset: ArcDataset): [ConsolidatedPermutation, ArcQL][] {
        return this.permutations.map(p => {
            const baseQuery = graph.get(this.request.ommers[p.changeIndex]).getQuery(graph);

            const queryChanges = [
                // add additional filters
                ...p.filterClauses.map(filter => new AddFilter(filter, false, dataset)),
                // remove fields that aren't retained
                ...baseQuery.fields.fields.filter(field => !p.retainedMeasures.includes(field.as))
                    .map(field => new DeleteField(field.as))
            ];

            const newQuery = queryChanges.reduce(
                (query, change) => change.apply(query).left,
                baseQuery
            );

            return [p, newQuery];
        });
    }

    with(props: Partial<HyperGraphNodeProps>): ConsolidateAnalysisNode {
        return new ConsolidateAnalysisNode(
            this.request,
            this.response,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn,
            props.nodeEmbedding ?? this.nodeEmbedding,
            props.rating ?? this.rating
        );
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            response: this.response
        };
    }

    static factoryOfFactory(
        request: ConsolidateAnalysis, id: string
    ): HyperGraphNodeFactory<ConsolidatedAnalysisJson, ConsolidateAnalysisNode> {
        return (response: ConsolidatedAnalysisJson) => new ConsolidateAnalysisNode(request, response, id);
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): ConsolidateAnalysisNode {
        return new ConsolidateAnalysisNode(
            ConsolidateAnalysis.fromJSON(json.request),
            json.response,
            json.id,
            json.createdOn,
            json.modifiedOn,
            json.nodeEmbedding,
            NodeRating.withDefault(json.rating)
        );
    }

}

export class ConsolidatedPermutation implements HyperGraphNodeHypothesis {

    constructor(
        public readonly changeIndex: number,
        public readonly why: string,
        public readonly nullHypothesis: string,
        public readonly alternativeHypothesis: string,
        public readonly retainedMeasures: string[],
        public readonly additionalFilters: FilterJson[]
    ) {}


    get change(): string {
        return `Added filters on ${this.additionalFilters.map(f => f.field).join(', ')} and retained measures ` +
            `${this.retainedMeasures.join(', ')} to ${StringUtils.unsentencify(this.why)}`;
    }

    get filterClauses(): FilterClause[] {
        return this.additionalFilters.map(
            filter => new LiteralsFilterClause(
                filter.field, FilterOperator.get(filter.operator), filter.values, false
            )
        );
    }

}

export type FilterJson = {
    field: string,
    operator: 'in' | 'notIn' | 'between' | 'greaterThanEqual' | 'lessThanEqual',
    values: (string | number)[]
}

export type ConsolidatedAnalysisJson = {

    consolidatedAnalysis: string,

    permutations: {
        why: string,
        nullHypothesis: string,
        alternativeHypothesis: string,
        changeIndex: number,
        retainedMeasures: string[]
        additionalFilters: FilterJson[]
    }[]

}