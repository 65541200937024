import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {ChartConfig} from "app/visualizations/config/ChartConfig";
import {VizType} from "metadata/query/VizType";
import {BarChartConfig} from "app/visualizations/config/BarChartConfig";
import {LineChartConfig} from "app/visualizations/config/LineChartConfig";
import {DonutChartConfig} from "app/visualizations/config/DonutChartConfig";
import {TimelineChartConfig} from "app/visualizations/config/TimelineChartConfig";
import {TreemapChartConfig} from "app/visualizations/config/TreemapChartConfig";
import {ChordChartConfig} from "app/visualizations/config/ChordChartConfig";
import {SankeyChartConfig} from "app/visualizations/config/SankeyChartConfig";
import {WaterfallChartConfig} from "app/visualizations/config/WaterfallChartConfig";
import {ScatterChartConfig} from "app/visualizations/config/ScatterChartConfig";
import {AreaChartConfig} from "app/visualizations/config/AreaChartConfig";
import {ComboChartConfig} from "app/visualizations/config/ComboChartConfig";
import {CandlestickChartConfig} from "app/visualizations/config/CandlestickChartConfig";

type FactoryMethod = (
    config: VisualizationConfig,
    queryResponse: ArcQLResponse
) => ChartConfig

const BY_TYPE: Map<VizType, FactoryMethod> = new Map([
    [
        VizType.BAR,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new BarChartConfig(response, config)
    ], [
        VizType.LINE,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new LineChartConfig(response, config)
    ], [
        VizType.AREA,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new AreaChartConfig(response, config)
    ], [
        VizType.COMBO,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new ComboChartConfig(response, config)
    ], [
        VizType.SCATTER,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new ScatterChartConfig(response, config, false)
    ], [
        VizType.BUBBLE,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new ScatterChartConfig(response, config, true)
    ], [
        VizType.DONUT,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new DonutChartConfig(response, config)
    ], [
        VizType.TIMELINE,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new TimelineChartConfig(response, config)
    ], [
        VizType.CANDLESTICK,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new CandlestickChartConfig(response, config)
    ], [
        VizType.TREEMAP,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new TreemapChartConfig(response, config)
    ], [
        VizType.CHORD,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new ChordChartConfig(response, config)
    ], [
        VizType.SANKEY,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new SankeyChartConfig(response, config)
    ], [
        VizType.WATERFALL,
        (config: VisualizationConfig, response: ArcQLResponse): ChartConfig =>
            new WaterfallChartConfig(response, config)
    ]
]);

export class ChartConfigFactory {

    static get(config: VisualizationConfig, response: ArcQLResponse): ChartConfig {
        return BY_TYPE.get(config.type)(config, response);
    }

}