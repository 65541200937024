import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {ValidateHypothesis} from "metadata/hypergraph/nodes/ValidateHypothesis";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {HyperGraphNodeHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {
    HyperGraphNodeFactory,
    HyperGraphNodeProps,
    HyperGraphNodeSerialized
} from "metadata/hypergraph/HyperGraphTypes";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {TrendsAndDataPoints, TrendsAndDataPointsJson} from 'metadata/hypergraph/request/TrendsAndDataPoints';
import {TextDetail} from 'metadata/hypergraph/content/TextDetail';

/**
 * @author zuyezheng
 */
export class ValidateHypothesisNode extends HyperGraphNode<ValidateHypothesis> {

    public readonly trendsAndDataPoints: TrendsAndDataPoints;

    constructor(
        public readonly request: ValidateHypothesis,
        public readonly response: HypothesisValidationJson,
        id?: string,
        createdOn?: number,
        modifiedOn?: number,
        nodeEmbedding?: number[],
        rating?: NodeRating
    ) {
        super(id, createdOn, modifiedOn, nodeEmbedding, undefined, rating);

        this.trendsAndDataPoints = TrendsAndDataPoints.fromJSON(this.response);
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.VALIDATE_HYPOTHESIS;
    }

    label(hyperGraph: HyperGraph): string {
        return this.request.label(hyperGraph);
    }

    get description(): string {
        return this.response.summary;
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        const result = this.getQueryResult(graph);
        return [
            new NodeStructuredSection(
                null,
                [new TextDetail(
                    this.response.nullHypothesis ? 'Null hypothesis accepted.' : 'Alternative hypothesis accepted.',
                    true
                )]
            ),
            ...this.trendsAndDataPoints.structuredContent(result)
        ];
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return undefined;
    }

    with(props: Partial<HyperGraphNodeProps>): ValidateHypothesisNode {
        return new ValidateHypothesisNode(
            this.request,
            this.response,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn,
            props.nodeEmbedding ?? this.nodeEmbedding,
            props.rating ?? this.rating
        );
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            response: this.response
        };
    }

    static factoryOfFactory(
        request: ValidateHypothesis, id: string
    ): HyperGraphNodeFactory<HypothesisValidationJson, ValidateHypothesisNode> {
        return (response: HypothesisValidationJson) => new ValidateHypothesisNode(request, response, id);
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): ValidateHypothesisNode {
        return new ValidateHypothesisNode(
            ValidateHypothesis.fromJSON(json.request),
            json.response,
            json.id,
            json.createdOn,
            json.modifiedOn,
            json.nodeEmbedding,
            NodeRating.withDefault(json.rating)
        );
    }

}

export type HypothesisValidationJson = TrendsAndDataPointsJson & {

    nullHypothesis: boolean

}