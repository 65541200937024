import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";

/**
 * @author zuyezheng
 */
export class NodeAndLinkChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name]
    ]);

    get properties(): VizProperty<any>[] {
        return NodeAndLinkChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return NodeAndLinkChartDefinition.DEFAULTS;
    }

}