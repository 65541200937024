import styled from "@emotion/styled";
import {UserProfile} from "app/components/UserProfile";
import {UserSettingsMenu} from "app/home/UserSettingsMenu";
import React, {FunctionComponent, useRef, useState} from "react";

const S = {
    Avatar: styled.div`
        cursor: pointer;
        padding: 8px;
    `
};

type Props = {

}

export const UserSettings: FunctionComponent<Props> = (props: Props) => {

    const container = useRef(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    return <S.Avatar ref={container}>
        <UserProfile showDetails={false} onClick={() => setShowMenu(true)} />
        {
            showMenu && <UserSettingsMenu
                onClose={() => setShowMenu(false)}
                anchorEl={container.current}
            />
        }
    </S.Avatar>;

};