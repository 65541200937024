import {ArcQL} from "metadata/query/ArcQL";
import {ServiceProvider} from "services/ServiceProvider";
import {QueryService} from "services/QueryService";
import {ArcMessage} from "engine/ArcMessage";
import {ActorStatusMessage} from "engine/ActorStatusMessage";
import {ActorStatus} from "engine/actor/ActorStatus";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ResultMessage} from "engine/ResultMessage";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Optional} from "common/Optional";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {QueryReason} from "engine/actor/QueryResason";
import {ChangeSetType} from "metadata/ChangeSetType";

/**
 * Querier that handles sequential queries ensuring only the last issued query gets published if queries are submitted
 * while past queries have not yet returned.
 *
 * @author zuyezheng
 */
export class SequentialQuerier {

    // abort controller of the last query
    private abortController: AbortController;
    // used to keep track of the last query made and ignore anything that might have been aborted
    private queryOrdinal: number;

    constructor(
        // optionally abort prior queries, otherwise let them complete in the background to be added to the cache
        private readonly abort: boolean
    ) {
        this.queryOrdinal = 0;
    }

    /**
     * Query the raw ArcQL endpoint.
     */
    query(arcql: ArcQL, queryReason: QueryReason, changeSetType: ChangeSetType, publish: (message: ArcMessage) => void): void {
        return this.queryInternal(
            arcql,
            queryReason,
            changeSetType,
            () => ServiceProvider.get(QueryService).query(arcql, this.abortController.signal),
            publish
        );
    }

    /**
     * Query a saved arcql with optional filters replacing the existing.
     */
    querySaved(
        arcql: ArcQL, queryReason: QueryReason, filters: Optional<ArcQLFilters>, publish: (message: ArcMessage) => void
    ): void {
        return this.queryInternal(
            arcql,
            queryReason,
            ChangeSetType.NONE,
            () => ServiceProvider.get(QueryService).querySaved(arcql, filters, this.abortController.signal),
            publish
        );
    }

    private queryInternal(
        // original arcql which just gets bundled with the result message, all the querying happens in doQuery
        arcql: ArcQL,
        // reason we are querying which will be included in the response message
        queryReason: QueryReason,
        // the type of change to the query that resulted in this execution
        changeSetType: ChangeSetType,
        // make the actual query
        doQuery: () => Promise<Either<ErrorResponse, ArcQLResponse>>,
        // publish results
        publish: (message: ArcMessage) => void
    ): void {
        if (this.abort && this.abortController != null) {
            // abort existing query
            this.abortController.abort();
        }
        this.abortController = new AbortController();

        // get a new ordinal as the expected to ensure we ignore query responses that might be inbound but out of date
        this.queryOrdinal++;
        const expectedQueryOrdinal = this.queryOrdinal;

        // busy querying
        publish(new ActorStatusMessage(ActorStatus.BUSY));

        doQuery().then(
            result => result.match(
                arcQLResponse => {
                    if (expectedQueryOrdinal === this.queryOrdinal) {
                        // reset the controller
                        this.abortController = null;

                        // successful query, publish results and status update
                        publish(new ResultMessage(arcQLResponse, arcql, queryReason, changeSetType));
                        publish(new ActorStatusMessage(ActorStatus.READY));
                    } else {
                        console.log(`query ${expectedQueryOrdinal} superseded by ${this.queryOrdinal}`);
                    }
                },
                () => {
                    ServiceProvider.get(NotificationsService).publish(
                        'SequentialQuerier', NotificationSeverity.ERROR, `Query error, use undo before continuing.`
                    );
                }
            ),
            reason => {
                console.log(`query aborted: ${reason}`);
            }
        );
    }

}