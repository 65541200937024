import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {LayoutLike} from "metadata/dashboard/DashboardLayouts";
import {Tuple} from "common/Tuple";

export class ModifyWidgetLayouts implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly layouts: Map<string, LayoutLike>
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const newMetadata = metadata.with({
            layouts: metadata.layouts.withUpdatesOnDefault(
                layout => layout.withUpdates(this.layouts).left
            )
        });

        return Tuple.of(newMetadata, new ModifyWidgetLayouts(metadata.layouts.default.widgets));
    }

    describe(): string {
        const widgetsEdited = Array.from(this.layouts.keys());
        let editedLabel = widgetsEdited.slice(0, 3).join(', ');
        if (widgetsEdited.length > 3) {
            editedLabel += `, and ${widgetsEdited.length - 3} more`
        }

        return `Modified layouts for widgets ${editedLabel}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}