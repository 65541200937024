import {Either} from "common/Either";
import {FQN} from "common/FQN";
import {ErrorResponse} from "services/ApiResponse";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {Alert} from "metadata/trend/Alert";
import {ArcTrendListItem} from "metadata/trend/ArcTrendListItem";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";

/**
 * Service specific for all Trend-related metadata + API.
 */
export abstract class TrendsService {

    /**
     * List trends for a dashboard.
     */
    abstract listTrends(
        dashboardFQN: FQN,
        loadImages?: boolean,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcTrendListItem[]>>;

    /**
     * Fetch a trend.
     */
    abstract fetchTrend(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>>;

    /**
     * Get a trend by ID.
     */
    abstract fetchTrendById(id: string, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>>;

    /**
     * Save a trend (create or update).
     */
    abstract saveTrend(fqn: FQN, trend: ArcTrend, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>>;

    /**
     * Get results for a trend.
     */
    abstract getTrendResults(
        trend: ArcTrend,
        since: string,
        dataset: ArcDataset,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcQLResponse>>;

    /**
     * List alerts for a trend.
     */
    abstract listAlerts(trendFqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert[]>>;

    /**
     * Create an alert from a trend.
     */
    abstract createAlert(trendFqn: FQN, alert: Alert, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert>>;

    /**
     * Patch an existing alert.
     */
    abstract patchAlert(trendFqn: FQN, alert: Alert, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert>>;
}