import * as React from "react"
import { SVGProps } from "react"

const FilterSetOutlinedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14px"
        height="16px"
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M4.52 8.358S1.693 4.718.16 2.754a.757.757 0 0 1 .6-1.22h10.548c.63 0 .986.72.599 1.22-1.532 1.964-4.353 5.604-4.353 5.604v4.55a.76.76 0 0 1-.758.758H5.279a.76.76 0 0 1-.758-.758v-4.55ZM9.83 3.05H2.246L6.03 7.827 9.829 3.05Z"
            clipRule="evenodd"
        />
        <path
            d="M9.45 9.238H14v-1H9.45v1ZM9.45 11.512H14v-1H9.45v1ZM9.45 13.787H14v-1H9.45v1Z"
        />
    </svg>
)
export default FilterSetOutlinedIcon