import {Enum} from "common/Enum";

export class HigherOrderSuperType extends Enum {
    static NONE = new this('none', "None", false);
    static BLOCK_HASH_EVENT = new this('blockHashEvent', "Blockhash Event", true);
    static BLOCK_HASH_ENTITY = new this('blockHashEntity', "Blockhash Entity", true);

    constructor(
        name: string,
        public readonly tooltipText: string,
        public readonly isHash: boolean
    ) {
        super(name);
    }
}

HigherOrderSuperType.finalize();

export class HigherOrderType extends Enum {
    static HASH = new this('hash', HigherOrderSuperType.BLOCK_HASH_EVENT);
    static BLOCK_HASH = new this('blockHash', HigherOrderSuperType.BLOCK_HASH_EVENT);
    static TRANSACTION_HASH = new this('transactionHash', HigherOrderSuperType.BLOCK_HASH_EVENT);
    static ADDRESS_HASH = new this('addressHash', HigherOrderSuperType.BLOCK_HASH_ENTITY);
    static ENUM = new this('enum', HigherOrderSuperType.NONE);
    static CURRENCY = new this('currency', HigherOrderSuperType.NONE);
    static COUNT = new this('count', HigherOrderSuperType.NONE);
    static INDEX = new this('index', HigherOrderSuperType.NONE);
    static BLOB = new this('blob', HigherOrderSuperType.NONE);

    static NONE = new this('none', HigherOrderSuperType.NONE);

    constructor(
        name: string,
        public readonly superType: HigherOrderSuperType
    ) {
        super(name);
    }
}

HigherOrderType.finalize();