import React, {FunctionComponent, ReactNode, useState} from "react";
import {ColorResult, SketchPicker} from "react-color";
import {IconButton} from "@mui/material";
import Popover from "@mui/material/Popover";
import {PresetColors} from "app/components/StyleVariables";
import Tooltip from "@mui/material/Tooltip";


interface Props {
    tooltip: string
    icon: ReactNode
    currentColor: string
    onChange: (color: ColorResult) => void
}

/**
 * IconButton for opening a color picker.
 */
export const ColorPickerButtonIcon: FunctionComponent<Props> = (props: Props) => {
    const [anchorColorPickerEl, setAnchorColorPickerEl] = useState<HTMLButtonElement>(null);

    const handleColorPickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorColorPickerEl(event.currentTarget);
    };

    const handleColorPickerClose = () => {
        setAnchorColorPickerEl(null);
    };


    return <>
        <Tooltip title={props.tooltip} placement="top" arrow>
            <IconButton
                onClick={handleColorPickerClick}
            >
                {props.icon}
            </IconButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorColorPickerEl)}
            anchorEl={anchorColorPickerEl}
            onClose={handleColorPickerClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <SketchPicker
                color={props.currentColor}
                onChangeComplete={(color: ColorResult) => props.onChange(color)}
                disableAlpha={true}
                presetColors={PresetColors}
            />
        </Popover>
    </>;
};