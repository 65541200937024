import * as React from "react";
import {FunctionComponent} from "react";
import Popover, {PopoverOrigin} from "@mui/material/Popover";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import styled from "@emotion/styled";
import {ColumnsPicker} from "app/query/components/ColumnsPicker";
import {Column} from "metadata/Column";

type Props = {
    // element and where to anchor the editor popup
    el: Element
    anchorOrigin: PopoverOrigin

    arcQLBundle: ArcQLBundle,
    // callback when column is selected
    onSelect: (col: Column) => void
    // close the editor without any changes
    onClose: () => void
}

/**
 * Dialog box for selecting a new field to filter on.
 */
export const NewFilterSelector: FunctionComponent<Props> = (props: Props) => {
    return (
        <Popover
            open={true}
            anchorEl={props.el}
            onClose={props.onClose}
            anchorOrigin={props.anchorOrigin}
        >
            <S.Container>
                <S.Content>
                    <ColumnsPicker
                        dataset={props.arcQLBundle.dataset}
                        isSaved={false}
                        onClick={props.onSelect}
                    />
                </S.Content>
            </S.Container>
        </Popover>
    );

};

class S {
    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static readonly Content = styled.div`
        width: 450px;
        max-height: 480px;
        padding: 4px 12px 8px 12px;
    `;
}