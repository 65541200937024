import React, {FunctionComponent} from "react";

import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textTransform: 'capitalize',
  fontSize: '0.85rem',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    },
}));

type Props = {
    onContinue: () => void,
    canGoBack?: boolean,
    onGoBack?: () => void,
    canSkip?: boolean,
    onSkip?: () => void
}

export const StepContinue: FunctionComponent<Props> = ({ onContinue, canGoBack = false, onGoBack, canSkip = false, onSkip }: Props) => {
    let backButton = canGoBack ?
        <Grid item>
            <LinkButton onClick={onGoBack}>Go back</LinkButton>
        </Grid> :
        <></>;

    let skipButton = canSkip ?
        <Grid item xs>
            <Grid container direction="row-reverse">
                <LinkButton onClick={onSkip}>Skip this step</LinkButton>
            </Grid>
        </Grid> :
        <></>;

    return <Stack spacing={ 1 }>
        <Button variant="contained" onClick={onContinue}>Continue</Button>
        <Grid container>
            {backButton}
            {skipButton}
        </Grid>
    </Stack>;
};