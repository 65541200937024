/**
 * Unhydrated references refer to the minimalist information required to then ask BE to fully populate references.
 * Specifically in this case, it's simply the list of FQNs.
 */
import {FQN} from "common/FQN";

export class UnhydratedReferences {

    static readonly JSON_KEY: string = 'unhydratedReferences';

    static fromJSON(json: string[]): UnhydratedReferences {
        return new UnhydratedReferences(json);
    }

    private readonly _fqns: Set<FQN>;

    constructor(fqns: string[]) {
        this._fqns = new Set(fqns.map(FQN.parse));
    }

    get refs(): Set<FQN> {
        return new Set(this._fqns);
    }

    toJSON(): string[] {
        return Array.from(this._fqns).map(fqn => fqn.toString());
    }

}




