import {BaseWidgetConfigPart} from "metadata/dashboard/widgets/config/BaseWidgetConfigPart";
import {HorizontalAlignment} from "metadata/dashboard/widgets/config/HorizontalAlignment";
import {VerticalAlignment} from "metadata/dashboard/widgets/config/VerticalAlignment";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";
import {JsonObject} from "common/CommonTypes";

/**
 * Properties for configuring Text-based widgets like alignment, color, background color, etc.
 */
export class TextConfigPart extends BaseWidgetConfigPart<TextConfigPart> {

    constructor(
        public readonly alignment: HorizontalAlignment,
        public readonly verticalAlignment: VerticalAlignment,
        public readonly fontColor: string,
        public readonly fontSize: string,
        defaults: TextConfigPart
    ) {
        super(defaults);
    }

    protected get properties(): (keyof TextConfigPart & string)[] {
        return ['alignment', 'verticalAlignment', 'fontColor', 'fontSize'];
    }

    get type(): WidgetConfigPartType {
        return WidgetConfigPartType.TEXT;
    }

    createInstance(props: Record<keyof TextConfigPart, any>, defaults: TextConfigPart): TextConfigPart {
        return new TextConfigPart(
            props.alignment,
            props.verticalAlignment,
            props.fontColor,
            props.fontSize,
            defaults
        );
    }

    static fromJSON(configJson: JsonObject, defaults: TextConfigPart = TextConfigPart.defaults()): TextConfigPart {
        return BaseWidgetConfigPart.fromJSONHelper(
            configJson,
            defaults,
            {
                'alignment': (value) => HorizontalAlignment.get(value),
                'verticalAlignment': (value) => VerticalAlignment.get(value)
            }
        );
    }

    static defaults(): TextConfigPart {
        return new TextConfigPart(
            HorizontalAlignment.LEFT_ALIGNED,
            VerticalAlignment.TOP_ALIGNED,
            'black',
            '14px',
            null
        );
    }
}