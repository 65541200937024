import {TabPath} from "app/TabPath";
import {ReactNode} from "react";

export type TabChange = (label?: string, path?: TabPath, hasChanges?: boolean) => void;

/**
 * An instance of a tab of TabType.
 *
 * @author zuyezheng
 */
export class Tab {

    static from(path: TabPath, context: Map<string, any> = new Map()): Tab {
        return new Tab(path, path.type.label(path), context, false);
    }

    constructor(
        // the public path in the URL
        public readonly path: TabPath,
        public readonly label: string,
        // sometimes we might open a new URL path within our app, but need to seed the tab with some extra context
        // that won't be in the url, e.g. the dataset of a new arcql since the arcql path will be where the actual
        // query gets saved
        public readonly context: Map<string, any>,
        // if the tab has unsaved changes
        public readonly hasChanges: boolean
    ) {}

    with(path: TabPath, label: string): Tab {
        return new Tab(path, label, this.context, this.hasChanges);
    }

    get icon(): ReactNode {
        return this.path.type.icon();
    }

}