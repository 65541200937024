import React, {ChangeEvent, FunctionComponent} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Optional} from "common/Optional";
import FormLabel from "@mui/material/FormLabel";
import styled from "@emotion/styled";
import {AssetIcon} from "app/components/icons/asset/AssetIcon";
import {AssetType} from "metadata/AssetType";
import {FontSizes} from "app/components/StyleVariables";
import Tooltip from "@mui/material/Tooltip";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import { FQN } from "common/FQN";

type Props = {
    disabled: boolean
    currentPersona: Optional<FQN>
    personas: AssetSearchResult[]
    onPersonaSelect: (selectedPersona: Optional<AssetSearchResult>) => void
};

const DEFAULT_VALUE: string = "default";

export const PersonaSelector: FunctionComponent<Props> = (props: Props) => {

    const handlePersonaChange = (e: ChangeEvent<HTMLInputElement>) => {
        const fqn = e.target.value;
        if (fqn === DEFAULT_VALUE) {
            props.onPersonaSelect(Optional.none());
        } else {
            for (let result of props.personas) {
                if (fqn === result.fqn.toString()) {
                    props.onPersonaSelect(Optional.of(result));
                    return;
                }
            }
        }
    };

    const selectEl = <S.Select
        value={props.currentPersona.map(p => p.toString()).getOr(DEFAULT_VALUE)}
        onChange={handlePersonaChange}
        aria-labelledby="view-as-label"
        className={"selector"}
        startAdornment={<AssetIcon assetType={AssetType.PERSONA} size={{ width: 18, height: 18 }}/>}
        disabled={props.disabled}
    >
        <S.MenuItem
            value={DEFAULT_VALUE}
            key={DEFAULT_VALUE}
            selected={props.currentPersona.isNone}
        >
            Default
        </S.MenuItem>
        {
            props.personas.map(p => {
                return <S.MenuItem
                    value={p.fqn.toString()}
                    key={p.fqn.toString()}
                    selected={p.fqn.equals(props.currentPersona.nullable)}
                >{p.label}</S.MenuItem>;
            })
        }
    </S.Select>;

    const wrappedSelect = props.disabled
        ? <Tooltip
            title="Dataset Masks can’t be changed on saved queries. To use a different Mask, please fork this query or start a new one."
            enterDelay={2000}
            placement="right"
        >
            {selectEl}
        </Tooltip>
        : selectEl;

    return <S.PersonaSelector>
        <FormControl variant="standard" fullWidth={true}>
            <S.FormLabel id="view-as-label">Viewing As</S.FormLabel>
            {wrappedSelect}
        </FormControl>
    </S.PersonaSelector>;
};

const S = {

    PersonaSelector: styled.div`
        padding: 8px 16px;
        display: flex;

        .selector {
            margin-top: 0px;

            .MuiInputBase-inputAdornedStart {
                padding-left: 8px;
            }
        }
    `,

    FormLabel: styled(FormLabel)`
        font-size: ${FontSizes.xxSmall};
    `,

    Select: styled(Select)`
        .MuiSelect-select {
            font-size: ${FontSizes.xSmall};
        }
    `,

    MenuItem: styled(MenuItem)`
        font-size: ${FontSizes.xSmall};
    `

};