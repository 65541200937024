import styled from "@emotion/styled";
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import {GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import {Optional} from "common/Optional";
import React, {useContext, useEffect, useState} from "react";
import {Colors} from "app/components/StyleVariables";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {Alert} from "metadata/trend/Alert";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {UserSubscription} from "metadata/subscription/UserSubscription";
import {ServiceProvider} from "services/ServiceProvider";
import {TrendsService} from "services/TrendsService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {SubscriptionService} from "services/SubscriptionService";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {UserContext} from "app/UserContext";
import {UserSubscriptionType} from "metadata/subscription/UserSubscriptionType";
import Button from "@mui/material/Button";
import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {IconButton} from "@mui/material";
import {SaveAlertDialog} from "app/components/trend/SaveAlertDialog";
import {GridPagination} from "@mui/x-data-grid-premium";
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import {LoadingMask} from "app/components/decoration/LoadingMask";

interface Props {
    className?: string;
    trend: ArcTrend;
    onSaveAlert: (savedAlert: Alert) => void;
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    ICON: 'icon',
    LABEL: 'label',
    TRIGGER_CONDITION: 'triggerCondition',
    ROWS_SELECTOR: 'rowsSelector',
    SUBSCRIBE: 'subscribe',
    ACTIONS: 'actions',
    RESULT: 'result',
};

/**
 * Display alert notifications affiliated with a trend + allow user to create / edit.
 *
 * @author fli
 */
export const TrendAlertsTable = (props: Props) => {
    const [alerts, setAlerts] = useState<Optional<Alert[]>>(Optional.none());
    const [selectedAlert, setSelectedAlert] = useState<Optional<Alert>>(Optional.none());
    const [subscriptions, setSubscriptions] = useState<Optional<UserSubscription[]>>(Optional.none());

    const [page, setPage] = useState<number>(0);
    const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
    const [reloadTrigger, setReloadTrigger] = useState<number>(0);

    const userContext = useContext(UserContext);
    const loggedInUserName = userContext.user.map(u => u.username).getOr(null);

    useEffect(() => {
        loadAlerts();
        loadSubscriptions();
    }, [page, reloadTrigger, props.trend]);

    const loadAlerts = () => {
        ServiceProvider.get(TrendsService)
            .listAlerts(props.trend.fqn)
            .then(
                response => response.match(
                    alerts => setAlerts(Optional.some(alerts)),
                    error => {
                        ServiceProvider.get(NotificationsService).publish(
                            'loadAlerts',
                            NotificationSeverity.ERROR,
                            `Failed to load alerts for trend: ${error.prettyPrint()}`
                        );
                    }
                )
            );
    };

    const loadSubscriptions = () => {
        ServiceProvider.get(SubscriptionService)
            .listSubscriptions()
            .then(
                response => response.match(
                    subscriptions => setSubscriptions(Optional.some(subscriptions)),
                    error => {
                        ServiceProvider.get(NotificationsService).publish(
                            'SingleTrendView.loadSubscriptions',
                            NotificationSeverity.ERROR,
                            `Failed to load subscriptions: ${error.prettyPrint()}`
                        );
                    }
                )
            );
    };

    const onRowClick = (params: GridRowParams) => {

    };

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        if (params.field === FIELD_IDENTIFIERS.ACTIONS) {
            event.stopPropagation();
        }
    };

    const reload = () => {
        setReloadTrigger(prev => prev + 1);
        // because user successfully updated an alert, revert back to page 0 where it should appear at the top of the list
        setPage(0);
    };

    const onToggleSubscription = (alert: Alert, subscribing: boolean) => {
        if (subscribing) {
            ServiceProvider.get(SubscriptionService)
                .subscribeToAlert(props.trend.fqn, alert.id)
                .then(
                    response => response.match(
                        _ => {
                            // refresh subscriptions
                            loadSubscriptions();
                        },
                        error => {
                            ServiceProvider.get(NotificationsService).publish(
                                'SingleTrendView.onToggleSubscription',
                                NotificationSeverity.ERROR,
                                `Failed to subscribe to alert: ${error.prettyPrint()}`
                            );
                        }
                    )
                );
        } else {
            ServiceProvider.get(SubscriptionService)
                .unsubscribeFromAlert(props.trend.fqn, alert.id)
                .then(
                    response => response.match(
                        _ => {
                            // refresh subscriptions
                            loadSubscriptions();
                        },
                        error => {
                            ServiceProvider.get(NotificationsService).publish(
                                'SingleTrendView.onToggleSubscription',
                                NotificationSeverity.ERROR,
                                `Failed to unsubscribe from alert: ${error.prettyPrint()}`
                            );
                        }
                    )
                );

        }
    };

    const onCancelSave = () => {
        setShowSaveDialog(false);
        setSelectedAlert(Optional.none());
    };

    const onSaveAlert = (alert: Alert) => {
        setShowSaveDialog(false);
        props.onSaveAlert(alert);
        reload();
    };

    const subscriptionMap = subscriptions.map(subs =>
        subs.filter(subscription => subscription.type === UserSubscriptionType.ALERT)
            .reduce((map, subscription) => {
                map.set(subscription.subscribedToId, subscription.isActive);
                return map;
            }, new Map<string, boolean>())
    ).getOr(new Map<string, boolean>());

    const columns: GridColDef[] = [{
        'field': FIELD_IDENTIFIERS.ICON,
        'headerName': '',
        'minWidth': 30,
        'width': 30,
        'renderCell': () =>
            <S.Icon>
                <NotificationsNoneIcon/>
            </S.Icon>
    }, {
        'field': FIELD_IDENTIFIERS.LABEL,
        'headerName': 'Name',
        'flex': 1.5
    }, {
        'field': FIELD_IDENTIFIERS.TRIGGER_CONDITION,
        'headerName': 'Triggers when...',
        'flex': 1
    }, {
        'field': FIELD_IDENTIFIERS.ROWS_SELECTOR,
        'headerName': 'Rows evaluated',
        'flex': 1
    }, {
        'field': FIELD_IDENTIFIERS.SUBSCRIBE,
        'headerName': '',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<Alert>) => {
            const alert = params.value;
            const isSubscribed = subscriptionMap.get(alert.id);
            return isSubscribed ?
                <S.Button
                    onClick={() => onToggleSubscription(alert, false)}
                    variant="contained"
                    startIcon={<CheckIcon/>}
                    sx={{backgroundColor: Colors.hyperarcYellow}}
                >
                    Subscribed
                </S.Button>
                :
                <S.Button
                    onClick={() => onToggleSubscription(alert, true)}
                    variant="outlined"
                    startIcon={<MailOutlineIcon/>}
                >
                    Subscribe
                </S.Button>;
        },
        'sortable': false
    }, {
        'field': FIELD_IDENTIFIERS.ACTIONS,
        'headerName': '',
        'minWidth': 50,
        'width': 50,
        'renderCell': (params: GridRenderCellParams<Alert>) => {
            const alert = params.value;
            const isOwner = loggedInUserName === alert.createdBy;
            return isOwner ?
                <IconButton
                    onClick={() => {
                        setSelectedAlert(Optional.some(alert));
                        setShowSaveDialog(true);
                    }}
                >
                    <EditOutlinedIcon/>
                </IconButton>
                :
                <></>;
        },
        'sortable': false
    }];

    const FooterWithAddNotification = () => {
        return (
            <S.Footer>
                <Button
                    variant="outlined"
                    startIcon={<AddAlertOutlinedIcon/>}
                    onClick={() => setShowSaveDialog(true)}
                >
                    Add Notification
                </Button>
                <GridPagination/>
            </S.Footer>
        );
    };

    const NoAlertsDisplay = () => {
        return (
            <S.NoRowsContainer>
                <S.NoRowsContent>
                    <span>Get notified when something changes.</span>
                    <S.CreateNotificationButton
                        color="primary"
                        variant="contained"
                        onClick={() => setShowSaveDialog(true)}
                    >
                        Create Notification
                    </S.CreateNotificationButton>
                </S.NoRowsContent>
            </S.NoRowsContainer>
        );
    };

    return (
        <div className={props.className}>
            {
                Optional.all([alerts, subscriptions]).map(([alerts, subscriptions]: [Alert[], UserSubscription[]]) => {
                    if (alerts.length === 0) {
                        return <NoAlertsDisplay/>;
                    }
                    return <S.ResultsTable
                        headerHeight={28}

                        columns={columns}
                        rows={
                            alerts
                                .map(alert => ({
                                    [FIELD_IDENTIFIERS.ID]: alert.id,
                                    [FIELD_IDENTIFIERS.LABEL]: alert.label,
                                    [FIELD_IDENTIFIERS.TRIGGER_CONDITION]: alert.triggerCondition.detail(alert.field),
                                    [FIELD_IDENTIFIERS.ROWS_SELECTOR]: alert.rowsSelector.label,
                                    [FIELD_IDENTIFIERS.SUBSCRIBE]: alert,
                                    [FIELD_IDENTIFIERS.ACTIONS]: alert,
                                    [FIELD_IDENTIFIERS.RESULT]: alert
                                }))
                        }
                        onRowClick={onRowClick}
                        onCellClick={handleCellClick}

                        pagination
                        paginationMode="server"
                        page={page}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        rowCount={alerts.length}
                        onPageChange={(newPage: number) => setPage(newPage)}
                        autoHeight={true}
                        rowHeight={40}
                        density="standard"
                        disableSelectionOnClick
                        disableColumnMenu
                        components={{
                            Footer: FooterWithAddNotification,
                            NoRowsOverlay: NoAlertsDisplay
                        }}
                    />;
                }).getOr(<LoadingMask/>)
            }
            {showSaveDialog && (
                <SaveAlertDialog
                    trend={props.trend}
                    alert={selectedAlert}
                    onCancel={onCancelSave}
                    onSave={onSaveAlert}
                />
            )}
        </div>
    );
};

class S {
    static readonly ResultsTable = styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-row {
            max-height: 30px;
        }

        .MuiDataGrid-columnSeparator {
            display: none
        }
    `;

    static readonly Icon = styled.div`
        display: flex;

        svg {
            width: 20px;
            height: 22px;
            color: ${Colors.iconPrimary};
        }
    `;

    static readonly Button = styled(Button)`
        height: 25px;
    `;

    static readonly Footer = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(224, 224, 224, 1)
    `;

    static readonly NoRowsContainer = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    static readonly NoRowsContent = styled.div`
        display: flex;
        flex-direction: column;
        padding: 40px 0px;
        align-items: center;
        gap: 10px;
    `;

    static readonly CreateNotificationButton = styled(Button)`
        text-transform: none;
        width: 200px;
    `;
}
