import {JsonObject} from "common/CommonTypes";
import {DatasetType} from "metadata/dataset/DatasetType";
import {SnowflakeColumnMetadata} from "metadata/dataset/snowflake/SnowflakeColumnMetadata";
import {ColumnMetadata} from "metadata/dataset/ColumnMetadata";
import {BigQueryColumnMetadata} from "metadata/dataset/bigquery/BigQueryColumnMetadata";
import {PersonalColumnMetadata} from "metadata/dataset/personal/PersonalColumnMetadata";

export class ColumnMetadataFactory {

    static fromJSON(json: JsonObject): ColumnMetadata {
        switch (json['type']) {
            case DatasetType.SNOWFLAKE.name:
                return new SnowflakeColumnMetadata();
            case DatasetType.BIG_QUERY.name:
                return new BigQueryColumnMetadata();
            case DatasetType.PERSONAL.name:
                return new PersonalColumnMetadata();
            default:
                throw new Error(`Unsupported column metadata type: ${json['type']}`);
        }
    }

}