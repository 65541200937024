import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VizSelection} from "engine/actor/VizSelection";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {ScatterData} from "app/visualizations/ScatterData";


/**
 * Scatter data for multiple series.
 *
 * @author zuyezheng
 */
export class MultiSeriesScatterData extends ScatterData {

    // shapes from triangle to octagons
    private static SHAPES: number[] = Array.from(Array(6), (_, x) => x + 3);

    // if there are more than 2 groupings, we need to collapse the first N-1 into a single grouping
    private readonly collapsedGroupIndices: number[];
    private readonly collapsedCategories: Map<string, string[]>;
    // last grouping we use as points in each series
    private readonly stackedGroupingIndex: number;

    constructor(response: ArcQLResponse, palette: string[], hasZ: boolean) {
        super(response, palette, hasZ);

        // for multi series, we use all by the last grouping to form unique series
        const result = this.response.result;
        this.collapsedGroupIndices = result.indexColumns.slice(0, -1).map(c => c.right);
        this.collapsedCategories = new Map(
            result.rows.map(row => [
                // generate a unique label which will also be used to dedupe since we will have multiple rows with the
                // same grouping label
                result.rowLabel(row, this.collapsedGroupIndices),
                // build a list of the individual grouping values
                this.collapsedGroupIndices.map(i => row[i])
            ])
        );
        this.stackedGroupingIndex = result.columnIndices.get(this.response.arcql.groupings.last.projectedAs);
    }

    /**
     * Every unique grouping value ("category" in cartesian charts) will get its own series.
     */
    dataset(selections: VizSelection): {[key: string]: any}[] {
        // create a new series for every collapsed category
        const datasets = new Map(
            Array.from(this.collapsedCategories.entries())
                .map(([category, _]: [string, string[]], categoryI: number) => [
                    category,
                    {
                        seriesName: category,
                        anchorSides: MultiSeriesScatterData.SHAPES[categoryI % MultiSeriesScatterData.SHAPES.length],
                        anchorRadius: 4,
                        anchorBorderColor: this.palette[categoryI % this.palette.length],
                        anchorBgColor: this.palette[categoryI % this.palette.length],
                        data: []
                    }
                ])
        );

        // go through the rows and add the data point to the appropriate category
        const result = this.response.result;
        result.forEachRow(
            (row: any[], index: string, formatters: ResultValueFormatter[]) => {
                const dataPoint = this.buildDataPoint(
                    row,
                    formatters[this.stackedGroupingIndex](row[this.stackedGroupingIndex]),
                    selections
                );

                // add the data point to the appropriate series
                datasets.get(result.rowLabel(row, this.collapsedGroupIndices))['data'].push(dataPoint);
            }
        );

        return Array.from(datasets.values());
    }

}