import * as React from "react";
import {FunctionComponent, useState} from "react";
import styled from "@emotion/styled";
import {SettingsTabSection} from "app/components/settings/SettingsTabSection";
import {Colors} from "app/components/StyleVariables";
import {HyperArcUser} from "metadata/HyperArcUser";
import {OrgMembership} from "metadata/OrgMembership";
import {Box, Chip} from "@mui/material";

interface Props {
    user: HyperArcUser
    memberships: OrgMembership[]
}

/**
 * General settings tab content.
 */
export const General: FunctionComponent<Props> = (props: Props) => {
    const [selectedOrg, setSelectedOrg] = useState<string>(props.memberships[0]?.name || '');

    return (
        <SettingsTabSection title={'Account'}>
            <S.AccountInfo>
                <S.InfoRow>
                    <S.Label>Username</S.Label>
                    <S.Value>{`@${props.user.username}`}</S.Value>
                </S.InfoRow>
                <S.InfoRow>
                    <S.Label>Email</S.Label>
                    <S.Value>{props.user.email}</S.Value>
                </S.InfoRow>
                <S.InfoRow>
                    <S.Label>
                        Organizations
                    </S.Label>
                    <S.Value>
                        <S.ChipBox>
                            {
                                props.memberships.map(m =>
                                    <Chip
                                        key={m.id}
                                        label={m.name}
                                        color={m.name === selectedOrg ? 'primary' : 'default'}
                                        onClick={() => setSelectedOrg(m.name)}
                                    />
                                )
                            }
                        </S.ChipBox>
                    </S.Value>
                </S.InfoRow>
                {selectedOrg && (
                    <S.InfoRow>
                        <S.Label>Roles</S.Label>
                        <S.Value>
                            <S.ChipBox>
                                {
                                    props.memberships.find(m => m.name === selectedOrg)
                                        ?.roles.map(role =>
                                        <S.RoleChip
                                            className={role.name}
                                            key={role.name}
                                            label={role.label}
                                        />
                                    )
                                }
                            </S.ChipBox>

                        </S.Value>
                    </S.InfoRow>
                )}
            </S.AccountInfo>
        </SettingsTabSection>
    );
};

class S {
    static readonly AccountInfo = styled.div`
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 32px;
    `;

    static readonly InfoRow = styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-start;
    `;

    static readonly Label = styled.span`
        color: ${Colors.textSecondary};
        width: 150px;
        flex-shrink: 0;
        margin-right: 12px;
    `;

    static readonly Value = styled.span`
        flex: 1;
    `;

    static readonly ChipBox = styled(Box)`
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    `;

    static readonly RoleChip = styled(Chip)`
        &.admin {
            background-color: lightblue;
        }
    `;
}
