import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {HyperGraphNodeHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {StringUtils} from "common/StringUtils";
import {HyperGraphNodeType} from 'metadata/hypergraph/nodes/HyperGraphNodeType';
import {HyperGraphRequestSerialized} from 'metadata/hypergraph/HyperGraphTypes';


export class ValidateHypothesis implements HyperGraphRequest {

    constructor(
        public readonly parent: string,
        public readonly hypothesisIndex: number,
        public readonly nTrends: number = 3,
        public readonly nDataPoints: number = 10
    ) { }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.VALIDATE_HYPOTHESIS;
    }

    get operation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.VALIDATION;
    }

    hypothesis(graph: HyperGraph): HyperGraphNodeHypothesis {
        return graph.get(this.parent).getHypothesis(this.hypothesisIndex);
    }

    label(graph: HyperGraph): string {
        // If we try to call this without a graph, just provide a static label
        return graph === null ?
            'Validate hypothesis' :
            `Validating hypothesis: ${StringUtils.unsentencify(this.hypothesis(graph).why)}.`;
    }

    get ommers(): string[] {
        return [this.parent];
    }

    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            parent: this.parent,
            hypothesisIndex: this.hypothesisIndex,
            nTrends: this.nTrends,
            nDataPoints: this.nDataPoints
        };
    }

    static fromJSON(json: HyperGraphRequestSerialized): ValidateHypothesis {
        return new ValidateHypothesis(
            json.parent,
            json.hypothesisIndex,
            json.nTrends,
            json.nDataPoints
        );
    }

}