import * as React from "react";
import {FunctionComponent, useRef, useState} from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import {ToolbarAction} from "app/components/toolbar/ToolbarAction";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";
import {TypedMenu} from "app/components/TypedMenu";
import {ToolbarActionsMenu} from "app/components/toolbar/ToolbarActions";

type Props = {
    menu: ToolbarActionsMenu
    onAction: (action: ToolbarAction) => void
}

/**
 * @author zuyezheng
 */
export const ActionMenu: FunctionComponent<Props> = (props: Props) => {

    const anchorRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const onMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const onMenuAction = (action: ToolbarAction) => {
        props.onAction(action);
        setShowMenu(false);
    };

    const onCloseMenu = () => {
        setShowMenu(false);
    };

    return <S.Menu variant={props.menu.isPrimary ? 'contained' : 'outlined'} ref={anchorRef}>
        {props.menu.topAction.comp(onMenuAction, false)}
        <Button className="menuArrow" size="small" onClick={onMenuClick}>
            <ArrowDropDownIcon/>
        </Button>
        {
            showMenu && <TypedMenu
                el={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                items={props.menu.menuActions}
                itemId={item => item.id}
                itemLabel={item => item.label}
                itemColor={item => item.customized.color}
                onSelect={onMenuAction}
                onClose={onCloseMenu}
            />
        }
    </S.Menu>;

};

export const S = {

    Menu: styled(ButtonGroup)`
        button.menuArrow {
            min-width: unset;
            padding-left: 4px;
            padding-right: 4px;

            &:hover {
                svg {
                    color: ${Colors.iconPrimary};
                }
            }
        }
    `

};
