import * as React from "react"
import {SVGProps} from "react"

const ChordIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M14.007 11.94c.109 0 .233-.016.342-.016.964 1.4 2.287 2.614 3.967 3.61a7.307 7.307 0 0 0 .684-2.63c-1.758-.046-3.033-.373-3.873-1.01 1.275-.047 2.566-.094 3.842-.156a7.026 7.026 0 0 0-.809-2.598c-.964.56-1.929 1.011-2.893 1.322a15 15 0 0 1 2.457-2.006 6.847 6.847 0 0 0-1.975-1.867l-1.727 3.127c-.046-.965.296-2.147 1.027-3.532A7.306 7.306 0 0 0 12.42 5.5c.047 1.867.436 3.562 1.136 5.04l-.203.358c-.56.078-1.12.124-1.68.124-.373 0-.746-.015-1.12-.062l-.217-.358c.637-1.509.933-3.22.886-5.086a6.864 6.864 0 0 0-2.582.808C9.433 7.678 9.838 8.83 9.838 9.81L7.956 6.776A6.849 6.849 0 0 0 6.089 8.75c.949.56 1.804 1.182 2.551 1.882a14.571 14.571 0 0 1-2.956-1.166A7.306 7.306 0 0 0 5 12.096c1.276 0 2.551-.016 3.842-.032-.793.67-2.053 1.074-3.81 1.198.092.902.372 1.79.808 2.582 1.618-1.073 2.893-2.348 3.78-3.795h.342a12.077 12.077 0 0 1 1.478 2.333l-.264.482c-1.634.172-3.298.747-4.947 1.712a7.11 7.11 0 0 0 1.991 1.866c.809-1.369 1.633-2.286 2.489-2.753l-1.742 3.127a7.391 7.391 0 0 0 2.629.684 14.98 14.98 0 0 1 .497-3.516c.358 1.09.576 2.24.67 3.485a7.026 7.026 0 0 0 2.597-.809l-1.898-3.033c.871.435 1.727 1.29 2.582 2.597.747-.528 1.323-1.197 1.82-1.96-1.617-.84-3.282-1.337-4.9-1.446l-.295-.482c.342-.856.793-1.65 1.338-2.396Zm-2.8.078a51.734 51.734 0 0 1 1.555-.031l-.746 1.337-.81-1.306Z"
        />
        <path stroke="#F8BF00" d="M12 21a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z"/>
    </svg>
);
export default ChordIcon;
