import * as React from "react";
import {ChangeEvent, FunctionComponent, useCallback, useEffect, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import {ServiceProvider} from 'services/ServiceProvider';
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {Colors, FontSizes} from "app/components/StyleVariables";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import {MetadataService} from "services/MetadataService";
import {AssetType} from "metadata/AssetType";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {ArcQLFilterValue} from "metadata/query/filterclause/FilterClause";
import {Optional} from "common/Optional";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import debounce from "lodash/debounce";
import ArcQLFilterClause from "metadata/query/filterclause/ArcQLFilterClause";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {ArcQL} from "metadata/query/ArcQL";
import {FQN} from "common/FQN";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import FormHelperText from "@mui/material/FormHelperText";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";

type Props = {
    arcqlBundle: ArcQLBundle;
    filter: ArcQLFilterClause;
    ordinal: number
    selected: ArcQLFilterValue
    onSelectedChange: (selected: ArcQLFilterValue) => void
    onClose: (change: Optional<EditorFilterChange>) => void
};

const PAGE_SIZE: number = 20;

/**
 * Search for ArcQLs and select one to use as a Dynamic Filter Set.
 */
export const DimensionDynamicFilterSetSearch: FunctionComponent<Props> = (props: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchParams, setSearchParams] = useState<AssetSearchParams>(
        new AssetSearchParams(
            PAGE_SIZE,
            searchTerm,
            [AssetType.ARCQL],
            'recent_all'
        )
    );
    const [resultColumn, setResultColumn] = useState<Optional<string>>(props.selected?.resultColumn || Optional.none());
    const [referencedArcQL, setReferencedArcQL] = useState<Optional<ArcQL>>(Optional.none());
    const [resultColumnError, setResultColumnError] = useState<string>('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        // handle case where selected arcql is removed or none
        if (!props.selected) {
            return;
        }
        ServiceProvider.get(MetadataService).fetchArcQL(FQN.parse(props.selected.arcql.fullyQualifiedName))
            .then(response => response.match(
                arcql => {
                    setReferencedArcQL(Optional.some(arcql));
                    // don't try to default to a result column if initial load of selected & result column already present
                    if (isInitialLoad && props.selected.resultColumn.isPresent) {
                        setIsInitialLoad(false);
                        return;
                    }
                    let defaultResultColumn: Optional<string> = Optional.none();
                    const fieldsSelection = ArcQLFilterClause.extractFieldsForDynamicFilterSet(arcql);
                    if (fieldsSelection.length > 0) {
                        defaultResultColumn = Optional.some(fieldsSelection[0].projectedAs);
                    }
                    validateResultColumnSelection(arcql, defaultResultColumn);
                },
                () => {
                    ServiceProvider.get(NotificationsService).publish(
                        'dimensionArcQLSearch',
                        NotificationSeverity.ERROR,
                        `Could not load columns for ${props.selected.arcql.fullyQualifiedName}`
                    );
                }
            ));
    }, [props.selected?.arcql.fullyQualifiedName]);

    const search = (params: AssetSearchParams) => {
        return ServiceProvider.get(MetadataService).assetSearch(params);
    };

    const updateSearchParams = useCallback(debounce(
            (searchTerm: string) => {
                setSearchParams(
                    new AssetSearchParams(PAGE_SIZE, searchTerm, [AssetType.ARCQL], 'recent_all')
                );
            }, 500),
        []
    );

    const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        updateSearchParams(newSearchTerm);
    };


    const onSelect = (arcqlAsset: AssetSearchResult) => {
        // if the selected arcql is the same as the one already selected, treat it as a deselection
        if (props.selected && props.selected.arcql.fullyQualifiedName === arcqlAsset.fullyQualifiedName) {
            props.onSelectedChange(null);
            return;
        }
        props.onSelectedChange({arcql: arcqlAsset, resultColumn: Optional.none()});
    };

    const onResultColumnChange = (e: SelectChangeEvent<string>) => {
        const columnSelected: Optional<string> = e.target.value === '' ? Optional.none() : Optional.some(e.target.value);
        validateResultColumnSelection(referencedArcQL.get(), columnSelected);
    };

    const validateResultColumnSelection = (arcql: ArcQL, selectedResultColumn: Optional<string>) => {
        if (ArcQLFilterClause.extractFieldsForDynamicFilterSet(arcql).length > 1 && selectedResultColumn.isNone) {
            setResultColumnError('A result column must be selected as more than one is present.');
        }
        setResultColumnError('');
        setResultColumn(selectedResultColumn);
        props.onSelectedChange({arcql: props.selected?.arcql, resultColumn: selectedResultColumn});
    };

    return <>
        <S.ControlsLabel>the results of:</S.ControlsLabel>
        <S.Controls>
            <S.SearchInput
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                }}
                value={searchTerm}
                onChange={onSearchTermChange}
                size='small'
                fullWidth
                autoComplete='off'
                placeholder="Search Queries..."
            />
        </S.Controls>
        <S.ResultsContainer>
            <AssetSearchResultsTable
                searchParams={searchParams}
                onSelect={onSelect}
                rowType={SearchResultsRowType.SIMPLE_LIST_ITEM}
                onSearch={search}
                bordered={true}
                disableAutoHeight={true}
                selectedRows={props.selected ? [props.selected.arcql] : []}
                bubbleUpSelected={true}
            />
        </S.ResultsContainer>
        <S.ResultColumnSelector>
            <S.FormControl variant="outlined" size="small" error={resultColumnError !== ''}>
                <S.InputLabel id="result-column-label" disabled={!referencedArcQL.isPresent}>
                    Result Column
                </S.InputLabel>
                <S.Select
                    id="result-column-select"
                    labelId="result-column-label"
                    label="Result Column"
                    value={resultColumn.getOr('')}
                    onChange={onResultColumnChange}
                    disabled={!referencedArcQL.isPresent}
                >
                    <MenuItem value="">
                        None
                    </MenuItem>
                    {referencedArcQL.map(ql =>
                        ArcQLFilterClause.extractFieldsForDynamicFilterSet(ql).map(field =>
                            <MenuItem key={field.projectedAs} value={field.projectedAs}>
                                {field.projectedAs}
                            </MenuItem>
                        )
                    ).getOr([])}
                </S.Select>
                {resultColumnError && (
                    <S.FormHelperText>
                        {resultColumnError}
                    </S.FormHelperText>
                )}
            </S.FormControl>
        </S.ResultColumnSelector>
    </>;

};

const S = {
    ControlsLabel: styled.div`
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.small};
    `,
    Controls: styled.div`
        display: flex;
    `,
    SearchInput: styled(TextField)`
        width: 250px;

        input {
            font-size: ${FontSizes.small};
        }
    `,
    ResultsContainer: styled.div`
        margin-top: 8px;
        width: 100%;
        height: 280px;
    `,
    ResultColumnSelector: styled.div`
        margin-top: 16px;
        display: flex;
        width: 220px;
    `,
    FormControl: styled(FormControl)`
        width: 100%;
    `,
    FormHelperText: styled(FormHelperText)`
        margin-left: 8px;
        width: 400px;
    `,
    Select: styled(Select)`
        &.Mui-disabled {
            background-color: ${Colors.disabledGrey};
        }
    `,
    InputLabel: styled(InputLabel)`
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
        transform: translate(14px, 9px) scale(1);

        &.MuiInputLabel-shrink {
            transform: translate(14px, -9px) scale(0.75);
        }
    `
};