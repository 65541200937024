import {Enum} from "common/Enum";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {ArcQL} from "metadata/query/ArcQL";

/**
 * Actions for Filter Panel.
 *
 */
export class FilterPanelAction extends Enum {

    static readonly EDIT_FILTER_EXPRESSION = new this(
        'editExpression',
        'Edit Filter Expression',
        (arcQL: ArcQL) => arcQL.filtersFor(false).size == 0
    );
    static readonly EDIT_AGGREGATE_FILTER_EXPRESSION = new this(
        'editAggregateExpression',
        'Edit Aggregate Filter Expression',
        (arcQL: ArcQL) => arcQL.filtersFor(true).size == 0
    );
    static readonly RESET_FILTER_EXPRESSION = new this(
        'resetExpression',
        'Reset Filter Expression'
    );
    static readonly RESET_AGGREGATE_FILTER_EXPRESSION = new this(
        'resetAggregateExpression',
        'Reset Aggregate Filter Expression'
    );


    static visibleActions(filters: ArcQLFilters, aggregateFilters: ArcQLFilters): FilterPanelAction[] {
        const filterAction = filters.hasExpression ? FilterPanelAction.RESET_FILTER_EXPRESSION : FilterPanelAction.EDIT_FILTER_EXPRESSION;
        const aggregateFilterAction = aggregateFilters.hasExpression ? FilterPanelAction.RESET_AGGREGATE_FILTER_EXPRESSION : FilterPanelAction.EDIT_AGGREGATE_FILTER_EXPRESSION;
        return [filterAction, aggregateFilterAction];
    }

    constructor(
        name: string,
        public readonly label: string,
        public readonly isDisabled: (arcQL: ArcQL) => boolean = () => false
    ) {
        super(name);
    }
}

FilterPanelAction.finalize();