import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {LayoutLike} from "metadata/dashboard/DashboardLayouts";
import {Tuple} from "common/Tuple";
import {DeleteWidgetLayout} from "metadata/dashboard/changes/DeleteWidgetLayout";

export class CreateWidgetLayout implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly widgetId: string,
        public readonly layout: LayoutLike
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        const newMetadata = metadata.with({
            layouts: metadata.layouts.withUpdatesOnDefault(
                layout => layout.with(this.widgetId, this.layout)
            )
        });

        return Tuple.of(newMetadata, new DeleteWidgetLayout(this.widgetId));
    }

    describe(): string {
        return `Created widget layout for ${this.widgetId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}