import AceEditor from "react-ace";
import * as React from "react";
import {FunctionComponent} from "react";

import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-github";
import {MarkdownConfigPart} from "metadata/dashboard/widgets/config/markdown/MarkdownConfigPart";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";

interface EditorProps extends ConfigEditorProps<MarkdownConfigPart> {
}

export const MarkdownConfigEditor: FunctionComponent<EditorProps> = (props: EditorProps) => {

    return <AceEditor
        mode="markdown"
        theme="github"
        width="100%"
        minLines={2}
        maxLines={10}
        value={props.config.getValue('content')}
        onChange={(changedMarkdown: string) => {
            props.onConfigPropertyChange(props.config.with({content: changedMarkdown}));
        }}
        editorProps={{
            $blockScrolling: true
        }}
        setOptions={{
            wrap: true,
            showPrintMargin: false,
            useWorker: false,
            showGutter: false,
            showLineNumbers: false
        }}
    />;

};