import {JsonObject} from "common/CommonTypes";
import {SessionType} from "metadata/session/SessionType";


/** Define ArcSession JSON structure */
export type ArcSessionJson = {
    id: string;
    appPath: string;
    type: string;
    referenceId: string;
    state: JsonObject;
    createdOn: string;
}


/**
 * Session metadata.
 */
export class ArcSession {

    static fromJson(json: ArcSessionJson): ArcSession {
        return new ArcSession(
            json.id,
            json.appPath,
            SessionType.get(json.type),
            json.referenceId,
            json.state,
            json.createdOn
        );
    }

    /**
     * To create a new session.
     */
    static forCreation(
        appPath: string,
        type: SessionType,
        referenceId: string,
        state: JsonObject
    ) {
        return new ArcSession(
            null,
            appPath,
            type,
            referenceId,
            state,
            null
        );
    }

    constructor(
        public readonly id: string,
        // What path this session references to
        public readonly appPath: string,
        // What type of session this is
        public readonly type: SessionType,
        public readonly referenceId: string,
        // Will use the type to eventually deserialize to correct object
        public readonly state: JsonObject,
        public readonly createdOn: string
    ) {

    }

    toJSON(): ArcSessionJson {
        return {
            id: this.id,
            appPath: this.appPath,
            type: this.type.toJSON(),
            state: this.state,
            referenceId: this.referenceId,
            createdOn: this.createdOn
        };
    }
}