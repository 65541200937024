import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import React, {FunctionComponent} from "react";
import {AutoCompleteS} from "app/components/search/autocomplete/AutoCompleteS";
import {AutoCompleteItemProps} from "app/components/search/AutoCompleteItemProps";
import { AccountResult } from "metadata/account/AccountResult";

/**
 * Autocomplete item for an account.
 */
export const AccountAutoCompleteItem: FunctionComponent<AutoCompleteItemProps<AccountResult>> = (props: AutoCompleteItemProps<AccountResult>) => {

    return <AutoCompleteS.Item
        key={props.result.id}
        onClick={() => props.onClick(props.result)}
        selected={props.selected}
    >
        <ListItemIcon>
            {/*TODO: actually replace with user/org profile pic. For now, just uses initials*/}
            < Avatar
                sx={{
                    textTransform: 'uppercase',
                    fontSize: 16,
                    fontWeight: 800,
                    height: 30,
                    width: 30
                }}
            >{
                props.result.name.substring(0, 1)
            }</Avatar>
        </ListItemIcon>
        < ListItemText primary={`@${props.result.name}`} />
    </AutoCompleteS.Item>;

};