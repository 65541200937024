import * as React from "react";
import {ReactNode} from "react";
import {DatasetType} from "metadata/dataset/DatasetType";
import {
    ConnectionStepperHandler,
    EnterDetailProps,
    TestConnectionProps
} from "app/components/settings/connections/ConnectionStepperHandler";
import {EnterBigQueryConnection} from "app/components/settings/connections/bigquery/EnterBigQueryConnection";
import {TestBigQueryConnection} from "app/components/settings/connections/bigquery/TestBigQueryConnection";

/**
 * Handler for a BigQuery connection in the connection stepper.
 */
export class BigQueryConnectionStepperHandler implements ConnectionStepperHandler {

    connectionType: DatasetType = DatasetType.SNOWFLAKE;

    enterDetailsStep(props: EnterDetailProps): ReactNode {
        return <EnterBigQueryConnection
            {...props}
        />;
    }

    testConnectionStep(props: TestConnectionProps): ReactNode {
        return <TestBigQueryConnection
            {...props}
        />;
    }


}