import {VizSelection} from "engine/actor/VizSelection";
import {CartesianChartConfig} from "app/visualizations/config/CartesianChartConfig";
import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";


export class AreaChartConfig extends CartesianChartConfig {

    get selectionType(): SelectionType {
        return SelectionType.NONE;
    }

    get isStacked(): boolean {
        return this.config.get('stack').getOr(true);
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        return {
            type: this.isStacked ? 'stackedarea2d' : 'msarea',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: this.config.get('showLegend').getOr(true),

                        xAxisName: this.config.emptyableString('xAxisName')
                            .getOr(this.xAxisName(this.isHierarchical)),
                        yAxisName: this.config.emptyableString('yAxisName')
                            .getOr(this.response.arcql.fields.fields[0].as),

                        showValues: this.config.get('showValues').getOr(false),

                        numVisiblePlot: Math.round(size[0]/40),
                        labelDisplay: 'rotate',
                        useEllipsesWhenOverflow: '1',
                        slantLabels: true
                    }
                ),
                categories: this.data.categories,
                dataset: this.data.dataset(selections)
            }
        };
    }

}