import * as React from "react";
import {ChangeEvent, FunctionComponent, ReactNode, useContext, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {Folders} from "metadata/project/Folders";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {SaveHandler} from "metadata/SaveHandler";
import {Asset} from "metadata/Asset";
import {DialogS} from "app/DialogS";
import {UserContext} from "app/UserContext";
import {FolderResult} from "metadata/project/FolderResult";
import {LoadingMask} from "app/components/decoration/LoadingMask";
import {ProjectInput} from "app/components/form/ProjectInput";
import {AccountInput} from "app/components/form/AccountInput";
import {AssetUrlInput} from "app/components/form/AssetUrlInput";
import {LabelInput} from "app/components/form/LabelInput";
import {StringUtils} from "common/StringUtils";
import {useAssetFormState} from "app/components/form/AssetFormState";

type Props = {
    asset: Asset
    fqn: FQN
    disableAccountSelection?: boolean

    saveHandler: SaveHandler<Asset>
    onCancel: () => void
    onSave: (asset: Asset) => void

    disclaimer?: ReactNode
}

/**
 * Dialog for saving a new asset or saving an existing asset as a fork.
 */
export const SaveAsDialog: FunctionComponent<Props> = (props: Props) => {

    const [description, setDescription] = useState<string>(props.asset.description);

    const userContext = useContext(UserContext);
    const loggedInUserName = userContext.user.map(u => u.username).getOr(null);

    const {
        account,
        project,
        label,
        name,
        accounts,
        folders,
        saveErrors,
        setAccount,
        setProject,
        setLabel,
        setName,
        setSaveErrors
    } = useAssetFormState({
        fqn: props.fqn,
        loggedInUserName: loggedInUserName,
        initialLabel: props.asset.label
    });

    const onSave = () => {
        props.saveHandler.save(new FQN(account, project, props.fqn.type, name), label, description)
            .then(r => r.match(
                asset => props.onSave(asset),
                errors => setSaveErrors(errors)
            ));
    };

    const dialogTitle = props.asset.isExisting ?
        `Fork ${props.asset.assetType.label} as...` :
        `Save ${props.asset.assetType.label} as...`;

    return Optional.all([accounts, folders]).map(([accounts, folders]: [string[], Folders]) =>
        <Dialog
            open={true}
            onClose={props.onCancel}
            PaperProps={{
                sx: {
                    width: "600px"
                }
            }}
        >
            <DialogS.Title>{dialogTitle}</DialogS.Title>
            <DialogS.Content dividers>
                <DialogS.Body>
                    {props.disclaimer}
                    <DialogS.InputRow>
                        <LabelInput
                            label={label}
                            name={name}
                            setLabel={setLabel}
                            setName={setName}
                            saveErrors={saveErrors}
                        />
                    </DialogS.InputRow>
                    <DialogS.InputRow>
                        <FormControl fullWidth>
                            <FormLabel id="description-label" sx={{mb: 0.5}}>Description</FormLabel>
                            <TextField
                                margin="none"
                                size="small"
                                id="description"
                                aria-labelledby="description-label"
                                placeholder={`What's the ${props.asset.assetType.label.toLowerCase()} about?`}
                                value={description}
                                error={saveErrors.map(errors => errors.has("description")).getOr(false)}
                                helperText={saveErrors.map(errors => errors.get("description")).getOr(null)}
                                fullWidth={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </FormControl>
                    </DialogS.InputRow>
                    <DialogS.SelectorRow>
                        <AccountInput
                            account={account}
                            accounts={accounts}
                            onSelectAccount={setAccount}
                            disabled={props.disableAccountSelection}
                        />
                        <ProjectInput
                            loggedInUserName={loggedInUserName}
                            folders={folders}
                            selected={project}
                            onSelectFolder={(folder: FolderResult) => setProject(folder.name)}
                        />
                    </DialogS.SelectorRow>
                    <DialogS.InputRow>
                        <AssetUrlInput
                            account={account}
                            project={project}
                            name={name}
                            fqn={props.fqn}
                            onEdit={setName}
                            saveErrors={saveErrors}
                        />
                    </DialogS.InputRow>
                </DialogS.Body>
            </DialogS.Content>
            <DialogS.Actions>
                <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={onSave}
                    disabled={StringUtils.isAbsent(name) || StringUtils.isAbsent(label) || StringUtils.isAbsent(project)}
                >
                    Save
                </Button>
            </DialogS.Actions>
        </Dialog>
    ).getOrElse(() =>
        <Dialog
            open={true}
            PaperProps={{
                sx: {
                    width: "600px"
                }
            }}
        >
            <LoadingMask containerDimensions={['100%', '200px']}/>
        </Dialog>
    );

};
