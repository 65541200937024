import * as React from "react";
import {ComponentType, FunctionComponent} from "react";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import styled from "@emotion/styled";
import {ClassNameFlags, StringUtils} from "common/StringUtils";
import {Optional} from "common/Optional";

export type Props = {
    className?: string,
    isCompact?: boolean
    operation: HyperGraphNodeOperation,

    onClick?(e: React.MouseEvent): void
}

/**
 * Icon representing the operation type of the node.
 *
 * @author zuyezheng
 */
export const ProcessFlowIcon: FunctionComponent<Props> = (props: Props) => {

    const iconSpec: Optional<IconSpec> = (() => {
        if (IconMap.has(props.operation)) {
            return Optional.some(IconMap.get(props.operation));
        } else {
            return Optional.none();
        }
    })();

    const containerClassNames: ClassNameFlags = {
        'compact': props.isCompact
    };
    if (props.className != null) {
        containerClassNames[props.className] = true;
    }

    return iconSpec.map(spec =>
        <S.Container
            {...spec}
            className={StringUtils.toClassName(containerClassNames)}
            onClick={e => props.onClick(e)}
        >
            <spec.icon />
        </S.Container>
    ).nullable;

};

class S {

    static readonly Container = styled.div((props: IconSpec) => ({
        borderRadius: '4px',
        width: '30px',
        height: '30px',
        border: `1px solid ${props.borderColor}`,
        backgroundColor: props.backgroundColor,
        display: 'flex',
        alignItems: 'center',

        'svg': {
            color: 'rgba(0, 0, 0, .54)',
            flex: '1',
            height: '16px'
        },

        '&.compact': {
            width: '24px',
            height: '24px',
            'svg': {
                height: '14px'
            }
        }
    }));

}

type IconSpec = {
    icon: ComponentType,
    borderColor: string
    backgroundColor: string,
}

const IconMap = new Map([
    [
        HyperGraphNodeOperation.USER,
        {
            icon: FaceOutlinedIcon,
            borderColor: "#97C897",
            backgroundColor: "rgba(151, 200, 151, 0.60)"
        }
    ], [
        HyperGraphNodeOperation.DISCOVERY,
        {
            icon: AutoAwesomeOutlinedIcon,
            borderColor: "#F8BF00",
            backgroundColor: "rgba(248, 191, 0, 0.40)"
        }
    ], [
        HyperGraphNodeOperation.VALIDATION,
        {
            icon: DownloadDoneOutlinedIcon,
            borderColor: "#9575CD",
            backgroundColor: "rgba(149, 117, 205, 0.25)"
        }
    ], [
        HyperGraphNodeOperation.CONSOLIDATION,
        {
            icon: PlaylistAddCheckOutlinedIcon,
            borderColor: "#E91E63",
            backgroundColor: "rgba(233, 30, 99, 0.25)"
        }
    ], [
        HyperGraphNodeOperation.MEASURES,
        {
            icon: InsightsOutlinedIcon,
            borderColor: "#97C897",
            backgroundColor: "rgba(151, 200, 151, 0.60)"
        }
    ], [
        HyperGraphNodeOperation.GROUPS,
        {
            icon: GroupWorkOutlinedIcon,
            borderColor: "#4397F8",
            backgroundColor: "rgba(67, 151, 248, 0.50)"
        }
    ], [
        HyperGraphNodeOperation.SEGMENTS,
        {
            icon: FilterAltOutlinedIcon,
            borderColor: "#BDBDBD",
            backgroundColor: "#D2D2D2"
        }
    ], [
        HyperGraphNodeOperation.COMPARE,
        {
            icon: CompareOutlinedIcon,
            borderColor: "#E91E63",
            backgroundColor: "rgba(233, 30, 99, 0.25)"
        }
    ]
]);