import * as React from "react";
import {FunctionComponent} from "react";
import {InlineEditorS as S} from "app/dashboard/widgets/InlineEditorS";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {LinkConfigPart} from "metadata/dashboard/widgets/config/link/LinkConfigPart";
import Tooltip from "@mui/material/Tooltip";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

interface EditorProps extends ConfigEditorProps<LinkConfigPart> {

}

/**
 * Toolbar for editing link widget such as underlining the link or not.
 */
export const LinkConfigToolbar: FunctionComponent<EditorProps> = (props: EditorProps) => {

    // change styling of the link, i.e. underlined or not
    const onUnderlinedToggle = () => {
        props.onConfigPropertyChange(
            props.config.with({underlined: !props.config.getValue('underlined')})
        );
    };

    return <>
        <Tooltip title="Underline link" placement="top" arrow>
            <S.ToggleButton
                value="check"
                selected={props.config.getValue('underlined')}
                onChange={onUnderlinedToggle}
            >
                <FormatUnderlinedIcon/>
            </S.ToggleButton>
        </Tooltip>

    </>;
};