import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useState} from "react";
import {VisualizationProps} from "app/visualizations/ArcVisualization";
import {TableData} from "app/visualizations/data/TableData";
import {PivotedTableData} from "app/visualizations/data/PivotedTableData";
import {TableDataProvider} from "app/visualizations/data/TableDataProvider";
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import {GridSelectionModel} from "@mui/x-data-grid/models/gridSelectionModel";
import {VizSelection} from "engine/actor/VizSelection";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";

/**
 * @author zuyezheng
 */
export const ArcTable: FunctionComponent<VisualizationProps> = (props: VisualizationProps) => {

    const [selections, setSelections] = useState<VizSelection>(VizSelection.none());

    // link the selectable to let it manage selections if set
    useEffect(() => {
        if (props.selectable == null) {
            return;
        }

        props.selectable.linkSelectable(setSelections, null);
        return () => props.selectable.unlinkSelectable();
    }, [props.selectable]);

    const [columns, rows] = useMemo(
        () => {
            const tableData: TableDataProvider = props.config.boolean('pivot')
                ? new PivotedTableData(props.queryResponse)
                : new TableData(props.queryResponse);

            return [tableData.columns, tableData.rows(props.config.boolean('compactNumbers'))]
        },
        [props.queryResponse, props.config.boolean('pivot'), props.config.boolean('compactNumbers')]
    );

    const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
        // can't select when pivoted since a row is actually a column
        if (props.config.get('pivot').getOr(false)) {
            return;
        }

        // convert the selected row id into a discrete grouping values for that row and tell the selectable
        props.selectable?.setDiscrete(
            selectionModel.map(
                s => {
                    const selectedRow = props.queryResponse.result.indexedRows.get(s as string);
                    return props.queryResponse.result.categoryColumns.map(c => selectedRow[c.right])
                }
            )
        );
    };

    // convert the selections into a model the table can understand
    const selectionModel = selections.rows().map(
        row => props.queryResponse.result.indexForRow(row, 0)
    );

    // selections enabled if not pivot and selectable has selections
    const canSelect = !props.config.get('pivot').getOr(false) && props.selectable?.canSelect();

    /**
     * TODO ZZ Props for grouped rows which could be preferred in some cases, make config based.
     *
     * rowGroupingColumnMode="multiple"
     * rowGroupingModel={tableData.rowGroupings}
     * columnVisibilityModel={tableData.columnVisibility}
     */

    return <S.Container>
        {
            props.config.emptyableString('title')
                .map(v => <S.Title>{v}</S.Title>)
                .nullable
        }
        <S.Table>
            <DataGridPro
                columns={columns}
                rows={rows}
                pageSize={2000}
                density="compact"

                checkboxSelection={canSelect}
                disableMultipleSelection={true}
                selectionModel={selectionModel}
                onSelectionModelChange={onSelectionModelChange}

                disableColumnMenu
                disableColumnReorder
            />
        </S.Table>
        {
            props.config.emptyableString('footer')
                .map(v => <S.Footer>{v}</S.Footer>)
                .nullable
        }
    </S.Container>;

};

export const S = {

    Container: styled.div`
        position: relative;
        height: 100%;
        width: 100%;
        background-color: inherit;
        display: flex;
        flex-direction: column;
    `,

    Title: styled.div`
        font-size: 14px;
        font-weight: 600;
        color: ${Colors.textSecondary};
        padding: 12px 8px;
    `,

    Table: styled.div`
        flex: 1;

        .MuiDataGrid-footerContainer {
            min-height: 40px;
        }
    `,

    Footer: styled.div`
        font-size: ${FontSizes.small};
        color: ${Colors.textTertiary};
        padding-top: 8px;
    `

};
