import * as React from "react";
import {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {DashboardDnDSource} from "app/dashboard/components/DashboardDnDSource";
import {DragAndDropData} from "common/DragAndDropData";
import {Colors, FontSizes} from "app/components/StyleVariables";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {WidgetTypeExtended} from "app/dashboard/WidgetTypeExtended";

interface Props {
    className?: string

    // handler for before a widget drag or click starts
    onMouseDown: (widgetType: WidgetType) => void
    // click instead of a drag
    onClick: (widgetType: WidgetType) => void
    onAddGlobalFilter: () => void
}

/**
 * Palette of enumerated dashboard widget types (charts, tables, markdown, etc.) for users to compose their masterpiece.
 *
 * @author zuyezheng
 */
export const DashboardPalette: FunctionComponent<Props> = (props: Props) => {

    const onDragStart = (event: React.DragEvent) => {
        DragAndDropData.fromAttributes<DashboardDnDSource>(event.currentTarget, 'data-drag-id', DashboardDnDSource)
            .setData(event);
    };

    return <S.Palette className={props.className}>
        {
            WidgetTypeExtended.enums().map(
                (extended: WidgetTypeExtended) =>
                    <S.Widget
                        key={extended.name}
                        data-drag-id={DashboardDnDSource.PALETTE.of(extended.name).serialize}
                        onMouseDown={() => props.onMouseDown(extended.widgetType)}
                        onDragStart={onDragStart}
                        onClick={() => props.onClick(extended.widgetType)}
                        draggable
                    >
                        {extended.widgetComponentFactory.icon()}
                        <S.Label>{extended.widgetType.label}</S.Label>
                    </S.Widget>
            )
        }
        <S.Widget
            key='globalFilter'
            onClick={() => props.onAddGlobalFilter()}
        >
            <FilterAltOutlinedIcon/>
            <S.Label>Global Filter</S.Label>
        </S.Widget>
    </S.Palette>;

};

const S = {

    Palette: styled.div`
        display: flex;
    `,

    Widget: styled.div`
        padding: 8px 20px;
        display: flex;
        align-items: center;
        border-right: 1px solid ${Colors.borderGrey};
        cursor: pointer;
        color: ${Colors.textSecondary};
        
        > svg {
            height: 18px;
            width: 18px;
            color: ${Colors.iconSecondary};
        }
    `,

    Label: styled.div`
        font-size: ${FontSizes.small};
        padding-left: 8px;
    `

};
