import * as React from "react";
import {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import styled from "@emotion/styled";
import {ArcQLFilterValue, RawFilterValue} from "metadata/query/filterclause/FilterClause";
import TextField from "@mui/material/TextField";
import {FilterEditorS} from "app/query/filters/FilterEditor";
import {FontSizes} from "app/components/StyleVariables";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {DimensionValueSearch} from "app/query/filters/DimensionValueSearch";
import {DimensionFilterMode} from "app/query/filters/DimensionFilterMode";
import {DimensionFilterSetSearch} from "app/query/filters/DimensionFilterSetSearch";
import {DimensionFilterEditorProps} from "app/query/filters/MeasureFilterEditorProps";

import FilterAlt from "@mui/icons-material/FilterAlt";
import FilterSetIcon from "app/components/icons/asset/FilterSetIcon";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {DimensionDynamicFilterSetSearch} from "app/query/filters/DimensionDynamicFilterSetSearch";
import ArcQLFilterClause from "metadata/query/filterclause/ArcQLFilterClause";
import DynamicFilterSetIcon from "app/components/icons/asset/DynamicFilterSetIcon";

import {ToggleS} from "app/components/input/ToggleS";
import {DimensionFilterHelper, FilterState} from "app/query/filters/DimensionFilterHelper";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {FilterSetFilterClause} from "metadata/query/filterclause/FilterSetFilterClause";
import AceEditor from "react-ace";
import language_tools from "ace-builds/src-noconflict/ext-language_tools";
import {CommonS} from "app/components/CommonS";
import {HigherOrderType} from "metadata/HigherOrderType";

// simple operators are just text inputs
export const SIMPLE_OPERATORS = new Set([
    FilterOperator.EQUAL,
    FilterOperator.TERM
]);

export const DimensionFilterEditor: FunctionComponent<DimensionFilterEditorProps> = (props: DimensionFilterEditorProps) => {

    // short circuit if the column is not found, maybe the dataset changed?
    const column = props.arcqlBundle.dataset.get(props.filter.column);
    if (column == null) {
        return <></>
    }

    const [dimensionFilterMode, setDimensionFilterMode] = useState<DimensionFilterMode>(
        DimensionFilterMode.from(props.filter.type)
    );

    // otherwise do some initialization and figure out which operators are supported
    const supportedOperators = useMemo(() => {
        const hasTextIndex = column.hasStringIndex;
        const isTextBlob = column.higherOrderType.nullable === HigherOrderType.BLOB;

        return dimensionFilterMode.operators.filter(operator => {
            // term operators only supported with text indices
            if (operator === FilterOperator.TERM) {
                return hasTextIndex;
            }

            // IN and NOT_IN operators not supported with text blobs
            if (operator === FilterOperator.IN || operator === FilterOperator.NOT_IN) {
                return !isTextBlob;
            }

            return true;
        });
    }, [props.arcqlBundle.dataset, props.filter.column]);

    // figure out the state, defaulting to the first supported operator if none has been selected
    const [modeToFilterState, setModeToFilterState] = useState<Map<DimensionFilterMode, FilterState>>(
        DimensionFilterHelper.filterModeState(props.filter, supportedOperators)
    );

    const filterState = modeToFilterState.get(dimensionFilterMode);
    const firstFilterValue = filterState.values.values().next().value;

    useEffect(() => {
        // add some auto complete options to ace editor
        language_tools.setCompleters([{
            getCompletions: (editor: any, session: any, pos: any, prefix: string, callback: any) => {
                const prefixNormalized = prefix.toLowerCase();

                // autocomplete to any columns in the dataset
                const matchedColumns = props.arcqlBundle.dataset.allFields()
                    .filter(f =>
                        f.label.toLowerCase().startsWith(prefixNormalized)
                        || f.name.toLowerCase().startsWith(prefixNormalized)
                    )
                    .map(f => ({
                        caption: f.label,
                        value: `"${f.name}"`,
                        meta: "dataset column"
                    }));

                callback(null, matchedColumns);
            }
        }]);
    }, []);

    useEffect(() => {
        const currentFilterState = modeToFilterState.get(dimensionFilterMode);
        const modeValues = Array.from(currentFilterState.values);
        props.onChange(dimensionFilterMode.clauseType, currentFilterState.operator, modeValues);
    }, [dimensionFilterMode, modeToFilterState]);

    const onChangeOperator = (_: React.MouseEvent<HTMLElement>, operatorName: string) => {
        const operator: FilterOperator = FilterOperator.get<FilterOperator>(operatorName);
        const currentState = modeToFilterState.get(dimensionFilterMode);
        currentState.operator = operator;
        setModeToFilterState(new Map(modeToFilterState));
    };

    const onChangeDimensionFilterMode = (_: React.MouseEvent<HTMLElement>, modeName: string) => {
        setDimensionFilterMode(DimensionFilterMode.get<DimensionFilterMode>(modeName));
    };

    const onFilterSetChange = useCallback((selectedFilterSet: AssetSearchResult) => {
        const newFilterState = {
            ...filterState,
            values: new Set([selectedFilterSet])
        };
        setModeToFilterState(prevMap => new Map(prevMap.set(dimensionFilterMode, newFilterState)));
    }, [filterState, dimensionFilterMode]);

    const onArcQLFilterChange = useCallback((arcqlFilter: ArcQLFilterValue) => {
        const newFilterState = {
            ...filterState,
            values: new Set([arcqlFilter])
        };
        setModeToFilterState(prevMap => new Map(prevMap.set(dimensionFilterMode, newFilterState)));
    }, [filterState, dimensionFilterMode]);

    const renderTextField = () => (
        <S.TextField
            value={firstFilterValue}
            onChange={e => {
                const newFilterState = {...filterState, values: new Set([e.target.value])};
                setModeToFilterState(prevMap => new Map(prevMap.set(dimensionFilterMode, newFilterState)));
            }}
            fullWidth
            size='small'
        />
    );

    const renderExpressionEditor = () => (
        <AceEditor
            mode="sql"
            theme="github"
            height="100px"
            width="100%"
            value={firstFilterValue}
            onChange={v => {
                const newFilterState = {...filterState, values: new Set([v])};
                setModeToFilterState(prevMap => new Map(prevMap.set(dimensionFilterMode, newFilterState)));
            }}
            editorProps={{
                $blockScrolling: true
            }}
            setOptions={{
                wrap: true,
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            }}
        />
    );

    const renderDimensionValueSearch = () => (
        <DimensionValueSearch
            arcqlBundle={props.arcqlBundle}
            filter={props.filter as LiteralsFilterClause}
            ordinal={props.ordinal}
            selected={filterState.values as Set<RawFilterValue>}
            onChange={newValues => {
                const newFilterState = {...filterState, values: newValues};
                setModeToFilterState(prevMap => new Map(prevMap.set(dimensionFilterMode, newFilterState)));
            }}
        />
    );

    const renderDimensionFilterSetSearch = () => (
        <DimensionFilterSetSearch
            arcqlBundle={props.arcqlBundle}
            filter={props.filter as FilterSetFilterClause}
            ordinal={props.ordinal}
            selected={firstFilterValue}
            onSelectedChange={onFilterSetChange}
            onClose={props.onClose}
        />
    );

    const renderDimensionArcQLSearch = () => (
        <DimensionDynamicFilterSetSearch
            arcqlBundle={props.arcqlBundle}
            filter={props.filter as ArcQLFilterClause}
            ordinal={props.ordinal}
            selected={firstFilterValue}
            onSelectedChange={onArcQLFilterChange}
            onClose={props.onClose}
        />
    );

    const renderInput = () => {
        if (dimensionFilterMode === DimensionFilterMode.VALUES) {
            if (filterState.operator === FilterOperator.EXPRESSION) {
                return renderExpressionEditor();
            } else if (SIMPLE_OPERATORS.has(filterState.operator)) {
                return renderTextField();
            } else {
                return renderDimensionValueSearch();
            }
        } else if (dimensionFilterMode === DimensionFilterMode.FILTER_SET) {
            return renderDimensionFilterSetSearch();
        } else if (dimensionFilterMode === DimensionFilterMode.ARCQL) {
            return renderDimensionArcQLSearch();
        }
        return null;
    };

    return <FilterEditorS.Container>
        {props.withFilterSetMode &&
            <CommonS.Tabs
                value={dimensionFilterMode.name}
                onChange={onChangeDimensionFilterMode}
                variant="fullWidth"
                zeroTop={true}
            >
                <CommonS.Tab
                    icon={<FilterAlt/>}
                    value={DimensionFilterMode.VALUES.name}
                    iconPosition="start"
                    label={DimensionFilterMode.VALUES.label}
                />
                <CommonS.Tab
                    icon={<FilterSetIcon/>}
                    value={DimensionFilterMode.FILTER_SET.name}
                    iconPosition="start"
                    label={DimensionFilterMode.FILTER_SET.label}
                />
                <CommonS.Tab
                    icon={<DynamicFilterSetIcon viewBox={"0 0 24 16"}/>}
                    value={DimensionFilterMode.ARCQL.name}
                    iconPosition="start"
                    label={DimensionFilterMode.ARCQL.label}
                />
            </CommonS.Tabs>
        }
        <FilterEditorS.HelpText>Use rows when the value is:</FilterEditorS.HelpText>
        <ToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            value={filterState.operator.name}
            onChange={onChangeOperator}
        >{
            supportedOperators.map(operator =>
                <ToggleS.ToggleButton value={operator.name} key={operator.name}>
                    {operator.label}
                </ToggleS.ToggleButton>
            )
        }</ToggleButtonGroup>
        <S.InputContainer>{
            renderInput()
        }</S.InputContainer>
    </FilterEditorS.Container>;

};

const S = {

    InputContainer: styled.div`
        padding: 14px 0 6px;
        width: 100%;
    `,

    TextField: styled(TextField)`
        input {
            font-size: ${FontSizes.small};
        }
    `

};