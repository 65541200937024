import {ArcDataset} from "metadata/dataset/ArcDataset";
import {FilterClause, FilterSetValue} from "metadata/query/filterclause/FilterClause";
import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";
import {References} from "metadata/References";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";
import {ReferencingFilterClause} from "metadata/query/filterclause/ReferencingFilterClause";

/**
 * Filter clause that uses a Filter Set to dictate the filter values.
 */
export class FilterSetFilterClause implements SingleFieldFilterClause, ReferencingFilterClause {

    static fromJSON(json: JsonObject, references: References): FilterSetFilterClause {
        const fullyQualifiedName = json['fullyQualifiedName'];
        return new FilterSetFilterClause(
            json['field'],
            fullyQualifiedName,
            json['include'],
            // either get the filter set from passed-in references in ArcQL response or local storage
            references ? references.get(fullyQualifiedName).nullable : null
        );
    }

    static fromFilterSetAssetAndOperator(
        // Field in question.
        field: string,
        // The selected FilterSetAsset.
        value: FilterSetValue,
        // FilterOperator selected, which then will be converted to the underlying model representation.
        operator: FilterOperator
    ): FilterSetFilterClause {
        return new FilterSetFilterClause(
            field,
            value.fullyQualifiedName,
            operator === FilterOperator.IN,
            value
        );
    }

    /**
     * All public fields are 1:1 model with the BE but contains additional data like underlying FilterSet asset.
     */
    constructor(
        public readonly column: string,
        public readonly fullyQualifiedName: string,
        public readonly include: boolean,
        private readonly _filterSetAsset: AssetSearchResult
    ) {
    }

    description(short: boolean): string {
        const display = this._filterSetAsset ? this._filterSetAsset.label : this.fullyQualifiedName;
        return `${this.operator.label} ${display}`;
    }

    get fields(): string[] {
        return [this.column];
    }

    fieldsLabel(dataset: ArcDataset): string {
        return dataset.getLabel(this.column, `<missing: ${this.column}>`);
    }

    get isAll(): boolean {
        return false;
    }

    isFor(fields: string[]): boolean {
        return fields.length === 1 && fields[0] === this.column;
    }

    get isStructureValid(): boolean {
        return this.column != null && this.fullyQualifiedName != null && this.include != null;
    }

    equals(other: FilterClause): boolean {
        return Optional.ofType(other, FilterSetFilterClause)
            .map(o => this.column === o.column
                && this.fullyQualifiedName === o.fullyQualifiedName
                && this.include === o.include)
            .getOr(false);
    }


    get operator(): FilterOperator {
        return this.include ? FilterOperator.IN : FilterOperator.NOT_IN;
    }

    get values(): FilterSetValue[] {
        return [this.filterSetAsset];
    }

    get type(): FilterClauseType {
        return FilterClauseType.FILTER_SET;
    }

    get filterSetAsset(): AssetSearchResult {
        return this._filterSetAsset;
    }

    toJSON(): JsonObject {
        return {
            'type': FilterClauseType.FILTER_SET.name,
            'field': this.column,
            'fullyQualifiedName': this.fullyQualifiedName,
            'include': this.include
        };
    }
}

