import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";
import {VizSectionedProperty} from "app/visualizations/definition/property/VizSectionedProperty";

/**
 * @author zuyezheng
 */
export class ScatterChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON_WITH_CARTESIAN_AND_LEGEND,
        [
            new VizSectionedProperty(
                'showRegression',
                'Show Regression',
                [new VizBooleanProperty('yOnX', 'Y on X Regression', 'checkbox')]
            )
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', true]
    ]);

    get properties(): VizProperty<any>[] {
        return ScatterChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return ScatterChartDefinition.DEFAULTS;
    }

}