import React, {FunctionComponent, useEffect, useState} from 'react';
import Prism from 'prismjs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import styled from '@emotion/styled';
import 'prismjs/themes/prism-okaidia.css';

// note: prefer not to use babel-loader and hence just manually import the supported languages
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-bash';

type supportedLanguage = 'javascript' | 'sql' | 'bash';

interface Props {
    language: supportedLanguage;
    code: string;
}

/**
 * Code block component with copy functionality.
 */
export const CodeBlock: FunctionComponent<Props> = (props: Props) => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        Prism.highlightAll();
    }, [props.code]);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Container>
            <CopyButton className={'copy-button'} onClick={handleCopy}>
                <CopyToClipboard text={props.code}>
                    <span>{copied ? <CheckIcon/> : <ContentCopyIcon/>}</span>
                </CopyToClipboard>
            </CopyButton>
            <pre>
                <code className={`language-${props.language}`}>
                  {props.code}
                </code>
            </pre>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;

    &:hover .copy-button {
        opacity: 1;
    }

    pre {
        margin: 0;
        padding: 1rem;
        border-radius: 5px;
        overflow: auto;
    }
`;

const CopyButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    color: #f8f8f2;
    font-size: 18px;
    padding: 5px;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.3s;

    &:hover {
        color: #50fa7b;
        background: rgba(0, 0, 0, 0.7);
    }

    &:focus {
        outline: none;
    }
`;
