import React, {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {ConnectionType} from "metadata/connections/ConnectionType";

export type IconSize = 'compact' | 'standard';

export type Props = {
    type: ConnectionType
    size?: IconSize
}

/**
 * Renders the appropriate icon for a remote data source type
 */
export const DataSourceTypeIcon: FunctionComponent<Props> = (props: Props) => {
    const size: IconSize = props.size || 'standard';
    return <S.TypeIcon className={size}>
        <img className="icon"
            src={`/static/images/${props.type.name}.svg`}
            alt={props.type.label}
        />
    </S.TypeIcon>;
};

class S {

    static readonly TypeIcon = styled.div`
        display: flex;
        align-items: center;

        &.standard {
            width: 24px;
            height: 24px;
        }
        
        &.compact {
            width: 20px;
            height: 20px;

        }

        .icon {
            max-width: 100%;
            max-height: 100%;
        }
    `;
}