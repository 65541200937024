import * as React from "react";
import {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {MarkdownWidgetConfig} from "metadata/dashboard/widgets/config/MarkdownWidgetConfig";
import {Markdown} from "app/components/Markdown";

interface Props {
    config: MarkdownWidgetConfig,
}

export const MarkdownWidget: FunctionComponent<Props> = (props: Props) => {

    return <S.Markdown
        fontColor={props.config.text.getValue('fontColor')}
        fontSize={props.config.text.getValue('fontSize')}
        styles={{
            ...props.config.text.getValue('alignment').styling,
            ...props.config.text.getValue('verticalAlignment').styling
        }}
    >
        {props.config.markdown.getValue('content')}
    </S.Markdown>;

};

const S = {

    Markdown: styled(Markdown)<{
        fontColor: string;
        fontSize: string;
        styles?: { [prop: string]: string };
    }>`
        padding: 4px 10px;
        height: 100%;
        overflow: hidden auto;
        color: ${(props) => props.fontColor};
        font-size: ${(props) => props.fontSize};

        ${props => props.styles ?? css(props.styles)}
    `

};