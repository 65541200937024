import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {EthBlock} from "semantic-abi";
import {EvmChain} from "semantic-abi";

/**
 * Service to interact with Web3 specific APIs.
 */
export abstract class Web3Service {

    /**
     * Fetch receipts and traces as a single EthBlock response.
     */
    abstract receiptsAndTraces(
        chain: EvmChain,
        transactions: string[],
        signal?: AbortSignal,
        // when to expire the query from the cache
        cacheTimeout?: number
    ): Promise<Either<ErrorResponse, EthBlock>>;

}