import * as React from "react"
import {SVGProps} from "react"

const StringIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        {...props}
    >
        <path
            d="M2.852 5.117h.78l1.865 4.602H4.432l-.37-.975H2.399l-.357.975H1l1.852-4.602Zm.363 1.339-.52 1.43h1.047l-.527-1.43ZM5.686 5.117h1.598c.185 0 .371.015.557.045.19.027.359.083.508.17.15.082.27.199.363.35.093.152.14.354.14.605 0 .26-.07.477-.206.65a1.1 1.1 0 0 1-.533.364v.013c.141.022.268.065.381.13.117.06.216.14.297.24.085.096.15.208.194.339.044.13.066.268.066.415 0 .243-.048.447-.145.611-.097.16-.222.29-.375.39-.154.1-.327.172-.52.215-.19.043-.38.065-.57.065H5.686V5.117Zm.944 1.84h.684a.861.861 0 0 0 .212-.026.57.57 0 0 0 .194-.085.473.473 0 0 0 .188-.39.415.415 0 0 0-.061-.234.383.383 0 0 0-.146-.15.618.618 0 0 0-.205-.071 1.014 1.014 0 0 0-.224-.026H6.63v.982Zm0 1.904h.847a.936.936 0 0 0 .218-.026.562.562 0 0 0 .206-.091c.06-.043.11-.1.145-.169.04-.07.06-.154.06-.253a.402.402 0 0 0-.078-.26.452.452 0 0 0-.187-.156.924.924 0 0 0-.249-.072 1.719 1.719 0 0 0-.248-.02H6.63v1.047ZM12.17 6.313a.93.93 0 0 0-.344-.28 1.05 1.05 0 0 0-.466-.097 1.13 1.13 0 0 0-.89.423c-.109.13-.196.285-.26.468-.06.181-.091.379-.091.591 0 .217.03.416.09.598.065.178.152.334.261.468.109.13.236.232.381.306.15.073.309.11.478.11.194 0 .366-.043.515-.13.15-.087.272-.208.37-.364l.786.63a1.669 1.669 0 0 1-.69.605 2.01 2.01 0 0 1-.86.195c-.334 0-.643-.056-.925-.169a2.125 2.125 0 0 1-.733-.481 2.282 2.282 0 0 1-.484-.767 2.813 2.813 0 0 1-.17-1.001c0-.368.057-.702.17-1.001.117-.299.278-.553.484-.76a2.08 2.08 0 0 1 .732-.488c.283-.113.592-.169.927-.169a1.9 1.9 0 0 1 .375.039 1.73 1.73 0 0 1 .756.319c.117.086.222.192.315.318l-.726.637Z"
        />
    </svg>
)
export default StringIcon
