import * as React from "react";
import {
    ChangeEvent,
    DragEventHandler,
    FunctionComponent,
    KeyboardEventHandler,
    MouseEventHandler,
    useEffect,
    useRef,
    useState
} from "react";
import styled from "@emotion/styled";
import {Colors, Durations, FontSizes} from "app/components/StyleVariables";
import Input from "@mui/material/Input";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

type Props = {
    // main label and events
    label: string
    // determines if label is editable and if so, allow double click interaction to edit
    isLabelEditable: boolean
    // if true, will put pill in label edit mode (note: presumed label is editable)
    enterLabelEditMode?: boolean
    // handler on label change commits, return false if it should be reverted back to the prop
    onLabelChange?: (label: string) => boolean
    onClickLabel?: React.MouseEventHandler

    // detail text to show under the label
    details?: string

    // tooltips for different parts
    tooltip?: string
    labelTooltip?: string
    detailsTooltip?: string

    // optional prefix and click handler
    prefix?: React.ReactNode
    onClickPrefix?: MouseEventHandler

    // optional suffix click handler
    suffix?: React.ReactNode
    onClickSuffix?: MouseEventHandler

    // handle drags and id used to identify what is being dragged
    onDragStart?: MouseEventHandler
    dataFieldId?: string
    onDrop?: DragEventHandler

    // click interactions disabled
    disabled?: boolean

    className?: string
}

/**
 * Clickable and draggable pill component.
 *
 * @author ashwinraja
 */
export const Pill: FunctionComponent<Props> = (props: Props) => {

    const [label, setLabel] = useState<string>(props.label);
    const [editingLabel, setEditingLabel] = useState<boolean>(props.enterLabelEditMode || false);
    const clickTimeoutRef = useRef<number>(null);

    useEffect(() => {
        setLabel(props.label);
    }, [props.label]);

    useEffect(() => {
        if (props.enterLabelEditMode !== undefined && props.enterLabelEditMode && props.isLabelEditable) {
            setEditingLabel(true);
        }
    }, [props.enterLabelEditMode]);

    const onLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value);
    };

    const finishLabelInput = () => {
        // see if we should commit the label change
        if (props.onLabelChange && !props.onLabelChange(label)) {
            setLabel(props.label);
        }
        setEditingLabel(false);
    };

    const onLabelClick = (event: React.MouseEvent) => {
        if (editingLabel) {
            // prevent click event from firing when label is in edit mode
            event.preventDefault();
            return;
        }
        // no double-click interaction if label is not deemed editable
        if (!props.isLabelEditable) {
            handleLabelSingleClick(event);
            return;
        }

        // if already existing timeout function, then must be double click event
        if (clickTimeoutRef.current !== null) {
            // clear the timeout to prevent single click event from firing
            window.clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
            handleLabelDoubleClick(event);
        } else {
            // else, single click event and start timeout function
            clickTimeoutRef.current = window.setTimeout(() => {
                handleLabelSingleClick(event);
                clickTimeoutRef.current = null;
            }, 300);
        }
    };

    const handleLabelSingleClick = (event: React.MouseEvent) => {
        props?.onClickLabel(event);
    };

    const handleLabelDoubleClick = (event: React.MouseEvent) => {
        if (!props.disabled) {
            setEditingLabel(true);
        }
    };

    const handleLabelKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") {
            finishLabelInput();
        } else if (event.key === "Escape") {
            // revert back to original label and exit edit mode
            setLabel(props.label);
            setEditingLabel(false);
        }
    };

    return <Tooltip
        title={props.tooltip}
        arrow
        // labels are less likely to be truncated so take a little longer to show the tooltip
        enterDelay={Durations.tooltipSecondaryEnterDelay}
        enterNextDelay={Durations.tooltipSecondaryEnterNextDelay}
    >
        <S.ButtonGroupHolder
            className={props.className}
            // if in editable state, don't allow drag
            draggable={props.onDragStart != null && !editingLabel}
            data-field-id={props.dataFieldId}
            onDragStart={props.onDragStart}
            onDrop={props.onDrop}
        >
            <S.ButtonGroup fullWidth>
                {props.prefix &&
                    <S.Button className='prefix' onClick={props.onClickPrefix}>
                        {props.prefix}
                    </S.Button>
                }
                <S.Button
                    className='main'
                    onClick={onLabelClick}
                    disabled={props.disabled}
                >
                    <Tooltip
                        title={props.labelTooltip}
                        arrow
                        disableInteractive
                        // labels are less likely to be truncated so take a little longer to show the tooltip
                        enterDelay={Durations.tooltipSecondaryEnterDelay}
                        enterNextDelay={Durations.tooltipSecondaryEnterNextDelay}
                    >
                        {
                            editingLabel ?
                                <S.LabelInput
                                    placeholder="Label"
                                    value={label}
                                    onChange={onLabelChange}
                                    onBlur={finishLabelInput}
                                    onKeyDown={handleLabelKeyDown}
                                    fullWidth
                                    disableUnderline
                                    disabled={props.disabled}
                                    autoFocus
                                /> :
                                <S.Label>{label}</S.Label>
                        }
                    </Tooltip>
                    {
                        props.details && <Tooltip
                            title={props.detailsTooltip}
                            arrow
                            disableInteractive
                            // details are more likely longer and more often truncated so more likely to need a tooltip
                            enterDelay={Durations.tooltipPrimaryEnterDelay}
                            enterNextDelay={Durations.tooltipPrimaryEnterNextDelay}
                        >
                            <S.Details>{props.details}</S.Details>
                        </Tooltip>
                    }
                </S.Button>
                {props.onClickSuffix &&
                    <S.Button className='suffix' onClick={props.onClickSuffix} disabled={props.disabled}>
                        {props.suffix || <KeyboardArrowDownOutlinedIcon/>}
                    </S.Button>
                }
            </S.ButtonGroup>
        </S.ButtonGroupHolder>
    </Tooltip>;

};

class S {

    static readonly ButtonGroupHolder = styled.div`

    `;

    static readonly Label = styled.div`
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding: 1px 6px;
        box-sizing: border-box;
    `;

    static readonly Details = styled.div`
        width: 100%;
        font-size: ${FontSizes.xSmall};
        color: ${Colors.textSecondary};
        overflow: hidden;
        overflow-wrap: inherit;
        text-align: left;
        text-overflow: ellipsis;
        padding: 1px 6px;
        box-sizing: border-box;
    `;

    static readonly ButtonGroup = styled(ButtonGroup)`
        &:hover {
            box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.15);
        }
    `;

    static readonly Button = styled(Button)`
        cursor: pointer;

        font-size: ${FontSizes.small};
        color: ${Colors.textPrimary};
        text-transform: none;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.prefix {
            font-weight: 600;
            font-size: ${FontSizes.xxSmall};
            line-height: ${FontSizes.xSmall};
            letter-spacing: 0.4px;
            width: 45px;
            min-width: unset;
            justify-content: center;
            align-items: center;
        }

        &.suffix {
            min-width: unset;
            justify-content: center;
            align-items: center;
            width: 26px;

            > svg {
                height: 14px;
                width: 14px;
            }
        }

        &.main {
            padding: 4px;
        }
    `;

    static readonly LabelInput = styled(Input)`
        font-size: ${FontSizes.small};
        color: ${Colors.textPrimary};

        input {
            padding: 1px 4px 2px;
            border: 1px solid transparent;
            border-radius: 3px;

            &:hover, &:focus {
                background-color: white;
                border: 1px solid #ccc;
            }
        }
    `;
}
