import React, {FunctionComponent, ReactElement} from 'react';
import {SvgIconProps} from "@mui/material";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import {AssetType} from "metadata/AssetType";
import styled from "@emotion/styled";
import {Enum} from 'common/Enum';
import FilterSetIcon from "app/components/icons/asset/FilterSetIcon";
import {Colors} from "app/components/StyleVariables";
import FilterSetOutlinedIcon from "app/components/icons/asset/FilterSetOutlinedIcon";
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

export enum IconStyling {
    FILLED = 'filled',
    OUTLINED = 'outlined',
    OUTLINED_COLORED = 'outlined_color'
}

type StylingDictionary = {
    [key in IconStyling]: (props: SvgIconProps) => ReactElement
};

const AssetTypeStyling: { [key: string]: StylingDictionary } = {

    [AssetType.ARCQL.name]: {
        [IconStyling.FILLED]: (props) => <QueryStatsIcon
            sx={{background: Colors.iconQuery, color: 'white !important'}}
            {...props}
        />,
        [IconStyling.OUTLINED]: (props) => <QueryStatsOutlinedIcon {...props}/>,
        [IconStyling.OUTLINED_COLORED]: (props) => <QueryStatsOutlinedIcon
            sx={{color: Colors.iconQuery}}
            {...props}
        />
    },

    [AssetType.DATASET.name]: {
        [IconStyling.FILLED]: (props) => <DatasetOutlinedIcon
            sx={{background: Colors.iconDataset, color: 'white !important'}}
            {...props}
        />,
        [IconStyling.OUTLINED]: (props) => <DatasetOutlinedIcon {...props}/>,
        [IconStyling.OUTLINED_COLORED]: (props) => <DatasetOutlinedIcon
            htmlColor={Colors.iconDataset}
            {...props}
        />
    },

    [AssetType.DATASET_V2.name]: {
        [IconStyling.FILLED]: (props) => <DatasetOutlinedIcon
            sx={{background: Colors.iconDataset, color: 'white !important'}}
            {...props}
        />,
        [IconStyling.OUTLINED]: (props) => <DatasetOutlinedIcon {...props}/>,
        [IconStyling.OUTLINED_COLORED]: (props) => <DatasetOutlinedIcon
            htmlColor={Colors.iconDataset}
            {...props}
        />
    },

    [AssetType.DASHBOARD.name]: {
        [IconStyling.FILLED]: (props) => <DashboardOutlinedIcon
            sx={{background: Colors.iconDashboard, color: 'white !important'}}
            {...props}
        />,
        [IconStyling.OUTLINED]: (props) => <DashboardOutlinedIcon {...props}/>,
        [IconStyling.OUTLINED_COLORED]: (props) => <DashboardOutlinedIcon
            htmlColor={Colors.iconDashboard}
            {...props}
        />
    },

    [AssetType.PERSONA.name]: {
        [IconStyling.FILLED]: (props) => <TheaterComedyIcon htmlColor={Colors.iconMask} {...props}/>,
        [IconStyling.OUTLINED]: (props) => <TheaterComedyIcon htmlColor={Colors.iconMask} {...props}/>,
        [IconStyling.OUTLINED_COLORED]: (props) => <TheaterComedyIcon htmlColor={Colors.iconMask} {...props}/>
    },

    [AssetType.FILTER_SET.name]: {
        [IconStyling.FILLED]: (props) =>
            <S.FilterSetIconBox width={props.width as string} height={props.height as string}>
                <FilterSetIcon
                    viewBox={"0 0 20 20"}
                    fill={Colors.iconFilterSet}
                    {...props}
                />
            </S.FilterSetIconBox>,
        [IconStyling.OUTLINED]: (props) => <FilterSetOutlinedIcon
            fill={"currentColor"}
            viewBox={"0 0 14 16"}
            {...props}
        />,
        [IconStyling.OUTLINED_COLORED]: (props) => <FilterSetOutlinedIcon
            fill={Colors.iconFilterSet}
            viewBox={"0 0 14 16"}
            {...props}
        />
    }
};

export interface IconSize {
    width: number
    height: number
}

export class StandardIconSize extends Enum implements IconSize {
    static SMALL = new this('small', 16, 16);
    static MEDIUM = new this('medium', 21, 21);

    private constructor(
        name: string,
        public readonly width: number,
        public readonly height: number
    ) {
        super(name);
    }
}

interface Props extends SvgIconProps {
    assetType: AssetType
    iconStyling?: IconStyling
    size?: IconSize
    // styling for the container div
    containerStyle?: React.CSSProperties
}

export const AssetIcon: FunctionComponent<Props> = (props: Props) => {

    const {
        assetType,
        iconStyling = IconStyling.FILLED,
        size = StandardIconSize.MEDIUM,
        containerStyle = {},
        ...svgProps
    } = props || {};

    const widthPx: string = size.width + "px";
    const heightPx: string = size.height + "px";

    return <S.IconContainer width={widthPx} height={heightPx}>
        {
            AssetTypeStyling[assetType.name][iconStyling]
            ({
                    ...svgProps,
                    width: widthPx,
                    height: heightPx
                }
            )
        }
    </S.IconContainer>;

};

const S = {

    IconContainer: styled.div<{ width: string, height: string }>`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${(props) => props.width};
        height: ${(props) => props.height};

        // only apply to immediate svg children
        & > svg {
            width: 100%;
            height: 100%;
        }
    `,

    FilterSetIconBox: styled.div<{ width: string, height: string }>`
        width: ${(props) => props.width};
        height: ${(props) => props.height};
        border-radius: 3px;
        border: 0.5px solid ${Colors.iconGreyBackground};
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: calc(${(props) => props.width} * (16 / 21));
            height: calc(${(props) => props.height} * (16 / 21));
        }
    `
};