import React, {DOMAttributes, FunctionComponent} from "react";
import EnhancedMarkdownProcessor from 'app/dashboard/widgets/EnhancedMarkdownProcessor';
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkGemoji from "remark-gemoji";

export type Props = DOMAttributes<HTMLDivElement> & {
    className?: string
    children: string
}

export const Markdown: FunctionComponent<Props> = (props: Props) => {

    return <S.Container {...props}>
        <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkGemoji]}
            components={{
                p: ({children, node, ...props}) => {
                    return <p children={EnhancedMarkdownProcessor.children(children)} {...props}/>;
                }
            }}
        >
            {props.children}
        </ReactMarkdown>
    </S.Container>;

};

const S = {

    Container: styled.div`

        > * {
            margin: 0;
            width: 100%;
            padding: 3px 0;
            line-height: 1.38em;
        }

        h1 {
            margin: 0.2em 0;
            font-size: 2.25em;
            font-weight: 300;

            &:first-child {
                margin-top: 0;
            }
        }

        h2 {
            margin: 0.22em 0;
            font-size: 1.5em;
            font-weight: 300;

            &:first-child {
                margin-top: 0;
            }
        }

        h3 {
            margin: 0.26em 0;
            font-size: 1.2em;
            font-weight: 300;

            &:first-child {
                margin-top: 0;
            }
        }

        h4 {
            margin: .35em 0;
            font-size: 1em;
            font-weight: 400;

            &:first-child {
                margin-top: 0;
            }
        }

        h5 {
            margin: .4em 0;
            font-size: 0.88em;
            font-weight: 400;

            &:first-child {
                margin-top: 0;
            }
        }

        h6 {
            margin: .5em 0;
            font-size: 0.75em;
            font-weight: 500;
            letter-spacing: 0.025em;

            &:first-child {
                margin-top: 0;
            }
        }

        p, ul, ol, li {
            font-weight: 400;
        }

        ol, ul {
            padding-left: 1.5rem;
            box-sizing: border-box;
        }

        li {
            margin: 0.18em 0;
        }

        hr {
            padding: 0;
            margin: 3px 0;
            border-style: solid;
            color: #d0d7de;
        }

        a {
            color: #1976D2;
        }

        code {
            background-color: #f1f1f1;
            padding: 2px;
            color: crimson;
            margin-right: 0;
            white-space: pre-wrap;
        }

        pre code {
            display: inline-block;
            width: 100%;
        }

        blockquote {
            padding: 0 0 0 20px;
            margin: 0.5em 0;  
            border-left: 3px solid #efefef;
        }

        table {
            border-spacing: 0;
            border-collapse: collapse;
            margin: 0.2em 0 1em 0;
            tr:nth-child(even){
                background-color: #F6F8FA;
            }
            th, td {
                border: 1px solid #d0d7de;
                padding: 0.25em 0.75em;
            }
            th {
                border-bottom: 2px solid #d0d7de;
            }
            td {
                display: table-cell;
            }
        }

        .footnotes {
            opacity: 0.7;
            h2 {
                font-size: 1em;
                font-weight: 500;
            }
            li {
                font-size: 0.9em;
                margin: 0.08em 0;
                p {
                    margin-block-start: 0.25em;
                    margin-block-end: 0.25em;
                }
            }
        }
    `

};