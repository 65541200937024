import {Colors, FontSizes} from "app/components/StyleVariables";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";

export class S {

    static readonly Canvas = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1;
    `;

    static readonly PanelTitle = styled.div`
        background-color: ${Colors.headerGrey};
        color: ${Colors.textSecondary};
        padding: 0 18px;
        font-size: ${FontSizes.xSmall};
        line-height: ${FontSizes.xSmall};
        font-weight: 500;
        text-transform: uppercase;
        min-height: 35px;
        display: flex;
        align-items: center;
    `;

    static readonly Left = styled.div`
        width: 250px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid ${Colors.borderGrey};
        overflow: auto;
    `;

    static readonly Shelf = styled.div`
        width: 100%;
    `;

    static readonly TopShelf = styled.div`
        width: 100%;
        display: flex;
    `;

    static readonly TopShelfContent = styled.div`
        width: 100%;
        padding: 14px 6px 6px;
    `;

    static readonly ShelfHeader = styled.div`
        color: ${Colors.textSecondary};
        padding: 0 10px;
        font-size: ${FontSizes.small};
        line-height: ${FontSizes.small};
        font-weight: 500;
        height: 35px;
        display: flex;
        align-items: center;
    `;

    static readonly BottomShelf = styled.div`
        border-top: 1px solid ${Colors.borderGrey};
    `;

    static readonly Right = styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `;

    static readonly Visualization = styled.div`
        flex: 1;
        overflow: hidden;
        padding: 16px 10px;
    `;

    static readonly ShelfContent = styled.div`
        padding: 6px;
    `;

    static readonly Fields = styled.div`
        width: 100%;

        &.horizontal {
            display: flex;
            padding: 0;
        }
    `;

    static readonly FieldDropTarget = styled.div`
        padding: 5px 18px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }

        &.fields {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%2397C897FF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

            svg {
                color: ${Colors.measureGreen};
            }
        }

        &.filters {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23BDBDBDFF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

            svg {
                color: ${Colors.filterGrey};
            }
        }

        &.groupings {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%234397F8FF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            min-width: 100px;

            svg {
                color: ${Colors.dimensionBlue};
            }
        }
    `;

    static readonly Divider = styled.div`
        width: 100%;
        margin-top: 5px;
        border-top: 1px solid ${Colors.borderGrey};
    `;

    static readonly Spacer = styled.div`
        flex: 1;
    `;

    static readonly LimitField = styled(TextField)`
        width: 100%;

        input {
            font-size: ${FontSizes.small};
            padding: 8px 14px;
        }
    `;

    static readonly FiltersSeperator = styled.div`
        display: flex;
        align-items: center;
    `;

    static readonly FiltersSeperatorSpacing = styled.div`
        border-top: 1px solid ${Colors.borderGrey};
        height: 1px;
        margin-top: 1px;

        &.left {
            width: 20px;
        }

        &.right {
            flex: 1;
        }
    `;

    static readonly FiltersSeperatorLabel = styled.div`
        font-size: ${FontSizes.xSmall};
        color: ${Colors.textSecondary};
        white-space: nowrap;
        padding: 6px 6px 4px;
    `;

}
