import * as React from "react";
import {Optional} from "common/Optional";
import {Enum, EnumGettable} from "common/Enum";

const MAGICAL_SEPERATOR = '|~|';

/**
 * Since drag and drop data can only operate on serialized strings, bring some uniformity in the data being transfered
 * in drag and drop events.
 *
 * @author zuyezheng
 */
export class DragAndDropData<E extends Enum> {

    static fromEvent<E extends Enum>(event: React.DragEvent, enumClazz: EnumGettable<E>): DragAndDropData<E> {
        return DragAndDropData.parse(event.dataTransfer.getData('text/plain'), enumClazz);
    }

    static fromAttributes<E extends Enum>(
        el: Element,
        attributeName: string,
        enumClazz: EnumGettable<E>
    ): DragAndDropData<E> {
        return DragAndDropData.parse(el.attributes.getNamedItem(attributeName).value, enumClazz);
    }

    /**
     * Parse the serialized version.
     */
    static parse<E extends Enum>(serialized: string, enumClazz: EnumGettable<E>): DragAndDropData<E>  {
        const fieldParts = serialized.split(MAGICAL_SEPERATOR);
        return new DragAndDropData(enumClazz.get(fieldParts[0]), fieldParts[1]);
    }

    constructor(
        public readonly type: E,
        public readonly name: string
    ) { }

    get serialize(): string {
        return `${this.type.name}${MAGICAL_SEPERATOR}${this.name}`;
    }

    /**
     * If the drag and drop data is of the type, return the name, otherwise return none.
     */
    isOf(type: E): Optional<string> {
        if (this.type !== type) {
            return Optional.none();
        }

        return Optional.some(this.name);
    }

    setData(event: React.DragEvent) {
        event.dataTransfer.setData('text/plain', this.serialize);
    }

}