import {ArcQL} from "metadata/query/ArcQL";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";

/**
 * Change the limit.
 *
 * @author zuyezheng
 */
export class LimitChange implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly limit: number
    ) {}

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        return new Tuple(
            metadata.with({
                limit: this.limit
            }),
            new LimitChange(metadata.limit)
        );
    }

    describe(): string {
        return `Limit changed to ${this.limit}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}