import * as React from "react";
import {FunctionComponent, useState} from "react";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import {FontSizes} from "app/components/StyleVariables";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {Column} from "metadata/Column";
import {ColumnsPicker} from "app/query/components/ColumnsPicker";

type Props = {
    className?: string,
    datasets: ArcDataset[]
    onClick: (dataset: ArcDataset, column: Column) => void
};

/**
 * Column picker for multiple datasets.
 *
 * @author zuyezheng
 */
export const DatasetColumnsPicker: FunctionComponent<Props> = (props: Props) => {

    const [dataset, setDataset] = useState<ArcDataset>(props.datasets[0]);

    const onSelectDataset = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFqn = event.target.value;
        setDataset(props.datasets.find(dataset => dataset.fqn.toString() === selectedFqn));
    };

    return <div className={props.className}>
        <FormControl fullWidth size="small">
            <S.DatasetLabel>Dataset</S.DatasetLabel>
            <S.Select
                select
                variant="standard"
                margin="dense"
                value={dataset.fqn.toString()}
                onChange={onSelectDataset}
            >{
                props.datasets.map(dataset =>
                    <S.SelectItem key={dataset.fqn.toString()} value={dataset.fqn.toString()}>
                        {dataset.label}
                    </S.SelectItem>
                )
            }</S.Select>
        </FormControl>
        <ColumnsPicker
            dataset={dataset}
            onClick={column => props.onClick(dataset, column)}
            isSaved={false}
        />
    </div>;

};

const S = {

    DatasetLabel: styled.div`
        font-size: ${FontSizes.small};
        font-weight: 500;
    `,

    Select: styled(TextField)`
        font-size: ${FontSizes.small};

        .MuiInput-input {
            font-size: ${FontSizes.medium};
        }
    `,

    SelectItem: styled(MenuItem)`
        font-size: ${FontSizes.small};
    `

};
