import {RGBA} from "common/RGBA";

/**
 * Tween RBGA colors between two values.
 */
export class RGBATweener {

    constructor(
        // start color
        public readonly startValue: number,
        public readonly startColor: RGBA,
        // mid color
        public readonly midValue: number,
        public readonly midColor: RGBA,
        // end color
        public readonly endValue: number,
        public readonly endColor: RGBA
    ) {}

    tween(value: number): RGBA {
        if (value <= this.startValue) {
            return this.startColor;
        }
        if (value >= this.endValue) {
            return this.endColor;
        }

        // since we have a mid, need to figure out the actual start and end
        let startValue: number, endValue: number;
        let startColor: RGBA, endColor: RGBA;
        if (value <= this.midValue) {
            startValue = this.startValue;
            endValue = this.midValue;
            startColor = this.startColor;
            endColor = this.midColor;
        } else {
            startValue = this.midValue;
            endValue = this.endValue;
            startColor = this.midColor;
            endColor = this.endColor;
        }

        // scale of 0 means should be the start color and 1 means should be the end color
        const scale = (value - startValue) / (endValue - startValue);
        const adjust = (start: number, end: number) => {
            return Math.round((start * (1 - scale)) + (end * scale));
        };

        return new RGBA(
            adjust(startColor.r, endColor.r),
            adjust(startColor.g, endColor.g),
            adjust(startColor.b, endColor.b),
            adjust(startColor.a, endColor.a)
        );
    }

}