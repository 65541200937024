import {Enum} from "common/Enum";
import {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {ChartWidgetFactory} from "app/dashboard/widgets/ChartWidgetFactory";
import {LinkWidgetFactory} from "app/dashboard/widgets/LinkWidgetFactory";
import {WidgetComponentFactory} from "app/dashboard/widgets/WidgetComponentFactory";
import {MarkdownWidgetFactory} from "app/dashboard/widgets/MarkdownWidgetFactory";
import {ImageWidgetFactory} from "app/dashboard/widgets/ImageWidgetFactory";

/**
 * Represents an extended widget type tailored for app usecases like generating
 * the appropriate views.
 */
export class WidgetTypeExtended extends Enum {

    static readonly CHART = new this(
        WidgetType.CHART,
        {w: 3, h: 3},
        new ChartWidgetFactory()
    );

    static readonly MARKDOWN = new this(
        WidgetType.MARKDOWN,
        {w: 2, h: 1},
        new MarkdownWidgetFactory()
    );

    static readonly LINK = new this(
        WidgetType.LINK,
        {w: 1, h: 1},
        new LinkWidgetFactory()
    );

    static readonly IMAGE = new this(
        WidgetType.IMAGE,
        {w: 1, h: 2},
        new ImageWidgetFactory()
    );

    private constructor(
        public readonly widgetType: WidgetType,
        public readonly defaultSize: { w: number; h: number },
        public readonly widgetComponentFactory: WidgetComponentFactory,
    ) {
        super(widgetType.name);
    }

    public static from(type: WidgetType): WidgetTypeExtended {
        return this.get(type.name);
    }

}

WidgetTypeExtended.finalize();