import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {
    HyperGraphNodeDeserializer,
    HyperGraphNodeSerialized,
    HyperGraphRequestDeserializer,
    HyperGraphRequestSerialized
} from "metadata/hypergraph/HyperGraphTypes";
import {ConsolidateAnalysisNode} from "metadata/hypergraph/nodes/ConsolidateAnalysisNode";
import {DataOfInterestNode} from "metadata/hypergraph/nodes/DataOfInterestNode";
import {NextBestGroupingsNode} from "metadata/hypergraph/nodes/NextBestGroupingsNode";
import {NextBestMeasuresNode} from "metadata/hypergraph/nodes/NextBestMeasuresNode";
import {QueryContainerNode} from "metadata/hypergraph/nodes/QueryContainerNode";
import {StartAnalysisNode} from "metadata/hypergraph/nodes/StartAnalysisNode";
import {ValidateHypothesisNode} from "metadata/hypergraph/nodes/ValidateHypothesisNode";
import {JsonObject} from "common/CommonTypes";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraphNodeLayout} from "metadata/hypergraph/HyperGraphNodeLayout";
import {HyperGraph, WorkingNodeStatus} from "metadata/hypergraph/HyperGraph";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {Optional} from "common/Optional";
import {RequestNode} from "metadata/hypergraph/nodes/RequestNode";
import {ConsolidateAnalysis} from "metadata/hypergraph/nodes/ConsolidateAnalysis";
import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {DataOfInterest} from "metadata/hypergraph/nodes/DataOfInterest";
import {ValidateHypothesis} from "metadata/hypergraph/nodes/ValidateHypothesis";
import {StartAnalysis} from "metadata/hypergraph/nodes/StartAnalysis";
import {QueryContainer} from "metadata/hypergraph/nodes/QueryContainer";
import {NextBestMeasures} from "metadata/hypergraph/nodes/NextBestMeasures";
import {NextBestGroupings} from "metadata/hypergraph/nodes/NextBestGroupings";

const NODE_DESERIALIZERS = new Map<HyperGraphNodeType, HyperGraphNodeDeserializer>([
    [HyperGraphNodeType.CONSOLIDATE_ANALYSIS, ConsolidateAnalysisNode.fromJSON],
    [HyperGraphNodeType.DATA_OF_INTEREST, DataOfInterestNode.fromJSON],
    [HyperGraphNodeType.NEXT_BEST_GROUPINGS, NextBestGroupingsNode.fromJSON],
    [HyperGraphNodeType.NEXT_BEST_MEASURES, NextBestMeasuresNode.fromJSON],
    [HyperGraphNodeType.QUERY_CONTAINER, QueryContainerNode.fromJSON],
    [HyperGraphNodeType.START_ANALYSIS, StartAnalysisNode.fromJSON],
    [HyperGraphNodeType.VALIDATE_HYPOTHESIS, ValidateHypothesisNode.fromJSON],
    [HyperGraphNodeType.REQUEST, RequestNode.fromJSON]
]);

const REQUEST_DESERIALIZERS = new Map<HyperGraphNodeType, HyperGraphRequestDeserializer>([
    [HyperGraphNodeType.CONSOLIDATE_ANALYSIS, ConsolidateAnalysis.fromJSON],
    [HyperGraphNodeType.DATA_OF_INTEREST, DataOfInterest.fromJSON],
    [HyperGraphNodeType.NEXT_BEST_GROUPINGS, NextBestGroupings.fromJSON],
    [HyperGraphNodeType.NEXT_BEST_MEASURES, NextBestMeasures.fromJSON],
    [HyperGraphNodeType.QUERY_CONTAINER, QueryContainer.fromJSON],
    [HyperGraphNodeType.START_ANALYSIS, StartAnalysis.fromJSON],
    [HyperGraphNodeType.VALIDATE_HYPOTHESIS, ValidateHypothesis.fromJSON]
]);

/**
 * Helps with deserializing a hypergraph from JSON given nodes are polymorphic.
 *
 * @author zuyezheng
 */
export class HyperGraphDeserializer {

    static toRequest(json: HyperGraphRequestSerialized, dataset: ArcDataset): HyperGraphRequest {
        return HyperGraphNodeType.possible(json.type)
            .map(type => REQUEST_DESERIALIZERS.get(type)(json, dataset))
            .getOrElse(() => {
                throw new Error('Unknown request type.');
            });
    }

    static toGraph(json: JsonObject, dataset: ArcDataset): HyperGraph {
        // deserialize all known node types
        const nodes = new Map<string, HyperGraphNode>(json.nodes.flatMap((json: HyperGraphNodeSerialized) =>
            HyperGraphNodeType.possible(json.type)
                .map(type => [json.id, NODE_DESERIALIZERS.get(type)(json, dataset)])
                .array
        ));
        if (nodes.size !== json.nodes.length) {
            throw new Error('Failed to deserialize all nodes.');
        }

        // make sure we have the root node
        const root = Optional.of(nodes.get(json.root));
        if (nodes.size > 0 && root.isNone) {
            throw new Error('Missing root node on non empty graph.');
        }

        const layouts = new Map(
            Object.entries(json.layouts)
                .map(([id, layout]: [string, JsonObject]) => [id, HyperGraphNodeLayout.fromJSON(layout)])
        );

        // make sure we have a valid working state
        const workingState = Optional.of(json.workingState)
            .map(s => ({
                nodeId: s.nodeId,
                status: WorkingNodeStatus.get(s.status)
            }));
        if (nodes.size > 0 && workingState.isNone) {
            throw new Error('Missing working state on non empty graph.');
        }

        return new HyperGraph(
            nodes,
            root,
            layouts,
            workingState,
            new Set(json.selected)
        );
    }

    static toNode(json: HyperGraphNodeSerialized, dataset: ArcDataset): HyperGraphNode {
        return HyperGraphNodeType.possible(json.type)
            .map(type => NODE_DESERIALIZERS.get(type)(json, dataset))
            .getOrElse(() => {
                throw new Error('Unknown node type.');
            });
    }
}