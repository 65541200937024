import {BaseWidgetConfigPart} from "metadata/dashboard/widgets/config/BaseWidgetConfigPart";
import {JsonObject} from "common/CommonTypes";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";

/**
 * Properties for configuring chart-like widgets.
 */
export class ChartConfigPart extends BaseWidgetConfigPart<ChartConfigPart> {

    constructor(
        public readonly queryId: string,
        defaults: ChartConfigPart
    ) {
        super(defaults);
    }

    protected get properties(): (keyof ChartConfigPart & string)[] {
        return ['queryId'];
    }

    get type(): WidgetConfigPartType {
        return WidgetConfigPartType.CHART;
    }

    createInstance(props: Record<keyof ChartConfigPart, any>, defaults: ChartConfigPart): ChartConfigPart {
        return new ChartConfigPart(props.queryId, defaults);
    }

    static fromJSON(configJson: JsonObject, defaults: ChartConfigPart = ChartConfigPart.defaults()): ChartConfigPart {
        return BaseWidgetConfigPart.fromJSONHelper(configJson, defaults);
    }

    static defaults(): ChartConfigPart {
        return new ChartConfigPart(null, null);
    }
}