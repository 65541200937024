import {Enum} from "common/Enum";

/**
 * Enumerate all the chains we support.
 *
 * @author zuyezheng
 */
export class Chain extends Enum {

    static ETHEREUM = new this('ethereum', 'Ethereum');
    static POLYGON = new this('polygon', 'Polygon');
    static OPTIMISM = new this('optimism', 'Optimism');
    static BASE = new this('base', 'Base');
    static ARBITRUM = new this('arbitrum', 'Arbitrum');
    static AVALANCHE_C = new this('avalanche_c', 'Avalanche C-Chain');
    static POLYGON_ZKEVM = new this('polygon_zkevm', 'Polygon zkEVM');
    static TRON = new this('tron', 'Tron');
    static ZKSYNC_ERA = new this('zksync_era', 'zkSync Era');
    static STABILITY = new this('stability', 'Stability');
    static STABILITY_TESTNET = new this('stability_testnet', 'Stability Testnet');
    static FARCASTER_EVENTS = new this('farcaster_events', 'Farcaster');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

    /**
     * Given a list of chains, sort them alphabetically by label.
     */
    static sort(chains: Chain[]): Chain[] {
        return chains.sort((a, b) => a.label.localeCompare(b.label));
    }
}
Chain.finalize();