import * as React from "react";
import {FunctionComponent, useRef, useState} from "react";
import Button from "@mui/material/Button";
import {ToolbarAction} from "app/components/toolbar/ToolbarAction";
import styled from "@emotion/styled";
import {TypedMenu} from "app/components/TypedMenu";
import {ToolbarActionsMore} from "app/components/toolbar/ToolbarActions";
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import {Colors} from "app/components/StyleVariables";


type Props = {
    more: ToolbarActionsMore
    onAction: (action: ToolbarAction) => void
}

/**
 * @author zuyezheng
 */
export const ActionMore: FunctionComponent<Props> = (props: Props) => {

    const anchorRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const onMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const onMenuAction = (action: ToolbarAction) => {
        props.onAction(action);
        setShowMenu(false);
    };

    const onCloseMenu = () => {
        setShowMenu(false);
    };

    return <S.More ref={anchorRef}>
        <Button onClick={onMenuClick}>
            <MoreVertOutlined />
        </Button>
        {
            showMenu && <TypedMenu
                el={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                items={props.more.moreActions}
                itemId={item => item.id}
                itemLabel={item => item.label}
                onSelect={onMenuAction}
                onClose={onCloseMenu}
            />
        }
    </S.More>;

};

export const S = {

    More: styled.div`
        button {
            min-width: unset;
            width: 33px;
            border-radius: 20px;

            &:hover {
                background-color: ${Colors.headerGrey};
                
                
                svg {
                    color: ${Colors.iconPrimary};
                }
            }
        }

    `

};
