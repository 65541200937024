import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {AssetProps} from "metadata/Asset";

/**
 * Change basic metadata like name and label.
 *
 * @author zuyezheng
 */
export class ArcQLInfoChange implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly infoChanges: AssetProps
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const originals = Object.keys(this.infoChanges).reduce(
            (acc: AssetProps, key: keyof AssetProps) => {
                acc[key] = metadata[key];
                return acc;
            },
            {}
        );

        return new Tuple(
            metadata.with(this.infoChanges), new ArcQLInfoChange(originals)
        );
    }

    describe(): string {
        return `Changed label to ${this.infoChanges.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}