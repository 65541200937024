import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";
import {VizEnumProperty} from "app/visualizations/definition/property/VizEnumProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {Tuple} from "common/Tuple";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";

/**
 * @author zuyezheng
 */
export class TimelineChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        [
            new VizEnumProperty(
                'plotType',
                'Plot Type',
                [
                    Tuple.of('column', 'Column'),
                    Tuple.of('line', 'Line'),
                    Tuple.of('smooth-line', 'Smooth Line'),
                    Tuple.of('area', 'Area'),
                    Tuple.of('smooth-area', 'Smooth Area'),
                    Tuple.of('candlestick', 'Candlestick'),
                ]
            )
        ],
        CommonChartProperties.COMMON,
        [
            new VizStringProperty('yAxisName', 'Y-Axis')
        ],
        CommonChartProperties.LEGEND,
        [
            new VizBooleanProperty('logAxis', 'Log Axis'),
            new VizBooleanProperty('connectMissing', 'Connect Missing'),
            new VizBooleanProperty('combo', 'Combo'),
            new VizEnumProperty(
                'navigator',
                'Show Navigator',
                [
                    Tuple.of('small', 'Small'),
                    Tuple.of('medium', 'Medium'),
                    Tuple.of('large', 'Large'),
                    Tuple.of('hide', 'Hide'),
                ]
            )
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', true],
        ['plotType', 'column'],
        ['connectMissing', false],
        ['combo', false],
        ['navigator', 'medium']
    ]);

    get properties(): VizProperty<any>[] {
        return TimelineChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return TimelineChartDefinition.DEFAULTS;
    }

}