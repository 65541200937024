import * as React from "react";
import {FunctionComponent} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import styled from "@emotion/styled";
import {Asset} from "metadata/Asset";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import ListItemIcon from "@mui/material/ListItemIcon";
import {AssetIcon} from "app/components/icons/asset/AssetIcon";
import {AssetType} from "metadata/AssetType";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {DialogS} from "app/DialogS";

type Props = {
    asset: Asset
    onCancel: () => void
    onArchive: () => void
}

/**
 * Dialog of archiving an asset.
 *
 * @author fli
 */
export const ArchiveDialog: FunctionComponent<Props> = (props: Props) => {

    const archiveAsset = () => {
        ServiceProvider.get(MetadataService)
            .archiveAsset(props.asset.fqn)
            .then(r => r.match(
                _ => {
                    ServiceProvider.get(NotificationsService).publish(
                        'archiveDialog', NotificationSeverity.SUCCESS, `Archived @${props.asset.fqn}!`
                    );
                    props.onArchive();
                },
                error => {
                    ServiceProvider.get(NotificationsService).publish(
                        'archiveDialog', NotificationSeverity.ERROR, `Archive failed: ${error.prettyPrint()}`
                    );
                    return props.onCancel();
                }
            ));
    };

    return <Dialog
        open={true}
        onClose={props.onCancel}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        <DialogS.Title>{`Are you sure you want to archive this ${props.asset.assetType.label}?`}</DialogS.Title>
        <DialogS.Content dividers>
            <S.Body>
                <S.AssetCard>
                    <ListItemIcon>
                        <AssetIcon
                            assetType={AssetType.get(props.asset.assetType.name)}
                            size={{width: 32, height: 32}}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={props.asset.label}
                        secondary={`@${props.asset.fqn.toString()}`}
                    />
                </S.AssetCard>
                <DialogS.Description>
                    {`Once you archive this ${props.asset.assetType.label}:`}
                    <ul>
                        <li>It will no longer appear in search or browse pages.</li>
                        <li>The URL will still be reserved incase you change your mind, please contact HyperArc to do so.</li>
                        <li>Assets referencing this will continue to work, but please consider updating them to a new version.</li>
                    </ul>
                </DialogS.Description>
            </S.Body>
        </DialogS.Content>
        <DialogS.Actions>
            <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
            <S.ArchiveButton
                variant="contained"
                color={"error"}
                onClick={archiveAsset}
            >
                {`Archive ${props.asset.assetType.label}`}
            </S.ArchiveButton>
        </DialogS.Actions>
    </Dialog>;

};

const S = {

    DialogTitle: styled(DialogTitle)`
        padding: 16px 40px;
        font-size: ${FontSizes.large};
        color: ${Colors.formError};
    `,

    Body: styled.div`
        display: flex;
        flex-direction: column;
    `,

    AssetCard: styled(ListItem)`
        margin-bottom: 16px;
        padding: 0;
    `,

    ArchiveButton: styled(Button)`
        &.Mui-disabled {
            background-color: ${Colors.formError};
            opacity: 0.7;
        }

        &.MuiButton-contained {
            color: white;
            background-color: ${Colors.formError};

            &:hover {
                color: white;
                background-color: ${Colors.formError};
            }
        }
    `
};
