import * as React from "react";
import {FunctionComponent, memo, useState} from "react";
import Dialog from '@mui/material/Dialog';
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {Cookies} from "common/Cookies";

/**
 * Welcome mat for first time users to existing dashboards.
 *
 * @author zuyezheng
 */
export const WelcomeMat: FunctionComponent = memo(() => {

    // show if if the cookie is not set and cookies are supported, if cookies are not supported, don't show since it'll
    // just get annoying
    const [open, setOpen] = useState<boolean>(Cookies.isSupported && !Cookies.getBoolean('seenWelcomeMat'));

    const onClose = () => {
        setOpen(false);
        // set the cookie once seen
        Cookies.set('seenWelcomeMat', 'true');
    };

    return <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: {
                maxWidth: "680px"
            }
        }}
    >
        <DialogTitle>Don't forget to click around and explore!</DialogTitle>
        <S.Content>
            <img src="/static/images/interactions.gif" alt="Click around!"/>
        </S.Content>
        <S.Actions>
            <Button variant="contained" onClick={onClose}>Got It</Button>
        </S.Actions>
    </Dialog>;

});

const S = {

    Content: styled.div`
        padding: 16px 40px;
        display: flex;
        justify-content: center;
        
        img {
            max-width: 100%;
        }
    `,

    Actions: styled(DialogActions)`
        justify-content: center;
        padding-bottom: 16px;
    `

};
