import {Json, JsonObject} from "common/CommonTypes";
import {Either, Right} from "common/Either";
import {ExpiryCache} from "common/ExpiryCache";
import {FQN} from "common/FQN";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcQL} from "metadata/query/ArcQL";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {MetadataService} from "services/MetadataService";
import {RestService} from "services/RestService";
import {ServiceProvider} from "services/ServiceProvider";
import {AssetVersions} from "metadata/AssetVersions";
import {Folders} from "metadata/project/Folders";
import {Asset} from "metadata/Asset";
import {AssetType} from "metadata/AssetType";
import {FoldersSearchResponse} from "metadata/project/FoldersSearchResponse";
import {FolderSearchParams} from "metadata/project/FolderSearchParams";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";
import {References} from "metadata/References";
import {PersonaListParams} from "metadata/PersonaListParams";
import {FolderResult} from "metadata/project/FolderResult";
import FolderCreateRequest from "metadata/project/FolderCreateRequest";
import FolderPatchRequest from "metadata/project/FolderPatchRequest";
import {AccountSearchParams} from "metadata/account/AccountSearchParams";
import {AccountsSearchResponse} from "metadata/account/AccountsSearchResponse";
import {Story} from "metadata/asset/story/Story";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {ImageService} from "services/ImageService";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {TrendsService} from "services/TrendsService";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {DatasetV2Service} from "services/DatasetV2Service";
import {HyperGraphSearchParams} from "metadata/search/HyperGraphSearchParams";
import {toParams} from "common/Indexable";
import {ServiceUtils} from "services/ServiceUtils";
import {GlobalAnswerWithNodes} from "metadata/search/GlobalAnswerWithNodes";

/**
 * @author zuyezheng
 */
export class ApiMetadataService implements MetadataService {

    private readonly metadataCache: ExpiryCache<
        // fqn as a string
        string,
        // untyped cached asset
        Promise<Either<ErrorResponse, any>>
    >;

    constructor(
        public readonly host: string = '',
        cacheSize: number = 50,
        // invalidate cache at 10 minutes by default
        defaultCacheTimeout: number = 10 * 60 * 1000
    ) {
        this.metadataCache = new ExpiryCache(cacheSize, defaultCacheTimeout);
    }

    listFolders(accountName: string, signal?: AbortSignal): Promise<Either<ErrorResponse, Folders>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/accounts/${accountName}/folders`, signal)
            .then(ApiResponse.success(Folders.fromJSON));
    }

    fetchFolder(accountName: string, folderName: string, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>> {
        const cacheKey = `accounts/${accountName}/folders/${folderName}`;

        return this.metadataCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService)
                .get(`/api/v1/${cacheKey}`, signal)
                .then(r => ApiResponse.custom(
                    r,
                    FolderResult.fromJSON,
                    (json: any, r: Response) => {
                        this.metadataCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                )),
            Infinity
        );
    }

    createFolder(accountName: string, folderRequest: FolderCreateRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${accountName}/folders`,
                folderRequest.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                json => FolderResult.fromJSON(json),
                ErrorResponse.of
            ));
    }

    patchFolder(accountName: string, folderName: string, folderRequest: FolderPatchRequest, signal?: AbortSignal): Promise<Either<ErrorResponse, FolderResult>> {
        return ServiceProvider.get(RestService)
            .patch(
                `/api/v1/accounts/${accountName}/folders/${folderName}`,
                folderRequest.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                json => FolderResult.fromJSON(json),
                ErrorResponse.of
            ));
    }

    private _fetchArcQL(path: string, cacheKey: string, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcQL>> {
        const headers: { [key: string]: string } = ServiceUtils.getRequestingAssetHeader(requestingFqn);
        return this.metadataCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService)
                .get(path, signal, null, headers)
                .then(r => ApiResponse.custom(
                    r,
                    // cache the json to produce new ArcQL objects for every caller since they might try to mutate it
                    json => json,
                    (json: any, r: Response) => {
                        this.metadataCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                )),
            Infinity
        ).then(r => r.map(ArcQL.fromJSON));
    }

    fetchArcQL(fqn: FQN, signal?: AbortSignal, requestingFqn?: FQN): Promise<Either<ErrorResponse, ArcQL>> {
        return this._fetchArcQL(
            `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/arcqls/${fqn.name}`,
            fqn.toString(),
            signal,
            requestingFqn
        );
    }

    fetchArcQLVersion(
        fqn: FQN, version: number, signal?: AbortSignal, requestingFqn?: FQN
    ): Promise<Either<ErrorResponse, ArcQL>> {
        return this._fetchArcQL(
            `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/arcqls/${fqn.name}/versions/${version}`,
            `${fqn.toString()}/${version}`,
            signal,
            requestingFqn
        );
    }

    listArcQLVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/arcqls/${fqn.name}/versions`, signal)
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => AssetVersions.fromJSON(json),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ));
    }

    saveArcQL(fqn: FQN, arcQL: ArcQL, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQL>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/arcqls`,
                arcQL.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    // if save was successful, delete any cached queries and versions
                    const arcQL = ArcQL.fromJSON(json);
                    this.metadataCache.delete(fqn.toString());
                    this.metadataCache.delete(fqn.toString() + '/versions');
                    return arcQL;
                },
                ErrorResponse.of
            ));
    }

    archiveArcQL(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQL>> {
        return this._archive(fqn, ArcQL.fromJSON, signal);
    }

    private _fetchDashboard(path: string, cacheKey: string, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return this.metadataCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService)
                .get(path, signal)
                .then(r => ApiResponse.custom(
                    r,
                    // cache the json to produce new ArcQL objects for every caller since they might try to mutate it
                    json => json,
                    (json: any, r: Response) => {
                        this.metadataCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                )),
            Infinity
        ).then(r => r.map(ArcDashboard.fromJSON));
    }

    fetchDashboard(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return this._fetchDashboard(
            `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/dashboards/${fqn.name}`,
            fqn.toString(),
            signal
        );
    }

    fetchDashboardVersion(fqn: FQN, version: number, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return this._fetchDashboard(
            `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/dashboards/${fqn.name}/versions/${version}`,
            `${fqn.toString()}/${version}`,
            signal
        );
    }

    listDashboardVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/dashboards/${fqn.name}/versions`, signal)
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => AssetVersions.fromJSON(json),
                (json: any, r: Response) => {
                    return ErrorResponse.of(json, r);
                }
            ))
    }

    saveDashboard(fqn: FQN, dashboard: ArcDashboard, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/dashboards`,
                dashboard.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    // if save was successful, delete any cached queries and versions
                    const dashboard = ArcDashboard.fromJSON(json);
                    this.metadataCache.delete(fqn.toString());
                    this.metadataCache.delete(fqn.toString() + '/versions');
                    return dashboard;
                },
                ErrorResponse.of
            ));
    }

    archiveDashboard(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcDashboard>> {
        return this._archive(fqn, ArcDashboard.fromJSON, signal);
    }

    private _fetchFilterSet(path: string, cacheKey: string, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return this.metadataCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService)
                .get(path, signal)
                .then(r => ApiResponse.custom(
                    r,
                    // cache the json to produce new ArcQL objects for every caller since they might try to mutate it
                    json => json,
                    (json: any, r: Response) => {
                        this.metadataCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                )),
            Infinity
        ).then(r => r.map(ArcFilterSet.fromJSON));
    }

    fetchFilterSet(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return this._fetchFilterSet(
            `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/filtersets/${fqn.name}`,
            fqn.toString(),
            signal
        );
    }

    fetchFilterSetVersion(fqn: FQN, version: number, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return this._fetchFilterSet(
            `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/filtersets/${fqn.name}/versions/${version}`,
            `${fqn.toString()}/${version}`,
            signal
        );
    }

    listFilterSetVersions(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetVersions>> {
        const cacheKey = fqn.toString() + '/versions';
        return this.metadataCache.getOr(
            cacheKey,
            () => ServiceProvider.get(RestService)
                .get(`/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/filtersets/${fqn.name}/versions`, signal)
                .then(r => ApiResponse.custom(
                    r,
                    (json: JsonObject) => AssetVersions.fromJSON(json),
                    (json: any, r: Response) => {
                        this.metadataCache.delete(cacheKey);
                        return ErrorResponse.of(json, r);
                    }
                )),
            Infinity
        );
    }

    saveFilterSet(fqn: FQN, filterSet: ArcFilterSet, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/filtersets`,
                filterSet.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    // if save was successful, delete any cached queries and versions
                    const filterSet = ArcFilterSet.fromJSON(json);
                    this.metadataCache.delete(fqn.toString());
                    this.metadataCache.delete(fqn.toString() + '/versions');
                    return filterSet;
                },
                ErrorResponse.of
            ));
    }

    saveStory(fqn: FQN, story: Story, signal?: AbortSignal): Promise<Either<ErrorResponse, Story>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/stories`,
                story.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    // if save was successful, delete any cached queries and versions
                    const story = Story.fromJSON(json);
                    this.metadataCache.delete(fqn.toString());
                    return story;
                },
                ErrorResponse.of
            ));
    }

    archiveFilterSet(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcFilterSet>> {
        return this._archive(fqn, ArcFilterSet.fromJSON, signal);
    }

    fetchTask(fqn: FQN, signal?: AbortSignal): Promise<Object> {
        return fetch(
            `${this.host}/static/repo/${fqn.account}/${fqn.folder}/task/${fqn.name}.json`,
            {'signal': signal}
        )
            .then(r => r.json());
    }

    async listStories(fqn: FQN, loadImages: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, Story[]>> {
        const storiesResponse = await ServiceProvider.get(RestService)
            .get(`/api/v1/${fqn.apiPath}/stories`, signal)
            .then(ApiResponse.success(
                (jsons: Json[], _: Response) => jsons.map(json => Story.fromJSON(json))
            ));

        // error or don't need to hydrate scenes
        if (storiesResponse.isLeft || !loadImages) {
            return storiesResponse;
        }

        // load the images for the first scene of each story
        const imageService = ServiceProvider.get(ImageService);
        const hydrated = await Promise.all(storiesResponse.rightOrThrow().map(async (story) => {
            if (story.sceneUrl == null) {
                return Promise.resolve(story);
            }

            const response = await imageService.getImageDataUrl(story.sceneUrl, signal);
            return response.fold(
                sceneDataUrl => story.with({sceneDataUrl}),
                _ => story
            );
        }));

        return new Right(hydrated);
    }

    createScene(story: Story, scene: StoryScene, signal?: AbortSignal): Promise<Either<ErrorResponse, StoryScene>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/${story.fqn.apiPath}/scenes`, scene, signal)
            .then(ApiResponse.success(
                (json: Json, _: Response) => StoryScene.fromJSON(json)
            ));
    }

    async listScenes(fqn: FQN, loadImages: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, StoryScene[]>> {
        const scenesResponse = await ServiceProvider.get(RestService)
            .get(`/api/v1/${fqn.apiPath}/scenes`, signal)
            .then(ApiResponse.success(
                (jsons: Json[], _: Response) => jsons.map(json => StoryScene.fromJSON(json))
            ));

        // error or don't need to hydrate scenes
        if (scenesResponse.isLeft || !loadImages) {
            return scenesResponse;
        }

        // load the image data for each scene
        const imageService = ServiceProvider.get(ImageService);
        const hydrated = await Promise.all(scenesResponse.rightOrThrow().map(async (scene) => {
            if (scene.image == null) {
                return Promise.resolve(scene);
            }

            const response = await imageService.getImageDataUrl(scene.imageUrl, signal);
            return response.fold(
                imageDataUrl => scene.with({imageDataUrl}),
                _ => scene
            );
        }));

        return new Right(hydrated);
    }

    fetchAsset(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>> {
        switch (fqn.type) {
            case AssetType.ARCQL:
                return this.fetchArcQL(fqn, signal);
            case AssetType.DASHBOARD:
                return this.fetchDashboard(fqn, signal);
            default:
                throw new Error(`Asset type ${fqn.type} not supported.`);
        }
    }

    saveAsset(fqn: FQN, asset: Asset, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>> {
        switch (fqn.type) {
            case AssetType.ARCQL:
                return this.saveArcQL(fqn, asset as ArcQL, signal);
            case AssetType.DATASET_V2:
                return ServiceProvider.get(DatasetV2Service).save(fqn, asset as DatasetV2, signal);
            case AssetType.DASHBOARD:
                return this.saveDashboard(fqn, asset as ArcDashboard, signal);
            case AssetType.FILTER_SET:
                return this.saveFilterSet(fqn, asset as ArcFilterSet, signal);
            case AssetType.STORY:
                return this.saveStory(fqn, asset as Story, signal);
            case AssetType.TREND:
                // TODO: need to figure out how to interface with metadata cache
                return ServiceProvider.get(TrendsService).saveTrend(fqn, asset as ArcTrend, signal);
            default:
                throw new Error(`Asset type ${fqn.type} not supported to save.`);
        }
    }

    archiveAsset(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Asset>> {
        switch (fqn.type) {
            case AssetType.ARCQL:
                return this.archiveArcQL(fqn, signal);
            case AssetType.DASHBOARD:
                return this.archiveDashboard(fqn, signal);
            case AssetType.FILTER_SET:
                return this.archiveFilterSet(fqn, signal);
            default:
                throw new Error(`Asset type ${fqn.type} not supported to archive.`);
        }
    }

    fetchReferences(fqns: Set<FQN>, signal?: AbortSignal): Promise<Either<ErrorResponse, References>> {
        const commaDelimitedFqns = Array.from(fqns).map(f => f.toString()).join(',');
        const params = {
            fqns: commaDelimitedFqns
        };
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search/references`, signal, params)
            .then(ApiResponse.identity)
            .then(r => r.map(References.fromJSON));
    }

    assetSearch(params: AssetSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search`, signal, toParams(params))
            .then(ApiResponse.identity)
            .then(r => r.map(AssetsSearchResponse.fromJSON));
    }

    folderSearch(params: FolderSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, FoldersSearchResponse>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search/folders`, signal, toParams(params))
            .then(ApiResponse.identity)
            .then(r => r.map(FoldersSearchResponse.fromJSON));
    }

    accountSearch(params: AccountSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AccountsSearchResponse>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search/accounts`, signal, toParams(params))
            .then(ApiResponse.identity)
            .then(r => r.map(AccountsSearchResponse.fromJSON));
    }


    personaSearch(datasetFqn: FQN, params: PersonaListParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/accounts/${datasetFqn.account}/folders/${datasetFqn.folder}/${datasetFqn.type.plural}/${datasetFqn.name}/personas`, signal, toParams(params))
            .then(ApiResponse.identity)
            .then(r => r.map(AssetsSearchResponse.fromJSON));
    }

    hyperGraphSearch(params: HyperGraphSearchParams, signal?: AbortSignal): Promise<Either<ErrorResponse, AssetsSearchResponse>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search/hypergraph`, signal, toParams(params))
            .then(ApiResponse.identity)
            .then(r => r.map(AssetsSearchResponse.fromJSON));
    }

    hyperGraphSearchTest(query: string, signal?: AbortSignal): Promise<Either<ErrorResponse, boolean>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search/hypergraph/test`, signal, [['query', query]])
            .then(ApiResponse.success(
                (json: Json, _: Response) => (json as JsonObject)['isQuestion'] as boolean
            ));
    }

    hyperGraphGlobalAnswer(query: string, signal?: AbortSignal): Promise<Either<ErrorResponse, GlobalAnswerWithNodes>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/search/hypergraph/answerWithNodes`, signal, [['query', query]])
            .then(ApiResponse.identity)
            .then(r => r.map(GlobalAnswerWithNodes.fromJSON));
    }

    private _archive<T>(fqn: FQN, fromJSON: (json: JsonObject) => T, signal?: AbortSignal): Promise<Either<ErrorResponse, T>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/${fqn.type.name}s/${fqn.name}/archive`,
                {},
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    // if archive was successful, delete any cached queries and versions
                    const asset = fromJSON(json);
                    this.metadataCache.delete(fqn.toString());
                    this.metadataCache.delete(fqn.toString() + '/versions');
                    return asset;
                },
                ErrorResponse.of
            ));
    }
}