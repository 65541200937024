import {DatasetStatusMetadata} from "metadata/dataset/DatasetStatusMetadata";
import {DatasetType} from "metadata/dataset/DatasetType";
import {ArcDatasetStatusMetadata} from "metadata/dataset/ArcDatasetStatusMetadata";
import {SnowflakeDatasetStatusMetadata} from "metadata/dataset/snowflake/SnowflakeDatasetStatusMetadata";
import {BigQueryDatasetStatusMetadata} from "metadata/dataset/bigquery/BigQueryDatasetStatusMetadata";
import {PersonalDatasetStatusMetadata} from "metadata/dataset/personal/PersonalDatasetStatusMetadata";


/**
 * Factory for creating DatasetStatusMetadata.
 */
export class DatasetStatusMetadataFactory {

    static fromJson(json: any): DatasetStatusMetadata {
        const datasetType = DatasetType.get<DatasetType>(json.type);
        switch (datasetType) {
            case DatasetType.ARC:
                return ArcDatasetStatusMetadata.fromJSON(json);
            case DatasetType.SNOWFLAKE:
                return SnowflakeDatasetStatusMetadata.fromJSON(json);
            case DatasetType.BIG_QUERY:
                return BigQueryDatasetStatusMetadata.fromJSON(json);
            case DatasetType.PERSONAL:
                return PersonalDatasetStatusMetadata.fromJSON(json);
            default:
                throw new Error(`Unknown dataset type: ${json.type}`);
        }
    }

}