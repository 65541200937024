import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {AnalyticsTyped} from "metadata/query/AnalyticsTyped";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {FieldProjection} from "metadata/query/FieldProjection";

export abstract class ArcQLGrouping implements AnalyticsTyped, FieldProjection {

    protected constructor(
        public readonly type: ArcQLGroupingType,
        public readonly field: string
    ) {
    }

    /**
     * Number of groupings defined in the field, this won't always be 1 due to hierarchies.
     */
    abstract size(): number;

    /**
     * What the grouping is projected as in the result set.
     */
    abstract get projectedAs(): string;

    abstract analyticsType(dataset: ArcDataset): AnalyticsType;

    /**
     * Return the label for the grouping using the dataset metadata if necessary.
     */
    label(dataset: ArcDataset): string {
        return dataset.getLabel(this.field, `<missing: ${this.field}>`);
    }
}