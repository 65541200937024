import {JsonObject} from "common/CommonTypes";

export class StoryScene {

    static fromJSON(json: JsonObject): StoryScene {
        return new StoryScene(
            json['label'],
            json['description'],
            json['image'],
            json['state'],
            json['imageUrl'],
            null
        );
    }

    static new(label: string) {
        return new StoryScene(label, '', null, {}, null, null);
    }

    constructor(
        public readonly label: string,
        public readonly description: string,
        public readonly image: string,
        public readonly state: JsonObject,
        public readonly imageUrl: string,
        // further loading a scene to include the image data url
        public readonly imageDataUrl: string
    ) {}

    get caption(): string {
        if (this.description) {
            return `${this.label}: ${this.description}`;
        } else {
            return this.label;
        }
    }

    with({label, description, image, state, imageDataUrl}: SceneProps): StoryScene {
        return new StoryScene(
            label ?? this.label,
            description ?? this.description,
            image ?? this.image,
            state ?? this.state,
            this.imageUrl,
            imageDataUrl ?? this.imageDataUrl
        );
    }

    toJSON(): JsonObject {
        return {
            label: this.label,
            description: this.description,
            image: this.image,
            state: this.state
        };
    }

}

export type SceneProps = {
    label?: string;
    description?: string;
    image?: string;
    state?: JsonObject;
    imageDataUrl?: string;
}