import * as React from "react";
import {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {FQN} from "common/FQN";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {css} from "@emotion/react";
import {LinkType} from "metadata/dashboard/widgets/LinkType";
import {AssetType} from "metadata/AssetType";
import {DashboardFilterActor} from "app/dashboard/DashboardFilterActor";
import {MetadataService} from "services/MetadataService";
import {SingleSource} from "metadata/query/ArcQLSource";
import {LinkWidgetConfig} from "metadata/dashboard/widgets/config/LinkWidgetConfig";
import {InternalRouterService} from "services/InternalRouterService";
import {TabPath} from "app/TabPath";
import {QueryPath, SubPathKey} from "app/query/QueryPath";
import { StatePassMode } from "metadata/dashboard/widgets/config/StatePassMode";

interface Props {
    config: LinkWidgetConfig
    filterActor: DashboardFilterActor
}

/**
 * Widget that links to another HyperArc asset or external URL.
 *
 * @author zuyezheng
 */
export const LinkWidget: FunctionComponent<Props> = (props: Props) => {

    const buildPath = async (targetFqn: FQN, statePassMode: StatePassMode): Promise<string> => {
        switch (targetFqn.type) {
            case AssetType.ARCQL:
                const metadata = (await ServiceProvider.get(MetadataService).fetchArcQL(targetFqn))
                    .rightOrThrow();
                const queryPath = QueryPath.fromBase(targetFqn);
                return props.filterActor
                    // see if there's filter state to attach
                    .filterStatePassToQuery(statePassMode, (metadata.source as SingleSource).fqn.toString())
                    .map(state => queryPath.with(SubPathKey.FACETS, [state]))
                    // no state, just use the base path
                    .getOr(queryPath)
                    .toString();
            case AssetType.DASHBOARD:
                // TODO ZZ maybe dashboards needs its own "QueryPath"
                return '/' + targetFqn.toString() +
                    props.filterActor.filterStatePassToDashboard(statePassMode)
                        .map(state => '/' + state)
                        .getOr('');
        }
    };

    // open URLs in a new browser tab without any callbacks for security (noopener) or assets in a new hyperarc tab
    const onClick = async () => {
        if (props.config.link.getValue('linkType') === LinkType.URL) {
            window.open(props.config.link.getValue('location'), '_blank', 'noopener');
        } else {
            if (FQN.isFqn(props.config.link.getValue('location'))) {
                ServiceProvider.get(InternalRouterService)
                    .route(
                        'dashboard',
                        TabPath.fromRaw(await buildPath(
                            FQN.parse(props.config.link.getValue('location')),
                            props.config.link.getValue('statePassing')
                        )),
                        new Map([[
                            'skipLocalStorage', true
                        ]])
                    );
            } else {
                // not an fqn
                ServiceProvider.get(NotificationsService).publish(
                    'linkWidget', NotificationSeverity.WARNING, 'Invalid asset link, please check with dashboard creator.'
                );
            }
        }
    };

    return <S.Container
        horizontalAlignStyling={props.config.text.getValue('alignment').styling}
        verticalAlignStyling={props.config.text.getValue('verticalAlignment').styling}
        fontColor={props.config.text.getValue('fontColor')}
        fontSize={props.config.text.getValue('fontSize')}
        onClick={onClick}
    >
        <S.Link
            underlined={props.config.link.getValue('underlined')}
        >
            {props.config.link.getValue('label')}
        </S.Link>
    </S.Container>;

};

const S = {

    Container: styled.div<{
        horizontalAlignStyling: { [prop: string]: string };
        verticalAlignStyling: { [prop: string]: string };
        fontColor: string;
        fontSize: string;
    }>`
        padding: 0 10px;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        ${props => props.horizontalAlignStyling && css(props.horizontalAlignStyling)}
        ${props => props.verticalAlignStyling && css(props.verticalAlignStyling)}
        color: ${(props) => props.fontColor};
        font-size: ${(props) => props.fontSize};
    `,

    Link: styled.a<{
        underlined: boolean
    }>`
        text-decoration: ${(props) => props.underlined ? 'underline' : 'none'};
        font-weight: 500;
    `
};