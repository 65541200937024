import {TabType} from "app/TabType";
import {PathError} from "app/PathError";
import {FQN} from "common/FQN";
import {AssetType} from "metadata/AssetType";
import {Optional} from "common/Optional";


type TabPathProps = {
    parts: string[],
    type: TabType,
    isEmbed: boolean,
    accountName: string,
    folderName: string,
    assetName: string
};

/**
 * Helps parse and manage paths.
 *
 * @author zuyezheng
 */
export class TabPath {

    static fromRaw(rawPath: string, isEmbed: boolean = false): TabPath {
        const path = TabPath.sanitize(rawPath);

        // parse out all the parts, we sanitize with a leading `/` so skip that
        const parts = path.substring(1).split('/');

        // check it's a tab to a standard page
        const type: TabType = TabType.get(parts[0]);
        if (type !== undefined) {
            if (type.isAsset) {
                throw new PathError('Invalid path.', path);
            }
            return new TabPath(parts, type, isEmbed);
        }

        // if there are 3 or more parts, see if it's a FQN to an asset
        if (parts.length >= 3) {
            const type: TabType = TabType.get(parts[2]);
            if (type !== undefined && type.isAsset) {
                const orgName = parts[0];
                const folderName = parts[1];
                const assetName = parts.length >= 4 ? parts[3] : null;
                return new TabPath(parts, type, isEmbed, orgName, folderName, assetName);
            }
        }
        throw new PathError('Invalid path.', path);
    }

    static fromFqn(fqn: FQN, isEmbed: boolean = false): TabPath {
        return new TabPath(
            fqn.parts,
            TabType.get(fqn.type.name),
            isEmbed,
            fqn.account,
            fqn.folder,
            fqn.name
        );
    }

    constructor(
        // path split into parts
        public readonly parts: string[],
        public readonly type: TabType,
        public readonly isEmbed: boolean,
        // if asset type, it will be contained in a folder for an org
        public readonly accountName?: string,
        public readonly folderName?: string,
        public readonly assetName?: string
    ) {
    }

    get path(): string {
        return '/' + this.parts.join('/');
    }

    /**
     * Full path as is.
     */
    get fullPath(): string {
        return window.location.protocol + '//' + window.location.host + '#' + this.path;
    }

    /**
     * Full path only up to the asset, excluding things like state.
     */
    get fullAssetPath(): string {
        return window.location.protocol + '//' + window.location.host + '#/' + this.parts.slice(0, 4).join('/');
    }

    /**
     * Paths can route to the same tab which we identify with tabId.
     */
    get tabId(): string {
        return '/' + this.tabIdParts.join('/');
    }

    get tabIdParts(): string[] {
        if (this.type.isAsset) {
            // assets use the full path
            return this.parts.slice(0, 4);
        } else {
            // everything else just use the first part such as /home/browse and /home/foryou both route to home
            return [this.parts[0]];
        }
    }

    extendTabId(...parts: string[]) {
        const newParts = [...this.tabIdParts, ...parts];

        return new TabPath(
            newParts,
            this.type,
            this.isEmbed,
            this.accountName,
            this.folderName,
            this.assetName
        );
    }

    isSameTab(path: string | TabPath): boolean {
        if (typeof path === 'string') {
            path = TabPath.fromRaw(path);
        }

        return path.tabId === this.tabId;
    }

    /**
     * If we are certain the tab is an asset, coerce into an FQN.
     */
    get assetFqn(): FQN {
        return new FQN(
            this.accountName,
            this.folderName,
            AssetType.get(this.type.name),
            this.assetName
        );
    }

    /**
     * If an asset, return the FQN for the asset.
     */
    get possibleFqn(): Optional<FQN> {
        return this.type.isAsset ? Optional.of(this.assetFqn) : Optional.none();
    }

    /**
     * Get a specific part of the path if it exists.
     */
    getPart(i: number): Optional<string> {
        if (i < this.parts.length) {
            return Optional.some(this.parts[i]);
        }

        return Optional.none();
    }

    /**
     * Create new instance of TabPath with the appropriate props changed.
     */
    with(
        props: Partial<TabPathProps>
    ): TabPath {
        return new TabPath(
            props.parts ?? this.parts,
            props.type ?? this.type,
            props.isEmbed ?? this.isEmbed,
            // below props are nullable so hence cannot use the nullish coalescing operator (??)
            props.accountName !== undefined ? props.accountName : this.accountName,
            props.folderName !== undefined ? props.folderName : this.folderName,
            props.assetName !== undefined ? props.assetName : this.assetName
        );
    }

    static sanitize(rawPath: string): string {
        // make sure there is a leading `/`
        if (rawPath.length > 0 && rawPath[0] !== '/') {
            rawPath = '/' + rawPath;
        }
        // remove trailing `/`
        if (rawPath.length > 1 && rawPath[rawPath.length - 1] === '/') {
            rawPath = rawPath.substring(0, rawPath.length - 1);
        }
        // normalize to home if empty string
        if (rawPath.length === 0 || rawPath === '/') {
            rawPath = TabType.HOME.singletonPath().path;
        }

        return rawPath;
    }

}