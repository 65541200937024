import {JsonObject} from "common/CommonTypes";
import {SubscriptionMetadata} from "metadata/subscription/SubscriptionMetadata";
import {UserSubscriptionType} from "metadata/subscription/UserSubscriptionType";
import {
    AlertSubscriptionMetadata,
    AlertSubscriptionMetadataJson
} from "metadata/subscription/AlertSubscriptionMetadata";

/**
 * Factory for creating subscription metadata.
 */
export class SubscriptionMetadataFactory {
    static fromJSON(json: JsonObject): SubscriptionMetadata {
        const type: UserSubscriptionType = UserSubscriptionType.get(json.type);
        switch (type) {
            case UserSubscriptionType.ALERT:
                return AlertSubscriptionMetadata.fromJSON(json as AlertSubscriptionMetadataJson);
            default:
                throw new Error(`Unsupported subscription type: ${type}`);
        }
    }
}