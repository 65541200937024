import {Enum} from "common/Enum";
import {RelativeDate} from "app/query/filters/RelativeDate";
import dayjs from "dayjs";
import {DateFilterGrain} from "app/query/filters/DateFilterGrain";
import {FilterClause} from "metadata/query/filterclause/FilterClause";

/**
 * Either absolute or relative date filter.
 *
 * @author zuyezheng
 */
export class DateFilterType extends Enum {

    static ABSOLUTE = new this(
        'absolute',
        'Absolute',
        () => dayjs().subtract(1, 'month').format(DateFilterGrain.DATE.arcQLFormat)
    );

    static RELATIVE = new this(
        'relative',
        'Relative',
        () => '30 days ago'
    );

    static fromFilter(filter: FilterClause): DateFilterType {
        if (filter.values.every(RelativeDate.isA)) {
            return DateFilterType.RELATIVE;
        } else {
            return DateFilterType.ABSOLUTE;
        }
    }

    private constructor(
        name: string,
        public readonly label: string,
        public readonly defaultValue: () => string
    ) {
        super(name);
    }

}
DateFilterType.finalize();
