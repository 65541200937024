import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";

import Alert, {AlertColor} from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {StyledEngineProvider} from "@mui/material/styles";
import {Chrome} from "app/home/Chrome";
import {Onboarding} from "app/home/onboarding/Onboarding";
import {Tab} from "app/Tab";
import {TabPath} from "app/TabPath";
import {TabType} from "app/TabType";
import {UserContext} from "app/UserContext";
import {Optional} from "common/Optional";
import {OrderedMap} from "common/OrderedMap";
import {useHistory, useLocation} from "react-router-dom";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Tuple} from "common/Tuple";
import {DuplicateEmailDialog} from "app/home/DuplicateEmailDialog";
import {BrandedFooter} from "app/home/BrandedFooter";
import {LocalStorageService} from "services/LocalStorageService";
import {InternalRouterService} from "services/InternalRouterService";
import {TabService} from "services/TabService";
import {useSearchHotkey} from "app/components/SearchHooks";
import {GlobalSearch} from "app/components/search/GlobalSearch";
import {TabContent} from "app/visualizations/TabContent";
import {useHyperArcAuth} from "app/components/hooks/AuthHook";


type Props = {
    isEmbed: boolean
}

/**
 * Main app component. It expects that the auth0 hook isLoading has been set to false prior to being rendered
 */
export const App: FunctionComponent<Props> = (props: Props) => {

    // identity stuff
    const {
        onLoggedIn,
        onFinishedSignup,
        onLogout,
        isAuthenticated,
        memberships,
        hyperArcUser,
        needsSignup,
        duplicateEmailMessage
    } = useHyperArcAuth(props.isEmbed);

    const location = useLocation();
    const history = useHistory();

    // current notification with severity and message
    const [notification, setNotification] = useState<Optional<Tuple<NotificationSeverity, string>>>(Optional.none());

    // current path
    const [selectedPath, setSelectedPath] = useState<TabPath>(
        props.isEmbed ?
            TabPath.fromRaw(location.pathname, props.isEmbed) :
            TabType.HOME.singletonPath()
    );
    // tabs keyed off of path
    const initialTab = Tab.from(props.isEmbed ? selectedPath : TabType.HOME.singletonPath());
    const [tabs, setTabs] = useState<OrderedMap<string, Tab>>(
        OrderedMap.fromKeyed(
            [initialTab], v => v.path.path
        )
    );

    const [showSearch, setShowSearch] = useSearchHotkey(selectedPath);

    const isLocationDifferent = (path: TabPath) => {
        return path.path !== location.pathname;
    };

    // handle location changes
    useEffect(() => {
        // given the tab path, see if we need to make any changes
        const finalTabPath = (async () => {
            // clean up the path and see if it is a new or existing tab
            const tabPath = TabPath.fromRaw(location.pathname, props.isEmbed);
            const existingTab = tabs.get(tabPath.tabId);

            if (existingTab.isNone || existingTab.get().path.type.shouldForceRedirect(tabPath)) {
                // create the new tab and also check if we need to redirect which is async
                return addTab((await tabPath.type.redirect(tabPath)).getOr(Tab.from(tabPath)));
            } else if (existingTab.get().path.path !== tabPath.path) {
                // if it's an existing tab, but the sub path changed, need to replace it
                onTabChange(
                    existingTab.get().path,
                    existingTab.get().label,
                    tabPath,
                    existingTab.get().hasChanges
                );
            }

            return tabPath;
        })();

        finalTabPath.then(path => {
            // update the history if sanitized path is slightly different
            if (isLocationDifferent(path)) {
                history.replace(path.path);
            }

            // go to the content for the selected path
            setSelectedPath(path);
        });
    }, [location]);

    useEffect(() => {
        const internalRouterService = ServiceProvider.get(InternalRouterService);
        internalRouterService.register(this, onInternalRoute);

        // stop listening to routes if unmounted
        return () => internalRouterService.unregister(this);
    }, []);

    useEffect(() => {
        // start listening to notifications so we can show them
        const notificationsService = ServiceProvider.get(NotificationsService);
        notificationsService.register(this, onNotification);

        // stop listening to notifications if unmounted
        return () => notificationsService.unregister(this);
    }, []);

    useEffect(() => {
        ServiceProvider.get(TabService).setTabs(tabs);
    }, [tabs]);

    const onInternalRoute = (caller: string, path: TabPath, context: Map<string, any>): void => {
        // when internally routing, also need to pass forward embedded context
        // (which may be lost for example due to link widget navigation)
        const routedPath = path.with({isEmbed: props.isEmbed});
        const newTabPath = (async () => {
            return addTab((await routedPath.type.redirect(routedPath)).getOr(Tab.from(routedPath, context)));
        })();

        newTabPath.then(path => {
            // update the history if sanitized path is slightly different
            if (isLocationDifferent(path)) {
                history.replace(path.path);
            }

            // go to the content for the selected path
            setSelectedPath(path);
        });
    };

    const addTab = (tab: Tab): TabPath => {
        // update the tabs, this uses a lambda since some callers might be a callback and have an outdated reference
        setTabs((tabs) => {
            const newTabs = tabs.copy();
            // replace the tab in the same order if it exists and override (in case of context changes)
            if (newTabs.has(tab.path.tabId)) {
                newTabs.replace(tab.path.tabId, tab);
            } else {
                // simply add new tab at end
                newTabs.add(tab.path.tabId, tab);
            }
            return newTabs;
        });

        // return the path of the new tab
        return tab.path;
    };

    const onNotification = (caller: string, severity: NotificationSeverity, message: string): void => {
        setNotification(Optional.of(Tuple.of(severity, message)));
    };

    const onAddTab = () => {
        history.push('/new');
    };

    const onSelectTab = (tab: Tab) => {
        // push to change to browser history
        history.push(tab.path.path);
    };

    const onCloseTab = (tab: Tab) => {
        // change to the tab to the left if closing the selected
        if (tab.path.isSameTab(selectedPath)) {
            if (tabs.size > 1) {
                history.replace(tabs.lastBefore(tab.path.tabId).getOr(tabs.first.path.path));
            } else {
                // if we are closing the only tab, this is likely from embedded (since there should always be a home)
                // so open the error page
                history.replace('/error');
            }
        }

        // if asset, clear any local storage since tab was voluntarily closed
        if (tab.path.type.isAsset) {
            ServiceProvider.get(LocalStorageService).clearAsset(tab.path.assetFqn);
        }

        // remove the tab
        const newTabs = tabs.copy();
        newTabs.delete(tab.path.tabId);
        setTabs(newTabs);
    };

    const onTabChange = (
        // original path being changed
        originalPath: TabPath,
        // new label, will default to asset name if empty
        label: string,
        // new path value
        tabPath: TabPath,
        // if the tab has any changes
        hasChanges: boolean
    ) => {
        // update the tab with the change
        const newTabs = tabs.copy();
        newTabs.replace(
            originalPath.tabId,
            new Tab(tabPath, label || tabPath.assetName, new Map(), hasChanges),
            tabPath.tabId
        );

        // replace the tabs
        setTabs(newTabs);

        // if we're replacing the selected tab, update the selected path and URL history
        if (selectedPath.isSameTab(originalPath)) {
            setSelectedPath(tabPath);
            history.replace(tabPath.path);
        }
    };

    const onCloseNotification = () => {
        setNotification(Optional.none());
    };

    const renderBody = () => {
        // not embedded and not authed, don't load
        if (!props.isEmbed && !hyperArcUser.isPresent) {
            return;
        }

        return <S.Body>{
            tabs.values.map((tab: Tab) =>
                <TabContent
                    key={tab.path.tabId}

                    selected={tab.path.isSameTab(selectedPath)}
                    tab={tab}

                    onTabChange={onTabChange}
                    onCloseTab={onCloseTab}
                />
            )
        }</S.Body>;
    };
    const isSuperUser = memberships.some(u => u.name === 'hyperarc');

    return <UserContext.Provider value={{user: hyperArcUser, memberships: memberships, isSuperUser}}>
        <StyledEngineProvider injectFirst>
            <S.App>
                {
                    !props.isEmbed && <>
                        {
                            duplicateEmailMessage.map(msg => {
                                return <DuplicateEmailDialog message={msg} onClose={() => onLogout()}/>;
                            }).getOr(<></>)
                        }
                        <Onboarding
                            open={isAuthenticated && needsSignup}
                            onLoggedIn={onLoggedIn}
                            onFinish={() => onFinishedSignup()}
                        />
                        <Chrome
                            tabs={tabs.values}
                            selectedPath={selectedPath}
                            onAdd={onAddTab}
                            onSelect={onSelectTab}
                            onClose={onCloseTab}
                        />
                    </>
                }
                {
                    renderBody()
                }
                {
                    showSearch.map(scope =>
                        <GlobalSearch
                            scope={scope}
                            onClose={() => setShowSearch(Optional.none())}
                        />
                    ).nullable
                }
                {
                    notification.map(notification =>
                        <Snackbar
                            open={true}
                            autoHideDuration={6000}
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            onClose={onCloseNotification}
                            ClickAwayListenerProps={{
                                'mouseEvent': false,
                                'touchEvent': false
                            }}
                        >
                            <Alert
                                onClose={onCloseNotification}
                                severity={notification.left.name as AlertColor}
                                sx={{width: '100%', whiteSpace: "pre-line"}}
                            >{notification.right}</Alert>
                        </Snackbar>
                    ).nullable
                }
                {
                    props.isEmbed && <BrandedFooter />
                }
            </S.App>
        </StyledEngineProvider>
    </UserContext.Provider>;

};

const S = {
    App: styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `,
    Body: styled.div`
        height: 100%;
        flex: 1;
        overflow: hidden;
    `
};