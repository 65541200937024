import { Enum } from "common/Enum";

/**
 * Enum for Snowflake authentication methods.
 */
export class SnowflakeAuthentication extends Enum {

    static readonly KEYPAIR = new this('keyPair', "Key Pair");

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
SnowflakeAuthentication.finalize();

