import * as React from "react"
import {SVGProps} from "react"

const DecimalIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M2.094 3 1.72 5.642H.374v.826h1.222l-.25 1.903H0v.827h1.222l-.374 2.667h.835l.374-2.667h1.57l-.373 2.667h.835l.374-2.667h1.346V8.37H4.587l.262-1.903h1.334v-.826H4.96L5.335 3H4.5l-.374 2.642h-1.57L2.93 3h-.836Zm1.92 3.468-.262 1.904h-1.57l.261-1.904h1.571ZM7.755 8.371v.827h1.222l-.374 2.667h.835l.374-2.667h1.57l-.373 2.667h.835l.374-2.667h1.346V8.37h-1.222l.262-1.903h1.334v-.826h-1.222L13.09 3h-.835l-.374 2.642h-1.57L10.684 3h-.836l-.374 2.642H8.13v.826h1.222L9.1 8.371H7.755Zm2.443-1.903h1.571l-.262 1.904h-1.57l.261-1.904Z"
            clipRule="evenodd"
        />
        <path
            d="M6.387 11.834a.72.72 0 0 0 .72-.723.72.72 0 0 0-.72-.722.72.72 0 0 0-.72.722.72.72 0 0 0 .72.723Z"
        />
    </svg>
)
export default DecimalIcon
