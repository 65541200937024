import * as React from "react";
import {FunctionComponent, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {FQN} from "common/FQN";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {Asset} from "metadata/Asset";
import {SaveHandler} from "metadata/SaveHandler";
import {DialogS} from "app/DialogS";

type Props = {
    fqn: FQN
    label: string
    description: string
    saveHandler: SaveHandler<Asset>

    onCancel: () => void
    onSave: (asset: Asset) => void

    // optional title override for the dialog
    overrideTitle?: string
}

/**
 * Dialog of saving a new version of an existing asset.
 *
 * @author zuyezheng
 */
export const SaveDialog: FunctionComponent<Props> = (props: Props) => {

    const [description, setDescription] = useState<string>(props.description);

    const onSave = () => {
        props.saveHandler.save(props.fqn, props.label, description)
            .then(r => r.match(
                asset => props.onSave(asset),
                errors => console.log(errors)
            ));
    };

    return <Dialog
        open={true}
        onClose={props.onCancel}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        <DialogS.Title>{ props.overrideTitle?? "What's changed?"}</DialogS.Title>
        <DialogS.Content dividers>
            <DialogS.Body>
                <DialogS.InputRow>
                    <FormControl fullWidth>
                        <FormLabel id="description-label">
                            Description
                        </FormLabel>
                        <TextField
                            margin="none"
                            size="small"
                            id="description"
                            aria-labelledby="description-label"
                            placeholder="Optional description"
                            value={description}
                            fullWidth={true}
                            multiline
                            rows={10}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </FormControl>
                </DialogS.InputRow>
            </DialogS.Body>
        </DialogS.Content>
        <DialogS.Actions>
            <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
            <Button variant="contained" onClick={onSave}>Save</Button>
        </DialogS.Actions>
    </Dialog>;

};
