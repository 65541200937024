import {JsonObject} from "common/CommonTypes";
import {FQN} from "common/FQN";
import {Asset} from "metadata/Asset";
import {AssetType} from "metadata/AssetType";

/**
 * Model for a story asset.
 */
export class Story implements Asset {

    static fromJSON(json: JsonObject): Story {
        return new Story(
            json['id'],
            FQN.parse(json['fqn']),
            json['createdOn'],
            json['createdBy'],
            json['name'],
            json['label'],
            json['description'],
            AssetType.get(json['targetAssetType']),
            json['targetAssetId'],
            json['sceneUrl'],
            null
        );
    }

    static new(targetAsset: Asset) {
        return new Story(
            undefined,
            new FQN(targetAsset.fqn.account, targetAsset.fqn.folder, AssetType.STORY, 'new-story'),
            null,
            null,
            '',
            'New Story',
            '',
            targetAsset.assetType,
            targetAsset.id,
            null,
            null
        );
    }

    constructor(
        public readonly id: string,
        public readonly fqn: FQN,
        public readonly createdOn: string,
        public readonly createdBy: string,
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        public readonly targetAssetType: AssetType,
        public readonly targetAssetId: string,
        public readonly sceneUrl: string,
        // further loading of sceneUrl
        public readonly sceneDataUrl: string
    ) {}

    get assetType(): AssetType {
        return AssetType.STORY;
    }

    get isExisting(): boolean {
        return this.id !== undefined;
    }

    get caption(): string {
        if (this.description) {
            return `${this.label}: ${this.description}`;
        } else {
            return this.label
        }
    }

    with({name, label, description, sceneDataUrl}: StoryProps): Story {
        return new Story(
            this.id,
            this.fqn,
            this.createdOn,
            this.createdBy,
            name ?? this.name,
            label ?? this.label,
            description ?? this.description,
            this.targetAssetType,
            this.targetAssetId,
            this.sceneUrl,
            sceneDataUrl ?? this.sceneDataUrl
        )
    }

    toJSON(): JsonObject {
        return {
            id: this.id,
            fqn: this.fqn.toString(),
            name: this.name,
            label: this.label,
            description: this.description,
            targetAssetType: this.targetAssetType.name,
            targetAssetId: this.targetAssetId
        };
    }

    toLocalStorageJSON(fqn: FQN): JsonObject {
        throw new Error("Method not implemented.");
    }

}

export type StoryProps = {
    name?: string,
    label?: string,
    description?: string,
    sceneDataUrl?: string
}