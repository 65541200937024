import {JsonObject} from "common/CommonTypes";

/**
 * Request for patching a folder.
 */
export default class FolderPatchRequest {
    constructor(
        public readonly label?: string,
        public readonly description?: string,
        public readonly isPublic?: boolean
    ) {
    }

    toJSON(): JsonObject {
        const result: JsonObject = {};

        if (this.label !== undefined && this.label !== null) {
            result.label = this.label;
        }

        if (this.description !== undefined) {
            result.description = this.description;
        }

        if (this.isPublic !== undefined && this.isPublic !== null) {
            result.isPublic = this.isPublic;
        }

        return result;
    }
}