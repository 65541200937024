import {DashboardQueries} from "metadata/dashboard/DashboardQueries";
import {DashboardLayouts} from "metadata/dashboard/DashboardLayouts";
import {DashboardWidgets} from "metadata/dashboard/DashboardWidgets";
import {JsonObject} from "common/CommonTypes";
import {Asset, AssetMetadataProps, AssetProps} from "metadata/Asset";
import {FQN} from "common/FQN";
import {DashboardGlobalFilters} from "metadata/dashboard/DashboardGlobalFilters";
import {References} from "metadata/References";
import {DashboardInteractions} from "metadata/dashboard/DashboardInteractions";
import {UnhydratedReferences} from "metadata/UnhydratedReferences";
import {AssetType} from "metadata/AssetType";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";
import {GlobalFilterSetFilterClause} from "metadata/dashboard/GlobalFilterSetFilterClause";

export type ArcDashboardBodyProps = {
    queries?: DashboardQueries,
    widgets?: DashboardWidgets,
    layouts?: DashboardLayouts,
    globalFilters?: DashboardGlobalFilters,
    interactions?: DashboardInteractions
    config?: DashboardConfig
}

// all mutable props
export type ArcDashboardProps = AssetProps & AssetMetadataProps & ArcDashboardBodyProps;

export class ArcDashboard implements Asset {

    static fromJSON(json: JsonObject): ArcDashboard {
        const references: References = References.fromJSON(json[References.JSON_KEY]);
        return new ArcDashboard(
            json['id'],
            json['fullyQualifiedName'] && FQN.parse(json['fullyQualifiedName']),
            json['name'],
            json['label'],
            json['description'],
            DashboardQueries.fromJSON(json['queries']),
            DashboardWidgets.fromJSON(json['widgets']),
            DashboardLayouts.fromJSON(json['layouts']),
            DashboardGlobalFilters.fromJSON(json['globalFilters'], references),
            references,
            DashboardInteractions.fromJSON(json['interactions']),
            DashboardConfig.fromJSON(json['config'] || {}),
            json['version']
        );
    }

    static minimal(): ArcDashboard {
        return new ArcDashboard();
    }

    private constructor(
        // id should default to null until saved as its the indicator for a new vs existing asset
        public readonly id: string = null,
        public readonly fullyQualifiedName: FQN = null,
        // these should default to null so we know when to apply defaults vs user explicitly set an empty string
        public readonly name: string = null,
        public readonly label: string = null,
        public readonly description: string = null,
        // referenced queries used in this dashboard
        public readonly queries: DashboardQueries = DashboardQueries.empty(),
        // widgets specifying visualization of queries for static context (images, markdown, etc)
        public readonly widgets: DashboardWidgets = DashboardWidgets.empty(),
        // how widgets are composed in various layouts
        public readonly layouts: DashboardLayouts = DashboardLayouts.default(),
        public readonly globalFilters: DashboardGlobalFilters = DashboardGlobalFilters.default(),
        public readonly references: References = new References(),
        public readonly interactions: DashboardInteractions = DashboardInteractions.default(),
        public readonly config: DashboardConfig = DashboardConfig.default(),
        public readonly version: number = -1
    ) {
    }

    get assetType(): AssetType {
        return AssetType.DASHBOARD;
    }

    /**
     * TODO ZZ: deprecate fullyQualifiedName.
     */
    get fqn(): FQN {
        return this.fullyQualifiedName;
    }

    with({
        name,
        label,
        description,
        queries,
        widgets,
        layouts,
        globalFilters,
        references,
        interactions,
        config
    }: ArcDashboardProps): ArcDashboard {
        return new ArcDashboard(
            this.id,
            this.fullyQualifiedName,
            name == null ? this.name : name,
            label == null ? this.label : label,
            description == null ? this.description : description,
            queries == null ? this.queries : queries,
            widgets == null ? this.widgets : widgets,
            layouts == null ? this.layouts : layouts,
            globalFilters == null ? this.globalFilters : globalFilters,
            references == null ? this.references : references,
            interactions == null ? this.interactions : interactions,
            config == null ? this.config : config,
            this.version
        );
    }

    get isExisting(): boolean {
        return this.id != null;
    }

    /**
     * Grabs the latest references unhydrated (i.e. just the FQNs) for ArcDashboard such as FilterSet assets in FilterSet filter clauses.
     */
    unhydratedReferences(): UnhydratedReferences {
        return new UnhydratedReferences(
            this.globalFilters.clauses.filter(c => c.type.hasAssetRefs)
                .map((c: GlobalFilterSetFilterClause) => c.fullyQualifiedName)
        );
    }

    toJSON(): JsonObject {
        return {
            id: this.id,
            name: this.name,
            label: this.label,
            description: this.description,
            queries: this.queries,
            widgets: this.widgets,
            layouts: this.layouts,
            globalFilters: this.globalFilters,
            interactions: this.interactions,
            config: this.config
        };
    }

    toLocalStorageJSON(fqn: FQN): JsonObject {
        return {
            ...this.toJSON(),
            // stuff we normally wouldn't pass to the server for saving
            fullyQualifiedName: fqn.toString(),
            version: this.version,
            // ArcDashboard may constantly change due to it being WIP and unsaved, hence store information in local to later hydrate refs
            unhydratedReferences: this.unhydratedReferences()
        };
    }

}