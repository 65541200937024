import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ToDetailFieldsChange} from "metadata/query/changes/ToDetailFieldsChange";
import {DetailField} from "metadata/query/DetailField";
import {MeasureField, MeasureOperator} from "metadata/query/MeasureField";

/**
 * Convert the given fields to measure fields.
 *
 * @author zuyezheng
 */
export class ToMeasureFieldsChange implements ArcMetadataChange<ArcQL> {

    constructor(
        // projected names of fields with the operator to use to convert them to measures
        public readonly fields: [string, MeasureOperator][]
    ) {}

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const fields = new Map(this.fields);

        return new Tuple(
            metadata.with({
                'fields': metadata.fields.withMapped(field => {
                    // only detail fields can be converted
                    if (!(field instanceof DetailField)) {
                        throw new Error(`Expected ${field.as} to be a detail to convert to a measure.`);
                    }

                    const measureField = field as DetailField;
                    if (fields.has(measureField.as)) {
                        // convert to a detail field
                        return new MeasureField(measureField.field, fields.get(measureField.as), measureField.as);
                    } else {
                        // no change
                        return field;
                    }
                })
            }),
            new ToDetailFieldsChange(
                Array.from(fields.keys()).map(as =>
                    // get the old metadata operators for undo
                    [as, (metadata.fields.get(as) as DetailField).operator]
                )
            )
        );
    }

    describe(): string {
        return `Changed details to grouping query with ${this.fields.length} fields.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}