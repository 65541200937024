import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {ReplaceReason} from "metadata/ReplaceReason";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";

/**
 * Replace the entire filter set.
 */
export class ArcFilterSetReplace implements ArcMetadataChange<ArcFilterSet> {

    constructor(
        public readonly filterSet: ArcFilterSet,
        public readonly reason: ReplaceReason
    ) {
    }

    apply(metadata: ArcFilterSet): Tuple<ArcFilterSet, ArcMetadataChange<ArcFilterSet>> {
        return Tuple.of(
            this.filterSet,
            new ArcFilterSetReplace(metadata, this.reason)
        );
    }

    describe(): string {
        return `Replaced filter set from ${this.reason.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.REPLACE;
    }
}