import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export class ValueFormatter {

    // use the standard locale based formatter
    private static BASIC = new Intl.NumberFormat('en-US', {
        // show up to 4 decimal places
        maximumFractionDigits: 4
    });

    private static BASIC_FRACTIONAL = new Intl.NumberFormat('en-US', {
        // show up to 4 significant digits, this should only be used for 1 > x < -1
        maximumSignificantDigits: 4
    });

    private static PERCENT_CHANGE = Intl.NumberFormat('en-US', {
        signDisplay: 'exceptZero',
        style: 'percent',
        maximumFractionDigits: 0
    });

    private static ROUNDED_NUMBER = Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
    });

    private static COMPACT_NUMBER = Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 2
    });

    static percentChange(value: number): string {
        return ValueFormatter.PERCENT_CHANGE.format(value);
    }

    static basicNumber(value: number): string {
        if (value < 1 && value > -1) {
            return ValueFormatter.BASIC_FRACTIONAL.format(value);
        } else {
            return ValueFormatter.BASIC.format(value);
        }
    }

    static roundedNumber(value: number | bigint): string {
        return ValueFormatter.ROUNDED_NUMBER.format(value);
    }

    static compactNumber(value: number | bigint): string {
        return ValueFormatter.COMPACT_NUMBER.format(value);
    }

    static fromNow(dateString: string): string {
        return dayjs(dateString).fromNow();
    }

}