import * as React from "react"
import {SVGProps} from "react"

const DynamicFilterSetIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={16}
        {...props}
    >
        <path
            d="M.25 1.66c.1.13 5.74 7.33 5.74 7.33V15c0 .55.45 1 1.01 1h2.01c.55 0 1.01-.45 1.01-1V8.98s5.49-7.02 5.75-7.34C16.03 1.32 16 1 16 1c0-.55-.45-1-1.01-1H1.01C.4 0 0 .48 0 1c0 .2.06.44.25.66ZM17.417 15.25h-.584l.584-4.083h-2.042c-.338 0-.332-.187-.222-.385.111-.199.03-.047.041-.07.753-1.33 1.884-3.314 3.39-5.962h.583l-.584 4.083h2.042c.286 0 .327.193.274.298l-.04.087c-2.299 4.02-3.442 6.032-3.442 6.032Z"
        />
    </svg>
)
export default DynamicFilterSetIcon
