import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {VizSelection} from "engine/actor/VizSelection";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {SingleMetricData} from "app/visualizations/data/SingleMetricData";
import {SingleMetricChartConfig} from "app/visualizations/config/SingleMetricChartConfig";

/**
 * Everyone's fav chart.
 *
 * @author zuyezheng
 */
export class DonutChartConfig extends SingleMetricChartConfig {

    private readonly data: SingleMetricData;

    constructor(response: ArcQLResponse, config: VisualizationConfig) {
        super(response, config);

        this.data = new SingleMetricData(
            response,
            (categoryValues: any[], selections: VizSelection) => ({
                isSliced: selections.has(categoryValues)
            })
        );
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        return {
            type: 'doughnut2d',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: this.config.get('showLegend').getOr(false),

                        useEllipsesWhenOverflow: '1',
                        doughnutRadius: '80%',
                        enableRotation: false,
                        minAngleForLabel: 2,

                        labelDistance: 0
                    }
                ),
                data: this.data.dataset(selections)
            }
        };
    }

}