import React, {FunctionComponent} from "react";
import PrivateProjectIcon from 'app/components/icons/project/PrivateProjectIcon';
import ProjectIcon from "app/components/icons/project/ProjectIcon";

type Props = {
    isPublic: boolean
}

export const WrappedProjectIcon: FunctionComponent<Props> = (props: Props) => {

    return props.isPublic ?
        <ProjectIcon color="primary"/> :
        <PrivateProjectIcon color="primary"/>;
};