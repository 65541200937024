import {BaseWidgetConfigPart} from "metadata/dashboard/widgets/config/BaseWidgetConfigPart";
import {JsonObject} from "common/CommonTypes";
import {LinkType} from "metadata/dashboard/widgets/LinkType";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";
import {StatePassMode} from "metadata/dashboard/widgets/config/StatePassMode";

/**
 * Properties for configuring Text-based widgets like alignment, color, background color, etc.
 */
export class LinkConfigPart extends BaseWidgetConfigPart<LinkConfigPart> {

    constructor(
        public readonly linkType: LinkType,
        public readonly label: string,
        public readonly location: string,
        public readonly underlined: boolean,
        public readonly statePassing: StatePassMode,
        defaults: LinkConfigPart
    ) {
        super(defaults);
    }

    protected get properties(): (keyof LinkConfigPart & string)[] {
        return ['linkType', 'label', 'location', 'underlined', 'statePassing'];
    }

    get type(): WidgetConfigPartType {
        return WidgetConfigPartType.LINK;
    }

    createInstance(props: Record<keyof LinkConfigPart, any>, defaults: LinkConfigPart): LinkConfigPart {
        return new LinkConfigPart(
            props.linkType,
            props.label,
            props.location,
            props.underlined,
            props.statePassing,
            defaults
        );
    }

    static fromJSON(configJson: JsonObject, defaults: LinkConfigPart = LinkConfigPart.defaults()): LinkConfigPart {
        return BaseWidgetConfigPart.fromJSONHelper(
            configJson,
            defaults,
            {
                'linkType': (value) => LinkType.get(value),
                'statePassing': (value) => StatePassMode.get(value)
            }
        );
    }

    static defaults(): LinkConfigPart {
        return new LinkConfigPart(
            LinkType.ASSET,
            '',
            '',
            true,
            StatePassMode.ALL,
            null
        );
    }
}