import * as React from "react";
import {FunctionComponent} from "react";
import Popover, {PopoverOrigin} from "@mui/material/Popover";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {ExpressionField} from "metadata/query/ExpressionField";
import "ace-builds/src-noconflict/mode-sql";
import {ExpressionEditorContent} from "app/query/components/ExpressionEditorContent";


export type Props = {
    // if used standalone, wrap in popover
    el: Element
    anchorOrigin: PopoverOrigin
    transformOrigin?: PopoverOrigin
    arcQLBundle: ArcQLBundle
    // existing field if editing
    field?: ExpressionField

    // expression field has changed and save requested
    onChange: (field: ExpressionField, shouldClose: boolean) => void
    // close without any changes
    onClose: () => void
}

/**
 * Expression editor.
 *
 * @author zuyezheng
 */
export const ExpressionEditor: FunctionComponent<Props> = (props: Props) => {
    return <Popover
        open={true}
        anchorEl={props.el}
        onClose={props.onClose}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
    >
        <ExpressionEditorContent
            arcQLBundle={props.arcQLBundle}
            withHelperText={true}
            field={props.field}
            onChange={props.onChange}
            onClose={props.onClose}
        />
    </Popover>;

};
