import {Either} from "common/Either";
import {FQN} from "common/FQN";
import {UserSubscription, UserSubscriptionJson} from "metadata/subscription/UserSubscription";
import {SubscriptionService} from "services/SubscriptionService";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";

/**
 * Api implementation of SubscriptionService.
 */
export class ApiSubscriptionService implements SubscriptionService {

    listSubscriptions(signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription[]>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/user/subscriptions`, signal)
            .then(ApiResponse.success(
                (jsons: UserSubscriptionJson[], _: Response) => jsons.map(json => UserSubscription.fromJSON(json))
            ));
    }

    subscribeToAlert(trendFqn: FQN, alertId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/${trendFqn.apiPath}/alerts/${alertId}/subscribe`, {}, signal)
            .then(ApiResponse.success(
                (json: UserSubscriptionJson, _: Response) => UserSubscription.fromJSON(json)
            ));
    }

    unsubscribeFromAlert(trendFqn: FQN, alertId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/${trendFqn.apiPath}/alerts/${alertId}/unsubscribe`, {}, signal)
            .then(ApiResponse.success(
                (json: UserSubscriptionJson, _: Response) => UserSubscription.fromJSON(json)
            ));
    }

    archiveSubscription(subscriptionId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, UserSubscription>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/user/subscriptions/${subscriptionId}/archive`, {}, signal)
            .then(ApiResponse.success(
                (json: UserSubscriptionJson, _: Response) => UserSubscription.fromJSON(json)
            ));
    }
}