import styled from "@emotion/styled";
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro';
import React from "react";
import {Button, IconButton} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {ExpandedConnection} from "app/components/settings/connections/ExpandedConnection";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";
import {ConnectionType} from "metadata/connections/ConnectionType";
import {DatasetListItem} from "metadata/dataset/DatasetListItem";
import {Colors} from "app/components/StyleVariables";
import {DataSourceTypeIcon} from "app/components/settings/connections/DataSourceTypeIcon";

interface Props {
    className?: string;
    connections: RemoteDataSource[];
    onAccountBreadcrumb: (account: string) => void;
    onDeleteConnection: (connection: RemoteDataSource) => void;
    onTestConnection: (connection: RemoteDataSource) => void;
    onDatasetSelect: (dataset: DatasetListItem) => void;
    onDatasetEdit: (dataset: DatasetListItem) => void;
    onClickAddTableForConnection: (dataSource: RemoteDataSource) => void;
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    TYPE: 'type',
    NAME: 'name',
    ORG: 'org',
    TABLES: 'tables',
    CREATED_ON: 'createdOn',
    RESULT: 'result',
    ACTIONS: 'actions'
};

/**
 * Table for displaying connections.
 */
export const ConnectionsTable: React.FC<Props> = (props: Props) => {

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        if (params.field === 'actions') {
            event.stopPropagation();
        }
    };

    const columns: GridColDef[] = [
        {
            field: FIELD_IDENTIFIERS.TYPE,
            headerName: '',
            width: 30,
            renderCell: (params: GridRenderCellParams<ConnectionType>) => (
                <DataSourceTypeIcon type={params.value}/>
            )
        },
        {field: FIELD_IDENTIFIERS.NAME, headerName: 'Connection Name', flex: 2},
        {
            field: FIELD_IDENTIFIERS.ORG,
            headerName: 'Org',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <AccountBreadCrumbInResultRow
                    text={params.value}
                    onClick={() => props?.onAccountBreadcrumb(params.value)}
                />
            )
        },
        {field: FIELD_IDENTIFIERS.TABLES, headerName: 'Tables', flex: 1},
        {
            field: FIELD_IDENTIFIERS.CREATED_ON,
            headerName: 'Date Created',
            flex: 1,
            renderCell: (params) => dayjs(params.value).format('MMM D, YYYY')
        },
        {
            field: FIELD_IDENTIFIERS.ACTIONS,
            headerName: '',
            width: 110,
            renderCell: (params) =>
                !params.value.type.isFake && <S.ActionsContainer>
                    <S.TestButton
                        size={"small"}
                        variant="outlined"
                        onClick={() => props.onTestConnection(params.value)}
                    >
                        Test
                    </S.TestButton>
                    <IconButton
                        onClick={() => props.onDeleteConnection(params.value)}
                        disabled={params.row.tables > 0}
                    >
                        <Delete/>
                    </IconButton>
                </S.ActionsContainer>

        }
    ];

    return (
        <div className={props.className}>
            <S.ResultsTable
                components={{
                    DetailPanelExpandIcon: ExpandMore,
                    DetailPanelCollapseIcon: ExpandLess,
                }}
                columns={columns}
                rows={
                    props.connections.map(
                        conn => ({
                            [FIELD_IDENTIFIERS.ID]: conn.id,
                            [FIELD_IDENTIFIERS.TYPE]: conn.type,
                            [FIELD_IDENTIFIERS.NAME]: conn.label,
                            [FIELD_IDENTIFIERS.ORG]: conn.organizationName,
                            [FIELD_IDENTIFIERS.TABLES]: conn.datasets.length,
                            [FIELD_IDENTIFIERS.CREATED_ON]: dayjs(conn.createdOn).format("MMM D, YYYY"),
                            [FIELD_IDENTIFIERS.ACTIONS]: conn,
                            [FIELD_IDENTIFIERS.RESULT]: conn
                        })
                    )
                }
                onCellClick={handleCellClick}

                pagination
                rowsPerPageOptions={[5, 10]}

                autoHeight={true}
                density="standard"

                getDetailPanelContent={({row}) =>
                    <S.ExpandedContent>
                        <ExpandedConnection
                            connection={row.result as RemoteDataSource}
                            onAccountBreadcrumb={props.onAccountBreadcrumb}
                            onSelect={props.onDatasetSelect}
                            onEdit={props.onDatasetEdit}
                        />
                        <S.ImportTableRow>
                            <S.AddTableButton
                                startIcon={<Add/>}
                                onClick={() => props.onClickAddTableForConnection(row.result as RemoteDataSource)}
                            >
                                {(row.result as RemoteDataSource).type === ConnectionType.HYPERARC ? "Upload Dataset" : "Import Table"}
                            </S.AddTableButton>
                        </S.ImportTableRow>
                    </S.ExpandedContent>
                }
                getDetailPanelHeight={() => 'auto'}

                disableSelectionOnClick
                disableColumnMenu
            />
        </div>
    );
};

class S {

    static readonly ResultsTable = styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-columnSeparator {
            display: none;
        }
    `;

    static readonly TestButton = styled(Button)`
        height: 32px;
        text-transform: none;
    `;

    static readonly ExpandedContent = styled.div`
        box-sizing: border-box;
        width: 100%;
        padding: 0 60px;
    `;

    static readonly ActionsContainer = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    `;

    static readonly ImportTableRow = styled.div`
        display: flex;
        justify-content: flex-end;
        padding: 6px 16px;
    `;

    static readonly AddTableButton = styled(Button)`
        &.MuiButton-root {
            color: ${Colors.inputBlue};

            &:hover {
                color: white;
                background-color: ${Colors.lightBlue};
            }
        }
    `;
}
