import * as React from "react";
import {FunctionComponent} from "react";
import {VizSuperType} from "metadata/query/VizType";
import {ArcChart} from "app/visualizations/ArcChart";
import {ArcTable} from "app/visualizations/ArcTable";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {Selectable} from "engine/actor/Selectable";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import styled from "@emotion/styled";
import {Optional} from "common/Optional";
import {NetworkGraph} from "app/visualizations/NetworkGraph";
import {Scorecard} from "app/visualizations/Scorecard";
import {ErrorBoundary} from "react-error-boundary";
import {Toggle} from "app/visualizations/Toggle";
import {LoadingMask} from "app/components/decoration/LoadingMask";

type Props = {
    queryResponse: Optional<ArcQLResponse>,
    isBusy: boolean,
    config: VisualizationConfig,
    selectable?: Selectable
}

// common props for concrete visualization components
export type VisualizationProps = {
    queryResponse: ArcQLResponse,
    config: VisualizationConfig,
    selectable?: Selectable
}

export const ArcVisualization: FunctionComponent<Props> = (props: Props) => {

    const visualization = props.queryResponse.map(
        response => {
            const visualizationProps: VisualizationProps = {
                queryResponse: response,
                config: props.config.type.definition.sanitize(props.config),
                selectable: props.selectable,
            };

            switch (props.config.type.superType) {
                case VizSuperType.FUSION:
                    return <ArcChart {...visualizationProps} />;
                case VizSuperType.D3:
                    return <NetworkGraph {...visualizationProps} />;
                case VizSuperType.TABLE:
                    return <ArcTable {...visualizationProps} />;
                case VizSuperType.SCORECARD:
                    return <Scorecard {...visualizationProps} />;
                case VizSuperType.TOGGLE:
                    return <Toggle {...visualizationProps} />;
            }
        }
    );

    return <ErrorBoundary
        FallbackComponent={ChartError}
        onReset={() => {}}
    >
        <VisualizationComponents.Container>
            {
                // if busy or visualization component can't be created, show a loading mask
                (props.isBusy || visualization.isNone) &&
                    <LoadingMask containerDimensions={['100%', '100%']} maskOpacity={0} />
            }
            {visualization.getOr(<></>)}
        </VisualizationComponents.Container>
    </ErrorBoundary>;

};

export const VisualizationComponents = {

    Container: styled.div`
        position: relative;
        height: 100%;
        width: 100%;
        background-color: inherit;
    `,

    Mask: styled.div`
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(255, 255, 255, .5);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `,

    Error: styled.div`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        div {
            padding: 4px 0;
        }
    `,

};

type ChartErrorProps = {
    error: Error
    resetErrorBoundary: () => void
}

const ChartError: FunctionComponent<ChartErrorProps> = (props: ChartErrorProps) => {
    return (
        <VisualizationComponents.Error>
            <div>Something went wrong visualizing your query, do you want to try again?</div>
            <div>
                <button onClick={props.resetErrorBoundary}>Reset</button>
            </div>
        </VisualizationComponents.Error>
    );
};