import React, {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";
import {DataGridPro} from "@mui/x-data-grid-pro/DataGridPro";

interface Props {
    className?: string;
    text: string;
    onClick: () => void;
}

/**
 * Clickable account breadcrumb in search results.
 */
export const AccountBreadCrumbInResultRow: FunctionComponent<Props> = (props: Props) => {
    return <S.Breadcrumb
        className={props.className}
        onClick={(event) => {
            // Prevent the event from bubbling up to the row in DataGridPro
            event.stopPropagation();
            props.onClick();
        }}
    >
        @{props.text}
    </S.Breadcrumb>;
};

class S {
    static Breadcrumb = styled.span`
        :hover {
            cursor: pointer;
            color: ${Colors.inputBlue};
        }
    `;
}