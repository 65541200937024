import * as React from "react";
import {FunctionComponent} from "react";
import Popover, {PopoverOrigin} from "@mui/material/Popover";
import {MeasureFieldSettingView} from "app/query/components/setting/MeasureFieldSettingView";
import {DetailFieldSettingView} from "app/query/components/setting/DetailFieldSettingView";
import {DetailDateSettingView} from "app/query/components/setting/DetailDateSettingView";
import {DetailDateField} from "metadata/query/DetailDateField";
import {DetailField} from "metadata/query/DetailField";
import {MeasureField} from "metadata/query/MeasureField";
import {ArcQLField, ColumnField} from "metadata/query/ArcQLField";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {Enum} from "common/Enum";
import {Optional} from "common/Optional";
import {CommonS} from "app/components/CommonS";
import styled from "@emotion/styled";
import {ExpressionEditorContent} from "app/query/components/ExpressionEditorContent";
import {ColumnsPicker} from "app/query/components/ColumnsPicker";
import {ArcQLBuilder} from "app/query/ArcQLBuilder";
import {Column} from "metadata/Column";
import {AnalyticsType} from "metadata/AnalyticsType";

type Props = {
    // element and where to anchor the editor popup
    el: Element
    anchorOrigin: PopoverOrigin

    arcQLBundle: ArcQLBundle,
    // field being modified
    field: Optional<ColumnField>,
    // callback when editor is closed
    onChange: (field: ArcQLField) => void
    // close the editor without any changes
    onClose: () => void
}

class FieldEditorMode extends Enum {

    static readonly FIELD = new this('field', "Select a Field");
    static readonly EXPRESSION = new this('expression', "Create Expression Field");

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}

FieldEditorMode.finalize();

/**
 * Dialog box for editing or adding a Query Field.
 */
export const FieldEditor: FunctionComponent<Props> = (props: Props) => {

    const [mode, setMode] = React.useState<FieldEditorMode>(FieldEditorMode.FIELD);
    const [pendingField, setPendingField] = React.useState<Optional<ColumnField>>(
        props.field
    );

    const onChangeMode = (_: React.MouseEvent<HTMLElement>, modeName: string) => {
        setMode(FieldEditorMode.get<FieldEditorMode>(modeName));
    };

    const buildSettingView = (field: ColumnField) => {
        const isModifying = props.field.map(f => f.as === field.as).getOr(false);
        const onBack = () => setPendingField(Optional.none);
        // if field is a star, use the as label, else use dataset metadata to grab field's label
        const fieldLabel = field.isStar ? field.as : props.arcQLBundle.dataset.getLabel(field.field);
        if (field instanceof MeasureField) {
            return <MeasureFieldSettingView
                field={field}
                fieldLabel={fieldLabel}
                dataset={props.arcQLBundle.dataset}
                modifying={isModifying}
                onChange={props.onChange}
                onBack={onBack}
            />;
        } else if (field instanceof DetailField) {
            return <DetailFieldSettingView
                field={field}
                fieldLabel={fieldLabel}
                modifying={isModifying}
                onChange={props.onChange}
                onBack={onBack}
            />;
        } else if (field instanceof DetailDateField) {
            return <DetailDateSettingView
                field={field}
                fieldLabel={fieldLabel}
                modifying={isModifying}
                onChange={props.onChange}
                onBack={onBack}
            />;
        }
        return null;
    };

    const newFieldFromColumn = (column: Column): ColumnField => {
        // skip validation, onus is on the caller
        return new ArcQLBuilder(props.arcQLBundle.dataset)
            .buildField(props.arcQLBundle.arcql, column.name, true)
            .rightOrThrow();
    };

    const buildEditor = () => {
        switch (mode) {
            case FieldEditorMode.FIELD:
                // if selected context has advanced view, display that
                return pendingField.map(
                    pending => buildSettingView(pending )
                ).getOrElse(
                    // else show field selection list
                    () => <ColumnsPicker
                        dataset={props.arcQLBundle.dataset}
                        isSaved={false}
                        isFieldSelected={(field) => props.field.map(f => f.field === field.name).getOr(false)}
                        onClick={(col) => props.onChange(newFieldFromColumn(col))}
                        hasFieldSettingView={() => true}
                        onFieldSettingView={
                            (column) =>
                                setPendingField(
                                    // if existing selected field, return that
                                    props.field.map(f => f.field === column.name ? props.field : null)
                                        // else create / build new
                                        .getOr(
                                            Optional.of(newFieldFromColumn(column))
                                        )
                                )
                        }
                        // if grouped, date aggregation is not allowed
                        sectionOrder={props.arcQLBundle.arcql.isGrouped() ? [AnalyticsType.MEASURE, AnalyticsType.DIMENSION] : [AnalyticsType.MEASURE, AnalyticsType.DIMENSION, AnalyticsType.DATE]}
                    />
                );
            case FieldEditorMode.EXPRESSION:
                return <ExpressionEditorContent
                    arcQLBundle={props.arcQLBundle}
                    onChange={props.onChange}
                    onClose={props.onClose}
                />;
        }
    };

    return (
        <Popover
            open={true}
            anchorEl={props.el}
            onClose={props.onClose}
            anchorOrigin={props.anchorOrigin}
        >
            <S.Container>
                <CommonS.Tabs
                    value={mode.name}
                    onChange={onChangeMode}
                    variant="fullWidth"
                >
                    {
                        FieldEditorMode.enums<FieldEditorMode>().map(
                            m =>
                                <CommonS.Tab
                                    key={m.name}
                                    value={m.name}
                                    label={m.label}
                                    disabled={m === FieldEditorMode.EXPRESSION && props.field.isPresent}
                                />
                        )
                    }
                </CommonS.Tabs>
                <S.Content>
                    {buildEditor()}
                </S.Content>

            </S.Container>

        </Popover>
    );

};

class S {
    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static readonly Content = styled.div`
        width: 450px;
        max-height: 480px;
        padding: 4px 12px 8px 12px;
    `;
}