import {Tuple} from "common/Tuple";
import {Enum} from "common/Enum";

/**
 * The type of individual change that was made to an ArcMetadata object
 */
export class ChangeType extends Enum {
    static readonly ADD = new this('add');
    static readonly DELETE = new this('delete');
    static readonly MODIFY = new this('modify');
    // Replace means the existing asset was entirely replaced with a new object, regardless of what the previous object was
    static readonly REPLACE = new this('replace');
}
ChangeType.finalize();

export interface ArcMetadataChange<T> {

    /** Apply the change and return a tuple of the new metadata and inverse change to undo. */
    apply(metadata: T): Tuple<T, ArcMetadataChange<T>>;

    /** Describe the change to the user in the context of the given metadata. */
    describe(metadata: T): string;

    /** The kind of change that was made */
    changeType: ChangeType;
}