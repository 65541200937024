import {Enum} from "common/Enum";

/**
 * Enumerates the types of user sessions (i.e. user interactions in our application).
 */
export class SessionType extends Enum {

    static DASHBOARD = new this('dashboard');

    constructor(
        name: string
    ) {
        super(name);
    }
}

SessionType.finalize();

