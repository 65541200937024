import {GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse} from "@auth0/auth0-spa-js";
import {RejectedResponse} from "services/RejectedResponse";
import {Optional} from "common/Optional";

/**
 * Service to make authenticated requests to arc-api
 */
export class RestService {

    private accessTokenGetter: (options: GetTokenSilentlyOptions) => Promise<GetTokenSilentlyVerboseResponse | string>;
    private isAuthenticated: boolean;
    private embedId: Optional<string>;

    constructor(private readonly apiHost: string) {
    }

    initialize(
        accessTokenGetter: (options: GetTokenSilentlyOptions) => Promise<GetTokenSilentlyVerboseResponse | string>,
        isAuthenticated: boolean,
        embedId: Optional<string>
    ) {
        this.accessTokenGetter = accessTokenGetter;
        this.isAuthenticated = isAuthenticated;
        this.embedId = embedId;
    }

    /**
     * Fetch a relative path to the api server with an access token if it's available
     */
    fetch(path: string, options?: RequestInit, isJSON: boolean = true): Promise<Response> {
        if (isJSON) {
            if (options == null) {
                options = {};
            }

            options.headers = {
                ...{
                    'Content-Type': 'application/json'
                },
                ...options.headers
            };
        }
        return this.fetchAbsolute(`${this.apiHost}${path}`, options)
            // translate rejected promises into responses
            .catch((reason: any) => new RejectedResponse(path, reason.toString()));
    }

    /**
     * Fetch an absolute path with an access token if it's available
     */
    fetchAbsolute(path: string, options?: RequestInit): Promise<Response> {
        if (options == null) {
            options = {};
        }

        if (options.headers == null) {
            options.headers = {};
        }

        this.embedId.forEach(id => {
            // @ts-ignore
            options.headers['X-Embedded-Token'] = id;
        });

        if (this.isAuthenticated) {
            return this.accessTokenGetter({}).then(token => {
                // @ts-ignore
                options.headers['Authorization'] = `Bearer ${token}`;

                return fetch(`${path}`, options);
            });
        } else {
            return fetch(`${path}`, options);
        }
    }


    post(path: string, json: any, signal?: AbortSignal): Promise<Response> {
        return this.fetch(path, {
            'method': 'POST',
            'signal': signal,
            'body': JSON.stringify(json)
        });
    }

    postForm(path: string, formData: FormData, signal?: AbortSignal): Promise<any> {
        return this.fetch(
            path,
            {
                method: 'POST',
                body: formData,
                signal: signal
            },
            false
        );
    }

    patch(path: string, json: any, signal?: AbortSignal): Promise<Response> {
        return this.fetch(path, {
            'method': 'PATCH',
            'signal': signal,
            'body': JSON.stringify(json)
        });
    }

    get(
        path: string,
        signal?: AbortSignal,
        // optional url params to serialize into the path
        params?: { [key: string]: string } | string[][],
        headers?: { [key: string]: string }
    ): Promise<Response> {
        if (params != null) {
            path = path + '?' + new URLSearchParams(params).toString();
        }

        return this.fetch(path, {
            'method': 'GET',
            'signal': signal,
            'headers': headers
        });
    }

    delete(path: string, signal?: AbortSignal): Promise<Response> {
        return this.fetch(path, {
            'method': 'DELETE',
            'signal': signal
        });
    }

}

