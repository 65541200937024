import {OrderedMap} from "common/OrderedMap";
import {VizType} from "metadata/query/VizType";
import {Optional} from "common/Optional";
import {JsonUtils} from "metadata/JsonUtils";
import {VizTheme} from "app/visualizations/VizTheme";


export class ArcQLVisualizations {

    static readonly DEFAULT_KEY = 'default';

    private readonly _configs: OrderedMap<string, VisualizationConfig>;

    constructor(configs: VisualizationConfig[]) {
        this._configs = OrderedMap.fromKeyed(configs, c => c.name);
    }

    static fromJSON(json: any): ArcQLVisualizations {
        return new ArcQLVisualizations(json.map(VisualizationConfig.fromJSON));
    }

    static default(): ArcQLVisualizations {
        return new ArcQLVisualizations([
            new VisualizationConfig(ArcQLVisualizations.DEFAULT_KEY, 'Default', VizType.BAR, new Map())
        ]);
    }

    /**
     * Get the default visualization if the key exists or the first.
     */
    get default(): VisualizationConfig {
        return this._configs.get(ArcQLVisualizations.DEFAULT_KEY).getOr(this._configs.first);
    }

    get(name: string): VisualizationConfig {
        return this._configs.get(name).get();
    }

    get configs(): VisualizationConfig[] {
        return this._configs.values;
    }

    with(config: VisualizationConfig): ArcQLVisualizations {
        return new ArcQLVisualizations(
            this._configs.values.map(c => c.name === config.name ? config : c)
        );
    }

    toJSON(): Object {
        return this._configs.toJSON();
    }

}

export class VisualizationConfig {

    static fromJSON(json: any): VisualizationConfig {
        return new VisualizationConfig(
            json['name'],
            json['label'],
            VizType.get(json['type']),
            JsonUtils.toMap(json['config'], v => v)
        );
    }

    constructor(
        public readonly name: string,
        public readonly label: string,
        // visualization type, e.g. chart, table, etc.
        public readonly type: VizType,
        // visualization specific configuration
        public readonly config: Map<string, any>
    ) { }

    get(configName: string): Optional<any> {
        return Optional.of(this.config.get(configName));
    }

    /**
     * Return true if the config exists and is true.
     */
    boolean(key: string): boolean {
        return this.get(key).getOr(false);
    }

    /**
     * Helper to retrieve a string config that should evaluate empty string to none.
     */
    emptyableString(key: string): Optional<string> {
        return this.get(key).flatMap(v => v == null || v.length === 0 ? null : v);
    }

    withType(vizType: VizType): VisualizationConfig {
        return new VisualizationConfig(this.name, this.label, vizType, this.config);
    }

    withConfigChange(key: string, value: any): VisualizationConfig {
        const newConfig = new Map(this.config);
        newConfig.set(key, value);

        return new VisualizationConfig(this.name, this.label, this.type, newConfig);
    }

    withConfig(config: Map<string, any>): VisualizationConfig {
        return new VisualizationConfig(this.name, this.label, this.type, config);
    }

    /**
     * Get the theme.
     */
    get theme(): VizTheme {
        return this.get('theme')
            .map(theme => VizTheme.get<VizTheme>(theme))
            .getOr(VizTheme.HYPERARC);
    }

    toJSON(): Object {
        return {
            'name': this.name,
            'label': this.label,
            'type': this.type,
            'config': JsonUtils.fromMap(this.config)
        };
    }

}