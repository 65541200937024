import * as React from "react";
import {ReactElement, ReactNode} from "react";
import HomeIcon from "@mui/icons-material/Home";
import SendIcon from '@mui/icons-material/Send';
import VolcanoIcon from '@mui/icons-material/Volcano';
import {DashboardBuilder} from "app/dashboard/DashboardBuilder";
import {Home} from "app/home/Home";
import {PathError} from "app/PathError";
import {QueryBuilder} from "app/query/QueryBuilder";
import {Tab, TabChange} from "app/Tab";
import {TabPath} from "app/TabPath";
import {Enum} from "common/Enum";
import {AssetType} from "metadata/AssetType";
import {NewLanding} from "app/home/NewLanding";
import {Optional} from "common/Optional";
import {ServiceProvider} from "services/ServiceProvider";
import {LinkService} from "services/LinkService";
import {AssetIcon, IconStyling} from "app/components/icons/asset/AssetIcon";
import {FilterSetBuilder} from "app/filterset/FilterSetBuilder";
import {SessionService} from "services/SessionService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {SemanticAbiBuilder} from "app/semanticabi/SemanticAbiBuilder";
import {TrendsService} from "services/TrendsService";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import {Settings} from "app/components/settings/Settings";
import {DatasetV2Builder} from "app/datasetv2/DatasetV2Builder";

export interface TabProps {
    path: TabPath
    context: Map<string, any>
    isSelected: boolean
    onTabChange: TabChange
    onClose: () => void
}

export type TabCreator = (props: TabProps) => ReactElement;

export class TabType extends Enum {

    static readonly HOME = new this(
        'home',
        false,
        false,
        () => <HomeIcon/>,
        () => null,
        () => Promise.resolve(Optional.none()),
        () => false,
        Home
    );

    static readonly NEW = new this(
        'new',
        true,
        false,
        () => '✨',
        () => 'New',
        () => Promise.resolve(Optional.none()),
        () => false,
        NewLanding
    );

    static readonly ARCQL = new this(
        AssetType.ARCQL.name,
        true,
        true,
        () => <AssetIcon assetType={AssetType.ARCQL} iconStyling={IconStyling.OUTLINED_COLORED}/>,
        (path: TabPath) => path.assetName === null ? 'ArcQL' : path.assetName,
        () => Promise.resolve(Optional.none()),
        () => false,
        QueryBuilder
    );

    static readonly DASHBOARD = new this(
        AssetType.DASHBOARD.name,
        true,
        true,
        () => <AssetIcon assetType={AssetType.DASHBOARD} iconStyling={IconStyling.OUTLINED_COLORED}/>,
        (path: TabPath) => path.assetName === null ? 'Dashboards' : path.assetName,
        () => Promise.resolve(Optional.none()),
        () => false,
        DashboardBuilder
    );

    static readonly DATASET = new this(
        AssetType.DATASET.name,
        true,
        true,
        () => <AssetIcon assetType={AssetType.DATASET} iconStyling={IconStyling.OUTLINED_COLORED}/>,
        (path: TabPath) => path.assetName === null ? 'Dataset' : path.assetName,
        (path: TabPath) => {
            // create a new query on the given dataset
            if (path.parts.length === 5 && path.parts[4] === 'query') {
                return ServiceProvider.get(LinkService).query()
                    .then(url => Tab.from(
                        TabPath.fromRaw(url),
                        new Map([['datasetFqn', path.assetFqn]])
                    ))
                    .then(Optional.some);
            }

            return Promise.resolve(Optional.of(Tab.from(TabType.HOME.singletonPath())));
        },
        (path: TabPath) => path.parts.length === 5 && path.parts[4] === 'query',
        () => <>Dataset</>
    );

    static readonly DATASET_V2 = new this(
        AssetType.DATASET_V2.name,
        true,
        true,
        () => <AssetIcon assetType={AssetType.DATASET} iconStyling={IconStyling.OUTLINED_COLORED}/>,
        (path: TabPath) => path.assetName === null ? 'Dataset' : path.assetName,
        (path: TabPath) => {
            // create a new query on the given dataset
            if (path.parts.length === 5 && path.parts[4] === 'query') {
                return ServiceProvider.get(LinkService).query()
                    .then(url => Tab.from(
                        TabPath.fromRaw(url),
                        new Map([['datasetFqn', path.assetFqn]])
                    ))
                    .then(Optional.some);
            }

            return Promise.resolve(Optional.none());
        },
        (path: TabPath) => path.parts.length === 5 && path.parts[4] === 'query',
        DatasetV2Builder
    );

    static readonly FILTERSET = new this(
        AssetType.FILTER_SET.name,
        true,
        true,
        () => <AssetIcon width={10} height={11} assetType={AssetType.FILTER_SET}
                         iconStyling={IconStyling.OUTLINED_COLORED}/>,
        (path: TabPath) => path.assetName === null ? 'Filter Sets' : path.assetName,
        () => Promise.resolve(Optional.none()),
        () => false,
        FilterSetBuilder
    );

    /**
     * Sessions tab path meant to redirect to app path with associated context + state loaded.
     */
    static readonly SESSIONS = new this(
        'sessions',
        false,
        false,
        () => <SendIcon/>,
        () => null,
        async (path: TabPath) => {
            const redirectHome = Optional.of(Tab.from(TabType.HOME.singletonPath()));

            // expected to be of form /sessions/guid. If not redirect home.
            if (path.parts.length !== 2) {
                return redirectHome;
            }

            return ServiceProvider.get(SessionService).get(path.parts[1])
                .then(resp => resp.fold(
                    session => {
                        return Optional.of(Tab.from(
                            TabPath.fromRaw(session.appPath),
                            new Map([['state', session.state]])
                        ));
                    },
                    () => {
                        ServiceProvider.get(NotificationsService).publish(
                            'TabType.sessions',
                            NotificationSeverity.ERROR,
                            'Failed to load session.',
                        );
                        return redirectHome;
                    }
                ));
        },
        () => false,
        () => <></>
    );

    /**
     * Redirect for specific Trend IDs.
     */
    static readonly TRENDS = new this(
        'trends',
        false,
        false,
        () => <SendIcon/>,
        () => null,
        async (path: TabPath) => {

            const redirectHome = Optional.of(Tab.from(TabType.HOME.singletonPath()));

            // expected to be of form /trends/guid. If not redirect home.
            if (path.parts.length !== 2) {
                return redirectHome;
            }

            // get trend by ID with its loaded dashboard session, redirect to that session
            return ServiceProvider.get(TrendsService).fetchTrendById(path.parts[1])
                .then(resp => resp.fold(
                    trend => {
                        return Optional.of(Tab.from(
                            TabPath.fromRaw(trend.session.appPath),
                            new Map<string, any>([
                                ['trend', trend],
                                ['state', trend.session.state]
                            ])
                        ));
                    },
                    () => {
                        ServiceProvider.get(NotificationsService).publish(
                            'TabType.trends',
                            NotificationSeverity.ERROR,
                            'Failed to load trend.',
                        );
                        return redirectHome;
                    }
                ));
        },
        () => false,
        () => <></>
    );

    static readonly SEMANTIC_ABI = new this(
        AssetType.ABI.name,
        true,
        false,
        () => <VolcanoIcon/>,
        () => null,
        () => Promise.resolve(Optional.none()),
        () => false,
        SemanticAbiBuilder
    );

    static readonly SETTINGS = new this(
        'settings',
        true,
        false,
        () => <SettingsOutlined/>,
        () => null,
        () => Promise.resolve(Optional.none()),
        () => false,
        Settings
    );

    constructor(
        name: string,
        // if it's an asset or a "singleton" path, assets are named and have a path of
        // `/<org>/<folder>/<TabType.name>/<name>` while "singleton" are just `/<TabType.name>`
        public readonly closeable: boolean,
        public readonly isAsset: boolean,
        public readonly icon: () => ReactNode,
        // generate the initial label before the tab is opened and content loaded
        public readonly label: (path: TabPath) => string,
        // when routed to the current tab type, give it an option to redirect to another path with injected context,
        // e.g. routing to a /foo/bar/dataset/1234/query should actually route to arcql with a new query on dataset 1234
        // return a promise for none for no redirect, otherwise the tab to redirect to
        public readonly redirect: (path: TabPath) => Promise<Optional<Tab>>,
        // In the event that we are trying to open a new tab with the same tabId as another tab, we may still want to
        // force a redirect to a different tab based on the remaining path.
        public readonly shouldForceRedirect: (path: TabPath) => boolean,
        // given the path, return the appropriate element
        public readonly ComponentType: React.ComponentType<TabProps>
    ) {
        super(name);
    }

    singletonPath(isEmbed: boolean = false): TabPath {
        if (this.isAsset) {
            throw new PathError('Not a singleton.', this.name);
        }

        return new TabPath([this.name], this, isEmbed);
    }

}

TabType.finalize();