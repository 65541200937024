import * as React from "react";
import {FunctionComponent} from "react";
import {ConnectionStepperHandler} from "app/components/settings/connections/ConnectionStepperHandler";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {ServiceProvider} from "services/ServiceProvider";
import {OrgService} from "services/OrgService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Optional} from "common/Optional";
import {Alert} from "@mui/material";
import {AlertTitle} from "@mui/lab";
import styled from "@emotion/styled";

interface Props {
    handler: ConnectionStepperHandler
    connection: RemoteDataSource
}


/**
 * Step for testing connection after successful save.
 */
export const TestConnectionStep: FunctionComponent<Props> = (props: Props) => {

    const [testStatus, setTestStatus] = React.useState<Optional<boolean>>(Optional.none);

    const onTestConnection = () => {
        ServiceProvider.get(OrgService)
            .testConnection(
                props.connection.organizationName, props.connection.id)
            .then(
                response => response.match(
                    testResponse => {
                        setTestStatus(Optional.some(testResponse.success));
                    },
                    error => {
                        ServiceProvider.get(NotificationsService).publish(
                            "TestConnectionStep.testConnection",
                            NotificationSeverity.ERROR,
                            `Failed to test connection: ${error.prettyPrint()}`
                        );
                    }
                )
            );
    };

    return (
        <div>
            {
                props.handler.testConnectionStep(
                    {
                        connection: props.connection,
                        onTestConnection
                    }
                )
            }
            {
                testStatus.map(
                    success => (
                        success ?
                            <S.Alert severity="success">
                                <AlertTitle>Connection Successful</AlertTitle>
                                HyperArc successfully connected to your database. You can start importing datasets!
                            </S.Alert> :
                            <S.Alert severity="error">
                                <AlertTitle>Connection Failed</AlertTitle>
                                HyperArc was unable to connect to your database. Please check your connection details
                                and credentials.
                            </S.Alert>
                    )
                ).getOr(<></>)
            }
        </div>
    );
};

class S {
    static readonly Alert = styled(Alert)`
        margin-top: 16px;
    `;
}