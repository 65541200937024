import {Enum} from "common/Enum";

export class IndexType extends Enum {

    static INVERTED = new this('inverted', false);
    static TEXT = new this('text', true);
    static NATIVE = new this('native', true);
    static TIMESTAMP = new this('timestamp', false);
    static RANGE = new this('range', false);

    constructor(
        name: string,
        public readonly isStringIndex: boolean
    ) {
        super(name)
    }
}

IndexType.finalize();

