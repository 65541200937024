import { Enum } from "common/Enum";

/**
 * Enumeration of hypergraph orchestrator models.
 *
 * @author zuyezheng
 */
export default class OrchestratorModel extends Enum {

    static readonly ASCEND = new this('ascend');
    static readonly BLOSSOM = new this('blossom');
    static readonly CASCADE = new this('cascade');
    static readonly CATALYST = new this('catalyst');
    static readonly DREAMER = new this('dreamer');
    static readonly INNOVATOR = new this('innovator');

    private constructor(name: string) {
        super(name);
    }

}

OrchestratorModel.finalize();
