import {Either} from "common/Either";
import {SessionService} from "services/SessionService";
import {ErrorResponse} from "services/ApiResponse";
import {JsonObject} from "common/CommonTypes";
import {SessionType} from "metadata/session/SessionType";
import {ArcSession} from "metadata/session/ArcSession";

/**
 * This is a mocked implementation of the SessionService.
 */
export class MockedSessionService implements SessionService {
    create(
        path: string,
        type: SessionType,
        referenceId: string,
        state: JsonObject,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcSession>> {
        throw new Error("Method not implemented.");
    }

    get(sessionId: string, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcSession>> {
        throw new Error("Method not implemented.");
    }
}
