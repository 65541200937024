import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {Tuple} from "common/Tuple";
import {GridColDef, GridRowsProp} from "@mui/x-data-grid";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {jaroWinkler} from "jaro-winkler-typescript";
import {FilterClause, FilterValue} from "metadata/query/filterclause/FilterClause";
import {Column} from "metadata/Column";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import DistinctValuesArcQLRequest from "services/DistinctValuesArcQLRequest";
import {Optional} from "common/Optional";
import {QueryResult} from "metadata/query/QueryResult";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";
import {DimensionFilterMode} from "app/query/filters/DimensionFilterMode";
import {SingleFieldFilterClause} from "metadata/query/filterclause/SingleFieldFilterClause";

export type FilterState = {
    values: Set<FilterValue>,
    operator: FilterOperator,
};

/**
 * Static helpers for the dimension filter component.
 *
 * @author zuyezheng
 */
export class DimensionFilterHelper {

    /**
     * Build the initial dimension filter state for each mode so we can retain state as modes are switched.
     */
    static filterModeState(
        filter: SingleFieldFilterClause,
        supportedOperators: FilterOperator[]
    ): Map<DimensionFilterMode, FilterState> {
        // make sure the given operator is supported, otherwise use the first
        let operator = supportedOperators[0];
        if (supportedOperators.includes(filter.operator)) {
            operator = filter.operator;
        }

        return new Map(DimensionFilterMode.enums<DimensionFilterMode>().map(
            mode => [
                mode,
                {
                    values: new Set(mode.clauseType === filter.type ? filter.values : []),
                    operator: operator
                }
            ]
        ));
    }

    /**
     * Build the appropriate request for auto-complete of values.
     */
    static distinctValuesRequest(
        arcqlBundle: ArcQLBundle,
        filter: LiteralsFilterClause,
        ordinal: number,
        startsWith: string,
        scoped: boolean,
        limit: number = 100
    ): DistinctValuesArcQLRequest {
        const clauses: FilterClause[] = [];
        const query = arcqlBundle.arcql;
        const column: Column = arcqlBundle.dataset.getPossible(filter.column).get();

        if (scoped) {
            // inherit all of the filters (except for the current) of the existing visualization query so we don't show
            // values outside of what's actually possible
            clauses.push(...query.filters.clauses.filter((_: FilterClause, o: number) => ordinal !== o));
        }
        return new DistinctValuesArcQLRequest(
            arcqlBundle.dataset.fqn.toString(),
            column.name,
            startsWith,
            Optional.of(ArcQLFilters.ands(clauses, false)),
            limit
        );
    }

    /**
     * Sort result based on string distance of the search to provide a better ordering.
     */
    static sortResult(startsWith: string, results: QueryResult): QueryResult {
        return results.sort((a: any[], b: any[]) => {
            const aDistance = jaroWinkler(startsWith, String(a[0]).toLowerCase(), {caseSensitive: false});
            const bDistance = jaroWinkler(startsWith, String(b[0]).toLowerCase(), {caseSensitive: false});

            if (aDistance < bDistance) {
                return 1;
            } else if (aDistance > bDistance) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    /**
     * The auto-complete results need to be post processed to account for the current or starting selections which
     * might not be in the queried result set.
     */
    static postProcessResults(
        startsWith: string, response: ArcQLResponse, selectedValues: Set<string | number>
    ): ArcQLResponse {
        // autocompleting which is showing partial results anyways so just provide a more relevant sort
        if (!response.arcql.filters.last.isAll) {
            return response.withResults(
                DimensionFilterHelper.sortResult(startsWith, response.result)
            );
        }

        // with no autocomplete search, we need to merge existing selections that might be outside of the range of limit
        // so that they can be unselected, also move all selected to the top of the list
        const processed = response.result
            // filter out anything selected to move to the top
            .filter(row => !selectedValues.has(row[0]))
            // add all selections to the top
            .extend(
                Array.from(selectedValues).map(v => [v]),
                true
            );

        return response.withResults(processed);
    }

    /**
     * Turn autocomplete results into the definitions needed to render the grid.
     */
    static autocompleteResultsToGrid(response: ArcQLResponse): Tuple<GridColDef[], GridRowsProp> {
        const columns: GridColDef[] = [
            {field: 'dimension', headerName: response.arcql.groupings.first.field, flex: 1}
        ];

        return Tuple.of(
            columns,
            response.result.rows.map((row: any[]) => {
                return {
                    // id used for datagrid can't be null
                    // note: presumption is that users wouldn't have both true null values and 'null' strings....
                    // else, we wouldn't properly handle that and the last "null" would win
                    id: row[0] == null ? 'null' : row[0],
                    // display null as a string
                    dimension: row[0] == null ? 'null' : row[0],
                    result: row[0]
                };
            })
        );
    }

    /**
     * Given the last auto-complete results, toggle the selection of a given value, returning the new selections.
     */
    static toggleSelection(
        value: string | number,
        selectedValues: Set<string | number>
    ): Set<string | number> {
        const newSelections = new Set(selectedValues);
        if (newSelections.has(value)) {
            newSelections.delete(value);
        } else {
            newSelections.add(value);
        }

        return newSelections;
    }

    /**
     * Toggle selection of all or none of the last auto-complete results.
     */
    static toggleAll(response: ArcQLResponse, selectedValues: Set<string | number>): Set<string | number> {
        const newSelections = new Set(selectedValues);
        const isAllSelected = response.result.rows.every(row => newSelections.has(row[0]));

        if (isAllSelected) {
            response.result.rows.forEach(row => newSelections.delete(row[0]));
        } else {
            response.result.rows.forEach(row => newSelections.add(row[0]));
        }

        return newSelections;
    }

}