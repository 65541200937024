import {Indexable} from "common/Indexable";
import {AssetType} from "metadata/AssetType";
import {PageParams} from "metadata/PageParams";
import {FolderResult} from "metadata/project/FolderResult";

export type SearchSort = 'trending' | 'recent_all' | 'recent';
export type SearchSince = 'today' | 'week' | 'month' | 'year';

export class AssetSearchParams implements Indexable, PageParams<AssetSearchParams> {

    /**
     * Default trending query.
     */
    static trending(types: AssetType[] = null, size: number = 10): AssetSearchParams {
        return new AssetSearchParams(size, null, types, 'trending', null, null, 'week');
    }

    /**
     * Default recent all query which sorts by MRU, then updated on.
     */
    static recentAll(types: AssetType[] = null, size: number = 10): AssetSearchParams {
        return new AssetSearchParams(size, null, types, 'recent_all', null, null, 'week');
    }

    /**
     * Default most recently used query.
     */
    static recent(types: AssetType[] = null, size: number = 10): AssetSearchParams {
        return new AssetSearchParams(size, null, types, 'recent', null, null, 'week');
    }

    /**
     * Default browse query.
     */
    static browse(query: string = null, size: number = 20): AssetSearchParams {
        return new AssetSearchParams(size, query, AssetType.searchable());
    }


    /**
     * Search contextualized to specific project.
     */
    static project(project: FolderResult, query: string, size: number = 20): AssetSearchParams {
        return new AssetSearchParams(size, query, AssetType.searchable(), null, null, null, null, project.getFolderUrl());
    }

    /**
     * Search contextualized to specific account
     */
    static account(account: string, query: string, size: number = 20): AssetSearchParams {
        return new AssetSearchParams(size, query, AssetType.searchable(), null, account);
    }

    constructor(
        // page size is required to support pagination
        public readonly size: number,
        public readonly query?: string,
        public readonly type?: AssetType[],
        public readonly sort?: SearchSort,
        public readonly owner?: string,
        public readonly user?: string,
        public readonly since?: SearchSince,
        public readonly folder?: string,
        // pages are 0 indexed
        public readonly page?: number
    ) {
    }

    isEmpty() {
        return this.query == null || this.query.length === 0;
    }

    with({
        size,
        page,
        query
    }: {
        size?: number
        page?: number
        query?: string
    }) {
        return new AssetSearchParams(
            size === undefined ? this.size : size,
            query === undefined ? this.query : query,
            this.type,
            this.sort,
            this.owner,
            this.user,
            this.since,
            this.folder,
            page === undefined ? this.page : page
        );
    }

}