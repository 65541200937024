import {BaseWidgetConfigPart} from "metadata/dashboard/widgets/config/BaseWidgetConfigPart";
import {JsonObject} from "common/CommonTypes";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";

/**
 * Properties for configuring Markdown Widget.
 */
export class MarkdownConfigPart extends BaseWidgetConfigPart<MarkdownConfigPart> {

    constructor(
        public readonly content: string,
        defaults: MarkdownConfigPart
    ) {
        super(defaults);
    }

    protected get properties(): (keyof MarkdownConfigPart & string)[] {
        return ['content'];
    }

    get type(): WidgetConfigPartType {
        return WidgetConfigPartType.MARKDOWN;
    }

    createInstance(props: Record<keyof MarkdownConfigPart, any>, defaults: MarkdownConfigPart): MarkdownConfigPart {
        return new MarkdownConfigPart(
            props.content,
            defaults
        );
    }


    static fromJSON(configJson: JsonObject, defaults: MarkdownConfigPart = MarkdownConfigPart.defaults()): MarkdownConfigPart {
        return BaseWidgetConfigPart.fromJSONHelper(configJson, defaults);
    }

    static defaults(): MarkdownConfigPart {
        return new MarkdownConfigPart(
            'Hello from **HyperArc**!',
            null
        );
    }
}