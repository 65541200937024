import React from "react";
import {Enum} from "common/Enum";
import BrowseViewProps from "app/home/browse/BrowseViewProps";
import {BrowseAllView} from "app/home/browse/BrowseAllView";
import {BrowseAccountView} from "app/home/browse/BrowseAccountView";
import {BrowseWithQueryView} from "app/home/browse/BrowseWithQueryView";
import {BrowseProjectView} from "app/home/browse/BrowseProjectView";

export default class BrowseView extends Enum {

    static BROWSE_ALL = new this(
        'browseAll',
        BrowseAllView
    );

    static BROWSE_WITH_QUERY = new this(
        'browseWithQuery',
        BrowseWithQueryView
    );

    static ACCOUNT_VIEW = new this(
        'accountView',
        BrowseAccountView
    );

    static PROJECT_VIEW = new this(
        'projectView',
        BrowseProjectView
    );

    private constructor(
        name: string,
        public readonly render: (props: BrowseViewProps) => React.JSX.Element
    ) {
        super(name);
    }

}
BrowseView.finalize();