import {Enum} from "common/Enum";
import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";
import {ToolbarAction} from "app/components/toolbar/ToolbarAction";
import {ToolbarActionsSingle} from "app/components/toolbar/ToolbarActions";
import {HyperGraphAction} from "app/components/toolbar/HyperGraphAction";

/**
 * Auxiliary actions that can be performed in DatasetV2Builder.
 */
export class DatasetV2BuilderActions extends Enum {

    static readonly EXPLORE = new this('explore', 'Explore');
    static readonly UPDATE_DATA = new this('updateData', 'Update Data');
    // action to automate generating labels + description for dataset columns
    static readonly DESCRIBE = new this('describe', 'Prep');

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

    /**
     * Actions that should be visible in the toolbar.
     */
    public static visibleToolbarActions(): ToolbarAction[] {
        return [
            HyperGraphAction(
                this.DESCRIBE.name,
                this.DESCRIBE.label,
                'Prep the dataset with column\n' +
                'labels and descriptions to make\n' +
                'the rest of HyperArc even smarter.'
            )
        ];
    }

    /**
     * Actions that should be visible in the more actions menu.
     */
    public static visibleMoreActions(state: DatasetV2BuilderState): ToolbarAction[] {
        const actions = [];
        if (state.isExisting) {
            actions.push(DatasetV2BuilderActions.EXPLORE);
        }

        // if personal dataset, allow option to re-upload and update their data
        if (state.datasetType === DatasetType.PERSONAL) {
            actions.push(DatasetV2BuilderActions.UPDATE_DATA);
        }

        return actions.map(a => new ToolbarAction(a.name, a.label));
    }

}

DatasetV2BuilderActions.finalize();