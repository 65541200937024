import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraph} from 'metadata/hypergraph/HyperGraph';
import {HyperGraphNodeType} from 'metadata/hypergraph/nodes/HyperGraphNodeType';
import {HyperGraphRequestSerialized} from 'metadata/hypergraph/HyperGraphTypes';

/**
 * @author zuyezheng
 */
export class NextBestMeasures implements HyperGraphRequest {

    constructor(
        public readonly parent: string,
        // up to number of measures to suggest
        public readonly n: number
    ) {}

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.NEXT_BEST_MEASURES;
    }

    get operation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.DISCOVERY;
    }

    label(graph: HyperGraph): string {
        return 'Finding additional measures to help with analysis.';
    }

    get ommers(): string[] {
        return [this.parent];
    }

    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            parent: this.parent,
            n: this.n
        };
    }

    static fromJSON(json: HyperGraphRequestSerialized): NextBestMeasures {
        return new NextBestMeasures(json.parent, json.n);
    }

}