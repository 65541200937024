import React, {FunctionComponent, ReactNode, useState} from "react";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
    className?: string
    sectionHeaderClassName?: string

    title: string
    children?: ReactNode

}

export const CollapsibleSection: FunctionComponent<Props> = (props: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    return (
        <S.Section className={props?.className}>
            <S.SectionHeader className={props?.sectionHeaderClassName} onClick={toggleCollapse}>
                <S.SectionTitle>{props.title}</S.SectionTitle>
                {isCollapsed ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
            </S.SectionHeader>
            <Collapse in={!isCollapsed}>
                <S.Content>
                    {props?.children}
                </S.Content>
            </Collapse>
        </S.Section>
    );
};


class S {
    static Section = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static SectionHeader = styled.div`
        background-color: ${Colors.headerGrey};
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.xSmall};
        line-height: ${FontSizes.xSmall};
        font-weight: 500;
        text-transform: uppercase;
        height: 35px;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
    `;

    static SectionTitle = styled.div`
        padding: 0 10px;
    `;

    static Content = styled.div`
        padding: 6px;   
    `;
}