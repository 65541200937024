import {Enum} from "common/Enum";
import {DatasetSource} from "metadata/dataset/DatasetStatusMetadata";

/**
 * Enum for dataset types.
 */
export class DatasetType extends Enum implements DatasetSource {

    static readonly ARC = new this('arc', 'Arc');
    static readonly SNOWFLAKE = new this('snowflake', 'Snowflake');
    static readonly BIG_QUERY = new this('bigquery', 'BigQuery');
    static readonly PERSONAL = new this('personal', 'Personal');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
DatasetType.finalize();