import * as React from "react"
import {SVGProps} from "react"

const FilterSetIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M5.825 10.825s-4.7-6-4.783-6.108a.831.831 0 0 1-.209-.55c0-.434.334-.834.842-.834h11.65c.467 0 .842.375.842.834 0 0 .025.266-.192.533a3275 3275 0 0 0-4.792 6.117v5.016a.843.843 0 0 1-.841.834H6.667a.838.838 0 0 1-.842-.834v-5.008Zm5.842 1.675h6.666v-1.25h-6.666v1.25Zm0 2.083h6.666v-1.25h-6.666v1.25Zm0 2.084h6.666v-1.25h-6.666v1.25Z"
            clipRule="evenodd"
        />
    </svg>
)
export default FilterSetIcon
