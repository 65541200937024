import * as React from "react";
import {ReactNode} from "react";
import {ToolbarAction} from "app/components/toolbar/ToolbarAction";
import ButtonGroup from "@mui/material/ButtonGroup";
import {ActionMenu} from "app/components/toolbar/ActionMenu";
import {ActionMore} from "app/components/toolbar/ActionMore";

/**
 * Collection of toolbar action sections.
 *
 * @author zuyezheng
 */
export class ToolbarActions {

    constructor(
        public readonly sections: ToolbarActionsSection[]
    ) {  }
}

/**
 * A set of actions that make sense together.
 */
export interface ToolbarActionsSection {

    get actions(): ToolbarAction[];

    /**
     * Render the component given the set of toggled action ids and an on action callback.
     */
    comp(toggled: Set<string>, onAction: (action: ToolbarAction) => void): ReactNode;

}

/**
 * 1 is the loneliest number.
 */
export class ToolbarActionsSingle implements ToolbarActionsSection {

    constructor(
        public readonly action: ToolbarAction
    ) { }

    get actions(): ToolbarAction[] {
        return [this.action];
    }

    comp(toggled: Set<string>, onAction: (action: ToolbarAction) => void): ReactNode {
        return this.action.comp(onAction, toggled.has(this.action.id));
    }

}

/**
 * A top or primary action with a menu of associated actions.
 */
export class ToolbarActionsMenu implements ToolbarActionsSection {

    constructor(
        // top action to show without opening the menu
        public readonly topAction: ToolbarAction,
        // actions to show in the menu with optional groupings
        public readonly menuActions: ToolbarAction[][],
        // if this should be styled as a primary action
        public readonly isPrimary: boolean = false
    ) { }

    get actions(): ToolbarAction[] {
        return this.menuActions.flatMap(group => group.flatMap(actions => actions));
    }

    comp(toggled: Set<string>, onAction: (action: ToolbarAction) => void): ReactNode {
        return <ActionMenu menu={this} onAction={onAction} />;
    }

}

/**
 * A more menu of actions with no top action.
 */
export class ToolbarActionsMore implements ToolbarActionsSection {

    constructor(
        // actions to show in the menu with optional groupings
        public readonly moreActions: ToolbarAction[][],
    ) { }

    get actions(): ToolbarAction[] {
        return this.moreActions.flatMap(group => group.flatMap(actions => actions));
    }

    comp(toggled: Set<string>, onAction: (action: ToolbarAction) => void): ReactNode {
        return <ActionMore more={this} onAction={onAction} />;
    }

}

/**
 * A group of actions that are always visible.
 */
export class ToolbarActionsGroup implements ToolbarActionsSection {

    constructor(
        //toggleable actions
        public readonly actions: ToolbarAction[],
        private readonly variant: 'outlined' | 'contained' = 'outlined'
    ) { }

    comp(toggled: Set<string>, onAction: (action: ToolbarAction) => void): ReactNode {
        return <ButtonGroup variant={this.variant}>
            {this.actions.map(a => a.comp(onAction, toggled.has(a.id)))}
        </ButtonGroup>;
    }

}