import {QueryContainer} from "metadata/hypergraph/nodes/QueryContainer";
import {QueryResult} from "metadata/query/QueryResult";
import {ArcQL} from "metadata/query/ArcQL";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {EditType, HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {HyperGraphNodeProps, HyperGraphNodeSerialized} from "metadata/hypergraph/HyperGraphTypes";
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';
import {VisualizationConfig} from 'metadata/query/ArcQLVisualizations';

/**
 * Node containing a query response to be analyzed.
 *
 * @author zuyezheng
 */
export class QueryContainerNode extends HyperGraphNode<QueryContainer> {

    constructor(
        public readonly request: QueryContainer,
        public readonly result: QueryResult,
        public readonly dataset: ArcDataset,
        id?: string,
        createdOn?: number,
        modifiedOn?: number,
        nodeEmbedding?: number[],
        rating?: NodeRating
    ) {
        super(id, createdOn, modifiedOn, nodeEmbedding, undefined, rating);
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.QUERY_CONTAINER;
    }

    label(hyperGraph: HyperGraph): string {
        return this.request.label(hyperGraph);
    }

    get description(): string {
        return this.request.hypothesis.change;
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        return [];
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.request.query;
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.result;
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return this.request.hypothesis;
    }

    get inProgress(): boolean {
        return false;
    }

    get editTypes(): Set<EditType> {
        return new Set([EditType.TITLE, EditType.DESCRIPTION]);
    }

    with(props: Partial<QueryContainerProps>): QueryContainerNode {
        return new QueryContainerNode(
            new QueryContainer(
                this.request.ref,
                props.query ?? this.request.query,
                this.request.operation,
                props.hypothesis ?? this.request.hypothesis,
                props.isFinal ?? this.request.isFinal
            ),
            this.result,
            this.dataset,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn,
            props.nodeEmbedding ?? this.nodeEmbedding,
            props.rating ?? this.rating
        );
    }

    withEdit(type: EditType, content: string): QueryContainerNode {
        const hypothesis = (() => {
            switch (type) {
                case EditType.TITLE:
                    return new SimpleHypothesis(
                        content,
                        this.request.hypothesis.change,
                        this.request.hypothesis.nullHypothesis,
                        this.request.hypothesis.alternativeHypothesis
                    );
                case EditType.DESCRIPTION:
                    return new SimpleHypothesis(
                        this.request.hypothesis.why,
                        content,
                        this.request.hypothesis.nullHypothesis,
                        this.request.hypothesis.alternativeHypothesis
                    );
            }
        })();

        return this.with({hypothesis});
    }

    /**
     * The queries themselves are immutable as changes will alter the results and analysis and therefor create child
     * nodes. However, we can alter the visualization configs for the query.
     */
    withVisualizationConfig(config: VisualizationConfig): QueryContainerNode {
        return this.with({
            query: this.request.query.with({
                visualizations: this.request.query.visualizations.with(config)
            })
        });
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            result: this.result
        };
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): QueryContainerNode {
        const request = QueryContainer.fromJSON(json.request);
        return new QueryContainerNode(
            request,
            new QueryResult(json.result, request.query, dataset),
            dataset,
            json.id,
            json.createdOn,
            json.modifiedOn,
            json.nodeEmbedding,
            NodeRating.withDefault(json.rating)
        );
    }

}

export type QueryContainerProps = HyperGraphNodeProps & {

    query: ArcQL,
    hypothesis: HyperGraphNodeHypothesis,
    isFinal: boolean

}
