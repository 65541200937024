import {Enum} from "common/Enum";

export class ReplaceReason extends Enum {

    static SAVE = new this('save', true, false);
    static VERSION = new this('version', true, false);
    static REFRESH = new this('refresh', true, false);

    // restored unpersisted changes
    static RESTORED = new this('restored', false, false);
    // edited from the dev panel or perhaps generated by AI, only content should be editable
    static DEV = new this('dev', false, true);
    // selection in the hypergraph, these are not saved individually so only content should be replaced
    static HYPERGRAPH = new this('hypergraph', false, false);

    private constructor(
        name: string,
        // indicates all changes to this point have been persisted
        public readonly isPersisted: boolean,
        // if this replace should be only for the content (query and visualization) while retaining metadata like ids
        public readonly onlyContent: boolean
    ) {
        super(name);
    }

}
ReplaceReason.finalize();