import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcQLField} from "metadata/query/ArcQLField";
import {ArcQLOrderBy} from "metadata/query/ArcQLOrderBy";
import {Optional} from "common/Optional";
import {DeleteField} from "metadata/query/changes/DeleteField";

/**
 * Add a field.
 *
 * @author zuyezheng
 */
export class AddField implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly field: ArcQLField,
        // if we should add it at a specific ordinal
        public readonly ordinal?: number,
        // if we should add an order by for the field at a given location
        public readonly orderBy: Optional<Tuple<ArcQLOrderBy, number>> = Optional.none()
    ) {
        this.orderBy.forEach(orderBy => {
            if (orderBy.left.field !== this.field.as) {
                throw new Error(`Order by field '${orderBy.left.field}' should be on the same field as being changed '${field.as}'.`);
            }
        });
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        return new Tuple(
            metadata.with({
                'fields': metadata.fields.with(this.field, this.ordinal),
                'orderBys': this.orderBy
                    .map(orderBy => metadata.orderBys.with(orderBy.left, orderBy.right))
                    .getOr(metadata.orderBys)
            }),
            new DeleteField(this.field.as)
        );
    }

    describe(): string {
        return `Added new ${this.field.type.label} field ${this.field.as}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.ADD;
    }
}