import {JsonObject} from "common/CommonTypes";
import {ArcQLGroupingType} from "metadata/query/ArcQLGroupings";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";


export class ExpressionGrouping extends ArcQLGrouping {

    constructor(
        public readonly expression: string,
        public readonly as: string
    ) {
        super(ArcQLGroupingType.EXPRESSION, as);
    }

    size(): number {
        return 1;
    }

    get projectedAs(): string {
        return this.as;
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        return AnalyticsType.DIMENSION;
    }

    label(dataset: ArcDataset): string {
        // expression groupings don't have column metadata, us the project as name
        return this.as;
    }

    toJSON(): JsonObject {
        return {
            'type': ArcQLGroupingType.EXPRESSION,
            'expression': this.expression,
            'as': this.as
        };
    }

}
