import {FQN} from "common/FQN";
import {JsonObject} from "common/CommonTypes";
import {HyperGraphDeserializer} from "metadata/hypergraph/HyperGraphDeserializer";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";

/**
 * Api response for global hypergraph search
 */
export class GlobalAnswerWithNodes {
    constructor(
        public readonly answer: string,
        public readonly nodeDetails: NodeDetail[]
    ) {
    }

    static fromJSON(json: JsonObject): GlobalAnswerWithNodes {
        return new GlobalAnswerWithNodes(
            json['answer'],
            json['nodeDetails'].map(NodeDetail.fromJSON),
        );
    }

    static empty(): GlobalAnswerWithNodes {
        return new GlobalAnswerWithNodes(null, []);
    }
}

export class NodeDetail {
    constructor(
        public readonly summary: string,
        public readonly node: HyperGraphNode,
        public readonly queryFqn: FQN
    ) {
    }

    static fromJSON(json: JsonObject): NodeDetail {
        return new NodeDetail(
            json['summary'],
            HyperGraphDeserializer.toNode(json['node'], null),
            FQN.parse(json['queryFqn'])
        );
    }
}