import {JsonObject} from "common/CommonTypes";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {FQN} from "common/FQN";
import {ArcQLFilterValue, FilterClause, FilterValue} from "metadata/query/filterclause/FilterClause";
import isEqual from "lodash/isEqual";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {References} from "metadata/References";
import ArcQLFilterClause from "metadata/query/filterclause/ArcQLFilterClause";

/**
 * Global ArcQL filter clause is just a ArcQL filter clause with a dataset reference.
 */
export class GlobalArcQLFilterClause extends ArcQLFilterClause implements GlobalFilterClause {

    static fromJSON(json: JsonObject, references: References): GlobalArcQLFilterClause {
        const arcQLClause: ArcQLFilterClause = ArcQLFilterClause.fromJSON(json, references);
        return new GlobalArcQLFilterClause(
            json['datasetFullyQualifiedName'],
            arcQLClause
        );
    }

    public readonly datasetFqn: FQN;

    constructor(
        datasetFullyQualifiedName: string,
        baseClause: ArcQLFilterClause
    ) {
        super(
            baseClause.column,
            baseClause.fullyQualifiedName,
            baseClause.operator,
            baseClause.resultColumn,
            baseClause.arcqlAsset
        );
        this.datasetFqn = FQN.parse(datasetFullyQualifiedName);
    }

    equals(other: GlobalFilterClause): boolean {
        return this.datasetFqn.equals(other.datasetFqn)
            && this.column === other.column
            && this.operator === other.operator
            && isEqual(this.values, other.values);
    }

    with(operator: FilterOperator, values: FilterValue[]): GlobalFilterClause {
        return new GlobalArcQLFilterClause(
            this.datasetFqn.toString(),
            ArcQLFilterClause.fromArcQLFilter(
                this.column,
                values[0] as ArcQLFilterValue,
                operator
            )
        );
    }

    withColumn(datasetFqn: FQN, column: string): GlobalFilterClause {
        return new GlobalArcQLFilterClause(
            datasetFqn.toString(),
            new ArcQLFilterClause(
                column,
                this.fullyQualifiedName,
                this.operator,
                this.resultColumn,
                this.arcqlAsset
            )
        );
    }

    toBaseFilterClause(): FilterClause {
        return new ArcQLFilterClause(
            this.column,
            this.fullyQualifiedName,
            this.operator,
            this.resultColumn,
            this.arcqlAsset
        );
    }

    toJSON(): JsonObject {
        return {
            'datasetFullyQualifiedName': this.datasetFqn.toString(),
            ...super.toJSON()
        };
    }
}