import {OrderedMap} from "common/OrderedMap";
import {ArcQLField, ColumnField} from "metadata/query/ArcQLField";
import {ArcQLFieldType, FieldSuperType} from "metadata/query/ArcQLFieldType";
import {Optional} from "common/Optional";
import {ArcQLError} from "metadata/query/ArcQLError";
import {Tuple} from "common/Tuple";

export class ArcQLFields {

    static empty() {
        return new ArcQLFields([]);
    }

    static fromJSON(json: any): ArcQLFields {
        return new ArcQLFields(json.map(ArcQLFieldType.fromJSON));
    }

    private readonly _fields: OrderedMap<string, ArcQLField>;

    constructor(fields: ArcQLField[]) {
        this._fields = OrderedMap.fromKeyed(fields, v => v.as);
    }

    map<T>(f: (field: ArcQLField) => T): T[] {
        return this._fields.values.map(f);
    }

    get fields(): ArcQLField[] {
        return this._fields.values;
    }

    get size(): number {
        return this._fields.size;
    }

    get first(): ArcQLField {
        return this._fields.first;
    }

    get last(): ArcQLField {
        return this._fields.last;
    }

    /**
     * Get a field by its projected name.
     */
    get<T extends ArcQLField>(as: string): T {
        return <T>this._fields.get(as).get();
    }

    /**
     * Get but with optional.
     */
    getPossible<T extends ArcQLField>(as: string): Optional<T> {
        return <Optional<T>>this._fields.get(as);
    }

    find<T extends ArcQLField>(as: string): Optional<Tuple<T, number>> {
        return <Optional<Tuple<T, number>>>this._fields.find(as);
    }

    has(as: string): boolean {
        return this._fields.has(as);
    }

    superType(): FieldSuperType {
        const superTypes = new Set(this.fields
            .filter(f => f.type.superType !== FieldSuperType.EITHER)
            .map(f => f.type.superType)
        );

        if (superTypes.size === 1) {
            return superTypes.values().next().value;
        }

        return FieldSuperType.EITHER;
    }

    /**
     * Get all fields for a specific column.
     */
    ofColumn(column: string): ArcQLField[] {
        return this._fields.values.filter(f => {
            return f instanceof ColumnField && f.field === column;
        });
    }

    with(field: ArcQLField, ordinal?: number): ArcQLFields {
        if (ordinal < 0) {
            throw new ArcQLError('Can\'t add a field before ordinal 0.');
        }

        // if no ordinal or greater than the length, add it to the end
        if (ordinal == null || ordinal >= this._fields.size) {
            return new ArcQLFields([...this._fields.values, field]);
        }

        return new ArcQLFields(
            this._fields.values.flatMap(
                (f: ArcQLField, gOrdinal: number) => ordinal === gOrdinal ? [field, f] : [f]
            )
        );
    }

    without(as: string): ArcQLFields {
        return new ArcQLFields(
            this._fields.values.filter(f => f.as !== as)
        );
    }

    /**
     * Create a new fields object mapping each of the existing fields.
     */
    withMapped(f: (field: ArcQLField) => ArcQLField): ArcQLFields {
        return new ArcQLFields(this._fields.values.map(f));
    }

    replace(field: ArcQLField, originalAs?: string): ArcQLFields {
        originalAs = originalAs || field.as;

        return new ArcQLFields(
            this._fields.values.map(
                f => f.as === originalAs ? field : f
            )
        );
    }

    toJSON(): Object {
        return this._fields.toJSON();
    }

}
