import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";

/**
 * @author zuyezheng
 */
export class ComboChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON,
        [
            new VizStringProperty('xAxisName', 'X-Axis'),
            new VizStringProperty('pYAxisName', 'Primary Y-Axis'),
            new VizStringProperty('sYAxisName', 'Secondary Y-Axis'),
            new VizBooleanProperty('showLegend', 'Display Legend'),
            new VizBooleanProperty('dualAxis', 'Dual Axis'),
            new VizBooleanProperty('scroll', 'Scroll'),
            new VizBooleanProperty('showValues', 'Show Values'),
            new VizBooleanProperty('rotateValues', 'Rotate Values')
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', true],
        ['dualAxis', false],
        ['scroll', false],
        ['showValues', false],
        ['rotateValues', false]
    ]);

    get properties(): VizProperty<any>[] {
        return ComboChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return ComboChartDefinition.DEFAULTS;
    }

}