import { JsonObject } from "common/CommonTypes";
import {Optional} from "common/Optional";

/**
 * Layout information for a node.
 *
 * @author zuyezheng
 */
export class HyperGraphNodeLayout {

    constructor(
        public readonly x: number,
        public readonly y: number,
        public readonly width: number,
        public readonly height: number
    ) {}

    /**
     * Move to given absolute coordinates.
     */
    position(x: number, y: number): HyperGraphNodeLayout {
        return new HyperGraphNodeLayout(x, y, this.width, this.height);
    }

    positionX(x: number): HyperGraphNodeLayout {
        return this.position(x, this.y);
    }

    positionY(y: number): HyperGraphNodeLayout {
        return this.position(this.x, y);
    }

    /**
     * Offset by given value.
     */
    offsetX(xOffset: number): HyperGraphNodeLayout {
        return this.position(this.x + xOffset, this.y);
    }

    /**
     * Offset by given value.
     */
    offsetY(yOffset: number): HyperGraphNodeLayout {
        return this.position(this.x, this.y + yOffset);
    }

    /**
     * Return the first layout with a collision.
     */
    collision(others: HyperGraphNodeLayout[]): Optional<HyperGraphNodeLayout> {
        return Optional.of(others.find(
            n => this.x < n.x + n.width &&
                this.x + this.width > n.x &&
                this.y < n.y + n.height &&
                this.y + this.height > n.y
        ));
    }

    /**
     * Return a layout starting at the origin with given dimensions.
     */
    static origin(width: number, height: number): HyperGraphNodeLayout {
        return new HyperGraphNodeLayout(0, 0, width, height);
    }

    /**
     * Zero dimension at origin.
     */
    static zero(): HyperGraphNodeLayout {
        return new HyperGraphNodeLayout(0, 0, 0, 0);
    }

    static fromJSON(json: JsonObject): HyperGraphNodeLayout {
        return new HyperGraphNodeLayout(json.x, json.y, json.width, json.height);
    }

}