import {InputConnectionDetails} from "metadata/connections/InputConnectionDetails";
import {ConnectionType} from "metadata/connections/ConnectionType";

/**
 * Record to request creating a new connection.
 */
export class CreateConnectionRequest {
    constructor(
        public readonly label: string,
        public readonly type: ConnectionType,
        public readonly connectionDetails: InputConnectionDetails
    ) {
    }
}