import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {ArcQLEventFilter} from "metadata/ArcQLMessage";
import {ArcActor} from "engine/actor/ArcActor";
import {SequentialQuerier} from "engine/actor/SequentialQuerier";
import {OnLoadSelection, Selectable, SetSelection} from "engine/actor/Selectable";
import {DiscreteSelection, RangeSelection, VizSelection} from "engine/actor/VizSelection";
import {FacetingSelectionHandler} from "engine/actor/FacetingSelectionHandler";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {Optional} from "common/Optional";
import debounce from "lodash/debounce";
import { ArcQLGroupingType } from "metadata/query/ArcQLGroupings";

/**
 * Querier that listens for new queries to issue from a specific publisher, the publisher delegates querying to this
 * actor such that the publisher can handle something such as just building or manipulating a query. This will also
 * handle the interaction state of that query such as selections.
 *
 * @author zuyezheng
 */
export class DelegatedQueryActor extends ArcActor implements Selectable  {

    private readonly arcqlEventFilter: ArcQLEventFilter;
    private readonly querier: SequentialQuerier;

    private query: Optional<ArcQLBundle>;
    private setSelection: Optional<SetSelection>;
    private _selection: VizSelection;

    private readonly debouncedOnSelection: () => void;

    constructor(
        id: string,
        // only listen for changes from the given actor
        private readonly publisherId: string
    ) {
        super(id);

        // listen for new queries to issue
        this.arcqlEventFilter = new ArcQLEventFilter(this.publisherId);

        this.querier = new SequentialQuerier(false);

        this.query = Optional.none();
        this.setSelection = Optional.none();
        this._selection = VizSelection.none();

        this.debouncedOnSelection = debounce(this.onSelection, 500);
    }

    eventFilters(): ArcEventFilter[] {
        return [this.arcqlEventFilter];
    }

    notify(event: ArcEvent): void {
        this.arcqlEventFilter.filter(event).forEach(m => {
            // going to make a new query, reset selections
            this._selection = VizSelection.none();
            this.onSelection();

            // make the actual query
            this.query = Optional.some(m.metadata);
            this.querier.query(
                m.metadata.arcql,
                m.reason,
                m.changeSetType,
                message => this.connector.publish(message)
            );
        });
    }

    private onSelection(): void {
        this.query.forEach(bundle => {
            // broadcast faceting message
            new FacetingSelectionHandler(bundle.arcql, bundle.dataset)
                .onSelection(this._selection)
                .forEach(message => {
                    this.connector.publish(message);
                });

            // notify the linked component
            this.setSelection.map(f => f(this._selection));
        });
    }

    // for now, no need to support onLoad (as no support for ArcQL sessions currently)
    linkSelectable(setSelection: SetSelection, onLoad: OnLoadSelection = null): void {
        this.setSelection = Optional.some(setSelection);
    }

    unlinkSelectable(): void {
        this.setSelection = Optional.none();
    }

    canSelect(): boolean {
        return this.query.map(q => {
            // can't select without groupings
            if (q.arcql.groupings.size === 0) {
                return false;
            }

            // can't select with expression groupings
            return !q.arcql.groupings.fields.some(g => g.type === ArcQLGroupingType.EXPRESSION);
        }).getOr(false);
    }

    toggleDiscrete(values: string[][]): void {
        this.query.forEach(q => {
            if (!this.canSelect()) {
                return;
            }

            // if the current selections are empty, create a new discrete selection with the current groupings
            if (this._selection.isEmpty()) {
                this._selection = VizSelection.empty(q.arcql.groupings.fields.map(f => f.field));
            }

            // toggle each selection
            this._selection = values.reduce(
                (selection, v) => selection.toggle(v),
                this._selection
            );

            this.onSelection();
        });
    }

    setDiscrete(values: string[][]): void {
        this.query.forEach(q => {
            if (!this.canSelect()) {
                return;
            }

            // set the selection as truth
            this._selection = DiscreteSelection.fromValues(q.arcql.groupings.fields.map(f => f.field), values);

            this.onSelection();
        });
    }

    selectRange(start: number, end: number): RangeSelection {
        this.query.forEach(q => {
            this._selection = new RangeSelection(q.arcql.firstDate()[2].field, [start, end]);

            // range changes needs to be debounced to quell the storm of events fired when dragging which causes fusion
            // to be a little jumpy
            this.debouncedOnSelection();
        });

        return this._selection as RangeSelection;
    }

    get selection(): VizSelection {
        return this._selection;
    }

}