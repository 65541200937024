import {ArcActor} from "engine/actor/ArcActor";
import {ArcEventFilter, TypedEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {Facet, FacetingMessage} from "engine/FacetingMessage";
import {ClearSelectionsMessage} from "engine/ClearSelectionsMessage";
import {DashboardInteractions} from "metadata/dashboard/DashboardInteractions";
import {FQN} from "common/FQN";
import {LoadStateEventFilter} from "engine/actor/LoadStateMessage";
import {Optional} from "common/Optional";

/**
 * Relays additional facets as needed for interactions.
 *
 * @author zuyezheng
 */
export class InteractionsRelayActor extends ArcActor {

    // might need to relay some stuff if added later
    private readonly loadStateFilter: LoadStateEventFilter;
    private readonly facetingFilter: TypedEventFilter<FacetingMessage>;
    private readonly clearSelectionsFilter: TypedEventFilter<ClearSelectionsMessage>;

    constructor(
        id: string,
        private readonly interactions: DashboardInteractions
    ) {
        super(id);

        this.loadStateFilter = new LoadStateEventFilter(id);
        this.facetingFilter = new TypedEventFilter<FacetingMessage>(FacetingMessage);
        this.clearSelectionsFilter = new TypedEventFilter<ClearSelectionsMessage>(ClearSelectionsMessage);
    }

    async initialize(controller: AbortController): Promise<boolean> {
        return Promise.resolve(true);
    }

    eventFilters(): ArcEventFilter[] {
        return [
            this.loadStateFilter,
            this.facetingFilter,
            this.clearSelectionsFilter
        ];
    }

    notify(event: ArcEvent): void {
        // relay any state that might've happened before attaching
        this.loadStateFilter.filter(event).forEach(message =>
            message.statefulMessages.forEach(statefulMessage => {
                Optional.ofType(statefulMessage, FacetingMessage).forEach(m => this.relayFacets(m));
            })
        );

        this.facetingFilter.filter(event).forEach(m => this.relayFacets(m));
        this.clearSelectionsFilter.filter(event).forEach(m => this.relayClearSelections(m));
    }

    private relayFacets(message: FacetingMessage) {
        // facets to relay by dataset fqn
        const facetsToRelay: Map<string, Facet[]> = new Map();

        message.facets.forEach(facet => {
            if (facet.columns.length !== 1) {
                return [];
            }

            this.interactions.findLinkedColumns(FQN.parse(message.datasetFqn), facet.columns[0])
                // replicate the facet for every matched interaction column
                .forEach(column => {
                    let facets = facetsToRelay.get(column.datasetFqn.toString());
                    if (facets == null) {
                        facets = [];
                        facetsToRelay.set(column.datasetFqn.toString(), facets);
                    }

                    facets.push(facet.withColumns([column.column]))
                });
        });

        // broadcast the faceting message to all linked
        Array.from(facetsToRelay.entries()).forEach(
            ([datasetFqn, facets]: [string, Facet[]]) => this.connector.publish(
                new FacetingMessage(datasetFqn, facets)
            )
        );
    }

    private relayClearSelections(message: ClearSelectionsMessage) {
        if (message.columns.length != 1) {
            return;
        }

        this.interactions.findLinkedColumns(FQN.parse(message.datasetFqn), message.columns[0])
            .forEach(column => {
                this.connector.publish(
                    new ClearSelectionsMessage(column.datasetFqn.toString(), [column.column])
                )
            })
    }

}