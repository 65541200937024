import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {BaseWidgetConfig} from "metadata/dashboard/widgets/config/BaseWidgetConfig";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {ChartConfigPart} from "metadata/dashboard/widgets/config/chart/ChartConfigPart";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

/**
 * Complete Configuration for Chart Widget such as its container and chart configuration.
 */
export class ChartWidgetConfig extends BaseWidgetConfig {

    static readonly CONTAINER_CONFIG_KEY = 'container';
    static readonly CHART_CONFIG_KEY = 'chart';

    constructor(
        public readonly container: ContainerConfigPart,
        public readonly chart: ChartConfigPart
    ) {
        super();
    }


    get parts(): Map<string, WidgetConfigPart> {
        return new Map<string, WidgetConfigPart>([
            [ChartWidgetConfig.CONTAINER_CONFIG_KEY, this.container],
            [ChartWidgetConfig.CHART_CONFIG_KEY, this.chart],
        ]);
    }

    static fromMap(configMap: Map<string, any>, dashboardConfig?: DashboardConfig): ChartWidgetConfig {
        let containerPart: ContainerConfigPart = ContainerConfigPart
            .fromJSON(configMap.get(ChartWidgetConfig.CONTAINER_CONFIG_KEY) || {});
        containerPart = dashboardConfig ? containerPart.withDefaults(dashboardConfig.container) : containerPart;
        return new ChartWidgetConfig(
            containerPart,
            ChartConfigPart.fromJSON(configMap.get(ChartWidgetConfig.CHART_CONFIG_KEY) || {})
        );
    }
}