import * as React from "react"
import {SVGProps} from "react"

const IntegerIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        {...props}
    >
        <path
            d="m2.304 5.68-1.136 1-.68-.784 1.848-1.56h1.12V10H2.304V5.68ZM4.966 8.728l2.2-1.976c.112-.101.224-.216.336-.344a.66.66 0 0 0 .168-.448.533.533 0 0 0-.208-.448.753.753 0 0 0-.488-.168c-.224 0-.4.07-.528.208a.85.85 0 0 0-.208.512l-1.2-.088a1.92 1.92 0 0 1 .184-.76c.107-.218.248-.4.424-.544.176-.144.382-.25.616-.32a2.6 2.6 0 0 1 .776-.112c.256 0 .496.038.72.112.224.07.419.176.584.32.166.139.294.315.384.528.096.214.144.462.144.744 0 .182-.018.347-.056.496a1.74 1.74 0 0 1-.392.728c-.09.102-.189.2-.296.296l-1.72 1.48h2.512V10H4.966V8.728ZM10.877 6.568h.392c.112 0 .221-.008.328-.024a.957.957 0 0 0 .288-.088.58.58 0 0 0 .216-.192.61.61 0 0 0 .08-.328c0-.17-.07-.312-.208-.424a.745.745 0 0 0-.496-.168.704.704 0 0 0-.464.152.698.698 0 0 0-.24.376L9.453 5.6c.064-.245.16-.453.288-.624.128-.176.28-.317.456-.424a1.94 1.94 0 0 1 .584-.232 2.894 2.894 0 0 1 1.408.016c.234.059.44.155.616.288.176.128.314.29.416.488.106.198.16.432.16.704 0 .315-.083.584-.248.808-.166.224-.406.368-.72.432v.024c.17.022.32.072.448.152.133.08.245.179.336.296.09.118.16.25.208.4.048.15.072.307.072.472a1.578 1.578 0 0 1-.608 1.28 1.878 1.878 0 0 1-.648.312c-.246.07-.507.104-.784.104-.507 0-.944-.117-1.312-.352-.363-.234-.603-.605-.72-1.112l1.264-.296a.874.874 0 0 0 .256.48c.133.118.328.176.584.176.261 0 .453-.066.576-.2a.748.748 0 0 0 .192-.528c0-.16-.035-.282-.104-.368a.56.56 0 0 0-.256-.2 1.08 1.08 0 0 0-.36-.072 5.015 5.015 0 0 0-.4-.016h-.28v-1.04Z"
        />
    </svg>
)
export default IntegerIcon
