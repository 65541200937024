import {StartAnalysis} from "metadata/hypergraph/nodes/StartAnalysis";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {HyperGraphNodeProps, HyperGraphNodeSerialized} from "metadata/hypergraph/HyperGraphTypes";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {NodeStructuredSection} from "metadata/hypergraph/content/NodeStructuredSection";

/**
 * Initial system prompt to give the LLM context.
 *
 * @author zuyezheng
 */
export class StartAnalysisNode extends HyperGraphNode<StartAnalysis> {

    constructor(
        public readonly request: StartAnalysis,
        id?: string,
        createdOn?: number
    ) {
        super(id, createdOn);
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.START_ANALYSIS;
    }

    label(hyperGraph: HyperGraph): string {
        return 'Start Analysis';
    }

    get description(): string {
        return '';
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        return [];
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    /**
     * Use the hypothesis from the parent.
     */
    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return new SimpleHypothesis(
            'The parent query leads to interesting insights.',
            'Staring analysis from the parent node.'
        );
    }

    get canRate(): boolean {
        return false;
    }

    with(props: Partial<HyperGraphNodeProps>): HyperGraphNode<StartAnalysis> {
        return this;
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): StartAnalysisNode {
        return new StartAnalysisNode(
            StartAnalysis.fromJSON(json.request, dataset),
            json.id,
            json.createdOn
        );
    }

}