import React, {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import Tooltip from "@mui/material/Tooltip";

/**
 * Metadata for presenting linked dataset columns.
 */
export type DatasetInfo = {
    datasetLabel: string,
    fieldLabel: string
}

/**
 * Complete metadata content needed for filter tooltip.
 */
export type FilterTooltipContent = {
    datasetInfos: DatasetInfo[]
    valueDescription: string
}

interface Props {
    title: string
    content: FilterTooltipContent
    children?: React.ReactElement
}

/**
 * Tooltip for filters in DashboardFilterPanel.
 */
export const FilterTooltip: FunctionComponent<Props> = (props: Props) => {

    const contentDiv = (
        <S.Container>
            <h4>{props.title}</h4>
            <S.Subtitle>
                Use rows when value is <strong>{props.content.valueDescription}</strong> from the following:
            </S.Subtitle>
            <S.Table>
                <thead>
                <tr>
                    <th>Dataset</th>
                    <th>Field</th>
                </tr>
                </thead>
                <tbody>
                {props.content.datasetInfos.map((info, index) => (
                    <tr key={index}>
                        <td>{info.datasetLabel}</td>
                        <td>{info.fieldLabel}</td>
                    </tr>
                ))}
                </tbody>
            </S.Table>
        </S.Container>
    );

    return (
        <Tooltip
            title={contentDiv}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -10],
                            },
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                // adds some padding to the left so that it doesn't hug edge of the screen
                                padding: { left: 5 }
                            }
                        }
                    ],
                },
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), ' +
                            '0px 8px 10px 1px rgba(0, 0, 0, 0.14), ' +
                            '0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
                    },
                },
            }}
        >
            {props.children}
        </Tooltip>
    );
};

class S {

    static readonly Container = styled.div`
        background-color: white;
        max-height: 500px;
        overflow-y: scroll;

        h4 {
            margin: 0 0 8px;
            padding-bottom: 8px;
            border-bottom: 1px solid ${Colors.borderGrey};
        }
    `;

    static readonly Subtitle = styled.div`
        padding: 4px 0;
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.xSmall};

        strong {
            font-weight: bolder;
            color: ${Colors.textPrimary};
        }
    `;

    static readonly Table = styled.table`
        width: 100%;
        border-collapse: collapse;
        margin-top: 5px;

        th {
            text-align: left;
            vertical-align: top;
            color: ${Colors.textTertiary};
            font-size: ${FontSizes.xSmall};
            font-width: 400;
            letter-spacing: 0.025em;
            padding: 5px 3px;
            border-bottom: 1px solid ${Colors.borderGrey};
        }

        td {
            font-size: ${FontSizes.small};
            color: ${Colors.textSecondary};
            letter-spacing: 0;
            padding: 5px 3px;
            border-bottom: 1px solid ${Colors.borderGrey};
        }

        tbody tr:last-child td {
            border-bottom: none;
        }
    `;
}
