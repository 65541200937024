import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";

/**
 * Move a field to a new ordinal position.
 *
 * @author zuyezheng
 */
export class MoveField implements ArcMetadataChange<ArcQL> {

    constructor(
        public readonly as: string,
        public readonly ordinal: number
    ) { }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        const field = metadata.fields.find(this.as).get();
        return new Tuple(
            metadata.with({
                'fields': metadata.fields.without(this.as)
                    .with(field.left, this.ordinal),
            }),
            new MoveField(this.as, field.right)
        );
    }

    describe(): string {
        return `Reordered field ${this.as} to ${this.ordinal}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}