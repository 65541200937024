import { Enum } from "common/Enum";

/**
 * User rating of each node.
 *
 * @author zuyezheng
 */
export class NodeRating extends Enum {

    static readonly LIKE = new this('like');
    static readonly DISLIKE = new this('dislike');
    static readonly UNRATED = new this('unrated');

    static withDefault(rating?: string): NodeRating {
        return NodeRating.possible(rating).getOr(NodeRating.UNRATED);
    }

}
NodeRating.finalize();