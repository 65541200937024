import * as React from "react";
import {FunctionComponent} from "react";
import {Position} from "reactflow";
import {HyperGraphNodeProps} from "app/query/hypergraph/nodes/HyperGraphNodeProps";
import styled from "@emotion/styled";
import {StringUtils} from "common/StringUtils";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {FlowNodeS} from "app/query/hypergraph/nodes/FlowNodeS";
import {ProcessFlowIcon} from "app/query/hypergraph/nodes/ProcessFlowIcon";
import { NodeRating } from "metadata/hypergraph/NodeRating";


/**
 * Node created as part of process.
 *
 * @author zuyezheng
 */
export const ProcessFlowNode: FunctionComponent<HyperGraphNodeProps> = (props: HyperGraphNodeProps) => {

    const containerClassNames = {
        'selected': props.selected,
        'disliked': props.data.node.rating === NodeRating.DISLIKE
    };

    return <S.Container className={StringUtils.toClassName(containerClassNames)}>
        <FlowNodeS.Handle type="target" position={Position.Top} />
        <S.Content>
            <ProcessFlowIcon operation={props.data.node.request.operation} />
            <S.Label>{props.data.label}</S.Label>
        </S.Content>
        <FlowNodeS.Handle type="source" position={Position.Bottom} />
    </S.Container>;

};

class S {

    static readonly Container = styled.div`
        width: 240px;
        height: 50px;
        
        border-radius: 6px; 
        border: 1px solid #E0E6E9;
        background: #FFF;
        padding: 1px;
        
        &.selected {
            border: 2px solid ${Colors.hyperarcYellow};
            padding: 0;
        }
        
        &.disliked {
            border: 1px dashed #999;
            background: #F6F6F6;
            
            &.selected {
                border: 2px dashed ${Colors.hyperarcYellow};
                padding: 0;
            }
        }
    `;

    static readonly Content = styled.div`
        flex: 1;
        display: flex;
        padding: 10px;
        align-items: center;
    `;

    static readonly Label = styled.div`
        flex: 1;
        font-size: ${FontSizes.small};
        overflow: hidden;
        margin-left: 8px;
        max-height: 2.3rem;
        line-height: 1.15rem;
    `;

}