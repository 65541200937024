import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcQL} from "metadata/query/ArcQL";
import {Tuple} from "common/Tuple";
import {ArcQLQueryProps} from "metadata/query/ArcQLQueryProps";
import {QueryType} from "metadata/QueryType";

/**
 * Determines and applies the appropriate default query from a map of defaults
 */
export class ApplyDefaultQuery implements ArcMetadataChange<ArcQL> {

    constructor(
        private readonly defaultQueries: Map<QueryType, ArcQLQueryProps>
    ) {
    }

    apply(metadata: ArcQL): Tuple<ArcQL, ArcMetadataChange<ArcQL>> {
        let arcqlProps: ArcQLQueryProps = this.defaultQueries.values().next().value;
        if (this.defaultQueries.size > 1) {
            // If there are multiple default queries, try to choose the same type of query as what the user is
            // currently viewing
            if (metadata.isGrouped() && this.defaultQueries.has(QueryType.AGGREGATE)) {
                arcqlProps = this.defaultQueries.get(QueryType.AGGREGATE);
            } else {
                arcqlProps = this.defaultQueries.get(QueryType.DETAIL);
            }
        }
        const currentQueryType: QueryType = metadata.isGrouped() ? QueryType.AGGREGATE : QueryType.DETAIL;
        return new Tuple<ArcQL, ArcMetadataChange<ArcQL>>(
            metadata.with(arcqlProps),
            new ApplyDefaultQuery(new Map([[currentQueryType, metadata]]))
        );
    }

    describe(metadata: ArcQL): string {
        return "Apply default query properties";
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}