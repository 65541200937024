import {VizSelection} from "engine/actor/VizSelection";
import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {SingleMetricData} from "app/visualizations/data/SingleMetricData";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {SingleMetricChartConfig} from "app/visualizations/config/SingleMetricChartConfig";

export class WaterfallChartConfig extends SingleMetricChartConfig {

    private readonly data: SingleMetricData;

    constructor(response: ArcQLResponse, config: VisualizationConfig) {
        super(response, config);

        this.data = new SingleMetricData(
            response,
            (categoryValues: any[], selections: VizSelection) => {
                const baseAlpha = selections.isEmpty() ? '80' : '30';
                return {
                    alpha: selections.has(categoryValues) ? '80' : baseAlpha
                };
            }
        );
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        return {
            type: 'waterfall2d',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: this.config.get('showLegend').getOr(false),

                        xAxisName: this.config.emptyableString('xAxisName')
                            .getOr(this.xAxisName()),
                        yAxisName: this.config.emptyableString('yAxisName')
                            .getOr(this.response.arcql.fields.fields[0].as),

                        showSumAtEnd: this.config.get('showSumAtEnd').getOr(true),

                        positiveColor: this.config.theme.threePoint[2],
                        negativeColor: this.config.theme.threePoint[0],

                        labelDisplay: 'auto',
                        useEllipsesWhenOverflow: '1',
                        plotFillAlpha: '80',
                        slantLabels: true
                    }
                ),
                data: this.data.dataset(selections)
            }
        };
    }

}