import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {Either} from "common/Either";
import {LocalStorageService} from "services/LocalStorageService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ErrorResponse} from "services/ApiResponse";

/**
 * Helps load and parse metadata for dashboards.
 *
 * @author zuyezheng
 */
export class DashboardLoader {

    /**
     * Figure out how to load or create dashboard for a given fqn.
     */
    static async load(assetFqn: FQN, signal?: AbortSignal): Promise<Optional<LoadResult>> {
        // see if we have a local version to restore from
        const localStorageService = ServiceProvider.get(LocalStorageService);
        const possibleLocal = await localStorageService.getAsset(assetFqn, ArcDashboard.fromJSON);

        let dashboard;
        let isRestored;
        if (possibleLocal.isPresent) {
            // we have a local version of the asset, do some validation
            dashboard = possibleLocal.get();
            isRestored = true;

            if (dashboard.isExisting) {
                // restored a dashboard that has been saved, still fetch from the server so we can inform the user if
                // there's any new version
                const dashboardResponse = await DashboardLoader.fetchOrCreate(assetFqn, true, signal);

                // if it doesn't exist on the server, something sketch, clear the local copy
                if (dashboardResponse.isNone) {
                    localStorageService.clearAsset(assetFqn);
                    ServiceProvider.get(NotificationsService)
                        .publish('dashboardBuilder', NotificationSeverity.ERROR, 'Restored dashboard not found.');

                    return Optional.none();
                }

                // see if the most recent server version is different to warn the user
                if (dashboard.version >= dashboardResponse.get().version) {
                    ServiceProvider.get(NotificationsService)
                        .publish('dashboardBuilder', NotificationSeverity.SUCCESS, 'Restored dashboard.');
                } else {
                    ServiceProvider.get(NotificationsService)
                        .publish(
                            'dashboardBuilder',
                            NotificationSeverity.WARNING,
                            'Restored dashboard, but a newer version found, reload to use the newer version.'
                        );
                }
            } else {
                ServiceProvider.get(NotificationsService)
                    .publish('queryBuilder', NotificationSeverity.SUCCESS, 'Restored unsaved dashboard.');
            }
        } else {
            const dashboardResponse = await DashboardLoader.fetchOrCreate(assetFqn, false, signal);

            // no dashboard with that FQN, start a new one
            if (dashboardResponse.isNone) {
                ServiceProvider.get(NotificationsService)
                    .publish('dashboardBuilder', NotificationSeverity.ERROR, 'No access to dashboard.');
                return Optional.none();
            }

            dashboard = dashboardResponse.get();
            isRestored = false;
        }

        return Optional.some(new LoadResult(dashboard, isRestored));
    }

    static async fetchOrCreate(
        assetFqn: FQN, strict: boolean, signal?: AbortSignal
    ): Promise<Optional<ArcDashboard>> {
        const response = await ServiceProvider.get(MetadataService).fetchDashboard(assetFqn, signal);

        return response.fold(
            r => Optional.some(r),
            (error) => {
                // if strict or no access, return a none
                if (strict || error.response.status === 403) {
                    return Optional.none();
                }

                // otherwise return a new dashboard
                return Optional.some(ArcDashboard.minimal());
            }
        );
    }

    static async refresh(dashboard: ArcDashboard): Promise<Either<ErrorResponse, ArcDashboard>> {
        const response = await ServiceProvider.get(MetadataService).fetchDashboard(dashboard.fqn);
        return response.match(
            (dashboard) => {
                ServiceProvider.get(LocalStorageService).clearAsset(dashboard.fqn);
                return dashboard;
            },
            (error) => {
                ServiceProvider.get(NotificationsService)
                    .publish('dashboardBuilder', NotificationSeverity.ERROR, 'Refresh error.');
                return error;
            }
        );
    }

}

export class LoadResult {

    constructor(
        public readonly dashboard: ArcDashboard,
        // if load was restored from local storage
        public readonly isRestored: boolean
    ) {}

}