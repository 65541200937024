import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";
import {Enum} from "common/Enum";
import {ComponentType} from "react";
import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';

export const PLACEHOLDER_TEXT = 'PLACEHOLDER';

/**
 * Enum for border properties.
 */
export class Border extends Enum implements StylingEnum {

    static readonly TOP = new this(
        'top',
        'Top Border',
        BorderTopIcon,
        {borderTop: PLACEHOLDER_TEXT}
    );

    static readonly RIGHT = new this(
        'right',
        'Right Border',
        BorderRightIcon,
        {borderRight: PLACEHOLDER_TEXT}
    );

    static readonly BOTTOM = new this(
        'bottom',
        'Bottom Border',
        BorderBottomIcon,
        {borderBottom: PLACEHOLDER_TEXT}
    );

    static readonly LEFT = new this(
        'left',
        'Left Border',
        BorderLeftIcon,
        {borderLeft: PLACEHOLDER_TEXT}
    );


    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType,
        public readonly styling: { [prop: string]: string }
    ) {
        super(name);
    }
}

Border.finalize();
