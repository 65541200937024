import {Optional} from "common/Optional";
import {ScopedSearch, ScopedSearchChipProps} from "app/home/ScopedSearch";
import React from "react";
import {WrappedProjectIcon} from "app/components/icons/project/WrappedProjectIcon";
import {S} from "app/home/ScopedSearchS";
import BrowseView from "app/home/browse/BrowseView";
import {FolderResult} from "metadata/project/FolderResult";

export class ScopedSearchProject implements ScopedSearch {

    constructor(
        private readonly _project: FolderResult
    ) {
    }

    /**
     *  note: although account is embedded inside project, this still needs to return none or else search params will conflict
     */
    get account(): Optional<string> {
        return Optional.none();
    }

    get project(): Optional<FolderResult> {
        return Optional.some(this._project);
    }

    renderChip(props: ScopedSearchChipProps): React.JSX.Element {
        return <S.ScopeChip
            icon={<WrappedProjectIcon isPublic={this._project.isPublic}/>}
            label={this._project.ownerName + "/" + this._project.label}
            onDelete={props.onDelete}
        />;
    }

    searchBarPlaceholderText(): string {
        return "Search for dashboards, queries, and data in selected project";
    }

    browseView(queryPresent: boolean): BrowseView {
        return BrowseView.PROJECT_VIEW;
    }

}