/**
 * Expected JSON response for test connection.
 */
export type TestConnectionResponseJson = {
    success: boolean;
    statusCode: number;
    error: string;
}

/**
 * Response from test connection.
 */
export class TestConnectionResponse {

    constructor(
        public readonly success: boolean,
        public readonly statusCode: number,
        public readonly error: string
    ) {
    }

    static fromJson(json: TestConnectionResponseJson): TestConnectionResponse {
        return new TestConnectionResponse(
            json.success,
            json.statusCode,
            json.error
        );
    }
}

