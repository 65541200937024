import {TriggerCondition, TriggerConditionType} from "metadata/trend/TriggerCondition";

export type RangeConditionJson = {
    type: string,
    lowerThreshold: number,
    upperThreshold: number,
    inclusiveLower: boolean,
    inclusiveUpper: boolean,
    inRange: boolean
}


type Props = {
    lowerThreshold: number,
    upperThreshold: number,
    inclusiveLower: boolean,
    inclusiveUpper: boolean,
    inRange: boolean
}

/**
 * Range condition for alerts.
 */
export class RangeCondition implements TriggerCondition {

    static fromJSON(json: RangeConditionJson): RangeCondition {
        return new RangeCondition(
            json.lowerThreshold,
            json.upperThreshold,
            json.inclusiveLower,
            json.inclusiveUpper,
            json.inRange
        );
    }

    static default(): RangeCondition {
        return new RangeCondition(0, 0, true, true, true);
    }

    constructor(
        public readonly lowerThreshold: number,
        public readonly upperThreshold: number,
        public readonly inclusiveLower: boolean,
        public readonly inclusiveUpper: boolean,
        public readonly inRange: boolean
    ) {
    }

    detail(field: string): string {
        const lowerOperator = this.inclusiveLower ? '≤' : '<';
        const upperOperator = this.inclusiveUpper ? '≤' : '<';
        const inRange = this.inRange ? '' : '!';
        return `${inRange}${this.lowerThreshold} ${lowerOperator} ${field} ${upperOperator} ${this.upperThreshold}`;
    }

    with(props: Partial<Props>): RangeCondition {
        return new RangeCondition(
            props.lowerThreshold ?? this.lowerThreshold,
            props.upperThreshold ?? this.upperThreshold,
            props.inclusiveLower ?? this.inclusiveLower,
            props.inclusiveUpper ?? this.inclusiveUpper,
            props.inRange ?? this.inRange
        );
    }

    toJSON(): RangeConditionJson {
        return {
            type: this.type.name,
            lowerThreshold: this.lowerThreshold,
            upperThreshold: this.upperThreshold,
            inclusiveLower: this.inclusiveLower,
            inclusiveUpper: this.inclusiveUpper,
            inRange: this.inRange
        };
    }

    get type(): TriggerConditionType {
        return TriggerConditionType.RANGE;
    }
}