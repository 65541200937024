import {Enum} from "common/Enum";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";
import {ComponentType} from "react";
import IgnoreGlobalFiltersIcon from "app/components/icons/config/IgnoreGlobalFiltersIcon";
import ReceiveGlobalFiltersIcon from "app/components/icons/config/ReceiveGlobalFiltersIcon";
import ReceiveReplaceGlobalFiltersIcon from "app/components/icons/config/ReceiveReplaceGlobalFiltersIcon";

export class GlobalFiltersSetting extends Enum implements ExplainedSetting {
    static IGNORE = new this(
        'ignore',
        'Ignore Global Filters',
        'Selections made on Global Filters will NOT be applicable to this chart.',
        IgnoreGlobalFiltersIcon
    );

    static RECEIVE = new this(
        'receive',
        'Receive Global Filters',
        'Selections made on Global Filters will be received and applied to this chart.',
        ReceiveGlobalFiltersIcon
    );

    static RECEIVE_AND_REPLACE = new this(
        'receive_and_replace',
        'Receive & Replace',
        'Global Filters received by this chart will replace current filters on the same field. By default, global filters are added to existing filters.',
        ReceiveReplaceGlobalFiltersIcon
    );

    private constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly explanation: string,
        public readonly icon: ComponentType
    ) {
        super(name);
    }
}

GlobalFiltersSetting.finalize();