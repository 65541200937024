import {JsonObject} from "common/CommonTypes";
import {AccountType} from "metadata/account/AccountType";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import {BROWSE_PATH} from "common/Paths";

export class AccountResult implements NavigableSearchResult {

    static fromJSON(json: JsonObject): AccountResult {
        const account = json;
        return new AccountResult(
            account['id'],
            account['name'],
            AccountType.get(json['type']),
            account['createdOn'],
            account['updatedOn'],
        );
    }

    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly type: AccountType,
        public readonly createdOn: string,
        public readonly updatedOn: string,
    ) {
    }

    static path(accountName: string): string {
        return BROWSE_PATH + "/" + accountName;

    }

    get path(): string {
        return AccountResult.path(this.name);
    }
}