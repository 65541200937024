import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {Optional} from "common/Optional";
import {FlowNodeType} from "app/query/hypergraph/nodes/FlowNodeType";
import {HyperGraphNodeLayout} from "metadata/hypergraph/HyperGraphNodeLayout";


/**
 * Help with HyperGraph node layout.
 *
 * @author zuyezheng
 */
export class HyperGraphLayout {

    constructor(
        private readonly hyperGraph: HyperGraph,
        private readonly xPadding = 15,
        private readonly yPadding = 90
    ) {}

    /**
     * Layout any nodes in the graph that currently don't have a layout, returning the updated layouts.
     */
    layout(): Map<string, HyperGraphNodeLayout> {
        const updatedLayouts = this.hyperGraph.layouts;

        // we're going to position nodes with the same parent in the same row so do some grouping
        const newNodesByParents = this.newNodesByParents();

        // add the new nodes
        [...newNodesByParents].forEach(([parentId, siblings]) => {
            // try to use the parent as the starting x position to center horizontally, since newly added nodes might be
            // children of other new nodes, we use the updated nodes map
            const parentX = Optional.of(updatedLayouts.get(parentId))
                .map(parent => parent.x)
                .getOr(0);
            // use the max Y position of shared parent and ommers of all siblings to place y
            const possibleParent: Optional<HyperGraphNode> = this.hyperGraph.getPossible(parentId);
            const ancestorY = possibleParent
                .map(() => [parentId, ...siblings.flatMap(s => s.request.ommers)])
                .getOr([parentId])
                .reduce(
                    (maxY, id) => Optional.of(updatedLayouts.get(id))
                        .map(n => Math.max(maxY, n.y))
                        .getOr(maxY),
                    0
                );

            // figure out the desired parent dimensions (vs actual reported) by type, parentId could be null so wrap
            // it in an optional
            const parentDimensions = possibleParent
                .map(n => FlowNodeType.for(n))
                .map(t => ({width: t.width, height: t.height}))
                .getOr({width: 0, height: 0});

            const childDimensions = this.findDimensions(siblings);
            const startPosition = this.findSpace(
                new HyperGraphNodeLayout(
                    parentX + (parentDimensions.width / 2) - (childDimensions.width / 2),
                    ancestorY + parentDimensions.height + this.yPadding,
                    childDimensions.width,
                    childDimensions.height
                ),
                [...updatedLayouts.values()]
            );

            let startX = startPosition.x;
            siblings.forEach(node => {
                const nodeType = FlowNodeType.for(node);
                const newLayout = new HyperGraphNodeLayout(
                    startX,
                    startPosition.y,
                    nodeType.width,
                    nodeType.height
                );
                startX += nodeType.width + this.xPadding;

                updatedLayouts.set(node.id, newLayout);
            });
        });

        return updatedLayouts;
    }

    /**
     * Given the top left and dimensions of a bounding box, find the next available vertical space.
     */
    findSpace(toPlace: HyperGraphNodeLayout, layouts: HyperGraphNodeLayout[]): HyperGraphNodeLayout {
        // create a copy of the top left start position
        let currentPosition = toPlace;

        // try the best case
        const collision = toPlace.collision(layouts);
        if (collision.isNone) {
            return currentPosition;
        }

        // try a couple of "columns" left and right before going down to avoid a graph that is too wide
        for (let i=0; i<5; i++) {
            // look left of the collision
            currentPosition = currentPosition.positionX(
                collision.get().x
                // since x is for the left most point, adjust for the thing we're trying to place
                - toPlace.width - this.xPadding
                // keep looking further left
                - ((collision.get().width + this.xPadding) * i)
            );
            if (currentPosition.collision(layouts).isNone) {
                return currentPosition;
            }

            // look right of the collision
            currentPosition = currentPosition.positionX(
                collision.get().x + ((collision.get().width + this.xPadding) * (i + 1))
            );
            if (currentPosition.collision(layouts).isNone) {
                return currentPosition;
            }
        }

        // immediate row tried, go to the next row and start looking down
        currentPosition = currentPosition.offsetY(toPlace.height + this.yPadding);
        for (let i=0; i<100; i++) {
            // make sure no nodes intersects the current bounding box
            if (currentPosition.collision(layouts).isNone) {
                return currentPosition;
            } else {
                // keep looking
                currentPosition= currentPosition.offsetY(toPlace.height + this.yPadding);
            }
        }

        // reached max iteration without finding something, just place it where it started
        return currentPosition;
    }

    /**
     * Find the dimensions required for a given set of HyperGraph nodes to be placed in the same row.
     */
    findDimensions(nodes: HyperGraphNode[]): HyperGraphNodeLayout {
        return nodes.reduce(
            (dimensions, n, nI) => {
                const nodeType = FlowNodeType.for(n);
                return HyperGraphNodeLayout.origin(
                    // adjust for node width and padding if not the last node
                    dimensions.width + nodeType.width + (nI < (nodes.length - 1)? this.xPadding : 0),
                    Math.max(dimensions.height, nodeType.height)
                );
            },
            HyperGraphNodeLayout.zero()
        );
    }

    /**
     * Figure out which are new nodes and group them by parents. This also implicitly ensures that parent groups are
     * topologically sorted since you can't add a node to the HyperGraph out of order (e.g. parent must be added
     * already) and maps in ES6 maintain insertion order.
     */
    newNodesByParents(): Map<string, HyperGraphNode[]> {
        return this.hyperGraph.nodesWithoutLayout.reduce(
            (acc, node) => {
                if (!acc.has(node.request.parent)) {
                    acc.set(node.request.parent, []);
                }
                acc.get(node.request.parent).push(node);
                return acc;
            },
            new Map<string, HyperGraphNode[]>()
        );
    }

}