/**
 * Represents a color in the RGBA color space.
 *
 * @author zuyezheng
 */
export class RGBA {

    static fromHex(hex: string) {
        if (hex.length === 7) {
            return new RGBA(
                parseInt(hex.slice(1, 3), 16),
                parseInt(hex.slice(3, 5), 16),
                parseInt(hex.slice(5, 7), 16)
            );
        } else {
            throw new Error(`Invalid hex color: ${hex}`);
        }
    }

    constructor(
        public readonly r: number,
        public readonly g: number,
        public readonly b: number,
        public readonly a: number = 1
    ) {}

    withA(a: number) {
        return new RGBA(this.r, this.g, this.b, a);
    }

    toString() {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }

    /**
     * Return a new color without an alpha channel (a=1) adjusted for the current alpha given the background color.
     */
    noA(background: RGBA = WHITE) {
        const adjust = (v: number, bV: number) => {
            return Math.round((v * this.a) + (bV * (1 - this.a)));
        };

        return new RGBA(
            adjust(this.r, background.r),
            adjust(this.g, background.g),
            adjust(this.b, background.b)
        );
    }

    toHex() {
        return '#' + [this.r, this.g, this.b]
            .map(c => c.toString(16).padStart(2, '0'))
            .join('');
    }

}

const WHITE = new RGBA(255, 255, 255);