import {JsonUtils} from "metadata/JsonUtils";
import type {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

export type WidgetMetadataBound = WidgetMetadata<WidgetConfig, WidgetMetadata<WidgetConfig, any>>

/**
 * Metadata defining a widget. Avoiding name of `DashboardWidget` to disambiguate from UI components that render the
 * actual dashboard widget.
 */
export abstract class WidgetMetadata<C extends WidgetConfig, T extends WidgetMetadata<C, T>> {

    constructor(
        public readonly type: WidgetType,
        public readonly id: string,
        public readonly label: string,
        public readonly description: string,
        public readonly config: Map<string, any>
    ) {
    }

    /**
     * Create a new metadata object by either replacing or merging the given configs.
     */
    withConfigs(configs: Map<string, any>, merge: boolean = false): T {
        return new this.type.ctor(
            this.type,
            this.id,
            this.label,
            this.description,
            new Map(merge ? [...this.config, ...configs] : configs)
        ) as T;
    }

    /**
     * Create a new metadata object without configs for the given keys.
     */
    withoutConfigs(keys: string[]): T {
        const keySet = new Set(keys);

        return new this.type.ctor(
            this.type,
            this.id,
            this.label,
            this.description,
            new Map(Array.from(this.config.entries())
                // filter configs in the given keys
                .filter(([k, _]: [string, any]) => !keySet.has(k))
            )
        ) as T;
    }

    /**
     * Return widget config from original base configMap.
     * Note: This is not a `get` property because want to avoid the misconception that it's a simple accessor
     * property. Everytime metadat.widgetConfig() is called, it requires deserialization everytime.
     */
    widgetConfig(): C {
        return this.mergeWidgetConfig();
    }

    /**
     * With the given widget's config map, merge it with the dashboard config.
     */
    abstract mergeWidgetConfig(dashboardConfig?: DashboardConfig): C;

    toJSON(): Object {
        return {
            'type': this.type,
            'label': this.label,
            'description': this.description,
            'config': JsonUtils.fromMap(this.config)
        };
    }

}
