import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {ReplaceReason} from "metadata/ReplaceReason";
import {DatasetV2BuilderState} from "app/datasetv2/DatasetV2BuilderState";

/**
 * Replace the entire dataset.
 */
export class DatasetV2StateReplace implements ArcMetadataChange<DatasetV2BuilderState> {

    constructor(
        public readonly state: DatasetV2BuilderState,
        public readonly reason: ReplaceReason
    ) {
    }

    apply(metadata: DatasetV2BuilderState): Tuple<DatasetV2BuilderState, ArcMetadataChange<DatasetV2BuilderState>> {
        return Tuple.of(
            this.state,
            new DatasetV2StateReplace(metadata, this.reason)
        );
    }

    describe(): string {
        return `Replaced dataset state from ${this.reason.name}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.REPLACE;
    }
}