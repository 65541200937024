import * as React from "react";
import {FunctionComponent} from "react";
import {DialogS} from "app/DialogS";
import FormControl from "@mui/material/FormControl";
import styled from "@emotion/styled";
import {useDropzone} from "react-dropzone";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {StringUtils} from "common/StringUtils";
import {IconButton} from "@mui/material";
import {Clear} from "@mui/icons-material";
import {FormS} from "app/components/form/FormS";

type Props = {
    file: File
    onFileSelected: (file: File) => void
    onFileRemoved: () => void
};

/**
 * FileUpload form component.
 */
export const FileUploadForm: FunctionComponent<Props> = (props: Props) => {

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'application/avro': ['.avro'],
            'text/csv': ['.csv'],
            'application/json': ['.json'],
            'application/vnd.apache.orc': ['.orc'],
            'application/vnd.apache.parquet': ['.parquet'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        onDropRejected: (rejectedFiles) => {
            ServiceProvider.get(NotificationsService).publish(
                'UploadDatasetDialog',
                NotificationSeverity.WARNING,
                `Unsupported or file exceeds 200MB: ${rejectedFiles[0].file.name}`
            );
        },
        // 200MB
        maxSize: 200 * 1024 * 1024,
        maxFiles: 1,
        multiple: false,
        onDrop: (acceptedFiles) => {
            props.onFileSelected(acceptedFiles[0]);
        }
    });

    const onRemoveFile = (event: React.MouseEvent) => {
        event.stopPropagation();
        props.onFileRemoved();
    };

    const buildDropzoneContent = () => {
        if (props.file) {
            return <S.DropContent>
                <p>{props.file.name} - {StringUtils.formatBytes(props.file.size)}</p>
                <IconButton
                    onClick={onRemoveFile}
                    size="small"
                >
                    <Clear/>
                </IconButton>
            </S.DropContent>;
        }
        if (isDragActive) {
            return <p>Drop the file here...</p>;
        }

        return <p>
            Drag and drop or select a file up to 200MB
            <br/>
            <small>(.csv, .json, .xls, .xlsx, .parquet, .avro, .orc)</small>
        </p>;
    };

    return <DialogS.InputRow>
        <FormControl fullWidth>
            <FormS.Label id="file-upload-label">Upload File</FormS.Label>
            <S.Dropzone {...getRootProps({isDragActive})}>
                <input {...getInputProps()} />
                {buildDropzoneContent()}
            </S.Dropzone>
        </FormControl>
    </DialogS.InputRow>;
};

class S {

    static readonly Dropzone = styled.div<{ isDragActive: boolean }>`
        border: 2px dashed ${props => props.isDragActive ? '#2196f3' : '#ccc'};
        padding: 20px;
        text-align: center;
        cursor: pointer;
        transition: border 0.3s ease-in-out;
    `;

    static readonly DropContent = styled.div`
        display: flex;
        gap: 8px;
        justify-content: center;
    `;
}


