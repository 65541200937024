import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {Asset, AssetProps} from "metadata/Asset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {JsonObject} from "common/CommonTypes";
import {JsonUtils} from "metadata/JsonUtils";
import {DataSuperType} from "metadata/DataType";
import {Column} from "metadata/Column";
import {OrderedMap} from "common/OrderedMap";
import {AssetType} from "metadata/AssetType";
import {QueryType} from "metadata/QueryType";
import {ArcQLQueryProps} from "metadata/query/ArcQLQueryProps";
import {ArcQLFields} from "metadata/query/ArcQLFields";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {ArcQLGroupings} from "metadata/query/ArcQLGroupings";
import {ArcQLOrderBys} from "metadata/query/ArcQLOrderBy";
import {ArcQLVisualizations} from "metadata/query/ArcQLVisualizations";
import {References} from "metadata/References";
import {DatasetStatus} from "metadata/dataset/DatasetStatus";
import {DatasetFactory} from "metadata/dataset/DatasetFactory";
import {MeasureOperator} from "metadata/query/MeasureField";

/**
 * Main metadata representation (like columns + status) for all dataset types.
 */
export class ArcDataset implements Asset {

    public readonly json: JsonObject;
    public readonly dates: Map<string, Column>;
    public readonly measures: Map<string, Column>;
    public readonly dimensions: Map<string, Column>;
    public readonly status: DatasetStatus;
    public readonly features: Features;

    private readonly fields: OrderedMap<string, Column>;
    public readonly defaultQueries: Map<QueryType, ArcQLQueryProps>;

    constructor(json: JsonObject) {
        this.json = json;

        this.fields = DatasetFactory.processColumns(json['columns']);

        const fieldsArray: Column[] = this.fields.values;
        this.dates = DatasetFactory.filterColumnsByAnalyticsType(fieldsArray, AnalyticsType.DATE);
        this.measures = DatasetFactory.filterColumnsByAnalyticsType(fieldsArray, AnalyticsType.MEASURE);
        this.dimensions = DatasetFactory.filterColumnsByAnalyticsType(fieldsArray, AnalyticsType.DIMENSION);
        this.status = DatasetStatus.fromJSON(json['datasetStatus']);
        this.features = Features.fromJSON(json['features']);

        const references = References.fromJSON(json['references']);
        this.defaultQueries = JsonUtils.toMapWithKey<QueryType, ArcQLQueryProps>(
            json['defaultQueries'],
            queryType => QueryType.get(queryType),
            props => {
                return {
                    fields: ArcQLFields.fromJSON(props['fields']),
                    filters: ArcQLFilters.fromJSON(props['filters'], false, references),
                    aggregateFilters: ArcQLFilters.fromJSON(props['aggregateFilters'], true, references),
                    groupings: ArcQLGroupings.fromJSON(props['groupings']),
                    orderBys: ArcQLOrderBys.fromJSON(props['orderBys']),
                    limit: props['limit'],
                    visualizations: ArcQLVisualizations.fromJSON(props['visualizations']),
                };
            }
        );
    }

    // asset type for dataset unique in that it can either be original v1 or v2
    get assetType(): AssetType {
        return this.fqn.type;
    }

    get description(): string {
        throw new Error("Method not implemented.");
    }

    get isExisting(): boolean {
        // can't create datasets
        return true;
    }

    with({name, label, description}: AssetProps): Asset {
        throw new Error("Method not implemented.");
    }

    get fqn(): FQN {
        return FQN.parse(this.json['fullyQualifiedName']);
    }

    get id(): string {
        return this.json['id'];
    }

    get name(): string {
        return this.fqn.name;
    }

    get label(): string {
        return this.json['label'];
    }

    get rowCount(): number {
        return this.status['rowCount'];
    }

    allFields(): Column[] {
        return this.fields.values;
    }

    get(name: string): Column {
        return this.fields.get(name).nullable;
    }

    /**
     * Try to find a column by name.
     */
    getPossible(name: string): Optional<Column> {
        return Optional.of(this.get(name));
    }

    /**
     * Try to find the column and its label.
     */
    getLabel(name: string, missing?: string): string {
        if (missing == null) {
            missing = name;
        }
        return this.getPossible(name).map(f => f.label).getOr(missing);
    }

    /**
     * Get the data type for the given field.
     */
    getDataSuperType(fieldName: string): DataSuperType {
        if (fieldName === '*') {
            return DataSuperType.STAR;
        }

        return this.fields.get(fieldName).nullable.dataSuperType;
    }

    /**
     * Return the primary date field, if any.
     */
    primaryDate(): Optional<Column> {
        return Optional.of(
            Array.from(this.dates.values()).find(d => d.extended('isTimeSortColumn').getOr(false))
        );
    }

    toLocalStorageJSON(): JsonObject {
        throw new Error('Datasets should not be saved locally.');
    }

}

export class TrackedRange {
    constructor(
        public readonly columnName: string,
        public readonly min: number,
        public readonly max: number
    ) {
    }
}

class Features {
    constructor(
        public readonly aggregationOperators: Set<MeasureOperator>
    ) {
    }

    static fromJSON(json: JsonObject): Features {
        return new Features(
            new Set(json['aggregationOperators'].map((s: string) => MeasureOperator.get(s)))
        );
    }
}