import * as React from "react";
import {ForwardedRef, FunctionComponent, ReactNode, useRef, useState} from "react";
import {S} from "app/dashboard/DashboardBuilderS";
import {WidgetLayout} from "metadata/dashboard/DashboardLayouts";
import {WidgetContainerAction} from "app/dashboard/widgets/WidgetContainerAction";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";
import {OnWidgetActionCallback, WidgetContainer} from "app/dashboard/widgets/WidgetContainer";
import {Optional} from "common/Optional";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {DashboardQuery} from "metadata/dashboard/DashboardQueries";
import {WidgetComponentFactory} from "app/dashboard/widgets/WidgetComponentFactory";
import Popper from "@mui/material/Popper";
import GridLayout, {Layout, WidthProvider} from "react-grid-layout";
import {DragAndDropData} from "common/DragAndDropData";
import {DashboardDnDSource} from "app/dashboard/components/DashboardDnDSource";
import {FQN} from "common/FQN";
import {useHotkeys} from "react-hotkeys-hook";
import {ActorStatus} from "engine/actor/ActorStatus";
import {ResultMessage} from "engine/ResultMessage";
import {DashboardBuilderDelegate} from "app/dashboard/DashboardBuilderDelegate";
import {ArcEngine} from "engine/ArcEngine";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {WidgetTypeExtended} from "app/dashboard/WidgetTypeExtended";
import {DashboardFilterActor} from "app/dashboard/DashboardFilterActor";
import {StylesUtil} from "app/components/StylesUtil";
import {StoryScene} from "metadata/asset/story/StoryScene";
import {WidgetTrendsMap} from "metadata/trend/WidgetTrendsMap";

const ResizeableGridLayout = WidthProvider(GridLayout);

interface Props {
    // ref to the viewport of the grid
    ref?: ForwardedRef<HTMLElement>

    // basics of a dashboard
    dashboard: ArcDashboard
    engine: ArcEngine
    delegate: DashboardBuilderDelegate
    filterActor: DashboardFilterActor

    isViewMode: boolean
    isEmbed: boolean
    // override showing widget actions for designated widget
    showWidgetActions: (widgetId: string) => boolean
    recordingScene: Optional<StoryScene>

    // current status of the dashboard
    resultMessages: Map<string, ResultMessage>
    queryStatuses: Map<string, ActorStatus>
    selectedWidget: Optional<SelectedWidgetState>
    lastPaletteItem: Optional<WidgetType>

    onSelectWidget: (selected: Optional<SelectedWidgetState>) => void
    onWidgetAction: OnWidgetActionCallback
    onAddQueryTo: (widgetId: string) => void

    // highlight which widgets have trends
    highlightedTrendWidgets: Set<string>
    // specify which widget whose trends are being viewed
    viewingTrendWidget: Optional<string>
    trendsMap: Optional<WidgetTrendsMap>
}

const DROP_PROXY_ID = '__dropping-elem__';

export const DashboardGrid: FunctionComponent<Props> = React.forwardRef<HTMLDivElement, Props>((
    props: Props, viewportRef: ForwardedRef<HTMLDivElement>
) => {

    const containerEl = useRef<HTMLDivElement>(null);

    const [draggingState, setDraggingState] = useState<Optional<DraggingWidgetState>>(Optional.none());
    const [preventCollision, setPreventCollision] = useState<boolean>(true);

    // "allowing" collisions shuffles widgets around all over the place and doesn't return them to their original
    // positions which messes up complex layouts, however sometimes you want to explicitly move a bunch of stuff down
    // all at once so let users explicitly do this with shift
    useHotkeys('shift', (event: KeyboardEvent) => {
        setPreventCollision(!event.shiftKey);
    }, {keydown: true, keyup: true});

    const onClickGrid = (e: React.MouseEvent) => {
        // only care in edit mode
        if (props.isViewMode) {
            return;
        }

        // the grid component is the only child to the grid container which the click event is attached to, we know
        // if the grid was directly clicked (vs a widget) if the parent is the canvasRef.
        // @ts-ignore
        if (e.target.parentElement !== containerEl.current) {
            return;
        }

        // unselect everything if grid is clicked
        props.onSelectWidget(Optional.none());
    };

    const onDragStart = (
        layout: Layout[],
        oldItem: Layout,
        newItem: Layout,
        placeholder: Layout,
        event: MouseEvent,
        element: HTMLElement
    ) => {
        // only care in edit mode
        if (props.isViewMode) {
            return;
        }
        // dragging a new widget from the palette, nothing to select
        if (oldItem.i === DROP_PROXY_ID) {
            return;
        }

        // start tracking the drag start
        setDraggingState(Optional.of({
            id: oldItem.i,
            startDrag: Date.now(),
            el: element
        }));
    };

    const onDragStop = () => {
        draggingState.map(state => {
            // since click events are hijacked by drag events, short drags (less than 150ms) are clicks
            if (Date.now() - state.startDrag < 150) {
                // click event, toggle selection
                if (props.selectedWidget.map(s => s.id === state.id).getOr(false)) {
                    props.onSelectWidget(Optional.none());
                } else {
                    props.onSelectWidget(Optional.of({
                        id: state.id,
                        el: state.el
                    }));
                }
            } else {
                // drag event, end in the dragged widget being selected
                props.onSelectWidget(Optional.of({
                    id: state.id,
                    el: state.el
                }));
            }

            setDraggingState(Optional.none());
        });
    };

    const onDropDragOver = (): { w: number, h: number } => {
        // figure out the default widget size based on the type
        return props.lastPaletteItem.map(widgetType => WidgetTypeExtended.from(widgetType).defaultSize)
            .getOr({w: 1, h: 1});
    };

    const onDropOnGrid = (layouts: Layout[], item: Layout, event: Event) => {
        // bunch of jankyness between frameworks and native es6 events vs react events so need to do some sketch casting
        const drop = DragAndDropData.fromEvent<DashboardDnDSource>(
            event as unknown as React.DragEvent, DashboardDnDSource
        );

        // dropped a widget placeholder
        drop.isOf(DashboardDnDSource.PALETTE).forEach((widgetTypeName: string) => {
            const widgetType = WidgetType.get(widgetTypeName);
            const widgetId = props.delegate.createPlaceholderWidget(WidgetType.get(widgetTypeName), item);

            // added a widget, see if user wants to attach a query
            if (widgetType === WidgetType.CHART) {
                props.onAddQueryTo(widgetId);
            }
        });

        // dropped a query
        drop.isOf(DashboardDnDSource.QUERIES).forEach((queryFqn: string) => {
            props.delegate.createQueryWithWidget(FQN.parse(queryFqn), item)
        });
    };

    const onWidgetDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const onWidgetDrop = (widgetMetadata: WidgetMetadataBound, event: React.DragEvent) => {
        // see if dropping a query onto a widget to attach it
        DragAndDropData.fromEvent<QueryDnDSource>(event, DashboardDnDSource)
            .isOf(DashboardDnDSource.QUERIES)
            .forEach((queryFqn: string) => {
                // make sure the widget is attachable
                if (!(widgetMetadata instanceof QueriedWidgetMetadata<any, any>)) {
                    return;
                }
                widgetMetadata = widgetMetadata as QueriedWidgetMetadata<any, any>;
                props.delegate.createAndAttachQuery(FQN.parse(queryFqn), widgetMetadata.id);
            });
    };

    const onClickAttachQuery = (widgetMetadata: WidgetMetadataBound) => {
        props.onAddQueryTo(widgetMetadata.id);
    };

    const onLayoutChange = (layouts: Layout[]) => {
        // safeguard in case drop stop event is not called
        setDraggingState(Optional.none());

        // no actual changes
        if (layouts.length === 0) {
            return;
        }
        // dragging a proxy to create a new item, not actually changing the layout
        if (layouts.some(l => l.i === DROP_PROXY_ID)) {
            return;
        }

        const namedLayouts = new Map(layouts.map(l => [l.i, l]));
        // this will get triggered sometimes with no changes, don't want to push those to undo/redo stack so need
        // to check for changes first
        if (props.dashboard.layouts.default.hasChanges(namedLayouts)) {
            props.delegate.modifyLayout(namedLayouts);
        }
    };

    const buildWidgets = (): ReactNode => {
        const isHighlightingTrendView = props.highlightedTrendWidgets.size > 0;

        return props.dashboard.layouts.default.map((widgetId: string) => {
            const widgetMetadata = props.dashboard.widgets.get(widgetId);
            const isSelected = props.selectedWidget.map(s => s.id === widgetId).getOr(false);

            const showActions: (isOver: boolean) => boolean = (isOver) => {
                // Hover actions should only be shown when actually in the dashboard builder, and
                // not in embedded mode
                if (props.isEmbed) {
                    return false;
                }

                // in view mode,  show action on hover or if a widget action menu is active
                // else in edit, show actions if widget selected
                return (props.isViewMode && isOver)
                    || (props.showWidgetActions(widgetId))
                    || (!props.isViewMode && isSelected);
            };

            const buildActions: () => WidgetContainerAction[] = () => {
                const actions = [];

                // open in query builder if there's a query
                if (widgetMetadata instanceof QueriedWidgetMetadata) {
                    actions.push(
                        WidgetContainerAction.OPEN,
                    );

                    if (props.isViewMode) {
                        actions.push(WidgetContainerAction.TREND, WidgetContainerAction.SHARE);
                    }
                }

                if (!props.isViewMode) {
                    // edit only actions
                    actions.push(WidgetContainerAction.DELETE);
                }
                return actions;
            };

            return <S.Widget
                key={widgetId}
                className={isSelected ? 'selected' : ''}
                onDragOver={onWidgetDragOver}
                onDrop={e => onWidgetDrop(widgetMetadata, e)}
            >
                <WidgetContainer
                    isViewMode={props.isViewMode}

                    onClickAttachQuery={onClickAttachQuery}

                    widgetMetadata={widgetMetadata}
                    dashboardConfig={props.dashboard.config}
                    resultMessages={props.resultMessages}
                    queryStatuses={props.queryStatuses}
                    engine={props.engine}
                    filterActor={props.filterActor}

                    showActions={showActions}
                    buildActions={buildActions}
                    onAction={props.onWidgetAction}

                    highlightAsTrendWidget={props.highlightedTrendWidgets.has(widgetId)}
                    highlightViewingTrend={props.viewingTrendWidget.map(v => v === widgetId).getOr(false)}
                    trendsMap={props.trendsMap}
                    pauseSelectionsMsg={
                        Optional.bool(isHighlightingTrendView)
                            .map(() => 'Selections are disabled when viewing a trend.')
                    }
                />
            </S.Widget>;
        });
    };

    const buildWidgetEditControls = (): ReactNode => {
        if (draggingState.isPresent) {
            return;
        }

        type Dependencies = [
            SelectedWidgetState, WidgetMetadataBound, Optional<DashboardQuery>, WidgetComponentFactory
        ];
        return props.selectedWidget
            .flatMap<Dependencies>(selectedWidget => {
                const widgetMetadata = props.dashboard.widgets.get(selectedWidget.id);
                const queryMetadata = Optional.ofType(widgetMetadata, QueriedWidgetMetadata)
                    .flatMap(m => m.queryId)
                    .map(qId => props.dashboard.queries.get(qId));
                return [
                    selectedWidget,
                    widgetMetadata,
                    queryMetadata,
                    WidgetTypeExtended.from(widgetMetadata.type).widgetComponentFactory
                ];
            })
            .map(([selectedWidget, widgetMetadata, dashboardQuery, factory]) =>
                <Popper
                    open={true}
                    anchorEl={selectedWidget.el}
                    placement={'bottom-start'}
                >
                    <S.InlineEditor style={factory.matchWidgetDimensions ? {
                        // 250px is to ensure there's enough width for toolbar if widget is too skinny
                        width: `${Math.max(selectedWidget.el.clientWidth, 250)}px`,
                        height: `100%`
                    } : {}}>
                        <S.InlineEditorContainer>
                            <S.InlineEditorContent>
                                {factory.inlineEditor(
                                    widgetMetadata,
                                    widgetMetadata.mergeWidgetConfig(props.dashboard.config),
                                    dashboardQuery,
                                    props.dashboard,
                                    props.delegate
                                )}
                            </S.InlineEditorContent>
                        </S.InlineEditorContainer>
                    </S.InlineEditor>
                </Popper>
            )
            .nullable;
    };

    return <S.GridCenter
        className={StylesUtil.toClassName([
            props.recordingScene.map(() => 'recording')
        ])}
        backgroundColor={props.dashboard.config.gutterColor}
    >
        <S.GridWidthBoundary
            maxWidth={props.dashboard.config.maxWidth}
            className={StylesUtil.toClassName([
                props.recordingScene.map(() => 'recording')
            ])}
        >
            <S.GridViewport
                ref={viewportRef}
                onClick={onClickGrid}
                className={StylesUtil.toClassName([
                    Optional.some(props.isViewMode ? 'view' : 'edit'),
                    props.recordingScene.map(() => 'recording')
                ])}
            >
                <S.GridContainer
                    ref={containerEl}
                    backgroundColor={props.dashboard.config.backgroundColor}
                >
                    <ResizeableGridLayout
                        cols={props.dashboard.config.numberColumns}
                        rowHeight={36}
                        margin={[props.dashboard.config.gridGapX, props.dashboard.config.gridGapY]}
                        resizeHandles={props.isViewMode ? [] : ['se']}
                        className={props.isViewMode ? 'view' : ''}
                        compactType={null}
                        preventCollision={preventCollision}
                        isDraggable={!props.isViewMode}
                        isResizable
                        isDroppable
                        layout={
                            props.dashboard.layouts.default.map((n: string, l: WidgetLayout) => ({
                                i: n,
                                x: l.x,
                                y: l.y,
                                w: l.w,
                                h: l.h
                            }))
                        }
                        onDragStart={onDragStart}
                        onDragStop={onDragStop}
                        onDropDragOver={onDropDragOver}
                        onDrop={onDropOnGrid}
                        onLayoutChange={onLayoutChange}
                    >
                        {buildWidgets()}
                    </ResizeableGridLayout>
                    {
                        props.recordingScene.map(s => <S.Caption><b>{s.label}</b> {s.description}</S.Caption>).nullable
                    }
                    { buildWidgetEditControls() }
                </S.GridContainer>
            </S.GridViewport>
        </S.GridWidthBoundary>
    </S.GridCenter>;

});

export type DraggingWidgetState = {
    // widget id
    id: string
    // epoch of when the drag started
    startDrag: number
    // dom element
    el: Element
}

export type SelectedWidgetState = {
    // widget id
    id: string
    // dom element
    el: Element
}