import * as React from "react";
import {FunctionComponent, useContext, useState} from "react";
import styled from "@emotion/styled";
import {General} from "app/components/settings/General";
import {CommonS} from "app/components/CommonS";
import {Colors} from "app/components/StyleVariables";
import {UserContext} from "app/UserContext";
import {DataSources} from "app/components/settings/connections/DataSources";
import {Notifications} from "app/components/settings/notifications/Notifications";
import {HyperArcUser} from "metadata/HyperArcUser";
import { Enum } from "common/Enum";


/**
 * Settings landing page with tabs.
 */
export const Settings: FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState(SettingsSection.GENERAL);
    const userContext = useContext(UserContext);

    const renderSettingsTab = (setting: SettingsSection, user: HyperArcUser) => {
        switch (setting) {
            case SettingsSection.GENERAL:
                return <General
                    user={user}
                    memberships={userContext.memberships}
                />;
            case SettingsSection.DATA_SOURCES:
                return <DataSources
                    user={user}
                    orgMemberships={userContext.memberships}
                />;
            case SettingsSection.NOTIFICATIONS:
                return <Notifications
                    user={user}
                />;
            default:
                return null;
        }
    };

    return <S.OverflowContainer>
            {
                userContext.user.map(user =>
                    <S.Container>
                        <S.Header>Settings</S.Header>
                        <S.Tabs
                            value={activeTab}
                            onChange={(_, newValue) => setActiveTab(newValue)}
                        >
                            {
                                SettingsSection.enums<SettingsSection>().map(setting =>
                                    <CommonS.Tab
                                        key={setting.name}
                                        value={setting}
                                        label={setting.label}
                                    />
                                )
                            }
                        </S.Tabs>
                        <S.Content>
                            {
                                renderSettingsTab(activeTab, user)
                            }
                        </S.Content>
                    </S.Container>
                ).getOr(
                    <S.Container>
                        <S.Header>Settings</S.Header>
                        You must be logged-in to view settings.
                    </S.Container>
                )
            }
        </S.OverflowContainer>;
};


class SettingsSection extends Enum {

    static readonly GENERAL = new SettingsSection('general', 'General');
    static readonly DATA_SOURCES = new SettingsSection('dataSources', 'Data Sources');
    static readonly NOTIFICATIONS = new SettingsSection('notifications', 'Notifications');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
SettingsSection.finalize();


class S {

    static readonly OverflowContainer = styled.div`
        height: 100%;
        overflow: auto;
    `;

    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
        padding: 24px;
        width: 75%;
        margin: 0 auto;
    `;

    static readonly Header = styled.h1`
        font-size: 24px;
        color: #333;
    `;

    static readonly Tabs = styled(CommonS.Tabs)`
        border-bottom: 1px solid ${Colors.borderGrey};

        .MuiTab-root {
            border-bottom: none;
            min-width: 100px;
        }

    `;

    static readonly Content = styled.div`
        margin-top: 16px;
    `;

}
