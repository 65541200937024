import {DatasetType} from "metadata/dataset/DatasetType";
import {RemoteTable, RemoteTableJson} from "metadata/connections/RemoteItem";
import {DatasetConfig} from "metadata/dataset/DatasetConfig";


/** JSON representation of a Personal dataset configuration. */
export type PersonalDatasetConfigJson = {
    table: RemoteTableJson,
    fileName: string
};

/**
 * Configuration for a Personal dataset.
 */
export class PersonalDatasetConfig implements DatasetConfig {

    public readonly type = DatasetType.PERSONAL;

    constructor(
        public readonly table: RemoteTable,
        public readonly fileName: string
    ) {
    }

    static fromJSON(json: PersonalDatasetConfigJson): PersonalDatasetConfig {
        return new PersonalDatasetConfig(
            RemoteTable.fromJSON(json.table),
            json.fileName
        );
    }

    get fullTableName(): string {
        return `${this.table.name}`;
    }
}