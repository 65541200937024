import {Colors} from "app/components/StyleVariables";
import {JsonPanel} from "app/components/dev/JsonPanel";
import {SqlPanel} from "app/components/dev/SqlPanel";
import {VersionsPanel} from "app/components/detailpanel/VersionsPanel";
import styled from "@emotion/styled";
import {HyperGraphPanel} from "app/query/hypergraph/HyperGraphPanel";

export const S = {

    QueryBuilder: styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `,

    Content: styled.div`
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    `,

    LeftPanel: styled.div`
        height: 100%;
        overflow: auto;
        border-right: 1px solid ${Colors.borderGrey};
    `,

    BodyPanel: styled.div`
        height: 100%;
        display: flex;
        overflow: hidden;
        flex: 1;
    `,

    DevPanel: styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
    `,

    JsonPanel: styled(JsonPanel)`
        flex: 1;
        border-top: 1px solid ${Colors.borderGrey};
    `,

    SqlPanel: styled(SqlPanel)`
        flex: .25;
        border-top: 1px solid ${Colors.borderGrey};
        margin-top: 10px;
    `,

    VersionsPanel: styled(VersionsPanel)`
        width: 300px;
        border-left: 1px solid ${Colors.borderGrey};
    `,

    HyperGraphPanel: styled(HyperGraphPanel)`
        width: 50%;
        border-left: 1px solid ${Colors.borderGrey};
    `

};