import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";

/**
 * Request to query the range of a dataset's numeric field.
 */
export default class RangeValuesArcQLRequest {
    constructor(
        public readonly datasetFqn: string,
        public readonly field: string,
        public readonly filters: Optional<ArcQLFilters>
    ) {
    }

    toJSON(): JsonObject {
        return {
            dataset: this.datasetFqn,
            field: this.field,
            filters: this.filters.nullable,
        };
    }
}