import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import isEqual from "lodash/isEqual";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {CLIPPED_DESCRIPTION_LENGTH, CompositeFilterValue, FilterClause} from "metadata/query/filterclause/FilterClause";
import {FilterClauseType} from "metadata/query/filterclause/FilterClauseType";

export class CompositeFilterClause implements FilterClause {

    static fromJSON(json: JsonObject): CompositeFilterClause {
        return new CompositeFilterClause(
            json['fields'],
            json['values']
        );
    }

    constructor(
        public readonly fields: string[],
        public readonly values: CompositeFilterValue[]
    ) {
    }

    description(short: boolean): string {
        if (this.isAll) {
            return 'All';
        }

        let description = this.values
            .map(valueSet => '(' + valueSet.join(', ') + ')')
            .join(', ');

        if (short && description.length >= CLIPPED_DESCRIPTION_LENGTH) {
            description = description.substring(0, CLIPPED_DESCRIPTION_LENGTH) + '...';
        }

        return `in [${description}]`;
    }

    equals(other: FilterClause): boolean {
        return Optional.ofType(other, CompositeFilterClause)
            .map(o => isEqual(this.fields, o.fields) && isEqual(this.values, o.values))
            .getOr(false);
    }

    fieldsLabel(dataset: ArcDataset): string {
        return '(' +
            this.fields
                .map(field => dataset.getLabel(field, `<missing: ${field}>`))
                .join(', ') +
            ')';
    }

    get isAll(): boolean {
        return this.values.length === 0;
    }

    isFor(fields: string | string[]): boolean {
        return isEqual(fields, this.fields);
    }

    get isStructureValid(): boolean {
        const numFields = this.fields.length;
        for (const value of this.values) {
            if (!Array.isArray(value) || value.length !== numFields) {
                return false;
            }
        }

        return true;
    }

    get type(): FilterClauseType {
        return FilterClauseType.COMPOSITE;
    }

    toJSON(): JsonObject {
        return {
            'type': FilterClauseType.COMPOSITE.name,
            'fields': this.fields,
            'values': this.values
        };
    }
}