import {ChartConfig} from "app/visualizations/config/ChartConfig";
import {Optional} from "common/Optional";
import {AnalyticsType} from "metadata/AnalyticsType";
import {SelectionEvent} from "app/visualizations/FusionTypes";

export abstract class SingleMetricChartConfig extends ChartConfig {

    validate(): Optional<string> {
        // ensure there is only a single metric
        if (this.response.result.columnsByType(new Set([AnalyticsType.MEASURE])).length !== 1) {
            return Optional.some('Chart type requires exactly 1 measure.');
        }

        return Optional.none();
    }

    handleDiscreteClick(event: SelectionEvent, originalDataSource: {[key: string]: any}): Optional<string[][]> {
        // make sure the data index is a known data point, charts like waterfall will add additional data points such
        // as for the cumulative total
        return Optional.of(originalDataSource.data[event.dataIndex])
            .map(dataPoint => [dataPoint.categoryValues]);
    }

}