import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";
import {VizSectionedProperty} from "app/visualizations/definition/property/VizSectionedProperty";

/**
 * @author zuyezheng
 */
export class BarChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON_WITH_CARTESIAN_AND_LEGEND,
        [
            new VizBooleanProperty('scroll', 'Scroll'),
            new VizBooleanProperty('vertical', 'Vertical'),
            new VizSectionedProperty(
                'stack',
                'Stack Groupings',
                [
                    new VizBooleanProperty('stack100', 'Stack 100%', 'checkbox'),
                    new VizBooleanProperty('showSum', 'Show Sum', 'checkbox')
                ]
            ),
            new VizSectionedProperty(
                'showValues',
                'Show Values',
                [
                    new VizBooleanProperty('rotateValues', 'Rotate Values', 'checkbox')
                ]
            ),
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', true],
        ['scroll', false],
        ['vertical', true],
        ['stack', true]
    ]);

    get properties(): VizProperty<any>[] {
        return BarChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return BarChartDefinition.DEFAULTS;
    }

}