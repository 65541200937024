import * as React from "react"
import {SVGProps} from "react"

const WaterfallIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M18 4.5h3v16h-3v-16Zm-15 9h3v7H3v-7Zm11-9h3v3h-3v-3Zm-4 1h3v4h-3v-4Zm-3 5h3v4H7v-4Z"
        />
    </svg>
);
export default WaterfallIcon;
