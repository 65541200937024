import {VizSelection} from "engine/actor/VizSelection";
import {Optional} from "common/Optional";
import {QueryResult} from "metadata/query/QueryResult";


const PIXELS_PER_CATEGORY = 180;


/**
 * Transform query results into data for a candlestick chart.
 *
 * @author zuyezheng
 */
export class CandlestickData {

    private readonly openCloseIndices: [number, number];
    private readonly highLowIndices: Optional<[number, number]>;
    private readonly volumeIndex: Optional<number>;

    constructor(
        openClose: [string, string],
        highLow: Optional<[string, string]>,
        volume: Optional<string>,
        private readonly queryResult: QueryResult,
        private readonly isGrouped: boolean
    ) {
        this.openCloseIndices = openClose.map(c => this.queryResult.columnIndices.get(c)) as [number, number];
        this.highLowIndices = highLow.map(c => c.map(c => this.queryResult.columnIndices.get(c)) as [number, number]);
        this.volumeIndex = volume.map(c => this.queryResult.columnIndices.get(c));
    }

    hasVolume(): boolean {
        return this.volumeIndex.isPresent;
    }

    dataset(selections: VizSelection): CandlestickDataset[] {
        const plotAlpha = selections.isEmpty() ? '80' : '30';
        const categoryColumnIndices = this.queryResult.categoryColumns.map(c => c.right);

        const data: CandlestickRow[] = this.queryResult.rows.map(((row, rowI) => {
            const label = this.queryResult.rowLabel(row, categoryColumnIndices, this.isGrouped ? null : rowI.toString());
            const categoryValues = categoryColumnIndices.map(i => row[i]);
            const candlestickRow: CandlestickRow = {
                toolText: this.toolText(label),
                x: rowI,
                open: row[this.openCloseIndices[0]],
                close: row[this.openCloseIndices[1]],
                alpha: selections.has(categoryValues) ? '80' : plotAlpha,
                categoryValues: categoryValues
            };

            this.highLowIndices.forEach(indices => {
                candlestickRow.high = row[indices[0]];
                candlestickRow.low = row[indices[1]];
            }).orForEach(() => {
                candlestickRow.high = row[this.openCloseIndices[0]];
                candlestickRow.low = row[this.openCloseIndices[1]];
            });

            this.volumeIndex.forEach(index => {
                candlestickRow.volume = row[index];
            });

            return candlestickRow;
        }));

        return [{data: data}];
    }

    categories(chartWidth: number): CandlestickCategory[] {
        if (this.queryResult.rows.length === 0) {
            return []
        }

        const categoriesToRender = Math.floor(chartWidth / PIXELS_PER_CATEGORY);
        // not enough space to render a category
        if (categoriesToRender === 0) {
            return [];
        }

        const rowStep = Math.round(this.queryResult.rows.length / categoriesToRender);
        // not enough rows to render categories for
        if (rowStep === 0) {
            return [];
        }

        const categories = [];
        // skip rendering the first category since it gets rendered centered and will be cut off
        for (let i = rowStep; i < this.queryResult.rows.length; i += rowStep) {
            const row = this.queryResult.rows[i];
            const label = this.queryResult.rowLabel(row, this.queryResult.categoryColumns.map(c => c.right), this.isGrouped ? null : i.toString());
            categories.push({
                label: label,
                x: i,
                showVerticalLine: true
            });
        }

        return [{
            category: categories
        }];
    }

    private toolText(label: string): string {
        const parts = [
            `<b>${label}</b>`,
            `${this.queryResult.columns[this.openCloseIndices[0]]}: <b>$openDataValue</b>`,
            `${this.queryResult.columns[this.openCloseIndices[1]]}: <b>$openDataValue</b>`
        ];

        this.highLowIndices.forEach(cIs => {
            parts.push(
                `${this.queryResult.columns[cIs[0]]}: <b>$highDataValue</b>`,
                `${this.queryResult.columns[cIs[1]]}: <b>$lowDataValue</b>`
            );
        });

        this.volumeIndex.forEach(cI => {
            parts.push(`${this.queryResult.columns[cI]}: <b>$volumeDataValue</b>`);
        });

        return parts.join('<br>');
    }

}

export type CandlestickRow = {
    x: number,
    open: number,
    close: number,
    high?: number,
    low?: number,
    volume?: number
    alpha?: string,
    displayValue?: string,
    toolText?: string,
    // internal values used for selection
    categoryValues: string[]
}

export type CandlestickDataset = {
    data: CandlestickRow[]
}

export type CandlestickCategorySegment = {
    label: string,
    x: number
}

export type CandlestickCategory = {
    category: CandlestickCategorySegment[]
}