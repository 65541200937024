import {useEffect, useState} from "react";
import {Either} from "common/Either";
import {ErrorCode, ErrorResponse} from "services/ApiResponse";
import {HyperArcUser} from "metadata/HyperArcUser";
import {ServiceProvider} from "services/ServiceProvider";
import {UserService} from "services/UserService";
import {Config} from "services/Config";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {Optional} from "common/Optional";
import {useAuth0} from "@auth0/auth0-react";
import {LogService} from "services/LogService";
import {OrgMembership} from "metadata/OrgMembership";

/**
 * Hook for handling HyperArc authentication.
 */
export const useHyperArcAuth = (isEmbed: boolean): {
    onLoggedIn: (user: HyperArcUser) => void,
    onFinishedSignup: () => void,
    onLogout: (returnTo?: string) => void,

    isAuthenticated: boolean,
    memberships: OrgMembership[],
    hyperArcUser: Optional<HyperArcUser>,
    needsSignup: boolean,
    duplicateEmailMessage: Optional<string>
} => {

    const {isAuthenticated, logout} = useAuth0();

    const [memberships, setMemberships] = useState<OrgMembership[]>([]);
    const [hyperArcUser, setHyperArcUser] = useState<Optional<HyperArcUser>>(Optional.none<HyperArcUser>());

    const [needsSignup, setNeedsSignup] = useState<boolean>(false);
    const [duplicateEmailMessage, setDuplicateEmailMessage] = useState<Optional<string>>(Optional.none());

    const onLoggedIn = (user: HyperArcUser) => {
        // authed, do authed stuff
        setHyperArcUser(Optional.of(user));
    };

    const onFinishedSignup = (): void => {
        setNeedsSignup(false);
    };

    const onLogout = (returnTo?: string): void => {
        logout({returnTo});
    };

    useEffect(() => {
        if (ServiceProvider.get(Config).isTest) {
            ServiceProvider.get(LogService).log({name: 'AuthHook'}, 'test mode, skipping auth');
            return;
        }

        if (!isAuthenticated) {
            return;
        }

        (async () => {
            const response: Either<ErrorResponse, HyperArcUser> = await ServiceProvider.get(UserService).getCurrentUser();
            response.match(
                user =>
                    ServiceProvider.get(UserService).listMemberships().then(listResponse =>
                        listResponse.match(
                            memberships => {
                                setMemberships(memberships);
                                onLoggedIn(user);
                            },
                            error => ServiceProvider.get(NotificationsService)
                                .publish("app", NotificationSeverity.ERROR, error.prettyPrint())
                        )
                    ),
                r => {
                    if (r.errorCode === ErrorCode.USER_NOT_FOUND) {
                        setNeedsSignup(true);
                    } else if (r.errorCode === ErrorCode.DUPLICATE_USER_EMAIL) {
                        setDuplicateEmailMessage(Optional.string(r.prettyPrint()));
                    } else {
                        ServiceProvider.get(LogService).error({name: 'AuthHook'}, r.prettyPrint());
                    }
                }
            );
        })();
    }, [isAuthenticated]);

    return {
        onLoggedIn,
        onFinishedSignup,
        onLogout,

        isAuthenticated,
        memberships,
        hyperArcUser,
        needsSignup,
        duplicateEmailMessage
    };
};