import React from "react";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";


interface Props {
    className?: string;
    // provide container dimensions, otherwise just use an absolute positioned mask which will mask the nearest ancestor
    // that is relatively positioned
    containerDimensions?: [string, string];
    maskOpacity?: number;
    // include message to display along with the spinner
    message?: string;
}

/**
 * Loading mask
 *
 * @author zuyezheng
 */
export const LoadingMask = (props: Props) => {

    const buildMaskContent = () => {
        const spinner = <S.Spinner src="/static/images/hyperarc-animated-loader.gif" alt="loading"/>;
        if (props.message) {
            return <S.Dialog>
                {spinner}
                <S.Message>{props.message}</S.Message>
            </S.Dialog>;
        }

        return spinner;
    };

    const innerMask = (
        <S.Mask maskOpacity={props.maskOpacity ?? 0.2}>
            { buildMaskContent() }
        </S.Mask>
    );

    if (props.containerDimensions == null) {
        return innerMask;
    } else {
        return <S.MaskContainer {...props}>{innerMask}</S.MaskContainer>;
    }
};

class S {

    static readonly MaskContainer = styled.div((props: Props) => ({
        width: props.containerDimensions[0],
        height: props.containerDimensions[1]
    }));

    static readonly Mask = styled.div((props: Props) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        backgroundColor: `rgba(0, 0, 0, ${props.maskOpacity})`,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1000',
    }));

    static readonly Dialog = styled.div`
        background-color: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 500px;
    `;

    static readonly Spinner = styled.img`
        height: 40px;
    `;

    static readonly Message = styled.div`
        font-size: ${FontSizes.large};
        color: ${Colors.textPrimary};
        padding-left: 20px;
        flex: 1;
    `;

}