import React, {memo} from "react";
import {CommonS} from "app/components/CommonS";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import styled from "@emotion/styled";

dayjs.extend(relativeTime);

interface Props {
    createdBy: string;
    createdOn: string;
}

/**
 * User and date for creation or update.
 */
export const CreatedInfo = memo((props: Props) => {
    return (
        <S.Info>
            <CommonS.Emphasized>{`@${props.createdBy}`}</CommonS.Emphasized>
            <S.Seperator>•</S.Seperator>
            <div>{dayjs(props.createdOn).fromNow()}</div>
        </S.Info>
    );
});

class S {

    static Info = styled(CommonS.MinorText)`
        display: flex;
    `;

    static Seperator = styled.div`
        padding: 0 5px;
    `;
    
}