import {Enum} from "common/Enum";

export class ArcMessageType extends Enum {

    static METADATA = new this('metadata', false);
    static RESULT = new this('result', true);
    static SELECTION = new this('selection', true);
    static CLEAR_SELECTION = new this('clearSelection', false);
    static GLOBAL_FILTERS = new this('globalFilters', true);
    static FACETING = new this('faceting', false);
    static STATUS = new this('status', false);
    static LOAD_STATE = new this('loadState', false);

    constructor(
        name: string,
        public readonly stateful: boolean
    ) {
        super(name);
    }

}

/**
 * Messages emitted from an actor to the engine without a specific recipient. Instead, other actors can listen for
 * specific messages by specifying filters. Messages are automatically wrapped with the actor that sent it and messages.
 */
export abstract class ArcMessage {

    readonly type: ArcMessageType;

}

export class MetadataMessage<T> implements ArcMessage {

    readonly type: ArcMessageType = ArcMessageType.METADATA;

    constructor(
        public readonly metadata: T
    ) { }

}
