import {ToolbarAction} from "app/components/toolbar/ToolbarAction";
import Button from "@mui/material/Button";
import * as React from "react";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";

/**
 * A toolbar action for trends in Dashboard.
 */
export const TrendToolbarAction = (trendsCount: number): ToolbarAction =>

    new ToolbarAction(
        'trends',
        'Trends',
        false,
        false,
        {
            content: <S.ButtonLabel>
                <S.StandaloneBadge className="standaloneBadge">
                    {trendsCount}
                </S.StandaloneBadge>
                Trends
            </S.ButtonLabel>,
            component: S.Button
        }
    );

class S {

    static ButtonLabel = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
    `;

    static StandaloneBadge = styled.div`
        width: 20px;
        height: 20px;
        background-color: ${Colors.changePositive};
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 0.75rem;
        margin-right: 10px;
    `;

    static Button = styled(Button)`

        &.MuiButtonBase-root {
            // // TODO ZZ use more "semantic" colors, this green is not because of a positive (vs negative) change
            border: 1px solid ${Colors.changePositive};
            &:hover {
                border-color: ${Colors.changePositive};
            }
        }
        
        &.toggled {
            background-color: ${Colors.changePositive};
            color: white;
        
            &:hover {
                & .standaloneBadge {
                    background-color: ${Colors.changePositive};
                    color: white;
                }
            }
            
            & .standaloneBadge {
                background-color: white;
                color: ${Colors.changePositive};
            }
        }

    `;

}