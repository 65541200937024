import {WidgetMetadata} from "metadata/dashboard/widgets/WidgetMetadata";
import {Optional} from "common/Optional";
import {WidgetConfig} from "metadata/dashboard/widgets/config/WidgetConfig";

/**
 * Widgets that are attachable to a query.
 */
export abstract class QueriedWidgetMetadata<C extends WidgetConfig, T extends WidgetMetadata<C, T>> extends WidgetMetadata<C, T> {

    /**
     * Attach the given query by id to the widget, returning a new QueriedWidgetMetadata.
     */
    abstract attach(queryId: string): QueriedWidgetMetadata<C, T>;

    /**
     * Detach the current query for the widget.
     */
    abstract detach(): QueriedWidgetMetadata<C, T>;

    /**
     * Get the id of the attached query, if any.
     */
    abstract get queryId(): Optional<string>;

    /**
     * If the current widget is attached to a query yet.
     */
    abstract get isAttached(): boolean;

}