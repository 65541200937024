import * as React from "react";
import {FunctionComponent, useEffect, useRef, useState} from "react";
import {ServiceProvider} from "services/ServiceProvider";
import {Optional} from "common/Optional";
import {ArcEngine} from "engine/ArcEngine";
import {ArcMetadata} from "metadata/ArcMetadata";
import {Toolbar} from "app/components/toolbar/Toolbar";
import {StandardActions, ToolbarAction} from "app/components/toolbar/ToolbarAction";
import {SaveAsDialog} from "app/components/SaveAsDialog";
import {TabProps} from "app/TabType";
import {SaveHandler} from "metadata/SaveHandler";
import {
    ToolbarActions,
    ToolbarActionsGroup,
    ToolbarActionsMenu,
    ToolbarActionsSection,
    ToolbarActionsSingle
} from "app/components/toolbar/ToolbarActions";
import {AssetVersion} from "metadata/AssetVersions";
import {MetadataService} from "services/MetadataService";
import {SaveDialog} from "app/components/SaveDialog";
import {SaveMode} from "app/components/SaveMode";
import {ReplaceReason} from "metadata/ReplaceReason";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";
import {FilterSetLoader} from "app/filterset/FilterSetLoader";
import {S} from "app/filterset/FilterSetBuilderS";
import {FilterSetBuilderDelegate} from "app/filterset/FilterSetBuilderDelegate";
import {GridColumns} from "@mui/x-data-grid-premium";
import {FilterSetTextHelper, ValidationError, ValidationSuccess} from "app/filterset/FilterSetTextHelper";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import {AlertTitle} from "@mui/lab";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ArchiveDialog} from "app/components/toolbar/ArchiveDialog";

const DELEGATE_ID = 'delegate';

/**
 * For constructing and editing a Filter Set, effectively a saved set of values.
 */
export const FilterSetBuilder: FunctionComponent<TabProps> = (props: TabProps) => {

    const canvasEl = useRef<HTMLElement>(null);
    const isFirstFilterSetLoad = useRef(true);

    const [filterSet, setFilterSet] = useState<Optional<ArcFilterSet>>(Optional.none());
    const [engine, setEngine] = useState<Optional<ArcEngine>>(Optional.none());
    const [saveMode, setSaveMode] = useState<Optional<SaveMode>>(Optional.none());
    const [showVersions, setShowVersions] = useState<boolean>(false);
    const [showArchive, setShowArchive] = useState<boolean>(false);
    const [valuesText, setValuesText] = useState<string>('');
    const [values, setValues] = useState<string[]>([]);
    const [areHexValues, setAreHexValues] = useState<boolean>(true);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const hasValuesError: boolean = validationErrors.length > 0;

    const delegate: Optional<FilterSetBuilderDelegate> = engine.map(e => e.getActor(DELEGATE_ID));
    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            const possibleLoadResult = (await FilterSetLoader.load(
                props.path.assetFqn, controller.signal
            ));

            // load error
            if (possibleLoadResult.isNone) {
                props.onClose();
                return;
            }

            // successfully loaded the metadata for the filterSet
            const loadResult = possibleLoadResult.get();
            setFilterSet(Optional.some(loadResult.filterSet));

            const delegate = new FilterSetBuilderDelegate(
                DELEGATE_ID,
                new ArcMetadata<ArcFilterSet>(loadResult.filterSet, 50),
                setFilterSet
            );

            const engine = new ArcEngine('builder');
            await engine.registerAll([delegate]);

            setEngine(Optional.some(engine));
            if (loadResult.isRestored) {
                // push a sentinel change so we know there are unsaved changes which will also trigger an initial filterSet
                delegate.replace(loadResult.filterSet, ReplaceReason.RESTORED);
            } else {
                // force the delegate to publish a change with the initial arcql filterSet
                delegate.onChanged();
            }

            props.onTabChange(loadResult.filterSet.label, props.path);
        })();

        return () => controller.abort();
    }, []);

    // update changes to local storage upon filter set change
    useEffect(() => {
        delegate.forEach(d => d.updateLocal(props.path.assetFqn));
    }, [filterSet]);


    // load values from filter set onto the text editor.
    // note: this would help trim any extra whitespaces for the users
    useEffect(() => {
        filterSet.map(fs => {
            const loadedText = FilterSetTextHelper.valuesToText(fs.data?.values || []);
            setValuesText(loadedText);
            setValues(fs.data?.values || []);
            isFirstFilterSetLoad.current = false;
        }).getOr();
    }, [filterSet]);

    // update the tab with any asset changes from labels to unsaved changes
    useEffect(() => {
        Optional.all([delegate, filterSet]).forEach(([delegate, filterSet]: [FilterSetBuilderDelegate, ArcFilterSet]) => {
            props.onTabChange(filterSet.label || filterSet.name, props.path, delegate.hasChanges);
        });
    }, [filterSet]);

    useEffect(() => {
        // we want to avoid processing the first filter set load due to default hex check even if that filter set
        // is not intended to have hex values
        if (!isFirstFilterSetLoad.current) {
            processText(true);
        }
    }, [areHexValues]);

    const onToolbarChange = (assetLabel: string) => {
        delegate.forEach(d => d.changeInfo({
            label: assetLabel
        }));
    };

    const saveHandler = filterSet.map(q => new SaveHandler(q, props.onTabChange, Optional.of(canvasEl.current)));
    const onToolbarAction = (action: ToolbarAction) => {
        switch (action.id) {
            case StandardActions.UNDO().id:
                delegate.forEach(d => d.undo());
                break;
            case StandardActions.REDO().id:
                delegate.forEach(d => d.redo());
                break;
            case StandardActions.SAVE.id:
                setSaveMode(Optional.some(SaveMode.SAVE));
                break;
            case StandardActions.SAVE_AS.id:
                setSaveMode(Optional.some(SaveMode.SAVE_AS));
                break;
            case StandardActions.VERSIONS.id:
                setShowVersions(!showVersions);
                break;
            case StandardActions.ARCHIVE.id:
                setShowArchive(!showArchive);
                break;
            case StandardActions.REFRESH.id:
                filterSet.forEach(
                    filterSet => FilterSetLoader.refresh(filterSet).then(
                        response => delegate.forEach(
                            delegate => delegate.replace(response.rightOrThrow(), ReplaceReason.REFRESH)
                        )
                    )
                );
                break;
        }
    };

    const onCancelSave = () => {
        setSaveMode(Optional.none());
    };

    const onSave = (arcFilterSet: ArcFilterSet) => {
        // persisted, clear local copy
        delegate.forEach(d => d.replace(arcFilterSet, ReplaceReason.SAVE));
        setSaveMode(Optional.none());
    };

    const onCancelArchive = () => {
        setShowArchive(false);
    };

    const onArchive = () => {
        props.onClose();
    };

    const refreshActions = filterSet.filter(q => q.isExisting)
        .map(() => new ToolbarActionsSingle(
            StandardActions.REFRESH
        ))
        .array;

    const saveActions = filterSet.filter(q => q.isExisting)
        .map<ToolbarActionsSection>(() => new ToolbarActionsMenu(
            StandardActions.SAVE,
            [[
                StandardActions.VERSIONS,
                StandardActions.SAVE_AS,
                StandardActions.ARCHIVE
            ]],
            true
        ))
        .getOrElse(() => new ToolbarActionsSingle(StandardActions.SAVE_AS));

    const toolbarActions = new ToolbarActions([
        new ToolbarActionsGroup([
            StandardActions.UNDO(delegate.map(d => !d.hasUndo).getOr(true)),
            StandardActions.REDO(delegate.map(d => !d.hasRedo).getOr(true)),
        ]),
        ...refreshActions,
        saveActions
    ]);

    const onSelectVersion = (version: AssetVersion) => {
        filterSet.forEach(
            q => ServiceProvider.get(MetadataService)
                .fetchFilterSetVersion(q.fullyQualifiedName, version.version)
                .then(result => {
                    delegate.forEach(d => d.replace(result.rightOrThrow(), ReplaceReason.VERSION));
                })
        );
    };

    // Prepare the data for DataGridPro
    const rows = hasValuesError ? [] : values.map((value, index) => ({id: index, value}));
    const columns: GridColumns = [
        {field: 'id', headerName: 'ID', hide: true},
        {field: 'value', headerName: 'FILTER SET PREVIEW', flex: 1, cellClassName: 'cellText'}
    ];

    const processText = (force: boolean) => {
        const valuesArray = FilterSetTextHelper.textToValues(valuesText);
        // noop if values are the same and not forcing processing text
        const areValuesSame = JSON.stringify(valuesArray) === JSON.stringify(values);
        if (!force && areValuesSame) {
            return;
        }

        // Left = validation errors, Right = validation success
        FilterSetTextHelper.validateAndProcessValues(valuesArray, areHexValues).match(
            (success: ValidationSuccess) => {
                ServiceProvider.get(NotificationsService).publish(
                    'FilterSetBuilder',
                    NotificationSeverity.INFO,
                    `Valid filter set values: ${success.validatedValues.length}\nDuplicates removed: ${success.numberOfDupes}`
                );
                delegate.map(d => d.modifyValues(success.validatedValues));
                setValues(success.validatedValues);
                setValidationErrors([]);
            },
            (errors: ValidationError[]) => {
                // still set bad values for diff checks
                setValues(valuesArray);
                setValidationErrors(errors);
            }
        );
    };

    const errorsInPreview = () => (
        <S.ErrorsInPreview>
            {
                validationErrors.map(
                    (error, index) => (
                        <Alert key={index} severity={"error"}>
                            <AlertTitle>{error.alertTitle}</AlertTitle>
                            {error.alertMessage}
                        </Alert>
                    ))
            }
        </S.ErrorsInPreview>
    );

    return <S.FilterSetBuilder>
        <Toolbar
            fqn={props.path.assetFqn}
            assetLabel={filterSet.map(q => q.label).getOr(null)}
            assetLabelEditable={true}
            onLabelChange={onToolbarChange}
            isCompactTitle={false}
            actions={toolbarActions}
            onAction={onToolbarAction}
        />
        <S.Content>
            <S.ValuesInputPanel>
                <S.InputHelpText hasInputError={hasValuesError}>
                    Enter up to 10k filter values separated by commas:
                </S.InputHelpText>
                <S.TextField
                    value={valuesText}
                    error={hasValuesError}
                    onBlur={() => processText(false)}
                    onChange={e => setValuesText(e.target.value)}
                    fullWidth
                    multiline
                    size='small'
                    rows={20}
                    maxRows={20}
                    helperText={hasValuesError ? 'Please resolve the errors' : null}
                />
                <S.AreHexValuesCheckbox
                    control={
                        <Checkbox
                            color="primary"
                            checked={areHexValues}
                            onChange={(e) => setAreHexValues(e.target.checked)}
                        />
                    }
                    label="These are hex-formatted values"
                />
            </S.ValuesInputPanel>
            <S.ValuesPreviewPanel>
                <S.DataGridPro
                    rows={rows}
                    columns={columns}
                    hideFooterRowCount={false}
                    hideFooterSelectedRowCount
                    hideFooter={rows.length === 0}
                    rowCount={rows.length}
                    isRowSelectable={() => false}
                    disableSelectionOnClick
                    disableColumnSelector
                    components={{
                        NoRowsOverlay: hasValuesError ?
                            errorsInPreview :
                            () => <S.NoDataPreview>No data to preview.</S.NoDataPreview>,
                    }}
                />
            </S.ValuesPreviewPanel>
            {
                showVersions && filterSet.map(filterSet =>
                    <S.VersionsPanel
                        fqn={filterSet.fullyQualifiedName}
                        selected={filterSet.version}
                        onSelect={onSelectVersion}
                    />
                ).getOr(<></>)
            }
        </S.Content>
        {
            Optional.all([filterSet, saveMode]).map(([filterSet, mode]: [ArcFilterSet, SaveMode]) =>
                mode === SaveMode.SAVE_AS ?
                    <SaveAsDialog
                        asset={filterSet}
                        fqn={props.path.assetFqn}
                        saveHandler={saveHandler.get()}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    /> :
                    <SaveDialog
                        fqn={props.path.assetFqn}
                        label={filterSet.label}
                        description={delegate.map(d => d.describeChanges()).getOr('')}
                        saveHandler={saveHandler.get()}
                        onCancel={onCancelSave}
                        onSave={onSave}
                    />
            ).nullable
        }
        {
            showArchive && filterSet.map(q =>
                <ArchiveDialog
                    asset={q}
                    onCancel={onCancelArchive}
                    onArchive={onArchive}
                />
            ).nullable
        }
    </S.FilterSetBuilder>;
};