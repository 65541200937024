import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {AssetProps} from "metadata/Asset";
import {ArcFilterSet} from "metadata/filterset/ArcFilterSet";

/**
 * Change basic metadata like name and label.
 */
export class ArcFilterSetInfoChange implements ArcMetadataChange<ArcFilterSet> {

    constructor(
        public readonly infoChanges: AssetProps
    ) {
    }

    apply(metadata: ArcFilterSet): Tuple<ArcFilterSet, ArcMetadataChange<ArcFilterSet>> {
        const originals = Object.keys(this.infoChanges).reduce(
            (acc: AssetProps, key: keyof AssetProps) => {
                acc[key] = metadata[key];
                return acc;
            },
            {}
        );

        return new Tuple(
            metadata.with(this.infoChanges), new ArcFilterSetInfoChange(originals)
        );
    }

    describe(): string {
        return `Changed label to ${this.infoChanges.label}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}