import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraphNodeType} from 'metadata/hypergraph/nodes/HyperGraphNodeType';
import {HyperGraphRequestSerialized} from 'metadata/hypergraph/HyperGraphTypes';


/**
 * Find the next best groupings to explore given a set of data points of interest and prior query results.
 *
 * @author zuyezheng
 */
export class NextBestGroupings implements HyperGraphRequest {

    constructor(
        public readonly parent: string,
        private readonly dataset: ArcDataset,
        // total number of groupings to suggest for replacement or addition
        public readonly n: number
    ) {}

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.NEXT_BEST_GROUPINGS;
    }

    get operation(): HyperGraphNodeOperation {
        return HyperGraphNodeOperation.DISCOVERY;
    }

    label(graph: HyperGraph): string {
        return 'Finding next set of groupings to explore.';
    }

    get ommers(): string[] {
        return [this.parent];
    }

    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            parent: this.parent,
            n: this.n
        };
    }

    static fromJSON(json: HyperGraphRequestSerialized, dataset: ArcDataset): NextBestGroupings {
        return new NextBestGroupings(json.parent, dataset, json.n);
    }

}