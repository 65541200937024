import {Config} from "services/Config";

/**
 * We may want to log stuff for internal development, debugging and test that we don't want visible for customers. This
 * uses the environment to figure out what to do.
 *
 * To enable logs in production, set `window.debugMode = true`.
 *
 * @author zuyezheng
 */
export class LogService {

    constructor(config: Config) {
        if (!config.isTest && window.location.hostname !== 'localhost') {
            this.configureForProduction();
        }
    }

    /**
     * Hijack console logging for production.
     */
    private configureForProduction() {
        const buildLogger = (originalLogger: (...args: any[]) => void) => {
            return (...args: any[]) => {
                // @ts-ignore
                if (window.debugMode === true) {
                    originalLogger(...args);
                }
            }
        };
        console.log = buildLogger(console.log);
        console.warn = buildLogger(console.warn);
        console.error = buildLogger(console.error);
    }

    /**
     * Log function that takes a constructor to log the class name with the arguments.
     */
    log(clazz: { name: string }, ...args: any[]) {
        console.log(`[hyperarc-${clazz.name}] ${args.join(',')}`);
    }

    /**
     * Log function that takes a constructor to log the class name with the arguments.
     */
    warn(clazz: { name: string }, ...args: any[]) {
        console.warn(`[hyperarc-${clazz.name}] ${args.join(',')}`);
    }

    /**
     * Log function that takes a constructor to log the class name with the arguments.
     */
    error(clazz: { name: string }, ...args: any[]) {
        console.error(`[hyperarc-${clazz.name}] ${args.join(',')}`);
    }

}