import * as React from "react";
import {ChangeEvent, FunctionComponent, useCallback, useContext, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import {ServiceProvider} from 'services/ServiceProvider';
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {FontSizes} from "app/components/StyleVariables";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import {MetadataService} from "services/MetadataService";
import {AssetType} from "metadata/AssetType";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {FilterSetValue} from "metadata/query/filterclause/FilterClause";
import Button from "@mui/material/Button";
import {LinkService} from "services/LinkService";
import {useHistory} from "react-router-dom";
import {Optional} from "common/Optional";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import debounce from "lodash/debounce";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {UserContext} from "app/UserContext";
import {FilterSetFilterClause} from "metadata/query/filterclause/FilterSetFilterClause";

type Props = {
    arcqlBundle: ArcQLBundle;
    filter: FilterSetFilterClause;
    ordinal: number
    selected: FilterSetValue
    onSelectedChange: (selected: FilterSetValue) => void
    onClose: (change: Optional<EditorFilterChange>) => void
};

const PAGE_SIZE: number = 20;

export const DimensionFilterSetSearch: FunctionComponent<Props> = (props: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchParams, setSearchParams] = useState<AssetSearchParams>(
        new AssetSearchParams(
            PAGE_SIZE,
            searchTerm,
            [AssetType.FILTER_SET]
        )
    );
    const userContext = useContext(UserContext);

    const history = useHistory();

    const search = (params: AssetSearchParams) => {
        return ServiceProvider.get(MetadataService).assetSearch(params);
    };

    const updateSearchParams = useCallback(debounce(
        (searchTerm: string) => {
            setSearchParams(new AssetSearchParams(PAGE_SIZE, searchTerm, [AssetType.FILTER_SET]));
        },
        500
    ), []);

    const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        updateSearchParams(newSearchTerm);
    };

    const onSelect = (filterSetAsset: AssetSearchResult) => {
        // if the selected filterset is the same as the one already selected, treat it as a deselection
        if (props.selected && props.selected.fullyQualifiedName === filterSetAsset.fullyQualifiedName) {
            props.onSelectedChange(null);
            return;
        }

        props.onSelectedChange(filterSetAsset);
    };

    return <>
        <S.Controls>
            <S.SearchInput
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                }}
                value={searchTerm}
                onChange={onSearchTermChange}
                size='small'
                fullWidth
                autoComplete='off'
            />
            <S.CreateFilterSetButton
                variant="outlined"
                onClick={
                    () => {
                        ServiceProvider.get(LinkService).filterset().then(link => history.push(link));
                        props.onClose(Optional.none());
                    }
                }
                disabled={userContext.user.isNone}
            >
                Create Filter Set
            </S.CreateFilterSetButton>
        </S.Controls>
        <S.ResultsContainer>
            <AssetSearchResultsTable
                searchParams={searchParams}
                onSelect={onSelect}
                rowType={SearchResultsRowType.SIMPLE_LIST_ITEM}
                onSearch={search}
                bordered={true}
                disableAutoHeight={true}
                selectedRows={props.selected ? [props.selected] : []}
                bubbleUpSelected={true}
            />
        </S.ResultsContainer>
    </>;

};

const S = {
    Controls: styled.div`
        display: flex;
    `,
    SearchInput: styled(TextField)`
        width: 250px;

        input {
            font-size: ${FontSizes.small};
        }
    `,
    CreateFilterSetButton: styled(Button)`
        flex-grow: 1;
        margin-left: 16px;
        text-transform: none;
    `,
    ResultsContainer: styled.div`
        margin-top: 8px;
        width: 100%;
        height: 360px;
    `
};