import {ArcQLFilters} from "metadata/query/ArcQLFilters";
import {Optional} from "common/Optional";
import {Tuple} from "common/Tuple";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import RangeValuesArcQLRequest from "services/RangeValuesArcQLRequest";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";


const GREATER_OPERATORS = new Set(
    [FilterOperator.EQUAL, FilterOperator.GREATER_THAN, FilterOperator.GREATER_THAN_EQUAL]
);

const LESSER_OPERATORS = new Set(
    [FilterOperator.LESS_THAN, FilterOperator.LESS_THAN_EQUAL]
);

const MIN_BOUND = 0;
const MAX_BOUND = 1;

// measure specific operators
export const OPERATORS = [
    FilterOperator.BETWEEN,
    FilterOperator.EQUAL,
    FilterOperator.GREATER_THAN,
    FilterOperator.GREATER_THAN_EQUAL,
    FilterOperator.LESS_THAN,
    FilterOperator.LESS_THAN_EQUAL,
];

export class MeasureFilterHelper {

    /**
     * Build the query request to query the range of a numeric field.
     */
    static rangeValuesRequest(arcqlBundle: ArcQLBundle, filter: LiteralsFilterClause, ordinal: number): RangeValuesArcQLRequest {
        return new RangeValuesArcQLRequest(
            arcqlBundle.dataset.fqn.toString(),
            filter.column,
            arcqlBundle.arcql.filters.hasExpression ?
                // if there's a filter expression, scoped filters gets tricky
                Optional.of(ArcQLFilters.empty(false)) :
                Optional.of(ArcQLFilters.ands(
                    arcqlBundle.arcql.filters.clauses.filter((_: FilterClause, o: number) => ordinal !== o),
                    false
                ))
        );
    }

    /**
     * Return the range of returned from the bounds query.
     */
    static range(response: ArcQLResponse): Tuple<number, number> {
        return Tuple.of(response.result.values('min')[0], response.result.values('max')[0]);
    }

    /**
     * Handle operator changes and return the new range values.
     */
    static changeBounds<T>(
        oldOperator: FilterOperator,
        newOperator: FilterOperator,
        value: T[],
        // optional range to use when changing to a between operator
        range: Optional<Tuple<T, T>>,
    ): T[] {
        return Optional.map(() => {
            if (newOperator === FilterOperator.BETWEEN) {
                if (GREATER_OPERATORS.has(oldOperator)) {
                    return [
                        value[MIN_BOUND],
                        range.map(r => r.right).getOr(value[MIN_BOUND])
                    ];
                } else {
                    return [
                        range.map(r => r.left).getOr(value[MIN_BOUND]),
                        value[MIN_BOUND]
                    ];
                }
            } else if (GREATER_OPERATORS.has(newOperator)) {
                // ensure only single value since single bound
                return [value[MIN_BOUND]];
            } else if (LESSER_OPERATORS.has(newOperator)) {
                if (oldOperator === FilterOperator.BETWEEN) {
                    return [value[MAX_BOUND]];
                } else {
                    // ensure only single value since single bound
                    return [value[MIN_BOUND]];
                }
            }

            return null;
        }).getOr(value);
    }

    /**
     * Process a change to the input value of a bound optionally sanitized to a given range.
     */
    static processBoundInput(
        index: number,
        input: string,
        value: number[],
        range: Optional<Tuple<number, number>>
    ): number[] {
        const sanitized = range.map<any>(r => {
            const v = parseFloat(input);
            if (isNaN(v)) {
                return index === MIN_BOUND ? r.left : r.right;
            } else {
                return v;
            }
        }).getOr(input);

        const newValues = value.slice();
        newValues[index] = sanitized;
        return newValues;
    }

}