import * as React from "react";
import {ChangeEvent, FunctionComponent, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {Optional} from "common/Optional";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {ServiceProvider} from "services/ServiceProvider";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import {ErrorCode} from "services/ApiResponse";
import {DialogS} from "app/DialogS";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {Alert} from "metadata/trend/Alert";
import {TriggerCondition} from "metadata/trend/TriggerCondition";
import {RowsSelectorType} from "metadata/trend/RowsSelectorType";
import Select from "@mui/material/Select";
import {TrendsService} from "services/TrendsService";
import MenuItem from "@mui/material/MenuItem";
import {AlertTriggerConditionForm} from "app/components/trend/AlertTriggerConditionForm";
import {ThresholdCondition} from "metadata/trend/ThresholdCondition";


type Props = {
    trend: ArcTrend
    // if present, editing an existing alert
    alert: Optional<Alert>
    onCancel: () => void
    onSave: (savedAlert: Alert) => void
}

/**
 * Dialog for creating or editing an alert.
 */
export const SaveAlertDialog: FunctionComponent<Props> = (props: Props) => {

    const [label, setLabel] = useState<string>(props.alert.map(a => a.label).getOr(''));
    const [field, setField] = useState<string>(props.alert.map(a => a.field).getOr(props.trend.arcQL.fields.first.as));
    const [description, setDescription] = useState<string>(
        props.alert.map(a => a.description).getOr('')
    );
    const [triggerCondition, setTriggerCondition] = useState<TriggerCondition>(
        props.alert.map(a => a.triggerCondition).getOr(ThresholdCondition.default())
    );
    const [rowsSelector, setRowsSelector] = useState<RowsSelectorType>(
        props.alert.map(a => a.rowsSelector).getOr(RowsSelectorType.ALL)
    );

    const [saveErrors, setSaveErrors] = useState<Optional<Map<string, string>>>(Optional.none());

    const isAbsent = (s: string): boolean => {
        return Optional.string(s).map(_ => false).getOr(true);
    };

    const onSave = () => {
        props.alert.match(
            // else do patch
            alert => {
                ServiceProvider.get(TrendsService).patchAlert(
                    props.trend.fqn,
                    alert.with({label, description, field, triggerCondition, rowsSelector})
                ).then(response => response.match(
                    alert => {
                        ServiceProvider.get(NotificationsService).publish(
                            'saveAlert', NotificationSeverity.SUCCESS, `Successfully edited Alert "${alert.label}"!`
                        );
                        props.onSave(alert);
                    },
                    error => {
                        if (error.errorCode === ErrorCode.INVALID_FIELDS) {
                            setSaveErrors(error.fieldErrors);
                            return;
                        }
                        ServiceProvider.get(NotificationsService).publish(
                            'saveAlert', NotificationSeverity.ERROR, `Alert edit failed: ${error.prettyPrint()}`
                        );
                    }
                ));
            },
            // if no existing alert, do create
            () => {
                ServiceProvider.get(TrendsService).createAlert(
                    props.trend.fqn,
                    Alert.forCreation(label, description, field, triggerCondition, rowsSelector)
                ).then(response => response.match(
                    alert => {
                        ServiceProvider.get(NotificationsService).publish(
                            'saveAlert', NotificationSeverity.SUCCESS, `Successfully created Alert "${alert.label}"!`
                        );
                        props.onSave(alert);
                    },
                    error => {
                        if (error.errorCode === ErrorCode.INVALID_FIELDS) {
                            setSaveErrors(error.fieldErrors);
                            return;
                        }
                        ServiceProvider.get(NotificationsService).publish(
                            'saveAlert', NotificationSeverity.ERROR, `Alert creation failed: ${error.prettyPrint()}`
                        );
                    }
                ));
            }
        );
    };

    return <Dialog
        open={true}
        onClose={props.onCancel}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        <DialogS.Title> {props.alert.isPresent ? `Editing Alert: ${props.alert.get().label}` : 'Create a New Alert'}</DialogS.Title>
        <DialogS.Content dividers>
            <DialogS.Body>
                <DialogS.SelectorRow>
                    <FormControl fullWidth>
                        <FormLabel id="name-label" sx={{mb: 0.5}}>Name</FormLabel>
                        <TextField
                            autoFocus
                            margin="none"
                            size="small"
                            id="label"
                            aria-labelledby="name-label"
                            placeholder="Enter the name for the Alert"
                            value={label}
                            error={saveErrors.map(errors => errors.has("label")).getOr(false)}
                            helperText={saveErrors.map(errors => errors.get("label")).getOr(null)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
                            sx={{
                                width: "280px"
                            }}
                        />
                    </FormControl>
                </DialogS.SelectorRow>
                <DialogS.InputRow>
                    <FormControl fullWidth>
                        <FormLabel id="description-label" sx={{mb: 0.5}}>Description</FormLabel>
                        <TextField
                            margin="none"
                            size="small"
                            id="description"
                            aria-labelledby="description-label"
                            placeholder="Optional description"
                            value={description}
                            error={saveErrors.map(errors => errors.has("description")).getOr(false)}
                            helperText={saveErrors.map(errors => errors.get("description")).getOr(null)}
                            fullWidth={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                        />
                    </FormControl>
                </DialogS.InputRow>
                <DialogS.SelectorRow>
                    <FormControl>
                        <FormLabel id="field-label" sx={{mb: 0.5}}>Field</FormLabel>
                        <Select
                            labelId="field-label"
                            id="field-select"
                            value={field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setField(e.target.value)}
                            size="small"
                        >
                            {
                                props.trend.arcQL.fields.map(f => <MenuItem key={f.as} value={f.as}>{f.as}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </DialogS.SelectorRow>
                <AlertTriggerConditionForm currentCondition={triggerCondition} onConditionChange={setTriggerCondition}/>
                <DialogS.SelectorRow>
                    <FormControl>
                        <FormLabel id="rows-selector-label" sx={{mb: 0.5}}>Results Selector</FormLabel>
                        <Select
                            labelId="rows-selector-label"
                            id="rows-selector-select"
                            value={rowsSelector.name}
                            onChange={
                                (e: ChangeEvent<HTMLInputElement>) =>
                                    setRowsSelector(RowsSelectorType.get<RowsSelectorType>(e.target.value))
                            }
                            size="small"
                        >
                            {
                                RowsSelectorType.enums<RowsSelectorType>().map(t =>
                                    <MenuItem key={t.name} value={t.name}>{t.label}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </DialogS.SelectorRow>
            </DialogS.Body>
        </DialogS.Content>
        <DialogS.Actions>
            <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
            <Button
                variant="contained"
                onClick={onSave}
                disabled={isAbsent(label)}
            >
                Save
            </Button>
        </DialogS.Actions>
    </Dialog>;
};