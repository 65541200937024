import React, {FunctionComponent} from "react";
import {ExpandButton} from "app/query/components/ExpandButton";
import {FieldsPickerSection} from "app/query/components/FieldsPickerSection";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import {FieldCard} from "app/query/components/FieldCard";
import {Column} from "metadata/Column";

export type Props = {
    className?: string,
    field: Column,
    section: FieldsPickerSection,
    draggable: boolean,
    // if defined, show selected or unselected state
    isFieldSelected?: boolean,
    // if defined, has additional field setting view option and will invoke callback
    onFieldSettingView?: (column: Column) => void
    isExpanded?: boolean,
    onToggleExpand?: (fieldName: string) => void
    onClick?: (field: Column) => void
    onDoubleClick?: (field: Column) => void
    onDragStart?: (event: React.DragEvent) => void
};

/**
 * FieldItem corresponds to a list item entry in a FieldPicker section, encapsulating logic like field expansion.
 */
export const SectionFieldItem: FunctionComponent<Props> = (props: Props) => {
    const renderChildren = () => {
        if (!props.field.hasChildren || !props.isExpanded) {
            return null;
        }

        return (
            <S.ChildFieldContainer>
                {
                    props.field.children.map((column) =>
                        <S.ChildFieldItem key={column.name} dense>
                            <FieldCard
                                key={`${column.analyticsType}.${column.name}`}
                                field={column}
                                draggable={props.draggable}
                                isSelected={props.isFieldSelected}
                                onClick={props.onClick}
                                onDoubleClick={props.onDoubleClick}
                                onDragStart={props.onDragStart}
                                onFieldSettingView={props.onFieldSettingView}
                            />
                        </S.ChildFieldItem>
                    )
                }
            </S.ChildFieldContainer>
        );
    };

    const fieldLeftMargin: string = (() => {
        if (props.section.hasExpandableFields) {
            return props.field.hasChildren ?
                S.RootFieldWithChildrenLeftMargin :
                S.RootFieldInExpandableFieldSectionLeftMargin;
        } else {
            return S.DefaultRootFieldLeftMargin;
        }
    })();

    return (
        <div className={props.className}>
            <S.FieldItem dense>
                <S.FieldLeftColumn marginLeft={fieldLeftMargin}>
                    {props.field.hasChildren && <ExpandButton
                        expanded={props.isExpanded}
                        onClick={() => props.onToggleExpand(props.field.name)}
                    />}
                </S.FieldLeftColumn>
                <FieldCard
                    key={`${props.field.analyticsType}.${props.field.name}`}
                    field={props.field}
                    draggable={props.draggable}
                    isSelected={props.isFieldSelected}
                    onClick={props.onClick}
                    onDoubleClick={props.onDoubleClick}
                    onDragStart={props.onDragStart}
                    onFieldSettingView={props.onFieldSettingView}
                />
            </S.FieldItem>
            {renderChildren()}
        </div>
    );
};


const S = {
    FieldItem: styled(ListItem)`
        display: flex;
        padding: 0 10px 0 0;
        margin-bottom: 4px;        
    `,
    FieldLeftColumn: styled.div<{ marginLeft: string | number }>`
      margin-left: ${({marginLeft}) => marginLeft};
    `,
    ChildFieldContainer: styled.div`
        display: flex;
        flex-direction: column;
        /* Want left border directly aligned at center of expand button: 
            4px margin left + 24px container of button,
            therefore center: 16px, but subtract 1 due to border width of 2
        */
        margin-left: 15px;
        border-left: 2px solid rgba(0, 0, 0, 0.26);
        margin-bottom: 4px;
    `,
    ChildFieldItem: styled(ListItem)`
        display: flex;
        /* Align nesting so that child field starts under parent child's dataType icon. */
        padding: 0 10px 0 31px;
        margin-bottom: 4px;
        /* Do not have bottom margin for last item as that's done in container. 
           Needed for proper border length, else will extend to include the margin.
        */
        &:last-of-type {
            margin-bottom: 0;
        }
    `,
    DefaultRootFieldLeftMargin: "10px",
    RootFieldWithChildrenLeftMargin: "4px",
    RootFieldInExpandableFieldSectionLeftMargin: "28px"
};
