import * as React from 'react';
import {HashRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';

import {LicenseInfo} from '@mui/x-license-pro/utils/licenseInfo';
import * as Sentry from '@sentry/browser';
import {BrowserTracing} from '@sentry/tracing';
import {Auth0Provider} from '@auth0/auth0-react';
import {css, Global} from '@emotion/react';

// @ts-ignore
import FusionCharts from 'fusioncharts/core';

import {AppContainer} from 'app/AppContainer';
import {Colors} from 'app/components/StyleVariables';
import {Config} from 'services/Config';
import {ServiceProvider} from 'services/ServiceProvider';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {DefaultTheme} from 'DefaultTheme';

// license some stuff
// @ts-ignore
FusionCharts.options.license({
    key: 'OuB3exvA8C7C3E6B5C5G3G4J4B8B1B4B1udnE4H1cgkA-13lB4A3C-7izA5C5B3D6F5F4A3E1A9D7C3F5A3E1A2E1mllC7E2B4dD4F3H3yxoF4E2D3isbB6C1E3ycaC1G4B1B8B4A4B3D3B4H3A33fhhD8B1SG4lhJ-7A9C11A5veE3NA1A1sslE2D6G2F3H3J3C8A5D5A3F4E2D2H4z==',
    creditLabel: false,
});
LicenseInfo.setLicenseKey('61c616faccc0110cb6c49106fa234df5Tz03NzQzMyxFPTE3Mjk5MDgwMzUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


const theme = createTheme(DefaultTheme);
const config: Config = ServiceProvider.get(Config);

if (config.sentryDsn) {
    Sentry.init({
        dsn: config.sentryDsn,
        environment: config.environment,
        release: config.version,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.5,
        tunnel: config.sentryTunnelPath,
        ignoreErrors: [
            "AbortError",
            "403 (Forbidden)",
            "404 (Not Found)",
            "Login required"
        ]
    });
}

if (config.userAnalytics === "appcues") {
    const appcuesScript = document.createElement("script");
    appcuesScript.type = "text/javascript";
    appcuesScript.src = config.appcuesScript;
    appcuesScript.async = true;
    document.head.appendChild(appcuesScript);
}

const root = createRoot(document.getElementById('root'));

// @ts-ignore
const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
if (queryParams.has("verification_email")) {
    // Remove the query param from the url in the browser
    window.history.replaceState({}, document.title, "/");
}

root.render(
    <Auth0Provider
        domain={config.auth0Domain}
        clientId={config.auth0ClientId}
        redirectUri={window.location.origin}
        audience={config.auth0Audience}
        useRefreshTokens={true}
        cacheLocation={"localstorage"}
    >
        <HashRouter>
            <Global styles={css`
                @font-face {
                    font-family: 'NotoSans';
                    src: url('/static/fonts/NotoSans/NotoSans-Light.ttf');
                    font-weight: 300;
                    font-style: normal;
                }
                @font-face {
                    font-family: 'NotoSans';
                    src: url('/static/fonts/NotoSans/NotoSans-Regular.ttf');
                    font-weight: 400;
                    font-style: normal;
                }
                @font-face {
                    font-family: 'NotoSans';
                    src: url('/static/fonts/NotoSans/NotoSans-Medium.ttf');
                    font-weight: 500;
                    font-style: normal;
                }

                html {
                    width: 100%;
                    height: 100%;
                    font-family: 'NotoSans', sans-serif;
                    font-size: 14px;
                    color: ${Colors.textPrimary};

                    body {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        overflow: hidden;
                
                        #root {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            `}/>
            <ThemeProvider theme={theme}>
                <AppContainer queryParams={queryParams}/>
            </ThemeProvider>
        </HashRouter>
    </Auth0Provider>
);