import React from "react";
import styled from "@emotion/styled";
import {TrendS} from "app/dashboard/components/TrendS";
import {AssetIcon, IconStyling, StandardIconSize} from "app/components/icons/asset/AssetIcon";
import {AssetType} from "metadata/AssetType";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

export interface Props {
    queryLabel: string
    // callback when create trend button is clicked
    handleTrendCreate: () => void
    onClose: () => void
}

/**
 * Empty trend view of a (widget, dashboard) in Trends panel.
 */
export const EmptyTrendView = (props: Props) => {
    return (
        <TrendS.Container>
            <TrendS.Header>
                <TrendS.TrendIcon/>
                <TrendS.HeaderLabel>Trend Analysis For </TrendS.HeaderLabel>
                <TrendS.SubHeaderArcQL.Container>
                    <AssetIcon assetType={AssetType.ARCQL} iconStyling={IconStyling.OUTLINED_COLORED}
                               size={StandardIconSize.SMALL}/>
                    <TrendS.SubHeaderArcQL.Label> {props.queryLabel} </TrendS.SubHeaderArcQL.Label>
                </TrendS.SubHeaderArcQL.Container>
                <TrendS.ButtonGroup>
                    <TrendS.HeaderButton size="small" onClick={props.onClose}>
                        <CloseIcon/>
                    </TrendS.HeaderButton>
                </TrendS.ButtonGroup>
            </TrendS.Header>
            <S.CreateTrendContainer>
                <span>
                    Trend
                    '
                    <strong> {props.queryLabel} </strong>
                    '
                </span>
                <span>
                    to find patterns and spot anomalies over time.
                </span>
                <S.Button
                    variant="contained"
                    onClick={props.handleTrendCreate}
                >
                    Create Trend
                </S.Button>
            </S.CreateTrendContainer>
        </TrendS.Container>
    );
};

class S {

    static readonly CreateTrendContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 400px;
        overflow: auto;
        position: relative;

        strong {
            color: black;
        }
    `;

    static readonly Button = styled(Button)`
        margin-top: 20px;
        text-transform: none;
    `;

}