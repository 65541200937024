import DialogTitle from "@mui/material/DialogTitle";
import {Story} from "metadata/asset/story/Story";
import React, {useEffect, useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {StoryScene} from "metadata/asset/story/StoryScene";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import {Optional} from "common/Optional";
import {UrlLink} from "app/components/decoration/UrlLink";
import {LinkService} from "services/LinkService";
import {LoadingMask} from "app/components/decoration/LoadingMask";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";

export interface Props {
    // story to play
    story: Story
    onClose: () => void
}

/**
 * Play a given story by allowing user to navigate from scene to scene.
 *
 * @author zuyezheng
 */
export const StoryPlayer = (props: Props) => {

    const [scenes, setScenes] = useState<Optional<StoryScene[]>>(Optional.none());
    const [currentScene, setCurrentScene] = useState<number>(0);

    // load the scenes for the story
    useEffect(() => {
        ServiceProvider.get(MetadataService)
            .listScenes(props.story.fqn, true)
            .then(response => response.match(
                scenes => {
                    setScenes(Optional.some(scenes));
                    setCurrentScene(0);
                },
                error => {
                    setScenes(Optional.some([]));
                    ServiceProvider.get(NotificationsService).publish(
                        'storyPlayer', NotificationSeverity.ERROR, `Failed to load scenes: ${error.prettyPrint()}`
                    );
                }
            ));
    }, []);

    const sceneComponents = (scenes: StoryScene[]) => {
        if (scenes.length === 0) {
            return <></>;
        }

        const scene = scenes[currentScene];

        // see if we should show the previous or next buttons
        const previous = Optional.bool(currentScene > 0)
            .map(_ =>
                <Button variant="contained" onClick={() => setCurrentScene(currentScene - 1)}>
                    Previous: {scenes[currentScene - 1].label}
                </Button>
            )
            .nullable;
        const next = Optional.bool(currentScene < scenes.length - 1)
            .map(_ =>
                <Button variant="contained" onClick={() => setCurrentScene(currentScene + 1)}>
                    Next: {scenes[currentScene + 1].label}
                </Button>
            )
            .nullable;

        return <S.Scene>
            <S.Description>
                {props.story.description}
            </S.Description>
            <S.Image>
                <img src={scene.imageDataUrl} alt={scene.caption}/>
            </S.Image>
            <S.Controls>
                <S.ControlsLeft>
                    {previous}
                </S.ControlsLeft>
                <S.ControlsRight>
                    {next}
                </S.ControlsRight>
            </S.Controls>
            <S.Share>
                <S.ShareLabel>Embed this scene:</S.ShareLabel>
                <S.ShareUrl path={
                    ServiceProvider.get(LinkService)
                        .embedUrl(props.story.fqn, new Map([['scene', currentScene]]))
                }/>
            </S.Share>
        </S.Scene>
    };

    return <S.Dialog open={true} onClose={props.onClose}>
        <DialogTitle>
            {props.story.label}
        </DialogTitle>
        <DialogContent>
        {
            scenes.map(s => sceneComponents(s))
                .getOr(<LoadingMask containerDimensions={['200px', '200px']}/>)
        }
        </DialogContent>
    </S.Dialog>

};

class S {

    static Dialog = styled(Dialog)`
        .MuiPaper-root {
            max-width: 90%;
        }
    `;

    static Mask = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        background-color: rgba(0, 0, 0, .5);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1000;
    `;

    static Scene = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static Description = styled.div`
          
    `;

    static Image = styled.div`
        display: flex;
    `;

    static Controls = styled.div`
        display: flex;
        padding-top: 10px;
    `;

    static ControlsLeft = styled.div`
        flex: 1;
        display: flex;
        justify-content: start;
    `;

    static ControlsRight = styled.div`
        flex: 1;
        display: flex;
        justify-content: end;
    `;

    static Share = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    static ShareLabel = styled.div`
        padding-right: 8px;
    `;

    static ShareUrl = styled(UrlLink)`
        width: 600px;
    `;
    
}