import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {ArcQLFilters} from "metadata/query/ArcQLFilters";

/**
 * Request to query the distinct values of a dataset's field (notably Dimension).
 */
export default class DistinctValuesArcQLRequest {
    constructor(
        public readonly datasetFqn: string,
        public readonly field: string,
        public readonly term: string,
        public readonly filters: Optional<ArcQLFilters>,
        public readonly limit: number,
    ) {
    }

    toJSON(): JsonObject {
        return {
            dataset: this.datasetFqn,
            field: this.field,
            term: this.term,
            filters: this.filters.nullable,
            limit: this.limit
        };
    }
}