import {Enum} from "common/Enum";

/**
 * Enum for connection types.
 */
export class ConnectionType extends Enum {

    static readonly SNOWFLAKE = new this('snowflake', "Snowflake");
    static readonly BIG_QUERY = new this('bigquery', "Big Query");
    // this is to support "fake" connection a user has to hyperarc for creating personal datasets
    static readonly HYPERARC = new this('hyperarc', "Hyperarc",  true);

    private constructor(
        name: string,
        public readonly label: string,
        // if fake, connection is not actionable (e.g. can't be deleted or tested)
        public readonly isFake: boolean = false
    ) {
        super(name);
    }

}
ConnectionType.finalize();

