import {JsonObject} from "common/CommonTypes";
import {TriggerCondition, TriggerConditionType} from "metadata/trend/TriggerCondition";
import {ThresholdCondition, ThresholdConditionJson} from "metadata/trend/ThresholdCondition";
import {RangeCondition, RangeConditionJson} from "metadata/trend/RangeCondition";

/**
 * Factory for creating trigger conditions.
 */
export class TriggerConditionFactory {

    static fromJSON(json: JsonObject): TriggerCondition {
        const triggerType = TriggerConditionType.get(json['type']);
        switch (triggerType) {
            case TriggerConditionType.THRESHOLD:
                return ThresholdCondition.fromJSON(json as ThresholdConditionJson);
            case TriggerConditionType.RANGE:
                return RangeCondition.fromJSON(json as RangeConditionJson);
            default:
                throw new Error(`Unknown trigger type: ${triggerType}`);
        }
    }

}