import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {ArcQL} from "metadata/query/ArcQL";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {JsonObject} from "common/CommonTypes";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraph} from 'metadata/hypergraph/HyperGraph';
import {HyperGraphNodeType} from 'metadata/hypergraph/nodes/HyperGraphNodeType';
import {HyperGraphRequestSerialized} from 'metadata/hypergraph/HyperGraphTypes';

/**
 * Container for an explicit query that will be stored with the node.
 *
 * @author zuyezheng
 */
export class QueryContainer implements HyperGraphRequest {

    public readonly hypothesis: SimpleHypothesis;

    constructor(
        public readonly ref: QueryReference,
        public readonly query: ArcQL,
        public readonly operation: HyperGraphNodeOperation,
        hypothesis: HyperGraphNodeHypothesis,
        // if not final, user interactions might update this node vs creating a new one to avoid sprawl
        public readonly isFinal: boolean = true
    ) {
        // we don't really care about the specific hypothesis type, just the basics, this also avoids polymorphism
        // during deserialization
        this.hypothesis = new SimpleHypothesis(
            hypothesis.why,
            hypothesis.change,
            hypothesis.nullHypothesis,
            hypothesis.alternativeHypothesis
        );
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.QUERY_CONTAINER;
    }

    label(graph: HyperGraph): string {
        return this.hypothesis.why;
    }

    get parent(): string {
        return this.ref.parent;
    }

    get ommers(): string[] {
        return this.parent == null ? [] : [this.parent];
    }

    toJSON(): HyperGraphRequestSerialized {
        return {
            type: this.type.toJSON(),
            ref: this.ref,
            query: this.query.toJSON('query', true, false),
            operation: this.operation,
            hypothesis: this.hypothesis,
            isFinal: this.isFinal
        };
    }

    static fromJSON(json: HyperGraphRequestSerialized): QueryContainer {
        return new QueryContainer(
            QueryReference.fromJSON(json.ref),
            ArcQL.fromJSON(json.query),
            HyperGraphNodeOperation.get(json.operation),
            new SimpleHypothesis(
                json.hypothesis.why,
                json.hypothesis.change,
                json.hypothesis.nullHypothesis,
                json.hypothesis.alternativeHypothesis
            ),
            json.isFinal
        );
    }

}

/**
 * Reference to a parent and possible i-th query variant if derived from one.
 */
export class QueryReference {

    static empty(): QueryReference {
        return new QueryReference(null);
    }

    constructor(
        public readonly parent: string,
        // i-th query variant from the parent
        public readonly i?: number
    ) {}

    static fromJSON(json: JsonObject): QueryReference {
        return new QueryReference(json.parent, json.i);
    }

}
