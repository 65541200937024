import React, {FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import {HyperGraphSearchParams} from "metadata/search/HyperGraphSearchParams";
import {Optional} from "common/Optional";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import {CommonS} from "app/components/CommonS";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";
import {ProcessFlowIcon} from "app/query/hypergraph/nodes/ProcessFlowIcon";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";
import {HyperGraphSearchResultsUtil, SortableNode} from "app/home/HyperGraphSearchResultsUtil";
import dayjs from "dayjs";
import {HyperGraphNodeSearchResult} from "app/components/search/HyperGraphNodeSearchResult";

type Props = {
    searchParams: HyperGraphSearchParams
    onSelect: (selected: NavigableSearchResult) => void
    onAccountBreadcrumb?: (account: string) => void
}

const COLUMNS_FN = (onAccountBreadcrumb: (account: string) => void) => {
    return [{
        'field': 'operation',
        'headerName': '',
        'width': 30,
        'renderCell': (params: GridRenderCellParams<HyperGraphNodeOperation>) =>
            <ProcessFlowIcon operation={params.value} isCompact={true}/>
    }, {
        'field': 'labelAndName',
        'headerName': 'Node',
        'renderCell': (params: GridRenderCellParams<[string, string]>) =>
            <S.LabelAndName>
                <div className="label">{params.value[0]}</div>
                <CommonS.MinorText>{`in ${params.value[1]}`}</CommonS.MinorText>
            </S.LabelAndName>,
        'flex': 1
    }, {
        'field': 'projectLabel',
        'headerName': 'Project',
        'flex': 1,
    }, {
        'field': 'projectOwner',
        'headerName': 'Owner',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<string>) =>
            <AccountBreadCrumbInResultRow
                text={params.value}
                onClick={() => onAccountBreadcrumb(params.value)}
            />
    }, {
        'field': 'createdBy',
        'headerName': 'Created By',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<string>) =>
            <AccountBreadCrumbInResultRow
                text={params.value}
                onClick={() => onAccountBreadcrumb(params.value)}
            />
    }, {
        'field': 'lastUpdatedOn',
        'headerName': 'Last Updated On ',
        'flex': 1
    }];
};

const ROW_BUILDER_FN = (row: SortableNode) => {
    return {
        'id': row.node.id,
        'operation': row.node.request.operation,
        'labelAndName': [row.node.label(null), row.query.name],
        'projectLabel': row.query.folderLabel,
        'projectOwner': row.query.folderOwner,
        'createdBy': row.query.createdBy,
        'lastUpdatedOn': dayjs(row.query.lastUpdatedOn).format("MMM D, YYYY"),
        'result': row
    };
};

/**
 * Table display of hypergraph node search results
 */
export const HyperGraphNodeSearchResultsTable: FunctionComponent<Props> = (props: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchResults, setSearchResults] = useState<Optional<SortableNode[]>>(Optional.none());

    useEffect(() => {
        setIsLoading(true);

        const controller = new AbortController();

        ServiceProvider.get(MetadataService).hyperGraphSearch(props.searchParams, controller.signal)
            .then(response => {
                setIsLoading(false);
                response.forEach(result => {
                    setSearchResults(Optional.of(HyperGraphSearchResultsUtil.sortSearchResultsBySimilarity(result)));
                });
            });

        return () => controller.abort();
    }, [props.searchParams]);

    const onRowClick = (params: GridRowParams) => {
        props.onSelect(new HyperGraphNodeSearchResult(params.row.result.query, params.row.id));
    };

    return <S.ResultTable
        loading={isLoading}
        components={{
            DetailPanelExpandIcon: ExpandMore,
            DetailPanelCollapseIcon: ExpandLess,
        }}
        columns={COLUMNS_FN(props.onAccountBreadcrumb)}
        rows={
            searchResults
                .map(results => results.map(ROW_BUILDER_FN))
                .getOr([])
        }
        onRowClick={onRowClick}

        pagination
        pageSize={5}
        paginationMode="client"

        autoHeight={true}
        density="standard"
        disableSelectionOnClick
        disableColumnMenu
    />;
};

class S {

    static readonly ResultTable = styled(DataGridPro)({
        'border': '0'
    });

    static readonly LabelAndName = styled.div`
        display: flex;
        flex-direction: column;
    `;
}