import * as React from "react";
import {FunctionComponent, useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Link from "@mui/material/Link";
import {EditorFilterChange, FilterEditor, SelectedFilter} from "app/query/filters/FilterEditor";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {Optional} from "common/Optional";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {Pill} from "app/query/components/Pill";
import {PanelS} from "app/query/panels/PanelS";
import {TypedMenu} from "app/components/TypedMenu";
import {NewFilterSelector} from "app/query/components/NewFilterSelector";
import {Column} from "metadata/Column";

type Props = {
    className?: string
    arcqlBundle: ArcQLBundle
    drillClauses: Optional<FilterClause[]>
    isAggregate: boolean
    delegate: QueryBuilderDelegate
    onDragStart: (event: React.DragEvent) => void
    onExpressionClick: (el: Element) => void
    onDeleteFilter: (ordinal: number, isAggregate: boolean) => void
}

/**
 * A section within the filter panel for pre or post aggregation filters.
 *
 * @author zuyezheng
 */
export const FilterPanelContent: FunctionComponent<Props> = (props: Props) => {

    const [selectedFilter, setSelectedFilter] = useState<Optional<SelectedFilter>>(Optional.none());
    const [selectedSuffix, setSelectedSuffix] = useState<Optional<SelectedFilter>>(Optional.none());
    const [addFilterTarget, setAddFilterTarget] = useState<Optional<Element>>(Optional.none());
    const newFilterRef = useRef<Optional<FilterClause>>(Optional.none());

    const filters = props.arcqlBundle.arcql.filtersFor(props.isAggregate);

    // when a new filter is added, mark it as selected
    useEffect(() => {
        if (newFilterRef.current.isPresent) {
            const newFilter = newFilterRef.current.get();
            const ordinal = filters.clauses.length - 1;
            const filterPill = document.querySelector(`[data-field-id='${getDndFieldId(ordinal)}']`);
            setSelectedFilter(
                Optional.some({
                    el: filterPill,
                    filter: newFilter,
                    ordinal: ordinal,
                    isAggregate: props.isAggregate
                })
            );
            newFilterRef.current = Optional.none();
        }
    }, [filters.clauses.length]);


    const filterLabel = (filter: FilterClause): string => {
        // use the dataset column label
        return filter.fieldsLabel(props.arcqlBundle.dataset);
    };

    const onAddFilter = (event: React.MouseEvent) => {
        setAddFilterTarget(Optional.some(event.currentTarget));
    };

    const onSelectFilter = (event: React.MouseEvent, filter: FilterClause, ordinal: number) => {
        setSelectedFilter(Optional.some({
            el: event.currentTarget,
            filter: filter,
            ordinal: ordinal,
            isAggregate: props.isAggregate
        }));
    };

    const onFilterChange = (change: Optional<EditorFilterChange>) => {
        change.forEach(c =>
            selectedFilter.map(filter =>
                props.delegate.modifyFilter(filter.ordinal, props.isAggregate, c)
            )
        );
        setSelectedFilter(Optional.none());
    };

    const onCloseAddFilter = () => {
        setAddFilterTarget(Optional.none());
    };

    const onDrillIn = () => {
        props.drillClauses.forEach(
            clauses => props.delegate.mergeFilters(clauses)
        );
    };

    const getDndFieldId = (ordinal: number): string => {
        const source = props.isAggregate ?
            QueryDnDSource.AGGREGATE_FILTER.of(ordinal.toString()) :
            QueryDnDSource.FILTER.of(ordinal.toString());

        return source.serialize;
    };

    const onClickSuffix = (event: React.MouseEvent, filter: FilterClause, ordinal: number) => {
        setSelectedSuffix(Optional.some({
            el: event.currentTarget,
            filter: filter,
            ordinal: ordinal,
            isAggregate: props.isAggregate
        }));
    };

    const onClickAction = (action: string) => {
        selectedSuffix.forEach(selected => {
            if (action === 'Delete') {
                props.onDeleteFilter(selected.ordinal, selected.isAggregate);
            }
        });

        setSelectedSuffix(Optional.none());
    };

    const onCloseActions = () => {
        setSelectedSuffix(Optional.none());
    };

    const onSelectedField = (col: Column) => {
        newFilterRef.current = Optional.of(
            props.delegate.startFilter(col.name)
        );
        onCloseAddFilter();
    };

    return <div className={props.className}>
        {
            filters.hasExpression && <S.Expression
                onClick={(event) => props.onExpressionClick(event.currentTarget)}
            >
                {filters.expression}
            </S.Expression>
        }
        <PanelS.Fields>{
            Array.from(filters.clauses)
                .map((filter: FilterClause, ordinal: number) =>
                    <S.FilterPill
                        key={`${ordinal}_${filter.fields.join('_')}`}
                        prefix={filters.hasExpression ? (ordinal + 1).toString() : undefined}
                        label={filterLabel(filter)}
                        labelTooltip={filterLabel(filter)}
                        details={filter.description(true)}
                        detailsTooltip={filter.description(false)}
                        isLabelEditable={false}
                        onClickLabel={event => onSelectFilter(event, filter, ordinal)}
                        onClickSuffix={event => onClickSuffix(event, filter, ordinal)}
                        onDragStart={props.onDragStart}
                        dataFieldId={getDndFieldId(ordinal)}
                    />
                )
        }</PanelS.Fields>
        {
            !props.isAggregate && <S.Add onClick={onAddFilter}>
                <AddOutlinedIcon/>
            </S.Add>
        }
        {
            props.drillClauses.map(() =>
                <S.DrillInContainer onClick={onDrillIn}>
                    <Link underline="always">
                        Drill into selection.
                    </Link>
                </S.DrillInContainer>
            ).nullable
        }
        {
            selectedFilter.map(selected =>
                <FilterEditor
                    selected={selected}
                    anchorOrigin={{
                        vertical: 'bottom', horizontal: 'left',
                    }}
                    arcqlBundle={props.arcqlBundle}
                    onClose={onFilterChange}
                />
            ).nullable
        }
        {
            selectedSuffix.map(selected =>
                <TypedMenu
                    el={selected.el}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    items={[['Delete']]}
                    itemId={item => item}
                    itemLabel={item => item}
                    onSelect={onClickAction}
                    onClose={onCloseActions}
                />
            ).getOr(<></>)
        }
        {
            addFilterTarget.map(element =>
                <NewFilterSelector
                    el={element}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    arcQLBundle={props.arcqlBundle}
                    onSelect={onSelectedField}
                    onClose={onCloseAddFilter}
                />
            ).nullable
        }
    </div>;

};

class S {

    static readonly FilterPill = styled(Pill)`
        margin-bottom: 6px;

        .MuiButton-root {
            border-color: ${Colors.borderGrey};

            &.prefix {
                width: unset;
                flex: 1;
                padding: 12px;
                background-color: ${Colors.filterPrefix};

                &:hover {
                    background-color: ${Colors.filterPrefix};
                    border-color: ${Colors.borderGrey};
                }
            }

            &.suffix, &.main {
                background-color: ${Colors.filterBackground};

                &:hover {
                    border-color: ${Colors.borderGrey};
                    background-color: ${Colors.filterHover};
                }
            }
        }
    `;

    static readonly Expression = styled.div`
        border-radius: 4px;
        border: 1px solid ${Colors.borderGrey};
        font-size: ${FontSizes.xSmall};
        padding: 8px 14px;
        margin-bottom: 6px;
        cursor: pointer;
        
        :hover {
            background-color: ${Colors.filterHover};
        }
    `;

    static readonly DrillInContainer = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;

        a {
            font-size: ${FontSizes.xSmall};
            cursor: pointer;
        }
    `;

    static readonly Add = styled(PanelS.Add)`
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23BDBDBDFF' stroke-width='2' stroke-dasharray='3%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

        svg {
            color: ${Colors.filterGrey};
        }
    `;
}
