import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {FolderResult} from "metadata/project/FolderResult";
import {SearchResponse} from "metadata/search/SearchResponse";

export class FoldersSearchResponse implements SearchResponse<FolderResult> {

    static fromJSON(json: JsonObject): FoldersSearchResponse {
        return new FoldersSearchResponse(
            json['results'].map(FolderResult.fromJSON),
            json['total']
        );
    }

    constructor(
        public readonly results: FolderResult[],
        public readonly total: number
    ) {
    }

    get optional(): Optional<FoldersSearchResponse> {
        if (this.results.length === 0) {
            return Optional.none();
        } else {
            return Optional.some(this);
        }
    }

}

