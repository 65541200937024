import {FQN} from "common/FQN";
import {AssetType} from "metadata/AssetType";
import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetConfig} from "metadata/dataset/DatasetConfig";
import {DatasetConfigFactory} from "metadata/dataset/DatasetConfigFactory";
import {JsonObject} from "common/CommonTypes";

/**
 * Expected JSON for DatasetV2
 */
export type DatasetListItemJson = {
    id: string;
    name: string;
    label: string;
    description: string;
    type: string;
    config: JsonObject;
    version: number;
    fqn: string;
    folderId: string;
    parentId: string;
    createdBy: string;
    createdOn: string;
};

/**
 * Simplified Dataset v2 metadata information.
 */
export class DatasetListItem {

    /**
     * Constructs an instance of Dataset from a JSON object.
     */
    static fromJSON(json: DatasetListItemJson): DatasetListItem {
        const datasetType = DatasetType.get<DatasetType>(json.type);
        return new DatasetListItem(
            json.id,
            json.name,
            json.label,
            json.description,
            datasetType,
            DatasetConfigFactory.fromJSON(datasetType, json.config),
            json.version,
            FQN.parse(json.fqn),
            json.folderId,
            json.parentId,
            json.createdBy,
            json.createdOn
        );
    }

    private constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        public readonly type: DatasetType,
        public readonly config: DatasetConfig,
        public readonly version: number = -1,
        public readonly fqn: FQN,
        public readonly folderId: string,
        public readonly parentId: string,
        public readonly createdBy: string,
        public readonly createdOn: string
    ) {
    }

    get assetType(): AssetType {
        return AssetType.DATASET_V2;
    }
}