import * as React from "react"
import {SVGProps} from "react"

const TimeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        {...props}
    >
        <path
            d="M6.994 1A5.997 5.997 0 0 0 1 7c0 3.312 2.682 6 5.994 6A6.003 6.003 0 0 0 13 7c0-3.312-2.688-6-6.006-6ZM7 11.8A4.799 4.799 0 0 1 2.2 7c0-2.652 2.148-4.8 4.8-4.8 2.652 0 4.8 2.148 4.8 4.8 0 2.652-2.148 4.8-4.8 4.8ZM7.3 4h-.9v3.6l3.15 1.89.45-.738L7.3 7.15V4Z"
        />
    </svg>
)
export default TimeIcon
