import {ArcActor} from "engine/actor/ArcActor";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {FacetingEventFilter} from "engine/FacetingMessage";
import {ArcEvent} from "engine/ArcEvent";
import {Optional} from "common/Optional";
import {Json} from "common/CommonTypes";
import {Either} from "common/Either";
import {ExternalStateDelegate} from "app/components/ExternalStateDelegate";
import {FilterClause} from "metadata/query/filterclause/FilterClause";
import {References} from "metadata/References";
import {ExternalFilterState} from "metadata/ExternalFilterState";
import {FilterClauseFactory} from "metadata/query/filterclause/FilterClauseFactory";
import {FQN} from "common/FQN";

/**
 * Actor to handle drill ins from the UI and external state.
 *
 * @author zuyezheng
 */
export class DrillActor extends ArcActor implements ExternalStateDelegate<ExternalFilterState<FilterClause>> {

    private readonly facetingFilter: FacetingEventFilter;

    constructor(
        id: string,
        private readonly datasetFqn: FQN,
        private readonly onFacets: (facetClauses: Optional<FilterClause[]>) => void,
        private readonly mergeFilters: (clauses: FilterClause[]) => void
    ) {
        super(id);

        this.facetingFilter = new FacetingEventFilter(this.datasetFqn.toString());
    }

    eventFilters(): ArcEventFilter[] {
        return [this.facetingFilter];
    }

    notify(event: ArcEvent): void {
        this.facetingFilter.filter(event)
            .map(m => {
                const facetFilters = m.facets
                    .filter(f => !f.isEmpty)
                    .map(f => f.filterClause);

                this.onFacets(Optional.of(facetFilters.length > 0 ? facetFilters : null));
            });
    }

    applyExternalState(state: ExternalFilterState<FilterClause>): string {
        this.mergeFilters(state.clauses);
        return `${state.clauses.length} filter clause(s) applied.`;
    }

    async decodeExternalState(externalStateJson: Json): Promise<Either<string, ExternalFilterState<FilterClause>>> {
        return ExternalFilterState.decodeExternalState(
            externalStateJson,
            "filter clauses",
            (json: Json, refs: References) => FilterClauseFactory.fromJSON(json, false, refs)
        )
    }

}