import {Enum} from "common/Enum";

/**
 * The type of Visualisation selection.
 */
export class VizSelectionType extends Enum {
    static DISCRETE = new this('discrete');
    static RANGE = new this('range');
}

VizSelectionType.finalize();