import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {Tuple} from "common/Tuple";
import {CreateWidget} from "metadata/dashboard/changes/CreateWidget";

export class DeleteWidget implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly widgetId: string
    ) { }

    apply(metadata: ArcDashboard): Tuple<ArcDashboard, ArcMetadataChange<ArcDashboard>> {
        return Tuple.of(
            metadata.with({
                widgets: metadata.widgets.without(this.widgetId)
            }),
            new CreateWidget(this.widgetId, metadata.widgets.get(this.widgetId))
        );
    }

    describe(): string {
        return `Removed widget ${this.widgetId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.DELETE;
    }
}