import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {SelectMode} from "metadata/dashboard/SelectMode";
import TextField from "@mui/material/TextField";
import {InlineEditorS as S} from "app/dashboard/widgets/InlineEditorS";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {FQN} from "common/FQN";
import Tooltip from "@mui/material/Tooltip";
import {Optional} from "common/Optional";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {LinkConfigPart} from "metadata/dashboard/widgets/config/link/LinkConfigPart";
import {AssetPicker} from "app/components/search/AssetPicker";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetType} from "metadata/AssetType";
import Button from "@mui/material/Button";
import {LinkType} from "metadata/dashboard/widgets/LinkType";
import {StatePassMode} from "metadata/dashboard/widgets/config/StatePassMode";

interface EditorProps extends ConfigEditorProps<LinkConfigPart> {

}

/**
 * Inline editor for link widgets on dashboards.
 *
 * @author zuyezheng
 */
export const LinkConfigEditor: FunctionComponent<EditorProps> = (props: EditorProps) => {

    const [pickingAsset, setPickingAsset] = useState<boolean>(false);
    const [assetLabel, setAssetLabel] = useState<Optional<string>>(Optional.none());

    // if its an asset link, grab the asset metadata so we can show the label.
    useEffect(() => {
        // only getch metadata labels for assets and valid fqns
        if (props.config.getValue('linkType') !== LinkType.ASSET || !FQN.isFqn(props.config.getValue('location'))) {
            return;
        }

        const controller = new AbortController();
        (async () => {
            const metadata = await ServiceProvider.get(MetadataService)
                .fetchAsset(FQN.parse(props.config.getValue('location')), controller.signal);
            metadata.forEach(m => setAssetLabel(Optional.some(m.label)));
        })();

        return () => controller.abort();
    }, [props.config.getValue('location')]);

    // link type changed, reset the location and current asset label
    const onLinkTypeChange = (event: SelectChangeEvent) => {
        setAssetLabel(Optional.none());
        props.onConfigPropertyChange(
            props.config.with(
                {
                    linkType: LinkType.get(event.target.value),
                    location: ''
                }
            )
        );
    };

    // display label of the link changed
    const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onConfigPropertyChange(
            props.config.with({label: event.target.value})
        );
    };

    // url of the link changed
    const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onConfigPropertyChange(
            props.config.with({location: event.target.value})
        );
    };

    // pass state changed
    const onPassStateChange = (event: SelectChangeEvent) => {
        props.onConfigPropertyChange(
            props.config.with({statePassing: StatePassMode.get(event.target.value)})
        );
    };

    const onChangeAsset = () => {
        setPickingAsset(true);
    };

    // new asset selected
    const onSelectAsset = (asset: AssetSearchResult) => {
        props.onConfigPropertyChange(
            props.config.with({location: asset.fqn.toString()})
        );
        setAssetLabel(Optional.none());
        setPickingAsset(false);
    };

    const onCancelChangeAsset = () => {
        setPickingAsset(false);
    };

    const locationEditor = (() => {
        if (props.config.getValue('linkType') === LinkType.ASSET) {
            const buttonLabel = assetLabel.getOr('Pick Asset');
            return <S.FormControl size="small">
                <S.Button variant="outlined" size="small" color="inherit" onClick={onChangeAsset}>
                    <Tooltip title={buttonLabel} arrow>
                        <S.ButtonLabel>{buttonLabel}</S.ButtonLabel>
                    </Tooltip>
                </S.Button>
            </S.FormControl>;
        } else {
            return <S.FormControl size="small">
                <TextField
                    label="URL"
                    variant="outlined"
                    size="small"
                    value={props.config.getValue('location')}
                    onChange={onChangeUrl}
                />
            </S.FormControl>;
        }
    })();

    return <S.Container>
        <S.Row>
            <S.Config>
                <S.FormControl size="small">
                    <InputLabel id="link-type-label">Link Type</InputLabel>
                    <Select
                        labelId="link-type-label"
                        id="link-type"
                        value={props.config.getValue('linkType').name}
                        onChange={onLinkTypeChange}
                        input={<S.OutlinedInput label="Link Type"/>}
                    >{
                        LinkType.enums<SelectMode>().map(type => (
                            <S.MenuItem key={type.name} value={type.name}>
                                {type.label}
                            </S.MenuItem>
                        ))
                    }</Select>
                </S.FormControl>
            </S.Config>
            <S.Config style={{width: "150px"}}>
                <S.FormControl size="small">
                    <TextField
                        label="Label"
                        variant="outlined"
                        size="small"
                        value={props.config.getValue('label')}
                        onChange={onChangeLabel}
                    />
                </S.FormControl>
            </S.Config>
            <S.Config style={{width: "150px"}}>
                {locationEditor}
            </S.Config>
            {
                props.config.getValue('linkType') === LinkType.ASSET && <S.Config style={{width: "130px"}}>
                    <S.FormControl size="small">
                        <InputLabel id="pass-state-label">Pass State</InputLabel>
                        <Select
                            labelId="pass-state-label"
                            id="passState"
                            value={props.config.getValue('statePassing').name}
                            onChange={onPassStateChange}
                            input={<S.OutlinedInput label="Pass State"/>}
                        >{
                            StatePassMode.enums<StatePassMode>().map(mode => (
                                <S.MenuItem key={mode.name} value={mode.name}>
                                    {mode.label}
                                </S.MenuItem>
                            ))
                        }</Select>
                    </S.FormControl>
                </S.Config>
            }
        </S.Row>
        {
            pickingAsset && <AssetPicker
                title="Select a query or dashboard to link."
                searchParams={AssetSearchParams.recentAll([AssetType.ARCQL, AssetType.DASHBOARD])}
                onSelect={onSelectAsset}
                onCancel={onCancelChangeAsset}
                footer={
                    <Button variant="contained" onClick={onCancelChangeAsset}>
                        Cancel
                    </Button>
                }
            />
        }
    </S.Container>;

};