import {Colors, FontSizes} from "app/components/StyleVariables";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";

export class InlineEditorS {

    static Container = styled.div`
        display: flex;
        flex-direction: column;
        font-size: ${FontSizes.small};
    `;

    static Row = styled.div`
        display: flex;
        align-items: start;
    `;

    static LabelRow = styled.div`
        display: flex;
        padding: 10px;
    `;

    static Config = styled.div`
        padding: 4px;
        display: flex;
        align-items: center;
        font-size: ${FontSizes.small};
        width: 100px;

        .MuiFormControl-root {
            width: 100%;
        }
    `;

    static FormControl = styled(FormControl)`
        margin: 6px 0 4px;

        .MuiFormLabel-root {
            color: ${Colors.textTertiary};
            transform: translate(9px, 6px) scale(1);

            &.MuiInputLabel-shrink {
                font-size: ${FontSizes.small};
                transform: translate(11.5px, -9.3px) scale(0.9);

            }
        }
    `;

    static MenuItem = styled(MenuItem)`
        font-size: ${FontSizes.small};
    `;

    static OutlinedInput = styled(OutlinedInput)`
        .MuiInputLabel-outlined {
            left: 8px;
        }
    `;

    static Label = styled.div`
        padding-left: 4px;
        padding-right: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${Colors.textPrimary};
    `;

    static Button = styled(Button)`
        margin-top: 2px;
        height: 37px;
        border-color: rgba(0, 0, 0, 0.23);
        color: ${Colors.textPrimary};

        :hover {
            border-color: rgba(0, 0, 0, 0.87);
        }
    `;

    static ToggleButton = styled(ToggleButton)`
        :hover {
            border-color: rgba(0, 0, 0, 0.87);
        }
    `;

    static ButtonLabel = styled.div`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    `;
}
