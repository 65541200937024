import {Edge, Node} from "@reactflow/core/dist/esm/types";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {MarkerType} from "reactflow";
import {FlowNodeType} from "app/query/hypergraph/nodes/FlowNodeType";
import {HyperGraphNodeLayout} from "metadata/hypergraph/HyperGraphNodeLayout";


/**
 * Convert a HyperGraph to a ReactFlow layout.
 *
 * @author zuyezheng
 */
export class HyperGraphToFlow {

    constructor(
        private readonly hyperGraph: HyperGraph
    ) { }

    nodesAndEdges(selectedNodes: Set<string>): [Node[], Edge[]] {
        return [this.nodes(selectedNodes), this.edges()];
    }

    nodes(selectedNodes: Set<string>): Node[] {
        return this.hyperGraph.nodesWithLayout.map(([node, layout]: [HyperGraphNode, HyperGraphNodeLayout]) => {
            const nodeType = FlowNodeType.for(node);
            return {
                id: node.id,
                selected: selectedNodes.has(node.id),
                type: nodeType.name,
                data: {
                    label: node.label(this.hyperGraph),
                    node: node
                },
                position: {
                    x: layout.x,
                    y: layout.y
                },
                width: layout.width,
                height: layout.height
            };
        });
    }

    edges(): Edge[] {
        return Array.from(this.hyperGraph.nodes).flatMap(
            (node) => node.request.ommers.map(parent => ({
                id: `${node.id}-${parent}`,
                source: parent,
                target: node.id,
                type: 'smoothstep',
                pathOptions: {
                    borderRadius: 18
                },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20
                },
                animated: node.inProgress
            }))
        );
    }

}