import {AssetType} from "metadata/AssetType";

export type FqnEditableParts = {
    account: string,
    folder: string,
    type: AssetType
    name: string
}

export class FQN {

    static parse(fqn: string): FQN {
        const parts = fqn.split('/');
        if (parts.length !== 4) {
            throw new Error(`FQNs should have exactly 4 parts: ${fqn}`);
        }

        return new FQN(parts[0], parts[1], AssetType.get(parts[2]), parts[3]);
    }

    static isFqn(fqn: string): boolean {
        if (fqn == null) {
            return false;
        }

        return fqn.split('/').length === 4;
    }

    constructor(
        public readonly account: string,
        public readonly folder: string,
        public readonly type: AssetType,
        public readonly name: string
    ) {}

    equals(other: FQN): boolean {
        if (other == null) {
            return false;
        }

        return this.account === other.account &&
            this.folder === other.folder &&
            this.type === other.type &&
            this.name === other.name;
    }

    with(props: Partial<FqnEditableParts>): FQN {
        return new FQN(
            props.account ?? this.account,
            props.folder ?? this.folder,
            props.type ?? this.type,
            props.name ?? this.name
        );
    }

    get parts(): string[] {
        return [this.account, this.folder, this.type.name, this.name];
    }

    get shortened(): string {
        return [this.account, this.folder, this.name].join('/');
    }

    get apiPath(): string {
        return `accounts/${this.account}/folders/${this.folder}/${this.type.plural}/${this.name}`;
    }

    toString(): string {
        return this.parts.join('/');
    }

    toJSON(): string {
        return this.toString();
    }

}