import {Optional} from "common/Optional";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";
import {ChartWidgetConfig} from "metadata/dashboard/widgets/config/ChartWidgetConfig";
import {ChartConfigPart} from "metadata/dashboard/widgets/config/chart/ChartConfigPart";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

export class ChartMetadata extends QueriedWidgetMetadata<ChartWidgetConfig, ChartMetadata> {

    mergeWidgetConfig(dashboardConfig?: DashboardConfig): ChartWidgetConfig {
        return ChartWidgetConfig.fromMap(this.config, dashboardConfig);
    }

    attach(queryId: string): ChartMetadata {
        return this.withConfigs(
            new Map([[ChartWidgetConfig.CHART_CONFIG_KEY, new ChartConfigPart(queryId, null).toJSON()]]),
            true
        );
    }

    detach(): ChartMetadata {
        return this.withoutConfigs([ChartWidgetConfig.CHART_CONFIG_KEY]);
    }

    get queryId(): Optional<string> {
        return Optional.some(this.widgetConfig().chart.queryId);
    }

    get isAttached(): boolean {
        return this.queryId.match(
            () => true,
            () => false
        );
    }
}