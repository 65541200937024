import {ConnectionDetailsFactory} from "metadata/connections/ConnectionDetailsFactory";
import {ConnectionDetails} from "metadata/connections/ConnectionDetails";
import {DatasetListItem, DatasetListItemJson} from "metadata/dataset/DatasetListItem";
import {ConnectionType} from "metadata/connections/ConnectionType";

export type RemoteDataSourceJson = {
    id: string;
    organizationName: string;
    label: string;
    type: string;
    connectionDetails: Record<string, any>;
    datasets: DatasetListItemJson[];
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
};

/**
 * Represents a deserialized version of a remote data source, handling secure connection details.
 */
export class RemoteDataSource {

    constructor(
        public readonly id: string,
        public readonly organizationName: string,
        public readonly label: string,
        public readonly type: ConnectionType,
        public readonly connectionDetails: ConnectionDetails,
        public readonly createdBy: string,
        public readonly createdOn: string,
        public readonly updatedBy: string,
        public readonly updatedOn: string,
        public readonly datasets: DatasetListItem[]
    ) {
    }

    /**
     * Constructs an instance of RemoteDataSource from a JSON object.
     */
    static fromJSON(json: RemoteDataSourceJson): RemoteDataSource {
        const connectionType = ConnectionType.get<ConnectionType>(json.type);
        return new RemoteDataSource(
            json.id,
            json.organizationName,
            json.label,
            connectionType,
            ConnectionDetailsFactory.fromJSON(connectionType, json.connectionDetails),
            json.createdBy,
            json.createdOn,
            json.updatedBy,
            json.updatedOn,
            json.datasets ? json.datasets.map(datasetJson => DatasetListItem.fromJSON(datasetJson)): [],
        );
    }
}
