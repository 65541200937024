export type GeneratedColumnDescriptionJson = {
    name: string,
    label: string,
    description: string,
    suggestedImprovement?: string
};

/**
 * POJO detailing column info generated by an AI.
 */
export class GeneratedColumnInfo {

    constructor(
        // name of the column
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        // what the AI suggests to improve column description
        public readonly suggestedImprovement?: string
    ) {

    }

    static fromJSON(json: GeneratedColumnDescriptionJson): GeneratedColumnInfo {
        return new GeneratedColumnInfo(
            json.name,
            json.label,
            json.description,
            json.suggestedImprovement
        );
    }

}