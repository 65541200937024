import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";
import {VizSelection} from "engine/actor/VizSelection";
import {ResultValueFormatter} from "metadata/query/ResultFormatter";
import {JsonObject} from "common/CommonTypes";
import {AnalyticsType} from "metadata/AnalyticsType";
import {Optional} from "common/Optional";
import {SelectionEvent} from "app/visualizations/FusionTypes";
import {RGBATweener} from "common/RGBATweener";
import {RGBA} from "common/RGBA";

/**
 * @author zuyezheng
 */
export class TreemapChartConfig extends ChartConfig {

    get selectionType(): SelectionType {
        // currently we only support selections with a single grouping
        if (this.response.arcql.groupings.size === 1) {
            return SelectionType.DISCRETE;
        }

        return SelectionType.NONE;
    }

    handleDiscreteClick(event: SelectionEvent, originalDataSource: {[key: string]: any}): Optional<string[][]> {
        if (this.response.arcql.groupings.size !== 1) {
            return Optional.none();
        }

        return Optional.some([
            [event.name]
        ]);
    }

    validate(): Optional<string> {
        const numMeasures = this.response.result.columnsByType(new Set([AnalyticsType.MEASURE])).length;
        if (!(numMeasures >= 1 && numMeasures <= 2)) {
            return Optional.some('Chart type requires between 1 and 2 measures.');
        }

        return Optional.none();
    }

    private dataset(selections: VizSelection): {
        minValue: number,
        maxValue: number,
        dataset: JsonObject
    } {
        const result = this.response.result;
        if (result.categoryColumns.length === 0) {
            return {
                minValue: 0,
                maxValue: 0,
                dataset: {}
            };
        }

        const metrics = result.columnsByType(new Set([AnalyticsType.MEASURE]));
        // we always need a metric for size
        const sizeMetric = result.columnIndices.get(metrics[0]);
        // use second metric for color if there is one, otherwise use size
        const colorMetric = metrics.length > 1 ? result.columnIndices.get(metrics[1]) : sizeMetric;

        const categoryColumnIndices = result.categoryColumns.map(c => c.right);
        const branchColumnIndices = categoryColumnIndices.slice(0, -1);
        const lastCategoryIndex = categoryColumnIndices[categoryColumnIndices.length - 1];

        // convert the tabular rows into hierarchical data such that [us, ca, sf, 10] turns into something like:
        // {
        //     us: {
        //         ca: {
        //             sf: {label: sf, value: 10}
        //         }
        //     }
        // }
        const dataHierarchy: Map<string, any> = new Map();

        // figure out the min and max values to tween colors
        let minValue: number;
        let maxValue: number;
        result.forEachRow((row: any[]) => {
            const colorValue = row[colorMetric];
            if (minValue == null || colorValue < minValue) {
                minValue = colorValue;
            }
            if (maxValue == null || colorValue > maxValue) {
                maxValue = colorValue;
            }
        });

        const reverseColors = this.config.get('reverseColors').getOr(false);
        const colorTweener = new RGBATweener(
            minValue,
            RGBA.fromHex(this.config.theme.threePoint[reverseColors ? 2 : 0]),
            (maxValue + minValue) / 2,
            RGBA.fromHex(this.config.theme.threePoint[1]),
            maxValue,
            RGBA.fromHex(this.config.theme.threePoint[reverseColors ? 0 : 2])
        );

        const baseAlpha = selections.isEmpty() ? 1 : 0.3;
        result.forEachRow((row: any[], index: string, formatters: ResultValueFormatter[]) => {
            const categoryNode: Map<string, any> = branchColumnIndices.reduce(
                (parent: Map<string, any>, index: number) => {
                    const label = formatters[index](row[index]);
                    let node = parent.get(label);
                    if (node == null) {
                        node = new Map();
                        parent.set(label, node);
                    }

                    return node;
                },
                dataHierarchy
            );

            const rowLabel = formatters[lastCategoryIndex](row[lastCategoryIndex]);
            const selected = selections.has([rowLabel]);

            const colorValue = row[colorMetric];
            categoryNode.set(row[lastCategoryIndex], {
                label: rowLabel,
                value: row[sizeMetric],
                sValue: colorValue,
                fillcolor:  colorTweener.tween(colorValue).withA(selected ? 1 : baseAlpha).noA().toHex()
            });
        });

        // serialize the hierarchical map into the json object expected by fusion
        const serializeNode = (label: string, node: Map<string, any>): JsonObject => {
            const entries: [string, any][] = Array.from(node.entries());

            return {
                label: label,
                // check the child to see if its a map or object
                data: entries[0][1] instanceof Map ?
                    // branch
                    entries.map(e => serializeNode(e[0], e[1])) :
                    // leaf
                    entries.map(e => e[1])
            };
        };

        return {
            minValue: minValue,
            maxValue: maxValue,
            dataset: serializeNode(this.xAxisName(), dataHierarchy)
        };
    }

    private get plotToolText(): string {
        const parts = ['<b>$label</b>'];

        const metrics = this.response.result.columnsByType(new Set([AnalyticsType.MEASURE]));
        parts.push(`${metrics[0]}: $value`);
        if (metrics.length > 1) {
            parts.push(`${metrics[1]}: $sValue`);
        }

        return parts.join('<br>');
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        const {minValue, maxValue, dataset} = this.dataset(selections);
        dataset['fillcolor'] = '8c8c8c';

        const reverseColors = this.config.get('reverseColors').getOr(false);
        return {
            type: 'treemap',
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),
                        showLegend: this.config.get('showLegend').getOr(true),
                        legendCaption: this.config.emptyableString('legendCaption')
                            .getOr(this.response.arcql.fields.last.as),

                        horizontalPadding: 1,
                        verticalPadding: 1,

                        plotToolText: this.plotToolText
                    }
                ),
                data: [dataset],
                colorRange: {
                    mapByPercent: false,
                    gradient: true,
                    minValue: minValue,
                    code: this.config.theme.threePoint[reverseColors ? 2 : 0],
                    color: [
                        {
                            code: this.config.theme.threePoint[1],
                            maxValue: (maxValue + minValue) / 2
                        },
                        {
                            code: this.config.theme.threePoint[reverseColors ? 0 : 2],
                            maxValue: maxValue
                        }
                    ]
                }
            }
        };
    }

}