import {ApiMetadataService} from "services/ApiMetadataService";
import {MetadataService} from "services/MetadataService";
import {QueryService} from "services/QueryService";
import {RestService} from "services/RestService";
import {UserAnalyticsService} from "services/UserAnalyticsService";
import {NoopUserAnalyticsService} from "services/NoopUserAnalyticsService";
import {AppcuesUserAnalyticsService} from "services/AppcuesUserAnalyticsService";
import {Config} from "services/Config";
import {UserService} from "services/UserService";
import {LocallyCachedQuerySerivce} from "services/LocallyCachedQuerySerivce";
import {ApiQueryService} from "services/ApiQueryService";
import {ImageService} from "services/ImageService";
import {NotificationsService} from "services/NotificationsService";
import {LinkService} from "services/LinkService";
import {MockedMetadataService} from "services/MockedMetadataService";
import {MockedQueryService} from "services/MockedQueryService";
import {LocalStorageService} from "services/LocalStorageService";
import {InternalRouterService} from "services/InternalRouterService";
import {TabService} from "services/TabService";
import {LogService} from "services/LogService";
import {SessionService} from "services/SessionService";
import {ApiSessionService} from "services/ApiSessionService";
import {MockedSessionService} from "services/MockedSessionService";
import {TrendsService} from "services/TrendsService";
import {MockedTrendsService} from "services/MockedTrendsService";
import {ApiTrendsService} from "services/ApiTrendsService";
import {ApiSubscriptionService} from "services/ApiSubscriptionService";
import {MockedSubscriptionService} from "services/MockedSubscriptionService";
import {SubscriptionService} from "services/SubscriptionService";
import {ApiWeb3Service} from "services/ApiWeb3Service";
import {Web3Service} from "services/Web3Service";
import {HyperGraphService} from "services/hypergraph/HyperGraphService";
import {OrgService} from "services/OrgService";
import {DatasetV2Service} from "services/DatasetV2Service";
import {MockedDatasetV2Service} from "services/MockedDatasetV2Service";
import {ApiDatasetV2Service} from "services/ApiDatasetV2Service";
import {HyperGraphDatasetService} from "services/HyperGraphDatasetService";
import {ApiHyperGraphDatasetService} from "services/ApiHyperGraphDatasetService";

const getUserAnalytics = (config: Config) => {
    if (config.userAnalytics === 'appcues') {
        return new AppcuesUserAnalyticsService();
    } else {
        return new NoopUserAnalyticsService();
    }
};

const getQueryService = (config: Config) => {
    if (config.isTest) {
        return new MockedQueryService();
    } else if (config.environment === 'local') {
        return new LocallyCachedQuerySerivce();
    } else {
        return new ApiQueryService();
    }
};

const getMetadataService = (config: Config): MetadataService => {
    if (config.isTest) {
        return new MockedMetadataService();
    } else {
        return new ApiMetadataService();
    }
};

const getDatasetV2Service = (config: Config): DatasetV2Service => {
    if (config.isTest) {
        return new MockedDatasetV2Service();
    } else {
        return new ApiDatasetV2Service();
    }
};

const getTrendsService = (config: Config): TrendsService => {
    if (config.isTest) {
        return new MockedTrendsService();
    } else {
        return new ApiTrendsService();
    }
};

const getSessionService = (config: Config): SessionService => {
    if (config.isTest) {
        return new MockedSessionService();
    } else {
        return new ApiSessionService();
    }
};

const getSubscriptionService = (config: Config): SubscriptionService => {
    if (config.isTest) {
        return new MockedSubscriptionService();
    } else {
        return new ApiSubscriptionService();
    }
};

export namespace ServiceProvider {

    const SERVICES = new Map<{ prototype: any }, any>;

    const register = <T, T1 extends T>(service: T1, clazz: { prototype: T }) => {
        SERVICES.set(clazz, service);
    };

    const config: Config = Config.fromWindow();
    register(config, Config);

    register(new LogService(config), LogService);
    register(new InternalRouterService(), InternalRouterService);
    register(new NotificationsService(), NotificationsService);
    register(new RestService(config.apiHost), RestService);
    register(getMetadataService(config), MetadataService);
    register(getDatasetV2Service(config), DatasetV2Service);
    register(getTrendsService(config), TrendsService);
    register(getQueryService(config), QueryService);
    register(getSessionService(config), SessionService);
    register(getSubscriptionService(config), SubscriptionService);
    register(getUserAnalytics(config), UserAnalyticsService);
    register(new UserService(), UserService);
    register(new OrgService(), OrgService);
    register(new ImageService(), ImageService);
    register(new TabService(), TabService);
    register(new LinkService(config.apiHost), LinkService);
    register(new ApiHyperGraphDatasetService(), HyperGraphDatasetService);
    register(new LocalStorageService(), LocalStorageService);
    register(new ApiWeb3Service(), Web3Service);
    register(new HyperGraphService(), HyperGraphService);

    export const get = <T>(clazz: { prototype: T }): T => {
        return SERVICES.get(clazz);
    };

}