import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {VizSelection} from "engine/actor/VizSelection";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {Enum} from "common/Enum";
import {SelectionEvent} from "app/visualizations/FusionTypes";
import {Optional} from "common/Optional";
import isEqual from "lodash/isEqual";


/**
 * Builds the config needed to render a chart which includes both the data and the metadata to stylize a chart.
 *
 * @author zuyezheng
 */
export abstract class ChartConfig {

    static buildConfig(hasTitle: boolean, hasSubTitle: boolean) {
        let canvasTopMargin = 10;
        if (hasTitle) {
            canvasTopMargin += 30;
        }
        if (hasSubTitle) {
            canvasTopMargin += 20;
        }

        return {
            theme: 'fusion',
            numberScaleValue: '1000,1000,1000,1000',
            numberScaleUnit: 'K,M,B,T',
            decimals: 2,

            alignCaptionWithCanvas: 0,

            baseFont: 'NotoSans',
            baseFontSize: 12,
            legendItemFontSize: 12,
            captionFontSize: 14,
            subCaptionFontSize: 12,

            xAxisNameFontSize: 12,
            yAxisNameFontSize: 12,

            canvasLeftMargin: 0,
            canvasRightMargin: 0,
            canvasTopMargin: canvasTopMargin,
            canvasBottomMargin: 0,

            chartLeftMargin: 0,
            chartRightMargin: 0,
            chartTopMargin: 12,
            chartBottomMargin: 0,

            legendPadding: 0,
            canvasPadding: 0,
            captionPadding: 24,
            labelBorderPadding: 0,

            xAxisNamePadding: 6,
            yAxisNamePadding: 6,
        };
    }

    constructor(
        public readonly response: ArcQLResponse,
        public readonly config: VisualizationConfig
    ) {}

    /**
     * What type of selection is supported?
     */
    abstract get selectionType(): SelectionType;

    /**
     * Validate the response and config for the given chart type, returning None if valid and Some(error string) if not.
     */
    abstract validate(): Optional<string>;

    /**
     * Build the config for FusionCharts.
     */
    abstract build(size: [number, number], selections: VizSelection): {[key: string]: any};

    /**
     * Handle a discrete click event returning an optional of selected values. Each selected value is an array since
     * a single composite selection will be comprised of multiple individual values.
     */
    handleDiscreteClick(
        // fusion charts selection event
        event: SelectionEvent,
        // fusion datasource specific to the visualization
        originalDataSource: {[key: string]: any},
        // fusion event type
        eventType: string
    ): Optional<string[][]> {
        throw new Error('Chart type does not support discrete selections.');
    }

    isEqual(newConfig: ChartConfig): boolean {
        // make sure response ids are the same which doesn't do a deep comparison of data which gets expensive
        return this.response.id === newConfig.response.id
            // metadata configuration of a chart which we need to do a deep comparison of
            && isEqual(this.config, newConfig.config);
    }

    xAxisName(includeLast: boolean = true): string {
        // if it's a grouped query, use the grouping names, instead of the projected names since there are no
        // projection of groupings with different names except for dates suffix with grains which we don't want to
        // show
        const parts: string[] = this.response.arcql.isGrouped()
            ? this.response.arcql.groupings.map(g => g.label(this.response.dataset))
            : this.response.result.indexColumns.map(c => c.left);

        // if stacked, the last dimension will be the stacks so pop those
        if (includeLast) {
            parts.pop();
        }

        return parts.join(' > ');
    }

}

export class SelectionType extends Enum {

    static NONE = new this('none');
    static DISCRETE = new this('discrete');
    static RANGE = new this('range');

}
SelectionType.finalize();