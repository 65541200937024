import * as React from "react";
import {FunctionComponent, useRef} from "react";
import styled from "@emotion/styled";
import 'reactflow/dist/style.css';
import {HyperGraphFlow, HyperGraphFlowHandlers} from "app/query/hypergraph/HyperGraphFlow";
import {FontSizes} from "app/components/StyleVariables";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import {HyperGraphTools} from "app/query/hypergraph/HyperGraphTools";
import { useReactFlow } from "reactflow";
import {useResize} from 'app/components/hooks/DomHooks';
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import { EditType } from "metadata/hypergraph/HyperGraphNode";
import { ArcDataset } from "metadata/dataset/ArcDataset";

export type Props = HyperGraphFlowHandlers & {
    className?: string
    hyperGraph: HyperGraph
    dataset: ArcDataset

    onChangeRating(nodeId: string, rating: NodeRating): void
    onChangeContent(nodeId: string, type: EditType, content: string): void
}

export const HyperGraphPanel: FunctionComponent<Props> = (props: Props) => {

    const containerRef = useRef(null);
    const containerSize = useResize(containerRef);
    const {setViewport} = useReactFlow();

    const onNodeClick = (nodeId: string, multiSelect: boolean, center: boolean = false) => {
        // center the selected node
        if (center) {
            containerSize.forEach(size => {
                const nodeLayout = props.hyperGraph.layouts.get(nodeId);
                setViewport({
                    // center horizontally excluding the tool panel
                    x: -nodeLayout.x + ((size[0] - 340) / 2) - (nodeLayout.width / 2),
                    // bring it to the top third of the screen
                    y: -nodeLayout.y + (size[1]/3) - (nodeLayout.height / 2),
                    zoom: 1
                }, {
                    duration: 1000
                });
            });
        }

        props.onNodeClick(nodeId, multiSelect, false);
    };

    return <S.Container className={props.className} ref={containerRef}>
        <S.Title>
            <AutoAwesomeOutlinedIcon />
            HYPERGRAPH
        </S.Title>
        <S.FlowContainer>
            <HyperGraphFlow
                hyperGraph={props.hyperGraph}
                onClickConnectStart={props.onClickConnectStart}
                onNodesChange={props.onNodesChange}
                onNodeClick={onNodeClick}
                onResetSelection={props.onResetSelection}
            />
        </S.FlowContainer>
        <HyperGraphTools
            hyperGraph={props.hyperGraph}
            dataset={props.dataset}

            onResetSelection={props.onResetSelection}
            onNodeClick={onNodeClick}
            onChangeRating={props.onChangeRating}
            onChangeContent={props.onChangeContent}
        />
    </S.Container>;

};

class S {

    static Container = styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    `;

    static Title = styled.div`
        position: absolute;
        z-index: 1000;
        padding: 10px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        
        color: rgba(0, 0, 0, 0.60);
        font-size: ${FontSizes.small};
        font-weight: 500;
        
        svg {
            color: rgba(0, 0, 0, 0.60);
            width: 14px;
            height: 14px;
            padding-right: 4px;
        }
    `;

    static FlowContainer = styled.div`
        flex: 1;
        background-color: rgba(245, 247, 250, 0.50);
    `;

}
