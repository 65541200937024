import * as React from "react";
import {FunctionComponent, useState} from "react";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {ColorResult} from "react-color";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {DropShadow} from "metadata/dashboard/widgets/config/DropShadow";
import {ColorPickerButtonIcon} from "app/dashboard/widgets/config/ColorPickerButtonIcon";
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import FormatBackgroundColorIcon from "app/components/icons/config/FormatBackgroundColorIcon";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import {StyleEnumMenu} from "metadata/dashboard/widgets/config/StyleEnumMenu";
import {IconButton} from "@mui/material";
import Popover from "@mui/material/Popover";
import {BorderConfigForm} from "app/dashboard/components/BorderConfigForm";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";

interface EditorProps extends ConfigEditorProps<ContainerConfigPart> {
}

/**
 * Editor for updating the widget's container such as background color + padding.
 */
export const ContainerConfigToolbar: FunctionComponent<EditorProps> = (props: EditorProps) => {
    const [anchorBorderConfigEl, setAnchorBorderConfigEl] = useState<HTMLButtonElement | null>(null);

    const handleBorderConfigClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorBorderConfigEl(event.currentTarget);
    };

    const handleBorderConfigPopoverClose = () => {
        setAnchorBorderConfigEl(null);
    };

    const onBackgroundColorChange = (color: ColorResult) => {
        props.onConfigPropertyChange(props.config.with({backgroundColor: color.hex}));
    };

    const onDropShadowChange = (dropShadow: DropShadow) => {
        props.onConfigPropertyChange(props.config.with({dropShadow: dropShadow}));
    };

    return <>
        <ColorPickerButtonIcon
            tooltip={"Widget Background Color"}
            icon={
                <FormatBackgroundColorIcon
                    style={{fill: props.config.getValue('backgroundColor')}}
                />
            }
            currentColor={props.config.getValue('backgroundColor')}
            onChange={onBackgroundColorChange}
        />
        <StyleEnumMenu
            id={"drop-shadow"}
            tooltipCategorization={"Drop Shadow"}
            menuButtonIcon={<LightModeOutlinedIcon/>}
            prefixLabel={"Drop Shadow"}
            current={props.config.getValue('dropShadow')}
            onStylingChange={onDropShadowChange}
            styles={DropShadow.enums()}
        />
        <Tooltip title={"Border Configuration"} placement="top" arrow>
            <IconButton
                onClick={handleBorderConfigClick}
            >
                <BorderStyleIcon/>
            </IconButton>
        </Tooltip>
        <Popover
            open={Boolean(anchorBorderConfigEl)}
            anchorEl={anchorBorderConfigEl}
            onClose={handleBorderConfigPopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <S.BorderConfigForm
                config={props.config}
                onChange={props.onConfigPropertyChange}
            />
        </Popover>
    </>;
};

class S {
    static BorderConfigForm = styled(BorderConfigForm)`
        padding: 8px;
        width: 150px;
    `;
}