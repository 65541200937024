import styled from "@emotion/styled";
import { Handle } from "reactflow";

export class FlowNodeS {

    static readonly Handle = styled(Handle)`
        
        &.react-flow__handle {
            width: 7px;
            background: #CFD8DC;
            border: 2px solid white;
            height: 7px;
        }
        
        &.react-flow__handle-bottom{
            bottom: -5px;
        }
        
    `;

}