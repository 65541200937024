import * as React from "react";
import {FunctionComponent} from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "@emotion/styled";


export type Props = {
    expanded: boolean,
    onClick: () => void
};

export const ExpandButton: FunctionComponent<Props> = (props: Props) => {
    return (
        <S.ExpandButton onClick={props.onClick}>
            {props.expanded ? (
                <S.RemoveCircleOutlineIcon/>
            ) : (
                <S.AddCircleOutlineIcon/>
            )}
        </S.ExpandButton>
    );
};

const S = {
    ExpandButton: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
     `,
    RemoveCircleOutlineIcon: styled(RemoveCircleOutlineIcon)`
        font-size: 16px;
        opacity: 0.54;
    `,
    AddCircleOutlineIcon: styled(AddCircleOutlineIcon)`
        font-size: 16px;
        opacity: 0.54;
    `
}