import React, {ChangeEvent, FunctionComponent, useEffect} from "react";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {RemoteDatabase, RemoteSchema, RemoteTable} from "metadata/connections/RemoteItem";
import Dialog from "@mui/material/Dialog";
import { DialogS } from "app/DialogS";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ServiceProvider} from "services/ServiceProvider";
import { OrgService } from "services/OrgService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import {DataGridPro, GridColDef} from "@mui/x-data-grid-pro";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ListItemIcon from "@mui/material/ListItemIcon";
import {DataSourceTypeIcon} from "app/components/settings/connections/DataSourceTypeIcon";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Search from "@mui/icons-material/Search";
import TableViewSharp from "@mui/icons-material/TableViewSharp";
import Warning from "@mui/icons-material/Warning";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {jaroWinkler} from "jaro-winkler-typescript";
import {LinkService} from "services/LinkService";
import {InternalRouterService} from "services/InternalRouterService";
import {TabPath} from "app/TabPath";
import { FontSizes } from "app/components/StyleVariables";
import {ErrorCode, ErrorResponse} from "services/ApiResponse";

type Props = {
    initialConnection: RemoteDataSource | null
    connections: RemoteDataSource[];
    onSelect: () => void
    onCancel: () => void
}

export const ImportTableDialog: FunctionComponent<Props> = (props: Props) => {

    const [selectedConnection, setSelectedConnection] = React.useState<RemoteDataSource | null>(props.initialConnection);
    const [databaseList, setDatabaseList] = React.useState<RemoteDatabase[]>([]);
    const [selectedDatabase, setSelectedDatabase] = React.useState<RemoteDatabase | null>(null);
    const [schemaList, setSchemaList] = React.useState<RemoteSchema[]>([]);
    const [selectedSchema, setSelectedSchema] = React.useState<RemoteSchema | null>(null);
    const [tableList, setTableList] = React.useState<RemoteTable[]>([]);
    const [isLoadingTables, setIsLoadingTables] = React.useState<boolean>(true);
    const [isRemoteError, setIsRemoteError] = React.useState<boolean>(false);
    const [searchString, setSearchString] = React.useState<string>('');

    useEffect(() => {
        if (selectedConnection) {
            onSelectConnection(selectedConnection);
        }
    }, []);

    const handleRequestError = (e: ErrorResponse, message: string) => {
        if (e.errorCode === ErrorCode.REMOTE_CONNECTION_ERROR) {
            setIsRemoteError(true);
            setIsLoadingTables(false);
        } else {
            ServiceProvider.get(NotificationsService).publish('ImportTable', NotificationSeverity.ERROR, message);
        }
    };

    const listRemoteDatabases = (connection: RemoteDataSource) => {
        ServiceProvider.get(OrgService)
            .listRemoteDatabases(connection.organizationName, connection.id)
            .then(response => response.match(
                databases => {
                    setDatabaseList(databases.items);
                    onSelectDatabase(connection, databases.items[0]);
                },
                (e) => {
                    handleRequestError(e, 'Could not list databases from connection.');
                }
            ));
    };

    const listRemoteSchemas = (connection: RemoteDataSource, database: RemoteDatabase) => {
        ServiceProvider.get(OrgService)
            .listRemoteSchemas(connection.organizationName, connection.id, database.name)
            .then(response => response.match(
                schemas => {
                    setSchemaList(schemas.items);
                    onSelectSchema(connection, database, schemas.items[0]);
                },
                (e) => {
                    handleRequestError(e, 'Could not list schemas from connection.');
                }
            ));
    };

    const listRemoteTables = (connection: RemoteDataSource, database: RemoteDatabase, schema: RemoteSchema) => {
        ServiceProvider.get(OrgService)
            .listRemoteTables(connection.organizationName, connection.id, database.name, schema.name)
            .then(response => response.match(
                tables => {
                    setTableList(tables.items);
                    setIsLoadingTables(false);
                },
                (e) => {
                    handleRequestError(e, 'Could not list tables from connection.');
                }
            ));
    };

    const onSelectConnection = (connection: RemoteDataSource) => {
        setIsRemoteError(false);
        setIsLoadingTables(true);
        setSelectedConnection(connection);
        // Clear existing lists and selections when selecting a different connection
        setDatabaseList([]);
        setSelectedDatabase(null);
        setSchemaList([]);
        setSelectedSchema(null);
        setTableList([]);

        listRemoteDatabases(connection);
    };

    const onSelectDatabase = (connection: RemoteDataSource, database: RemoteDatabase) => {
        setIsRemoteError(false);
        setIsLoadingTables(true);
        setSelectedDatabase(database);
        listRemoteSchemas(connection, database);
    };

    const onSelectSchema = (connection: RemoteDataSource, database: RemoteDatabase, schema: RemoteSchema) => {
        setIsRemoteError(false);
        setIsLoadingTables(true);
        setSelectedSchema(schema);
        listRemoteTables(connection, database, schema);
    };

    const onSelectTable = (dataSource: RemoteDataSource, remoteTable: RemoteTable) => {
        props.onSelect();
        ServiceProvider.get(LinkService).datasetV2(dataSource.organizationName)
            .then(url => ServiceProvider.get(InternalRouterService).route(
                'importTable',
                TabPath.fromRaw(url),
                new Map<string, any>([
                    ["connection", dataSource],
                    ["remoteTable", remoteTable],
                    ["isNew", true]
                ])
            ));
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: '',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <S.TableRow>
                    <TableViewSharp color={"disabled"}/>
                    {params.value}
                </S.TableRow>
            )
        }
    ];

    const buildNoDataSourcesBody = () => {
        return <S.NoDataSourcesBody>
            <S.Warning sx={{ fontSize: 50 }} color={"primary"}/>
            <S.NoDataSourcesTitle>No Data Sources found</S.NoDataSourcesTitle>
            <S.NoDataSourcesText>To create imported datasets in HyperArc you must first set up a Connection to your database.</S.NoDataSourcesText>
        </S.NoDataSourcesBody>;
    };

    const buildConnectionFailed = () => {
        return <S.ConnectionFailed>
            <S.IconContainer>
                <ErrorOutline color={"error"}/>
            </S.IconContainer>
            <S.ConnectionFailedText>
                <div>Connection Failed</div>
                <S.ConnectionFailedDescription>
                    HyperArc was unable to connect to your database. Please check your connection details and credentials.
                </S.ConnectionFailedDescription>
            </S.ConnectionFailedText>
        </S.ConnectionFailed>;
    };

    const buildTableList = (tables: RemoteTable[]) => {
        return <S.TableListBox>
            <S.SearchRow>
                <FormControl fullWidth>
                    <FormLabel id="tablename-label" sx={{mb: 0.5}}>Select a table to import:</FormLabel>
                    <TextField
                        margin="none"
                        size="small"
                        id="name"
                        aria-labelledby="tablename-label"
                        placeholder="Search Tables..."
                        value={searchString}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </S.SearchRow>
            <S.TableList
                rows={tables.map(t => ({
                    id: t.name,
                    name: t.name,
                    result: t
                }))}
                columns={columns}
                onRowClick={(params) => onSelectTable(selectedConnection, params.row.result as RemoteTable)}
                rowHeight={40}
                headerHeight={0}
                hideFooter
            />
        </S.TableListBox>;
    };

    const buildBody = () => {
        const tableSearchResults: RemoteTable[] = searchString ?
            tableList.filter(t => t.name.toUpperCase().includes(searchString.toUpperCase()))
                .sort((a: RemoteTable, b: RemoteTable) => {
                    const aDistance = jaroWinkler(searchString, String(a.name).toUpperCase(), {caseSensitive: false});
                    const bDistance = jaroWinkler(searchString, String(b.name).toUpperCase(), {caseSensitive: false});

                    if (aDistance < bDistance) {
                        return 1;
                    } else if (aDistance > bDistance) {
                        return -1;
                    } else {
                        return 0;
                    }
                }) :
            tableList;

        const content = () => {
            if (isLoadingTables) {
                return <S.SpinnerDiv>
                    <CircularProgress />
                </S.SpinnerDiv>;
            } else if (isRemoteError) {
                return buildConnectionFailed();
            } else {
                return buildTableList(tableSearchResults);
            }
        };

        return <S.Body>
            <DialogS.SelectorRow>
                <FormControl sx={{width: "363px"}}>
                    <FormLabel id="connection-label" sx={{mb: 0.5}}>Connected Database</FormLabel>
                    <S.ConnectionSelect
                        labelId="connection-label"
                        id="connection-select"
                        value={selectedConnection?.id ?? ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectConnection(
                            props.connections.find(c => c.id === e.target.value)
                        )}
                        size="small"
                    >
                        {
                            props.connections.map(c =>
                                <MenuItem key={c.id} value={c.id}>
                                    <S.ListItemIcon>
                                        <DataSourceTypeIcon type={c.type}/>
                                    </S.ListItemIcon>
                                    {c.label}
                                </MenuItem>
                            )
                        }
                    </S.ConnectionSelect>
                </FormControl>
            </DialogS.SelectorRow>
            <S.SelectorBox>
                <S.HalfSpan>
                    <DialogS.SelectorRow>
                        <FormControl fullWidth>
                            <FormLabel id="database-label" sx={{mb: 0.5}}>Database</FormLabel>
                            <Select
                                labelId="database-label"
                                id="database-select"
                                value={selectedDatabase?.name ?? ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectDatabase(
                                    selectedConnection,
                                    databaseList.find(d => d.name === e.target.value)
                                )}
                                size="small"
                            >
                                {
                                    databaseList.map(d =>
                                        <MenuItem key={d.name} value={d.name}>
                                            {d.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </DialogS.SelectorRow>
                </S.HalfSpan>
                <S.HalfSpan>
                    <DialogS.SelectorRow>
                        <FormControl fullWidth>
                            <FormLabel id="schema-label" sx={{mb: 0.5}}>Schema</FormLabel>
                            <Select
                                labelId="schema-label"
                                id="schema-select"
                                value={selectedSchema?.name ?? ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectSchema(
                                    selectedConnection,
                                    selectedDatabase,
                                    schemaList.find(s => s.name === e.target.value),
                                )}
                                size="small"
                            >
                                {
                                    schemaList.map(s =>
                                        <MenuItem key={s.name} value={s.name}>
                                            {s.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </DialogS.SelectorRow>
                </S.HalfSpan>
            </S.SelectorBox>
            { content() }
        </S.Body>;
    };

    const paperProps = props.initialConnection == null ? {
        sx: {
            width: "600px"
        }
    } : {
        sx: {
            width: "600px",
            height: "626px"
        }
    };

    return <Dialog
        open={true}
        onClose={props.onCancel}
        PaperProps={paperProps}
    >
        <S.Title>Select a Table to Import</S.Title>
        <S.Content dividers>
            {
                props.initialConnection == null ?
                    buildNoDataSourcesBody() :
                    buildBody()
            }
        </S.Content>
    </Dialog>;
};

class S {

    static readonly Title = styled(DialogTitle)`
        padding: 16px 24px;
    `;

    static readonly Body = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
    `;

    static readonly Content = styled(DialogContent)`
        padding: 24px 24px;
    `;

    static readonly ConnectionSelect = styled(Select)`
        .MuiSelect-select {
            display: flex;
            align-items: center;
        }
    `;

    static readonly ListItemIcon = styled(ListItemIcon)`
        min-width: 36px;
    `;

    static readonly SelectorBox = styled(Box)`
        display: flex;
        align-items: center;
        gap: 16px;
    `;

    static readonly HalfSpan = styled.span`
        width: 50%
    `;

    static readonly TableListBox = styled(Box)`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        height: 100%;
    `;

    static readonly SearchRow = styled.div`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    `;

    static readonly TableList = styled(DataGridPro)`
        width: 100%;

        .MuiDataGrid-columnHeaders {
            display: none;
        }
        
        &.MuiDataGrid-root {
            font-size: 14px;
        }
        
        .MuiDataGrid-cell {
            padding: 8px 24px;
        }
    `;

    static readonly TableRow = styled.div`
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    `;

    static readonly SpinnerDiv = styled.div`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    static readonly NoDataSourcesBody = styled.div`
        height: 300px;
        padding-left: 67px;
        padding-right: 67px;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    `;

    static readonly Warning = styled(Warning)`
        align-self: center;
    `;

    static readonly NoDataSourcesTitle = styled.div`
        font-size: ${FontSizes.large};
        text-align: center;
    `;

    static readonly NoDataSourcesText = styled.div`
        text-align: center;
    `;

    static readonly ConnectionFailed = styled.div`
        display: flex;
        padding: 6px 16px;
        align-items: flex-start;
        border-radius: 4px;
        background: var(--Light-Error-Shades-190p, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #D32F2F);
    `;

    static readonly IconContainer = styled.div`
        display: flex;
        padding: 7px 12px 7px 0;
        align-items: flex-start;
    `;

    static readonly ConnectionFailedText = styled.div`
        display: flex;
        padding: 8px 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
    `;

    static readonly ConnectionFailedDescription = styled.div`
        font-size: ${FontSizes.small};
    `;
}