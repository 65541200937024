import {useContext, useEffect, useState} from "react";
import {ServiceProvider} from 'services/ServiceProvider';
import {UserService} from 'services/UserService';
import {MetadataService} from 'services/MetadataService';
import {AssetSearchParams} from 'metadata/search/AssetSearchParams';
import {AssetType} from 'metadata/AssetType';
import {UserContext} from 'app/UserContext';

export const useOnboardingState = (): {
    hasUploadedDatasets: boolean,
    hasCreatedQueries: boolean
} => {

    const userContext = useContext(UserContext);

    const [hasUploadedDatasets, setHasUploadedDatasets] = useState<boolean>(false);
    const [hasCreatedQueries, setHasCreatedQueries] = useState<boolean>(false);

    useEffect(() => {
        // what?
        if (userContext.user.isNone) {
            return;
        }

        ServiceProvider.get(UserService)
            .listDatasets()
            .then(response => response.match(
                datasets => setHasUploadedDatasets(datasets.length > 0),
                // onboarding is secondary, be silent
                () => null
            ));

        ServiceProvider.get(MetadataService)
            .assetSearch(new AssetSearchParams(
                1,
                null,
                [AssetType.ARCQL],
                null,
                null,
                userContext.user.get().name
            ))
            .then(response => response.match(
                results => setHasCreatedQueries(results.results.length > 0),
                // onboarding is secondary, be silent
                () => null
            ));
    }, []);

    return {
        hasUploadedDatasets,
        hasCreatedQueries
    };

};