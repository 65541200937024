import * as React from "react"
import {SVGProps} from "react"

const BooleanIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        {...props}
    >
        <path
            d="M10.182 3H3.818C1.712 3 0 4.793 0 7s1.712 4 3.818 4h6.364C12.288 11 14 9.207 14 7s-1.712-4-3.818-4Zm0 6.667H3.818C2.412 9.667 1.273 8.473 1.273 7c0-1.473 1.139-2.667 2.545-2.667h6.364c1.406 0 2.545 1.194 2.545 2.667 0 1.473-1.139 2.667-2.545 2.667ZM3.818 5C2.762 5 1.91 5.893 1.91 7s.853 2 1.91 2c1.056 0 1.908-.893 1.908-2s-.852-2-1.909-2Z"
        />
    </svg>
)
export default BooleanIcon
