import dayjs from "dayjs";
import {Enum} from "common/Enum";
import utc from "dayjs/plugin/utc";
import {Optional} from "common/Optional";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {DateFilterType} from "app/query/filters/DateFilterType";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

/**
 * Supported date grains for the filter UI.
 */
export class DateFilterGrain extends Enum {

    static DATE = new this(
        'date',
        'Date',
        'YYYY-MM-DD',
        'date',
        'YYYY-MM-DD',
        'MM/DD/YYYY'
    );

    static DATETIME = new this(
        'datetime',
        'Date & Time',
        'YYYY-MM-DD hh:mm:ss',
        'datetime-local',
        'YYYY-MM-DD[T]hh:mm[:ss]',
        'MM/DD/YYYY hh:mm:ss'
    );

    /**
     * Try to infer the grain from an existing filter.
     */
    static fromFilter(filter: LiteralsFilterClause): Optional<DateFilterGrain> {
        if (filter.values.length < 1) {
            return Optional.none();
        }

        if (dayjs(filter.values[0], DateFilterGrain.DATE.arcQLFormat, true).isValid()) {
            return Optional.of(DateFilterGrain.DATE);
        } else if (dayjs(filter.values[0], DateFilterGrain.DATETIME.arcQLFormat, true).isValid()) {
            return Optional.of(DateFilterGrain.DATETIME);
        }
        return Optional.none();
    }

    /**
     * Return the supported grains for the given type.
     */
    static supported(type: DateFilterType): DateFilterGrain[] {
        if (type === DateFilterType.RELATIVE) {
            return [this.DATE];
        } else {
            return this.enums();
        }
    }

    private constructor(
        name: string,
        public readonly label: string,
        // how ArcQL expects dates
        public readonly arcQLFormat: string,
        // how the inputs expects dates
        public readonly inputType: string,
        public readonly inputFormat: string,
        public readonly displayFormat: string
    ) {
        super(name);
    }

    /**
     * Time in epoch milliseconds to ArcQL format.
     */
    epochToArcQL(value: number): string {
        return dayjs(value).utc().format(this.arcQLFormat);
    }

    /**
     * Date of the current format to epoch.
     */
    arcQLToEpoch(value: string): number {
        return dayjs.utc(value, this.arcQLFormat).valueOf();
    }

    arcQLToInput(value: string): string {
        return dayjs(value, this.arcQLFormat).format(this.inputFormat);
    }

    inputToArcQL(value: string): string {
        return dayjs(value, this.inputFormat).format(this.arcQLFormat);
    }

    epochToDisplay(value: number): string {
        return dayjs(value).utc().format(this.displayFormat);
    }

}

DateFilterGrain.finalize();