import styled from '@emotion/styled';
import {ProcessFlowIcon} from 'app/query/hypergraph/nodes/ProcessFlowIcon';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export class NodeDetailsS {

    static readonly Header = styled.div`
        display: flex;
        align-items: center;
        padding: 14px 24px;
        border-bottom: 1px solid #757575;
    `;

    static readonly NodeIcon = styled(ProcessFlowIcon)`
        margin-right: 8px;
    `;

    static readonly NodeTitle = styled.div`
        font-weight: 500;
        flex: 1;
    `;

    static readonly CloseIcon = styled(CloseOutlinedIcon)`
        padding-left: 8px;
        color: rgba(255, 255, 255, 0.56);
        width: 18px;
        cursor: pointer;
    `;

    static readonly Body = styled.div`
        padding: 20px 24px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    `;

    static readonly Details = styled.div`
        margin-bottom: 6px;
        overflow: hidden auto;
    `;

}