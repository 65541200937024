import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {CheckCircle, RadioButtonUncheckedOutlined} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

/**
 * Common styles for list items, particularly within context of Grouping/Field selection and update.
 */
export class ListS {

    static readonly Container = styled.div`
        flex: 1;
        overflow: auto;
    `;

    static readonly List = styled(List)`
        padding: 0;
    `;

    static readonly ItemContainer = styled.div`
        border-top: 1px solid ${Colors.borderGrey};
        border-left: 1px solid ${Colors.borderGrey};
        border-right: 1px solid ${Colors.borderGrey};
        border-radius: 2px;

        box-sizing: border-box;
        padding: 6px 8px 6px 0;
        width: 100%;
        background-color: white;

        display: flex;
        align-items: center;
        cursor: pointer;

        :last-of-type {
            border-bottom: 1px solid ${Colors.borderGrey};
        }

        :hover {
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        }
    `;

    static readonly StartIcon = styled(ListItemIcon)`
        padding: 0 8px 0 4px;
        min-width: 18px;
    `;

    static readonly ThemedCheckedRadio = styled(CheckCircle)`
        color: ${Colors.hyperarcYellow};
    `;

    static readonly OpenRadio = styled(RadioButtonUncheckedOutlined)`
        color: ${Colors.borderGrey};
    `;

    static readonly ItemText = styled(ListItemText)`
        > span {
            line-height: ${FontSizes.medium};
            color: ${Colors.textSecondary};
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `;
}