import {JsonObject} from "common/CommonTypes";
import {FilterSetData} from "metadata/filterset/FilterSetData";
import {FilterSetType} from "metadata/filterset/FilterSetType";
import {FilterSetValueList} from "metadata/filterset/FilterSetValueList";

/**
 * Factory to help generate Filter Set data.
 */
export class FilterSetDataFactory {

    /**
     * JSON deserialization may come from different sources such as from the API call or from local storage.
     * Additionally, some extra attributes are needed for proper deserialization logic based on the type.
     */
    static fromJSON(json: JsonObject): FilterSetData {
        const type: FilterSetType = FilterSetType.get(json['filterSetType']);
        switch (type) {
            case FilterSetType.LIST:
                return FilterSetValueList.fromJSON(json);
            default:
                throw new Error(`Unsupported filter set data type: ${type}`);
        }
    }
}
