import {Enum} from "common/Enum";

/**
 * Statuses where ordinal provides an easy way to filter by healthy vs unhealthy states.
 *
 * @author zuyezheng
 */
export class ActorStatus extends Enum {

    // starting state
    static INITIALIZING = new this('initializing');
    // initialization failed
    static INITIALIZATION_ERROR = new this('initialization_error');
    // error after initialization that isn't recoverable
    static ERROR = new this('error');

    // special case of the first "ready" state after being initialized
    static INITIALIZED = new this('initialized');
    // ready to do stuff
    static READY = new this('ready');
    // busy doing stuff, whatever stuff the actor might do
    static BUSY = new this('busy');

    /**
     * If it's a steady state status.
     */
    public isSteady() {
        return this.ordinal >= ActorStatus.INITIALIZED.ordinal;
    }

    /**
     * If busy initializing or doing some other work.
     */
    public isBusy() {
        return this === ActorStatus.INITIALIZING || this === ActorStatus.BUSY;
    }

}
ActorStatus.finalize();