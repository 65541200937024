import React, {FunctionComponent, useEffect, useState} from "react";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";
import {GlobalAnswerWithNodes, NodeDetail} from "metadata/search/GlobalAnswerWithNodes";
import {Optional} from "common/Optional";
import {AiSummaryNodeSearchResult} from "app/components/search/AiSummaryNodeSearchResult";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ProcessFlowIcon} from "app/query/hypergraph/nodes/ProcessFlowIcon";
import ListItemText from "@mui/material/ListItemText";
import styled from "@emotion/styled";
import {SkeletonS} from "app/components/search/SkeletonS";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import {Colors} from "app/components/StyleVariables";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {Markdown} from 'app/components/Markdown';

type Props = {
    searchTerm: string
    onSelect: (selected: NavigableSearchResult) => void
    className?: string
}

/**
 * Displays the AI summary from the global answerWithNodes api. While fetching the answer, displays a skeleton loader.
 */
export const HyperGraphAnswer: FunctionComponent<Props> = (props: Props) => {
    const [answer, setAnswer] = useState<Optional<GlobalAnswerWithNodes>>(Optional.none());

    useEffect(() => {
        setAnswer(Optional.none());

        const controller = new AbortController();
        ServiceProvider.get(MetadataService).hyperGraphGlobalAnswer(props.searchTerm, controller.signal)
            .then(response => response.match(
                result => {
                    setAnswer(Optional.of(result));
                },
                () => {
                    // Just set an empty answer to hide the entire block
                    setAnswer(Optional.of(GlobalAnswerWithNodes.empty()));
                })
            );

        return () => controller.abort();
    }, [props.searchTerm]);

    const buildNodeItem = (nodeDetail: NodeDetail) => {
        return <div key={nodeDetail.node.id}>
            <S.NodeListItem
                alignItems="flex-start"
                onClick={() => props.onSelect(new AiSummaryNodeSearchResult(nodeDetail.queryFqn, nodeDetail.node.id))}
            >
                <S.NodeIcon>
                    <ProcessFlowIcon operation={nodeDetail.node.request.operation} isCompact={true}/>
                </S.NodeIcon>
                <ListItemText
                    primary={nodeDetail.node.label(null)}
                    secondary={nodeDetail.summary}
                />
            </S.NodeListItem>
        </div>;
    };

    const buildNodeItems = (nodeDetails: NodeDetail[]) => {
        // Only want to show at most 3 nodes
        const items = nodeDetails.slice(0, 3).map(buildNodeItem);

        return <S.NodeList>
            { items }
        </S.NodeList>;
    };

    // If there is no answer/empty answer, just hide the component
    if (answer.map(a => a.answer === null).getOr(false)) {
        return null;
    }

    const buildPlaceholder = () => {
        return <S.GlobalAnswer>
            <S.Summary>
                <S.Title>
                    <AutoAwesomeOutlinedIcon />
                    <SkeletonS.GradientText>Searching "{props.searchTerm}"...</SkeletonS.GradientText>
                </S.Title>
                <SkeletonS.Line className="answer shortest"/>
                <SkeletonS.Line className="answer longest"/>
                <SkeletonS.Line className="answer midlength"/>
                <SkeletonS.Line className="answer "/>
                <SkeletonS.Line className="answer shortest endparagraph"/>

                <SkeletonS.Line className="answer longest"/>
                <SkeletonS.Line className="answer "/>
                <SkeletonS.Line className="answer shortest"/>
                <SkeletonS.Line className="answer midlength"/>
                <SkeletonS.Line className="answer shortest"/>
            </S.Summary>
            <S.TopSources>
                <S.Title>Top Sources</S.Title>
                <S.SourcesGroup>
                    {
                        [1, 2, 3].map(i =>
                            <S.Sources key={i}>
                                <SkeletonS.Line className="source icon"/>
                                <div className="source-group">
                                    <SkeletonS.Line className="source block"/>
                                    <SkeletonS.Line className="source block"/>
                                    <SkeletonS.Line className="source block small"/>
                                </div>
                            </S.Sources>)
                    }
                </S.SourcesGroup>
            </S.TopSources>
        </S.GlobalAnswer>;
    };

    const buildAnswer = (answer: GlobalAnswerWithNodes) => {
        return <S.GlobalAnswer>
            <S.Summary>
                <S.Title>
                    <AutoAwesomeOutlinedIcon />
                    <S.SummaryTitle>HyperGraph Answer</S.SummaryTitle>
                </S.Title>
                <S.Answer>
                    <Markdown>{answer.answer}</Markdown>
                </S.Answer>
            </S.Summary>
            <S.TopSources>
                <S.Title>Top Sources</S.Title>
                { buildNodeItems(answer.nodeDetails) }
            </S.TopSources>
        </S.GlobalAnswer>;
    };

    return <S.Container className={props.className}>
        { answer.match(buildAnswer, buildPlaceholder) }
    </S.Container>;
};

class S {

    static readonly Container = styled.div`
        height: 425px;
        flex: none;
        overflow: hidden;
        margin-bottom: 20px;
    `;

    static readonly GlobalAnswer = styled.div`
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        padding: 16px 30px;
    `;

    static readonly Summary = styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
    `;

    static readonly Answer = styled.div`
        margin-top: 8px;
        text-wrap: wrap;
        overflow: auto;
    `;

    static readonly Title = styled.div`
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 18px;

        svg {
            color: ${Colors.hyperarcYellow};
            width: 16px;
            margin-right: 4px;
        }
    `;

    static readonly SummaryTitle = styled.div`
        color: ${Colors.textSecondary};
    `;

    static readonly TopSources = styled.div`
        width: 350px;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 60px;
    `;

    static readonly SourcesGroup = styled.div`
        padding: 1rem 0;
        display: flex;
        flex-direction: column;

        > * + * {
            margin-top: 1rem;
        }
    `;

    static readonly Sources = styled.div`
        display: flex;
        flex-direction: row;
        background-color: #FFFFFF;
        padding: 12px 18px 18px 12px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

        > * + * {
            margin-left: 1rem;
        }
    `;

    static readonly NodeList = styled(List)`
        padding: 0;
        overflow: auto;
        flex: 1;
    `;

    static readonly NodeListItem = styled(ListItemButton)`
        &.MuiListItemButton-root {
            border-radius: 8px;
            border: 1px #D9D9D9;
        }
    `;

    static readonly NodeIcon = styled(ListItemIcon)`
        min-width: 36px;
    `;

}