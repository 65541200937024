import {SubscriptionMetadata} from "metadata/subscription/SubscriptionMetadata";
import {FQN} from "common/FQN";
import {UserSubscriptionType} from "metadata/subscription/UserSubscriptionType";

export type AlertSubscriptionMetadataJson = {
    trendId: string,
    trendFqn: string,
    createdBy: string,
    createdOn: string,
}

/**
 * Metadata for an alert subscription.
 */
export class AlertSubscriptionMetadata implements SubscriptionMetadata {

    constructor(
        public readonly trendId: string,
        public readonly trendFqn: FQN,
        public readonly createdBy: string,
        public readonly createdOn: string,
    ) {
    }

    static fromJSON(json: AlertSubscriptionMetadataJson): AlertSubscriptionMetadata {
        return new AlertSubscriptionMetadata(
            json.trendId,
            FQN.parse(json.trendFqn),
            json.createdBy,
            json.createdOn
        );
    }

    get type(): UserSubscriptionType {
        return UserSubscriptionType.ALERT;
    }
}