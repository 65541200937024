import { Enum } from "common/Enum";

/**
 * The "abstract" operation type for a node. A node of a single type can have different operations such as a query
 * node being primarily a change in "measures" or "groups". Nodes of different types can also have the same operation.
 *
 * @author zuyezheng
 */
export class HyperGraphNodeOperation extends Enum {

    // system action
    static readonly SYSTEM = new this('system');
    // user interaction
    static readonly USER = new this('user');

    // agent actions
    static readonly DISCOVERY = new this('discovery');
    static readonly VALIDATION = new this('validation');
    static readonly CONSOLIDATION = new this('consolidation');

    // changes to the query
    static readonly MEASURES = new this('measures');
    static readonly GROUPS = new this('groups');
    static readonly SEGMENTS = new this('segments');

    // aggregate steps
    static readonly COMPARE = new this('compare');

}
HyperGraphNodeOperation.finalize();
