import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";
import {SearchResponse} from "metadata/search/SearchResponse";

export class AssetsSearchResponse implements SearchResponse<AssetSearchResult> {

    static fromJSON(json: JsonObject): AssetsSearchResponse {
        return new AssetsSearchResponse(
            json['results'].map(AssetSearchResult.fromJSON),
            json['total']
        );
    }

    constructor(
        public readonly results: AssetSearchResult[],
        public readonly total: number
    ) {
    }

    get optional(): Optional<AssetsSearchResponse> {
        if (this.results.length === 0) {
            return Optional.none();
        } else {
            return Optional.some(this);
        }
    }

}
