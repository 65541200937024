import * as React from "react";
import {FunctionComponent, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {StoriesProps} from "app/components/detailpanel/StoriesPanel";
import {Story} from "metadata/asset/story/Story";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {Colors} from "app/components/StyleVariables";
import {CreatedInfo} from "app/components/decoration/CreatedInfo";
import {Optional} from "common/Optional";
import {StoryPlayer} from "app/components/detailpanel/StoryPlayer";
import {LoadingMask} from "app/components/decoration/LoadingMask";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";


/**
 * Lists stories for a targeted asset.
 *
 * @author zuyezheng
 */
export const StoriesList: FunctionComponent<StoriesProps> = (props: StoriesProps) => {

    const [stories, setStories] = useState<Optional<Story[]>>(Optional.none());
    const [selectedStory, setSelectedStory] = useState<Optional<Story>>(Optional.none());

    // load stories for the current asset
    useEffect(() => {
        ServiceProvider.get(MetadataService)
            .listStories(props.targetAsset.fqn, true)
            .then(response => response.match(
                stories => setStories(Optional.some(stories)),
                error => {
                    setStories(Optional.some([]));
                    ServiceProvider.get(NotificationsService).publish(
                        'storiesList', NotificationSeverity.ERROR, `Failed to load stories: ${error.prettyPrint()}`
                    );
                }
            ));
    }, []);

    const listComponent = (stories: Story[]) => {
        return stories.map((story, i) =>
            <S.Story
                key={i}
                onClick={() => onClickStory(story)}
            >
                { story.sceneDataUrl && <img src={story.sceneDataUrl} alt={story.caption}/> }
                <div>{story.label}</div>
                <CreatedInfo
                    createdBy={story.createdBy}
                    createdOn={story.createdOn}
                />
            </S.Story>
        );
    };

    const onClickStory = (story: Story) => {
        setSelectedStory(Optional.some(story));
    };

    const onClosePlayer = () => {
        setSelectedStory(Optional.none());
    };

    return <S.Container>
        <S.Stories>
        {
            stories.map(stories => <>{listComponent(stories)}</>)
                .getOr(<LoadingMask />)
        }
        </S.Stories>
        {
            selectedStory.map(story =>
                <StoryPlayer
                    story={story}
                    onClose={onClosePlayer}
                />
            ).nullable
        }
    </S.Container>;

};

class S {

    static Container = styled.div`
        padding: 10px;
        display: flex;
        flex: 1;
        overflow: auto;
        position: relative;
    `;

    static Stories = styled.div`
    `;

    static Story = styled.div`
        margin-bottom: 10px;
        border: 1px solid ${Colors.borderGrey};
        border-radius: 4px;
        padding: 6px;
        cursor: pointer;
        
        img {
            width: 100%;
        }
    `;

}