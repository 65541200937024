import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";
import {CommonS} from "app/components/CommonS";
import {UserContext} from "app/UserContext";
import React, {FunctionComponent} from "react";


const S = {
    Container: styled.div`
        display: flex;
        align-items: center;
    `,
    Details: styled.div`
        display: flex;
        flex-direction: column;
        padding-left: 8px;
    `
};

type Props = {
    className?: string,
    showDetails: boolean,
    onClick?: () => void
}

export const UserProfile: FunctionComponent<Props> = (props: Props) => {

    return <S.Container className={props.className}>
        <UserContext.Consumer>{
            userContext => userContext.user.map(
                user => <>
                    <Avatar
                        onClick={props.onClick}
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: 16,
                            fontWeight: 800,
                            height: 30,
                            width: 30
                        }}
                        src={user.picture}
                    >{user.initials}</Avatar>
                    {
                        props.showDetails && <S.Details>
                            <div>@{user.username}</div>
                            <CommonS.MinorText>{user.email}</CommonS.MinorText>
                        </S.Details>
                    }
                </>
            )
            .getOr(<></>)
        }</UserContext.Consumer>
    </S.Container>;

};