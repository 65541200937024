import {Tuple} from "common/Tuple";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";

export class ModifyWidgetConfig implements ArcMetadataChange<ArcDashboard> {

    constructor(
        public readonly widgetId: string,
        public readonly config: Map<string, any>,
        // if partial config, merge will be used instead of replace
        public readonly partial: boolean
    ) { }

    apply(metadata: ArcDashboard) {
        return Tuple.of(
            metadata.with({
                widgets: metadata.widgets.withChange(
                    this.widgetId,
                    (widget) => widget.withConfigs(this.config, this.partial)
                )
            }),
            // fully restore to previous state
            new ModifyWidgetConfig(this.widgetId, metadata.widgets.get(this.widgetId).config, false)
        );
    }

    describe(): string {
        return `Modified config for widget ${this.widgetId}.`;
    }

    get changeType(): ChangeType {
        return ChangeType.MODIFY;
    }
}