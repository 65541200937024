import * as React from "react";
import {FunctionComponent} from "react";
import {ConfigEditorProps} from "app/dashboard/widgets/config/AppWidgetConfigPartType";
import {StyleEnumButtonMenu} from "app/dashboard/widgets/config/StyleEnumButtonMenu";
import {HorizontalAlignment} from "metadata/dashboard/widgets/config/HorizontalAlignment";
import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";
import {ImageConfigPart} from "metadata/dashboard/widgets/config/image/ImageConfigPart";
import {ImageScale} from "metadata/dashboard/widgets/config/ImageScale";

interface EditorProps extends ConfigEditorProps<ImageConfigPart> {
}

/**
 * Editor for updating image widget styling like alignment and scaling.
 */
export const ImageConfigToolbar: FunctionComponent<EditorProps> = (props: EditorProps) => {

    const onAlignmentChange = (alignment: HorizontalAlignment) => {
        props.onConfigPropertyChange(props.config.with({alignment: alignment}));
    };

    const onScaleChange = (scale: ImageScale) => {
        props.onConfigPropertyChange(props.config.with({scale: scale}));
    };


    return <>
        <StyleEnumButtonMenu
            id="horizontal-alignment"
            tooltipCategorization={"Image Alignment"}
            current={props.config.getValue('alignment')}
            onStylingChange={onAlignmentChange}
            styles={HorizontalAlignment.enums<StylingEnum>()}
        />
        <StyleEnumButtonMenu
            id="image-scale"
            tooltipCategorization={"Image Scale"}
            current={props.config.getValue('scale')}
            onStylingChange={onScaleChange}
            styles={ImageScale.enums<StylingEnum>()}
        />
    </>;
};