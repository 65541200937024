
/**
 * Utils for parsing and serializing JSON.
 *
 * @author zuyezheng
 */
export class JsonUtils {

    /**
     * Object to a map.
     */
    static toMap<T>(
        json: {[key: string]: any},
        valueMapper: (v: any, k: string) => T
    ): Map<string, T> {
        return new Map(
            Object.entries(json).map(([key, value]) => [
                key, valueMapper(value, key)
            ])
        );
    }

    static toMapWithKey<K, V>(
        json: {[key: string]: any},
        keyMapper: (k: string) => K,
        valueMapper: (v: any, k: string) => V
    ): Map<K, V> {
        return new Map(
            Object.entries(json).map(([key, value]) => [
                keyMapper(key), valueMapper(value, key)
            ])
        );
    }

    /**
     * Map to object.
     */
    static fromMap(map: Map<string, any>): {[key: string]: any} {
        return Object.fromEntries(map);
    }

    /**
     * Merge two JSON objects, with the second one overriding keys of the first if conflict and adding new keys if not.
     */
    static merge<T>(first: T, second: T): T {
        return {...first, ...second};
    }
}