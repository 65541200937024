import * as React from "react";
import {ReactNode} from "react";
import {DatasetType} from "metadata/dataset/DatasetType";
import {
    ConnectionStepperHandler,
    EnterDetailProps,
    TestConnectionProps
} from "app/components/settings/connections/ConnectionStepperHandler";
import {EnterSnowflakeConnection} from "app/components/settings/connections/snowflake/EnterSnowflakeConnection";
import {TestSnowflakeConnection} from "app/components/settings/connections/snowflake/TestSnowflakeConnection";

/**
 * Handler for a Snowflake connection in the connection stepper.
 */
export class SnowflakeConnectionStepperHandler implements ConnectionStepperHandler {

    connectionType: DatasetType = DatasetType.SNOWFLAKE;

    enterDetailsStep(props: EnterDetailProps): ReactNode {
        return <EnterSnowflakeConnection
            {...props}
        />;
    }

    testConnectionStep(props: TestConnectionProps): ReactNode {
        return <TestSnowflakeConnection
            {...props}
        />;
    }


}