import * as React from "react";
import {FunctionComponent, useState} from "react";
import {GridColDef, GridRenderCellParams, GridRowParams} from "@mui/x-data-grid";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import NotificationsOffOutlined from "@mui/icons-material/NotificationsOffOutlined";
import {AccountBreadCrumbInResultRow} from "app/components/search/AccountBreadCrumbInResultRow";
import {TableResultMenu} from "app/components/TableResultMenu";
import {Optional} from "common/Optional";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {DataGridPro} from "@mui/x-data-grid-pro/DataGridPro";
import {UserSubscription} from "metadata/subscription/UserSubscription";
import {TableResultAction} from "app/components/TableResultAction";
import dayjs from "dayjs";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";


interface Props {
    className?: string
    subscriptions: UserSubscription[]
    onSubscriptionSelect: (subscription: UserSubscription) => void
    onSubscriptionAction: (subscription: UserSubscription, action: TableResultAction) => void
    onAccountBreadcrumb: (account: string) => void
}

const FIELD_IDENTIFIERS = {
    ID: 'id',
    ICON: 'icon',
    NAME_AND_DESCRIPTION: 'nameAndDescription',
    CREATED_BY: 'createdBy',
    CREATED_ON: 'createdOn',
    RESULT: 'result',
    ACTIONS: 'actions'
};


/**
 * Standard actions for subscription row.
 */
export const StandardSubscriptionActions = {
    ENABLE: new TableResultAction('enable', 'Enable', <EmailOutlined/>),
    DISABLE: new TableResultAction('disable', 'Disable', <NotificationsOffOutlined/>),
    DELETE: new TableResultAction('delete', 'Delete', <DeleteOutline color='error'/>, false, Colors.formError),
};

/**
 * Table for displaying subscriptions.
 */
export const SubscriptionsTable: FunctionComponent<Props> = (props: Props) => {

    const [subSelectedForActions, setSubSelectedForActions] = useState<Optional<UserSubscription>>(Optional.none);

    const subscriptionActions = (sub: UserSubscription) => {
        return [
            sub.isActive ? StandardSubscriptionActions.DISABLE : StandardSubscriptionActions.ENABLE,
            StandardSubscriptionActions.DELETE
        ];
    };

    const onAction = (action: TableResultAction) => {
        if (subSelectedForActions.isNone) {
            return;
        }
        props.onSubscriptionAction(subSelectedForActions.get(), action);
    };


    const columns: GridColDef[] = [{
        'field': FIELD_IDENTIFIERS.ICON,
        'headerName': '',
        'renderCell': (params: GridRenderCellParams<boolean>) =>
            // if is active
            params.value ?
                <EmailOutlined/> :
                <NotificationsOffOutlined/>,
        'width': 30
    }, {
        'field': FIELD_IDENTIFIERS.NAME_AND_DESCRIPTION,
        'headerName': 'Name and Description',
        'flex': 3,
        'renderCell': (params: GridRenderCellParams<string[]>) =>
            <S.NameAndDescriptionContainer>
                <S.Name>{params.value[0]}</S.Name>
                <S.Description>{params.value[1]}</S.Description>
            </S.NameAndDescriptionContainer>
    }, {
        'field': FIELD_IDENTIFIERS.CREATED_BY,
        'headerName': 'Created By',
        'flex': 1,
        'renderCell': (params: GridRenderCellParams<string>) =>
            <AccountBreadCrumbInResultRow
                text={params.value}
                onClick={() => props.onAccountBreadcrumb(params.value)}
            />
    }, {
        'field': FIELD_IDENTIFIERS.CREATED_ON,
        'headerName': 'Date Created',
        'flex': 1
    }, {
        'field': FIELD_IDENTIFIERS.ACTIONS,
        'headerName': '',
        'width': 80,
        'renderCell': (params) => {
            return <S.ActionContainer
                className={
                    subSelectedForActions.map(
                        selected => selected.id === params.row.id ? 'SelectedActionMenu' : 'ActionMenu'
                    )
                        .getOr('ActionMenu')
                }
            >
                <TableResultMenu
                    actions={subscriptionActions(params.value)}
                    onAction={onAction}
                    onCancel={() => setSubSelectedForActions(Optional.none())}
                />
            </S.ActionContainer>;
        },
        'sortable': false
    }];

    const onRowClick = (params: GridRowParams) => {
        const sub = params.row.result as UserSubscription;
        return props.onSubscriptionSelect(sub);
    };

    const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
        if (params.field === FIELD_IDENTIFIERS.ACTIONS) {
            event.stopPropagation();
            setSubSelectedForActions(Optional.some(params.row.result as UserSubscription));
        }
    };

    return <S.ResultsTable
        className={props.className}
        columns={columns}
        rows={
            props.subscriptions.map(sub => ({
                [FIELD_IDENTIFIERS.ID]: sub.id,
                [FIELD_IDENTIFIERS.ICON]: sub.isActive,
                [FIELD_IDENTIFIERS.NAME_AND_DESCRIPTION]: [sub.label, sub.description],
                [FIELD_IDENTIFIERS.CREATED_BY]: sub.metadata.createdBy,
                [FIELD_IDENTIFIERS.CREATED_ON]: dayjs(sub.metadata.createdOn).format("MMM D, YYYY"),
                [FIELD_IDENTIFIERS.RESULT]: sub,
                [FIELD_IDENTIFIERS.ACTIONS]: sub
            }))
        }
        onRowClick={onRowClick}
        onCellClick={handleCellClick}

        pagination
        pageSize={5}
        autoHeight
        density="standard"

        disableSelectionOnClick
        disableColumnMenu
    />;
};


class S {

    static readonly ResultsTable = styled(DataGridPro)`
        border: 0;

        .MuiDataGrid-columnSeparator {
            display: none;
        }

        .MuiDataGrid-row:hover .ActionMenu {
            visibility: visible;
        }

        .SelectedActionMenu {
            visibility: visible;
        }
    `;

    static readonly NameAndDescriptionContainer = styled.div`
        display: flex;
        flex-direction: column;
    `;

    static readonly Name = styled.span`
        font-size: ${FontSizes.medium};
        color: ${Colors.textPrimary};
    `;

    static readonly Description = styled.span`
        font-size: ${FontSizes.small};
        color: ${Colors.textTertiary};
    `;

    static readonly ActionContainer = styled.div`
        visibility: hidden;
    `;
}
