import {Column} from "metadata/Column";
import {AnalyticsType} from "metadata/AnalyticsType";
import {JsonObject} from "common/CommonTypes";
import {OrderedMap} from "common/OrderedMap";

/**
 * Utility + instance generation for both v1 + v2 datasets.
 */
export class DatasetFactory {

    static processColumns(json: Map<string, JsonObject>): OrderedMap<string, Column> {
        // map to Columns for easier handling
        let columns: Column[] = Object.values(json).map(Column.fromJSON);

        // sort all fields for predictable order in the UI
        columns.sort((a: Column, b: Column) => a.label.localeCompare(b.label));

        // process the sorted columns to group children by parent, since all columns were sorted, the children
        // will remain sorted when added to the parent as well
        const childrenByParent: Map<string, Column[]> = new Map();
        columns.forEach(column =>
            column.parentField.forEach(parentField => {
                let children = childrenByParent.get(parentField);
                if (children == null) {
                    children = [];
                    childrenByParent.set(parentField, children);
                }

                children.push(column);
            })
        );

        columns = columns.map(column => {
            if (childrenByParent.has(column.name)) {
                return column.withChildren(
                    // build any relative labels
                    childrenByParent.get(column.name).map(c => c.withRelativeLabel(column))
                );
            } else {
                // no kids, smart column
                return column;
            }
        });

        return OrderedMap.fromKeyed(columns, c => c.name);
    }

    static filterColumnsByAnalyticsType(fields: Column[], analyticsType: AnalyticsType): Map<string, Column> {
        return new Map(
            fields.filter((field) => field.analyticsType === analyticsType)
                .map(field => [field.name, field])
        );
    }
}