import React, {FunctionComponent, useRef, useState} from 'react';
import {IconButton} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TypedMenu} from "app/components/TypedMenu";
import {TableResultAction} from "app/components/TableResultAction";

interface Props {
    actions: TableResultAction[]
    onAction: (action: TableResultAction) => void
    onCancel: () => void
}

/**
 * Table result menu component.
 */
export const TableResultMenu: FunctionComponent<Props> = (props) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const onMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const onMenuAction = (action: TableResultAction) => {
        props.onAction(action);
        setShowMenu(false);
    };

    const onCloseMenu = () => {
        props.onCancel();
        setShowMenu(false);
    };

    return (
        <div ref={anchorRef}>
            <IconButton onClick={onMenuClick}>
                <MoreHorizIcon/>
            </IconButton>
            {showMenu &&
                <TypedMenu
                    el={anchorRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    items={[props.actions]}
                    itemId={item => item.id}
                    itemLabel={item => item.label}
                    itemColor={item => item.color}
                    itemIcon={item => item.icon}
                    onSelect={onMenuAction}
                    onClose={onCloseMenu}
                />
            }
        </div>
    );
};