import {JsonObject} from "common/CommonTypes";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {VizSelection} from "engine/actor/VizSelection";
import {VizSelectionFactory} from "engine/actor/VizSelectionFactory";
import {GlobalFilterClauseFactory} from "metadata/dashboard/GlobalFilterClauseFactory";
import {JsonUtils} from "metadata/JsonUtils";
import {FQN} from "common/FQN";
import {ReferencingFilterClause} from "metadata/query/filterclause/ReferencingFilterClause";
import {References} from "metadata/References";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {SessionType} from "metadata/session/SessionType";

export type DashboardStateJson = {
    type: string,
    globalFilters: JsonObject[];
    selections: { [key: string]: JsonObject };
};

export type DashboardStateProps = {
    globalFilters: GlobalFilterClause[],
    selections: Map<string, VizSelection>
};

export class DashboardState {
    constructor(
        public readonly globalFilters: GlobalFilterClause[],
        public readonly selections: Map<string, VizSelection>
    ) {
    }

    /**
     * Given a JSON, create the appropriate DashboardState instance. Async is required as it requires potentially
     * fetching references.
     */
    static async fromJSON(json: DashboardStateJson): Promise<DashboardState> {

        const refs = await this.getReferences(json);

        const globalFilters = json.globalFilters.map(
            (clauseJson: JsonObject) => GlobalFilterClauseFactory.fromJSON(clauseJson, refs)
        );

        const selections: Map<string, VizSelection> = new Map();
        Object.entries(json.selections).forEach(([key, selectionJson]) => {
            selections.set(key, VizSelectionFactory.fromJSON(selectionJson));
        });

        return new DashboardState(globalFilters, selections);
    }

    with({
        globalFilters,
        selections
    }: Partial<DashboardStateProps>): DashboardState {
        return new DashboardState(
            globalFilters == null ? this.globalFilters : globalFilters,
            selections == null ? this.selections : selections
        );
    }

    /**
     * From the raw state JSON, fetch the appropriate references (such as from global filters)
     */
    private static async getReferences(json: DashboardStateJson): Promise<References> {

        const unreferencedClauses = json.globalFilters.map(
            (clauseJson: JsonObject) => GlobalFilterClauseFactory.fromJSON(clauseJson, null)
        );

        // collect FQNs to hydrate
        const fqnsToReference: FQN[] = unreferencedClauses
            .filter(c => c.type.hasAssetRefs)
            .map((gf) => gf.toBaseFilterClause())
            .map((c: ReferencingFilterClause) => FQN.parse(c.fullyQualifiedName));

        return await ServiceProvider.get(MetadataService)
            .fetchReferences(new Set(fqnsToReference))
            .then(e => e.rightOrThrow());
    }

    toJSON(): JsonObject {
        const dashboardStateJson: DashboardStateJson = {
            type: SessionType.DASHBOARD.name,
            globalFilters: this.globalFilters,
            selections: JsonUtils.fromMap(this.selections)
        };
        return dashboardStateJson;
    }
}
