import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";
import React, {FunctionComponent, useState} from "react";
import {IconButton} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";

interface Props {
    id: string
    /** Describe what the styling enum is affecting stylistic-wise (ex: box shadow). */
    tooltipCategorization: string
    current: StylingEnum
    onStylingChange: (s: StylingEnum) => void
    styles: StylingEnum[]
}

/**
 * Button that opens a menu to display current styling option and select a new.
 */
export const StyleEnumButtonMenu: FunctionComponent<Props> = (props: Props) => {
    const [anchorMenu, setAnchorMenu] = useState<HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorMenu(null);
    };

    return (
        <>
            <Tooltip title={props.tooltipCategorization} placement="top" arrow>
                <IconButton
                    aria-controls={`${props.id}-menu`}
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                >
                    <props.current.icon/>
                </IconButton>
            </Tooltip>
            <S.Menu
                id={`${props.id}-menu`}
                anchorEl={anchorMenu}
                keepMounted
                open={Boolean(anchorMenu)}
                onClose={handleMenuClose}
            >
                {props.styles.map((s) => (
                    <Tooltip key={s.name} title={s.label} placement="right">
                        <MenuItem
                            selected={s === props.current}
                            onClick={() => {
                                props.onStylingChange(s);
                                handleMenuClose();
                            }}
                        >
                            <s.icon/>
                        </MenuItem>
                    </Tooltip>
                ))}
            </S.Menu>
        </>
    );
};

const S = {

    Menu: styled(Menu)`
        && .Mui-selected {
            background-color: #F8BF0050;
        }
    `
};