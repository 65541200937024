import {Enum} from "common/Enum";
import {ChartMetadata} from "metadata/dashboard/widgets/ChartMetadata";
import {MarkdownMetadata} from "metadata/dashboard/widgets/MarkdownMetadata";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {LinkMetadata} from "metadata/dashboard/widgets/LinkMetadata";
import {ImageMetadata} from "metadata/dashboard/widgets/ImageMetadata";

interface WidgetMetadataConstructor {

    new(type: WidgetType, id: string, label: string, description: string, config: Map<string, any>): WidgetMetadataBound;

}

/**
 * Enumerates the types of widgets supported on a dashboard with polymoprphic metadata.
 *
 * @author zuyezheng
 */
export class WidgetType extends Enum {

    static readonly CHART = new this(
        'chart',
        'Chart',
        ChartMetadata
    );

    static readonly MARKDOWN = new this(
        'markdown',
        'Text',
        MarkdownMetadata,
    );

    static readonly LINK = new this(
        'link',
        'Link',
        LinkMetadata,
    );

    static readonly IMAGE = new this(
        'image',
        'Image',
        ImageMetadata,
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly ctor: WidgetMetadataConstructor
    ) {
        super(name);
    }

    public placeholder(id: string, label: string): WidgetMetadataBound {
        return new this.ctor(this, id, label, '', new Map());
    }

}

WidgetType.finalize();