import {DateGrain} from "metadata/query/DateGrain";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AnalyticsType} from "metadata/AnalyticsType";
import {ArcQLFieldType} from "metadata/query/ArcQLFieldType";
import {ColumnField} from "metadata/query/ArcQLField";

/**
 * Date field use in a detail query.
 *
 * @author zuyezheng
 */
export class DetailDateField extends ColumnField {

    constructor(
        public readonly field: string,
        public readonly grain: DateGrain,
        as: string
    ) {
        super(ArcQLFieldType.DETAIL_DATE, as);
    }

    analyticsType(dataset: ArcDataset): AnalyticsType {
        return AnalyticsType.DATE;
    }

    with({grain, as}: {
        grain?: DateGrain,
        as?: string
    }): DetailDateField {
        return new DetailDateField(
            this.field,
            grain == null ? this.grain : grain,
            as == null ? this.as : as
        );
    }

    get tooltip(): string {
        return `${this.field} (${this.grain.label})`;
    }

    get prefix(): string {
        return this.grain.short;
    }

}