import * as React from "react";
import {FunctionComponent} from "react";
import styled from "@emotion/styled";
import Dialog from '@mui/material/Dialog';
import DialogContent from "@mui/material/DialogContent";
import {SearchBar} from "app/components/search/SearchBar";
import {useHistory} from "react-router-dom";
import {ScopedSearch} from "app/home/ScopedSearch";
import {ScopedSearchNone} from "app/home/ScopedSearchNone";
import {NavigableSearchResult} from "metadata/search/NavigableSearchResult";

interface Props {
    scope: ScopedSearch
    onClose: () => void
}

/**
 * Popup for global search anywhere in the app.
 *
 * @author zuyezheng
 */
export const GlobalSearch: FunctionComponent<Props> = (props: Props) => {

    const history = useHistory();
    const [searchScope, setSearchScope] = React.useState<ScopedSearch>(props.scope);

    const onSelectAutoComplete = (selected: NavigableSearchResult) => {
        history.push(selected.path);
        props.onClose();
    };

    return <S.Dialog
        open={true}
        onClose={props.onClose}
        fullWidth
        maxWidth="md"
    >
        <DialogContent>
            <S.SearchBar
                onSearch={() => {
                }}

                scopedSearch={searchScope}
                onScopeReset={() => setSearchScope(new ScopedSearchNone())}

                autoComplete
                onSelectAutoComplete={onSelectAutoComplete}
                focus
            />
        </DialogContent>
    </S.Dialog>;

};

const S = {

    Dialog: styled(Dialog)`
        .MuiPaper-root {
            background: transparent;
        }

        .MuiDialogContent-root {
            padding: 0;
        }

        .MuiFormControl-root {
            margin: 0;

            .MuiInputBase-root {
                background: white;
            }

            input {
                padding: 12px 14px;
            }
        }
    `,

    SearchBar: styled(SearchBar)`
        .autoComplete {
            position: relative;
        }
    `

};
