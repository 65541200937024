import * as React from "react"
import {SVGProps} from "react"

const TreemapIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
    >
        <path
            fill="#F8BF00"
            d="M21 5.5V12H9.33V5.5H21Zm-6.33 14V13H9.33v6.5h5.34Zm1-6.5v6.5H21V13h-5.33Zm-7.34 6.5v-14H3v14h5.33Z"
        />
    </svg>
);
export default TreemapIcon;
