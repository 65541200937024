import * as React from 'react';
import {ChangeEvent, FunctionComponent, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import {FontSizes} from 'app/components/StyleVariables';
import DialogTitle from '@mui/material/DialogTitle';
import {ColumnGroup, DashboardInteractions} from 'metadata/dashboard/DashboardInteractions';
import {ArcDataset} from 'metadata/dataset/ArcDataset';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {Column} from 'metadata/Column';
import {Button, Divider, IconButton, InputBase, Paper, Popover} from '@mui/material';
import {Optional} from 'common/Optional';
import {DatasetColumnsPicker} from "app/components/dataset/DatasetColumnsPicker";

type Props = {
    datasets: ArcDataset[],
    interactions: DashboardInteractions,

    onCreateColumnGroup: (label: string) => void,
    onDeleteColumnGroup: (label: string) => void,
    onAddColumnToGroup: (label: string, dataset: ArcDataset, column: Column) => void,
    onDeleteColumnFromGroup: (label: string, dataset: ArcDataset, column: string) => void,
    onCancel: () => void
}

type AddColumnGroup = {
    label: string,
    target: Element
}

export const InteractionsEditor: FunctionComponent<Props> = (props: Props) => {

    const [newColumnGroupLabel, setNewColumnGroupLabel] = useState<string>('');
    const [addColumnGroup, setAddColumnGroup] = useState<Optional<AddColumnGroup>>(Optional.none());

    const onChangeNewColumnGroupLabel = (e: ChangeEvent<HTMLInputElement>) => {
        setNewColumnGroupLabel(e.target.value);
    };

    const onCreateColumnGroup = () => {
        if (newColumnGroupLabel.length === 0) {
            return
        }

        props.onCreateColumnGroup(newColumnGroupLabel);
        setNewColumnGroupLabel('');
    };

    const onStartAddColumn = (group: string, e: React.MouseEvent) => {
        setAddColumnGroup(Optional.some({
            label: group,
            target: e.currentTarget
        }));
    };

    const onCancelAddColumn = () => {
        setAddColumnGroup(Optional.none());
    };

    const onAddColumn = (label: string, dataset: ArcDataset, column: Column) => {
        props.onAddColumnToGroup(label, dataset, column);
        setAddColumnGroup(Optional.none());
    };

    const groupComps = props.interactions.columnGroups.map(
        (columnGroup: ColumnGroup, columnGroupI: number) =>
            <S.ColumnGroup key={columnGroupI}>
                <S.ColumnGroupContent>
                    <S.ColumnGroupHeader>
                        <S.ColumnGroupLabel>{columnGroup.label}</S.ColumnGroupLabel>
                        <S.DeleteButton
                            className='delete'
                            onClick={() => props.onDeleteColumnGroup(columnGroup.label)}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </S.DeleteButton>
                    </S.ColumnGroupHeader>
                    <Divider orientation='horizontal' />
                    {
                        columnGroup.columns.map((column, columnI) => {
                            const dataset = props.datasets.find(d => d.fqn.equals(column.datasetFqn));

                            return <S.Column key={columnI}>
                                <S.ColumnLabel>{dataset.label} / {dataset.getLabel(column.column)}</S.ColumnLabel>
                                <S.DeleteButton
                                    className='delete'
                                    onClick={() => props.onDeleteColumnFromGroup(columnGroup.label, dataset, column.column)}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </S.DeleteButton>
                            </S.Column>
                        })
                    }
                    <S.ColumnGroupFooter>
                        <S.ColumnGroupAdd
                            variant='outlined'
                            onClick={(e) => onStartAddColumn(columnGroup.label, e)}
                        >
                            Add Column
                        </S.ColumnGroupAdd>
                    </S.ColumnGroupFooter>
                </S.ColumnGroupContent>
            </S.ColumnGroup>
    );

    return <Dialog
        open={true}
        onClose={props.onCancel}
        maxWidth={'md'}
    >
        <DialogTitle>Interactions</DialogTitle>
        <S.DialogContent dividers>
            {groupComps}
            <S.CreateColumnGroup>
                <S.CreateColumnGroupText
                    placeholder='New column group name.'
                    value={newColumnGroupLabel}
                    onChange={onChangeNewColumnGroupLabel}
                />
                <S.CreateColumnGroupDivider orientation='vertical' />
                <IconButton onClick={onCreateColumnGroup}>
                    <AddOutlinedIcon />
                </IconButton>
            </S.CreateColumnGroup>
        </S.DialogContent>
        {
            addColumnGroup.map(group =>
                <Popover
                    open={true}
                    anchorEl={group.target}
                    onClose={onCancelAddColumn}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <S.ColumnsPicker
                        datasets={props.datasets}
                        onClick={(dataset, column) => onAddColumn(group.label, dataset, column)}
                    />
                </Popover>
            ).nullable
        }
    </Dialog>

};

export const S = {

    DatasetLabel: styled.div`
        font-size: ${FontSizes.small};
        font-weight: 500;
    `,

    DialogContent: styled(DialogContent)`
        max-height: 800px;
        width: 500px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    `,

    DeleteButton: styled(IconButton)`
        padding: 2px;
        
        svg {
            height: 16px;
            width: 16px;
        }
    `,

    ColumnGroup: styled(Paper)`
        width: 100%;
        margin-bottom: 10px;
    `,

    ColumnGroupHeader: styled.div`
        display: flex;
        align-items: center;
        
        .delete {
            opacity: 0;
        }
        
        &:hover .delete {
            opacity: 1;
        }
    `,

    ColumnGroupLabel: styled.div`
        font-weight: 600;
        padding: 8px 4px;
        flex: 1;
    `,

    Column: styled.div`
        display: flex;
        align-items: center;
        padding: 4px;

        .delete {
            opacity: 0;
        }

        &:hover .delete {
            opacity: 1;
        }
    `,

    ColumnLabel: styled.div`
        flex: 1;
    `,

    ColumnGroupAdd: styled(Button)`
        width: 200px;
    `,

    ColumnGroupFooter: styled.div`
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 8px;
    `,

    ColumnGroupContent: styled.div`
        padding: 8px;
        display: flex;
        flex-direction: column;
    `,

    CreateColumnGroup: styled(Paper)`
        width: 100%;
        display: flex;
        align-items: center;
    `,

    CreateColumnGroupText: styled(InputBase)`
        flex: 1;
        padding: 4px 8px 5px;
    `,

    CreateColumnGroupDivider: styled(Divider)`
        height: 28px;
    `,

    ColumnsPicker: styled(DatasetColumnsPicker)`
        height: 450px;
        width: 400px;
        padding: 12px;
    `

};