import {Enum} from "common/Enum";

/**
 * Enum for BigQuery authentication methods.
 */
export class BigQueryAuthentication extends Enum {

    static readonly SERVICE_ACCOUNT = new this('serviceAccount', "Service Account Key");

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}

BigQueryAuthentication.finalize();

