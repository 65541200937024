import * as React from 'react';
import {FunctionComponent, ReactNode, useEffect, useState} from 'react';
import {NodeDetailsS} from 'app/query/hypergraph/selection/NodeDetailsS';
import {HyperGraph} from 'metadata/hypergraph/HyperGraph';
import {EditType, HyperGraphNode} from 'metadata/hypergraph/HyperGraphNode';
import styled from '@emotion/styled';
import {NodeRating} from 'metadata/hypergraph/NodeRating';
import {StringUtils} from 'common/StringUtils';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import TextField from '@mui/material/TextField';
import {Markdown} from 'app/components/Markdown';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";


export type Props = {
    hyperGraph: HyperGraph,
    node: HyperGraphNode,
    children?: React.ReactNode

    onChangeRating(rating: NodeRating): void
    onChangeDescription(description: string): void
}

/**
 * Body of the node details containing 2 sections:
 * Description: plain "text"/markdown, stored as a string, and optionally user editable given the node type
 * Structured Content: stored as structured objects so can't be easily edited as text. Supports interactions such
 *     as clicking on a point of interest to highlight it in the chart.
 *
 * @author zuyezheng
 */
export const NodeDetailsBody: FunctionComponent<Props> = (props: Props) => {

    const [isDescriptionHovered, setIsDescriptionHovered] = useState<boolean>(false);
    const [isDescriptionEditing, setIsDescriptionEditing] = useState<boolean>(false);
    const [description, setDescription] = useState<string>(props.node.description);

    useEffect(() => {
        setDescription(props.node.description);
    }, [props.node]);

    const canEditDescription = props.node.editTypes.has(EditType.DESCRIPTION);
    const startEditing = () => {
        if (!canEditDescription) {
            return;
        }
        setIsDescriptionEditing(true);
    };

    const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const onCommitDescriptionChange = () => {
        // see if there was a change
        if (description !== props.node.description) {
            props.onChangeDescription(description);
        }

        setIsDescriptionEditing(false);
    };

    const buildRatings = (): ReactNode => {
        // node doesn't support ratings
        if (!props.node.canRate) {
            return;
        }

        const toggle = (rating: NodeRating): void => {
            props.onChangeRating(props.node.rating === rating ? NodeRating.UNRATED : rating);
        };

        const className = (rating: NodeRating): string => {
            return StringUtils.toClassName({
                'selected': props.node.rating === rating,
                [props.node.rating.name]: true
            });
        };

        // show rating controls
        return <S.Ratings>
            <S.RatingButton className={className(NodeRating.LIKE)} onClick={() => toggle(NodeRating.LIKE)}>
                <ThumbUpOutlinedIcon />
            </S.RatingButton>
            <S.RatingButton className={className(NodeRating.DISLIKE)} onClick={() => toggle(NodeRating.DISLIKE)}>
                <ThumbDownOutlinedIcon />
            </S.RatingButton>
        </S.Ratings>;
    };

    return <NodeDetailsS.Body>
        <NodeDetailsS.Details>
            <S.Description
                onMouseLeave={() => setIsDescriptionHovered(false)}
                onMouseEnter={() => setIsDescriptionHovered(true)}
                onDoubleClick={startEditing}
            >
                {
                    isDescriptionEditing ?
                        <S.TextField
                            fullWidth
                            multiline
                            variant="filled"
                            size="small"
                            value={description}
                            onChange={onChangeDescription}
                            onBlur={onCommitDescriptionChange}
                        /> :
                        <Markdown>{description}</Markdown>
                }
                {
                    canEditDescription && isDescriptionHovered &&
                        <S.EditContainer>
                            <S.EditButton onClick={startEditing}>
                                <EditOutlinedIcon />
                            </S.EditButton>
                        </S.EditContainer>
                }
            </S.Description>
            <Markdown>{
                // TODO:  we should have a renderer for the structured content that is more visitor pattern-y to also
                //        support interactions, render as separate markdown for now.
                props.node.structuredContent(props.hyperGraph)
                    .map(c => c.embeddingContent)
                    .join('\n\n')
            }</Markdown>
        </NodeDetailsS.Details>
        {buildRatings()}
        {props.children}
    </NodeDetailsS.Body>;

};

class S {

    static readonly Description = styled.div`
    `;

    static readonly Ratings = styled.div`
        display: flex;
        justify-content: flex-end;
        margin-bottom: 6px;
    `;

    static readonly EditContainer = styled.div`
        position: sticky;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    `;

    static readonly EditButton = styled.div`
        border: 1px solid #757575;
        border-radius: 2px;
        padding: 4px;
        cursor: pointer;
        display: flex;
        
        svg {
            height: 14px;
            width: 14px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.2)
        }
    `;

    static readonly RatingButton = styled.div`
        border: 1px solid #757575;
        border-radius: 2px;
        display: flex;
        padding: 4px;
        margin-left: 6px;
        cursor: pointer;
        
        svg {
            height: 14px;
            width: 14px;
        }
        
        &:hover {
            background: rgba(255, 255, 255, 0.2)
        }

        &.selected.like {
            background: rgb(19, 128, 41);
        }

        &.selected.dislike {
            background: rgb(128, 26, 19);
        }
    `;

    static readonly TextField = styled(TextField)`
        .MuiInputBase-root {
            border-radius: 0;
            color: white;
            padding: 0 0 8px;
            background: rgba(255, 255, 255, 0.1);
            
            ::before {
                border-color: rgba(255, 255, 255, 0.87);
            }
            
            &.MuiFilledInput-underline:hover {
                ::before {
                    border-bottom: 2px solid white;
                }
            }
        }
    `;

}