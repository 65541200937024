import {ComponentType} from "react";
import {Enum} from "common/Enum";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import HeightIcon from '@mui/icons-material/Height';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";

/**
 * Enumerates the different types of image scaling (scale, stretch, etc.)
 */
export class ImageScale extends Enum implements StylingEnum {

    static readonly SCALE = new this(
        'scale',
        'Scale',
        AspectRatioIcon,
        {width: "100%", height: "100%", "objectFit": "contain"}
    );

    static readonly STRETCH = new this(
        'stretch',
        'Stretch',
        HeightIcon,
        {width: "100%", height: "100%"}
    );

    static readonly ORIGINAL = new this(
        'original',
        'Original',
        PhotoSizeSelectActualIcon,
        {}
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly icon: ComponentType,
        public readonly styling: { [prop: string]: string }
    ) {
        super(name);
    }
}

ImageScale.finalize();