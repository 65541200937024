import * as React from "react";
import {FunctionComponent, useEffect, useMemo, useRef, useState} from "react";
import {VisualizationComponents, VisualizationProps} from "app/visualizations/ArcVisualization";
import styled from "@emotion/styled";
import {ScorecardData} from "app/visualizations/data/ScorecardData";
import {Colors, FontSizes} from "app/components/StyleVariables";
import ReactFC from "react-fusioncharts";
// @ts-ignore
import FusionCharts from "fusioncharts/core";
// @ts-ignore
import Sparkline from 'fusioncharts/viz/sparkline';
import {ValueFormatter} from "common/ValueFormatter";
import {useResize} from "app/components/hooks/DomHooks";
import {Optional} from "common/Optional";

ReactFC.fcRoot(FusionCharts, Sparkline);

/**
 * Score card visualization.
 *
 * @author zuyezheng
 */
export const Scorecard: FunctionComponent<VisualizationProps> = (props: VisualizationProps) => {

    const [data, setData] = useState<Optional<ScorecardData>>(Optional.none());
    const [validationMessage, setValidationMessage] = useState<Optional<string>>(Optional.none());
    const [score, setScore] = useState<number>(0);
    const containerRef = useRef(null);
    const containerSize = useResize(containerRef);

    // process query response once
    useMemo(() => {
        ScorecardData.from(props.queryResponse).match(
            data => {
                setData(Optional.some(data));
                setValidationMessage(Optional.none());
            },
            message => {
                setValidationMessage(Optional.some(message));
                setData(Optional.none());
            }
        );
    }, [props.queryResponse]);

    useEffect(() => {
        // no data yet, nothing to animate
        if (data.isNone) {
            return;
        }

        let targetScore = data.map(d => d.score).get();
        // handle infinity and beyond
        if (typeof targetScore !== 'number') {
            targetScore = Number(targetScore);
        }

        // animated to the final value, all done
        if (targetScore === score) {
            return;
        }

        // if things are +/- infinity, math gets hard
        const clippedScore = isFinite(score) ? score : 0;
        const clippedTargetScore = isFinite(targetScore) ? targetScore : 0;

        if (!props.config.get('animate').getOr(false) || Math.abs(Math.round(clippedTargetScore - clippedScore)) <= 1) {
            // if no animations or we're close enough
            setScore(targetScore);
        } else {
            // animate a change
            const scoreAnimate = setInterval(
                () => setScore(clippedScore + ((clippedTargetScore - clippedScore) / 3)),
                10
            );
            return () => clearInterval(scoreAnimate);
        }
    }, [score, data]);

    const scoreComponents = data.map(data => {
        // figure out the color if there is a change
        const changeColor = props.config.get('change').bool
            .flatMap(() => data.change(1))
            .map(c => {
                if (c < 0) {
                    return props.config.get('positiveGreen').getOr(false) ? Colors.changeNegative : Colors.changePositive;
                } else if (c > 0) {
                    return props.config.get('positiveGreen').getOr(false) ? Colors.changePositive : Colors.changeNegative;
                } else {
                    return '';
                }
            }).getOr('');

        return <>
            <S.Title>
                {props.config.emptyableString('title').getOr(data.metric)}
            </S.Title>
            <S.Content>
                <S.Score>
                    {
                        props.config.emptyableString('prefix')
                            .map(v => <S.ScoreDecoration className="prefix">{v}</S.ScoreDecoration>)
                            .getOr(<></>)
                    }
                    {
                        props.config.get('compact').getOr(false) ?
                            ValueFormatter.compactNumber(score) :
                            ValueFormatter.roundedNumber(score)
                    }
                    {
                        props.config.emptyableString('suffix')
                            .map(v => <S.ScoreDecoration className="suffix">{v}</S.ScoreDecoration>)
                            .getOr(<></>)
                    }
                </S.Score>
                {
                    props.config.get('change').getOr(false) && <S.Change style={{'color': changeColor}}>
                        {data.change(1).map(v => <>{ValueFormatter.percentChange(v)}</>).getOr(<></>)}
                    </S.Change>
                }
            </S.Content>
            {
                containerSize.map(size => {
                    // need at least 110px to render the spark line
                    if (size[1] < 110) {
                        return <></>;
                    }

                    return props.config.get('spark').getOr(false) && <S.Spark>
                        <ReactFC
                            key={props.queryResponse.id}
                            type='sparkline'
                            width={Math.min(size[0] - 10, 240)}
                            height={40}
                            dataFormat='json'
                            dataSource={{
                                chart: {
                                    lineColor: changeColor,
                                    showLowAnchor: false,
                                    showHighAnchor: false,
                                    showOpenAnchor: false,
                                    showCloseAnchor: false,
                                    showHighLowValue: false,
                                    showOpenValue: false,
                                    showCloseValue: false,
                                    plotToolText: '$label: <b>$dataValue</b>'
                                },
                                dataset: [{
                                    data: data.sparklineData
                                }]
                            }}
                        />
                    </S.Spark>;
                }).getOr(<></>)
            }
        </>;
    }).getOr(<></>);

    return <VisualizationComponents.Container>
        <S.CardContainer ref={containerRef}>
            <S.CardPadding />
            <S.Card>
                {validationMessage.map(m => <>{m}</>).getOr(<></>)}
                {scoreComponents}
            </S.Card>
            <S.CardPadding />
        </S.CardContainer>
    </VisualizationComponents.Container>;

};

export const S = {

    CardContainer: styled.div`
        height: 100%;
        width: 100%;
        align-items: center;
        display: flex;
    `,

    CardPadding: styled.div`
        flex: 1;
    `,

    Card: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Title: styled.div`
        color: ${Colors.textSecondary};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,

    Content: styled.div`
        display: flex;
        align-items: baseline;
    `,

    Score: styled.div`
        font-size: ${FontSizes.xxLarge};
        font-weight: 500;
        white-space: nowrap;
    `,

    ScoreDecoration: styled.span`
        font-size: ${FontSizes.xLarge};
        font-weight: 500;
        
        &.prefix {
            padding-right: 2px;
        }
        
        &.suffix {
            padding-left: 2px;
        }
    `,

    Change: styled.div`
        padding-left: 10px;
        font-size: ${FontSizes.small};
    `,

    Spark: styled.div`
    
    `

};
