import * as React from "react";
import {Fragment, FunctionComponent, useEffect, useMemo, useState} from "react";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {DashboardFilterActor} from "app/dashboard/DashboardFilterActor";
import {Optional} from "common/Optional";
import {EditorFilterChange, FilterEditor, SelectedFilter} from "app/query/filters/FilterEditor";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {ArcQL} from "metadata/query/ArcQL";
import {ArcQLSourceRefType, SingleSource} from "metadata/query/ArcQLSource";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {Facet} from "engine/FacetingMessage";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Tooltip from "@mui/material/Tooltip";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {GlobalFilterEmbedMode} from "metadata/dashboard/GlobalFilterEmbedMode";
import {IconButton} from "@mui/material";
import {FQN} from "common/FQN";
import {DatasetInfo, FilterTooltip, FilterTooltipContent} from "app/dashboard/components/FilterTooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

type Props = {
    dashboard: ArcDashboard
    actor: DashboardFilterActor
    // if in view mode, global filters will be transient
    isViewMode: boolean
    // if the dashboard is embedded, global filters may be hidden or unchangeable
    isEmbed: boolean
    // Freeze the filters so they can't be modified
    freezeFilters: boolean
    // if in edit mode, also modify the metadata so changes can be persisted
    onModifyGlobalFilter: (ordinal: number, change: EditorFilterChange) => void
    // delete the global filter from the metadata
    onDeleteGlobalFilter: (ordinal: number) => void
}

/**
 * ConsolidatedFacet includes the top facet and for now, a number affiliated with link facets.
 */
type ConsolidatedFacet = {
    datasetFqn: string
    facet: Facet
    linkedFacets: number
}

export const DashboardFilterPanel: FunctionComponent<Props> = (props: Props) => {

    // current filter selection and query to power it
    const [selected, setSelected] = useState<Optional<SelectedFilter>>(Optional.none());
    const [arcqlBundle, setArcqlBundle] = useState<Optional<ArcQLBundle>>(Optional.none());

    // dataset and global filters on them
    const [datasets, setDatasets] = useState<Map<string, ArcDataset>>(new Map());
    const [globalFilters, setGlobalFilters] = useState<GlobalFilterClause[]>([]);

    // facet state by dataset fqn
    const [facets, setFacets] = useState<Map<string, Facet[]>>(new Map());

    // on creation or when the actor changes, link to it to know the current filter state
    useEffect(() => {
        props.actor.link(onGlobalFiltersChange, onFacetsChange);
        return () => props.actor.unlink();
    }, [props.actor]);

    // if the dashboard metadata changes, make sure the actor knows about it
    useEffect(() => {
        props.actor.setGlobalFiltersAndInteractions(props.dashboard.globalFilters, props.dashboard.interactions);
    }, [props.dashboard]);

    // when view mode changes, reset session global filters
    useEffect(() => {
        props.actor.clearSession();
    }, [props.isViewMode]);

    const onGlobalFiltersChange = (clauses: GlobalFilterClause[], datasets: Map<string, ArcDataset>) => {
        setDatasets(datasets);
        setGlobalFilters(clauses);
    };

    const onFacetsChange = (facets: Map<string, Facet[]>, datasets: Map<string, ArcDataset>) => {
        setDatasets(datasets);
        setFacets(facets);
    };

    const onSelectFilter = (event: React.MouseEvent, filter: GlobalFilterClause, ordinal: number) => {
        if (props.isEmbed && props.dashboard.config.globalFilterEmbedMode === GlobalFilterEmbedMode.STATIC) {
            return;
        }

        if (props.freezeFilters) {
            return;
        }

        setSelected(Optional.some({
            el: event.currentTarget,
            filter: filter,
            ordinal: ordinal,
            isAggregate: false
        }));

        const dataset = datasets.get(filter.datasetFqn.toString());
        setArcqlBundle(Optional.some(
            new ArcQLBundle(
                ArcQL.minimal(new SingleSource(ArcQLSourceRefType.DATASET, dataset.fqn)),
                dataset
            )
        ));
    };

    const onFilterChange = (change: Optional<EditorFilterChange>) => {
        change.forEach(c => {
            if (props.isViewMode) {
                props.actor.updateSessionFilter(selected.get().ordinal, c);
            } else {
                props.onModifyGlobalFilter(selected.get().ordinal, c);
            }
        });

        setSelected(Optional.none());
        setArcqlBundle(Optional.none());
    };

    const onDeleteGlobalFilter = (ordinal: number) => {
        props.onDeleteGlobalFilter(ordinal);
    };

    const clearFacet = (datasetFqn: string, fields: string[]) => {
        props.actor.clearFacets(datasetFqn, fields);
    };

    const hideGlobalFilters = props.isEmbed && props.dashboard.config.globalFilterEmbedMode === GlobalFilterEmbedMode.HIDDEN;
    const hasFiltersToShow = useMemo(() => {
        const hasFacets = Array.from(facets.values())
            .some(facets => facets.some(facet => !facet.isEmpty));

        return (globalFilters.length > 0 && !hideGlobalFilters) || hasFacets;
    }, [globalFilters, facets, props.isEmbed, props.dashboard.config.globalFilterEmbedMode]);

    const consolidatedFacets = useMemo(() => {
        const newConsolidatedFacets: ConsolidatedFacet[] = [];
        const accountedFacets: Set<string> = new Set();

        facets.forEach((facetList, datasetFqn) => {
            facetList.forEach(facet => {
                if (facet.isEmpty) {
                    return;
                }

                // a unique consolidated facet is defined by the dataset, the column, and the filter clause (operator, values)
                const key = datasetFqn + "/" + facet.columns[0] + "/" + facet.filterClause.description(true);
                if (accountedFacets.has(key)) {
                    return;
                }
                accountedFacets.add(key);

                // find linked columns for this facet
                const cols = props.dashboard.interactions.findLinkedColumns(FQN.parse(datasetFqn), facet.columns[0]);
                cols.forEach(col => {
                    // same key signature but now using linked column
                    accountedFacets.add(col.datasetFqn + "/" + col.column + "/" + facet.filterClause.description(true));
                });
                newConsolidatedFacets.push({
                    datasetFqn: datasetFqn,
                    facet: facet,
                    linkedFacets: cols.length
                });
            });
        });

        return newConsolidatedFacets;
    }, [facets, props.dashboard.interactions]);

    const fieldLabel = (dataset: ArcDataset, colName: string): string => {
        return dataset.getLabel(colName, `<missing: ${colName}>`);
    };

    // given a global filter or consolidated facet, fetch the needed tooltip metadata
    const generateFilterTooltipMetadata = (dataset: ArcDataset, fieldName: string, valueDescription: string): FilterTooltipContent => {
        const cols = props.dashboard.interactions.findLinkedColumns(dataset.fqn, fieldName);
        const datasetInfos: DatasetInfo[] = cols.map(col => {
            const dataset = datasets.get(col.datasetFqn.toString());
            return {
                datasetLabel: dataset.label,
                fieldLabel: fieldLabel(dataset, col.column)
            };
        }).filter(info => info != null);

        // add itself to the front because linked columns does not include the original column
        datasetInfos.unshift({
            datasetLabel: dataset.label,
            fieldLabel: fieldLabel(dataset, fieldName)
        });

        return {datasetInfos, valueDescription};
    };

    const buildFacetInfo = (consolidatedFacet: ConsolidatedFacet, key: string) => {
        const dataset = datasets.get(consolidatedFacet.datasetFqn);
        if (dataset == null) {
            return <></>;
        }
        const fieldLabel = consolidatedFacet.facet.filterClause.fieldsLabel(dataset);
        const tooltipContent = generateFilterTooltipMetadata(dataset, consolidatedFacet.facet.columns[0], consolidatedFacet.facet.filterClause.description(false));

        return (
            <FilterTooltip
                title={'Active Facet'}
                content={tooltipContent}
                key={key}
            >
                <S.Facet className={props.freezeFilters ? 'disabled' : ''}>
                    <CheckOutlinedIcon/>
                    <span>{fieldLabel}</span>
                    {consolidatedFacet.linkedFacets == 1 && <S.Others>(and 1 other...)</S.Others>}
                    {consolidatedFacet.linkedFacets > 1 &&
                        <S.Others>(and {consolidatedFacet.linkedFacets} others...)</S.Others>}
                    <S.Value>{consolidatedFacet.facet.filterClause.description(true)}</S.Value>
                    <Tooltip title={'Clear Facet'} arrow>
                        <S.IconButton
                            onClick={() => clearFacet(consolidatedFacet.datasetFqn, consolidatedFacet.facet.filterClause.fields)}
                            disabled={props.freezeFilters}
                        >
                            <CloseOutlinedIcon/>
                        </S.IconButton>
                    </Tooltip>
                </S.Facet>
            </FilterTooltip>
        );
    };

    const buildGlobalFilterPill = (filter: GlobalFilterClause, ordinal: number) => {
        const dataset = datasets.get(filter.datasetFqn.toString());
        if (dataset == null) {
            return <Fragment key={ordinal}></Fragment>;
        }
        const tooltipContent = generateFilterTooltipMetadata(dataset, filter.column, filter.description(false));
        return (
            <FilterTooltip
                key={`${ordinal}_${filter.column}`}
                title={'Global Filter'}
                content={tooltipContent}
            >
                <S.GlobalFilterPill.Container>
                    <S.GlobalFilterPill.ButtonGroup fullWidth>
                        <S.GlobalFilterPill.Button
                            className='main'
                            onClick={(event: React.MouseEvent<Element, MouseEvent>) => onSelectFilter(event, filter, ordinal)}
                            disabled={props.freezeFilters}
                        >
                            <S.GlobalFilterPill.MainButtonContent>
                                <FilterAltOutlinedIcon/>
                                <S.GlobalFilterPill.Label>{fieldLabel(dataset, filter.column)}</S.GlobalFilterPill.Label>
                                <S.GlobalFilterPill.Details>{filter.description(true)}</S.GlobalFilterPill.Details>
                            </S.GlobalFilterPill.MainButtonContent>
                        </S.GlobalFilterPill.Button>
                        {!props.isViewMode &&
                            <S.GlobalFilterPill.Button
                                className='suffix'
                                onClick={() => onDeleteGlobalFilter(ordinal)}
                                disabled={props.freezeFilters}
                            >
                                <DeleteOutlineOutlinedIcon/>
                            </S.GlobalFilterPill.Button>
                        }
                    </S.GlobalFilterPill.ButtonGroup>
                </S.GlobalFilterPill.Container>
            </FilterTooltip>
        );
    };

    return hasFiltersToShow && <S.Container>
        <S.Row>
            {
                !hideGlobalFilters && globalFilters
                    .map((filter: GlobalFilterClause, ordinal: number) => buildGlobalFilterPill(filter, ordinal))
            }
            {
                Optional.all([selected, arcqlBundle])
                    .map(([selected, arcqlBundle]: [SelectedFilter, ArcQLBundle]) =>
                        <FilterEditor
                            selected={selected}
                            anchorOrigin={{
                                vertical: 'bottom', horizontal: 'left',
                            }}
                            arcqlBundle={arcqlBundle}
                            onClose={onFilterChange}
                        />
                    ).nullable
            }
        </S.Row>
        <S.Row>
            {
                consolidatedFacets.map(
                    (consolidatedFacet, idx) => buildFacetInfo(
                        consolidatedFacet,
                        idx.toString()
                    )
                )
            }
        </S.Row>
    </S.Container>;

};

class S {

    static readonly Container = styled.div`
        padding: 8px 6px;
        border-bottom: 1px solid ${Colors.borderGrey};
        display: flex;
        flex-direction: column;
        background: white;
    `;

    static readonly Row = styled.div`
        display: flex;
        flex-wrap: wrap;
    `;

    static readonly Facet = styled.div`
        color: ${Colors.textSecondary};
        font-weight: 400;
        margin: 5px;
        padding: 0 4px;
        border: 1px solid ${Colors.hyperarcYellow};
        border-radius: 4px;
        font-size: ${FontSizes.xSmall};
        display: flex;
        align-items: center;
        white-space: nowrap;
        box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.06);
        max-width: 600px;
        gap: 3px;

        svg {
            padding: 8px 4px 8px 8px;
            width: 16px;
            height: 16px;
            color: ${Colors.iconSecondary};
        }

        &.disabled {
            background: #F5F5F5;
            opacity: 0.5;
        }

        &:hover {
            color: ${Colors.textPrimary};
            box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.15);
            border-color: ${Colors.hyperarcYellowSecondary};

            svg {
                color: ${Colors.iconPrimary};
            }
        }
    `;

    static readonly Others = styled.span`
        font-weight: 1200;
    `;

    static readonly Value = styled.div`
        display: flex;
        color: ${Colors.textPrimary};
        font-size: ${FontSizes.small};
        font-weight: 500;
        padding: 0 5px;
        overflow: auto;
    `;

    static readonly IconButton = styled(IconButton)`
        color: ${Colors.iconTertiary};
        padding: 0px 2px;

        &:hover {
            color: ${Colors.iconPrimary};
            background-color: transparent;
        }

        &:disabled {
            opacity: 0.3;
        }
    `;

    static GlobalFilterPill = class {

        static readonly Container = styled.div`
            padding: 3px 8px 5px;
            max-width: 600px;
        `;

        static readonly ButtonGroup = styled(ButtonGroup)`
            &:hover {
                box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.15);
            }
        `;

        static readonly Button = styled(Button)`

            text-transform: none;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.03);
            background-color: #FAFAFA;
            border-color: ${Colors.borderGrey};

            &.main {
                padding-left: 8px;
                align-items: center;

                svg {
                    width: 16px;
                    height: 16px;
                    color: ${Colors.iconSecondary};
                    padding: 2px 4px 0 0;
                }
            }

            &.suffix {
                min-width: unset;
                justify-content: center;
                align-items: center;
                width: 26px;

                > svg {
                    height: 14px;
                    width: 14px;
                }
            }


            &:hover {
                background-color: #FAFAFA;
                border-color: #EEEEEE;
            }
        `;

        static readonly MainButtonContent = styled.div`
            display: flex;
            align-items: center;
            max-width: 500px;
            width: auto;
        `;

        static readonly Label = styled.div`
            font-size: ${FontSizes.xSmall};
            color: ${Colors.textSecondary};
            font-weight: 400;
        `;

        static readonly Details = styled.div`
            font-size: ${FontSizes.small};
            color: ${Colors.textPrimary};
            font-weight: 500;
            padding-left: 5px;
        `;
    };
}
