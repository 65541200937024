import {DatasetStatusMetadata} from "metadata/dataset/DatasetStatusMetadata";
import {DatasetStatusMetadataFactory} from "metadata/dataset/DatasetStatusMetadataFactory";

type DatasetStatusJson = {
    rowCount: number;
    metadata: DatasetStatusMetadata;
}

/**
 * Dataset status details.
 */
export class DatasetStatus {

    static fromJSON(json: DatasetStatusJson): DatasetStatus {
        return new DatasetStatus(
            json.rowCount,
            DatasetStatusMetadataFactory.fromJson(json.metadata)
        );

    }

    constructor(
        public readonly rowCount: number,
        public readonly metadata: DatasetStatusMetadata
    ) {
    }

}