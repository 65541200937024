import {FQN} from "common/FQN";
import {Either, Left, Right} from "common/Either";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {JsonObject} from "common/CommonTypes";
import {domToJpeg} from 'modern-screenshot'

export class ImageService {

    saveAssetScreenshot(
        element: HTMLElement,
        assetId: string,
        fqn: FQN,
        // dom-to-image doesn't handle scrolling and will scroll elements to the top before taking a screenshot, if
        // we need to adjust for scrolling it is assumed the provided element is the scroll viewport and contains a
        // single child that scrolls and will be adjusted by changing its absolute position
        adjustScroll: boolean = false,
        // if we should persist the asset screenshot to the db which will also update the latest screenshot for the
        // asset
        persist: boolean = true,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, AssetScreenshotResponse>> {
        // store the originals incase we need to adjust for scrollg
        let originalScrollTop: number;
        let originalOverflowStyle: string;
        let scrollElement: HTMLElement;
        let originalChildPositionStyled: string;

        if (adjustScroll) {
            originalScrollTop = element.scrollTop;
            originalOverflowStyle = element.style.overflow;
            scrollElement = element.children[0] as HTMLElement;
            originalChildPositionStyled = scrollElement.style.position;

            element.scrollTop = 0;
            element.style.overflow = 'hidden';
            scrollElement.style.position = 'absolute';
            scrollElement.style.top = `${-originalScrollTop}px`;
        }

        return domToJpeg(element, {
            quality: 0.7,
            width: element.clientWidth,
            // clip height to square since we don't need much more for thumbnails
            height: Math.min(element.clientHeight, element.clientWidth),
            backgroundColor: 'white'
        }).then(dataUrl => {
            const screenshot: AssetScreenshotRequest = new AssetScreenshotRequest(assetId, dataUrl, persist);

            // restore original scroll state
            if (adjustScroll) {
                scrollElement.style.position = originalChildPositionStyled;
                scrollElement.style.top = 'initial';
                element.style.overflow = originalOverflowStyle;
                element.scrollTop = originalScrollTop;
            }

            return ServiceProvider.get(RestService)
                .post(
                    // need to pluralize the fqn.type.name for the url
                    `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/${fqn.type.plural}/${fqn.name}/screenshot`,
                    screenshot.toJSON(),
                    signal
                )
                .then(ApiResponse.success(json => AssetScreenshotResponse.fromJSON(json, dataUrl)));
        });
    }

    /**
     * Retrieves an image and returns a data url that can be used in the src attribute of an image tag
     */
    getImageDataUrl(imageUrl: string, signal?: AbortSignal): Promise<Either<ErrorResponse, string>> {
        return ServiceProvider.get(RestService)
            .fetchAbsolute(imageUrl, {
                "headers": {
                    "Content-Type": "image/png"
                },
                "signal": signal
            })
            .then(async (response: Response) => {
                if (response.status === 200) {
                    const blob: Blob = await response.blob();
                    return new Right(URL.createObjectURL(blob));
                } else {
                    const json = await response.json();
                    return new Left(ErrorResponse.of(json, response));
                }
            });
    }

}

class AssetScreenshotRequest {

    constructor(
        public readonly assetId: string,
        public readonly dataUrl: string,
        public readonly persist: boolean,
        public readonly metadata?: JsonObject
    ) {}

    toJSON(): JsonObject {
        return {
            assetId: this.assetId,
            dataUrl: this.dataUrl,
            persist: this.persist,
            metadata: this.metadata
        };
    }
}

export class AssetScreenshotResponse {

    static fromJSON(json: JsonObject, dataUrl: string): AssetScreenshotResponse {
        return new AssetScreenshotResponse(json['path'], dataUrl);
    }

    constructor(
        public readonly path: string,
        // image data url that was posted
        public readonly dataUrl: string
    ) {}

}