import {IdentityProperties, Property, UserAnalyticsService} from "services/UserAnalyticsService";

export class NoopUserAnalyticsService implements UserAnalyticsService {
    identify(userId: string, properties: IdentityProperties): void {
        console.log("Identify user: " + userId + ", properties: " + JSON.stringify(properties));
    }

    startFlow(flowId: string): void {
        console.log("Start flow: " + flowId);
    }

    track(eventName: string, additionalProperties: Record<string, Property>): void {
        console.log("Track event: " + eventName + ", properties: " + JSON.stringify(additionalProperties));
    }
}