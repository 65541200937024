import {VizDefinition} from "app/visualizations/definition/VizDefinition";
import {VizProperty} from "app/visualizations/definition/property/VizProperty";
import {VizTheme} from "app/visualizations/VizTheme";
import {CommonChartProperties} from "app/visualizations/definition/CommonChartProperties";
import {VizBooleanProperty} from "app/visualizations/definition/property/VizBooleanProperty";
import {VizStringProperty} from "app/visualizations/definition/property/VizStringProperty";

/**
 * @author zuyezheng
 */
export class TreemapChartDefinition extends VizDefinition {

    static PROPERTIES: VizProperty<any>[] = [].concat(
        CommonChartProperties.COMMON,
        CommonChartProperties.LEGEND,
        [
            new VizStringProperty('legendCaption', 'Legend Caption'),
            new VizBooleanProperty('reverseColors', 'Reverse Colors')
        ]
    );

    static DEFAULTS = new Map<string, any>([
        ['theme', VizTheme.HYPERARC.name],
        ['showLegend', true]
    ]);

    get properties(): VizProperty<any>[] {
        return TreemapChartDefinition.PROPERTIES;
    }

    get defaults(): Map<string, any> {
        return TreemapChartDefinition.DEFAULTS;
    }

}