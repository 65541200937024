import * as React from "react"
import {SVGProps} from "react"

const MultiSelectIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        {...props}
    >
        <path
            fill="#000"
            fillOpacity={0.54}
            d="M9.88 1.56H2.6A1.04 1.04 0 0 0 1.56 2.6v7.28c0 .572.463 1.04 1.04 1.04h7.28a1.04 1.04 0 0 0 1.04-1.04V2.6a1.04 1.04 0 0 0-1.04-1.04ZM5.2 8.84l-2.6-2.6.733-.733L5.2 7.368l3.947-3.946.733.738L5.2 8.84ZM9.88 14.12v7.28H2.6v-7.28h7.28Zm0-1.04H2.6c-.572 0-1.04.468-1.04 1.04v7.28c0 .572.468 1.04 1.04 1.04h7.28c.572 0 1.04-.468 1.04-1.04v-7.28c0-.572-.468-1.04-1.04-1.04ZM21.4 13.08h-7.28a1.04 1.04 0 0 0-1.04 1.04v7.28c0 .572.462 1.04 1.04 1.04h7.28a1.04 1.04 0 0 0 1.04-1.04v-7.28a1.04 1.04 0 0 0-1.04-1.04Zm-4.68 7.28-2.6-2.6.733-.733 1.867 1.861 3.946-3.946.733.738-4.68 4.68ZM21.4 1.56h-7.28a1.04 1.04 0 0 0-1.04 1.04v7.28c0 .572.462 1.04 1.04 1.04h7.28a1.04 1.04 0 0 0 1.04-1.04V2.6a1.04 1.04 0 0 0-1.04-1.04Zm-4.68 7.28-2.6-2.6.733-.733 1.867 1.861 3.946-3.946.733.738-4.68 4.68Z"
        />
    </svg>
)
export default MultiSelectIcon
