import {isEqual} from "lodash";
import {FQN} from "common/FQN";

import {ArcActor} from "engine/actor/ArcActor";
import {ArcDashboard} from "metadata/dashboard/ArcDashboard";
import {ArcEventFilter} from "engine/ArcEventFilter";
import {ArcEvent} from "engine/ArcEvent";
import {ArcMetadata} from "metadata/ArcMetadata";
import {LayoutLike} from "metadata/dashboard/DashboardLayouts";
import {CreateWidgetLayout} from "metadata/dashboard/changes/CreateWidgetLayout";
import {ModifyWidgetLayouts} from "metadata/dashboard/changes/ModifyWidgetLayouts";
import {Optional} from "common/Optional";
import {CreateWidget} from "metadata/dashboard/changes/CreateWidget";
import {DashboardReplace} from "metadata/dashboard/changes/DashboardReplace";
import {ReferenceQuery} from "metadata/dashboard/DashboardQueries";
import {ReferencedQueryActor} from "engine/actor/ReferencedQueryActor";
import {DashboardInfoChange} from "metadata/dashboard/changes/DashboardInfoChange";
import {CreateQuery} from "metadata/dashboard/changes/CreateQuery";
import {AttachQueryToWidget} from "metadata/dashboard/changes/AttachQueryToWidget";
import {ActorStatus} from "engine/actor/ActorStatus";
import {ActorStatusEventFilter} from "engine/ActorStatusMessage";
import {DeleteQuery} from "metadata/dashboard/changes/DeleteQuery";
import {DeleteWidget} from "metadata/dashboard/changes/DeleteWidget";
import {DeleteWidgetLayout} from "metadata/dashboard/changes/DeleteWidgetLayout";
import {AssetProps} from "metadata/Asset";
import {WidgetMetadataBound} from "metadata/dashboard/widgets/WidgetMetadata";
import {ModifyWidgetDelegate} from "app/query/ModifyWidgetDelegate";
import {ModifyWidgetConfig} from "metadata/dashboard/changes/ModifyWidgetConfig";
import {JsonObject} from "common/CommonTypes";
import {ModifyQueryConfig} from "metadata/dashboard/changes/ModifyQueryConfig";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {AddGlobalFilter} from "metadata/dashboard/changes/AddGlobalFilter";
import {ModifyGlobalFilter} from "metadata/dashboard/changes/ModifyGlobalFilter";
import {DeleteGlobalFilter} from "metadata/dashboard/changes/DeleteGlobalFilter";
import {ResultEventFilter, ResultMessage} from "engine/ResultMessage";
import {DataSuperType} from "metadata/DataType";
import {DateFilterType} from "app/query/filters/DateFilterType";
import {Column} from "metadata/Column";
import {ReplaceReason} from "metadata/ReplaceReason";
import {ArcMetadataChange} from "metadata/ArcMetadataChange";
import {ServiceProvider} from "services/ServiceProvider";
import {LocalStorageService} from "services/LocalStorageService";
import throttle from 'lodash/debounce';
import {FilterOperator} from "metadata/query/filterclause/FilterOperator";
import {GlobalLiteralsFilterClause} from "metadata/dashboard/GlobalLiteralsFilterClause";
import {GlobalFilterClause} from "metadata/dashboard/GlobalFilterClause";
import {EditorFilterChange} from "app/query/filters/FilterEditor";
import {GlobalFilterClauseFactory} from "metadata/dashboard/GlobalFilterClauseFactory";
import {CreateColumnGroup} from "metadata/dashboard/changes/CreateColumnGroup";
import {DeleteColumnGroup} from "metadata/dashboard/changes/DeleteColumnGroup";
import {AddColumnToColumnGroup} from "metadata/dashboard/changes/AddColumnToColumnGroup";
import {DeleteColumnFromColumnGroup} from "metadata/dashboard/changes/DeleteColumnFromColumnGroup";
import {InteractionsRelayActor} from "engine/actor/InteractionsRelayActor";
import {WidgetType} from "metadata/dashboard/widgets/WidgetType";
import {QueriedWidgetMetadata} from "metadata/dashboard/widgets/QueriedWidgetMetadata";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";
import {ModifyDashboardConfig} from "metadata/dashboard/changes/ModifyDashboardConfig";


export class DashboardBuilderDelegate extends ArcActor implements ModifyWidgetDelegate {

    public static readonly RELAY_ACTOR = '__SYSTEM__interactions_relay';
    private static readonly QUERY_ACTOR_PREFIX = 'query_';

    public static queryActorId(queryId: string): string {
        return `${DashboardBuilderDelegate.QUERY_ACTOR_PREFIX}${queryId}`;
    }

    public static isQueryActor(actorId: string): boolean {
        return actorId.startsWith(DashboardBuilderDelegate.QUERY_ACTOR_PREFIX);
    }

    public static queryIdFromQueryActor(actorId: string): string {
        return actorId.substring(DashboardBuilderDelegate.QUERY_ACTOR_PREFIX.length);
    }

    public readonly updateLocal: (fqn: FQN) => void;

    private readonly resultEventFilter: ResultEventFilter;
    private readonly statusEventFilter: ActorStatusEventFilter;

    constructor(
        id: string,
        private readonly dashboard: ArcMetadata<ArcDashboard>,
        private readonly onChange: (dashboard: ArcDashboard) => void,
        private readonly onQueryResults: (queryId: string, message: ResultMessage) => void,
        private readonly onActorStatusChange: (statuses: Map<string, ActorStatus>) => void
    ) {
        super(id);

        this.resultEventFilter = new ResultEventFilter();
        this.statusEventFilter = new ActorStatusEventFilter();

        // after query results, let the UI settle (in case it gets crashy) and store a copy in local storage, we don't
        // want to immediately store incase the change causes a crash and we end up in an unrecoverable loop
        this.updateLocal = throttle(this._updateLocal, 1000, {trailing: true});
    }

    initialize(controller: AbortController): Promise<boolean> {
        // register actors for all the queries
        return this.connector.registerActor(
            new InteractionsRelayActor(DashboardBuilderDelegate.RELAY_ACTOR, this.dashboard.metadata.interactions),
        ).then(() => false);
    }

    postInitialize() {
        return this.connector.registerAll([
            ...this.dashboard.metadata.queries.map((queryId: string, query: ReferenceQuery) =>
                new ReferencedQueryActor(
                    DashboardBuilderDelegate.queryActorId(queryId),
                    queryId,
                    query,
                    this.dashboard.metadata.fullyQualifiedName
                )
            )
        ]);
    }

    undo(): void {
        if (this.dashboard.undo()) {
            this.reconcileActors();
            this.onChanged();
        }
    }

    redo(): void {
        if (this.dashboard.redo()) {
            this.reconcileActors();
            this.onChanged();
        }
    }

    /**
     * If there are any unsaved changes.
     */
    get hasChanges(): boolean {
        const undoChanges = this.dashboard.undoChanges;
        return undoChanges.length !== 0 && !this.isChangePersisted(undoChanges[0]);
    }

    get hasUndo(): boolean {
        return this.dashboard.hasUndo();
    }

    get hasRedo(): boolean {
        return this.dashboard.hasRedo();
    }

    /**
     * If the specific change indicates persistence (e.g. was saved or loading a saved asset).
     */
    private isChangePersisted(change: ArcMetadataChange<ArcDashboard>[]): boolean {
        return change.length === 1 && Optional.ofType(change[0], DashboardReplace)
            .map(c => c.reason.isPersisted)
            .getOr(false);
    }

    /**
     * Explicitly take an FQN to save the asset with since new queries will not have an FQN.
     */
    private _updateLocal(fqn: FQN) {
        const localService = ServiceProvider.get(LocalStorageService);
        if (this.hasChanges) {
            // have changes, store it
            localService.storeAsset(this.dashboard.metadata, fqn);
        } else {
            // changes persisted or no longer relevant, can clear local storage
            localService.clearAsset(fqn);
        }
    }

    /**
     * Return a flattened list of changes after the most recent persisted change from new to old .
     */
    get changes(): string[] {
        const changesBeforePersistence: string[] = [];
        this.dashboard.changesZipped.some(change => {
            if (this.isChangePersisted(change.right)) {
                // found a persisted change, can stop
                return true;
            } else {
                changesBeforePersistence.push(...change.left);
                return false;
            }
        });

        return changesBeforePersistence;
    }

    /**
     * Serialize changes into a single string from new to old.
     */
    describeChanges(): string {
        return this.changes.map(c => '- ' + c).join('\n');
    }

    nextWidgetId(widgetType: WidgetType): string {
        let i = 0;
        while (true) {
            const possible = `${widgetType.name}_${i++}`;
            if (!this.dashboard.metadata.widgets.has(possible)) {
                return possible;
            }
        }
    }

    nextQueryId(queryFqn: FQN): string {
        const id = `${queryFqn.folder}_${queryFqn.name}`;
        if (!this.dashboard.metadata.queries.has(id)) {
            return id;
        }

        // why can't it be that easy? pick the first that is not used
        let i = 0;
        while (true) {
            const possible = `${id}_${i++}`;
            if (!this.dashboard.metadata.queries.has(possible)) {
                return possible;
            }
        }
    }

    changeInfo(info: AssetProps) {
        this.dashboard.apply([new DashboardInfoChange(info)]);
        this.onChanged();
    }

    replace(dashboard: ArcDashboard, reason: ReplaceReason) {
        // if the reason is saving, do a little diffing to not reload the entire dashboard again
        if (
            reason === ReplaceReason.SAVE &&
            isEqual(
                JSON.parse(JSON.stringify(dashboard)),
                JSON.parse(JSON.stringify(this.dashboard.metadata))
            )
        ) {
            // no changes, but we still want to update/clear what's in local storage
            this._updateLocal(dashboard.fullyQualifiedName);
            return;
        }
        // diff detected, apply the change and update dashboard completely
        this.dashboard.apply([new DashboardReplace(dashboard, reason)]);
        this.reconcileActors(reason === ReplaceReason.REFRESH);
        this.onChanged();
    }

    /**
     * Create a placeholder widget, returning the id.
     */
    createPlaceholderWidget(widgetType: WidgetType, layout: LayoutLike): string {
        const nextId = this.nextWidgetId(widgetType);
        this.dashboard.apply([
            new CreateWidget(nextId, widgetType.placeholder(nextId, '')),
            new CreateWidgetLayout(nextId, layout)
        ]);
        this.onChanged();

        return nextId;
    }

    modifyLayout(layouts: Map<string, LayoutLike>) {
        this.dashboard.apply([
            new ModifyWidgetLayouts(layouts)
        ]);
        this.onChanged();
    }

    /**
     * Create a new query and attach it to an existing widget.
     */
    createAndAttachQuery(queryFqn: FQN, widgetId: string) {
        const queryId = this.nextQueryId(queryFqn);

        this.dashboard.apply([
            new CreateQuery(new ReferenceQuery(queryId, queryFqn)),
            new AttachQueryToWidget(Optional.some(queryId), widgetId)
        ]);

        this.reconcileActors();
        this.onChanged();
    }

    createQueryWithWidget(queryFqn: FQN, layout: LayoutLike) {
        const queryId = this.nextQueryId(queryFqn);
        const widgetId = this.nextWidgetId(WidgetType.CHART);

        this.dashboard.apply([
            // create the widget and add it to the layout
            new CreateWidget(
                widgetId,
                WidgetType.CHART.placeholder(widgetId, '')
            ),
            new CreateWidgetLayout(widgetId, layout),
            // create the query and attach it
            new CreateQuery(new ReferenceQuery(queryId, queryFqn)),
            new AttachQueryToWidget(Optional.some(queryId), widgetId)
        ]);

        this.reconcileActors();
        this.onChanged();
    }

    deleteWidget(widgetMetadata: WidgetMetadataBound) {
        const changes = [];

        // delete the layout and widget
        changes.push(new DeleteWidgetLayout(widgetMetadata.id));
        changes.push(new DeleteWidget(widgetMetadata.id));

        // delete the query if only used by this widget
        if (widgetMetadata instanceof QueriedWidgetMetadata<any, any>) {
            widgetMetadata.queryId.forEach(queryId => {
                // we know the query is used by the given widget so if exactly 1 then delete
                if (this.dashboard.metadata.widgets.attachedToQuery(queryId).length === 1) {
                    changes.push(new DeleteQuery(queryId));
                }
            });
        }

        // apply, cleanup, and notify
        this.dashboard.apply(changes);
        this.reconcileActors();
        this.onChanged();
    }

    modifyWidgetConfig(widgetId: string, config: Map<string, any>) {
        this.dashboard.apply([new ModifyWidgetConfig(widgetId, config, true)]);
        this.onChanged();
    }

    modifyQueryConfig(queryId: string, config: JsonObject) {
        this.dashboard.apply([new ModifyQueryConfig(queryId, config, true)]);
        this.reconcileActors();
        this.onChanged();
    }

    startGlobalFilter(dataset: ArcDataset, column: Column) {
        Optional.map(() => {
            switch (column.dataSuperType) {
                case DataSuperType.BOOLEAN:
                case DataSuperType.STRING:
                    return new GlobalLiteralsFilterClause(dataset.fqn.toString(), column.name, FilterOperator.IN, []);
                case DataSuperType.NUMBER:
                    return new GlobalLiteralsFilterClause(dataset.fqn.toString(), column.name, FilterOperator.BETWEEN, []);
                case DataSuperType.TIMESTAMP:
                    return new GlobalLiteralsFilterClause(
                        dataset.fqn.toString(),
                        column.name,
                        FilterOperator.GREATER_THAN_EQUAL,
                        [DateFilterType.RELATIVE.defaultValue()]
                    );
            }
        }).forEach(clause => {
            this.addGlobalFilters([clause]);
        });
    }

    modifyGlobalFilter(
        ordinal: number,
        change: EditorFilterChange
    ) {
        const existingClause = this.dashboard.metadata.globalFilters.get(ordinal);
        this.dashboard.apply([
            new ModifyGlobalFilter(
                GlobalFilterClauseFactory.fromFilterChange(existingClause, change),
                ordinal
            )
        ]);
        this.onChanged();
    }

    addGlobalFilters(clauses: GlobalFilterClause[]) {
        this.dashboard.apply(clauses.map(c => new AddGlobalFilter(c)));
        this.onChanged();
    }

    deleteGlobalFilter(ordinal: number) {
        this.dashboard.apply([new DeleteGlobalFilter(ordinal)]);
        this.onChanged();
    }

    modifyDashboardConfig(config: DashboardConfig) {
        this.dashboard.apply([new ModifyDashboardConfig(config, this.dashboard.metadata.config)]);
        this.onChanged();
    }

    /**
     * Start a column group returning some error.
     */
    startColumnGroup(label: string): Optional<string> {
        return this.dashboard.metadata.interactions.getColumnGroup(label)
            .map(() => `Column group ${label} already exists.`)
            .orForEach(() => {
                this.dashboard.apply([new CreateColumnGroup(label, [])]);
                this.onChanged();
                return Optional.none();
            })
    }

    deleteColumnGroup(label: string) {
        this.dashboard.apply([new DeleteColumnGroup(label)]);
        this.reconcileInteractions();
    }

    addColumnToColumnGroup(label: string, dataset: ArcDataset, column: Column): Optional<string> {
        const hasColumn = this.dashboard.metadata.interactions.getColumnGroup(label)
            .map(group => group.hasColumn(dataset.fqn, column.name))
            .getOr(false);

        if (hasColumn) {
            return Optional.some(`Column ${column.name} already exists in column grouping ${label}.`)
        }

        this.dashboard.apply([new AddColumnToColumnGroup(label, dataset, column)]);
        this.reconcileInteractions();
        return Optional.none();
    }

    deleteColumnFromColumnGroup(label: string, dataset: ArcDataset, column: string) {
        this.dashboard.apply([new DeleteColumnFromColumnGroup(label, dataset, column)]);
        this.reconcileInteractions();
    }

    private reconcileInteractions() {
        // recreate the interactions actor with any interactions changes
        this.connector.removeActor(DashboardBuilderDelegate.RELAY_ACTOR);
        this.connector.registerActor(
            new InteractionsRelayActor(DashboardBuilderDelegate.RELAY_ACTOR, this.dashboard.metadata.interactions)
        );
        this.onChanged();
    }

    /**
     * Diff the actors currently in the engine vs. what is specified in the dashboard metadata.
     */
    private reconcileActors(forceReload: boolean = false) {
        // see if we need to add or remove actors
        let existingActors = this.connector.getActorIds(false, false);
        const actorsToCreate: ArcActor[] = [];
        const actorsToRemove = new Set(existingActors);

        // if we want to force a reload, clear out existing actors so we reload everything
        if (forceReload) {
            existingActors = new Set();
        }

        this.dashboard.metadata.queries.map((queryId: string, query: ReferenceQuery) => {
            const actorId = DashboardBuilderDelegate.queryActorId(queryId);
            const newActor = new ReferencedQueryActor(
                actorId,
                queryId,
                query,
                this.dashboard.metadata.fullyQualifiedName
            );

            if (existingActors.has(actorId)) {
                // see if we need to replace the existing actor or not
                const existingActor: ReferencedQueryActor = this.connector.getActor(actorId);
                if (existingActor.query.equals(newActor.query)) {
                    // it's the same so noop, remove the actor from the list of actors to remove
                    actorsToRemove.delete(actorId);
                } else {
                    // something changed, remove the old and add in the new
                    actorsToCreate.push(newActor);
                }
            } else {
                // net new actor
                actorsToCreate.push(newActor);
            }
        });

        // remove actors
        actorsToRemove.forEach((actorId: string) => this.connector.removeActor(actorId));

        // add the new or changed ones
        actorsToCreate.forEach((actor: ArcActor) => this.connector.registerActor(actor));
    }

    /**
     * Broadcast a metadata change to the engine and UI.
     */
    public onChanged() {
        this.onChange(this.dashboard.metadata);
    }

    eventFilters(): ArcEventFilter[] {
        return [
            this.resultEventFilter,
            this.statusEventFilter
        ];
    }

    notify(event: ArcEvent): void {
        this.resultEventFilter.filter(event)
            .forEach(m => {
                this.onQueryResults((event.actor as ReferencedQueryActor).queryId, m);
            });

        this.statusEventFilter.filter(event)
            // only care about query actor status changes
            .filter(() => DashboardBuilderDelegate.isQueryActor(event.actor.id))
            .forEach(() => {
                this.onActorStatusChange(
                    new Map(
                        // aggregate all query actor statuses
                        Array.from(this.connector.getActorStatuses().entries())
                            .filter(([actorId, _]: [string, ActorStatus]) =>
                                DashboardBuilderDelegate.isQueryActor(actorId)
                            )
                            .map(([actorId, status]: [string, ActorStatus]) => [
                                DashboardBuilderDelegate.queryIdFromQueryActor(actorId),
                                status
                            ])
                    )
                );
            });
    }

}