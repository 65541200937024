import * as React from "react"
import {SVGProps} from "react"

const BlockHashEventIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        {...props}
    >
        <path
            d="M1 1v12h12V1H1Zm1.09 1.09h9.82v9.82H2.09V2.09Zm3.274 1.637v1.637H3.727v1.09h1.637v1.091H3.727v1.091h1.637v1.637h1.09V8.636h1.091v1.637h1.091V8.636h1.637v-1.09H8.636V6.454h1.637V5.364H8.636V3.727h-1.09v1.637H6.454V3.727H5.364Zm1.09 2.728h1.091v1.09h-1.09v-1.09Z"
        />
    </svg>
)
export default BlockHashEventIcon
