import * as React from "react";
import {FunctionComponent} from "react";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";

type Props = {}

export const BrandedFooter: FunctionComponent<Props> = (props: Props) => {
    return <S.Footer>
        <S.Branding>
            <a href="https://hyperarc.com/">
                <img src="/static/images/logo.svg" height="24px" alt="Hyperarc"/>
            </a>
        </S.Branding>
        <S.RightSide>
            <S.TagLine>Web3 Insights without the complexity.</S.TagLine>
            <S.Copyright>©️2024 HyperArc Inc.</S.Copyright>
        </S.RightSide>
    </S.Footer>;
};

const S = {
    Footer: styled.div`
        background: ${Colors.backgroundGrey};
        display: flex;
        flex-direction: row;
    `,

    Branding: styled.div`
        display: flex;
        flex: 1;
        align-items: center;
        padding: 16px 0 16px 24px;
    `,

    RightSide: styled.div`
        padding: 0 24px 0 0;
        display: flex;
        align-items: center;
        justify-content: right;
    `,

    TagLine: styled.div`
        padding: 0 24px 0 0;
        display: flex;
        font-size: ${FontSizes.xSmall};
    `,

    Copyright: styled.div`
        display: flex;
        font-size: ${FontSizes.xSmall};
        font-weight: bold;
    `
};