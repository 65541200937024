import {Json, JsonObject} from "common/CommonTypes";
import {Either, Right} from "common/Either";
import {FQN} from "common/FQN";
import {ApiResponse, ErrorResponse} from "services/ApiResponse";
import {RestService} from "services/RestService";
import {ServiceProvider} from "services/ServiceProvider";
import {ImageService} from "services/ImageService";
import {ArcTrend} from "metadata/trend/ArcTrend";
import {Alert, AlertJson} from "metadata/trend/Alert";
import {TrendsService} from "services/TrendsService";
import {ArcTrendListItem, ArcTrendListItemJson} from "metadata/trend/ArcTrendListItem";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";

/**
 * Api implementation of TrendsService.
 */
export class ApiTrendsService implements TrendsService {

    async listTrends(dashboardFQN: FQN, loadImages?: boolean, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrendListItem[]>> {
        const trendsResponse = await ServiceProvider.get(RestService)
            .get(`/api/v1/${dashboardFQN.apiPath}/trends`, signal)
            .then(ApiResponse.success(
                (jsons: Json[], _: Response) => jsons.map(json => ArcTrendListItem.fromJSON(json as ArcTrendListItemJson))
            ));

        // error or don't need to hydrate trends with images
        if (trendsResponse.isLeft || !loadImages) {
            return trendsResponse;
        }

        // load the images for each trend
        const imageService = ServiceProvider.get(ImageService);
        const hydrated = await Promise.all(trendsResponse.rightOrThrow().map(async (trend) => {
            if (!trend.previewUrl) {
                return Promise.resolve(trend);
            }

            const response = await imageService.getImageDataUrl(trend.previewUrl, signal);
            return response.fold(
                dataUrl => trend.with({previewDataUrl: dataUrl}),
                _ => trend
            );
        }));

        return new Right(hydrated);
    }

    fetchTrend(fqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/${fqn.apiPath}`, signal)
            .then(ApiResponse.success(
                (json: Json, _: Response) => ArcTrend.fromJSON(json as JsonObject)
            ));
    }

    fetchTrendById(id: string, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/trends/${id}`, signal)
            .then(ApiResponse.success(
                (json: Json, _: Response) => ArcTrend.fromJSON(json as JsonObject)
            ));
    }

    saveTrend(fqn: FQN, trend: ArcTrend, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcTrend>> {
        return ServiceProvider.get(RestService)
            .post(
                `/api/v1/accounts/${fqn.account}/folders/${fqn.folder}/trends`,
                trend.toJSON(),
                signal
            )
            .then(r => ApiResponse.custom(
                r,
                (json: JsonObject) => {
                    const trend = ArcTrend.fromJSON(json);
                    return trend;
                },
                ErrorResponse.of
            ));
    }

    getTrendResults(trend: ArcTrend, since: string, dataset: ArcDataset, signal?: AbortSignal): Promise<Either<ErrorResponse, ArcQLResponse>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/${trend.fqn.apiPath}/results?since=${since}`, signal)
            .then(r => ApiResponse.custom(
                r,
                json => ArcQLResponse.fromJson(json, dataset),
                ErrorResponse.of
            ));
    }

    listAlerts(trendFqn: FQN, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert[]>> {
        return ServiceProvider.get(RestService)
            .get(`/api/v1/${trendFqn.apiPath}/alerts`, signal)
            .then(ApiResponse.success(
                (jsons: Json[], _: Response) => jsons.map(json => Alert.fromJSON(json as AlertJson))
            ));
    }

    createAlert(trendFqn: FQN, alert: Alert, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert>> {
        return ServiceProvider.get(RestService)
            .post(`/api/v1/${trendFqn.apiPath}/alerts`, alert, signal)
            .then(ApiResponse.success(
                (json: Json, _: Response) => Alert.fromJSON(json as AlertJson)
            ));
    }

    patchAlert(trendFqn: FQN, alert: Alert, signal?: AbortSignal): Promise<Either<ErrorResponse, Alert>> {
        return ServiceProvider.get(RestService)
            .patch(`/api/v1/${trendFqn.apiPath}/alerts/${alert.id}`, alert, signal)
            .then(ApiResponse.success(
                (json: Json, _: Response) => Alert.fromJSON(json as AlertJson)
            ));
    }
}