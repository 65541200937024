import {Enum} from "common/Enum";

export class FilterClauseType extends Enum {

    static SINGLE = new FilterClauseType(
        'single',
        true,
        false
    );
    static COMPOSITE = new FilterClauseType(
        'composite',
        false,
        false
    );
    static FILTER_SET = new FilterClauseType(
        'filterset',
        true,
        true
    );
    static ARCQL = new FilterClauseType(
        'arcql',
        true,
        true
    );

    private constructor(
        name: string,
        public readonly editable: boolean,
        // specific filter clause type may refer to other assets (generally an fqn)
        public readonly hasAssetRefs: boolean
    ) {
        super(name);
    }
}

FilterClauseType.finalize();
