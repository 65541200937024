export type Property = string | number | boolean

export type IdentityProperties = {
    "email": string,
    "username": string,
    "environment"?: string
}

/**
 * Interface for implementing a third party user analytics and onboarding tool
 */
export abstract class UserAnalyticsService {

    /**
     * Identify who is using the app when they log in
     */
    abstract identify(userId: string, properties: IdentityProperties): void;

    abstract track(eventName: string, additionalProperties?: Record<string, Property>): void;

    abstract startFlow(flowId: string): void;

}