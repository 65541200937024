import {DataType} from "metadata/DataType";
import {AnalyticsType} from "metadata/AnalyticsType";
import {JsonObject} from "common/CommonTypes";
import {ColumnMetadataFactory} from "metadata/dataset/ColumnMetadataFactory";
import {ColumnMetadata} from "metadata/dataset/ColumnMetadata";

export type DatasetV2ColumnJson = {
    name: string;
    label: string;
    description: string;
    columnType: string;
    dataType: string;
    isPublic: boolean;
    parentColumnName: string;
    metadata: JsonObject;
}

/**
 * A single column in a Dataset V2
 */
export class DatasetV2Column {
    public constructor(
        public readonly name: string,
        public readonly label: string,
        public readonly description: string,
        public readonly columnType: AnalyticsType,
        public readonly dataType: DataType,
        public readonly isPublic: boolean,
        public readonly parentColumnName: string,
        public readonly metadata: ColumnMetadata
    ) {
    }

    static fromJSON(json: DatasetV2ColumnJson): DatasetV2Column {
        return new DatasetV2Column(
            json.name,
            json.label,
            json.description,
            AnalyticsType.get(json.columnType),
            DataType.get(json.dataType),
            json.isPublic,
            json.parentColumnName,
            ColumnMetadataFactory.fromJSON(json.metadata)
        );

    }
}