import React, {FunctionComponent, ReactNode} from "react";
import Tooltip from "@mui/material/Tooltip";
import {SelectChangeEvent, SelectProps} from "@mui/material/Select";
import styled from "@emotion/styled";
import {ExplainedSetting} from "metadata/dashboard/ExplainedSetting";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import {Colors, FontSizes} from "app/components/StyleVariables";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

interface Props<T> {
    title: string;
    tooltipTitle: string;
    selected: T;
    onSelectionChange: (event: SelectChangeEvent<T>) => void;
    settings: ExplainedSetting[]
    isSelectedIcon?: ReactNode
    helperText?: string
    additionalSelectProps?: SelectProps
}

/**
 * A select menu with a label and a tooltip in a configuration toolbar.
 */
export const ToolbarLabeledSelectMenu: FunctionComponent<Props<string | string[]>> = (props) => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <Tooltip
            title={props.tooltipTitle}
            placement="top" arrow
            open={tooltipOpen}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
        >
            <S.Column>
                <S.SectionLabel>{props.title}</S.SectionLabel>
                <S.NoBorderSelect
                    value={props.selected}
                    onChange={props.onSelectionChange}
                    input={<S.OutlinedInput/>}
                    onFocus={handleTooltipClose}
                    onBlur={handleTooltipClose}
                    {...props.additionalSelectProps}
                >
                    {props.settings.map((setting: ExplainedSetting, i: number) => {
                        const isSelected = props.selected.includes(setting.label);
                        return <S.MenuItem
                            key={setting.label}
                            value={setting.label}
                            selected={isSelected}
                            // if helperText is present, always add divider as it'll create a new menu item at end
                            divider={i !== (props.settings.length - 1) || props.helperText !== undefined}
                        >
                            <ListItemIcon>
                                {isSelected && props.isSelectedIcon}
                            </ListItemIcon>
                            <ListItemText
                                primary={setting.label}
                                secondary={setting.explanation}
                            />
                        </S.MenuItem>;
                    })}
                    {
                        props.helperText && <S.MenuItem disabled>
                            <ListItemText
                                primary={props.helperText}
                                primaryTypographyProps={{variant: 'caption'}}
                            />
                        </S.MenuItem>
                    }
                </S.NoBorderSelect>
            </S.Column>
        </Tooltip>
    );
};

class S {

    static Column = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 4px;
    `;

    static SectionLabel = styled.div`
        font-size: ${FontSizes.small};
        color: ${Colors.textTertiary};
    `;

    static OutlinedInput = styled(OutlinedInput)`
        MuiInputLabel-outlined {
            left: 8px;
        }

        .MuiChip-root {
            font-size: ${FontSizes.xSmall};

            .MuiChip-label {
                padding-right: 4px;
            }

            svg {
                color: ${Colors.hyperarcYellow};
                font-weight: 800;
                margin-left: 0;
                margin-right: -6px;
            }
        }
    `;

    static NoBorderSelect = styled(Select)`
        height: 20px;

        .MuiOutlinedInput-notchedOutline {
            border: none;
            box-shadow: none;
        }

        .MuiOutlinedInput-input {
            padding: 0;
        }
    `;

    static MenuItem = styled(MenuItem)`
        width: 300px;
        white-space: normal;
        padding-left: 8px;
    `;
}
