import {QueryDnDSource} from "app/query/components/QueryDnDSource";
import ListItemIcon from "@mui/material/ListItemIcon";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {FieldIcon} from "app/components/icons/field/FieldIcon";
import Tooltip from "@mui/material/Tooltip";
import React, {FunctionComponent, useState} from "react";
import styled from "@emotion/styled";
import {Colors, Durations, FontSizes} from "app/components/StyleVariables";
import ListItemText from "@mui/material/ListItemText";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {Column} from "metadata/Column";
import VerifiedIcon from '@mui/icons-material/Verified';
import ReactMarkdown from "react-markdown";
import {ChevronRight} from "@mui/icons-material";
import {ListS} from "app/query/components/ListS";

export type Props = {
    field: Column,
    draggable: boolean,
    isSelected?: boolean,
    onClick?: (field: Column) => void
    onDoubleClick?: (field: Column) => void
    onDragStart?: (event: React.DragEvent) => void
    onFieldSettingView?: (column: Column) => void
};

/**
 * FieldCard corresponds to the card component when displaying and selecting fields in ColumnsPicker.
 */
export const FieldCard: FunctionComponent<Props> = (props: Props) => {
    const [onMouseEntered, setOnMouseEntered] = useState(false);

    const descriptionComp = () => {
        if (!onMouseEntered) {
            return;
        }

        const descriptionComps = [];
        descriptionComps.push(<S.DescriptionTitle key='label'>{props.field.label}</S.DescriptionTitle>);
        if (props.field.description !== '') {
            descriptionComps.push(<S.DescriptionBody key='description'>{props.field.description}</S.DescriptionBody>);
        }
        if (props.field.isVerified) {
            descriptionComps.push(<S.DescriptionFooter key='verified'>Verified by HyperArc.</S.DescriptionFooter>);
        }

        return <Tooltip
            title={descriptionComps}
            placement="right"
            arrow
        >
            <S.InfoOutlined/>
        </Tooltip>;
    };

    const buildStartIcons = () => {
        if (props.isSelected === undefined && !props.draggable) {
            return [<S.NullStartIcon/>];
        }

        const startIcons = [];
        if (props.draggable) {
            startIcons.push(<S.DragIconContainer>
                <S.DragIndicatorIcon color={"disabled"}/>
            </S.DragIconContainer>);
        }
        if (props.isSelected !== undefined) {
            startIcons.push(<ListS.StartIcon>
                {props.isSelected ? <ListS.ThemedCheckedRadio/> : <ListS.OpenRadio/>}
            </ListS.StartIcon>);
        }
        return startIcons;
    };


    return <S.ListItemContainer
        className={props.draggable ? "draggable" : null}
        draggable={props.draggable}
        data-drag-id={QueryDnDSource.FIELD.of(props.field.name).serialize}
        onDragStart={props.onDragStart}
        onMouseEnter={() => setOnMouseEntered(true)}
        onMouseLeave={() => setOnMouseEntered(false)}
    >
        <S.ClickHitBox
            onClick={() => props.onClick && props.onClick(props.field)}
            onDoubleClick={() => props.onDoubleClick && props.onDoubleClick(props.field)}
        >
            {...buildStartIcons()}
            <FieldIcon field={props.field}/>
            <Tooltip
                title={props.field.name}
                enterDelay={Durations.tooltipPrimaryEnterDelay}
                enterNextDelay={Durations.tooltipPrimaryEnterNextDelay}
                disableInteractive
                arrow
            >
                <S.ListItemText primary={props.field.relativeLabel}/>
            </Tooltip>
            <S.DescriptionContainer>
                {!onMouseEntered && props.field.isVerified && <S.Verified/>}
                {descriptionComp()}
            </S.DescriptionContainer>
        </S.ClickHitBox>
        {props.onFieldSettingView && (
            <S.AdvancedViewIcon onClick={() => props.onFieldSettingView && props.onFieldSettingView(props.field)}/>
        )}
    </S.ListItemContainer>;
};

class S {
    static readonly ListItemContainer = styled.div`
        border: 1px solid ${Colors.borderGrey};
        border-radius: 2px;
        display: flex;
        padding: 4px 8px 4px 0;
        background-color: white;
        width: 100%;
        overflow: hidden;
        align-items: center;
        cursor: pointer;

        :hover {
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

            > .MuiSvgIcon-root {
                display: block;
            }

            svg {
                opacity: 1;
            }
        }

        &.draggable {
            cursor: grab;
            :active {
                cursor: grabbing;
            }
        }
    `;

    static ClickHitBox = styled.div`
        display: flex;
        align-items: center;
        width: 100%;
    `;

    static readonly NullStartIcon = styled(ListItemIcon)`
        min-width: 12px;
    `;


    static readonly DragIconContainer = styled(ListItemIcon)`
        min-width: 18px;

        svg {
            opacity: 0;
        }
    `;

    static readonly DragIndicatorIcon = styled(DragIndicatorIcon)`
        font-size: 16px
    `;

    static readonly ListItemText = styled(ListItemText)`
        margin-left: 12px;

        > span {
            line-height: ${FontSizes.medium};
            color: ${Colors.textSecondary};
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `;

    static readonly DescriptionContainer = styled.div`
        display: flex;
        flex-direction: row-reverse;

        svg {
            width: 14px;
            height: 14px;
        }
    `;

    static readonly DescriptionTooltip = styled.div`

    `;

    static readonly DescriptionTitle = styled.div`
        font-size: ${FontSizes.xSmall};
        font-weight: 600;
    `;

    static readonly DescriptionBody = styled(ReactMarkdown)`
        margin-top: 4px;

        > * {
            margin: 0;
        }
    `;

    static readonly DescriptionFooter = styled.div`
        font-size: ${FontSizes.xSmall};
        margin-top: 4px;
        color: ${Colors.textSecondary};
    `;

    static readonly Verified = styled(VerifiedIcon)`
        color: ${Colors.hyperarcYellow};
    `;

    static readonly InfoOutlined = styled(InfoOutlined)`
        color: ${Colors.iconSecondary};
    `;

    static readonly AdvancedViewIcon = styled(ChevronRight)`
        margin-left: auto;
        color: ${Colors.iconSecondary};
    `;
}
