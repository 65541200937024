import {Optional} from "common/Optional";
import isEqual from "lodash/isEqual";
import {JsonObject} from "common/CommonTypes";
import {VizSelectionType} from "engine/actor/VizSelectionType";


/**
 * Immutable selection state.
 *
 * @author zuyezheng
 */
export abstract class VizSelection {

    static empty(columns: string[] = []): VizSelection {
        return new DiscreteSelection(columns, new Map());
    }

    static none(): NoneSelection {
        return new NoneSelection();
    }

    /**
     * Toggle a selection, returning a new selections object.
     */
    abstract toggle(selection: string[]): VizSelection;

    /**
     * Iterate through all the selections rows.
     */
    abstract rows(): string[][];

    /**
     * If the selection is on the given field.
     */
    abstract isOn(fields: string[]): boolean;

    /**
     * If the given selection is selected.
     */
    abstract has(selection: string[]): boolean;

    /**
     * Return an empty version of the current selection.
     */
    abstract empty(): VizSelection;

    /**
     * Are there any selections?
     */
    abstract isEmpty(): boolean;

    /**
     * If the 2 selections are equal.
     */
    abstract isEqual(newSelections: VizSelection): boolean;

    /**
     * Return the "root" of this selection which is just the selection on the first grouping if a composite.
     */
    abstract get rootSelection(): VizSelection;

}

export class NoneSelection implements VizSelection {

    isOn(fields: string[]): boolean {
        return false;
    }

    has(selection: string[]): boolean {
        return false;
    }

    empty(): VizSelection {
        return this;
    }

    isEmpty(): boolean {
        return true;
    }

    isEqual(newSelections: VizSelection): boolean {
        return Optional.ofType(newSelections, NoneSelection).map(() => true).getOr(false);
    }

    rows(): string[][] {
        return [];
    }

    toggle(selection: string[]): VizSelection {
        return new NoneSelection();
    }

    get rootSelection(): VizSelection {
        return this;
    }
}

export class DiscreteSelection implements VizSelection {

    constructor(
        // fields used for a selection, consider a query grouped by A and B, neither column alone can indicate a
        // unique selection, but instead both must be used
        public readonly fields: string[],
        // selected rows by their hash where each selection should have the same number of values as columns
        public readonly selections: Map<string, string[]>
    ) {
    }

    static fromValues(fields: string[], values: string[][]): DiscreteSelection {
        return new DiscreteSelection(
            fields,
            new Map(values.map(v => [DiscreteSelection.toKey(v), v]))
        );
    }

    private static toKey(selection: string[]): string {
        return '{' + selection.join('}{') + '}';
    }

    /**
     * Toggle a selection, returning a new selections object.
     */
    toggle(selection: string[]): VizSelection {
        if (selection.length !== this.fields.length) {
            throw new Error('Selection has mismatched number of values.');
        }

        const key = DiscreteSelection.toKey(selection);
        const newSelections = new Map(this.selections);

        if (this.selections.has(key)) {
            newSelections.delete(key);
        } else {
            newSelections.set(key, selection);
        }

        return new DiscreteSelection(this.fields, newSelections);
    }

    /**
     * Iterate through all the selections rows.
     */
    rows(): string[][] {
        return Array.from(this.selections.values());
    }

    isOn(fields: string[]): boolean {
        return isEqual(fields, this.fields);
    }

    has(selection: string[]): boolean {
        // return false if the provided selection doesn't match the number of columns
        if (selection.length !== this.fields.length) {
            return false;
        }

        // otherwise check the map
        return this.selections.has(DiscreteSelection.toKey(selection));
    }

    empty(): VizSelection {
        return new DiscreteSelection(this.fields, new Map());
    }

    isEmpty(): boolean {
        return this.selections.size === 0;
    }

    isEqual(newSelections: VizSelection): boolean {
        return Optional.ofType(newSelections, DiscreteSelection)
            .map(s => isEqual(this.fields, s.fields) && isEqual(this.selections, s.selections))
            .getOr(false);
    }

    get rootSelection(): VizSelection {
        return new DiscreteSelection(
            // take the first field
            this.fields.slice(0, 1),
            new Map(
                Array.from(this.selections.values())
                    // trim each row down to the first
                    .map(row => row.slice(0, 1))
                    // create a map entry
                    .map(row => [DiscreteSelection.toKey(row), row])
            )
        );
    }


    toJSON(): JsonObject {
        return {
            type: VizSelectionType.DISCRETE,
            fields: this.fields,
            values: this.rows(),
        };
    }

    static fromJSON(json: JsonObject): DiscreteSelection {
        return DiscreteSelection.fromValues(json['fields'], json['values']);
    }
}

export class RangeSelection implements VizSelection {

    constructor(
        // project field name of the range
        public readonly field: string,
        public readonly range: [number, number] | null
    ) {
    }

    get start(): number {
        return this.range[0];
    }

    get end(): number {
        return this.range[1];
    }

    toggle(selection: string[]): VizSelection {
        return this;
    }

    rows(): string[][] {
        return [];
    }

    isOn(fields: string[]): boolean {
        return fields.length === 1 && this.field === fields[0];
    }

    has(selection: string[]): boolean {
        return false;
    }

    empty(): VizSelection {
        return new RangeSelection(this.field, null);
    }

    /**
     * Empty if range is null or either bounds are empty or invalid.
     */
    isEmpty(): boolean {
        return this.range == null || this.start == null || this.end == null || this.start > this.end;
    }

    isEqual(newSelections: VizSelection): boolean {
        return Optional.ofType(newSelections, RangeSelection)
            .map(s => isEqual(this.range, s.range))
            .getOr(false);
    }

    get rootSelection(): VizSelection {
        return this;
    }

    toJSON(): JsonObject {
        return {
            type: VizSelectionType.RANGE,
            field: this.field,
            range: this.range,
        };
    }

    static fromJSON(json: JsonObject): RangeSelection {
        return new RangeSelection(json['field'], json['range']);
    }
}