import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import {Tab, Tabs, TabsProps} from "@mui/material";

export class CommonS {

    // DEPRECATED
    static readonly Buttons = styled('div')`
        display: flex;
        
        > button {
            margin-left: 7px;
        
            &:first-of-type {
                margin-left: 0;
            }
        }
    `;

    // DEPRECATED
    static readonly Button = styled('button')`
        height: 30px;
        border: none;
        border-radius: 4px;
        background-color: ${Colors.hyperarcYellowA(0.75)};
        color: black;
        padding: 0 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        
        &:hover {
            background-color: ${Colors.hyperarcYellowA(1)};
        }
    `;

    static readonly MajorText = styled('div')`
        font-size: ${FontSizes.large};
    `;

    static readonly MinorText = styled('div')`
        font-size: ${FontSizes.small};
        color: ${Colors.textTertiary};
        letter-spacing: 0.02px;
    `;

    static readonly Emphasized = styled('div')`
        font-weight: 500;
    `;

    static readonly TruncatedLink = styled('a')`
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

    static readonly Tabs = styled(Tabs)<TabsProps & {
        zeroTop?: boolean
    }>`
        font-size: ${FontSizes.xSmall};
        margin-bottom: 8px;
        width: 100%;
        min-height: 36px;
        
        .Mui-selected {
            svg, .MuiSvgIcon-root {
                fill: ${Colors.hyperarcYellow};
            }
        }

        svg, .MuiSvgIcon-root {
            // when not selected
            fill: gray; 
        }

        .MuiTab-root {
            opacity: 0.5;
            // don't do the default uppercase text transform
            text-transform: none;
            // custom indicator for unselected tabs
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            padding-top: ${props => props.zeroTop ? 0 : '12px'};

            &.Mui-selected {
                color: black;
                font-weight: bold;
                opacity: 1;
                border-bottom: 2px solid ${Colors.hyperarcYellow};
            }
        }

        .MuiTabs-indicator {
            // hide the default indicator line in favor of our own
            display: none; 
        }
    `;

    static readonly Tab = styled(Tab)`
        justify-content: center;
        white-space: nowrap;
        min-height: unset;
    `;

}