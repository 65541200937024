import * as React from "react"
import {SVGProps} from "react"

const IgnoreGlobalFiltersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={24}
        {...props}
    >
        <path
            fill="#000"
            fillOpacity={0.54}
            d="M3 6h10l-5.01 6.3L3 6ZM.25 5.61C2.27 8.2 6 13 6 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39A.998.998 0 0 0 14.95 4H1.04c-.83 0-1.3.95-.79 1.61Z"
        />
        <path
            fill="#E53935"
            d="M20 9c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm0 9c-2.21 0-4-1.79-4-4 0-.925.315-1.775.845-2.45l5.605 5.605A3.951 3.951 0 0 1 20 18Zm3.155-1.55-5.605-5.605A3.951 3.951 0 0 1 20 10c2.21 0 4 1.79 4 4 0 .925-.315 1.775-.845 2.45Z"
        />
    </svg>
)
export default IgnoreGlobalFiltersIcon
