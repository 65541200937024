import {ChartConfig} from "app/visualizations/config/ChartConfig";
import {CartesianData} from "app/visualizations/data/CartesianData";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VisualizationConfig} from "metadata/query/ArcQLVisualizations";
import {GroupedCartesianData} from "app/visualizations/data/GroupedCartesianData";
import {MultiSeriesCartesianData} from "app/visualizations/data/MultiSeriesCartesianData";
import {SelectionEvent} from "app/visualizations/FusionTypes";
import {Optional} from "common/Optional";
import {AnalyticsType} from "metadata/AnalyticsType";

/**
 * Chart config for cartesian charts like lines and bars.
 *
 * @author zuyezheng
 */
export abstract class CartesianChartConfig extends ChartConfig {

    protected readonly isHierarchical: boolean;
    protected data: CartesianData;

    constructor(response: ArcQLResponse, config: VisualizationConfig) {
        super(response, config);

        // can visualize as hierarchical if 2 or more groupings and with exactly 1 measure
        this.isHierarchical = response.arcql.groupings.size >= 2 && response.arcql.fields.size === 1;
    }

    get isStacked(): boolean {
        return false;
    }

    validate(): Optional<string> {
        // needs at least 1 measure to visualize
        if (this.response.result.columnsByType(new Set([AnalyticsType.MEASURE])).length <= 0) {
            return Optional.some('Chart type requires at least 1 measure.');
        }

        if (this.isHierarchical) {
            this.data = new GroupedCartesianData(this.response, this.isStacked);
        } else {
            this.data = new MultiSeriesCartesianData(this.response);
        }

        return Optional.none();
    }

    handleDiscreteClick(event: SelectionEvent, originalDataSource: {[key: string]: any}): Optional<string[][]> {
        return Optional.some([
            originalDataSource.dataset[event.datasetIndex].data[event.dataIndex].categoryValues
        ]);
    }

}