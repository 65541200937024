import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {HyperGraphRequest} from "metadata/hypergraph/HyperGraphRequest";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {HyperGraphNodeType} from "metadata/hypergraph/nodes/HyperGraphNodeType";
import {HyperGraphNodeProps, HyperGraphNodeSerialized} from "metadata/hypergraph/HyperGraphTypes";
import {Enum} from "common/Enum";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {HyperGraphDeserializer} from 'metadata/hypergraph/HyperGraphDeserializer';
import {NodeStructuredSection} from 'metadata/hypergraph/content/NodeStructuredSection';

/**
 * Node container for an in progress request. This node will be replace on successful completion of the request or store
 * the error otherwise.
 *
 * @author zuyezheng
 */
export class RequestNode extends HyperGraphNode {

    constructor(
        public readonly request: HyperGraphRequest,
        public readonly state: RequestState = RequestState.QUERYING,
        id?: string,
        createdOn?: number,
        modifiedOn?: number
    ) {
        super(id, createdOn, modifiedOn);
    }

    get type(): HyperGraphNodeType {
        return HyperGraphNodeType.REQUEST;
    }

    label(hyperGraph: HyperGraph): string {
        return this.request.label(hyperGraph);
    }

    get description(): string {
        return this.state.label;
    }

    structuredContent(graph: HyperGraph): NodeStructuredSection[] {
        return [];
    }

    getQuery(graph: HyperGraph): ArcQL {
        return this.getParentQuery(graph);
    }

    getQueryResult(graph: HyperGraph): QueryResult {
        return this.getParentQueryResult(graph);
    }

    getHypothesis(nth: number): HyperGraphNodeHypothesis {
        return new SimpleHypothesis(this.state.name, this.state.label);
    }

    with(props: Partial<RequestNodeProps>): HyperGraphNode {
        return new RequestNode(
            this.request,
            props.state ?? this.state,
            this.id,
            this.createdOn,
            props.modifiedOn ?? this.modifiedOn
        );
    }

    get inProgress(): boolean {
        return this.state === RequestState.QUERYING;
    }

    get canRate(): boolean {
        return false;
    }

    toJSON(): HyperGraphNodeSerialized {
        return {
            ...super.toJSON(),
            state: RequestNode.sanitizeState(this.state)
        };
    }

    static fromJSON(json: HyperGraphNodeSerialized, dataset: ArcDataset): RequestNode {
        return new RequestNode(
            HyperGraphDeserializer.toRequest(json.request, dataset),
            RequestNode.sanitizeState(RequestState.get(json.state)),
            json.id,
            json.createdOn,
            json.modifiedOn
        );
    }

    /**
     * Sanitize in progress states when serializing or deserializing since can't be in progress once serialized.
     */
    private static sanitizeState(state: RequestState): RequestState {
        return state === RequestState.QUERYING ? RequestState.STALE : state;
    }

}

export type RequestNodeProps = HyperGraphNodeProps & {
    state: RequestState
}

export class RequestState extends Enum {

    // doing stuff
    static readonly QUERYING = new this('querying', 'Thinking...');
    // error during the request
    static readonly ERROR = new this('error', 'Something went wrong, try again?');
    // request is stale, like hypergraph was saved while in progress node was querying
    static readonly STALE = new this('stale', 'Saved without finishing, try again?');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }

}
RequestState.finalize();