import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {DataOfInterestNode} from "metadata/hypergraph/nodes/DataOfInterestNode";
import {DataOfInterest} from "metadata/hypergraph/nodes/DataOfInterest";
import {QueryContainerNode} from "metadata/hypergraph/nodes/QueryContainerNode";
import {NextBestMeasures} from "metadata/hypergraph/nodes/NextBestMeasures";
import {NextBestMeasuresNode} from "metadata/hypergraph/nodes/NextBestMeasuresNode";
import {HyperGraphNodeHypothesis, SimpleHypothesis} from "metadata/hypergraph/HyperGraphNodeHypothesis";
import {NextBestGroupingsNode} from "metadata/hypergraph/nodes/NextBestGroupingsNode";
import {NextBestGroupings} from "metadata/hypergraph/nodes/NextBestGroupings";
import {ConsolidateAnalysisNode} from "metadata/hypergraph/nodes/ConsolidateAnalysisNode";
import {ValidateHypothesis} from "metadata/hypergraph/nodes/ValidateHypothesis";
import {ValidateHypothesisNode} from "metadata/hypergraph/nodes/ValidateHypothesisNode";
import {ConsolidateAnalysis} from "metadata/hypergraph/nodes/ConsolidateAnalysis";
import {HyperGraphExecutor} from "app/query/hypergraph/HyperGraphExecutor";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {StartAnalysisNode} from "metadata/hypergraph/nodes/StartAnalysisNode";
import {StartAnalysis} from "metadata/hypergraph/nodes/StartAnalysis";
import {WithQueryVariants} from "metadata/hypergraph/WithQueryVariants";
import { HyperGraph } from "metadata/hypergraph/HyperGraph";
import {HyperGraphNodeOperation} from "metadata/hypergraph/HyperGraphNodeOperation";

/**
 * Executes an automated process to alter an existing hypergraph.
 *
 * @author zuyezheng
 */
export class HyperGraphProcess {

    constructor(
        private readonly dataset: ArcDataset,
        // node to start the process from
        private readonly nodeId: string
    ) { }

    public start(executor: HyperGraphExecutor): void {
        const startAnalysis = async (parentId: string, dataset: ArcDataset): Promise<StartAnalysisNode> => {
            return executor.upsertNode(
                new StartAnalysisNode(new StartAnalysis(parentId, dataset))
            );
        };

        const dataOfInterest = async (parent: HyperGraphNode): Promise<DataOfInterestNode> => {
            return (await executor.newCompletion(
                new DataOfInterest(parent.id), DataOfInterestNode.factoryOfFactory, this.dataset
            ))[0];
        };

        const nextBestMeasures = async (parent: HyperGraphNode): Promise<QueryContainerNode> => {
            // figure out the new measures
            const [nextBestMeasures, hyperGraph] = await executor.newCompletion(
                new NextBestMeasures(parent.id, 3), NextBestMeasuresNode.factoryOfFactory, this.dataset
            );

            // query with the new measures
            return executor.newQuery(
                nextBestMeasures,
                nextBestMeasures.getVariant(hyperGraph, this.dataset),
                HyperGraphNodeOperation.MEASURES,
                new SimpleHypothesis(
                    'Adding additional measures.',
                    'Added additional measures.'
                )
            ).then(q => q[0]);
        };

        const nextBestGroupings = async (parent: HyperGraphNode): Promise<[NextBestGroupingsNode, HyperGraph]> => {
            return (await executor.newCompletion(
                new NextBestGroupings(parent.id, this.dataset, 8),
                NextBestGroupingsNode.factoryOfFactory,
                this.dataset
            ));
        };

        const consolidateHypotheses = async (
            [parent, queries]: [HyperGraphNode, QueryContainerNode[]]
        ): Promise<[ConsolidateAnalysisNode, HyperGraph]> => {
            const validations: Promise<ValidateHypothesisNode>[] = executor.batchLayout(() =>
                queries.map(nextQuery =>
                    executor.newCompletion(
                        new ValidateHypothesis(nextQuery.id, 0),
                        ValidateHypothesisNode.factoryOfFactory,
                        this.dataset
                    ).then(c => c[0])
                )
            );
            const hypothesisValidations = await Promise.all(validations);

            return executor.newCompletion(
                new ConsolidateAnalysis(
                    parent.id,
                    hypothesisValidations.map(n => n.id),
                    4
                ),
                ConsolidateAnalysisNode.factoryOfFactory,
                this.dataset
            );
        };

        const queryVariants = async <H extends HyperGraphNodeHypothesis, N extends HyperGraphNode & WithQueryVariants<H>>(
            [node, hyperGraph]: [N, HyperGraph]
        ): Promise<[N, QueryContainerNode[]]> => {
            // parallelize queries for next set of groupings
            const queries: Promise<QueryContainerNode>[] = executor.batchLayout(() =>
                node.getVariants(hyperGraph, this.dataset).map(([hypothesis, query]) =>
                    executor.newQuery(node, query, node.variantsOperation, hypothesis)
                        .then(q => q[0])
                )
            );
            return Promise.all(queries).then(queryNodes => [node, queryNodes]);
        };

        // chaining of the steps in the analysis
        startAnalysis(this.nodeId, this.dataset)
            .then(dataOfInterest)
            .then(nextBestMeasures)
            .then(dataOfInterest)
            .then(nextBestGroupings)
            .then(queryVariants)
            .then(consolidateHypotheses)
            .then(queryVariants);
    }



}