import * as React from "react";
import {FunctionComponent} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import styled from "@emotion/styled";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {NotificationSeverity, NotificationsService} from "services/NotificationsService";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {DialogS} from "app/DialogS";
import {FolderResult} from "metadata/project/FolderResult";
import FolderPatchRequest from "metadata/project/FolderPatchRequest";
import {Enum} from "common/Enum";
import {WrappedProjectIcon} from "app/components/icons/project/WrappedProjectIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

type Props = {
    project: FolderResult
    onCancel: () => void
    onVisibilityChange: (project: FolderResult) => void
}

class Mode extends Enum {

    static TO_PRIVATE = new this(
        'toPrivate',
        'public',
        'private',
        'Making this project private will remove access for anyone outside of your organization; this could break existing references to assets in this project.'
    );

    static TO_PUBLIC = new this(
        'toPublic',
        'private',
        'public',
        'Share this project and it\'s insights with the world! This will enable embedding and others to fork these assets.'
    );

    constructor(
        name: string,
        public readonly startVisibility: string,
        public readonly endVisibility: string,
        public readonly details: string
    ) {
        super(name);
    }
}

/**
 * Dialog for changing the visibility of a Project.
 *
 * @author fli
 */
export const ChangeVisibilityProjectDialog: FunctionComponent<Props> = (props: Props) => {

    const mode = props.project.isPublic ? Mode.TO_PRIVATE : Mode.TO_PUBLIC;

    const changeVisibility = () => {
        ServiceProvider.get(MetadataService)
            .patchFolder(
                props.project.ownerName,
                props.project.name,
                new FolderPatchRequest(null, null, !props.project.isPublic)
            )
            .then(response => response.match(
                folder => {
                    ServiceProvider.get(NotificationsService).publish(
                        'visibilityProject',
                        NotificationSeverity.SUCCESS,
                        `Successfully made Project "${props.project.label}" ${mode.endVisibility}!`
                    );
                    props.onVisibilityChange(folder);
                },
                error => {
                    ServiceProvider.get(NotificationsService).publish(
                        'visibilityProject', NotificationSeverity.ERROR, `Project visibility change failed: ${error.prettyPrint()}`
                    );
                }
            ));
    };

    return <Dialog
        open={true}
        onClose={props.onCancel}
        PaperProps={{
            sx: {
                width: "600px"
            }
        }}
    >
        <DialogS.Title>{`Change project visibility of @${props.project.getFolderUrl()}`}</DialogS.Title>
        <DialogS.Content dividers>
            <S.Body>
                <S.ProjectItem>
                    <S.Icon>
                        <WrappedProjectIcon isPublic={props.project.isPublic}/>
                    </S.Icon>
                    <ListItemText
                        primary={
                            <>
                                {'@' + props.project.getFolderUrl() + ' is currently '}
                                <Typography component="span" fontWeight="bold">
                                    {mode.startVisibility}.
                                </Typography>
                            </>
                        }
                    />
                </S.ProjectItem>
                <S.Divider/>
                <DialogS.Description>
                    {mode.details}
                </DialogS.Description>
            </S.Body>
        </DialogS.Content>
        <DialogS.Actions>
            <Button variant="outlined" onClick={props.onCancel}>Cancel</Button>
            <S.ChangeVisibilityButton
                variant="contained"
                onClick={changeVisibility}
            >
                {`make ${mode.endVisibility}`}
            </S.ChangeVisibilityButton>
        </DialogS.Actions>
    </Dialog>;

};

const S = {

    Body: styled.div`
        display: flex;
        flex-direction: column;
    `,

    ProjectItem: styled(ListItem)`
        padding-left: 0;
    `,

    Icon: styled(ListItemIcon)`
        min-width: 30px;
    `,

    Divider: styled(Divider)`
        margin-bottom: 16px;
    `,

    ChangeVisibilityButton: styled(Button)`
    `
};
