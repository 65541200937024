import {ArcQLGroupingType, FieldGrouping} from "metadata/query/ArcQLGroupings";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {AnalyticsType} from "metadata/AnalyticsType";
import {DateGrouping} from "metadata/query/DateGrouping";
import {DateGrain} from "metadata/query/DateGrain";
import {Column} from "metadata/Column";

/**
 * ArcQLGroupingFactory is a factory class for creating ArcQLGrouping instances.
 */
export class ArcQLGroupingFactory {

    public static fromJSON(json: any): ArcQLGrouping {
        return ArcQLGroupingType.get<ArcQLGroupingType>(json['type']).fromJson(json);
    }

    /**
     * Given a column, create the appropriate ArcQLGrouping.
     */
    public static fromColumn(column: Column): ArcQLGrouping {
        return column.analyticsType === AnalyticsType.DATE
            ? new DateGrouping(column.name, DateGrain.DAY)
            : new FieldGrouping(column.name);
    }
}
