import {Enum} from "common/Enum";


/**
 * Enum for Dataset display status.
 */
export class DatasetStatusDisplay extends Enum {
    static readonly SCHEDULED = new this('scheduled', 'Scheduled');
    static readonly STREAMING = new this('streaming', 'Streaming');
    static readonly ZERO_COPY = new this('zero_copy', 'Zero Copy');
    static readonly PERSONAL = new this('personal', 'Personal');

    private constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}