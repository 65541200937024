import {Enum} from "common/Enum";
import {DetailField, DetailOperator} from "metadata/query/DetailField";
import {DetailDateField} from "metadata/query/DetailDateField";
import {DateGrain} from "metadata/query/DateGrain";
import {ArcQLField} from "metadata/query/ArcQLField";
import {MeasureField, MeasureOperator} from "metadata/query/MeasureField";
import {JsonObject} from "common/CommonTypes";
import {ExpressionField} from "metadata/query/ExpressionField";

export class FieldSuperType extends Enum {
    static DETAIL = new this('detail');
    static AGGREGATE = new this('aggregate');
    static EITHER = new this('either');
}
FieldSuperType.finalize();

export class ArcQLFieldType extends Enum {

    static fromJSON(json: JsonObject): ArcQLField {
        return ArcQLFieldType.get<ArcQLFieldType>(json['type']).fromJSON(json);
    }

    static DETAIL = new ArcQLFieldType(
        'detail',
        'detail',
        FieldSuperType.DETAIL,
        (json: any) => new DetailField(json['field'], DetailOperator.get(json['operator']), json['as'])
    );
    static DETAIL_DATE = new ArcQLFieldType(
        'detailDate',
        'date',
        FieldSuperType.DETAIL,
        (json: any) => new DetailDateField(json['field'], DateGrain.get(json['grain']), json['as'])
    );
    static MEASURE = new ArcQLFieldType(
        'measure',
        'measure',
        FieldSuperType.AGGREGATE,
        (json: any) => new MeasureField(
            json['field'],
            MeasureOperator.get(json['operator']),
            json['as'],
            json['cumulative']
        )
    );
    static EXPRESSION = new ArcQLFieldType(
        'expression',
        'expression',
        FieldSuperType.EITHER,
        (json: any) => new ExpressionField(json['expression'], json['as'])
    );

    private constructor(
        name: string,
        public readonly label: string,
        public readonly superType: FieldSuperType,
        public readonly fromJSON: (json: any) => ArcQLField
    ) {
        super(name);
    }

}
ArcQLFieldType.finalize();