import {Enum} from "common/Enum";

/**
 * From trend's results, select which rows to use for alert triggering condition.
 */
export class RowsSelectorType extends Enum {
    static ALL = new this('all', 'All');
    static LAST = new this('last', 'Last');
    static SECOND_TO_LAST = new this('second_to_last', 'Second to Last');

    constructor(
        name: string,
        public readonly label: string
    ) {
        super(name);
    }
}

RowsSelectorType.finalize();