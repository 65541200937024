import React, {FunctionComponent, useState} from "react";
import styled from "@emotion/styled";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import {DataSourceTypeIcon} from "app/components/settings/connections/DataSourceTypeIcon";
import TableViewSharp from "@mui/icons-material/TableViewSharp";
import {Colors} from "app/components/StyleVariables";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Edit} from "@mui/icons-material";
import {StringUtils} from "common/StringUtils";

type Props = {
    description: string;
    onDescriptionChange: (description: string) => void;
    connection: RemoteDataSource;
    metadata: RemoteTableMetadata;
    fileName?: string;
};

/**
 * Header in DatasetV2Builder.
 */
export const DatasetV2BuilderHeader: FunctionComponent<Props> = (props: Props) => {

    const [descriptionAnchorEl, setDescriptionAnchorEl] = useState<HTMLElement | null>(null);
    const [descriptionDraft, setDescriptionDraft] = useState<string>(props.description || '');

    const onDescriptionClick = (event: React.MouseEvent<HTMLElement>) => {
        setDescriptionAnchorEl(event.currentTarget);
    };

    const onDescriptionSave = () => {
        props.onDescriptionChange(descriptionDraft);
        setDescriptionAnchorEl(null);
    };

    const onDescriptionClose = () => {
        setDescriptionAnchorEl(null);
    };

    const description = StringUtils.isAbsent(props.description) ? "Edit to add a description..." : props.description;

    return (
        <S.Header>
            <S.HeaderItem maxWidth={"150px"}>
                <S.HeaderItemLabel>Connection</S.HeaderItemLabel>
                <S.HeaderItemValue>
                    <DataSourceTypeIcon type={props.connection.type} size={"compact"}/>
                    {props.connection.label}
                </S.HeaderItemValue>
            </S.HeaderItem>
            <S.HeaderItem maxWidth={"200px"}>
                <S.HeaderItemLabel>Table</S.HeaderItemLabel>
                <S.HeaderItemValue>
                    <TableViewSharp color={"disabled"}/>
                    {props.metadata.table.name}
                </S.HeaderItemValue>
            </S.HeaderItem>
            {
                props.fileName && (
                    <S.HeaderItem maxWidth={"200px"}>
                        <S.HeaderItemLabel>File</S.HeaderItemLabel>
                        <S.HeaderItemValue>{props.fileName}</S.HeaderItemValue>
                    </S.HeaderItem>
                )
            }
            <S.HeaderItem maxWidth={"50px"}>
                <S.HeaderItemLabel>Rows</S.HeaderItemLabel>
                <S.HeaderItemValue>{props.metadata.rowCount}</S.HeaderItemValue>
            </S.HeaderItem>
            <S.HeaderItem maxWidth={"70px"}>
                <S.HeaderItemLabel>Columns</S.HeaderItemLabel>
                <S.HeaderItemValue>{props.metadata.columns.length}</S.HeaderItemValue>
            </S.HeaderItem>
            <S.HeaderItem maxWidth={"500px"}>
                <S.HeaderItemLabel>Dataset Description</S.HeaderItemLabel>
                <S.HeaderItemValue>
                    <Tooltip title={description} placement="bottom">
                        <S.TruncatedDescription onClick={onDescriptionClick}>
                            {description}
                        </S.TruncatedDescription>
                    </Tooltip>
                    <S.EditButton variant={"outlined"} onClick={onDescriptionClick}>
                        <Edit/>
                    </S.EditButton>
                    <Popover
                        open={Boolean(descriptionAnchorEl)}
                        anchorEl={descriptionAnchorEl}
                        onClose={onDescriptionClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <S.PopoverContent>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                value={descriptionDraft}
                                onChange={(e) => setDescriptionDraft(e.target.value)}
                                rows={5}
                            />
                            <S.PopoverActions>
                                <Button onClick={onDescriptionClose}>Cancel</Button>
                                <Button variant="contained" onClick={onDescriptionSave}>Save</Button>
                            </S.PopoverActions>
                        </S.PopoverContent>
                    </Popover>
                </S.HeaderItemValue>
            </S.HeaderItem>
        </S.Header>
    );
};

class S {

    static readonly Header = styled.div`
        display: flex;
        flex-direction: row;
        padding: 14px 24px;
        align-items: flex-start;
        gap: 30px;
        height: 39px;
        background-color: ${Colors.headerGrey};
    `;

    static readonly HeaderItem = styled.div<{
        maxWidth?: string
    }>`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        max-width: ${props => props.maxWidth};
        flex: 1;
    `;

    static readonly HeaderItemLabel = styled.div`
        color: ${Colors.textSecondary};
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

    static readonly HeaderItemValue = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        gap: 4px;
        height: 25px;
    `;

    static readonly PopoverContent = styled.div`
        padding: 16px;
        min-width: 500px;
    `;

    static readonly PopoverActions = styled.div`
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    `;

    static readonly EditButton = styled(Button)`
        padding: 4px;
        min-width: unset;
        margin-left: 4px;

        svg {
            font-size: 16px;
        }
    `;

    static readonly TruncatedDescription = styled.div`
        max-width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    `;

}
