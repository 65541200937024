import {Enum} from "common/Enum";
import {FQN} from "common/FQN";
import {Optional} from "common/Optional";
import {AssetType} from "metadata/AssetType";

export abstract class ArcQLSource {

    static fromJSON(json: any): ArcQLSource {
        return ArcQLSourceType.get<ArcQLSourceType>(json['type']).fromJson(json['ref']);
    }

    abstract get type(): ArcQLSourceType;

    /**
     * If this source is a persona, return the FQN of the persona. Otherwise, return none.
     */
    abstract get persona(): Optional<FQN>;

}

export class SingleSource implements ArcQLSource {

    static fromJSON(json: any): SingleSource {
        return new SingleSource(
            ArcQLSourceRefType.get(json['type']),
            FQN.parse(json['fullyQualifiedName'])
        );
    }

    public readonly type: ArcQLSourceType = ArcQLSourceType.SINGLE;

    constructor(
        public readonly refType: ArcQLSourceRefType,
        public readonly fqn: FQN
    ) {}

    public toJSON(): Object {
        return {
            'type': this.type,
            'ref': {
                'type': this.refType,
                'fullyQualifiedName': this.fqn.toString()
            }
        };
    }

    get persona(): Optional<FQN> {
        return this.fqn.type === AssetType.PERSONA ?
            Optional.some(this.fqn) :
            Optional.none();
    }

}

export class ArcQLSourceType extends Enum {

    static SINGLE = new ArcQLSourceType(
        'single',
        SingleSource.fromJSON
    );
    static UNION = new ArcQLSourceType(
        'union',
        () => null
    );
    static JOIN = new ArcQLSourceType(
        'join',
        () => null
    );

    private constructor(
        name: string,
        public readonly fromJson: (json: any) => ArcQLSource
    ) {
        super(name);
    }


}
ArcQLSourceType.finalize();


export class ArcQLSourceRefType extends Enum {

    static DATASET = new ArcQLSourceRefType('dataset');
    static ARCQL = new ArcQLSourceRefType('arcql');
    static PERSONA = new ArcQLSourceRefType('persona');

}
ArcQLSourceRefType.finalize();
