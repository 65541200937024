import {Either} from "common/Either";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {ServiceProvider} from "services/ServiceProvider";
import {RestService} from "services/RestService";
import {CompletionRequest} from "metadata/hypergraph/request/CompletionRequest";
import OrchestratorModel from "metadata/hypergraph/request/OrchestratorModel";
import {ApiResponse} from "services/ApiResponse";
import {HyperGraph} from "metadata/hypergraph/HyperGraph";
import {LLMMessage} from "metadata/hypergraph/request/LLMMessage";
import {ArcQL} from "metadata/query/ArcQL";
import {QueryResult} from "metadata/query/QueryResult";
import {EmbeddingRequest} from "metadata/hypergraph/request/EmbeddingRequest";
import EmbeddingModel from "metadata/hypergraph/request/EmbeddingModel";
import {AnswerResponse, AnswerWithNodesRequest, HyperGraphAnswer} from 'services/hypergraph/HyperGraphAnswer';
import {HyperGraphNodeHypothesis} from 'metadata/hypergraph/HyperGraphNodeHypothesis';
import {TrendsAndDataPoints, TrendsAndDataPointsJson} from 'metadata/hypergraph/request/TrendsAndDataPoints';
import {JsonObject} from "common/CommonTypes";
import {HyperGraphRequest} from 'metadata/hypergraph/HyperGraphRequest';
import {ArcDataset} from 'metadata/dataset/ArcDataset';
import {RequestNode} from "metadata/hypergraph/nodes/RequestNode";
import {HyperGraphNodeFactory} from "metadata/hypergraph/HyperGraphTypes";

export class HyperGraphService {

    /**
     * Raw completion that shouldn't be used in production, API is protected.
     */
    raw(messages: LLMMessage[], signal?: AbortSignal): Promise<Either<any, JsonObject>> {
        return ServiceProvider.get(RestService).post(
            '/api/v1/hypergraph/completion',
            new CompletionRequest(this.model, messages),
            signal
        ).then(ApiResponse.success(json => json));
    }

    /**
     * Summarize the difference between a parent and resulting query.
     */
    summarizeQueryChanges(
        parentHypothesis: HyperGraphNodeHypothesis,
        parentQuery: ArcQL,
        parentResult: QueryResult,
        newQuery: ArcQL,
        newResult: QueryResult,
        nTrends: number = 3,
        nDataPoints: number = 10,
        signal?: AbortSignal
    ): Promise<Either<any, TrendsAndDataPoints>> {
        return ServiceProvider.get(RestService).post(
            '/api/v1/hypergraph/summarizeQueryChanges',
            {
                'parentHypothesis': parentHypothesis,
                'parentQuery': parentQuery,
                'parentResult': parentResult,
                'newQuery': newQuery,
                'newResult': newResult,
                'nTrends': nTrends,
                'nDataPoints': nDataPoints
            },
            signal
        ).then(ApiResponse.success(json => TrendsAndDataPoints.fromJSON(json as TrendsAndDataPointsJson)));
    }

    completeNode<R extends HyperGraphRequest, RE, N extends HyperGraphNode<R>>(
        // node already in the graph that we will be requesting a completion for
        node: RequestNode,
        // factory method use to deserialize the completion response into a node
        factory: HyperGraphNodeFactory<RE, N>,
        hyperGraph: HyperGraph,
        dataset: ArcDataset,
        signal?: AbortSignal
    ): Promise<Either<any, N>> {
        return ServiceProvider.get(RestService).post(
            '/api/v1/hypergraph/completeNode',
            {
                'nodeId': node.id,
                'hyperGraph': hyperGraph,
                'datasetFqn': dataset.fqn.toString()
            },
            signal
        ).then(ApiResponse.success(json => factory(json as RE)));
    }

    /**
     * Try to answer a question with the given nodes.
     */
    answerWithNodes(
        question: string,
        nodes: HyperGraphNode[],
        graph: HyperGraph,
        dataset: ArcDataset,
        signal?: AbortSignal
    ): Promise<Either<any, HyperGraphAnswer>> {
        const primary = nodes[0];
        const supporting = nodes.slice(1);

        const request: AnswerWithNodesRequest = {
            question: question,
            primary: {
                label: primary.label(graph),
                embeddableContent: primary.embeddingContent(graph),
                arcql: primary.getQuery(graph).toJSON('arcql', false, false),
                sql: primary.getQueryResult(graph).sql,
                rows: primary.getQueryResult(graph).rows
            },
            secondaries: supporting.map((s, sI) => ({
                label: s.label(graph),
                embeddableContent: s.embeddingContent(graph),
                arcql: s.getQuery(graph).toJSON('arcql', false, false),
                sql: s.getQueryResult(graph).sql,
                rows: sI < 1 ? s.getQueryResult(graph).rows : undefined
            })),
            datasetFqn: dataset.fqn
        };

        return ServiceProvider.get(RestService).post('/api/v1/hypergraph/answerWithNodes', request, signal)
            .then(ApiResponse.success((response: AnswerResponse) => {
                const whys = [response.primary, ...response.secondaries];
                return new HyperGraphAnswer(response.answer, nodes.map((node, nodeI) => ({
                    node,
                    why: whys[nodeI]
                })));
            }));
    }

    embedding(content: string, signal?: AbortSignal): Promise<Either<any, number[]>> {
        return ServiceProvider.get(RestService).post(
            '/api/v1/hypergraph/embedding',
            new EmbeddingRequest(EmbeddingModel.PLANE, content),
            signal
        ).then(ApiResponse.success(json => json as number[]));
    }

    /**
     * Figure out which model to use.
     */
    private get model(): OrchestratorModel {
        // @ts-ignore
        return OrchestratorModel.possible(window.hyperGraphModel)
            .getOr(OrchestratorModel.CASCADE);
    }

}
