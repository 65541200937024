import {Enum} from "common/Enum";
import {ArcMetadataChange, ChangeType} from "metadata/ArcMetadataChange";

/**
 * The type of all the changes that are made in a single set of changes being applied to an ArcMetadata object
 */
export class ChangeSetType extends Enum {
    static readonly ADD = new this('add');
    static readonly DELETE = new this('delete');
    static readonly MODIFY = new this('modify');
    static readonly REPLACE = new this('replace');
    // If a set of changes has various different ChangeTypes
    static readonly MIXED = new this('mixed');
    static readonly NONE = new this('none');

    static computeTypeFromChanges<T>(changes: ArcMetadataChange<T>[]): ChangeSetType {
        if (changes.length === 0) {
            return ChangeSetType.NONE;
        }

        const types: Set<ChangeType> = new Set();
        changes.forEach(change => types.add(change.changeType));
        if (types.size === 1) {
            switch (types.values().next().value) {
                case ChangeType.ADD:
                    return ChangeSetType.ADD;
                case ChangeType.DELETE:
                    return ChangeSetType.DELETE;
                case ChangeType.MODIFY:
                    return ChangeSetType.MODIFY;
                case ChangeType.REPLACE:
                    return ChangeSetType.REPLACE;
            }
        } else {
            return ChangeSetType.MIXED;
        }
    }
}
ChangeSetType.finalize();