import {Optional} from "common/Optional";
import React from "react";
import {ScopedSearch} from "app/home/ScopedSearch";
import BrowseView from "app/home/browse/BrowseView";
import {FolderResult} from "metadata/project/FolderResult";

export class ScopedSearchNone implements ScopedSearch {

    get account(): Optional<string> {
        return Optional.none();
    }

    get project(): Optional<FolderResult> {
        return Optional.none();
    }

    renderChip(): React.JSX.Element {
        return <></>;
    }

    browseView(queryPresent: boolean): BrowseView {
        return queryPresent ? BrowseView.BROWSE_WITH_QUERY : BrowseView.BROWSE_ALL;
    }

    searchBarPlaceholderText(): string {
        return "Search for dashboards, queries, projects, data, or accounts";
    }

}