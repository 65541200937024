import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {JsonObject} from "common/CommonTypes";
import {SessionType} from "metadata/session/SessionType";
import {ArcSession} from "metadata/session/ArcSession";

/**
 * Service to load sessions (i.e. a saved state of user interactions for dashboard, arcqls, etc.).
 */
export abstract class SessionService {

    /**
     * Create a new session.
     */
    abstract create(
        path: string,
        type: SessionType,
        referenceId: string,
        state: JsonObject,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcSession>>;

    /**
     * Get metadata of a session.
     */
    abstract get(
        sessionId: string,
        signal?: AbortSignal
    ): Promise<Either<ErrorResponse, ArcSession>>;
}