import {User} from "@auth0/auth0-react";

export type HyperArcUserJson = {
    id: string,
    username: string,
    email: string,
    createdOn: string
}

export class HyperArcUser {

    static fromJSON(json: {[key: string]: any}, user?: User): HyperArcUser {
        return new HyperArcUser(
            json["id"],
            json["username"],
            json["email"],
            json["createdOn"],
            user && user.name,
            user && user.picture
        );
    }

    constructor(
        public readonly id: string,
        public readonly username: string,
        public readonly email: string,
        public readonly createdOn: string,
        public readonly name: string,
        public readonly picture: string = null
    ) { }

    get initials(): string {
        return this.username.substring(0, 1);
    }

}