import {VizSelection} from "engine/actor/VizSelection";
import {CartesianChartConfig} from "app/visualizations/config/CartesianChartConfig";
import {ChartConfig, SelectionType} from "app/visualizations/config/ChartConfig";

export class BarChartConfig extends CartesianChartConfig {

    get isStacked(): boolean {
        return this.config.get('stack').getOr(true);
    }

    get chartType(): string {
        const isVertical = this.config.get('vertical').getOr(true);

        if (this.config.get('scroll').getOr(true)) {
            if (this.isHierarchical) {
                if (isVertical) {
                    return this.isStacked ? 'scrollstackedcolumn2d' : 'scrollcolumn2d';
                } else {
                    return this.isStacked ? 'scrollstackedbar2d' : 'scrollbar2d';
                }
            } else {
                return isVertical ? 'scrollcolumn2d' : 'scrollbar2d';
            }
        } else {
            if (this.isHierarchical) {
                if (isVertical) {
                    return this.isStacked ? 'stackedcolumn2d' : 'mscolumn2d';
                } else {
                    return this.isStacked ? 'stackedbar2d' : 'msbar2d';
                }
            } else {
                return isVertical ? 'mscolumn2d': 'msbar2d';
            }
        }
    }

    get selectionType(): SelectionType {
        return SelectionType.DISCRETE;
    }

    build(size: [number, number], selections: VizSelection): {[key: string]: any} {
        const rotateValues = this.config.get('rotateValues').getOr(false);

        // if showing rotated values, need to make a bit more space for them
        const dynamicConfigs: {[key: string]: any} = {};
        if (rotateValues && this.config.get('vertical').getOr(true)) {
            dynamicConfigs['canvasTopMargin'] = 100;
        }

        return {
            type: this.chartType,
            width: size[0],
            height: size[1],
            dataFormat: 'json',
            dataSource: {
                chart: Object.assign(
                    ChartConfig.buildConfig(
                        this.config.emptyableString('title').isPresent,
                        this.config.emptyableString('subTitle').isPresent,
                    ),
                    {
                        paletteColors: this.config.theme.toConfig(),
                        caption: this.config.emptyableString('title').nullable,
                        subCaption: this.config.emptyableString('subTitle').nullable,
                        captionAlignment: this.config.get('titlePosition').getOr('left'),

                        showLegend: this.config.get('showLegend').getOr(true),

                        xAxisName: this.config.emptyableString('xAxisName')
                            .getOr(this.xAxisName(this.isHierarchical)),
                        yAxisName: this.config.emptyableString('yAxisName')
                            .getOr(this.response.arcql.fields.first.as),

                        stack100Percent: this.config.get('stack100').getOr(false),
                        showSum: this.config.get('showSum').getOr(false),
                        showValues: this.config.get('showValues').getOr(false),
                        rotateValues: rotateValues,

                        plotToolText: this.data.plotToolText,
                        numVisiblePlot: Math.floor(
                            // pick the right dimensions and size each bar to 25px
                            size[this.config.get('vertical').getOr(true) ? 0 : 1] / 25
                        ),
                        labelDisplay: 'auto',
                        useEllipsesWhenOverflow: '1',
                        plotFillAlpha: '80',
                        slantLabels: true
                    },
                    dynamicConfigs
                ),
                categories: this.data.categories,
                dataset: this.data.dataset(selections)
            }
        };
    }

}