import {DataType} from "metadata/DataType";
import {AnalyticsType} from "metadata/AnalyticsType";
import {RemoteTableMetadata} from "metadata/connections/RemoteTableMetadata";
import {DatasetV2Column} from "metadata/dataset/DatasetV2Column";
import {RemoteDataSource} from "metadata/connections/RemoteDataSource";
import {ConnectionType} from "metadata/connections/ConnectionType";
import {DatasetV2} from "metadata/dataset/DatasetV2";
import {DatasetType} from "metadata/dataset/DatasetType";
import {SnowflakeDatasetConfig} from "metadata/dataset/snowflake/SnowflakeDatasetConfig";
import {DatasetConfig} from "metadata/dataset/DatasetConfig";
import {ColumnMetadata} from "metadata/dataset/ColumnMetadata";
import {SnowflakeColumnMetadata} from "metadata/dataset/snowflake/SnowflakeColumnMetadata";
import {BigQueryDatasetConfig} from "metadata/dataset/bigquery/BigQueryDatasetConfig";
import {BigQueryColumnMetadata} from "metadata/dataset/bigquery/BigQueryColumnMetadata";
import {GeneratedColumnInfo} from "metadata/dataset/GeneratedColumnInfo";
import {FQN} from "common/FQN";
import {StringUtils} from "common/StringUtils";
import {PersonalColumnMetadata} from "metadata/dataset/personal/PersonalColumnMetadata";
import {Column} from "metadata/Column";
import {ProjectionValidation} from "metadata/query/ProjectionValidation";

/**
 * A single dataset column that gets mapped to a row in the dataset editor table
 */
export class DatasetV2BuilderColumn {
    public constructor(
        public readonly name: string,
        public readonly type: DataType,
        public readonly originalColumnType: AnalyticsType,
        public readonly isValid: boolean,
        public readonly include: boolean,
        public readonly isDimension: boolean,
        public readonly label: string,
        public readonly description: string,
        public readonly metadata: ColumnMetadata,
        public readonly descriptionHelperText?: string
    ) {
    }

    /**
     * Has invalid label.
     */
    get hasInvalidLabel(): boolean {
        return !ProjectionValidation.isValid(this.label);
    }

    with({
        include,
        isDimension,
        label,
        description,
        metadata,
        descriptionHelperText
    }: Partial<DatasetV2BuilderColumn>): DatasetV2BuilderColumn {
        return new DatasetV2BuilderColumn(
            this.name,
            this.type,
            this.originalColumnType,
            this.isValid,
            include ?? this.include,
            isDimension ?? this.isDimension,
            label ?? this.label,
            description ?? this.description,
            metadata ?? this.metadata,
            descriptionHelperText !== undefined ? descriptionHelperText : this.descriptionHelperText
        );
    };

    /**
     * Checks to see if current column is equal to another column. Blank string equates to null or undefined.
     */
    equals(other: DatasetV2BuilderColumn): boolean {
        return this.name === other.name
            && this.type === other.type
            && this.originalColumnType === other.originalColumnType
            && this.isValid === other.isValid
            && this.include === other.include
            && this.isDimension === other.isDimension
            && StringUtils.equalsNormalized(this.label, other.label)
            && StringUtils.equalsNormalized(this.description, other.description)
            && JSON.stringify(this.metadata) === JSON.stringify(other.metadata);
    }

    toDatasetV2Column(): DatasetV2Column {
        return new DatasetV2Column(
            this.name,
            this.label,
            this.description,
            this.columnType,
            this.type,
            true,
            null,
            this.metadata
        );
    }

    /**
     * Convert to unified dataset column format.
     */
    toArcColumn(): Column {
        return Column.fromJSON(
            {
                name: this.name,
                label: this.label,
                description: this.description,
                columnType: this.columnType,
                dataType: this.type,
                isPublic: this.include,
                parentColumnName: null
            }
        );
    }

    private get columnType(): AnalyticsType {
        return this.originalColumnType === AnalyticsType.MEASURE && this.isDimension ? AnalyticsType.DIMENSION : this.originalColumnType;
    }

}

/**
 * Stores the state that gets updated when building a dataset
 */
export class DatasetV2BuilderState {

    public constructor(
        public readonly id: string | null,
        public readonly name: string | null,
        public readonly label: string | null,
        public readonly description: string | null,
        public readonly fqn: FQN,
        public readonly datasetType: DatasetType,
        public readonly datasetConfig: DatasetConfig,
        public readonly rows: DatasetV2BuilderColumn[]
    ) {
    }

    /**
     * Checks to see if it's an existing/persisted dataset.
     */
    get isExisting(): boolean {
        return this.id != null;
    }

    /**
     * Get column by name.
     */
    getColumn(name: string): DatasetV2BuilderColumn {
        return this.rows.find(c => c.name === name);
    }

    /**
     * Update a single dataset column, identified by the name of the column
     */
    withUpdatedColumn(builderColumn: DatasetV2BuilderColumn): DatasetV2BuilderState {
        return new DatasetV2BuilderState(
            this.id,
            this.name,
            this.label,
            this.description,
            this.fqn,
            this.datasetType,
            this.datasetConfig,
            this.rows.map(r => r.name === builderColumn.name ? builderColumn : r)
        );
    }

    /**
     * Update labels + descriptions of columns.
     */
    withGeneratedColumnInfos(infos: Map<string, GeneratedColumnInfo>): DatasetV2BuilderState {
        return new DatasetV2BuilderState(
            this.id,
            this.name,
            this.label,
            this.description,
            this.fqn,
            this.datasetType,
            this.datasetConfig,
            this.rows.map(row => {
                    const columnInfo = infos.get(row.name);
                    const shouldReplaceLabel = row.hasInvalidLabel || StringUtils.isAbsent(row.label);
                    return row.with(
                        {
                            label: shouldReplaceLabel ? columnInfo.label : row.label,
                            description: columnInfo.description,
                            descriptionHelperText: columnInfo.suggestedImprovement
                        }
                    );
                }
            )
        );
    }

    with({
        name = this.name,
        label = this.label,
        description = this.description,
    }: Partial<DatasetV2BuilderState>): DatasetV2BuilderState {
        return new DatasetV2BuilderState(
            this.id,
            name,
            label,
            description,
            this.fqn,
            this.datasetType,
            this.datasetConfig,
            this.rows
        );
    }

    toDatasetV2(): DatasetV2 {
        return new DatasetV2(
            this.id,
            this.name,
            this.label,
            this.description,
            this.datasetType,
            this.datasetConfig,
            new Map(this.rows.filter(row => row.include).map(row => [row.name, row.toDatasetV2Column()])),
            this.fqn,
            null
        );
    }


    /**
     * Initialize an empty state with all the rows from the underlying remote table
     */
    static fromNewTable(tableMetadata: RemoteTableMetadata, connection: RemoteDataSource): DatasetV2BuilderState {
        const datasetType: DatasetType = this.datasetTypeFromConnectionType(connection.type);
        return new DatasetV2BuilderState(
            null,
            null,
            null,
            null,
            null,
            datasetType,
            this.getDefaultDatasetConfig(datasetType, tableMetadata, connection),
            tableMetadata.columns.map(column => new DatasetV2BuilderColumn(
                column.name,
                column.type,
                column.columnType,
                column.isValid,
                column.isValid,
                false,
                '',
                '',
                this.getDefaultColumnMetadata(datasetType)
            ))
        );
    }

    /**
     * Initialize a state combining the existing dataset with the rows from the underlying remote table
     */
    static fromExistingDataset(tableMetadata: RemoteTableMetadata, dataset: DatasetV2): DatasetV2BuilderState {
        const builderColumns: DatasetV2BuilderColumn[] = [];
        for (const column of tableMetadata.columns) {
            const existingColumn = dataset.columns.get(column.name);
            builderColumns.push(new DatasetV2BuilderColumn(
                column.name,
                column.type,
                column.columnType,
                column.isValid,
                // Only include the columns that are currently a part of the existing dataset
                existingColumn != null,
                existingColumn ? existingColumn.columnType === AnalyticsType.DIMENSION : false,
                existingColumn ? existingColumn.label : '',
                existingColumn ? existingColumn.description : '',
                existingColumn ? existingColumn.metadata : this.getDefaultColumnMetadata(dataset.config.type)
            ));
        }
        return new DatasetV2BuilderState(
            dataset.id,
            dataset.name,
            dataset.label,
            dataset.description,
            dataset.fqn,
            dataset.type,
            dataset.config,
            builderColumns
        );
    }

    private static datasetTypeFromConnectionType(connectionType: ConnectionType): DatasetType {
        switch (connectionType) {
            case ConnectionType.SNOWFLAKE:
                return DatasetType.SNOWFLAKE;
            case ConnectionType.BIG_QUERY:
                return DatasetType.BIG_QUERY;
            default:
                throw new Error(`Unsupported connection type: ${connectionType}`);
        }
    }

    private static getDefaultDatasetConfig(datasetType: DatasetType, tableMetadata: RemoteTableMetadata, connection: RemoteDataSource): DatasetConfig {
        switch (datasetType) {
            case DatasetType.SNOWFLAKE:
                return new SnowflakeDatasetConfig(connection.id, tableMetadata.table.databaseName, tableMetadata.table.schemaName, tableMetadata.table.name);
            case DatasetType.BIG_QUERY:
                return new BigQueryDatasetConfig(connection.id, tableMetadata.table.databaseName, tableMetadata.table.schemaName, tableMetadata.table.name);
            default:
                throw new Error(`Unsupported dataset type: ${datasetType}`);
        }
    }

    private static getDefaultColumnMetadata(datasetType: DatasetType): ColumnMetadata {
        switch (datasetType) {
            case DatasetType.SNOWFLAKE:
                return new SnowflakeColumnMetadata();
            case DatasetType.BIG_QUERY:
                return new BigQueryColumnMetadata();
            case DatasetType.PERSONAL:
                return new PersonalColumnMetadata();
            default:
                throw new Error(`Unsupported dataset type: ${datasetType}`);
        }
    }
}