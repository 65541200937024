import React, {FunctionComponent, ReactNode, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import {StylingEnum} from "metadata/dashboard/widgets/config/StylingEnum";
import Tooltip from "@mui/material/Tooltip";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import styled from "@emotion/styled";
import {Colors} from "app/components/StyleVariables";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

interface Props {
    id: string;
    tooltipCategorization: string
    menuButtonIcon: ReactNode
    prefixLabel?: string;
    current: StylingEnum;
    onStylingChange: (s: StylingEnum) => void;
    styles: StylingEnum[];
}


export const StyleEnumMenu: FunctionComponent<Props> = (props) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const onMenuClick = () => {
        setShowMenu(!showMenu);
    };

    const onStyleEnumClick = (styleEnum: StylingEnum) => {
        props.onStylingChange(styleEnum);
        setShowMenu(false);
    };

    const onCloseMenu = () => {
        setShowMenu(false);
    };

    const prefixLabel = props.prefixLabel ? props.prefixLabel + " " : "";

    return (
        <div ref={anchorRef}>
            <Tooltip title={props.tooltipCategorization} placement="top" arrow>
                <IconButton
                    onClick={onMenuClick}
                >
                    {props.menuButtonIcon}
                </IconButton>
            </Tooltip>

            {showMenu &&
                <Menu
                    id={`${props.id}-menu`}
                    anchorEl={anchorRef.current}
                    keepMounted
                    open={Boolean(anchorRef)}
                    onClose={onCloseMenu}
                >
                    {props.styles.map((style) => (
                        <S.MenuItem
                            key={style.name}
                            selected={style === props.current}
                            onClick={() => {
                                onStyleEnumClick(style);
                            }}
                        >
                            {style === props.current && (
                                <S.SelectedIcon>
                                    <S.CheckIcon/>
                                </S.SelectedIcon>
                            )}
                            {style !== props.current && <S.NonSelectedMargin/>}
                            <ListItemText primary={prefixLabel + style.label}/>
                        </S.MenuItem>
                    ))}
                </Menu>
            }
        </div>
    );
};

class S {

    static MenuItem = styled(MenuItem)`
        padding-left: 8px;
    `;

    static SelectedIcon = styled(ListItemIcon)`
        &.MuiListItemIcon-root {
            min-width: 0;
            padding-right: 8px;
        }
    `;

    static CheckIcon = styled(CheckOutlinedIcon)`
        width: 16px;
        height: 16px;
        color: ${Colors.hyperarcYellow}
    `;

    static NonSelectedMargin = styled.div`
        width: 24px;
    `;
}