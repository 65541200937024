import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetStatusMetadata} from "metadata/dataset/DatasetStatusMetadata";
import {DatasetStatusDisplay} from "metadata/dataset/DatasetStatusDisplay";


type SnowflakeDatasetStatusMetadataJson = {}

/**
 * Metadata for a Snowflake dataset status.
 */
export class SnowflakeDatasetStatusMetadata implements DatasetStatusMetadata {

    static fromJSON(json: SnowflakeDatasetStatusMetadataJson): SnowflakeDatasetStatusMetadata {
        return new SnowflakeDatasetStatusMetadata();
    }

    get sources(): DatasetType[] {
        return [DatasetType.SNOWFLAKE];
    }

    get isStreamable(): boolean {
        return false;
    }

    get type(): DatasetType {
        return DatasetType.SNOWFLAKE;
    }

    get statusDisplay() {
        return DatasetStatusDisplay.ZERO_COPY;
    }
}