import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {VizSelection} from "engine/actor/VizSelection";
import {ScatterData} from "app/visualizations/ScatterData";


/**
 * Builds the dataset for a multi metric scatter chart.
 */
export class SingleSeriesScatterData extends ScatterData {

    private readonly categoryColumnIndices: number[];

    constructor(response: ArcQLResponse, palette: string[], hasZ: boolean) {
        super(response, palette, hasZ);

        this.categoryColumnIndices = response.result.categoryColumns.map(c => c.right);
    }

    /**
     * Every unique grouping value ("category" in cartesian charts) will get its own series.
     */
    dataset(selections: VizSelection): {[key: string]: any}[] {
        // go through the rows and add the data point to the appropriate category
        const result = this.response.result;

        return [{
            anchorSides: 0,
            anchorRadius: 4,
            anchorBorderColor: this.palette[0],
            anchorBgColor: this.palette[0],

            data: result.mapRows((row: any[]) => {
                return this.buildDataPoint(
                    row,
                    result.rowLabel(row, this.categoryColumnIndices),
                    selections
                );
            })
        }];
    }

}