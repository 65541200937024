import {JsonObject} from "common/CommonTypes";
import {FilterSetType} from "metadata/filterset/FilterSetType";
import {FilterSetData} from "metadata/filterset/FilterSetData";

export class FilterSetValueList implements FilterSetData {

    static fromJSON(json: JsonObject): FilterSetValueList {
        return new FilterSetValueList(
            json['values']
        )
    }

    public constructor(
        public readonly values: string[]
    ) {
    }

    toJSON(): JsonObject {
        return {
            'filterSetType': this.type,
            'values': this.values
        }
    }

    withValues(values: string[]): FilterSetData {
        return new FilterSetValueList(values);
    }

    get type(): FilterSetType {
        return FilterSetType.LIST;
    }
}