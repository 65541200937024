import React, {FunctionComponent} from "react";
import {TestConnectionProps} from "app/components/settings/connections/ConnectionStepperHandler";
import {DialogS} from "app/DialogS";
import {SnowflakeConnectionDetails} from "metadata/connections/snowflake/SnowflakeConnectionDetails";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import {CodeBlock} from "app/components/CodeBlock";

/**
 * Test Snowflake connection details.
 */
export const TestSnowflakeConnection: FunctionComponent<TestConnectionProps> = (props: TestConnectionProps) => {

    const details = props.connection.connectionDetails as SnowflakeConnectionDetails;

    const setPublicKey = `ALTER USER ${details.username} SET RSA_PUBLIC_KEY='${details.publicKey}';`;
    return (
        <S.Container>
            <DialogS.Description>
                Your Public Key:
            </DialogS.Description>
            <CodeBlock
                language={"bash"}
                code={details.publicKeyPem}
            />
            <DialogS.Description>
                <strong>Important: </strong>
                {"You must assign this public key to your Snowflake user by executing the following "}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"https://docs.snowflake.com/en/user-guide/key-pair-auth#assign-the-public-key-to-a-snowflake-user"}
                >
                    ALTER USER
                </a>
                {" command:"}
            </DialogS.Description>
            <CodeBlock
                language={`sql`}
                code={setPublicKey}
            />
            <S.Button
                variant={'outlined'}
                onClick={props.onTestConnection}
            >
                Test Connection
            </S.Button>
        </S.Container>
    );
};

class S {
    static readonly Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `;
    static readonly Button = styled(Button)`
        width: fit-content;
        text-transform: none;
    `;
}