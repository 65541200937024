import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {JsonObject} from "common/CommonTypes";
import {WidgetConfigPartType} from "metadata/dashboard/widgets/config/WidgetConfigPartType";

export type JsonValueHandler = (value: any) => any;

export abstract class BaseWidgetConfigPart<T> implements WidgetConfigPart {
    protected constructor(
        /** Defaults for this specific config part */
        protected readonly defaults: T
    ) {
    }

    /** Factory method to create a new instance of this config props part. */
    abstract createInstance(props: Record<string, any>, defaults?: T): T;

    abstract get type(): WidgetConfigPartType;

    /** Keys of a part that should be serialized to JSON. */
    protected abstract get properties(): (keyof T & string)[];

    /** Modify config props with given partial change. */
    with(changes: Partial<Record<keyof T, any>>): T {
        // shallow copy of itself
        const updatedConfig = {...this};

        this.properties.forEach(key => {
            if (changes[key] !== undefined) {
                (updatedConfig as any)[key] = changes[key];
            }
        });

        return this.createInstance(updatedConfig, this.defaults);
    }

    /** Create a new instance with given defaults. */
    withDefaults(defaults: T): T {
        return this.createInstance(this, defaults);
    }

    /**
     * Get the value of a property, or the given override default if the property is not set.
     */
    getValue<K extends keyof T>(property: K, overrideDefault?: T[K]): T[K] {
        const propertyValue = (this as any)[property];
        if (propertyValue === undefined || propertyValue === null) {
            return overrideDefault !== undefined ? overrideDefault : this.defaults[property];
        }
        return propertyValue;
    }

    /**
     * Serializes this config part to a JSON object, i.e a map of property names to values.
     * Note: distinction of property name to JSON name is assumed 1:1 (ex: verticalAlignment -> verticalAlignment)
     */
    toJSON(): JsonObject {
        const json: JsonObject = {};
        this.properties.forEach(key => {
            const propertyValue = (this as any)[key];
            // Check if propertyValue is an object and has toJSON method
            // The reason why: output of toJSON expects the enum.name of Enum class
            if (propertyValue && typeof propertyValue === 'object' && typeof propertyValue.toJSON === 'function') {
                json[key as string] = propertyValue.toJSON();
            } else if (propertyValue instanceof Set) {
                // Convert Set elements to JSON if they have a toJSON method
                json[key as string] = Array.from(propertyValue).map(item =>
                    item && typeof item.toJSON === 'function' ? item.toJSON() : item
                );
            }
            else {
                json[key as string] = propertyValue;
            }
        });
        return json;
    }

    /** Static helper method to create an instance part from a JSON object.
     *  Note: special property handlers need to be passed if the property is not a primitive type like an Enum.
     */
    static fromJSONHelper<T extends BaseWidgetConfigPart<T>>(json: JsonObject, defaults: T, jsonHandlers: Partial<Record<keyof T, JsonValueHandler>> = {}): T {
        const jsonValues: JsonObject = {};
        defaults.properties.forEach(key => {
            const jsonValue = json[key];
            const handler = jsonHandlers[key];
            jsonValues[key] = handler && jsonValue != null ? handler(jsonValue) : jsonValue;
        });

        return defaults.createInstance(jsonValues, defaults);
    }
}