import {ArcQL} from "metadata/query/ArcQL";
import React, {FunctionComponent, memo, ReactNode, useEffect, useState} from "react";
import {ArcQLResponse} from "metadata/query/ArcQLResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {QueryService} from "services/QueryService";
import {zip} from "common/Collections";
import {ArcVisualization} from "app/visualizations/ArcVisualization";
import {Optional} from "common/Optional";
import styled from "@emotion/styled";
import {Colors, FontSizes} from "app/components/StyleVariables";
import Tooltip from "@mui/material/Tooltip";

export type Props = {
    // cells to show
    cells: Cell[]
}

export type Cell = {
    query: ArcQL
    label: string
    description?: string
}

/**
 * Display a list of queries as small multiples or as a trellis.
 *
 * @author zuyezheng
 */
export const SmallMultiples: FunctionComponent<Props> = memo(
    (props: Props) => {
        const [responses, setResponses] = useState<Map<number, ArcQLResponse>>(new Map());

        // make all the queries
        useEffect(() => {
            //reset response
            setResponses(new Map());

            // make the queries
            const queryService = ServiceProvider.get(QueryService);
            const controllers = props.cells.map((cell, cellI) => {
                const controller = new AbortController();
                queryService.query(cell.query, controller.signal).then(result =>
                    result.match(
                        (response) => {
                            // queries will be cached at this point so use the setter with a callback to update the most
                            // recent version of queries
                            setResponses(queries => {
                                const newQueries = new Map(queries);
                                newQueries.set(cellI, response);
                                return newQueries;
                            });
                        },
                        error => {
                            console.log(error);
                        }
                    )
                );

                return controller;
            });

            return () => controllers.forEach(controller => controller.abort());
        }, [props.cells]);

        const buildCellLabel = (cell: Cell, isLoading: boolean): ReactNode => {
            const label = <S.Label>{isLoading ? 'Loading: ' : ''}{cell.label}</S.Label>;
            return cell.description == null ?
                label :
                <Tooltip title={cell.description} arrow>{label}</Tooltip>;
        };

        const buildVizContent = (
            // original query which will have the visualization configs
            arcql: ArcQL,
            // response
            response: ArcQLResponse
        ): ReactNode => {
            if (response) {
                return <S.Visualization>
                    <ArcVisualization
                        queryResponse={Optional.some(response)}
                        isBusy={false}
                        config={arcql.visualizations.default}
                    />
                </S.Visualization>;
            } else {
                // still loading
                return <S.Placeholder />;
            }
        };

        const buildVisualizationCell = (cellI: number): ReactNode => {
            const cell = props.cells[cellI];
            const response = responses.get(cellI);

            return <S.Cell key={cellI}>
                { buildVizContent(cell.query, response) }
                <S.Label>{ buildCellLabel(cell, response == null) }</S.Label>
            </S.Cell>;
        };

        const buildTrellis = (): ReactNode[] => {
            // figure out the dimensions of the trellis, start with columns to bias towards horizontal screen real estate
            const numCols = Math.ceil(Math.sqrt(props.cells.length));
            const numRows = Math.ceil(props.cells.length / numCols);

            const cols = [];
            for (let colI = 0; colI < numCols; colI++) {
                const rows = [];
                for (let rowI = 0; rowI < numRows; rowI++) {
                    const cellI = (colI * numRows) + rowI;
                    if (cellI >= props.cells.length) {
                        // empty cell
                        rows.push(<S.Cell key={cellI} />);
                    } else {
                        rows.push(buildVisualizationCell(cellI));
                    }
                }
                cols.push(<S.Column key={colI}>{rows}</S.Column>);
            }

            return cols;
        };

        return <S.Container>
            { buildTrellis() }
        </S.Container>;
    },
    // need to custom compare props since arrays will be different on each render when the underlying queries are the
    // same, this is problematic when queries are in flight as the changes will cancel the prior queries
    (prevProps, nextProps) => {
        if (prevProps.cells.length !== nextProps.cells.length) {
            return false;
        }

        // diff query hashes
        return zip(prevProps.cells, nextProps.cells)
            .every(([prev, next]) =>
                prev.query.hash() === next.query.hash() &&
                prev.label === next.label &&
                prev.description === next.description
            );
    }
);

class S {

    static Container = styled.div`
        display: flex;
        flex-direction: row;
        width: 100%;
    `;

    static Column = styled.div`
        display: flex;
        flex-direction: column;
        width: 1px;
        flex: 1;
        border-left: 1px solid ${Colors.borderGrey};
        
        &:first-of-type {
            border-left: none;
        }
    `;

    static Cell = styled.div`
        border-top: 1px solid ${Colors.borderGrey};
        height: 1px;
        flex: 1;
        display: flex;
        flex-direction: column;
        
        &:first-of-type {
            border-top: none;
        }
    `;

    static Placeholder = styled.div`
        flex: 1;
        height: 1px;
    `;

    static Visualization = styled.div`
        flex: 1;
        padding: 8px;
        height: 1px;
    `;

    static Label = styled.div`
        padding: 4px;
        background-color: #f8f8f8;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${FontSizes.small};
        font-weight: 600;
        color: ${Colors.textSecondary};
    `;

}
