import * as React from "react";
import {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {DataGridPro} from '@mui/x-data-grid-pro/DataGridPro';
import debounce from 'lodash/debounce';
import {Either} from 'common/Either';
import {Optional} from 'common/Optional';
import {ArcQLResponse} from 'metadata/query/ArcQLResponse';
import {ErrorResponse} from 'services/ApiResponse';
import {ServiceProvider} from 'services/ServiceProvider';
import {Colors, FontSizes} from "app/components/StyleVariables";
import {DimensionFilterHelper} from 'app/query/filters/DimensionFilterHelper';
import {Tuple} from "common/Tuple";
import {GridColDef, GridRowParams, GridRowsProp} from "@mui/x-data-grid";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {GRID_CHECKBOX_SELECTION_FIELD} from "@mui/x-data-grid/colDef/gridCheckboxSelectionColDef";
import {GridColumnHeaderParams} from "@mui/x-data-grid/models/params";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {RawFilterValue} from "metadata/query/filterclause/FilterClause";
import {QueryService} from "services/QueryService";
import {LiteralsFilterClause} from "metadata/query/filterclause/LiteralsFilterClause";

const SCOPE_TOOLTIP = 'Scope your search with respect to your existing filters, will be disabled if there\'s a filter expression.';

type Props = {
    arcqlBundle: ArcQLBundle
    filter: LiteralsFilterClause
    ordinal: number
    selected: Set<RawFilterValue>
    onChange: (selected: Set<RawFilterValue>) => void
}

/**
 * Pick values for a specific dimension.
 *
 * @author zuyezheng
 */
export const DimensionValueSearch: FunctionComponent<Props> = (props: Props) => {

    const [term, setTerm] = useState<string>('');
    // default to scoped value search unless there is a filter expression
    const [scoped, setScoped] = useState<boolean>(!props.arcqlBundle.arcql.filters.hasExpression);
    const [autocompleteResults, setAutocompleteResults] = useState<Optional<ArcQLResponse>>(Optional.none());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const search = (startsWith: string) => {
        const controller = new AbortController();

        const request = DimensionFilterHelper.distinctValuesRequest(
            props.arcqlBundle, props.filter, props.ordinal, startsWith, scoped
        );

        setIsLoading(true);
        // perform the actual query
        ServiceProvider.get(QueryService)
            .distinctValues(props.arcqlBundle.dataset, request, controller.signal)
            .then((results: Either<ErrorResponse, ArcQLResponse>) =>
                results.optional().forEach(response => {
                    setAutocompleteResults(
                        Optional.some(
                            DimensionFilterHelper.postProcessResults(
                                startsWith, response, props.selected
                            )
                        )
                    );
                    setIsLoading(false);
                })
            );

        return controller;
    };

    const autocomplete = useCallback(
        debounce(search, 500),
        [props.filter, props.selected, scoped]
    );

    useEffect(() => {
        // if the term is empty, likely just opened up the search so don't wait to debounce
        const controller = term.length === 0 ? search('') : autocomplete(term);

        return () => {
            if (controller != null) {
                controller.abort();
            }
        };
    }, [term, scoped]);

    const onColumnHeaderClick = (params: GridColumnHeaderParams) => {
        // since we're managing our own selection state we need to implement our own select all and none
        if (params.field === GRID_CHECKBOX_SELECTION_FIELD) {
            // see if it should be a select all or select none
            autocompleteResults
                .map(results => DimensionFilterHelper.toggleAll(results, props.selected))
                .forEach(props.onChange);
        }
    };

    const toggleSelection = (rowValue: string) => {
        autocompleteResults
            .map(_ => DimensionFilterHelper.toggleSelection(rowValue, props.selected))
            .forEach(props.onChange);
    };

    const onCellClick = (params: GridCellParams) => {
        // material ui checkboxes have a shitty interface, it only tells you the final end state, not the specific row
        // that changed and since we want to retain selections across autocomplete results, we need to manage state
        // ourselves since default selection state is reset with new data
        if (params.field === GRID_CHECKBOX_SELECTION_FIELD) {
            toggleSelection(params.row.result);
        }
    };

    const onRowClick = (params: GridRowParams) => {
        toggleSelection(params.row.result);
    };

    const gridData: Optional<Tuple<GridColDef[], GridRowsProp>> = useMemo(() => {
        // results should have 1 grouping of the dimension we care about and a count of the distinct values
        // if there are no results, show an empty grid and let loading state correctly handle spinner
        return autocompleteResults.map(
            results => DimensionFilterHelper.autocompleteResultsToGrid(results)
        ).orElse(() => Optional.of(Tuple.of([], [])));
    }, [autocompleteResults]);

    return <>
        <S.Controls>
            <S.Left>
                <S.SearchInput
                    InputProps={{
                        startAdornment:
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                    }}
                    value={term}
                    onChange={e => setTerm(e.target.value)}
                    size='small'
                    fullWidth
                    autoComplete='off'
                />
            </S.Left>
            <S.Right>
                Scoped search
                <Tooltip title={SCOPE_TOOLTIP} arrow>
                    <S.InfoOutlined/>
                </Tooltip>
                <Switch
                    color="primary"
                    checked={scoped}
                    disabled={props.arcqlBundle.arcql.filters.hasExpression}
                    onChange={e => setScoped(e.target.checked)}
                />
            </S.Right>
        </S.Controls>
        <S.SearchResults>{
            gridData.map(data =>
                <DataGridPro
                    loading={isLoading}
                    columns={data.left}
                    rows={data.right}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    checkboxSelection={true}
                    density="compact"
                    selectionModel={Array.from(props.selected)}
                    disableColumnMenu
                    disableColumnResize

                    onColumnHeaderClick={onColumnHeaderClick}
                    onCellClick={onCellClick}
                    onRowClick={onRowClick}
                />
            ).getOr(<></>)
        }</S.SearchResults>
    </>;

};

const S = {
    SearchContainer: styled.div`
        padding: 14px 0 6px;
        width: 100%;
    `,
    Controls: styled.div`
        display: flex;
    `,
    Left: styled.div`
        flex: 1;
    `,
    Right: styled.div`
        flex: 1;
        font-size: ${FontSizes.small};
        color: ${Colors.textSecondary};
        justify-content: right;
        display: flex;
        align-items: center;
    `,
    SearchInput: styled(TextField)`
        width: 250px;

        input {
            font-size: ${FontSizes.small};
        }
    `,
    SearchResults: styled.div`
        padding-top: 6px;
        width: 100%;
        height: 360px;
        font-size: ${FontSizes.small};
    `,
    InfoOutlined: styled(InfoOutlined)`
        height: 18px;
        width: 18px;
        color: ${Colors.iconSecondary};
    `
};