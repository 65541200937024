import {LinkConfigPart} from "metadata/dashboard/widgets/config/link/LinkConfigPart";
import {ContainerConfigPart} from "metadata/dashboard/widgets/config/container/ContainerConfigPart";
import {HorizontalAlignment} from "metadata/dashboard/widgets/config/HorizontalAlignment";
import {TextConfigPart} from "metadata/dashboard/widgets/config/text/TextConfigPart";
import {BaseWidgetConfig} from "metadata/dashboard/widgets/config/BaseWidgetConfig";
import {WidgetConfigPart} from "metadata/dashboard/widgets/config/WidgetConfigPart";
import {VerticalAlignment} from "metadata/dashboard/widgets/config/VerticalAlignment";
import {DashboardConfig} from "metadata/dashboard/DashboardConfig";

/**
 * Complete Configuration for Link Widget such as its container and text configuration.
 */
export class LinkWidgetConfig extends BaseWidgetConfig {

    static readonly CONTAINER_CONFIG_KEY = 'container';
    static readonly TEXT_CONFIG_KEY = 'text';
    static readonly LINK_CONFIG_KEY = 'link';

    constructor(
        public readonly container: ContainerConfigPart,
        public readonly text: TextConfigPart,
        public readonly link: LinkConfigPart
    ) {
        super();
    }

    get parts(): Map<string, WidgetConfigPart> {
        return new Map<string, WidgetConfigPart>([
            [LinkWidgetConfig.CONTAINER_CONFIG_KEY, this.container],
            [LinkWidgetConfig.TEXT_CONFIG_KEY, this.text],
            [LinkWidgetConfig.LINK_CONFIG_KEY, this.link]
        ]);
    }

    static fromMap(configMap: Map<string, any>, dashboardConfig: DashboardConfig): LinkWidgetConfig {
        let containerPart: ContainerConfigPart = ContainerConfigPart
            .fromJSON(configMap.get(LinkWidgetConfig.CONTAINER_CONFIG_KEY) || {});
        containerPart = dashboardConfig ? containerPart.withDefaults(dashboardConfig.container) : containerPart;
        return new LinkWidgetConfig(
            containerPart,
            TextConfigPart.fromJSON(
                configMap.get(LinkWidgetConfig.TEXT_CONFIG_KEY) || {},
                // default to center horizontal + vertical alignment for link widget
                TextConfigPart.defaults().with(
                    {
                        alignment: HorizontalAlignment.CENTER_ALIGNED,
                        verticalAlignment: VerticalAlignment.CENTER_ALIGNED
                    }
                )
            )
            ,
            LinkConfigPart.fromJSON(configMap.get(LinkWidgetConfig.LINK_CONFIG_KEY) || {})
        );
    }
}