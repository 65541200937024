import {Enum} from "common/Enum";
import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {ProcessFlowNode} from "app/query/hypergraph/nodes/ProcessFlowNode";
import {HyperGraphNodeProps} from "app/query/hypergraph/nodes/HyperGraphNodeProps";
import {ComponentType} from "react";
import {RootFlowNode} from "app/query/hypergraph/nodes/RootFlowNode";
import { ProcessStartNode } from "app/query/hypergraph/nodes/ProcessStartNode";
import { StartAnalysisNode } from "metadata/hypergraph/nodes/StartAnalysisNode";

/**
 * Maps HyperGraph nodes to react flow nodes for rendering.
 *
 * @author zuyezheng
 */
export class FlowNodeType extends Enum {

    static NODE_MAP: {[key: string]: ComponentType<HyperGraphNodeProps>};

    static readonly ROOT = new this(
        'root',
        150,
        30,
        RootFlowNode,
        node => node.isRoot
    );
    static readonly START = new this(
        'start',
        150,
        30,
        ProcessStartNode,
        node => node instanceof StartAnalysisNode
    );
    // this is the generic type and should be a last catch all
    static readonly PROCESS = new this(
        'process',
        240,
        50,
        ProcessFlowNode,
        () => true
    );

    constructor(
        name: string,
        // default dimensions
        public readonly width: number,
        public readonly height: number,
        // component to use to render the node
        private readonly component: ComponentType<HyperGraphNodeProps>,
        // is this the right flow node type for the given hypergraph node?
        private readonly isFor: (node: HyperGraphNode) => boolean
    ) {
        super(name);
    }

    static for(node: HyperGraphNode): FlowNodeType {
        return this.enums<FlowNodeType>().find(nodeType =>
            nodeType.isFor(node)
        );
    }

    static map(): {[key: string]: ComponentType<HyperGraphNodeProps>} {
        if (this.NODE_MAP == null) {
            // construct a map of all the custom node types
            this.NODE_MAP = this.enums<FlowNodeType>()
                .reduce<{[key: string]: ComponentType<HyperGraphNodeProps>}>(
                    (map, nodeType) => {
                        map[nodeType.name] = nodeType.component;
                        return map;
                    },
                    {}
                );
        }

        return this.NODE_MAP;
    }

}
FlowNodeType.finalize();
