import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {CommonS} from "app/components/CommonS";

export const LoginButton = () => {

    const { loginWithRedirect } = useAuth0();

    return <CommonS.Button onClick={() => loginWithRedirect()}>Log In</CommonS.Button>;

};