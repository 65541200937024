import {ArcQL} from "metadata/query/ArcQL";
import {DiscreteSelection, RangeSelection, VizSelection} from "engine/actor/VizSelection";
import {ArcQLGrouping} from "metadata/query/ArcQLGrouping";
import {ArcDataset} from "metadata/dataset/ArcDataset";
import {DateGrouping} from "metadata/query/DateGrouping";
import {SelectionMessage} from "engine/SelectionMessage";
import {DiscreteFacet, RangeFacet} from "engine/FacetingMessage";
import {Optional} from "common/Optional";
import {DataSuperType} from "metadata/DataType";

/**
 * Turn selections into facets.
 *
 * @author zuyezheng
 */
export class FacetingSelectionHandler {

    constructor(
        private readonly arcQL: ArcQL,
        private readonly dataset: ArcDataset
    ) {
    }

    /**
     * Delegate to the right selection handler based on the type of selection it is.
     */
    onSelection(selection: VizSelection): Optional<SelectionMessage> {
        return Optional.ofType(selection, DiscreteSelection)
            .flatMap(s => this.onDiscreteSelection(s))
            .orElse(() =>
                Optional.ofType(selection, RangeSelection)
                    .flatMap(s => this.onRangeSelection(s))
            );
    }

    onDiscreteSelection(selection: DiscreteSelection): Optional<SelectionMessage> {
        // need groupings to facet
        if (this.arcQL.groupings.size === 0) {
            return Optional.none();
        }

        // massage the selected values to what's expected by the column type as filters
        const facetRows = selection.rows().map(row =>
            this.arcQL.groupings.map((grouping: ArcQLGrouping, i: number) => {
                const column = this.dataset.get(grouping.field);
                switch (column.dataSuperType) {
                    case DataSuperType.TIMESTAMP:
                        return (grouping as DateGrouping).grain.formatMysteryDate(row[i]);
                    case DataSuperType.NUMBER:
                        return parseFloat(row[i]);
                    default:
                        return row[i];
                }
            })
        );

        return Optional.some(new SelectionMessage(
            this.dataset.fqn.toString(),
            selection,
            new DiscreteFacet(selection.fields, facetRows)
        ));
    }

    onRangeSelection(selection: RangeSelection): Optional<SelectionMessage> {
        // need a date for range selections
        const firstDate = this.arcQL.firstDate();
        if (firstDate == null) {
            return Optional.none();
        }
        const [_, grain, dateField] = firstDate;

        // fire an empty range to remove/select all
        if (selection.isEmpty()) {
            return Optional.some(new SelectionMessage(
                this.dataset.fqn.toString(),
                selection,
                new RangeFacet(
                    dateField.field,
                    null,
                    null
                )
            ));
        }

        return Optional.some(new SelectionMessage(
            this.dataset.fqn.toString(),
            selection,
            new RangeFacet(
                dateField.field,
                // fucking fusion is off by 1 hour for the local TZ even though its epoch, not clue what's going on
                grain.formatEpoch(selection.start - 3600000),
                grain.formatEpoch(selection.end - 3600000)
            )
        ));
    }

}