import * as React from 'react';
import {FunctionComponent, useEffect, useState} from 'react';
import {NodeDetailsS} from 'app/query/hypergraph/selection/NodeDetailsS';
import {HyperGraph} from 'metadata/hypergraph/HyperGraph';
import {EditType, HyperGraphNode} from 'metadata/hypergraph/HyperGraphNode';
import styled from '@emotion/styled';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TextField from '@mui/material/TextField';


export type Props = {
    hyperGraph: HyperGraph,
    node: HyperGraphNode,

    onClose(event: React.MouseEvent): void
    onHeaderClick(): void
    onChangeTitle(title: string): void
}

/**
 * Details header for a single node selection
 *
 * @author zuyezheng
 */
export const NodeDetailsHeader: FunctionComponent<Props> = (props: Props) => {

    const [isTitleHovered, setIsTitleHovered] = useState<boolean>(false);
    const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(props.node.label(props.hyperGraph));

    useEffect(() => {
        setTitle(props.node.label(props.hyperGraph));
    }, [props.node]);

    const canEditTitle = props.node.editTypes.has(EditType.TITLE);
    const startEditing = () => {
        if (!canEditTitle) {
            return;
        }
        setIsTitleEditing(true);
    };

    const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const onCommitLabelChange = () => {
        // see if there was a change
        if (title !== props.node.label(props.hyperGraph)) {
            props.onChangeTitle(title);
        }

        setIsTitleEditing(false);
    };

    const onClose = (e: React.MouseEvent) => {
        if (isTitleEditing) {
            setIsTitleEditing(false);
        } else {
            props.onClose(e);
        }
    };

    return <S.Header>
        <NodeDetailsS.NodeIcon
            operation={props.node.request.operation}
            isCompact={true}
            onClick={props.onHeaderClick}
        />
        <S.HeaderContent onMouseLeave={() => setIsTitleHovered(false)}>
            <NodeDetailsS.NodeTitle
                onMouseEnter={() => setIsTitleHovered(true)}
                onDoubleClick={startEditing}
            >
            {
                isTitleEditing ?
                    <S.TextField
                        fullWidth
                        variant="standard"
                        size="small"
                        value={title}
                        onChange={onChangeTitle}
                        onBlur={onCommitLabelChange}
                    /> :
                    props.node.label(props.hyperGraph)
            }
            </NodeDetailsS.NodeTitle>
            {
                (canEditTitle && isTitleHovered && !isTitleEditing) ?
                    <S.EditIcon className="editIcon" onClick={startEditing} /> :
                    <NodeDetailsS.CloseIcon onClick={onClose} />
            }
        </S.HeaderContent>
    </S.Header>;

};

class S {

    static readonly Header = styled(NodeDetailsS.Header)`
        &:hover {
            .editIcon {
                display: block;
            }
        }
    `;

    static readonly HeaderContent = styled.div`
        flex: 1;
        display: flex;
        align-items: center;
    `;

    static readonly EditIcon = styled(EditOutlinedIcon)`
        padding-left: 8px;
        color: rgba(255, 255, 255, 0.56);
        width: 18px;
        cursor: pointer;
        display: none;
    `;

    static readonly TextField = styled(TextField)`
        input.MuiInputBase-input {
            color: white;
        }
    `;

}