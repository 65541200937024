import {JsonObject} from "common/CommonTypes";
import {Optional} from "common/Optional";
import {UnhydratedReferences} from "metadata/UnhydratedReferences";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";

/**
 * References help map a FQN to the corresponding AssetSearchResult.
 * Here, we encapsulate map to have proper deserialization + serialization behavior.
 */
export class References {

    static readonly JSON_KEY: string = 'references';

    static fromJSON(json: JsonObject): References {
        return new References(
            json
                ? Object.entries(json).map(
                    ([key, valueJson]) => [key, AssetSearchResult.fromJSON(valueJson)]
                )
                : []
        );
    }

    /**
     * Given an existing JSON object, return new JSON with references hydrated.
     * Specifically, looks to see if FQNs are in `unhydratedReferences` and then appropriately
     * does a callback to BE to fetch asset contents of those FQNs.
     */
    static async hydrate(json: JsonObject): Promise<JsonObject> {
        const unhydatedJson = json[UnhydratedReferences.JSON_KEY];
        if (!unhydatedJson) {
            return json;
        }

        const unhydratedRefs: UnhydratedReferences = UnhydratedReferences.fromJSON(unhydatedJson);
        const resp: Either<ErrorResponse, References> = await ServiceProvider.get(MetadataService).fetchReferences(unhydratedRefs.refs);

        // copy the original JSON and update with the hydrated references
        const copiedJson = {...json};
        copiedJson[this.JSON_KEY] = resp.rightOrThrow().toJSON();
        return copiedJson;
    }

    static fromArray(refs: AssetSearchResult[]): References {
        return new References(refs.map(value => [value.fqn.toString(), value]));
    }

    static empty() {
        return new References();
    }

    private _map: Map<string, AssetSearchResult>;

    constructor(entries?: [string, AssetSearchResult][]) {
        this._map = new Map(entries);
    }

    toJSON(): JsonObject {
        const entries = Array.from(this._map.entries()).map(
            ([key, assetSearchResult]) => [key, assetSearchResult.toJSON()]
        );
        return Object.fromEntries(entries);
    }

    get(key: string): Optional<AssetSearchResult> {
        return Optional.of(this._map.get(key));
    }

    set(key: string, value: AssetSearchResult): this {
        this._map.set(key, value);
        return this;
    }

    values(): AssetSearchResult[] {
        return [...this._map.values()];
    }
}




