import * as React from "react";
import {FunctionComponent, ReactNode, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import {Optional} from "common/Optional";
import {SearchBar} from "app/components/search/SearchBar";
import DialogContent from "@mui/material/DialogContent";
import {AssetSearchParams} from "metadata/search/AssetSearchParams";
import {AssetSearchResultsTable} from "app/components/search/AssetSearchResultsTable";
import {AssetsSearchResponse} from "metadata/search/AssetsSearchResponse";
import {SearchResultsRowType} from "app/components/search/SearchResultsRowType";
import {Either} from "common/Either";
import {ErrorResponse} from "services/ApiResponse";
import {ServiceProvider} from "services/ServiceProvider";
import {MetadataService} from "services/MetadataService";
import {ScopedSearchNone} from "app/home/ScopedSearchNone";
import {AssetSearchResult} from "metadata/search/AssetSearchResult";

interface Props {
    title: string
    searchParams: AssetSearchParams
    onSelect: (selected: AssetSearchResult) => void
    onCancel: () => void
    footer?: ReactNode
}

const PAGE_SIZE = 10;

export const AssetPicker: FunctionComponent<Props> = (props: Props) => {

    const [searchParams, setSearchParams] = useState<AssetSearchParams>(props.searchParams.with({size: PAGE_SIZE}));

    const onSearch = (searchTerm: Optional<string>) => {
        searchTerm.forEach(
            term => setSearchParams(searchParams.with({size: PAGE_SIZE, query: term}))
        ).orForEach(
            () => setSearchParams(searchParams.with({size: PAGE_SIZE, query: null}))
        );
    };

    const onSearchAssets = (params: AssetSearchParams, controller: AbortController): Promise<Either<ErrorResponse, AssetsSearchResponse>> => {
        return ServiceProvider.get(MetadataService)
            .assetSearch(params, controller.signal);
    };

    return <Dialog
        open={true}
        onClose={props.onCancel}
        fullWidth
        maxWidth="md"
    >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
            <SearchBar
                scopedSearch={new ScopedSearchNone()}
                onScopeReset={() => {}}
                onSearch={onSearch}
                delayOnSearch={500}
                disablePlaceholderText={true}
            />
            <AssetSearchResultsTable
                searchParams={searchParams}
                onSelect={props.onSelect}
                rowType={SearchResultsRowType.TABLE}
                onSearch={onSearchAssets}
            />
            {props.footer}
        </DialogContent>
    </Dialog>;

};