import {DatasetType} from "metadata/dataset/DatasetType";
import {DatasetStatusMetadata} from "metadata/dataset/DatasetStatusMetadata";
import {DatasetStatusDisplay} from "metadata/dataset/DatasetStatusDisplay";


type UserDatasetStatusMetadataJson = {}

/**
 * Metadata for a User dataset status.
 */
export class PersonalDatasetStatusMetadata implements DatasetStatusMetadata {

    static fromJSON(json: UserDatasetStatusMetadataJson): PersonalDatasetStatusMetadata {
        return new PersonalDatasetStatusMetadata();
    }

    get sources(): DatasetType[] {
        return [DatasetType.PERSONAL];
    }

    get isStreamable(): boolean {
        return false;
    }

    get type(): DatasetType {
        return DatasetType.PERSONAL;
    }

    get statusDisplay() {
        return DatasetStatusDisplay.PERSONAL;
    }
}