import * as React from "react";
import {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {Colors, FontSizes} from "app/components/StyleVariables";
import styled from "@emotion/styled";
import AceEditor from "react-ace";
import language_tools from "ace-builds/src-noconflict/ext-language_tools";
import {ArcQLBundle} from "metadata/query/ArcQLBundle";
import {CommonS} from "app/components/CommonS";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {Optional} from "common/Optional";
import {ExpressionField} from "metadata/query/ExpressionField";
import "ace-builds/src-noconflict/mode-sql";
import {ProjectionValidation} from "metadata/query/ProjectionValidation";

export type Props = {
    className?: string
    arcQLBundle: ArcQLBundle
    withHelperText?: boolean
    // existing field if editing
    field?: ExpressionField
    // expression field has changed and save requested
    onChange: (field: ExpressionField, shouldClose: boolean) => void
    // close without any changes
    onClose: () => void
}

/**
 * Display content of the expression editor.
 */
export const ExpressionEditorContent: FunctionComponent<Props> = (props: Props) => {

    const [expression, setExpression] = useState<string>(props.field ? props.field.expression : '');
    const [as, setAs] = useState<string>(props.field ? props.field.as : '');
    const [uncommittedWarning, setUncommittedWarning] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<Optional<string>>(Optional.none());

    useEffect(() => {
        // add some auto complete options to ace editor
        language_tools.setCompleters([{
            getCompletions: (editor: any, session: any, pos: any, prefix: string, callback: any) => {
                const prefixNormalized = prefix.toLowerCase();

                // autocomplete to any existing fields in the query
                const matchedFields = props.arcQLBundle.arcql.fields.fields
                    .filter(f => f.as.toLowerCase().startsWith(prefixNormalized))
                    .map(f => ({
                        caption: f.as,
                        value: `"${f.as}"`,
                        meta: "query field"
                    }));

                // autocomplete to any columns in the dataset
                const matchedColumns = props.arcQLBundle.dataset.allFields()
                    .filter(f =>
                        f.label.toLowerCase().startsWith(prefixNormalized)
                        || f.name.toLowerCase().startsWith(prefixNormalized)
                    )
                    .map(f => ({
                        caption: f.label,
                        value: `"${f.name}"`,
                        meta: "dataset column"
                    }));

                callback(null, [...matchedFields, ...matchedColumns]);
            }
        }]);
    }, []);

    const onChangeAs = (event: ChangeEvent<HTMLInputElement>) => {
        setAs(event.target.value);
    };

    // check if there are uncommitted changes we should confirm before closing the dialog
    const checkUncommitted = () => {
        if (props.field == null) {
            // check if there is an expression if new
            if (expression.length > 0 || as.length > 0) {
                setUncommittedWarning(true);
                return;
            }
        } else {
            // check if expression changed if editing
            if (props.field.expression !== expression) {
                setUncommittedWarning(true);
                return;
            }
        }

        props.onClose();
    };
    // on adding an expression, do some validation
    const onAdd = () => {
        const expressionSanitized = expression.trim();
        const asSanitized = as.trim();
        if (expressionSanitized.length === 0 || asSanitized.length === 0) {
            setValidationError(Optional.some('Expression and as name required.'));
            return;
        }

        // only validate for existing fields if creating new
        if (props.field == null && props.arcQLBundle.arcql.fields.getPossible(asSanitized).isPresent) {
            setValidationError(Optional.some('Field as name already used.'));
            return;
        }

        // validate as has no illegal characters
        if (!ProjectionValidation.isValid(as)) {
            setValidationError(Optional.some(`Expression name '${as}' cannot contain ${ProjectionValidation.ILLEGAL_CHARS}.`));
            return;
        }

        props.onChange(new ExpressionField(expressionSanitized, asSanitized), true);
        checkUncommitted();
    };

    const closeWarnings = () => {
        setUncommittedWarning(false);
        setValidationError(Optional.none());
    };


    return <S.Content className={props.className}>
        {props.withHelperText && <S.HelpText>{props.field ? 'Edit' : 'add'} expression:</S.HelpText>}
        <S.Editor>
            <AceEditor
                mode="sql"
                theme="github"
                height="100%"
                width="100%"
                value={expression}
                onChange={setExpression}
                editorProps={{
                    $blockScrolling: true
                }}
                setOptions={{
                    printMargin: 120,
                    wrap: true,
                    useWorker: false,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true
                }}
            />
        </S.Editor>
        <S.AsRow>
            <S.HelpText>as: </S.HelpText>
            <S.Input
                margin="dense"
                value={as}
                placeholder="Enter a field name..."
                onChange={onChangeAs}
            />
        </S.AsRow>
        <S.ControlRow>
            <S.ControlRight>
                <CommonS.Button onClick={onAdd}>
                    {props.field ? 'Save' : 'Add'}
                </CommonS.Button>
            </S.ControlRight>
        </S.ControlRow>
        {
            uncommittedWarning && <Dialog open={true} onClose={closeWarnings}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to close without adding the expression?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CommonS.Button onClick={props.onClose}>Close Without Saving</CommonS.Button>
                    <CommonS.Button onClick={closeWarnings}>Back</CommonS.Button>
                </DialogActions>
            </Dialog>
        }
        {
            validationError.isPresent && <Dialog
                open={true}
                onClose={closeWarnings}
            >
                <DialogContent>
                    <DialogContentText>{validationError.get()}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CommonS.Button onClick={closeWarnings}>Back</CommonS.Button>
                </DialogActions>
            </Dialog>
        }
    </S.Content>;
};

class S {
    static readonly Content = styled.div`
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 18px;
        width: 425px;
    `;

    static readonly Input = styled(TextField)`
        margin: 0;

        input {
            font-size: ${FontSizes.small};
            padding: 6px 8px;
        }
    `;

    static readonly Editor = styled.div`
        width: 100%;
        height: 200px;
    `;

    static readonly HelpText = styled.div`
        color: ${Colors.textSecondary};
        font-size: ${FontSizes.small};
        padding: 6px 0;
    `;

    static readonly AsRow = styled.div`
        display: flex;
        gap: 8px;
        margin-top: 16px;
    `;

    static readonly ControlRow = styled.div`
        display: flex;
        gap: 8px;
    `;

    static readonly ControlRight = styled.div`
        display: flex;
        margin-left: auto;
    `;
}