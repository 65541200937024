import {HyperGraphNode} from "metadata/hypergraph/HyperGraphNode";
import {Optional} from "common/Optional";
import {HyperGraphLayout} from "metadata/hypergraph/HyperGraphLayout";
import {HyperGraphNodeLayout} from "metadata/hypergraph/HyperGraphNodeLayout";
import {isEqual} from "lodash";
import {Enum} from "common/Enum"
import {toObject} from "common/Collections";
import {JsonObject} from "common/CommonTypes";
import {VisualizationConfig} from 'metadata/query/ArcQLVisualizations';
import {QueryContainerNode} from 'metadata/hypergraph/nodes/QueryContainerNode';


/**
 * Immutable HyperGraph.
 *
 * @author zuyezheng
 */
export class HyperGraph {

    // lookup for children by parent id
    private readonly _children: Map<string, HyperGraphNode[]>;

    constructor(
        // all nodes in the graph
        private readonly _nodes: Map<string, HyperGraphNode>,
        private readonly root: Optional<HyperGraphNode>,
        // full or partial layouts of nodes in the graph, this could be partial since we don't layout on every add of a
        // new node and could instead choose to do them in batches
        private readonly _layouts: Map<string, HyperGraphNodeLayout>,
        // the working node is the "primary" node and there should always be one if the graph is not empty, it's what
        // all branching and other operations are based off of
        public readonly _workingState: Optional<WorkingNodeState>,
        // nodes that are selected, if there are selections, one will be the working node, selections are maintained as
        // ids to avoid object reference issues as nodes are replaced vs mutable
        public readonly _selected: Set<string>
    ) {
        this._children = new Map();
        this._nodes.forEach(node => {
            const parent = node.request.parent;
            if (parent !== null) {
                if (!this._children.has(parent)) {
                    this._children.set(parent, []);
                }
                this._children.get(parent).push(node);
            }
        });
    }

    get isEmpty(): boolean {
        return this._nodes.size === 0;
    }

    get nodes(): HyperGraphNode[] {
        return Array.from(this._nodes.values());
    }

    getPossible(id: string): Optional<HyperGraphNode> {
        return Optional.of(this._nodes.get(id));
    }

    get(id: string): HyperGraphNode {
        return this.getPossible(id)
            .getOrElse(() => {
                throw new Error(`Node ${id} is missing.`);
            });
    }

    /**
     * Get a possible node with an expected type.
     */
    getPossibleTyped<T extends HyperGraphNode>(id: string, expectedType: abstract new (...args: any[]) => T): Optional<T> {
        return Optional.of(this._nodes.get(id))
            .flatMap(n => Optional.ofType(n, expectedType));
    }

    /**
     * Get a node or throw.
     */
    getTyped<T extends HyperGraphNode>(id: string, expectedType: abstract new (...args: any[]) => T): T {
        return this.getPossibleTyped(id, expectedType)
            .getOrElse(() => {
                throw new Error(`Mode ${id} is missing or of the wrong type.`);
            });
    }

    isLeaf(id: string): boolean {
        // make sure it's actually a node
        this.get(id);

        // make sure no other node points to this one
        return this.nodes.every(n => n.request.parent !== id);
    }

    /**
     * Find all children of a given node.
     */
    children(id: string): HyperGraphNode[] {
        return this._children.get(id) || [];
    }

    /**
     * Find all descendents of a given node.
     */
    descendents(id: string): HyperGraphNode[] {
        const children = this.children(id);
        return [
            ...children,
            ...children.flatMap(c => this.descendents(c.id))
        ];
    }

    /**
     * Find all direct ancestors of a given node.
     */
    ancestors(id: string): HyperGraphNode[] {
        let node = this.get(id);
        const ancestors: HyperGraphNode[] = [];
        while (node.request.parent !== null) {
            const parent = this.get(node.request.parent);
            ancestors.push(parent);
            node = parent;
        }
        return ancestors;
    }

    /**
     * Strictly add a new node to the graph. The new node must be connected to an existing node unless the graph is
     * empty.
     */
    upsert(node: HyperGraphNode): HyperGraph {
        // make sure we only have a single root

        if (this._nodes.size === 0 && node.request.parent !== null) {
            throw new Error('Can\'t add root node with a parent or in non-empty graph.');
        }
        if (
            // only need parent if not adding or updating the root node
            this._nodes.size > 0 &&
            this.root.map(r => r.id !== node.id).getOr(true) &&
            // make sure there's a valid parent
            this.getPossibleTyped(node.request.parent, HyperGraphNode).isNone
        ) {
            throw new Error('Node missing valid parent.');
        }

        return this.with({
            nodes: new Map([
                ...this._nodes,
                // last one wins so will handle add or update
                [node.id, node]
            ]),
            root: this.isEmpty ? Optional.some(node) : this.root
        });
    }

    /**
     * Return all nodes without a layout.
     */
    get nodesWithoutLayout(): HyperGraphNode[] {
        return this.nodes.filter(n => !this._layouts.has(n.id));
    }

    /**
     * Return tuples of nodes with layouts for all nodes with layouts.
     */
    get nodesWithLayout(): [HyperGraphNode, HyperGraphNodeLayout][] {
        return [...this.layouts.entries()]
            .map(([id, layout]) => [this.get(id), layout]);
    }

    /**
     * Return a copy of the node layouts.
     */
    get layouts(): Map<string, HyperGraphNodeLayout> {
        return new Map(this._layouts);
    }

    /**
     * Layout any nodes without layouts and return a new graph.
     */
    layoutNewNodes(): HyperGraph {
        // no new nodes
        if (this.nodesWithoutLayout.length === 0) {
            return this;
        }

        return this.withLayout(new HyperGraphLayout(this).layout());
    }

    withLayout(layouts: Map<string, HyperGraphNodeLayout>): HyperGraph {
        return this.with({layouts});
    }

    /**
     * Change selections and use the first as the working node.
     */
    withSelections(selectedNodeIds: string[]): HyperGraph {
        const newWorkingState = {
            nodeId: selectedNodeIds[0],
            status: WorkingNodeStatus.FULL
        };
        const newSelectedNodeIds = new Set(selectedNodeIds);

        // return the current if the selections and working states are the same
        if (
            isEqual(this.selectedNodeIds, newSelectedNodeIds) &&
            this._workingState.map(s => isEqual(s, newWorkingState)).getOr(false)
        ) {
            return this;
        }

        return this.with({
            workingState: Optional.some(newWorkingState),
            selected: newSelectedNodeIds,
        });
    }

    /**
     * Change to the working status of the current node.
     */
    withWorkingStatus(status: WorkingNodeStatus): HyperGraph {
        return this._workingState.map(s => {
            if (s.status === status) {
                // no changes
                return this;
            } else {
                return this.with({
                    workingState: Optional.some({
                        nodeId: s.nodeId,
                        status
                    })
                });
            }
        }).getOr(this);
    }

    /**
     * Update the query for the working node with visualization changes.
     */
    withVisualizationConfig(config: VisualizationConfig): HyperGraph {
        return this.workingNode
            .filter(node => node instanceof QueryContainerNode)
            .map(node => this.upsert(
                (<QueryContainerNode> node).withVisualizationConfig(config)
            ))
            .getOr(this);
    }

    /**
     * Return all selected nodes.
     */
    get selectedNodes(): HyperGraphNode[] {
        return Array.from(this._selected).map(id => this.get(id));
    }

    /**
     * Return all selected node ids.
     */
    get selectedNodeIds(): Set<string> {
        return new Set(this._selected);
    }

    /**
     * Return the working node, the main visualized node that maybe be fully or partially visualized.
     */
    get workingNode(): Optional<HyperGraphNode> {
        return this._workingState.map(s => this.get(s.nodeId));
    }

    /**
     * Get the hydrated version of the working state.
     */
    get workingState(): [HyperGraphNode, WorkingNodeStatus] {
        return this._workingState
            .map<[HyperGraphNode, WorkingNodeStatus]>(s => [this.get(s.nodeId), s.status])
            .getOrElse(() => {
                throw new Error('No working node, is the graph initialized?');
            });
    }

    toJSON(): JsonObject {
        return {
            nodes: [...this._nodes.values()],
            root: this.root.map(n => n.id).nullable,
            layouts: toObject(this._layouts),
            workingState: this._workingState.nullable,
            selected: [...this._selected]
        };
    }

    private with(props: Partial<HyperGraphProps>): HyperGraph {
        return new HyperGraph(
            props.nodes ?? this._nodes,
            props.root ?? this.root,
            props.layouts ?? this._layouts,
            props.workingState ?? this._workingState,
            props.selected ?? this._selected
        );
    }

    static empty(): HyperGraph {
        return new HyperGraph(new Map(), Optional.none(), new Map(), Optional.none(), new Set());
    }

}

type HyperGraphProps = {
    nodes: Map<string, HyperGraphNode>,
    root: Optional<HyperGraphNode>,
    layouts: Map<string, HyperGraphNodeLayout>,
    workingState: Optional<WorkingNodeState>,
    selected: Set<string>
}

type WorkingNodeState = {
    // node being fully or partially visualized
    nodeId: string,
    // match status of the visualization node and actual query results as the result of undo/redo
    status: WorkingNodeStatus
}

export class WorkingNodeStatus extends Enum {

    // node and visualization are a full match
    static readonly FULL = new WorkingNodeStatus('full');
    // partial match of the current visualization, with the query being slightly ahead (branching should occur with
    // the current node)
    static readonly AHEAD = new WorkingNodeStatus('ahead');
    // partial match of the current visualization, with the query being slightly behind (branching should occur with
    // the parent node)
    static readonly BEHIND = new WorkingNodeStatus('behind');

}
WorkingNodeStatus.finalize();

