import {
    ToolbarActions,
    ToolbarActionsGroup,
    ToolbarActionsMenu,
    ToolbarActionsMore,
    ToolbarActionsSection,
    ToolbarActionsSingle
} from "app/components/toolbar/ToolbarActions";
import {StandardActions, ToolbarAction} from "app/components/toolbar/ToolbarAction";
import {Optional} from "common/Optional";
import {QueryBuilderDelegate} from "app/query/QueryBuilderDelegate";
import {ArcQL} from "metadata/query/ArcQL";
import {ServiceProvider} from "services/ServiceProvider";
import {InternalRouterService} from "services/InternalRouterService";
import {TabPath} from "app/TabPath";
import {FQN} from "common/FQN";
import {AssetType} from "metadata/AssetType";
import {LinkService} from "services/LinkService";
import {QueryLoader} from "app/query/QueryLoader";
import {ReplaceReason} from "metadata/ReplaceReason";
import {QueryBuilderTool} from "app/query/QueryBuilderTool";
import {useState} from "react";
import {HyperGraphAction} from 'app/components/toolbar/HyperGraphAction';

/**
 * Query builder specific toolbar handler.
 *
 * @author zuyezheng
 */
export const useToolbar = (
    path: TabPath,
    delegate: Optional<QueryBuilderDelegate>,
    query: Optional<ArcQL>,
    // initial set of tools to show
    initial: QueryBuilderTool[]
): {
    isToolShown: (tool: QueryBuilderTool) => boolean,
    // toggle the status of the tool or explicitly enable or disable it
    toggleTool: (tool: QueryBuilderTool, explicit?: boolean) => void,
    toolbarActions: ToolbarActions
    onToolbarAction: (action: ToolbarAction) => void
} => {

    const [toolsShown, setToolsShown] = useState<Set<QueryBuilderTool>>(new Set(initial));

    const toggleTool = (tool: QueryBuilderTool, explicit?: boolean) => {
        setToolsShown(toolsShown => {
            const newToolsShown = new Set(toolsShown);

            if (explicit == null) {
                // status not explicitly specified, toggle it
                if (newToolsShown.has(tool)) {
                    newToolsShown.delete(tool);
                } else {
                    newToolsShown.add(tool);
                }
            } else {
                // status explicitly specified
                if (explicit) {
                    newToolsShown.add(tool);
                } else {
                    newToolsShown.delete(tool);
                }
            }

            // update state if there were changes
            return newToolsShown.size === toolsShown.size ? toolsShown : newToolsShown;
        });
    };

    const isToolShown = (tool: QueryBuilderTool) => {
        return toolsShown.has(tool);
    };

    const fork = () => {
        query.map(q => ServiceProvider.get(InternalRouterService).route(
            'queryBuilder',
            TabPath.fromFqn(new FQN(
                path.accountName,
                path.folderName,
                AssetType.ARCQL,
                path.assetName + '-' + ServiceProvider.get(LinkService).incrementCounter(AssetType.ARCQL)
            )),
            new Map([[
                'arcql', q.with({
                    label: q.label + ' Fork'
                }).toForkingJSON()
            ]])
        ));
    };

    const refresh = () => {
        query.forEach(query => QueryLoader.refresh(query).then(
            response => delegate.forEach(
                delegate => delegate.replace(response.rightOrThrow(), ReplaceReason.REFRESH)
            )
        ));
    };

    const onToolbarAction = (action: ToolbarAction) => {
        switch (action.id) {
            case StandardActions.UNDO().id:
                delegate.forEach(d => d.undo());
                break;
            case StandardActions.REDO().id:
                delegate.forEach(d => d.redo());
                break;
            case StandardActions.DEV.id:
                toggleTool(QueryBuilderTool.DEV);
                break;
            case StandardActions.SAVE.id:
            case StandardActions.SAVE_AS.id:
                toggleTool(QueryBuilderTool.SAVE);
                break;
            case StandardActions.FORK.id:
                fork();
                break;
            case StandardActions.VERSIONS.id:
                toggleTool(QueryBuilderTool.VERSIONS);
                break;
            case StandardActions.ARCHIVE.id:
                toggleTool(QueryBuilderTool.ARCHIVE);
                break;
            case StandardActions.SHARE.id:
                toggleTool(QueryBuilderTool.SHARE);
                break;
            case StandardActions.REFRESH.id:
                refresh();
                break;
            case 'hyperGraph':
                // default to closed dataset panel if hypergraph is shown and vice versa
                toggleTool(QueryBuilderTool.DATASET_PANEL, isToolShown(QueryBuilderTool.HYPER_GRAPH));
                toggleTool(QueryBuilderTool.HYPER_GRAPH);
                break;
        }
    };

    const saveActions = query.filter(q => q.isExisting)
        .map<ToolbarActionsSection>(() => new ToolbarActionsMenu(
            StandardActions.SAVE,
            [[
                StandardActions.FORK,
                StandardActions.VERSIONS,
                StandardActions.ARCHIVE
            ]],
            true
        ))
        .getOrElse(() => new ToolbarActionsSingle(StandardActions.SAVE_AS));

    const toolbarActions = new ToolbarActions([
        new ToolbarActionsGroup([
            StandardActions.UNDO(delegate.map(d => !d.hasUndo).getOr(true)),
            StandardActions.REDO(delegate.map(d => !d.hasRedo).getOr(true)),
        ]),
        new ToolbarActionsMore([[
            ...query.filter(q => q.isExisting)
                .map(() => StandardActions.REFRESH)
                .array,
            StandardActions.SHARE,
            StandardActions.DEV
        ]]),
        new ToolbarActionsSingle(
            HyperGraphAction('hyperGraph', 'Memory', 'View, search, and annotate \nthe HyperGraph\'s memory \nof your query.'),
        ),
        saveActions
    ]);

    return { isToolShown, toggleTool, toolbarActions, onToolbarAction };

};