import * as React from "react";
import {PropsWithChildren, ReactElement} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import {FontSizes} from "app/components/StyleVariables";
import {PopoverOrigin} from "@mui/material/Popover";
import Menu from "@mui/material/Menu";
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";

type Props<T> = {
    // element and where to anchor the editor popup
    el: Element
    anchorOrigin: PopoverOrigin
    transformOrigin?: PopoverOrigin

    // items in sections, if more than 1 section dividers will be rendered
    items: T[][]

    // TODO ZZ T should implement an interface and these should all be properties of it and encapsulated properly
    itemId: (item: T) => string
    itemLabel: (item: T) => string
    itemIcon?: (item: T) => React.JSX.Element
    itemColor?: (item: T) => string;

    isSelected?: (item: T) => boolean
    isDisabled?: (item: T) => boolean

    onSelect: (selected: T) => void
    onClose: () => void
}

/**
 * Menu of typed objects.
 *
 * @author zuyezheng
 */
export const TypedMenu = <T extends Object>(props: PropsWithChildren<Props<T>>): ReactElement => {

    const makeItem = (item: T) => {
        const isSelected = props.isSelected && props.isSelected(item);
        const color = props.itemColor && props.itemColor(item);
        const iconElement = props.itemIcon ? props.itemIcon(item) : null;

        return <MenuItem
            key={props.itemId(item)}
            onClick={() => props.onSelect(item)}
            style={{color: color}}
            disabled={props.isDisabled && props.isDisabled(item)}
        >
            {iconElement &&
                <ListItemIcon>
                    {iconElement}
                </ListItemIcon>
            }
            <S.ListItemText primary={props.itemLabel(item)}/>
            {
                isSelected && <S.ListItemIcon>
                    <CheckOutlinedIcon/>
                </S.ListItemIcon>
            }
        </MenuItem>;
    };

    const menuItems = props.items.flatMap((section: any[], sectionI: number) => {
        const divider = sectionI > 1 ? [<Divider/>] : [];

        return [
            ...divider,
            ...section.map(makeItem)
        ];
    });

    return <Menu
        open={true}
        anchorEl={props.el}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        onClose={props.onClose}
        autoFocus={false}
    >
        {menuItems}
    </Menu>;

};

class S {

    static readonly ListItemText = styled(ListItemText)`
        span {
            font-size: ${FontSizes.small};
        }
    `;

    static readonly ListItemIcon = styled(ListItemIcon)`
        &.MuiListItemIcon-root {
            min-width: 0;
            padding-left: 8px;
        }
    `;
}