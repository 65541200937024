import {DiscreteSelection, RangeSelection, VizSelection} from "engine/actor/VizSelection";
import {JsonObject} from "common/CommonTypes";
import {VizSelectionType} from "engine/actor/VizSelectionType";

/**
 * Factory for creating VizSelection from JSON.
 */
export class VizSelectionFactory {
    static fromJSON(json: JsonObject): VizSelection {
        switch (VizSelectionType.get(json.type)) {
            case VizSelectionType.DISCRETE:
                return DiscreteSelection.fromJSON(json);
            case VizSelectionType.RANGE:
                return RangeSelection.fromJSON(json);
            default:
                throw new Error(`Unsupported selection type: ${json.type}`);
        }
    }
}